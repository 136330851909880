import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button, Field, TableSkeleton } from "../../../components";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase";
import useFormatter from "../../../hooks/useFormatter";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

export default function PublisherBalances() {
  const {
    getPublisherBalances,
    updateBalance,
    updateCPM,
    updatePublisherStatus,
  } = useFetch();
  const [publisherBalances, setPublisherBalances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newBalance, setNewBalance] = useState(null);
  const [newCPM, setNewCPM] = useState(null);
  const [modalType, setModalType] = useState(null);
  const { addCommasKeepMinus, formatDate } = useFormatter();
  const [activeDetails, setActiveDetails] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);

  const { budget, arrowUp, arrowDown } = localData.svgs;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!auth.currentUser) return;
        const raw = {
          uuid: auth.currentUser.uid,
        };
        const result = await getPublisherBalances(() => {}, raw);
        const data = result.res_data.map((item) => {
          return {
            ...item,
            status: item.active == "1" ? "Active" : "Inactive",
            sites: item.sites.map((site, index) => {
              return {
                ...site,
              };
            }),
          };
        });
        setPublisherBalances(data);
      } catch (error) {
        console.error("Error fetching publisher data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty depend
  const { textPreloader } = localData.images;
  const openModal = (item, type) => {
    setShowModal(true);
    setCurrentItem(item);
    setModalType(type);
  };
  const closeModal = () => {
    setShowModal(false);
    setCurrentItem(null);
    setNewBalance(null);
    setModalType(null);
    setButtonLoading(false);
  };

  const handleConfirm = async () => {
    if (!auth.currentUser) return;
    let raw = {
      uuid: auth.currentUser.uid,
    };
    let data;
    if (modalType == "balance") {
      data = {
        publisherId: currentItem.user_id,
        amount: newBalance,
        uuid: auth.currentUser.uid,
      };
    } else {
      data = {
        publisherId: currentItem.user_id,
        cpm: newCPM,
        uuid: auth.currentUser.uid,
      };
    }

    try {
      setButtonLoading(true);
      if (modalType === "balance") {
        await updateBalance(() => {}, data);
      } else {
        await updateCPM(() => {}, data);
      }

      const result = await getPublisherBalances(() => {}, raw);
      const data = result.res_data.map((item) => {
        return {
          ...item,
          status: item.active == "1" ? "Active" : "Inactive",
          sites: item.sites.map((site, index) => {
            return {
              ...site,
            };
          }),
        };
      });
      setPublisherBalances(data);
      closeModal();
      toast.success("Updated successfully");
    } catch (e) {
      toast.error(e?.message || "Oops looks like something went wrong");
      closeModal();
    }
  };

  const handleStatusChange = async (status, userId) => {
    if (!auth.currentUser) return;
    let raw = {
      uuid: auth.currentUser.uid,
    };
    const reqData = {
      userid: userId,
      status: status,
      uuid: auth.currentUser.uid,
    };
    try {
      setActivateLoading(true);

      await updatePublisherStatus(() => {}, reqData);

      const result = await getPublisherBalances(() => {}, raw);
      const data = result.res_data.map((item) => {
        return {
          ...item,
          status: item.active == "1" ? "Active" : "Inactive",
          sites: item.sites.map((site, index) => {
            return {
              ...site,
            };
          }),
        };
      });
      setPublisherBalances(data);

      setActivateLoading(false);
      toast.success("Updated successfully");
    } catch (e) {
      console.log(e);
      toast.error(e?.message || "Oops looks like something went wrong");
      setActivateLoading(false);
    }
  };

  const handleInputChange = (event) => {
    if (modalType === "balance") {
      setNewBalance(event.target.value);
    } else {
      setNewCPM(event.target.value / 100);
    }
  };

  return (
    <>
      <div
        className="modal-dialog-scrollable accounts modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">PUBLISHERS</h5>
        </div>
        <div className="scroll">
          {isLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>
                    #
                  </th>
                  <th style={{ width: 0 }}>Publisher Name</th>
                  <th style={{ width: 0 }}>Publisher Status</th>
                  <th style={{ width: 0 }}>Email</th>
                  <th style={{ width: 0 }}>Balance</th>
                  <th style={{ width: 0 }}>Pending withdrawals</th>
                  <th style={{ width: 0 }}>CPM</th>
                  <th style={{ width: 0, textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!publisherBalances.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  publisherBalances.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            {" "}
                            <Button
                              variant="circle"
                              size="sm"
                              color="secondary"
                              icon={
                                activeDetails.id === item.id
                                  ? arrowUp
                                  : arrowDown
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveDetails(
                                  activeDetails.id === item.id
                                    ? {}
                                    : { id: item.id }
                                );
                              }}
                            />
                          </td>
                          <td>{item.contact_name}</td>
                          <td className="table-td-status">
                            <div
                              data-tooltip-id="status-tooltip"
                              className={`status ${item.status.toLowerCase()}`}
                            >
                              {item.status}
                            </div>
                          </td>
                          <td>{item.email_address}</td>
                          <td>${addCommasKeepMinus(item.balance) || 0}</td>
                          <td>${addCommasKeepMinus(item.pending) || 0}</td>
                          <td>{item.cpm * 100}% </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                size="sm"
                                onClick={() => openModal(item, "balance")}
                              >
                                Adjust Balance
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="sm"
                                onClick={() => openModal(item, "cpm")}
                              >
                                Adjust CPM
                              </Button>
                              {item.status == "Active" ? (
                                <Button
                                  variant="outlined"
                                  color="danger"
                                  size="sm"
                                  disabled={activateLoading}
                                  onClick={() =>
                                    handleStatusChange(0, item.user_id)
                                  }
                                >
                                  Deactivate
                                  {activateLoading ? (
                                    <div className="after-button-loading-spinner">
                                      <CircularProgress className="white" />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="success"
                                  size="sm"
                                  disabled={activateLoading}
                                  onClick={() =>
                                    handleStatusChange(1, item.user_id)
                                  }
                                >
                                  Activate
                                  {activateLoading ? (
                                    <div className="after-button-loading-spinner">
                                      <CircularProgress className="white" />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                        {activeDetails.id === item.id &&
                          item.sites.length > 0 && (
                            <>
                              <tr className="light-table-heads">
                                <th
                                  style={{
                                    width: 0,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                  }}
                                >
                                  #
                                </th>
                                <th style={{ width: 0 }}>Name</th>
                                <th style={{ width: 0 }}>Contact</th>
                                <th style={{ width: 0 }}>Email</th>
                                <th style={{ width: 0 }}>Website</th>
                                <th></th>
                              </tr>
                              {item.sites.map((subItem, subIndex) => {
                                return (
                                  <tr key={subIndex} className="details-tr">
                                    <td>{subIndex + 1}</td>
                                    <td>{subItem.name}</td>
                                    <td>{subItem.contact}</td>
                                    <td>{subItem.email}</td>
                                    <td>{subItem.website}</td>
                                    <td></td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        <br />
        <br />
      </div>
      <br />
      <br />
      <Modal isOpen={showModal} onClose={closeModal}>
        <div
          className="confirmation-modal"
          style={{
            width: "100%",
            padding: "20px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <p>
            {modalType == "balance"
              ? "Adjust publisher balance"
              : "Adjust publisher CPM"}
          </p>
          {modalType == "balance" ? (
            <Field
              type="number"
              label="Balance"
              color="secondary"
              placeholder=""
              value={currentItem?.balance || 0}
              required={true}
              name="priceAmount"
              size="sm"
              callback={handleInputChange}
              startIcon={budget}
            />
          ) : (
            <Field
              type="number"
              label="CPM"
              color="secondary"
              placeholder=""
              value={currentItem?.cpm * 100 || 0}
              required={true}
              name="cpm"
              size="sm"
              callback={handleInputChange}
            />
          )}

          <div className="confirmation-modal-buttons">
            <Button
              variant="outlined"
              color="primary"
              size="sm"
              onClick={handleConfirm}
            >
              Confirm{" "}
              {buttonLoading ? (
                <div className="after-button-loading-spinner">
                  <CircularProgress className="white" />
                </div>
              ) : (
                ""
              )}
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              color="danger"
              size="sm"
              onClick={closeModal}
            >
              Cancel{" "}
              {buttonLoading ? (
                <div className="after-button-loading-spinner">
                  <CircularProgress className="white" />
                </div>
              ) : (
                ""
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-user" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};
