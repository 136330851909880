import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import localData from '../../../localData';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { useGlobalContext } from '../../../context';

const ResetPasswordConfirmation = () => {
  const { resetAccountPasswordConfirm } = useFetch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { logoSm } = localData.images;
  const {successAlert, errorAlert} = useGlobalContext()

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: ""
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  if (!token) {
    navigate("/sign-in");
  }

  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    if (!minLength) {
      errorAlert("Password must be at least 8 characters long!");
      return false;
    }
    if (!hasUppercase) {
      errorAlert("Password must contain at least one uppercase letter!");
      return false;
    }
    if (!hasSymbol) {
      errorAlert("Password must contain at least one special character!");
      return false;
    }
  
    return true;
  };

  const handleResetPassword = async () => {
    if (passwordData.password !== passwordData.confirmPassword) {
      errorAlert("Passwords do not match!");
      return;
    }

    if (!validatePassword(passwordData.password)) {
      return;
    }
  

    const tempRaw = {
      token: token,
      password: passwordData.password
    };

    try {
      const data = await resetAccountPasswordConfirm(() => {}, tempRaw);
      console.log(data, "Data");
      successAlert(data.res_msg)
      navigate("/sign-in")
    } catch (error) {
      errorAlert(error.res_msg)
      navigate("/sign-in")
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh', backgroundColor: '#f4f6f8' }}
    >
      <Box
        sx={{
          width: 600,
          padding: 4,
          borderRadius: 2,
          boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
          backgroundColor: '#fff',
          textAlign: 'center',
        }}
      >
        {/* Placeholder for Logo */}
        <Box sx={{ marginBottom: 2, maxWidth: "50px", margin: "0 auto" }}>
          <img src={logoSm} alt="Logo" />
        </Box>

        {/* Title */}
        <Typography variant="h5" fontWeight="bold">
          Set up your password
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 3 }}>
          Almost done, enter your new password.
        </Typography>

        {/* Password Input Fields */}
        <Box component="form" noValidate autoComplete="off">
          <TextField
            fullWidth
            label="Password"
            size='small'
            name="password"
            type={showPassword.password ? "text" : "password"}
            margin="normal"
            variant="outlined"
            value={passwordData.password}
            onChange={handleChange}
            sx={{ marginBottom: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleTogglePasswordVisibility('password')} edge="end">
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            size='small'
            name="confirmPassword"
            type={showPassword.confirmPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            value={passwordData.confirmPassword}
            onChange={handleChange}
            sx={{ marginBottom: 3 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleTogglePasswordVisibility('confirmPassword')} edge="end">
                    {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Reset Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              paddingY: 1,
              background: "linear-gradient(96deg, #1BA7C3 3.21%, #3BF693 107.26%)",
              color: '#fff',
            }}
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        </Box>

        {/* Sign In Link */}
        <Typography variant="body2" sx={{ marginTop: 3 }}>
          Already have an account?{' '}
          <Link href="/signin" underline="hover" color="#00B268">
            Sign In
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};

export default ResetPasswordConfirmation;