import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { Button, Drawer, Tabs } from "../../../components";
import InstallPixelTab from "../tracking-drawer/tracking-tabs/install-pixel-tab/InstallPixelTab";
import CreateConversionTab from "../tracking-drawer/tracking-tabs/create-conversion-tab/CreateConversionTab";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  CircularProgress ,
  Box,
  Alert,
  AlertTitle,
  Stack
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetch from "../../../hooks/useFetch";

const { arrowLeft, plus } = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel,serverToServer } = localData.images;

export default function ValidateConversion({ setIsOpen }) {

  const { requestedData, trackingState, isConversionModalOpen, setIsConversionModalOpen, validateConversionRequest, verifyConversionRequest, conversionOptions,
    setConversionOptions } =
    useGlobalContext();
  
  const { defaultTab, setDefaultTab, isEditingMode, editingTagId, setEditingTagId } = trackingState;
  setEditingTagId(editingTagId);

  const { close, arrowDown, arrowUp } = localData.svgs;
  const [isLoading, setIsLoading] = useState(false);
  const [adCode, setAdCode] = useState(''); 
  const [aViewerId, setViewerId] = useState('');
  const [aBannerId, setBannerId] = useState(0);
  const [verifyloading, setverifyLoading] = useState(false);
  const [verifysuccess, setverifySuccess] = useState(false);
  const navigate = useNavigate();
  const { validateConversion } = useFetch();
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
    
  const handleChange = (event) => {
    const value = event.target.value;
    setUrl(value);
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/;
    if (!value) {
      setError("URL is required");
    } else if (!urlPattern.test(value)) {
      setError("Enter a valid URL (e.g., https://example.com)");
    } else {
      setError(""); // Clear error if valid
    }
  };
  
  const [intervalId, setIntervalId] = useState(0); // Stores interval ID

  const handleAdCodeClick = () => {
    setverifyLoading(true);
    verifyConversion();
  };

const verifyConversion = async () => {
  try {
    const response = await verifyConversionRequest({
    tagId: editingTagId,
    bannerid: aBannerId,
    viewerid: aViewerId
    });

    if (!response || response?.status === 400) {
      setIntervalId(0);
      setverifyLoading(false);
      setverifySuccess(false);
    }else if (response?.error) {
      setIntervalId(0);
      setverifyLoading(false);
      setverifySuccess(false);
    } else if (response?.response?.details?.[0]?.status !== undefined &&  response?.response?.details?.[0]?.status
    === '1') {          
      setIntervalId(0);
      setverifyLoading(false);
      setverifySuccess(true);
    }else{
      setIntervalId(9);
    }
  } catch (error) {
    console.error("Verification failed:", error);
    
  }
};
  
useEffect(() => {
  console.log('intervalId'+intervalId);
  if (intervalId !== 0) {
    const id = setInterval(() => {
      verifyConversion();
    }, 10000); // Adjust interval time as needed (2000ms = 2 seconds)

    return () => clearInterval(id); // Cleanup function to prevent memory leaks
  }
}, [intervalId]);
  
const handleSubmit = async (event) => {
    event.preventDefault();
    if (!error && url) {
      setIsLoading(true); // Start loading
      setAdCode('');
      try {
        const response = await validateConversionRequest({
        tagId: editingTagId,
        url: url
        });

        if (response?.response?.adcode !== undefined && response?.response?.adcode !== null) {
        const parsedData = JSON.parse(response.response.adcode);
        if (response.response.bannerid) {
          setAdCode(parsedData.adcode);
        }else{
          setAdCode(response.response.code);
        }
        setViewerId(parsedData.viewerid);
        setBannerId(response.response.bannerid);
        }else{
        setAdCode('<a href="'+response.response.code+'" target="_blank">Click here to validate conversion.</a>');
        setViewerId(response.response.viewerid);
        }
        setIsLoading(false); // Stop loading after response
      } catch (error) {
        console.error("Validation failed:", error);
        setIsLoading(false);
      }
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  
  return (
    <div className="deposit-component">
      <div className="modal-header">
        <h2 className="modal-title">Validate Conversion</h2>
        <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
      </div>
     
      
      <div className="modal-body" style={{ maxWidth: "600px" }}>
          <form onSubmit={handleSubmit}>
          <p className="balance-info" style={{ fontSize: "14px", textWrap: "wrap" }}>Input the URL where you have installed the conversion script and verify if your installation works by performing a test conversion.</p>
            <TextField
              fullWidth
              label="URL"
              name="url"
              value={url}
              onChange={handleChange}
              error={!!error}
              helperText={error || ""}
              margin="normal"
            />
            <br/>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ mt: 2 }}
              disabled={!!error || !url || isLoading} // Disable button if error exists or empty
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : "Validate"}
            </Button>
          </form>
        

          {adCode && ( <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="code">
              <div className="rowcode">
                <p className="text-style-1">
                  {aBannerId ? <>Click on the banner to verify the conversion.</> : <></>}
                </p>
                {verifysuccess && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                      Conversion Successfully Verified!.
                    </Alert>
                    </Stack>
                )}
              </div>
              <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {verifyloading ? <> <CircularProgress size={40} /> Click registered! ✅ Conversion in progress... Please hold tight! ⏳ </> : <>
                    {adCode ? (
                    aBannerId ? (
                    <Box
                    sx={{ mt: 2, p: 2, border: "1px solid #ccc", borderRadius: "5px", background: "#f9f9f9" }}
                    dangerouslySetInnerHTML={{ __html: adCode }}
                    onClick={handleAdCodeClick} 
                    />
                    ) : (
                    <Box
                    sx={{ mt: 2, p: 2, border: "1px solid #ccc", borderRadius: "5px", background: "#f9f9f9" }}
                    dangerouslySetInnerHTML={{ __html: adCode }}
                    onClick={handleAdCodeClick}
                    />
                    )
                    ) : <></>}
                  </>
                }
              </div>
            </div>
          </Grid>
        </Grid>
        )}
      </div>
      <div className="modal-footer"></div>
    </div>
  );
}