import React, { useState, useMemo, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import useFormatter from "../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import localData from "../../localData";
import ReactDOMServer from "react-dom/server";
import { Pagination } from "../../components";
import { countryList } from "./fallbackCountryList";
import { useGlobalContext } from "../../context";

const CustomProgressBar = ({ value, maxValue, color, displayValue, isHighlighted }) => {
  const percentage = maxValue > 0 ? Math.min((value / maxValue) * 100, 100) : 0;

  return (
    <Box sx={{ 
      display: "flex", 
      alignItems: "center", 
      width: "70%",
      backgroundColor: isHighlighted ? "#1973E8" : "transparent", // Light blue background when highlighted
      padding: isHighlighted ? "8px" : "0",
      borderRadius: isHighlighted ? "4px" : "0"
    }}>
      <Typography
        variant="body2"
        sx={{ 
          minWidth: "80px", 
          textAlign: "right", 
          marginRight: "8px",
          fontWeight: isHighlighted ? "bold" : "normal", // Make text bold if highlighted
          color: isHighlighted ? "#ffff" : "inherit" // Blue text if highlighted
        }}
      >
        {displayValue}
      </Typography>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "8px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: `${percentage}%`,
            minWidth: percentage > 0 ? "4px" : "0px",
            backgroundColor: isHighlighted ? "#ffff" : color, // Use blue when highlighted
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

const EnhancedPerformanceTable = ({ data, tabValue }) => {
  const { performanceOption } = useGlobalContext();
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const { formatNumber, formatCurrency } = useFormatter();
  const { arrowDown, arrowUp, sortIcon } = localData.svgs;
  const countries = useMemo(() => countryList, []);  

  const getFieldFromOption = (option) => {
    const mapping = {
      'Impressions': 'impressions',
      'Clicks': 'clicks',
      'CTR': 'ctr',
      'Ad Spend': 'adSpend',
      'CPC': 'cpc',
      'Conversions': 'conversions',
    };
    return mapping[option] || '';
  };

  useEffect(() => {
    const field = getFieldFromOption(performanceOption);
    if (field) {
      setSortField(field);
      setSortOrder('desc')
    }
  }, [performanceOption]);

  const calculateCTR = (clicks, impressions) => {
    return impressions > 0 ? (clicks / impressions) * 100 : 0;
  };

  const calculateCPC = (spent, clicks) => {
    return clicks > 0 ? spent / clicks : 0;
  };

  const getSortIcon = (field) => {
    if (sortField !== field) {
      return <span className="sort-btn">{sortIcon}</span>;
    }
    return (
      <span className="sort-btn active">
        {sortOrder === "desc" ? arrowDown : arrowUp}
      </span>
    );
  };

  const handleSortClick = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortOrder("desc"); 
    }
  };

  const handlePagination = (newPage) => {
    setPage(newPage);
  };

  const getColumnTitle = () => {
    if (tabValue === 0) return "Audience";
    if (tabValue === 1) return "Ad";
    if (tabValue === 2) return "Geo";
    if (tabValue === 3) return "Publisher";
    return "Name";
  };

  const maxValues = {
    impressions: Math.max(...data.map((row) => row.impressions || 0)),
    clicks: Math.max(...data.map((row) => row.clicks || 0)),
    ctr: Math.max(
      ...data.map(
        (row) => row.ctr || calculateCTR(row.clicks, row.impressions) || 0
      )
    ),
    adSpend: Math.max(...data.map((row) => row.adSpend || row.spent || 0)),
    cpc: Math.max(
      ...data.map((row) => {
        const spent = row.spent || row.adSpend || 0;
        const clicks = row.clicks || 0;
        return row.cpc || (clicks > 0 ? spent / clicks : 0) || 0;
      })
    ),
    conversions: Math.max(...data.map((row) => row.conversions || 0)),
  };

  const colors = {
    impressions: "#2E6F40", // Original forest green
    clicks: "#3E8E53", // Lighter forest green
    ctr: "#568B45", // Olive green
    adSpend: "#1E5C30", // Darker forest green
    cpc: "#4A9E63", // Bright forest green
    conversions: "#2C8059", // Forest green with teal tint
  };

  const sortedData = useMemo(() => {
    if (!sortField) return data;

    return [...data].sort((a, b) => {
      let aValue, bValue;

      switch (sortField) {
        case "impressions":
          aValue = a.impressions || 0;
          bValue = b.impressions || 0;
          break;
        case "clicks":
          aValue = a.clicks || 0;
          bValue = b.clicks || 0;
          break;
        case "ctr":
          aValue = a.ctr || calculateCTR(a.clicks, a.impressions) || 0;
          bValue = b.ctr || calculateCTR(b.clicks, b.impressions) || 0;
          break;
        case "adSpend":
        case "spent":
          aValue = a.adSpend || a.spent || 0;
          bValue = b.adSpend || b.spent || 0;
          break;
        case "cpc":
          aValue = a.cpc || calculateCPC(a.spent || a.adSpend, a.clicks) || 0;
          bValue = b.cpc || calculateCPC(b.spent || b.adSpend, b.clicks) || 0;
          break;
        case "conversions":
          aValue = a.conversions || 0;
          bValue = b.conversions || 0;
          break;
        default:
          aValue = a[sortField] || 0;
          bValue = b[sortField] || 0;
      }

      if (a.name === "Total") return 1;
      if (b.name === "Total") return -1;

      if (sortOrder === "asc") {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
  }, [data, sortField, sortOrder]);

  const paginatedData = useMemo(() => {
    const totalRows = sortedData.filter((row) => row.name !== "Total");
    const totalPages = Math.ceil(totalRows.length / rowsPerPage);

    const totalRow = sortedData.find((row) => row.name === "Total");

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalRows.length);

    let currentPageData = totalRows.slice(startIndex, endIndex);

    if (totalRow) {
      if (page === totalPages) {
        currentPageData = [...currentPageData, totalRow];
      }
    }

    return {
      data: currentPageData,
      totalPages: totalPages || 1,
    };
  }, [sortedData, page, rowsPerPage]);

  const currentPerformanceField = getFieldFromOption(performanceOption);

  return (
    <div className="zones-wrapper">
      <div className="scroll shadow bg-white" style={{ padding: 0 }}>
        <table id="ads-table" className="table reports-table">
          <thead style={{ padding: "15px 15px", background: "#F8F9FA" }}>
            <tr className="table-row" style={{ padding: "15px 15px", border: "none !important" }}>
              <th
                className="table-th-hash text-center"
                style={{ padding: "15px 15px" }}
              >
                {getColumnTitle()}
              </th>
              <th
                className={`table-th-age text-center clickable ${currentPerformanceField === 'impressions' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("impressions")}
                style={currentPerformanceField === 'impressions' ? { backgroundColor: '#1973E8', border: "none", color: "#ffff" } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-impressions"
                  data-tooltip-content="How many times your ads have been seen."
                  className={`with-sort ${currentPerformanceField === 'impressions' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'impressions' ? { color: '#ffff', fontWeight: 'bold', border: "none" } : {}}
                >
                  Impressions {getSortIcon("impressions")}
                </span>
              </th>
              <th
                className={`table-th-from text-center clickable ${currentPerformanceField === 'clicks' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("clicks")}
                style={currentPerformanceField === 'clicks' ? { backgroundColor: '#1973E8',border: "none", } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-clicks"
                  data-tooltip-content="How many times your ads have been clicked."
                  className={`with-sort ${currentPerformanceField === 'clicks' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'clicks' ? { color: '#ffff', fontWeight: 'bold',border: "none", } : {}}
                >
                  Clicks {getSortIcon("clicks")}
                </span>
              </th>
              <th
                className={`table-th-quantity text-center clickable ${currentPerformanceField === 'ctr' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("ctr")}
                style={currentPerformanceField === 'ctr' ? { backgroundColor: '#1973E8', border: "none", } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-ctr"
                  data-tooltip-content="How often your ads are clicked when viewed by users."
                  className={`with-sort ${currentPerformanceField === 'ctr' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'ctr' ? { color: '#ffff', fontWeight: 'bold', border: "none", } : {}}
                >
                  CTR {getSortIcon("ctr")}
                </span>
              </th>
              <th
                className={`table-th-quantity text-center clickable ${currentPerformanceField === 'adSpend' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("adSpend")}
                style={currentPerformanceField === 'adSpend' ? { backgroundColor: '#1973E8', border: "none", } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-ad-spend"
                  data-tooltip-content="How much budget has been spent."
                  className={`with-sort ${currentPerformanceField === 'adSpend' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'adSpend' ? { color: '#ffff', fontWeight: 'bold', border: "none", } : {}}
                >
                  Ad Spend {getSortIcon("adSpend")}
                </span>
              </th>
              <th
                className={`table-th-quantity text-center clickable ${currentPerformanceField === 'cpc' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("cpc")}
                style={currentPerformanceField === 'cpc' ? { backgroundColor: '#1973E8', border: "none", } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-cpc"
                  data-tooltip-content="How much you pay for each click."
                  className={`with-sort ${currentPerformanceField === 'cpc' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'cpc' ? { color: '#ffff', fontWeight: 'bold', border: "none", } : {}}
                >
                  CPC {getSortIcon("cpc")}
                </span>
              </th>
              <th
                className={`table-th-conversions text-center clickable ${currentPerformanceField === 'conversions' ? 'highlighted-column' : ''}`}
                onClick={() => handleSortClick("conversions")}
                style={currentPerformanceField === 'conversions' ? { backgroundColor: '#1973E8', border: "none", } : {}}
              >
                <span
                  data-tooltip-id="tooltip-table-conversions"
                  data-tooltip-content="Total amount of the conversions."
                  className={`with-sort ${currentPerformanceField === 'conversions' && "active-svg-icon"}`}
                  style={currentPerformanceField === 'conversions' ? { color: '#ffff', fontWeight: 'bold', border: "none", } : {}}
                >
                  Conversions {getSortIcon("conversions")}
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {paginatedData.data.map((row, index) => (
              <tr
                key={index}
                className="table-row"
                style={{ 
                  background: row.name === "Total" ? "lightgray" : index % 2 === 0 ? "white" : "#F8F9FA" 
                }}
              >
                <td
                  className="ads-td-image text-star"
                  style={{
                    fontWeight: row.name === "Total" && "bold",
                    minWidth: "150px",
                  }}
                >
                  {row.image ? (
                    <div
                      className="content"
                      data-tooltip-id="tooltip-campaign-image"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <div className="image-wrapper">
                          <img src={row.image} alt={row.name} />
                        </div>
                      )}
                      data-tooltip-float={true}
                    >
                      <img src={row.image} alt={row.name} />
                    </div>
                  ) : tabValue === 2 && row.name !== "Total" ? (
                    <div
                      data-tooltip-id="tooltip-country-name"
                      data-tooltip-content={countries[row.name] || row.name}
                    >
                      {countries[row.name]}
                      {`(${row.name})`}
                    </div>
                  ) : (
                    row.name
                  )}
                </td>
                <td 
                  className={`table-td-age text-center ${currentPerformanceField === 'impressions' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'impressions' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none"  } : {}}
                >
                  <CustomProgressBar
                    value={row.impressions || 0}
                    maxValue={maxValues.impressions}
                    color={colors.impressions}
                    displayValue={formatNumber(row.impressions)}
                    isHighlighted={currentPerformanceField === 'impressions'}
                  />
                </td>
                <td 
                  className={`table-td-from text-center ${currentPerformanceField === 'clicks' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'clicks' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none"  } : {}}
                >
                  <CustomProgressBar
                    value={row.clicks || 0}
                    maxValue={maxValues.clicks}
                    color={colors.clicks}
                    displayValue={formatNumber(row.clicks)}
                    isHighlighted={currentPerformanceField === 'clicks'}
                  />
                </td>
                <td 
                  className={`table-td-quantity text-center ${currentPerformanceField === 'ctr' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'ctr' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none"  } : {}}
                >
                  <CustomProgressBar
                    value={row.ctr || calculateCTR(row.clicks, row.impressions)}
                    maxValue={maxValues.ctr}
                    color={colors.ctr}
                    displayValue={`${(
                      row.ctr || calculateCTR(row.clicks, row.impressions)
                    ).toFixed(2)}%`}
                    isHighlighted={currentPerformanceField === 'ctr'}
                  />
                </td>
                <td 
                  className={`table-td-quantity text-center ${currentPerformanceField === 'adSpend' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'adSpend' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none"  } : {}}
                >
                  <CustomProgressBar
                    value={row.adSpend || row.spent || 0}
                    maxValue={maxValues.adSpend}
                    color={colors.adSpend}
                    displayValue={formatCurrency(row.adSpend || row.spent, 2)}
                    isHighlighted={currentPerformanceField === 'adSpend'}
                  />
                </td>
                <td 
                  className={`table-td-cpc text-center ${currentPerformanceField === 'cpc' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'cpc' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none"  } : {}}
                >
                  <CustomProgressBar
                    value={
                      row.cpc ||
                      calculateCPC(row.spent || row.adSpend, row.clicks) ||
                      0
                    }
                    maxValue={maxValues.cpc}
                    color={colors.cpc}
                    displayValue={formatCurrency(
                      row.cpc ||
                        calculateCPC(row.spent || row.adSpend, row.clicks),
                      2
                    )}
                    isHighlighted={currentPerformanceField === 'cpc'}
                  />
                </td>
                <td 
                  className={`table-td-conversion text-center ${currentPerformanceField === 'conversions' ? 'highlighted-cell' : ''}`}
                  style={currentPerformanceField === 'conversions' ? { backgroundColor: '#1973E8', color: "#ffff", border: "none" } : {}}
                >
                  <CustomProgressBar
                    value={row.conversions || 0}
                    maxValue={maxValues.conversions}
                    color={colors.conversions}
                    displayValue={formatNumber(row.conversions)}
                    isHighlighted={currentPerformanceField === 'conversions'}
                  />
                </td>
              </tr>
            ))}
            {!paginatedData.data.length && (
              <tr>
                <td colSpan="7">
                  <div className="no-data">No Data To Show!</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data.length > rowsPerPage && (
        <div className="pagination-wrapper">
          <Pagination
            callback={handlePagination}
            currentPage={page}
            totalPages={paginatedData.totalPages}
            isLoading={false}
          />
        </div>
      )}
      <Tooltip
        id="tooltip-campaign-image"
        className="custom-tooltip image-tooltip"
      />
      <Tooltip id="tooltip-country-name" className="custom-tooltip" />
      <Tooltip id="tooltip-table-impressions" className="custom-tooltip" />
      <Tooltip id="tooltip-table-clicks" className="custom-tooltip" />
      <Tooltip id="tooltip-table-ctr" className="custom-tooltip" />
      <Tooltip id="tooltip-table-ad-spend" className="custom-tooltip" />
      <Tooltip id="tooltip-table-cpc" className="custom-tooltip" />
      <Tooltip id="tooltip-table-conversions" className="custom-tooltip" />
    </div>
  );
};

export default EnhancedPerformanceTable;