import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Box, IconButton, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalContext } from '../../../context';

const TrackingSteps = () => {
    const {conversionActiveStep, setConversionActiveStep} = useGlobalContext()
    const steps = ["Setup Method", "Setup Conversion", "Installation"]
    const navigate = useNavigate()
    const handleStep = (step) => () => {
      setConversionActiveStep(step);
    };

  return (
    <Box
    sx={{
      position: "sticky",
      zIndex: "10",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // padding: "15px 24px",
      borderBottom: "1px solid #e0e0e0",
      backgroundColor: "#f9f9f9",
    }}
  >
    <Stepper
      activeStep={conversionActiveStep}
      style={{ maxWidth: "800px", margin: "0 auto" }}
    >
      {steps.map((label, index) => (
        <Step
          key={label}
          sx={{
            "& .MuiStepLabel-horizontal": {
              borderBottom:
              conversionActiveStep === index ? "2px solid #1f8352" : "none",
              padding: "15px 15px",
            },
          }}
        >
          <StepLabel
            // onClick={handleStep(index)}
            StepIconProps={{
              sx: {
                color:
                conversionActiveStep === index
                    ? "#1f8352" // Green for active valid step
                    : "grey", // Default color for inactive steps
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
    <IconButton onClick={() => navigate("/tracking")} aria-label="Go back">
      <CloseIcon />
    </IconButton>
  </Box>
  );
};

export default TrackingSteps;