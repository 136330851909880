import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import localData from "../../localData";
import { Button, Field } from "../";

export default function Search({
  className = "",
  color,
  icon,
  children,
  size = "md",
  isOpen = false,
  setIsOpen = () => {},
  disabled = false,
  callback = () => {},
  placeholder = "Filter Address / Txn Hash",
}) {
  const clickWrapper = useRef(null);
  const searchRef = useRef(null);
  const fieldRef = useRef(null);

  const { searchIcon } = localData.svgs;

  const [query, setQuery] = useState("");

  useEffect(() => {
    let handler = (e) =>
      !clickWrapper.current.contains(e.target) && setIsOpen(false);

    document.addEventListener("click", handler);
    return () => document.removeEventListener("click", handler);
  });

  const openMenu = () => {
    setIsOpen(true);
    fieldRef.current.focus();
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleOnChange = (e) => {
    setQuery(e.target.value);
  };

  const handleOnSubmit = () => {
    callback(query);
    setQuery("");
    closeMenu();
  };

  return (
    <div
      className={`search search-${size}  ${
        isOpen ? "active" : ""
      }  ${className} ${disabled ? "disabled" : ""}`}
      ref={searchRef}
    >
      <div className="search-toggle-wrapper" ref={clickWrapper}>
        <Button
          className={`search-toggle`}
          // data-toggle="search"
          onClick={() => (isOpen ? closeMenu() : openMenu())}
          variant="circle"
          color={color}
          size="sm"
        >
          {searchIcon}
        </Button>
        <div className="search-menu">
          <Field
            type="text"
            name="search-field"
            className="search-field"
            value={query}
            color="secondary"
            size="sm"
            placeholder={placeholder}
            callback={handleOnChange}
            _ref={fieldRef}
          />
          <Button
            variant="contained"
            color={color}
            name="find"
            onClick={handleOnSubmit}
            className="search-submit"
          />
        </div>
      </div>
    </div>
  );
}
