import { useState } from "react";
import { Box, Grid, Card, CardActionArea, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"; // Example icons
import CodeIcon from "@mui/icons-material/Code";
import CloudIcon from "@mui/icons-material/Cloud";
import { useGlobalContext } from "../../../../context";

const options = [
  { id: 1, label: "Install Manually", icon: <CodeIcon fontSize="large" />, value: "pixel", description: "Choose this option to receive the pixel code. Copy the provided HTML script and paste it into your website’s code where you want to track conversions." },
  { id: 2, label: "Install through Google Tag Manager", icon: <GoogleIcon fontSize="large" />, value: "gtm", description: "Select this option to connect your GTM account. Follow the prompts to authorize access, and we’ll help you set up the pixel through GTM." },
  { id: 3, label: "Install Server-To-Server", icon: <CloudIcon fontSize="large" />, value: "server", description: "Pick this option for server-side tracking. You’ll receive instructions to configure the integration between your server and Blockchain-Ads for accurate tracking." }
];

export default function ConversionType({handleNext}) {
  const { createConversionData, setCreateConversionData } = useGlobalContext();

  const handleOptionSelect = (value) => {    
    setCreateConversionData({
      ...createConversionData,
      conversionMethod: value
    });
    
    handleNext(value);
  };

  return (
    <Box 
      sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100%",
        paddingTop: "10%"
      }}
    >
      <Box sx={{ maxWidth: 900, width: "100%", px: 2 }}>
        <Grid container spacing={3} justifyContent="center">
          {options.map((option) => (
            <Grid item xs={12} sm={4} key={option.id}>
              <Card
                sx={{
                  border: createConversionData.conversionMethod === option.value ? "2px solid #1976d2" : "1px solid #ddd",
                  borderRadius: 2,
                  boxShadow: createConversionData.conversionMethod === option.value ? "0px 4px 10px rgba(25, 118, 210, 0.2)" : "none",
                  textAlign: "center",
                  transition: "0.3s",
                }}
              >
                <CardActionArea 
                  onClick={() => handleOptionSelect(option.value)} 
                  sx={{ p: 2, minHeight: "150px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {option.icon}
                    <Typography variant="body2" fontWeight="bold" mt={1}>
                      {option.label}
                    </Typography>
                    {option.description && (
                      <Typography variant="caption" color="text.secondary" mt={1}>
                        {option.description}
                      </Typography>
                    )}
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}