import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal as CustomModal,
  Tabs as CustomTabs,
} from "../../components";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import useFormatter from "../../hooks/useFormatter";
import useFetch from "../../hooks/useFetch";
import { Tooltip } from "react-tooltip";
import {CheckCircleOutline } from "@mui/icons-material";
import ValidateConversion from "./ValidateConversion/ValidateConversion";
import TrackingItemAdditionInformation from "./TrackingItemAdditionInformation";

export default function ConversionTableRow ({ item, index }) {
    const {
        trashV2,
      } = localData.svgs;
    const {
      EditConversionPixelRequest,
      requestedData: {
        userProfileData: { currentOrganization },
        campaignData: { currentOrganizationCampaigns },
      },
      isLoading: { campaignDataIsLoading },
      trackingState,
      DeleteConversionPixelRequest,
    } = useGlobalContext();
  
    const {
      setIsEditingMode,
      setEditingTagId,
      setState,
    } = trackingState;
  
    const { formatDateToYYYYMMDD } = useFormatter();
    const { DeleteConversionPixel } = useFetch();
  
    const [isStatLoading, setIsStatLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(item.conversionCount > 0);
  
    const { info, penFill, pen, edit, code, arrowUp, arrowDown } = localData.svgs;
  
    const isGray = index % 2 === 0;
  
    const navigate = useNavigate();
  
    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
  
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
  
      return date.toLocaleDateString("en-US", options);
    };
    const [isEditing, setIsEditing] = useState(false);
    const [editedTagName, setEditedTagName] = useState(item.name);
    const [currentTagName, setCurrentTagName] = useState(item.name);
  
    const handleEditClick = () => {
      setIsEditing(true);
    };
  
    const handleConfirmClick = () => {
      EditConversionPixelRequest({
        trackerid: item.trackerid,
        name: editedTagName,
        type: item.type,
        tracker_group: item.tracker_group,
      });
      setCurrentTagName(editedTagName);
      setIsEditing(false);
    };
  
    const handleCancelClick = () => {
      setEditedTagName(currentTagName);
      setIsEditing(false);
    };
  
    const handleInputChange = (e) => {
      setEditedTagName(e.target.value);
    };
  
    const [isPopupOpen, setIsPopupOpen] = useState(false);
  
    const handleDeleteClick = () => {
      setIsPopupOpen(true);
    };
  
    const handleConfirm = () => {
      console.log(item);
      setIsPopupOpen(false);
      DeleteConversionPixelRequest({
        tagId: item.trackerid,
        tagName: item.name,
      });
    };
  
    const handleCancel = () => {
      setIsPopupOpen(false);
    };
  
    const [activeInfoId, setActiveInfoId] = useState(null);
    const [showValidateConversionModal, setShowValidateConversionModal] =
      useState("none");
  
    return (
      <>
        <tr>
          <td className="table-td-hush">
            <Button
              variant="circle"
              size="sm"
              color="secondary"
              // icon={activeRow === index ? eyeSlash : eye}
              icon={activeInfoId === item.id ? arrowUp : arrowDown}
              onClick={(e) => {
                e.stopPropagation();
                // setActiveRow(activeRow === index ? null : index);
                setActiveInfoId(activeInfoId === item.id ? null : item.id);
              }}
            />
          </td>
          <td className="text-start">
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={editedTagName}
                  onChange={handleInputChange}
                  style={{
                    marginRight: "10px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                />
                <Button
                  variant="contained"
                  size="sm"
                  color="pale-dark"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </Button>
                <Button
                  color="danger"
                  variant="contained"
                  size="sm"
                  onClick={handleCancelClick}
                  style={{ marginLeft: "5px" }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div>
                {currentTagName || "unknown"}{" "}
                <span
                  onClick={handleEditClick}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    marginLeft: "2px",
                    textDecoration: "underline",
                  }}
                  className="edit-name-span"
                >
                  {edit} {/* This represents penV2 */}
                </span>
              </div>
            )}
          </td>
          <td>
            <div>
            {item.status === "verified" || item.totalconversion > 0 ? (
              <span className="verified">Verified</span>
            ) : (
              <span
                className="unverified"
                data-tooltip-id="tooltip-unverified"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <p>
                    An Insight Tag isn't installed on your website.
                    <br /> If you've already tried to install it, make sure it was
                    done correctly.
                  </p>
                )}
              >
                Unverified
              </span>
            )}
             <CustomModal
                Child={ValidateConversion}
                className="deposit-modal modal-dialog-centered"
                buttonClassName={`rounded-pill deposit-btn ${
                  window.innerWidth < 576 ? "btn-sm" : ""
                }`}
                display={showValidateConversionModal}
                setDisplay={setShowValidateConversionModal}
                Toggler={() => (
                  <Button
                    title="Validate"
                    name="Validate"
                    tooltipContent={<div>Validate Conversion</div>}
                    variant="outlined"
                    size="sm"
                    color="dark"
                    // id="validate-conversion"
                    icon={<CheckCircleOutline />}
                    // endIcon={<CheckCircleOutline />}
                    onClick={() => {
                      setShowValidateConversionModal("block");
                      setIsEditingMode(true);
                      setEditingTagId(item.trackerid);
                      setState({
                        tagName: item.name,
                        url: "",
                        contains: "",
                        tag: item.jspixel,
                        type: item.type,
                      });
                    }}
                    style={{marginLeft: "5px", padding: "2px 2px", fontSize: "10px"}}
                  />
                )}
              />
              </div>
            <Tooltip id="tooltip-unverified" className="custom-tooltip" />
          </td>
          <td className="text-center">{item.totalconversion || 0}</td>
          <td className="text-center">
            {item.created
              ? (typeof item.created === "object"
                  ? new Date(item.created._seconds * 1000)
                  : new Date(item.created)
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              : null}
          </td>
          <td className="text-center">
            {item.last_trigger_date_time == null
              ? null
              : (typeof item.last_trigger_date_time === "object"
                  ? new Date(item.last_trigger_date_time._seconds * 1000)
                  : new Date(item.last_trigger_date_time)
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
          </td>
          <td style={{ width: 0 }} className="text-center table-td-actions">
            <div className="btn-group">
              <div
                data-tooltip-id="tooltip-createpixel"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <p>Get Pixel Code</p>
                )}
              >
                <Button
                  variant="circle"
                  icon={code}
                  color="dark"
                  size="sm"
                  // onClick={() => {
                  //   // setDisplayInstallPixel("block");
                  //   setDefaultTab(1);
                  //   setIsEditingMode(true);
                  //   setEditingTagId(item.tagId);
                  //   setState({
                  //     tagName: item.name,
                  //     url: "",
                  //     contains: "",
                  //     tag: item.jspixel,
                  //     type: item.type,
                  //   });
                  //   navigate("/tracking/create-pixel");
                  // }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setActiveRow(activeRow === index ? null : index);
                    setActiveInfoId(activeInfoId === item.id ? null : item.id);
                  }}
                />
                <Tooltip id="tooltip-createpixel" className="custom-tooltip" />
              </div>
  
              <CustomModal
                Child={ValidateConversion}
                className="deposit-modal modal-dialog-centered"
                buttonClassName={`rounded-pill deposit-btn ${
                  window.innerWidth < 576 ? "btn-sm" : ""
                }`}
                display={showValidateConversionModal}
                setDisplay={setShowValidateConversionModal}
                Toggler={() => (
                  <Button
                    tooltipContent={<div>Validate Conversion</div>}
                    variant="circle"
                    size="sm"
                    color="dark"
                    id="validate-conversion"
                    icon={<CheckCircleOutline />}
                    onClick={() => {
                      setShowValidateConversionModal("block");
                      setIsEditingMode(true);
                      setEditingTagId(item.trackerid);
                      setState({
                        tagName: item.name,
                        url: "",
                        contains: "",
                        tag: item.jspixel,
                        type: item.type,
                      });
                    }}
                  />
                )}
              />
  
              <div
                data-tooltip-id="tooltip-deleteicon"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <p>Delete</p>
                )}
              >
                <Button
                  variant="circle"
                  icon={trashV2}
                  color="danger"
                  size="sm"
                  onClick={handleDeleteClick}
                  disabled={isStatLoading}
                />
                <ConfirmationPopup
                  message="Are you sure you want to delete this tag?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  isOpen={isPopupOpen}
                  item={item.tagName}
                />
                <Tooltip id="tooltip-deleteicon" className="custom-tooltip" />
              </div>
            </div>
          </td>
        </tr>
        {activeInfoId === item.id && (
          <TrackingItemAdditionInformation data={item} />
        )}
      </>
    );
  };



const ConfirmationPopup = ({ message, onConfirm, onCancel, isOpen, item }) => {
    if (!isOpen) return null;
  
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div className="confirmation-modal">
          <p>{message}</p>
          <h4 className={{ fontSize: "1rem" }}>{item}</h4>
          <div style={{ marginTop: "20px" }}>
            <Button
              onClick={onConfirm}
              color="pale-dark"
              variant="contained"
              style={{ marginRight: "20px" }}
            >
              Confirm
            </Button>
            <Button onClick={onCancel} color="danger" variant="contained">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  