import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  Chip,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import localData from "../../localData";
import { Calendar } from "../../components";
import useFormatter from "../../hooks/useFormatter";
import { RequestInvoice } from "./RequestInvoice";
import { EditCompanyInfoDialog } from "./BillingPage";

// PDF Templates remain the same as in your original code
const PDFTemplate = ({ selectedPayments, companyInfo }) => {
  const styles = StyleSheet.create({
    page: { padding: 40, fontSize: 10, color: "#333" },
    header: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: { maxWidth: "175px", height: "100%" },
    companyInfo: { textAlign: "right" },
    section: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: { fontSize: 18, color: "#4CAF50", marginBottom: 10 },
    table: { display: "table", width: "auto", marginBottom: 20 },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid #e0e0e0",
      paddingVertical: 5,
    },
    tableHeader: { fontSize: 12, fontWeight: "bold", color: "#4CAF50" },
    tableCell: { flex: 1, textAlign: "left", paddingHorizontal: 5 },
    total: {
      marginTop: 10,
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "right",
    },
    footer: { marginTop: 40, textAlign: "center", fontSize: 10, color: "#aaa" },
  });

  const totalAmount = selectedPayments.reduce(
    (sum, payment) => sum + (payment.amount || 0),
    0
  );
  const { logoDark } = localData.images;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image src={logoDark} style={styles.logo} />
          <View style={styles.companyInfo}>
            <Text>Blockchain-Ads Labs LLC</Text>
            <Text>Suite 305, Griffith Corporate Centre</Text>
            <Text>Beachmonth, Kingston, St. Vincent and the Grenadines</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.title}>Receipt</Text>
            <Text>Payment Summary</Text>
          </View>
          <View>
            <Text>
              Company Name: {!companyInfo.name ? "N/A" : companyInfo.name}
            </Text>
            <Text>
              Comapny Address:{" "}
              {!companyInfo.address ? "N/A" : companyInfo.address}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Date</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Amount</Text>
          </View>
          {selectedPayments.map((payment, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{payment.date}</Text>
              <Text style={styles.tableCell}>${payment.amount || "0.00"}</Text>
            </View>
          ))}
        </View>

        <Text style={styles.total}>Total: ${totalAmount.toFixed(2)}</Text>
        <Text style={styles.footer}>Thank you for your payment!</Text>
      </Page>
    </Document>
  );
};

const SinglePDFTemplate = ({ payment, companyInfo }) => {
  const styles = StyleSheet.create({
    page: { padding: 40, fontSize: 10, color: "#333" },
    header: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: { maxWidth: "175px", height: "100%" },
    companyInfo: { textAlign: "right" },
    section: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    title: { fontSize: 18, color: "#4CAF50", marginBottom: 10 },
    billTo: { marginBottom: 20 },
    table: { display: "table", width: "auto", marginBottom: 20 },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid #e0e0e0",
      paddingVertical: 5,
    },
    tableHeader: { fontSize: 12, fontWeight: "bold", color: "#4CAF50" },
    tableCell: { flex: 1, textAlign: "left", paddingHorizontal: 5 },
    total: {
      marginTop: 10,
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "right",
    },
    footer: { marginTop: 40, textAlign: "center", fontSize: 10, color: "#aaa" },
  });

  const { logoDark } = localData.images;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image src={logoDark} style={styles.logo} />
          <View style={styles.companyInfo}>
            <Text>Blockchain-Ads Labs LLC</Text>
            <Text>Suite 305, Griffith Corporate Centre</Text>
            <Text>Beachmonth, Kingston, St. Vincent and the Grenadines</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View>
            <Text style={styles.title}>Receipt</Text>
            <Text>Date: {payment.date}</Text>
          </View>
          <View>
            <Text>Company Name: {companyInfo.name}</Text>
            <Text>Comapny Address: {companyInfo.address}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Date</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Amount</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{payment.date}</Text>
            <Text style={styles.tableCell}>${payment.amount || "0.00"}</Text>
          </View>
        </View>
        <Text style={styles.total}>Total: ${payment.amount || "0.00"}</Text>
        <Text style={styles.footer}>Thank you for your payment!</Text>
      </Page>
    </Document>
  );
};

const BillingHistoryPage = () => {
  const {
    requestedData,
    isLoading,
    paymentHistory,
    companyInfo,
    setCompanyInfo,
    tempCompanyInfo,
    setTempCompanyInfo,
    openCompanyInfoModal,
    setOpenCompanyInfoModal,
    handleSaveCompanyInfo,
    handleOpenCompanyModalModal,
    handleCloseCompanyInfoModal,
  } = useGlobalContext();

  const [loading, setLoading] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredPayments, setFilteredPayments] = useState(paymentHistory);

  const { formatDate, formatCurrency } = useFormatter();

  const handleSelect = (payment) => {
    setSelectedPayments((prev) =>
      prev.some((p) => p === payment)
        ? prev.filter((p) => p !== payment)
        : [...prev, payment]
    );
  };

  const isSelected = (payment) => selectedPayments.includes(payment);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (requestedData?.userProfileData?.payment_history?.length > 0) {
      setFilteredPayments(requestedData.userProfileData.payment_history);
    }
  }, [requestedData]);

  const handleDateFilter = (range) => {
    setLoading(true);
    const { startDate, endDate } = range;

    if (!startDate || !endDate) {
      setFilteredPayments(paymentHistory); // Reset to all payments
      setLoading(false);
      return;
    }

    const normalizedStartDate = new Date(startDate).setHours(0, 0, 0, 0);
    const normalizedEndDate = new Date(endDate).setHours(23, 59, 59, 999);

    const filtered = paymentHistory.filter((payment) => {
      if (payment.date != "N/A") {
        const paymentDate = new Date(payment.date);
        return (
          paymentDate >= normalizedStartDate && paymentDate <= normalizedEndDate
        );
      }
      return false;
    });

    setFilteredPayments(filtered);
    setLoading(false);
  };

  const sortedPayments = [...filteredPayments].sort((a, b) => {
    const getDateValue = (item) => {
      if (item.date && item.date !== "N/A") {
        const [day, month, year] = item.date.split("/").map(Number);
        return new Date(year, month - 1, day);
      }
      return new Date(0); // Push "N/A" to the bottom
    };

    return getDateValue(b) - getDateValue(a); // Sort newest first
  });

  const displayedPayments = sortedPayments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const today = new Date();
  const tenYearsAgo = new Date();
  tenYearsAgo.setFullYear(today.getFullYear() - 10);

  const defaultStartDate = tenYearsAgo;
  const defaultEndDate = today;

  const isBelow800px = useMediaQuery("(max-width:800px)");

  return (
    <div style={{ padding: isBelow800px ? "1rem" : "2rem" }}>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Grid className="d-flex">
          <Calendar
            className="reports-calendar"
            callback={(range) => handleDateFilter(range)}
            defaultRange={{
              startDate: defaultStartDate,
              endDate: defaultEndDate,
            }}
            allTime={true}
          />
          {/* <Grid item>
            <RequestInvoiceModal
              setOpenCompanyInfoModal={setOpenCompanyInfoModal}
              companyInfo={companyInfo}
            />
          </Grid> */}
        </Grid>
        <PDFDownloadLink
          document={
            <PDFTemplate
              selectedPayments={selectedPayments}
              companyInfo={companyInfo}
            />
          }
          fileName="selected-payments-summary.pdf"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ background: "#1f8352" }}
            disabled={selectedPayments.length === 0}
            size="small"
          >
            DOWNLOAD SELECTED
          </Button>
        </PDFDownloadLink>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ padding: "5px 8px" }}>
                <Checkbox
                  indeterminate={
                    selectedPayments.length > 0 &&
                    selectedPayments.length < filteredPayments.length
                  }
                  checked={selectedPayments.length === filteredPayments.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPayments(filteredPayments);
                    } else {
                      setSelectedPayments([]);
                    }
                  }}
                  size="small"
                />
              </TableCell>
              <TableCell sx={{ padding: "5px 8px" }}>Issue Date</TableCell>
              <TableCell sx={{ padding: "5px 8px" }}>Document Type</TableCell>
              <TableCell sx={{ padding: "5px 8px" }}>Status</TableCell>
              <TableCell sx={{ padding: "5px 8px" }}>Amount</TableCell>
              <TableCell sx={{ padding: "5px 8px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading.userProfileIsLoading ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  sx={{ padding: "5px 8px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : displayedPayments.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  sx={{ padding: "5px 8px" }}
                >
                  <Typography variant="body1">
                    No data available for the selected date range.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayedPayments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox" sx={{ padding: "5px 8px" }}>
                    <Checkbox
                      checked={isSelected(payment)}
                      onChange={() => handleSelect(payment)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "5px 8px" }}>
                    {payment.date}
                  </TableCell>
                  <TableCell sx={{ padding: "5px 8px" }}>Receipt</TableCell>
                  <TableCell sx={{ padding: "5px 8px" }}>
                    <Chip
                      size="small"
                      label="Paid"
                      sx={{
                        background: "green",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "5px 8px" }}>
                    {formatCurrency(payment.amount, 0) || "0.00"}
                  </TableCell>
                  <TableCell sx={{ padding: "5px 8px" }}>
                    <PDFDownloadLink
                      document={
                        <SinglePDFTemplate
                          payment={payment}
                          companyInfo={companyInfo}
                        />
                      }
                      fileName={`receipt-${index + 1}.pdf`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button variant="text" sx={{ color: "#1f8352" }}>
                        PDF
                      </Button>
                    </PDFDownloadLink>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredPayments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export const RequestInvoiceModal = ({
  paymentValue,
  setOpenCompanyInfoModal,
  companyInfo,
}) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(paymentValue);
  const { setIsCompanyInfoMissing } = useGlobalContext();

  const handleOpen = () => {
    if (!companyInfo.name.length || !companyInfo.address.length) {
      setOpenCompanyInfoModal(true);
      setIsCompanyInfoMissing(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAmount();
  };

  const handleRequest = () => {
    if (amount < 10000) return;
    handleClose(); // Close modal after request
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Button
        variant="outlined"
        sx={{
          borderColor: "#1f8352",
          fontSize: "10px",
          marginLeft: "15px",
          color: "#1f8352",
        }}
        onClick={handleOpen}
        size="small"
      >
        Request Invoice
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Request Invoice</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Amount"
            type="number"
            fullWidth
            size="small"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>

          <div onClick={handleRequest}>
            <RequestInvoice
              paymentValue={amount}
              condition={amount < 10000}
              companyInfo={companyInfo}
            />
          </div>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BillingHistoryPage;
