import ReferralTable from "../ReferralTable";
import { Button, Field, TableSkeleton } from "../../../components";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Modal } from "@mui/material";
import { isAddress } from "ethers";
import { auth } from "../../../config/firebase";
import { useGlobalContext } from "../../../context";

export default function Reports({ details, loading, fetchData }) {
  const { textPreloader, budget } = localData.images;

  const headers = [
    {
      header: "Organization Name",
      prop: "orgnizationName",
    },
    {
      header: "Email",
      prop: "email",
    },
    {
      header: "From Date",
      prop: "startDate",
      type: "date",
    },
    {
      header: "To Date",
      prop: "endDate",
      type: "date",
    },
    {
      header: "Commission",
      prop: "amount",
      type: "amount",
    },
  ];

  const { successAlert, errorAlert } = useGlobalContext();

  const { referralWithdrawal } = useFetch();
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [amountError, setAmountError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const [isValid, setIsValid] = useState(true);
  const [state, setState] = useState({});
  const validateAddress = (input) => {
    // Use ethers.js to validate the address
    if (isAddress(input)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const validateAmount = (input) => {
    console.log(typeof input);
    if (input < 10) {
      console.log("yeah");
      setAmountError("Amount must be greateer than 10");
      setIsAmountValid(false);
    } else if (input > details?.user?.referral_balance) {
      setAmountError("Amount cant be above available balance");
      setIsAmountValid(false);
    } else {
      setAmountError(null);
      setIsAmountValid(true);
    }
  };
  const handleOnModalChange = (e) => {
    const { name, value } = e.target || e;
    if (name === "address") {
      validateAddress(value);
      setWithdrawalAddress(value);
    }
    if (name === "priceAmount") {
      validateAmount(parseFloat(value));
      setWithdrawalAmount(value);
    }
  };

  const handleOpen = () => {
    setWithdrawalAmount(details?.user?.referral_balance || 0);
    setOpen(true);
  };
  const handleClose = () => {
    setWithdrawalAmount("");
    setOpen(false);
  };

  const handleWithdrawalSubmit = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) return;
      const raw = {
        uuid: auth.currentUser.uid,
        amount: withdrawalAmount,
        address: withdrawalAddress,
      };
      const result = await referralWithdrawal(() => {}, raw);
      if (result.res_sts) {
        successAlert(result.res_msg);
      }
      fetchData();
    } catch (error) {
      errorAlert(error.message || "Error sending an invite");
      console.error("Error fetching sending invite:", error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const onInput = (e) => {
    if (
      parseFloat(e.target.value) >
      parseFloat(details?.user?.referral_balance || 0)?.toFixed(0)
    ) {
      e.target.value = parseFloat(
        details?.user?.referral_balance || 0
      )?.toFixed(0);
    }
  };

  return (
    <>
      {loading ? (
        <TableSkeleton message="Loading..." icon={textPreloader} />
      ) : (
        <div className="reports">
          <div className="flex-row-between">
            <div className="flex-item">
              <h2 className="display-2">Commission Reports: </h2>
              <p className="text-style-1">
                Any commissions you earn will be denominated in US Dollars value
                and payout made in stable coin (USDT on BSC){" "}
              </p>
            </div>
            <div className="flex-item">
              <div
                style={{
                  maxWidth: "300px",
                  marginLeft: "auto",
                  background: "#fff",
                  padding: "20px",
                  borderRadius: "12px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                }}
              >
                <h2
                  style={{
                    color: "#777",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  Available Balance
                </h2>
                <p
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {details?.user?.referral_balance}$
                </p>
                <Button onClick={handleOpen}>Withdraw</Button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="reports">
            <h2 className="display-2"></h2>

            <ReferralTable
              allRows={details?.commissions}
              isLoading={false}
              headers={headers}
            />
          </div>
          <br />
          <div className="cover"></div>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minHeight: 200,
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    top: "0px",
                    left: "0px",
                    textAlign: "center",
                    zIndex: "999",
                  }}
                >
                  <CircularProgress
                    style={{
                      position: "relative",
                      top: "50%",
                      transform: "translate(-50%, 0%)",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <h2 style={{ margin: 0 }}>Withdraw</h2>
              <br />
              <Field
                type="text"
                label="Wallet Address"
                color="secondary"
                placeholder=""
                value={withdrawalAddress}
                required={true}
                name="address"
                size="sm"
                errorMessage={
                  !isValid ? "Address must be a valid BSC address (USDT)" : ""
                }
                callback={handleOnModalChange}
              />
              <Field
                type="number"
                label="Amount"
                color="secondary"
                placeholder=""
                value={withdrawalAmount}
                max={parseFloat(details?.user?.referral_balance || 0)?.toFixed(
                  0
                )}
                required={true}
                errorMessage={!isAmountValid ? amountError : ""}
                name="priceAmount"
                size="sm"
                callback={handleOnModalChange}
                startIcon={budget}
                onInput={onInput}
              />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ background: "red !important" }}
                  onClick={handleWithdrawalSubmit}
                  disabled={
                    withdrawalAmount <= 0 ||
                    !isValid ||
                    withdrawalAmount > details?.user?.referral_balance
                  }
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}
