import React, {useState, useEffect} from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import DepositFundsPage from './DepositFundsPage';
import BillingHistoryPage, { RequestInvoiceModal } from './BillingHistoryPage';
import { useGlobalContext } from '../../context';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  Chip,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Container,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import BillingNavigation from './Navigation';
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";

const BillingPage = () => {
  const isBelow800px = useMediaQuery('(max-width:800px)');
  const {
    companyInfo,
    setCompanyInfo,
    tempCompanyInfo,
    setTempCompanyInfo,
    openCompanyInfoModal,
    setOpenCompanyInfoModal,
    handleSaveCompanyInfo,
    handleOpenCompanyModalModal,
    handleCloseCompanyInfoModal,
  } = useGlobalContext();
  
  
  return (
    <Container maxWidth="xl" disableGutters>
      <Box sx={{ 
        display: "flex", 
        width: '100%', 
        mt: 2,
        mb: 2,
        flexDirection: 'column',
        position: 'relative',
      }}>
        {/* <Box sx={{ 
          display: "flex",
          // alignItems: "center",
          position: 'absolute',
          left: '40px',
          top: 0,
          visibility: isBelow800px ? 'hidden' : 'visible',
        }}>
          <Paper
            sx={{
              padding: "1rem",
              display: "flex",
              // alignItems: "center",
              justifyContent: "space-between",
              width: "380px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Company Details
                </Typography>

                <br />
                <Typography variant="body2" fontWeight="bold">
                  Business Name:
                </Typography>
                <Typography variant="body2">
                  {!companyInfo.name
                    ? "Please enter your company name!"
                    : companyInfo.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  Business Address:
                </Typography>
                <Typography variant="body2">
                  {!companyInfo.address
                    ? "Please enter your company address."
                    : companyInfo.address}
                </Typography>
              </Grid>
            </Grid>


            <Grid>
            
            <IconButton
              onClick={handleOpenCompanyModalModal}
              sx={{ color: "#1f8352" }}
            >
              <EditIcon />
            </IconButton>
            <RequestInvoiceModal
              setOpenCompanyInfoModal={setOpenCompanyInfoModal}
              companyInfo={companyInfo}
            />
            </Grid>

          </Paper>

          <Box sx={{ 
            marginLeft: '15px',
            alignItems: "flex-end",
          }}>

          </Box>
        </Box> */}
        
        {/* Navigation - centered in page */}
        <Box sx={{ 
          width: '100%',
          display: 'flex', 
          justifyContent: 'center',
        }}>
          <BillingNavigation />
        </Box>
      </Box>
      
      <div className='biling-page-tabs-content' >
        <Routes>
          <Route path="/deposit" element={<DepositFundsPage />} />
          <Route path="/" element={<BillingHistoryPage />} />
          <Route path="*" element={<Navigate to="/billing" replace />} />
        </Routes>
      </div>

      {/* Company Info Dialog */}
      <EditCompanyInfoDialog
        open={openCompanyInfoModal}
        onClose={handleCloseCompanyInfoModal}
        companyInfo={companyInfo}
        onSave={handleSaveCompanyInfo}
        tempInfo={tempCompanyInfo}
        setTempInfo={setTempCompanyInfo}
      />
    </Container>
  );
};

export default BillingPage;

export const EditCompanyInfoDialog = ({
  open,
  onClose,
  companyInfo,
  onSave,
  tempInfo,
  setTempInfo,
}) => {
  const [isEditLoading, setIsEditLoading] = useState(false);
  const { isCompanyInfoMissing } = useGlobalContext();

  useEffect(() => {
    setTempInfo(companyInfo);
  }, [companyInfo]);

  const handleSave = async () => {
    setIsEditLoading(true);
    try {
      await onSave(tempInfo);
      setIsEditLoading(false);
      onClose();
    } catch (error) {
      console.error("Error updating company info:", error);
      setIsEditLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {!isCompanyInfoMissing
          ? "Edit Company Information"
          : "Please enter your company details to request an invoice."}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Company Name"
          fullWidth
          margin="normal"
          value={tempInfo.name}
          onChange={(e) => setTempInfo({ ...tempInfo, name: e.target.value })}
        />
        <TextField
          label="Company Address"
          fullWidth
          margin="normal"
          multiline
          value={tempInfo.address}
          onChange={(e) =>
            setTempInfo({ ...tempInfo, address: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="success"
          variant="contained"
          disabled={
            isEditLoading ||
            (tempInfo.name === companyInfo.name &&
              tempInfo.address === companyInfo.address) ||
            !tempInfo.name.length ||
            !tempInfo.address.length
          }
        >
          {isEditLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};