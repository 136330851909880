// Enhanced CSV Export Functions
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const convertToCSV = (objArray) => {
  if (!objArray || objArray.length === 0) return '';
  
  const header = Object.keys(objArray[0]);
  let csv = header.join(',') + '\n';
  
  objArray.forEach(obj => {
    const row = header.map(key => {
      // Handle values with commas by quoting them
      const value = obj[key] !== undefined ? obj[key] : '';
      const valueStr = String(value);
      return valueStr.includes(',') ? `"${valueStr}"` : valueStr;
    });
    csv += row.join(',') + '\n';
  });
  
  return csv;
};

const handleExportToCSV = async (campaignData, getAllTabsData, selectedCampaigns) => {
  console.log(selectedCampaigns, "selectedCampaignsKiko");
  
  // Create a zip file with multiple CSV sheets
  const currentDate = new Date().toISOString().slice(0, 10);
  const zip = new JSZip();
  
  try {
    const allTabsData = getAllTabsData();    
    
    const campaignInsightsData = [
      {
        campaign: selectedCampaigns.name,
        impressions: campaignData.impressions || 0,
        clicks: campaignData.clicks || 0,
        ctr: campaignData.CTR || 0,
        adSpend: campaignData.amount_spent.toFixed(2) || 0,
        cpc: campaignData.CPC || 0,
        conversions: campaignData.conversions || 0
      }
    ];
    
    // Create CSV content for each sheet
    zip.file("1-campaign-insights.csv", convertToCSV(campaignInsightsData));
    zip.file("2-audiences.csv", convertToCSV(allTabsData.audiences));
    zip.file("3-ads.csv", convertToCSV(allTabsData.ads));
    zip.file("4-geo.csv", convertToCSV(allTabsData.geo));
    zip.file("5-publishers.csv", convertToCSV(allTabsData.publishers));
    
    // Generate zip file and trigger download
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `campaign-report-${currentDate}.zip`);
    
  } catch (error) {
    console.error("Error creating CSV export:", error);
    alert("There was an error creating the export. Please try again.");
  }
};

export default handleExportToCSV;