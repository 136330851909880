import {
  Box,
  Paper,
  CircularProgress,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { TrackAnEventContent } from "../../tracking-drawer/tracking-tabs/create-conversion-tab/CreateConversionTab";
import { useEffect, useState } from "react";
import GTMIntegration from "../components/GTMIntegration"; // Import the GTM component

export default function ConversionSetup() {
  const { conversionOptions, createConversionData } = useGlobalContext();

  const { trackAnEventScriptTag } = conversionOptions || {};
  const [conversionContent, setConversionContent] = useState({
    title: "",
    description: "",
    step1Title: "",
    step1Description: "",
  });

  useEffect(() => {
    if (createConversionData?.conversionMethod) {
      updateContentByMethod(createConversionData.conversionMethod);
    }
  }, [createConversionData?.conversionMethod]);

  const updateContentByMethod = (method) => {
    const content = {
      title: `Conversion Setup: ${getMethodTitle(method)}`,
      description: getMethodDescription(method),
      step1Title: `Step 1: ${getStep1Title(method)}`,
      step1Description: getStep1Description(method),
    };
    setConversionContent(content);
  };

  const getMethodTitle = (method) => {
    switch (method) {
      case "gtm":
        return "Google Tag Manager";
      case "pixel":
        return "Manual Installation";
      case "server":
        return "Server-to-Server Integration";
      default:
        return "";
    }
  };

  const getMethodDescription = (method) => {
    switch (method) {
      case "gtm":
        return "You've chosen to install the Blockchain-Ads conversion pixel using Google Tag Manager (GTM). Follow these steps to add the pixel and start tracking conversions.";
      case "pixel":
        return "You've chosen to manually install the Blockchain-Ads conversion pixel on your website. Follow these steps to complete the setup and start tracking conversions.";
      case "server":
        return "Server-to-Server (S2S) Conversion Tracking allows advertisers to track conversions without relying on browser-based cookies. This ensures accurate tracking, even in cases where users have blocked third-party cookies or switched devices.";
      default:
        return "";
    }
  };

  const getStep1Title = (method) => {
    switch (method) {
      case "gtm":
        return "Add the Pixel Code to GTM";
      case "pixel":
        return "Add the Pixel Code to Your Website";
      case "server":
        return "Configure the Server-to-Server Integration";
      default:
        return "";
    }
  };

  const getStep1Description = (method) => {
    switch (method) {
      case "gtm":
        return "Copy the script below and add it as a new tag in your Google Tag Manager account. Set the tag to trigger on the page where you want to track conversions (e.g., a confirmation page).";
      case "pixel":
        return "Copy the script below and paste it into the HTML of the webpage where you want to track conversions (e.g., a thank-you page after a purchase or sign-up).";
      case "server":
        return "You'll need to set up an API call from your server to Blockchain-Ads to track conversions. Use the following endpoint and parameters:";
      default:
        return "";
    }
  };

  const renderS2SGuide = () => {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          S2S Conversion Tracking Guide
        </Typography>
        
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          Overview
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Server-to-Server (S2S) Conversion Tracking allows advertisers to track conversions without relying on browser-based cookies. This ensures accurate tracking, even in cases where users have blocked third-party cookies or switched devices.
        </Typography>
        
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          How It Works
        </Typography>
        <List dense sx={{ mb: 2 }}>
          <ListItem sx={{ pl: 0 }}>
            <ListItemText 
              primary={<Typography variant="body1" fontWeight="bold">Advertiser Provides a Destination URL</Typography>}
              secondary={
                <Box component="span" sx={{ display: 'block', ml: 2 }}>
                  • While creating a campaign, the advertiser must enter a destination URL.<br />
                  • Example: Destination URL: https://www.playbet.io/promotions
                </Box>
              }
            />
          </ListItem>
          
          <ListItem sx={{ pl: 0 }}>
            <ListItemText 
              primary={<Typography variant="body1" fontWeight="bold">Generating a Unique Click Tracking ID</Typography>}
              secondary={
                <Box component="span" sx={{ display: 'block', ml: 2 }}>
                  • When a user clicks the banner, our ad server generates a unique tracking ID (sig) and appends it to the destination URL.<br />
                  • Example: Redirected URL: https://www.playbet.io/promotions?sig=fg8d7fg87dfg8df8g7d8fg7d8f7g8df7887f88dh78sfsdfdgs78d7f8s7df87s
                </Box>
              }
            />
          </ListItem>
          
          <ListItem sx={{ pl: 0 }}>
            <ListItemText 
              primary={<Typography variant="body1" fontWeight="bold">Advertiser Captures the Tracking ID</Typography>}
              secondary={
                <Box component="span" sx={{ display: 'block', ml: 2 }}>
                  • The advertiser must capture and store the sig parameter on their system when the user lands on their page.
                </Box>
              }
            />
          </ListItem>
          
          <ListItem sx={{ pl: 0 }}>
            <ListItemText 
              primary={<Typography variant="body1" fontWeight="bold">Tracking Conversions</Typography>}
              secondary={
                <Box component="span" sx={{ display: 'block', ml: 2 }}>
                  • When a user completes a conversion (e.g., signup, purchase, lead submission), the advertiser must send the stored sig value back to our ad server using either:<br />
                  • HTML Pixel Tracking<br />
                  • Postback URL Tracking
                </Box>
              }
            />
          </ListItem>
        </List>
        
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          Implementation Methods
        </Typography>
        
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          1. HTML Pixel Tracking
        </Typography>
        <Box sx={{ ml: 2, mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            • The advertiser must place the following tracking pixel on the conversion page:
          </Typography>
          <Box 
            sx={{ 
              backgroundColor: '#f5f5f5', 
              p: 2, 
              borderRadius: 1,
              fontFamily: 'monospace',
              fontSize: '0.875rem',
              mb: 1,
              overflowX: 'auto'
            }}
          >
            &lt;div id='tracker' style='position: absolute; left: 0px; top: 0px; visibility: hidden;'&gt;<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;img src='https://server.blockchain-ads.com/www/delivery/tracker-mobile.php?click_id=&#123;clickid&#125;' width='0' height='0' alt='' /&gt;<br />
            &lt;/div&gt;
          </Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            • The &#123;clickid&#125; must be replaced with the sig parameter received during the initial click.
          </Typography>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0.5 }}>
            • Example:
          </Typography>
          <Box 
            sx={{ 
              backgroundColor: '#f5f5f5', 
              p: 2, 
              borderRadius: 1,
              fontFamily: 'monospace',
              fontSize: '0.875rem',
              mb: 1,
              overflowX: 'auto'
            }}
          >
            &lt;img src='https://server.blockchain-ads.com/www/delivery/tracker-mobile.php?click_id=fg8d7fg87dfg8df8g7d8fg7d8f7g8df7887f88dh78sfsdfdgs78d7f8s7df87s' width='0' height='0' alt='' /&gt;
          </Box>
        </Box>
        
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          2. Postback URL Tracking
        </Typography>
        <Box sx={{ ml: 2, mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            • The advertiser can send a server-to-server request to our postback URL:
          </Typography>
          <Box 
            sx={{ 
              backgroundColor: '#f5f5f5', 
              p: 2, 
              borderRadius: 1,
              fontFamily: 'monospace',
              fontSize: '0.875rem',
              mb: 1,
              overflowX: 'auto'
            }}
          >
            GET https://server.blockchain-ads.com/www/delivery/tracker-mobile.php?click_id=&#123;clickid&#125;
          </Box>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0.5 }}>
            • Example:
          </Typography>
          <Box 
            sx={{ 
              backgroundColor: '#f5f5f5', 
              p: 2, 
              borderRadius: 1,
              fontFamily: 'monospace',
              fontSize: '0.875rem',
              mb: 1,
              overflowX: 'auto'
            }}
          >
            GET https://server.blockchain-ads.com/www/delivery/tracker-mobile.php?click_id=fg8d7fg87dfg8df8g7d8fg7d8f7g8df7887f88dh78sfsdfdgs78d7f8s7df87s
          </Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            • This request must be made from the advertiser's server when a conversion occurs.
          </Typography>
        </Box>
        
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          Summary
        </Typography>
        <List dense sx={{ mb: 2 }}>
          <ListItem sx={{ pl: 2 }}>
            <ListItemText primary="• Our ad server generates a unique sig parameter on banner clicks." />
          </ListItem>
          <ListItem sx={{ pl: 2 }}>
            <ListItemText primary="• Advertisers must capture and store the sig value." />
          </ListItem>
          <ListItem sx={{ pl: 2 }}>
            <ListItemText primary="• On conversion, advertisers must send the sig value back to our ad server via:" secondary={
              <Box component="span" sx={{ display: 'block', ml: 2 }}>
                • HTML Pixel Tracking (for browser-based tracking)<br />
                • Postback URL Tracking (for server-to-server tracking)
              </Box>
            } />
          </ListItem>
          <ListItem sx={{ pl: 2 }}>
            <ListItemText primary="• This ensures precise conversion tracking without dependency on third-party cookies." />
          </ListItem>
        </List>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        {/* GTM Integration Box - Only shows when conversionMethod is "gtm" */}
        {createConversionData.conversionMethod === "gtm" && <GTMIntegration />}

        {/* S2S Implementation Guide */}
        {createConversionData.conversionMethod === "server" ? (
          renderS2SGuide()
        ) : (
          <>
            <Typography variant="body1">
              <strong
                dangerouslySetInnerHTML={{ __html: conversionContent.title }}
              />
            </Typography>
            
            <Box sx={{ my: 2 }}>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: conversionContent.description,
                }}
              />
            </Box>

            <Divider sx={{ my: 2 }} />
            
            <Typography variant="body1">
              <strong
                dangerouslySetInnerHTML={{ __html: conversionContent.step1Title }}
              />
            </Typography>

            <Box sx={{ my: 2 }}>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: conversionContent.step1Description,
                }}
              />
              <br />
            </Box>
            
            <Box sx={{ my: 3 }}>
              {trackAnEventScriptTag?.isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexGrow: 1,
                    height: "100%",
                  }}
                >
                  <CircularProgress sx={{ my: "10%" }} />
                </Box>
              ) : (
                <TrackAnEventContent type="conversions" />
              )}
            </Box>

            <Typography variant="body1">
              <strong>Step 3: Confirm Setup</strong>
            </Typography>

            <Box sx={{ my: 2 }}>
              <Typography variant="body1">
                Once the script is added to your website, click "Confirm" to
                complete the setup. We'll verify the pixel is working correctly.
              </Typography>
            </Box>
          </>
        )}

        {/* Always show TrackAnEventContent for any method */}
        {createConversionData.conversionMethod === "server" && (
          <Box sx={{ my: 3 }}>
            {trackAnEventScriptTag?.isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexGrow: 1,
                  height: "100%",
                }}
              >
                <CircularProgress sx={{ my: "10%" }} />
              </Box>
            ) : (
              <TrackAnEventContent type="conversions" />
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4, p: 3 }}>
      <Paper
        elevation={1}
        sx={{
          p: 3,
          borderRadius: 2,
          minHeight: "200px",
        }}
      >
        {renderContent()}
      </Paper>
    </Box>
  );
}