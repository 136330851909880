import React from "react";
import ReferralTable from "../ReferralTable";

export default function ReferredUsers({ details = [] }) {
  const headers = [
    {
      header: "Email",
      prop: "email",
    },
    {
      header: "Created At",
      prop: "createdAt",
      type: "date",
    },
    {
      header: "Invite Accepted",
      prop: "inviteAccepted",
      type: "boolean",
    },
    {
      header: "Invite Accepted Date",
      prop: "acceptedAt",
      type: "date",
    },
  ];
  console.log(details.invites);
  return (
    <div className="reports">
      <h2 className="display-2">Referred Users: </h2>
      <br />
      <ReferralTable
        allRows={details?.invites}
        isLoading={false}
        headers={headers}
      />
      <br />
    </div>
  );
}
