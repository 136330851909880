import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  Chip
} from "@mui/material";
import { useGlobalContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";

export const GoalsPageAdminPage = () => {
  const {
    requestedData: { userProfileData },
    getAdvertiserPixelStatsRequestAdmin,
    adminTrackingPixels,
    setAdminTrackingPixels,
    successAlert,
    errorAlert,
    isTrackingPageLoading, 
  } = useGlobalContext();

  const {pixelVerification} = useFetch()

  const [selectedOption, setSelectedOption] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [selectedTracker, setSelectedTracker] = useState(null);

  useEffect(() => {
    if (selectedOption !== null) {
      getAdvertiserPixelStatsRequestAdmin(selectedOption);
    }else {
        setAdminTrackingPixels()
    }
  }, [selectedOption]);

  const handleOpenModal = (tracker) => {
    setSelectedTracker(tracker);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setInputValue("");
  };

  const handleSubmit = async () => {

    const tempRaw = {
        organizationId: selectedOption,
        trackerid: selectedTracker.trackerid,
        totalconversion: parseFloat(inputValue),
        total_audience: 0,
        tracker_group: selectedTracker.tracker_group
    }

    try {
        const data = await pixelVerification(() => {}, tempRaw)

        console.log(console.log(data, "Ajde sega"));

        successAlert(data.res_msg)

        handleCloseModal()
        getAdvertiserPixelStatsRequestAdmin(selectedOption);

    }catch(err){
        console.log(err);
        errorAlert("Ops! Something went wrong! Please select organization.")
    }
  };


  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A"; // Handle null values
    if (typeof timestamp === "string") return timestamp; // Already formatted
  
    const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // Format the date as a readable string
  };
  

  console.log(adminTrackingPixels, "adminTrackingPixels123");
  

  return (
    <div style={{ padding: "20px", margin: "auto" }}>
      <br />

      <Autocomplete
        options={userProfileData.allOrganization}
        getOptionLabel={(option) => option.bcaName}
        onChange={(_, newValue) => setSelectedOption(newValue ? newValue.id : null)}
        renderInput={(params) => <TextField {...params} label="Search & Select Goal Type" variant="outlined" />}
        style={{ marginBottom: "20px", maxWidth: "300px" }}
        size="small"
      />

      {adminTrackingPixels !== null && (
        isTrackingPageLoading ? <Box sx={{display: "flex", justifyContent: "center", paddingTop: "5rem"}}><CircularProgress /></Box>:
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Goal Name</TableCell>
                <TableCell>Last Trigger Date Time</TableCell>
                <TableCell>Total Conversion</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {adminTrackingPixels?.length ? <>
                    {(adminTrackingPixels || []).map((row) => (
                <TableRow key={row.trackerid}>
                  <TableCell>{row?.trackerid}</TableCell>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{formatTimestamp(row?.last_trigger_date_time)}</TableCell>
                  <TableCell>{row?.totalconversion}</TableCell>
                  <TableCell><Chip label={row?.status} sx={{background: `${row.status === "verified" ? "#2aab7e" : "#ff6161" }`, color: "#ffff"}} /></TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleOpenModal(row)}>
                      +
                    </Button>
                  </TableCell>
                </TableRow>
              ))}</> : <Box sx={{padding: "2rem"}}>
                    <h3 className="text-muted">No data! Please select organization</h3>
                </Box>}

            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
        <Box sx={{ minWidth: 600, minHeight: 200, padding: 3 }}>
          <DialogTitle>Add total conversions</DialogTitle>
          <DialogContent>
            <TextField
              label="Enter Number"
              type="number"
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={inputValue <= 0}>
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
