import React, { useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ExportDropdown = ({ onExportCSV, onExportPDF }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportCSV = () => {
    onExportCSV();
    handleClose();
  };

  const handleExportPDF = () => {
    onExportPDF();
    handleClose();
  };

  return (
    <Box>
      <Button
        ref={buttonRef}
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<GetAppIcon />}
        sx={{
          borderColor: '#2c8059',
          background: "#2c8059",
          color: '#ffff',
          '&:hover': {
            background: "#1e5c30",
          }
        }}
      >
        Export
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleExportCSV}>CSV</MenuItem>
        <MenuItem onClick={handleExportPDF}>PDF</MenuItem>
      </Menu>
    </Box>
  );
};

export default ExportDropdown;