import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import {
  Button,
  CopyTextButton,
  Search,
  TableSkeleton,
  Field,
  Pagination,
  Loading,
} from "..";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter";
import useSort from "../../hooks/useSort";
import useAccountsSort from "./useAccountsSort";
import Switch from "@mui/material/Switch";
import { getAllByAltText } from "@testing-library/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetch from "../../hooks/useFetch";
import { auth } from "../../config/firebase";
import { CircularProgress } from "@mui/material";
const { close, arrowDown, arrowUp, eyeSolid, sortDown, sortUp, sortIcon } =
  localData.svgs;

export default function Accounts({ setIsOpen }) {
  const { textPreloader } = localData.images;

  const {
    requestedData,
    setRequestedData,
    authUser,
    setAuthUser,
    errorAlert,
    getLoadUserProfile,
  } = useGlobalContext();

  const [accountOrganization, setAccountOrganization] = useState(null);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [allOrganization, setAllOrganization] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const organization = allOrganization.find(
      (item) => item.adminID === requestedData.userProfileData.userId
    );
    setAccountOrganization(organization);
  }, [requestedData]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const [sort, setSort] = useState({
    type: "created_at",
    orderDest: "desc",
  });

  useEffect(() => {
    console.log("sort changed");
    fetchData();
  }, [sort]);
  const { adminGetUsers, adminGetOrganizations } = useFetch();

  const getSortIcon = (type) => {
    if (sort.type !== type) return <span className="sort-btn">{sortIcon}</span>;
    return (
      <span className="sort-btn active">
        {sort.orderDest !== "desc" ? sortUp : sortDown}
      </span>
    );
  };

  const handleOnClick = (type = "") => {
    const a = sort.orderDest == "asc" ? "desc" : "asc";
    console.log("setting sort");
    setSort({
      type: type,
      orderDest: a,
    });
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isOrganizationsLoading, setIsOrganizationsLoading] = useState(false);
  const [isQueryExist, setIsQueryExist] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchData = async (props = {}) => {
    setIsOrganizationsLoading(true);
    if (!auth.currentUser) return;
    let raw = {
      uuid: auth.currentUser.uid,
      ...props,
    };
    console.log(sort);
    console.log("fetching data");
    if (sort.type != "") {
      raw = { ...raw, ...sort };
    }

    const orgsResult = await adminGetOrganizations(() => {}, raw);

    setFilteredOrganizations(orgsResult.res_data);
    setAllOrganization(orgsResult.res_data);
    setTotalPages(Math.ceil(orgsResult.total_items / 50));
    setIsOrganizationsLoading(false);
  };
  const handlePagination = (nextPage = 1) => {
    console.log("pagi");
    setPage(nextPage);
    fetchData({ page: nextPage });
  };

  const handleFind = (query) => {
    console.log("find");
    fetchData({ search: query });
    setIsQueryExist(true);
    setQuery(query);
  };

  const resetFind = () => {
    console.log("reset");
    setIsQueryExist(false);
    setQuery("");
    fetchData();
  };

  return (
    <>
      <div className="modal-body" style={{ padding: "0px 1rem 20px" }}>
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">ORGANIZATIONS</h5>
          <Search
            {...{
              variant: "contained",
              className: `accounts-search`,
              isOpen: isSearchOpen,
              setIsOpen: setIsSearchOpen,
              size: "sm",
              color: "purple",
              disabled: isOrganizationsLoading,
              callback: handleFind,
              placeholder: "Filter Address / ID, BCA Name",
            }}
          />
          {isQueryExist && !isOrganizationsLoading && (
            <div className="query-badge">
              <div className="query-badge-text">{query}</div>
              <Button
                variant="circle"
                icon={close}
                size="sm"
                color="secondary"
                onClick={() => {
                  resetFind();
                }}
              />
            </div>
          )}
        </div>
        <div className="scroll">
          {isOrganizationsLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th className="table-th-details text-center">
                    <div
                      className="details-icon"
                      style={{ margin: "0 auto" }}
                      data-tooltip-id="tooltip-organization-details"
                      data-tooltip-content={`See the members.`}
                    >
                      {eyeSolid}
                    </div>
                  </th>
                  <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>
                    #
                  </th>
                  <th style={{ width: 0 }}>Admin Name</th>
                  <th style={{ width: 0 }}>BCA Name</th>
                  <th style={{ width: 0 }}>
                    <span
                      className="status-with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        resetFind();
                        handleOnClick("created_at");
                      }}
                    >
                      Created At{getSortIcon("created_at")}
                    </span>
                  </th>
                  <th className="">Admin Email</th>
                  <th>
                    <span
                      className="status-with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        resetFind();
                        handleOnClick("account_status");
                      }}
                    >
                      Account Status{getSortIcon("account_status")}
                    </span>
                  </th>
                  <th>
                    <span
                      className="with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        resetFind();
                        handleOnClick("has_active_campaigns");
                      }}
                    >
                      Active Campaign Status
                      {getSortIcon("has_active_campaigns")}
                    </span>
                  </th>
                  <th>
                    <span
                      className="with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        resetFind();
                        handleOnClick("current_balance");
                      }}
                    >
                      Current Balance {getSortIcon("current_balance")}
                    </span>
                  </th>
                  {/* <th className="">Admin ID</th> */}
                  <th className="">ID</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!filteredOrganizations.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  filteredOrganizations.map((item, index) => {
                    const color =
                      item.who_is_admin === "3FoFz6dNudSQEJefCOEcortk9mN2"
                        ? "text-purple"
                        : item.who_is_admin ===
                          requestedData.userProfileData.userId
                        ? "text-success"
                        : "";
                    return (
                      <AccountsRow
                        key={index}
                        {...{ ...item, item, index }}
                        color={color}
                        users={users}
                        fetchData={fetchData}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        <br />
        <br />
        {!filteredOrganizations.length ? (
          ""
        ) : (
          <div
            className={`pagination-wrapper  ${
              isOrganizationsLoading ? "on-hold" : ""
            }`}
          >
            <Pagination
              callback={handlePagination}
              currentPage={page}
              totalPages={totalPages}
              isLoading={isOrganizationsLoading}
            />
          </div>
        )}
      </div>
      <br />
      <br />
    </>
  );
}

const AccountsRow = ({ item, color, index, users, fetchData }) => {
  const {
    getAdminResetUserPassword,
    requestedData,
    isLoading: { adminResetUserPasswordIsLoading },
  } = useGlobalContext();

  const { addCommas, addCommasKeepMinus, formatCurrency } = useFormatter();
  const [activeDetails, setActiveDetails] = useState({});
  const {
    updateAccountBCANameRequest,
    updateAccountStatusBCARequest,
    errorAlert,
    successAlert,
    getLoadUserProfile,
  } = useGlobalContext();

  const navigate = useNavigate();

  const [bcaNameIsLoading, setBcaNameIsLoading] = useState(false);
  const [bcaStatusIsLoading, setBcaStatusIsLoading] = useState(false);
  const [isBcaNameStored, setIsBcaNameStored] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);

  const findUserId = (userName) => {
    const user = users.find((u) => u.name === userName);
    return user.id;
  };

  const handleOnBlur = (e) => {
    console.log("blr");
    updateAccountBCANameRequest({
      bcaName: e.target.value,
      organizationID: item.id,
      setBcaNameIsLoading,
    });
    setIsBcaNameStored(true);

    const updateAccountInStorage = () => {
      const storedBcaNames = JSON.parse(localStorage.getItem("bcaNames")) || [];
      storedBcaNames.push({ name: e.target.name, created_at: Date.now() });

      localStorage.setItem("bcaNames", JSON.stringify(storedBcaNames));
    };
    updateAccountInStorage();
    fetchData();
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleStatusChange = (id, account_status) => {
    updateAccountStatusBCARequest({
      organizationID: id,
      account_status: account_status == "Active" ? "Inactive" : "Active",
      setBcaStatusIsLoading,
      setShowModal,
    });

    setShowModal(false);
    item.account_status = account_status == "Active" ? "Inactive" : "Active";
  };

  const { updateAdvertiserBalance, addMemberToOrganization } = useFetch();

  const [open, setOpen] = useState(false);
  const [isModalInviteOpen, setIsModalInivteOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [inviteMember, setInivteMemeber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInivteModalOpen = () => setIsModalInivteOpen(true);
  const handleInivteModalClose = () => setIsModalInivteOpen(false);

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleInviteMemberChange = (e) => {
    setInivteMemeber(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const tempRaw = {
      amount: parseFloat(amount),
      organizationId: item.id,
      uuid: requestedData.userProfileData.userId,
      type: balanceType,
    };
    try {
      const data = await updateAdvertiserBalance(() => {}, tempRaw);
      successAlert(data.res_msg);
      setIsLoading(false);
      item.current_balance =
        parseFloat(item.current_balance) + parseFloat(amount);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateAdvertiserBalance= request error");
      setIsLoading(false);
    }
    handleClose();
  };

  const [balanceType, setBalanceType] = useState("Refund");

  const handleChange = (event) => {
    setBalanceType(event.target.value);
  };

  const addMemberToOrganizationRequest = async (email, id) => {
    setInviteLoading(true);
    setIsLoading((prev) => ({
      ...prev,
      adminResetUserPasswordIsLoading: true,
    }));
    const temRaw = {
      organizationID: id,
      email,
    };
    try {
      const data = await addMemberToOrganization(() => {}, temRaw);
      successAlert(data?.res_msg || "Member invited successfully");
      setInivteMemeber("");
      setIsModalInivteOpen(false);
      setInviteLoading(false);
      if (data?.res_data?.name) {
        item.member_names.push(data.res_data.name);
      }
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      setInivteMemeber("");
      setInviteLoading(false);
    }
  };

  const { switchOrganization } = useFetch();

  const getSwitchOrganization = async (id) => {
    if (!auth.currentUser) return;
    setIsLoading(true);
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationID: id,
    };
    try {
      const data = await switchOrganization(() => {}, temRaw);
      getLoadUserProfile(id);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=switchOrganization= request error");
    }
    setIsLoading(false);
  };

  const [isSwitched, setIsSwitched] = useState(false);

  const handleChangeOrg = async (selectedOption) => {
    if (isSwitched) return;
    setIsSwitched(true);
    try {
      await getSwitchOrganization(selectedOption);
      navigate("/campaigns");
    } catch (err) {
      console.log(err);
    }
    setIsSwitched(false);
  };

  return (
    <>
      <tr
        className={`${
          item.current_balance == 0
            ? ""
            : item.current_balance > 0
            ? "bg-success-100"
            : item.current_balance < 0
            ? "bg-danger-100"
            : ""
        }`}
      >
        <td className="table-td-hush">
          <Button
            variant="circle"
            size="sm"
            color="secondary"
            icon={activeDetails.id === item.id ? arrowUp : arrowDown}
            onClick={(e) => {
              e.stopPropagation();
              setActiveDetails(
                activeDetails.id === item.id ? {} : { id: item.id }
              );
            }}
          />{" "}
        </td>
        <td style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>
          {index + 1}.
        </td>
        <td title={item.name} className={`${color} table-td-name`}>
          <div className="ellipsis" onClick={() => handleChangeOrg(item.id)}>
            {item.name}
          </div>
          {isSwitched ? (
            <CircularProgress size="13px" sx={{ marginLeft: "10px" }} />
          ) : (
            <CopyTextButton text={item.name} />
          )}
        </td>
        <td
          title={item.bcaName}
          className={`table-td-bca-name ${color} ${
            isBcaNameStored ? "disabled" : ""
          }`}
          style={{ width: 0 }}
        >
          {/* <input type="text" value={bcaName} onChange={handleOnChange} onBlur={handleOnBlur} /> */}
          <Field
            name={item.bca_name}
            type="text"
            labelInfo="change name here"
            value={item.bca_name}
            color="secondary"
            placeholder="e.g. johnDoeCamp"
            required={true}
            size="sm"
            // callback={handleOnChange}
            onBlur={(e) => e.target.value !== item.bca_name && handleOnBlur(e)}
            disabled={bcaNameIsLoading}
          />
        </td>
        <td>{item.created_at}</td>
        <td title={item.adminEmail} className={`${color} table-td-admin-email`}>
          <div className="ellipsis">{item.adminEmail}</div>
          <CopyTextButton text={item.adminEmail} />
        </td>
        <td>
          <span className={`text-inactive`}>Inactive</span>
          <Switch
            checked={item.account_status === "Active"}
            onChange={() => setShowModal(true)}
            size="small"
            color="default"
          />
          <span
            className={` text-${item.account_status === "Active" && "success"}`}
          >
            Active
          </span>
          <CustomModal isOpen={showModal} onClose={closeModal}>
            <div
              style={{
                padding: "20px",
                maxWidth: "400px",
                textAlign: "center",
              }}
            >
              <h2 style={{ marginBottom: "15px" }}>Confirm Status Change</h2>
              <p style={{ textWrap: "wrap" }}>
                You are about to change the status of{" "}
                <strong>{item.name}</strong>.
              </p>
              <p>
                <strong>Current Status:</strong> {item.account_status}
              </p>
              <p>
                <strong>New Status:</strong>{" "}
                {item.account_status == "Active" ? "Inactive" : "Active"}{" "}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={closeModal}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#ccc",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    handleStatusChange(item.id, item.account_status)
                  }
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#4CAF50",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </CustomModal>
        </td>
        <td className={`text-center`}>
          <span
            className={` text-${
              item.activeStatus ? "success" : "secondary-500"
            }`}
          >
            {item.activeStatus ? "Active" : "Inactive"}
          </span>
        </td>
        <td className={`${color}`}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {formatCurrency(item.current_balance, 0) || 0}{" "}
            <AddCircleIcon
              sx={{ color: "#2aab7e", width: "20px", marginLeft: "10px" }}
              onClick={handleOpen}
            />
          </div>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minHeight: 200,
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    top: "0px",
                    left: "0px",
                    textAlign: "center",
                    zIndex: "999",
                  }}
                >
                  <CircularProgress
                    style={{
                      position: "relative",
                      top: "50%",
                      transform: "translate(-50%, 0%)",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <h2 style={{ margin: 0 }}>Add Balance</h2>
              <br />
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={balanceType}
                  label="Age"
                  onChange={handleChange}
                >
                  <MenuItem value={"Credit"}>Credit</MenuItem>
                  <MenuItem value={"Receipt"}>Receipt</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                fullWidth
                margin="normal"
                label="Enter Amount"
                type="number"
                value={amount}
                onChange={handleInputChange}
                size="small"
              />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ background: "red !important" }}
                  onClick={handleSubmit}
                  disabled={amount <= 0}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Modal>
        </td>
        {/* <td className={`${color}`}>{item.adminID}</td> */}
        <td className={`${color}`} style={{ width: 0 }}>
          {item.id} <CopyTextButton text={item.id} />
        </td>
        <td className={`${color}`}>
          <Button
            onClick={() => getAdminResetUserPassword(item.adminEmail)}
            variant="outlined"
            color="primary"
            size="sm"
            disabled={adminResetUserPasswordIsLoading}
          >
            {adminResetUserPasswordIsLoading
              ? `Sending...`
              : "Send reset password link"}
          </Button>
          <Button
            variant="outlined"
            color="marketplace"
            size="sm"
            disabled={adminResetUserPasswordIsLoading}
            onClick={handleInivteModalOpen}
            style={{ marginLeft: "5px" }}
          >
            Add Member
          </Button>
        </td>
        <td className={`${color}`}>
          <Modal open={isModalInviteOpen} onClose={handleInivteModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minHeight: 200,
              }}
            >
              {inviteLoading ? (
                <div
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    top: "0px",
                    left: "0px",
                    textAlign: "center",
                    zIndex: "999",
                  }}
                >
                  <CircularProgress
                    style={{
                      position: "relative",
                      top: "50%",
                      transform: "translate(-50%, 0%)",
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <h2 style={{ margin: 0 }}>Add Member</h2>
              <br />
              <TextField
                fullWidth
                margin="normal"
                label="Enter Email"
                type="email"
                value={inviteMember}
                onChange={handleInviteMemberChange}
                size="small"
              />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ background: "red !important" }}
                  onClick={() =>
                    addMemberToOrganizationRequest(inviteMember, item.id)
                  }
                  disabled={inviteMember <= 0}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Modal>
        </td>
      </tr>
      {activeDetails.id === item.id && (
        <>
          {item.members.map((subItem, subIndex) => {
            return (
              <tr key={subIndex} className="details-tr">
                <td colSpan={6} className="">
                  {subItem.name}
                </td>
                <td colSpan={4} className="">
                  Last Seen: {subItem.lastSeen}
                </td>
                <td colSpan={2}>
                  <Link to={`/user-info/${subItem.id}`}>
                    <Button
                      name="User Profile"
                      variant="contained"
                      size="sm"
                      style={{ marginLeft: "5px" }}
                    />
                  </Link>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-user" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};
