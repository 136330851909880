import React, { useState, useEffect } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import useFormatter from "../../../../hooks/useFormatter";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Menu, MenuItem } from "@mui/material";

export default function Geo() {
    const {
        requestedData,
        isLoading,
        requestedData: {
            campaignStatistics: { geo },
        },
        conversionOptions,
        selectedConversionTag, 
        setSlectedConversionTag
    } = useGlobalContext();
    const { textPreloader } = localData.images;
    const params = useParams();
    const { sortDown, sortUp, sortIcon, arrowDown } = localData.svgs;

    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSortClick = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    useEffect(() => {
        setConversionsData(conversionOptions.advertiserPixelStats.value.filter(
            (item) => item.tracker_group === "0"
        ))
    }, [conversionOptions])

    const [conversionsData, setConversionsData] = useState(null)
    const [anchorConversions, setAnchorConversions] = React.useState(null);
    
    const openConversions = Boolean(anchorConversions);
    const handleClickConversions = (event) => {
        setAnchorConversions(event.currentTarget);
    };
    const handleCloseConversions = (item) => {
        if(item === "close"){
            return setAnchorConversions(null)
        };
        setSlectedConversionTag(item)
        setAnchorConversions(null)
    };

    const geoArray = Object.entries(geo)
        .map(([key, value]) => ({
            country: key,
            ...value,
        }))
        .sort((a, b) => {
            if (!sortField) return 0; // No sorting
            const fieldA = a[sortField];
            const fieldB = b[sortField];

            if (fieldA == null || fieldB == null) return 0;

            if (sortOrder === "asc") {
                return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            }
            return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
        });

        const getSortIcon = (type) => {
            if (sortField !== type) {
              return <span className="sort-btn">{sortIcon}</span>;
            }
            return (
              <span className="sort-btn active">
                {sortOrder === "desc" ? sortDown : sortUp}
              </span>
            );
          };

    return (
        <div className="zones-wrapper">
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.campaignStatisticsLoading ? (
                    <TableSkeleton
                        message={`Processing data on server - please wait... `}
                        icon={textPreloader}
                    />
                ) : (
                    <table id="geo-table" className="table geo-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-hash text-start">Country Code</th>
                                <th
                                    className="table-th-age text-center clickable"
                                    onClick={() => handleSortClick("impressions")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions {getSortIcon("impressions")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-from text-center clickable"
                                    onClick={() => handleSortClick("clicks")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks {getSortIcon("clicks")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("ctr")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={`How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR {getSortIcon("ctr")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("spent")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={`How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend {getSortIcon("spent")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("cpc")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC {getSortIcon("cpc")}
                                    </span>
                                </th>
                                <th className="table-th-conversions text-center"
                                    style={{ width: 0 }}
                                    >
                                    <span
                                        data-tooltip-id="tooltip-table-conversions"
                                        data-tooltip-content={`Total amount of the conversions.`}
                                        className="with-sort"
                                        style={{maxHeight: "30px"}}
                                    >
                                        <span
                                        id="basic-button"
                                        aria-controls={openConversions ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openConversions ? 'true' : undefined}
                                        onClick={handleClickConversions}
                                        style={{
                                            display: "flex", // Aligns text and icon in a row
                                            alignItems: "center", // Centers them vertically
                                            gap: "5px", // Adds small space between text and icon
                                            color: "black",
                                            cursor: "pointer",
                                        }}
                                        >
                                        Conversions
                                        </span>
                                        <span className="sort-btn">{arrowDown}</span>
                                        <Menu
                                        id="basic-menu"
                                        anchorEl={anchorConversions}
                                        open={openConversions}
                                        onClose={() => handleCloseConversions("close")}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        >
                                            <MenuItem
                                                value={"all"}
                                                onClick={() => handleCloseConversions("all")}
                                                sx={{background: selectedConversionTag === "all" ? "lightgray": "none"}}
                                            >
                                                All
                                            </MenuItem>
                                            {(conversionsData || []).map((item, index) => (
                                            <MenuItem
                                                value={parseFloat(item.trackerid)}
                                                key={index}
                                                onClick={()=> handleCloseConversions(item.trackerid)}
                                                sx={{background: selectedConversionTag === item.trackerid ? "lightgray": "none" }}
                                            >
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Menu>
                                        <span
                                        className="sort-btn"
                                        onClick={() => handleSortClick("conversion")}
                                        >
                                        {getSortIcon("conversion")}
                                        </span>
                                    </span>
                                    <Tooltip
                                        id="tooltip-table-conversions"
                                        className="custom-tooltip"
                                    />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!geoArray.length ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                geoArray.map((item, index) => {
                                    if (
                                        (params?.id === "8256340" &&
                                            item.country === "United States (US)") ||
                                        (params?.id === "8256340" &&
                                            item.country === "Anonymous Proxy (A1)")
                                    )
                                        return null;
                                    return <Row key={index} item={item} index={index} />;
                                })
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

const Row = ({ item, index }) => {
    const { formatNumber, formatCurrency, addCommas } = useFormatter();
    const params = useParams();
    const { requestedData,selectedConversionTag } = useGlobalContext();

    return (
        <>
            <tr className="table-row" style={{background: item.country === "Total" && "lightgray"}}>
                <td className="table-td-transaction text-start" style={{fontWeight: item.country === "Total" && "bold"}}>
                    {params?.id === "8256340" && item.country === "Mexico (MX)"
                        ? "United States (US)"
                        : item.country}
                </td>
                <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
                <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>
                <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
                <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
                <td className="table-td-conversion text-center">
                    {selectedConversionTag === "all"
                        ? addCommas(parseFloat(item.conversions?.toFixed(2))) || 0
                        : item.tagConversions?.length
                        ? addCommas(
                            parseFloat(
                            item.tagConversions?.find(tag => tag.tagID === selectedConversionTag)?.conversionCount ?? 0
                            )                
                        )
                        : 0}
                </td>
            </tr>
        </>
    );
};
