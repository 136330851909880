import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field, Loading } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';

export default function Invite({}) {
  const {
    getInviteUser,
    getInvitedUsers,
    isLoading,
    requestedData,
    successAlert,
    errorAlert,
    invitedUsers,
    deleteOrgInvite
  } = useGlobalContext();
  const { preloaderBig } = localData.images;

  const [state, setState] = useState({
    inviteeEmail: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getInviteUser(state.inviteeEmail, () => setState({ inviteeEmail: "" }));
    // getInviteUser({...state,profile_image: profileImage})
  };

  useEffect(() => {
    if (requestedData.userProfileData.currentOrganization.id) {
      getInvitedUsers();
    }
  }, [requestedData.userProfileData.currentOrganization.id]);

  const handleRemoveInvite = (userEmail) => {
      console.log(userEmail, "handleRemoveInvite");
      deleteOrgInvite(userEmail)
  }

  return (
    <div className="invite">
        <div className="form-header">
          <h2 className="form-title">Invite by email</h2>
          <p className="form-description">
            Add mail to invite user to this organization
          </p>
        </div>
        <div className="form-header">
          <br />
          {invitedUsers.isLoading ? <CircularProgress color="success" /> : <TableContainer component={Paper} sx={{ maxWidth: 450 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invited User</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitedUsers.data.length ? (invitedUsers.data || []).map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip label="Pending" size="small" sx={{background: "#f5a731", color: "white"}}/>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                          className="submit-btn"
                          name="remove"
                          color="danger"
                          variant="contained"
                          size="sm"
                          style={{borderRadius: "20px", marginTop: "0"}}
                          onClick={() => handleRemoveInvite(item)}
                          icon={
                            invitedUsers.isLoading ? (
                              <span className="endIcon">
                                <img src={preloaderBig} />
                              </span>
                            ) : null
                          }
                        />
                    </TableCell>
                  </TableRow>
                )): <h4 style={{padding: "2rem"}}>No invites</h4>}
              </TableBody>
            </Table>
          </TableContainer> }
         
        </div>
        <br />
        <br />
        <form className="form" onSubmit={handleSubmit}>
        <div style={{ maxWidth: "280px" }}>
          <Field
            name="inviteeEmail"
            value={state.inviteeEmail}
            type="email"
            label="Email"
            color="secondary"
            placeholder="eg: mail@blockchain-ads.com"
            required={true}
            size="sm"
            callback={handleOnChange}
          />
        </div>

        <Button
          className="submit-btn"
          name="Invite"
          color="primary"
          variant="contained"
          size="sm"
          disabled={state.inviteeEmail == "" || isLoading.inviteUserIsLoading}
          icon={
            isLoading.inviteUserIsLoading ? (
              <span className="endIcon">
                <img src={preloaderBig} />
              </span>
            ) : null
          }
        />
      </form>
    </div>
  );
}
