import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import {
  Button,
  TableSkeleton,
  ConfirmationDialog,
  Pagination,
} from "../../../components";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase";
import useFormatter from "../../../hooks/useFormatter";
import { toast } from "react-toastify";

export default function Payouts() {
  const { getPublisherPayouts, approvePayout, rejectPayout, paidPayout } =
    useFetch();
  const [publisherPayouts, setPublisherPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [data, setData] = useState(null);
  const { addCommasKeepMinus, formatDate } = useFormatter();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const sortAndPaginate = (data) => {
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(a.created_at || a.createdAt || 0);
      const dateB = new Date(b.created_at || b.createdAt || 0);
      return dateB - dateA;
    });

    setData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    return sortedData;
  };

  const setItems = (data) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    setPublisherPayouts(data.slice(startIndex, endIndex));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!auth.currentUser) return;
        const raw = {
          uuid: auth.currentUser.uid,
        };
        const result = await getPublisherPayouts(() => {}, raw);
        const sortedData = sortAndPaginate(result.res_data);
        // Set the first 10 items initially
        setItems(sortedData);
      } catch (error) {
        console.error("Error fetching publisher payouts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty depend
  const { textPreloader } = localData.images;
  const openModal = (type, item) => {
    setShowModal(true);
    setActionType(type);
    setCurrentItem(item);
  };
  const closeModal = () => {
    setShowModal(false);
    setActionType(null);
    setCurrentItem(null);
  };

  const handlePagination = (nextPage = 1) => {
    setPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setPublisherPayouts(data.slice(startIndex, endIndex));
  };

  const handleConfirm = async () => {
    if (!auth.currentUser) return;
    let raw = {
      uuid: auth.currentUser.uid,
    };
    setIsLoading(true);
    closeModal();

    let data = {
      withdrawalId: currentItem.id,
      email: undefined,
      amount: undefined,
      publisherId: currentItem.user_id,
      uuid: auth.currentUser.uid,
      type: currentItem.type,
    };
    if (currentItem.type == "referral") {
      data.payoutId = currentItem.docId;
    }
    try {
      let res;
      if (actionType === "approve") {
        res = await approvePayout(() => {}, data);
      }
      if (actionType === "decline") {
        data.amount = currentItem.amount;
        data.email = currentItem.email_address || currentItem.email;
        res = await rejectPayout(() => {}, data);
      }
      if (actionType === "paid") {
        data.amount = currentItem.amount;
        data.email = currentItem.email_address || currentItem.email;
        res = await paidPayout(() => {}, data);
      }
      if (!res || res?.res_sts == false) {
        toast.error(res?.res_msg || "Oops looks like something went wrong");
      } else {
        toast.success("Payout updated");
      }
      const result = await getPublisherPayouts(() => {}, raw);
      const sortedData = sortAndPaginate(result.res_data);
      setItems(sortedData);
      setIsLoading(false);
    } catch (e) {
      toast.error(
        e?.response?.data?.res_msg || "Oops looks like something went wrong"
      );
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <>
      <div
        className="modal-dialog-scrollable accounts modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">PAYOUTS</h5>
        </div>
        <div className="scroll">
          {isLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 0 }}>Type</th>
                  <th style={{ width: 0 }}>Name</th>
                  <th style={{ width: 0 }}>Email Address</th>
                  <th style={{ width: 0 }}>Amount</th>
                  <th style={{ width: 0 }}>Created At</th>
                  <th style={{ width: 0 }}>Status</th>
                  <th style={{ width: 0 }}>External ID</th>
                  <th style={{ width: 0 }}>TX Hash</th>
                  <th style={{ width: 0 }}>Address</th>
                  <th style={{ width: 0, textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!publisherPayouts.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  publisherPayouts.map((item, index) => {
                    return (
                      <tr>
                        <td className="capitalize">{item.type}</td>
                        <td>{item.contact_name || item.name}</td>
                        <td>{item.email_address || item.email}</td>
                        <td>${addCommasKeepMinus(item.amount) || 0}</td>
                        <td>
                          {formatDate(
                            new Date(item.created_at || item.createdAt)
                          )}
                        </td>
                        <td className="table-td-status">
                          <div
                            data-tooltip-id="status-tooltip"
                            className={`status ${
                              item.payment_status || item.status
                            }`}
                          >
                            {item.payment_status || item.status}
                          </div>
                        </td>
                        <td>{item.external_id || item.externalId}</td>
                        <td>{item.txid}</td>
                        <td>{item.wallet_address || item.address}</td>
                        <td>
                          {item.payment_status === "pending" ||
                          item.status === "pending" ? (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="sm"
                                onClick={() => openModal("approve", item)}
                              >
                                Approve
                              </Button>
                              <Button
                                style={{ marginLeft: 20 }}
                                variant="outlined"
                                color="secondary"
                                size="sm"
                                onClick={() => openModal("paid", item)}
                              >
                                Mark as paid
                              </Button>
                              <Button
                                style={{ marginLeft: 20 }}
                                variant="outlined"
                                color="danger"
                                size="sm"
                                onClick={() => openModal("decline", item)}
                              >
                                Decline
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        {!publisherPayouts.length ? (
          ""
        ) : (
          <div className={`pagination-wrapper  ${isLoading ? "on-hold" : ""}`}>
            <Pagination
              callback={handlePagination}
              currentPage={page}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </div>
        )}
        <br />
        <br />
      </div>
      <br />
      <br />
      <ConfirmationDialog
        isOpen={showModal}
        onClose={closeModal}
        onConfirm={handleConfirm}
        message="Are you sure you want to proceed?"
      />
    </>
  );
}
