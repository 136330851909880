import React, { useState, useEffect, useRef } from "react";
import {
  Field,
  Button,
  ReferralBanner,
  TableSkeleton,
} from "../../../components";
import localData from "../../../localData";
import useUtil from "../../../hooks/useUtil";
import { Tooltip } from "react-tooltip";
import ReferralTable from "../ReferralTable";
import useFetch from "../../../hooks/useFetch";
import { useGlobalContext } from "../../../context";
import { auth } from "../../../config/firebase";
import { CircularProgress } from "@mui/material";

const ReferralField = ({ label, name = "field", value }) => {
  // const [fieldValue, setFieldValue] = useState(value);
  const [isCopied, setIsCopied] = useState();

  const fieldRef = useRef(null);
  const { copyText } = useUtil();

  const { copy, check } = localData.svgs;

  const handleCopyText = async () => {
    if (isCopied) return;
    const success = await copyText(fieldRef.current);

    if (!success) return;
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Field
      type="text"
      label={label}
      color="secondary"
      required={true}
      size="sm"
      name={name}
      value={value}
      // callback={handleOnChange}
      readOnly={true}
      _ref={fieldRef}
      className="horizontal"
    >
      <Button
        tabIndex={-1}
        onClick={handleCopyText}
        className="field-endIcon"
        icon={isCopied ? check : copy}
        variant="circle"
        size="sm"
        color="secondary"
        type="button"
        tooltipContent={isCopied ? "Copied!" : "Copy Address"}
      />
    </Field>
  );
};

export default function ReferralProgram({ details, loading, fetchData }) {
  const { createUserFromReferral } = useFetch();

  const {
    bannerTransparent,
    bannerDark,
    bannerTransparentV2,
    bannerDarkV2,
    bannerTransparentHorizontal,
    bannerDarkHorizontal,
  } = localData.images;
  const { textPreloader } = localData.images;
  const [inviteEmail, setInviteEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [open, setOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { successAlert, errorAlert } = useGlobalContext();
  const handleSubmit = async () => {
    setButtonLoading(true);
    try {
      if (!auth.currentUser) return;
      const raw = {
        uuid: auth.currentUser.uid,
        email: inviteEmail,
      };
      const result = await createUserFromReferral(() => {}, raw);
      if (result.res_sts) {
        successAlert(result.res_msg);
        setInviteEmail("");
        fetchData();
      } else {
        errorAlert(result.msg || "Error creating a user");
        setInviteEmail("");
      }
    } catch (error) {
      errorAlert(error.message || "Error creating a user");
      console.error("Error fetching sending invite:", error);
    } finally {
      setButtonLoading(false);
    }
  };
  const handleOnChange = (e) => {
    const { value } = e.target || e;
    setValidEmail(isValidEmail(value));
    setInviteEmail(value);
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const headers = [
    {
      header: "Email",
      prop: "email",
    },
    {
      header: "Invite Status",
      prop: "inviteAccepted",
      type: "boolean",
    },
    {
      header: "Accepted Date",
      prop: "acceptedAt",
      type: "date",
    },
  ];
  return (
    <>
      {loading ? (
        <TableSkeleton message="Loading..." icon={textPreloader} />
      ) : (
        <div className="referral-program">
          <div className="flex-row">
            <div className="flex-item">
              <h2 className="display-2">Your Referral Link</h2>
              <br />
              <ReferralField
                label="Referral Link:"
                name="referralLink"
                value={`https://hub.blockchain-ads.com/sign-up?ref=${details?.user?.referralCode}`}
              />
              <br />

              {/* <p style={{ maxWidth: "90%" }} className="text-style-1">
                OR, link to any page on the blockchain-ads.com website with the
                query string: ?ref=
                {details?.user?.referralCode}
              </p>
              <p style={{ maxWidth: "90%" }} className="text-style-1">
                For example, to link to the home page use:
                https://hub.blockchain-ads.com/sign-up?ref=
                {details?.user?.referralCode}
              </p> */}
              <h2 className="display-2">Invite a friend as a referral</h2>
              <br />
              <Field
                type="text"
                label="Email address"
                color="secondary"
                required={true}
                size="sm"
                callback={handleOnChange}
                value={inviteEmail}
                className="refferal-field"
                placeholder="user@email.com"
                errorMessage={
                  !validEmail
                    ? `Invalid Email. Please enter a valid Email. e.g.
                <br /> user@email.com`
                    : ""
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="xs"
                  className={`wallet-btn`}
                  onClick={handleSubmit}
                  disabled={
                    inviteEmail.length === 0 || !validEmail || buttonLoading
                  }
                >
                  Invite{" "}
                  {buttonLoading ? (
                    <div className="create-account-loading-spinner">
                      <CircularProgress className="white" />
                    </div>
                  ) : (
                    ""
                  )}
                </Button>
              </Field>

              <p style={{ maxWidth: "90%" }} className="text-style-1">
                When you refer someone by email:
              </p>
              <ul style={{ maxWidth: "90%" }} className="text-style-1">
                <li>They get an email to set up their account.</li>
                <li>You’re added to their account as the referrer.</li>
              </ul>

              <h3 className="display-2">How Referrals Works:</h3>
              <p className="text-style-1">
                Simply refer by using the link or create an account for your
                referrals, every new sign-up you refer will be tracked. You’ll
                earn {details?.user?.referralCommission || 10}% of their total
                spend, which will be calculated weekly. Your commission can be
                withdrawan at any time from the payouts page. Start referring
                and earn as they spend!
              </p>
              <p className="block-share text-style-1">
                Earn {details?.user?.referralCommission || 10}% recurring
                commissions by referring clients to Blockchain-Ads!
              </p>
            </div>

          </div>
          <br />
          <br />
          <div className="flex-item">
              <div className="reports">
                <h2 className="display-2">Referred Users: </h2>
                <br />
                <ReferralTable
                  allRows={details?.invites}
                  isLoading={false}
                  headers={headers}
                />
              </div>
            </div>
          <br />
          <br />
        </div>
      )}
    </>
  );
}
