import React from "react";
import {
  Typography,
  Box,
  Card,
  Divider,
  Link,
  List,
  ListItem,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { format } from "date-fns";
import useFormatter from "../../../hooks/useFormatter";
import { useNavigate } from "react-router-dom";

const TransactionHistory = ({ paymentHistory = [] }) => {
  const { formatNumber } = useFormatter();

  const sortedTransactions = [...paymentHistory].sort((a, b) => {
    const getDateValue = (item) => {
      if (item.date && item.date !== "N/A") {
        const [day, month, year] = item.date.split("/").map(Number);
        return new Date(year, month - 1, day);
      }
      return new Date(0); // Push "N/A" to the bottom
    };

    return getDateValue(b) - getDateValue(a); // Sort newest first
  });

  const latestTransactions = sortedTransactions.slice(0, 3);

  console.log(latestTransactions);

  const formatDate = (dateString) => {
    try {
      if (!dateString) return "N/A";

      const date = new Date(dateString);

      if (isNaN(date.getTime())) {
        const [datePart] = dateString.split("T");
        if (datePart && datePart.includes("-")) {
          const [year, month, day] = datePart.split("-");
          return `${year}/${month}/${day}`;
        }
        return "N/A";
      }

      return format(date, "yyyy/MM/dd");
    } catch (error) {
      console.error("Error formatting date:", error);
      try {
        if (dateString && dateString.length > 10) {
          const datePart = dateString.substring(0, 10);
          return datePart.replace(/-/g, "/");
        }
      } catch (e) {}
      return "N/A";
    }
  };

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        borderRadius: "8px",
        backgroundColor: "white",
        color: "black",
        p: 2,
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          Latest Deposits
        </Typography>
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#2e2680",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{ mr: 0.5, fontSize: "0.75rem" }}
            onClick={() => navigate("/billing")}
          >
            View All
          </Typography>
          <ChevronRightIcon sx={{ fontSize: "1rem" }} />
        </Link>
      </Box>

      <Divider sx={{ mb: 1 }} />

      <List sx={{ p: 0 }}>
        {latestTransactions.length > 0 ? (
          latestTransactions.map((transaction, index) => (
            <ListItem
              key={index}
              sx={{
                px: 0,
                py: 0.75,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom:
                  index < latestTransactions.length - 1
                    ? "1px solid #f0f0f0"
                    : "none",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    backgroundColor: "#e8f5e9",
                    color: "#4caf50",
                    px: 1,
                    py: 0.25,
                    borderRadius: "12px",
                    fontSize: "0.65rem",
                    fontWeight: 500,
                    mr: 1,
                  }}
                >
                  Completed
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.75rem" }}
                >
                  {transaction.date}
                </Typography>
              </Box>

              <Typography
                variant="body2"
                fontWeight={500}
                sx={{
                  color: transaction.amount > 0 ? "#4caf50" : "#f44336",
                  fontSize: "0.8rem",
                }}
              >
                {transaction.amount > 0 ? "+" : "-"} €
                {formatNumber(Math.abs(transaction.amount))}
              </Typography>
            </ListItem>
          ))
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ py: 1, fontSize: "0.75rem" }}
          >
            No transactions found.
          </Typography>
        )}
      </List>
    </Card>
  );
};

export default TransactionHistory;
