import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Divider,
  TextField,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  LinearProgress,
  useTheme,
  OutlinedInput,
  ListItemText,
  Checkbox,
  CircularProgress,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  ArrowBack,
  CalendarToday,
  FilterList,
  GetApp,
  Share,
  Search,
  MoreVert,
  Info,
  CheckBox,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Chart } from "react-google-charts";
import { useResizeDetector } from "react-resize-detector";
import CampaignsGraph from "../campaigns/campaigns-graph/CampaignsGraph";
import { Calendar, LineChart, VerticalComposedChart } from "../../components";
import useFormatter from "../../hooks/useFormatter";
import useFetch from "../../hooks/useFetch";
import { useGlobalContext } from "../../context";
import ReportsGraph from "./ReportsGraph";
import EnhancedPerformanceTable from "./EnhancedPerformanceTable";
import Blockchains from "../campaigns/blockchains/Blockchains";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CampaignAccordion from "./Campaings";
import PerformanceDropdown from "./components/PerformanceDropdown";
import ExportDropdown from "./components/ExportDropdown";
import handleExportToCSV from "./components/handleExportToCSV";
import { useRef } from "react";
import { PDFDownloader } from "./components/CampaignReportPDF";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    top: 0,
    bottom: "auto",
    height: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  "& .MuiTabs-flexContainer": {
    borderBottom: "none",
  },
  "& .MuiTab-root": {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "0.9rem",
    borderRadius: "8px 8px 0 0",
    border: `1px solid transparent`,
    "&.Mui-selected": {
      color: theme.palette.text.primary,
      backgroundColor: "#ffffff",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "&:not(.Mui-selected)": {
      color: theme.palette.text.secondary,
      backgroundColor: "#F7F7F8",
      border: "1px solid #e0e0e0",
    },
  },
}));

const DropdownIcon = () => (
  <Box
    component="span"
    sx={{ display: "inline-flex", ml: 0.5, alignItems: "center" }}
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 10l5 5 5-5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

const ReportsPage = () => {
  const formatDate = useFormatter();
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 6);
  const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
  };
  const {
    requestedData: {
      userProfileData,
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    requestedData,
    markedCampaignReports,
    setMarkedCampaignsReports,
    selectedCampaigns,
    setSelectedCampaigns,
    campaignSelectionRange,
    setCampaignSelectionRange,
  } = useGlobalContext();

  const [searchTerm, setSearchTerm] = useState("");
  const [timeRange, setTimeRange] = useState("month");
  const [tabValue, setTabValue] = useState(0);
  const [selectedMetric, setSelectedMetric] = useState("ctr");
  const { getReporting } = useFetch();
  const [campaignData, setCampaignData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const campaigns = requestedData.campaignData.currentOrganizationCampaigns;

  useEffect(() => {
    if (selectedCampaigns.length) return;
    const marked = currentOrganizationCampaigns.filter((campaign) =>
      campaigns.some(
        (markedCampaignReports) => markedCampaignReports.id === campaign.id
      )
    );
    setSelectedCampaigns(marked.slice(0, 1));
    setMarkedCampaignsReports(marked);
  }, [currentOrganizationCampaigns]);

  function formatDateToDDMMYYYY(date) {
    if (!(date instanceof Date) || isNaN(date)) {
      return "";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const fetchData = async () => {
    setIsLoading(true);
    const raw = {
      dateFrom: formatDateToDDMMYYYY(campaignSelectionRange.startDate),
      dateTill: formatDateToDDMMYYYY(campaignSelectionRange.endDate),
      organizationId: userProfileData.currentOrganization.id,
      campaignIds: selectedCampaigns.map((item) => item.id),
    };
    try {
      const response = await getReporting(() => {}, raw);
      setCampaignData(response.data.current);
    } catch (error) {
      console.error("Error loading data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    markedCampaignReports,
    userProfileData,
    campaignSelectionRange,
    selectedCampaigns,
  ]);

  const formatNumber = (num) => {
    return num !== undefined ? num.toLocaleString() : "0";
  };

  const formatCurrency = (num) => {
    return num !== undefined
      ? `$${num.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : "$0.00";
  };

  const formatPercentage = (num) => {
    return num !== undefined
      ? `${num.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}%`
      : "0.00%";
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getCurrentTabData = () => {
    if (!campaignData || Object.keys(campaignData).length === 0) return [];
    console.log(campaignData.ads, "campaignData123123");

    switch (tabValue) {
      case 0:
        return Object.entries(campaignData.audiences || {})
          .map(([key, value]) => ({
            name: key,
            impressions: value.impressions || 0,
            clicks: value.clicks || 0,
            ctr: calculateCTR(value.clicks, value.impressions),
            adSpend: value.spent || 0,
            cpc: calculateCPC(value.spent, value.clicks),
            conversions: value.conversions || 0,
          }))
          .sort((a, b) => b.impressions - a.impressions);

      case 1:
        return Object.entries(campaignData.ads || {}).map(([key, value]) => ({
          name: value.title || key.substring(0, 20) + "...",
          impressions: value.impressions || 0,
          clicks: value.clicks || 0,
          ctr: calculateCTR(value.clicks, value.impressions),
          adSpend: value.spent || 0,
          cpc: calculateCPC(value.spent, value.clicks),
          conversions: value.conversions || 0,
          image: key,
        }));

      case 2:
        return Object.entries(campaignData.geos || {}).map(([key, value]) => ({
          name: key,
          code: key,
          impressions: value.impressions || 0,
          clicks: value.clicks || 0,
          ctr: calculateCTR(value.clicks, value.impressions),
          adSpend: value.spent || 0,
          cpc: calculateCPC(value.spent, value.clicks),
          conversions: value.conversions || 0,
        }));

      case 3:
        return Object.entries(campaignData.zones || {}).map(([key, value]) => ({
          name: key,
          impressions: value.impressions || 0,
          clicks: value.clicks || 0,
          ctr: calculateCTR(value.clicks, value.impressions),
          adSpend: value.spent || 0,
          cpc: calculateCPC(value.spent, value.clicks),
          conversions: value.conversions || 0,
        }));
      default:
        return [];
    }
  };

  const calculateCTR = (clicks, impressions) => {
    return impressions > 0 ? (clicks / impressions) * 100 : 0;
  };

  const calculateCPC = (spent, clicks) => {
    return clicks > 0 ? spent / clicks : 0;
  };

  const getSummaryData = () => {
    if (!campaignData || Object.keys(campaignData).length === 0) {
      return {
        impressions: 0,
        clicks: 0,
        ctr: 0,
        adSpend: 0,
        cpc: 0,
        conversions: 0,
      };
    }

    return {
      impressions: campaignData.impressions || 0,
      clicks: campaignData.clicks || 0,
      ctr: campaignData.CTR || 0,
      adSpend: campaignData.amount_spent || 0,
      cpc: campaignData.CPC || 0,
      conversions: campaignData.conversions || 0,
    };
  };

  const summaryData = getSummaryData();

  const handleCalendar = (range) => {
    const { startDate, endDate } = range;
    setCampaignSelectionRange(range);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteChip = (campaignToDelete) => {
    const newSelected = selectedCampaigns.filter(
      (campaign) => campaign.id !== campaignToDelete.id
    );

    setSelectedCampaigns(newSelected);
    setMarkedCampaignsReports(newSelected);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedValues = typeof value === "string" ? value.split(",") : value;

    const selectedCampaignObjects = currentOrganizationCampaigns.filter(
      (campaign) => selectedValues.includes(campaign.id)
    );

    setSelectedCampaigns(selectedCampaignObjects);
    setMarkedCampaignsReports(selectedCampaignObjects);
  };

  const filteredCampaigns = currentOrganizationCampaigns.filter((campaign) =>
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const containerStyle = {
    boxShadow: "none",
    border: "none",
    borderRadius: 8,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [geoOption, setGeoOption] = useState("Geo");

  const handleGeoOptionSelect = (option) => {
    setGeoOption(option);
    setTabValue(2);
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGeoTabClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getAllTabsData = () => {
    if (!campaignData || Object.keys(campaignData).length === 0) {
      return {
        audiences: [],
        ads: [],
        geo: [],
        publishers: [],
      };
    }

    const audiences = Object.entries(campaignData.audiences || {}).map(
      ([key, value]) => ({
        name: key,
        impressions: value.impressions || 0,
        clicks: value.clicks || 0,
        ctr: calculateCTR(value.clicks, value.impressions),
        adSpend: value.spent || 0,
        cpc: calculateCPC(value.spent, value.clicks),
        conversions: value.conversions || 0,
      })
    );

    const ads = Object.entries(campaignData.ads || {}).map(([key, value]) => ({
      name: value.title || key.substring(0, 20) + "...",
      impressions: value.impressions || 0,
      clicks: value.clicks || 0,
      ctr: calculateCTR(value.clicks, value.impressions),
      adSpend: value.spent || 0,
      cpc: calculateCPC(value.spent, value.clicks),
      conversions: value.conversions || 0,
    }));

    const geo = Object.entries(campaignData.geos || {}).map(([key, value]) => ({
      name: key,
      code: key,
      impressions: value.impressions || 0,
      clicks: value.clicks || 0,
      ctr: calculateCTR(value.clicks, value.impressions),
      adSpend: value.spent || 0,
      cpc: calculateCPC(value.spent, value.clicks),
      conversions: value.conversions || 0,
    }));

    const publishers = Object.entries(campaignData.zones || {}).map(
      ([key, value]) => ({
        name: key,
        impressions: value.impressions || 0,
        clicks: value.clicks || 0,
        ctr: calculateCTR(value.clicks, value.impressions),
        adSpend: value.spent || 0,
        cpc: calculateCPC(value.spent, value.clicks),
        conversions: value.conversions || 0,
      })
    );

    return { audiences, ads, geo, publishers };
  };

  const pdfExportRef = useRef(null);

  // Export functions
  const handleExportPDF = () => {
    if (pdfExportRef.current) {
      const link = pdfExportRef.current.querySelector("a");
      if (link) link.click();
    }
  };

  const handleExportCSV = () => {
    handleExportToCSV(campaignData, getAllTabsData, selectedCampaigns[0]);
  };

  return (
    <div>
      <div className="campaign-page-header-reports">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", maxWidth: "330px" }}
          >
            <Box sx={{ minWidth: "330px" }}>
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel id="campaign-multiselect-label" size="small">
                  Select Campaigns
                </InputLabel>
                <Select
                  labelId="campaign-multiselect-label"
                  id="campaign-multiselect"
                  multiple
                  size="small"
                  value={(selectedCampaigns || []).map(
                    (campaign) => campaign.id
                  )}
                  onChange={handleChange}
                  input={
                    <OutlinedInput label="Select Campaigns" size="small" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        maxWidth: "100%",
                        marginTop: "5px",
                      }}
                    >
                      {selected.slice(0, 2).map((value) => {
                        const campaign = currentOrganizationCampaigns.find(
                          (c) => c.id === value
                        );
                        return campaign ? (
                          <Chip
                            key={value}
                            label={campaign.name}
                            size="small"
                            deleteIcon={<CancelIcon style={{ fontSize: 16 }} />}
                            onDelete={() => handleDeleteChip(campaign)}
                            sx={{
                              maxWidth: "120px",
                              "& .MuiChip-label": {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                          />
                        ) : null;
                      })}
                      {selected.length > 2 && (
                        <Chip
                          label={`+${selected.length - 2}`}
                          size="small"
                          sx={{
                            backgroundColor: "#e0e0e0",
                            "& .MuiChip-label": {
                              fontWeight: 500,
                            },
                          }}
                        />
                      )}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 350,
                      },
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                  }}
                >
                  <MenuItem
                    disableRipple
                    disableTouchRipple
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1,
                      padding: "8px",
                    }}
                  >
                    <TextField
                      placeholder="Search..."
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onClick={(e) => e.stopPropagation()}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon
                            fontSize="small"
                            sx={{ color: "#757575", mr: 1 }}
                          />
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "#4b4b4b",
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </MenuItem>

                  {filteredCampaigns.map((campaign) => (
                    <MenuItem
                      key={campaign.id}
                      value={campaign.id}
                      sx={{ paddingY: 1 }}
                    >
                      <Checkbox
                        checked={selectedCampaigns.some(
                          (selected) => selected.id === campaign.id
                        )}
                      />
                      <Tooltip title={campaign.name} arrow>
                        <ListItemText
                          primary={campaign.name}
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingRight: "10px",
                            },
                          }}
                        />
                      </Tooltip>
                    </MenuItem>
                  ))}

                  {filteredCampaigns.length === 0 && (
                    <MenuItem disabled>
                      <ListItemText primary="No campaigns found" />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                marginLeft: "15px",
                border: "1px solid gray",
                padding: "3px",
                borderRadius: "8px",
              }}
            >
              <Calendar
                className="reports-calendar"
                _selectionRange={campaignSelectionRange}
                callback={handleCalendar}
              />
            </Box>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <ExportDropdown
              onExportCSV={handleExportCSV}
              onExportPDF={handleExportPDF}
            />
            <div style={{ display: "none" }} ref={pdfExportRef}>
              <PDFDownloader
                campaignData={campaignData}
                allTabsData={getAllTabsData()}
                selectedPeriod={campaignSelectionRange}
                selectedCampaing={selectedCampaigns[0]}
              />
            </div>
          </Box>
        </Box>
      </div>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress
              size={70}
              sx={{ marginTop: "10%", color: "#2aab7e" }}
            />
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 700, mb: 2 }}>
              Campaign Insights
            </Typography>
            <ReportsGraph selectedCampaigns={selectedCampaigns} />

            <br />
            <br />
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 700 }}>
              All Campaigns
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1, maxWidth: "800px", ...containerStyle }}>
                <CustomTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  aria-label="navigation tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#1976d2",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      height: 4,
                    },
                  }}
                >
                  <Tab label="Audiences" />
                  <Tab label="Ads" />
                  <Tab label="Geo" />
                  <Tab label="Publishers" />
                </CustomTabs>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={() => handleGeoOptionSelect("Geo")}>
                    Geo
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleGeoOptionSelect("Geo Performance")}
                  >
                    Geo Performance
                  </MenuItem>
                </Menu>
              </Box>
              <Box>
                <PerformanceDropdown />
              </Box>
            </Box>
            <Paper>
              <EnhancedPerformanceTable
                data={getCurrentTabData()}
                formatNumber={formatNumber}
                formatPercentage={formatPercentage}
                formatCurrency={formatCurrency}
                tabValue={tabValue}
              />
              <br />
              <br />
            </Paper>
            <br />
            <br />
            {tabValue === 2 && (
              <GeoChartWithTable geoData={getCurrentTabData()} />
            )}
            <br />

            <br />
            <Blockchains />

            <CampaignAccordion data={currentOrganizationCampaigns} />
          </Box>
        )}
      </Container>
    </div>
  );
};

const GeoChartWithTable = ({ geoData = [] }) => {
  const theme = useTheme();
  const { width, ref } = useResizeDetector();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const formatGeoChartData = () => {
    return [
      [
        "Country",
        "Impressions",
        { role: "tooltip", type: "string", p: { html: true } },
      ],
      ...geoData.map((country) => [
        country.code,
        country.impressions,
        createTooltipHtml(country),
      ]),
    ];
  };

  const createTooltipHtml = (country) => {
    return `<div style="padding: 10px; min-width: 200px; font-family: Arial, sans-serif;">
      <div style="font-weight: bold; font-size: 14px; margin-bottom: 6px;">${
        country.name
      }</div>
      <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
        <tr>
          <td style="padding: 3px 0;">Impressions:</td>
          <td style="text-align: right; padding: 3px 0;">${country.impressions.toLocaleString()}</td>
        </tr>
        <tr>
          <td style="padding: 3px 0;">Clicks:</td>
          <td style="text-align: right; padding: 3px 0;">${country.clicks.toLocaleString()}</td>
        </tr>
        <tr>
          <td style="padding: 3px 0;">CTR:</td>
          <td style="text-align: right; padding: 3px 0;">${country.ctr.toFixed(
            2
          )}%</td>
        </tr>
        <tr>
          <td style="padding: 3px 0;">Ad Spend:</td>
          <td style="text-align: right; padding: 3px 0;">$${country.adSpend.toFixed(
            2
          )}</td>
        </tr>
        <tr>
          <td style="padding: 3px 0;">CPC:</td>
          <td style="text-align: right; padding: 3px 0;">$${country.cpc.toFixed(
            2
          )}</td>
        </tr>
        <tr>
          <td style="padding: 3px 0;">Conversions:</td>
          <td style="text-align: right; padding: 3px 0;">${
            country.conversions
          }</td>
        </tr>
      </table>
    </div>`;
  };

  const geoChartOptions = {
    colorAxis: {
      colors: ["#2aab7e", "#1f8352"],
      minValue: 0,
    },
    backgroundColor: "#FFFFFF",
    datalessRegionColor: "#F5F5F5",
    defaultColor: "#F5F5F5",
    legend: {
      textStyle: {
        color: "#333333",
        fontSize: 12,
      },
    },
    tooltip: {
      isHtml: true,
      showTitle: false,
    },
  };

  const handleCountrySelect = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();

    if (selection.length === 0) {
      setSelectedCountry(null);
      return;
    }

    const selectedIndex = selection[0].row;
    const countryCode = formatGeoChartData()[selectedIndex + 1][0];
    const selectedCountryData = geoData.find(
      (country) => country.code === countryCode
    );

    setSelectedCountry(selectedCountryData);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={2} sx={{ p: 2, mb: 4, bgcolor: "white" }}>
        <Typography variant="h6" gutterBottom>
          Geo Performance
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Box ref={ref} sx={{ flex: 2, minHeight: "400px", width: "100%" }}>
            <Chart
              chartEvents={[
                {
                  eventName: "select",
                  callback: handleCountrySelect,
                },
              ]}
              chartType="GeoChart"
              width="100%"
              height="400px"
              data={formatGeoChartData()}
              options={geoChartOptions}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ReportsPage;
