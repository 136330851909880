import React, { useEffect, useState } from "react";
import { Drawer, DepositComponent, Sidebar } from "../index.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "../index.js";
import { useGlobalContext } from "../../context.js";
import localData from "../../localData.js";
import { auth } from "../../config/firebase.js";
import { default as ReactSelect } from "react-select";
import useFetch from "../../hooks/useFetch.js";
import { color } from "framer-motion";
import { EditCompanyInfoDialog } from "../../pages/billing/BillingPage.jsx";

export default function Navbar({ title = "", description = "", titlePopup = "", isPublic = false, mainContentRef, accountStatus = "Active" }) {
    const navigate = useNavigate()
    const location = useLocation();


    const { avatar, user, hamburgerMenu } = localData.svgs;
    const {
        showSidebar,
        setShowSidebar,
        successAlert,
        showProfileDrawer,
        setShowProfileDrawer,
        showDepositModal,
        setShowDepositModal,
        requestedData,
        requestedData: { userProfileData },
        requestedData: {
            userProfileData: {
                currentOrganization: { balance },
            },
        },
        isLoading: { userProfileIsLoading },
        navbarRef,
        formatNumber,
        isDarkModeEnabled,
        setIsDarkModeEnabled,
        handleSignOut,
        getLoadUserProfile,
        errorAlert,
        companyInfo, 
        setCompanyInfo,
        tempCompanyInfo, 
        setTempCompanyInfo,
        openCompanyInfoModal, 
        setOpenCompanyInfoModal,
        isEditCompanyLoading, 
        setIsEditCompanyloading,
        handleSaveCompanyInfo,
        handleOpenCompanyModalModal,
        handleCloseCompanyInfoModal,
        setMarkedCampaigns
    } = useGlobalContext();
    
    const { arrowDown, bars, barsClose, sun, moon, deposit, usersIcon, dolarIcon } = localData.svgs;
    const { textPreloader, logo, logoDark } = localData.images;
    const [showHamburgerDrawer, setShowHamburgerDrawer] = useState(false);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: "#ccc",
            // height: '35px',
            // minHeight: 'auto',
            borderColor: "rgba(255,255,255,.5)",
            outline: "none",
            backgroundColor: "transparent",
            color: "#fff",
            fontWeight: "400",
            "&:hover": {
                borderColor: "rgba(255,255,255,.8)",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            // backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : state.data.color || "#fff", // Use option-specific color
            backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
            // color:
            //     state.data.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
            //         ? "purple"
            //         : state.data.adminID === userProfileData.userId
            //         ? "green"
            //         : "",
            color: state.data.color,
            padding: "7px 15px",
            fontWeight: 500,
            "&:hover": {
                backgroundColor: !state.isSelected && "rgba(0,0,0,0.05)",
                color: state.data.color || "#000",
            },
        }),
        // menu: (provided) => ({
        //     ...provided,
        //     scrollbarWidth: 'thin', // For Firefox
        // }),
        menuList: (provided) => ({
            ...provided,
            padding: "0px",
            overflowY: "auto",
            scrollbarWidth: "thin",
            borderTopLeftRadius: "5px",
        }),
        singleValue: (provided, state) => {
            return {
                ...provided,
                // color:
                //     state.data.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
                //         ? "purple"
                //         : state.data.adminID === userProfileData.userId
                //         ? "green"
                //         : "",
                color: state.data.color,
                // padding: '7px 15px',
            };
        },
    };
    

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [previousItem, setPreviousItem] = useState("");
    const [isLoading, setIsLoading] = useState();

    const { switchOrganization } = useFetch();

    const handleChange = async (selectedOption) => {
        setPreviousItem(selectedItem);
        setSelectedItem({ label: "loading..." });
        try {
            await getSwitchOrganization(selectedOption.id);
            if (location.pathname === "/campaigns/create" || location.pathname === "/campaigns/edit") {
                navigate("/campaigns");
            }
            setSelectedItem(selectedOption);
            
        } catch (err) {
            setSelectedItem(previousItem);
        }
    };

        // switchOrganization
        const getSwitchOrganization = async (id) => {
            if (!auth.currentUser) return;
            // setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
            setIsLoading(true);
            const temRaw = {
                uuid: auth.currentUser.uid,
                organizationID: id,
            };
            try {
                const data = await switchOrganization(() => {}, temRaw);
                getLoadUserProfile(id);
                setMarkedCampaigns([])
            } catch (err) {
                errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
                console.error(err, "=switchOrganization= request error");
            }
            // filterAllOrganization();
            setIsLoading(false);
        };

        const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  
        useEffect(() => {
            const superAdminEmails = [
                "jack@blockchain-ads.com",
                "marko@blockchain-ads.com",
                "vlad@blockchain-ads.com",
                "contact@blockchain-ads.com",
                "rende@blockchain-ads.com"
            ];
        
            const checkSuperAdmin = () => {
                return superAdminEmails.includes(requestedData?.userProfileData?.userEmail || "");
            };
        
            setIsUserSuperAdmin(checkSuperAdmin());
        }, [requestedData]);

        

        const filterAllOrganization = () => {
            // setIsLoading(true);
    
            let array = userProfileData.allOrganization.map((item, index) => {
                return {
                    title: isUserSuperAdmin ? item.bcaName : item.name || "---Not Exist---",
                    isSelected: item.id === userProfileData.currentOrganization.id,
                    id: item.id,
                    adminID: item.adminID,
                    color:
                        item.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
                            ? // ? "purple"
                              "rgb(150, 45, 255)"
                            : item.adminID === userProfileData.userId
                            ? // ? "success"
                              "rgb(42, 171, 126)"
                            : "",
                };
            });
    
            array.sort((a, b) => {
                const titleA = a.title.toLowerCase();
                const titleB = b.title.toLowerCase();
    
                if (titleA < titleB) return -1;
                if (titleA > titleB) return 1;
                return 0;
            });
    
            setItems(array);
    
            let tempFilteredItems = array.map((item) => {
                if (item.id === userProfileData.currentOrganization.id) {
                    setSelectedItem({
                        ...item,
                        value: item.title + " - value",
                        label: item.title,
                    });
                }
                return {
                    ...item,
                    value: item.title + " - value",
                    label: item.title,
                };
            });
            setFilteredItems(tempFilteredItems);
        };
    
        useEffect(() => {
            if (!userProfileData.allOrganization.length) return;
            filterAllOrganization();
        }, [userProfileData, isUserSuperAdmin]);
        

    const DrawerComponent = ({ callbackFromParent }) => {
        const { logout } = localData.svgs;
        return (
            <>
                <div className="drawer-header">
                    <img src={userProfileData.profile_image} alt="" />
                    <p className="drawer-text">
                        Welcome to Blockchain-Ads
                        <br />{" "}
                        <strong>
                            {userProfileIsLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : (
                                userProfileData.userName
                            )}
                        </strong>
                    </p>
                </div>

                <div className="drawer-body">
                    <Link to="/profile?tab=0">
                        <Button name="My Profile" color="dark" onClick={callbackFromParent} />
                    </Link>
                    <br />
                    <Link to="/profile?tab=3">
                        <Button name="Manage Access" color="dark" onClick={callbackFromParent} />
                    </Link>

                    <br />

                    <BillingDropdown setShowHamburgerDrawer={setShowHamburgerDrawer} />
                    {/* <Button
                        name="deposit funds"
                        color="dark"
                        onClick={() => {
                            setShowDepositModal("block");
                        }}
                    /> */}

                    <Link to="/referral">
                        <Button name="Referral Program" color="dark" onClick={callbackFromParent} />
                    </Link>

                    <br />

                    <Link to="/api-page">
                        <Button name="API Keys" color="dark" onClick={callbackFromParent} />
                    </Link>

        
                    <br />
                    <br />
                    <div className="account-selection-mobile">
                        <h3>Account:</h3>
                    {!isPublic && <ReactSelect
                                id="login-tutorial-8"
                                value={selectedItem}
                                // closeMenuOnSelect={false}
                                options={filteredItems}
                                className="react-select accounts-autocomplete"
                                onChange={handleChange}
                                placeholder="Select Account"
                                menuPlacement="top"
                                styles={customStyles}
                    /> }
                    </div>

                    <br/>

                    <Button
                        name="log out"
                        color="danger"
                        variant="outlined"
                        startIcon={logout}
                        onClick={() => {
                            callbackFromParent();
                            setTimeout(() => {
                                handleSignOut();
                            }, 1000);
                        }}
                    />
                </div>

                <div className="drawer-footer">
                    <div className="wrapper">
                        <Button
                            icon={sun}
                            variant="text"
                            color="success"
                            className={`mode ${isDarkModeEnabled ? "" : "active"}`}
                            onClick={() => {
                                setIsDarkModeEnabled(false);
                                document.body.classList.remove("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", false);
                            }}
                        />
                        {/* <Button
                            icon={moon}
                            variant="contained"
                            color="dark"
                            className={`mode ${isDarkModeEnabled ? "active" : ""}`}
                            onClick={() => {
                                setIsDarkModeEnabled(true);
                                document.body.classList.add("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", true);
                            }}
                        /> */}
                    </div>
                </div>
            </>
        );
    };

    const HamburgerDrawerComponent = ({ showHamburgerDrawer, setShowHamburgerDrawer }) => {
        return (
            <>
                <Sidebar showHamburgerDrawer={showHamburgerDrawer} setShowHamburgerDrawer={setShowHamburgerDrawer} />
            </>
        );
    };

    
    return (
        <>
            <nav className={`navbar navbar-expand-lg ${isPublic ? "navbar-public" : ""}`} ref={navbarRef}>
                <div className="wrapper custom-wrapper-navigation">
                    <div className="left-side-navigation">             
                        <img src={logoDark} onClick={() => navigate("/")}/>
                        <div className="d-flex">
                        {/* <p className="accounts-title text-style-1">Account:</p> */}
                        
                        {!isPublic && <ReactSelect
                                id="login-tutorial-8"
                                value={selectedItem}
                                // closeMenuOnSelect={false}
                                options={filteredItems}
                                className="react-select accounts-autocomplete"
                                onChange={handleChange}
                                placeholder="Select Account"
                                menuPlacement="top"
                                styles={customStyles}
                        /> }

                        </div>
                  
                    </div>
                    <div className="right-side-navigation">
                    <div className="deposit-container">
                        <div className="budget dark-mode-style">
                            <p className="budget-title">
                            BALANCE
                            </p>
                            <div className="budget-balance">
                                ${balance > 0 ? formatNumber(balance?.toFixed(0) || "0") : 0}
                            </div>
                        </div>
                        <div id="login-tutorial-2">
                            <Modal
                                Child={DepositComponent}
                                className="deposit-modal modal-dialog-centered"
                                buttonClassName={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                display={!isPublic && showDepositModal}
                                setDisplay={!isPublic && setShowDepositModal}
                                Toggler={() => (
                                    <Button
                                        tooltipContent={
                                            <div>
                                                Top up your account balance to use <br /> Blockchain-Ads.
                                            </div>
                                        }
                                        name="Deposit"
                                        variant="contained"
                                        size="sm"
                                        color="primary"
                                        style={{
                                            padding: "7px 15px",
                                            border: "1px solid #1ba7c3b5 !important"
                                        }}
                                        className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                        id="login-tutorial-2"
                                        startIcon={deposit}
                                        onClick={() => {setShowDepositModal("block"); navigate("/billing/deposit")}}
                                    />
                                )}
                            ></Modal>
                                        <EditCompanyInfoDialog
              open={openCompanyInfoModal}
              onClose={handleCloseCompanyInfoModal}
              companyInfo={companyInfo}
              onSave={handleSaveCompanyInfo}
              tempInfo={tempCompanyInfo}
              setTempInfo={setTempCompanyInfo}
            />
                        </div>
                        {accountStatus !== "Inactive" ? 
                        null :
                            <Button
                                tooltipContent={
                                    <div>
                                        Acctivate your Account
                                    </div>
                                }
                                name="Activate Account"
                                style={{background: "#2aab7e"}}
                                variant="contained"
                                color="primary"
                                className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                id="login-tutorial-4"
                                onClick={() => window.location.href = "https://calendly.com/blockchain-ads-marko/30min"}
                            /> 
                        }

                        {/* <div>
                            <Link to="/profile?tab=invite">
                                <Button
                                    tooltipContent={
                                        <div>
                                            Add users to your organization
                                        </div>
                                    }
                                    name="Add users"
                                    variant="outlined"
                                    color="primary"
                                    className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                    id="login-tutorial-3"
                                    startIcon={usersIcon}
                                // onClick={() => window.loc}
                                />
                            </Link>
                        </div> */}
                    </div>
                    {!isPublic ? (
                        <Drawer
                            Component={DrawerComponent}
                            className="drawer-right"
                            display={showProfileDrawer}
                            setDisplay={setShowProfileDrawer}
                            disabled={userProfileIsLoading}
                            toggler={
                                <Button color="dark" variant="text">
                                    <div className="profile  dark-mode-style">
                                        <div className="profile-image">
                                            <img src={userProfileData.profile_image} alt="" />
                                        </div>
                                        {/* <div className="profile-name">
                                            {userProfileIsLoading ? (
                                                <img src={textPreloader} className="text-preloader" />
                                            ) : (
                                                userProfileData.userName
                                            )}
                                        </div> */}
                                        <div className="profile-icon">{arrowDown}</div>
                                    </div>
                                </Button>
                            }
                        />
                    ) : (
                        <div>
                        <Link to="/sign-up" className="sing-up-btn">
                            <Button name="sign up" variant="outlined" size="sm" style={{padding: "7px 15px",}}/>
                        </Link>
                        <Link to="/sign-in" className="sing-up-btn">
                            <Button name="sign in" variant="outlined" size="sm" style={{padding: "7px 15px",border: "1px solid #00B268", color:"#ffff", background: "#2aab7e",
                        }}/>
                        </Link>
                        </div>
                    )}

                    <div className="hamburger-menu">
                        <Drawer
                            Component={() => <HamburgerDrawerComponent showHamburgerDrawer={showHamburgerDrawer} setShowHamburgerDrawer={setShowHamburgerDrawer} />}
                            className="drawer-right drawer-sidebar"
                            display={showHamburgerDrawer}
                            setDisplay={setShowHamburgerDrawer}
                            toggler={
                                <div onClick={() => setShowHamburgerDrawer(!showHamburgerDrawer)}>
                                    {hamburgerMenu}
                                </div>
                            }
                        />
                    </div>
                    </div>
                </div>
            </nav>
        </>
    );
}


const BillingDropdown = ({ setShowHamburgerDrawer }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { setShowDepositModal, setShowProfileDrawer } = useGlobalContext();
    const navigate = useNavigate()
    
    const toggleExpand = () => {
      navigate("/billing/deposit")
      setShowProfileDrawer("none")
    };
  
    const handleDepositClick = () => {
      setShowDepositModal("block");
    };
  
    return (
      <div className="billing-dropdown">
        <div 
          className={`billing-dropdown-header ${isExpanded ? 'active' : ''}`} 
          onClick={toggleExpand}
        >
          <div className="sidebar-item d-flex" >
            <span>Billing</span>
          </div>
        </div>
      </div>
    );
  };
  