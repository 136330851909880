import React, { useEffect, useRef, useState } from "react";
import {
  Field,
  Switch,
  Button,
  MultiSelect,
  Autocomplete,
  MultiSelectSearch,
  CountriesAndBid,
  Modal,
  EstimatedResults,
  AudienceEstimate,
  OptionCard,
  ConfirmationPopup,
} from "../../../components";
import localData from "../../../localData";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import Joyride, { STATUS } from "react-joyride";
import useCampaignWizardData from "../useCampaignWizardData";
import MultiSelectInput from "../../multi-select-input/MultiSelectInput";
import Select from "../../select/Select";
import CustomMultiSelect from "../../custom-multi-select/CustomMultiSelect";
import CustomOptionCard from "../../cards/custom-option-card/CustomOptionCard";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import UploadAds from "./UploadAds";
import Summary from "./Summary";

const {
  budget,
  date,
  arrowLeft,
  arrowRight,
  close,
  settings,
  info,
  trashV2,
  exclamation,
  TargetingImage,
  arrowDown,
  arrowUp,
} = localData.svgs;

let timeout = "";

const initialPortfolioBalance = [
  { title: "less than $100", isSelected: false, id: uuidv4() },
  { title: "$500 - $1,500", isSelected: false, id: uuidv4() },
  { title: "$1,500 -$10,000", isSelected: false, id: uuidv4() },
  { title: "$10,000- $100,000", isSelected: false, id: uuidv4() },
  { title: "more than $100,000", isSelected: false, id: uuidv4() },
];

export default function Targeting({ setActiveTab, activeTab, setActiveStep }) {
  const {
    requestedData,
    campaignState,
    setCampaignState,
    campaignWizardStatus,
    isDarkModeEnabled,
    campaignsState,
    assistedCampaignData,
    isAssistedCampaignEnabled,
    setIsAssistedCampaignEnabled,
    selectedObjective,
    formatDate,
    campaignErrors,
    setCampaignErrors,
    isAccordionOpen,
    toggleAccordion,
    creatives,
  } = useGlobalContext();

  const {
    categoryOptions,
    setCategoryOptions,
    walletTargetingOptions,
    setWalletTargetingOptions,
    web3AudienceOptions,
    setWeb3AudienceOptions,
    chainTargetingOptions,
    setChainTargetingOptions,
    countriesOptions,
    setCountriesOptions,
  } = useCampaignWizardData();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCollapsedMainSelection, setIsCollapsedMainSelection] =
    useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedWeb2Targeting, setIsCollapsedWeb2Targeting] =
    useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [displayGeoModal, setDisplayGeoModal] = useState("none");

  const [activeAssistedCampaignData, setActiveAssistedCampaignData] = useState(
    {}
  );
  const [activeAdditionalData, setActiveAdditionalData] = useState({});

  const [startDate, setStartDate] = useState(
    campaignState.campaignInfo.startDate?.split("/").reverse().join("-") ||
      new Date().toISOString().slice(0, 10)
  );

  const [endDate, setEndDate] = useState(
    campaignState.campaignInfo.endDate?.split("/").reverse().join("-") || ""
  );

  const [state, setState] = useState({
    campaignName:
      campaignWizardStatus === "edit" ||
      campaignState.campaignInfo.campaignName.length
        ? campaignState.campaignInfo.campaignName
        : `${selectedObjective} - ${startDate}`,
    targetURL: campaignState.campaignInfo.targetURL,
    smartContractAddress: campaignState.campaignInfo.smartContractAddress,
    budget: campaignState.campaignInfo.budget,
    maxCPC: campaignState.campaignInfo.maxCPC,
    startDate: campaignState.campaignInfo.startDate,
    endDate: campaignState.campaignInfo.endDate,
    campaignType: campaignState.campaignInfo.campaignType,
    isUTMChecked: isChecked,
    category: campaignState.campaignInfo.category,
    web3Audience: campaignState.campaignInfo.web3Audience,
    chainTargeting: campaignState.campaignInfo.chainTargeting,
    // portfolioBalance: [],
    // competitorSmartContracts: [],
    walletTargeting: campaignState.campaignInfo.walletTargeting,
    deviceType: campaignState.campaignInfo.deviceType,
    operativeSystem: campaignState.campaignInfo.operativeSystem,
    browser: campaignState.campaignInfo.browser,
    countriesTargeting: campaignState.campaignInfo.countriesTargeting,
  });

  const [deviceTypeOptions, setDeviceTypeOptions] = useState([
    { title: "Mobile", isSelected: false, percentage: "58.21", id: uuidv4() },
    { title: "Desktop", isSelected: false, percentage: "41.79", id: uuidv4() },
  ]);

  const [operativeSystemOptions, setOperativeSystemOptions] = useState([
    { title: "Android", isSelected: false, percentage: "41.00", id: uuidv4() },
    { title: "Windows", isSelected: false, percentage: "40.00", id: uuidv4() },
    { title: "IOS", isSelected: false, percentage: "17.00", id: uuidv4() },
    { title: "Linux", isSelected: false, percentage: "2.00", id: uuidv4() },
    { title: "macOS", isSelected: false, percentage: "10.00", id: uuidv4() },
  ]);

  const [portfolioBalance, setPortfolioBalance] = useState(
    initialPortfolioBalance
  );

  const [competitorSmartContracts, setCompetitorSmartContracts] = useState([]);

  const [browserOptions, setBrowserOptions] = useState([
    {
      title: "Google Chrome",
      isSelected: false,
      percentage: "64.75",
      id: uuidv4(),
    },
    { title: "Safari", isSelected: false, percentage: "19.66", id: uuidv4() },
    {
      title: "Microsoft Edge",
      isSelected: false,
      percentage: "4.32",
      id: uuidv4(),
    },
    { title: "Firefox", isSelected: false, percentage: "2.98", id: uuidv4() },
    { title: "Opera", isSelected: false, percentage: "2.40", id: uuidv4() },
    { title: "Other", isSelected: false, percentage: "5.89", id: uuidv4() },
  ]);

  const [allEstimatedSizes, setAllEstimatedSizes] = useState({
    categoryEstimatedSize: 0,
    web3AudienceEstimatedSize: 0,
    walletTargetingEstimatedSize: 0,
    chainTargetingEstimatedSize: 0,
    countriesEstimatedSize: 0,
  });

  const [estimatedSize, setEstimatedSize] = useState({
    smallestEstimateSize: 0,
    largestEstimateSize: 0,
  });
  const [isEstimatedSizeLoading, setIsEstimatedSizeLoading] = useState(false);
  const [isUnableToEstimate, setIsUnableToEstimate] = useState(false);

  const [estimatedPercentage, setEstimatedPercentage] = useState({
    smallestEstimatePercentage: 0,
    largestEstimatePercentage: 0,
  });
  const [allEstimatedPercentages, setAllEstimatedPercentages] = useState({
    deviceTypeEstimatedPercentage: 0,
    operativeSystemPercentage: 0,
    browserPercentage: 0,
  });

  const [result, setResult] = useState(0);

  const [errors, setErrors] = useState({
    categoryError: false,
    web3AudienceError: false,
    chainTargetingError: false,
    walletTargetingError: false,
    deviceTypeError: false,
    operativeSystemError: false,
    browserError: false,
    countriesTargetingError: false,
    campaignNameError: false,
    targetURLError: false,
    smartContractAddressError: false,
    budgetError: false,
    startDateError: false,
    endDateError: false,
  });

  const { addCommas, formatLargeNumber } = useFormatter();

  const { campaignTabs, setCampaignTabs } = campaignsState;

  const toggleCollapseMainSelection = () => {
    setIsCollapsedMainSelection(!isCollapsedMainSelection);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleCollapseWeb2Targeting = () => {
    setIsCollapsedWeb2Targeting(!isCollapsedWeb2Targeting);
  };

  const setDefaultOptions = ({ property, state, setState }) => {
    let tempState = [...state];
    tempState = tempState.map((item) => {
      const isSelected = campaignState?.campaignInfo[property]?.includes(
        item.value || item.title
      );
      return { ...item, isSelected };
    });
    setState(tempState);
  };

  const setAdditionalFormOptions = ({ property, state, setState }) => {
    let tempState = [...state];
    tempState = tempState.map((item) => {
      const isSelected = campaignState.campaignInfo[property].includes(
        item.value || item.title
      );
      return { ...item, isSelected };
    });
    setState(tempState);
  };

  const setDefaultMultiSelectItems = () => {
    setCompetitorSmartContracts(
      campaignState.campaignInfo.competitorSmartContracts
    );
    setDefaultOptions({
      property: "category",
      state: categoryOptions,
      setState: setCategoryOptions,
    });
    setDefaultOptions({
      property: "web3Audience",
      state: web3AudienceOptions,
      setState: setWeb3AudienceOptions,
    });
    setDefaultOptions({
      property: "chainTargeting",
      state: chainTargetingOptions,
      setState: setChainTargetingOptions,
    });

    setDefaultOptions({
      property: "walletTargeting",
      state: walletTargetingOptions,
      setState: setWalletTargetingOptions,
    });
    setDefaultOptions({
      property: "deviceType",
      state: deviceTypeOptions,
      setState: setDeviceTypeOptions,
    });
    setDefaultOptions({
      property: "operativeSystem",
      state: operativeSystemOptions,
      setState: setOperativeSystemOptions,
    });
    setDefaultOptions({
      property: "po",
      state: operativeSystemOptions,
      setState: setOperativeSystemOptions,
    });
    setDefaultOptions({
      property: "portfolioBalance",
      state: portfolioBalance,
      setState: setPortfolioBalance,
    });
    setDefaultOptions({
      property: "browser",
      state: browserOptions,
      setState: setBrowserOptions,
    });
    setDefaultOptions({
      property: "countriesTargeting",
      state: countriesOptions,
      setState: setCountriesOptions,
    });

    // for countries and bid
    setState((state) => ({
      ...state,
      countriesTargeting: campaignState.campaignInfo.countriesTargeting,
    }));
  };

  useEffect(() => {
    if (campaignWizardStatus === "edit" || campaignWizardStatus === "copy") {
      setDefaultMultiSelectItems();
    }
  }, []);

  // useEffect(() => {
  //     if (campaignWizardStatus === "create") {
  //         const { websiteURL } = requestedData.userProfileData.currentOrganization.additionalData;
  //         console.log(websiteURL);
  //         // setAdditionalFormOptions
  //         if (websiteURL) {
  //         }
  //     }
  // }, [requestedData.userProfileData]);

  const concat = (options) => {
    let concatedValue = 0;
    options.forEach((item) => {
      if (!item.isSelected) return;
      concatedValue +=
        parseFloat(item.size) || parseFloat(item.percentage) || 0;
    });

    return concatedValue;
  };

  const getFilteredSmallestEstimatePercentage = ({
    smallestEstimatePercentage,
  }) => {
    const deviceType = deviceTypeOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);
    const os = operativeSystemOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);
    const browser = browserOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);

    setIsUnableToEstimate((prev) => false);

    if (
      (os.includes("Android") || os.includes("Windows")) &&
      (browser.includes("Safari") || browser.includes("Opera"))
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (
      os.includes("IOS") &&
      (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (
      (os.includes("Windows") ||
        os.includes("Linux") ||
        os.includes("macOS")) &&
      deviceType.includes("Mobile")
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (os.includes("Android") && deviceType.includes("Desktop")) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }

    // if (
    //     os.length === 1 &&
    //     (os.includes("Android") || os.includes("Windows")) &&
    //     browser.length === 1 &&
    //     (browser.includes("Safari") || browser.includes("Opera"))
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     os.includes("IOS") &&
    //     browser.length === 1 &&
    //     (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
    //     deviceType.length === 1 &&
    //     deviceType.includes("Mobile")
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     os.includes("Android") &&
    //     deviceType.length === 1 &&
    //     deviceType.includes("Desktop")
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }

    return smallestEstimatePercentage;
  };

  const handleEstimatedSize = () => {
    // let categoryEstimatedSize = 0;
    // let web3AudienceEstimatedSize = 0;
    // let walletTargetingEstimatedSize = 0;
    // let chainTargetingEstimatedSize = 0;
    // let countriesEstimatedSize = 0;

    // categoryOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     categoryEstimatedSize += parseInt(item.size) || 0;
    // });
    // web3AudienceOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     web3AudienceEstimatedSize += parseInt(item.size) || 0;
    // });
    // walletTargetingOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     walletTargetingEstimatedSize += parseInt(item.size) || 0;
    // });
    // chainTargetingOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     chainTargetingEstimatedSize += parseInt(item.size) || 0;
    // });
    // countriesOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     countriesEstimatedSize += parseInt(item.size) || 0;
    // });

    //SIZES

    const categoryEstimatedSize = concat(categoryOptions);
    const web3AudienceEstimatedSize = concat(web3AudienceOptions);
    const walletTargetingEstimatedSize = concat(walletTargetingOptions);
    const chainTargetingEstimatedSize = concat(chainTargetingOptions);
    const countriesEstimatedSize = concat(countriesOptions);

    const allSizes = {
      categoryEstimatedSize,
      web3AudienceEstimatedSize,
      walletTargetingEstimatedSize,
      chainTargetingEstimatedSize,
      countriesEstimatedSize,
    };

    const nonZeroSizes = Object.values(allSizes).filter((size) => size !== 0);
    let smallestEstimateSize =
      nonZeroSizes.length > 0 ? Math.min(...nonZeroSizes) : 0;
    let largestEstimateSize =
      nonZeroSizes.length > 0 ? Math.max(...nonZeroSizes) : 0;

    // if (largestEstimateSize < 32300000) largestEstimateSize = 32300000;
    // if (smallestEstimateSize < 29200000 || smallestEstimateSize == largestEstimateSize)
    //     smallestEstimateSize = 29200000;

    const sizes = { smallestEstimateSize, largestEstimateSize };

    setEstimatedSize(sizes);
    setAllEstimatedSizes(allSizes);

    // PERCENTAGES

    const deviceTypeEstimatedPercentage = concat(deviceTypeOptions);
    const operativeSystemPercentage = concat(operativeSystemOptions);
    const browserPercentage = concat(browserOptions);

    const allPercentages = {
      deviceTypeEstimatedPercentage,
      operativeSystemPercentage,
      browserPercentage,
    };

    const nonZeroPercentages = Object.values(allPercentages).filter(
      (percentage) => percentage !== 0
    );
    let smallestEstimatePercentage =
      nonZeroPercentages.length > 0 ? Math.min(...nonZeroPercentages) : 0;
    let largestEstimatePercentage =
      nonZeroPercentages.length > 0 ? Math.max(...nonZeroPercentages) : 0;

    const percentages = {
      smallestEstimatePercentage,
      largestEstimatePercentage,
    };

    setEstimatedPercentage(percentages);
    setAllEstimatedPercentages(allPercentages);

    smallestEstimatePercentage = getFilteredSmallestEstimatePercentage({
      smallestEstimatePercentage,
    });

    let result = smallestEstimateSize;

    if (smallestEstimatePercentage) {
      result = (smallestEstimateSize * smallestEstimatePercentage) / 100;
    }

    setResult(result);
  };

  useEffect(() => {
    if (!isEstimatedSizeLoading) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setIsEstimatedSizeLoading(false);
    }, 3000);
  }, [estimatedSize]);

  useEffect(() => {
    setIsEstimatedSizeLoading(true);

    handleEstimatedSize();
  }, [
    categoryOptions,
    web3AudienceOptions,
    chainTargetingOptions,
    walletTargetingOptions,
    countriesOptions,
    portfolioBalance,
    competitorSmartContracts,
    deviceTypeOptions,
    operativeSystemOptions,
    browserOptions,
  ]);

  const handleMultiSelectChange = (items, name) => {
    let selectedItems = items?.filter((item) => item.isSelected);
    selectedItems = selectedItems?.map((item) => item.value || item.title);
    setState((state) => ({
      ...state,
      [name]: selectedItems,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setState((state) => ({
      ...state,
      portfolioBalance: selectedOption.title,
    }));
  };

  const handleSelectMultiInput = (items) => {
    setState((state) => ({
      ...state,
      competitorSmartContracts: items,
    }));
  };

  useEffect(() => {
    setCampaignState((prev) => {
      return {
        ...prev,
        campaignInfo: {
          ...prev.campaignInfo,
          ...state,
        },
      };
    });
  }, [state]);

  const getStartDateError = () => {
    let errorMessage = "";
    const startDate = new Date(startDateRef.current.value).getTime();
    const endDate = new Date(endDateRef.current.value).getTime();
    const day = 24 * 60 * 60 * 1000;

    if (startDate < Date.now() - day)
      errorMessage = "Start date can't be in the past.";
    if (!startDate) errorMessage = "Start date can't be empty";

    return errorMessage;
  };

  const getEndDateError = () => {
    let errorMessage = "";
    const startDate = new Date(startDateRef.current.value).getTime();
    const endDate = new Date(endDateRef.current.value).getTime();
    const day = 24 * 60 * 60 * 1000;

    if (endDate < startDate)
      errorMessage = "End date must be after start date.";
    if (endDate < Date.now() - day && campaignWizardStatus !== "edit")
      errorMessage = "End date can't be in the past.";
    // if(startDate && !endDate) errorMessage = "End date can't be empty"
    return errorMessage;
  };

  useEffect(() => {
    setErrors({
      ...errors,
      categoryError: !state.category.length,
      web3AudienceError: !state.web3Audience.length,
      chainTargetingError: !state.chainTargeting.length,
      walletTargetingError: !state.walletTargeting.length,
      deviceTypeError: !state.deviceType.length,
      operativeSystemError: !state.operativeSystem.length,
      browserError: !state.browser.length,
      countriesTargetingError: !state.countriesTargeting.length,
      campaignNameError: state.campaignName === "",
      targetURLError:
        state.targetURL === "" || !checkIsValidUrl(state.targetURL),
      smartContractAddressError:
        state.smartContractAddress !== "" &&
        !checkIsBlockchainAddress(state.smartContractAddress),
      budgetError: state.budget && state.budget.replace(/,/g, "") < 360,
      // startDateError: state.startDate === "",
      // campaignTypeError: !state.campaignType.length,
      startDateError:
        campaignWizardStatus !== "edit" && getStartDateError() ? true : false,
      endDateError: getEndDateError() ? true : false,
    });
    // setCampaignState({})
  }, [campaignState]);

  useEffect(() => {
    if (campaignState.campaignInfo.targetURL.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignTragetUrl: false,
      }));
    }
    if (campaignState.campaignInfo.campaignName.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignName: false,
      }));
    }
    // if(campaignState.creatives.length){
    //   setCampaignErrors((prevState) => ({
    //     ...prevState,
    //     campaignCreatives: false,
    //   }));
    // }
    if (campaignState.campaignInfo.budget.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignBudget: false,
      }));
    }
    if (campaignState.campaignInfo.maxCPC.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignMaxCPC: false,
      }));
    }
    if (selectedObjective.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignObjective: false,
      }));
    }
  }, [campaignState]);

  useEffect(() => {
    const haveError = true;
    // !errors.categoryError &&
    // !errors.web3AudienceError &&
    // !errors.chainTargetingError &&
    // !errors.walletTargetingError &&
    // !errors.deviceTypeError &&
    // !errors.operativeSystemError &&
    // !errors.browserError &&
    // !errors.countriesTargetingError

    setIsButtonDisabled(!haveError);
  }, [errors]);

  const resetParameters = () => {
    setState({
      category: [],
      web3Audience: [],
      chainTargeting: [],
      walletTargeting: [],
      portfolioBalance: [],
      competitorSmartContracts: [],
      deviceType: [],
      operativeSystem: [],
      browser: [],
      countriesTargeting: [],
    });

    setCategoryOptions(
      categoryOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setWeb3AudienceOptions(
      web3AudienceOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setChainTargetingOptions(
      chainTargetingOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setWalletTargetingOptions(
      walletTargetingOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setDeviceTypeOptions(
      deviceTypeOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setOperativeSystemOptions(
      operativeSystemOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setPortfolioBalance(
      portfolioBalance.map((item) => ({ ...item, isSelected: false }))
    );
    setCompetitorSmartContracts([]);
    setBrowserOptions(
      browserOptions.map((item) => ({ ...item, isSelected: false }))
    );
    setCountriesOptions(
      countriesOptions.map((item) => ({ ...item, isSelected: false }))
    );
    // setActiveAssistedCampaignData({});
    setIsAssistedCampaignEnabled(false);
  };

  const setAssistedDataOptions = ({ selectedOptions, state, setState }) => {
    let tempState = [...state];
    tempState = tempState.map((item) => {
      const isSelected = selectedOptions.includes(item.value || item.title);
      return { ...item, isSelected };
    });
    setState(tempState);
  };

  useEffect(() => {
    if (!isAssistedCampaignEnabled) return;
    if (campaignWizardStatus === "edit") return;
    if (campaignWizardStatus === "copy") return;
    if (!assistedCampaignData.length) return;

    const activeData = assistedCampaignData.find((item) => item.isSelected);
    if (!activeData) return;

    setState((prev) => ({
      ...prev,
      category: activeData.category,
      web3Audience: activeData.web3Audience,
    }));

    setAssistedDataOptions({
      selectedOptions: activeData.category,
      state: categoryOptions,
      setState: setCategoryOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.web3Audience,
      state: web3AudienceOptions,
      setState: setWeb3AudienceOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.chainTargeting,
      state: chainTargetingOptions,
      setState: setChainTargetingOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.walletTargeting,
      state: walletTargetingOptions,
      setState: setWalletTargetingOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.category,
      state: categoryOptions,
      setState: setCategoryOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.operativeSystem,
      state: operativeSystemOptions,
      setState: setOperativeSystemOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.browser,
      state: browserOptions,
      setState: setBrowserOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.deviceType,
      state: deviceTypeOptions,
      setState: setDeviceTypeOptions,
    });
    setAssistedDataOptions({
      selectedOptions: activeData.geo,
      state: countriesOptions,
      setState: setCountriesOptions,
    });

    // for countries and bid
    setState((state) => ({
      ...state,
      countriesTargeting: activeData.geo,
    }));
  }, [assistedCampaignData, isAssistedCampaignEnabled]);

  useEffect(() => {
    if (state.web3Audience.includes("Custom")) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
      if (!isCollapsed && campaignWizardStatus !== "edit") {
        setCompetitorSmartContracts([]);
        setPortfolioBalance(initialPortfolioBalance);
      }
    }
  }, [state.web3Audience]);

  useEffect(() => {
    const isSelected = portfolioBalance.some((item) => item.isSelected);
    if (isSelected || competitorSmartContracts?.length) {
      setWeb3AudienceOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.title === "Custom" ? { ...option, isSelected: true } : option
        )
      );
    } else {
      setWeb3AudienceOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.title === "Custom" ? { ...option, isSelected: false } : option
        )
      );
    }
  }, [portfolioBalance, competitorSmartContracts]);

  const isLargeScreen = useMediaQuery("(min-width:1250px)");

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({
      ...state,
      [name]:
        name === "startDate" || name === "endDate"
          ? formatDate(value)
          : name === "budget" || name === "maxCPC"
          ? addCommas(value)
          : // : name === "smartContractAddress"
            // ? checkIsBlockchainAddress(value)
            value,
    });
  };

  function checkIsValidUrl(url) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  }

  function checkIsBlockchainAddress(text) {
    var pattern = /^0x[a-fA-F0-9]+$/;
    return pattern.test(text);
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked === false) {
      setIsPopupOpen(true);
    } else {
      setIsChecked(true);
      setState({
        ...state,
        isUTMChecked: true,
      });
    }
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handlePopupConfirm = () => {
    setIsChecked(false);
    setState({
      ...state,
      isUTMChecked: false,
    });
    setIsPopupOpen(false);
  };

  // const getStartDateError = () => {
  //     let errorMessage = "";
  //     const startDate = new Date(startDateRef.current.value).getTime();
  //     const endDate = new Date(endDateRef.current.value).getTime();
  //     const day = 24 * 60 * 60 * 1000;

  //     if (startDate < Date.now() - day) errorMessage = "Start date can't be in the past.";
  //     if (!startDate) errorMessage = "Start date can't be empty";

  //     return errorMessage;
  // };

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  useEffect(() => {
    if (campaignWizardStatus === "edit" || campaignWizardStatus === "copy") {
      return;
    }
    setState((state) => ({
      ...state,
      campaignName: selectedObjective + ` - ${startDate}`,
    }));
  }, [selectedObjective]);

  return (
    <Box className="targeting ">
      <Grid
        container
        spacing={2}
        style={{
          maxWidth: isLargeScreen ? "1250px" : "100%",
          margin: isLargeScreen ? "0 auto" : "unset",
        }}
        className="main-conatiner-create-campaing"
      >
        <Grid item xs={12} md={9}>
          <Accordion
            style={{
              border: "2px solid rgba(219, 222, 225,1)",
              borderRadius: "14px",
            }}
            id="campaign-details-id"
            expanded={isAccordionOpen.campaignDetails}
            onChange={() => toggleAccordion("campaignDetails")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {(campaignErrors.campaignName ||
                campaignErrors.campaignTargetUrl ||
                campaignErrors.campaignBudget ||
                campaignErrors.campaignMaxCPC) && (
                <span className="accordion-error-icon">
                  <ReportProblemIcon />
                </span>
              )}
              Campaign Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="wizard-block">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Field
                      dataGuide="campaign-information-tutorial-2"
                      type="text"
                      label="Campaign Name *"
                      labelInfo="Enter a unique name for your campaign to easily identify it later. Example: 'Website Traffic - 2025-03-10'."
                      name="campaignName"
                      value={state.campaignName}
                      color="secondary"
                      placeholder="e.g. johnDoeCamp"
                      required={true}
                      size="sm"
                      callback={handleOnChange}
                      borderColor={campaignErrors.campaignName && "red"}
                      errorElement={
                        campaignErrors.campaignName && (
                          <span className="custom-error-message">
                            <ReportProblemIcon />
                            This field is required.
                          </span>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      dataGuide="campaign-information-tutorial-4"
                      type="text"
                      id="targetURL"
                      label="Target URL*"
                      labelInfo="Provide the URL where you want to drive traffic. Ensure it starts with http:// or https:// (e.g., https://yourwebsite.com)."
                      name="targetURL"
                      value={state.targetURL}
                      color="secondary"
                      placeholder="e.g. https://johndoe.com"
                      required={true}
                      size="sm"
                      callback={handleOnChange}
                      // isValid={state.targetURL === "" || checkIsValidUrl(state.targetURL)}
                      // errorMessage={`Invalid URL. Please enter a valid URL. e.g.
                      // <br /> https://johndoe.com`}
                      errorMessage={
                        state.targetURL !== "" &&
                        !checkIsValidUrl(state.targetURL)
                          ? `Invalid URL. Please enter a valid URL. e.g.
                                <br /> https://johndoe.com`
                          : ""
                      }
                      errorElement={
                        campaignErrors.campaignTragetUrl && (
                          <span className="custom-error-message">
                            <ReportProblemIcon />
                            This field is required.
                          </span>
                        )
                      }
                      borderColor={campaignErrors.campaignTragetUrl && "red"}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "-10px",
                      }}
                    >
                      <input
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                      />
                      <p style={{ fontSize: "9px", marginTop: "2px" }}>
                        /?UTM_Source=Blockchain-Ads&UTM_medium=Display
                      </p>
                    </div>
                    <ConfirmationCheckBoxPopup
                      open={isPopupOpen}
                      onClose={handlePopupClose}
                      onConfirm={handlePopupConfirm}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      dataGuide="campaign-information-tutorial-5"
                      type="text"
                      label="Smart Contract"
                      labelInfo="(Optional) Select the Smart Contract of your project, so аre able to track transactions."
                      color="secondary"
                      placeholder="e.g. 0x00000000000000"
                      required={true}
                      size="sm"
                      name="smartContractAddress"
                      value={state.smartContractAddress}
                      callback={handleOnChange}
                      errorMessage={
                        state.smartContractAddress !== "" &&
                        !checkIsBlockchainAddress(state.smartContractAddress)
                          ? `Invalid smart contract address.
                                <br /> e.g. 0x00000000000000`
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      dataGuide="campaign-information-tutorial-6"
                      type="text"
                      label="Daily Budget (USD)*"
                      labelInfo="Set your daily spending limit in USD. This caps how much you’ll spend per day on this campaign (e.g., $360)."
                      name="budget"
                      value={state.budget}
                      color="secondary"
                      placeholder="Budget"
                      required={true}
                      size="sm"
                      currencyInput={true}
                      startIcon={budget}
                      callback={handleOnChange}
                      errorMessage={
                        (errors.budgetError &&
                          "Daily budget must be at least $360.") ||
                        (state.budget &&
                          state.budget.replace(/,/g, "") >= 10000 &&
                          `Your DAILY budget was set to ${state.budget}`)
                      }
                      additionalMessage={
                        campaignWizardStatus === "edit" && (
                          <>
                            Updates to this limit will take effect the next day.
                          </>
                        )
                      }
                      errorElement={
                        campaignErrors.campaignBudget && (
                          <span className="custom-error-message">
                            <ReportProblemIcon />
                            This field is required.
                          </span>
                        )
                      }
                      // warningInfo={
                      //     campaignWizardStatus === "edit" &&
                      //    <div>Updates to this limit will take<br/>  effect the next day.</div>
                      // }
                      borderColor={campaignErrors.campaignBudget && "red"}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <div
                      // className={`${
                      //   campaignWizardStatus === "edit" ? "not-editable" : ""
                      // }`}
                      data-tooltip-id="tooltip-max-cpc"
                      data-tooltip-content="Not Editable"
                    >
                      <Field
                        dataGuide="campaign-information-tutorial-3"
                        type="text"
                        label="CPC Bid (USD)*"
                        labelInfo="Specify the maximum cost per click you’re willing to pay. This helps control your ad spend per user click (e.g., $2)."
                        name="maxCPC"
                        value={state.maxCPC}
                        color="secondary"
                        placeholder="CPC Bid"
                        required={true}
                        size="sm"
                        currencyInput={true}
                        startIcon={budget}
                        callback={handleOnChange}
                        borderColor={campaignErrors.campaignMaxCPC && "red"}
                        errorElement={
                          campaignErrors.campaignMaxCPC && (
                            <span className="custom-error-message">
                              <ReportProblemIcon />
                              This field is required.
                            </span>
                          )
                        }
                      />
                      {campaignWizardStatus === "edit" && (
                        <Tooltip
                          id="tooltip-max-cpc"
                          className="custom-tooltip"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div
                      className={`${
                        campaignWizardStatus === "edit" ? "not-editable" : ""
                      }`}
                      data-tooltip-id="tooltip-start-date"
                      data-tooltip-content="Not Editable"
                    >
                      <Field
                        dataGuide="campaign-information-tutorial-7"
                        className="date"
                        type="date"
                        label="Start Date"
                        labelInfo=" Pick the starting date for your campaign. If you leave it empty, the campaign will start immediately after moderation."
                        name="startDate"
                        // value={state.startDate}
                        value={startDate}
                        // placeholder="dd/mm/yyyy"
                        color="secondary"
                        required={true}
                        size="sm"
                        // endIcon={date}
                        callback={(e) => {
                          handleOnChange(e);
                          setStartDate(e.target.value);
                        }}
                        // errorMessage={
                        //     campaignWizardStatus !== "edit" &&
                        //     startDateRef.current &&
                        //     getStartDateError()
                        // }
                        _ref={startDateRef}
                      />
                      {campaignWizardStatus === "edit" && (
                        <Tooltip
                          id="tooltip-start-date"
                          className="custom-tooltip"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div
                      // className={`${
                      //   campaignWizardStatus === "edit" ? "not-editable" : ""
                      // }`}
                      data-tooltip-id="tooltip-end-date"
                      data-tooltip-content="Not Editable"
                    >
                      <Field
                        className="date"
                        type="date"
                        label="End Date"
                        labelInfo="Choose the date your campaign will stop running. Use the format MM/DD/YYYY (e.g., 03/10/2025). Leave blank for no end date."
                        name="endDate"
                        // value={state.endDate}
                        value={endDate}
                        color="secondary"
                        placeholder="Placeholder text"
                        required={true}
                        size="sm"
                        // endIcon={date}
                        callback={(e) => {
                          handleOnChange(e);
                          setEndDate(e.target.value);
                        }}
                        // errorMessage={errors.endDateError ? "end date can't be in the past." : ""}
                        // errorMessage={endDateRef.current && getEndDateError()}
                        _ref={endDateRef}
                      />
                      {campaignWizardStatus === "edit" && (
                        <Tooltip
                          id="tooltip-end-date"
                          className="custom-tooltip"
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion
            style={{
              border: "2px solid rgba(219, 222, 225,1)",
              borderRadius: "14px",
            }}
            sx={{
              "::before": {
                display: "none",
              },
            }}
            expanded={isAccordionOpen.targetingFeatures}
            onChange={() => toggleAccordion("targetingFeatures")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Targeting features
            </AccordionSummary>
            <AccordionDetails>
              <Accordion
                defaultExpanded
                style={{
                  border: "2px solid rgba(219, 222, 225,1)",
                  borderRadius: "14px",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Web3 Targeting
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="wizard-block"
                    data-guide="targeting-tutorial-1"
                  >
                    <div
                      className={`wizard-block-body fields-group fields-group-bottom`}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <MultiSelect
                            {...{
                              items: web3AudienceOptions,
                              setItems: setWeb3AudienceOptions,
                              variant: "outlined",
                              limitTags: 2,
                              color: "secondary",
                              name: "web3Audience",
                              label: "Web3 Audiences",
                              labelInfo:
                                "Choose predefined Web3 audience tags to target users based on their blockchain activity, such as NFT collectors or DeFi users.",
                              callback: handleMultiSelectChange,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <MultiSelect
                            {...{
                              items: walletTargetingOptions,
                              setItems: setWalletTargetingOptions,
                              variant: "outlined",
                              limitTags: 2,
                              color: "secondary",
                              label: "Wallet Targeting",
                              labelInfo:
                                "Target users who interact with a specific smart contract. Enter the contract address to refine your audience (e.g., 0x0000000000…)",
                              name: "walletTargeting",
                              callback: handleMultiSelectChange,
                              // direction: window.innerWidth > 576 ? "up" : "down",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <MultiSelect
                            {...{
                              items: chainTargetingOptions,
                              setItems: setChainTargetingOptions,
                              variant: "outlined",
                              limitTags: 2,
                              color: "secondary",
                              name: "chainTargeting",
                              label: "Chain Targeting",
                              labelInfo:
                                "Select the blockchain network to target users on, such as Ethereum, Binance Smart Chain, or Polygon.",
                              callback: handleMultiSelectChange,
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* <MultiSelect
                                {...{
                                    items: categoryOptions,
                                    setItems: setCategoryOptions,
                                    variant: "outlined",
                                    limitTags: 2,
                                    color: "secondary",
                                    label: "Category",
                                    labelInfo: "(Optional) Select your project’s vertical.",
                                    name: "category",
                                    callback: handleMultiSelectChange,
                                }}
                            /> */}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion
                defaultExpanded
                style={{
                  border: "2px solid rgba(219, 222, 225,1)",
                  borderRadius: "14px",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Granular Web3 Targeting - Custom Audience
                </AccordionSummary>
                <AccordionDetails>
                  <div className="wizard-block">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Select
                          {...{
                            items: portfolioBalance,
                            setItems: setPortfolioBalance,
                            variant: "outlined",
                            color: "secondary",
                            label: "Portfolio balance",
                            labelInfo:
                              "Target users based on their wallet’s portfolio balance. Select a range to focus on users with specific asset values.",
                            name: "portfolioBalance",
                            placeholder: "Select",
                            callback: handleSelectChange,
                            className: "custom-select-create-campaign",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MultiSelectInput
                          {...{
                            label: "Competitor Smart Contract",
                            labelInfo:
                              "Target users who have interacted with a competitor’s smart contract. Type the contract address and press Enter to add it.",
                            name: "competitorSmartContracts",
                            setItems: setCompetitorSmartContracts,
                            callBack: handleSelectMultiInput,
                            items: competitorSmartContracts,
                            // disabled: !state.web3Audience.includes("Custom")
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion
                defaultExpanded
                style={{
                  border: "2px solid rgba(219, 222, 225,1)",
                  borderRadius: "14px",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Web2 Targeting
                </AccordionSummary>
                <AccordionDetails>
                  <div className={`wizard-block`}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Modal
                          // overlayClickDisabled={true}
                          restProps={{
                            state,
                            setState,
                            items: countriesOptions,
                            setItems: setCountriesOptions,
                            estimatedSize,
                            isEstimatedSizeLoading,
                            result,
                            isUnableToEstimate,
                          }}
                          Toggler={() => (
                            // <Button
                            //     className="geo-modal-toggler"
                            //     name="Geo"
                            //     endIcon={settings}
                            //     onClick={() => setDisplayGeoModal("block")}
                            //     color="secondary"
                            //     variant="outlined"
                            // />
                            <MultiSelect
                              {...{
                                items: countriesOptions,
                                setItems: setCountriesOptions,
                                variant: "outlined",
                                color: "secondary",
                                label: "Geo",
                                name: "countriesTargeting",
                                labelInfo:
                                  "Select the geographical locations to target your ads, such as specific countries, regions, or cities.",
                                toggleIcon: settings,
                                // callback: handleMultiSelectChange,
                                useAsButton: true,
                                limitTags: 2,
                                buttonCallback: () =>
                                  setDisplayGeoModal("block"),
                              }}
                            />
                          )}
                          // modalCloseCallback={modalCloseCallback}
                          display={displayGeoModal}
                          setDisplay={setDisplayGeoModal}
                          className="modal-dialog-centered geo-modal"
                          Child={Geo}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MultiSelect
                          {...{
                            items: browserOptions,
                            setItems: setBrowserOptions,
                            variant: "outlined",
                            limitTags: 2,
                            color: "secondary",
                            label: "Browser",
                            labelInfo:
                              "Choose the browsers to target, such as Chrome, Firefox, or Safari, to reach users on their preferred platforms.",
                            name: "browser",
                            callback: handleMultiSelectChange,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <MultiSelect
                          {...{
                            withCheckbox: false,
                            items: deviceTypeOptions,
                            setItems: setDeviceTypeOptions,
                            variant: "outlined",
                            limitTags: 2,
                            color: "secondary",
                            label: "Device Type",
                            labelInfo:
                              "Select the device types to target, such as Desktop, Mobile, or Tablet, to optimize your ad delivery.",
                            name: "deviceType",
                            callback: handleMultiSelectChange,
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* <MultiSelect
                                    {...{
                                        items: operativeSystemOptions,
                                        setItems: setOperativeSystemOptions,
                                        variant: "outlined",
                                        limitTags: 2,
                                        color: "secondary",
                                        label: "Operative System",
                                        labelInfo: "(Optional) Choose specific operating systems to target.",
                                        name: "operativeSystem",
                                        callback: handleMultiSelectChange,
                                    }}
                                /> */}
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />
              <Button
                size="sm"
                disabled={false}
                name="Reset Parameters"
                color={"dark"}
                startIcon={trashV2}
                onClick={resetParameters}
                style={{ marginRight: "auto" }}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <UploadAds />
          <br />
          {/* <Summary /> */}
        </Grid>

        <Grid item xs={12} md={3}>
          <EstimatedResults
            {...{
              categoryOptions,
              walletTargetingOptions,
              web3AudienceOptions,
              operativeSystem: state.operativeSystem,
              browsers: state.browser,
              geo: countriesOptions,
              deviceType: state.deviceType,
              chainTargetingOptions,
              result,
              isLoading: isEstimatedSizeLoading,
              error: isUnableToEstimate,
            }}
            campaignState={campaignState}
          />
        </Grid>
      </Grid>
      <TargetingJoyride activeTab={activeTab} />
    </Box>
  );
}

const Geo = ({
  setIsOpen,
  state,
  setState,
  items,
  setItems,
  estimatedSize,
  isEstimatedSizeLoading,
  result,
  isUnableToEstimate,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };
  const { close } = localData.svgs;
  const { map } = localData.images;

  const { formatLargeNumber } = useFormatter();

  return (
    <>
      <div className="modal-header">
        <Button
          className="btn-close"
          variant="circle"
          color="dark"
          size="sm"
          icon={close}
          onClick={closeModal}
        />
      </div>
      <div className="modal-body">
        <CountriesAndBid
          state={state}
          setState={setState}
          items={items}
          setItems={setItems}
        />
        <br />
        <img src={map} className="geo-modal-cover" alt="" />
        <br />
      </div>
      <div className="modal-footer">
        {!result ? (
          ""
        ) : (
          <AudienceEstimate
            {...{ isEstimatedSizeLoading, isUnableToEstimate, result }}
          />
        )}
      </div>

      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%" }}
        onClick={closeModal}
        name="Done"
        // size="sm"
      />
    </>
  );
};

const TargetingJoyride = ({ activeTab }) => {
  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: [
      {
        content: (
          <h2 className="joyride-subtitle"> Adjust audience settings here.</h2>
        ),
        locale: { skip: <strong>Skip</strong> },
        placement: "center",
        target: "body",
        title: "Targeting",
      },
      {
        content: (
          <h2 className="joyride-subtitle">
            Set web3 targeting parameters, such as token holders or blockchain
            users.
          </h2>
        ),
        locale: { skip: <strong>Skip</strong> },
        placement: "auto",
        target: "[data-guide=targeting-tutorial-1]",
        title: "Web3 Targeting Parameters",
        // scrollToOffset: 260,
      },
      {
        content: (
          <h2 className="joyride-subtitle">
            Set web2 targeting parameters, such as countries or specific
            browsers.
          </h2>
        ),
        locale: { skip: <strong>Skip</strong> },
        placement: "auto",
        target: "[data-guide=targeting-tutorial-2]",
        title: "Web2 Targeting Parameters",
        // scrollToOffset: 260,
      },
      {
        content: (
          <h2 className="joyride-subtitle">
            Click here to proceed to the next steps.
          </h2>
        ),
        locale: { skip: <strong>Skip</strong> },
        placement: "auto",
        target: "[data-guide=targeting-tutorial-3]",
        title: "Proceed to Next Steps",
        // scrollToOffset: 260,
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "targetingTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (guideTutorials && guideTutorials.includes("targetingTutorial")) {
      if (activeTab == 1) {
        setTimeout(() => {
          setSteps((prev) => ({ ...prev, run: true }));
        }, 500);
      }
    }
  }, [activeTab]);
  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={100}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

const ConfirmationCheckBoxPopup = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>IT IS HIGHLY RECOMMENDED TO HAVE UTMs ON!</DialogTitle>
      <DialogContent>
        <p>
          THIS WILL PROPERLY TRACK THE TRAFFIC AND HELP YOU OPTIMISE YOUR
          CAMPAIGNS
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          name={"Keep the UTMs"}
          variant="contained"
          color="success"
          onClick={onClose}
        />
        <Button
          name={"Remove UTMs anyway"}
          variant="contained"
          color="dark"
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
