import React, { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import AdsCard from "../../cards/ads-card/AdsCard";
import localData from "../../../localData";
import Loading from "../../loading/Loading";

const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
const allowedDimensions = [
  { width: 300, height: 250 },
  { width: 320, height: 100 },
  { width: 728, height: 90 },
  { width: 320, height: 50 },
];

const ImageUploader = () => {
  const fileInputRef = useRef(null);
  const { creatives, setCreatives, campaignErrors, errorAlert } = useGlobalContext();
  const [disabled, setDisabled] = useState(false)
  const {placeholder} = localData.images
  const [errors, setErrors] = useState([]);

  const MAX_FILE_SIZE = 700 * 1024; // 700 KB

const validateImage = (file) => {
  return new Promise((resolve) => {
    if (file.size > MAX_FILE_SIZE) {
      resolve({
        isValid: false,
        message: `File size exceeds 700KB: ${file.name}. Please upload a smaller file.`,
      });
      errorAlert(`File size exceeds 700KB: ${file.name}. Please upload a smaller file.`)
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const isValidType = allowedTypes.includes(file.type);
        const isValidSize = allowedDimensions.some(
          (dim) => dim.width === img.width && dim.height === img.height
        );

        if (!isValidType) {
          resolve({
            isValid: false,
            message: `Invalid file type: ${file.type}. Allowed types are ${allowedTypes.join(", ")}.`,
          });
          errorAlert(`Invalid file type: ${file.type}. Allowed types are ${allowedTypes.join(", ")}.`)
        } else if (!isValidSize) {
          resolve({
            isValid: false,
            message: `Invalid dimensions: ${img.width}x${img.height}. Allowed dimensions are ${allowedDimensions
              .map((dim) => `${dim.width}x${dim.height}`)
              .join(", ")}.`,
          });
          errorAlert(`Image with dimensions ${img.width}x${img.height} was not uploaded. Please upload images with the following dimensions: ${allowedDimensions
              .map((dim) => `${dim.width}x${dim.height}`)
              .join(", ")}.`);
        } else {
          resolve({
            isValid: true,
            file,
            preview: reader.result,
            dimensions: { width: img.width, height: img.height },
          });
        }
      };
    };
    reader.readAsDataURL(file);
  });
};

  const handleFileUpload = async (file) => {
    setDisabled(true)
    const formData = new FormData();
    formData.append("photo", file);

    const response = await fetch("https://server.blockchain-ads.com/api/fileupload.php", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    setDisabled(false)
    return data;
  };

  const processImage = async (item) => {
    const { file, preview, dimensions } = item;

    try {
      const uploadResult = await handleFileUpload(file);

      return {
        id: uuidv4(),
        title: `${dimensions.width}x${dimensions.height} - ${new Date().toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
        })}`,
        callToAction: "", // Assuming empty by default
        apiImage: uploadResult.name,
        image: preview,
        size: `${dimensions.width}x${dimensions.height}`,
        status: 1, // Default status
        name: uploadResult.name,
        width: dimensions.width,
        height: dimensions.height,
        reviveName: uploadResult.name,
        reviveWidth: dimensions.width,
        reviveHeight: dimensions.height,
      };
    } catch (error) {
      setErrors((prev) => [...prev, `Failed to upload file: ${file.name}`]);
      return null;
    }
  };

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);        
    const validationPromises = selectedFiles.map((file) => validateImage(file));
    const validationResults = await Promise.all(validationPromises);

    const validImages = validationResults.filter((result) => result.isValid);
    const invalidResults = validationResults.filter((result) => !result.isValid);

    setErrors((prevErrors) => [...prevErrors, ...invalidResults.map((err) => err.message)]);

    const uploadPromises = validImages.map((img) => processImage(img));
    const newCreatives = await Promise.all(uploadPromises);

    const validCreatives = newCreatives.filter(Boolean);
    setCreatives((prevCreatives) => [...prevCreatives, ...validCreatives]);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const clearErrors = () => setErrors([]);

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={`upload-image-container ${disabled ? "on-hold" : ""}`}
      onClick={handleContainerClick} // Trigger file input on container click
      style={{ cursor: "pointer" }}
    >
      <div className="upload-image drag-drop-container">
        <input
          ref={fileInputRef} // Attach the file input reference
          type="file"
          accept={allowedTypes.join(", ")}
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }} // Hide the file input
        />
        <div
          className="wrapper"
          data-guide="upload-ads-tutorial-1"
          style={{ borderColor: campaignErrors.campaignCreatives && "red" }}
        >
          <div className="image-wrapper">
            <img className="file-image" src={placeholder} alt="Placeholder" />
          </div>
          <strong>
            Drag your files here, or <span className="text-primary">browse</span>
          </strong>
          <p className="info">
            Format: (GIF, PNG, JPG)
            <br />
            Ad Sizes: (300x250, 320x100, 728x90, 320x50)
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
