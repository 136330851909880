import React from "react";
import localData from "../../../localData";

export default function RefundPolicy() {
    const { circleQuestion } = localData.svgs;

    return (
        <div className="advertising-policy">
            <h2 className="display-2 advertising-policy-title">Refund Policy</h2>
            <div className="row question" style={{marginBottom: "15px"}}>
                <div className="wrapper">
                    <h2 className="display-2">Eligibility for Refund</h2>
                    <p className="question-text">
                        Refunds are only available under specific conditions and will not be issued unless all requirements are met.
                    </p>
                </div>
            </div>
            <div className="row question">
                <div className="wrapper">
                    <h5 className="display-6 question-title">Platform Errors</h5>
                    <p className="question-text">
                        1. If a campaign fails due to a verified platform error, a refund may be considered.
                        <br/> 2. The issue must be reported within <strong>48 hours</strong> of the malfunction.
                        <br />
                        3. Blockchain-Ads will investigate the malfunction and determine if a refund or credit is warranted.
                    </p>
                </div>
            </div>
            <div className="row question" style={{marginTop: "5px"}}>
                <div className="wrapper">
                    <h5 className="display-6 question-title">Undelivered Ad Spend</h5>
                    <p className="question-text">
                        1. Unused advertising credit due to system failures on our end may be refunded or credited.
                        <br />
                        2. <strong>No refunds</strong> will be provided for client-side issues (e.g., non-compliance with ad policies, insufficient creatives, or delays in approval).
                        3. A <strong>client must have spent at least 35% of the initial campaign budget</strong> before being eligible to request a refund.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-6 question-title">Service Misrepresentation</h5>
                    <p className="question-text">
                        1. If a service is grossly misrepresented, we will review claims made within <strong>5 business days</strong> of the campaign start date.
                        <br /> 2. Valid claims may result in a <strong>partial refund</strong> after deducting applicable fees.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-6 question-title">Non-Compliance</h5>
                    <p className="question-text">
                        Accounts suspended for violating Terms of Service (fraud, unauthorized use, or ad policy violations) are <strong>ineligible for refunds</strong>.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-2">Non-Refundable Situations</h5>
                    <p className="question-text">
                        1. <strong>Platform Misuse:</strong> Refunds will not be issued if users fail to follow platform guidelines or provide necessary materials.
                        <br /> 2. <strong>Performance Dissatisfaction:</strong> Campaign performance (CTR, conversions, leads) is not guaranteed, and refunds based on performance dissatisfaction will not be issued.
                        <br /> 3. <strong>Fraudulent Activity:</strong> Accounts flagged for suspicious behavior are <strong>ineligible for refunds</strong>.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-2">Additional Terms</h5>
                    <p className="question-text">
                        <strong>Inactivity:</strong> Accounts inactive for <strong>6 months or more</strong> will lose access to refunds.
                        <strong>Minimum Spend Requirement:</strong> Refunds are <strong>not available</strong> until at least <strong>35% of the initial campaign budget</strong> has been spent.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-2">Refund Request Procedure</h5>
                    <p className="question-text">
                        To request a refund, contact Blockchain-Ads compliance team via <strong>email admin@blockchain-ads.com</strong> with campaign details.
                        <br/>
                        <br /> 1. Refund requests must be submitted within <strong>14 days</strong> of the campaign end date.
                        <br /> 2. Refunds will be processed within <strong>7-14 business days</strong>, subject to deductions.
                        <br /> 3. <strong>Cryptocurrency refunds:</strong> Unused balances will be refunded based on the <strong>USD value at the time of deposit</strong>, regardless of cryptocurrency price fluctuations.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-2">Additional Fees in Case of Refund Request</h5>
                    <p className="question-text">
                        All refund requests are subject to the following <strong>non-refundable fees</strong>:
                        <br/> <br/>
                        1.<strong> 20% Managed Service Fee:</strong> If the campaign involved any form of managed service by our team, a 20% fee on the total managed service cost will be deducted.
                        <br/> 2.<strong> 10% Design Fee:</strong> If Blockchain-Ads provided ad designs, a 10% fee will be deducted from the refund amount.
                        <br/> 3.<strong> $2,500 Refund Processing Fee:</strong> Non-refundable under any circumstances.
                    </p>
                </div>
            </div>
            <div className="row question">
                
                <div className="wrapper">
                    <h5 className="display-2">Final Statement</h5>
                    <p className="question-text">
                        By using Blockchain-Ads, you agree to these terms. For further inquiries, please contact our support team via <strong>Telegram</strong> or email at <strong>contact@blockchain-ads.com</strong>.
                    </p>
                </div>
            </div>
        </div>
    );
}