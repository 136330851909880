import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import GetInTouch from "./tabs/GetInTouch";
import AdvertisingPolicy from "./tabs/AdvertisingPolicy";
import GeneralQuestions from "./tabs/GeneralQuestions";
import RefundPolicy from "./tabs/RefundPolicy";

export default function Support({isPublic = false}) {
    useEffect(() => {
        document.title = "Support";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Fast and reliable support for your questions and technical issues. Explore FAQs, submit tickets, and chat with our team for quick assistance.";
    }, []);
    const { fade } = useGlobalContext().animations;

    const { edit, question, password, support, userShield, moneyBillTransfer } = localData.svgs;
    const { avatar } = localData.images;

    const tabs = [
        {
            startIcon: support,
            title: "Support",
            content: <GetInTouch />,
        },
        {
            startIcon: question,
            title: "General Questions",
            content: <GeneralQuestions />,
        },
        {
            startIcon: userShield,
            title: "Advertising Policy",
            content: <AdvertisingPolicy />,
        },
        {
            startIcon: moneyBillTransfer,
            title: "Refund Policy",
            content: <RefundPolicy />,
        },
    ];

    return (
        <motion.main {...fade} className="main support-page">
            {/* <Navbar title="support" isPublic={isPublic} /> */}
            <Tabs tabs={tabs} />
        </motion.main>
    );
}
