import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { CSVLink } from "react-csv";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";

export default function StatisticsCSV(tab) {
  const {
    requestedData: { campaignStatistics },
  } = useGlobalContext();
  const { exportIcon } = localData.svgs;
  const [csvReport, setCsvReport] = useState({
    filename: "file.csv",
    headers: [],
    data: [],
  });
  useEffect(() => {
    handleoDwnload();
  }, [campaignStatistics]);

  const formatDataToCSV = () => {
    let csvData = [];
    const type = tab.tab.toLowerCase();
    const data = campaignStatistics[type];

    const mainColumnNames = {
      geo: { label: "Country Code" },
      ads: { label: "Banner" },
      audience: { label: "Audience" },
    };
    Object.keys(data || {}).forEach((key) => {

        
      const item = data[key];
      const colName = mainColumnNames[type].label;
      const colVal = key;
      let csvRow = {
        Clicks: item.clicks,
        Impressions: item.impressions,
        AmountSpent: item.spent,
        CTR: item.ctr.toFixed(2),
        CPC: item.cpc.toFixed(2),
      };
      csvRow[colName] = colVal;
      csvData.push(csvRow);
    });
    const headers = {
      geo: [
        { label: "Country Code", key: "Country Code" },
        { label: "Impressions", key: "Impressions" },
        { label: "Clicks", key: "Clicks" },
        { label: "CTR", key: "CTR" },
        { label: "Ad Spent", key: "AmountSpent" },
        { label: "CPC", key: "CPC" },
      ],
      ads: [
        { label: "Banner", key: "Banner" },
        { label: "Impressions", key: "Impressions" },
        { label: "Clicks", key: "Clicks" },
        { label: "CTR", key: "CTR" },
        { label: "Amount Spent", key: "AmountSpent" },
        { label: "CPC", key: "CPC" },
      ],
      audience: [
        { label: "Audience", key: "Audience" },
        { label: "Impressions", key: "Impressions" },
        { label: "Clicks", key: "Clicks" },
        { label: "CTR", key: "CTR" },
        { label: "Amount Spent", key: "AmountSpent" },
        { label: "CPC", key: "CPC" },
      ],
    };

    return { data: csvData, headers: headers[type], type: type };
  };

  const handleoDwnload = () => {
    const { data, headers, type } = formatDataToCSV();
    const csvReport = {
      filename: `campaign_statistics_${type}.csv`,
      headers: headers,
      data: data,
    };
    setCsvReport(csvReport);
  };

  return (
    <div className={`csv-btn-link-wrapper`}>
      <CSVLink {...csvReport} className={`csv-btn-link`}>
        <Button
          size="sm"
          className="csv-btn"
          color="secondary"
          name="Export-stats"
          startIcon={exportIcon}
        />
      </CSVLink>
    </div>
  );
}
