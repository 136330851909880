import React, { useState, useEffect } from "react";
import {
  Tabs as CustomTabs,
  Field,
  Button,
  CopyTextButton,
  Modal,
} from "../../../../../components";
import { useGlobalContext } from "../../../../../context";
import localData from "../../../../../localData";
import { v4 as uuidv4 } from "uuid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import Joyride, { STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { Grid, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetch from "../../../../../hooks/useFetch";
import { auth } from "../../../../../config/firebase";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1f8352", // Change the border color here
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1f8352", // Change the hover border color here
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1f8352", // Change the focused border color here
          },
        },
      },
    },
  },
});

const { arrowLeft, arrowRight, TrackingPage, BloomingImage, email } =
  localData.svgs;
const { pixel, preloader, preloaderBig } = localData.images;

export default function CreateConversionTab({
  setActiveTab,
  activeTab,
  callbackFromParent,
  type,
}) {
  const [showCreateConversionModal, setShowCreateConversionModal] =
    useState("none");

  const { trackingState, requestedData } = useGlobalContext();

  const { isEditingMode } = trackingState;

  const navigate = useNavigate();

  return (
    <div className="create-conversion-tab">
      <CustomTabs
        tabsProps={{}}
        tabs={[
          {
            title: "Track an event",
            // startImageIcon: pixel,
            content: <TrackAnEventContent type={type} />,
            id: "track-an-event-2983jfosfod",
          },
        ]}
        title="Create conversion"
        // indicatorIcon={question}
        className="create-conversion-tabs"
      />
      <br />
      <div className="create-conversion-tab-footer">
        <div className="btn-group">
          {/* <Button
                        color="pale-dark"
                        // variant="contained"
                        startIcon={arrowLeft}
                        name="back"
                        size="sm"
                        onClick={() => setActiveTab(0)}
                    /> */}
          <Button
            color="pale-dark"
            variant="contained"
            name={`${"Complete"}`}
            size="md"
            onClick={() => {
              navigate("/tracking");
            }}
            className="complete-btn"
            style={{ background: "#2aab7e" }}
          />

          {/* <Modal
                        Child={CreateConversionModal}
                        className="create-conversion-modal tracking-modal modal-dialog-centered"
                        display={showCreateConversionModal}
                        setDisplay={setShowCreateConversionModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                className="next-step-btn"
                                name="Next"
                                endIcon={arrowRight}
                                size="sm"
                                variant="contained"
                                color="pale-dark"
                                onClick={() => setShowCreateConversionModal("block")}
                            />
                        )}
                    ></Modal> */}
        </div>
      </div>
    </div>
  );
}

const CreateConversionJoyride = ({ activeTab }) => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Create a conversion script. Choose a recognizable name for the action
          (e.g., "Form Sign Up").
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Creating Conversion Script",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Click "Generate Script" to view the conversion code.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#create-conversion-tutorial-2",
      title: "Generating Conversion Script",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Ask the developer to place the conversion script on the appropriate
          conversion trigger (e.g., form sign-ups). Contact support for help.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#create-conversion-tutorial-3",
      title: "Implementing Conversion Script",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          View conversion data on the main dashboard under "Campaigns." If no
          conversions appear after a few days, double-check the tracking
          implementation or contact support.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Viewing Conversion Data",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "createConversionTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("createConversionTutorial"))
      return;
    if (activeTab == 1) {
      setTimeout(() => {
        setSteps((prev) => ({ ...prev, run: true }));
      }, 500);
    }
  }, [activeTab]);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

const GoogleTagManager = () => {
  const {
    conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
    trackingState,
    requestedData,
    successAlert,
    googleTrackerId,
    errorAlert,
    typeOfGTMStep,
    createConversionData,
  } = useGlobalContext();
  const { isEditingMode, editingTagId, state, setState } = trackingState;
  const { gtm } = localData.images;
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { submitUserRequestPixelCreationGTM } = useFetch();

  const handleReqest = async () => {
    setIsRequestLoading(true);
    try {
      const tempRawr = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
        trackerid: googleTrackerId,
      };

      const data = await submitUserRequestPixelCreationGTM(() => {}, tempRawr);
      successAlert(data.res_msg);
    } catch (error) {
      console.log(error);
      errorAlert("Unable to send request, please try again later");
    }
    setIsRequestLoading(false);
  };

  console.log(trackAnEventScriptTag, "trackAnEventScriptTag123");

  return (
    <div className="code">
      <br />
      <div className="row">
        <div className="label">
          <img src={gtm} alt="" />
          Google Tag Manager (GTM)
        </div>
        <CopyTextButton
          text={trackAnEventScriptTag?.htmlpixel}
          disabled={!trackAnEventScriptTag?.htmlpixel}
          size="medium"
        />
      </div>

      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {trackAnEventScriptTag?.isLoading ? (
          "Loading..."
        ) : trackAnEventScriptTag?.htmlpixel ? (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {trackAnEventScriptTag?.htmlpixel}
          </SyntaxHighlighter>
        ) : (
          "Filled up the form above to generate the event script"
        )}
      </div>

      {typeOfGTMStep !== "manual" && (
        <div>
          <br />
          <br />
          <br />
          {/* <p className="muted" style={{ fontSize: "14px" }}>
            * Request for assistance in installing the coversion pixel in GTM{" "}
          </p> */}
          <Button
            onClick={handleReqest}
            size="sm"
            color="marketplace"
            variant="contained"
            name="Request assistance"
            style={{ marginTop: "1rem" }}
            disabled={isRequestLoading}
          />
          <br />
          <br />
        </div>
      )}

      {/* <Button variant="contained" name="Request" color="marketplace" size="sm"/> */}
    </div>
  );
};
const PixelContent = () => {
  const {
    conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
    trackingState,
  } = useGlobalContext();
  const { isEditingMode, editingTagId, state, setState } = trackingState;

  return (
    <div className="code">
      <br />
      <div className="row">
        <div className="label">
          <img src={pixel} alt="" />
          Tracking Pixel
        </div>
        <CopyTextButton
          text={trackAnEventScriptTag?.value}
          disabled={!trackAnEventScriptTag?.value}
          size="medium"
        />
      </div>

      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {trackAnEventScriptTag?.isLoading ? (
          "Loading..."
        ) : trackAnEventScriptTag?.value || state.tag ? (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {trackAnEventScriptTag?.value}
          </SyntaxHighlighter>
        ) : (
          "Filled up the form above to generate the event script"
        )}
      </div>
    </div>
  );
};
const ServerToServer = () => {
  const {
    conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
    trackingState,
  } = useGlobalContext();
  const { isEditingMode, editingTagId, state, setState } = trackingState;

  const { serverToServer } = localData.images;

  return (
    <div className="code">
      <br />
      <div className="row">
        <div className="label">
          <img src={serverToServer} alt="" />
          Server-to-server Pixel
        </div>
        <CopyTextButton
          text={trackAnEventScriptTag?.s2s}
          disabled={!trackAnEventScriptTag?.s2s}
          size="medium"
        />
      </div>

      <div className="script-wrapper" style={{ fontSize: "12px" }}>
        {trackAnEventScriptTag?.isLoading ? (
          "Loading..."
        ) : trackAnEventScriptTag?.s2s ? (
          <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
            {trackAnEventScriptTag?.s2s}
          </SyntaxHighlighter>
        ) : (
          "Filled up the form above to generate the event script"
        )}
      </div>
    </div>
  );
};

export const TrackAnEventContent = ({ type }) => {
  const {
    CreateConversionPixelRequest,
    EditConversionPixelRequest,
    conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
    requestedData,
    requestedData: {
      userProfileData: { currentOrganization },
    },
    trackingState,
    _conversionTagEmail,
    setTrackingPageTab,
    createConversionData,
    conversionOptions,
  } = useGlobalContext();

  const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel, serverToServer } =
    localData.images;

  const { isEditingMode, editingTagId, state, setState } = trackingState;

  const resetState = () => {
    setState({ tagName: "", url: "", contains: "", type: "" });
  };

  useEffect(() => {
    resetState();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const [isEditLoading, setIsEditLoading] = useState(false);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isEditingMode) {
      EditConversionPixelRequest({
        tagId: editingTagId,
        tagName: state.tagName,
        pixelType: pixelType,
        setIsLoading: setIsEditLoading,
      });
    } else {
      CreateConversionPixelRequest({
        tagName: state.tagName,
        pixelType: pixelType,
        tracker_group: type === "conversions" ? "0" : "1",
      });
      // setTrackingPageTab(type === "conversions" ? 1 : 0)
    }

    if (type === "conversions") {
      return setTrackingPageTab(1);
    } else {
      return setTrackingPageTab(0);
    }
  };

  const [isSendInstructionsLoading, setIsSendInstructionsLoading] =
    useState(false);

  const [developerEmail, setDeveloperEmail] = useState("");

  const [pixelType, setPixelType] = useState("");

  const handleChange = (event) => {
    setPixelType(event.target.value);
  };

  const [scriptData, setScriptData] = useState({
    isLoading: false,
    value: "",
  });

  useEffect(() => {
    setScriptData(trackAnEventScriptTag);
  }, [trackAnEventScriptTag]);

  const [activeTab, setActiveTab] = useState("gtm");

  const renderComponent = () => {
    switch (createConversionData.conversionMethod) {
      case "gtm":
        return <GoogleTagManager />;
      case "pixel":
        return <PixelContent />;
      case "server":
        return <ServerToServer />;
      default:
        return <GoogleTagManager />;
    }
  };
  
  return (
    <div className="step-1">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className="code" id="create-conversion-tutorial-3">
            <div>
              {/* Render Selected Component */}
              <div className="tab-content mt-3">{renderComponent()}</div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <p>Request Assistance (Optional)</p>
          <br />
          <p>
            If you’d like your developer to handle this, we can send detailed
            instructions to their email.
          </p>
          <br />
          {trackAnEventScriptTag.value && (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const data = {
                    uuid: auth.currentUser?.uid,
                    organizationID:
                      requestedData?.userProfileData?.currentOrganization?.id,
                    pixelName: createConversionData.conversionTagName,
                    pixelId: String(
                      conversionOptions.trackAnEventScriptTag.tagID
                    ),
                    developerEmail: developerEmail,
                  };
                  _conversionTagEmail({
                    setIsLoading: setIsSendInstructionsLoading,
                    ...data,
                  });
                }}
                className="instructions-form"
              >
                <h4 className="instructions-form-title">
                  {email} Send Detailed Instructions to your Developer{" "}
                </h4>
                <p className="instructions-form-description description">
                  Enter your developer’s email address, and we’ll send them the
                  script and instructions for implementation.
                </p>
                <div className="wrapper">
                  <ThemeProvider theme={theme}>
                    <TextField
                      required={true}
                      type="email"
                      label="Your developer email"
                      name="tagName"
                      value={developerEmail}
                      placeholder="Your developer email"
                      size="small"
                      onChange={(e) => setDeveloperEmail(e.target.value)}
                      color="#ffff"
                      fullWidth
                      id="tagName"
                    />
                    <br />
                  </ThemeProvider>
                  <br />
                  {/* <Field
                                required={true}
                                type="email"
                                label="Your developer email"
                                name="tagName"
                                value={developerEmail}
                                color="secondary"
                                placeholder=""
                                // required={true}
                                size="sm"
                                callback={(e) => setDeveloperEmail(e.target.value)}
                            /> */}
                  <Button
                    style={{ width: "100%" }}
                    id="instructions-btn"
                    name="Send Instructions"
                    variant="contained"
                    color="dark"
                    icon={
                      isSendInstructionsLoading ? (
                        <span className="endIcon">
                          <img src={preloaderBig} />
                        </span>
                      ) : null
                    }
                    // disabled={!state.tagName}
                  />
                </div>
              </form>
            </>
          )}
        </Grid>
      </Grid>

      <br />
      <br />
    </div>
  );
};
