import React, { useEffect, useState } from "react";
import Joyride, { STATUS } from "react-joyride";

export default function TrackingJoyride() {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Welcome to the tracking guide. Follow these steps to track
          conversions.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Welcome to Tracking Guide",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Click here to view the Blockchain-Ads tracking script.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#tracking-tutorial-2",
      title: "Viewing Tracking Script",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "trackingTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("trackingTutorial")) return;
    setTimeout(() => {
      setSteps((prev) => ({ ...prev, run: true }));
    }, 500);
  }, []);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
}
