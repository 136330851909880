import React, { useState, useEffect, useRef } from "react";
import {
  TableSkeleton,
  Select as CustomSelect,
  Button,
  Pagination,
  Modal,
  Search,
} from "../../../components/index.js";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../localData.js";
import { useGlobalContext } from "../../../context.js";
import { auth } from "../../../config/firebase.js";
import useFetch from "../../../hooks/useFetch.js";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter.jsx";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CampaignInfo from "../../campaigns/campaigns-table/campaign-info/CampaignInfo.jsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

let isStatExist = false;

export default function AdminCampaigns() {
  const { orgPerformanceReport, adminGetCampaigns } = useFetch();
  const { sortDown, sortUp, sortIcon, exportIcon, arrowDown, arrowUp } =
    localData.svgs;
  const {
    requestedData,
    requestedData: {
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    getLoadCampaignData,
    isLoading,
    campaignsState,
    markedCampaigns,
    setMarkedCampaigns,
    statusOption,
    successAlert,
    errorAlert,
    getAdvertiserPixelStatsRequest,
    conversionOptions,
    selectedConversionTag,
    setSlectedConversionTag,
  } = useGlobalContext();
  const {
    campaignsPage,
    setCampaignsPage,
    campaignsPageSize,
    setCampaignsPageSize,
    campaignsTotalPages,
    setCampaignsTotalPages,
    campaignsItemsPerPage,
    setCampaignsItemsPerPage,
    campaignSelectionRange,
  } = campaignsState;

  const { deleteCampaigns, playOrPauseCampaigns } = useFetch();
  const [loading, setLoading] = useState(false);
  const requestPlayOrPauseCampaigns = async (actionType = "active") => {
    handleClose();
    setLoading(true);
    const campaignsToModify = markedCampaigns.filter((campaign) => {
      if (actionType === "active") {
        return campaign.isPaused === true;
      } else if (actionType === "pause") {
        return !campaign.isPaused;
      }
      return false;
    });

    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const userEmail = requestedData.userProfileData.userEmail;

    const temRaw = {
      uuid: auth.currentUser.uid,
      userName: requestedData.userProfileData.userName,
      organizationID,
      adminEmail: userEmail,
      campaignIds: campaignsToModify.map((item) => {
        return item.id;
      }),
      campaignNames: campaignsToModify.map((item) => {
        return item.name;
      }),
      toPlayCampaigns: actionType === "active" ? true : false,
      campaignEndDates: campaignsToModify.map((item) => {
        return item.end_date ? item.end_date : null;
      }),
      isRevive: true,
    };
    try {
      const data = await playOrPauseCampaigns(() => {}, temRaw);
      await getLoadCampaignData(
        requestedData.userProfileData.currentOrganization.id,
        1,
        15
      );
      const successMessage =
        actionType === "active"
          ? "Campaigns successfully activated!"
          : "Campaigns successfully paused!";
      successAlert(successMessage);
      console.log(data, "=playOrPauseCampaign= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=playOrPauseCampaign= request error");
    }
    setLoading(false);
  };
  const [page, setPage] = useState(1);

  const handleDeleteCampaigns = async () => {
    handleClose();
    const temRaw = {
      uuid: auth.currentUser.uid,
      userName: requestedData.userProfileData.userName,
      organizationID: requestedData.userProfileData.currentOrganization.id,
      campaignIds: markedCampaigns.map((item) => {
        return item.id;
      }),
    };
    setLoading(true);
    try {
      const data = await deleteCampaigns(() => {}, temRaw);
      await getLoadCampaignData(
        requestedData.userProfileData.currentOrganization.id,
        1,
        15
      );
      successAlert("Campaigns deleted successfully");
      console.log(data, "=deleteCampaign= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=deleteCampaign= request error");
    }
    setLoading(false);
  };

  const { formatNumber, formatCurrency, addCommas } = useFormatter();

  const [organizationId, setOrganizationId] = useState(null);

  const campaignTableRef = useRef(null);

  const handlePagination = (nextPage = 1) => {
    console.log("pagi");
    setPage(nextPage);
    fetchData({ page: nextPage });
  };

  useEffect(() => {
    setCampaignsPageSize(
      campaignsItemsPerPage.find((item) => item.isSelected).title
    );
  }, [campaignsItemsPerPage]);

  useEffect(() => {
    if (!organizationId) return;
    getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
  }, [campaignsPage]);

  useEffect(() => {
    if (!organizationId) return;
    getLoadCampaignData(organizationId, 1, campaignsPageSize);

    const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
    localStorage.setItem(
      "campaignsStates",
      JSON.stringify({
        ...campaignsStates,
        pageSize: campaignsPageSize,
      })
    );
  }, [campaignsPageSize, statusOption]);

  useEffect(() => {
    const id = requestedData.userProfileData.currentOrganization.id;
    if (!id) return;
    setOrganizationId(id);
    // if(requestedData.campaignData.isRequested) return
    // getLoadCampaignData(id, 1, 10);
    getAdvertiserPixelStatsRequest();
  }, [requestedData.userProfileData]);

  useEffect(() => {
    const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
    if (!campaigns.length) return;
    const campaignsTotalPages = Math.ceil(
      requestedData.campaignData.dataSize / campaignsPageSize
    );
    setCampaignsTotalPages(campaignsTotalPages);
  }, [requestedData.campaignData]);

  const { settings, eyeSolid, close } = localData.svgs;
  const { textPreloader } = localData.images;

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const handleMarkedCampaigns = (campaign, isMarked) => {
    let tempMarkedCampaigns = [...markedCampaigns];
    if (isMarked) {
      tempMarkedCampaigns = [...tempMarkedCampaigns, campaign];
    } else {
      tempMarkedCampaigns = tempMarkedCampaigns.filter(
        (item) => item.id !== campaign.id
      );
    }

    setMarkedCampaigns(tempMarkedCampaigns);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleAllMarkedCampaigns = (isMarked) => {
    let tempMarkedCampaigns = [];
    if (isMarked) tempMarkedCampaigns = [...currentOrganizationCampaigns];
    setMarkedCampaigns(tempMarkedCampaigns);
  };

  useEffect(() => {
    const sameLength =
      markedCampaigns.length === currentOrganizationCampaigns.length;
    setIsChecked(sameLength);
  }, [markedCampaigns]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [sortedCampaigns, setSortedCampaigns] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [sort, setSort] = useState({
    type: "status",
    orderDest: "desc",
  });

  useEffect(() => {
    fetchData();
  }, [sort]);

  const fetchData = async (props = {}) => {
    setLoading(true);
    if (!auth.currentUser) return;
    let raw = {
      uuid: auth.currentUser.uid,
      ...props,
    };
    console.log(sort);
    console.log("fetching data");
    if (sort.type != "") {
      raw = { ...raw, ...sort };
    }

    const campaignResult = await adminGetCampaigns(() => {}, raw);

    setSortedCampaigns(campaignResult.res_data);
    setTotalPages(Math.ceil(campaignResult.total_items / 50));
    // setIsOrganizationsLoading(false);
    setLoading(false);
  };

  const handleOnClick = (type = "") => {
    const a = sort.orderDest == "asc" ? "desc" : "asc";
    console.log("setting sort");
    setSort({
      type: type,
      orderDest: a,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    const hasArchived = markedCampaigns.some((campaign) => campaign.isArchived);
    if (hasArchived) {
      errorAlert(
        "Action cannot be performed because one or more campaigns are archived."
      );
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSortIconSvg = (columnKey) => {
    if (sort.type === columnKey) {
      return sortConfig.orderDest === "asc" ? sortUp : sortDown;
    }
    return sortIcon;
  };

  useEffect(() => {
    setConversionsData(
      conversionOptions.advertiserPixelStats.value.filter(
        (item) => item.tracker_group === "0"
      )
    );
  }, [conversionOptions]);

  const [conversionsData, setConversionsData] = useState(null);

  const [anchorConversions, setAnchorConversions] = React.useState(null);

  const openConversions = Boolean(anchorConversions);
  const handleClickConversions = (event) => {
    setAnchorConversions(event.currentTarget);
  };
  const handleCloseConversions = (item) => {
    if (item === "close") {
      return setAnchorConversions(null);
    }
    setSlectedConversionTag(item);
    setAnchorConversions(null);
  };

  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [reportURL, setReportURL] = useState(null);

  const orgPerformanceReportRequest = async () => {
    setIsRequestLoading(true);
    const tempRaw = {
      organizationID: requestedData.userProfileData.currentOrganization.id,
      startDate: formatDate(campaignSelectionRange.startDate),
      endDate: formatDate(campaignSelectionRange.endDate),
      campaignIds: markedCampaigns.map((campaign) => campaign.id).join(","),
    };

    try {
      const data = await orgPerformanceReport(() => {}, tempRaw);
      if (data.res_data) {
        setReportURL(data.res_data);
      }
    } catch (err) {
      errorAlert(
        err?.response?.data?.res_msg ||
          "Unable to generate report, please try again later."
      );
    }
    setIsRequestLoading(false);
  };

  const openReport = () => {
    if (reportURL) {
      window.open(reportURL, "_blank");
      setReportURL(null);
    }
  };

  const [exportElement, setExportElement] = useState(null);
  const exportOpen = Boolean(exportElement);

  const handleClickExport = (event) => {
    setExportElement(event.currentTarget);
  };

  const handleCloseExport = () => {
    setExportElement(null);
    setReportURL(null);
  };

  const [selectedConversion, setSelectedConversion] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isQueryExist, setIsQueryExist] = useState(false);
  const [query, setQuery] = useState("");
  const handleFind = (query) => {
    console.log("find");
    fetchData({ search: query });
    setIsQueryExist(true);
    setQuery(query);
  };

  const resetFind = () => {
    setIsQueryExist(false);
    setQuery("");
    fetchData();
  };
  return (
    <div className="modal-dialog-centered modal-fullscreen modal-dialog-scrollable accounts">
      <div className="modal-body" style={{ padding: "0px 1rem 20px" }}>
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">CAMPAIGNS</h5>
          <Search
            {...{
              variant: "contained",
              className: `accounts-search`,
              isOpen: isSearchOpen,
              setIsOpen: setIsSearchOpen,
              size: "sm",
              color: "purple",
              disabled: loading,
              callback: handleFind,
              placeholder: "Filter campaign name / ID, Organization Name",
            }}
          />
          {isQueryExist && !loading && (
            <div className="query-badge">
              <div className="query-badge-text">{query}</div>
              <Button
                variant="circle"
                icon={close}
                size="sm"
                color="secondary"
                onClick={() => {
                  resetFind();
                }}
              />
            </div>
          )}
        </div>
      <div
        className=""
        ref={campaignTableRef}
        id="tour-step-2"
      >
        <br />
        <div className={`scroll `} style={{ padding: 0 }}>
          {loading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table id="campaigns-table" className="table campaigns-table">
              <thead>
                <tr className="table-row">
                  <th className="table-th-details text-center">
                    <div
                      className="details-icon"
                      style={{ margin: "0 auto" }}
                      data-tooltip-id="tooltip-table-details"
                      data-tooltip-content={`See preview of the campaign details.`}
                    >
                      {eyeSolid}
                    </div>
                  </th>

                  <th
                    className="table-th-name text-start"
                    style={{ width: "20%" }}
                  >
                    <span
                      data-tooltip-id="tooltip-table-name"
                      data-tooltip-content={`The name of your campaign.`}
                    >
                      Name
                    </span>
                  </th>

                  <th
                    className="table-th-name text-start"
                    style={{ width: "20%" }}
                  >
                    <span
                      data-tooltip-id="tooltip-table-name"
                      data-tooltip-content={`The name of the organization.`}
                    >
                      Organization Name
                    </span>
                  </th>
                  {/* <th
                    className="table-th-settings text-center"
                    // style={{ width: "20%" }}
                    style={{ width: "0" }}
                    // title="Activate or Pause your campaign"
                  >
                    <div
                      className="settings-icon"
                      style={{ margin: "0 auto" }}
                      data-tooltip-id="tooltip-table-settings"
                      data-tooltip-content={`Activate or Pause your campaign.`}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {settings}
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => requestPlayOrPauseCampaigns("pause")}
                      >
                        Stop
                      </MenuItem>
                      <MenuItem
                        onClick={() => requestPlayOrPauseCampaigns("active")}
                      >
                        Play
                      </MenuItem>
                      <MenuItem onClick={handleDeleteCampaigns}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </th> */}

                  <th
                    className="table-th-status text-center"
                    onClick={() => handleOnClick("status")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-status"
                      data-tooltip-content={`Sort by campaign status (working or stopped).`}
                      className="with-sort"
                    >
                      Status
                      <span className="sort-btn">
                        {getSortIconSvg("status")}
                      </span>
                    </span>
                  </th>

                  <th
                    className="table-th-impressions text-center"
                    onClick={() => handleOnClick("impressions")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-impressions"
                      data-tooltip-content={`How many times your ads have been seen.`}
                      className="with-sort"
                    >
                      Impressions
                      <span className="sort-btn">
                        {getSortIconSvg("impressions")}
                      </span>
                    </span>
                  </th>
                  <th
                    className="table-th-clicks text-center"
                    onClick={() => handleOnClick("clicks")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-clicks"
                      data-tooltip-content={`How many times your ads have been clicked.`}
                      className="with-sort"
                    >
                      Clicks{" "}
                      <span className="sort-btn">
                        {getSortIconSvg("clicks")}
                      </span>
                    </span>
                  </th>
                  <th
                    className="table-th-ctr text-center"
                    onClick={() => handleOnClick("CTR")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-ctr"
                      data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
                      className="with-sort"
                    >
                      CTR{" "}
                      <span className="sort-btn">{getSortIconSvg("CTR")}</span>
                    </span>
                  </th>
                  <th
                    className="table-th-ad-spend text-center"
                    onClick={() => handleOnClick("amount_spent")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-ad-spend"
                      data-tooltip-content={` How much budget has been spent.`}
                      className="with-sort"
                    >
                      Ad Spend{" "}
                      <span className="sort-btn">
                        {getSortIconSvg("amount_spent")}
                      </span>
                    </span>
                  </th>
                  <th
                    className="table-th-cpc text-center"
                    onClick={() => handleOnClick("CPC")}
                  >
                    <span
                      data-tooltip-id="tooltip-table-cpc"
                      data-tooltip-content={`How much you pay for each click.`}
                      className="with-sort"
                    >
                      CPC{" "}
                      <span className="sort-btn">{getSortIconSvg("CPC")}</span>
                    </span>
                  </th>
                  <th
                    className="table-th-conversions text-center"
                    style={{ width: 0 }}
                  >
                    <span
                      data-tooltip-id="tooltip-table-conversions"
                      data-tooltip-content={`Total amount of the conversions.`}
                      className="with-sort"
                      style={{ maxHeight: "30px" }}
                    >
                      <Button
                        id="basic-button"
                        aria-controls={
                          openConversions ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openConversions ? "true" : undefined}
                        onClick={handleClickConversions}
                        style={{ color: "black" }}
                        endIcon={!openConversions ? arrowDown : arrowUp}
                      >
                        {!selectedConversion.length
                          ? "Conversions"
                          : selectedConversion}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorConversions}
                        open={openConversions}
                        onClose={() => handleCloseConversions("close")}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          value={"all"}
                          onClick={() => {
                            handleCloseConversions("all");
                            setSelectedConversion("");
                          }}
                          sx={{
                            background:
                              selectedConversionTag === "all"
                                ? "lightgray"
                                : "none",
                          }}
                        >
                          All
                        </MenuItem>
                        {(conversionsData || []).map((item, index) => (
                          <MenuItem
                            value={parseFloat(item.trackerid)}
                            key={index}
                            onClick={() => {
                              handleCloseConversions(item.trackerid);
                              setSelectedConversion(item.name);
                            }}
                            sx={{
                              background:
                                selectedConversionTag === item.trackerid
                                  ? "lightgray"
                                  : "none",
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Menu>
                      <span
                        className="sort-btn"
                        onClick={() => handleOnClick("conversions")}
                      >
                        {getSortIconSvg("conversions")}
                      </span>
                    </span>
                    <Tooltip
                      id="tooltip-table-conversions"
                      className="custom-tooltip"
                    />
                  </th>
                  {/* {isWithSmartContractAddress && ( */}
                  <>
                    <th
                      className="table-th-txn-value text-center"
                      onClick={() => handleOnClick("transactionValue")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-txn-value"
                        data-tooltip-content={`Total amount of the transaction.`}
                        className="with-sort"
                      >
                        Txn Value{" "}
                        <span className="sort-btn">
                          {getSortIconSvg("transactionValue")}
                        </span>
                      </span>
                      <Tooltip
                        id="tooltip-table-txn-value"
                        className="custom-tooltip"
                      />
                    </th>
                    <th
                      className="table-th-transactions text-center"
                      onClick={() => handleOnClick("transactions")}
                    >
                      <span className="with-sort">
                        Transactions{" "}
                        <span className="sort-btn">
                          {getSortIconSvg("transactions")}
                        </span>
                      </span>
                    </th>
                  </>
                  {/* )} */}
                </tr>
              </thead>

              <tbody>
                {!sortedCampaigns.length === 0 ? (
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="14">
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {sortedCampaigns.map((item, index) => (
                      <CampaignRow
                        key={index}
                        item={item}
                        index={index}
                        handleMarkedCampaigns={handleMarkedCampaigns}
                      />
                    ))}
                  </>
                )}
              </tbody>
            </table>
          )}
          {!loading &&
            !requestedData.campaignData.currentOrganizationCampaigns.length && (
              <div className="no-data-cover"></div>
            )}
        </div>
        {!requestedData.campaignData.currentOrganizationCampaigns.length ? (
          ""
        ) : (
          <div className={`pagination-wrapper  ${loading ? "on-hold" : ""}`}>
            <Pagination
              callback={handlePagination}
              currentPage={page}
              totalPages={totalPages}
              isLoading={loading}
            />
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

const showStopBtn = [6, 7, 2];
const showPlayBtn = [8, 1, 4, 5];

const CampaignRow = ({ item, index, handleMarkedCampaigns }) => {
  const {
    showCampaingWizardDrawer,
    setShowCampaignWizardDrawer,
    campaignWizardStatus,
    setCampaignWizardStatus,
    errorAlert,
    successAlert,
    campaignState,
    setCampaignState,
    requestedData,
    // activeCampaign,
    // setActiveCampaign,

    activeInfoId,
    setActiveInfoId,
    isDarkModeEnabled,
    getLoadCampaignData,
    setCreatives,
    campaignsState,
    conversionOptions: { advertiserPixelStats },
    markedCampaigns,
    selectedConversionTag,
  } = useGlobalContext();
  const { campaignTabs, setCampaignTabs } = campaignsState;
  const {
    arrowDown,
    arrowUp,
    settings,
    play,
    stop,
    penV2,
    trashV2,
    close,
    info,
    copy,
    copyIcon,
  } = localData.svgs;
  const { removeAlias, formatDate, addCommas, formatNumber, formatCurrency } =
    useFormatter();

  // const [isPaused, setIsPaused] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState({
    isStopped: false,
    status: "unknown",
    statusNumber: null,
  });

  const [campaignBtns, setCampaignBtns] = useState({
    playBtn: {
      isReadOnly: true,
      message: "",
      isPlayBtnShown: false,
    },
    stopBtn: {
      isReadOnly: true,
      message: "",
      isStopBtnShown: false,
    },
    editBtn: {
      isReadOnly: true,
      message: "",
    },
    deleteBtn: {
      isReadOnly: true,
      message: "",
    },
    copyBtn: {
      isReadOnly: true,
      message: "",
    },
  });
  const [itemStatus, setitemStatus] = useState("");

  useEffect(() => {
    const isEditBtnShown = true;
    const isEditBtnReadOnly = [].includes(campaignDetails.statusNumber);

    const isDeleteBtnShown = true;
    const isDeleteBtnReadOnly = [1].includes(campaignDetails.statusNumber);

    const isStopBtnShown = showStopBtn.includes(campaignDetails.statusNumber);
    const isStopBtnReadOnly = false;

    const isPlayBtnShown = showPlayBtn.includes(campaignDetails.statusNumber);
    const isPlayBtnReadOnly = false;

    // const isLowBalance = requestedData.userProfileData.currentOrganization.balance < 330;
    const isLowBalance = false;

    const message = (
      <span>
        Can't be modified <br /> due to it's {item.status} status
      </span>
    );

    setCampaignBtns({
      ...campaignBtns,

      playBtn: {
        isReadOnly: isPlayBtnReadOnly || isLowBalance,
        message: isPlayBtnReadOnly ? message : "Insufficient balance",
        isPlayBtnShown,
      },
      stopBtn: {
        isReadOnly: isStopBtnReadOnly || isLowBalance,
        message: isStopBtnReadOnly ? message : "Insufficient balance",
        isStopBtnShown,
      },
      editBtn: {
        isReadOnly: isEditBtnReadOnly,
        message: message,
        isEditBtnShown,
      },
      deleteBtn: {
        isReadOnly: isDeleteBtnReadOnly,
        message: message,
        isDeleteBtnShown,
      },
    });
  }, [campaignDetails]);

  useEffect(() => {
    setitemStatus(item.status);
    setCampaignDetails((prev) => ({
      ...prev,
      isStopped: item.isPaused,
      status: item.status,
      statusNumber: item.statusNumber,
    }));
  }, []);

  // FETCH
  const [campaignInfoData, setCampaignInfoData] = useState({});
  const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const { playOrPauseCampaign, deleteCampaign } = useFetch();

  // PLAY OR PAUSE CAMPAIGN
  const getPlayOrPauseCampaign = async ({
    campaignId,
    toPlayCampaign,
    campaignName,
    isRevive,
    reviveID,
  }) => {
    // if (!auth.currentUser) return;
    setIsPlayPauseLoading(true);

    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const userEmail = requestedData.userProfileData.userEmail;

    const temRaw = {
      uuid: auth.currentUser.uid,
      userName: requestedData.userProfileData.userName,
      organizationID,
      adminEmail: userEmail,
      campaignId,
      campaignName,
      toPlayCampaign,
      isRevive: isRevive,
      reviveID,
    };
    try {
      const data = await playOrPauseCampaign(() => {}, temRaw);
      const {
        isPaused,
        status = "unknown",
        statusNumber = null,
      } = data.res_data;
      setCampaignDetails((prev) => ({
        ...prev,
        isStopped: isPaused,
        status: status.toLowerCase(),
        statusNumber,
      }));
      // getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, 1, 15);
      setitemStatus(data.res_data.status.toLowerCase());
      console.log(data, "=playOrPauseCampaign= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=playOrPauseCampaign= request error");
    }
    setIsPlayPauseLoading(false);
  };

  const handleDeleteCampaign = async (campaign) => {
    setIsDeleteLoading(true);

    const temRaw = {
      uuid: auth.currentUser.uid,
      userName: requestedData.userProfileData.userName,
      campaignName: campaign.name,
      organizationID: requestedData.userProfileData.currentOrganization.id,
      campaignId: campaign.id,
      reviveID: campaign.reviveID,
    };
    try {
      const data = await deleteCampaign(() => {}, temRaw);
      successAlert("Campaign deleted successfully");
      getLoadCampaignData(
        requestedData.userProfileData.currentOrganization.id,
        1,
        15
      );
      console.log(data, "=deleteCampaign= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=deleteCampaign= request error");
    }
    setIsDeleteLoading(false);
  };

  const [displayDeleteModal, setDisplayDeleteModal] = useState("none");

  const DeleteCampaignModal = ({ setIsOpen }) => {
    const closeModal = () => {
      setIsOpen(false);
    };
    return (
      <>
        <div className="modal-header">
          <Button
            className="btn-close"
            variant="circle"
            color="dark"
            size="sm"
            icon={close}
            onClick={closeModal}
          />
        </div>
        <div className="modal-header" style={{ padding: "0px 1rem 20px" }}>
          <h5 className="modal-title">Delete this campaign?</h5>
        </div>
        <div className="modal-footer">
          <Button
            variant="contained"
            color="light"
            style={{ marginRight: "10px" }}
            onClick={closeModal}
            name="cancel"
            size="sm"
          />
          <Button
            variant="contained"
            color="danger"
            onClick={() => {
              closeModal();
              handleDeleteCampaign(item);
            }}
            name="Confirm"
            size="sm"
          />
        </div>
      </>
    );
  };

  const [txnValue, setTxnValue] = useState("");
  const [txnValueIsLoading, setTxnValueIsLoading] = useState(false);

  const [isMarked, setIsMarked] = useState(false);

  useEffect(() => {
    const marked = markedCampaigns.find(
      (markedItem) => markedItem.id === item.id
    );
    setIsMarked(marked ? true : false);
  }, [markedCampaigns]);

  const navigate = useNavigate();

  return (
    <>
      <tr>
        <td className="table-td-hush">
          <Button
            variant="circle"
            size="sm"
            color="secondary"
            icon={activeInfoId === item.id ? arrowUp : arrowDown}
            onClick={(e) => {
              e.stopPropagation();
              setActiveInfoId(activeInfoId === item.id ? null : item.id);
            }}
          />
        </td>
        <td className="table-td-name text-start">
          <span>{removeAlias(item.name)}</span>
        </td>
        <td className="text-start">
          <span>{removeAlias(item.organizationBcaName)}</span>
        </td>
        {/* <td className="table-td-settings text-center">
          <div
            onClick={(e) => e.stopPropagation()}
            className="table-td-settings-wrapper"
            style={{ justifyContent: item.isArchived && "center" }}
          >
            {!item.isArchived && (
              <div
                className={`play-pause-btn-wrapper  ${
                  isPlayPauseLoading ? "on-hold spin-loading" : ""
                }`}
              >
                {campaignBtns.playBtn.isPlayBtnShown && (
                  <div
                    data-tooltip-id="play-btn-tooltip"
                    data-tooltip-html={
                      campaignBtns.playBtn.isReadOnly
                        ? ReactDOMServer.renderToStaticMarkup(
                            campaignBtns.playBtn.message
                          )
                        : ""
                    }
                  >
                    <Button
                      tooltipContent={"Play"}
                      variant="circle"
                      color={"success"}
                      size="sm"
                      icon={play}
                      onClick={() => {
                        // getPlayOrPauseCampaign(item.id, true);
                        getPlayOrPauseCampaign({
                          campaignId: item.id,
                          campaignName: removeAlias(item.name),
                          toPlayCampaign: true,
                          isRevive: item.isRevive,
                          reviveID: item.reviveID,
                        });
                      }}
                      className="play-pause"
                      disabled={campaignBtns.playBtn.isReadOnly}
                    />
                    <Tooltip id="play-btn-tooltip" className="custom-tooltip" />
                  </div>
                )}
                {campaignBtns.stopBtn.isStopBtnShown && (
                  <div
                    className="play-pause-btn-wrapper-inner"
                    data-tooltip-id="stop-btn-tooltip"
                    data-tooltip-html={
                      campaignBtns.stopBtn.isReadOnly
                        ? ReactDOMServer.renderToStaticMarkup(
                            campaignBtns.stopBtn.message
                          )
                        : ""
                    }
                  >
                    <Button
                      tooltipContent={`Stop`}
                      variant="circle"
                      color={"danger"}
                      size="sm"
                      icon={stop}
                      onClick={() => {
                        // getPlayOrPauseCampaign(item.id, false);
                        getPlayOrPauseCampaign({
                          campaignId: item.id,
                          campaignName: removeAlias(item.name),
                          toPlayCampaign: false,
                          isRevive: item.isRevive,
                          reviveID: item.reviveID,
                        });
                      }}
                      className="play-pause"
                      disabled={campaignBtns.stopBtn.isReadOnly}
                    />
                    <Tooltip id="stop-btn-tooltip" className="custom-tooltip" />
                  </div>
                )}
              </div>
            )}

            {!item.isArchived && (
              <div
                className={`edit-btn-wrapper ${
                  false ? "on-hold spin-loading" : ""
                }`}
                data-tooltip-id="edit-btn-wrapper-tooltip"
                data-tooltip-html={
                  campaignBtns.editBtn.isReadOnly
                    ? ReactDOMServer.renderToStaticMarkup(
                        campaignBtns.editBtn.message
                      )
                    : ""
                }
              >
                <Button
                  tooltipContent={"Edit"}
                  variant="circle"
                  // color="secondary"
                  color={isDarkModeEnabled ? "light" : "secondary"}
                  size="sm"
                  icon={penV2}
                  onClick={() => {
                    setCampaignState({
                      ...campaignState,
                      campaignId: item.id,
                      creatorEmail: requestedData.userProfileData.userEmail,
                      campaignInfo: {
                        ...campaignState.campaignInfo,
                        campaignName: item.name,
                        targetURL: item.extraData.targetURL,
                        smartContractAddress:
                          item.extraData.smartContractAddress,
                        campaignType: item.extraData.campaignType || [],
                        // budget:
                        //     item.total_amount >= 360
                        //         ? item.total_amount.toString()
                        //         : "360",
                        portfolioBalance: item.extraData.portfolioBalance,
                        competitorSmartContracts:
                          item.extraData.competitorSmartContracts,
                        budget: item.total_amount.toString(),
                        maxCPC: item.max_cpc,
                        startDate: item.creation_date,
                        endDate: item.endDate || item.end_date || "",
                        // endDate: '',
                        category: item.extraData.category || [],
                        web3Audience: item.extraData.web3Audience || [],
                        // chainTargeting: item.extraData.chainTargeting || [],
                        chainTargeting: Array.isArray(
                          item.extraData.chainTargeting
                        )
                          ? item.extraData.chainTargeting
                          : [],
                        walletTargeting: item.extraData.walletTargeting || [],
                        deviceType: item.extraData.deviceType || [],
                        operativeSystem: item.extraData.operativeSystem || [],
                        browser: item.extraData.browser || [],
                        countriesTargeting: item.extraData
                          .countriesTargeting || [
                          "ad",
                          "af",
                          "ag",
                          "ai",
                          "al",
                          "am",
                          "an",
                          "ao",
                          "aq",
                          "ar",
                          "as",
                          "at",
                          "au",
                          "aw",
                          "ax",
                          "az",
                          "ba",
                          "bb",
                          "bd",
                          "be",
                          "bf",
                          "bg",
                          "bh",
                          "bi",
                          "bj",
                          "bl",
                          "bm",
                          "bn",
                          "bo",
                          "bq",
                          "br",
                          "bs",
                          "bt",
                          "bv",
                          "bw",
                          "by",
                          "bz",
                          "ca",
                          "cc",
                          "cd",
                          "cf",
                          "cg",
                          "ch",
                          "ci",
                          "ck",
                          "cl",
                          "cm",
                          "cn",
                          "co",
                          "cr",
                          "cu",
                          "cv",
                          "cw",
                          "cx",
                          "cy",
                          "cz",
                          "de",
                          "dj",
                          "dk",
                          "dm",
                          "do",
                          "dz",
                          "ec",
                          "ee",
                          "eg",
                          "eh",
                          "er",
                          "es",
                          "et",
                          "fi",
                          "fj",
                          "fk",
                          "fm",
                          "fo",
                          "fr",
                          "ga",
                          "gb",
                          "gd",
                          "ge",
                          "gf",
                          "gg",
                          "gh",
                          "gi",
                          "gl",
                          "gm",
                          "gn",
                          "gp",
                          "gq",
                          "gr",
                          "gs",
                          "gt",
                          "gu",
                          "gw",
                          "gy",
                          "hk",
                          "hm",
                          "hn",
                          "hr",
                          "ht",
                          "hu",
                          "id",
                          "ie",
                          "il",
                          "im",
                          "in",
                          "io",
                          "iq",
                          "ir",
                          "is",
                          "it",
                          "je",
                          "jm",
                          "jo",
                          "jp",
                          "ke",
                          "kg",
                          "kh",
                          "ki",
                          "km",
                          "kn",
                          "kp",
                          "kr",
                          "kw",
                          "ky",
                          "kz",
                          "la",
                          "lb",
                          "lc",
                          "li",
                          "lk",
                          "lr",
                          "ls",
                          "lt",
                          "lu",
                          "lv",
                          "ly",
                          "ma",
                          "mc",
                          "md",
                          "me",
                          "mf",
                          "mg",
                          "mh",
                          "mk",
                          "ml",
                          "mm",
                          "mn",
                          "mo",
                          "mp",
                          "mq",
                          "mr",
                          "ms",
                          "mt",
                          "mu",
                          "mv",
                          "mw",
                          "mx",
                          "my",
                          "mz",
                          "na",
                          "nc",
                          "ne",
                          "nf",
                          "ng",
                          "ni",
                          "nl",
                          "no",
                          "np",
                          "nr",
                          "nu",
                          "nz",
                          "om",
                          "pa",
                          "pe",
                          "pf",
                          "pg",
                          "ph",
                          "pk",
                          "pl",
                          "pm",
                          "pn",
                          "pr",
                          "ps",
                          "pt",
                          "pw",
                          "py",
                          "qa",
                          "re",
                          "ro",
                          "rs",
                          "ru",
                          "rw",
                          "sa",
                          "sb",
                          "sc",
                          "sd",
                          "se",
                          "sg",
                          "sh",
                          "si",
                          "sj",
                          "sk",
                          "sl",
                          "sm",
                          "sn",
                          "so",
                          "sr",
                          "ss",
                          "st",
                          "sv",
                          "sx",
                          "sy",
                          "sz",
                          "tc",
                          "td",
                          "tf",
                          "tg",
                          "th",
                          "tj",
                          "tk",
                          "tl",
                          "tm",
                          "tn",
                          "to",
                          "tr",
                          "tt",
                          "tv",
                          "tw",
                          "tz",
                          "ua",
                          "ug",
                          "um",
                          "us",
                          "uy",
                          "uz",
                          "va",
                          "vc",
                          "ve",
                          "vg",
                          "vi",
                          "vn",
                          "vu",
                          "wf",
                          "ws",
                          "ye",
                          "yt",
                          "za",
                          "zm",
                          "zw",
                        ],
                      },
                    });
                    setCreatives(
                      item.creatives.map((item) => {
                        return {
                          ...item,
                          readOnly: true,
                        };
                      })
                    );
                    setCampaignWizardStatus("edit");
                    navigate("/campaigns/edit");
                    setCampaignTabs({
                      ...campaignTabs,
                      marked: [...campaignTabs.marked, 1, 2, 3],
                      clickable: [...campaignTabs.clickable, 1, 2, 3],
                    });
                  }}
                  disabled={campaignBtns.editBtn.isReadOnly}
                />
                {campaignBtns.editBtn.isReadOnly && (
                  <Tooltip
                    id="edit-btn-wrapper-tooltip"
                    className="custom-tooltip"
                  />
                )}
              </div>
            )}
            <div
              className={`edit-btn-wrapper ${
                false ? "on-hold spin-loading" : ""
              }`}
              data-tooltip-id="edit-btn-wrapper-tooltip"
              data-tooltip-html={
                campaignBtns.editBtn.isReadOnly
                  ? ReactDOMServer.renderToStaticMarkup(
                      campaignBtns.editBtn.message
                    )
                  : ""
              }
            >
              <Button
                tooltipContent={"Copy Campaign"}
                variant="circle"
                color={isDarkModeEnabled ? "light" : "secondary"}
                size="sm"
                icon={copy}
                onClick={() => {
                  setCampaignState({
                    ...campaignState,
                    campaignInfo: {
                      ...campaignState.campaignInfo,
                      campaignName: item.name,
                      targetURL: item.extraData.targetURL,
                      smartContractAddress: item.extraData.smartContractAddress,
                      campaignType: item.extraData.campaignType || [],
                      portfolioBalance: item.extraData.portfolioBalance,
                      competitorSmartContracts:
                        item.extraData.competitorSmartContracts,
                      budget: item.total_amount.toString(),
                      maxCPC: item.max_cpc,
                      startDate: item.creation_date,
                      endDate: item.endDate || item.end_date || "",
                      category: item.extraData.category || [],
                      web3Audience: item.extraData.web3Audience || [],
                      chainTargeting: Array.isArray(
                        item.extraData.chainTargeting
                      )
                        ? item.extraData.chainTargeting
                        : [],
                      walletTargeting: item.extraData.walletTargeting || [],
                      deviceType: item.extraData.deviceType || [],
                      operativeSystem: item.extraData.operativeSystem || [],
                      browser: item.extraData.browser || [],
                      countriesTargeting: item.extraData.countriesTargeting,
                    },
                    creatives: item.creatives.map((item) => {
                      return {
                        id: item.id,
                        image: item.image,
                        reviveBannerId: item.reviveBannerId,
                        reviveHeight: item.reviveHeight,
                        reviveName: item.reviveName,
                        reviveWidth: item.reviveWidth,
                        size: item.size,
                        status: item.status,
                        title: item.title,
                        isCopy: true,
                      };
                    }),
                  });
                  setCreatives(
                    item.creatives.map((item) => {
                      return {
                        id: item.id,
                        image: item.image,
                        reviveBannerId: item.reviveBannerId,
                        reviveHeight: item.reviveHeight,
                        reviveName: item.reviveName,
                        reviveWidth: item.reviveWidth,
                        size: item.size,
                        status: item.status,
                        title: item.title,
                        isCopy: true,
                      };
                    })
                  );
                  setCampaignWizardStatus("copy");
                  navigate("/campaigns/create");
                }}
                disabled={campaignBtns.editBtn.isReadOnly}
              />
              {campaignBtns.editBtn.isReadOnly && (
                <Tooltip
                  id="edit-btn-wrapper-tooltip"
                  className="custom-tooltip"
                />
              )}
            </div>
            {!item.isArchived && (
              <div
                className={`delete-btn-wrapper ${
                  isDeleteLoading ? "on-hold spin-loading" : ""
                }`}
                data-tooltip-id="delete-btn-wrapper-tooltip"
                data-tooltip-html={
                  campaignBtns.deleteBtn.isReadOnly
                    ? ReactDOMServer.renderToStaticMarkup(
                        campaignBtns.deleteBtn.message
                      )
                    : ""
                }
              >
                <Button
                  tooltipContent={"Delete"}
                  variant="circle"
                  color={isDarkModeEnabled ? "light" : "secondary"}
                  size="sm"
                  icon={trashV2}
                  onClick={() => {
                    setDisplayDeleteModal("block");
                  }}
                  disabled={campaignBtns.deleteBtn.isReadOnly}
                />
                {campaignBtns.deleteBtn.isReadOnly && (
                  <Tooltip
                    id="delete-btn-wrapper-tooltip"
                    className="custom-tooltip"
                  />
                )}
                <Modal
                  Toggler={() => {}}
                  Child={DeleteCampaignModal}
                  display={displayDeleteModal}
                  setDisplay={setDisplayDeleteModal}
                  className="modal-dialog-centered modal-sm"
                />
              </div>
            )}
          </div>
        </td> */}
        <td className="table-td-status text-center">
          <div
            data-tooltip-id="status-tooltip"
            data-tooltip-html={
              itemStatus === "Paused · Daily Budget"
                ? ReactDOMServer.renderToStaticMarkup(
                    <span>Daily budget limit reached.</span>
                  )
                : ""
            }
            className={`status ${item.isArchived ? "archived" : item.status}`}
          >
            {item.isArchived ? "Archived" : item.status}
          </div>
          <Tooltip id="status-tooltip" className="custom-tooltip" />
        </td>
        <td className="table-td-impressions text-center">
          {addCommas(Math.floor(item?.impressions)) || 0}
        </td>
        <td className="table-td-clicks text-center">
          {addCommas(item.clicks) || 0}
        </td>
        <td className="table-td-ctr text-center">{item.CTR.toFixed(2)}%</td>
        <td className="table-td-ad-spend text-center">
          {formatCurrency(item.amount_spent, 0)}
        </td>
        <td className="table-td-cpc text-center">
          {formatCurrency(item.CPC, 2)}
        </td>
        <td className="table-td-conversion text-center">
          {selectedConversionTag === "all"
            ? addCommas(parseFloat(item.conversions)) || 0
            : item.tagConversions?.length
            ? addCommas(
                parseFloat(
                  item.tagConversions.find(
                    (tag) => tag.id === selectedConversionTag
                  )?.conversionCount || 0
                )
              )
            : 0}
        </td>
        <>
          <td className="table-td-txn-value text-center">
            {item.extraData.smartContractAddress
              ? addCommas(parseFloat(item.transactionValue?.toFixed(2))) || 0
              : "-"}
          </td>
          <td className="table-td-transactions text-center">
            {item.extraData.smartContractAddress
              ? addCommas(parseFloat(item.transactions?.toFixed(2))) || 0
              : "-"}
          </td>
        </>
      </tr>
      {activeInfoId === item.id && (
        <tr className="campaign-details-tr">
          <td colSpan={14} className="campaign-info-td">
            <CampaignInfo data={item} isLoading={false} />
          </td>
        </tr>
      )}
    </>
  );
};
