import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Collapse
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useFormatter from "../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import { Pagination } from '../../components';
import { countryList } from './fallbackCountryList';
import localData from '../../localData';

// Custom progress bar component for the table cells
const CustomProgressBar = ({ value, maxValue, color, displayValue }) => {
  const percentage = maxValue > 0 ? Math.min((value / maxValue) * 100, 100) : 0;
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Typography variant="body2" sx={{ minWidth: '80px', textAlign: 'right', marginRight: '8px' }}>
        {displayValue}
      </Typography>
      
      <Box sx={{ 
        position: 'relative',
        width: '100%',
        height: '8px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
        overflow: 'hidden'
      }}>
        <Box sx={{ 
          position: 'absolute',
          height: '100%',
          width: `${percentage}%`,
          minWidth: percentage > 0 ? '4px' : '0px',
          backgroundColor: color,
          borderRadius: '4px'
        }} />
      </Box>
    </Box>
  );
};

// Main Accordion Component
const CampaignAccordion = ({ title, data, tabValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const { formatNumber, formatCurrency } = useFormatter();
  const countries = useMemo(() => countryList, []);
  const { sortDown, sortUp, sortIcon } = localData.svgs;


  const calculateCTR = (clicks, impressions) => {
    return impressions > 0 ? (clicks / impressions) * 100 : 0;
  };

  const calculateCPC = (spent, clicks) => {
    return clicks > 0 ? spent / clicks : 0;
  };

  const getSortIcon = (field) => {
    if (sortField !== field) {
      return <span className="sort-btn">{sortIcon}</span>;
    }
    return (
      <span className="sort-btn active">
        {sortOrder === "desc" ? sortDown : sortUp}
      </span>
    );
  };

  const handleSortClick = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handlePagination = (newPage) => {
    setPage(newPage);
  };
  
  const maxValues = {
    impressions: Math.max(...data.map(row => row.impressions || 0)),
    clicks: Math.max(...data.map(row => row.clicks || 0)),
    ctr: Math.max(...data.map(row => row.CTR || calculateCTR(row.clicks, row.impressions) || 0)),
    adSpend: Math.max(...data.map(row => (row.amount_spent || row.spent) || 0)),
    cpc: Math.max(...data.map(row => {
      const spent = row.amount_spent || row.spent || 0;
      const clicks = row.clicks || 0;
      return (row.CPC || (clicks > 0 ? spent / clicks : 0)) || 0;
    })),
    conversions: Math.max(...data.map(row => row.conversions || 0))
  };

  const colors = {
    impressions: "#2E6F40", // Original forest green
    clicks: "#3E8E53",      // Lighter forest green
    ctr: "#568B45",         // Olive green
    adSpend: "#1E5C30",     // Darker forest green
    cpc: "#4A9E63",         // Bright forest green
    conversions: "#2C8059"  // Forest green with teal tint
  };

  const sortedData = useMemo(() => {
    if (!sortField) return data;

    return [...data].sort((a, b) => {
      let aValue, bValue;

      switch (sortField) {
        case "impressions":
          aValue = a.impressions || 0;
          bValue = b.impressions || 0;
          break;
        case "clicks":
          aValue = a.clicks || 0;
          bValue = b.clicks || 0;
          break;
        case "ctr":
          aValue = a.CTR || calculateCTR(a.clicks, a.impressions) || 0;
          bValue = b.CTR || calculateCTR(b.clicks, b.impressions) || 0;
          break;
        case "adSpend":
        case "spent":
          aValue = a.amount_spent || a.spent || 0;
          bValue = b.amount_spent || b.spent || 0;
          break;
        case "cpc":
          aValue = a.CPC || calculateCPC(a.amount_spent || a.spent, a.clicks) || 0;
          bValue = b.CPC || calculateCPC(b.amount_spent || b.spent, b.clicks) || 0;
          break;
        case "conversions":
          aValue = a.conversions || 0;
          bValue = b.conversions || 0;
          break;
        default:
          aValue = a[sortField] || 0;
          bValue = b[sortField] || 0;
      }

      if (a.name === "Total") return 1;
      if (b.name === "Total") return -1;

      if (sortOrder === "asc") {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
  }, [data, sortField, sortOrder]);

  const paginatedData = useMemo(() => {
    const totalRows = sortedData.filter(row => row.name !== "Total");
    const totalPages = Math.ceil(totalRows.length / rowsPerPage);
    
    const totalRow = sortedData.find(row => row.name === "Total");
    
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalRows.length);
    
    let currentPageData = totalRows.slice(startIndex, endIndex);
    
    if (totalRow) {
      if (page === totalPages) {
        currentPageData = [...currentPageData, totalRow];
      }
    }
    
    return {
      data: currentPageData,
      totalPages: totalPages || 1
    };
  }, [sortedData, page, rowsPerPage]);

  return (
        <Box sx={{ py: 2, mt: 3 }}>
          
          <Typography variant="h6" sx={{mb: 3, fontWeight: 700}}>Performance by Campaign</Typography>
          <br/>
          <div className="zones-wrapper">
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
              <table id="ads-table" className="table reports-table" >
                <thead>
                  <tr className="table-row">
                    <th className="table-th-hash text-center">Campaigns</th>
                    <th
                      className="table-th-age text-center clickable"
                      onClick={() => handleSortClick("impressions")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-impressions"
                        data-tooltip-content="How many times your ads have been seen."
                        className="with-sort"
                      >
                        Impressions {getSortIcon("impressions")}
                      </span>
                    </th>
                    <th
                      className="table-th-from text-center clickable"
                      onClick={() => handleSortClick("clicks")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-clicks"
                        data-tooltip-content="How many times your ads have been clicked."
                        className="with-sort"
                      >
                        Clicks {getSortIcon("clicks")}
                      </span>
                    </th>
                    <th
                      className="table-th-quantity text-center clickable"
                      onClick={() => handleSortClick("ctr")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-ctr"
                        data-tooltip-content="How often your ads are clicked when viewed by users."
                        className="with-sort"
                      >
                        CTR {getSortIcon("ctr")}
                      </span>
                    </th>
                    <th
                      className="table-th-quantity text-center clickable"
                      onClick={() => handleSortClick("adSpend")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-ad-spend"
                        data-tooltip-content="How much budget has been spent."
                        className="with-sort"
                      >
                        Ad Spend {getSortIcon("adSpend")}
                      </span>
                    </th>
                    <th
                      className="table-th-quantity text-center clickable"
                      onClick={() => handleSortClick("cpc")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-cpc"
                        data-tooltip-content="How much you pay for each click."
                        className="with-sort"
                      >
                        CPC {getSortIcon("cpc")}
                      </span>
                    </th>
                    <th
                      className="table-th-conversions text-center clickable"
                      onClick={() => handleSortClick("conversions")}
                    >
                      <span
                        data-tooltip-id="tooltip-table-conversions"
                        data-tooltip-content="Total amount of the conversions."
                        className="with-sort"
                      >
                        Conversions {getSortIcon("conversions")}
                      </span>
                    </th>
                  </tr>
                </thead>
                
                <tbody>
                  {paginatedData.data.map((row, index) => (
                    <tr 
                      key={index} 
                      className="table-row" 
                      style={{ background: row.name === "Total" && "lightgray" }}
                    >
                      <td className="ads-td-image text-star" style={{ fontWeight: row.name === "Total" && "bold", minWidth: "150px" }}>
                        {row.name}
                      </td>
                      <td className="table-td-age">
                        <CustomProgressBar
                          value={row.impressions || 0}
                          maxValue={maxValues.impressions}
                          color={colors.impressions}
                          displayValue={formatNumber(row.impressions)}
                        />
                      </td>
                      <td className="table-td-from">
                        <CustomProgressBar
                          value={row.clicks || 0}
                          maxValue={maxValues.clicks}
                          color={colors.clicks}
                          displayValue={formatNumber(row.clicks)}
                        />
                      </td>
                      <td className="table-td-quantity">
                        <CustomProgressBar
                          value={row.CTR || calculateCTR(row.clicks, row.impressions)}
                          maxValue={maxValues.ctr}
                          color={colors.ctr}
                          displayValue={`${(row.CTR || calculateCTR(row.clicks, row.impressions)).toFixed(2)}%`}
                        />
                      </td>
                      <td className="table-td-quantity">
                        <CustomProgressBar
                          value={(row.amount_spent || row.spent) || 0}
                          maxValue={maxValues.adSpend}
                          color={colors.adSpend}
                          displayValue={formatCurrency(row.amount_spent || row.spent, 2)}
                        />
                      </td>
                      <td className="table-td-cpc">
                        <CustomProgressBar
                          value={(row.CPC || calculateCPC(row.amount_spent || row.spent, row.clicks)) || 0}
                          maxValue={maxValues.cpc}
                          color={colors.cpc}
                          displayValue={formatCurrency(row.CPC || calculateCPC(row.amount_spent || row.spent, row.clicks), 2)}
                        />
                      </td>
                      <td className="table-td-conversion">
                        <CustomProgressBar
                          value={row.conversions || 0}
                          maxValue={maxValues.conversions}
                          color={colors.conversions}
                          displayValue={formatNumber(row.conversions)}
                        />
                      </td>
                    </tr>
                  ))}
                  {!paginatedData.data.length && (
                    <tr>
                      <td colSpan="7">
                        <div className="no-data">No Data To Show!</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            {data.length > rowsPerPage && (
              <div className="pagination-wrapper">
                <Pagination
                  callback={handlePagination}
                  currentPage={page}
                  totalPages={paginatedData.totalPages}
                  isLoading={false}
                />
              </div>
            )}
            
            <Tooltip
              id="tooltip-campaign-image"
              className="custom-tooltip image-tooltip"
            />
            
            <Tooltip
              id="tooltip-country-name"
              className="custom-tooltip"
            />
            
            <Tooltip id="tooltip-table-impressions" className="custom-tooltip" />
            <Tooltip id="tooltip-table-clicks" className="custom-tooltip" />
            <Tooltip id="tooltip-table-ctr" className="custom-tooltip" />
            <Tooltip id="tooltip-table-ad-spend" className="custom-tooltip" />
            <Tooltip id="tooltip-table-cpc" className="custom-tooltip" />
            <Tooltip id="tooltip-table-conversions" className="custom-tooltip" />
          </div>
        </Box>
  );
};

export default CampaignAccordion;