import React, { useEffect, useState } from "react";
import useFormatter from "../../../hooks/useFormatter";
import { CircularProgress, Grid } from "@mui/material";

export default function UserProfile({ userInfo, actions, loading }) {
  const { addCommasKeepMinus } = useFormatter();
  function truncateString(str, maxLength = 20) {
    if (!str) return ""; // Handle null/undefined cases
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
  }
  const { formatDateTime } = useFormatter();

  const [lastLogin, setLastLogin] = useState(null);

  useEffect(() => {
    if (userInfo?.lastSeen) {
      setLastLogin(formatDateTime(userInfo.lastSeen));
    } else {
      setLastLogin("N/A");
    }
  }, [userInfo]);

  return (
    <>
      <Grid
        container
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} md={3} className="card ads-card estimated-results">
          <div className="accounts">
            {loading ? (
              <CircularProgress />
            ) : (
              <div style={{ maxHeight: "600px" }}>
                <h5
                  style={{ marginTop: "53px", marginLeft: "20px" }}
                  className="modal-title display-2"
                >
                  User Info
                </h5>

                <div className="estimated-results-body">
                  <br />
                  <div className="wrapper-conatiner">
                    <div className="estimated-result-item-container">
                      <h4 className="title">User Name:</h4>

                      <div>
                        <div className="start"> {userInfo?.name}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="wrapper-conatiner">
                    <div className="estimated-result-item-container">
                      <h4 className="title">User ID:</h4>

                      <div>
                        <div className="start"> {userInfo?.id}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="wrapper-conatiner">
                    <div className="estimated-result-item-container">
                      <h4 className="title">User Email:</h4>

                      <div>
                        <div className="start"> {userInfo?.email}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="wrapper-conatiner">
                    <div className="estimated-result-item-container">
                      <h4 className="title">Last seen:</h4>

                      <div>
                        <div className="start"> {lastLogin}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={8} className="card ads-card estimated-results">
          <div className="modal-dialog-scrollable accounts modal-body">
            {loading ? (
              <div
                style={{
                  margin: "auto",
                  position: "absolute",
                  margin: "auto",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ maxHeight: "600px" }}>
                <div className="search-bar-wrapper">
                  <h5 className="modal-title display-2">User Organizations</h5>
                </div>
                <table className="table">
                  <thead>
                    <th>Organization Name</th>
                    <th>Organization ID</th>
                    <th>Account Status</th>
                    <th>Admin Name</th>
                    <th>Balance</th>
                  </thead>
                  <tbody>
                    {!userInfo || !userInfo.all_organization_details.length ? (
                      <tr>
                        <td>
                          <div className="no-data">No Data To Show!</div>
                        </td>
                      </tr>
                    ) : (
                      userInfo?.all_organization_details.map((item, index) => {
                        return (
                          <tr>
                            <td className="capitalize">
                              {truncateString(item.name)}
                            </td>
                            <td>{item.id}</td>
                            <td>{item.account_status}</td>
                            <td>{truncateString(item.adminName)}</td>
                            <td>
                              ${addCommasKeepMinus(item.current_balance || 0)}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
