import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import {
  Navbar,
  Button,
  Select,
  Geochart,
  TrafficSourcesTable,
  SocialMetrics,
  Field,
  Testimonials,
} from "../../../components";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";

import { auth, googleProvider } from "../../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import useFetch from "../../../hooks/useFetch";

export default function SignIn() {
  const { fade } = useGlobalContext().animations;
  const {
    isLoggedIn,
    setIsLoggedIn,
    errorAlert,
    warningAlert,
    successAlert,
    handleGoogleSignIn,
    handleGoogleSignUp,
    handleFacebookSignIn,
    getLoadUserProfile,
    requestedData,
    setRequestedData,
  } = useGlobalContext();
  const { disconnect, eye, eyeSlash } = localData.svgs;
  const { preloaderBig } = localData.images;
  const { logoDark, google, facebook } = localData.images;
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // console.log(auth?.currentUser?.email, 'sign in page');

  const { checkEmailExists, loginFailed, loginSuccess } = useFetch();

  const [raw, setRaw] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setRaw({ ...raw, [name]: value });
  };

  const getAccountType = (emailData) => {
    let accountType = "";
    let profileData = null;
    profileData = emailData.res_data.providerData;
    if (profileData) {
      if (profileData.length == 2) {
        accountType = "passwordAndGoogle";
      } else {
        accountType = profileData[0].providerId;
      }
    }
    return accountType;
  };

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const emailData = await checkEmailExists(() => {}, { email: raw.email });
      const accountType = getAccountType(emailData);
      console.log(accountType, " (account type)");

      if (!emailData.emailExists) {
        errorAlert("No account found with this email. Please sign up first.");
      } else if (accountType === "google.com") {
        warningAlert(
          "Please sign in with Google. This email is already associated with a Google login."
        );
      } else {
        const data = await signInWithEmailAndPassword(
          auth,
          raw.email,
          raw.password
        );
        await loginSuccess(() => {}, { email: raw.email });

        successAlert("You have successfully signed in!");
      }
    } catch (err) {
      let errorMessage = err.message;
      if (errorMessage == "Firebase: Error (auth/invalid-credential).")
        errorMessage = "Incorrect password. Please try again.";
      if (
        errorMessage ==
        "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
      )
        errorMessage =
          "Blockchain-Ads: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
      errorAlert(errorMessage);
      await loginFailed(() => {}, { email: raw.email });
    }
    setIsLoading(false);
  };

  return (
    <motion.main {...fade} className="main sign-in-page">
      <div className="container">
        <div className="col col-left">
          <div className="wrapper">
            <form className="form" onSubmit={handleLogin}>
              <img className="logo" src={logoDark} />

              <div className="form-description">
                Sign in or create an account
              </div>

              <h2 className="form-title">Sign In</h2>

              <Field
                type="email"
                label="Email"
                color="secondary"
                placeholder="Enter your email address"
                required={true}
                name="email"
                callback={handleOnChange}
              />

              <Field
                type={showPassword ? "text" : "password"}
                label="Password"
                link={
                  <Link
                    to="/forgot-password"
                    underline="hover"
                    className="link link-primary"
                    tabIndex={-1}
                  >
                    {" "}
                    Forgot Password
                  </Link>
                }
                color="secondary"
                placeholder="Enter your password"
                required={true}
                name="password"
                callback={handleOnChange}
              >
                <Button
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  className="field-endIcon"
                  icon={showPassword ? eye : eyeSlash}
                  variant="circle"
                  size="sm"
                  color="secondary"
                  type="button"
                />
              </Field>

              <Button
                className="submit-btn"
                name="Sign In"
                color="primary"
                variant="contained"
                size="lg"
                icon={
                  isLoading ? (
                    <span className="endIcon">
                      <img src={preloaderBig} />
                    </span>
                  ) : null
                }
                disabled={isLoading}
              />
            </form>

            <div className="cut-line">
              <div className="cut-line-inner">or</div>
            </div>

            <Button
              variant="outlined"
              size="lg"
              color="secondary"
              className="alt social-btn google-btn"
              onClick={handleGoogleSignIn}
            >
              <span className="startIcon">
                <img src={google} alt="" />
              </span>
              Sign in with Google
            </Button>

            {/* <Button
                            variant="outlined"
                            size="lg"
                            color="secondary"
                            className="alt social-btn facebook-btn"
                            onClick={handleFacebookSignIn}
                        >
                            <span className="startIcon">
                                <img src={facebook} alt="" />
                            </span>
                            Sign in with Facebook
                        </Button> */}
            <p className="login-signup-now">
              Don’t have an account?
              <Link
                to="/sign-up"
                underline="hover"
                className="link link-primary"
              >
                {" "}
                Sign up
              </Link>
            </p>
          </div>
          <p className="copyright">
            ©Blockchain-Ads. All rights reserved.
            <Link
              to="/privacy-policy"
              underline="hover"
              className="link link-primary"
            >
              {" "}
              Privacy Policy
            </Link>
          </p>
        </div>

        <div className="col col-right">
          <Testimonials />
        </div>
      </div>
    </motion.main>
  );
}
