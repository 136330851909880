import React, { useEffect, useState } from "react";
import { Dropdown, Select, Autocomplete, Button, Field, NestedModal, Checkbox } from "../../components";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import localData from "../../localData";
import useFormatter from "../../hooks/useFormatter";
import { useGlobalContext } from "../../context";
  

const SinglePDFTemplate = ({ payment, companyInfo, paymentValue, companyInfoDetails }) => {
    const { formatNumber, formatCurrency, addCommas } = useFormatter();

  const styles = StyleSheet.create({
    page: { padding: 40, fontSize: 10, color: "#333" },
    header: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: { maxWidth: "175px", height: "100%" },
    companyInfo: { textAlign: "right" },
    section: {
      marginBottom: 20,
      marginTop: 20
    },
    title: { fontSize: 18, color: "#4CAF50", marginBottom: 10 },
    billTo: { marginBottom: 20, textAlign: "right" },
    table: { display: "table", width: "100%", marginBottom: 20 },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid #e0e0e0",
      paddingVertical: 5,
    },
    tableHeader: { fontSize: 12, fontWeight: "bold", color: "#4CAF50" },
    tableHeaderBill: {fontSize: 12, fontWeight: "bold", color: "#4CAF50",marginBottom: "10px"},
    tableCell: { flex: 1, textAlign: "left", paddingHorizontal: 5, width: "50%" },
    totalSection: { textAlign: "right", marginTop: 10 },
    totalText: { fontSize: 12, fontWeight: "bold", marginBottom: "10px" },
    footer: { marginTop: 40, textAlign: "center", fontSize: 10, color: "#aaa" },
    flexElement: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "30px", marginTop: "50px" },
    leftItem: { flex: 1, textAlign: "left" },
    rightItem: { flex: 1, textAlign: "right" },
    mt: {marginTop: "10px"},
    boldText: {fontWeight: "900"}
  });

  const { logo, logoDark } = localData.images;

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const generateNextInvoiceNumber = () => {
    const min = 103;
    const max = 200;

    let lastInvoice = parseInt(localStorage.getItem("lastInvoiceNumber"), 10) || min - 1;
    let nextInvoice = lastInvoice + 1;

    if (nextInvoice > max) {
      console.warn("Invoice limit reached!");
      return;
    }

    localStorage.setItem("lastInvoiceNumber", nextInvoice.toString());
    setInvoiceNumber(nextInvoice.toString().padStart(5, "0"));
  };

  useEffect(() => {
    generateNextInvoiceNumber();
  }, []); 
  

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image src={logoDark} style={styles.logo} />
          <View style={styles.companyInfo}>
            <Text>Blockchain-Ads Labs LLC</Text>
            <Text>Suite 305, Griffith Corporate Centre</Text>
            <Text>Beachmonth, Kingston, St. Vincent and the Grenadines</Text>
          </View>
        </View>

        <View style={styles.flexElement}>
          <View style={styles.leftItem}>
            <Text style={styles.title}>Invoice</Text>
            <Text style={styles.mt}>Invoice No.: {invoiceNumber}</Text>
            <Text style={styles.mt}>Payment Terms: Upon Receipt</Text>
            <Text style={styles.mt}>Due Date: {new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}</Text>

          </View>

          <View style={styles.rightItem}>
            <Text style={styles.tableHeaderBill}>BILL TO:</Text>
            <Text style={styles.mt}>{companyInfo.name}</Text>
            <Text style={styles.mt}>{companyInfo.address}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <Text style={[styles.tableCell, styles.tableHeader]}>NAME</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>PRICE</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>QTY</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>SUBTOTAL</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Paid Media & Inventory - {new Date().toLocaleString('default', { month: 'long' })}</Text>
            <Text style={styles.tableCell}>{formatCurrency(paymentValue, 0)}</Text>
            <Text style={styles.tableCell}>1</Text>
            <Text style={styles.tableCell}>{formatCurrency(paymentValue, 0)}</Text>
          </View>
        </View>

        <View style={styles.totalSection}>
          <Text style={styles.totalText}>Subtotal:{formatCurrency(paymentValue, 0)}</Text>
          <Text style={styles.totalText}>Discount: $0.00</Text>
          <Text style={styles.totalText}>Tax: $0.00</Text>
          <Text style={[styles.totalText, styles.boldText]}>Total: {formatCurrency(paymentValue, 0)}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.tableHeader}>Here are the USD account details for BlockchainAds Labs LLC.</Text>
          <Text style={styles.mt}>Account Holder: BlockchainAds Labs LLC</Text>
          <Text style={styles.mt}>Routing Number: 026073150</Text>
          <Text style={styles.mt}>SWIFT/BIC: CMFGUS33</Text>
          <Text style={styles.mt}>Account Number: 8313742795</Text>
          <Text style={styles.mt}>Address: 89-16 Jamaica Ave, Woodhaven NY 11421, United States</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.tableHeader}>For Crypto Payments</Text>
          <Text style={styles.mt}>BEP20 / ETH / Polygon Network</Text>
          <Text style={styles.mt}>USDT/USDC/DAI</Text>
          <Text style={styles.mt}>0xa98365fA3A975E2bC56881ED1fef86Da6129Ad05</Text>
        </View>

        <Text style={styles.footer}>Thank you for your payment!</Text>
      </Page>
    </Document>
  );
};

export default SinglePDFTemplate;


export const RequestInvoice = ({ condition = false, paymentValue, payment, companyInfo }) => {
    const { requestedData } = useGlobalContext();
    const [companyInfoDetails, setCompanyInfo] = useState({
      name: requestedData.userProfileData.currentOrganization.billing.companyName,
      address: requestedData.userProfileData.currentOrganization.billing.address,
    });
  
    return (
      <div>
        {!condition ? (
          <PDFDownloadLink
            document={
              <SinglePDFTemplate
                payment={payment}
                companyInfo={companyInfo}
                paymentValue={paymentValue}
                companyInfoDetails={companyInfo}
              />
            }
            fileName={`BCA ${new Date().toLocaleString('en-US', { month: 'long' })} ${new Date().getDate()}.pdf`}
            style={{ textDecoration: "none" }}
          >
            {({ loading }) => (
              <Button
                variant="contained"
                color="marketplace"
                className="balance-btn"
                disabled={loading}
              >
                {"Request Invoice"}
              </Button>
            )}
          </PDFDownloadLink>
        ) : (
          <Button
            variant="contained"
            color="marketplace"
            disabled
            className="balance-btn"
          >
            Request Invoice
          </Button>
        )}
      </div>
    );
  };
  