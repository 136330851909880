import React, { useEffect, useState } from "react";
import {
  Button,
  AreaChart,
} from "../../../components";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useFormatter from "../../../hooks/useFormatter";
import { useGlobalContext } from "../../../context";


export default function AnalysisChart  ({ categories, dateRange }) {
  const {activeCategory} = useGlobalContext()
    const { formatDateToMMDD, addCommas } = useFormatter();
    const [data, setData] = useState([]);    
    
    const getPropertiesForCategory = (category) => {
      if (category === "web3GamingCasinos") {
        return [
          { name: "impressions", displayName: "Impressions", isSelected: false },
          { name: "clicks", displayName: "Clicks", isSelected: true },
          { name: "cost", displayName: "Cost per Sign Up", isSelected: false },
          { name: "deposits", displayName: "Deposits", isSelected: false },
          { name: "signups", displayName: "Sign Ups", isSelected: false },
        ];
      } else {
        return [
          { name: "Impressions", displayName: "Impressions", isSelected: false },
          { name: "clicks", displayName: "Clicks", isSelected: true },
          { name: "conversions", displayName: "Conversions", isSelected: false },
          { name: "volumes", displayName: "Conversion Value", isSelected: false },
          { name: "cost", displayName: "Cost per Result", isSelected: false },
        ];
      }
    };
  
    const [properties, setProperties] = useState(getPropertiesForCategory(activeCategory));

    useEffect(() => {
      setProperties(getPropertiesForCategory(activeCategory));
      setSelectedValue(getPropertiesForCategory(activeCategory).find(prop => prop.isSelected).name);
    }, [activeCategory]);
  
    const setChart = ({ category, range }) => {
      const totalData = category.TotalData;
      let startDate = null;
      let endDate = null;
      const sum = [];
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - range);
  
      totalData.data.forEach((item) => {
        const itemDate = new Date(item.Date);
        itemDate.setHours(0, 0, 0, 0);
  
        if (itemDate >= sevenDaysAgo && itemDate < today) {
          if (!startDate || itemDate < startDate) startDate = itemDate;
          if (!endDate || itemDate > endDate) endDate = itemDate;
  
          const activeProperty = properties.find((prop) => prop.isSelected);
  
          const obj = {
            name: formatDateToMMDD(itemDate),
            progress: parseFloat(
              parseFloat(item[activeProperty.displayName].replace(/[$,]/g, ""))
                .toFixed(2)
            ),
            ySecondaryName: activeProperty.name,
          };

          sum.push(obj);
        }
      });  
      setData(sum);
    };
  
    useEffect(() => {
      if (!Object.keys(categories).length) return;
      const category = categories[activeCategory];
      const range = dateRange.find((item) => item.isSelected).value;
  
      setChart({ category, range });
    }, [activeCategory, categories, dateRange, properties]);

    const [selectedValue, setSelectedValue] = useState(
      properties.find((item) => item.isSelected).name
    );
  
    const handleChange = (e) => {
      const selected = e.target.value;
  
      const updatedProperties = properties.map((item) => ({
        ...item,
        isSelected: item.name === selected,
      }));
  
      setSelectedValue(selected);
      setProperties(updatedProperties);
    };
    
  
    return (
      <div className="analysis-chart ">
        <div className="header-container">
        <h3 className="analysis-chart-title">Trend Analysis</h3>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {properties.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >
                      {item.name === "cost"
                        ? "Avg. CPA"
                        : item.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </div>
        
        <br />
        <div className="shadow">
          <div className="wrapper ">
            <div className="btn-group">

            </div>
          </div>
          <br />
          <br />
  
          <AreaChart
            data={data}
          />
        </div>
      </div>
    );
  };
  