import React, { useEffect, useState, useRef } from "react";
import {
  Navbar,
  Button,
  Modal,
  Carousel,
  MarketplaceCard,
  Breadcrumbs,
} from "../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import PublicationsTable from "./publications-table/PublicationsTable";
import { Link } from "react-router-dom";
import ShoppingPanel from "./shopping-panel/ShoppingPanel";
import Joyride, { STATUS } from "react-joyride";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';

const { edit, question, password, support, shoppingCart, heart } =
  localData.svgs;
const { productExample } = localData.images;

export default function PR() {
  useEffect(() => {
    document.title = "Marketplace";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content = "Markeplace description";
  }, []);
  const { fade } = useGlobalContext().animations;

  const { marketplaceState, requestedData, activePRCategory, setActivePRCategory  } = useGlobalContext();

  const {
    activeMarketplaceDetails,
    displayMarketplaceDetailsModal,
    setDisplayMarketplaceDetailsModal,
    MarketplaceCardDetails,
    publications,
  } = marketplaceState;

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setIsLoading(true); 
    setTimeout(() => {
      setActivePRCategory(newValue);
      setIsLoading(false);
    }, 500); 
  };
    
  return (
    <motion.main {...fade} className="main marketplace-page">
      <PRJoyride />
      {/* <Navbar title="marketplace" /> */}
      <ShoppingPanel />
      {/* <Breadcrumbs items={[{ name: "PR" }]} /> */}
      <h2
          className="featured-packages-title displaly-2"
          style={{ textAlign: "center", marginBottom: "0", marginTop: "10px" }}
        >
          Media PR Packages
      </h2>
      <Box
          sx={{
            bgcolor: "none",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <Tabs
            value={activePRCategory}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{ justifyContent: "center", display: "flex" }}
          >
            <Tab label="Crypto" />
            <Tab label="Casino" />
            {/* <Tab label="Gaming" /> */}
          </Tabs>
        </Box>
      <br />

      {isLoading ? <div className="loading-spinner-container"><CircularProgress /></div> : 
       <motion.main {...fade}>
        <FeaturedPackages />
       </motion.main> }
      <br />
      <br />

      <h2 className="display-2">Custom Packages</h2>
      <br />

      <PublicationsTable />
      {/* <Tabs
                swiper={true}
                tabs={publicationsTabs}
                className="publications-tabs"
                callback={() => {}}
                isLoading={false}
            /> */}
      <Modal
        Toggler={() => {}}
        Child={MarketplaceCardDetails}
        display={displayMarketplaceDetailsModal}
        setDisplay={setDisplayMarketplaceDetailsModal}
        className="modal-dialog-centered  marketplace-details-modal"
      />
    </motion.main>
  );
}

const FeaturedPackages = () => {
    const { marketplaceState, activePRCategory, setActivePRCategory } = useGlobalContext();
  
    const handleChange = (event, newValue) => {
      setActivePRCategory(newValue);
    };
    
    const { setActiveMarketplaceDetails, publications } = marketplaceState;
  
    const [filteredPublications, setFilteredPublications] = useState([]);
  
    useEffect(() => {
        const categories = ["crypto", "casino", "gaming"];
        const selectedCategory = categories[activePRCategory];

        const filteredItems = publications.filter(
            (item) =>
                item.isFeatured && item.category && item.category === selectedCategory
        );

        setFilteredPublications(filteredItems);
    }, [publications, activePRCategory]);
  
    return (
      <section className="featured-packages">
        <div style={{padding: "0 2rem"}}>
          <Carousel
            items={filteredPublications}
            Card={MarketplaceCard}
            cardOptions={{ readOnly: true, setActiveMarketplaceDetails }}
            options={{
              spaceBetween: 10,
              autoplay: false,
              loop: false,
              breakpoints: {
                640: {
                  slidesPerView: 2,
                },
                960: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
              },
            }}
          />
        </div>
      </section>
    );
  };

const PRJoyride = () => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Welcome to the PR tutorial. Choose and book PR services on various
          publications.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Welcome to PR Tutorial",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Choose the appropriate package. Read the list of publications
          carefully. Contact support for help selecting the right package.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Choosing the Right Package",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Click the info button for additional details on PR packages, such as
          standard distribution time.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "bottom",
      target: "#pr-tutorial-3",
      title: "Viewing Additional Details",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Click the cart button to proceed to checkout.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "bottom",
      target: "#pr-tutorial-4",
      title: "Proceeding to Checkout",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter((item) => item !== "prTutorial");
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("prTutorial")) return;
    // setTimeout(() => {
    setSteps((prev) => ({ ...prev, run: true }));
    // }, 500);
  }, []);
  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      disableScrolling={true}
      disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};
