import React, { useState, useEffect } from "react";
import { Button } from "../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import localData from "../../localData";

const { info } = localData.svgs;

export default function Field({
    label,
    labelInfo,
    warningInfo = null,
    name,
    color = "primary",
    placeholder = "",
    type,
    callback = () => {},
    onClick = () => {},
    onBlur = () => {},
    value = "",
    dataValue = "",
    className = "",
    id = "",
    dataGuide = "",
    inputClassName = "",
    required = false,
    successMessage = "",
    additionalMessage = null,
    errorMessage = "",
    errorElement,
    Tag = "input",
    link,
    onKeyDown = null,
    startIcon,
    endIcon,
    children,
    size = "md",
    _ref = null,
    readOnly = false,
    disabled = false,
    maxLength=null,
    borderColor="",
    currencyInput=false
}) {
    const [_value, _setValue] = useState(value);

    useEffect(() => {
        _setValue(value);
    }, [value]);

      const onChange = (e) => {
        let value = e.target.value;
        if (currencyInput) {
            value = value.replace(/[^0-9,]/g, "");
            const numberValue = value.replace(/,/g, "");
            if (!isNaN(numberValue) && numberValue !== "") {
                value = Number(numberValue).toLocaleString();
            }
            _setValue(value);
            callback(e);
        } else {
            _setValue(e.target.value);
            callback(e);
        }
    };

    return (
        <div className={`field ${className}`} id={id} data-guide={dataGuide}>
            {label && (
                <div className="wrapper">
                    <label
                        className="form-label"
                        htmlFor={name}
                        data-tooltip-id={`tooltip-campaign-${name}`}
                        data-tooltip-content={labelInfo}
                    >
                        {label}
                    </label>
                    {link}
                </div>
            )}
            {labelInfo && <Tooltip id={`tooltip-campaign-${name}`} className="custom-tooltip" />}
            {warningInfo && (
                <>
                    <div
                        data-tooltip-id={`warning-tooltip-campaign-${name}`}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(warningInfo)}
                        className="info-popup info-popup-warning"
                    >
                        {info}
                    </div>
                    <Tooltip id={`warning-tooltip-campaign-${name}`} className="custom-tooltip warning" />
                </>
            )}
            <div className="input-group">
                {/* onClick={(e) => e.preventDefault()} */}
                <div>
                    {children}
                    {startIcon && (
                        <Button
                            className="field-startIcon"
                            icon={startIcon}
                            variant="circle"
                            size="sm"
                            color="secondary"
                        />
                    )}
                    {endIcon && (
                        <Button
                            className="field-endIcon"
                            icon={endIcon}
                            variant="circle"
                            size="sm"
                            color="secondary"
                        />
                    )}
                    {maxLength && <div className="max-length">{maxLength - _value.length}</div>}

                    <Tag
                        type={type}
                        name={name}
                        id={name}
                        className={`form-control form-control-${color} ${inputClassName} form-control-${size} ${
                            disabled ? "disabled" : ""
                        }`}
                        onClick={onClick}
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        placeholder={placeholder}
                        value={_value}
                        data-value={dataValue}
                        required={required}
                        ref={_ref}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        style={{borderColor: borderColor}}
                    />
                </div>
            </div>
            {errorElement && <p className="error-message">{errorElement}</p>}
            {errorMessage && (
                <p className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
            )}
            {successMessage && (
                <p className="success-message" dangerouslySetInnerHTML={{ __html: successMessage }}></p>
            )}
            {additionalMessage && <div className="additional-message">{additionalMessage}</div>}
            {(parseFloat(value) < 2 && name === "maxCPC") && <span className="error-message">Lower CPC can impact campaign performance and reduce reach.</span>}
        </div>
    );
}
