import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Paper, Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';

const BillingNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const getTabValue = () => {
    if (location.pathname === '/billing/deposit') return 0;
    return 1;
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      navigate('/billing/deposit');
    } else {
      navigate('/billing');
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      margin: "20px auto",
      marginBottom: "0"
    }}>
      <Paper 
        elevation={1} 
        sx={{ 
          borderRadius: '5px', 
          overflow: 'hidden',
          width: 'auto',
          maxWidth: isMobile ? '90%' : '300px'
        }}
      >
        <Tabs 
          value={getTabValue()} 
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          centered
          sx={{
            minHeight: isMobile ? '40px' : '48px',
            '& .MuiTab-root': {
              fontSize: isMobile ? '0.8rem' : '0.9rem',
              fontWeight: 500,
              py: isMobile ? 1 : 1.2,
              px: isMobile ? 1 : 2,
              minWidth: 'auto',
              textTransform: 'none',
              color: '#555',
              '&.Mui-selected': {
                color: '#1f8352',
              }
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#1f8352',
              height: 3,
            }
          }}
        >
          <Tab 
            label="Deposit Funds" 
            disableRipple
          />
          <Tab 
            label="Billing History" 
            disableRipple
          />
        </Tabs>
      </Paper>
    </Box>
  );
};

export default BillingNavigation;