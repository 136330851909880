import React, { useEffect, useState } from "react";
import { Tabs } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import UserActions from "./tabs/UserActions";
import UserProfile from "./tabs/UserProfile";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import UserReferrals from "./tabs/UserReferrals";

export default function UserInfo() {
  const { adminGetUserInfo, getReferralDetails } = useFetch();
  const [userInfo, setUserInfo] = useState({});
  const [referralInfo, setReferralInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const fetchData = async () => {
    if (Object.keys(userInfo).length == 0) {
      setIsLoading(true);
      try {
        const raw = {
          uuid: params.id,
        };
        let result = await adminGetUserInfo(() => {}, raw);
        result.res_data.user.id = params.id;
        setUserInfo(result.res_data);

        const referrals = await getReferralDetails(() => {}, raw);
        setReferralInfo(referrals.res_data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    document.title = "User Info";

    fetchData();
  }, []);

  const {
    animations: { fade },
  } = useGlobalContext();
  const { usersIcon, bars, referral } = localData.svgs;

  const tabs = [
    {
      startIcon: usersIcon,
      title: "User Pofile",
      content: (
        <UserProfile
          userInfo={userInfo.user}
          actions={userInfo?.userActions}
          loading={isLoading}
        />
      ),
    },
    {
      startIcon: bars,
      title: "User Actions",
      content: (
        <UserActions actions={userInfo?.userActions} loading={isLoading} />
      ),
    },
    {
      startIcon: referral,
      title: "Referrals",
      content: (
        <UserReferrals referralInfo={referralInfo} loading={isLoading} />
      ),
    },
  ];

  return (
    <motion.main {...fade} className="main referral-page">
      <div className="row">
        <h2 className="title display-2">User Info - {userInfo?.user?.name}</h2>
      </div>

      <Tabs tabs={tabs} className="bg-white" />
    </motion.main>
  );
}
