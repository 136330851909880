import React, { useEffect, useState } from "react";
import ReferredUsers from "../../referral/tabs/ReferredUsers";
import { Button, Field, TableSkeleton } from "../../../components";
import localData from "../../../localData";
import Reports from "../../referral/tabs/Reports";
import ReferralPayouts from "../../referral/tabs/ReferralPayouts";
import { auth } from "../../../config/firebase";
import { useGlobalContext } from "../../../context";
import { Grid } from "@mui/material";

import useFetch from "../../../hooks/useFetch";

export default function UserReferrals({ referralInfo, loading }) {
  const { updateReferralCode, updateReferralCommission } = useFetch();

  const [details, setDetails] = useState(null);
  const [code, setCode] = useState("");
  const [valid, setValid] = useState(true);
  const [commissionValid, setCommissionValid] = useState(true);
  const [commission, setCommission] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { successAlert, errorAlert } = useGlobalContext();
  useEffect(() => {
    if (referralInfo) {
      console.log("setting");
      console.log(referralInfo);
      setDetails(referralInfo);
      setCode(referralInfo?.user?.referralCode);
      setCommission(referralInfo?.user?.referralCommission || 10);
    }
  }, [referralInfo]);

  const { textPreloader } = localData.images;
  const handleCodeChange = (e) => {
    const { value } = e.target || e;
    setValid(value?.length > 0);
    setCode(value);
  };

  const handleCommissionChange = (e) => {
    const { value } = e.target || e;
    setCommissionValid(value > 0);
    setCommission(value);
  };

  const handleCodeSubmit = async () => {
    setButtonLoading(true);
    try {
      if (!auth.currentUser) return;
      const raw = {
        uuid: auth.currentUser.uid,
        userId: details.user.id,
        code: code,
      };
      const result = await updateReferralCode(() => {}, raw);
      if (result.res_sts) {
        successAlert(result.res_msg);
      }
    } catch (error) {
      errorAlert(error.message || "Error updating code");
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleCommissionSubmit = async () => {
    setButtonLoading(true);
    try {
      if (!auth.currentUser) return;
      const raw = {
        uuid: auth.currentUser.uid,
        userId: details.user.id,
        commission: commission,
      };
      const result = await updateReferralCommission(() => {}, raw);
      if (result.res_sts) {
        successAlert(result.res_msg);
      }
    } catch (error) {
      errorAlert(error.message || "Error updating commission");
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <TableSkeleton message="Loading..." icon={textPreloader} />
      ) : (
        <div className="referral-program">
          <h2 className="display-2">User Referral Details</h2>
          <br />
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} md={5} className="card">
              <Field
                type="text"
                label="Referral code:"
                color="secondary"
                required={true}
                size="sm"
                readOnly={false}
                className="horizontal"
                placeholder="user@email.com"
                value={code}
                callback={handleCodeChange}
                errorMessage={!valid ? `Referral code cant be empty` : ""}
              ></Field>
              <Button
                fullWidth
                variant="contained"
                onClick={handleCodeSubmit}
                disabled={!valid || buttonLoading}
              >
                {buttonLoading ? "Loading..." : "Update code"}
              </Button>
            </Grid>
            <Grid item xs={12} md={5} className="card">
              <Field
                type="number"
                label="Referral Commission %:"
                color="secondary"
                required={true}
                size="sm"
                readOnly={false}
                className="horizontal"
                placeholder="user@email.com"
                value={commission}
                callback={handleCommissionChange}
                errorMessage={!valid ? `Commission has to be above 0` : ""}
              ></Field>
              <Button
                fullWidth
                variant="contained"
                onClick={handleCommissionSubmit}
                disabled={!commissionValid || buttonLoading}
              >
                {buttonLoading ? "Loading..." : "Update commission"}
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
          {details?.invites?.length > 0 ? (
            <ReferredUsers details={details}></ReferredUsers>
          ) : (
            ""
          )}
          {details?.commissions?.length > 0 ? (
            <Reports details={details} />
          ) : (
            ""
          )}
          {details?.payouts?.length > 0 ? (
            <ReferralPayouts details={details} />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
