import React from "react";
import ReferralTable from "../ReferralTable";

export default function ReferralPayouts({ details }) {
  const headers = [
    {
      header: "Address",
      prop: "address",
    },
    {
      header: "Amount",
      prop: "amount",
      type: "amount",
    },
    {
      header: "Status",
      prop: "status",
      type: "status",
    },
    {
      header: "TXID",
      prop: "txid",
    },
    {
      header: "Created At",
      prop: "createdAt",
      type: "date",
    },
  ];
  return (
    <div className="reports">
      <h2 className="display-2">Payouts Reports: </h2>
      <p className="text-style-1">Referrals commsision payouts</p>
      <br />
      <ReferralTable
        allRows={details.payouts}
        isLoading={false}
        headers={headers}
      />
      <br />
    </div>
  );
}
