import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import localData from '../../localData';
import { useGlobalContext } from '../../context';
import { Button } from '../../components';

const AnalyticsConnectionPrompt = ({ isAnalyticsConnected, handleConnectAnalytics, isLoading, children }) => {
  const {analyticsState, getDisconnectAnalyticsAccount} = useGlobalContext()
  const { link,  disconnect, unlink  } = localData.svgs;
  const { preloaderBig } = localData.images;

  if (isAnalyticsConnected) {
    return children;
  }

  const {
    setAnalyticsProperties,
  } = analyticsState;


  const handleDisconnectAnalytics = () => {
    setAnalyticsProperties([]);
    getDisconnectAnalyticsAccount();
  };


  


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
        padding: '2rem'
      }}
    >
      <Card 
        sx={{
          maxWidth: 500,
          width: '100%',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          textAlign: 'center',
          padding: '2rem'
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
            Connect Google Analytics
          </Typography>
          
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            To view your website analytics data, please connect your Google Analytics account. 
            This will allow you to see visitor insights directly on this platform.
          </Typography>
          
          <Button
              id="analytics-tutorial-2"
              color={`${isAnalyticsConnected ? "danger" : "success"}`}
              startIcon={isAnalyticsConnected ? unlink : link}
              variant="contained"
              onClick={() =>
                isAnalyticsConnected
                  ? handleDisconnectAnalytics()
                  : handleConnectAnalytics()
              }
              endIcon={
                isLoading.analyticsDisconnectIsLoading ||
                isLoading.analyticsConnectIsLoading ? (
                  <span className="endIcon">
                    <img src={preloaderBig} />
                  </span>
                ) : null
              }
              disabled={
                isLoading.analyticsDisconnectIsLoading ||
                isLoading.analyticsConnectIsLoading
              }
            >
              {isAnalyticsConnected
                ? "Disconnect Google Analytics"
                : "Connect Google Analytics"}
            </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnalyticsConnectionPrompt;