import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useGlobalContext } from '../../../context';

const PerformanceDropdown = () => {
  const {performanceOption, setPerformanceOption} = useGlobalContext()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleOptionSelect = (option) => {
    setPerformanceOption(option);
    handleClose();
  };
  
  const options = [
    'Impressions',
    'Clicks',
    'CTR',
    'Ad Spend',
    'CPC',
    'Conversions'
  ];
  
  return (
    <Box sx={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
      <Button
        id="performance-button"
        aria-controls={open ? 'performance-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          width: '100%',
          backgroundColor: '#3174e0',
          color: 'white',
          minWidth: "150px",
          textTransform: 'none',
          fontWeight: 'normal',
          fontSize: '15px',
          borderRadius: '5px',
          padding: '6px 16px',
          marginBottom: "5px",
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: '#2861c6',
          }
        }}
      >
        {performanceOption}
      </Button>
      <Menu
        id="performance-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'performance-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
            marginTop: '4px',
            width: anchorEl ? anchorEl.clientWidth : undefined,
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option}
            onClick={() => handleOptionSelect(option)}
            sx={{
              fontSize: '16px',
              padding: '12px 16px',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default PerformanceDropdown;