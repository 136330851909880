import React from 'react';
import { Box, Typography, Button, CircularProgress, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PaymentUrlDisplay = ({ paymentUrl, onReset, isLoading }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(paymentUrl);
  };

  const handlePayNow = () => {
    window.open(paymentUrl, '_blank');
  };

  if (!paymentUrl) return null;

  return (
    <Box 
      sx={{ 
        mt: 3, 
        p: 1.5, 
        bgcolor: '#f8f9fa', 
        borderRadius: '8px',
        border: '1px solid #e0e0e0'
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 2, fontSize: "12px" }}>
        Share Payment URL
      </Typography>
      
      <Box sx={{ display: 'flex', mb: 1.5 }}>
        <TextField
          fullWidth
          value={paymentUrl}
          size="small"
          InputProps={{
            style: { height: '32px', fontSize: '0.8rem' },
            readOnly: true,
            sx: { 
              backgroundColor: '#fff',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }
          }}
        />
        <Button
          variant="contained"
          onClick={handleCopy}
          sx={{ 
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: '#1f8352',
            '&:hover': {
              backgroundColor: '#1f8352'
            },
            fontSize: '0.75rem',
            minWidth: '60px',
            height: '32px'
          }}
          size='small'
        >
          Copy
        </Button>
      </Box>
      
      <Box sx={{ textAlign: 'center', my: 1 }}>
        <Typography variant="body2" color="text.secondary">OR</Typography>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          variant="contained"
          onClick={handlePayNow}
          sx={{ 
            backgroundColor: '#1f8352',
            '&:hover': {
              backgroundColor: '#1f8352'
            },
            py: 0.25,
            fontSize: '0.75rem',
            height: '28px'
          }}
          size='small'
        >
          Pay Now
        </Button>
        
        <Button
          variant="text"
          onClick={onReset}
          startIcon={<CloseIcon sx={{ fontSize: '16px' }} />}
          sx={{ 
            color: 'black',
            fontSize: '0.75rem',
            height: '28px'
          }}
          size='small'
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentUrlDisplay;