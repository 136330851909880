import React, { useEffect, useState, useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({
  data = [],
  defaultData = [],
  isLoading = false,
  lines = [],
  className = "",
  isTooltipFormatted = false,
  isHover = false,
}) {
  const { formatNumber, formatCurrency, addCommas } = useFormatter();
  const [processedLines, setProcessedLines] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [hideYAxis, setHideYAxis] = useState(false);
  const [showDollarSign, setShowDollarSign] = useState(false);
  
  // Create a deep clone of lines prop - this prevents mutation of parent state
  // This is the key fix that breaks the infinite update cycle
  const clonedLines = useMemo(() => {
    return Object.fromEntries(
      Object.entries(lines).map(([key, value]) => [key, {...value}])
    );
  }, [lines]);
  
  useEffect(() => {
    const adjustYAxis = (linesObj) => {
      // Create standalone line objects that won't affect parent state
      const lineArray = Object.keys(linesObj).map((key) => ({
        ...linesObj[key],
        key,
      }));
      
      const activeLines = lineArray.filter((line) => line.isActive);      
      const isAmountSpentSelected = activeLines.some(
        (line) => line.propertyName === "amount_spent"
      );      
      setShowDollarSign(isAmountSpentSelected);

      // Determine axis configuration
      let hideYAxisValue = false;
      const processedLines = lineArray.map(line => {
        // Create a new object instead of modifying the existing one
        const processedLine = {...line};
        
        // Set normalizeName and originalName
        if (line.propertyName === "amount_spent") {
          processedLine.normalizeName = "normalizeAmount_spent";
          processedLine.originalName = "amount_spent";
        } else if (line.propertyName === "clicks") {
          processedLine.normalizeName = "normalizeClicks";
          processedLine.originalName = "clicks";
        } else if (line.propertyName === "impressions") {
          processedLine.normalizeName = "normalizeImpressions";
          processedLine.originalName = "impressions";
        } else if (line.propertyName === "conversions") {
          processedLine.normalizeName = "normalizeConversions";
          processedLine.originalName = "conversions";
        } else {
          processedLine.normalizeName = line.propertyName;
          processedLine.originalName = line.propertyName;
        }
        
        // Set axis configuration
        if (activeLines.length === 1) {
          processedLine.isRightAxis = "left";
        } else if (activeLines.length === 2) {
          const isFirstActive = activeLines[0].propertyName === line.propertyName && 
                                activeLines[0].key === line.key;
          processedLine.isRightAxis = isFirstActive ? "left" : "right";
        } else if (activeLines.length > 2) {
          processedLine.isRightAxis = "right";
          hideYAxisValue = true;
        }
        
        return processedLine;
      });
      
      setHideYAxis(hideYAxisValue);
      return processedLines;
    };

    setProcessedLines(adjustYAxis(clonedLines));
  }, [clonedLines]);

  useEffect(() => {
    const normalizeData = (sourceData, linesObj) => {
      if (!sourceData.length) {
        if (defaultData.length) {
          return defaultData.map(item => {
            const newItem = { ...item };
            
            Object.keys(linesObj).forEach(key => {
              if (linesObj[key].isActive) {
                const propertyName = linesObj[key].propertyName;
                newItem[propertyName] = 0;
                
                if (propertyName === 'amount_spent') newItem.normalizeAmount_spent = 0;
                if (propertyName === 'clicks') newItem.normalizeClicks = 0;
                if (propertyName === 'impressions') newItem.normalizeImpressions = 0;
                if (propertyName === 'conversions') newItem.normalizeConversions = 0;
              }
            });
            
            return newItem;
          });
        }
        
        return [{ 
          name: "No Data", 
          amount_spent: 0, 
          clicks: 0, 
          impressions: 0, 
          conversions: 0,
          normalizeAmount_spent: 0, 
          normalizeClicks: 0, 
          normalizeImpressions: 0,
          normalizeConversions: 0
        }];
      }
      
      const activeCount = Object.keys(linesObj).filter(key => linesObj[key].isActive).length;

      if (activeCount > 2) {
        return sourceData.map(item => ({
          ...item,
          normalizeAmount_spent: item.amount_spent !== undefined ? Math.log10(item.amount_spent + 1) : 0,
          normalizeClicks: item.clicks !== undefined ? Math.log10(item.clicks + 1) : 0,
          normalizeImpressions: item.impressions !== undefined ? Math.log10(item.impressions + 1) : 0,
          normalizeConversions: item.conversions !== undefined ? Math.log10(item.conversions + 1) : 0,
        }));
      }

      return [...sourceData];
    };

    setProcessedData(normalizeData(data, clonedLines));
  }, [data, clonedLines]);
  
  return (
    <div className={`position ${className}`}>
      <div className="position-inner">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={processedData.length ? processedData : defaultData}
            margin={{
              top: 30,
              right: 30,
              left: leftMargin,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              dy={15}
              tick={{ fontSize: fontSize }}
              interval="preserveStartEnd"
              tickFormatter={(value) => {
                return value;
              }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              axisLine={false}
              tick={{ fontSize: fontSize }}
              tickLine={false}
              tickFormatter={(tick) => {
                const leftAxisLine = processedLines.find(
                  (line) => line.isRightAxis === "left" && line.isActive
                );
                return leftAxisLine?.propertyName === "amount_spent"
                  ? `$${addCommas(tick)}`
                  : addCommas(tick) || "0";
              }}
              dx={-15}
              hide={hideYAxis}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tick={{ fontSize: fontSize }}
              tickLine={false}
              tickFormatter={(tick) => {
                const rightAxisLine = processedLines.find(
                  (line) => line.isRightAxis === "right" && line.isActive
                );
                return rightAxisLine?.propertyName === "amount_spent"
                  ? `$${addCommas(tick)}`
                  : addCommas(tick) || "0";
              }}
              hide={hideYAxis}
            />
            <Tooltip
              formatter={(value, name, props) => {
                if (!props.payload) {
                  return "0";
                }
                
                const originalValue = props.payload;
                
                const matchingLine = processedLines.find(line => 
                  line.displayName === name && line.isActive
                );
                
                if (matchingLine) {
                  const originalPropertyName = matchingLine.originalName;
                  const originalPropertyValue = originalValue[originalPropertyName];
                  
                  if (originalPropertyValue === undefined || originalPropertyValue === null) {
                    return originalPropertyName === "amount_spent" ? formatCurrency(0, 2) : "0";
                  }
                  
                  if (originalPropertyName === "amount_spent") {
                    return formatCurrency(originalPropertyValue, 2);
                  }
                  if (originalPropertyName === "impressions" || originalPropertyName === "clicks" || originalPropertyName === "conversions") {
                    return formatNumber(originalPropertyValue);
                  }
                }

                console.log(originalValue, "originalValue123");
                
                
                if (isTooltipFormatted) {
                  if (name === "CPM")
                    return formatCurrency(originalValue.AllCPM, 2);
                  if (name === "CPC")
                    return formatCurrency(originalValue.AllCPC, 2);
                }
                
                if (name === "Ad Spend")
                  return formatCurrency(originalValue.amount_spent, 2);
                if (name === "CPM") 
                  return formatCurrency(originalValue.CPM, 2);
                if (name === "CPC") 
                  return formatCurrency(originalValue.CPC, 2);
                if (name === "CTR") 
                  return `${(originalValue.CTR).toFixed(2)}%`
                if (name === "unknown") 
                  return "0";
                
                return formatNumber(value);
              }}
            />
            {processedLines.map((line, index) => {
              if (!line.isActive) return null;
              
              const activeCount = processedLines.filter(l => l.isActive).length;
              const dataKey = activeCount > 2 ? line.normalizeName : line.propertyName;
              
              return (
                <Line
                  key={index}
                  dataKey={dataKey}
                  name={line.displayName}
                  yAxisId={line.isRightAxis}
                  type="monotone"
                  strokeWidth="3"
                  stroke={line.color}
                  dot={isHover}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}