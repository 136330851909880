import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import ReferralProgram from "./tabs/ReferralProgram";
import PayPal from "./tabs/PayPal";
import Reports from "./tabs/Reports";
import useFetch from "../../hooks/useFetch";
import { auth } from "../../config/firebase";
import ReferralMeida from "./tabs/ReferralMedia";

export default function Referral() {
  const { getReferralDetails } = useFetch();

  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!auth.currentUser) return;
      const raw = {
        uuid: auth.currentUser.uid,
      };
      const result = await getReferralDetails(() => {}, raw);
      setDetails(result.res_data);
    } catch (error) {
      console.error("Error fetching referral info:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    document.title = "Referral Program";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Refer friends, get rewards! Join our referral program today.";
    fetchData();
  }, []);

  const {
    animations: { fade },
  } = useGlobalContext();
  const {
    referral,
    reports,
    paypal,
    users,
    pen,
    transferMoney,
    handHoldingDollar,
    sendDollar,
  } = localData.svgs;
  const { avatar } = localData.images;

  const tabs = [
    {
      startIcon: referral,
      title: "Dashboard",
      content: (
        <ReferralProgram
          details={details}
          loading={isLoading}
          fetchData={fetchData}
        />
      ),
    },
    {
      startIcon: reports,
      title: "Commissions",
      content: (
        <Reports fetchData={fetchData} details={details} loading={isLoading} />
      ),
    },
    {
      startIcon: paypal,
      title: "Payouts",
      content: (
        <PayPal fetchData={fetchData} details={details} loading={isLoading} />
      ),
    },
    {
      startIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
      <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
      <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
    </svg>,
      title: "Referral Media",
      content: (
        <ReferralMeida details={details}/>
      ),
    },
  ];

  return (
    <motion.main {...fade} className="main referral-page">
      <br />
      {/* <Navbar title="Referral Program" /> */}
      {/* <div className="paypal card-body">
        <div className="paypal-banner">
          <h2 className="display-3">Your Referral Account</h2>
          <p className="text-style-1">
            Within this section, you can access a comprehensive array of tools
            and features to manage, customize, and monitor the various settings,
            preferences, and details tied to your Payout account, ensuring
            seamless control and optimization of your financial transactions and
            experiences.
          </p>
        </div>

      </div> */}

      <Tabs tabs={tabs} className="bg-white" />
      <br />
      <br/>
      <div className="paypal-cards-group">
          <div className="card paypal-card">
            <div className="card-icon">{transferMoney}</div>
            <div className="card-title">Referral Dashboard</div>
            <p className="card-description">
              Maximize your earnings with insights. Monitor referral invites and
              analyze performance.
            </p>
          </div>
          <div className="card paypal-card">
            <div className="card-icon">{sendDollar}</div>
            <div className="card-title">Commission Tracking</div>
            <p className="card-description">
              Track your earnings effortlessly. Stay updated on referral
              performance and commissions.
            </p>
          </div>
          <div className="card paypal-card">
            <div className="card-icon">{handHoldingDollar}</div>
            <div className="card-title">Payout History</div>
            <p className="card-description">
              Review past transactions easily, request payouts and access your
              complete payout history.
            </p>
          </div>
        </div>
    </motion.main>
  );
}
