import React, { useState, useEffect, useRef } from "react";
import { Select, TableSkeleton, Pagination } from "../../../components";
import { v4 as uuidv4 } from "uuid";
import { useGlobalContext } from "../../../context";
import useFormatter from "../../../hooks/useFormatter";

export default function AnalyticsTable() {
    const {
        requestedData: {
            analyticsProjectData: {
                tableData,
                transformedData
            },
        },
        isLoading,
        analyticsState,
        
    } = useGlobalContext();
    const {
        analyticsData,
        setAnalyticsData,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        activeAnalytic,
        setActiveAnalytic,
        activeAnalyticRows,setActiveAnalyticRows
    } = analyticsState;
    const analyticsTableRef = useRef(null);

    const handlePagination = (nextPage = 0) => {
        setPage(nextPage);
        const indexOfLastItem = nextPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        const currentPageItems = transformedData.slice(indexOfFirstItem, indexOfLastItem);
        setAnalyticsData(currentPageItems);
    };

    const handlePageSizeChange = (activeItem) => {
        setPageSize(activeItem.title);
        const tempAnalyticsData = transformedData.slice(0, activeItem.title);
        setAnalyticsData(tempAnalyticsData);
        setPage(1);

        const analyticsStates = JSON.parse(localStorage.getItem("analyticsStates"));
        localStorage.setItem(
            "analyticsStates",
            JSON.stringify({ ...analyticsStates, pageSize: activeItem.title })
        );
    };

    useEffect(() => {
        const tempAnalyticsData = transformedData.slice((page - 1) * pageSize, page * pageSize);
        setAnalyticsData(tempAnalyticsData);
    }, [transformedData]);

    useEffect(() => {
        setTotalPages(Math.ceil(transformedData.length / pageSize));
        setActiveAnalyticRows(analyticsData)
    }, [analyticsData]);

    const {addCommas} = useFormatter()

    function formatTimeFromMilliseconds(milliseconds) {
        let seconds = Math.floor(milliseconds / 1000);
      
        const MONTH_IN_SECONDS = 30 * 24 * 60 * 60; // Approximate month as 30 days
        const DAY_IN_SECONDS = 24 * 60 * 60;
        const HOUR_IN_SECONDS = 60 * 60;
        const MINUTE_IN_SECONDS = 60;
      
        const months = Math.floor(seconds / MONTH_IN_SECONDS);
        seconds %= MONTH_IN_SECONDS;
      
        const days = Math.floor(seconds / DAY_IN_SECONDS);
        seconds %= DAY_IN_SECONDS;
      
        const hours = Math.floor(seconds / HOUR_IN_SECONDS);
        seconds %= HOUR_IN_SECONDS;
      
        const minutes = Math.floor(seconds / MINUTE_IN_SECONDS);
        const remainingSeconds = seconds % MINUTE_IN_SECONDS;
      
        let result = [];
        if (months > 0) result.push(`${months}mo`);
        if (days > 0) result.push(`${days}d`);
        if (hours > 0) result.push(`${hours}h`);
        if (minutes > 0) result.push(`${minutes}m`);
        if (remainingSeconds > 0 || result.length === 0) result.push(`${remainingSeconds}s`);
      
        return result.join(' ');
      }
      

    return (
        <div className="analytics-table-wrapper" ref={analyticsTableRef}>
            <h2 className="display-2">Traffic Sources</h2>
            <br />

            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.analyticsProjectIsLoading ? (
                    <TableSkeleton />
                ) : (
                    <table className="table analytics-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-source" style={{ width: "20%" }}>
                                    First User Source
                                </th>

                                <th className="table-th-conversions text-center">Conversions</th>
                                <th className="table-th-engaged-sessions text-center">
                                    Engaged
                                    <br /> Sessions
                                </th>
                                <th className="table-th-engagement-rate text-center">
                                    Engagement
                                    <br /> Rate
                                </th>
                                <th className="table-th-events-count text-center">
                                    Events
                                    <br /> Count
                                </th>
                                <th className="table-th-new-users text-center">New Users</th>
                                <th className="table-th-conversions text-center">Total Revenue</th>
                                <th className="table-th-average-engagement-time text-center">
                                    Average
                                    <br /> Engagement Time
                                </th>

                                <th className="table-th-engaged-sessions-per-user text-center">
                                    Engaged
                                    <br /> Sessions per User
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!analyticsData.length ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                analyticsData.map((item, index) => {
                                    if(!item.id) item.id = uuidv4()
                                    return (
                                        <React.Fragment key={index}>
                                            <tr
                                                // className={`table-row ${item?.id === activeAnalytic?.id ? "active" : ""}`}
                                                className={`table-row `}
                                                // onClick={() => {
                                                //     setActiveAnalytic(item);
                                                // }}
                                            >
                                                <td className="table-td text-start">
                                                    {item.firstUserSource}
                                                </td>
                                                <td className="table-td text-center">{addCommas(item.conversions) || "0"}</td>
                                                <td className="table-td text-center">{addCommas(item.sessions) || "0"}</td>
                                                <td className="table-td text-center">
                                                    {item.engagementRate.toFixed(2)}%
                                                </td>
                                                <td className="table-td text-center">{addCommas(item.eventCount)}</td>
                                                <td className="table-td text-center">{addCommas(item.newUsers)}</td>
                                                <td className="table-td text-center">{addCommas(item.totalRevenue) || "0"}</td>
                                                <td className="table-td text-center">
                                                    {formatTimeFromMilliseconds(item.userEngagementDuration)}
                                                </td>
                                                <td className="table-td text-center">
                                                    0.{item.engagedSessions}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.analyticsProjectIsLoading && !analyticsData.length && (
                    <div className="no-data-cover"></div>
                )}
            </div>
            {!analyticsData.length ? (
                ""
            ) : (
                <div className="pagination-wrapper">
                    <Pagination
                        callback={handlePagination}
                        currentPage={page}
                        totalPages={totalPages}
                        isLoading={isLoading.analyticsProjectIsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: itemsPerPage,
                                setItems: setItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                                callback: handlePageSizeChange,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
