import { auth } from "../../../config/firebase";

export const initGoogleAuthPopup = (onSuccess, onError) => {
  if (!window.google) {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = initClient;
    document.head.appendChild(script);
  } else {
    initClient();
  }

  function initClient() {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: '181319171049-t8050hd7pu5i5gg9c6bgm9401v5gsesl.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/tagmanager.edit.containers https://www.googleapis.com/auth/tagmanager.edit.containerversions https://www.googleapis.com/auth/tagmanager.publish https://www.googleapis.com/auth/tagmanager.manage.users',
      ux_mode: 'popup',
      callback: (response) => {
        if (response.code) {
          console.log("Auth code received:", response.code);
          onSuccess(response.code);
        } else {
          console.error("Auth failed:", response);
          onError(response);
        }
      }
    });

    client.requestCode();
  }
};

export const handleGTMAuthentication = async (
  organizationID, 
  connectGTMAccount, 
  getAccountsGTM, 
  setGtmAccounts, 
  setIsLoading, 
  setIsGTMConfigurationPopupOpen,
  setIsGTMPopupOpen,
  onSuccess
) => {
  setIsLoading(true);
  
  try {
    initGoogleAuthPopup(
      async (authCode) => {        
        try {
          const requestData = {
            uuid: auth.currentUser?.uid,
            organizationID: organizationID,
            authCode: authCode,
          };
          
          const accountRequestData = {
            uuid: auth.currentUser?.uid,
            organizationID: organizationID,
          };
          
          await connectGTMAccount(() => {}, requestData);
          
          setIsGTMConfigurationPopupOpen(true);
          
          setTimeout(() => {
            setIsGTMPopupOpen(false);
          }, 100);

          const data = await getAccountsGTM(() => {}, accountRequestData);
          
          setGtmAccounts(data.res_data.account ? data.res_data.account : []);
          
          if (onSuccess) onSuccess();
          
        } catch (error) {
          console.error("Error connecting GTM account:", error);
        }
        
        setIsLoading(false);
      },
      (error) => {
        console.error("Google Auth Error:", error);
        setIsLoading(false);
      }
    );
  } catch (error) {
    console.error("GTM authentication failed:", error);
    setIsLoading(false);
  }
};