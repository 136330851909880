import React, { useEffect, useState } from "react";
import {
  Navbar,
  CampaignsWizard,
  Tabs,
  Modal,
  Button,
  Calendar,
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import CampaignsGraph from "./campaigns-graph/CampaignsGraph.jsx";
import CampaignsTable from "./campaigns-table/CampaignsTable.jsx";
import Blockchains from "./blockchains/Blockchains.jsx";
import CampaignObjectiveModal from "./campaign-objective-modal/CampaignObjectiveModal.jsx";
import CampaignBuildOptionModal from "./campaign-build-option-modal/CampaignBuildOptionModal.jsx";
import Joyride, { STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  ListItemText,
  TextField,
  Grid,
  Tooltip,
  Menu,
  Box,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Campaigns() {
  useEffect(() => {
    document.title = "Blockchain-Ads Platform";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content = "Empower change. Join our campaign today!";
  }, []);

  const { fade } = useGlobalContext().animations;
  const {
    requestedData,
    isLoading,
    getLoadBlockchainData,
    setSelectedObjective,
    blockchainsState,
    setIsLoading,
    setCampaignState,
    setCreatives,
    setCampaignWizardStatus,
    markedCampaigns,
    setMarkedCampaigns,
    defaultCampaign,
    requestedData: {
      userProfileData,
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    campaignsState,
    statusOption,
    setStatusOption,
    setSelectedOption,
    selectedStatusOption,
    selectedGrpahCampaigns, 
    setSelectedGraphCampaigns,
    getLoadCampaignData
  } = useGlobalContext();

  const { campaignSelectionRange, handleCalendar } = campaignsState;

  const { blockchainTabs, setActiveNetwork } = blockchainsState;

  const { plus, arrowRightExtended, checkbox } = localData.svgs;

  const [showCampaignObjectiveModal, setShowCampaignObjectiveModal] =
    useState("none");
  const [showCampaignBuildOptionModal, setShowCampaignBuildOptionModal] =
    useState("none");

  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    setCampaignState(defaultCampaign);
    setSelectedObjective("");
    setCreatives([]);
    setCampaignWizardStatus("create");
    navigate("/campaigns/create");
  };

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const marked = currentOrganizationCampaigns.filter((campaign) =>
      markedCampaigns.some(
        (markedCampaign) => markedCampaign.id === campaign.id
      )
    );

    setSelectedGraphCampaigns(marked);
  }, [currentOrganizationCampaigns, markedCampaigns]);

  useEffect(() => {
    getLoadCampaignData(
      userProfileData.currentOrganization.id,
      1,
      10,
      campaignSelectionRange.startDate,
      campaignSelectionRange.endDate
    )
  }, [campaignSelectionRange])


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    const selectedValues = typeof value === "string" ? value.split(",") : value;

    const selectedCampaignObjects = currentOrganizationCampaigns.filter(
      (campaign) => selectedValues.includes(campaign.id)
    );
    setSelectedGraphCampaigns(selectedCampaignObjects);
    setMarkedCampaigns(selectedCampaignObjects);
  };

  const handleDeleteChip = (campaignToDelete) => {
    const newSelected = selectedGrpahCampaigns.filter(
      (campaign) => campaign.id !== campaignToDelete.id
    );
    setMarkedCampaigns(newSelected);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCampaigns = currentOrganizationCampaigns.filter((campaign) =>
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const handleClickFilter = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseFilter = (option) => {
    if (option) {
      setSelectedFilter(option);
    }
    setAnchorElement(null);
  };

  const statusOptions = [
    "Active",
    "Paused",
    "Active, Paused",
    "Archived",
    "All",
  ];

  const handleChangeFilter = (option) => {
    let selectedValue = [];

    if (option === "Active") {
      selectedValue = ["Active"];
    } else if (option === "Paused") {
      selectedValue = ["Paused"];
    } else if (option === "Active, Paused") {
      selectedValue = ["Active", "Paused"];
    } else if (option === "Archived") {
      selectedValue = ["Archived"];
    } else if (option === "All") {
      selectedValue = ["Active", "Paused", "Archived"];
    }

    setStatusOption(option);
    setSelectedOption(selectedValue);

    setAnchorElement(null);
  };

  return (
    <motion.main {...fade} className="main campaigns-page">
      <div className="campaign-page-header">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Grid
            sx={{ display: "flex"}}
            className="campaign-targeting-conatiner"
          >
            <Grid sx={{ width: "100%", minWidth: "280px", maxWidth: "350px" }}>
              <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel id="campaign-multiselect-label" size="small">
                  Select Campaigns
                </InputLabel>
                <Select
                  labelId="campaign-multiselect-label"
                  id="campaign-multiselect"
                  multiple
                  size="small"
                  value={selectedGrpahCampaigns.map((campaign) => campaign.id)}
                  onChange={handleChange}
                  input={
                    <OutlinedInput label="Select Campaigns" size="small" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        maxWidth: "100%",
                        marginTop: "5px",
                      }}
                    >
                      {selected.slice(0, 2).map((value) => {
                        const campaign = currentOrganizationCampaigns.find(
                          (c) => c.id === value
                        );
                        return campaign ? (
                          <Chip
                            key={value}
                            label={campaign.name}
                            size="small"
                            deleteIcon={<CancelIcon style={{ fontSize: 16 }} />}
                            onDelete={() => handleDeleteChip(campaign)}
                            sx={{
                              maxWidth: "120px",
                              "& .MuiChip-label": {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                          />
                        ) : null;
                      })}
                      {selected.length > 2 && (
                        <Chip
                          label={`+${selected.length - 2}`}
                          size="small"
                          sx={{
                            backgroundColor: "#e0e0e0",
                            "& .MuiChip-label": {
                              fontWeight: 500,
                            },
                          }}
                        />
                      )}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 350,
                      },
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4b4b4b",
                    },
                  }}
                >
                  {/* Search field at the top of dropdown */}
                  <MenuItem
                    disableRipple
                    disableTouchRipple
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1,
                      padding: "8px",
                    }}
                  >
                    <TextField
                      placeholder="Search..."
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onClick={(e) => e.stopPropagation()}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon
                            fontSize="small"
                            sx={{ color: "#757575", mr: 1 }}
                          />
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "#4b4b4b",
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </MenuItem>

                  {/* Campaign options */}
                  {filteredCampaigns.map((campaign) => (
                    <MenuItem
                      key={campaign.id}
                      value={campaign.id}
                      sx={{ paddingY: 1 }}
                    >
                      <Checkbox
                        checked={selectedGrpahCampaigns.some(
                          (selected) => selected.id === campaign.id
                        )}
                      />
                      <Tooltip title={campaign.name} arrow>
                        <ListItemText
                          primary={campaign.name}
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingRight: "10px",
                            },
                          }}
                        />
                      </Tooltip>
                    </MenuItem>
                  ))}

                  {filteredCampaigns.length === 0 && (
                    <MenuItem disabled>
                      <ListItemText primary="No campaigns found" />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              sx={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                border: " 1px solid #4b4b4b",
                borderRadius: " 8px",
                marginLeft: " 15px",
              }}
              className="data-range-select-container"
            >
              <Calendar
                className="reports-calendar"
                _selectionRange={campaignSelectionRange}
                callback={handleCalendar}
              />
            </Grid>
           
          </Grid>

          <Grid>
            <Modal
              restProps={{ setShowCampaignBuildOptionModal }}
              Toggler={() => (
                <Button
                  name="Create Campaign"
                  variant="contained"
                  startIcon={plus}
                  color="success"
                  id="login-tutorial-3"
                  onClick={handleCreateCampaign}
                />
              )}
              Child={CampaignObjectiveModal}
              display={showCampaignObjectiveModal}
              setDisplay={setShowCampaignObjectiveModal}
              className="modal-dialog-centered campaign-objective-modal"
            />
          </Grid>
        </Grid>
        <Grid sx={{ minWidth: "260px", marginTop: "10px" }} className="campaign-status-conatiner">
              <Button
                onClick={handleClickFilter}
                style={{ color: "#5f6368", border: "1px solid #5f6368", padding: "6.5px 11px" }}
                disabled={isLoading.campaignDataIsLoading}
              >
                Filter:{" "}
                <span style={{ marginLeft: "5px" }}> {statusOption}</span>
              </Button>

              <Menu
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={() => handleCloseFilter(null)}
                PaperProps={{
                  sx: {
                    borderRadius: "12px",
                    boxShadow: 3,
                    minWidth: "180px",
                    marginTop: "5px",
                  },
                }}
              >
                {statusOptions.map((option) => {
                  const isSelected =
                    JSON.stringify(statusOption) ===
                    JSON.stringify(
                      option.split(",").map((item) => item.trim())
                    );

                  return (
                    <MenuItem
                      key={option}
                      onClick={() => handleChangeFilter(option)}
                      sx={{ fontSize: "14px", padding: "10px 16px" }}
                    >
                      {isSelected && (
                        <FiberManualRecordIcon
                          sx={{
                            color: "green",
                            width: "12px",
                            marginRight: "3px",
                          }}
                        />
                      )}
                      <span
                        style={{ marginLeft: !isSelected ? "13px" : "0px" }}
                      >
                        {option}
                      </span>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
      </div>

      <div className="campaign-page">
        <div className="" style={{ display: "block" }}>
          <h2
            className="campaign-title display-2"
            style={{ marginBottom: "5px" }}
          >
            Campaign Insights
          </h2>
        </div>

        <CampaignsJoyride />
        <br />
        <CampaignsGraph />
        <br />
        <br />
        <br />
        <CampaignsTable />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Blockchains />
      </div>
    </motion.main>
  );
}

const CampaignsJoyride = () => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          {" "}
          Welcome to Blockchain-Ads! Let's explore the main features of the
          platform.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Welcome to Blockchain-Ads",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Launch your first ad campaign here.[Launch Campaign]
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-3",
      title: "Launch Your First Ad Campaign",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Set up the pixel on the Tracking page and start measuring conversions.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-6",
      title: "Setting Up Pixel Tracking",
      isHidden: window.innerWidth < 576,
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          To switch between your account and the accounts you are a member of,
          just use the dropdown menu.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#login-tutorial-8",
      title: "Switch Accounts",
      isHidden: window.innerWidth < 576,
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;
    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "loginTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("loginTutorial")) return;
    const filteredSteps = defaultSteps.filter((step) => !step.isHidden);
    setSteps((prev) => ({
      ...prev,
      run: true,
      steps: filteredSteps,
    }));
  }, []);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      showSkipButton
      showProgress
      disableBeacon
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};
