import React, { useEffect, useState } from "react";
import {
  Tabs as CustomTabs,
} from "../../components";
import { useGlobalContext } from "../../context";
import {
  GoogleTagManagerContent,
  InstallPixelJoyRide,
  PixelContent,
  ServerToServer,
} from "./tracking-drawer/tracking-tabs/install-pixel-tab/InstallPixelTab";
import localData from "../../localData";


export default function TrackingItemAdditionInformation ({ data }) {
    const { gtm, pixel, serverToServer } = localData.images;

    const installPixelCodeTabsAudience = [
      {
        title: "Google Tag Manager",
        startImageIcon: gtm,
        content: <GoogleTagManagerContent propData={data} />,
        id: "tracking-1",
      },
      {
        title: "Tracking Pixel",
        startImageIcon: pixel,
        content: <PixelContent propData={data} />,
        id: "tracking-6",
      },
    ];
  
    const installPixelCodeTabsConversions = [
      {
        title: "Google Tag Manager",
        startImageIcon: gtm,
        content: <GoogleTagManagerContent propData={data} />,
        id: "tracking-1",
      },
      {
        title: "Tracking Pixel",
        startImageIcon: pixel,
        content: <PixelContent propData={data} />,
        id: "tracking-6",
      },
      {
        title: "Server-to-server",
        startImageIcon: serverToServer,
        content: <ServerToServer propData={data} />,
        id: "tracking-6",
      },
    ];
  
    const [instalPixelActiveTab, setInstalPixelActiveTab] = useState("");
    const [pixelTab, setPixelTab] = useState(instalPixelActiveTab);
    const [activeTab, setActiveTab] = useState("");
    const { CreatePixelTagRequest, requestedData } = useGlobalContext();
  
    useEffect(() => {
      if (requestedData?.userProfileData?.currentOrganization?.id) {
        CreatePixelTagRequest();
      }
    }, [requestedData]);
  
    return (
      <tr className="campaign-details-tr tracking-drawer">
        <td colSpan={14} className="campaign-info-td instructions-form">
          <div className="install-pixel-tab">
            <h3>
              {data.tracker_group === "1" ? "Audience" : "Conversions"} Pixel
            </h3>
            <br />
            <h3>Tag Name: {data.name}</h3>
            <InstallPixelJoyRide activeTab={activeTab} />
            <br />
            <CustomTabs
              contentId="install-pixel-tutorial-2"
              // hideTabs={true}
              tabsProps={""}
              tabs={
                data.tracker_group === "1"
                  ? installPixelCodeTabsAudience
                  : installPixelCodeTabsConversions
              }
              title="fsdfsd"
              callback={setInstalPixelActiveTab}
              activeTabInstalPixel={pixelTab}
              // indicatorIcon={question}
              // className="install-pixel-code-tabs"
            />
            <br />
          </div>
          <div></div>
        </td>
      </tr>
    );
  };
  