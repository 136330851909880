import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function useCampaignWizardData() {
    const [categoryOptions, setCategoryOptions] = useState([
        {
            title: "DeFi",
            CTR1: "0.55%",
            CTR2: "1.23%",
            CVR1: "3.10%",
            CVR2: "3.30%",
            size: "1200000",
            isSelected: false,
            id: "20ed9ad0-3b78-477d-abb4-5e95edf8d493",
        },
        {
            title: "Blockchain Gaming",
            CTR1: "0.75%",
            CTR2: "1.75%",
            CVR1: "3.20%",
            CVR2: "3.60%",
            size: "2400000",
            isSelected: false,
            id: "b003ccf1-010a-44e7-bf26-a299dacccab3",
        },
        {
            title: "Marketplace",
            CTR1: "0.57%",
            CTR2: "1.48%",
            CVR1: "3.50%",
            CVR2: "4.00%",
            size: "2400000",
            isSelected: false,
            id: "c9aef47a-37f4-4e90-8611-87f160a985c9",
        },
        {
            title: "Infrastructure",
            CTR1: "0.70%",
            CTR2: "3.30%",
            CVR1: "3.90%",
            CVR2: "1.30%",
            size: "4800000",
            isSelected: false,
            id: "4b6208ef-f2ad-45b6-8e7c-7efe8abd0039",
        },
        {
            title: "NFT",
            CTR1: "0.71%",
            CTR2: "1.08%",
            CVR1: "3.10%",
            CVR2: "3.70%",
            size: "700000",
            isSelected: false,
            id: "8578e0c2-9d4e-4f40-bdec-5c4ac2e7ea8c",
        },
        {
            title: "DEX",
            CTR1: "0.85%",
            CTR2: "1.40%",
            CVR1: "2.70%",
            CVR2: "3.40%",
            size: "2400000",
            isSelected: false,
            id: "81b01bb6-e5eb-47ed-8fd2-c6a7596e4902",
        },
        {
            title: "CEX",
            CTR1: "0.86%",
            CTR2: "1.80%",
            CVR1: "2.80%",
            CVR2: "3.50%",
            size: "8000000",
            isSelected: false,
            id: "55ad473f-71ba-44ea-9640-1dfbcc6ba32f",
        },
        {
            title: "Betting & Gambling",
            CTR1: "0.74%",
            CTR2: "1.55%",
            CVR1: "3.00%",
            CVR2: "3.60%",
            size: "1000000",
            isSelected: false,
            id: "2d4a0380-e758-4b2e-aa52-ab576928a33e",
        },
        // {
        //     title: "TradeFI",
        //     CTR1: "0.00%",
        //     CTR2: "0.00%",
        //     CVR1: "0.00%",
        //     CVR2: "0.00%",
        //     size: "0",
        //     isSelected: false,
        //     id: "2d4a0380-e758-4b2e-afdsfsdfewfsd",
        // },
        {
            title: "Other",
            CTR1: "0.00%",
            CTR2: "0.00%",
            CVR1: "0.00%",
            CVR2: "0.00%",
            size: "0",
            isSelected: false,
            id: uuidv4(),
        },
    ]);

    const [walletTargetingOptions, setWalletTargetingOptions] = useState([
        {
            title: "Metamask",
            CTR1: "0.96%",
            CTR2: "1.97%",
            CVR1: "0.65%",
            CVR2: "1.37%",
            size: "4800000",
            isSelected: false,
            id: "c3469e01-5ccd-401a-9b4a-6e1450a4ab3e",
        },
        {
            title: "Trust",
            CTR1: "0.74%",
            CTR2: "1.77%",
            CVR1: "0.50%",
            CVR2: "1.09%",
            size: "2400000",
            isSelected: false,
            id: "b9a3f121-66bd-436a-aa8d-5abf21cf05bd",
        },
        {
            title: "MEV",
            CTR1: "0.78%",
            CTR2: "1.18%",
            CVR1: "0.81%",
            CVR2: "1.08%",
            size: "24000",
            isSelected: false,
            id: "d8983fef-acd1-4d18-b4fc-75a586f4c68c",
        },
        {
            title: "Coinomi",
            CTR1: "0.82%",
            CTR2: "1.21%",
            CVR1: "0.89%",
            CVR2: "2.00%",
            size: "600000",
            isSelected: false,
            id: "217f5606-c33c-4a8b-841f-9c3806dea92c",
        },
        {
            title: "Coinbase",
            CTR1: "0.51%",
            CTR2: "1.02%",
            CVR1: "0.58%",
            CVR2: "1.05%",
            size: "8000000",
            isSelected: false,
            id: "2950a1f0-4c93-4a59-a790-7ac2884cee49",
        },
        {
            title: "OKX",
            CTR1: "0.69%",
            CTR2: "1.76%",
            CVR1: "0.52%",
            CVR2: "1.14%",
            size: "3840000",
            isSelected: false,
            id: "bdbea865-79c1-4182-a0a9-a1dd8424f19b",
        },
        {
            title: "Exodus",
            CTR1: "0.67%",
            CTR2: "1.61%",
            CVR1: "0.82%",
            CVR2: "1.65%",
            size: "240000",
            isSelected: false,
            id: "77423193-fcde-445a-872f-00ebd8472f4b",
        },
        {
            title: "Rainbow",
            CTR1: "0.87%",
            CTR2: "1.12%",
            CVR1: "0.91%",
            CVR2: "1.35%",
            size: "12000",
            isSelected: false,
            id: "2055238a-c953-4d9d-b813-f27eb8c395ad",
        },
        {
            title: "Argent",
            CTR1: "0.70%",
            CTR2: "1.54%",
            CVR1: "0.59%",
            CVR2: "1.85%",
            size: "120000",
            isSelected: false,
            id: "e421d0f5-7787-4714-926f-8d0830f3a5c0",
        },
        {
            title: "Safe",
            CTR1: "0.55%",
            CTR2: "1.06%",
            CVR1: "0.85%",
            CVR2: "1.69%",
            size: "12000",
            isSelected: false,
            id: "ca573c13-846c-4dc2-a762-12540acf239e",
        },
        {
            title: "Zerion",
            CTR1: "0.62%",
            CTR2: "1.67%",
            CVR1: "0.60%",
            CVR2: "1.32%",
            size: "84000",
            isSelected: false,
            id: "f27a8322-f413-4442-9b48-4f5d1502f867",
        },
    ]);

    const [web3AudienceOptions, setWeb3AudienceOptions] = useState([
        {
            title: "Custom",
            isSelected: false
        },
        {
            title: "BTC Holders",
            CTR1: "0.79%",
            CTR2: "1.53%",
            CVR1: "0.99%",
            CVR2: "1.95%",
            size: "6400000",
            isSelected: false,
            id: "b8004c95-8f61-40fb-b49e-fe8288cc5d07",
        },
        {
            title: "ETH Holders",
            CTR1: "0.74%",
            CTR2: "1.04%",
            CVR1: "0.52%",
            CVR2: "1.13%",
            size: "8000000",
            isSelected: false,
            id: "0ee6af6d-e095-496e-b94b-64d9c0b9bf80",
        },
        {
            title: "MATIC Holders",
            CTR1: "0.81%",
            CTR2: "1.80%",
            CVR1: "0.79%",
            CVR2: "1.78%",
            size: "2400000",
            isSelected: false,
            id: "041fc03d-f5ff-4bad-b090-36c05469ca4b",
        },
        {
            title: "NEAR Holders",
            CTR1: "0.57%",
            CTR2: "1.65%",
            CVR1: "0.77%",
            CVR2: "1.89%",
            size: "2400000",
            isSelected: false,
            id: "b6a5d52f-54ed-412c-a7dd-a5fb1c180869",
        },
        {
            title: "SOL Holders",
            CTR1: "0.56%",
            CTR2: "1.26%",
            CVR1: "0.93%",
            CVR2: "1.23%",
            size: "1800000",
            isSelected: false,
            id: "835c93cd-a706-4477-b8d9-44904662b07e",
        },
        {
            title: "BNB Holders",
            CTR1: "0.94%",
            CTR2: "1.33%",
            CVR1: "0.61%",
            CVR2: "1.62%",
            size: "1440000",
            isSelected: false,
            id: "f754cc5d-a816-4582-a6c4-e5e23217bf97",
        },
        {
            title: "Stable Coin Holders",
            CTR1: "0.66%",
            CTR2: "1.99%",
            CVR1: "0.91%",
            CVR2: "1.70%",
            size: "6000000",
            isSelected: false,
            id: "8fc4ae04-e848-4d62-9593-04a541207413",
        },
        {
            title: "DeFi",
            CTR1: "0.98%",
            CTR2: "1.32%",
            CVR1: "0.94%",
            CVR2: "1.37%",
            size: "1200000",
            isSelected: false,
            id: "a17143b4-66a2-428b-9de8-cd53226c942d",
        },
        {
            title: "NFT Collectors",
            CTR1: "0.67%",
            CTR2: "1.88%",
            CVR1: "0.96%",
            CVR2: "1.94%",
            size: "1440000",
            isSelected: false,
            id: "27f360fe-0734-472d-9da6-5fe9ea6e89dd",
        },
        {
            title: "Web3 Natives",
            CTR1: "0.60%",
            CTR2: "1.39%",
            CVR1: "0.85%",
            CVR2: "1.76%",
            size: "2400000",
            isSelected: false,
            id: "169a31fb-007f-4f7e-a5b2-1a7a1a88bf52",
        },
        {
            title: "Blockchain Technology",
            CTR1: "0.91%",
            CTR2: "1.93%",
            CVR1: "0.53%",
            CVR2: "1.03%",
            size: "8000000",
            isSelected: false,
            id: "ff15596e-5aad-47e8-8d80-f54c3136abc9",
        },
        {
            title: "Sports Betting",
            CTR1: "0.68%",
            CTR2: "1.19%",
            CVR1: "0.62%",
            CVR2: "1.53%",
            size: "1200000",
            isSelected: false,
            id: "eae07280-2239-4aca-851f-bcad1918504e",
        },
        {
            title: "PlayToEarn",
            CTR1: "0.57%",
            CTR2: "1.97%",
            CVR1: "0.71%",
            CVR2: "1.67%",
            size: "4800000",
            isSelected: false,
            id: "3128716a-1d53-4cc9-a566-f7366f1c3122",
        },
        {
            title: "HOLDers",
            CTR1: "0.53%",
            CTR2: "1.47%",
            CVR1: "0.89%",
            CVR2: "1.97%",
            size: "12000000",
            isSelected: false,
            id: "56515d1a-c281-448b-a47b-50ff921bac38",
        },
        {
            title: "Crypto News",
            CTR1: "0.54%",
            CTR2: "1.09%",
            CVR1: "0.59%",
            CVR2: "1.11%",
            size: "7200000",
            isSelected: false,
            id: "45e7fd73-942b-41fe-b521-e653b62f9cb6",
        },
        {
            title: "DeFi Trading",
            CTR1: "0.72%",
            CTR2: "1.22%",
            CVR1: "0.99%",
            CVR2: "1.04%",
            size: "3600000",
            isSelected: false,
            id: "b945fa33-df7d-4e85-ac40-3a2bf4951887",
        },
        {
            title: "Finance Users",
            CTR1: "0.87%",
            CTR2: "1.20%",
            CVR1: "0.73%",
            CVR2: "1.68%",
            size: "7200000",
            isSelected: false,
            id: "0408728c-4d19-48f1-9513-954365af84e2",
        },
        {
            title: "NFT Whales",
            CTR1: "0.60%",
            CTR2: "1.53%",
            CVR1: "0.85%",
            CVR2: "1.47%",
            size: "120000",
            isSelected: false,
            id: "9d862f17-75e7-43c3-9875-79e911c71ef2",
        },
        {
            title: "NFT Marketplaces",
            CTR1: "0.68%",
            CTR2: "1.69%",
            CVR1: "0.72%",
            CVR2: "1.31%",
            size: "3600000",
            isSelected: false,
            id: "ce1bb46f-7613-4876-a63e-5c75c9cc4a50",
        },
        {
            title: "Blockchain Gaming",
            CTR1: "0.91%",
            CTR2: "1.58%",
            CVR1: "0.66%",
            CVR2: "1.17%",
            size: "4800000",
            isSelected: false,
            id: "36dac636-0651-4ab3-b4d8-281679ddb0ff",
        },
        {
            title: "Web3 Gamers",
            CTR1: "0.87%",
            CTR2: "1.65%",
            CVR1: "0.93%",
            CVR2: "1.68%",
            size: "4320000",
            isSelected: false,
            id: "515be8f8-651d-4c7b-922b-ae1d6ef9662d",
        },
        {
            title: "Casino Players",
            CTR1: "0.74%",
            CTR2: "1.04%",
            CVR1: "0.52%",
            CVR2: "1.13%",
            size: "10000000",
            isSelected: false,
            id: "0ee6af6d-e095-496e-b94b-64d9c0b9bf23332",
        },
        {
            title: "High Value Players",
            CTR1: "0.87%",
            CTR2: "1.65%",
            CVR1: "0.93%",
            CVR2: "1.68%",
            size: "975000",
            isSelected: false,
            id: "",
        },
    ]);

    const [chainTargetingOptions, setChainTargetingOptions] = useState([
        {
            title: "Ethereum",
            CTR1: "0.52%",
            CTR2: "1.83%",
            CVR1: "0.98%",
            CVR2: "1.50%",
            size: "8000000",
            isSelected: false,
            id: "5d508f5c-5114-4650-818d-d30716f15f0f",
        },
        {
            title: "Binance Smart Chain",
            CTR1: "0.64%",
            CTR2: "1.38%",
            CVR1: "0.82%",
            CVR2: "1.59%",
            size: "1440000",
            isSelected: false,
            id: "0078d3de-9938-481c-9e44-cf8a0cbbe745",
        },
        {
            title: "Polygon",
            CTR1: "0.68%",
            CTR2: "1.99%",
            CVR1: "0.93%",
            CVR2: "1.89%",
            size: "2400000",
            isSelected: false,
            id: "d88433b6-bce2-486e-a1c7-deb414c5d7d7",
        },
        {
            title: "Solana",
            CTR1: "0.98%",
            CTR2: "1.98%",
            CVR1: "0.65%",
            CVR2: "1.38%",
            size: "1800000",
            isSelected: false,
            id: "cdcc132d-417d-4bb9-8014-4870bb7b23e9",
        },
        {
            title: "NEAR",
            CTR1: "0.78%",
            CTR2: "1.17%",
            CVR1: "0.92%",
            CVR2: "1.22%",
            size: "2400000",
            isSelected: false,
            id: "ff569b16-e9de-41b2-b119-094ed5b062c0",
        },
        {
            title: "Arbitrum",
            CTR1: "0.57%",
            CTR2: "1.81%",
            CVR1: "0.57%",
            CVR2: "1.30%",
            size: "1200000",
            isSelected: false,
            id: "19e17437-823e-4883-bd4e-11bc03661a29",
        },
        {
            title: "Optimism",
            CTR1: "0.55%",
            CTR2: "1.21%",
            CVR1: "0.60%",
            CVR2: "1.23%",
            size: "720000",
            isSelected: false,
            id: "e1d46e78-6b1f-419e-9a03-a527626224e3",
        },
        {
            title: "Ripple",
            CTR1: "0.53%",
            CTR2: "1.15%",
            CVR1: "0.65%",
            CVR2: "1.53%",
            size: "1920000",
            isSelected: false,
            id: "6a183a17-4c78-448e-bbc1-e7c9a6eec26f",
        },
        {
            title: "Cardano",
            CTR1: "0.74%",
            CTR2: "1.15%",
            CVR1: "0.64%",
            CVR2: "1.88%",
            size: "1680000",
            isSelected: false,
            id: "bc8cb956-4248-4312-ad7a-c9f3f4f5ca42",
        },
        {
            title: "Tron",
            CTR1: "0.58%",
            CTR2: "1.86%",
            CVR1: "0.57%",
            CVR2: "1.49%",
            size: "2400000",
            isSelected: false,
            id: "21618b57-3b1d-4076-bbfd-8a0ba08e3158",
        },
        {
            title: "Polkadot",
            CTR1: "0.96%",
            CTR2: "1.21%",
            CVR1: "0.67%",
            CVR2: "1.90%",
            size: "480000",
            isSelected: false,
            id: "7b96779a-dc10-4c87-a13f-3eac7f3f579e",
        },
        {
            title: "Avalanche",
            CTR1: "0.53%",
            CTR2: "1.57%",
            CVR1: "0.76%",
            CVR2: "1.92%",
            size: "720000",
            isSelected: false,
            id: "c14f2023-9f91-4863-9143-9f09e04c8f25",
        },
        {
            title: "Stellar",
            CTR1: "0.68%",
            CTR2: "1.82%",
            CVR1: "0.90%",
            CVR2: "1.92%",
            size: "1680000",
            isSelected: false,
            id: "5b85d857-fb88-4856-9291-bb5f96474a80",
        },
        {
            title: "Chainlink",
            CTR1: "0.74%",
            CTR2: "1.75%",
            CVR1: "0.87%",
            CVR2: "1.34%",
            size: "240000",
            isSelected: false,
            id: "2d9f97c2-6f66-455a-9f28-e701ab9cc126",
        },
        {
            title: "Monero",
            CTR1: "0.82%",
            CTR2: "1.73%",
            CVR1: "0.57%",
            CVR2: "1.48%",
            size: "192000",
            isSelected: false,
            id: "0a66b93e-0a46-42ef-8c6e-cd26f5c09a0b",
        },
        {
            title: "Cosmos",
            CTR1: "0.84%",
            CTR2: "1.30%",
            CVR1: "0.86%",
            CVR2: "1.63%",
            size: "120000",
            isSelected: false,
            id: "99b5a78d-6354-4e13-8c90-93f937841e7f",
        },
        {
            title: "Hedera",
            CTR1: "0.52%",
            CTR2: "1.51%",
            CVR1: "0.68%",
            CVR2: "1.76%",
            size: "120000",
            isSelected: false,
            id: "e4897740-2f62-43de-a34a-3c16b96c5356",
        },
        {
            title: "Mantle",
            CTR1: "0.53%",
            CTR2: "1.39%",
            CVR1: "0.58%",
            CVR2: "1.18%",
            size: "60000",
            isSelected: false,
            id: "1b6994f7-20b7-4853-bf2e-9216698ac684",
        },
        {
            title: "Aptos",
            CTR1: "0.53%",
            CTR2: "1.38%",
            CVR1: "0.81%",
            CVR2: "1.57%",
            size: "14400",
            isSelected: false,
            id: "af910184-a094-4786-a5c7-4f8d11c5d3e1",
        },
        {
            title: "Cronos",
            CTR1: "0.63%",
            CTR2: "1.57%",
            CVR1: "0.98%",
            CVR2: "1.99%",
            size: "240000",
            isSelected: false,
            id: "f0edcb35-0777-4001-8270-9dca9a75e170",
        },
        {
            title: "Algorand",
            CTR1: "0.88%",
            CTR2: "1.90%",
            CVR1: "0.63%",
            CVR2: "1.56%",
            size: "2400000",
            isSelected: false,
            id: "a3327055-cf0d-479c-b1a2-7464a7ea533b",
        },
        {
            title: "Texos",
            CTR1: "0.87%",
            CTR2: "1.78%",
            CVR1: "0.51%",
            CVR2: "1.14%",
            size: "240000",
            isSelected: false,
            id: "ad825187-0061-4f43-ae7b-631d60680e60",
        },
        {
            title: "Eos",
            CTR1: "0.82%",
            CTR2: "1.98%",
            CVR1: "0.66%",
            CVR2: "1.42%",
            size: "360000",
            isSelected: false,
            id: "d2ec1678-af7a-4447-9b4a-43fc817a4124",
        },
        {
            title: "Bitcoin",
            CTR1: "0.51%",
            CTR2: "1.27%",
            CVR1: "0.91%",
            CVR2: "1.26%",
            size: "6400000",
            isSelected: false,
            id: "79d50c1e-28db-4f39-87ff-0758a622c321",
        },
        {
            title: "Base",
            CTR1: "0.91%",
            CTR2: "1.40%",
            CVR1: "0.86%",
            CVR2: "1.47%",
            size: "480000",
            isSelected: false,
            id: "0298369b-5129-430e-9b0b-9f71eee3531e",
        },
        {
            title: "TON",
            CTR1: "0.91%",
            CTR2: "1.40%",
            CVR1: "0.86%",
            CVR2: "1.47%",
            size: "480000",
            isSelected: false,
            id: "0298369b-5129-430e-9b0b-9f71eee35332",
        },
    ]);

    // const [countriesOptions, setCountriesOptions] = useState([
    //     {
    //         title: "India",
    //         CTR1: "0.87%",
    //         CTR2: "1.74%",
    //         CVR1: "0.84%",
    //         CVR2: "1.93%",
    //         size: "17006078",
    //         isSelected: false,
    //         id: "31e73149-a736-4af9-82ec-99f1b2d20e59",
    //     },
    //     {
    //         title: "United States",
    //         CTR1: "0.52%",
    //         CTR2: "1.69%",
    //         CVR1: "0.86%",
    //         CVR2: "1.25%",
    //         size: "4079959",
    //         isSelected: false,
    //         id: "779f19fe-17a2-4cbd-9727-21a58deee148",
    //     },
    //     {
    //         title: "Indonesia",
    //         CTR1: "0.91%",
    //         CTR2: "1.43%",
    //         CVR1: "0.94%",
    //         CVR2: "2.00%",
    //         size: "3330409",
    //         isSelected: false,
    //         id: "077c7401-9944-41dc-9554-b6533b8c121e",
    //     },
    //     {
    //         title: "Pakistan",
    //         CTR1: "0.98%",
    //         CTR2: "1.59%",
    //         CVR1: "0.59%",
    //         CVR2: "1.77%",
    //         size: "2885828",
    //         isSelected: false,
    //         id: "9a6fb6e7-ad4c-4fbb-bfb0-7e64c66565d2",
    //     },
    //     {
    //         title: "Nigeria",
    //         CTR1: "1.00%",
    //         CTR2: "1.38%",
    //         CVR1: "0.73%",
    //         CVR2: "1.67%",
    //         size: "2690929",
    //         isSelected: false,
    //         id: "70a4439c-1caf-4b19-bcfc-cc28474e9323",
    //     },
    //     {
    //         title: "Brazil",
    //         CTR1: "0.66%",
    //         CTR2: "1.13%",
    //         CVR1: "0.51%",
    //         CVR2: "1.42%",
    //         size: "2597069",
    //         isSelected: false,
    //         id: "e7719721-1f37-4bb5-838c-3f5435520d67",
    //     },
    //     {
    //         title: "Bangladesh",
    //         CTR1: "0.86%",
    //         CTR2: "1.49%",
    //         CVR1: "0.90%",
    //         CVR2: "1.02%",
    //         size: "2098079",
    //         isSelected: false,
    //         id: "bdeaf39a-9fc5-4f70-af81-a0d7a51b0a04",
    //     },
    //     {
    //         title: "Russia",
    //         CTR1: "0.54%",
    //         CTR2: "1.16%",
    //         CVR1: "0.72%",
    //         CVR2: "1.48%",
    //         size: "1733332",
    //         isSelected: false,
    //         id: "c84a93c0-4f25-4282-9d3f-e79751b3c686",
    //     },
    //     {
    //         title: "Mexico",
    //         CTR1: "0.50%",
    //         CTR2: "1.96%",
    //         CVR1: "0.59%",
    //         CVR2: "1.43%",
    //         size: "1588494",
    //         isSelected: false,
    //         id: "697232c7-527d-4746-bcec-9c8141cc4ae5",
    //     },
    //     {
    //         title: "Japan",
    //         CTR1: "0.86%",
    //         CTR2: "1.16%",
    //         CVR1: "0.74%",
    //         CVR2: "1.31%",
    //         size: "1476348",
    //         isSelected: false,
    //         id: "59610a6b-aa6b-4007-9bc7-0cab7aa82668",
    //     },
    //     {
    //         title: "Ethiopia",
    //         CTR1: "0.58%",
    //         CTR2: "1.91%",
    //         CVR1: "0.66%",
    //         CVR2: "1.79%",
    //         size: "1518325",
    //         isSelected: false,
    //         id: "f3e37228-ff3c-4ddd-b7d5-e2389cf1994c",
    //     },
    //     {
    //         title: "Philippines",
    //         CTR1: "0.61%",
    //         CTR2: "1.73%",
    //         CVR1: "0.70%",
    //         CVR2: "1.35%",
    //         size: "1401167",
    //         isSelected: false,
    //         id: "2ce9f1c5-83fb-4ed1-a4a2-b448d30fed0c",
    //     },
    //     {
    //         title: "Egypt",
    //         CTR1: "0.59%",
    //         CTR2: "1.09%",
    //         CVR1: "0.72%",
    //         CVR2: "1.01%",
    //         size: "1352599",
    //         isSelected: false,
    //         id: "3fc9fed0-3563-456b-8310-296c056b4785",
    //     },
    //     {
    //         title: "Vietnam",
    //         CTR1: "0.54%",
    //         CTR2: "1.50%",
    //         CVR1: "0.51%",
    //         CVR2: "1.29%",
    //         size: "1186307",
    //         isSelected: false,
    //         id: "9e7f92e5-0faf-452d-bc0f-9c77d0751624",
    //     },
    //     {
    //         title: "Congo",
    //         CTR1: "0.91%",
    //         CTR2: "1.42%",
    //         CVR1: "0.90%",
    //         CVR2: "1.49%",
    //         size: "1227145",
    //         isSelected: false,
    //         id: "482f00bf-8a02-4ce1-b239-87b7c0383e0d",
    //     },
    //     {
    //         title: "Turkey",
    //         CTR1: "0.96%",
    //         CTR2: "1.86%",
    //         CVR1: "0.54%",
    //         CVR2: "1.78%",
    //         size: "1061080",
    //         isSelected: false,
    //         id: "8c8915f1-fe9c-4b67-90d0-4359a10cdc1a",
    //     },
    //     {
    //         title: "Iran",
    //         CTR1: "0.86%",
    //         CTR2: "1.15%",
    //         CVR1: "0.59%",
    //         CVR2: "1.50%",
    //         size: "1070054",
    //         isSelected: false,
    //         id: "8285962a-31b8-48a5-82db-a9d4e188c850",
    //     },
    //     {
    //         title: "Germany",
    //         CTR1: "0.70%",
    //         CTR2: "1.14%",
    //         CVR1: "0.83%",
    //         CVR2: "1.87%",
    //         size: "1013901",
    //         isSelected: false,
    //         id: "da110987-7d2c-4016-a3bd-da1ebf798d65",
    //     },
    //     {
    //         title: "Thailand",
    //         CTR1: "0.83%",
    //         CTR2: "1.47%",
    //         CVR1: "0.70%",
    //         CVR2: "1.26%",
    //         size: "860364",
    //         isSelected: false,
    //         id: "f2c518db-9d88-4135-b2d5-1f8a734d3021",
    //     },
    //     {
    //         title: "United Kingdom",
    //         CTR1: "0.57%",
    //         CTR2: "2.00%",
    //         CVR1: "0.96%",
    //         CVR2: "1.12%",
    //         size: "847910",
    //         isSelected: false,
    //         id: "19b4466f-8374-4724-858b-16a881b9b466",
    //     },
    //     {
    //         title: "France",
    //         CTR1: "0.66%",
    //         CTR2: "1.16%",
    //         CVR1: "0.97%",
    //         CVR2: "1.73%",
    //         size: "789560",
    //         isSelected: false,
    //         id: "3f9a4e8d-a9fe-4203-b79a-5b52acc45aa9",
    //     },
    //     {
    //         title: "Italy",
    //         CTR1: "0.81%",
    //         CTR2: "1.02%",
    //         CVR1: "0.54%",
    //         CVR2: "1.62%",
    //         size: "707647",
    //         isSelected: false,
    //         id: "dfee011f-00b2-4800-99d6-2ba1497d464b",
    //     },
    //     {
    //         title: "Tanzania",
    //         CTR1: "0.89%",
    //         CTR2: "2.00%",
    //         CVR1: "0.69%",
    //         CVR2: "1.35%",
    //         size: "809256",
    //         isSelected: false,
    //         id: "ea6ff9a3-8d8b-4719-9a6b-1b8deefe054f",
    //     },
    //     {
    //         title: "South Korea",
    //         CTR1: "0.53%",
    //         CTR2: "1.28%",
    //         CVR1: "0.62%",
    //         CVR2: "1.07%",
    //         size: "623696",
    //         isSelected: false,
    //         id: "7f23af17-950f-4446-a872-fc23e1ff0a17",
    //     },
    //     {
    //         title: "Kenya",
    //         CTR1: "0.95%",
    //         CTR2: "1.61%",
    //         CVR1: "0.55%",
    //         CVR2: "1.07%",
    //         size: "673184",
    //         isSelected: false,
    //         id: "53fd5870-deac-446e-9182-fc319189fe29",
    //     },
    //     {
    //         title: "Colombia",
    //         CTR1: "0.78%",
    //         CTR2: "1.13%",
    //         CVR1: "0.56%",
    //         CVR2: "1.83%",
    //         size: "650973",
    //         isSelected: false,
    //         id: "ea956171-d611-49b2-b281-056265ce1300",
    //     },
    //     {
    //         title: "Spain",
    //         CTR1: "0.50%",
    //         CTR2: "1.88%",
    //         CVR1: "0.74%",
    //         CVR2: "1.12%",
    //         size: "570492",
    //         isSelected: false,
    //         id: "63a1c378-6ca7-4841-810c-bf32df3dcb3b",
    //     },
    //     {
    //         title: "Uganda",
    //         CTR1: "0.69%",
    //         CTR2: "1.23%",
    //         CVR1: "0.83%",
    //         CVR2: "1.73%",
    //         size: "570779",
    //         isSelected: false,
    //         id: "5c60d082-be47-4963-80cb-09a7f2126d3d",
    //     },
    //     {
    //         title: "Argentina",
    //         CTR1: "0.73%",
    //         CTR2: "1.08%",
    //         CVR1: "0.89%",
    //         CVR2: "1.66%",
    //         size: "554903",
    //         isSelected: false,
    //         id: "4fe2c731-f34a-48d6-a592-01d427889e59",
    //     },
    //     {
    //         title: "Sudan",
    //         CTR1: "0.59%",
    //         CTR2: "2.00%",
    //         CVR1: "0.86%",
    //         CVR2: "1.24%",
    //         size: "589251",
    //         isSelected: false,
    //         id: "fff0b08a-9d79-48a3-99fd-69c916d04721",
    //     },
    //     {
    //         title: "Algeria",
    //         CTR1: "0.65%",
    //         CTR2: "1.17%",
    //         CVR1: "0.65%",
    //         CVR2: "1.03%",
    //         size: "553871",
    //         isSelected: false,
    //         id: "5fca152f-b6df-4f40-89ea-7b8ba148cc92",
    //     },
    //     {
    //         title: "Ukraine",
    //         CTR1: "0.95%",
    //         CTR2: "1.78%",
    //         CVR1: "0.67%",
    //         CVR2: "1.23%",
    //         size: "447477",
    //         isSelected: false,
    //         id: "4e7e78c0-76a7-4a4f-927d-bd0ae3c3fdb1",
    //     },
    //     {
    //         title: "Iraq",
    //         CTR1: "0.77%",
    //         CTR2: "1.89%",
    //         CVR1: "0.74%",
    //         CVR2: "1.44%",
    //         size: "550190",
    //         isSelected: false,
    //         id: "0c9ad4ae-6d50-42ba-a071-5d4bb6a45361",
    //     },
    //     {
    //         title: "Afghanistan",
    //         CTR1: "0.99%",
    //         CTR2: "1.75%",
    //         CVR1: "0.65%",
    //         CVR2: "1.65%",
    //         size: "525560",
    //         isSelected: false,
    //         id: "769d7abf-0cb5-456a-831e-f88357b1d7b1",
    //     },
    //     {
    //         title: "Poland",
    //         CTR1: "0.94%",
    //         CTR2: "1.10%",
    //         CVR1: "0.91%",
    //         CVR2: "1.14%",
    //         size: "453449",
    //         isSelected: false,
    //         id: "6e2342c5-1971-49b4-bb55-9eb5e1fb6a2f",
    //     },
    //     {
    //         title: "Canada",
    //         CTR1: "0.83%",
    //         CTR2: "1.77%",
    //         CVR1: "0.85%",
    //         CVR2: "1.48%",
    //         size: "481160",
    //         isSelected: false,
    //         id: "b1ec457e-4600-4563-8e95-6e5f31af0bb4",
    //     },
    //     {
    //         title: "Morocco",
    //         CTR1: "0.89%",
    //         CTR2: "1.89%",
    //         CVR1: "0.63%",
    //         CVR2: "1.06%",
    //         size: "446643",
    //         isSelected: false,
    //         id: "e037a5bc-6c84-4485-a5db-e7723b71fbf1",
    //     },
    //     {
    //         title: "Saudi Arabia",
    //         CTR1: "0.93%",
    //         CTR2: "1.32%",
    //         CVR1: "1.00%",
    //         CVR2: "1.72%",
    //         size: "447516",
    //         isSelected: false,
    //         id: "f4250f94-8764-47de-bf27-ecc0ed0671d2",
    //     },
    //     {
    //         title: "Uzbekistan",
    //         CTR1: "0.77%",
    //         CTR2: "1.56%",
    //         CVR1: "0.71%",
    //         CVR2: "1.35%",
    //         size: "421955",
    //         isSelected: false,
    //         id: "debd1395-11fb-4c47-af5e-528803eb01fa",
    //     },
    //     {
    //         title: "Malaysia",
    //         CTR1: "0.88%",
    //         CTR2: "1.61%",
    //         CVR1: "0.82%",
    //         CVR2: "1.31%",
    //         size: "402886",
    //         isSelected: false,
    //         id: "b744d8e8-8e93-4953-9292-d01578f649d1",
    //     },
    //     {
    //         title: "Peru",
    //         CTR1: "0.87%",
    //         CTR2: "1.11%",
    //         CVR1: "0.68%",
    //         CVR2: "1.30%",
    //         size: "413934",
    //         isSelected: false,
    //         id: "638012b4-1498-428d-9c21-3cc8cfe8d3b6",
    //     },
    //     {
    //         title: "Angola",
    //         CTR1: "0.60%",
    //         CTR2: "1.37%",
    //         CVR1: "0.64%",
    //         CVR2: "1.77%",
    //         size: "427068",
    //         isSelected: false,
    //         id: "176dce06-2125-422e-87df-dc09953adf53",
    //     },
    //     {
    //         title: "Ghana",
    //         CTR1: "0.75%",
    //         CTR2: "1.81%",
    //         CVR1: "0.54%",
    //         CVR2: "1.38%",
    //         size: "409462",
    //         isSelected: false,
    //         id: "b76cfed0-612b-4a77-9dd8-ee3002c06245",
    //     },
    //     {
    //         title: "Mozambique",
    //         CTR1: "0.99%",
    //         CTR2: "1.85%",
    //         CVR1: "0.89%",
    //         CVR2: "1.00%",
    //         size: "401035",
    //         isSelected: false,
    //         id: "8e45b30e-9a27-461c-8b3d-6cdcba7fda6b",
    //     },
    //     {
    //         title: "Nepal",
    //         CTR1: "0.99%",
    //         CTR2: "1.65%",
    //         CVR1: "0.60%",
    //         CVR2: "1.42%",
    //         size: "383856",
    //         isSelected: false,
    //         id: "699c0e4b-7a8e-48f4-b3c4-6bd5d1746b73",
    //     },
    //     {
    //         title: "Venezuela",
    //         CTR1: "0.98%",
    //         CTR2: "1.56%",
    //         CVR1: "0.75%",
    //         CVR2: "1.67%",
    //         size: "404855",
    //         isSelected: false,
    //         id: "f79c94ae-8150-4c30-91c5-b0daaf48a338",
    //     },
    //     {
    //         title: "Madagascar",
    //         CTR1: "0.72%",
    //         CTR2: "1.20%",
    //         CVR1: "0.56%",
    //         CVR2: "1.02%",
    //         size: "380421",
    //         isSelected: false,
    //         id: "9a02cea1-6b60-4b33-a400-e346c0f010e5",
    //     },
    //     {
    //         title: "Yemen",
    //         CTR1: "0.96%",
    //         CTR2: "1.38%",
    //         CVR1: "0.62%",
    //         CVR2: "1.63%",
    //         size: "406524",
    //         isSelected: false,
    //         id: "b36f69e2-fe1b-44e2-80ca-522b404f6db4",
    //     },
    //     {
    //         title: "North Korea",
    //         CTR1: "0.62%",
    //         CTR2: "1.31%",
    //         CVR1: "0.70%",
    //         CVR2: "1.33%",
    //         size: "308631",
    //         isSelected: false,
    //         id: "97ac8158-e747-4c8b-bf64-adbbd1ab31c8",
    //     },
    // ]);

    const [countriesOptions, setCountriesOptions] = useState([
        {
            id: 403915,
            value: "ad",
            title: "Andorra",
            size: 950,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403916,
            value: "ae",
            title: "United Arab Emirates",
            size: 120203,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403918,
            value: "af",
            title: "Afghanistan",
            size: 525560,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403920,
            value: "ag",
            title: "Antigua And Barbuda",
            size: 1212,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403921,
            value: "ai",
            title: "Anguilla",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403923,
            value: "al",
            title: "Albania",
            size: 33142,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403924,
            value: "am",
            title: "Armenia",
            size: 33337,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403926,
            value: "an",
            title: "Netherlands Antilles",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403928,
            value: "ao",
            title: "Angola",
            size: 427068,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403931,
            value: "aq",
            title: "Antarctica",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403932,
            value: "ar",
            title: "Argentina",
            size: 554903,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403934,
            value: "as",
            title: "American Samoa",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403936,
            value: "at",
            title: "Austria",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403937,
            value: "au",
            title: "Australia",
            size: 306492,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403939,
            value: "aw",
            title: "Aruba",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403940,
            value: "ax",
            title: "Aland Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403942,
            value: "az",
            title: "Azerbaijan",
            size: 121768,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403944,
            value: "ba",
            title: "Bosnia And Herzegowina",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403946,
            value: "bb",
            title: "Barbados",
            size: 3168,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403947,
            value: "bd",
            title: "Bangladesh",
            size: 2098079,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403948,
            value: "be",
            title: "Belgium",
            size: 141096,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403950,
            value: "bf",
            title: "Burkina Faso",
            size: 279799,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403951,
            value: "bg",
            title: "Bulgaria",
            size: 78244,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403953,
            value: "bh",
            title: "Bahrain",
            size: 21656,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403955,
            value: "bi",
            title: "Burundi",
            size: 154068,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403957,
            value: "bj",
            title: "Benin",
            size: 163692,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403958,
            value: "bl",
            title: "Saint Barthelemy",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403959,
            value: "bm",
            title: "Bermuda",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403961,
            value: "bn",
            title: "Brunei Darussalam",
            size: 5298,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403963,
            value: "bo",
            title: "Bolivia",
            size: 144655,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403965,
            value: "bq",
            title: "Bonaire/sint Eustatius/saba",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403966,
            value: "br",
            title: "Brazil",
            size: 2597069,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403969,
            value: "bs",
            title: "Bahamas",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403971,
            value: "bt",
            title: "Bhutan",
            size: 9330,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403972,
            value: "bv",
            title: "Bouvet Island",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403973,
            value: "bw",
            title: "Botswana",
            size: 30204,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403974,
            value: "by",
            title: "Belarus",
            size: 109512,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403975,
            value: "bz",
            title: "Belize",
            size: 5298,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403976,
            value: "ca",
            title: "Canada",
            size: 481160,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403979,
            value: "cc",
            title: "Cocos (keeling) Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403980,
            value: "cd",
            title: "Democratic Republic Of The Congo",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403982,
            value: "cf",
            title: "Central African Republic",
            size: 64548,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403983,
            value: "cg",
            title: "Congo",
            size: 1227145,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403984,
            value: "ch",
            title: "Switzerland",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403987,
            value: "ci",
            title: "Cote D Ivoire",
            size: 340675,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403988,
            value: "ck",
            title: "Cook Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403989,
            value: "cl",
            title: "Chile",
            size: 233907,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403990,
            value: "cm",
            title: "Cameroon",
            size: 342290,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403991,
            value: "cn",
            title: "China",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403992,
            value: "co",
            title: "Colombia",
            size: 650973,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403994,
            value: "cr",
            title: "Costa Rica",
            size: 62491,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403997,
            value: "cu",
            title: "Cuba",
            size: 133359,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 403999,
            value: "cv",
            title: "Cape Verde",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404001,
            value: "cw",
            title: "Curacao",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404002,
            value: "cx",
            title: "Christmas Island",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404003,
            value: "cy",
            title: "Cyprus",
            size: 15378,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404005,
            value: "cz",
            title: "Czech Republic",
            size: 129118,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404007,
            value: "de",
            title: "Germany",
            size: 1013901,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404011,
            value: "dj",
            title: "Djibouti",
            size: 12912,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404012,
            value: "dk",
            title: "Denmark",
            size: 70587,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404013,
            value: "dm",
            title: "Dominica",
            size: 870,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404014,
            value: "do",
            title: "Dominican Republic",
            size: 130896,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404016,
            value: "dz",
            title: "Algeria",
            size: 553871,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404018,
            value: "ec",
            title: "Ecuador",
            size: 220752,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404019,
            value: "ee",
            title: "Estonia",
            size: 15898,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404021,
            value: "eg",
            title: "Egypt",
            size: 1352599,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404022,
            value: "eh",
            title: "Western Sahara",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404026,
            value: "er",
            title: "Eritrea",
            size: 44688,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404027,
            value: "es",
            title: "Spain",
            size: 570492,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404029,
            value: "et",
            title: "Ethiopia",
            size: 1518325,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404035,
            value: "fi",
            title: "Finland",
            size: 66860,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404038,
            value: "fj",
            title: "Fiji",
            size: 11095,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404040,
            value: "fk",
            title: "Falkland Islands (malvinas)",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404041,
            value: "fm",
            title: "Federated States Of Micronesia",
            size: 1368,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404042,
            value: "fo",
            title: "Faroe Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404044,
            value: "fr",
            title: "France",
            size: 789560,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404047,
            value: "ga",
            title: "Gabon",
            size: 28716,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404049,
            value: "gb",
            title: "United Kingdom",
            size: 847910,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404050,
            value: "gd",
            title: "Grenada",
            size: 1488,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404052,
            value: "ge",
            title: "Georgia",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404053,
            value: "gf",
            title: "French Guiana",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404054,
            value: "gg",
            title: "Guernsey",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404055,
            value: "gh",
            title: "Ghana",
            size: 409462,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404056,
            value: "gi",
            title: "Gibraltar",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404057,
            value: "gl",
            title: "Greenland",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404059,
            value: "gm",
            title: "Gambia",
            size: 31644,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404060,
            value: "gn",
            title: "Guinea",
            size: 179172,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404062,
            value: "gp",
            title: "Guadeloupe",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404063,
            value: "gq",
            title: "Equatorial Guinea",
            size: 18698,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404064,
            value: "gr",
            title: "Greece",
            size: 125790,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404065,
            value: "gs",
            title: "South Georgia / South Sandwich Isl",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404066,
            value: "gt",
            title: "Guatemala",
            size: 217351,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404067,
            value: "gu",
            title: "Guam",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404070,
            value: "gw",
            title: "Guinea-bissau",
            size: 24312,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404071,
            value: "gy",
            title: "Guyana",
            size: 9476,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404075,
            value: "hk",
            title: "Hong Kong",
            size: 88958,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404076,
            value: "hm",
            title: "Heard And Mc Donald Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404077,
            value: "hn",
            title: "Honduras",
            size: 128436,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404079,
            value: "hr",
            title: "Croatia",
            size: 46092,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404081,
            value: "ht",
            title: "Haiti",
            size: 135336,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404083,
            value: "hu",
            title: "Hungary",
            size: 115275,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404088,
            value: "id",
            title: "Indonesia",
            size: 3330409,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404090,
            value: "ie",
            title: "Ireland",
            size: 62516,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404096,
            value: "il",
            title: "Israel",
            size: 117564,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404097,
            value: "im",
            title: "Isle Of Man",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404098,
            value: "in",
            title: "India",
            size: 17006078,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404099,
            value: "io",
            title: "British Indian Ocean Territory",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404104,
            value: "iq",
            title: "Iraq",
            size: 550190,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404105,
            value: "ir",
            title: "Iran (islamic Republic Of)",
            size: 1070054,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404106,
            value: "is",
            title: "Iceland",
            size: 4504,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404108,
            value: "it",
            title: "Italy",
            size: 707647,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404112,
            value: "je",
            title: "Jersey",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404113,
            value: "jm",
            title: "Jamaica",
            size: 32112,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404114,
            value: "jo",
            title: "Jordan",
            size: 133956,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404115,
            value: "jp",
            title: "Japan",
            size: 1476348,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404118,
            value: "ke",
            title: "Kenya",
            size: 673184,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404119,
            value: "kg",
            title: "Kyrgyzstan",
            size: 80735,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404121,
            value: "kh",
            title: "Cambodia",
            size: 200666,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404122,
            value: "ki",
            title: "Kiribati",
            size: 1440,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404128,
            value: "km",
            title: "Comoros",
            size: 10889,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404130,
            value: "kn",
            title: "Saint Kitts And Nevis",
            size: 571,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404133,
            value: "kp",
            title: "North Korea",
            size: 308631,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404134,
            value: "kr",
            title: "South Korea",
            size: 623696,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404139,
            value: "kw",
            title: "Kuwait",
            size: 57864,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404141,
            value: "ky",
            title: "Cayman Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404143,
            value: "kz",
            title: "Kazakhstan",
            size: 235541,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404144,
            value: "la",
            title: "Lao Peoples Democratic Republic",
            size: 92995,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404146,
            value: "lb",
            title: "Lebanon",
            size: 82260,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404148,
            value: "lc",
            title: "Saint Lucia",
            size: 1992,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404150,
            value: "li",
            title: "Liechtenstein",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404152,
            value: "lk",
            title: "Sri Lanka",
            size: 266280,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404155,
            value: "lr",
            title: "Liberia",
            size: 67536,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404156,
            value: "ls",
            title: "Lesotho",
            size: 26352,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404157,
            value: "lt",
            title: "Lithuania",
            size: 31925,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404159,
            value: "lu",
            title: "Luxembourg",
            size: 7930,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404161,
            value: "lv",
            title: "Latvia",
            size: 21549,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404163,
            value: "ly",
            title: "Libyan Arab Jamahiriya",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404164,
            value: "ma",
            title: "Morocco",
            size: 446643,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404165,
            value: "mc",
            title: "Monaco",
            size: 438,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404166,
            value: "md",
            title: "Moldova",
            size: 30563,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404167,
            value: "me",
            title: "Montenegro",
            size: 7260,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404168,
            value: "mf",
            title: "Saint Martin",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404169,
            value: "mg",
            title: "Madagascar",
            size: 380421,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404171,
            value: "mh",
            title: "Marshall Islands",
            size: 720,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404174,
            value: "mk",
            title: "Macedonia",
            size: 22041,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404176,
            value: "ml",
            title: "Mali",
            size: 278840,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404178,
            value: "mm",
            title: "Myanmar",
            size: 662726,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404179,
            value: "mn",
            title: "Mongolia",
            size: 41249,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404181,
            value: "mo",
            title: "Macau",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404183,
            value: "mp",
            title: "Northern Mariana Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404184,
            value: "mq",
            title: "Martinique",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404185,
            value: "mr",
            title: "Mauritania",
            size: 61188,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404187,
            value: "ms",
            title: "Montserrat",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404189,
            value: "mt",
            title: "Malta",
            size: 6456,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404191,
            value: "mu",
            title: "Mauritius",
            size: 15192,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404192,
            value: "mv",
            title: "Maldives",
            size: 6372,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404193,
            value: "mw",
            title: "Malawi",
            size: 275052,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404194,
            value: "mx",
            title: "Mexico",
            size: 1588494,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404195,
            value: "my",
            title: "Malaysia",
            size: 402886,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404197,
            value: "mz",
            title: "Mozambique",
            size: 401035,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404198,
            value: "na",
            title: "Namibia",
            size: 31741,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404202,
            value: "nc",
            title: "New Caledonia",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404204,
            value: "ne",
            title: "Niger",
            size: 337396,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404206,
            value: "nf",
            title: "Norfolk Island",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404207,
            value: "ng",
            title: "Nigeria",
            size: 2690929,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404209,
            value: "ni",
            title: "Nicaragua",
            size: 83580,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404210,
            value: "nl",
            title: "Netherlands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404213,
            value: "no",
            title: "Norway",
            size: 66033,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404215,
            value: "np",
            title: "Nepal",
            size: 383856,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404216,
            value: "nr",
            title: "Nauru",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404218,
            value: "nu",
            title: "Niue",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404221,
            value: "nz",
            title: "New Zealand",
            size: 61772,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404226,
            value: "om",
            title: "Oman",
            size: 55224,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404233,
            value: "pa",
            title: "Panama",
            size: 55068,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404235,
            value: "pe",
            title: "Peru",
            size: 413934,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404236,
            value: "pf",
            title: "French Polynesia",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404237,
            value: "pg",
            title: "Papua New Guinea",
            size: 123984,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404238,
            value: "ph",
            title: "Philippines",
            size: 1401167,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404240,
            value: "pk",
            title: "Pakistan",
            size: 2885828,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404241,
            value: "pl",
            title: "Poland",
            size: 453449,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404244,
            value: "pm",
            title: "St. Pierre And Miquelon",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404245,
            value: "pn",
            title: "Pitcairn",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404246,
            value: "pr",
            title: "Puerto Rico",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404247,
            value: "ps",
            title: "Palestinian Territories",
            size: 66084,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404249,
            value: "pt",
            title: "Portugal",
            size: 125608,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404252,
            value: "pw",
            title: "Palau",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404253,
            value: "py",
            title: "Paraguay",
            size: 90736,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404254,
            value: "qa",
            title: "Qatar",
            size: 34795,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404256,
            value: "re",
            title: "Reunion",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404259,
            value: "ro",
            title: "Romania",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404261,
            value: "rs",
            title: "Serbia",
            size: 79764,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404262,
            value: "ru",
            title: "Russian Federation",
            size: 1733332,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404264,
            value: "rw",
            title: "Rwanda",
            size: 164592,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404266,
            value: "sa",
            title: "Saudi Arabia",
            size: 447516,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404270,
            value: "sb",
            title: "Solomon Islands",
            size: 8885,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404271,
            value: "sc",
            title: "Seychelles",
            size: 1284,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404273,
            value: "sd",
            title: "Sudan",
            size: 589251,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404275,
            value: "se",
            title: "Sweden",
            size: 125804,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404277,
            value: "sg",
            title: "Singapore",
            size: 68443,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404279,
            value: "sh",
            title: "St. Helena",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404280,
            value: "si",
            title: "Slovenia",
            size: 25284,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404282,
            value: "sj",
            title: "Svalbard And Jan Mayen Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404283,
            value: "sk",
            title: "Slovakia (slovak Republic)",
            size: 64656,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404285,
            value: "sl",
            title: "Sierra Leone",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404287,
            value: "sm",
            title: "San Marino",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404289,
            value: "sn",
            title: "Senegal",
            size: 226899,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404291,
            value: "so",
            title: "Somalia",
            size: 204792,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404294,
            value: "sr",
            title: "Suriname",
            size: 7356,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404296,
            value: "ss",
            title: "South Sudan",
            size: 164182,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404298,
            value: "st",
            title: "Sao Tome And Principe",
            size: 2772,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404301,
            value: "sv",
            title: "El Salvador",
            size: 78248,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404304,
            value: "sx",
            title: "Sint Maarten",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404305,
            value: "sy",
            title: "Syrian Arab Republic",
            size: 233428,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404306,
            value: "sz",
            title: "Swaziland",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404310,
            value: "tc",
            title: "Turks And Caicos Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404311,
            value: "td",
            title: "Chad",
            size: 219343,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404313,
            value: "tf",
            title: "French Southern Territories",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404314,
            value: "tg",
            title: "Togo",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404316,
            value: "th",
            title: "Thailand",
            size: 860364,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404319,
            value: "tj",
            title: "Tajikistan",
            size: 125064,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404320,
            value: "tk",
            title: "Tokelau",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404322,
            value: "tl",
            title: "Timor-leste",
            size: 15816,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404324,
            value: "tm",
            title: "Turkmenistan",
            size: 77057,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404325,
            value: "tn",
            title: "Tunisia",
            size: 145956,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404327,
            value: "to",
            title: "Tonga",
            size: 1272,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404329,
            value: "tr",
            title: "Turkey",
            size: 1061080,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404332,
            value: "tt",
            title: "Trinidad And Tobago",
            size: 16757,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404334,
            value: "tv",
            title: "Tuvalu",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404335,
            value: "tw",
            title: "Taiwan Province Of China",
            size: 280391,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404338,
            value: "tz",
            title: "Tanzania",
            size: 809256,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404339,
            value: "ua",
            title: "Ukraine",
            size: 447477,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404341,
            value: "ug",
            title: "Uganda",
            size: 570779,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 404344,
            value: "um",
            title: "Us Minor Outlying Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405138,
            value: "us",
            title: "United States",
            size: 4079959,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405236,
            value: "uy",
            title: "Uruguay",
            size: 42491,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405237,
            value: "uz",
            title: "Uzbekistan",
            size: 421955,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405249,
            value: "va",
            title: "Holy See (vatican City State)",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405397,
            value: "vc",
            title: "Saint Vincent And The Grenadines",
            size: 1200,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405414,
            value: "ve",
            title: "Venezuela",
            size: 404855,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405603,
            value: "vg",
            title: "British Virgin Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 405608,
            value: "vi",
            title: "Us Virgin Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 406023,
            value: "vn",
            title: "Viet Nam",
            size: 1186307,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 406254,
            value: "vu",
            title: "Vanuatu",
            size: 3708,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 406744,
            value: "wf",
            title: "Wallis And Futuna Islands",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407209,
            value: "ws",
            title: "Samoa",
            size: 2724,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407472,
            value: "ye",
            title: "Yemen",
            size: 406524,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407551,
            value: "yt",
            title: "Mayotte",
            size: 0,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407575,
            value: "za",
            title: "South Africa",
            size: 723116,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407843,
            value: "zm",
            title: "Zambia",
            size: 235329,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407904,
            value: "zw",
            title: "Zimbabwe",
            size: 198360,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407905,
            value: "sco",
            title: "Scotland",
            size: 198360,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
        {
            id: 407906,
            value: "wal",
            title: "Wales",
            size: 198360,
            isSelected: false,
            CTR1: "0.57%",
            CTR2: "1.45%",
            CVR1: "0.81%",
            CVR2: "1.38%",
        },
    ]);
    return {
        categoryOptions,
        setCategoryOptions,
        walletTargetingOptions,
        setWalletTargetingOptions,
        web3AudienceOptions,
        setWeb3AudienceOptions,
        chainTargetingOptions,
        setChainTargetingOptions,
        countriesOptions,
        setCountriesOptions,
    };
}
