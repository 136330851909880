import { useEffect, useState } from "react";
import axios from "axios";
import {
  Calendar,
  Loading,
  Button,
  TableSkeleton,
  Pagination,
} from "../../../components";
import { useGlobalContext } from "../../../context";
import { default as ReactSelect } from "react-select";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import localData from "../../../localData";
const { eyeSolid } = localData.svgs;
const endDate = new Date(); // Today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
  startDate,
  endDate,
  key: "selection",
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "rgba(255,255,255,.5)",
    outline: "none",
    backgroundColor: "transparent",
    color: "black",
    fontWeight: "400",
    "&:hover": {
      borderColor: "rgba(255,255,255,.8)",
    },
    width: "100%",
    minWidth: "200px",
    maxWidth: "300px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
    color: state.data.color,
    padding: "7px 15px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: !state.isSelected && "rgba(0,0,0,0.05)",
      color: state.data.color || "black",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0px",
    overflowY: "auto",
    scrollbarWidth: "thin",
    borderTopLeftRadius: "5px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.color,
  }),
};

function formatTimestamp({ _seconds, _nanoseconds }) {
  const date = new Date(_seconds * 1000 + _nanoseconds / 1e6);
  return date.toLocaleString();
}

export default function ReportsPage() {
  const { textPreloader } = localData.images;
  const { getCampaignLogs } = useFetch();
  const [filteredItems, setFilteredItems] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState({});

  const [selectedItem, setSelectedItem] = useState(null);

  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [timeRange, setTimeRange] = useState(SELECTIONRANGE);
  const [logsInformation, setLogsInformation] = useState({
    organizationID: null,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    successAlert,
    errorAlert,
    requestedData: { userProfileData },
    requestedData
  } = useGlobalContext();

  const openModal = (details) => {
    setDetails(details);
    setShowModal(true);
  };

  const closeModal = () => {
    setDetails({});
    setShowModal(false);
  };

  const sortAndPaginate = (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    // Set the sorted data
    setData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    return sortedData;
  };

  function formatDate(value = null) {
    const date = value !== null ? new Date(value) : new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleCalendar = (range) => {
    const { startDate, endDate } = range;
    setTimeRange(range);
    setLogsInformation((prev) => ({
      ...prev,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    }));
  };
  const setItems = (data) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    setLogsData(data.slice(startIndex, endIndex));
  };

  const fetchCampaignLogs = async () => {
    setIsLoading(true);

    try {
      const data = await getCampaignLogs(() => {}, logsInformation);
      const sortedData = sortAndPaginate(data.res_data);

      setItems(sortedData);
      successAlert(data.res_msg || "Data fetched successfully!");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.msg || "An unexpected error occurred.";
      if (error?.response?.status === 404) {
        errorAlert("No Logs event found");
      } else {
        errorAlert(errorMessage);
      }
      console.error("Error fetching campaign logs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = (nextPage = 1) => {
    setPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setLogsData(data.slice(startIndex, endIndex));
  };


  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  
  useEffect(() => {
      const superAdminEmails = [
          "jack@blockchain-ads.com",
          "marko@blockchain-ads.com",
          "vlad@blockchain-ads.com",
          "contact@blockchain-ads.com",
          "rende@blockchain-ads.com"
      ];
  
      const checkSuperAdmin = () => {
          return superAdminEmails.includes(requestedData?.userProfileData?.userEmail || "");
      };
  
      setIsUserSuperAdmin(checkSuperAdmin());
  }, [requestedData]);
  
  const filterAllOrganization = () => {
    const array = userProfileData.allOrganization.map((item) => ({
      title: item.bcaName,
      isSelected: item.id === userProfileData.currentOrganization.id,
      id: item.id,
      adminID: item.adminID,
      color:
        item.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
          ? "rgb(150, 45, 255)"
          : item.adminID === userProfileData.userId
          ? "rgb(42, 171, 126)"
          : "",
      value: item.id,
      label: item.bcaName,
    }));

    array.sort((a, b) => a.title.localeCompare(b.title));

    setFilteredItems(array);

    const defaultItem = array.find(
      (item) => item.id === userProfileData.currentOrganization.id
    );
    setSelectedItem(defaultItem || null);
  };

  const handleChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    setLogsInformation((prev) => ({
      ...prev,
      organizationID: selectedOption?.id || null,
    }));
  };

  useEffect(() => {
    if (!userProfileData.allOrganization.length) return;
    filterAllOrganization();
  }, [userProfileData]);

  useEffect(() => {
    if (logsInformation.organizationID) {
      fetchCampaignLogs();
    }
  }, [logsInformation]);

  useEffect(() => {
    setLogsData([]);
  }, []);

  console.log(logsData);

  return (
    <Grid>
      <Grid
        container
        sx={{
          background: "#ffff",
          borderRadius: "8px",
          maxWidth: "600px",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Calendar
          className="reports-calendar"
          _selectionRange={timeRange}
          callback={handleCalendar}
        />
        <ReactSelect
          id="login-tutorial-8"
          value={selectedItem}
          options={filteredItems}
          className="react-select accounts-autocomplete"
          onChange={handleChange}
          placeholder="Select Account"
          menuPlacement="top"
          styles={customStyles}
        />
      </Grid>
      <br />
      <br />
      <br />
      <div
        className="modal-dialog-scrollable accounts modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">CAMPAIGN LOGS</h5>
        </div>
        {isLoading ? (
          <TableSkeleton message="Loading..." icon={textPreloader} />
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: 0 }}>Campaign</th>
                <th style={{ width: 0 }}>Operation</th>
                <th style={{ width: 0 }}>User / User ID</th>
                <th style={{ width: 0 }}>Organization ID</th>
                <th style={{ width: 0 }}>Created At</th>
                <th style={{ width: 0, textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!logsData.length ? (
                <tr>
                  <td>
                    <div className="no-data">No Data To Show!</div>
                  </td>
                </tr>
              ) : (
                logsData.map((item) => {
                  return (
                    <tr>
                      <td>{item.campaignName}</td>
                      <td>{item.operation}</td>
                      <td>{`${item.userName} / ${item.userId}`}</td>
                      <td>{item.organizationId}</td>
                      <td>{formatTimestamp(item.timestamp)}</td>
                      <td>
                        {item?.details &&
                        Object.keys(item?.details).length > 0 ? (
                          <div style={{ textAlign: "center" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="sm"
                              onClick={() => openModal(item.details)}
                            >
                              Details
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
      {!logsData.length ? (
        ""
      ) : (
        <div className={`pagination-wrapper  ${isLoading ? "on-hold" : ""}`}>
          <Pagination
            callback={handlePagination}
            currentPage={page}
            totalPages={totalPages}
            isLoading={isLoading}
          />
        </div>
      )}

      <Dialog
        open={showModal}
        onClose={() => closeModal()}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          {Object.keys(details).map((key) => (
            <div key={key} style={{ marginBottom: "10px" }}>
              <strong style={{ marginBottom: "10px" }}>{key}:</strong>
              <div>
                <p style={{ marginBottom: "5px", wordWrap: "break-word" }}>
                  <strong>Old Value:</strong>{" "}
                  {Array.isArray(details[key].oldValue)
                    ? details[key].oldValue.join(", ")
                    : details[key].oldValue}
                </p>
                <p style={{ wordWrap: "break-word" }}>
                  <strong>New Value:</strong>{" "}
                  {Array.isArray(details[key].newValue)
                    ? details[key].newValue.join(", ")
                    : details[key].newValue}
                </p>
              </div>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
