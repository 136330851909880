import React, { useState, useEffect } from "react";
import axios from "axios";
import AverageCostCard from "../../../components/cards/average-cost-card/AverageCostCard";
import adClick from "../../../assets/images/icons/adClick.svg";
import CPM from "../../../assets/images/icons/CPM.svg";
import firstTimeTransaction from "../../../assets/images/icons/firstTimeTransaction.svg";
import walletConnection from "../../../assets/images/icons/walletConnection.svg";
import newUser from "../../../assets/images/icons/newUser.svg";
import appDownload from "../../../assets/images/icons/appDownload.svg"
import { useGlobalContext } from "../../../context";

export default function AverageCosts({
  categories,
  dateRange,
}) {
  const [metrics, setMetrics] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const {activeCategory} = useGlobalContext()

  const categoryMapping = {
    blockchainGaming: "Blockchain Gaming",
    cryptoTrading: "Crypto Trading",
    deFi: "DeFi",
    NFT: "NFT",
    infrastructure: "Infrastructure",
    web3Casinos: "Gambling & Betting",
    tokenLaunches: "Token Launches",
    web3GamingCasinos: "Blockchain Gaming"
  };

  const web3GamingCasinosMetrics = {
    cpmFrom: 0.72,
    cpmTo: 1.38,
    clicks1: 1.17,
    click2: 2.01,
    registartion1: 15.30,
    registartion2: 25.70,
    activePlayers1: 22.90,
    activePlayers2: 29.30,
    deposits1: 32.00,
    deposits2: 44.70,
    firstTimeDeposit1: 25.20,
    firstTimeDeposit2: 28.10,
    averagePlayerValue1: 132.40,
    averagePlayerValue2: 449.00
  };

  const getConvertedData = (data) => {
    const tempData = JSON.parse(data.substr(47).slice(0, -2));
    const headers = tempData.table.cols.map((col) => col.label.trim());
    const rows = tempData.table.rows.map((row, rowIndex) => {
      const rowData = {};
      row.c.forEach((cell, index) => {
        const headerName = headers[index];
        rowData[headerName] = cell ? cell.f || cell.v : null;
      });
      return rowData;
    });

    return rows;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const googleVertical = categoryMapping[activeCategory];
        console.log(googleVertical, "googleVertical");
        const encodedVertical = encodeURIComponent(`'${googleVertical}'`);

        const url = `https://docs.google.com/spreadsheets/d/1LgW9uJ2CU7-WH_Vi5UdAne8EfH1qxPgnwaqk4jNrKuw/gviz/tq?sheet=Sheet1&tq=select%20*%20where%20A%20=${encodedVertical}`;

        const sheetData = await axios.get(url);
        const verticalsData = getConvertedData(sheetData.data);        

        if (verticalsData && verticalsData.length > 0) {
          setMetrics(verticalsData[0]);
        } else {
          console.warn(
            "No data found for the active vertical:",
            activeCategory
          );
          setMetrics(null);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
      setIsDataLoading(false);
    };

    fetchData();
  }, [activeCategory]);

  if (isDataLoading) {
    return <p>Loading...</p>;
  }

  if (!metrics && activeCategory !== "web3GamingCasinos") {
    return <p>No data available for this vertical: {activeCategory}</p>;
  }

  const renderCard = (title, value1, value2) => {
    if (value1 || value2) {
      return (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p>{value1 ? `Value 1: ${value1}` : "No data"}</p>
            <p>{value2 ? `Value 2: ${value2}` : "No data"}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="average-costs">
      <h3 className="display-3 average-costs-title">Average Costs</h3>

      <div className="cards-conatiner">
        {activeCategory === "web3GamingCasinos" ? (
          <>
            <AverageCostCard
              title="CPM"
              from={web3GamingCasinosMetrics.cpmFrom}
              to={web3GamingCasinosMetrics.cpmTo}
              image={CPM}
            />
            
            <AverageCostCard
              title="Ad Click"
              from={web3GamingCasinosMetrics.clicks1}
              to={web3GamingCasinosMetrics.click2}
              image={adClick}
            />
            
            <AverageCostCard
              title="Registration"
              from={web3GamingCasinosMetrics.registartion1}
              to={web3GamingCasinosMetrics.registartion2}
              image={newUser}
            />
            
            <AverageCostCard
              title="Active Players"
              from={web3GamingCasinosMetrics.activePlayers1}
              to={web3GamingCasinosMetrics.activePlayers2}
              image={newUser}
            />
            
            <AverageCostCard
              title="Deposits"
              from={web3GamingCasinosMetrics.deposits1}
              to={web3GamingCasinosMetrics.deposits2}
              image={firstTimeTransaction}
            />
            
            <AverageCostCard
              title="First Time Deposit"
              from={web3GamingCasinosMetrics.firstTimeDeposit1}
              to={web3GamingCasinosMetrics.firstTimeDeposit2}
              image={firstTimeTransaction}
            />
            
            <AverageCostCard
              title="Average Player Value"
              from={web3GamingCasinosMetrics.averagePlayerValue1}
              to={web3GamingCasinosMetrics.averagePlayerValue2}
              image={walletConnection}
            />
          </>
        ) : (
          metrics && (
            <>
              {metrics["CPC 1"] || metrics["CPC 2"] ? (
                <AverageCostCard
                  title="Ad Click"
                  from={metrics["CPC 1"]}
                  to={metrics["CPC 2"]}
                  image={adClick}
                />
              ) : null}

              {metrics["CPM 1"] || metrics["CPM 2"] ? (
                <AverageCostCard
                  title="CPM"
                  from={metrics["CPM 1"]}
                  to={metrics["CPM 2"]}
                  image={CPM}
                />
              ) : null}

              {metrics["Cost per User Acquisition 1"] ||
              metrics["Cost per User Acquisition 2"] ? (
                <AverageCostCard
                  title="User Acquisition"
                  from={metrics["Cost per User Acquisition 1"]}
                  to={metrics["Cost per User Acquisition 2"]}
                  image={newUser}
                />
              ) : null}

              {metrics["Cost per App Download 1"] ||
              metrics["Cost per App Download 2"] ? (
                <AverageCostCard
                  title="App Download"
                  from={metrics["Cost per App Download 1"]}
                  to={metrics["Cost per App Download 2"]}
                  image={appDownload}
                />
              ) : null}

              {metrics["Cost per Purchase 1"] ||
              metrics["Cost per Purchase 2"] ? (
                <AverageCostCard
                  title="First Time Transaction"
                  from={metrics["Cost per Purchase 1"]}
                  to={metrics["Cost per Purchase 2"]}
                  image={firstTimeTransaction}
                />
              ) : null}

              {metrics["Cost per Wallet Connection 1"] ||
              metrics["Cost per Wallet Connection 2"] ? (
                <AverageCostCard
                  title="Wallet Connections"
                  from={metrics["Cost per Wallet Connection 1"]}
                  to={metrics["Cost per Wallet Connection 2"]}
                  image={walletConnection}
                />
              ) : null}

              {metrics["Cost per Registration 1"] ||
              metrics["Cost per Registration 2"] ? (
                <AverageCostCard
                  title="Registration"
                  from={metrics["Cost per Registration 1"]}
                  to={metrics["Cost per Registration 2"]}
                  image={newUser}
                />
              ) : null}
            </>
          )
        )}
      </div>
    </div>
  );
}