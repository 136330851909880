import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Divider,
  Alert,
  AlertTitle
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const BankTransferDetail = () => {
  const [copiedFields, setCopiedFields] = useState({});

  const handleCopy = (text, fieldName) => {
    // Create a unique identifier for each field based on both label and section
    const fieldId = `${fieldName}`;
    
    navigator.clipboard.writeText(text)
      .then(() => {
        // Use the functional form of setState to ensure we're working with the latest state
        setCopiedFields(prev => ({ ...prev, [fieldId]: true }));
        
        setTimeout(() => {
          setCopiedFields(prev => {
            const newState = { ...prev };
            newState[fieldId] = false;
            return newState;
          });
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const DetailRow = ({ label, value, section }) => {
    // Create a unique identifier for the field
    const fieldId = `${label}`;
    
    return (
      <Box mb={0.5}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={0.5}>
          <Grid item xs>
            <Typography variant="caption" color="text.secondary" fontSize="0.7rem">
              {label}
            </Typography>
            <Typography variant="body2" fontSize="0.8rem">
              {value}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton 
              size="small"
              onClick={() => handleCopy(value, fieldId)}
              color={copiedFields[fieldId] ? "success" : "default"}
              sx={{ padding: 0.5 }}
            >
              {copiedFields[fieldId] ? (
                <CheckCircleIcon sx={{ fontSize: '1rem' }} />
              ) : (
                <ContentCopyIcon sx={{ fontSize: '1rem' }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 0.5 }} />
      </Box>
    );
  };

  return (
    <Paper  sx={{ padding: "1rem" }}>
      <Typography variant="h6" fontWeight="medium" mb={1} fontSize="1rem">
        Bank Transfer Deposit
      </Typography>
      
      <Typography variant="body2" color="text.secondary" mb={1} fontSize="0.75rem">
        Deposit funds via bank transfer to BlockchainAds Labs LLC. Transfers usually process within 1-5
        business days, but can take up to 14 days.
      </Typography>
      
      {/* Alerts */}
      <Alert 
        severity="info" 
        sx={{ 
          mb: 1.5, 
          py: 0.5, 
          px: 1,
          backgroundColor: "rgba(232, 245, 233, 0.5)", 
          border: "1px solid rgba(76, 175, 80, 0.2)",
          '& .MuiAlert-icon': { fontSize: '1rem', marginRight: 0.5, alignItems: "center" }
        }}
      >
        <Typography variant="caption" fontSize="0.7rem">Deposited funds are non-refundable.</Typography>
        <Typography variant="caption" fontSize="0.7rem" display="block">
          Ensure the sender name matches the account holder name. Mismatched names may cause delays or rejection.
        </Typography>
      </Alert>
      
      <Typography variant="subtitle1" fontWeight="medium" mb={0.5} fontSize="0.85rem">
        USD Bank Transfer Details (Wise)
      </Typography>
      
      <Box mb={1.5}>
        <Typography variant="subtitle2" mb={0.5} fontSize="0.8rem">
          For Domestic Transfers (USA)
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, bgcolor: "grey.50" }}>
          <DetailRow label="Account Number (Domestic)" value="8313742795" section="domestic" />
          <DetailRow label="Routing Number (ACH/Wire)" value="026073150" section="domestic" />
          <DetailRow label="Bank Name" value="Community Federal Savings Bank" section="domestic" />
          <DetailRow label="Bank Address" value="89-16 Jamaica Ave, Woodhaven, NY, 11421, United States" section="domestic" />
        </Paper>
      </Box>
      
      <Box mb={1.5}>
        <Typography variant="subtitle2" mb={0.5} fontSize="0.8rem">
          For International SWIFT Transfers
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, bgcolor: "grey.50" }}>
          <DetailRow label="SWIFT/BIC" value="CMFGUS33" section="international" />
          <DetailRow label="Account Number (International)" value="8313742795" section="international" />
          <DetailRow label="Bank Name (International)" value="Community Federal Savings Bank" section="international" />
          <DetailRow label="Bank Address (International)" value="89-16 Jamaica Ave, Woodhaven, NY, 11421, United States" section="international" />
        </Paper>
      </Box>
      
      <Box mb={1.5}>
        <Typography variant="subtitle2" mb={0.5} fontSize="0.8rem">
          Reference Code (Required)
        </Typography>
        <Paper variant="outlined" sx={{ p: 1, bgcolor: "rgba(255, 244, 229, 0.5)" }}>
          <DetailRow label="Reference Code" value="a76afb14-1f2f-4518-949e-96994c057cf1" section="reference" />
          <Typography variant="caption" color="warning.dark" mt={0.5} fontSize="0.7rem">
            You must include this reference when making the payment. This ensures the deposit is
            credited to your account.
          </Typography>
        </Paper>
      </Box>
      
      <Alert 
        severity="info" 
        sx={{ 
          mt: 1, 
          py: 0.5, 
          px: 1,
          backgroundColor: "rgba(227, 242, 253, 0.5)", 
          border: "1px solid rgba(33, 150, 243, 0.2)",
          '& .MuiAlert-icon': { fontSize: '1rem', marginRight: 0.5, alignItems: "center" }
        }}
      >
        <Typography variant="caption" fontSize="0.7rem">
          Account Holder Name: <strong>BlockchainAds Labs LLC</strong>
        </Typography>
      </Alert>
    </Paper>
  );
};
