import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useFormatter from "../useFormatter";
import { useGlobalContext } from "../../context";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
};

const ITEMSPERPAGE = [
    { title: 5, isSelected: false, id: uuidv4() },
    { title: 10, isSelected: false, id: uuidv4() },
    { title: 15, isSelected: true, id: uuidv4() },
]



export default function useCampaignsState({requestedData,getLoadCampaignData}) {
    const [campaignsPage, setCampaignsPage] = useState(1);
    const [campaignsPageSize, setCampaignsPageSize] = useState(10);
    const [campaignsTotalPages, setCampaignsTotalPages] = useState(10);
    


    const [campaignsItemsPerPage, setCampaignsItemsPerPage] = useState(ITEMSPERPAGE);

    const {formatDate} = useFormatter()

    const resetCampaignsState = ()=>{
        setCampaignsPage(1)
        setCampaignsPageSize(10)
        setCampaignsTotalPages(10)
        setCampaignsItemsPerPage(ITEMSPERPAGE)
        setCampaignSelectionRange(SELECTIONRANGE)
    }

    useEffect(() => {
        const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        if (campaignsStates?.pageSize) {
            let tempItemsPerPage = [...campaignsItemsPerPage];
            tempItemsPerPage = tempItemsPerPage.map((item) => ({
                title: item.title,
                isSelected: item.title === campaignsStates.pageSize,
                id: item.id,
            }));
            setCampaignsItemsPerPage(tempItemsPerPage);
            setCampaignsPageSize(campaignsStates.pageSize);
        }
    }, []);

    const [campaignSelectionRange, setCampaignSelectionRange] = useState(SELECTIONRANGE);

    useEffect(() => {
        const selectionRange = JSON.parse(localStorage.getItem("selectionRange"));

        if (selectionRange) {
            const { startDate, endDate } = selectionRange;
            setCampaignSelectionRange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                key: "selection",
            });
        }
    }, []);

    const handleCalendar = (range) => {
        const { startDate, endDate } = range;
        const dateFrom = formatDate(startDate);
        const dateTill = formatDate(endDate);
        localStorage.setItem("selectionRange", JSON.stringify(range));
        localStorage.setItem("selectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
        campaignsPage !== 1
            ? setCampaignsPage(1)
            : getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, campaignsPage, campaignsPageSize);
        setCampaignSelectionRange(range);
    };

    //  CAMPAIGNS WIZARD
    const [campaignTabs, setCampaignTabs] = useState({
        clickable: [],
        marked: [],
        disabled: [],
    });

    const [displayCreativesModal, setDisplayCreativesModal] = useState("none");
    const [creativesState, setCreativesState] = useState({
        uploadedImage: null,
        croppedImage: null,
        file: null,
    });

    

    return {
        campaignsPage,
        setCampaignsPage,
        campaignsPageSize,
        setCampaignsPageSize,
        campaignsTotalPages,
        setCampaignsTotalPages,
        campaignsItemsPerPage,
        setCampaignsItemsPerPage,
        campaignSelectionRange,
        setCampaignSelectionRange,
        resetCampaignsState,
        handleCalendar,

        // CAMPAIGNS WIZARD
        campaignTabs,
        setCampaignTabs,
        displayCreativesModal,
        setDisplayCreativesModal,
        creativesState,
        setCreativesState,
    };
}
