import React, { useEffect, useState } from "react";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase";
import useFormatter from "../../../hooks/useFormatter";
import { Pagination, TableSkeleton, Button } from "../../../components";

export default function PasswordResetList() {
  const { getPasswordResetList } = useFetch();
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { formatDate } = useFormatter();
  const [campaignsPage, setCampaignsPage] = useState(1);
  const [campaignsTotalPages, setCampaignsTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!auth.currentUser) return;
        const raw = {
          uuid: auth.currentUser.uid,
        };
        const result = await getPasswordResetList(() => {}, raw);
        // Sort the response by createdDate (latest first)
        const sortedData = result.res_data.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        );

        // Set the sorted data
        setData(sortedData);
        setCampaignsTotalPages(Math.ceil(sortedData.length / 10));
        // Set the first 20 items initially
        setItems(sortedData.slice(0, 10));
      } catch (error) {
        console.error("Error fetching reset list:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePagination = (nextPage = 1) => {
    setCampaignsPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setItems(data.slice(startIndex, endIndex));
  };

  const { textPreloader } = localData.images;
  const { check, close } = localData.svgs;

  return (
    <>
      <div
        className="modal-dialog-scrollable accounts modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">PASSWORD RESET LIST</h5>
        </div>
        <div className="scroll">
          {isLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 0 }}>Email</th>
                  <th style={{ width: 0 }}>Status</th>
                  <th style={{ width: 0, textAlign: "center" }}>
                    Created From Calendly Event
                  </th>
                  <th style={{ width: 0 }}>Created At</th>
                </tr>
              </thead>
              <tbody>
                {!data.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  items.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.email}</td>
                        <td className="table-td-status">
                          <div
                            data-tooltip-id="status-tooltip"
                            className={`status ${item.status}`}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.isCreate &&
                          (item.isCreate == "true" || item.isCreate == true) ? (
                            <Button
                              variant="circle"
                              size="sm"
                              icon={check}
                              type="button"
                            ></Button>
                          ) : (
                            <Button
                              variant="circle"
                              color="danger"
                              size="sm"
                              icon={close}
                              type="button"
                            ></Button>
                          )}
                        </td>
                        <td>{formatDate(new Date(item.createdDate))}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
        {!items.length ? (
          ""
        ) : (
          <div className={`pagination-wrapper  ${isLoading ? "on-hold" : ""}`}>
            <Pagination
              callback={handlePagination}
              currentPage={campaignsPage}
              totalPages={campaignsTotalPages}
              isLoading={isLoading}
            />
          </div>
        )}
        <br />
        <br />
      </div>
      <br />
      <br />
    </>
  );
}
