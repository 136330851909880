export const categoriesData = {
    "blockchainGaming": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1877694",
            "Clicks": "26799",
            "Conversions": "349",
            "Conversion Value": "$46,018.00",
            "Cost per Result": "$8.03"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "2281457",
            "Clicks": "18896",
            "Conversions": "392",
            "Conversion Value": "$35,891.00",
            "Cost per Result": "$10.82"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "2019291",
            "Clicks": "22615",
            "Conversions": "321",
            "Conversion Value": "$52,538.00",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "1149595",
            "Clicks": "18529",
            "Conversions": "405",
            "Conversion Value": "$50,754.00",
            "Cost per Result": "$7.67"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1915949",
            "Clicks": "24875",
            "Conversions": "333",
            "Conversion Value": "$53,443.00",
            "Cost per Result": "$10.85"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1772358",
            "Clicks": "22076",
            "Conversions": "423",
            "Conversion Value": "$59,289.00",
            "Cost per Result": "$9.65"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "1922561",
            "Clicks": "23924",
            "Conversions": "352",
            "Conversion Value": "$46,977.00",
            "Cost per Result": "$8.01"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "2006940",
            "Clicks": "19823",
            "Conversions": "363",
            "Conversion Value": "$60,930.00",
            "Cost per Result": "$9.26"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1881988",
            "Clicks": "29556",
            "Conversions": "346",
            "Conversion Value": "$57,833.00",
            "Cost per Result": "$10.68"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "1621624",
            "Clicks": "24760",
            "Conversions": "374",
            "Conversion Value": "$54,134.00",
            "Cost per Result": "$9.83"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1641890",
            "Clicks": "26587",
            "Conversions": "341",
            "Conversion Value": "$56,353.00",
            "Cost per Result": "$9.14"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1739789",
            "Clicks": "22492",
            "Conversions": "371",
            "Conversion Value": "$71,752.00",
            "Cost per Result": "$11.35"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "1813618",
            "Clicks": "25575",
            "Conversions": "458",
            "Conversion Value": "$64,747.00",
            "Cost per Result": "$9.41"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "2041581",
            "Clicks": "24445",
            "Conversions": "258",
            "Conversion Value": "$70,184.00",
            "Cost per Result": "$10.71"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1576378",
            "Clicks": "22524",
            "Conversions": "346",
            "Conversion Value": "$69,696.00",
            "Cost per Result": "$9.45"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "1592172",
            "Clicks": "24528",
            "Conversions": "351",
            "Conversion Value": "$61,885.00",
            "Cost per Result": "$8.24"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1541463",
            "Clicks": "23226",
            "Conversions": "366",
            "Conversion Value": "$73,558.00",
            "Cost per Result": "$10.78"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "2057230",
            "Clicks": "19186",
            "Conversions": "302",
            "Conversion Value": "$52,536.00",
            "Cost per Result": "$11.43"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1969972",
            "Clicks": "27148",
            "Conversions": "381",
            "Conversion Value": "$54,270.00",
            "Cost per Result": "$9.83"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1840233",
            "Clicks": "26052",
            "Conversions": "288",
            "Conversion Value": "$64,095.00",
            "Cost per Result": "$9.78"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1538188",
            "Clicks": "25901",
            "Conversions": "416",
            "Conversion Value": "$74,640.00",
            "Cost per Result": "$9.98"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1654492",
            "Clicks": "29035",
            "Conversions": "402",
            "Conversion Value": "$56,116.00",
            "Cost per Result": "$12.32"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1643323",
            "Clicks": "22966",
            "Conversions": "325",
            "Conversion Value": "$71,111.00",
            "Cost per Result": "$9.23"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1943495",
            "Clicks": "28094",
            "Conversions": "297",
            "Conversion Value": "$69,684.00",
            "Cost per Result": "$7.59"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1287465",
            "Clicks": "24266",
            "Conversions": "373",
            "Conversion Value": "$50,812.00",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1652095",
            "Clicks": "21821",
            "Conversions": "369",
            "Conversion Value": "$59,263.00",
            "Cost per Result": "$12.31"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "2039121",
            "Clicks": "28804",
            "Conversions": "324",
            "Conversion Value": "$51,502.00",
            "Cost per Result": "$10.55"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "1690317",
            "Clicks": "28864",
            "Conversions": "393",
            "Conversion Value": "$69,798.00",
            "Cost per Result": "$8.35"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "2191737",
            "Clicks": "20123",
            "Conversions": "336",
            "Conversion Value": "$39,308.00",
            "Cost per Result": "$10.21"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "2430355",
            "Clicks": "28986",
            "Conversions": "444",
            "Conversion Value": "$46,692.00",
            "Cost per Result": "$9.14"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1481056",
            "Clicks": "21779",
            "Conversions": "407",
            "Conversion Value": "$47,640.00",
            "Cost per Result": "$9.91"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1741787",
            "Clicks": "30682",
            "Conversions": "274",
            "Conversion Value": "$71,617.00",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1523520",
            "Clicks": "24168",
            "Conversions": "330",
            "Conversion Value": "$63,326.00",
            "Cost per Result": "$8.19"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "2153009",
            "Clicks": "14632",
            "Conversions": "326",
            "Conversion Value": "$55,661.00",
            "Cost per Result": "$10.06"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1254274",
            "Clicks": "24489",
            "Conversions": "397",
            "Conversion Value": "$40,472.00",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1378116",
            "Clicks": "19644",
            "Conversions": "383",
            "Conversion Value": "$50,331.00",
            "Cost per Result": "$10.27"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "2004572",
            "Clicks": "31622",
            "Conversions": "315",
            "Conversion Value": "$49,709.00",
            "Cost per Result": "$10.50"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1365001",
            "Clicks": "23771",
            "Conversions": "305",
            "Conversion Value": "$73,912.00",
            "Cost per Result": "$9.89"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "2116465",
            "Clicks": "23488",
            "Conversions": "341",
            "Conversion Value": "$47,571.00",
            "Cost per Result": "$11.13"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "1495106",
            "Clicks": "21039",
            "Conversions": "420",
            "Conversion Value": "$39,023.00",
            "Cost per Result": "$10.53"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1870374",
            "Clicks": "24895",
            "Conversions": "372",
            "Conversion Value": "$57,711.00",
            "Cost per Result": "$11.10"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1639448",
            "Clicks": "24349",
            "Conversions": "340",
            "Conversion Value": "$36,106.00",
            "Cost per Result": "$8.16"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1343852",
            "Clicks": "27535",
            "Conversions": "334",
            "Conversion Value": "$47,263.00",
            "Cost per Result": "$8.14"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1785615",
            "Clicks": "18553",
            "Conversions": "241",
            "Conversion Value": "$66,100.00",
            "Cost per Result": "$10.53"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1744488",
            "Clicks": "32464",
            "Conversions": "391",
            "Conversion Value": "$52,913.00",
            "Cost per Result": "$11.60"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "881273",
            "Clicks": "23587",
            "Conversions": "404",
            "Conversion Value": "$44,953.00",
            "Cost per Result": "$11.63"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1605312",
            "Clicks": "17951",
            "Conversions": "342",
            "Conversion Value": "$66,647.00",
            "Cost per Result": "$10.76"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1371987",
            "Clicks": "22413",
            "Conversions": "374",
            "Conversion Value": "$67,768.00",
            "Cost per Result": "$8.54"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1908004",
            "Clicks": "29303",
            "Conversions": "321",
            "Conversion Value": "$48,371.00",
            "Cost per Result": "$10.67"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "1772933",
            "Clicks": "14846",
            "Conversions": "419",
            "Conversion Value": "$49,832.00",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1440319",
            "Clicks": "26848",
            "Conversions": "299",
            "Conversion Value": "$52,240.00",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1330191",
            "Clicks": "21688",
            "Conversions": "294",
            "Conversion Value": "$43,173.00",
            "Cost per Result": "$11.11"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "1603833",
            "Clicks": "19465",
            "Conversions": "356",
            "Conversion Value": "$61,856.00",
            "Cost per Result": "$12.54"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "2269253",
            "Clicks": "18351",
            "Conversions": "355",
            "Conversion Value": "$47,967.00",
            "Cost per Result": "$11.98"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "2092920",
            "Clicks": "19967",
            "Conversions": "279",
            "Conversion Value": "$50,913.00",
            "Cost per Result": "$8.06"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1565005",
            "Clicks": "16271",
            "Conversions": "309",
            "Conversion Value": "$60,326.00",
            "Cost per Result": "$10.12"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1263808",
            "Clicks": "30671",
            "Conversions": "281",
            "Conversion Value": "$49,653.00",
            "Cost per Result": "$10.51"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "2413922",
            "Clicks": "29901",
            "Conversions": "327",
            "Conversion Value": "$58,398.00",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1723949",
            "Clicks": "26552",
            "Conversions": "385",
            "Conversion Value": "$61,600.00",
            "Cost per Result": "$10.84"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1357871",
            "Clicks": "25235",
            "Conversions": "381",
            "Conversion Value": "$67,633.00",
            "Cost per Result": "$9.81"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1388614",
            "Clicks": "27068",
            "Conversions": "346",
            "Conversion Value": "$67,795.00",
            "Cost per Result": "$11.05"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1259309",
            "Clicks": "25915",
            "Conversions": "384",
            "Conversion Value": "$53,773.00",
            "Cost per Result": "$8.76"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1987659",
            "Clicks": "25595",
            "Conversions": "325",
            "Conversion Value": "$48,756.00",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1615071",
            "Clicks": "23644",
            "Conversions": "327",
            "Conversion Value": "$45,063.00",
            "Cost per Result": "$11.50"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "2046186",
            "Clicks": "25870",
            "Conversions": "389",
            "Conversion Value": "$62,838.00",
            "Cost per Result": "$9.55"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "2124235",
            "Clicks": "20149",
            "Conversions": "406",
            "Conversion Value": "$28,980.00",
            "Cost per Result": "$11.56"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1416458",
            "Clicks": "26738",
            "Conversions": "343",
            "Conversion Value": "$71,021.00",
            "Cost per Result": "$8.61"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1593775",
            "Clicks": "22293",
            "Conversions": "317",
            "Conversion Value": "$64,257.00",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1583869",
            "Clicks": "25826",
            "Conversions": "445",
            "Conversion Value": "$50,348.00",
            "Cost per Result": "$9.43"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1820934",
            "Clicks": "25645",
            "Conversions": "368",
            "Conversion Value": "$44,891.00",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1608921",
            "Clicks": "23134",
            "Conversions": "417",
            "Conversion Value": "$54,621.00",
            "Cost per Result": "$9.65"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1476907",
            "Clicks": "20276",
            "Conversions": "369",
            "Conversion Value": "$48,675.00",
            "Cost per Result": "$8.89"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1972736",
            "Clicks": "27277",
            "Conversions": "404",
            "Conversion Value": "$44,072.00",
            "Cost per Result": "$12.05"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "1223017",
            "Clicks": "23705",
            "Conversions": "298",
            "Conversion Value": "$52,140.00",
            "Cost per Result": "$8.19"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1261252",
            "Clicks": "24543",
            "Conversions": "329",
            "Conversion Value": "$51,193.00",
            "Cost per Result": "$10.81"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1805264",
            "Clicks": "18824",
            "Conversions": "394",
            "Conversion Value": "$42,881.00",
            "Cost per Result": "$9.48"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "2019507",
            "Clicks": "19872",
            "Conversions": "360",
            "Conversion Value": "$64,483.00",
            "Cost per Result": "$11.27"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1030272",
            "Clicks": "25997",
            "Conversions": "384",
            "Conversion Value": "$29,824.00",
            "Cost per Result": "$8.41"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1621330",
            "Clicks": "20232",
            "Conversions": "320",
            "Conversion Value": "$69,399.00",
            "Cost per Result": "$8.38"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1656215",
            "Clicks": "25414",
            "Conversions": "416",
            "Conversion Value": "$68,393.00",
            "Cost per Result": "$10.53"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "1292298",
            "Clicks": "30971",
            "Conversions": "321",
            "Conversion Value": "$47,844.00",
            "Cost per Result": "$8.04"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1814140",
            "Clicks": "24219",
            "Conversions": "310",
            "Conversion Value": "$43,194.00",
            "Cost per Result": "$8.56"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1777018",
            "Clicks": "22241",
            "Conversions": "367",
            "Conversion Value": "$71,433.00",
            "Cost per Result": "$9.28"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1783229",
            "Clicks": "28816",
            "Conversions": "242",
            "Conversion Value": "$30,190.00",
            "Cost per Result": "$10.60"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1210832",
            "Clicks": "28133",
            "Conversions": "373",
            "Conversion Value": "$58,245.00",
            "Cost per Result": "$8.70"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "2199408",
            "Clicks": "28212",
            "Conversions": "323",
            "Conversion Value": "$52,988.00",
            "Cost per Result": "$10.62"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1916858",
            "Clicks": "22017",
            "Conversions": "413",
            "Conversion Value": "$54,939.00",
            "Cost per Result": "$8.75"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1498965",
            "Clicks": "26437",
            "Conversions": "420",
            "Conversion Value": "$52,944.00",
            "Cost per Result": "$9.81"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1350047",
            "Clicks": "21295",
            "Conversions": "362",
            "Conversion Value": "$61,108.00",
            "Cost per Result": "$10.00"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1953044",
            "Clicks": "24865",
            "Conversions": "405",
            "Conversion Value": "$42,943.00",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1321313",
            "Clicks": "20557",
            "Conversions": "384",
            "Conversion Value": "$46,086.00",
            "Cost per Result": "$9.04"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1496997",
            "Clicks": "28086",
            "Conversions": "316",
            "Conversion Value": "$75,255.00",
            "Cost per Result": "$9.67"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1798016",
            "Clicks": "14625",
            "Conversions": "297",
            "Conversion Value": "$63,675.00",
            "Cost per Result": "$8.94"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "2410278",
            "Clicks": "21769",
            "Conversions": "344",
            "Conversion Value": "$33,211.00",
            "Cost per Result": "$9.52"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1356630",
            "Clicks": "28753",
            "Conversions": "327",
            "Conversion Value": "$40,680.00",
            "Cost per Result": "$9.21"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1663291",
            "Clicks": "19114",
            "Conversions": "380",
            "Conversion Value": "$45,854.00",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1394843",
            "Clicks": "27379",
            "Conversions": "361",
            "Conversion Value": "$67,642.00",
            "Cost per Result": "$10.85"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "1471015",
            "Clicks": "26062",
            "Conversions": "323",
            "Conversion Value": "$55,821.00",
            "Cost per Result": "$9.38"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1350998",
            "Clicks": "27880",
            "Conversions": "371",
            "Conversion Value": "$63,005.00",
            "Cost per Result": "$11.06"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "1828342",
            "Clicks": "21802",
            "Conversions": "367",
            "Conversion Value": "$37,575.00",
            "Cost per Result": "$11.41"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "1458657",
            "Clicks": "26699",
            "Conversions": "375",
            "Conversion Value": "$57,655.00",
            "Cost per Result": "$8.09"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1346272",
            "Clicks": "22743",
            "Conversions": "399",
            "Conversion Value": "$54,248.00",
            "Cost per Result": "$8.03"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1134085",
            "Clicks": "29908",
            "Conversions": "339",
            "Conversion Value": "$56,870.00",
            "Cost per Result": "$8.51"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "1354467",
            "Clicks": "28754",
            "Conversions": "415",
            "Conversion Value": "$43,083.00",
            "Cost per Result": "$8.47"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1778106",
            "Clicks": "24637",
            "Conversions": "380",
            "Conversion Value": "$52,774.00",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1524828",
            "Clicks": "19346",
            "Conversions": "370",
            "Conversion Value": "$66,195.00",
            "Cost per Result": "$7.99"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1816072",
            "Clicks": "18578",
            "Conversions": "448",
            "Conversion Value": "$37,751.00",
            "Cost per Result": "$9.69"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1440031",
            "Clicks": "27185",
            "Conversions": "434",
            "Conversion Value": "$58,967.00",
            "Cost per Result": "$9.58"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1817732",
            "Clicks": "24473",
            "Conversions": "362",
            "Conversion Value": "$66,137.00",
            "Cost per Result": "$11.02"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "1664418",
            "Clicks": "30681",
            "Conversions": "312",
            "Conversion Value": "$49,141.00",
            "Cost per Result": "$8.49"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1871236",
            "Clicks": "26157",
            "Conversions": "294",
            "Conversion Value": "$71,339.00",
            "Cost per Result": "$12.28"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1853418",
            "Clicks": "17950",
            "Conversions": "370",
            "Conversion Value": "$33,555.00",
            "Cost per Result": "$9.63"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1443360",
            "Clicks": "28868",
            "Conversions": "416",
            "Conversion Value": "$47,049.00",
            "Cost per Result": "$9.59"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1689723",
            "Clicks": "26292",
            "Conversions": "448",
            "Conversion Value": "$49,958.00",
            "Cost per Result": "$10.77"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1573760",
            "Clicks": "24131",
            "Conversions": "336",
            "Conversion Value": "$57,822.00",
            "Cost per Result": "$8.80"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1684425",
            "Clicks": "31401",
            "Conversions": "331",
            "Conversion Value": "$51,005.00",
            "Cost per Result": "$9.46"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "1629086",
            "Clicks": "21332",
            "Conversions": "434",
            "Conversion Value": "$45,533.00",
            "Cost per Result": "$11.81"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "1901153",
            "Clicks": "28112",
            "Conversions": "383",
            "Conversion Value": "$50,954.00",
            "Cost per Result": "$8.98"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "2309733",
            "Clicks": "24172",
            "Conversions": "351",
            "Conversion Value": "$57,332.00",
            "Cost per Result": "$11.91"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "2019245",
            "Clicks": "21272",
            "Conversions": "394",
            "Conversion Value": "$59,647.00",
            "Cost per Result": "$7.62"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "955748",
            "Clicks": "18992",
            "Conversions": "358",
            "Conversion Value": "$47,936.00",
            "Cost per Result": "$12.14"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "1763720",
            "Clicks": "24808",
            "Conversions": "300",
            "Conversion Value": "$61,080.00",
            "Cost per Result": "$10.20"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1259515",
            "Clicks": "27227",
            "Conversions": "356",
            "Conversion Value": "$55,570.00",
            "Cost per Result": "$8.02"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "1607547",
            "Clicks": "22187",
            "Conversions": "362",
            "Conversion Value": "$62,401.00",
            "Cost per Result": "$8.48"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "1452732",
            "Clicks": "21817",
            "Conversions": "290",
            "Conversion Value": "$75,652.00",
            "Cost per Result": "$7.14"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1254658",
            "Clicks": "26394",
            "Conversions": "324",
            "Conversion Value": "$44,688.00",
            "Cost per Result": "$9.69"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1408469",
            "Clicks": "21720",
            "Conversions": "318",
            "Conversion Value": "$66,349.00",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1796228",
            "Clicks": "29564",
            "Conversions": "382",
            "Conversion Value": "$67,108.00",
            "Cost per Result": "$8.73"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "1070666",
            "Clicks": "31021",
            "Conversions": "396",
            "Conversion Value": "$71,207.00",
            "Cost per Result": "$10.25"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "1601195",
            "Clicks": "22758",
            "Conversions": "327",
            "Conversion Value": "$57,274.00",
            "Cost per Result": "$9.53"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1601647",
            "Clicks": "34580",
            "Conversions": "311",
            "Conversion Value": "$63,082.00",
            "Cost per Result": "$9.79"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1054140",
            "Clicks": "25189",
            "Conversions": "359",
            "Conversion Value": "$71,029.00",
            "Cost per Result": "$9.37"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1802148",
            "Clicks": "26675",
            "Conversions": "382",
            "Conversion Value": "$53,030.00",
            "Cost per Result": "$10.25"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "2136698",
            "Clicks": "22942",
            "Conversions": "366",
            "Conversion Value": "$55,870.00",
            "Cost per Result": "$8.28"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1809443",
            "Clicks": "17977",
            "Conversions": "350",
            "Conversion Value": "$52,476.00",
            "Cost per Result": "$8.65"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1905788",
            "Clicks": "19180",
            "Conversions": "412",
            "Conversion Value": "$68,533.00",
            "Cost per Result": "$10.70"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "1990768",
            "Clicks": "22285",
            "Conversions": "325",
            "Conversion Value": "$42,057.00",
            "Cost per Result": "$11.93"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1443743",
            "Clicks": "23247",
            "Conversions": "358",
            "Conversion Value": "$63,463.00",
            "Cost per Result": "$10.54"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1316317",
            "Clicks": "28602",
            "Conversions": "370",
            "Conversion Value": "$68,721.00",
            "Cost per Result": "$10.44"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "1229133",
            "Clicks": "22504",
            "Conversions": "366",
            "Conversion Value": "$53,935.00",
            "Cost per Result": "$10.17"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1885798",
            "Clicks": "23440",
            "Conversions": "369",
            "Conversion Value": "$40,000.00",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "1136950",
            "Clicks": "25533",
            "Conversions": "314",
            "Conversion Value": "$41,670.00",
            "Cost per Result": "$10.28"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1583550",
            "Clicks": "26509",
            "Conversions": "367",
            "Conversion Value": "$39,205.00",
            "Cost per Result": "$9.61"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1653846",
            "Clicks": "21877",
            "Conversions": "423",
            "Conversion Value": "$68,861.00",
            "Cost per Result": "$11.92"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "1410566",
            "Clicks": "19571",
            "Conversions": "322",
            "Conversion Value": "$50,589.00",
            "Cost per Result": "$9.63"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1366221",
            "Clicks": "34461",
            "Conversions": "421",
            "Conversion Value": "$64,185.00",
            "Cost per Result": "$8.72"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1669615",
            "Clicks": "26688",
            "Conversions": "352",
            "Conversion Value": "$65,572.00",
            "Cost per Result": "$10.81"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "1621033",
            "Clicks": "21484",
            "Conversions": "417",
            "Conversion Value": "$59,103.00",
            "Cost per Result": "$10.41"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1617106",
            "Clicks": "23339",
            "Conversions": "360",
            "Conversion Value": "$59,365.00",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1992807",
            "Clicks": "29795",
            "Conversions": "339",
            "Conversion Value": "$43,030.00",
            "Cost per Result": "$8.22"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1918119",
            "Clicks": "21874",
            "Conversions": "312",
            "Conversion Value": "$54,177.00",
            "Cost per Result": "$9.63"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "2334096",
            "Clicks": "23630",
            "Conversions": "400",
            "Conversion Value": "$69,964.00",
            "Cost per Result": "$9.45"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "1597835",
            "Clicks": "24134",
            "Conversions": "391",
            "Conversion Value": "$60,750.00",
            "Cost per Result": "$8.41"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "2006018",
            "Clicks": "26462",
            "Conversions": "392",
            "Conversion Value": "$58,617.00",
            "Cost per Result": "$9.24"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "1343419",
            "Clicks": "29249",
            "Conversions": "383",
            "Conversion Value": "$58,496.00",
            "Cost per Result": "$8.16"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "1542669",
            "Clicks": "16268",
            "Conversions": "344",
            "Conversion Value": "$43,260.00",
            "Cost per Result": "$9.35"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1917093",
            "Clicks": "25860",
            "Conversions": "362",
            "Conversion Value": "$60,992.00",
            "Cost per Result": "$10.87"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "1507567",
            "Clicks": "26508",
            "Conversions": "459",
            "Conversion Value": "$52,623.00",
            "Cost per Result": "$12.48"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "2157799",
            "Clicks": "33289",
            "Conversions": "371",
            "Conversion Value": "$53,868.00",
            "Cost per Result": "$10.59"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1257599",
            "Clicks": "15128",
            "Conversions": "365",
            "Conversion Value": "$55,876.00",
            "Cost per Result": "$11.01"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "1511028",
            "Clicks": "18250",
            "Conversions": "380",
            "Conversion Value": "$50,324.00",
            "Cost per Result": "$10.69"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1693461",
            "Clicks": "22798",
            "Conversions": "356",
            "Conversion Value": "$62,386.00",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1591796",
            "Clicks": "19222",
            "Conversions": "381",
            "Conversion Value": "$47,060.00",
            "Cost per Result": "$8.04"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1494788",
            "Clicks": "17064",
            "Conversions": "297",
            "Conversion Value": "$42,521.00",
            "Cost per Result": "$9.09"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1483885",
            "Clicks": "25771",
            "Conversions": "382",
            "Conversion Value": "$53,240.00",
            "Cost per Result": "$9.54"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "2110067",
            "Clicks": "26890",
            "Conversions": "408",
            "Conversion Value": "$48,158.00",
            "Cost per Result": "$8.98"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1360610",
            "Clicks": "22281",
            "Conversions": "409",
            "Conversion Value": "$64,672.00",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1789240",
            "Clicks": "28231",
            "Conversions": "372",
            "Conversion Value": "$44,681.00",
            "Cost per Result": "$12.00"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "1901937",
            "Clicks": "19323",
            "Conversions": "369",
            "Conversion Value": "$46,103.00",
            "Cost per Result": "$10.19"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1251368",
            "Clicks": "27336",
            "Conversions": "438",
            "Conversion Value": "$60,804.00",
            "Cost per Result": "$11.94"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1624602",
            "Clicks": "22265",
            "Conversions": "301",
            "Conversion Value": "$54,015.00",
            "Cost per Result": "$11.28"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1907955",
            "Clicks": "19683",
            "Conversions": "356",
            "Conversion Value": "$47,168.00",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1753331",
            "Clicks": "27966",
            "Conversions": "349",
            "Conversion Value": "$54,513.00",
            "Cost per Result": "$10.73"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1698642",
            "Clicks": "28418",
            "Conversions": "431",
            "Conversion Value": "$44,867.00",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "1680820",
            "Clicks": "29825",
            "Conversions": "264",
            "Conversion Value": "$45,358.00",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "1521432",
            "Clicks": "26089",
            "Conversions": "364",
            "Conversion Value": "$51,610.00",
            "Cost per Result": "$10.77"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1702830",
            "Clicks": "24129",
            "Conversions": "397",
            "Conversion Value": "$62,795.00",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "1081681",
            "Clicks": "20154",
            "Conversions": "421",
            "Conversion Value": "$72,734.00",
            "Cost per Result": "$10.46"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1105063",
            "Clicks": "22284",
            "Conversions": "388",
            "Conversion Value": "$57,602.00",
            "Cost per Result": "$9.83"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1968218",
            "Clicks": "23355",
            "Conversions": "342",
            "Conversion Value": "$72,315.00",
            "Cost per Result": "$11.17"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1524950",
            "Clicks": "25886",
            "Conversions": "406",
            "Conversion Value": "$58,809.00",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "2362424",
            "Clicks": "23424",
            "Conversions": "345",
            "Conversion Value": "$61,643.00",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "1240382",
            "Clicks": "20841",
            "Conversions": "283",
            "Conversion Value": "$66,385.00",
            "Cost per Result": "$11.79"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1665635",
            "Clicks": "25253",
            "Conversions": "397",
            "Conversion Value": "$46,382.00",
            "Cost per Result": "$10.16"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1147239",
            "Clicks": "20282",
            "Conversions": "440",
            "Conversion Value": "$48,815.00",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "2307177",
            "Clicks": "24951",
            "Conversions": "351",
            "Conversion Value": "$68,704.00",
            "Cost per Result": "$8.09"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1798017",
            "Clicks": "20540",
            "Conversions": "337",
            "Conversion Value": "$62,367.00",
            "Cost per Result": "$13.13"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "1382041",
            "Clicks": "28291",
            "Conversions": "361",
            "Conversion Value": "$56,812.00",
            "Cost per Result": "$12.15"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1877390",
            "Clicks": "29908",
            "Conversions": "297",
            "Conversion Value": "$39,642.00",
            "Cost per Result": "$10.66"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1522949",
            "Clicks": "26008",
            "Conversions": "352",
            "Conversion Value": "$61,692.00",
            "Cost per Result": "$7.59"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1500660",
            "Clicks": "21561",
            "Conversions": "353",
            "Conversion Value": "$50,039.00",
            "Cost per Result": "$12.38"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "1892049",
            "Clicks": "27482",
            "Conversions": "388",
            "Conversion Value": "$70,332.00",
            "Cost per Result": "$10.05"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "1823677",
            "Clicks": "19473",
            "Conversions": "382",
            "Conversion Value": "$54,533.00",
            "Cost per Result": "$9.44"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1286198",
            "Clicks": "19772",
            "Conversions": "364",
            "Conversion Value": "$55,477.00",
            "Cost per Result": "$10.69"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1955538",
            "Clicks": "25000",
            "Conversions": "348",
            "Conversion Value": "$43,751.00",
            "Cost per Result": "$10.60"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1685939",
            "Clicks": "26051",
            "Conversions": "442",
            "Conversion Value": "$49,120.00",
            "Cost per Result": "$9.67"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1547673",
            "Clicks": "29458",
            "Conversions": "295",
            "Conversion Value": "$69,157.00",
            "Cost per Result": "$11.80"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "1789040",
            "Clicks": "27446",
            "Conversions": "387",
            "Conversion Value": "$47,160.00",
            "Cost per Result": "$9.50"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1496150",
            "Clicks": "25666",
            "Conversions": "307",
            "Conversion Value": "$59,897.00",
            "Cost per Result": "$8.97"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1598055",
            "Clicks": "27518",
            "Conversions": "347",
            "Conversion Value": "$65,358.00",
            "Cost per Result": "$10.58"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1611442",
            "Clicks": "29569",
            "Conversions": "356",
            "Conversion Value": "$57,094.00",
            "Cost per Result": "$10.49"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "2168421",
            "Clicks": "22503",
            "Conversions": "363",
            "Conversion Value": "$43,700.00",
            "Cost per Result": "$8.90"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1776685",
            "Clicks": "23123",
            "Conversions": "353",
            "Conversion Value": "$34,152.00",
            "Cost per Result": "$8.37"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1856288",
            "Clicks": "22188",
            "Conversions": "383",
            "Conversion Value": "$45,457.00",
            "Cost per Result": "$9.24"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1898401",
            "Clicks": "20230",
            "Conversions": "439",
            "Conversion Value": "$57,786.00",
            "Cost per Result": "$9.51"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1967892",
            "Clicks": "20717",
            "Conversions": "311",
            "Conversion Value": "$47,626.00",
            "Cost per Result": "$9.94"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "1441917",
            "Clicks": "21668",
            "Conversions": "417",
            "Conversion Value": "$74,547.00",
            "Cost per Result": "$9.04"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1135778",
            "Clicks": "20607",
            "Conversions": "377",
            "Conversion Value": "$63,252.00",
            "Cost per Result": "$9.31"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "1397782",
            "Clicks": "24116",
            "Conversions": "307",
            "Conversion Value": "$56,396.00",
            "Cost per Result": "$11.07"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "1909547",
            "Clicks": "18971",
            "Conversions": "359",
            "Conversion Value": "$39,838.00",
            "Cost per Result": "$8.91"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "1828071",
            "Clicks": "23821",
            "Conversions": "381",
            "Conversion Value": "$52,698.00",
            "Cost per Result": "$7.70"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "2124805",
            "Clicks": "23193",
            "Conversions": "329",
            "Conversion Value": "$56,047.00",
            "Cost per Result": "$9.05"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1347152",
            "Clicks": "27271",
            "Conversions": "410",
            "Conversion Value": "$58,345.00",
            "Cost per Result": "$11.04"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "2025413",
            "Clicks": "23293",
            "Conversions": "317",
            "Conversion Value": "$58,868.00",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1176479",
            "Clicks": "30462",
            "Conversions": "293",
            "Conversion Value": "$33,092.00",
            "Cost per Result": "$9.82"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1679337",
            "Clicks": "16991",
            "Conversions": "312",
            "Conversion Value": "$38,390.00",
            "Cost per Result": "$9.98"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "2025600",
            "Clicks": "23365",
            "Conversions": "364",
            "Conversion Value": "$47,362.00",
            "Cost per Result": "$10.62"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1912975",
            "Clicks": "18054",
            "Conversions": "421",
            "Conversion Value": "$46,016.00",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1861975",
            "Clicks": "26720",
            "Conversions": "297",
            "Conversion Value": "$59,457.00",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1655129",
            "Clicks": "26224",
            "Conversions": "402",
            "Conversion Value": "$67,689.00",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1682313",
            "Clicks": "18288",
            "Conversions": "337",
            "Conversion Value": "$36,852.00",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1138698",
            "Clicks": "29784",
            "Conversions": "372",
            "Conversion Value": "$52,830.00",
            "Cost per Result": "$10.17"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "2051835",
            "Clicks": "23496",
            "Conversions": "369",
            "Conversion Value": "$44,387.00",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1162141",
            "Clicks": "30152",
            "Conversions": "310",
            "Conversion Value": "$45,411.00",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1870926",
            "Clicks": "24327",
            "Conversions": "401",
            "Conversion Value": "$60,048.00",
            "Cost per Result": "$11.80"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1416366",
            "Clicks": "19681",
            "Conversions": "349",
            "Conversion Value": "$49,960.00",
            "Cost per Result": "$9.78"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1618716",
            "Clicks": "30482",
            "Conversions": "394",
            "Conversion Value": "$65,308.00",
            "Cost per Result": "$10.99"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "2286020",
            "Clicks": "23680",
            "Conversions": "381",
            "Conversion Value": "$67,505.00",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "2039512",
            "Clicks": "25016",
            "Conversions": "334",
            "Conversion Value": "$38,988.00",
            "Cost per Result": "$11.56"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1733681",
            "Clicks": "26625",
            "Conversions": "337",
            "Conversion Value": "$52,207.00",
            "Cost per Result": "$10.65"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1832367",
            "Clicks": "23530",
            "Conversions": "310",
            "Conversion Value": "$68,465.00",
            "Cost per Result": "$8.55"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "2064824",
            "Clicks": "27062",
            "Conversions": "347",
            "Conversion Value": "$49,846.00",
            "Cost per Result": "$12.61"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "1999402",
            "Clicks": "25980",
            "Conversions": "301",
            "Conversion Value": "$55,757.00",
            "Cost per Result": "$10.64"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "1491089",
            "Clicks": "27098",
            "Conversions": "377",
            "Conversion Value": "$69,699.00",
            "Cost per Result": "$10.30"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1950031",
            "Clicks": "24336",
            "Conversions": "345",
            "Conversion Value": "$59,214.00",
            "Cost per Result": "$10.26"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1296574",
            "Clicks": "20077",
            "Conversions": "302",
            "Conversion Value": "$63,113.00",
            "Cost per Result": "$11.07"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1462926",
            "Clicks": "24896",
            "Conversions": "340",
            "Conversion Value": "$71,486.00",
            "Cost per Result": "$9.45"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "1550862",
            "Clicks": "28412",
            "Conversions": "356",
            "Conversion Value": "$57,554.00",
            "Cost per Result": "$10.98"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "2114352",
            "Clicks": "23389",
            "Conversions": "350",
            "Conversion Value": "$33,195.00",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1784158",
            "Clicks": "24188",
            "Conversions": "394",
            "Conversion Value": "$64,268.00",
            "Cost per Result": "$12.15"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1600960",
            "Clicks": "27184",
            "Conversions": "279",
            "Conversion Value": "$59,708.00",
            "Cost per Result": "$9.60"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1672509",
            "Clicks": "20290",
            "Conversions": "329",
            "Conversion Value": "$46,177.00",
            "Cost per Result": "$10.82"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1750130",
            "Clicks": "25560",
            "Conversions": "327",
            "Conversion Value": "$55,086.00",
            "Cost per Result": "$8.72"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1899011",
            "Clicks": "30239",
            "Conversions": "435",
            "Conversion Value": "$63,013.00",
            "Cost per Result": "$9.51"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1725655",
            "Clicks": "20967",
            "Conversions": "419",
            "Conversion Value": "$54,362.00",
            "Cost per Result": "$8.96"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1532158",
            "Clicks": "16997",
            "Conversions": "349",
            "Conversion Value": "$57,318.00",
            "Cost per Result": "$9.69"
          }
        ]
      },
      "TokenTradersGamingData": {
        "name": "Token Traders (Gaming)",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "327569",
            "Clicks": "3793",
            "Conversions": "117",
            "Conversion Value": "$20,741",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "508030",
            "Clicks": "4347",
            "Conversions": "97",
            "Conversion Value": "$11,015",
            "Cost per Result": "$14.61"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "498123",
            "Clicks": "5980",
            "Conversions": "86",
            "Conversion Value": "$11,336",
            "Cost per Result": "$9.78"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "522991",
            "Clicks": "4273",
            "Conversions": "128",
            "Conversion Value": "$14,992",
            "Cost per Result": "$6.50"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "436893",
            "Clicks": "6616",
            "Conversions": "113",
            "Conversion Value": "$16,668",
            "Cost per Result": "$6.22"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "154648",
            "Clicks": "7994",
            "Conversions": "69",
            "Conversion Value": "$20,713",
            "Cost per Result": "$5.96"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "532599",
            "Clicks": "8734",
            "Conversions": "70",
            "Conversion Value": "$11,835",
            "Cost per Result": "$6.10"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "603226",
            "Clicks": "7715",
            "Conversions": "110",
            "Conversion Value": "$9,535",
            "Cost per Result": "$8.56"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "350213",
            "Clicks": "7508",
            "Conversions": "91",
            "Conversion Value": "$7,651",
            "Cost per Result": "$12.17"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "604946",
            "Clicks": "4812",
            "Conversions": "74",
            "Conversion Value": "$19,255",
            "Cost per Result": "$11.71"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "665187",
            "Clicks": "3529",
            "Conversions": "110",
            "Conversion Value": "$16,105",
            "Cost per Result": "$6.48"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "694684",
            "Clicks": "3897",
            "Conversions": "76",
            "Conversion Value": "$13,442",
            "Cost per Result": "$14.85"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "377338",
            "Clicks": "3210",
            "Conversions": "121",
            "Conversion Value": "$10,024",
            "Cost per Result": "$6.36"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "642723",
            "Clicks": "8032",
            "Conversions": "126",
            "Conversion Value": "$12,896",
            "Cost per Result": "$10.01"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "504379",
            "Clicks": "4001",
            "Conversions": "66",
            "Conversion Value": "$11,059",
            "Cost per Result": "$13.33"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "716664",
            "Clicks": "3156",
            "Conversions": "84",
            "Conversion Value": "$10,226",
            "Cost per Result": "$8.16"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "270799",
            "Clicks": "3568",
            "Conversions": "98",
            "Conversion Value": "$7,250",
            "Cost per Result": "$10.84"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "350957",
            "Clicks": "8985",
            "Conversions": "94",
            "Conversion Value": "$17,502",
            "Cost per Result": "$10.75"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "228624",
            "Clicks": "3210",
            "Conversions": "120",
            "Conversion Value": "$18,508",
            "Cost per Result": "$6.45"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "538551",
            "Clicks": "3890",
            "Conversions": "109",
            "Conversion Value": "$11,884",
            "Cost per Result": "$5.85"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "656605",
            "Clicks": "3992",
            "Conversions": "66",
            "Conversion Value": "$18,964",
            "Cost per Result": "$9.10"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "730753",
            "Clicks": "5015",
            "Conversions": "81",
            "Conversion Value": "$18,918",
            "Cost per Result": "$5.52"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "264752",
            "Clicks": "3412",
            "Conversions": "71",
            "Conversion Value": "$21,960",
            "Cost per Result": "$9.01"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "653249",
            "Clicks": "7346",
            "Conversions": "124",
            "Conversion Value": "$22,315",
            "Cost per Result": "$8.08"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "709693",
            "Clicks": "8748",
            "Conversions": "118",
            "Conversion Value": "$23,399",
            "Cost per Result": "$9.74"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "451018",
            "Clicks": "7289",
            "Conversions": "100",
            "Conversion Value": "$10,176",
            "Cost per Result": "$11.55"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "727946",
            "Clicks": "3992",
            "Conversions": "119",
            "Conversion Value": "$23,192",
            "Cost per Result": "$13.38"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "367006",
            "Clicks": "7515",
            "Conversions": "121",
            "Conversion Value": "$15,912",
            "Cost per Result": "$5.29"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "390536",
            "Clicks": "8875",
            "Conversions": "78",
            "Conversion Value": "$10,949",
            "Cost per Result": "$7.69"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "465369",
            "Clicks": "4928",
            "Conversions": "101",
            "Conversion Value": "$13,222",
            "Cost per Result": "$7.84"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "641956",
            "Clicks": "7626",
            "Conversions": "97",
            "Conversion Value": "$13,989",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "727975",
            "Clicks": "5801",
            "Conversions": "64",
            "Conversion Value": "$22,337",
            "Cost per Result": "$11.99"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "224782",
            "Clicks": "4884",
            "Conversions": "96",
            "Conversion Value": "$14,959",
            "Cost per Result": "$6.79"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "202909",
            "Clicks": "5585",
            "Conversions": "118",
            "Conversion Value": "$22,661",
            "Cost per Result": "$11.51"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "720553",
            "Clicks": "6459",
            "Conversions": "116",
            "Conversion Value": "$10,192",
            "Cost per Result": "$5.34"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "157862",
            "Clicks": "7921",
            "Conversions": "109",
            "Conversion Value": "$9,878",
            "Cost per Result": "$9.20"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "216972",
            "Clicks": "5088",
            "Conversions": "117",
            "Conversion Value": "$9,525",
            "Cost per Result": "$12.20"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "303374",
            "Clicks": "6039",
            "Conversions": "125",
            "Conversion Value": "$12,559",
            "Cost per Result": "$6.56"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "697268",
            "Clicks": "3384",
            "Conversions": "86",
            "Conversion Value": "$7,299",
            "Cost per Result": "$10.71"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "486768",
            "Clicks": "7289",
            "Conversions": "74",
            "Conversion Value": "$16,031",
            "Cost per Result": "$14.35"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "242342",
            "Clicks": "7634",
            "Conversions": "124",
            "Conversion Value": "$11,082",
            "Cost per Result": "$8.69"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "624768",
            "Clicks": "5929",
            "Conversions": "116",
            "Conversion Value": "$20,751",
            "Cost per Result": "$7.60"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "240299",
            "Clicks": "6070",
            "Conversions": "123",
            "Conversion Value": "$21,268",
            "Cost per Result": "$10.30"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "420773",
            "Clicks": "6825",
            "Conversions": "86",
            "Conversion Value": "$22,995",
            "Cost per Result": "$13.43"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "157364",
            "Clicks": "5939",
            "Conversions": "123",
            "Conversion Value": "$22,878",
            "Cost per Result": "$11.73"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "502979",
            "Clicks": "3141",
            "Conversions": "108",
            "Conversion Value": "$12,265",
            "Cost per Result": "$6.02"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "182972",
            "Clicks": "5555",
            "Conversions": "120",
            "Conversion Value": "$14,862",
            "Cost per Result": "$11.78"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "195139",
            "Clicks": "5764",
            "Conversions": "69",
            "Conversion Value": "$17,602",
            "Cost per Result": "$7.05"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "310135",
            "Clicks": "7250",
            "Conversions": "76",
            "Conversion Value": "$13,012",
            "Cost per Result": "$10.93"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "187969",
            "Clicks": "8726",
            "Conversions": "99",
            "Conversion Value": "$20,524",
            "Cost per Result": "$6.84"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "737493",
            "Clicks": "6713",
            "Conversions": "102",
            "Conversion Value": "$8,942",
            "Cost per Result": "$14.46"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "682897",
            "Clicks": "5625",
            "Conversions": "123",
            "Conversion Value": "$17,906",
            "Cost per Result": "$8.98"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "715873",
            "Clicks": "6582",
            "Conversions": "112",
            "Conversion Value": "$9,696",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "420017",
            "Clicks": "6500",
            "Conversions": "79",
            "Conversion Value": "$19,775",
            "Cost per Result": "$10.12"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "740754",
            "Clicks": "4865",
            "Conversions": "66",
            "Conversion Value": "$23,392",
            "Cost per Result": "$7.42"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "739419",
            "Clicks": "8005",
            "Conversions": "123",
            "Conversion Value": "$22,083",
            "Cost per Result": "$12.86"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "690746",
            "Clicks": "5225",
            "Conversions": "121",
            "Conversion Value": "$9,605",
            "Cost per Result": "$13.91"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "642094",
            "Clicks": "3771",
            "Conversions": "87",
            "Conversion Value": "$18,852",
            "Cost per Result": "$12.41"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "605783",
            "Clicks": "7236",
            "Conversions": "72",
            "Conversion Value": "$6,068",
            "Cost per Result": "$9.96"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "668006",
            "Clicks": "4323",
            "Conversions": "114",
            "Conversion Value": "$11,626",
            "Cost per Result": "$10.68"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "608916",
            "Clicks": "6349",
            "Conversions": "123",
            "Conversion Value": "$22,756",
            "Cost per Result": "$11.91"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "570837",
            "Clicks": "4725",
            "Conversions": "125",
            "Conversion Value": "$6,819",
            "Cost per Result": "$9.57"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "320959",
            "Clicks": "5769",
            "Conversions": "130",
            "Conversion Value": "$6,107",
            "Cost per Result": "$13.68"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "189794",
            "Clicks": "4030",
            "Conversions": "100",
            "Conversion Value": "$13,918",
            "Cost per Result": "$14.24"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "663100",
            "Clicks": "6087",
            "Conversions": "94",
            "Conversion Value": "$6,213",
            "Cost per Result": "$8.02"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "368683",
            "Clicks": "3168",
            "Conversions": "129",
            "Conversion Value": "$9,292",
            "Cost per Result": "$14.81"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "351431",
            "Clicks": "5122",
            "Conversions": "114",
            "Conversion Value": "$8,173",
            "Cost per Result": "$10.07"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "393264",
            "Clicks": "3106",
            "Conversions": "101",
            "Conversion Value": "$15,153",
            "Cost per Result": "$9.11"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "268160",
            "Clicks": "8706",
            "Conversions": "108",
            "Conversion Value": "$9,134",
            "Cost per Result": "$7.04"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "273607",
            "Clicks": "6354",
            "Conversions": "91",
            "Conversion Value": "$20,316",
            "Cost per Result": "$12.33"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "653238",
            "Clicks": "4358",
            "Conversions": "119",
            "Conversion Value": "$18,873",
            "Cost per Result": "$10.20"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "424379",
            "Clicks": "4953",
            "Conversions": "97",
            "Conversion Value": "$23,342",
            "Cost per Result": "$9.16"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "250145",
            "Clicks": "5145",
            "Conversions": "118",
            "Conversion Value": "$7,088",
            "Cost per Result": "$13.18"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "250518",
            "Clicks": "8604",
            "Conversions": "113",
            "Conversion Value": "$10,576",
            "Cost per Result": "$9.79"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "410420",
            "Clicks": "3005",
            "Conversions": "109",
            "Conversion Value": "$9,401",
            "Cost per Result": "$12.95"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "357883",
            "Clicks": "8985",
            "Conversions": "72",
            "Conversion Value": "$12,647",
            "Cost per Result": "$8.58"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "609884",
            "Clicks": "7374",
            "Conversions": "66",
            "Conversion Value": "$10,485",
            "Cost per Result": "$6.99"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "629743",
            "Clicks": "8335",
            "Conversions": "63",
            "Conversion Value": "$12,386",
            "Cost per Result": "$10.27"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "348985",
            "Clicks": "3769",
            "Conversions": "107",
            "Conversion Value": "$21,756",
            "Cost per Result": "$7.02"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "163511",
            "Clicks": "4351",
            "Conversions": "75",
            "Conversion Value": "$12,675",
            "Cost per Result": "$7.39"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "640663",
            "Clicks": "8507",
            "Conversions": "69",
            "Conversion Value": "$21,987",
            "Cost per Result": "$10.31"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "393126",
            "Clicks": "4998",
            "Conversions": "64",
            "Conversion Value": "$21,427",
            "Cost per Result": "$7.71"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "568865",
            "Clicks": "5187",
            "Conversions": "111",
            "Conversion Value": "$7,324",
            "Cost per Result": "$7.41"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "747876",
            "Clicks": "3314",
            "Conversions": "87",
            "Conversion Value": "$7,494",
            "Cost per Result": "$5.93"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "513719",
            "Clicks": "4691",
            "Conversions": "115",
            "Conversion Value": "$17,258",
            "Cost per Result": "$5.38"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "557174",
            "Clicks": "5196",
            "Conversions": "109",
            "Conversion Value": "$16,527",
            "Cost per Result": "$7.90"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "366092",
            "Clicks": "3992",
            "Conversions": "65",
            "Conversion Value": "$8,541",
            "Cost per Result": "$10.91"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "713521",
            "Clicks": "8800",
            "Conversions": "100",
            "Conversion Value": "$9,135",
            "Cost per Result": "$13.72"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "523169",
            "Clicks": "3161",
            "Conversions": "95",
            "Conversion Value": "$10,127",
            "Cost per Result": "$5.67"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "385241",
            "Clicks": "7997",
            "Conversions": "72",
            "Conversion Value": "$17,925",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "492205",
            "Clicks": "4857",
            "Conversions": "70",
            "Conversion Value": "$12,420",
            "Cost per Result": "$6.85"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "531137",
            "Clicks": "7609",
            "Conversions": "125",
            "Conversion Value": "$23,133",
            "Cost per Result": "$7.83"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "203719",
            "Clicks": "7746",
            "Conversions": "61",
            "Conversion Value": "$13,308",
            "Cost per Result": "$10.23"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "737180",
            "Clicks": "4121",
            "Conversions": "87",
            "Conversion Value": "$23,363",
            "Cost per Result": "$11.06"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "716447",
            "Clicks": "6534",
            "Conversions": "126",
            "Conversion Value": "$23,246",
            "Cost per Result": "$8.76"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "469173",
            "Clicks": "8666",
            "Conversions": "110",
            "Conversion Value": "$23,253",
            "Cost per Result": "$6.83"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "699895",
            "Clicks": "6435",
            "Conversions": "88",
            "Conversion Value": "$21,175",
            "Cost per Result": "$10.30"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "334937",
            "Clicks": "8309",
            "Conversions": "101",
            "Conversion Value": "$6,163",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "418642",
            "Clicks": "6174",
            "Conversions": "92",
            "Conversion Value": "$12,499",
            "Cost per Result": "$9.46"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "467058",
            "Clicks": "8479",
            "Conversions": "119",
            "Conversion Value": "$22,972",
            "Cost per Result": "$6.55"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "474729",
            "Clicks": "3348",
            "Conversions": "114",
            "Conversion Value": "$13,795",
            "Cost per Result": "$6.83"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "486498",
            "Clicks": "6674",
            "Conversions": "105",
            "Conversion Value": "$11,993",
            "Cost per Result": "$6.56"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "446673",
            "Clicks": "3845",
            "Conversions": "70",
            "Conversion Value": "$7,153",
            "Cost per Result": "$7.61"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "574014",
            "Clicks": "7796",
            "Conversions": "84",
            "Conversion Value": "$16,512",
            "Cost per Result": "$5.23"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "698784",
            "Clicks": "7626",
            "Conversions": "102",
            "Conversion Value": "$23,401",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "668151",
            "Clicks": "5390",
            "Conversions": "116",
            "Conversion Value": "$21,397",
            "Cost per Result": "$5.63"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "622291",
            "Clicks": "6902",
            "Conversions": "106",
            "Conversion Value": "$8,879",
            "Cost per Result": "$6.09"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "404903",
            "Clicks": "8090",
            "Conversions": "84",
            "Conversion Value": "$6,267",
            "Cost per Result": "$6.94"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "457018",
            "Clicks": "4409",
            "Conversions": "67",
            "Conversion Value": "$11,779",
            "Cost per Result": "$9.96"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "316444",
            "Clicks": "3426",
            "Conversions": "61",
            "Conversion Value": "$21,394",
            "Cost per Result": "$5.66"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "472561",
            "Clicks": "8962",
            "Conversions": "83",
            "Conversion Value": "$18,447",
            "Cost per Result": "$13.59"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "487826",
            "Clicks": "4199",
            "Conversions": "67",
            "Conversion Value": "$6,303",
            "Cost per Result": "$13.79"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "651510",
            "Clicks": "6119",
            "Conversions": "119",
            "Conversion Value": "$11,703",
            "Cost per Result": "$6.14"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "626315",
            "Clicks": "6442",
            "Conversions": "109",
            "Conversion Value": "$23,437",
            "Cost per Result": "$11.38"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "201514",
            "Clicks": "6780",
            "Conversions": "78",
            "Conversion Value": "$11,583",
            "Cost per Result": "$8.95"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "595152",
            "Clicks": "5978",
            "Conversions": "80",
            "Conversion Value": "$19,308",
            "Cost per Result": "$9.24"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "362158",
            "Clicks": "3744",
            "Conversions": "115",
            "Conversion Value": "$23,443",
            "Cost per Result": "$14.54"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "290733",
            "Clicks": "5215",
            "Conversions": "74",
            "Conversion Value": "$6,847",
            "Cost per Result": "$7.24"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "597676",
            "Clicks": "8266",
            "Conversions": "71",
            "Conversion Value": "$20,931",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "160421",
            "Clicks": "7354",
            "Conversions": "117",
            "Conversion Value": "$22,798",
            "Cost per Result": "$7.46"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "255457",
            "Clicks": "3788",
            "Conversions": "70",
            "Conversion Value": "$20,631",
            "Cost per Result": "$9.67"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "349815",
            "Clicks": "6242",
            "Conversions": "82",
            "Conversion Value": "$20,494",
            "Cost per Result": "$10.09"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "697908",
            "Clicks": "6294",
            "Conversions": "66",
            "Conversion Value": "$11,364",
            "Cost per Result": "$7.31"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "688050",
            "Clicks": "7628",
            "Conversions": "78",
            "Conversion Value": "$23,749",
            "Cost per Result": "$5.78"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "203107",
            "Clicks": "8897",
            "Conversions": "104",
            "Conversion Value": "$12,343",
            "Cost per Result": "$6.36"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "643754",
            "Clicks": "4285",
            "Conversions": "81",
            "Conversion Value": "$22,892",
            "Cost per Result": "$8.67"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "196586",
            "Clicks": "3818",
            "Conversions": "123",
            "Conversion Value": "$8,919",
            "Cost per Result": "$12.82"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "460996",
            "Clicks": "5251",
            "Conversions": "85",
            "Conversion Value": "$14,835",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "587644",
            "Clicks": "8994",
            "Conversions": "110",
            "Conversion Value": "$14,089",
            "Cost per Result": "$14.60"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "183996",
            "Clicks": "6784",
            "Conversions": "83",
            "Conversion Value": "$20,217",
            "Cost per Result": "$5.10"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "401410",
            "Clicks": "4045",
            "Conversions": "130",
            "Conversion Value": "$22,836",
            "Cost per Result": "$8.52"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "443565",
            "Clicks": "6633",
            "Conversions": "87",
            "Conversion Value": "$18,462",
            "Cost per Result": "$11.17"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "631464",
            "Clicks": "3010",
            "Conversions": "62",
            "Conversion Value": "$17,613",
            "Cost per Result": "$7.04"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "470990",
            "Clicks": "6054",
            "Conversions": "71",
            "Conversion Value": "$18,746",
            "Cost per Result": "$5.18"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "193218",
            "Clicks": "7182",
            "Conversions": "125",
            "Conversion Value": "$15,812",
            "Cost per Result": "$10.58"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "262244",
            "Clicks": "5790",
            "Conversions": "102",
            "Conversion Value": "$22,442",
            "Cost per Result": "$14.53"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "288551",
            "Clicks": "8970",
            "Conversions": "94",
            "Conversion Value": "$23,892",
            "Cost per Result": "$12.83"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "594434",
            "Clicks": "5222",
            "Conversions": "116",
            "Conversion Value": "$23,140",
            "Cost per Result": "$12.15"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "746994",
            "Clicks": "8385",
            "Conversions": "70",
            "Conversion Value": "$9,497",
            "Cost per Result": "$5.49"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "326974",
            "Clicks": "8860",
            "Conversions": "71",
            "Conversion Value": "$23,742",
            "Cost per Result": "$8.75"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "184869",
            "Clicks": "8312",
            "Conversions": "117",
            "Conversion Value": "$23,520",
            "Cost per Result": "$9.60"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "653319",
            "Clicks": "3731",
            "Conversions": "130",
            "Conversion Value": "$12,711",
            "Cost per Result": "$10.87"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "514762",
            "Clicks": "4162",
            "Conversions": "117",
            "Conversion Value": "$8,396",
            "Cost per Result": "$5.20"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "728479",
            "Clicks": "8650",
            "Conversions": "100",
            "Conversion Value": "$20,069",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "583845",
            "Clicks": "6918",
            "Conversions": "75",
            "Conversion Value": "$13,803",
            "Cost per Result": "$6.42"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "228188",
            "Clicks": "6117",
            "Conversions": "128",
            "Conversion Value": "$8,266",
            "Cost per Result": "$8.06"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "158426",
            "Clicks": "8497",
            "Conversions": "110",
            "Conversion Value": "$9,958",
            "Cost per Result": "$13.99"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "314308",
            "Clicks": "6430",
            "Conversions": "112",
            "Conversion Value": "$9,224",
            "Cost per Result": "$8.33"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "187523",
            "Clicks": "4264",
            "Conversions": "61",
            "Conversion Value": "$12,297",
            "Cost per Result": "$14.01"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "526898",
            "Clicks": "3563",
            "Conversions": "101",
            "Conversion Value": "$12,502",
            "Cost per Result": "$6.84"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "551636",
            "Clicks": "7406",
            "Conversions": "117",
            "Conversion Value": "$15,421",
            "Cost per Result": "$14.33"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "506948",
            "Clicks": "5246",
            "Conversions": "124",
            "Conversion Value": "$9,151",
            "Cost per Result": "$9.63"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "161922",
            "Clicks": "5607",
            "Conversions": "82",
            "Conversion Value": "$12,273",
            "Cost per Result": "$14.67"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "607763",
            "Clicks": "7466",
            "Conversions": "60",
            "Conversion Value": "$9,021",
            "Cost per Result": "$13.11"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "644547",
            "Clicks": "7614",
            "Conversions": "123",
            "Conversion Value": "$15,122",
            "Cost per Result": "$5.74"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "181862",
            "Clicks": "8928",
            "Conversions": "125",
            "Conversion Value": "$13,366",
            "Cost per Result": "$12.65"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "496775",
            "Clicks": "5937",
            "Conversions": "110",
            "Conversion Value": "$16,678",
            "Cost per Result": "$14.19"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "160377",
            "Clicks": "5904",
            "Conversions": "111",
            "Conversion Value": "$6,999",
            "Cost per Result": "$15.00"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "703958",
            "Clicks": "3273",
            "Conversions": "63",
            "Conversion Value": "$15,485",
            "Cost per Result": "$14.69"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "471593",
            "Clicks": "3895",
            "Conversions": "73",
            "Conversion Value": "$7,098",
            "Cost per Result": "$6.21"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "675295",
            "Clicks": "6779",
            "Conversions": "129",
            "Conversion Value": "$17,481",
            "Cost per Result": "$9.44"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "294481",
            "Clicks": "7523",
            "Conversions": "77",
            "Conversion Value": "$22,301",
            "Cost per Result": "$6.12"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "403542",
            "Clicks": "6539",
            "Conversions": "112",
            "Conversion Value": "$14,646",
            "Cost per Result": "$5.89"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "503992",
            "Clicks": "6676",
            "Conversions": "80",
            "Conversion Value": "$22,847",
            "Cost per Result": "$12.69"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "638774",
            "Clicks": "3650",
            "Conversions": "102",
            "Conversion Value": "$11,015",
            "Cost per Result": "$10.54"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "451125",
            "Clicks": "4895",
            "Conversions": "124",
            "Conversion Value": "$17,394",
            "Cost per Result": "$7.69"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "244993",
            "Clicks": "8930",
            "Conversions": "118",
            "Conversion Value": "$13,760",
            "Cost per Result": "$5.74"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "218446",
            "Clicks": "6801",
            "Conversions": "107",
            "Conversion Value": "$16,314",
            "Cost per Result": "$12.36"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "583745",
            "Clicks": "7170",
            "Conversions": "82",
            "Conversion Value": "$10,896",
            "Cost per Result": "$14.15"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "508007",
            "Clicks": "8884",
            "Conversions": "127",
            "Conversion Value": "$19,829",
            "Cost per Result": "$14.72"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "440693",
            "Clicks": "7152",
            "Conversions": "70",
            "Conversion Value": "$23,458",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "733423",
            "Clicks": "6466",
            "Conversions": "95",
            "Conversion Value": "$12,722",
            "Cost per Result": "$14.36"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "626133",
            "Clicks": "6454",
            "Conversions": "72",
            "Conversion Value": "$6,428",
            "Cost per Result": "$14.70"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "602215",
            "Clicks": "4967",
            "Conversions": "73",
            "Conversion Value": "$20,202",
            "Cost per Result": "$6.83"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "682058",
            "Clicks": "7068",
            "Conversions": "96",
            "Conversion Value": "$21,611",
            "Cost per Result": "$9.94"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "461903",
            "Clicks": "4980",
            "Conversions": "108",
            "Conversion Value": "$7,333",
            "Cost per Result": "$13.87"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "207238",
            "Clicks": "3459",
            "Conversions": "91",
            "Conversion Value": "$23,991",
            "Cost per Result": "$14.01"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "538722",
            "Clicks": "3845",
            "Conversions": "60",
            "Conversion Value": "$11,574",
            "Cost per Result": "$14.56"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "555044",
            "Clicks": "5091",
            "Conversions": "130",
            "Conversion Value": "$11,604",
            "Cost per Result": "$14.10"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "488286",
            "Clicks": "5584",
            "Conversions": "117",
            "Conversion Value": "$12,714",
            "Cost per Result": "$11.57"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "700581",
            "Clicks": "7489",
            "Conversions": "104",
            "Conversion Value": "$17,880",
            "Cost per Result": "$5.46"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "491875",
            "Clicks": "5922",
            "Conversions": "122",
            "Conversion Value": "$9,124",
            "Cost per Result": "$7.84"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "545326",
            "Clicks": "7211",
            "Conversions": "91",
            "Conversion Value": "$17,636",
            "Cost per Result": "$14.33"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "431067",
            "Clicks": "4751",
            "Conversions": "92",
            "Conversion Value": "$8,712",
            "Cost per Result": "$9.34"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "206559",
            "Clicks": "7933",
            "Conversions": "122",
            "Conversion Value": "$9,277",
            "Cost per Result": "$8.11"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "439681",
            "Clicks": "4520",
            "Conversions": "128",
            "Conversion Value": "$7,426",
            "Cost per Result": "$5.02"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "400479",
            "Clicks": "8276",
            "Conversions": "81",
            "Conversion Value": "$7,242",
            "Cost per Result": "$13.73"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "720089",
            "Clicks": "5533",
            "Conversions": "126",
            "Conversion Value": "$15,455",
            "Cost per Result": "$13.22"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "593570",
            "Clicks": "8784",
            "Conversions": "110",
            "Conversion Value": "$7,597",
            "Cost per Result": "$7.71"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "597131",
            "Clicks": "5828",
            "Conversions": "95",
            "Conversion Value": "$17,234",
            "Cost per Result": "$6.64"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "685046",
            "Clicks": "4005",
            "Conversions": "75",
            "Conversion Value": "$9,173",
            "Cost per Result": "$11.00"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "515416",
            "Clicks": "4889",
            "Conversions": "88",
            "Conversion Value": "$22,728",
            "Cost per Result": "$12.76"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "459636",
            "Clicks": "6180",
            "Conversions": "99",
            "Conversion Value": "$9,345",
            "Cost per Result": "$6.14"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "434862",
            "Clicks": "8702",
            "Conversions": "69",
            "Conversion Value": "$15,876",
            "Cost per Result": "$10.36"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "476675",
            "Clicks": "5460",
            "Conversions": "125",
            "Conversion Value": "$17,050",
            "Cost per Result": "$10.57"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "386864",
            "Clicks": "6285",
            "Conversions": "71",
            "Conversion Value": "$15,058",
            "Cost per Result": "$14.24"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "247813",
            "Clicks": "4555",
            "Conversions": "93",
            "Conversion Value": "$21,755",
            "Cost per Result": "$8.23"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "683348",
            "Clicks": "6481",
            "Conversions": "130",
            "Conversion Value": "$15,454",
            "Cost per Result": "$10.55"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "307585",
            "Clicks": "6452",
            "Conversions": "113",
            "Conversion Value": "$11,810",
            "Cost per Result": "$5.79"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "158973",
            "Clicks": "7080",
            "Conversions": "120",
            "Conversion Value": "$12,525",
            "Cost per Result": "$13.50"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "451527",
            "Clicks": "5624",
            "Conversions": "88",
            "Conversion Value": "$6,041",
            "Cost per Result": "$14.96"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "430436",
            "Clicks": "3973",
            "Conversions": "128",
            "Conversion Value": "$6,922",
            "Cost per Result": "$8.37"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "455378",
            "Clicks": "8492",
            "Conversions": "63",
            "Conversion Value": "$6,841",
            "Cost per Result": "$7.46"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "605686",
            "Clicks": "7460",
            "Conversions": "81",
            "Conversion Value": "$18,865",
            "Cost per Result": "$13.08"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "492713",
            "Clicks": "3544",
            "Conversions": "83",
            "Conversion Value": "$21,824",
            "Cost per Result": "$5.08"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "421397",
            "Clicks": "7660",
            "Conversions": "85",
            "Conversion Value": "$21,820",
            "Cost per Result": "$5.92"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "730822",
            "Clicks": "7492",
            "Conversions": "126",
            "Conversion Value": "$20,935",
            "Cost per Result": "$7.68"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "352240",
            "Clicks": "5778",
            "Conversions": "128",
            "Conversion Value": "$12,802",
            "Cost per Result": "$7.63"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "646035",
            "Clicks": "7195",
            "Conversions": "79",
            "Conversion Value": "$6,474",
            "Cost per Result": "$13.10"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "165767",
            "Clicks": "6054",
            "Conversions": "111",
            "Conversion Value": "$22,932",
            "Cost per Result": "$11.14"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "197504",
            "Clicks": "5458",
            "Conversions": "104",
            "Conversion Value": "$9,691",
            "Cost per Result": "$5.40"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "208802",
            "Clicks": "8272",
            "Conversions": "105",
            "Conversion Value": "$23,706",
            "Cost per Result": "$10.78"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "516650",
            "Clicks": "4468",
            "Conversions": "60",
            "Conversion Value": "$14,519",
            "Cost per Result": "$9.77"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "444172",
            "Clicks": "7930",
            "Conversions": "130",
            "Conversion Value": "$22,492",
            "Cost per Result": "$8.53"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "389313",
            "Clicks": "4877",
            "Conversions": "84",
            "Conversion Value": "$17,873",
            "Cost per Result": "$13.45"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "421066",
            "Clicks": "7097",
            "Conversions": "87",
            "Conversion Value": "$6,618",
            "Cost per Result": "$6.66"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "256916",
            "Clicks": "3938",
            "Conversions": "107",
            "Conversion Value": "$8,998",
            "Cost per Result": "$13.38"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "690866",
            "Clicks": "8867",
            "Conversions": "78",
            "Conversion Value": "$13,667",
            "Cost per Result": "$14.47"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "566512",
            "Clicks": "5398",
            "Conversions": "91",
            "Conversion Value": "$19,337",
            "Cost per Result": "$8.49"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "377572",
            "Clicks": "5633",
            "Conversions": "75",
            "Conversion Value": "$6,547",
            "Cost per Result": "$9.17"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "529108",
            "Clicks": "8123",
            "Conversions": "80",
            "Conversion Value": "$8,738",
            "Cost per Result": "$13.50"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "704164",
            "Clicks": "7232",
            "Conversions": "94",
            "Conversion Value": "$11,993",
            "Cost per Result": "$14.59"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "169732",
            "Clicks": "5772",
            "Conversions": "128",
            "Conversion Value": "$21,637",
            "Cost per Result": "$14.99"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "561720",
            "Clicks": "7834",
            "Conversions": "100",
            "Conversion Value": "$7,457",
            "Cost per Result": "$12.64"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "186659",
            "Clicks": "3875",
            "Conversions": "93",
            "Conversion Value": "$18,844",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "207009",
            "Clicks": "7333",
            "Conversions": "79",
            "Conversion Value": "$20,234",
            "Cost per Result": "$5.66"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "693511",
            "Clicks": "8762",
            "Conversions": "96",
            "Conversion Value": "$15,172",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "178454",
            "Clicks": "3269",
            "Conversions": "63",
            "Conversion Value": "$19,864",
            "Cost per Result": "$8.13"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "155596",
            "Clicks": "7197",
            "Conversions": "103",
            "Conversion Value": "$16,426",
            "Cost per Result": "$12.02"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "343808",
            "Clicks": "8000",
            "Conversions": "114",
            "Conversion Value": "$14,658",
            "Cost per Result": "$13.20"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "507035",
            "Clicks": "8989",
            "Conversions": "90",
            "Conversion Value": "$21,848",
            "Cost per Result": "$8.84"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "721343",
            "Clicks": "5264",
            "Conversions": "62",
            "Conversion Value": "$16,123",
            "Cost per Result": "$10.77"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "250796",
            "Clicks": "3918",
            "Conversions": "116",
            "Conversion Value": "$17,558",
            "Cost per Result": "$11.22"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "257265",
            "Clicks": "3372",
            "Conversions": "99",
            "Conversion Value": "$23,152",
            "Cost per Result": "$11.55"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "499603",
            "Clicks": "3292",
            "Conversions": "124",
            "Conversion Value": "$19,594",
            "Cost per Result": "$7.29"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "375243",
            "Clicks": "4211",
            "Conversions": "64",
            "Conversion Value": "$18,580",
            "Cost per Result": "$14.63"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "658343",
            "Clicks": "4364",
            "Conversions": "127",
            "Conversion Value": "$12,757",
            "Cost per Result": "$5.28"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "440351",
            "Clicks": "7046",
            "Conversions": "107",
            "Conversion Value": "$7,760",
            "Cost per Result": "$13.43"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "621821",
            "Clicks": "8315",
            "Conversions": "84",
            "Conversion Value": "$10,839",
            "Cost per Result": "$10.33"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "373028",
            "Clicks": "7811",
            "Conversions": "62",
            "Conversion Value": "$12,572",
            "Cost per Result": "$13.56"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "355717",
            "Clicks": "5138",
            "Conversions": "99",
            "Conversion Value": "$10,922",
            "Cost per Result": "$8.22"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "603609",
            "Clicks": "3115",
            "Conversions": "62",
            "Conversion Value": "$16,278",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "630888",
            "Clicks": "8517",
            "Conversions": "77",
            "Conversion Value": "$21,011",
            "Cost per Result": "$9.04"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "641791",
            "Clicks": "8544",
            "Conversions": "123",
            "Conversion Value": "$21,956",
            "Cost per Result": "$8.35"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "625722",
            "Clicks": "8684",
            "Conversions": "98",
            "Conversion Value": "$9,573",
            "Cost per Result": "$11.30"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "336911",
            "Clicks": "7960",
            "Conversions": "84",
            "Conversion Value": "$13,301",
            "Cost per Result": "$11.61"
          }
        ]
      },
      "BlockchainGamingData": {
        "name": "Blockchain Gaming",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "355243",
            "Clicks": "5728",
            "Conversions": "49",
            "Conversion Value": "$6,752",
            "Cost per Result": "$6.03"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "382813",
            "Clicks": "2449",
            "Conversions": "66",
            "Conversion Value": "$6,743",
            "Cost per Result": "$10.74"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "329259",
            "Clicks": "3934",
            "Conversions": "59",
            "Conversion Value": "$6,025",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "405056",
            "Clicks": "2220",
            "Conversions": "74",
            "Conversion Value": "$9,155",
            "Cost per Result": "$5.36"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "226857",
            "Clicks": "5043",
            "Conversions": "51",
            "Conversion Value": "$9,170",
            "Cost per Result": "$11.45"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "318967",
            "Clicks": "4608",
            "Conversions": "62",
            "Conversion Value": "$15,488",
            "Cost per Result": "$9.93"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "180494",
            "Clicks": "3965",
            "Conversions": "47",
            "Conversion Value": "$5,076",
            "Cost per Result": "$11.94"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "465934",
            "Clicks": "6085",
            "Conversions": "67",
            "Conversion Value": "$15,445",
            "Cost per Result": "$8.82"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "372296",
            "Clicks": "3562",
            "Conversions": "55",
            "Conversion Value": "$14,304",
            "Cost per Result": "$12.63"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "461582",
            "Clicks": "5875",
            "Conversions": "88",
            "Conversion Value": "$10,389",
            "Cost per Result": "$6.43"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "104874",
            "Clicks": "3208",
            "Conversions": "64",
            "Conversion Value": "$6,944",
            "Cost per Result": "$8.11"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "481740",
            "Clicks": "6108",
            "Conversions": "70",
            "Conversion Value": "$15,698",
            "Cost per Result": "$9.49"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "361549",
            "Clicks": "4274",
            "Conversions": "69",
            "Conversion Value": "$8,429",
            "Cost per Result": "$10.53"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "373527",
            "Clicks": "6091",
            "Conversions": "55",
            "Conversion Value": "$10,466",
            "Cost per Result": "$5.59"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "408138",
            "Clicks": "4520",
            "Conversions": "90",
            "Conversion Value": "$13,509",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "370079",
            "Clicks": "2110",
            "Conversions": "80",
            "Conversion Value": "$12,851",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "387855",
            "Clicks": "6114",
            "Conversions": "81",
            "Conversion Value": "$15,926",
            "Cost per Result": "$11.16"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "385996",
            "Clicks": "4359",
            "Conversions": "53",
            "Conversion Value": "$6,118",
            "Cost per Result": "$9.92"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "409853",
            "Clicks": "6444",
            "Conversions": "44",
            "Conversion Value": "$11,066",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "103427",
            "Clicks": "3533",
            "Conversions": "58",
            "Conversion Value": "$13,756",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "195249",
            "Clicks": "6123",
            "Conversions": "67",
            "Conversion Value": "$14,012",
            "Cost per Result": "$7.49"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "493478",
            "Clicks": "3467",
            "Conversions": "76",
            "Conversion Value": "$15,415",
            "Cost per Result": "$12.90"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "317534",
            "Clicks": "3679",
            "Conversions": "58",
            "Conversion Value": "$11,724",
            "Cost per Result": "$6.92"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "403029",
            "Clicks": "5831",
            "Conversions": "79",
            "Conversion Value": "$6,868",
            "Cost per Result": "$5.54"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "284298",
            "Clicks": "2330",
            "Conversions": "52",
            "Conversion Value": "$9,302",
            "Cost per Result": "$11.89"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "203331",
            "Clicks": "3733",
            "Conversions": "89",
            "Conversion Value": "$5,878",
            "Cost per Result": "$14.06"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "312171",
            "Clicks": "4762",
            "Conversions": "61",
            "Conversion Value": "$15,078",
            "Cost per Result": "$7.52"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "255048",
            "Clicks": "6908",
            "Conversions": "82",
            "Conversion Value": "$15,696",
            "Cost per Result": "$6.02"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "436404",
            "Clicks": "4816",
            "Conversions": "43",
            "Conversion Value": "$12,332",
            "Cost per Result": "$9.40"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "387477",
            "Clicks": "5460",
            "Conversions": "90",
            "Conversion Value": "$8,053",
            "Cost per Result": "$12.01"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "491237",
            "Clicks": "3788",
            "Conversions": "61",
            "Conversion Value": "$4,237",
            "Cost per Result": "$9.19"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "391230",
            "Clicks": "6729",
            "Conversions": "62",
            "Conversion Value": "$11,646",
            "Cost per Result": "$12.61"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "449032",
            "Clicks": "3591",
            "Conversions": "87",
            "Conversion Value": "$8,719",
            "Cost per Result": "$9.37"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "438816",
            "Clicks": "2263",
            "Conversions": "45",
            "Conversion Value": "$7,249",
            "Cost per Result": "$9.69"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "298418",
            "Clicks": "2162",
            "Conversions": "78",
            "Conversion Value": "$9,684",
            "Cost per Result": "$11.72"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "112643",
            "Clicks": "4851",
            "Conversions": "72",
            "Conversion Value": "$4,389",
            "Cost per Result": "$9.18"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "188658",
            "Clicks": "5197",
            "Conversions": "71",
            "Conversion Value": "$8,994",
            "Cost per Result": "$7.35"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "352553",
            "Clicks": "3956",
            "Conversions": "51",
            "Conversion Value": "$15,820",
            "Cost per Result": "$14.68"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "415058",
            "Clicks": "4916",
            "Conversions": "61",
            "Conversion Value": "$12,745",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "133916",
            "Clicks": "3020",
            "Conversions": "73",
            "Conversion Value": "$12,282",
            "Cost per Result": "$9.47"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "245193",
            "Clicks": "5136",
            "Conversions": "84",
            "Conversion Value": "$7,361",
            "Cost per Result": "$11.94"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "132667",
            "Clicks": "2302",
            "Conversions": "88",
            "Conversion Value": "$4,154",
            "Cost per Result": "$5.52"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "196441",
            "Clicks": "4324",
            "Conversions": "90",
            "Conversion Value": "$13,674",
            "Cost per Result": "$8.93"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "486688",
            "Clicks": "3199",
            "Conversions": "42",
            "Conversion Value": "$9,663",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "211075",
            "Clicks": "5593",
            "Conversions": "77",
            "Conversion Value": "$8,809",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "166389",
            "Clicks": "5971",
            "Conversions": "78",
            "Conversion Value": "$8,312",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "406830",
            "Clicks": "6213",
            "Conversions": "55",
            "Conversion Value": "$13,365",
            "Cost per Result": "$14.56"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "344842",
            "Clicks": "5325",
            "Conversions": "81",
            "Conversion Value": "$8,007",
            "Cost per Result": "$7.24"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "110215",
            "Clicks": "2160",
            "Conversions": "51",
            "Conversion Value": "$5,941",
            "Cost per Result": "$12.41"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "314566",
            "Clicks": "2180",
            "Conversions": "87",
            "Conversion Value": "$11,777",
            "Cost per Result": "$14.89"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "240293",
            "Clicks": "6571",
            "Conversions": "43",
            "Conversion Value": "$4,680",
            "Cost per Result": "$6.66"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "320419",
            "Clicks": "3870",
            "Conversions": "62",
            "Conversion Value": "$9,907",
            "Cost per Result": "$9.26"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "166373",
            "Clicks": "4710",
            "Conversions": "78",
            "Conversion Value": "$5,813",
            "Cost per Result": "$12.62"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "433439",
            "Clicks": "2122",
            "Conversions": "64",
            "Conversion Value": "$10,307",
            "Cost per Result": "$13.85"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "210124",
            "Clicks": "2808",
            "Conversions": "47",
            "Conversion Value": "$7,148",
            "Cost per Result": "$7.30"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "127910",
            "Clicks": "2796",
            "Conversions": "74",
            "Conversion Value": "$10,800",
            "Cost per Result": "$8.42"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "127564",
            "Clicks": "5852",
            "Conversions": "41",
            "Conversion Value": "$10,769",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "473367",
            "Clicks": "4972",
            "Conversions": "79",
            "Conversion Value": "$12,181",
            "Cost per Result": "$5.57"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "447812",
            "Clicks": "5309",
            "Conversions": "90",
            "Conversion Value": "$7,523",
            "Cost per Result": "$6.89"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "325989",
            "Clicks": "4830",
            "Conversions": "64",
            "Conversion Value": "$13,112",
            "Cost per Result": "$12.92"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "152485",
            "Clicks": "5930",
            "Conversions": "77",
            "Conversion Value": "$13,917",
            "Cost per Result": "$11.87"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "192681",
            "Clicks": "6430",
            "Conversions": "48",
            "Conversion Value": "$13,346",
            "Cost per Result": "$6.86"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "473259",
            "Clicks": "5680",
            "Conversions": "46",
            "Conversion Value": "$12,198",
            "Cost per Result": "$5.76"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "119543",
            "Clicks": "3040",
            "Conversions": "50",
            "Conversion Value": "$13,142",
            "Cost per Result": "$12.00"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "416951",
            "Clicks": "5856",
            "Conversions": "88",
            "Conversion Value": "$15,374",
            "Cost per Result": "$13.95"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "479858",
            "Clicks": "2074",
            "Conversions": "78",
            "Conversion Value": "$6,103",
            "Cost per Result": "$11.71"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "381126",
            "Clicks": "3502",
            "Conversions": "56",
            "Conversion Value": "$15,950",
            "Cost per Result": "$11.15"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "341233",
            "Clicks": "5626",
            "Conversions": "50",
            "Conversion Value": "$14,545",
            "Cost per Result": "$14.89"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "112755",
            "Clicks": "3642",
            "Conversions": "79",
            "Conversion Value": "$15,774",
            "Cost per Result": "$8.25"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "294843",
            "Clicks": "3449",
            "Conversions": "86",
            "Conversion Value": "$10,864",
            "Cost per Result": "$5.41"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "233889",
            "Clicks": "3189",
            "Conversions": "71",
            "Conversion Value": "$8,763",
            "Cost per Result": "$6.51"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "130568",
            "Clicks": "3432",
            "Conversions": "49",
            "Conversion Value": "$13,673",
            "Cost per Result": "$5.63"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "388340",
            "Clicks": "6944",
            "Conversions": "89",
            "Conversion Value": "$6,785",
            "Cost per Result": "$8.74"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "120390",
            "Clicks": "5735",
            "Conversions": "54",
            "Conversion Value": "$11,425",
            "Cost per Result": "$8.69"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "260412",
            "Clicks": "2008",
            "Conversions": "84",
            "Conversion Value": "$14,678",
            "Cost per Result": "$12.66"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "289898",
            "Clicks": "4195",
            "Conversions": "85",
            "Conversion Value": "$11,306",
            "Cost per Result": "$10.98"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "420091",
            "Clicks": "2489",
            "Conversions": "81",
            "Conversion Value": "$10,503",
            "Cost per Result": "$13.53"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "156884",
            "Clicks": "5053",
            "Conversions": "43",
            "Conversion Value": "$4,861",
            "Cost per Result": "$13.63"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "429508",
            "Clicks": "4230",
            "Conversions": "52",
            "Conversion Value": "$15,680",
            "Cost per Result": "$5.39"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "220471",
            "Clicks": "2681",
            "Conversions": "90",
            "Conversion Value": "$12,462",
            "Cost per Result": "$8.04"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "405065",
            "Clicks": "3862",
            "Conversions": "56",
            "Conversion Value": "$4,583",
            "Cost per Result": "$9.62"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "499179",
            "Clicks": "5953",
            "Conversions": "59",
            "Conversion Value": "$7,777",
            "Cost per Result": "$5.29"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "101213",
            "Clicks": "4830",
            "Conversions": "62",
            "Conversion Value": "$11,560",
            "Cost per Result": "$13.29"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "328108",
            "Clicks": "6954",
            "Conversions": "45",
            "Conversion Value": "$5,000",
            "Cost per Result": "$14.88"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "230366",
            "Clicks": "6646",
            "Conversions": "46",
            "Conversion Value": "$7,845",
            "Cost per Result": "$12.80"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "401568",
            "Clicks": "5369",
            "Conversions": "52",
            "Conversion Value": "$15,080",
            "Cost per Result": "$14.39"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "354154",
            "Clicks": "4171",
            "Conversions": "47",
            "Conversion Value": "$11,494",
            "Cost per Result": "$9.41"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "236797",
            "Clicks": "2727",
            "Conversions": "89",
            "Conversion Value": "$10,637",
            "Cost per Result": "$7.17"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "301367",
            "Clicks": "4645",
            "Conversions": "47",
            "Conversion Value": "$11,827",
            "Cost per Result": "$6.59"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "467093",
            "Clicks": "3411",
            "Conversions": "85",
            "Conversion Value": "$9,797",
            "Cost per Result": "$8.97"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "445621",
            "Clicks": "2611",
            "Conversions": "52",
            "Conversion Value": "$4,766",
            "Cost per Result": "$5.75"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "259911",
            "Clicks": "5352",
            "Conversions": "58",
            "Conversion Value": "$10,762",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "300418",
            "Clicks": "2519",
            "Conversions": "60",
            "Conversion Value": "$11,389",
            "Cost per Result": "$8.60"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "389930",
            "Clicks": "3675",
            "Conversions": "59",
            "Conversion Value": "$4,694",
            "Cost per Result": "$5.60"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "481655",
            "Clicks": "6042",
            "Conversions": "90",
            "Conversion Value": "$4,646",
            "Cost per Result": "$14.90"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "152532",
            "Clicks": "2335",
            "Conversions": "85",
            "Conversion Value": "$4,109",
            "Cost per Result": "$12.52"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "499653",
            "Clicks": "3864",
            "Conversions": "57",
            "Conversion Value": "$11,970",
            "Cost per Result": "$14.08"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "134753",
            "Clicks": "6344",
            "Conversions": "58",
            "Conversion Value": "$10,489",
            "Cost per Result": "$13.27"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "357653",
            "Clicks": "4937",
            "Conversions": "55",
            "Conversion Value": "$4,809",
            "Cost per Result": "$13.78"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "404701",
            "Clicks": "3394",
            "Conversions": "74",
            "Conversion Value": "$9,857",
            "Cost per Result": "$13.63"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "265910",
            "Clicks": "4512",
            "Conversions": "82",
            "Conversion Value": "$12,457",
            "Cost per Result": "$6.55"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "154450",
            "Clicks": "6287",
            "Conversions": "43",
            "Conversion Value": "$11,480",
            "Cost per Result": "$6.59"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "375711",
            "Clicks": "5646",
            "Conversions": "47",
            "Conversion Value": "$8,069",
            "Cost per Result": "$6.10"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "434271",
            "Clicks": "4973",
            "Conversions": "86",
            "Conversion Value": "$12,121",
            "Cost per Result": "$12.06"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "372811",
            "Clicks": "3599",
            "Conversions": "55",
            "Conversion Value": "$4,877",
            "Cost per Result": "$12.66"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "427741",
            "Clicks": "5504",
            "Conversions": "82",
            "Conversion Value": "$7,199",
            "Cost per Result": "$8.61"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "453120",
            "Clicks": "3251",
            "Conversions": "76",
            "Conversion Value": "$6,299",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "199325",
            "Clicks": "6837",
            "Conversions": "60",
            "Conversion Value": "$14,110",
            "Cost per Result": "$5.22"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "474412",
            "Clicks": "4688",
            "Conversions": "86",
            "Conversion Value": "$7,273",
            "Cost per Result": "$13.74"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "340751",
            "Clicks": "4712",
            "Conversions": "53",
            "Conversion Value": "$8,454",
            "Cost per Result": "$6.73"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "107127",
            "Clicks": "5560",
            "Conversions": "48",
            "Conversion Value": "$12,556",
            "Cost per Result": "$10.78"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "173979",
            "Clicks": "4815",
            "Conversions": "71",
            "Conversion Value": "$4,347",
            "Cost per Result": "$7.06"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "196874",
            "Clicks": "6110",
            "Conversions": "82",
            "Conversion Value": "$6,045",
            "Cost per Result": "$9.87"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "141727",
            "Clicks": "2647",
            "Conversions": "52",
            "Conversion Value": "$13,673",
            "Cost per Result": "$10.26"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "434304",
            "Clicks": "2468",
            "Conversions": "88",
            "Conversion Value": "$11,271",
            "Cost per Result": "$9.47"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "117710",
            "Clicks": "6034",
            "Conversions": "62",
            "Conversion Value": "$5,377",
            "Cost per Result": "$6.66"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "168983",
            "Clicks": "3511",
            "Conversions": "73",
            "Conversion Value": "$5,018",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "265621",
            "Clicks": "3616",
            "Conversions": "56",
            "Conversion Value": "$4,973",
            "Cost per Result": "$11.76"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "498518",
            "Clicks": "4183",
            "Conversions": "73",
            "Conversion Value": "$8,265",
            "Cost per Result": "$11.92"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "417171",
            "Clicks": "5991",
            "Conversions": "51",
            "Conversion Value": "$13,310",
            "Cost per Result": "$10.41"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "339357",
            "Clicks": "3474",
            "Conversions": "65",
            "Conversion Value": "$8,136",
            "Cost per Result": "$14.75"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "455355",
            "Clicks": "4178",
            "Conversions": "85",
            "Conversion Value": "$12,683",
            "Cost per Result": "$9.74"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "299764",
            "Clicks": "5587",
            "Conversions": "46",
            "Conversion Value": "$4,951",
            "Cost per Result": "$8.84"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "190794",
            "Clicks": "5662",
            "Conversions": "72",
            "Conversion Value": "$8,311",
            "Cost per Result": "$7.95"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "317589",
            "Clicks": "5019",
            "Conversions": "61",
            "Conversion Value": "$9,376",
            "Cost per Result": "$9.81"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "299192",
            "Clicks": "3383",
            "Conversions": "52",
            "Conversion Value": "$14,268",
            "Cost per Result": "$5.00"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "416779",
            "Clicks": "2301",
            "Conversions": "44",
            "Conversion Value": "$10,712",
            "Cost per Result": "$7.14"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "220305",
            "Clicks": "6402",
            "Conversions": "52",
            "Conversion Value": "$13,800",
            "Cost per Result": "$6.64"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "228570",
            "Clicks": "5701",
            "Conversions": "80",
            "Conversion Value": "$14,945",
            "Cost per Result": "$13.37"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "158655",
            "Clicks": "2876",
            "Conversions": "60",
            "Conversion Value": "$10,719",
            "Cost per Result": "$8.44"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "499565",
            "Clicks": "6874",
            "Conversions": "55",
            "Conversion Value": "$15,820",
            "Cost per Result": "$14.53"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "109593",
            "Clicks": "5789",
            "Conversions": "87",
            "Conversion Value": "$5,646",
            "Cost per Result": "$7.91"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "283643",
            "Clicks": "6052",
            "Conversions": "83",
            "Conversion Value": "$7,187",
            "Cost per Result": "$8.70"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "479070",
            "Clicks": "3302",
            "Conversions": "83",
            "Conversion Value": "$8,401",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "233669",
            "Clicks": "5850",
            "Conversions": "84",
            "Conversion Value": "$14,618",
            "Cost per Result": "$9.98"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "270225",
            "Clicks": "3723",
            "Conversions": "73",
            "Conversion Value": "$6,076",
            "Cost per Result": "$14.29"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "149230",
            "Clicks": "5053",
            "Conversions": "51",
            "Conversion Value": "$8,896",
            "Cost per Result": "$5.21"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "154848",
            "Clicks": "6874",
            "Conversions": "89",
            "Conversion Value": "$11,214",
            "Cost per Result": "$14.84"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "406266",
            "Clicks": "5432",
            "Conversions": "50",
            "Conversion Value": "$10,831",
            "Cost per Result": "$14.84"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "394209",
            "Clicks": "5013",
            "Conversions": "80",
            "Conversion Value": "$8,236",
            "Cost per Result": "$14.97"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "433013",
            "Clicks": "5433",
            "Conversions": "50",
            "Conversion Value": "$12,820",
            "Cost per Result": "$12.46"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "164236",
            "Clicks": "2675",
            "Conversions": "40",
            "Conversion Value": "$8,637",
            "Cost per Result": "$13.25"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "274773",
            "Clicks": "4807",
            "Conversions": "48",
            "Conversion Value": "$5,964",
            "Cost per Result": "$9.49"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "198438",
            "Clicks": "2422",
            "Conversions": "89",
            "Conversion Value": "$10,560",
            "Cost per Result": "$13.79"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "209682",
            "Clicks": "5608",
            "Conversions": "58",
            "Conversion Value": "$4,182",
            "Cost per Result": "$12.42"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "293301",
            "Clicks": "6265",
            "Conversions": "88",
            "Conversion Value": "$13,798",
            "Cost per Result": "$8.95"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "171961",
            "Clicks": "2231",
            "Conversions": "72",
            "Conversion Value": "$15,647",
            "Cost per Result": "$14.04"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "211592",
            "Clicks": "2639",
            "Conversions": "68",
            "Conversion Value": "$7,084",
            "Cost per Result": "$13.52"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "472779",
            "Clicks": "5213",
            "Conversions": "54",
            "Conversion Value": "$15,861",
            "Cost per Result": "$5.45"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "436533",
            "Clicks": "3364",
            "Conversions": "78",
            "Conversion Value": "$6,199",
            "Cost per Result": "$13.17"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "377197",
            "Clicks": "4431",
            "Conversions": "44",
            "Conversion Value": "$9,621",
            "Cost per Result": "$7.48"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "223066",
            "Clicks": "6907",
            "Conversions": "57",
            "Conversion Value": "$8,759",
            "Cost per Result": "$6.49"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "126025",
            "Clicks": "3291",
            "Conversions": "74",
            "Conversion Value": "$4,664",
            "Cost per Result": "$6.66"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "491492",
            "Clicks": "4045",
            "Conversions": "90",
            "Conversion Value": "$6,055",
            "Cost per Result": "$10.51"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "341691",
            "Clicks": "4902",
            "Conversions": "72",
            "Conversion Value": "$12,742",
            "Cost per Result": "$8.76"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "324876",
            "Clicks": "3132",
            "Conversions": "47",
            "Conversion Value": "$5,600",
            "Cost per Result": "$12.74"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "217074",
            "Clicks": "2374",
            "Conversions": "84",
            "Conversion Value": "$6,739",
            "Cost per Result": "$8.29"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "257543",
            "Clicks": "3577",
            "Conversions": "87",
            "Conversion Value": "$11,407",
            "Cost per Result": "$14.59"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "376282",
            "Clicks": "6258",
            "Conversions": "43",
            "Conversion Value": "$8,901",
            "Cost per Result": "$14.81"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "251260",
            "Clicks": "2134",
            "Conversions": "74",
            "Conversion Value": "$12,158",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "368493",
            "Clicks": "2898",
            "Conversions": "70",
            "Conversion Value": "$12,628",
            "Cost per Result": "$11.10"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "203759",
            "Clicks": "4258",
            "Conversions": "54",
            "Conversion Value": "$13,290",
            "Cost per Result": "$9.39"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "493546",
            "Clicks": "5862",
            "Conversions": "63",
            "Conversion Value": "$13,254",
            "Cost per Result": "$5.25"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "157081",
            "Clicks": "2291",
            "Conversions": "41",
            "Conversion Value": "$9,310",
            "Cost per Result": "$7.24"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "375309",
            "Clicks": "5606",
            "Conversions": "45",
            "Conversion Value": "$5,901",
            "Cost per Result": "$11.85"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "288680",
            "Clicks": "3929",
            "Conversions": "87",
            "Conversion Value": "$4,172",
            "Cost per Result": "$6.90"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "241666",
            "Clicks": "2568",
            "Conversions": "83",
            "Conversion Value": "$8,405",
            "Cost per Result": "$10.86"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "474908",
            "Clicks": "4298",
            "Conversions": "72",
            "Conversion Value": "$11,812",
            "Cost per Result": "$12.17"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "115349",
            "Clicks": "4853",
            "Conversions": "42",
            "Conversion Value": "$9,512",
            "Cost per Result": "$6.83"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "394683",
            "Clicks": "6531",
            "Conversions": "77",
            "Conversion Value": "$10,748",
            "Cost per Result": "$9.87"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "275759",
            "Clicks": "5608",
            "Conversions": "62",
            "Conversion Value": "$5,413",
            "Cost per Result": "$12.81"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "452024",
            "Clicks": "5897",
            "Conversions": "65",
            "Conversion Value": "$9,265",
            "Cost per Result": "$11.53"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "258092",
            "Clicks": "3043",
            "Conversions": "59",
            "Conversion Value": "$13,127",
            "Cost per Result": "$11.79"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "382298",
            "Clicks": "3049",
            "Conversions": "55",
            "Conversion Value": "$5,842",
            "Cost per Result": "$7.69"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "282995",
            "Clicks": "4257",
            "Conversions": "49",
            "Conversion Value": "$10,518",
            "Cost per Result": "$12.97"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "392433",
            "Clicks": "5424",
            "Conversions": "73",
            "Conversion Value": "$8,377",
            "Cost per Result": "$11.36"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "359673",
            "Clicks": "5703",
            "Conversions": "50",
            "Conversion Value": "$12,728",
            "Cost per Result": "$6.27"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "274878",
            "Clicks": "2391",
            "Conversions": "80",
            "Conversion Value": "$9,827",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "311114",
            "Clicks": "4216",
            "Conversions": "63",
            "Conversion Value": "$13,439",
            "Cost per Result": "$11.30"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "315219",
            "Clicks": "2786",
            "Conversions": "71",
            "Conversion Value": "$10,371",
            "Cost per Result": "$12.56"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "196178",
            "Clicks": "3093",
            "Conversions": "77",
            "Conversion Value": "$12,610",
            "Cost per Result": "$7.00"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "451628",
            "Clicks": "6282",
            "Conversions": "67",
            "Conversion Value": "$15,372",
            "Cost per Result": "$10.29"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "345597",
            "Clicks": "3301",
            "Conversions": "59",
            "Conversion Value": "$14,904",
            "Cost per Result": "$14.65"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "489518",
            "Clicks": "6976",
            "Conversions": "66",
            "Conversion Value": "$13,306",
            "Cost per Result": "$8.22"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "214278",
            "Clicks": "2618",
            "Conversions": "84",
            "Conversion Value": "$15,657",
            "Cost per Result": "$8.11"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "357034",
            "Clicks": "5050",
            "Conversions": "86",
            "Conversion Value": "$8,709",
            "Cost per Result": "$8.33"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "138970",
            "Clicks": "5382",
            "Conversions": "82",
            "Conversion Value": "$11,732",
            "Cost per Result": "$13.15"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "317609",
            "Clicks": "5583",
            "Conversions": "58",
            "Conversion Value": "$14,889",
            "Cost per Result": "$7.56"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "167719",
            "Clicks": "6724",
            "Conversions": "69",
            "Conversion Value": "$4,415",
            "Cost per Result": "$7.87"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "127373",
            "Clicks": "2264",
            "Conversions": "61",
            "Conversion Value": "$11,810",
            "Cost per Result": "$6.74"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "216442",
            "Clicks": "2263",
            "Conversions": "86",
            "Conversion Value": "$9,377",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "111533",
            "Clicks": "3830",
            "Conversions": "53",
            "Conversion Value": "$10,948",
            "Cost per Result": "$7.40"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "160145",
            "Clicks": "3388",
            "Conversions": "41",
            "Conversion Value": "$4,958",
            "Cost per Result": "$11.08"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "290004",
            "Clicks": "5531",
            "Conversions": "43",
            "Conversion Value": "$4,944",
            "Cost per Result": "$9.34"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "465228",
            "Clicks": "3564",
            "Conversions": "70",
            "Conversion Value": "$6,256",
            "Cost per Result": "$12.48"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "244915",
            "Clicks": "3556",
            "Conversions": "84",
            "Conversion Value": "$6,323",
            "Cost per Result": "$6.22"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "229176",
            "Clicks": "5853",
            "Conversions": "67",
            "Conversion Value": "$11,913",
            "Cost per Result": "$12.98"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "308751",
            "Clicks": "3410",
            "Conversions": "61",
            "Conversion Value": "$12,086",
            "Cost per Result": "$6.17"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "360688",
            "Clicks": "4524",
            "Conversions": "52",
            "Conversion Value": "$7,499",
            "Cost per Result": "$9.17"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "360523",
            "Clicks": "6202",
            "Conversions": "44",
            "Conversion Value": "$6,291",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "483624",
            "Clicks": "4662",
            "Conversions": "73",
            "Conversion Value": "$12,283",
            "Cost per Result": "$6.29"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "428763",
            "Clicks": "4331",
            "Conversions": "54",
            "Conversion Value": "$6,436",
            "Cost per Result": "$7.82"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "290534",
            "Clicks": "6041",
            "Conversions": "65",
            "Conversion Value": "$9,942",
            "Cost per Result": "$6.94"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "175439",
            "Clicks": "3328",
            "Conversions": "80",
            "Conversion Value": "$5,945",
            "Cost per Result": "$9.38"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "445164",
            "Clicks": "3707",
            "Conversions": "68",
            "Conversion Value": "$5,794",
            "Cost per Result": "$5.09"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "216807",
            "Clicks": "2591",
            "Conversions": "51",
            "Conversion Value": "$8,076",
            "Cost per Result": "$11.04"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "209129",
            "Clicks": "5190",
            "Conversions": "49",
            "Conversion Value": "$11,250",
            "Cost per Result": "$9.01"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "149513",
            "Clicks": "3377",
            "Conversions": "86",
            "Conversion Value": "$10,080",
            "Cost per Result": "$8.12"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "447757",
            "Clicks": "2463",
            "Conversions": "53",
            "Conversion Value": "$4,507",
            "Cost per Result": "$10.34"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "198033",
            "Clicks": "4008",
            "Conversions": "53",
            "Conversion Value": "$4,336",
            "Cost per Result": "$7.09"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "387055",
            "Clicks": "2957",
            "Conversions": "54",
            "Conversion Value": "$13,974",
            "Cost per Result": "$10.05"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "469112",
            "Clicks": "4412",
            "Conversions": "51",
            "Conversion Value": "$13,183",
            "Cost per Result": "$8.54"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "112563",
            "Clicks": "4288",
            "Conversions": "71",
            "Conversion Value": "$12,303",
            "Cost per Result": "$10.09"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "457820",
            "Clicks": "4553",
            "Conversions": "64",
            "Conversion Value": "$5,940",
            "Cost per Result": "$6.35"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "116864",
            "Clicks": "3853",
            "Conversions": "64",
            "Conversion Value": "$8,161",
            "Cost per Result": "$12.08"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "447012",
            "Clicks": "4354",
            "Conversions": "67",
            "Conversion Value": "$8,024",
            "Cost per Result": "$10.00"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "243015",
            "Clicks": "5202",
            "Conversions": "50",
            "Conversion Value": "$7,596",
            "Cost per Result": "$13.88"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "407409",
            "Clicks": "4364",
            "Conversions": "89",
            "Conversion Value": "$6,320",
            "Cost per Result": "$14.60"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "294115",
            "Clicks": "4673",
            "Conversions": "44",
            "Conversion Value": "$11,844",
            "Cost per Result": "$14.23"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "350613",
            "Clicks": "2783",
            "Conversions": "65",
            "Conversion Value": "$4,100",
            "Cost per Result": "$13.20"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "174219",
            "Clicks": "4317",
            "Conversions": "73",
            "Conversion Value": "$4,256",
            "Cost per Result": "$14.53"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "304588",
            "Clicks": "6837",
            "Conversions": "89",
            "Conversion Value": "$9,710",
            "Cost per Result": "$9.45"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "413489",
            "Clicks": "6665",
            "Conversions": "77",
            "Conversion Value": "$7,240",
            "Cost per Result": "$11.53"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "244454",
            "Clicks": "6727",
            "Conversions": "74",
            "Conversion Value": "$11,872",
            "Cost per Result": "$14.27"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "262767",
            "Clicks": "5707",
            "Conversions": "83",
            "Conversion Value": "$10,201",
            "Cost per Result": "$12.93"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "375777",
            "Clicks": "3124",
            "Conversions": "52",
            "Conversion Value": "$15,730",
            "Cost per Result": "$8.03"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "103020",
            "Clicks": "6978",
            "Conversions": "83",
            "Conversion Value": "$15,999",
            "Cost per Result": "$7.62"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "469374",
            "Clicks": "6982",
            "Conversions": "46",
            "Conversion Value": "$9,227",
            "Cost per Result": "$5.21"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "417509",
            "Clicks": "2290",
            "Conversions": "65",
            "Conversion Value": "$4,502",
            "Cost per Result": "$10.59"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "303441",
            "Clicks": "6261",
            "Conversions": "52",
            "Conversion Value": "$10,108",
            "Cost per Result": "$10.23"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "265129",
            "Clicks": "6397",
            "Conversions": "68",
            "Conversion Value": "$13,063",
            "Cost per Result": "$8.55"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "279456",
            "Clicks": "6425",
            "Conversions": "50",
            "Conversion Value": "$5,041",
            "Cost per Result": "$13.69"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "348792",
            "Clicks": "5028",
            "Conversions": "64",
            "Conversion Value": "$12,111",
            "Cost per Result": "$12.69"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "422852",
            "Clicks": "6288",
            "Conversions": "75",
            "Conversion Value": "$13,072",
            "Cost per Result": "$5.43"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "401387",
            "Clicks": "5983",
            "Conversions": "57",
            "Conversion Value": "$9,966",
            "Cost per Result": "$9.93"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "119264",
            "Clicks": "2449",
            "Conversions": "64",
            "Conversion Value": "$6,941",
            "Cost per Result": "$12.44"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "353121",
            "Clicks": "5530",
            "Conversions": "52",
            "Conversion Value": "$6,385",
            "Cost per Result": "$9.29"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "214755",
            "Clicks": "3238",
            "Conversions": "50",
            "Conversion Value": "$6,597",
            "Cost per Result": "$14.55"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "368634",
            "Clicks": "5444",
            "Conversions": "74",
            "Conversion Value": "$8,394",
            "Cost per Result": "$7.93"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "247650",
            "Clicks": "2145",
            "Conversions": "60",
            "Conversion Value": "$6,571",
            "Cost per Result": "$11.74"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "152146",
            "Clicks": "2009",
            "Conversions": "50",
            "Conversion Value": "$9,654",
            "Cost per Result": "$5.94"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "385641",
            "Clicks": "3540",
            "Conversions": "64",
            "Conversion Value": "$11,358",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "247267",
            "Clicks": "5778",
            "Conversions": "88",
            "Conversion Value": "$9,803",
            "Cost per Result": "$5.04"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "300796",
            "Clicks": "6082",
            "Conversions": "89",
            "Conversion Value": "$7,660",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "369346",
            "Clicks": "2342",
            "Conversions": "81",
            "Conversion Value": "$14,866",
            "Cost per Result": "$5.34"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "394816",
            "Clicks": "2454",
            "Conversions": "44",
            "Conversion Value": "$9,626",
            "Cost per Result": "$5.65"
          }
        ]
      },
      "Web3GamersData": {
        "name": "Web3 Gamers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "378771",
            "Clicks": "7106",
            "Conversions": "147",
            "Conversion Value": "$24,146",
            "Cost per Result": "$5.42"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "740657",
            "Clicks": "7343",
            "Conversions": "174",
            "Conversion Value": "$9,813",
            "Cost per Result": "$7.57"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "811417",
            "Clicks": "6653",
            "Conversions": "103",
            "Conversion Value": "$23,068",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "450302",
            "Clicks": "8438",
            "Conversions": "165",
            "Conversion Value": "$18,938",
            "Cost per Result": "$7.34"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "982092",
            "Clicks": "8535",
            "Conversions": "127",
            "Conversion Value": "$23,465",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "671562",
            "Clicks": "5158",
            "Conversions": "157",
            "Conversion Value": "$23,877",
            "Cost per Result": "$6.38"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "658026",
            "Clicks": "12291",
            "Conversions": "100",
            "Conversion Value": "$19,874",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "537619",
            "Clicks": "5022",
            "Conversions": "160",
            "Conversion Value": "$23,108",
            "Cost per Result": "$11.40"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "653251",
            "Clicks": "12432",
            "Conversions": "128",
            "Conversion Value": "$22,445",
            "Cost per Result": "$8.38"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "312821",
            "Clicks": "9129",
            "Conversions": "140",
            "Conversion Value": "$13,054",
            "Cost per Result": "$13.33"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "910735",
            "Clicks": "10763",
            "Conversions": "93",
            "Conversion Value": "$30,061",
            "Cost per Result": "$5.95"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "270221",
            "Clicks": "7487",
            "Conversions": "132",
            "Conversion Value": "$22,791",
            "Cost per Result": "$9.75"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "588302",
            "Clicks": "9760",
            "Conversions": "174",
            "Conversion Value": "$30,985",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "842337",
            "Clicks": "9399",
            "Conversions": "83",
            "Conversion Value": "$22,226",
            "Cost per Result": "$13.46"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "234145",
            "Clicks": "5144",
            "Conversions": "142",
            "Conversion Value": "$27,232",
            "Cost per Result": "$8.82"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "321378",
            "Clicks": "11028",
            "Conversions": "140",
            "Conversion Value": "$17,907",
            "Cost per Result": "$7.02"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "292456",
            "Clicks": "9549",
            "Conversions": "128",
            "Conversion Value": "$23,961",
            "Cost per Result": "$13.97"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "668077",
            "Clicks": "7945",
            "Conversions": "144",
            "Conversion Value": "$24,757",
            "Cost per Result": "$14.37"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "380700",
            "Clicks": "11523",
            "Conversions": "168",
            "Conversion Value": "$22,297",
            "Cost per Result": "$9.88"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "961545",
            "Clicks": "8632",
            "Conversions": "116",
            "Conversion Value": "$14,559",
            "Cost per Result": "$5.74"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "906388",
            "Clicks": "12216",
            "Conversions": "170",
            "Conversion Value": "$31,115",
            "Cost per Result": "$13.20"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "371583",
            "Clicks": "13993",
            "Conversions": "170",
            "Conversion Value": "$14,423",
            "Cost per Result": "$12.81"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "584116",
            "Clicks": "13682",
            "Conversions": "99",
            "Conversion Value": "$24,758",
            "Cost per Result": "$9.61"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "907113",
            "Clicks": "8449",
            "Conversions": "81",
            "Conversion Value": "$26,886",
            "Cost per Result": "$6.24"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "277124",
            "Clicks": "9083",
            "Conversions": "140",
            "Conversion Value": "$29,297",
            "Cost per Result": "$11.26"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "600082",
            "Clicks": "5001",
            "Conversions": "132",
            "Conversion Value": "$29,166",
            "Cost per Result": "$5.69"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "788101",
            "Clicks": "13022",
            "Conversions": "111",
            "Conversion Value": "$19,683",
            "Cost per Result": "$11.96"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "596051",
            "Clicks": "11790",
            "Conversions": "132",
            "Conversion Value": "$24,221",
            "Cost per Result": "$11.71"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "890270",
            "Clicks": "5002",
            "Conversions": "144",
            "Conversion Value": "$9,751",
            "Cost per Result": "$11.02"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "975682",
            "Clicks": "12816",
            "Conversions": "174",
            "Conversion Value": "$13,704",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "213401",
            "Clicks": "6622",
            "Conversions": "180",
            "Conversion Value": "$26,058",
            "Cost per Result": "$7.07"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "523369",
            "Clicks": "13908",
            "Conversions": "89",
            "Conversion Value": "$24,495",
            "Cost per Result": "$5.61"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "234169",
            "Clicks": "12775",
            "Conversions": "82",
            "Conversion Value": "$20,570",
            "Cost per Result": "$6.59"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "950302",
            "Clicks": "5109",
            "Conversions": "166",
            "Conversion Value": "$22,777",
            "Cost per Result": "$10.20"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "482643",
            "Clicks": "13935",
            "Conversions": "163",
            "Conversion Value": "$19,532",
            "Cost per Result": "$14.37"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "492002",
            "Clicks": "7372",
            "Conversions": "126",
            "Conversion Value": "$30,337",
            "Cost per Result": "$6.12"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "745564",
            "Clicks": "13899",
            "Conversions": "89",
            "Conversion Value": "$21,703",
            "Cost per Result": "$13.69"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "520113",
            "Clicks": "9452",
            "Conversions": "111",
            "Conversion Value": "$29,126",
            "Cost per Result": "$9.88"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "763830",
            "Clicks": "9176",
            "Conversions": "104",
            "Conversion Value": "$9,767",
            "Cost per Result": "$6.94"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "582345",
            "Clicks": "6991",
            "Conversions": "161",
            "Conversion Value": "$11,668",
            "Cost per Result": "$9.69"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "790167",
            "Clicks": "10039",
            "Conversions": "142",
            "Conversion Value": "$12,915",
            "Cost per Result": "$13.63"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "623455",
            "Clicks": "11037",
            "Conversions": "106",
            "Conversion Value": "$18,596",
            "Cost per Result": "$6.77"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "559764",
            "Clicks": "12198",
            "Conversions": "85",
            "Conversion Value": "$11,385",
            "Cost per Result": "$5.48"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "916369",
            "Clicks": "6034",
            "Conversions": "86",
            "Conversion Value": "$25,630",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "360527",
            "Clicks": "13611",
            "Conversions": "172",
            "Conversion Value": "$15,812",
            "Cost per Result": "$10.99"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "281321",
            "Clicks": "5387",
            "Conversions": "136",
            "Conversion Value": "$16,613",
            "Cost per Result": "$14.73"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "662077",
            "Clicks": "5306",
            "Conversions": "101",
            "Conversion Value": "$30,186",
            "Cost per Result": "$14.10"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "489848",
            "Clicks": "5799",
            "Conversions": "112",
            "Conversion Value": "$23,465",
            "Cost per Result": "$7.08"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "927414",
            "Clicks": "13838",
            "Conversions": "82",
            "Conversion Value": "$13,540",
            "Cost per Result": "$12.23"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "899690",
            "Clicks": "4203",
            "Conversions": "168",
            "Conversion Value": "$11,970",
            "Cost per Result": "$9.14"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "804940",
            "Clicks": "7340",
            "Conversions": "129",
            "Conversion Value": "$14,646",
            "Cost per Result": "$13.50"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "668940",
            "Clicks": "4435",
            "Conversions": "84",
            "Conversion Value": "$12,570",
            "Cost per Result": "$14.17"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "559901",
            "Clicks": "6776",
            "Conversions": "140",
            "Conversion Value": "$28,022",
            "Cost per Result": "$12.75"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "838108",
            "Clicks": "5925",
            "Conversions": "134",
            "Conversion Value": "$9,330",
            "Cost per Result": "$12.02"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "894957",
            "Clicks": "9154",
            "Conversions": "112",
            "Conversion Value": "$28,836",
            "Cost per Result": "$6.90"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "794965",
            "Clicks": "6408",
            "Conversions": "94",
            "Conversion Value": "$12,211",
            "Cost per Result": "$9.83"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "633207",
            "Clicks": "13889",
            "Conversions": "119",
            "Conversion Value": "$24,587",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "959110",
            "Clicks": "12563",
            "Conversions": "96",
            "Conversion Value": "$26,980",
            "Cost per Result": "$8.46"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "560118",
            "Clicks": "6591",
            "Conversions": "154",
            "Conversion Value": "$30,746",
            "Cost per Result": "$12.38"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "513943",
            "Clicks": "10952",
            "Conversions": "146",
            "Conversion Value": "$31,444",
            "Cost per Result": "$8.27"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "372274",
            "Clicks": "13998",
            "Conversions": "82",
            "Conversion Value": "$29,572",
            "Cost per Result": "$9.17"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "408196",
            "Clicks": "10860",
            "Conversions": "133",
            "Conversion Value": "$9,331",
            "Cost per Result": "$7.06"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "752143",
            "Clicks": "7999",
            "Conversions": "125",
            "Conversion Value": "$21,662",
            "Cost per Result": "$11.13"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "745041",
            "Clicks": "6452",
            "Conversions": "146",
            "Conversion Value": "$11,617",
            "Cost per Result": "$11.29"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "932958",
            "Clicks": "7698",
            "Conversions": "139",
            "Conversion Value": "$15,989",
            "Cost per Result": "$5.57"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "534277",
            "Clicks": "8975",
            "Conversions": "153",
            "Conversion Value": "$8,345",
            "Cost per Result": "$5.37"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "452885",
            "Clicks": "13413",
            "Conversions": "86",
            "Conversion Value": "$23,553",
            "Cost per Result": "$5.18"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "224799",
            "Clicks": "7807",
            "Conversions": "161",
            "Conversion Value": "$17,367",
            "Cost per Result": "$9.37"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "501611",
            "Clicks": "11636",
            "Conversions": "180",
            "Conversion Value": "$19,578",
            "Cost per Result": "$11.20"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "845589",
            "Clicks": "9505",
            "Conversions": "81",
            "Conversion Value": "$20,463",
            "Cost per Result": "$14.96"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "776950",
            "Clicks": "11630",
            "Conversions": "180",
            "Conversion Value": "$22,589",
            "Cost per Result": "$9.34"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "511613",
            "Clicks": "5524",
            "Conversions": "159",
            "Conversion Value": "$9,309",
            "Cost per Result": "$9.95"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "797773",
            "Clicks": "12450",
            "Conversions": "108",
            "Conversion Value": "$16,564",
            "Cost per Result": "$14.83"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "206328",
            "Clicks": "5902",
            "Conversions": "97",
            "Conversion Value": "$25,824",
            "Cost per Result": "$9.71"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "329012",
            "Clicks": "9565",
            "Conversions": "124",
            "Conversion Value": "$23,756",
            "Cost per Result": "$11.71"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "807637",
            "Clicks": "8539",
            "Conversions": "158",
            "Conversion Value": "$8,722",
            "Cost per Result": "$7.31"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "993537",
            "Clicks": "6656",
            "Conversions": "141",
            "Conversion Value": "$21,187",
            "Cost per Result": "$13.84"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "314332",
            "Clicks": "12131",
            "Conversions": "145",
            "Conversion Value": "$8,492",
            "Cost per Result": "$5.46"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "765792",
            "Clicks": "5529",
            "Conversions": "121",
            "Conversion Value": "$30,154",
            "Cost per Result": "$14.31"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "952914",
            "Clicks": "7582",
            "Conversions": "154",
            "Conversion Value": "$17,280",
            "Cost per Result": "$9.28"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "217118",
            "Clicks": "13231",
            "Conversions": "98",
            "Conversion Value": "$21,540",
            "Cost per Result": "$5.07"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "460311",
            "Clicks": "4730",
            "Conversions": "107",
            "Conversion Value": "$16,056",
            "Cost per Result": "$8.40"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "928990",
            "Clicks": "4367",
            "Conversions": "97",
            "Conversion Value": "$26,933",
            "Cost per Result": "$10.35"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "469193",
            "Clicks": "12271",
            "Conversions": "97",
            "Conversion Value": "$11,479",
            "Cost per Result": "$7.11"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "487034",
            "Clicks": "10254",
            "Conversions": "143",
            "Conversion Value": "$30,536",
            "Cost per Result": "$7.58"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "654337",
            "Clicks": "12386",
            "Conversions": "132",
            "Conversion Value": "$12,428",
            "Cost per Result": "$14.84"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "856950",
            "Clicks": "7737",
            "Conversions": "176",
            "Conversion Value": "$25,941",
            "Cost per Result": "$7.38"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "378572",
            "Clicks": "13066",
            "Conversions": "175",
            "Conversion Value": "$19,697",
            "Cost per Result": "$6.06"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "527198",
            "Clicks": "5624",
            "Conversions": "117",
            "Conversion Value": "$20,756",
            "Cost per Result": "$12.81"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "815372",
            "Clicks": "10559",
            "Conversions": "171",
            "Conversion Value": "$20,846",
            "Cost per Result": "$14.98"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "249177",
            "Clicks": "7267",
            "Conversions": "173",
            "Conversion Value": "$9,023",
            "Cost per Result": "$6.40"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "294720",
            "Clicks": "8717",
            "Conversions": "87",
            "Conversion Value": "$29,866",
            "Cost per Result": "$5.88"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "642168",
            "Clicks": "4531",
            "Conversions": "101",
            "Conversion Value": "$21,743",
            "Cost per Result": "$6.14"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "854849",
            "Clicks": "7776",
            "Conversions": "134",
            "Conversion Value": "$12,991",
            "Cost per Result": "$9.22"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "349270",
            "Clicks": "11780",
            "Conversions": "81",
            "Conversion Value": "$11,526",
            "Cost per Result": "$5.33"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "782138",
            "Clicks": "10905",
            "Conversions": "158",
            "Conversion Value": "$11,561",
            "Cost per Result": "$12.47"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "485672",
            "Clicks": "13170",
            "Conversions": "137",
            "Conversion Value": "$25,738",
            "Cost per Result": "$14.74"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "545429",
            "Clicks": "5761",
            "Conversions": "152",
            "Conversion Value": "$14,832",
            "Cost per Result": "$5.99"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "528214",
            "Clicks": "12350",
            "Conversions": "148",
            "Conversion Value": "$28,931",
            "Cost per Result": "$11.44"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "601531",
            "Clicks": "10380",
            "Conversions": "104",
            "Conversion Value": "$11,870",
            "Cost per Result": "$14.24"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "334448",
            "Clicks": "8990",
            "Conversions": "141",
            "Conversion Value": "$21,129",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "251223",
            "Clicks": "7765",
            "Conversions": "149",
            "Conversion Value": "$10,230",
            "Cost per Result": "$6.42"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "291423",
            "Clicks": "10551",
            "Conversions": "117",
            "Conversion Value": "$20,660",
            "Cost per Result": "$11.60"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "207050",
            "Clicks": "13137",
            "Conversions": "152",
            "Conversion Value": "$11,665",
            "Cost per Result": "$9.07"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "383337",
            "Clicks": "12691",
            "Conversions": "146",
            "Conversion Value": "$27,878",
            "Cost per Result": "$13.42"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "305948",
            "Clicks": "8148",
            "Conversions": "103",
            "Conversion Value": "$29,521",
            "Cost per Result": "$12.96"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "399276",
            "Clicks": "5937",
            "Conversions": "171",
            "Conversion Value": "$9,960",
            "Cost per Result": "$10.99"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "333801",
            "Clicks": "10871",
            "Conversions": "170",
            "Conversion Value": "$16,032",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "406482",
            "Clicks": "6081",
            "Conversions": "138",
            "Conversion Value": "$29,905",
            "Cost per Result": "$11.87"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "860605",
            "Clicks": "11578",
            "Conversions": "135",
            "Conversion Value": "$24,216",
            "Cost per Result": "$5.37"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "882337",
            "Clicks": "8530",
            "Conversions": "82",
            "Conversion Value": "$25,396",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "911681",
            "Clicks": "7301",
            "Conversions": "163",
            "Conversion Value": "$11,332",
            "Cost per Result": "$9.16"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "283392",
            "Clicks": "13010",
            "Conversions": "174",
            "Conversion Value": "$17,936",
            "Cost per Result": "$14.70"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "982444",
            "Clicks": "12042",
            "Conversions": "180",
            "Conversion Value": "$14,649",
            "Cost per Result": "$10.00"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "548843",
            "Clicks": "10892",
            "Conversions": "89",
            "Conversion Value": "$21,825",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "864260",
            "Clicks": "10815",
            "Conversions": "134",
            "Conversion Value": "$23,995",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "767518",
            "Clicks": "7579",
            "Conversions": "170",
            "Conversion Value": "$12,540",
            "Cost per Result": "$10.54"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "872156",
            "Clicks": "10258",
            "Conversions": "180",
            "Conversion Value": "$26,816",
            "Cost per Result": "$5.46"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "923442",
            "Clicks": "12211",
            "Conversions": "136",
            "Conversion Value": "$20,899",
            "Cost per Result": "$14.92"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "799078",
            "Clicks": "4329",
            "Conversions": "171",
            "Conversion Value": "$18,399",
            "Cost per Result": "$6.72"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "278921",
            "Clicks": "5052",
            "Conversions": "161",
            "Conversion Value": "$11,076",
            "Cost per Result": "$13.26"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "276332",
            "Clicks": "13096",
            "Conversions": "80",
            "Conversion Value": "$11,851",
            "Cost per Result": "$10.77"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "222579",
            "Clicks": "13770",
            "Conversions": "106",
            "Conversion Value": "$18,930",
            "Cost per Result": "$11.05"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "568386",
            "Clicks": "5270",
            "Conversions": "155",
            "Conversion Value": "$29,286",
            "Cost per Result": "$12.29"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "631512",
            "Clicks": "6042",
            "Conversions": "84",
            "Conversion Value": "$30,025",
            "Cost per Result": "$5.54"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "307452",
            "Clicks": "10072",
            "Conversions": "123",
            "Conversion Value": "$10,495",
            "Cost per Result": "$9.89"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "363345",
            "Clicks": "11131",
            "Conversions": "107",
            "Conversion Value": "$27,642",
            "Cost per Result": "$12.37"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "577022",
            "Clicks": "12118",
            "Conversions": "145",
            "Conversion Value": "$25,918",
            "Cost per Result": "$13.16"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "459210",
            "Clicks": "13846",
            "Conversions": "175",
            "Conversion Value": "$27,660",
            "Cost per Result": "$5.61"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "543770",
            "Clicks": "10171",
            "Conversions": "123",
            "Conversion Value": "$21,597",
            "Cost per Result": "$10.07"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "518963",
            "Clicks": "13446",
            "Conversions": "101",
            "Conversion Value": "$12,657",
            "Cost per Result": "$11.21"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "336743",
            "Clicks": "9122",
            "Conversions": "169",
            "Conversion Value": "$30,800",
            "Cost per Result": "$8.93"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "884358",
            "Clicks": "8108",
            "Conversions": "162",
            "Conversion Value": "$27,253",
            "Cost per Result": "$8.43"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "866658",
            "Clicks": "6814",
            "Conversions": "146",
            "Conversion Value": "$22,128",
            "Cost per Result": "$10.09"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "775569",
            "Clicks": "4382",
            "Conversions": "112",
            "Conversion Value": "$19,701",
            "Cost per Result": "$13.05"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "914042",
            "Clicks": "7464",
            "Conversions": "137",
            "Conversion Value": "$29,030",
            "Cost per Result": "$10.29"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "875529",
            "Clicks": "5424",
            "Conversions": "89",
            "Conversion Value": "$10,020",
            "Cost per Result": "$12.11"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "803089",
            "Clicks": "10089",
            "Conversions": "122",
            "Conversion Value": "$30,358",
            "Cost per Result": "$8.40"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "211273",
            "Clicks": "12357",
            "Conversions": "143",
            "Conversion Value": "$29,367",
            "Cost per Result": "$9.74"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "391068",
            "Clicks": "9379",
            "Conversions": "104",
            "Conversion Value": "$21,447",
            "Cost per Result": "$7.22"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "570878",
            "Clicks": "8885",
            "Conversions": "158",
            "Conversion Value": "$13,733",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "576132",
            "Clicks": "13090",
            "Conversions": "102",
            "Conversion Value": "$19,770",
            "Cost per Result": "$10.61"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "409953",
            "Clicks": "9988",
            "Conversions": "164",
            "Conversion Value": "$9,897",
            "Cost per Result": "$10.70"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "856993",
            "Clicks": "11599",
            "Conversions": "143",
            "Conversion Value": "$27,510",
            "Cost per Result": "$9.72"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "397000",
            "Clicks": "4153",
            "Conversions": "90",
            "Conversion Value": "$21,426",
            "Cost per Result": "$11.83"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "426463",
            "Clicks": "13587",
            "Conversions": "120",
            "Conversion Value": "$22,988",
            "Cost per Result": "$10.16"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "438587",
            "Clicks": "13981",
            "Conversions": "109",
            "Conversion Value": "$27,837",
            "Cost per Result": "$11.35"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "969755",
            "Clicks": "5836",
            "Conversions": "178",
            "Conversion Value": "$20,438",
            "Cost per Result": "$6.19"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "492227",
            "Clicks": "9200",
            "Conversions": "128",
            "Conversion Value": "$14,867",
            "Cost per Result": "$11.65"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "853064",
            "Clicks": "13146",
            "Conversions": "91",
            "Conversion Value": "$16,386",
            "Cost per Result": "$6.34"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "658388",
            "Clicks": "7024",
            "Conversions": "141",
            "Conversion Value": "$24,466",
            "Cost per Result": "$5.79"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "975837",
            "Clicks": "6960",
            "Conversions": "164",
            "Conversion Value": "$29,408",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "747183",
            "Clicks": "8466",
            "Conversions": "164",
            "Conversion Value": "$23,463",
            "Cost per Result": "$7.38"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "631430",
            "Clicks": "13290",
            "Conversions": "124",
            "Conversion Value": "$27,281",
            "Cost per Result": "$8.55"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "406380",
            "Clicks": "12698",
            "Conversions": "171",
            "Conversion Value": "$27,862",
            "Cost per Result": "$6.18"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "448332",
            "Clicks": "4036",
            "Conversions": "172",
            "Conversion Value": "$11,675",
            "Cost per Result": "$9.11"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "704307",
            "Clicks": "9962",
            "Conversions": "87",
            "Conversion Value": "$16,986",
            "Cost per Result": "$11.91"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "602781",
            "Clicks": "12318",
            "Conversions": "165",
            "Conversion Value": "$20,475",
            "Cost per Result": "$6.62"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "975456",
            "Clicks": "12874",
            "Conversions": "161",
            "Conversion Value": "$18,002",
            "Cost per Result": "$5.16"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "501243",
            "Clicks": "5451",
            "Conversions": "99",
            "Conversion Value": "$29,303",
            "Cost per Result": "$13.65"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "611991",
            "Clicks": "5531",
            "Conversions": "175",
            "Conversion Value": "$22,042",
            "Cost per Result": "$8.28"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "487889",
            "Clicks": "7024",
            "Conversions": "101",
            "Conversion Value": "$27,664",
            "Cost per Result": "$12.94"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "837386",
            "Clicks": "4654",
            "Conversions": "128",
            "Conversion Value": "$11,716",
            "Cost per Result": "$8.37"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "660598",
            "Clicks": "4654",
            "Conversions": "87",
            "Conversion Value": "$16,713",
            "Cost per Result": "$7.63"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "507848",
            "Clicks": "4654",
            "Conversions": "149",
            "Conversion Value": "$15,389",
            "Cost per Result": "$7.20"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "964347",
            "Clicks": "4654",
            "Conversions": "126",
            "Conversion Value": "$9,581",
            "Cost per Result": "$12.84"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "294644",
            "Clicks": "4654",
            "Conversions": "178",
            "Conversion Value": "$25,390",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "320641",
            "Clicks": "4654",
            "Conversions": "147",
            "Conversion Value": "$12,597",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "909577",
            "Clicks": "4654",
            "Conversions": "172",
            "Conversion Value": "$10,267",
            "Cost per Result": "$14.82"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "356201",
            "Clicks": "12297",
            "Conversions": "170",
            "Conversion Value": "$23,011",
            "Cost per Result": "$6.80"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "913311",
            "Clicks": "10777",
            "Conversions": "93",
            "Conversion Value": "$30,224",
            "Cost per Result": "$10.73"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "585991",
            "Clicks": "4535",
            "Conversions": "126",
            "Conversion Value": "$8,767",
            "Cost per Result": "$9.74"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "920752",
            "Clicks": "9358",
            "Conversions": "133",
            "Conversion Value": "$16,589",
            "Cost per Result": "$11.04"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "520073",
            "Clicks": "12863",
            "Conversions": "179",
            "Conversion Value": "$10,647",
            "Cost per Result": "$9.37"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "780738",
            "Clicks": "13053",
            "Conversions": "82",
            "Conversion Value": "$13,426",
            "Cost per Result": "$7.23"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "698334",
            "Clicks": "10545",
            "Conversions": "116",
            "Conversion Value": "$19,346",
            "Cost per Result": "$14.69"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "912803",
            "Clicks": "7367",
            "Conversions": "134",
            "Conversion Value": "$30,366",
            "Cost per Result": "$6.29"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "272021",
            "Clicks": "10242",
            "Conversions": "178",
            "Conversion Value": "$29,535",
            "Cost per Result": "$6.98"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "248056",
            "Clicks": "5551",
            "Conversions": "160",
            "Conversion Value": "$15,127",
            "Cost per Result": "$5.32"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "568861",
            "Clicks": "11966",
            "Conversions": "126",
            "Conversion Value": "$28,229",
            "Cost per Result": "$12.49"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "223344",
            "Clicks": "11674",
            "Conversions": "167",
            "Conversion Value": "$19,984",
            "Cost per Result": "$14.06"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "984572",
            "Clicks": "8923",
            "Conversions": "113",
            "Conversion Value": "$31,190",
            "Cost per Result": "$9.42"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "359013",
            "Clicks": "5925",
            "Conversions": "104",
            "Conversion Value": "$28,877",
            "Cost per Result": "$6.01"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "385324",
            "Clicks": "4394",
            "Conversions": "155",
            "Conversion Value": "$19,083",
            "Cost per Result": "$14.31"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "314922",
            "Clicks": "4847",
            "Conversions": "175",
            "Conversion Value": "$10,908",
            "Cost per Result": "$10.47"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "993482",
            "Clicks": "9881",
            "Conversions": "148",
            "Conversion Value": "$27,731",
            "Cost per Result": "$7.21"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "833046",
            "Clicks": "7717",
            "Conversions": "105",
            "Conversion Value": "$29,499",
            "Cost per Result": "$12.19"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "235245",
            "Clicks": "11572",
            "Conversions": "176",
            "Conversion Value": "$26,167",
            "Cost per Result": "$13.55"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "567149",
            "Clicks": "13432",
            "Conversions": "102",
            "Conversion Value": "$10,184",
            "Cost per Result": "$7.22"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "388978",
            "Clicks": "12818",
            "Conversions": "149",
            "Conversion Value": "$23,250",
            "Cost per Result": "$10.30"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "661868",
            "Clicks": "8236",
            "Conversions": "113",
            "Conversion Value": "$21,662",
            "Cost per Result": "$14.83"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "784450",
            "Clicks": "10737",
            "Conversions": "158",
            "Conversion Value": "$26,107",
            "Cost per Result": "$5.05"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "929107",
            "Clicks": "5465",
            "Conversions": "166",
            "Conversion Value": "$25,541",
            "Cost per Result": "$13.07"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "426355",
            "Clicks": "5998",
            "Conversions": "154",
            "Conversion Value": "$26,336",
            "Cost per Result": "$11.92"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "895311",
            "Clicks": "11497",
            "Conversions": "111",
            "Conversion Value": "$13,893",
            "Cost per Result": "$10.85"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "672780",
            "Clicks": "13841",
            "Conversions": "178",
            "Conversion Value": "$24,268",
            "Cost per Result": "$7.78"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "637812",
            "Clicks": "13316",
            "Conversions": "112",
            "Conversion Value": "$21,200",
            "Cost per Result": "$10.63"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "575964",
            "Clicks": "8564",
            "Conversions": "167",
            "Conversion Value": "$20,107",
            "Cost per Result": "$5.08"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "433503",
            "Clicks": "9030",
            "Conversions": "80",
            "Conversion Value": "$23,910",
            "Cost per Result": "$13.12"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "636473",
            "Clicks": "12402",
            "Conversions": "108",
            "Conversion Value": "$26,703",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "312423",
            "Clicks": "13173",
            "Conversions": "126",
            "Conversion Value": "$17,436",
            "Cost per Result": "$9.09"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "742820",
            "Clicks": "6459",
            "Conversions": "128",
            "Conversion Value": "$14,279",
            "Cost per Result": "$8.13"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "808932",
            "Clicks": "9499",
            "Conversions": "110",
            "Conversion Value": "$11,969",
            "Cost per Result": "$11.95"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "839369",
            "Clicks": "7079",
            "Conversions": "124",
            "Conversion Value": "$9,703",
            "Cost per Result": "$7.58"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "762802",
            "Clicks": "9051",
            "Conversions": "158",
            "Conversion Value": "$27,714",
            "Cost per Result": "$10.90"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "821157",
            "Clicks": "5851",
            "Conversions": "101",
            "Conversion Value": "$15,711",
            "Cost per Result": "$10.08"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "633295",
            "Clicks": "5344",
            "Conversions": "168",
            "Conversion Value": "$27,584",
            "Cost per Result": "$5.20"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "492741",
            "Clicks": "7841",
            "Conversions": "162",
            "Conversion Value": "$15,767",
            "Cost per Result": "$14.31"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "204913",
            "Clicks": "9615",
            "Conversions": "124",
            "Conversion Value": "$31,695",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "909456",
            "Clicks": "7292",
            "Conversions": "134",
            "Conversion Value": "$9,591",
            "Cost per Result": "$6.34"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "600488",
            "Clicks": "10933",
            "Conversions": "177",
            "Conversion Value": "$17,306",
            "Cost per Result": "$8.63"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "766241",
            "Clicks": "6964",
            "Conversions": "114",
            "Conversion Value": "$21,381",
            "Cost per Result": "$8.31"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "654510",
            "Clicks": "10765",
            "Conversions": "136",
            "Conversion Value": "$24,664",
            "Cost per Result": "$12.34"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "796616",
            "Clicks": "9782",
            "Conversions": "84",
            "Conversion Value": "$26,803",
            "Cost per Result": "$9.86"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "478466",
            "Clicks": "12086",
            "Conversions": "113",
            "Conversion Value": "$13,403",
            "Cost per Result": "$11.06"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "440044",
            "Clicks": "5083",
            "Conversions": "86",
            "Conversion Value": "$14,297",
            "Cost per Result": "$9.99"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "874375",
            "Clicks": "5525",
            "Conversions": "150",
            "Conversion Value": "$11,910",
            "Cost per Result": "$13.61"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "740089",
            "Clicks": "6345",
            "Conversions": "155",
            "Conversion Value": "$12,232",
            "Cost per Result": "$13.48"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "789113",
            "Clicks": "11251",
            "Conversions": "130",
            "Conversion Value": "$29,114",
            "Cost per Result": "$9.99"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "321014",
            "Clicks": "9956",
            "Conversions": "127",
            "Conversion Value": "$26,045",
            "Cost per Result": "$12.61"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "579380",
            "Clicks": "5528",
            "Conversions": "136",
            "Conversion Value": "$14,343",
            "Cost per Result": "$9.90"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "291962",
            "Clicks": "11786",
            "Conversions": "140",
            "Conversion Value": "$15,694",
            "Cost per Result": "$14.52"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "666689",
            "Clicks": "6104",
            "Conversions": "172",
            "Conversion Value": "$13,784",
            "Cost per Result": "$11.02"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "343291",
            "Clicks": "8721",
            "Conversions": "106",
            "Conversion Value": "$17,227",
            "Cost per Result": "$6.35"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "657483",
            "Clicks": "5100",
            "Conversions": "177",
            "Conversion Value": "$23,111",
            "Cost per Result": "$14.95"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "389124",
            "Clicks": "8858",
            "Conversions": "164",
            "Conversion Value": "$9,816",
            "Cost per Result": "$13.58"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "901799",
            "Clicks": "11875",
            "Conversions": "129",
            "Conversion Value": "$22,575",
            "Cost per Result": "$11.26"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "896400",
            "Clicks": "7029",
            "Conversions": "133",
            "Conversion Value": "$28,706",
            "Cost per Result": "$9.95"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "943680",
            "Clicks": "9832",
            "Conversions": "82",
            "Conversion Value": "$10,243",
            "Cost per Result": "$14.18"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "908808",
            "Clicks": "11288",
            "Conversions": "91",
            "Conversion Value": "$29,685",
            "Cost per Result": "$5.83"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "619251",
            "Clicks": "9345",
            "Conversions": "107",
            "Conversion Value": "$26,511",
            "Cost per Result": "$10.75"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "871643",
            "Clicks": "12618",
            "Conversions": "116",
            "Conversion Value": "$19,070",
            "Cost per Result": "$13.60"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "987883",
            "Clicks": "6384",
            "Conversions": "127",
            "Conversion Value": "$17,309",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "200968",
            "Clicks": "10743",
            "Conversions": "100",
            "Conversion Value": "$23,203",
            "Cost per Result": "$14.35"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "604577",
            "Clicks": "11113",
            "Conversions": "148",
            "Conversion Value": "$25,536",
            "Cost per Result": "$7.90"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "790156",
            "Clicks": "6657",
            "Conversions": "88",
            "Conversion Value": "$27,866",
            "Cost per Result": "$10.03"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "286555",
            "Clicks": "9246",
            "Conversions": "135",
            "Conversion Value": "$28,860",
            "Cost per Result": "$11.36"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "744090",
            "Clicks": "10197",
            "Conversions": "128",
            "Conversion Value": "$31,066",
            "Cost per Result": "$11.96"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "785395",
            "Clicks": "8454",
            "Conversions": "146",
            "Conversion Value": "$9,862",
            "Cost per Result": "$14.52"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "573705",
            "Clicks": "13523",
            "Conversions": "178",
            "Conversion Value": "$30,834",
            "Cost per Result": "$13.69"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "601859",
            "Clicks": "13158",
            "Conversions": "84",
            "Conversion Value": "$15,867",
            "Cost per Result": "$12.73"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "431193",
            "Clicks": "7919",
            "Conversions": "153",
            "Conversion Value": "$16,916",
            "Cost per Result": "$10.37"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "590182",
            "Clicks": "9929",
            "Conversions": "84",
            "Conversion Value": "$19,241",
            "Cost per Result": "$13.54"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "841934",
            "Clicks": "8488",
            "Conversions": "173",
            "Conversion Value": "$31,993",
            "Cost per Result": "$11.54"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "670984",
            "Clicks": "7037",
            "Conversions": "162",
            "Conversion Value": "$22,674",
            "Cost per Result": "$13.38"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "477960",
            "Clicks": "4783",
            "Conversions": "125",
            "Conversion Value": "$25,695",
            "Cost per Result": "$12.34"
          }
        ]
      },
      "P2EGamersData": {
        "name": "P2E Gamers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "647770",
            "Clicks": "8699",
            "Conversions": "83",
            "Conversion Value": "$7,497",
            "Cost per Result": "$8.15"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "742047",
            "Clicks": "7089",
            "Conversions": "100",
            "Conversion Value": "$6,272",
            "Cost per Result": "$12.92"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "672472",
            "Clicks": "6643",
            "Conversions": "118",
            "Conversion Value": "$12,637",
            "Cost per Result": "$9.84"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "184069",
            "Clicks": "4836",
            "Conversions": "101",
            "Conversion Value": "$14,834",
            "Cost per Result": "$5.92"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "221768",
            "Clicks": "6561",
            "Conversions": "77",
            "Conversion Value": "$9,007",
            "Cost per Result": "$13.67"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "543514",
            "Clicks": "6920",
            "Conversions": "120",
            "Conversion Value": "$12,486",
            "Cost per Result": "$9.87"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "588387",
            "Clicks": "5473",
            "Conversions": "120",
            "Conversion Value": "$8,496",
            "Cost per Result": "$6.27"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "552640",
            "Clicks": "3792",
            "Conversions": "89",
            "Conversion Value": "$15,885",
            "Cost per Result": "$7.43"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "467562",
            "Clicks": "7890",
            "Conversions": "104",
            "Conversion Value": "$16,427",
            "Cost per Result": "$14.89"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "470494",
            "Clicks": "6226",
            "Conversions": "60",
            "Conversion Value": "$17,139",
            "Cost per Result": "$13.93"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "498465",
            "Clicks": "8344",
            "Conversions": "110",
            "Conversion Value": "$11,154",
            "Cost per Result": "$10.26"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "645583",
            "Clicks": "4953",
            "Conversions": "81",
            "Conversion Value": "$19,922",
            "Cost per Result": "$13.35"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "739017",
            "Clicks": "8701",
            "Conversions": "126",
            "Conversion Value": "$16,247",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "658874",
            "Clicks": "3571",
            "Conversions": "66",
            "Conversion Value": "$23,172",
            "Cost per Result": "$10.20"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "441519",
            "Clicks": "8677",
            "Conversions": "73",
            "Conversion Value": "$14,342",
            "Cost per Result": "$7.01"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "527914",
            "Clicks": "7595",
            "Conversions": "61",
            "Conversion Value": "$17,548",
            "Cost per Result": "$10.14"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "551485",
            "Clicks": "5001",
            "Conversions": "101",
            "Conversion Value": "$20,712",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "676194",
            "Clicks": "3551",
            "Conversions": "64",
            "Conversion Value": "$9,354",
            "Cost per Result": "$14.51"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "701845",
            "Clicks": "3305",
            "Conversions": "96",
            "Conversion Value": "$10,175",
            "Cost per Result": "$12.94"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "329449",
            "Clicks": "8664",
            "Conversions": "62",
            "Conversion Value": "$22,398",
            "Cost per Result": "$14.80"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "241240",
            "Clicks": "5016",
            "Conversions": "101",
            "Conversion Value": "$14,160",
            "Cost per Result": "$13.51"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "368834",
            "Clicks": "5471",
            "Conversions": "108",
            "Conversion Value": "$22,056",
            "Cost per Result": "$14.72"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "536609",
            "Clicks": "3539",
            "Conversions": "92",
            "Conversion Value": "$23,069",
            "Cost per Result": "$11.57"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "197963",
            "Clicks": "7706",
            "Conversions": "71",
            "Conversion Value": "$22,403",
            "Cost per Result": "$8.22"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "292495",
            "Clicks": "6266",
            "Conversions": "116",
            "Conversion Value": "$6,885",
            "Cost per Result": "$13.87"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "636938",
            "Clicks": "7390",
            "Conversions": "88",
            "Conversion Value": "$10,228",
            "Cost per Result": "$13.50"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "494513",
            "Clicks": "7038",
            "Conversions": "76",
            "Conversion Value": "$12,036",
            "Cost per Result": "$9.77"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "383512",
            "Clicks": "4369",
            "Conversions": "118",
            "Conversion Value": "$14,731",
            "Cost per Result": "$8.37"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "421512",
            "Clicks": "6629",
            "Conversions": "72",
            "Conversion Value": "$10,480",
            "Cost per Result": "$12.82"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "643491",
            "Clicks": "5768",
            "Conversions": "118",
            "Conversion Value": "$10,013",
            "Cost per Result": "$5.15"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "386202",
            "Clicks": "7522",
            "Conversions": "120",
            "Conversion Value": "$12,061",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "464951",
            "Clicks": "3058",
            "Conversions": "83",
            "Conversion Value": "$23,586",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "410217",
            "Clicks": "5164",
            "Conversions": "103",
            "Conversion Value": "$20,154",
            "Cost per Result": "$11.04"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "271626",
            "Clicks": "3910",
            "Conversions": "68",
            "Conversion Value": "$11,204",
            "Cost per Result": "$6.07"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "166747",
            "Clicks": "3506",
            "Conversions": "77",
            "Conversion Value": "$6,129",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "609170",
            "Clicks": "4549",
            "Conversions": "120",
            "Conversion Value": "$7,644",
            "Cost per Result": "$11.58"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "728443",
            "Clicks": "5638",
            "Conversions": "105",
            "Conversion Value": "$8,311",
            "Cost per Result": "$5.53"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "245581",
            "Clicks": "7352",
            "Conversions": "90",
            "Conversion Value": "$23,550",
            "Cost per Result": "$14.65"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "634030",
            "Clicks": "6816",
            "Conversions": "119",
            "Conversion Value": "$10,748",
            "Cost per Result": "$14.63"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "626807",
            "Clicks": "8509",
            "Conversions": "114",
            "Conversion Value": "$6,248",
            "Cost per Result": "$6.19"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "574308",
            "Clicks": "5203",
            "Conversions": "73",
            "Conversion Value": "$21,797",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "625833",
            "Clicks": "7154",
            "Conversions": "101",
            "Conversion Value": "$6,344",
            "Cost per Result": "$13.60"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "334182",
            "Clicks": "6679",
            "Conversions": "95",
            "Conversion Value": "$11,263",
            "Cost per Result": "$6.13"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "255196",
            "Clicks": "5929",
            "Conversions": "67",
            "Conversion Value": "$22,606",
            "Cost per Result": "$7.77"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "696480",
            "Clicks": "6744",
            "Conversions": "76",
            "Conversion Value": "$12,647",
            "Cost per Result": "$11.76"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "177825",
            "Clicks": "8905",
            "Conversions": "113",
            "Conversion Value": "$12,127",
            "Cost per Result": "$14.97"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "396321",
            "Clicks": "3682",
            "Conversions": "102",
            "Conversion Value": "$11,162",
            "Cost per Result": "$6.60"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "160054",
            "Clicks": "4503",
            "Conversions": "96",
            "Conversion Value": "$22,292",
            "Cost per Result": "$9.13"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "439930",
            "Clicks": "7766",
            "Conversions": "117",
            "Conversion Value": "$16,924",
            "Cost per Result": "$14.68"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "396333",
            "Clicks": "3924",
            "Conversions": "90",
            "Conversion Value": "$10,374",
            "Cost per Result": "$5.49"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "193556",
            "Clicks": "7746",
            "Conversions": "70",
            "Conversion Value": "$23,683",
            "Cost per Result": "$12.72"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "201880",
            "Clicks": "8931",
            "Conversions": "104",
            "Conversion Value": "$12,069",
            "Cost per Result": "$13.95"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "678542",
            "Clicks": "3648",
            "Conversions": "80",
            "Conversion Value": "$20,278",
            "Cost per Result": "$10.39"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "743814",
            "Clicks": "5871",
            "Conversions": "99",
            "Conversion Value": "$18,106",
            "Cost per Result": "$8.10"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "545866",
            "Clicks": "3621",
            "Conversions": "66",
            "Conversion Value": "$8,496",
            "Cost per Result": "$5.52"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "361319",
            "Clicks": "4014",
            "Conversions": "76",
            "Conversion Value": "$23,704",
            "Cost per Result": "$11.66"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "263286",
            "Clicks": "4331",
            "Conversions": "75",
            "Conversion Value": "$10,249",
            "Cost per Result": "$7.49"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "731301",
            "Clicks": "8520",
            "Conversions": "82",
            "Conversion Value": "$15,001",
            "Cost per Result": "$13.34"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "442713",
            "Clicks": "8230",
            "Conversions": "65",
            "Conversion Value": "$19,051",
            "Cost per Result": "$12.90"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "359703",
            "Clicks": "7253",
            "Conversions": "122",
            "Conversion Value": "$13,822",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "523945",
            "Clicks": "3299",
            "Conversions": "103",
            "Conversion Value": "$11,982",
            "Cost per Result": "$6.69"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "234183",
            "Clicks": "3071",
            "Conversions": "113",
            "Conversion Value": "$17,252",
            "Cost per Result": "$13.35"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "369315",
            "Clicks": "8319",
            "Conversions": "82",
            "Conversion Value": "$7,195",
            "Cost per Result": "$5.63"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "646733",
            "Clicks": "7371",
            "Conversions": "78",
            "Conversion Value": "$6,124",
            "Cost per Result": "$14.72"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "415188",
            "Clicks": "7879",
            "Conversions": "85",
            "Conversion Value": "$18,058",
            "Cost per Result": "$7.30"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "692887",
            "Clicks": "5792",
            "Conversions": "117",
            "Conversion Value": "$8,739",
            "Cost per Result": "$9.43"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "470321",
            "Clicks": "4629",
            "Conversions": "111",
            "Conversion Value": "$17,160",
            "Cost per Result": "$8.29"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "656775",
            "Clicks": "3969",
            "Conversions": "62",
            "Conversion Value": "$22,282",
            "Cost per Result": "$14.97"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "694607",
            "Clicks": "4520",
            "Conversions": "118",
            "Conversion Value": "$6,421",
            "Cost per Result": "$13.74"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "199796",
            "Clicks": "7221",
            "Conversions": "113",
            "Conversion Value": "$6,313",
            "Cost per Result": "$13.78"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "378175",
            "Clicks": "3798",
            "Conversions": "89",
            "Conversion Value": "$12,624",
            "Cost per Result": "$11.62"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "658454",
            "Clicks": "7078",
            "Conversions": "71",
            "Conversion Value": "$10,714",
            "Cost per Result": "$13.81"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "460627",
            "Clicks": "3761",
            "Conversions": "122",
            "Conversion Value": "$9,015",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "718921",
            "Clicks": "6554",
            "Conversions": "78",
            "Conversion Value": "$9,980",
            "Cost per Result": "$5.17"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "570761",
            "Clicks": "7807",
            "Conversions": "66",
            "Conversion Value": "$6,687",
            "Cost per Result": "$12.16"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "339248",
            "Clicks": "3989",
            "Conversions": "72",
            "Conversion Value": "$18,100",
            "Cost per Result": "$9.20"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "262483",
            "Clicks": "7094",
            "Conversions": "69",
            "Conversion Value": "$23,664",
            "Cost per Result": "$12.16"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "223628",
            "Clicks": "5078",
            "Conversions": "117",
            "Conversion Value": "$10,624",
            "Cost per Result": "$8.35"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "203976",
            "Clicks": "6705",
            "Conversions": "102",
            "Conversion Value": "$14,846",
            "Cost per Result": "$5.85"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "277524",
            "Clicks": "8267",
            "Conversions": "97",
            "Conversion Value": "$22,956",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "422324",
            "Clicks": "7883",
            "Conversions": "85",
            "Conversion Value": "$10,992",
            "Cost per Result": "$6.04"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "370427",
            "Clicks": "8790",
            "Conversions": "101",
            "Conversion Value": "$13,501",
            "Cost per Result": "$6.67"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "289685",
            "Clicks": "7778",
            "Conversions": "125",
            "Conversion Value": "$17,761",
            "Cost per Result": "$6.89"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "658923",
            "Clicks": "6264",
            "Conversions": "60",
            "Conversion Value": "$7,921",
            "Cost per Result": "$11.80"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "181212",
            "Clicks": "8846",
            "Conversions": "108",
            "Conversion Value": "$8,725",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "653104",
            "Clicks": "6479",
            "Conversions": "65",
            "Conversion Value": "$13,222",
            "Cost per Result": "$8.45"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "458069",
            "Clicks": "5148",
            "Conversions": "124",
            "Conversion Value": "$11,552",
            "Cost per Result": "$9.83"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "592284",
            "Clicks": "7879",
            "Conversions": "67",
            "Conversion Value": "$9,462",
            "Cost per Result": "$12.82"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "396212",
            "Clicks": "7451",
            "Conversions": "121",
            "Conversion Value": "$13,568",
            "Cost per Result": "$14.28"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "201695",
            "Clicks": "7688",
            "Conversions": "77",
            "Conversion Value": "$7,179",
            "Cost per Result": "$7.50"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "355676",
            "Clicks": "6091",
            "Conversions": "88",
            "Conversion Value": "$22,633",
            "Cost per Result": "$13.18"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "572264",
            "Clicks": "8606",
            "Conversions": "89",
            "Conversion Value": "$20,656",
            "Cost per Result": "$12.74"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "574932",
            "Clicks": "3778",
            "Conversions": "84",
            "Conversion Value": "$22,073",
            "Cost per Result": "$12.29"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "704577",
            "Clicks": "4985",
            "Conversions": "78",
            "Conversion Value": "$9,451",
            "Cost per Result": "$10.11"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "186534",
            "Clicks": "4044",
            "Conversions": "97",
            "Conversion Value": "$13,683",
            "Cost per Result": "$5.06"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "621549",
            "Clicks": "3200",
            "Conversions": "80",
            "Conversion Value": "$14,418",
            "Cost per Result": "$12.85"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "236829",
            "Clicks": "5139",
            "Conversions": "101",
            "Conversion Value": "$20,372",
            "Cost per Result": "$8.06"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "339615",
            "Clicks": "6977",
            "Conversions": "68",
            "Conversion Value": "$22,109",
            "Cost per Result": "$11.36"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "203515",
            "Clicks": "5482",
            "Conversions": "127",
            "Conversion Value": "$15,264",
            "Cost per Result": "$7.14"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "425059",
            "Clicks": "3475",
            "Conversions": "111",
            "Conversion Value": "$11,104",
            "Cost per Result": "$9.73"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "737248",
            "Clicks": "7826",
            "Conversions": "82",
            "Conversion Value": "$18,475",
            "Cost per Result": "$12.33"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "501693",
            "Clicks": "4408",
            "Conversions": "125",
            "Conversion Value": "$17,529",
            "Cost per Result": "$5.82"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "300657",
            "Clicks": "8368",
            "Conversions": "126",
            "Conversion Value": "$17,786",
            "Cost per Result": "$11.35"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "341791",
            "Clicks": "5880",
            "Conversions": "95",
            "Conversion Value": "$7,735",
            "Cost per Result": "$8.49"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "580602",
            "Clicks": "4943",
            "Conversions": "95",
            "Conversion Value": "$15,936",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "508991",
            "Clicks": "3560",
            "Conversions": "109",
            "Conversion Value": "$21,858",
            "Cost per Result": "$9.51"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "644493",
            "Clicks": "3971",
            "Conversions": "117",
            "Conversion Value": "$13,079",
            "Cost per Result": "$14.80"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "546919",
            "Clicks": "4616",
            "Conversions": "127",
            "Conversion Value": "$22,301",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "483307",
            "Clicks": "8271",
            "Conversions": "93",
            "Conversion Value": "$16,141",
            "Cost per Result": "$10.00"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "159347",
            "Clicks": "8931",
            "Conversions": "62",
            "Conversion Value": "$6,051",
            "Cost per Result": "$12.02"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "713454",
            "Clicks": "7272",
            "Conversions": "77",
            "Conversion Value": "$23,628",
            "Cost per Result": "$12.72"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "317526",
            "Clicks": "3016",
            "Conversions": "75",
            "Conversion Value": "$9,538",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "624956",
            "Clicks": "7540",
            "Conversions": "80",
            "Conversion Value": "$12,210",
            "Cost per Result": "$5.37"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "390563",
            "Clicks": "8942",
            "Conversions": "129",
            "Conversion Value": "$17,095",
            "Cost per Result": "$10.68"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "389255",
            "Clicks": "6096",
            "Conversions": "100",
            "Conversion Value": "$16,711",
            "Cost per Result": "$5.20"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "429851",
            "Clicks": "7898",
            "Conversions": "65",
            "Conversion Value": "$8,412",
            "Cost per Result": "$6.62"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "447958",
            "Clicks": "4375",
            "Conversions": "112",
            "Conversion Value": "$22,540",
            "Cost per Result": "$13.61"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "574944",
            "Clicks": "8075",
            "Conversions": "72",
            "Conversion Value": "$9,919",
            "Cost per Result": "$11.30"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "662698",
            "Clicks": "5471",
            "Conversions": "96",
            "Conversion Value": "$18,132",
            "Cost per Result": "$13.94"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "384124",
            "Clicks": "6473",
            "Conversions": "99",
            "Conversion Value": "$12,077",
            "Cost per Result": "$10.59"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "231258",
            "Clicks": "6468",
            "Conversions": "62",
            "Conversion Value": "$20,420",
            "Cost per Result": "$9.39"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "548447",
            "Clicks": "4429",
            "Conversions": "65",
            "Conversion Value": "$22,306",
            "Cost per Result": "$13.27"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "478641",
            "Clicks": "5442",
            "Conversions": "128",
            "Conversion Value": "$22,004",
            "Cost per Result": "$6.64"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "715123",
            "Clicks": "7235",
            "Conversions": "70",
            "Conversion Value": "$20,566",
            "Cost per Result": "$7.19"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "272955",
            "Clicks": "8301",
            "Conversions": "81",
            "Conversion Value": "$23,819",
            "Cost per Result": "$5.58"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "538695",
            "Clicks": "7273",
            "Conversions": "92",
            "Conversion Value": "$8,651",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "450942",
            "Clicks": "5849",
            "Conversions": "117",
            "Conversion Value": "$13,149",
            "Cost per Result": "$12.91"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "582273",
            "Clicks": "8997",
            "Conversions": "100",
            "Conversion Value": "$16,661",
            "Cost per Result": "$9.49"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "269540",
            "Clicks": "7913",
            "Conversions": "95",
            "Conversion Value": "$14,409",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "713718",
            "Clicks": "4616",
            "Conversions": "71",
            "Conversion Value": "$15,826",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "227990",
            "Clicks": "8175",
            "Conversions": "79",
            "Conversion Value": "$21,068",
            "Cost per Result": "$12.23"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "162629",
            "Clicks": "7952",
            "Conversions": "61",
            "Conversion Value": "$22,422",
            "Cost per Result": "$12.64"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "376193",
            "Clicks": "8843",
            "Conversions": "84",
            "Conversion Value": "$7,255",
            "Cost per Result": "$13.88"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "524668",
            "Clicks": "6258",
            "Conversions": "73",
            "Conversion Value": "$9,575",
            "Cost per Result": "$7.79"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "582974",
            "Clicks": "4703",
            "Conversions": "79",
            "Conversion Value": "$13,126",
            "Cost per Result": "$6.79"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "493315",
            "Clicks": "3292",
            "Conversions": "117",
            "Conversion Value": "$21,733",
            "Cost per Result": "$5.62"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "672970",
            "Clicks": "8414",
            "Conversions": "113",
            "Conversion Value": "$10,158",
            "Cost per Result": "$13.07"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "160848",
            "Clicks": "3129",
            "Conversions": "100",
            "Conversion Value": "$14,925",
            "Cost per Result": "$8.00"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "240375",
            "Clicks": "6730",
            "Conversions": "91",
            "Conversion Value": "$23,689",
            "Cost per Result": "$14.15"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "326990",
            "Clicks": "5459",
            "Conversions": "106",
            "Conversion Value": "$10,882",
            "Cost per Result": "$7.14"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "545914",
            "Clicks": "4062",
            "Conversions": "96",
            "Conversion Value": "$8,622",
            "Cost per Result": "$9.72"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "274296",
            "Clicks": "3034",
            "Conversions": "122",
            "Conversion Value": "$8,854",
            "Cost per Result": "$7.54"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "431194",
            "Clicks": "8553",
            "Conversions": "111",
            "Conversion Value": "$9,189",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "474654",
            "Clicks": "5645",
            "Conversions": "121",
            "Conversion Value": "$18,662",
            "Cost per Result": "$7.19"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "620049",
            "Clicks": "4303",
            "Conversions": "104",
            "Conversion Value": "$12,619",
            "Cost per Result": "$6.72"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "263729",
            "Clicks": "8133",
            "Conversions": "126",
            "Conversion Value": "$22,160",
            "Cost per Result": "$7.88"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "594243",
            "Clicks": "6354",
            "Conversions": "115",
            "Conversion Value": "$8,856",
            "Cost per Result": "$8.14"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "214588",
            "Clicks": "6606",
            "Conversions": "92",
            "Conversion Value": "$23,764",
            "Cost per Result": "$10.34"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "152883",
            "Clicks": "6351",
            "Conversions": "91",
            "Conversion Value": "$18,911",
            "Cost per Result": "$8.84"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "234087",
            "Clicks": "6671",
            "Conversions": "123",
            "Conversion Value": "$11,850",
            "Cost per Result": "$7.45"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "509387",
            "Clicks": "4955",
            "Conversions": "77",
            "Conversion Value": "$10,700",
            "Cost per Result": "$12.87"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "644481",
            "Clicks": "3220",
            "Conversions": "107",
            "Conversion Value": "$20,381",
            "Cost per Result": "$5.40"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "612505",
            "Clicks": "6163",
            "Conversions": "88",
            "Conversion Value": "$17,543",
            "Cost per Result": "$8.48"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "467978",
            "Clicks": "4586",
            "Conversions": "114",
            "Conversion Value": "$17,723",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "460923",
            "Clicks": "4673",
            "Conversions": "91",
            "Conversion Value": "$8,722",
            "Cost per Result": "$12.30"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "545775",
            "Clicks": "4582",
            "Conversions": "82",
            "Conversion Value": "$16,786",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "723491",
            "Clicks": "8989",
            "Conversions": "128",
            "Conversion Value": "$21,305",
            "Cost per Result": "$5.91"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "259219",
            "Clicks": "7642",
            "Conversions": "130",
            "Conversion Value": "$10,771",
            "Cost per Result": "$13.57"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "585336",
            "Clicks": "7251",
            "Conversions": "121",
            "Conversion Value": "$21,812",
            "Cost per Result": "$8.18"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "215013",
            "Clicks": "3655",
            "Conversions": "130",
            "Conversion Value": "$8,448",
            "Cost per Result": "$8.24"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "366836",
            "Clicks": "3123",
            "Conversions": "87",
            "Conversion Value": "$8,459",
            "Cost per Result": "$11.69"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "666276",
            "Clicks": "5742",
            "Conversions": "123",
            "Conversion Value": "$15,027",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "157476",
            "Clicks": "5969",
            "Conversions": "100",
            "Conversion Value": "$9,769",
            "Cost per Result": "$6.71"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "196455",
            "Clicks": "5731",
            "Conversions": "121",
            "Conversion Value": "$10,737",
            "Cost per Result": "$10.20"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "366731",
            "Clicks": "6755",
            "Conversions": "123",
            "Conversion Value": "$19,833",
            "Cost per Result": "$14.54"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "544782",
            "Clicks": "5985",
            "Conversions": "109",
            "Conversion Value": "$20,107",
            "Cost per Result": "$14.41"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "412770",
            "Clicks": "6660",
            "Conversions": "91",
            "Conversion Value": "$19,065",
            "Cost per Result": "$13.84"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "523481",
            "Clicks": "7063",
            "Conversions": "93",
            "Conversion Value": "$12,653",
            "Cost per Result": "$12.08"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "387487",
            "Clicks": "3297",
            "Conversions": "95",
            "Conversion Value": "$19,483",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "313424",
            "Clicks": "3885",
            "Conversions": "102",
            "Conversion Value": "$21,226",
            "Cost per Result": "$14.67"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "190352",
            "Clicks": "3338",
            "Conversions": "99",
            "Conversion Value": "$8,473",
            "Cost per Result": "$13.25"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "543277",
            "Clicks": "4140",
            "Conversions": "89",
            "Conversion Value": "$14,168",
            "Cost per Result": "$14.61"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "298068",
            "Clicks": "8640",
            "Conversions": "90",
            "Conversion Value": "$15,609",
            "Cost per Result": "$7.29"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "296347",
            "Clicks": "6985",
            "Conversions": "126",
            "Conversion Value": "$22,810",
            "Cost per Result": "$11.26"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "413100",
            "Clicks": "8679",
            "Conversions": "69",
            "Conversion Value": "$8,575",
            "Cost per Result": "$11.51"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "260070",
            "Clicks": "4055",
            "Conversions": "125",
            "Conversion Value": "$19,753",
            "Cost per Result": "$8.41"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "239627",
            "Clicks": "8159",
            "Conversions": "130",
            "Conversion Value": "$15,085",
            "Cost per Result": "$13.10"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "367865",
            "Clicks": "3788",
            "Conversions": "93",
            "Conversion Value": "$18,924",
            "Cost per Result": "$14.02"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "395321",
            "Clicks": "7423",
            "Conversions": "108",
            "Conversion Value": "$23,262",
            "Cost per Result": "$6.86"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "586633",
            "Clicks": "5386",
            "Conversions": "98",
            "Conversion Value": "$23,450",
            "Cost per Result": "$6.34"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "653116",
            "Clicks": "6214",
            "Conversions": "103",
            "Conversion Value": "$17,072",
            "Cost per Result": "$13.31"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "601607",
            "Clicks": "3660",
            "Conversions": "105",
            "Conversion Value": "$8,711",
            "Cost per Result": "$13.45"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "387418",
            "Clicks": "8187",
            "Conversions": "78",
            "Conversion Value": "$11,828",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "342048",
            "Clicks": "8914",
            "Conversions": "112",
            "Conversion Value": "$6,449",
            "Cost per Result": "$14.82"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "489483",
            "Clicks": "8170",
            "Conversions": "123",
            "Conversion Value": "$8,530",
            "Cost per Result": "$14.27"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "496367",
            "Clicks": "5899",
            "Conversions": "72",
            "Conversion Value": "$17,793",
            "Cost per Result": "$10.84"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "629157",
            "Clicks": "3880",
            "Conversions": "84",
            "Conversion Value": "$13,044",
            "Cost per Result": "$12.67"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "675712",
            "Clicks": "4283",
            "Conversions": "84",
            "Conversion Value": "$10,567",
            "Cost per Result": "$11.85"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "734123",
            "Clicks": "6713",
            "Conversions": "84",
            "Conversion Value": "$13,139",
            "Cost per Result": "$12.90"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "714787",
            "Clicks": "8768",
            "Conversions": "66",
            "Conversion Value": "$21,137",
            "Cost per Result": "$9.75"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "489219",
            "Clicks": "5202",
            "Conversions": "81",
            "Conversion Value": "$13,644",
            "Cost per Result": "$9.97"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "633284",
            "Clicks": "6525",
            "Conversions": "123",
            "Conversion Value": "$18,211",
            "Cost per Result": "$12.27"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "430217",
            "Clicks": "4454",
            "Conversions": "119",
            "Conversion Value": "$17,108",
            "Cost per Result": "$7.71"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "328600",
            "Clicks": "5731",
            "Conversions": "109",
            "Conversion Value": "$13,311",
            "Cost per Result": "$8.24"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "418523",
            "Clicks": "5288",
            "Conversions": "92",
            "Conversion Value": "$17,113",
            "Cost per Result": "$5.33"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "409799",
            "Clicks": "3026",
            "Conversions": "105",
            "Conversion Value": "$11,497",
            "Cost per Result": "$7.75"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "382229",
            "Clicks": "7383",
            "Conversions": "66",
            "Conversion Value": "$22,696",
            "Cost per Result": "$11.62"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "515571",
            "Clicks": "8579",
            "Conversions": "81",
            "Conversion Value": "$6,207",
            "Cost per Result": "$9.77"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "438337",
            "Clicks": "8726",
            "Conversions": "89",
            "Conversion Value": "$18,335",
            "Cost per Result": "$7.77"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "258389",
            "Clicks": "5186",
            "Conversions": "112",
            "Conversion Value": "$22,030",
            "Cost per Result": "$13.11"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "661305",
            "Clicks": "8842",
            "Conversions": "79",
            "Conversion Value": "$14,848",
            "Cost per Result": "$12.17"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "498329",
            "Clicks": "5461",
            "Conversions": "127",
            "Conversion Value": "$18,365",
            "Cost per Result": "$10.67"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "521559",
            "Clicks": "4643",
            "Conversions": "122",
            "Conversion Value": "$7,699",
            "Cost per Result": "$9.89"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "440056",
            "Clicks": "7574",
            "Conversions": "110",
            "Conversion Value": "$18,496",
            "Cost per Result": "$7.87"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "518458",
            "Clicks": "5162",
            "Conversions": "125",
            "Conversion Value": "$14,320",
            "Cost per Result": "$8.36"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "454230",
            "Clicks": "6172",
            "Conversions": "108",
            "Conversion Value": "$19,799",
            "Cost per Result": "$11.59"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "306529",
            "Clicks": "7573",
            "Conversions": "110",
            "Conversion Value": "$22,608",
            "Cost per Result": "$6.15"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "210688",
            "Clicks": "4909",
            "Conversions": "83",
            "Conversion Value": "$21,729",
            "Cost per Result": "$6.61"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "539385",
            "Clicks": "6058",
            "Conversions": "83",
            "Conversion Value": "$11,868",
            "Cost per Result": "$15.00"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "557918",
            "Clicks": "5227",
            "Conversions": "94",
            "Conversion Value": "$13,937",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "507601",
            "Clicks": "5157",
            "Conversions": "109",
            "Conversion Value": "$12,971",
            "Cost per Result": "$5.59"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "645992",
            "Clicks": "7650",
            "Conversions": "107",
            "Conversion Value": "$14,193",
            "Cost per Result": "$9.77"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "446417",
            "Clicks": "5220",
            "Conversions": "118",
            "Conversion Value": "$16,676",
            "Cost per Result": "$14.81"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "644698",
            "Clicks": "5479",
            "Conversions": "118",
            "Conversion Value": "$19,638",
            "Cost per Result": "$14.55"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "355213",
            "Clicks": "8667",
            "Conversions": "62",
            "Conversion Value": "$6,234",
            "Cost per Result": "$7.26"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "503252",
            "Clicks": "4339",
            "Conversions": "88",
            "Conversion Value": "$10,909",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "468548",
            "Clicks": "7393",
            "Conversions": "120",
            "Conversion Value": "$16,031",
            "Cost per Result": "$5.73"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "602898",
            "Clicks": "3103",
            "Conversions": "121",
            "Conversion Value": "$11,604",
            "Cost per Result": "$11.74"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "410536",
            "Clicks": "8507",
            "Conversions": "63",
            "Conversion Value": "$10,771",
            "Cost per Result": "$6.88"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "510853",
            "Clicks": "7227",
            "Conversions": "126",
            "Conversion Value": "$21,803",
            "Cost per Result": "$11.37"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "459667",
            "Clicks": "5445",
            "Conversions": "82",
            "Conversion Value": "$6,466",
            "Cost per Result": "$5.92"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "317296",
            "Clicks": "4824",
            "Conversions": "60",
            "Conversion Value": "$22,508",
            "Cost per Result": "$8.21"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "555800",
            "Clicks": "4676",
            "Conversions": "79",
            "Conversion Value": "$10,046",
            "Cost per Result": "$5.72"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "462192",
            "Clicks": "8896",
            "Conversions": "60",
            "Conversion Value": "$9,145",
            "Cost per Result": "$8.06"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "548037",
            "Clicks": "8638",
            "Conversions": "74",
            "Conversion Value": "$15,058",
            "Cost per Result": "$11.59"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "393289",
            "Clicks": "4269",
            "Conversions": "66",
            "Conversion Value": "$11,071",
            "Cost per Result": "$5.97"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "261237",
            "Clicks": "7233",
            "Conversions": "105",
            "Conversion Value": "$15,871",
            "Cost per Result": "$8.07"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "470144",
            "Clicks": "5866",
            "Conversions": "129",
            "Conversion Value": "$14,326",
            "Cost per Result": "$10.98"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "446749",
            "Clicks": "7411",
            "Conversions": "115",
            "Conversion Value": "$11,384",
            "Cost per Result": "$13.74"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "192842",
            "Clicks": "4207",
            "Conversions": "121",
            "Conversion Value": "$7,041",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "573102",
            "Clicks": "4467",
            "Conversions": "68",
            "Conversion Value": "$15,276",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "711462",
            "Clicks": "4270",
            "Conversions": "94",
            "Conversion Value": "$17,533",
            "Cost per Result": "$11.76"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "445327",
            "Clicks": "8592",
            "Conversions": "62",
            "Conversion Value": "$13,784",
            "Cost per Result": "$8.13"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "708412",
            "Clicks": "5340",
            "Conversions": "112",
            "Conversion Value": "$18,470",
            "Cost per Result": "$11.37"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "454561",
            "Clicks": "4468",
            "Conversions": "60",
            "Conversion Value": "$14,043",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "157495",
            "Clicks": "6767",
            "Conversions": "65",
            "Conversion Value": "$16,088",
            "Cost per Result": "$6.25"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "336805",
            "Clicks": "3881",
            "Conversions": "71",
            "Conversion Value": "$23,953",
            "Cost per Result": "$9.02"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "289838",
            "Clicks": "8064",
            "Conversions": "96",
            "Conversion Value": "$9,088",
            "Cost per Result": "$8.96"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "542284",
            "Clicks": "3272",
            "Conversions": "67",
            "Conversion Value": "$8,804",
            "Cost per Result": "$10.58"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "748353",
            "Clicks": "4879",
            "Conversions": "69",
            "Conversion Value": "$22,176",
            "Cost per Result": "$9.66"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "712553",
            "Clicks": "5962",
            "Conversions": "63",
            "Conversion Value": "$21,697",
            "Cost per Result": "$11.41"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "421896",
            "Clicks": "5372",
            "Conversions": "69",
            "Conversion Value": "$7,650",
            "Cost per Result": "$6.51"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "656372",
            "Clicks": "7660",
            "Conversions": "93",
            "Conversion Value": "$12,730",
            "Cost per Result": "$6.63"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "422107",
            "Clicks": "8904",
            "Conversions": "101",
            "Conversion Value": "$14,462",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "578802",
            "Clicks": "8415",
            "Conversions": "109",
            "Conversion Value": "$10,686",
            "Cost per Result": "$8.38"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "226484",
            "Clicks": "6755",
            "Conversions": "103",
            "Conversion Value": "$15,108",
            "Cost per Result": "$10.18"
          }
        ]
      },
      "TokenTradersBlockchainData": {
        "name": "Token Traders (Blockchain)",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "267089",
            "Clicks": "4129",
            "Conversions": "78",
            "Conversion Value": "$15,571",
            "Cost per Result": "$6.88"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "440134",
            "Clicks": "2783",
            "Conversions": "85",
            "Conversion Value": "$11,794",
            "Cost per Result": "$12.46"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "288944",
            "Clicks": "5624",
            "Conversions": "86",
            "Conversion Value": "$12,958",
            "Cost per Result": "$9.27"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "162912",
            "Clicks": "3360",
            "Conversions": "57",
            "Conversion Value": "$5,266",
            "Cost per Result": "$8.73"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "284488",
            "Clicks": "5220",
            "Conversions": "88",
            "Conversion Value": "$12,517",
            "Cost per Result": "$14.02"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "238697",
            "Clicks": "5716",
            "Conversions": "78",
            "Conversion Value": "$7,831",
            "Cost per Result": "$11.74"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "466053",
            "Clicks": "6422",
            "Conversions": "84",
            "Conversion Value": "$8,380",
            "Cost per Result": "$5.58"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "365127",
            "Clicks": "2530",
            "Conversions": "44",
            "Conversion Value": "$11,081",
            "Cost per Result": "$6.97"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "366415",
            "Clicks": "6441",
            "Conversions": "86",
            "Conversion Value": "$14,169",
            "Cost per Result": "$7.82"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "486433",
            "Clicks": "2726",
            "Conversions": "90",
            "Conversion Value": "$9,070",
            "Cost per Result": "$6.08"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "422397",
            "Clicks": "2975",
            "Conversions": "73",
            "Conversion Value": "$12,797",
            "Cost per Result": "$13.24"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "460207",
            "Clicks": "3778",
            "Conversions": "75",
            "Conversion Value": "$9,911",
            "Cost per Result": "$10.91"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "388844",
            "Clicks": "5679",
            "Conversions": "87",
            "Conversion Value": "$14,647",
            "Cost per Result": "$6.56"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "421752",
            "Clicks": "3722",
            "Conversions": "52",
            "Conversion Value": "$14,609",
            "Cost per Result": "$14.17"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "465968",
            "Clicks": "6680",
            "Conversions": "73",
            "Conversion Value": "$13,442",
            "Cost per Result": "$10.45"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "299676",
            "Clicks": "6615",
            "Conversions": "87",
            "Conversion Value": "$15,177",
            "Cost per Result": "$6.46"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "422043",
            "Clicks": "2589",
            "Conversions": "56",
            "Conversion Value": "$10,218",
            "Cost per Result": "$10.42"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "293988",
            "Clicks": "3368",
            "Conversions": "78",
            "Conversion Value": "$9,863",
            "Cost per Result": "$6.16"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "235799",
            "Clicks": "2762",
            "Conversions": "47",
            "Conversion Value": "$11,211",
            "Cost per Result": "$5.14"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "163494",
            "Clicks": "3147",
            "Conversions": "46",
            "Conversion Value": "$14,038",
            "Cost per Result": "$12.34"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "233247",
            "Clicks": "2710",
            "Conversions": "75",
            "Conversion Value": "$9,023",
            "Cost per Result": "$9.92"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "150549",
            "Clicks": "4665",
            "Conversions": "73",
            "Conversion Value": "$11,424",
            "Cost per Result": "$13.03"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "447639",
            "Clicks": "5606",
            "Conversions": "72",
            "Conversion Value": "$8,965",
            "Cost per Result": "$10.71"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "346869",
            "Clicks": "3538",
            "Conversions": "74",
            "Conversion Value": "$12,754",
            "Cost per Result": "$11.68"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "115845",
            "Clicks": "3311",
            "Conversions": "54",
            "Conversion Value": "$6,764",
            "Cost per Result": "$14.33"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "204357",
            "Clicks": "5352",
            "Conversions": "70",
            "Conversion Value": "$9,994",
            "Cost per Result": "$14.99"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "497812",
            "Clicks": "3818",
            "Conversions": "46",
            "Conversion Value": "$11,478",
            "Cost per Result": "$5.76"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "430944",
            "Clicks": "6910",
            "Conversions": "45",
            "Conversion Value": "$10,758",
            "Cost per Result": "$11.83"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "457363",
            "Clicks": "6208",
            "Conversions": "54",
            "Conversion Value": "$10,177",
            "Cost per Result": "$13.98"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "475977",
            "Clicks": "2505",
            "Conversions": "90",
            "Conversion Value": "$11,622",
            "Cost per Result": "$6.69"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "293206",
            "Clicks": "4079",
            "Conversions": "71",
            "Conversion Value": "$15,253",
            "Cost per Result": "$14.77"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "376499",
            "Clicks": "6399",
            "Conversions": "46",
            "Conversion Value": "$15,441",
            "Cost per Result": "$13.79"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "410449",
            "Clicks": "2715",
            "Conversions": "87",
            "Conversion Value": "$9,603",
            "Cost per Result": "$9.15"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "359883",
            "Clicks": "2581",
            "Conversions": "80",
            "Conversion Value": "$6,525",
            "Cost per Result": "$10.73"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "358524",
            "Clicks": "5600",
            "Conversions": "85",
            "Conversion Value": "$14,824",
            "Cost per Result": "$12.22"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "116093",
            "Clicks": "2459",
            "Conversions": "59",
            "Conversion Value": "$9,198",
            "Cost per Result": "$11.40"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "145559",
            "Clicks": "2889",
            "Conversions": "69",
            "Conversion Value": "$10,354",
            "Cost per Result": "$14.48"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "181959",
            "Clicks": "4270",
            "Conversions": "69",
            "Conversion Value": "$15,756",
            "Cost per Result": "$6.87"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "117629",
            "Clicks": "4411",
            "Conversions": "69",
            "Conversion Value": "$15,514",
            "Cost per Result": "$12.91"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "318954",
            "Clicks": "4638",
            "Conversions": "66",
            "Conversion Value": "$13,682",
            "Cost per Result": "$14.46"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "440940",
            "Clicks": "5226",
            "Conversions": "49",
            "Conversion Value": "$13,783",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "295324",
            "Clicks": "4969",
            "Conversions": "44",
            "Conversion Value": "$10,814",
            "Cost per Result": "$9.34"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "254032",
            "Clicks": "3595",
            "Conversions": "50",
            "Conversion Value": "$4,774",
            "Cost per Result": "$12.38"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "464201",
            "Clicks": "4022",
            "Conversions": "79",
            "Conversion Value": "$7,485",
            "Cost per Result": "$9.08"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "205010",
            "Clicks": "4013",
            "Conversions": "70",
            "Conversion Value": "$9,142",
            "Cost per Result": "$12.20"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "404208",
            "Clicks": "3377",
            "Conversions": "56",
            "Conversion Value": "$6,024",
            "Cost per Result": "$10.40"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "426262",
            "Clicks": "2015",
            "Conversions": "75",
            "Conversion Value": "$11,299",
            "Cost per Result": "$6.72"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "481847",
            "Clicks": "6246",
            "Conversions": "73",
            "Conversion Value": "$14,624",
            "Cost per Result": "$13.54"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "128174",
            "Clicks": "4613",
            "Conversions": "89",
            "Conversion Value": "$8,253",
            "Cost per Result": "$7.99"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "210675",
            "Clicks": "5710",
            "Conversions": "79",
            "Conversion Value": "$8,078",
            "Cost per Result": "$14.42"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "347073",
            "Clicks": "5042",
            "Conversions": "49",
            "Conversion Value": "$5,030",
            "Cost per Result": "$8.53"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "207352",
            "Clicks": "3462",
            "Conversions": "56",
            "Conversion Value": "$8,225",
            "Cost per Result": "$7.25"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "225543",
            "Clicks": "2048",
            "Conversions": "84",
            "Conversion Value": "$10,923",
            "Cost per Result": "$13.04"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "146543",
            "Clicks": "6275",
            "Conversions": "45",
            "Conversion Value": "$15,603",
            "Cost per Result": "$13.05"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "331128",
            "Clicks": "6275",
            "Conversions": "56",
            "Conversion Value": "$10,014",
            "Cost per Result": "$6.32"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "344299",
            "Clicks": "2969",
            "Conversions": "60",
            "Conversion Value": "$6,734",
            "Cost per Result": "$6.59"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "159010",
            "Clicks": "5395",
            "Conversions": "75",
            "Conversion Value": "$9,770",
            "Cost per Result": "$5.75"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "282957",
            "Clicks": "4740",
            "Conversions": "44",
            "Conversion Value": "$9,983",
            "Cost per Result": "$8.67"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "474031",
            "Clicks": "4302",
            "Conversions": "60",
            "Conversion Value": "$15,698",
            "Cost per Result": "$7.92"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "341135",
            "Clicks": "5226",
            "Conversions": "41",
            "Conversion Value": "$10,699",
            "Cost per Result": "$10.81"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "316575",
            "Clicks": "4673",
            "Conversions": "69",
            "Conversion Value": "$8,751",
            "Cost per Result": "$12.47"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "102623",
            "Clicks": "2197",
            "Conversions": "53",
            "Conversion Value": "$6,844",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "446238",
            "Clicks": "3055",
            "Conversions": "59",
            "Conversion Value": "$8,858",
            "Cost per Result": "$14.49"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "161708",
            "Clicks": "4862",
            "Conversions": "75",
            "Conversion Value": "$8,892",
            "Cost per Result": "$8.05"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "278763",
            "Clicks": "3083",
            "Conversions": "41",
            "Conversion Value": "$8,163",
            "Cost per Result": "$9.85"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "474825",
            "Clicks": "6296",
            "Conversions": "76",
            "Conversion Value": "$13,718",
            "Cost per Result": "$13.48"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "468637",
            "Clicks": "3445",
            "Conversions": "44",
            "Conversion Value": "$7,773",
            "Cost per Result": "$10.84"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "365693",
            "Clicks": "4895",
            "Conversions": "66",
            "Conversion Value": "$13,020",
            "Cost per Result": "$12.24"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "316430",
            "Clicks": "5716",
            "Conversions": "40",
            "Conversion Value": "$12,288",
            "Cost per Result": "$10.33"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "290875",
            "Clicks": "2061",
            "Conversions": "53",
            "Conversion Value": "$15,357",
            "Cost per Result": "$12.87"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "382237",
            "Clicks": "2560",
            "Conversions": "84",
            "Conversion Value": "$6,548",
            "Cost per Result": "$9.28"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "213793",
            "Clicks": "6776",
            "Conversions": "76",
            "Conversion Value": "$4,674",
            "Cost per Result": "$7.99"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "141217",
            "Clicks": "6052",
            "Conversions": "87",
            "Conversion Value": "$8,071",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "197781",
            "Clicks": "4721",
            "Conversions": "78",
            "Conversion Value": "$8,046",
            "Cost per Result": "$5.11"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "232648",
            "Clicks": "4858",
            "Conversions": "75",
            "Conversion Value": "$14,707",
            "Cost per Result": "$8.85"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "273971",
            "Clicks": "2081",
            "Conversions": "87",
            "Conversion Value": "$12,490",
            "Cost per Result": "$12.92"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "469357",
            "Clicks": "3944",
            "Conversions": "40",
            "Conversion Value": "$12,371",
            "Cost per Result": "$14.54"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "310446",
            "Clicks": "3795",
            "Conversions": "87",
            "Conversion Value": "$11,906",
            "Cost per Result": "$6.45"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "460714",
            "Clicks": "2469",
            "Conversions": "59",
            "Conversion Value": "$5,199",
            "Cost per Result": "$9.24"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "228746",
            "Clicks": "5641",
            "Conversions": "60",
            "Conversion Value": "$9,579",
            "Cost per Result": "$13.38"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "261482",
            "Clicks": "3016",
            "Conversions": "41",
            "Conversion Value": "$7,267",
            "Cost per Result": "$7.83"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "487821",
            "Clicks": "3948",
            "Conversions": "82",
            "Conversion Value": "$13,832",
            "Cost per Result": "$9.14"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "223860",
            "Clicks": "3950",
            "Conversions": "55",
            "Conversion Value": "$14,930",
            "Cost per Result": "$8.62"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "269971",
            "Clicks": "4620",
            "Conversions": "59",
            "Conversion Value": "$7,376",
            "Cost per Result": "$12.49"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "165564",
            "Clicks": "6809",
            "Conversions": "47",
            "Conversion Value": "$5,861",
            "Cost per Result": "$14.50"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "325994",
            "Clicks": "2630",
            "Conversions": "41",
            "Conversion Value": "$5,162",
            "Cost per Result": "$9.10"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "168469",
            "Clicks": "2537",
            "Conversions": "80",
            "Conversion Value": "$15,704",
            "Cost per Result": "$7.85"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "416286",
            "Clicks": "3640",
            "Conversions": "46",
            "Conversion Value": "$5,917",
            "Cost per Result": "$6.77"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "317027",
            "Clicks": "6390",
            "Conversions": "90",
            "Conversion Value": "$6,665",
            "Cost per Result": "$7.93"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "251309",
            "Clicks": "2299",
            "Conversions": "88",
            "Conversion Value": "$5,491",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "331715",
            "Clicks": "6271",
            "Conversions": "41",
            "Conversion Value": "$10,182",
            "Cost per Result": "$9.20"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "299813",
            "Clicks": "2303",
            "Conversions": "70",
            "Conversion Value": "$6,349",
            "Cost per Result": "$10.89"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "239574",
            "Clicks": "2527",
            "Conversions": "81",
            "Conversion Value": "$15,354",
            "Cost per Result": "$7.91"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "189114",
            "Clicks": "6144",
            "Conversions": "81",
            "Conversion Value": "$11,262",
            "Cost per Result": "$10.41"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "372071",
            "Clicks": "3116",
            "Conversions": "72",
            "Conversion Value": "$10,985",
            "Cost per Result": "$6.23"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "442444",
            "Clicks": "3611",
            "Conversions": "75",
            "Conversion Value": "$11,116",
            "Cost per Result": "$8.36"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "142187",
            "Clicks": "3008",
            "Conversions": "69",
            "Conversion Value": "$6,137",
            "Cost per Result": "$11.83"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "238920",
            "Clicks": "5529",
            "Conversions": "40",
            "Conversion Value": "$6,899",
            "Cost per Result": "$8.07"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "410867",
            "Clicks": "4599",
            "Conversions": "88",
            "Conversion Value": "$11,528",
            "Cost per Result": "$13.12"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "378441",
            "Clicks": "4609",
            "Conversions": "49",
            "Conversion Value": "$6,823",
            "Cost per Result": "$9.81"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "422221",
            "Clicks": "5141",
            "Conversions": "67",
            "Conversion Value": "$5,363",
            "Cost per Result": "$6.81"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "204229",
            "Clicks": "5087",
            "Conversions": "42",
            "Conversion Value": "$11,153",
            "Cost per Result": "$10.60"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "412986",
            "Clicks": "6276",
            "Conversions": "72",
            "Conversion Value": "$14,475",
            "Cost per Result": "$5.75"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "378878",
            "Clicks": "4287",
            "Conversions": "48",
            "Conversion Value": "$8,143",
            "Cost per Result": "$9.21"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "461477",
            "Clicks": "4986",
            "Conversions": "53",
            "Conversion Value": "$15,234",
            "Cost per Result": "$8.70"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "105434",
            "Clicks": "2256",
            "Conversions": "70",
            "Conversion Value": "$10,197",
            "Cost per Result": "$5.58"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "242981",
            "Clicks": "2832",
            "Conversions": "57",
            "Conversion Value": "$7,883",
            "Cost per Result": "$14.05"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "394763",
            "Clicks": "3159",
            "Conversions": "58",
            "Conversion Value": "$14,424",
            "Cost per Result": "$13.05"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "441590",
            "Clicks": "3424",
            "Conversions": "51",
            "Conversion Value": "$7,611",
            "Cost per Result": "$11.33"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "346751",
            "Clicks": "2283",
            "Conversions": "68",
            "Conversion Value": "$7,743",
            "Cost per Result": "$9.30"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "103629",
            "Clicks": "4470",
            "Conversions": "69",
            "Conversion Value": "$10,169",
            "Cost per Result": "$14.70"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "159548",
            "Clicks": "4214",
            "Conversions": "56",
            "Conversion Value": "$9,827",
            "Cost per Result": "$6.70"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "106994",
            "Clicks": "4705",
            "Conversions": "84",
            "Conversion Value": "$14,923",
            "Cost per Result": "$14.55"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "413208",
            "Clicks": "2215",
            "Conversions": "53",
            "Conversion Value": "$5,001",
            "Cost per Result": "$11.40"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "397097",
            "Clicks": "4735",
            "Conversions": "62",
            "Conversion Value": "$6,949",
            "Cost per Result": "$6.50"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "178040",
            "Clicks": "5875",
            "Conversions": "74",
            "Conversion Value": "$10,819",
            "Cost per Result": "$7.68"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "168230",
            "Clicks": "5764",
            "Conversions": "44",
            "Conversion Value": "$15,000",
            "Cost per Result": "$12.16"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "441322",
            "Clicks": "3131",
            "Conversions": "49",
            "Conversion Value": "$9,800",
            "Cost per Result": "$6.66"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "344626",
            "Clicks": "2945",
            "Conversions": "68",
            "Conversion Value": "$11,645",
            "Cost per Result": "$5.45"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "177533",
            "Clicks": "4450",
            "Conversions": "62",
            "Conversion Value": "$15,766",
            "Cost per Result": "$5.06"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "165258",
            "Clicks": "4187",
            "Conversions": "65",
            "Conversion Value": "$13,466",
            "Cost per Result": "$11.51"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "337144",
            "Clicks": "3959",
            "Conversions": "48",
            "Conversion Value": "$12,506",
            "Cost per Result": "$9.86"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "358862",
            "Clicks": "6857",
            "Conversions": "75",
            "Conversion Value": "$13,389",
            "Cost per Result": "$5.45"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "308361",
            "Clicks": "6055",
            "Conversions": "86",
            "Conversion Value": "$8,150",
            "Cost per Result": "$10.49"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "299938",
            "Clicks": "4404",
            "Conversions": "60",
            "Conversion Value": "$10,904",
            "Cost per Result": "$5.31"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "307954",
            "Clicks": "2500",
            "Conversions": "84",
            "Conversion Value": "$12,611",
            "Cost per Result": "$11.81"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "183593",
            "Clicks": "3318",
            "Conversions": "90",
            "Conversion Value": "$6,365",
            "Cost per Result": "$11.50"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "154843",
            "Clicks": "6390",
            "Conversions": "86",
            "Conversion Value": "$7,583",
            "Cost per Result": "$6.79"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "398768",
            "Clicks": "5362",
            "Conversions": "73",
            "Conversion Value": "$6,519",
            "Cost per Result": "$11.94"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "328953",
            "Clicks": "4951",
            "Conversions": "42",
            "Conversion Value": "$9,938",
            "Cost per Result": "$7.08"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "398955",
            "Clicks": "6200",
            "Conversions": "71",
            "Conversion Value": "$4,111",
            "Cost per Result": "$5.98"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "253975",
            "Clicks": "5842",
            "Conversions": "67",
            "Conversion Value": "$7,302",
            "Cost per Result": "$6.39"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "381422",
            "Clicks": "3670",
            "Conversions": "70",
            "Conversion Value": "$11,379",
            "Cost per Result": "$14.99"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "183875",
            "Clicks": "4048",
            "Conversions": "50",
            "Conversion Value": "$12,374",
            "Cost per Result": "$6.13"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "235773",
            "Clicks": "2032",
            "Conversions": "51",
            "Conversion Value": "$10,658",
            "Cost per Result": "$5.62"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "317998",
            "Clicks": "2308",
            "Conversions": "85",
            "Conversion Value": "$11,268",
            "Cost per Result": "$13.19"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "187608",
            "Clicks": "3971",
            "Conversions": "57",
            "Conversion Value": "$14,595",
            "Cost per Result": "$14.66"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "298538",
            "Clicks": "3186",
            "Conversions": "43",
            "Conversion Value": "$5,888",
            "Cost per Result": "$7.85"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "150997",
            "Clicks": "4443",
            "Conversions": "48",
            "Conversion Value": "$11,529",
            "Cost per Result": "$6.10"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "118335",
            "Clicks": "4815",
            "Conversions": "52",
            "Conversion Value": "$4,232",
            "Cost per Result": "$8.62"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "254177",
            "Clicks": "3060",
            "Conversions": "88",
            "Conversion Value": "$12,885",
            "Cost per Result": "$13.03"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "228897",
            "Clicks": "4258",
            "Conversions": "72",
            "Conversion Value": "$10,317",
            "Cost per Result": "$11.55"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "486813",
            "Clicks": "3619",
            "Conversions": "60",
            "Conversion Value": "$5,713",
            "Cost per Result": "$13.62"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "212219",
            "Clicks": "2485",
            "Conversions": "90",
            "Conversion Value": "$6,310",
            "Cost per Result": "$14.42"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "336417",
            "Clicks": "6130",
            "Conversions": "78",
            "Conversion Value": "$13,973",
            "Cost per Result": "$13.02"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "370295",
            "Clicks": "5766",
            "Conversions": "65",
            "Conversion Value": "$13,958",
            "Cost per Result": "$10.86"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "169280",
            "Clicks": "6796",
            "Conversions": "84",
            "Conversion Value": "$8,637",
            "Cost per Result": "$7.98"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "379632",
            "Clicks": "5659",
            "Conversions": "61",
            "Conversion Value": "$11,610",
            "Cost per Result": "$12.18"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "331871",
            "Clicks": "2333",
            "Conversions": "53",
            "Conversion Value": "$5,166",
            "Cost per Result": "$6.13"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "422113",
            "Clicks": "4707",
            "Conversions": "51",
            "Conversion Value": "$13,512",
            "Cost per Result": "$6.18"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "198012",
            "Clicks": "4963",
            "Conversions": "45",
            "Conversion Value": "$7,967",
            "Cost per Result": "$9.89"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "187029",
            "Clicks": "3987",
            "Conversions": "69",
            "Conversion Value": "$4,393",
            "Cost per Result": "$12.61"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "192014",
            "Clicks": "2782",
            "Conversions": "65",
            "Conversion Value": "$12,174",
            "Cost per Result": "$7.02"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "172166",
            "Clicks": "6076",
            "Conversions": "69",
            "Conversion Value": "$11,236",
            "Cost per Result": "$6.07"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "436617",
            "Clicks": "4784",
            "Conversions": "78",
            "Conversion Value": "$12,789",
            "Cost per Result": "$5.31"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "105657",
            "Clicks": "5156",
            "Conversions": "73",
            "Conversion Value": "$5,988",
            "Cost per Result": "$5.28"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "196545",
            "Clicks": "6965",
            "Conversions": "89",
            "Conversion Value": "$7,859",
            "Cost per Result": "$14.13"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "257741",
            "Clicks": "2384",
            "Conversions": "60",
            "Conversion Value": "$6,382",
            "Cost per Result": "$12.59"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "134352",
            "Clicks": "3014",
            "Conversions": "89",
            "Conversion Value": "$14,223",
            "Cost per Result": "$14.35"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "286067",
            "Clicks": "2475",
            "Conversions": "50",
            "Conversion Value": "$11,360",
            "Cost per Result": "$13.31"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "217014",
            "Clicks": "3566",
            "Conversions": "77",
            "Conversion Value": "$6,122",
            "Cost per Result": "$14.20"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "315231",
            "Clicks": "2481",
            "Conversions": "56",
            "Conversion Value": "$7,093",
            "Cost per Result": "$10.63"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "292617",
            "Clicks": "3555",
            "Conversions": "72",
            "Conversion Value": "$12,007",
            "Cost per Result": "$7.41"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "226790",
            "Clicks": "4954",
            "Conversions": "70",
            "Conversion Value": "$13,749",
            "Cost per Result": "$5.56"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "310968",
            "Clicks": "3605",
            "Conversions": "81",
            "Conversion Value": "$8,468",
            "Cost per Result": "$5.21"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "147937",
            "Clicks": "4060",
            "Conversions": "66",
            "Conversion Value": "$13,892",
            "Cost per Result": "$5.90"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "235612",
            "Clicks": "4633",
            "Conversions": "83",
            "Conversion Value": "$8,874",
            "Cost per Result": "$11.81"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "173950",
            "Clicks": "4624",
            "Conversions": "66",
            "Conversion Value": "$9,194",
            "Cost per Result": "$13.80"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "264734",
            "Clicks": "2839",
            "Conversions": "64",
            "Conversion Value": "$8,077",
            "Cost per Result": "$6.47"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "221673",
            "Clicks": "2706",
            "Conversions": "58",
            "Conversion Value": "$14,746",
            "Cost per Result": "$11.96"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "352726",
            "Clicks": "5503",
            "Conversions": "40",
            "Conversion Value": "$4,710",
            "Cost per Result": "$12.69"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "149923",
            "Clicks": "2845",
            "Conversions": "72",
            "Conversion Value": "$12,052",
            "Cost per Result": "$10.47"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "142428",
            "Clicks": "6422",
            "Conversions": "87",
            "Conversion Value": "$6,294",
            "Cost per Result": "$6.94"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "140358",
            "Clicks": "2013",
            "Conversions": "88",
            "Conversion Value": "$8,376",
            "Cost per Result": "$12.24"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "350884",
            "Clicks": "3508",
            "Conversions": "84",
            "Conversion Value": "$7,596",
            "Cost per Result": "$8.39"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "232976",
            "Clicks": "5001",
            "Conversions": "61",
            "Conversion Value": "$13,745",
            "Cost per Result": "$8.56"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "300711",
            "Clicks": "6863",
            "Conversions": "46",
            "Conversion Value": "$15,471",
            "Cost per Result": "$12.87"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "158918",
            "Clicks": "4290",
            "Conversions": "83",
            "Conversion Value": "$9,113",
            "Cost per Result": "$9.33"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "288305",
            "Clicks": "3279",
            "Conversions": "77",
            "Conversion Value": "$14,699",
            "Cost per Result": "$6.91"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "496154",
            "Clicks": "6528",
            "Conversions": "66",
            "Conversion Value": "$8,026",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "493296",
            "Clicks": "6448",
            "Conversions": "85",
            "Conversion Value": "$9,951",
            "Cost per Result": "$13.61"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "458326",
            "Clicks": "4648",
            "Conversions": "83",
            "Conversion Value": "$15,028",
            "Cost per Result": "$9.52"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "156817",
            "Clicks": "5353",
            "Conversions": "43",
            "Conversion Value": "$6,846",
            "Cost per Result": "$11.21"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "462497",
            "Clicks": "2505",
            "Conversions": "48",
            "Conversion Value": "$14,943",
            "Cost per Result": "$5.44"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "416128",
            "Clicks": "2412",
            "Conversions": "87",
            "Conversion Value": "$7,744",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "237759",
            "Clicks": "2805",
            "Conversions": "68",
            "Conversion Value": "$5,424",
            "Cost per Result": "$7.92"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "438099",
            "Clicks": "6526",
            "Conversions": "83",
            "Conversion Value": "$6,267",
            "Cost per Result": "$14.24"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "445031",
            "Clicks": "3059",
            "Conversions": "73",
            "Conversion Value": "$14,436",
            "Cost per Result": "$12.65"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "333652",
            "Clicks": "2319",
            "Conversions": "51",
            "Conversion Value": "$7,051",
            "Cost per Result": "$13.68"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "471373",
            "Clicks": "6777",
            "Conversions": "48",
            "Conversion Value": "$6,005",
            "Cost per Result": "$6.08"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "479879",
            "Clicks": "3799",
            "Conversions": "57",
            "Conversion Value": "$11,551",
            "Cost per Result": "$10.66"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "313750",
            "Clicks": "2722",
            "Conversions": "41",
            "Conversion Value": "$5,221",
            "Cost per Result": "$7.88"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "278483",
            "Clicks": "3300",
            "Conversions": "77",
            "Conversion Value": "$8,296",
            "Cost per Result": "$5.56"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "432292",
            "Clicks": "5720",
            "Conversions": "77",
            "Conversion Value": "$12,075",
            "Cost per Result": "$11.27"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "121378",
            "Clicks": "2866",
            "Conversions": "45",
            "Conversion Value": "$11,306",
            "Cost per Result": "$11.12"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "268192",
            "Clicks": "3495",
            "Conversions": "76",
            "Conversion Value": "$15,048",
            "Cost per Result": "$12.17"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "131316",
            "Clicks": "2029",
            "Conversions": "50",
            "Conversion Value": "$11,697",
            "Cost per Result": "$13.25"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "404011",
            "Clicks": "5611",
            "Conversions": "51",
            "Conversion Value": "$4,155",
            "Cost per Result": "$14.41"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "342954",
            "Clicks": "5703",
            "Conversions": "55",
            "Conversion Value": "$4,328",
            "Cost per Result": "$6.78"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "292996",
            "Clicks": "3766",
            "Conversions": "76",
            "Conversion Value": "$4,900",
            "Cost per Result": "$11.50"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "264796",
            "Clicks": "2579",
            "Conversions": "66",
            "Conversion Value": "$12,657",
            "Cost per Result": "$11.14"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "179451",
            "Clicks": "6088",
            "Conversions": "73",
            "Conversion Value": "$10,203",
            "Cost per Result": "$11.30"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "423086",
            "Clicks": "4995",
            "Conversions": "84",
            "Conversion Value": "$15,234",
            "Cost per Result": "$6.15"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "135185",
            "Clicks": "3422",
            "Conversions": "83",
            "Conversion Value": "$15,955",
            "Cost per Result": "$6.70"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "174568",
            "Clicks": "6580",
            "Conversions": "60",
            "Conversion Value": "$10,362",
            "Cost per Result": "$13.90"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "190506",
            "Clicks": "4752",
            "Conversions": "51",
            "Conversion Value": "$4,322",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "156616",
            "Clicks": "5781",
            "Conversions": "88",
            "Conversion Value": "$10,611",
            "Cost per Result": "$13.88"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "265734",
            "Clicks": "4493",
            "Conversions": "52",
            "Conversion Value": "$8,245",
            "Cost per Result": "$5.73"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "222672",
            "Clicks": "4426",
            "Conversions": "64",
            "Conversion Value": "$6,991",
            "Cost per Result": "$5.41"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "215991",
            "Clicks": "6468",
            "Conversions": "69",
            "Conversion Value": "$7,490",
            "Cost per Result": "$6.93"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "121284",
            "Clicks": "6928",
            "Conversions": "70",
            "Conversion Value": "$15,649",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "374299",
            "Clicks": "6359",
            "Conversions": "76",
            "Conversion Value": "$6,062",
            "Cost per Result": "$9.97"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "482879",
            "Clicks": "2654",
            "Conversions": "76",
            "Conversion Value": "$13,793",
            "Cost per Result": "$6.01"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "383495",
            "Clicks": "4697",
            "Conversions": "53",
            "Conversion Value": "$7,247",
            "Cost per Result": "$8.82"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "375342",
            "Clicks": "6795",
            "Conversions": "59",
            "Conversion Value": "$9,027",
            "Cost per Result": "$9.94"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "294846",
            "Clicks": "5154",
            "Conversions": "53",
            "Conversion Value": "$4,950",
            "Cost per Result": "$13.59"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "304538",
            "Clicks": "5950",
            "Conversions": "63",
            "Conversion Value": "$4,045",
            "Cost per Result": "$8.66"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "210687",
            "Clicks": "4722",
            "Conversions": "77",
            "Conversion Value": "$12,652",
            "Cost per Result": "$5.24"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "327233",
            "Clicks": "3710",
            "Conversions": "90",
            "Conversion Value": "$10,529",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "471209",
            "Clicks": "3250",
            "Conversions": "81",
            "Conversion Value": "$13,003",
            "Cost per Result": "$8.46"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "200972",
            "Clicks": "2702",
            "Conversions": "46",
            "Conversion Value": "$5,529",
            "Cost per Result": "$11.07"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "124244",
            "Clicks": "4668",
            "Conversions": "82",
            "Conversion Value": "$8,027",
            "Cost per Result": "$5.43"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "239015",
            "Clicks": "2694",
            "Conversions": "81",
            "Conversion Value": "$15,393",
            "Cost per Result": "$10.01"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "345453",
            "Clicks": "6847",
            "Conversions": "56",
            "Conversion Value": "$6,776",
            "Cost per Result": "$5.25"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "420354",
            "Clicks": "5451",
            "Conversions": "59",
            "Conversion Value": "$14,643",
            "Cost per Result": "$14.10"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "249603",
            "Clicks": "4625",
            "Conversions": "61",
            "Conversion Value": "$8,105",
            "Cost per Result": "$11.31"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "308400",
            "Clicks": "5188",
            "Conversions": "82",
            "Conversion Value": "$11,861",
            "Cost per Result": "$13.60"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "188254",
            "Clicks": "2531",
            "Conversions": "56",
            "Conversion Value": "$11,677",
            "Cost per Result": "$14.84"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "471527",
            "Clicks": "2046",
            "Conversions": "75",
            "Conversion Value": "$12,652",
            "Cost per Result": "$8.73"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "482367",
            "Clicks": "6781",
            "Conversions": "86",
            "Conversion Value": "$12,123",
            "Cost per Result": "$14.34"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "120024",
            "Clicks": "3444",
            "Conversions": "84",
            "Conversion Value": "$11,689",
            "Cost per Result": "$5.03"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "489312",
            "Clicks": "2367",
            "Conversions": "79",
            "Conversion Value": "$12,077",
            "Cost per Result": "$13.74"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "154123",
            "Clicks": "4859",
            "Conversions": "66",
            "Conversion Value": "$7,836",
            "Cost per Result": "$12.95"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "332215",
            "Clicks": "5424",
            "Conversions": "68",
            "Conversion Value": "$13,315",
            "Cost per Result": "$5.67"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "264714",
            "Clicks": "5609",
            "Conversions": "67",
            "Conversion Value": "$15,551",
            "Cost per Result": "$11.37"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "287159",
            "Clicks": "6474",
            "Conversions": "90",
            "Conversion Value": "$9,697",
            "Cost per Result": "$10.86"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "481819",
            "Clicks": "3910",
            "Conversions": "86",
            "Conversion Value": "$11,372",
            "Cost per Result": "$7.36"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "372811",
            "Clicks": "4855",
            "Conversions": "90",
            "Conversion Value": "$10,896",
            "Cost per Result": "$9.55"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "296258",
            "Clicks": "6446",
            "Conversions": "81",
            "Conversion Value": "$6,367",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "141562",
            "Clicks": "2300",
            "Conversions": "46",
            "Conversion Value": "$11,946",
            "Cost per Result": "$13.10"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "289508",
            "Clicks": "5372",
            "Conversions": "47",
            "Conversion Value": "$11,840",
            "Cost per Result": "$13.15"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "136213",
            "Clicks": "5770",
            "Conversions": "45",
            "Conversion Value": "$10,277",
            "Cost per Result": "$10.64"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "128381",
            "Clicks": "2167",
            "Conversions": "58",
            "Conversion Value": "$5,467",
            "Cost per Result": "$10.62"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "438006",
            "Clicks": "5796",
            "Conversions": "84",
            "Conversion Value": "$12,926",
            "Cost per Result": "$11.78"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "479634",
            "Clicks": "4480",
            "Conversions": "89",
            "Conversion Value": "$7,071",
            "Cost per Result": "$8.92"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "357932",
            "Clicks": "6384",
            "Conversions": "81",
            "Conversion Value": "$7,673",
            "Cost per Result": "$7.99"
          }
        ]
      }
    },
    "web3GamingCasinos": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "808857",
            "Clicks": "136098",
            "Sign Ups": "2149",
            "Deposits": "899",
            "Cost per Sign Up": "$28.72",
            "Cost per Deposit)": "$50.07"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1035165",
            "Clicks": "128095",
            "Sign Ups": "2090",
            "Deposits": "927",
            "Cost per Sign Up": "$20.95",
            "Cost per Deposit)": "$39.19"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "974511",
            "Clicks": "146286",
            "Sign Ups": "1839",
            "Deposits": "992",
            "Cost per Sign Up": "$25.31",
            "Cost per Deposit)": "$49.41"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "1006087",
            "Clicks": "111531",
            "Sign Ups": "2024",
            "Deposits": "979",
            "Cost per Sign Up": "$22.83",
            "Cost per Deposit)": "$41.22"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "999100",
            "Clicks": "132727",
            "Sign Ups": "2002",
            "Deposits": "952",
            "Cost per Sign Up": "$26.46",
            "Cost per Deposit)": "$47.87"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1003086",
            "Clicks": "137920",
            "Sign Ups": "1897",
            "Deposits": "1068",
            "Cost per Sign Up": "$26.45",
            "Cost per Deposit)": "$49.56"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "1027958",
            "Clicks": "141545",
            "Sign Ups": "1941",
            "Deposits": "920",
            "Cost per Sign Up": "$24.32",
            "Cost per Deposit)": "$49.18"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "991143",
            "Clicks": "127116",
            "Sign Ups": "1952",
            "Deposits": "921",
            "Cost per Sign Up": "$26.71",
            "Cost per Deposit)": "$39.67"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1007095",
            "Clicks": "135931",
            "Sign Ups": "2012",
            "Deposits": "944",
            "Cost per Sign Up": "$25.97",
            "Cost per Deposit)": "$45.55"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "1034283",
            "Clicks": "139490",
            "Sign Ups": "1896",
            "Deposits": "973",
            "Cost per Sign Up": "$23.77",
            "Cost per Deposit)": "$45.70"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "992912",
            "Clicks": "118429",
            "Sign Ups": "2104",
            "Deposits": "943",
            "Cost per Sign Up": "$23.57",
            "Cost per Deposit)": "$41.77"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1015065",
            "Clicks": "115504",
            "Sign Ups": "1991",
            "Deposits": "964",
            "Cost per Sign Up": "$26.28",
            "Cost per Deposit)": "$49.95"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "1057537",
            "Clicks": "135626",
            "Sign Ups": "2044",
            "Deposits": "950",
            "Cost per Sign Up": "$27.23",
            "Cost per Deposit)": "$48.02"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "1070996",
            "Clicks": "139096",
            "Sign Ups": "1755",
            "Deposits": "961",
            "Cost per Sign Up": "$22.92",
            "Cost per Deposit)": "$37.92"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "891863",
            "Clicks": "138487",
            "Sign Ups": "2303",
            "Deposits": "860",
            "Cost per Sign Up": "$23.63",
            "Cost per Deposit)": "$42.35"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "1062624",
            "Clicks": "130045",
            "Sign Ups": "1852",
            "Deposits": "1084",
            "Cost per Sign Up": "$19.27",
            "Cost per Deposit)": "$44.43"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "916005",
            "Clicks": "137639",
            "Sign Ups": "2156",
            "Deposits": "904",
            "Cost per Sign Up": "$18.04",
            "Cost per Deposit)": "$54.93"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "987479",
            "Clicks": "119660",
            "Sign Ups": "1977",
            "Deposits": "973",
            "Cost per Sign Up": "$21.40",
            "Cost per Deposit)": "$45.50"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "950738",
            "Clicks": "134397",
            "Sign Ups": "1843",
            "Deposits": "968",
            "Cost per Sign Up": "$27.19",
            "Cost per Deposit)": "$38.50"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1083609",
            "Clicks": "160134",
            "Sign Ups": "1856",
            "Deposits": "928",
            "Cost per Sign Up": "$22.72",
            "Cost per Deposit)": "$41.34"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "924420",
            "Clicks": "140533",
            "Sign Ups": "1950",
            "Deposits": "904",
            "Cost per Sign Up": "$25.64",
            "Cost per Deposit)": "$45.65"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "968770",
            "Clicks": "135312",
            "Sign Ups": "1961",
            "Deposits": "1170",
            "Cost per Sign Up": "$22.82",
            "Cost per Deposit)": "$39.58"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "994197",
            "Clicks": "128777",
            "Sign Ups": "2029",
            "Deposits": "954",
            "Cost per Sign Up": "$29.37",
            "Cost per Deposit)": "$48.93"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1059593",
            "Clicks": "136304",
            "Sign Ups": "2069",
            "Deposits": "1067",
            "Cost per Sign Up": "$24.83",
            "Cost per Deposit)": "$42.79"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "990692",
            "Clicks": "138545",
            "Sign Ups": "1732",
            "Deposits": "984",
            "Cost per Sign Up": "$27.17",
            "Cost per Deposit)": "$42.38"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "962885",
            "Clicks": "132851",
            "Sign Ups": "2106",
            "Deposits": "1016",
            "Cost per Sign Up": "$23.24",
            "Cost per Deposit)": "$45.25"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "1059309",
            "Clicks": "140297",
            "Sign Ups": "1925",
            "Deposits": "1021",
            "Cost per Sign Up": "$21.54",
            "Cost per Deposit)": "$42.41"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "1083383",
            "Clicks": "142072",
            "Sign Ups": "1968",
            "Deposits": "955",
            "Cost per Sign Up": "$22.16",
            "Cost per Deposit)": "$51.40"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "982475",
            "Clicks": "145290",
            "Sign Ups": "2102",
            "Deposits": "1013",
            "Cost per Sign Up": "$28.54",
            "Cost per Deposit)": "$45.98"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1013242",
            "Clicks": "157197",
            "Sign Ups": "1897",
            "Deposits": "862",
            "Cost per Sign Up": "$32.62",
            "Cost per Deposit)": "$39.46"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "984550",
            "Clicks": "129898",
            "Sign Ups": "2091",
            "Deposits": "956",
            "Cost per Sign Up": "$23.52",
            "Cost per Deposit)": "$48.87"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1004729",
            "Clicks": "125840",
            "Sign Ups": "1660",
            "Deposits": "914",
            "Cost per Sign Up": "$27.78",
            "Cost per Deposit)": "$48.75"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "949546",
            "Clicks": "159644",
            "Sign Ups": "2049",
            "Deposits": "1036",
            "Cost per Sign Up": "$18.97",
            "Cost per Deposit)": "$47.99"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "994473",
            "Clicks": "111582",
            "Sign Ups": "1938",
            "Deposits": "977",
            "Cost per Sign Up": "$25.02",
            "Cost per Deposit)": "$51.27"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "931620",
            "Clicks": "138871",
            "Sign Ups": "1916",
            "Deposits": "980",
            "Cost per Sign Up": "$22.17",
            "Cost per Deposit)": "$43.81"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "962805",
            "Clicks": "118463",
            "Sign Ups": "1931",
            "Deposits": "991",
            "Cost per Sign Up": "$19.76",
            "Cost per Deposit)": "$46.53"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1005374",
            "Clicks": "153681",
            "Sign Ups": "1654",
            "Deposits": "912",
            "Cost per Sign Up": "$26.15",
            "Cost per Deposit)": "$41.63"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "962321",
            "Clicks": "131248",
            "Sign Ups": "1970",
            "Deposits": "947",
            "Cost per Sign Up": "$19.16",
            "Cost per Deposit)": "$46.31"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "989013",
            "Clicks": "140883",
            "Sign Ups": "1668",
            "Deposits": "965",
            "Cost per Sign Up": "$25.53",
            "Cost per Deposit)": "$44.56"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "984118",
            "Clicks": "133055",
            "Sign Ups": "1847",
            "Deposits": "851",
            "Cost per Sign Up": "$24.50",
            "Cost per Deposit)": "$35.65"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "965660",
            "Clicks": "132479",
            "Sign Ups": "1911",
            "Deposits": "873",
            "Cost per Sign Up": "$23.77",
            "Cost per Deposit)": "$44.02"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "967028",
            "Clicks": "151179",
            "Sign Ups": "1990",
            "Deposits": "968",
            "Cost per Sign Up": "$24.18",
            "Cost per Deposit)": "$50.03"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "979242",
            "Clicks": "127128",
            "Sign Ups": "1843",
            "Deposits": "1052",
            "Cost per Sign Up": "$26.83",
            "Cost per Deposit)": "$46.26"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1003910",
            "Clicks": "153895",
            "Sign Ups": "2032",
            "Deposits": "990",
            "Cost per Sign Up": "$19.96",
            "Cost per Deposit)": "$46.24"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "970097",
            "Clicks": "127162",
            "Sign Ups": "2067",
            "Deposits": "988",
            "Cost per Sign Up": "$23.03",
            "Cost per Deposit)": "$38.48"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "939769",
            "Clicks": "139512",
            "Sign Ups": "1817",
            "Deposits": "821",
            "Cost per Sign Up": "$21.22",
            "Cost per Deposit)": "$48.02"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "945675",
            "Clicks": "147335",
            "Sign Ups": "1861",
            "Deposits": "900",
            "Cost per Sign Up": "$22.80",
            "Cost per Deposit)": "$43.76"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1019829",
            "Clicks": "155556",
            "Sign Ups": "1891",
            "Deposits": "1000",
            "Cost per Sign Up": "$26.49",
            "Cost per Deposit)": "$38.34"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1043315",
            "Clicks": "115610",
            "Sign Ups": "2021",
            "Deposits": "987",
            "Cost per Sign Up": "$22.11",
            "Cost per Deposit)": "$55.62"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "902904",
            "Clicks": "138282",
            "Sign Ups": "1974",
            "Deposits": "941",
            "Cost per Sign Up": "$24.89",
            "Cost per Deposit)": "$45.45"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1120256",
            "Clicks": "124412",
            "Sign Ups": "2331",
            "Deposits": "982",
            "Cost per Sign Up": "$24.98",
            "Cost per Deposit)": "$37.03"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "908486",
            "Clicks": "136694",
            "Sign Ups": "1970",
            "Deposits": "974",
            "Cost per Sign Up": "$24.36",
            "Cost per Deposit)": "$44.15"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "991980",
            "Clicks": "139996",
            "Sign Ups": "1977",
            "Deposits": "1034",
            "Cost per Sign Up": "$23.29",
            "Cost per Deposit)": "$45.70"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "927388",
            "Clicks": "147204",
            "Sign Ups": "1926",
            "Deposits": "976",
            "Cost per Sign Up": "$21.70",
            "Cost per Deposit)": "$44.44"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "1070059",
            "Clicks": "127318",
            "Sign Ups": "2010",
            "Deposits": "889",
            "Cost per Sign Up": "$21.63",
            "Cost per Deposit)": "$49.59"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "938745",
            "Clicks": "150317",
            "Sign Ups": "2013",
            "Deposits": "944",
            "Cost per Sign Up": "$23.23",
            "Cost per Deposit)": "$37.50"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "997231",
            "Clicks": "151421",
            "Sign Ups": "1864",
            "Deposits": "875",
            "Cost per Sign Up": "$21.82",
            "Cost per Deposit)": "$38.61"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "994436",
            "Clicks": "134476",
            "Sign Ups": "1927",
            "Deposits": "982",
            "Cost per Sign Up": "$20.39",
            "Cost per Deposit)": "$46.75"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "978437",
            "Clicks": "139908",
            "Sign Ups": "2101",
            "Deposits": "971",
            "Cost per Sign Up": "$24.52",
            "Cost per Deposit)": "$48.51"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1019958",
            "Clicks": "128848",
            "Sign Ups": "2098",
            "Deposits": "907",
            "Cost per Sign Up": "$27.89",
            "Cost per Deposit)": "$51.94"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "955089",
            "Clicks": "119834",
            "Sign Ups": "1942",
            "Deposits": "971",
            "Cost per Sign Up": "$25.41",
            "Cost per Deposit)": "$43.60"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "993631",
            "Clicks": "150738",
            "Sign Ups": "2063",
            "Deposits": "904",
            "Cost per Sign Up": "$19.03",
            "Cost per Deposit)": "$40.59"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "955596",
            "Clicks": "143327",
            "Sign Ups": "1865",
            "Deposits": "943",
            "Cost per Sign Up": "$22.74",
            "Cost per Deposit)": "$43.29"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "959064",
            "Clicks": "122769",
            "Sign Ups": "1932",
            "Deposits": "950",
            "Cost per Sign Up": "$27.68",
            "Cost per Deposit)": "$34.96"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "972249",
            "Clicks": "131641",
            "Sign Ups": "1837",
            "Deposits": "950",
            "Cost per Sign Up": "$20.01",
            "Cost per Deposit)": "$37.08"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1085916",
            "Clicks": "138651",
            "Sign Ups": "1775",
            "Deposits": "1038",
            "Cost per Sign Up": "$25.52",
            "Cost per Deposit)": "$41.89"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1003851",
            "Clicks": "115990",
            "Sign Ups": "1827",
            "Deposits": "1098",
            "Cost per Sign Up": "$21.17",
            "Cost per Deposit)": "$37.22"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1067346",
            "Clicks": "126249",
            "Sign Ups": "2030",
            "Deposits": "1020",
            "Cost per Sign Up": "$20.48",
            "Cost per Deposit)": "$46.38"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1040598",
            "Clicks": "138391",
            "Sign Ups": "2060",
            "Deposits": "1025",
            "Cost per Sign Up": "$24.45",
            "Cost per Deposit)": "$34.71"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1067442",
            "Clicks": "145964",
            "Sign Ups": "1881",
            "Deposits": "888",
            "Cost per Sign Up": "$25.66",
            "Cost per Deposit)": "$51.51"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "934351",
            "Clicks": "132997",
            "Sign Ups": "1938",
            "Deposits": "1078",
            "Cost per Sign Up": "$21.59",
            "Cost per Deposit)": "$47.57"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "945838",
            "Clicks": "126783",
            "Sign Ups": "1777",
            "Deposits": "975",
            "Cost per Sign Up": "$25.60",
            "Cost per Deposit)": "$47.95"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "891051",
            "Clicks": "137406",
            "Sign Ups": "1792",
            "Deposits": "996",
            "Cost per Sign Up": "$25.69",
            "Cost per Deposit)": "$45.72"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "939066",
            "Clicks": "124239",
            "Sign Ups": "2100",
            "Deposits": "1015",
            "Cost per Sign Up": "$21.89",
            "Cost per Deposit)": "$52.71"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1004963",
            "Clicks": "146397",
            "Sign Ups": "1934",
            "Deposits": "885",
            "Cost per Sign Up": "$18.74",
            "Cost per Deposit)": "$44.27"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "947760",
            "Clicks": "138562",
            "Sign Ups": "2081",
            "Deposits": "1034",
            "Cost per Sign Up": "$23.72",
            "Cost per Deposit)": "$49.88"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1015788",
            "Clicks": "139191",
            "Sign Ups": "1921",
            "Deposits": "1029",
            "Cost per Sign Up": "$25.51",
            "Cost per Deposit)": "$34.13"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1016314",
            "Clicks": "134069",
            "Sign Ups": "1972",
            "Deposits": "1091",
            "Cost per Sign Up": "$23.70",
            "Cost per Deposit)": "$39.13"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "983075",
            "Clicks": "143832",
            "Sign Ups": "2036",
            "Deposits": "896",
            "Cost per Sign Up": "$22.91",
            "Cost per Deposit)": "$48.53"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1007024",
            "Clicks": "136105",
            "Sign Ups": "1623",
            "Deposits": "1038",
            "Cost per Sign Up": "$26.80",
            "Cost per Deposit)": "$38.68"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "1037633",
            "Clicks": "137125",
            "Sign Ups": "1643",
            "Deposits": "920",
            "Cost per Sign Up": "$17.95",
            "Cost per Deposit)": "$40.60"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "975891",
            "Clicks": "129974",
            "Sign Ups": "2026",
            "Deposits": "909",
            "Cost per Sign Up": "$20.63",
            "Cost per Deposit)": "$44.41"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "972449",
            "Clicks": "135610",
            "Sign Ups": "1868",
            "Deposits": "1105",
            "Cost per Sign Up": "$17.46",
            "Cost per Deposit)": "$43.08"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "973819",
            "Clicks": "125460",
            "Sign Ups": "1844",
            "Deposits": "886",
            "Cost per Sign Up": "$21.42",
            "Cost per Deposit)": "$47.20"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "966392",
            "Clicks": "142037",
            "Sign Ups": "2004",
            "Deposits": "937",
            "Cost per Sign Up": "$17.69",
            "Cost per Deposit)": "$48.89"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1067419",
            "Clicks": "149968",
            "Sign Ups": "1898",
            "Deposits": "1065",
            "Cost per Sign Up": "$23.92",
            "Cost per Deposit)": "$46.57"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "999830",
            "Clicks": "114524",
            "Sign Ups": "2016",
            "Deposits": "922",
            "Cost per Sign Up": "$27.70",
            "Cost per Deposit)": "$46.62"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1011364",
            "Clicks": "145219",
            "Sign Ups": "2015",
            "Deposits": "995",
            "Cost per Sign Up": "$21.21",
            "Cost per Deposit)": "$44.77"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "904132",
            "Clicks": "138037",
            "Sign Ups": "1936",
            "Deposits": "1040",
            "Cost per Sign Up": "$20.57",
            "Cost per Deposit)": "$47.54"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1122807",
            "Clicks": "145276",
            "Sign Ups": "2064",
            "Deposits": "976",
            "Cost per Sign Up": "$29.77",
            "Cost per Deposit)": "$39.57"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "964898",
            "Clicks": "143475",
            "Sign Ups": "2162",
            "Deposits": "1002",
            "Cost per Sign Up": "$26.65",
            "Cost per Deposit)": "$50.73"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1042393",
            "Clicks": "143503",
            "Sign Ups": "1917",
            "Deposits": "922",
            "Cost per Sign Up": "$25.33",
            "Cost per Deposit)": "$52.97"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "974849",
            "Clicks": "141221",
            "Sign Ups": "1869",
            "Deposits": "881",
            "Cost per Sign Up": "$24.20",
            "Cost per Deposit)": "$49.67"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1010728",
            "Clicks": "140594",
            "Sign Ups": "2009",
            "Deposits": "885",
            "Cost per Sign Up": "$19.97",
            "Cost per Deposit)": "$51.01"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1106270",
            "Clicks": "143195",
            "Sign Ups": "2196",
            "Deposits": "1089",
            "Cost per Sign Up": "$23.82",
            "Cost per Deposit)": "$35.44"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1029591",
            "Clicks": "130151",
            "Sign Ups": "1741",
            "Deposits": "933",
            "Cost per Sign Up": "$22.61",
            "Cost per Deposit)": "$42.62"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1048729",
            "Clicks": "154732",
            "Sign Ups": "2031",
            "Deposits": "910",
            "Cost per Sign Up": "$26.11",
            "Cost per Deposit)": "$44.07"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "999985",
            "Clicks": "138921",
            "Sign Ups": "2039",
            "Deposits": "906",
            "Cost per Sign Up": "$23.02",
            "Cost per Deposit)": "$50.30"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1076496",
            "Clicks": "146428",
            "Sign Ups": "1801",
            "Deposits": "901",
            "Cost per Sign Up": "$24.12",
            "Cost per Deposit)": "$51.17"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "931087",
            "Clicks": "150371",
            "Sign Ups": "2036",
            "Deposits": "930",
            "Cost per Sign Up": "$19.74",
            "Cost per Deposit)": "$40.03"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "867210",
            "Clicks": "137062",
            "Sign Ups": "2249",
            "Deposits": "913",
            "Cost per Sign Up": "$19.55",
            "Cost per Deposit)": "$33.95"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "853039",
            "Clicks": "127979",
            "Sign Ups": "1792",
            "Deposits": "931",
            "Cost per Sign Up": "$22.26",
            "Cost per Deposit)": "$43.30"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "960789",
            "Clicks": "165112",
            "Sign Ups": "1905",
            "Deposits": "1032",
            "Cost per Sign Up": "$20.42",
            "Cost per Deposit)": "$47.83"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "1070306",
            "Clicks": "137239",
            "Sign Ups": "2045",
            "Deposits": "967",
            "Cost per Sign Up": "$24.91",
            "Cost per Deposit)": "$35.07"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "972194",
            "Clicks": "115466",
            "Sign Ups": "1847",
            "Deposits": "891",
            "Cost per Sign Up": "$25.50",
            "Cost per Deposit)": "$43.51"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1009807",
            "Clicks": "152113",
            "Sign Ups": "2089",
            "Deposits": "917",
            "Cost per Sign Up": "$22.19",
            "Cost per Deposit)": "$44.34"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1096883",
            "Clicks": "144326",
            "Sign Ups": "1886",
            "Deposits": "976",
            "Cost per Sign Up": "$26.93",
            "Cost per Deposit)": "$47.53"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "948870",
            "Clicks": "150771",
            "Sign Ups": "1963",
            "Deposits": "935",
            "Cost per Sign Up": "$21.37",
            "Cost per Deposit)": "$50.97"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1071310",
            "Clicks": "132848",
            "Sign Ups": "2012",
            "Deposits": "822",
            "Cost per Sign Up": "$27.66",
            "Cost per Deposit)": "$38.09"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "961829",
            "Clicks": "139488",
            "Sign Ups": "2103",
            "Deposits": "1081",
            "Cost per Sign Up": "$21.72",
            "Cost per Deposit)": "$49.27"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1007642",
            "Clicks": "130219",
            "Sign Ups": "1880",
            "Deposits": "923",
            "Cost per Sign Up": "$21.83",
            "Cost per Deposit)": "$50.84"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1091944",
            "Clicks": "145339",
            "Sign Ups": "2033",
            "Deposits": "912",
            "Cost per Sign Up": "$25.31",
            "Cost per Deposit)": "$49.85"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "940833",
            "Clicks": "149633",
            "Sign Ups": "2002",
            "Deposits": "1021",
            "Cost per Sign Up": "$21.24",
            "Cost per Deposit)": "$49.58"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "978292",
            "Clicks": "144602",
            "Sign Ups": "1864",
            "Deposits": "983",
            "Cost per Sign Up": "$21.32",
            "Cost per Deposit)": "$39.82"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "983360",
            "Clicks": "109287",
            "Sign Ups": "2133",
            "Deposits": "1010",
            "Cost per Sign Up": "$20.79",
            "Cost per Deposit)": "$46.02"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "977246",
            "Clicks": "145743",
            "Sign Ups": "1759",
            "Deposits": "971",
            "Cost per Sign Up": "$26.56",
            "Cost per Deposit)": "$48.48"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "938845",
            "Clicks": "146748",
            "Sign Ups": "1754",
            "Deposits": "932",
            "Cost per Sign Up": "$23.70",
            "Cost per Deposit)": "$34.74"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "937771",
            "Clicks": "115076",
            "Sign Ups": "2187",
            "Deposits": "858",
            "Cost per Sign Up": "$22.89",
            "Cost per Deposit)": "$33.59"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "877247",
            "Clicks": "128067",
            "Sign Ups": "2111",
            "Deposits": "1087",
            "Cost per Sign Up": "$16.46",
            "Cost per Deposit)": "$41.40"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "982257",
            "Clicks": "149525",
            "Sign Ups": "2092",
            "Deposits": "845",
            "Cost per Sign Up": "$24.19",
            "Cost per Deposit)": "$48.96"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "873406",
            "Clicks": "132120",
            "Sign Ups": "2118",
            "Deposits": "946",
            "Cost per Sign Up": "$25.04",
            "Cost per Deposit)": "$42.49"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "1083499",
            "Clicks": "129964",
            "Sign Ups": "1800",
            "Deposits": "847",
            "Cost per Sign Up": "$19.99",
            "Cost per Deposit)": "$36.74"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1035559",
            "Clicks": "150539",
            "Sign Ups": "1768",
            "Deposits": "1132",
            "Cost per Sign Up": "$24.38",
            "Cost per Deposit)": "$51.01"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "948388",
            "Clicks": "149594",
            "Sign Ups": "1653",
            "Deposits": "1029",
            "Cost per Sign Up": "$27.12",
            "Cost per Deposit)": "$42.80"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "1024076",
            "Clicks": "130754",
            "Sign Ups": "1785",
            "Deposits": "968",
            "Cost per Sign Up": "$25.00",
            "Cost per Deposit)": "$44.12"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1004929",
            "Clicks": "114023",
            "Sign Ups": "1990",
            "Deposits": "1010",
            "Cost per Sign Up": "$24.89",
            "Cost per Deposit)": "$40.40"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1082774",
            "Clicks": "125387",
            "Sign Ups": "2098",
            "Deposits": "1009",
            "Cost per Sign Up": "$21.44",
            "Cost per Deposit)": "$51.63"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "966705",
            "Clicks": "141749",
            "Sign Ups": "1910",
            "Deposits": "989",
            "Cost per Sign Up": "$21.15",
            "Cost per Deposit)": "$36.62"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "1069728",
            "Clicks": "142990",
            "Sign Ups": "1975",
            "Deposits": "990",
            "Cost per Sign Up": "$19.75",
            "Cost per Deposit)": "$34.30"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "984681",
            "Clicks": "153704",
            "Sign Ups": "1766",
            "Deposits": "1007",
            "Cost per Sign Up": "$22.22",
            "Cost per Deposit)": "$49.18"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1042747",
            "Clicks": "151627",
            "Sign Ups": "2048",
            "Deposits": "1002",
            "Cost per Sign Up": "$24.34",
            "Cost per Deposit)": "$44.65"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1006623",
            "Clicks": "141290",
            "Sign Ups": "2112",
            "Deposits": "1009",
            "Cost per Sign Up": "$18.57",
            "Cost per Deposit)": "$46.18"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "911721",
            "Clicks": "131244",
            "Sign Ups": "1820",
            "Deposits": "954",
            "Cost per Sign Up": "$25.53",
            "Cost per Deposit)": "$42.95"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1007435",
            "Clicks": "130932",
            "Sign Ups": "2026",
            "Deposits": "996",
            "Cost per Sign Up": "$21.29",
            "Cost per Deposit)": "$40.51"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "938209",
            "Clicks": "143987",
            "Sign Ups": "2009",
            "Deposits": "998",
            "Cost per Sign Up": "$29.84",
            "Cost per Deposit)": "$41.67"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1077146",
            "Clicks": "136490",
            "Sign Ups": "1834",
            "Deposits": "996",
            "Cost per Sign Up": "$22.12",
            "Cost per Deposit)": "$43.30"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "998564",
            "Clicks": "148193",
            "Sign Ups": "1755",
            "Deposits": "902",
            "Cost per Sign Up": "$21.53",
            "Cost per Deposit)": "$41.72"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1076242",
            "Clicks": "125274",
            "Sign Ups": "2057",
            "Deposits": "1037",
            "Cost per Sign Up": "$26.67",
            "Cost per Deposit)": "$38.81"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1143953",
            "Clicks": "154064",
            "Sign Ups": "1919",
            "Deposits": "1008",
            "Cost per Sign Up": "$28.83",
            "Cost per Deposit)": "$46.59"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "1039707",
            "Clicks": "139498",
            "Sign Ups": "1957",
            "Deposits": "940",
            "Cost per Sign Up": "$23.81",
            "Cost per Deposit)": "$44.88"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1006800",
            "Clicks": "148217",
            "Sign Ups": "1905",
            "Deposits": "1040",
            "Cost per Sign Up": "$27.69",
            "Cost per Deposit)": "$53.85"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "1031628",
            "Clicks": "128431",
            "Sign Ups": "1909",
            "Deposits": "865",
            "Cost per Sign Up": "$22.73",
            "Cost per Deposit)": "$42.02"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "903064",
            "Clicks": "139196",
            "Sign Ups": "1908",
            "Deposits": "863",
            "Cost per Sign Up": "$23.23",
            "Cost per Deposit)": "$44.79"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "992264",
            "Clicks": "126890",
            "Sign Ups": "1917",
            "Deposits": "1110",
            "Cost per Sign Up": "$28.39",
            "Cost per Deposit)": "$35.96"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "1092538",
            "Clicks": "124705",
            "Sign Ups": "1991",
            "Deposits": "1022",
            "Cost per Sign Up": "$20.31",
            "Cost per Deposit)": "$31.88"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1020272",
            "Clicks": "107688",
            "Sign Ups": "1805",
            "Deposits": "998",
            "Cost per Sign Up": "$19.61",
            "Cost per Deposit)": "$47.79"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1031009",
            "Clicks": "143736",
            "Sign Ups": "2291",
            "Deposits": "1077",
            "Cost per Sign Up": "$20.25",
            "Cost per Deposit)": "$42.81"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "966248",
            "Clicks": "118062",
            "Sign Ups": "2067",
            "Deposits": "967",
            "Cost per Sign Up": "$20.91",
            "Cost per Deposit)": "$43.04"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "928982",
            "Clicks": "132780",
            "Sign Ups": "1869",
            "Deposits": "997",
            "Cost per Sign Up": "$24.21",
            "Cost per Deposit)": "$39.66"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "912498",
            "Clicks": "136468",
            "Sign Ups": "1925",
            "Deposits": "979",
            "Cost per Sign Up": "$21.21",
            "Cost per Deposit)": "$36.69"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "898824",
            "Clicks": "136509",
            "Sign Ups": "1796",
            "Deposits": "954",
            "Cost per Sign Up": "$24.58",
            "Cost per Deposit)": "$48.25"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "951119",
            "Clicks": "134396",
            "Sign Ups": "1742",
            "Deposits": "930",
            "Cost per Sign Up": "$20.97",
            "Cost per Deposit)": "$42.94"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "1027631",
            "Clicks": "135898",
            "Sign Ups": "1910",
            "Deposits": "938",
            "Cost per Sign Up": "$26.10",
            "Cost per Deposit)": "$41.65"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "1021651",
            "Clicks": "130828",
            "Sign Ups": "1768",
            "Deposits": "1021",
            "Cost per Sign Up": "$28.03",
            "Cost per Deposit)": "$40.89"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "1034739",
            "Clicks": "139078",
            "Sign Ups": "2035",
            "Deposits": "966",
            "Cost per Sign Up": "$23.61",
            "Cost per Deposit)": "$56.71"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "925721",
            "Clicks": "131193",
            "Sign Ups": "2099",
            "Deposits": "988",
            "Cost per Sign Up": "$23.05",
            "Cost per Deposit)": "$43.01"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1054180",
            "Clicks": "134957",
            "Sign Ups": "1976",
            "Deposits": "978",
            "Cost per Sign Up": "$22.21",
            "Cost per Deposit)": "$38.09"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "923426",
            "Clicks": "125539",
            "Sign Ups": "1873",
            "Deposits": "904",
            "Cost per Sign Up": "$25.25",
            "Cost per Deposit)": "$42.97"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "922374",
            "Clicks": "141651",
            "Sign Ups": "1928",
            "Deposits": "904",
            "Cost per Sign Up": "$19.18",
            "Cost per Deposit)": "$40.80"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "941230",
            "Clicks": "132397",
            "Sign Ups": "1757",
            "Deposits": "980",
            "Cost per Sign Up": "$29.60",
            "Cost per Deposit)": "$44.28"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "985004",
            "Clicks": "140421",
            "Sign Ups": "1862",
            "Deposits": "991",
            "Cost per Sign Up": "$24.47",
            "Cost per Deposit)": "$53.48"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1079598",
            "Clicks": "132071",
            "Sign Ups": "2042",
            "Deposits": "1028",
            "Cost per Sign Up": "$22.56",
            "Cost per Deposit)": "$42.33"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "994373",
            "Clicks": "137458",
            "Sign Ups": "2036",
            "Deposits": "1083",
            "Cost per Sign Up": "$22.59",
            "Cost per Deposit)": "$44.02"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1047488",
            "Clicks": "140066",
            "Sign Ups": "1994",
            "Deposits": "987",
            "Cost per Sign Up": "$21.05",
            "Cost per Deposit)": "$45.52"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "995985",
            "Clicks": "126388",
            "Sign Ups": "2081",
            "Deposits": "917",
            "Cost per Sign Up": "$23.94",
            "Cost per Deposit)": "$48.68"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "928005",
            "Clicks": "148778",
            "Sign Ups": "1879",
            "Deposits": "1053",
            "Cost per Sign Up": "$26.39",
            "Cost per Deposit)": "$43.90"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "951483",
            "Clicks": "141758",
            "Sign Ups": "1925",
            "Deposits": "1050",
            "Cost per Sign Up": "$17.17",
            "Cost per Deposit)": "$45.97"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "882600",
            "Clicks": "141897",
            "Sign Ups": "2009",
            "Deposits": "1053",
            "Cost per Sign Up": "$24.53",
            "Cost per Deposit)": "$52.00"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "932726",
            "Clicks": "120481",
            "Sign Ups": "1935",
            "Deposits": "1074",
            "Cost per Sign Up": "$26.76",
            "Cost per Deposit)": "$40.92"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "919559",
            "Clicks": "125429",
            "Sign Ups": "1944",
            "Deposits": "1089",
            "Cost per Sign Up": "$26.22",
            "Cost per Deposit)": "$41.46"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1070418",
            "Clicks": "143708",
            "Sign Ups": "2091",
            "Deposits": "967",
            "Cost per Sign Up": "$21.60",
            "Cost per Deposit)": "$53.65"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "995165",
            "Clicks": "150154",
            "Sign Ups": "1829",
            "Deposits": "957",
            "Cost per Sign Up": "$28.09",
            "Cost per Deposit)": "$52.05"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1109643",
            "Clicks": "139989",
            "Sign Ups": "2126",
            "Deposits": "954",
            "Cost per Sign Up": "$24.52",
            "Cost per Deposit)": "$46.62"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1042990",
            "Clicks": "130260",
            "Sign Ups": "1806",
            "Deposits": "955",
            "Cost per Sign Up": "$21.07",
            "Cost per Deposit)": "$43.34"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "927549",
            "Clicks": "147866",
            "Sign Ups": "1980",
            "Deposits": "974",
            "Cost per Sign Up": "$20.18",
            "Cost per Deposit)": "$47.81"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "979076",
            "Clicks": "129681",
            "Sign Ups": "1967",
            "Deposits": "1026",
            "Cost per Sign Up": "$18.83",
            "Cost per Deposit)": "$43.38"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "925148",
            "Clicks": "147782",
            "Sign Ups": "1921",
            "Deposits": "985",
            "Cost per Sign Up": "$23.56",
            "Cost per Deposit)": "$39.32"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "839386",
            "Clicks": "122746",
            "Sign Ups": "2241",
            "Deposits": "946",
            "Cost per Sign Up": "$23.43",
            "Cost per Deposit)": "$38.46"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "961071",
            "Clicks": "152228",
            "Sign Ups": "1884",
            "Deposits": "969",
            "Cost per Sign Up": "$20.85",
            "Cost per Deposit)": "$47.12"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1112844",
            "Clicks": "136026",
            "Sign Ups": "1993",
            "Deposits": "1011",
            "Cost per Sign Up": "$25.41",
            "Cost per Deposit)": "$48.37"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1081193",
            "Clicks": "131143",
            "Sign Ups": "2111",
            "Deposits": "1034",
            "Cost per Sign Up": "$19.53",
            "Cost per Deposit)": "$34.61"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "931153",
            "Clicks": "124410",
            "Sign Ups": "2085",
            "Deposits": "948",
            "Cost per Sign Up": "$20.45",
            "Cost per Deposit)": "$39.54"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "950768",
            "Clicks": "131385",
            "Sign Ups": "1958",
            "Deposits": "970",
            "Cost per Sign Up": "$23.90",
            "Cost per Deposit)": "$47.65"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "958484",
            "Clicks": "142047",
            "Sign Ups": "2033",
            "Deposits": "1059",
            "Cost per Sign Up": "$24.52",
            "Cost per Deposit)": "$42.73"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1087493",
            "Clicks": "132974",
            "Sign Ups": "2004",
            "Deposits": "924",
            "Cost per Sign Up": "$21.98",
            "Cost per Deposit)": "$47.21"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1003651",
            "Clicks": "133160",
            "Sign Ups": "1849",
            "Deposits": "1006",
            "Cost per Sign Up": "$20.11",
            "Cost per Deposit)": "$55.11"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1082402",
            "Clicks": "126655",
            "Sign Ups": "1938",
            "Deposits": "1023",
            "Cost per Sign Up": "$24.19",
            "Cost per Deposit)": "$36.12"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "929471",
            "Clicks": "137863",
            "Sign Ups": "1728",
            "Deposits": "954",
            "Cost per Sign Up": "$23.62",
            "Cost per Deposit)": "$47.50"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "994455",
            "Clicks": "127487",
            "Sign Ups": "1985",
            "Deposits": "930",
            "Cost per Sign Up": "$24.55",
            "Cost per Deposit)": "$47.96"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1013056",
            "Clicks": "123902",
            "Sign Ups": "1947",
            "Deposits": "980",
            "Cost per Sign Up": "$19.99",
            "Cost per Deposit)": "$38.60"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1032835",
            "Clicks": "119806",
            "Sign Ups": "1951",
            "Deposits": "1120",
            "Cost per Sign Up": "$21.05",
            "Cost per Deposit)": "$35.75"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "1022897",
            "Clicks": "154540",
            "Sign Ups": "1878",
            "Deposits": "980",
            "Cost per Sign Up": "$28.36",
            "Cost per Deposit)": "$43.82"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "963369",
            "Clicks": "121946",
            "Sign Ups": "2116",
            "Deposits": "1036",
            "Cost per Sign Up": "$23.53",
            "Cost per Deposit)": "$43.22"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1100038",
            "Clicks": "124416",
            "Sign Ups": "1771",
            "Deposits": "1066",
            "Cost per Sign Up": "$24.88",
            "Cost per Deposit)": "$50.99"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1012378",
            "Clicks": "118493",
            "Sign Ups": "1764",
            "Deposits": "930",
            "Cost per Sign Up": "$15.47",
            "Cost per Deposit)": "$48.15"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1097884",
            "Clicks": "141425",
            "Sign Ups": "2005",
            "Deposits": "895",
            "Cost per Sign Up": "$23.46",
            "Cost per Deposit)": "$57.05"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1008492",
            "Clicks": "146892",
            "Sign Ups": "1933",
            "Deposits": "1051",
            "Cost per Sign Up": "$21.03",
            "Cost per Deposit)": "$53.51"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "976552",
            "Clicks": "120938",
            "Sign Ups": "2038",
            "Deposits": "929",
            "Cost per Sign Up": "$23.46",
            "Cost per Deposit)": "$51.11"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "952372",
            "Clicks": "139263",
            "Sign Ups": "2201",
            "Deposits": "970",
            "Cost per Sign Up": "$24.64",
            "Cost per Deposit)": "$49.04"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1000494",
            "Clicks": "145755",
            "Sign Ups": "1855",
            "Deposits": "995",
            "Cost per Sign Up": "$25.75",
            "Cost per Deposit)": "$47.66"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1062876",
            "Clicks": "119727",
            "Sign Ups": "1968",
            "Deposits": "1000",
            "Cost per Sign Up": "$21.45",
            "Cost per Deposit)": "$44.82"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "1091740",
            "Clicks": "122196",
            "Sign Ups": "1836",
            "Deposits": "1033",
            "Cost per Sign Up": "$19.97",
            "Cost per Deposit)": "$45.95"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "988993",
            "Clicks": "118691",
            "Sign Ups": "2094",
            "Deposits": "1007",
            "Cost per Sign Up": "$21.19",
            "Cost per Deposit)": "$39.16"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "994807",
            "Clicks": "125804",
            "Sign Ups": "1823",
            "Deposits": "1105",
            "Cost per Sign Up": "$25.57",
            "Cost per Deposit)": "$38.10"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1098238",
            "Clicks": "135647",
            "Sign Ups": "1687",
            "Deposits": "942",
            "Cost per Sign Up": "$19.01",
            "Cost per Deposit)": "$45.05"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1153017",
            "Clicks": "114273",
            "Sign Ups": "2023",
            "Deposits": "932",
            "Cost per Sign Up": "$21.38",
            "Cost per Deposit)": "$47.00"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "928244",
            "Clicks": "149190",
            "Sign Ups": "1989",
            "Deposits": "939",
            "Cost per Sign Up": "$24.43",
            "Cost per Deposit)": "$36.36"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1100367",
            "Clicks": "145689",
            "Sign Ups": "1912",
            "Deposits": "1149",
            "Cost per Sign Up": "$23.57",
            "Cost per Deposit)": "$50.29"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "1008903",
            "Clicks": "140384",
            "Sign Ups": "1994",
            "Deposits": "979",
            "Cost per Sign Up": "$26.08",
            "Cost per Deposit)": "$47.70"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "979710",
            "Clicks": "136067",
            "Sign Ups": "1837",
            "Deposits": "971",
            "Cost per Sign Up": "$20.43",
            "Cost per Deposit)": "$48.37"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "982545",
            "Clicks": "128562",
            "Sign Ups": "1970",
            "Deposits": "940",
            "Cost per Sign Up": "$23.55",
            "Cost per Deposit)": "$57.38"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "928343",
            "Clicks": "145646",
            "Sign Ups": "2049",
            "Deposits": "880",
            "Cost per Sign Up": "$27.98",
            "Cost per Deposit)": "$41.64"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1030898",
            "Clicks": "111506",
            "Sign Ups": "2072",
            "Deposits": "1019",
            "Cost per Sign Up": "$22.68",
            "Cost per Deposit)": "$36.50"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "919121",
            "Clicks": "121879",
            "Sign Ups": "1746",
            "Deposits": "997",
            "Cost per Sign Up": "$20.09",
            "Cost per Deposit)": "$45.94"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "995877",
            "Clicks": "136791",
            "Sign Ups": "2071",
            "Deposits": "1024",
            "Cost per Sign Up": "$21.48",
            "Cost per Deposit)": "$39.86"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "971709",
            "Clicks": "136654",
            "Sign Ups": "2010",
            "Deposits": "893",
            "Cost per Sign Up": "$28.60",
            "Cost per Deposit)": "$49.34"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1069327",
            "Clicks": "138685",
            "Sign Ups": "1872",
            "Deposits": "1025",
            "Cost per Sign Up": "$18.79",
            "Cost per Deposit)": "$51.61"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "978718",
            "Clicks": "133207",
            "Sign Ups": "2151",
            "Deposits": "938",
            "Cost per Sign Up": "$18.57",
            "Cost per Deposit)": "$50.88"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1032300",
            "Clicks": "134052",
            "Sign Ups": "1710",
            "Deposits": "984",
            "Cost per Sign Up": "$23.55",
            "Cost per Deposit)": "$42.79"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1039238",
            "Clicks": "127107",
            "Sign Ups": "1909",
            "Deposits": "909",
            "Cost per Sign Up": "$22.87",
            "Cost per Deposit)": "$52.08"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "960860",
            "Clicks": "148570",
            "Sign Ups": "1931",
            "Deposits": "1029",
            "Cost per Sign Up": "$24.32",
            "Cost per Deposit)": "$49.76"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1113146",
            "Clicks": "146639",
            "Sign Ups": "1994",
            "Deposits": "976",
            "Cost per Sign Up": "$23.37",
            "Cost per Deposit)": "$46.02"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "861788",
            "Clicks": "126455",
            "Sign Ups": "1817",
            "Deposits": "973",
            "Cost per Sign Up": "$28.72",
            "Cost per Deposit)": "$41.42"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1038812",
            "Clicks": "142261",
            "Sign Ups": "1869",
            "Deposits": "1046",
            "Cost per Sign Up": "$17.57",
            "Cost per Deposit)": "$55.04"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1052737",
            "Clicks": "146885",
            "Sign Ups": "2149",
            "Deposits": "950",
            "Cost per Sign Up": "$21.12",
            "Cost per Deposit)": "$43.65"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1051107",
            "Clicks": "138522",
            "Sign Ups": "1875",
            "Deposits": "944",
            "Cost per Sign Up": "$23.79",
            "Cost per Deposit)": "$43.96"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1017662",
            "Clicks": "139767",
            "Sign Ups": "1883",
            "Deposits": "1046",
            "Cost per Sign Up": "$19.72",
            "Cost per Deposit)": "$42.48"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "1052399",
            "Clicks": "144374",
            "Sign Ups": "2038",
            "Deposits": "993",
            "Cost per Sign Up": "$19.43",
            "Cost per Deposit)": "$38.07"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "995670",
            "Clicks": "118645",
            "Sign Ups": "1969",
            "Deposits": "941",
            "Cost per Sign Up": "$17.65",
            "Cost per Deposit)": "$44.35"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1014647",
            "Clicks": "139919",
            "Sign Ups": "1823",
            "Deposits": "959",
            "Cost per Sign Up": "$27.65",
            "Cost per Deposit)": "$43.74"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "921452",
            "Clicks": "121864",
            "Sign Ups": "1839",
            "Deposits": "1147",
            "Cost per Sign Up": "$20.59",
            "Cost per Deposit)": "$38.67"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1063219",
            "Clicks": "142470",
            "Sign Ups": "1926",
            "Deposits": "886",
            "Cost per Sign Up": "$20.31",
            "Cost per Deposit)": "$43.41"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "975375",
            "Clicks": "137424",
            "Sign Ups": "1802",
            "Deposits": "1015",
            "Cost per Sign Up": "$24.97",
            "Cost per Deposit)": "$37.53"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "962147",
            "Clicks": "142306",
            "Sign Ups": "1924",
            "Deposits": "924",
            "Cost per Sign Up": "$19.93",
            "Cost per Deposit)": "$38.25"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1064018",
            "Clicks": "126687",
            "Sign Ups": "1741",
            "Deposits": "945",
            "Cost per Sign Up": "$27.17",
            "Cost per Deposit)": "$50.54"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "965077",
            "Clicks": "120897",
            "Sign Ups": "1947",
            "Deposits": "851",
            "Cost per Sign Up": "$22.60",
            "Cost per Deposit)": "$44.41"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1095797",
            "Clicks": "131136",
            "Sign Ups": "1930",
            "Deposits": "879",
            "Cost per Sign Up": "$24.47",
            "Cost per Deposit)": "$53.36"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "916479",
            "Clicks": "135467",
            "Sign Ups": "1859",
            "Deposits": "919",
            "Cost per Sign Up": "$27.72",
            "Cost per Deposit)": "$50.51"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "995645",
            "Clicks": "125952",
            "Sign Ups": "1897",
            "Deposits": "905",
            "Cost per Sign Up": "$25.00",
            "Cost per Deposit)": "$33.57"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1034484",
            "Clicks": "150236",
            "Sign Ups": "2047",
            "Deposits": "1006",
            "Cost per Sign Up": "$23.80",
            "Cost per Deposit)": "$41.67"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "976896",
            "Clicks": "129873",
            "Sign Ups": "2040",
            "Deposits": "1126",
            "Cost per Sign Up": "$25.42",
            "Cost per Deposit)": "$47.23"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "950903",
            "Clicks": "134058",
            "Sign Ups": "2003",
            "Deposits": "1017",
            "Cost per Sign Up": "$21.75",
            "Cost per Deposit)": "$36.65"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1063401",
            "Clicks": "135073",
            "Sign Ups": "2142",
            "Deposits": "1046",
            "Cost per Sign Up": "$20.83",
            "Cost per Deposit)": "$45.07"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "918062",
            "Clicks": "142185",
            "Sign Ups": "2104",
            "Deposits": "1032",
            "Cost per Sign Up": "$26.48",
            "Cost per Deposit)": "$47.52"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1120845",
            "Clicks": "146005",
            "Sign Ups": "1932",
            "Deposits": "939",
            "Cost per Sign Up": "$20.42",
            "Cost per Deposit)": "$43.38"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1047994",
            "Clicks": "133445",
            "Sign Ups": "1872",
            "Deposits": "855",
            "Cost per Sign Up": "$29.09",
            "Cost per Deposit)": "$41.29"
          }
        ]
      },
      "PlayToEarnUsersData": {
        "name": "PlayToEarn Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "176111",
            "Clicks": "22300",
            "Sign Ups": "428",
            "Deposits": "167",
            "Cost per Sign Up": "$14.70",
            "Cost per Deposit)": "$59.38"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "192093",
            "Clicks": "24757",
            "Sign Ups": "310",
            "Deposits": "139",
            "Cost per Sign Up": "$12.72",
            "Cost per Deposit)": "$35.03"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "195253",
            "Clicks": "24311",
            "Sign Ups": "302",
            "Deposits": "198",
            "Cost per Sign Up": "$27.75",
            "Cost per Deposit)": "$35.41"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "136720",
            "Clicks": "18276",
            "Sign Ups": "353",
            "Deposits": "224",
            "Cost per Sign Up": "$18.03",
            "Cost per Deposit)": "$50.08"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "189368",
            "Clicks": "16938",
            "Sign Ups": "379",
            "Deposits": "221",
            "Cost per Sign Up": "$19.42",
            "Cost per Deposit)": "$33.46"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "209918",
            "Clicks": "18312",
            "Sign Ups": "303",
            "Deposits": "151",
            "Cost per Sign Up": "$18.71",
            "Cost per Deposit)": "$49.79"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "149043",
            "Clicks": "24823",
            "Sign Ups": "396",
            "Deposits": "137",
            "Cost per Sign Up": "$19.43",
            "Cost per Deposit)": "$35.94"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "214335",
            "Clicks": "22736",
            "Sign Ups": "279",
            "Deposits": "195",
            "Cost per Sign Up": "$10.14",
            "Cost per Deposit)": "$23.03"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "207320",
            "Clicks": "17038",
            "Sign Ups": "448",
            "Deposits": "172",
            "Cost per Sign Up": "$24.54",
            "Cost per Deposit)": "$37.89"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "196374",
            "Clicks": "22406",
            "Sign Ups": "387",
            "Deposits": "140",
            "Cost per Sign Up": "$27.51",
            "Cost per Deposit)": "$48.63"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "159569",
            "Clicks": "15832",
            "Sign Ups": "308",
            "Deposits": "184",
            "Cost per Sign Up": "$12.20",
            "Cost per Deposit)": "$48.87"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "215594",
            "Clicks": "16873",
            "Sign Ups": "318",
            "Deposits": "129",
            "Cost per Sign Up": "$28.39",
            "Cost per Deposit)": "$24.65"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "163227",
            "Clicks": "16704",
            "Sign Ups": "430",
            "Deposits": "207",
            "Cost per Sign Up": "$26.34",
            "Cost per Deposit)": "$21.67"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "195109",
            "Clicks": "23058",
            "Sign Ups": "319",
            "Deposits": "207",
            "Cost per Sign Up": "$12.08",
            "Cost per Deposit)": "$21.48"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "197114",
            "Clicks": "17398",
            "Sign Ups": "445",
            "Deposits": "135",
            "Cost per Sign Up": "$11.89",
            "Cost per Deposit)": "$36.00"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "168961",
            "Clicks": "25656",
            "Sign Ups": "300",
            "Deposits": "218",
            "Cost per Sign Up": "$15.95",
            "Cost per Deposit)": "$56.62"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "132102",
            "Clicks": "28707",
            "Sign Ups": "440",
            "Deposits": "176",
            "Cost per Sign Up": "$10.42",
            "Cost per Deposit)": "$52.02"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "151415",
            "Clicks": "21807",
            "Sign Ups": "399",
            "Deposits": "148",
            "Cost per Sign Up": "$19.24",
            "Cost per Deposit)": "$28.36"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "134327",
            "Clicks": "18139",
            "Sign Ups": "323",
            "Deposits": "173",
            "Cost per Sign Up": "$27.31",
            "Cost per Deposit)": "$23.61"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "196450",
            "Clicks": "23971",
            "Sign Ups": "376",
            "Deposits": "164",
            "Cost per Sign Up": "$15.78",
            "Cost per Deposit)": "$42.58"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "164788",
            "Clicks": "19025",
            "Sign Ups": "281",
            "Deposits": "177",
            "Cost per Sign Up": "$28.37",
            "Cost per Deposit)": "$42.84"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "138641",
            "Clicks": "23336",
            "Sign Ups": "276",
            "Deposits": "195",
            "Cost per Sign Up": "$18.17",
            "Cost per Deposit)": "$50.86"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "143142",
            "Clicks": "18015",
            "Sign Ups": "376",
            "Deposits": "203",
            "Cost per Sign Up": "$29.85",
            "Cost per Deposit)": "$48.56"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "207873",
            "Clicks": "27636",
            "Sign Ups": "267",
            "Deposits": "217",
            "Cost per Sign Up": "$10.75",
            "Cost per Deposit)": "$37.16"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "141064",
            "Clicks": "22312",
            "Sign Ups": "307",
            "Deposits": "210",
            "Cost per Sign Up": "$24.58",
            "Cost per Deposit)": "$28.05"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "154274",
            "Clicks": "18765",
            "Sign Ups": "425",
            "Deposits": "211",
            "Cost per Sign Up": "$21.75",
            "Cost per Deposit)": "$59.37"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "201338",
            "Clicks": "20852",
            "Sign Ups": "251",
            "Deposits": "222",
            "Cost per Sign Up": "$27.82",
            "Cost per Deposit)": "$38.65"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "189157",
            "Clicks": "23106",
            "Sign Ups": "324",
            "Deposits": "207",
            "Cost per Sign Up": "$21.26",
            "Cost per Deposit)": "$24.13"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "151884",
            "Clicks": "28860",
            "Sign Ups": "381",
            "Deposits": "214",
            "Cost per Sign Up": "$29.62",
            "Cost per Deposit)": "$49.45"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "196011",
            "Clicks": "20027",
            "Sign Ups": "258",
            "Deposits": "139",
            "Cost per Sign Up": "$29.63",
            "Cost per Deposit)": "$32.46"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "160206",
            "Clicks": "20249",
            "Sign Ups": "348",
            "Deposits": "145",
            "Cost per Sign Up": "$15.16",
            "Cost per Deposit)": "$34.50"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "219582",
            "Clicks": "22646",
            "Sign Ups": "394",
            "Deposits": "208",
            "Cost per Sign Up": "$28.60",
            "Cost per Deposit)": "$56.15"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "178944",
            "Clicks": "28761",
            "Sign Ups": "311",
            "Deposits": "223",
            "Cost per Sign Up": "$27.55",
            "Cost per Deposit)": "$47.14"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "183246",
            "Clicks": "20904",
            "Sign Ups": "299",
            "Deposits": "152",
            "Cost per Sign Up": "$16.57",
            "Cost per Deposit)": "$42.89"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "179921",
            "Clicks": "19965",
            "Sign Ups": "374",
            "Deposits": "131",
            "Cost per Sign Up": "$27.46",
            "Cost per Deposit)": "$30.27"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "206144",
            "Clicks": "21190",
            "Sign Ups": "414",
            "Deposits": "126",
            "Cost per Sign Up": "$10.71",
            "Cost per Deposit)": "$23.96"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "184447",
            "Clicks": "27584",
            "Sign Ups": "294",
            "Deposits": "205",
            "Cost per Sign Up": "$25.15",
            "Cost per Deposit)": "$54.15"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "183450",
            "Clicks": "17843",
            "Sign Ups": "318",
            "Deposits": "149",
            "Cost per Sign Up": "$16.85",
            "Cost per Deposit)": "$28.64"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "135868",
            "Clicks": "28507",
            "Sign Ups": "341",
            "Deposits": "134",
            "Cost per Sign Up": "$29.53",
            "Cost per Deposit)": "$38.23"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "190613",
            "Clicks": "15500",
            "Sign Ups": "441",
            "Deposits": "126",
            "Cost per Sign Up": "$18.72",
            "Cost per Deposit)": "$26.10"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "145154",
            "Clicks": "25161",
            "Sign Ups": "439",
            "Deposits": "199",
            "Cost per Sign Up": "$18.28",
            "Cost per Deposit)": "$27.00"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "196137",
            "Clicks": "18557",
            "Sign Ups": "421",
            "Deposits": "167",
            "Cost per Sign Up": "$15.93",
            "Cost per Deposit)": "$58.59"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "157492",
            "Clicks": "22291",
            "Sign Ups": "382",
            "Deposits": "218",
            "Cost per Sign Up": "$28.39",
            "Cost per Deposit)": "$45.12"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "176337",
            "Clicks": "25352",
            "Sign Ups": "419",
            "Deposits": "149",
            "Cost per Sign Up": "$26.89",
            "Cost per Deposit)": "$57.94"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "182573",
            "Clicks": "19954",
            "Sign Ups": "357",
            "Deposits": "169",
            "Cost per Sign Up": "$27.02",
            "Cost per Deposit)": "$30.81"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "209338",
            "Clicks": "18623",
            "Sign Ups": "281",
            "Deposits": "144",
            "Cost per Sign Up": "$14.77",
            "Cost per Deposit)": "$58.26"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "180978",
            "Clicks": "22564",
            "Sign Ups": "388",
            "Deposits": "208",
            "Cost per Sign Up": "$12.80",
            "Cost per Deposit)": "$48.02"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "217354",
            "Clicks": "25529",
            "Sign Ups": "414",
            "Deposits": "182",
            "Cost per Sign Up": "$16.71",
            "Cost per Deposit)": "$34.55"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "155516",
            "Clicks": "20887",
            "Sign Ups": "299",
            "Deposits": "210",
            "Cost per Sign Up": "$15.24",
            "Cost per Deposit)": "$39.69"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "154776",
            "Clicks": "25862",
            "Sign Ups": "409",
            "Deposits": "191",
            "Cost per Sign Up": "$18.49",
            "Cost per Deposit)": "$44.99"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "208506",
            "Clicks": "18092",
            "Sign Ups": "422",
            "Deposits": "177",
            "Cost per Sign Up": "$26.56",
            "Cost per Deposit)": "$53.22"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "171929",
            "Clicks": "21261",
            "Sign Ups": "333",
            "Deposits": "148",
            "Cost per Sign Up": "$26.70",
            "Cost per Deposit)": "$42.52"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "212132",
            "Clicks": "26587",
            "Sign Ups": "406",
            "Deposits": "133",
            "Cost per Sign Up": "$22.55",
            "Cost per Deposit)": "$32.64"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "158353",
            "Clicks": "29199",
            "Sign Ups": "407",
            "Deposits": "169",
            "Cost per Sign Up": "$21.25",
            "Cost per Deposit)": "$50.45"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "199738",
            "Clicks": "18641",
            "Sign Ups": "387",
            "Deposits": "162",
            "Cost per Sign Up": "$26.43",
            "Cost per Deposit)": "$33.53"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "166328",
            "Clicks": "23445",
            "Sign Ups": "418",
            "Deposits": "183",
            "Cost per Sign Up": "$13.07",
            "Cost per Deposit)": "$23.37"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "206013",
            "Clicks": "28624",
            "Sign Ups": "284",
            "Deposits": "166",
            "Cost per Sign Up": "$17.05",
            "Cost per Deposit)": "$39.98"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "151906",
            "Clicks": "17953",
            "Sign Ups": "388",
            "Deposits": "159",
            "Cost per Sign Up": "$12.30",
            "Cost per Deposit)": "$44.21"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "155692",
            "Clicks": "18288",
            "Sign Ups": "394",
            "Deposits": "192",
            "Cost per Sign Up": "$17.53",
            "Cost per Deposit)": "$48.07"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "152277",
            "Clicks": "23532",
            "Sign Ups": "330",
            "Deposits": "133",
            "Cost per Sign Up": "$24.31",
            "Cost per Deposit)": "$24.95"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "154996",
            "Clicks": "25542",
            "Sign Ups": "397",
            "Deposits": "164",
            "Cost per Sign Up": "$25.74",
            "Cost per Deposit)": "$46.95"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "150760",
            "Clicks": "24094",
            "Sign Ups": "387",
            "Deposits": "182",
            "Cost per Sign Up": "$14.22",
            "Cost per Deposit)": "$34.07"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "151236",
            "Clicks": "17776",
            "Sign Ups": "328",
            "Deposits": "154",
            "Cost per Sign Up": "$19.23",
            "Cost per Deposit)": "$32.72"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "143396",
            "Clicks": "16377",
            "Sign Ups": "441",
            "Deposits": "149",
            "Cost per Sign Up": "$12.12",
            "Cost per Deposit)": "$36.91"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "131884",
            "Clicks": "23325",
            "Sign Ups": "326",
            "Deposits": "221",
            "Cost per Sign Up": "$16.17",
            "Cost per Deposit)": "$28.86"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "209081",
            "Clicks": "28948",
            "Sign Ups": "301",
            "Deposits": "208",
            "Cost per Sign Up": "$23.86",
            "Cost per Deposit)": "$26.27"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "160055",
            "Clicks": "16372",
            "Sign Ups": "257",
            "Deposits": "218",
            "Cost per Sign Up": "$12.72",
            "Cost per Deposit)": "$50.81"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "171661",
            "Clicks": "17564",
            "Sign Ups": "262",
            "Deposits": "201",
            "Cost per Sign Up": "$25.31",
            "Cost per Deposit)": "$52.58"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "163459",
            "Clicks": "15335",
            "Sign Ups": "421",
            "Deposits": "204",
            "Cost per Sign Up": "$27.83",
            "Cost per Deposit)": "$36.00"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "161700",
            "Clicks": "17134",
            "Sign Ups": "443",
            "Deposits": "147",
            "Cost per Sign Up": "$18.75",
            "Cost per Deposit)": "$43.57"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "135134",
            "Clicks": "26007",
            "Sign Ups": "356",
            "Deposits": "201",
            "Cost per Sign Up": "$17.76",
            "Cost per Deposit)": "$50.05"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "164890",
            "Clicks": "19800",
            "Sign Ups": "254",
            "Deposits": "161",
            "Cost per Sign Up": "$19.10",
            "Cost per Deposit)": "$52.02"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "147886",
            "Clicks": "25793",
            "Sign Ups": "294",
            "Deposits": "141",
            "Cost per Sign Up": "$24.20",
            "Cost per Deposit)": "$46.72"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "146706",
            "Clicks": "19115",
            "Sign Ups": "320",
            "Deposits": "204",
            "Cost per Sign Up": "$17.48",
            "Cost per Deposit)": "$39.25"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "158555",
            "Clicks": "23930",
            "Sign Ups": "283",
            "Deposits": "140",
            "Cost per Sign Up": "$20.72",
            "Cost per Deposit)": "$45.53"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "130172",
            "Clicks": "20929",
            "Sign Ups": "428",
            "Deposits": "169",
            "Cost per Sign Up": "$22.45",
            "Cost per Deposit)": "$39.44"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "170905",
            "Clicks": "15447",
            "Sign Ups": "430",
            "Deposits": "157",
            "Cost per Sign Up": "$18.18",
            "Cost per Deposit)": "$23.66"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "157888",
            "Clicks": "24733",
            "Sign Ups": "284",
            "Deposits": "147",
            "Cost per Sign Up": "$21.04",
            "Cost per Deposit)": "$32.15"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "176710",
            "Clicks": "19630",
            "Sign Ups": "386",
            "Deposits": "148",
            "Cost per Sign Up": "$28.48",
            "Cost per Deposit)": "$58.06"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "146196",
            "Clicks": "18833",
            "Sign Ups": "291",
            "Deposits": "214",
            "Cost per Sign Up": "$18.35",
            "Cost per Deposit)": "$53.35"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "208019",
            "Clicks": "16321",
            "Sign Ups": "261",
            "Deposits": "169",
            "Cost per Sign Up": "$12.12",
            "Cost per Deposit)": "$40.70"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "219858",
            "Clicks": "23417",
            "Sign Ups": "278",
            "Deposits": "193",
            "Cost per Sign Up": "$14.21",
            "Cost per Deposit)": "$40.67"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "150930",
            "Clicks": "17388",
            "Sign Ups": "308",
            "Deposits": "218",
            "Cost per Sign Up": "$13.47",
            "Cost per Deposit)": "$42.62"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "149493",
            "Clicks": "16150",
            "Sign Ups": "386",
            "Deposits": "125",
            "Cost per Sign Up": "$28.25",
            "Cost per Deposit)": "$38.62"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "173318",
            "Clicks": "19156",
            "Sign Ups": "280",
            "Deposits": "126",
            "Cost per Sign Up": "$11.20",
            "Cost per Deposit)": "$57.13"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "151585",
            "Clicks": "22408",
            "Sign Ups": "404",
            "Deposits": "197",
            "Cost per Sign Up": "$10.73",
            "Cost per Deposit)": "$52.96"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "148946",
            "Clicks": "17545",
            "Sign Ups": "398",
            "Deposits": "151",
            "Cost per Sign Up": "$24.18",
            "Cost per Deposit)": "$44.39"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "175788",
            "Clicks": "25160",
            "Sign Ups": "402",
            "Deposits": "169",
            "Cost per Sign Up": "$10.46",
            "Cost per Deposit)": "$27.31"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "135569",
            "Clicks": "17617",
            "Sign Ups": "369",
            "Deposits": "208",
            "Cost per Sign Up": "$14.39",
            "Cost per Deposit)": "$20.78"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "175995",
            "Clicks": "17331",
            "Sign Ups": "286",
            "Deposits": "202",
            "Cost per Sign Up": "$24.28",
            "Cost per Deposit)": "$56.17"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "209609",
            "Clicks": "27390",
            "Sign Ups": "438",
            "Deposits": "206",
            "Cost per Sign Up": "$22.11",
            "Cost per Deposit)": "$37.41"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "157810",
            "Clicks": "20497",
            "Sign Ups": "448",
            "Deposits": "145",
            "Cost per Sign Up": "$24.91",
            "Cost per Deposit)": "$58.54"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "138676",
            "Clicks": "22270",
            "Sign Ups": "310",
            "Deposits": "205",
            "Cost per Sign Up": "$20.68",
            "Cost per Deposit)": "$43.43"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "134223",
            "Clicks": "29829",
            "Sign Ups": "365",
            "Deposits": "149",
            "Cost per Sign Up": "$18.32",
            "Cost per Deposit)": "$59.12"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "201872",
            "Clicks": "21440",
            "Sign Ups": "440",
            "Deposits": "179",
            "Cost per Sign Up": "$11.65",
            "Cost per Deposit)": "$56.20"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "165222",
            "Clicks": "24525",
            "Sign Ups": "253",
            "Deposits": "135",
            "Cost per Sign Up": "$21.33",
            "Cost per Deposit)": "$37.69"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "156770",
            "Clicks": "29922",
            "Sign Ups": "444",
            "Deposits": "219",
            "Cost per Sign Up": "$29.88",
            "Cost per Deposit)": "$49.10"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "173236",
            "Clicks": "19620",
            "Sign Ups": "341",
            "Deposits": "139",
            "Cost per Sign Up": "$12.32",
            "Cost per Deposit)": "$41.08"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "189146",
            "Clicks": "20926",
            "Sign Ups": "335",
            "Deposits": "159",
            "Cost per Sign Up": "$12.16",
            "Cost per Deposit)": "$40.90"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "170640",
            "Clicks": "22124",
            "Sign Ups": "328",
            "Deposits": "163",
            "Cost per Sign Up": "$16.98",
            "Cost per Deposit)": "$51.34"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "133825",
            "Clicks": "15080",
            "Sign Ups": "343",
            "Deposits": "155",
            "Cost per Sign Up": "$27.20",
            "Cost per Deposit)": "$30.28"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "144588",
            "Clicks": "28416",
            "Sign Ups": "256",
            "Deposits": "191",
            "Cost per Sign Up": "$19.22",
            "Cost per Deposit)": "$43.32"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "201836",
            "Clicks": "23339",
            "Sign Ups": "344",
            "Deposits": "148",
            "Cost per Sign Up": "$23.16",
            "Cost per Deposit)": "$44.03"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "148100",
            "Clicks": "18556",
            "Sign Ups": "362",
            "Deposits": "212",
            "Cost per Sign Up": "$20.95",
            "Cost per Deposit)": "$36.51"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "143894",
            "Clicks": "15063",
            "Sign Ups": "376",
            "Deposits": "133",
            "Cost per Sign Up": "$18.59",
            "Cost per Deposit)": "$57.08"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "180283",
            "Clicks": "25473",
            "Sign Ups": "414",
            "Deposits": "186",
            "Cost per Sign Up": "$19.51",
            "Cost per Deposit)": "$30.77"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "214132",
            "Clicks": "25113",
            "Sign Ups": "268",
            "Deposits": "167",
            "Cost per Sign Up": "$12.97",
            "Cost per Deposit)": "$49.83"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "166808",
            "Clicks": "25687",
            "Sign Ups": "272",
            "Deposits": "206",
            "Cost per Sign Up": "$17.03",
            "Cost per Deposit)": "$31.94"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "200887",
            "Clicks": "23615",
            "Sign Ups": "261",
            "Deposits": "147",
            "Cost per Sign Up": "$29.97",
            "Cost per Deposit)": "$30.60"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "208922",
            "Clicks": "19314",
            "Sign Ups": "281",
            "Deposits": "188",
            "Cost per Sign Up": "$21.24",
            "Cost per Deposit)": "$47.86"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "215913",
            "Clicks": "28770",
            "Sign Ups": "300",
            "Deposits": "131",
            "Cost per Sign Up": "$18.68",
            "Cost per Deposit)": "$37.23"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "183501",
            "Clicks": "23382",
            "Sign Ups": "414",
            "Deposits": "166",
            "Cost per Sign Up": "$14.85",
            "Cost per Deposit)": "$33.23"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "216118",
            "Clicks": "24868",
            "Sign Ups": "323",
            "Deposits": "190",
            "Cost per Sign Up": "$17.55",
            "Cost per Deposit)": "$54.90"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "171665",
            "Clicks": "27185",
            "Sign Ups": "303",
            "Deposits": "155",
            "Cost per Sign Up": "$11.72",
            "Cost per Deposit)": "$55.53"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "167029",
            "Clicks": "15247",
            "Sign Ups": "324",
            "Deposits": "127",
            "Cost per Sign Up": "$20.98",
            "Cost per Deposit)": "$32.87"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "191342",
            "Clicks": "19276",
            "Sign Ups": "252",
            "Deposits": "126",
            "Cost per Sign Up": "$19.80",
            "Cost per Deposit)": "$55.76"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "167778",
            "Clicks": "20005",
            "Sign Ups": "314",
            "Deposits": "135",
            "Cost per Sign Up": "$18.84",
            "Cost per Deposit)": "$20.47"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "182829",
            "Clicks": "23253",
            "Sign Ups": "332",
            "Deposits": "132",
            "Cost per Sign Up": "$16.97",
            "Cost per Deposit)": "$25.78"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "139149",
            "Clicks": "17560",
            "Sign Ups": "398",
            "Deposits": "214",
            "Cost per Sign Up": "$11.48",
            "Cost per Deposit)": "$32.86"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "191909",
            "Clicks": "26279",
            "Sign Ups": "390",
            "Deposits": "162",
            "Cost per Sign Up": "$16.13",
            "Cost per Deposit)": "$55.62"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "148965",
            "Clicks": "18130",
            "Sign Ups": "447",
            "Deposits": "145",
            "Cost per Sign Up": "$24.25",
            "Cost per Deposit)": "$27.72"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "214424",
            "Clicks": "23280",
            "Sign Ups": "256",
            "Deposits": "168",
            "Cost per Sign Up": "$27.83",
            "Cost per Deposit)": "$25.66"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "199868",
            "Clicks": "23541",
            "Sign Ups": "286",
            "Deposits": "200",
            "Cost per Sign Up": "$21.79",
            "Cost per Deposit)": "$20.29"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "169549",
            "Clicks": "23274",
            "Sign Ups": "254",
            "Deposits": "183",
            "Cost per Sign Up": "$25.27",
            "Cost per Deposit)": "$33.52"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "139907",
            "Clicks": "20558",
            "Sign Ups": "422",
            "Deposits": "182",
            "Cost per Sign Up": "$27.66",
            "Cost per Deposit)": "$29.93"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "177390",
            "Clicks": "22613",
            "Sign Ups": "443",
            "Deposits": "180",
            "Cost per Sign Up": "$27.80",
            "Cost per Deposit)": "$24.00"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "184828",
            "Clicks": "23031",
            "Sign Ups": "350",
            "Deposits": "181",
            "Cost per Sign Up": "$14.05",
            "Cost per Deposit)": "$30.14"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "164122",
            "Clicks": "25330",
            "Sign Ups": "438",
            "Deposits": "218",
            "Cost per Sign Up": "$10.82",
            "Cost per Deposit)": "$25.54"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "195654",
            "Clicks": "24108",
            "Sign Ups": "340",
            "Deposits": "150",
            "Cost per Sign Up": "$21.34",
            "Cost per Deposit)": "$23.42"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "194857",
            "Clicks": "25775",
            "Sign Ups": "337",
            "Deposits": "193",
            "Cost per Sign Up": "$27.35",
            "Cost per Deposit)": "$42.84"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "176253",
            "Clicks": "28647",
            "Sign Ups": "270",
            "Deposits": "189",
            "Cost per Sign Up": "$20.60",
            "Cost per Deposit)": "$33.75"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "199015",
            "Clicks": "25495",
            "Sign Ups": "337",
            "Deposits": "164",
            "Cost per Sign Up": "$15.96",
            "Cost per Deposit)": "$51.44"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "192625",
            "Clicks": "18835",
            "Sign Ups": "331",
            "Deposits": "175",
            "Cost per Sign Up": "$14.86",
            "Cost per Deposit)": "$56.13"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "204337",
            "Clicks": "29723",
            "Sign Ups": "257",
            "Deposits": "202",
            "Cost per Sign Up": "$11.20",
            "Cost per Deposit)": "$49.13"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "200452",
            "Clicks": "19089",
            "Sign Ups": "450",
            "Deposits": "163",
            "Cost per Sign Up": "$26.83",
            "Cost per Deposit)": "$52.56"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "216164",
            "Clicks": "25824",
            "Sign Ups": "298",
            "Deposits": "190",
            "Cost per Sign Up": "$14.70",
            "Cost per Deposit)": "$43.13"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "186162",
            "Clicks": "24217",
            "Sign Ups": "360",
            "Deposits": "171",
            "Cost per Sign Up": "$21.02",
            "Cost per Deposit)": "$26.13"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "216479",
            "Clicks": "18962",
            "Sign Ups": "442",
            "Deposits": "213",
            "Cost per Sign Up": "$21.54",
            "Cost per Deposit)": "$43.15"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "202631",
            "Clicks": "29887",
            "Sign Ups": "317",
            "Deposits": "136",
            "Cost per Sign Up": "$15.96",
            "Cost per Deposit)": "$43.62"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "218806",
            "Clicks": "15711",
            "Sign Ups": "389",
            "Deposits": "151",
            "Cost per Sign Up": "$18.82",
            "Cost per Deposit)": "$35.00"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "146490",
            "Clicks": "19839",
            "Sign Ups": "393",
            "Deposits": "189",
            "Cost per Sign Up": "$22.53",
            "Cost per Deposit)": "$58.43"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "217694",
            "Clicks": "19186",
            "Sign Ups": "311",
            "Deposits": "182",
            "Cost per Sign Up": "$27.92",
            "Cost per Deposit)": "$26.82"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "204652",
            "Clicks": "28496",
            "Sign Ups": "446",
            "Deposits": "172",
            "Cost per Sign Up": "$16.81",
            "Cost per Deposit)": "$49.52"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "165700",
            "Clicks": "20873",
            "Sign Ups": "338",
            "Deposits": "210",
            "Cost per Sign Up": "$24.23",
            "Cost per Deposit)": "$22.24"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "182344",
            "Clicks": "22402",
            "Sign Ups": "326",
            "Deposits": "190",
            "Cost per Sign Up": "$12.59",
            "Cost per Deposit)": "$20.25"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "202811",
            "Clicks": "21918",
            "Sign Ups": "252",
            "Deposits": "139",
            "Cost per Sign Up": "$17.10",
            "Cost per Deposit)": "$47.88"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "200253",
            "Clicks": "23807",
            "Sign Ups": "348",
            "Deposits": "220",
            "Cost per Sign Up": "$16.46",
            "Cost per Deposit)": "$30.57"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "137605",
            "Clicks": "21426",
            "Sign Ups": "366",
            "Deposits": "167",
            "Cost per Sign Up": "$27.26",
            "Cost per Deposit)": "$44.66"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "149935",
            "Clicks": "25103",
            "Sign Ups": "306",
            "Deposits": "184",
            "Cost per Sign Up": "$29.64",
            "Cost per Deposit)": "$25.01"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "131752",
            "Clicks": "20793",
            "Sign Ups": "352",
            "Deposits": "224",
            "Cost per Sign Up": "$10.68",
            "Cost per Deposit)": "$35.45"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "152696",
            "Clicks": "29303",
            "Sign Ups": "295",
            "Deposits": "208",
            "Cost per Sign Up": "$18.57",
            "Cost per Deposit)": "$59.73"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "217980",
            "Clicks": "17486",
            "Sign Ups": "300",
            "Deposits": "194",
            "Cost per Sign Up": "$29.62",
            "Cost per Deposit)": "$30.93"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "139899",
            "Clicks": "28353",
            "Sign Ups": "290",
            "Deposits": "128",
            "Cost per Sign Up": "$21.81",
            "Cost per Deposit)": "$59.46"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "141145",
            "Clicks": "20631",
            "Sign Ups": "312",
            "Deposits": "195",
            "Cost per Sign Up": "$26.54",
            "Cost per Deposit)": "$27.75"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "181790",
            "Clicks": "24443",
            "Sign Ups": "360",
            "Deposits": "207",
            "Cost per Sign Up": "$25.19",
            "Cost per Deposit)": "$38.65"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "175068",
            "Clicks": "20485",
            "Sign Ups": "437",
            "Deposits": "152",
            "Cost per Sign Up": "$23.47",
            "Cost per Deposit)": "$21.69"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "206381",
            "Clicks": "17283",
            "Sign Ups": "298",
            "Deposits": "147",
            "Cost per Sign Up": "$20.76",
            "Cost per Deposit)": "$30.40"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "137784",
            "Clicks": "28280",
            "Sign Ups": "417",
            "Deposits": "164",
            "Cost per Sign Up": "$21.14",
            "Cost per Deposit)": "$44.77"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "200818",
            "Clicks": "20234",
            "Sign Ups": "387",
            "Deposits": "215",
            "Cost per Sign Up": "$14.63",
            "Cost per Deposit)": "$28.15"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "141779",
            "Clicks": "23067",
            "Sign Ups": "311",
            "Deposits": "146",
            "Cost per Sign Up": "$22.90",
            "Cost per Deposit)": "$59.86"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "190538",
            "Clicks": "26543",
            "Sign Ups": "340",
            "Deposits": "170",
            "Cost per Sign Up": "$15.34",
            "Cost per Deposit)": "$45.19"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "175908",
            "Clicks": "19416",
            "Sign Ups": "423",
            "Deposits": "209",
            "Cost per Sign Up": "$24.51",
            "Cost per Deposit)": "$36.30"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "219033",
            "Clicks": "17446",
            "Sign Ups": "384",
            "Deposits": "205",
            "Cost per Sign Up": "$21.75",
            "Cost per Deposit)": "$36.79"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "203133",
            "Clicks": "29901",
            "Sign Ups": "309",
            "Deposits": "170",
            "Cost per Sign Up": "$26.73",
            "Cost per Deposit)": "$52.61"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "135388",
            "Clicks": "26567",
            "Sign Ups": "414",
            "Deposits": "128",
            "Cost per Sign Up": "$16.12",
            "Cost per Deposit)": "$53.54"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "134341",
            "Clicks": "28012",
            "Sign Ups": "378",
            "Deposits": "203",
            "Cost per Sign Up": "$26.50",
            "Cost per Deposit)": "$40.50"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "152031",
            "Clicks": "24526",
            "Sign Ups": "279",
            "Deposits": "216",
            "Cost per Sign Up": "$17.73",
            "Cost per Deposit)": "$38.83"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "188398",
            "Clicks": "16083",
            "Sign Ups": "368",
            "Deposits": "185",
            "Cost per Sign Up": "$18.93",
            "Cost per Deposit)": "$32.10"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "210138",
            "Clicks": "22772",
            "Sign Ups": "449",
            "Deposits": "187",
            "Cost per Sign Up": "$29.20",
            "Cost per Deposit)": "$49.21"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "142761",
            "Clicks": "15057",
            "Sign Ups": "447",
            "Deposits": "208",
            "Cost per Sign Up": "$14.09",
            "Cost per Deposit)": "$38.26"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "190636",
            "Clicks": "26941",
            "Sign Ups": "383",
            "Deposits": "183",
            "Cost per Sign Up": "$16.50",
            "Cost per Deposit)": "$55.77"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "136556",
            "Clicks": "28981",
            "Sign Ups": "286",
            "Deposits": "133",
            "Cost per Sign Up": "$17.74",
            "Cost per Deposit)": "$30.19"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "219920",
            "Clicks": "19959",
            "Sign Ups": "436",
            "Deposits": "200",
            "Cost per Sign Up": "$14.03",
            "Cost per Deposit)": "$55.89"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "168559",
            "Clicks": "20365",
            "Sign Ups": "376",
            "Deposits": "183",
            "Cost per Sign Up": "$12.81",
            "Cost per Deposit)": "$25.50"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "208657",
            "Clicks": "25463",
            "Sign Ups": "258",
            "Deposits": "163",
            "Cost per Sign Up": "$22.07",
            "Cost per Deposit)": "$55.31"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "189178",
            "Clicks": "18371",
            "Sign Ups": "341",
            "Deposits": "180",
            "Cost per Sign Up": "$12.05",
            "Cost per Deposit)": "$37.68"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "151235",
            "Clicks": "23671",
            "Sign Ups": "428",
            "Deposits": "175",
            "Cost per Sign Up": "$24.17",
            "Cost per Deposit)": "$24.65"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "137070",
            "Clicks": "15817",
            "Sign Ups": "425",
            "Deposits": "212",
            "Cost per Sign Up": "$12.31",
            "Cost per Deposit)": "$28.79"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "141342",
            "Clicks": "27761",
            "Sign Ups": "305",
            "Deposits": "194",
            "Cost per Sign Up": "$16.95",
            "Cost per Deposit)": "$37.70"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "204434",
            "Clicks": "23620",
            "Sign Ups": "400",
            "Deposits": "194",
            "Cost per Sign Up": "$14.72",
            "Cost per Deposit)": "$39.67"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "204256",
            "Clicks": "18300",
            "Sign Ups": "445",
            "Deposits": "168",
            "Cost per Sign Up": "$21.70",
            "Cost per Deposit)": "$29.19"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "208328",
            "Clicks": "15354",
            "Sign Ups": "358",
            "Deposits": "197",
            "Cost per Sign Up": "$16.19",
            "Cost per Deposit)": "$38.42"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "139231",
            "Clicks": "25658",
            "Sign Ups": "256",
            "Deposits": "170",
            "Cost per Sign Up": "$15.93",
            "Cost per Deposit)": "$30.91"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "219576",
            "Clicks": "15318",
            "Sign Ups": "411",
            "Deposits": "184",
            "Cost per Sign Up": "$16.54",
            "Cost per Deposit)": "$25.84"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "210658",
            "Clicks": "18357",
            "Sign Ups": "294",
            "Deposits": "174",
            "Cost per Sign Up": "$17.29",
            "Cost per Deposit)": "$40.27"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "132875",
            "Clicks": "21248",
            "Sign Ups": "365",
            "Deposits": "141",
            "Cost per Sign Up": "$28.84",
            "Cost per Deposit)": "$49.20"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "216633",
            "Clicks": "24448",
            "Sign Ups": "367",
            "Deposits": "192",
            "Cost per Sign Up": "$10.86",
            "Cost per Deposit)": "$45.12"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "136941",
            "Clicks": "22368",
            "Sign Ups": "276",
            "Deposits": "154",
            "Cost per Sign Up": "$21.35",
            "Cost per Deposit)": "$48.18"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "179670",
            "Clicks": "24560",
            "Sign Ups": "441",
            "Deposits": "212",
            "Cost per Sign Up": "$15.53",
            "Cost per Deposit)": "$52.25"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "197720",
            "Clicks": "17791",
            "Sign Ups": "427",
            "Deposits": "201",
            "Cost per Sign Up": "$18.84",
            "Cost per Deposit)": "$20.43"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "200016",
            "Clicks": "20811",
            "Sign Ups": "332",
            "Deposits": "224",
            "Cost per Sign Up": "$12.86",
            "Cost per Deposit)": "$20.83"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "210286",
            "Clicks": "28897",
            "Sign Ups": "280",
            "Deposits": "192",
            "Cost per Sign Up": "$23.55",
            "Cost per Deposit)": "$38.72"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "195617",
            "Clicks": "20207",
            "Sign Ups": "413",
            "Deposits": "222",
            "Cost per Sign Up": "$22.47",
            "Cost per Deposit)": "$22.23"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "200994",
            "Clicks": "17474",
            "Sign Ups": "336",
            "Deposits": "220",
            "Cost per Sign Up": "$17.19",
            "Cost per Deposit)": "$34.96"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "149250",
            "Clicks": "22151",
            "Sign Ups": "294",
            "Deposits": "136",
            "Cost per Sign Up": "$16.06",
            "Cost per Deposit)": "$46.55"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "194097",
            "Clicks": "28611",
            "Sign Ups": "411",
            "Deposits": "177",
            "Cost per Sign Up": "$12.18",
            "Cost per Deposit)": "$45.73"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "167403",
            "Clicks": "25750",
            "Sign Ups": "303",
            "Deposits": "205",
            "Cost per Sign Up": "$16.80",
            "Cost per Deposit)": "$46.40"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "200554",
            "Clicks": "21037",
            "Sign Ups": "345",
            "Deposits": "151",
            "Cost per Sign Up": "$21.50",
            "Cost per Deposit)": "$51.09"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "153771",
            "Clicks": "27463",
            "Sign Ups": "361",
            "Deposits": "186",
            "Cost per Sign Up": "$23.67",
            "Cost per Deposit)": "$58.05"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "138428",
            "Clicks": "22282",
            "Sign Ups": "381",
            "Deposits": "161",
            "Cost per Sign Up": "$12.53",
            "Cost per Deposit)": "$39.35"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "174093",
            "Clicks": "20428",
            "Sign Ups": "425",
            "Deposits": "153",
            "Cost per Sign Up": "$18.96",
            "Cost per Deposit)": "$38.13"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "197391",
            "Clicks": "16349",
            "Sign Ups": "323",
            "Deposits": "206",
            "Cost per Sign Up": "$22.58",
            "Cost per Deposit)": "$41.99"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "186549",
            "Clicks": "23310",
            "Sign Ups": "285",
            "Deposits": "138",
            "Cost per Sign Up": "$11.48",
            "Cost per Deposit)": "$25.73"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "145748",
            "Clicks": "21994",
            "Sign Ups": "355",
            "Deposits": "212",
            "Cost per Sign Up": "$25.82",
            "Cost per Deposit)": "$36.22"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "181036",
            "Clicks": "19521",
            "Sign Ups": "252",
            "Deposits": "178",
            "Cost per Sign Up": "$13.80",
            "Cost per Deposit)": "$49.16"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "189476",
            "Clicks": "17119",
            "Sign Ups": "367",
            "Deposits": "207",
            "Cost per Sign Up": "$28.85",
            "Cost per Deposit)": "$43.80"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "131029",
            "Clicks": "23015",
            "Sign Ups": "433",
            "Deposits": "135",
            "Cost per Sign Up": "$16.55",
            "Cost per Deposit)": "$45.06"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "204303",
            "Clicks": "23535",
            "Sign Ups": "307",
            "Deposits": "168",
            "Cost per Sign Up": "$19.99",
            "Cost per Deposit)": "$37.57"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "197825",
            "Clicks": "23884",
            "Sign Ups": "418",
            "Deposits": "175",
            "Cost per Sign Up": "$11.17",
            "Cost per Deposit)": "$47.62"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "155451",
            "Clicks": "20673",
            "Sign Ups": "354",
            "Deposits": "205",
            "Cost per Sign Up": "$15.33",
            "Cost per Deposit)": "$46.63"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "150092",
            "Clicks": "22757",
            "Sign Ups": "327",
            "Deposits": "161",
            "Cost per Sign Up": "$29.46",
            "Cost per Deposit)": "$51.53"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "164596",
            "Clicks": "25929",
            "Sign Ups": "434",
            "Deposits": "157",
            "Cost per Sign Up": "$21.18",
            "Cost per Deposit)": "$46.44"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "142795",
            "Clicks": "20278",
            "Sign Ups": "361",
            "Deposits": "204",
            "Cost per Sign Up": "$26.49",
            "Cost per Deposit)": "$35.57"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "140454",
            "Clicks": "19770",
            "Sign Ups": "262",
            "Deposits": "161",
            "Cost per Sign Up": "$29.61",
            "Cost per Deposit)": "$41.96"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "171524",
            "Clicks": "20022",
            "Sign Ups": "300",
            "Deposits": "216",
            "Cost per Sign Up": "$17.61",
            "Cost per Deposit)": "$43.65"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "151719",
            "Clicks": "19582",
            "Sign Ups": "404",
            "Deposits": "198",
            "Cost per Sign Up": "$13.43",
            "Cost per Deposit)": "$26.82"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "148717",
            "Clicks": "26387",
            "Sign Ups": "253",
            "Deposits": "142",
            "Cost per Sign Up": "$28.40",
            "Cost per Deposit)": "$54.37"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "208278",
            "Clicks": "26374",
            "Sign Ups": "289",
            "Deposits": "140",
            "Cost per Sign Up": "$24.31",
            "Cost per Deposit)": "$45.51"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "181478",
            "Clicks": "22637",
            "Sign Ups": "327",
            "Deposits": "205",
            "Cost per Sign Up": "$13.75",
            "Cost per Deposit)": "$35.37"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "146464",
            "Clicks": "16785",
            "Sign Ups": "389",
            "Deposits": "134",
            "Cost per Sign Up": "$17.45",
            "Cost per Deposit)": "$51.61"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "130902",
            "Clicks": "18368",
            "Sign Ups": "385",
            "Deposits": "223",
            "Cost per Sign Up": "$14.55",
            "Cost per Deposit)": "$46.81"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "215049",
            "Clicks": "25674",
            "Sign Ups": "408",
            "Deposits": "186",
            "Cost per Sign Up": "$17.95",
            "Cost per Deposit)": "$26.30"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "166003",
            "Clicks": "20516",
            "Sign Ups": "281",
            "Deposits": "175",
            "Cost per Sign Up": "$29.04",
            "Cost per Deposit)": "$34.43"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "152421",
            "Clicks": "15471",
            "Sign Ups": "447",
            "Deposits": "191",
            "Cost per Sign Up": "$23.52",
            "Cost per Deposit)": "$55.44"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "152008",
            "Clicks": "21379",
            "Sign Ups": "412",
            "Deposits": "132",
            "Cost per Sign Up": "$17.76",
            "Cost per Deposit)": "$43.69"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "149665",
            "Clicks": "26332",
            "Sign Ups": "252",
            "Deposits": "180",
            "Cost per Sign Up": "$29.21",
            "Cost per Deposit)": "$30.70"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "180802",
            "Clicks": "17267",
            "Sign Ups": "401",
            "Deposits": "139",
            "Cost per Sign Up": "$13.39",
            "Cost per Deposit)": "$33.52"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "204616",
            "Clicks": "19138",
            "Sign Ups": "447",
            "Deposits": "139",
            "Cost per Sign Up": "$22.79",
            "Cost per Deposit)": "$45.60"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "197507",
            "Clicks": "19690",
            "Sign Ups": "371",
            "Deposits": "137",
            "Cost per Sign Up": "$15.00",
            "Cost per Deposit)": "$35.48"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "205356",
            "Clicks": "22965",
            "Sign Ups": "293",
            "Deposits": "213",
            "Cost per Sign Up": "$27.87",
            "Cost per Deposit)": "$34.45"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "141096",
            "Clicks": "19871",
            "Sign Ups": "261",
            "Deposits": "224",
            "Cost per Sign Up": "$27.10",
            "Cost per Deposit)": "$28.49"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "207591",
            "Clicks": "27627",
            "Sign Ups": "424",
            "Deposits": "184",
            "Cost per Sign Up": "$12.11",
            "Cost per Deposit)": "$45.41"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "131990",
            "Clicks": "22727",
            "Sign Ups": "355",
            "Deposits": "210",
            "Cost per Sign Up": "$29.21",
            "Cost per Deposit)": "$20.50"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "204858",
            "Clicks": "29254",
            "Sign Ups": "408",
            "Deposits": "155",
            "Cost per Sign Up": "$16.09",
            "Cost per Deposit)": "$26.25"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "186733",
            "Clicks": "21831",
            "Sign Ups": "260",
            "Deposits": "147",
            "Cost per Sign Up": "$26.81",
            "Cost per Deposit)": "$41.05"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "157337",
            "Clicks": "15164",
            "Sign Ups": "327",
            "Deposits": "192",
            "Cost per Sign Up": "$22.56",
            "Cost per Deposit)": "$54.20"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "189278",
            "Clicks": "26786",
            "Sign Ups": "398",
            "Deposits": "147",
            "Cost per Sign Up": "$20.31",
            "Cost per Deposit)": "$47.38"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "139005",
            "Clicks": "24779",
            "Sign Ups": "438",
            "Deposits": "184",
            "Cost per Sign Up": "$17.04",
            "Cost per Deposit)": "$43.26"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "132447",
            "Clicks": "23488",
            "Sign Ups": "292",
            "Deposits": "147",
            "Cost per Sign Up": "$27.43",
            "Cost per Deposit)": "$22.95"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "171961",
            "Clicks": "23596",
            "Sign Ups": "273",
            "Deposits": "223",
            "Cost per Sign Up": "$19.37",
            "Cost per Deposit)": "$22.10"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "168624",
            "Clicks": "19064",
            "Sign Ups": "286",
            "Deposits": "198",
            "Cost per Sign Up": "$15.73",
            "Cost per Deposit)": "$32.06"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "194753",
            "Clicks": "20777",
            "Sign Ups": "374",
            "Deposits": "208",
            "Cost per Sign Up": "$25.18",
            "Cost per Deposit)": "$21.63"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "189626",
            "Clicks": "27540",
            "Sign Ups": "361",
            "Deposits": "198",
            "Cost per Sign Up": "$16.16",
            "Cost per Deposit)": "$34.53"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "170576",
            "Clicks": "20045",
            "Sign Ups": "386",
            "Deposits": "209",
            "Cost per Sign Up": "$22.13",
            "Cost per Deposit)": "$59.56"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "205248",
            "Clicks": "21234",
            "Sign Ups": "424",
            "Deposits": "199",
            "Cost per Sign Up": "$13.02",
            "Cost per Deposit)": "$48.88"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "184090",
            "Clicks": "19383",
            "Sign Ups": "340",
            "Deposits": "138",
            "Cost per Sign Up": "$22.09",
            "Cost per Deposit)": "$34.89"
          }
        ]
      },
      "Web3GamersData": {
        "name": "Web3 Gamers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "135783",
            "Clicks": "23387",
            "Sign Ups": "363",
            "Deposits": "127",
            "Cost per Sign Up": "$20.91",
            "Cost per Deposit)": "$50.28"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "148459",
            "Clicks": "15267",
            "Sign Ups": "332",
            "Deposits": "200",
            "Cost per Sign Up": "$23.83",
            "Cost per Deposit)": "$21.93"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "152670",
            "Clicks": "22072",
            "Sign Ups": "420",
            "Deposits": "198",
            "Cost per Sign Up": "$13.78",
            "Cost per Deposit)": "$58.28"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "193080",
            "Clicks": "17506",
            "Sign Ups": "343",
            "Deposits": "165",
            "Cost per Sign Up": "$12.70",
            "Cost per Deposit)": "$26.08"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "153772",
            "Clicks": "17791",
            "Sign Ups": "262",
            "Deposits": "125",
            "Cost per Sign Up": "$23.71",
            "Cost per Deposit)": "$29.56"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "207248",
            "Clicks": "26403",
            "Sign Ups": "260",
            "Deposits": "196",
            "Cost per Sign Up": "$27.78",
            "Cost per Deposit)": "$47.92"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "174699",
            "Clicks": "29247",
            "Sign Ups": "273",
            "Deposits": "202",
            "Cost per Sign Up": "$29.20",
            "Cost per Deposit)": "$49.66"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "168895",
            "Clicks": "24902",
            "Sign Ups": "435",
            "Deposits": "151",
            "Cost per Sign Up": "$29.15",
            "Cost per Deposit)": "$34.09"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "173724",
            "Clicks": "29325",
            "Sign Ups": "279",
            "Deposits": "146",
            "Cost per Sign Up": "$24.99",
            "Cost per Deposit)": "$44.86"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "186174",
            "Clicks": "20108",
            "Sign Ups": "388",
            "Deposits": "190",
            "Cost per Sign Up": "$22.73",
            "Cost per Deposit)": "$30.09"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "167610",
            "Clicks": "15770",
            "Sign Ups": "394",
            "Deposits": "199",
            "Cost per Sign Up": "$10.10",
            "Cost per Deposit)": "$20.22"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "204918",
            "Clicks": "23169",
            "Sign Ups": "288",
            "Deposits": "189",
            "Cost per Sign Up": "$15.96",
            "Cost per Deposit)": "$56.84"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "213448",
            "Clicks": "22248",
            "Sign Ups": "445",
            "Deposits": "131",
            "Cost per Sign Up": "$26.50",
            "Cost per Deposit)": "$55.25"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "205545",
            "Clicks": "22800",
            "Sign Ups": "306",
            "Deposits": "179",
            "Cost per Sign Up": "$19.85",
            "Cost per Deposit)": "$25.83"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "133244",
            "Clicks": "21275",
            "Sign Ups": "441",
            "Deposits": "126",
            "Cost per Sign Up": "$16.27",
            "Cost per Deposit)": "$58.81"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "219812",
            "Clicks": "21212",
            "Sign Ups": "312",
            "Deposits": "184",
            "Cost per Sign Up": "$17.52",
            "Cost per Deposit)": "$36.02"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "158567",
            "Clicks": "21784",
            "Sign Ups": "408",
            "Deposits": "200",
            "Cost per Sign Up": "$11.11",
            "Cost per Deposit)": "$37.15"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "207288",
            "Clicks": "22789",
            "Sign Ups": "293",
            "Deposits": "136",
            "Cost per Sign Up": "$20.12",
            "Cost per Deposit)": "$39.84"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "195219",
            "Clicks": "21769",
            "Sign Ups": "422",
            "Deposits": "215",
            "Cost per Sign Up": "$21.53",
            "Cost per Deposit)": "$35.04"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "177532",
            "Clicks": "29539",
            "Sign Ups": "309",
            "Deposits": "180",
            "Cost per Sign Up": "$27.35",
            "Cost per Deposit)": "$49.05"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "140345",
            "Clicks": "22371",
            "Sign Ups": "263",
            "Deposits": "141",
            "Cost per Sign Up": "$14.99",
            "Cost per Deposit)": "$24.09"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "136245",
            "Clicks": "17413",
            "Sign Ups": "383",
            "Deposits": "180",
            "Cost per Sign Up": "$15.57",
            "Cost per Deposit)": "$27.23"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "170774",
            "Clicks": "22816",
            "Sign Ups": "321",
            "Deposits": "169",
            "Cost per Sign Up": "$24.01",
            "Cost per Deposit)": "$42.91"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "209853",
            "Clicks": "22480",
            "Sign Ups": "365",
            "Deposits": "215",
            "Cost per Sign Up": "$28.58",
            "Cost per Deposit)": "$40.32"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "176077",
            "Clicks": "16127",
            "Sign Ups": "266",
            "Deposits": "188",
            "Cost per Sign Up": "$15.16",
            "Cost per Deposit)": "$52.34"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "141445",
            "Clicks": "17389",
            "Sign Ups": "431",
            "Deposits": "169",
            "Cost per Sign Up": "$10.27",
            "Cost per Deposit)": "$49.82"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "188670",
            "Clicks": "23589",
            "Sign Ups": "308",
            "Deposits": "204",
            "Cost per Sign Up": "$19.19",
            "Cost per Deposit)": "$40.30"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "153645",
            "Clicks": "29891",
            "Sign Ups": "412",
            "Deposits": "177",
            "Cost per Sign Up": "$15.95",
            "Cost per Deposit)": "$55.80"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "206995",
            "Clicks": "25435",
            "Sign Ups": "370",
            "Deposits": "208",
            "Cost per Sign Up": "$26.47",
            "Cost per Deposit)": "$22.00"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "198224",
            "Clicks": "25312",
            "Sign Ups": "318",
            "Deposits": "146",
            "Cost per Sign Up": "$27.76",
            "Cost per Deposit)": "$36.75"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "183919",
            "Clicks": "17367",
            "Sign Ups": "400",
            "Deposits": "127",
            "Cost per Sign Up": "$29.57",
            "Cost per Deposit)": "$53.92"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "194092",
            "Clicks": "17116",
            "Sign Ups": "303",
            "Deposits": "156",
            "Cost per Sign Up": "$24.59",
            "Cost per Deposit)": "$36.02"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "188259",
            "Clicks": "29644",
            "Sign Ups": "383",
            "Deposits": "149",
            "Cost per Sign Up": "$11.24",
            "Cost per Deposit)": "$57.26"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "211152",
            "Clicks": "15245",
            "Sign Ups": "438",
            "Deposits": "145",
            "Cost per Sign Up": "$29.23",
            "Cost per Deposit)": "$45.18"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "161878",
            "Clicks": "25142",
            "Sign Ups": "405",
            "Deposits": "166",
            "Cost per Sign Up": "$26.15",
            "Cost per Deposit)": "$26.38"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "177362",
            "Clicks": "23927",
            "Sign Ups": "251",
            "Deposits": "201",
            "Cost per Sign Up": "$22.01",
            "Cost per Deposit)": "$45.21"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "151835",
            "Clicks": "25727",
            "Sign Ups": "367",
            "Deposits": "146",
            "Cost per Sign Up": "$23.29",
            "Cost per Deposit)": "$39.36"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "135940",
            "Clicks": "27738",
            "Sign Ups": "398",
            "Deposits": "170",
            "Cost per Sign Up": "$17.28",
            "Cost per Deposit)": "$46.92"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "157432",
            "Clicks": "26158",
            "Sign Ups": "262",
            "Deposits": "206",
            "Cost per Sign Up": "$16.52",
            "Cost per Deposit)": "$25.30"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "132781",
            "Clicks": "24709",
            "Sign Ups": "251",
            "Deposits": "214",
            "Cost per Sign Up": "$14.91",
            "Cost per Deposit)": "$22.30"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "195850",
            "Clicks": "16028",
            "Sign Ups": "334",
            "Deposits": "156",
            "Cost per Sign Up": "$13.33",
            "Cost per Deposit)": "$32.72"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "175694",
            "Clicks": "29300",
            "Sign Ups": "336",
            "Deposits": "200",
            "Cost per Sign Up": "$23.79",
            "Cost per Deposit)": "$43.83"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "159834",
            "Clicks": "17522",
            "Sign Ups": "359",
            "Deposits": "194",
            "Cost per Sign Up": "$11.67",
            "Cost per Deposit)": "$34.03"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "175013",
            "Clicks": "28798",
            "Sign Ups": "389",
            "Deposits": "157",
            "Cost per Sign Up": "$19.55",
            "Cost per Deposit)": "$55.30"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "153347",
            "Clicks": "18211",
            "Sign Ups": "268",
            "Deposits": "179",
            "Cost per Sign Up": "$18.01",
            "Cost per Deposit)": "$46.98"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "153189",
            "Clicks": "27459",
            "Sign Ups": "309",
            "Deposits": "177",
            "Cost per Sign Up": "$17.14",
            "Cost per Deposit)": "$57.98"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "170145",
            "Clicks": "29363",
            "Sign Ups": "301",
            "Deposits": "135",
            "Cost per Sign Up": "$19.60",
            "Cost per Deposit)": "$59.23"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "171347",
            "Clicks": "26141",
            "Sign Ups": "320",
            "Deposits": "206",
            "Cost per Sign Up": "$25.75",
            "Cost per Deposit)": "$40.69"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "149295",
            "Clicks": "28047",
            "Sign Ups": "311",
            "Deposits": "135",
            "Cost per Sign Up": "$15.83",
            "Cost per Deposit)": "$56.76"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "136249",
            "Clicks": "21926",
            "Sign Ups": "325",
            "Deposits": "126",
            "Cost per Sign Up": "$14.22",
            "Cost per Deposit)": "$51.28"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "176150",
            "Clicks": "17482",
            "Sign Ups": "437",
            "Deposits": "155",
            "Cost per Sign Up": "$27.59",
            "Cost per Deposit)": "$22.89"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "167868",
            "Clicks": "24722",
            "Sign Ups": "432",
            "Deposits": "224",
            "Cost per Sign Up": "$28.39",
            "Cost per Deposit)": "$37.90"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "157144",
            "Clicks": "29834",
            "Sign Ups": "281",
            "Deposits": "142",
            "Cost per Sign Up": "$24.78",
            "Cost per Deposit)": "$36.43"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "137653",
            "Clicks": "24130",
            "Sign Ups": "317",
            "Deposits": "163",
            "Cost per Sign Up": "$25.45",
            "Cost per Deposit)": "$25.53"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "159143",
            "Clicks": "17035",
            "Sign Ups": "253",
            "Deposits": "153",
            "Cost per Sign Up": "$26.86",
            "Cost per Deposit)": "$54.46"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "130934",
            "Clicks": "27206",
            "Sign Ups": "351",
            "Deposits": "191",
            "Cost per Sign Up": "$27.54",
            "Cost per Deposit)": "$21.60"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "175282",
            "Clicks": "26951",
            "Sign Ups": "259",
            "Deposits": "137",
            "Cost per Sign Up": "$14.23",
            "Cost per Deposit)": "$38.12"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "164557",
            "Clicks": "26508",
            "Sign Ups": "346",
            "Deposits": "211",
            "Cost per Sign Up": "$17.32",
            "Cost per Deposit)": "$34.97"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "208001",
            "Clicks": "28365",
            "Sign Ups": "412",
            "Deposits": "217",
            "Cost per Sign Up": "$23.70",
            "Cost per Deposit)": "$43.19"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "177208",
            "Clicks": "20177",
            "Sign Ups": "415",
            "Deposits": "129",
            "Cost per Sign Up": "$28.31",
            "Cost per Deposit)": "$35.11"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "159336",
            "Clicks": "16386",
            "Sign Ups": "276",
            "Deposits": "147",
            "Cost per Sign Up": "$10.73",
            "Cost per Deposit)": "$49.24"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "184918",
            "Clicks": "24351",
            "Sign Ups": "291",
            "Deposits": "145",
            "Cost per Sign Up": "$26.49",
            "Cost per Deposit)": "$51.73"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "138648",
            "Clicks": "29922",
            "Sign Ups": "303",
            "Deposits": "207",
            "Cost per Sign Up": "$29.00",
            "Cost per Deposit)": "$52.24"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "146268",
            "Clicks": "15056",
            "Sign Ups": "328",
            "Deposits": "170",
            "Cost per Sign Up": "$25.38",
            "Cost per Deposit)": "$33.96"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "178396",
            "Clicks": "20981",
            "Sign Ups": "272",
            "Deposits": "184",
            "Cost per Sign Up": "$11.91",
            "Cost per Deposit)": "$29.76"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "180044",
            "Clicks": "26815",
            "Sign Ups": "344",
            "Deposits": "212",
            "Cost per Sign Up": "$21.47",
            "Cost per Deposit)": "$38.83"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "211163",
            "Clicks": "18007",
            "Sign Ups": "272",
            "Deposits": "203",
            "Cost per Sign Up": "$24.78",
            "Cost per Deposit)": "$32.35"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "194775",
            "Clicks": "20390",
            "Sign Ups": "414",
            "Deposits": "183",
            "Cost per Sign Up": "$13.22",
            "Cost per Deposit)": "$21.38"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "202001",
            "Clicks": "16661",
            "Sign Ups": "340",
            "Deposits": "182",
            "Cost per Sign Up": "$25.82",
            "Cost per Deposit)": "$29.42"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "213054",
            "Clicks": "24811",
            "Sign Ups": "259",
            "Deposits": "178",
            "Cost per Sign Up": "$23.99",
            "Cost per Deposit)": "$45.84"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "152523",
            "Clicks": "24016",
            "Sign Ups": "313",
            "Deposits": "168",
            "Cost per Sign Up": "$10.74",
            "Cost per Deposit)": "$46.85"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "180064",
            "Clicks": "20608",
            "Sign Ups": "370",
            "Deposits": "176",
            "Cost per Sign Up": "$22.81",
            "Cost per Deposit)": "$55.70"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "149974",
            "Clicks": "19022",
            "Sign Ups": "377",
            "Deposits": "161",
            "Cost per Sign Up": "$23.83",
            "Cost per Deposit)": "$21.73"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "172777",
            "Clicks": "21974",
            "Sign Ups": "435",
            "Deposits": "128",
            "Cost per Sign Up": "$10.40",
            "Cost per Deposit)": "$48.85"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "190352",
            "Clicks": "26874",
            "Sign Ups": "438",
            "Deposits": "149",
            "Cost per Sign Up": "$16.22",
            "Cost per Deposit)": "$48.92"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "164100",
            "Clicks": "16619",
            "Sign Ups": "266",
            "Deposits": "214",
            "Cost per Sign Up": "$28.47",
            "Cost per Deposit)": "$34.78"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "209062",
            "Clicks": "29319",
            "Sign Ups": "281",
            "Deposits": "193",
            "Cost per Sign Up": "$14.55",
            "Cost per Deposit)": "$24.14"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "171414",
            "Clicks": "15721",
            "Sign Ups": "390",
            "Deposits": "174",
            "Cost per Sign Up": "$15.69",
            "Cost per Deposit)": "$50.02"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "161844",
            "Clicks": "26519",
            "Sign Ups": "413",
            "Deposits": "142",
            "Cost per Sign Up": "$23.96",
            "Cost per Deposit)": "$58.21"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "215779",
            "Clicks": "23749",
            "Sign Ups": "297",
            "Deposits": "194",
            "Cost per Sign Up": "$14.35",
            "Cost per Deposit)": "$33.89"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "198725",
            "Clicks": "24240",
            "Sign Ups": "287",
            "Deposits": "140",
            "Cost per Sign Up": "$13.47",
            "Cost per Deposit)": "$42.99"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "172142",
            "Clicks": "18578",
            "Sign Ups": "399",
            "Deposits": "133",
            "Cost per Sign Up": "$17.17",
            "Cost per Deposit)": "$30.40"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "142529",
            "Clicks": "28426",
            "Sign Ups": "434",
            "Deposits": "222",
            "Cost per Sign Up": "$10.76",
            "Cost per Deposit)": "$45.53"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "210726",
            "Clicks": "27437",
            "Sign Ups": "285",
            "Deposits": "126",
            "Cost per Sign Up": "$18.78",
            "Cost per Deposit)": "$30.35"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "173413",
            "Clicks": "26684",
            "Sign Ups": "404",
            "Deposits": "172",
            "Cost per Sign Up": "$18.32",
            "Cost per Deposit)": "$26.60"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "209782",
            "Clicks": "25427",
            "Sign Ups": "380",
            "Deposits": "214",
            "Cost per Sign Up": "$23.46",
            "Cost per Deposit)": "$35.97"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "181513",
            "Clicks": "19564",
            "Sign Ups": "419",
            "Deposits": "131",
            "Cost per Sign Up": "$27.20",
            "Cost per Deposit)": "$33.27"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "162929",
            "Clicks": "26956",
            "Sign Ups": "383",
            "Deposits": "151",
            "Cost per Sign Up": "$27.32",
            "Cost per Deposit)": "$52.41"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "150181",
            "Clicks": "26276",
            "Sign Ups": "379",
            "Deposits": "139",
            "Cost per Sign Up": "$23.89",
            "Cost per Deposit)": "$51.75"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "193613",
            "Clicks": "25223",
            "Sign Ups": "414",
            "Deposits": "144",
            "Cost per Sign Up": "$15.12",
            "Cost per Deposit)": "$48.16"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "133411",
            "Clicks": "22495",
            "Sign Ups": "432",
            "Deposits": "168",
            "Cost per Sign Up": "$13.41",
            "Cost per Deposit)": "$53.04"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "211824",
            "Clicks": "17021",
            "Sign Ups": "328",
            "Deposits": "181",
            "Cost per Sign Up": "$20.12",
            "Cost per Deposit)": "$59.25"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "148597",
            "Clicks": "28414",
            "Sign Ups": "294",
            "Deposits": "146",
            "Cost per Sign Up": "$21.58",
            "Cost per Deposit)": "$46.16"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "196488",
            "Clicks": "20870",
            "Sign Ups": "259",
            "Deposits": "134",
            "Cost per Sign Up": "$17.99",
            "Cost per Deposit)": "$28.26"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "192321",
            "Clicks": "26872",
            "Sign Ups": "397",
            "Deposits": "172",
            "Cost per Sign Up": "$21.62",
            "Cost per Deposit)": "$33.49"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "151761",
            "Clicks": "26679",
            "Sign Ups": "387",
            "Deposits": "179",
            "Cost per Sign Up": "$18.08",
            "Cost per Deposit)": "$35.63"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "204563",
            "Clicks": "29310",
            "Sign Ups": "351",
            "Deposits": "158",
            "Cost per Sign Up": "$28.72",
            "Cost per Deposit)": "$23.81"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "148501",
            "Clicks": "23510",
            "Sign Ups": "355",
            "Deposits": "159",
            "Cost per Sign Up": "$17.40",
            "Cost per Deposit)": "$29.41"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "179838",
            "Clicks": "21174",
            "Sign Ups": "287",
            "Deposits": "154",
            "Cost per Sign Up": "$26.97",
            "Cost per Deposit)": "$57.97"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "158218",
            "Clicks": "29162",
            "Sign Ups": "421",
            "Deposits": "155",
            "Cost per Sign Up": "$13.13",
            "Cost per Deposit)": "$26.56"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "150867",
            "Clicks": "26740",
            "Sign Ups": "443",
            "Deposits": "159",
            "Cost per Sign Up": "$20.62",
            "Cost per Deposit)": "$39.83"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "156641",
            "Clicks": "22342",
            "Sign Ups": "284",
            "Deposits": "146",
            "Cost per Sign Up": "$22.29",
            "Cost per Deposit)": "$20.26"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "187430",
            "Clicks": "27184",
            "Sign Ups": "356",
            "Deposits": "166",
            "Cost per Sign Up": "$17.92",
            "Cost per Deposit)": "$55.13"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "206506",
            "Clicks": "20597",
            "Sign Ups": "391",
            "Deposits": "139",
            "Cost per Sign Up": "$13.39",
            "Cost per Deposit)": "$26.38"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "205112",
            "Clicks": "22090",
            "Sign Ups": "319",
            "Deposits": "155",
            "Cost per Sign Up": "$14.52",
            "Cost per Deposit)": "$58.47"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "174082",
            "Clicks": "28325",
            "Sign Ups": "450",
            "Deposits": "188",
            "Cost per Sign Up": "$21.68",
            "Cost per Deposit)": "$21.40"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "173224",
            "Clicks": "25484",
            "Sign Ups": "388",
            "Deposits": "201",
            "Cost per Sign Up": "$25.28",
            "Cost per Deposit)": "$25.08"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "174321",
            "Clicks": "24474",
            "Sign Ups": "396",
            "Deposits": "154",
            "Cost per Sign Up": "$21.06",
            "Cost per Deposit)": "$51.43"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "189943",
            "Clicks": "23725",
            "Sign Ups": "359",
            "Deposits": "157",
            "Cost per Sign Up": "$18.37",
            "Cost per Deposit)": "$29.93"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "164106",
            "Clicks": "29666",
            "Sign Ups": "428",
            "Deposits": "200",
            "Cost per Sign Up": "$23.04",
            "Cost per Deposit)": "$38.24"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "194715",
            "Clicks": "27162",
            "Sign Ups": "282",
            "Deposits": "173",
            "Cost per Sign Up": "$14.01",
            "Cost per Deposit)": "$39.69"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "198995",
            "Clicks": "27600",
            "Sign Ups": "414",
            "Deposits": "166",
            "Cost per Sign Up": "$11.98",
            "Cost per Deposit)": "$46.43"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "131481",
            "Clicks": "24729",
            "Sign Ups": "328",
            "Deposits": "147",
            "Cost per Sign Up": "$10.18",
            "Cost per Deposit)": "$51.47"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "153950",
            "Clicks": "23914",
            "Sign Ups": "350",
            "Deposits": "153",
            "Cost per Sign Up": "$21.72",
            "Cost per Deposit)": "$43.72"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "191776",
            "Clicks": "19820",
            "Sign Ups": "449",
            "Deposits": "200",
            "Cost per Sign Up": "$26.65",
            "Cost per Deposit)": "$47.36"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "163991",
            "Clicks": "22052",
            "Sign Ups": "446",
            "Deposits": "159",
            "Cost per Sign Up": "$20.17",
            "Cost per Deposit)": "$22.46"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "219289",
            "Clicks": "24749",
            "Sign Ups": "286",
            "Deposits": "156",
            "Cost per Sign Up": "$13.65",
            "Cost per Deposit)": "$22.30"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "133352",
            "Clicks": "20743",
            "Sign Ups": "435",
            "Deposits": "212",
            "Cost per Sign Up": "$24.05",
            "Cost per Deposit)": "$39.54"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "138904",
            "Clicks": "15089",
            "Sign Ups": "397",
            "Deposits": "223",
            "Cost per Sign Up": "$28.36",
            "Cost per Deposit)": "$41.39"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "196887",
            "Clicks": "26100",
            "Sign Ups": "437",
            "Deposits": "157",
            "Cost per Sign Up": "$26.93",
            "Cost per Deposit)": "$37.68"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "135232",
            "Clicks": "20853",
            "Sign Ups": "313",
            "Deposits": "142",
            "Cost per Sign Up": "$24.49",
            "Cost per Deposit)": "$54.65"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "191020",
            "Clicks": "16068",
            "Sign Ups": "286",
            "Deposits": "140",
            "Cost per Sign Up": "$12.47",
            "Cost per Deposit)": "$46.23"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "131669",
            "Clicks": "29964",
            "Sign Ups": "252",
            "Deposits": "203",
            "Cost per Sign Up": "$24.44",
            "Cost per Deposit)": "$55.17"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "152820",
            "Clicks": "25535",
            "Sign Ups": "346",
            "Deposits": "146",
            "Cost per Sign Up": "$15.44",
            "Cost per Deposit)": "$31.93"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "179237",
            "Clicks": "16160",
            "Sign Ups": "283",
            "Deposits": "129",
            "Cost per Sign Up": "$18.60",
            "Cost per Deposit)": "$29.63"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "159813",
            "Clicks": "17133",
            "Sign Ups": "275",
            "Deposits": "219",
            "Cost per Sign Up": "$20.87",
            "Cost per Deposit)": "$20.66"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "149968",
            "Clicks": "15549",
            "Sign Ups": "349",
            "Deposits": "182",
            "Cost per Sign Up": "$18.60",
            "Cost per Deposit)": "$48.59"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "173269",
            "Clicks": "20037",
            "Sign Ups": "323",
            "Deposits": "125",
            "Cost per Sign Up": "$14.14",
            "Cost per Deposit)": "$32.68"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "167030",
            "Clicks": "22433",
            "Sign Ups": "396",
            "Deposits": "148",
            "Cost per Sign Up": "$17.90",
            "Cost per Deposit)": "$51.64"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "218033",
            "Clicks": "24445",
            "Sign Ups": "261",
            "Deposits": "217",
            "Cost per Sign Up": "$20.24",
            "Cost per Deposit)": "$53.65"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "219887",
            "Clicks": "24136",
            "Sign Ups": "400",
            "Deposits": "197",
            "Cost per Sign Up": "$17.44",
            "Cost per Deposit)": "$46.84"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "169855",
            "Clicks": "27318",
            "Sign Ups": "345",
            "Deposits": "211",
            "Cost per Sign Up": "$19.43",
            "Cost per Deposit)": "$21.90"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "148760",
            "Clicks": "18791",
            "Sign Ups": "267",
            "Deposits": "145",
            "Cost per Sign Up": "$22.56",
            "Cost per Deposit)": "$34.49"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "189888",
            "Clicks": "20075",
            "Sign Ups": "431",
            "Deposits": "223",
            "Cost per Sign Up": "$20.74",
            "Cost per Deposit)": "$40.89"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "146704",
            "Clicks": "18939",
            "Sign Ups": "329",
            "Deposits": "174",
            "Cost per Sign Up": "$20.31",
            "Cost per Deposit)": "$46.47"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "136877",
            "Clicks": "25673",
            "Sign Ups": "353",
            "Deposits": "191",
            "Cost per Sign Up": "$17.39",
            "Cost per Deposit)": "$32.25"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "167584",
            "Clicks": "23357",
            "Sign Ups": "252",
            "Deposits": "211",
            "Cost per Sign Up": "$19.52",
            "Cost per Deposit)": "$49.29"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "192956",
            "Clicks": "22835",
            "Sign Ups": "280",
            "Deposits": "155",
            "Cost per Sign Up": "$14.69",
            "Cost per Deposit)": "$27.24"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "213910",
            "Clicks": "25392",
            "Sign Ups": "334",
            "Deposits": "209",
            "Cost per Sign Up": "$26.96",
            "Cost per Deposit)": "$52.62"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "154264",
            "Clicks": "28394",
            "Sign Ups": "390",
            "Deposits": "173",
            "Cost per Sign Up": "$17.27",
            "Cost per Deposit)": "$25.68"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "194234",
            "Clicks": "27801",
            "Sign Ups": "306",
            "Deposits": "159",
            "Cost per Sign Up": "$26.12",
            "Cost per Deposit)": "$56.56"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "164219",
            "Clicks": "29120",
            "Sign Ups": "357",
            "Deposits": "141",
            "Cost per Sign Up": "$16.99",
            "Cost per Deposit)": "$37.31"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "156959",
            "Clicks": "28481",
            "Sign Ups": "312",
            "Deposits": "141",
            "Cost per Sign Up": "$25.58",
            "Cost per Deposit)": "$37.78"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "144712",
            "Clicks": "16033",
            "Sign Ups": "358",
            "Deposits": "175",
            "Cost per Sign Up": "$25.21",
            "Cost per Deposit)": "$38.99"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "196930",
            "Clicks": "21581",
            "Sign Ups": "341",
            "Deposits": "176",
            "Cost per Sign Up": "$25.28",
            "Cost per Deposit)": "$34.28"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "189172",
            "Clicks": "18896",
            "Sign Ups": "433",
            "Deposits": "141",
            "Cost per Sign Up": "$24.23",
            "Cost per Deposit)": "$24.62"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "155958",
            "Clicks": "27336",
            "Sign Ups": "425",
            "Deposits": "213",
            "Cost per Sign Up": "$13.10",
            "Cost per Deposit)": "$53.97"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "214961",
            "Clicks": "15052",
            "Sign Ups": "377",
            "Deposits": "164",
            "Cost per Sign Up": "$25.64",
            "Cost per Deposit)": "$37.79"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "155998",
            "Clicks": "28029",
            "Sign Ups": "434",
            "Deposits": "216",
            "Cost per Sign Up": "$13.69",
            "Cost per Deposit)": "$58.48"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "180372",
            "Clicks": "29304",
            "Sign Ups": "281",
            "Deposits": "175",
            "Cost per Sign Up": "$12.08",
            "Cost per Deposit)": "$37.10"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "158436",
            "Clicks": "21093",
            "Sign Ups": "317",
            "Deposits": "184",
            "Cost per Sign Up": "$26.28",
            "Cost per Deposit)": "$28.76"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "131290",
            "Clicks": "28607",
            "Sign Ups": "277",
            "Deposits": "128",
            "Cost per Sign Up": "$20.14",
            "Cost per Deposit)": "$24.55"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "169100",
            "Clicks": "23429",
            "Sign Ups": "415",
            "Deposits": "175",
            "Cost per Sign Up": "$18.38",
            "Cost per Deposit)": "$34.52"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "162814",
            "Clicks": "18676",
            "Sign Ups": "350",
            "Deposits": "221",
            "Cost per Sign Up": "$21.46",
            "Cost per Deposit)": "$48.39"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "171974",
            "Clicks": "28944",
            "Sign Ups": "282",
            "Deposits": "219",
            "Cost per Sign Up": "$27.43",
            "Cost per Deposit)": "$50.37"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "153678",
            "Clicks": "17416",
            "Sign Ups": "389",
            "Deposits": "201",
            "Cost per Sign Up": "$18.56",
            "Cost per Deposit)": "$35.69"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "176033",
            "Clicks": "22816",
            "Sign Ups": "387",
            "Deposits": "135",
            "Cost per Sign Up": "$18.33",
            "Cost per Deposit)": "$35.11"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "153562",
            "Clicks": "16516",
            "Sign Ups": "345",
            "Deposits": "164",
            "Cost per Sign Up": "$28.41",
            "Cost per Deposit)": "$22.63"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "136528",
            "Clicks": "27285",
            "Sign Ups": "396",
            "Deposits": "141",
            "Cost per Sign Up": "$11.70",
            "Cost per Deposit)": "$37.25"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "141302",
            "Clicks": "15649",
            "Sign Ups": "426",
            "Deposits": "199",
            "Cost per Sign Up": "$25.64",
            "Cost per Deposit)": "$59.26"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "141770",
            "Clicks": "22359",
            "Sign Ups": "417",
            "Deposits": "186",
            "Cost per Sign Up": "$17.97",
            "Cost per Deposit)": "$54.72"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "192159",
            "Clicks": "29934",
            "Sign Ups": "361",
            "Deposits": "147",
            "Cost per Sign Up": "$10.28",
            "Cost per Deposit)": "$24.29"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "158045",
            "Clicks": "27985",
            "Sign Ups": "360",
            "Deposits": "181",
            "Cost per Sign Up": "$24.30",
            "Cost per Deposit)": "$35.53"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "154524",
            "Clicks": "18544",
            "Sign Ups": "320",
            "Deposits": "212",
            "Cost per Sign Up": "$18.04",
            "Cost per Deposit)": "$46.82"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "182588",
            "Clicks": "17993",
            "Sign Ups": "442",
            "Deposits": "184",
            "Cost per Sign Up": "$16.98",
            "Cost per Deposit)": "$40.45"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "186548",
            "Clicks": "29490",
            "Sign Ups": "365",
            "Deposits": "188",
            "Cost per Sign Up": "$10.88",
            "Cost per Deposit)": "$35.51"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "178133",
            "Clicks": "28928",
            "Sign Ups": "318",
            "Deposits": "180",
            "Cost per Sign Up": "$15.38",
            "Cost per Deposit)": "$30.06"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "134929",
            "Clicks": "25215",
            "Sign Ups": "394",
            "Deposits": "225",
            "Cost per Sign Up": "$21.06",
            "Cost per Deposit)": "$42.15"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "152939",
            "Clicks": "23286",
            "Sign Ups": "283",
            "Deposits": "217",
            "Cost per Sign Up": "$27.02",
            "Cost per Deposit)": "$25.87"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "206435",
            "Clicks": "22083",
            "Sign Ups": "330",
            "Deposits": "225",
            "Cost per Sign Up": "$11.43",
            "Cost per Deposit)": "$39.88"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "219466",
            "Clicks": "26320",
            "Sign Ups": "342",
            "Deposits": "148",
            "Cost per Sign Up": "$18.09",
            "Cost per Deposit)": "$55.17"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "155495",
            "Clicks": "26584",
            "Sign Ups": "347",
            "Deposits": "171",
            "Cost per Sign Up": "$12.28",
            "Cost per Deposit)": "$43.76"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "170062",
            "Clicks": "26487",
            "Sign Ups": "429",
            "Deposits": "145",
            "Cost per Sign Up": "$20.08",
            "Cost per Deposit)": "$36.94"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "182337",
            "Clicks": "25573",
            "Sign Ups": "325",
            "Deposits": "131",
            "Cost per Sign Up": "$10.72",
            "Cost per Deposit)": "$34.91"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "172878",
            "Clicks": "22574",
            "Sign Ups": "403",
            "Deposits": "199",
            "Cost per Sign Up": "$10.82",
            "Cost per Deposit)": "$26.57"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "177575",
            "Clicks": "29735",
            "Sign Ups": "358",
            "Deposits": "209",
            "Cost per Sign Up": "$20.14",
            "Cost per Deposit)": "$39.84"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "149222",
            "Clicks": "22264",
            "Sign Ups": "446",
            "Deposits": "208",
            "Cost per Sign Up": "$15.61",
            "Cost per Deposit)": "$38.03"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "133949",
            "Clicks": "19280",
            "Sign Ups": "449",
            "Deposits": "141",
            "Cost per Sign Up": "$27.90",
            "Cost per Deposit)": "$24.20"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "140123",
            "Clicks": "29077",
            "Sign Ups": "261",
            "Deposits": "139",
            "Cost per Sign Up": "$18.66",
            "Cost per Deposit)": "$48.17"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "209992",
            "Clicks": "26209",
            "Sign Ups": "338",
            "Deposits": "141",
            "Cost per Sign Up": "$29.50",
            "Cost per Deposit)": "$21.29"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "164973",
            "Clicks": "27729",
            "Sign Ups": "432",
            "Deposits": "152",
            "Cost per Sign Up": "$23.70",
            "Cost per Deposit)": "$21.57"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "135823",
            "Clicks": "26208",
            "Sign Ups": "369",
            "Deposits": "130",
            "Cost per Sign Up": "$24.28",
            "Cost per Deposit)": "$36.44"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "155722",
            "Clicks": "29686",
            "Sign Ups": "325",
            "Deposits": "196",
            "Cost per Sign Up": "$20.96",
            "Cost per Deposit)": "$55.66"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "138274",
            "Clicks": "27800",
            "Sign Ups": "441",
            "Deposits": "219",
            "Cost per Sign Up": "$13.34",
            "Cost per Deposit)": "$52.41"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "212823",
            "Clicks": "20123",
            "Sign Ups": "265",
            "Deposits": "154",
            "Cost per Sign Up": "$20.40",
            "Cost per Deposit)": "$55.86"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "161908",
            "Clicks": "26961",
            "Sign Ups": "436",
            "Deposits": "168",
            "Cost per Sign Up": "$16.98",
            "Cost per Deposit)": "$59.58"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "142415",
            "Clicks": "23469",
            "Sign Ups": "355",
            "Deposits": "221",
            "Cost per Sign Up": "$22.19",
            "Cost per Deposit)": "$23.53"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "208811",
            "Clicks": "24309",
            "Sign Ups": "376",
            "Deposits": "153",
            "Cost per Sign Up": "$20.12",
            "Cost per Deposit)": "$58.29"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "141326",
            "Clicks": "29841",
            "Sign Ups": "327",
            "Deposits": "140",
            "Cost per Sign Up": "$17.85",
            "Cost per Deposit)": "$53.45"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "133001",
            "Clicks": "16780",
            "Sign Ups": "309",
            "Deposits": "150",
            "Cost per Sign Up": "$26.21",
            "Cost per Deposit)": "$26.92"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "187358",
            "Clicks": "18922",
            "Sign Ups": "269",
            "Deposits": "222",
            "Cost per Sign Up": "$11.05",
            "Cost per Deposit)": "$22.94"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "203533",
            "Clicks": "28451",
            "Sign Ups": "378",
            "Deposits": "194",
            "Cost per Sign Up": "$19.42",
            "Cost per Deposit)": "$34.67"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "146753",
            "Clicks": "17370",
            "Sign Ups": "282",
            "Deposits": "200",
            "Cost per Sign Up": "$26.46",
            "Cost per Deposit)": "$46.74"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "192798",
            "Clicks": "20856",
            "Sign Ups": "312",
            "Deposits": "142",
            "Cost per Sign Up": "$20.67",
            "Cost per Deposit)": "$45.97"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "180380",
            "Clicks": "16773",
            "Sign Ups": "286",
            "Deposits": "224",
            "Cost per Sign Up": "$19.85",
            "Cost per Deposit)": "$54.69"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "145647",
            "Clicks": "18674",
            "Sign Ups": "370",
            "Deposits": "149",
            "Cost per Sign Up": "$25.36",
            "Cost per Deposit)": "$57.86"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "171207",
            "Clicks": "26279",
            "Sign Ups": "380",
            "Deposits": "139",
            "Cost per Sign Up": "$29.64",
            "Cost per Deposit)": "$57.12"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188880",
            "Clicks": "19265",
            "Sign Ups": "315",
            "Deposits": "186",
            "Cost per Sign Up": "$21.14",
            "Cost per Deposit)": "$41.16"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "131378",
            "Clicks": "24371",
            "Sign Ups": "360",
            "Deposits": "130",
            "Cost per Sign Up": "$19.81",
            "Cost per Deposit)": "$35.33"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "205860",
            "Clicks": "26120",
            "Sign Ups": "255",
            "Deposits": "181",
            "Cost per Sign Up": "$16.65",
            "Cost per Deposit)": "$51.10"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "200378",
            "Clicks": "16433",
            "Sign Ups": "421",
            "Deposits": "147",
            "Cost per Sign Up": "$22.40",
            "Cost per Deposit)": "$20.69"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "214744",
            "Clicks": "21301",
            "Sign Ups": "339",
            "Deposits": "176",
            "Cost per Sign Up": "$10.81",
            "Cost per Deposit)": "$48.98"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "161253",
            "Clicks": "21723",
            "Sign Ups": "450",
            "Deposits": "222",
            "Cost per Sign Up": "$11.36",
            "Cost per Deposit)": "$23.55"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "151595",
            "Clicks": "22150",
            "Sign Ups": "436",
            "Deposits": "184",
            "Cost per Sign Up": "$14.95",
            "Cost per Deposit)": "$29.05"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "218915",
            "Clicks": "17382",
            "Sign Ups": "351",
            "Deposits": "207",
            "Cost per Sign Up": "$24.37",
            "Cost per Deposit)": "$46.52"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "167740",
            "Clicks": "24822",
            "Sign Ups": "299",
            "Deposits": "166",
            "Cost per Sign Up": "$22.90",
            "Cost per Deposit)": "$36.23"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "159636",
            "Clicks": "27350",
            "Sign Ups": "418",
            "Deposits": "218",
            "Cost per Sign Up": "$28.54",
            "Cost per Deposit)": "$20.05"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "198745",
            "Clicks": "18061",
            "Sign Ups": "253",
            "Deposits": "216",
            "Cost per Sign Up": "$29.15",
            "Cost per Deposit)": "$52.52"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "149848",
            "Clicks": "16077",
            "Sign Ups": "326",
            "Deposits": "181",
            "Cost per Sign Up": "$24.89",
            "Cost per Deposit)": "$51.77"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "175695",
            "Clicks": "24357",
            "Sign Ups": "346",
            "Deposits": "144",
            "Cost per Sign Up": "$10.95",
            "Cost per Deposit)": "$32.04"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "183129",
            "Clicks": "16322",
            "Sign Ups": "296",
            "Deposits": "157",
            "Cost per Sign Up": "$15.87",
            "Cost per Deposit)": "$56.22"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "176669",
            "Clicks": "23492",
            "Sign Ups": "337",
            "Deposits": "161",
            "Cost per Sign Up": "$22.07",
            "Cost per Deposit)": "$23.34"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "179460",
            "Clicks": "16045",
            "Sign Ups": "429",
            "Deposits": "161",
            "Cost per Sign Up": "$13.65",
            "Cost per Deposit)": "$23.80"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "141854",
            "Clicks": "17268",
            "Sign Ups": "373",
            "Deposits": "205",
            "Cost per Sign Up": "$15.45",
            "Cost per Deposit)": "$49.36"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "139759",
            "Clicks": "28187",
            "Sign Ups": "306",
            "Deposits": "168",
            "Cost per Sign Up": "$13.01",
            "Cost per Deposit)": "$45.02"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "142346",
            "Clicks": "29874",
            "Sign Ups": "269",
            "Deposits": "221",
            "Cost per Sign Up": "$28.48",
            "Cost per Deposit)": "$53.17"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "180494",
            "Clicks": "16556",
            "Sign Ups": "285",
            "Deposits": "210",
            "Cost per Sign Up": "$13.46",
            "Cost per Deposit)": "$40.47"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "133842",
            "Clicks": "15052",
            "Sign Ups": "359",
            "Deposits": "161",
            "Cost per Sign Up": "$21.21",
            "Cost per Deposit)": "$43.73"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "168927",
            "Clicks": "28863",
            "Sign Ups": "280",
            "Deposits": "192",
            "Cost per Sign Up": "$17.30",
            "Cost per Deposit)": "$42.34"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "208846",
            "Clicks": "24902",
            "Sign Ups": "340",
            "Deposits": "132",
            "Cost per Sign Up": "$28.12",
            "Cost per Deposit)": "$49.12"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "154753",
            "Clicks": "20437",
            "Sign Ups": "347",
            "Deposits": "200",
            "Cost per Sign Up": "$23.57",
            "Cost per Deposit)": "$57.62"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "217315",
            "Clicks": "22105",
            "Sign Ups": "376",
            "Deposits": "148",
            "Cost per Sign Up": "$14.75",
            "Cost per Deposit)": "$54.86"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "131248",
            "Clicks": "18091",
            "Sign Ups": "390",
            "Deposits": "194",
            "Cost per Sign Up": "$27.92",
            "Cost per Deposit)": "$37.67"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "186640",
            "Clicks": "28486",
            "Sign Ups": "257",
            "Deposits": "196",
            "Cost per Sign Up": "$16.26",
            "Cost per Deposit)": "$56.12"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "200748",
            "Clicks": "27835",
            "Sign Ups": "392",
            "Deposits": "172",
            "Cost per Sign Up": "$21.80",
            "Cost per Deposit)": "$26.65"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "219138",
            "Clicks": "21787",
            "Sign Ups": "256",
            "Deposits": "137",
            "Cost per Sign Up": "$18.06",
            "Cost per Deposit)": "$20.93"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "189370",
            "Clicks": "22630",
            "Sign Ups": "272",
            "Deposits": "205",
            "Cost per Sign Up": "$26.50",
            "Cost per Deposit)": "$40.16"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "143634",
            "Clicks": "29985",
            "Sign Ups": "285",
            "Deposits": "208",
            "Cost per Sign Up": "$15.57",
            "Cost per Deposit)": "$57.66"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "173283",
            "Clicks": "15491",
            "Sign Ups": "296",
            "Deposits": "176",
            "Cost per Sign Up": "$21.63",
            "Cost per Deposit)": "$55.55"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "138091",
            "Clicks": "29495",
            "Sign Ups": "358",
            "Deposits": "187",
            "Cost per Sign Up": "$15.63",
            "Cost per Deposit)": "$26.16"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "189122",
            "Clicks": "15019",
            "Sign Ups": "281",
            "Deposits": "211",
            "Cost per Sign Up": "$18.50",
            "Cost per Deposit)": "$49.34"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "181290",
            "Clicks": "25694",
            "Sign Ups": "251",
            "Deposits": "213",
            "Cost per Sign Up": "$17.80",
            "Cost per Deposit)": "$45.46"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "179678",
            "Clicks": "16954",
            "Sign Ups": "311",
            "Deposits": "154",
            "Cost per Sign Up": "$16.26",
            "Cost per Deposit)": "$32.51"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "143303",
            "Clicks": "24687",
            "Sign Ups": "254",
            "Deposits": "178",
            "Cost per Sign Up": "$25.64",
            "Cost per Deposit)": "$58.62"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "180144",
            "Clicks": "23586",
            "Sign Ups": "343",
            "Deposits": "192",
            "Cost per Sign Up": "$27.43",
            "Cost per Deposit)": "$59.42"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "149688",
            "Clicks": "24580",
            "Sign Ups": "342",
            "Deposits": "142",
            "Cost per Sign Up": "$11.42",
            "Cost per Deposit)": "$23.62"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "201633",
            "Clicks": "23597",
            "Sign Ups": "282",
            "Deposits": "182",
            "Cost per Sign Up": "$26.06",
            "Cost per Deposit)": "$35.02"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "171967",
            "Clicks": "18370",
            "Sign Ups": "325",
            "Deposits": "141",
            "Cost per Sign Up": "$12.74",
            "Cost per Deposit)": "$38.60"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "216533",
            "Clicks": "16164",
            "Sign Ups": "288",
            "Deposits": "188",
            "Cost per Sign Up": "$19.39",
            "Cost per Deposit)": "$22.02"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "214002",
            "Clicks": "19112",
            "Sign Ups": "420",
            "Deposits": "145",
            "Cost per Sign Up": "$22.47",
            "Cost per Deposit)": "$53.53"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "162682",
            "Clicks": "19350",
            "Sign Ups": "428",
            "Deposits": "219",
            "Cost per Sign Up": "$17.95",
            "Cost per Deposit)": "$25.08"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "178215",
            "Clicks": "29447",
            "Sign Ups": "450",
            "Deposits": "167",
            "Cost per Sign Up": "$19.95",
            "Cost per Deposit)": "$47.04"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "210970",
            "Clicks": "23014",
            "Sign Ups": "450",
            "Deposits": "212",
            "Cost per Sign Up": "$19.73",
            "Cost per Deposit)": "$43.88"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "148460",
            "Clicks": "26740",
            "Sign Ups": "333",
            "Deposits": "202",
            "Cost per Sign Up": "$29.47",
            "Cost per Deposit)": "$33.61"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "148632",
            "Clicks": "18701",
            "Sign Ups": "265",
            "Deposits": "175",
            "Cost per Sign Up": "$25.79",
            "Cost per Deposit)": "$27.37"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "214465",
            "Clicks": "27600",
            "Sign Ups": "412",
            "Deposits": "153",
            "Cost per Sign Up": "$29.97",
            "Cost per Deposit)": "$58.71"
          }
        ]
      },
      "BlockchainGamingData": {
        "name": "Blockchain Gaming",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "140907",
            "Clicks": "25,396",
            "Sign Ups": "350",
            "Deposits": "202",
            "Cost per Sign Up": "$29.83",
            "Cost per Deposit)": "$54.93"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "178303",
            "Clicks": "19,552",
            "Sign Ups": "402",
            "Deposits": "183",
            "Cost per Sign Up": "$21.39",
            "Cost per Deposit)": "$27.69"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "170469",
            "Clicks": "27,448",
            "Sign Ups": "385",
            "Deposits": "211",
            "Cost per Sign Up": "$28.34",
            "Cost per Deposit)": "$54.42"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "146086",
            "Clicks": "21,278",
            "Sign Ups": "396",
            "Deposits": "163",
            "Cost per Sign Up": "$24.01",
            "Cost per Deposit)": "$34.37"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "188058",
            "Clicks": "26,864",
            "Sign Ups": "326",
            "Deposits": "167",
            "Cost per Sign Up": "$17.66",
            "Cost per Deposit)": "$31.23"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "142929",
            "Clicks": "16,090",
            "Sign Ups": "446",
            "Deposits": "204",
            "Cost per Sign Up": "$25.39",
            "Cost per Deposit)": "$58.14"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "173864",
            "Clicks": "25,257",
            "Sign Ups": "261",
            "Deposits": "139",
            "Cost per Sign Up": "$25.10",
            "Cost per Deposit)": "$29.14"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "165741",
            "Clicks": "19,904",
            "Sign Ups": "395",
            "Deposits": "142",
            "Cost per Sign Up": "$26.90",
            "Cost per Deposit)": "$23.01"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "213166",
            "Clicks": "25,480",
            "Sign Ups": "286",
            "Deposits": "152",
            "Cost per Sign Up": "$22.17",
            "Cost per Deposit)": "$46.89"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "133022",
            "Clicks": "23,257",
            "Sign Ups": "335",
            "Deposits": "202",
            "Cost per Sign Up": "$16.82",
            "Cost per Deposit)": "$54.48"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "133753",
            "Clicks": "22,817",
            "Sign Ups": "359",
            "Deposits": "127",
            "Cost per Sign Up": "$22.78",
            "Cost per Deposit)": "$28.05"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "141132",
            "Clicks": "15,394",
            "Sign Ups": "427",
            "Deposits": "202",
            "Cost per Sign Up": "$24.70",
            "Cost per Deposit)": "$50.93"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "161903",
            "Clicks": "29,025",
            "Sign Ups": "351",
            "Deposits": "192",
            "Cost per Sign Up": "$28.33",
            "Cost per Deposit)": "$46.77"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "161284",
            "Clicks": "17,823",
            "Sign Ups": "381",
            "Deposits": "180",
            "Cost per Sign Up": "$21.18",
            "Cost per Deposit)": "$44.55"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "147290",
            "Clicks": "21,880",
            "Sign Ups": "337",
            "Deposits": "202",
            "Cost per Sign Up": "$16.76",
            "Cost per Deposit)": "$48.72"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "179937",
            "Clicks": "28,735",
            "Sign Ups": "403",
            "Deposits": "204",
            "Cost per Sign Up": "$16.65",
            "Cost per Deposit)": "$47.30"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "184296",
            "Clicks": "16,300",
            "Sign Ups": "259",
            "Deposits": "146",
            "Cost per Sign Up": "$17.64",
            "Cost per Deposit)": "$59.40"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "215871",
            "Clicks": "23,216",
            "Sign Ups": "278",
            "Deposits": "211",
            "Cost per Sign Up": "$10.96",
            "Cost per Deposit)": "$42.77"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "179312",
            "Clicks": "26,315",
            "Sign Ups": "306",
            "Deposits": "190",
            "Cost per Sign Up": "$17.46",
            "Cost per Deposit)": "$34.17"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "210180",
            "Clicks": "26,550",
            "Sign Ups": "302",
            "Deposits": "158",
            "Cost per Sign Up": "$13.25",
            "Cost per Deposit)": "$20.11"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "149934",
            "Clicks": "28,005",
            "Sign Ups": "369",
            "Deposits": "153",
            "Cost per Sign Up": "$17.26",
            "Cost per Deposit)": "$49.36"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "174127",
            "Clicks": "28,192",
            "Sign Ups": "363",
            "Deposits": "225",
            "Cost per Sign Up": "$22.84",
            "Cost per Deposit)": "$54.74"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "193344",
            "Clicks": "19,582",
            "Sign Ups": "348",
            "Deposits": "173",
            "Cost per Sign Up": "$27.19",
            "Cost per Deposit)": "$44.18"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "211080",
            "Clicks": "18,027",
            "Sign Ups": "403",
            "Deposits": "137",
            "Cost per Sign Up": "$27.74",
            "Cost per Deposit)": "$42.24"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "143341",
            "Clicks": "22,367",
            "Sign Ups": "255",
            "Deposits": "139",
            "Cost per Sign Up": "$18.39",
            "Cost per Deposit)": "$48.19"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "175107",
            "Clicks": "20,883",
            "Sign Ups": "323",
            "Deposits": "138",
            "Cost per Sign Up": "$27.41",
            "Cost per Deposit)": "$34.29"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "211108",
            "Clicks": "29,372",
            "Sign Ups": "381",
            "Deposits": "174",
            "Cost per Sign Up": "$27.98",
            "Cost per Deposit)": "$28.47"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "171125",
            "Clicks": "25,466",
            "Sign Ups": "424",
            "Deposits": "189",
            "Cost per Sign Up": "$13.53",
            "Cost per Deposit)": "$54.80"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "135833",
            "Clicks": "16,299",
            "Sign Ups": "408",
            "Deposits": "162",
            "Cost per Sign Up": "$23.56",
            "Cost per Deposit)": "$29.22"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "141325",
            "Clicks": "29,005",
            "Sign Ups": "380",
            "Deposits": "148",
            "Cost per Sign Up": "$29.56",
            "Cost per Deposit)": "$45.15"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "172251",
            "Clicks": "17,987",
            "Sign Ups": "401",
            "Deposits": "202",
            "Cost per Sign Up": "$26.60",
            "Cost per Deposit)": "$30.35"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "164884",
            "Clicks": "19,185",
            "Sign Ups": "252",
            "Deposits": "130",
            "Cost per Sign Up": "$12.72",
            "Cost per Deposit)": "$32.13"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "131371",
            "Clicks": "25,054",
            "Sign Ups": "374",
            "Deposits": "202",
            "Cost per Sign Up": "$10.07",
            "Cost per Deposit)": "$22.15"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "163351",
            "Clicks": "16,861",
            "Sign Ups": "366",
            "Deposits": "146",
            "Cost per Sign Up": "$17.47",
            "Cost per Deposit)": "$30.20"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "144264",
            "Clicks": "21,798",
            "Sign Ups": "332",
            "Deposits": "212",
            "Cost per Sign Up": "$12.18",
            "Cost per Deposit)": "$32.62"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "201554",
            "Clicks": "16,696",
            "Sign Ups": "313",
            "Deposits": "176",
            "Cost per Sign Up": "$25.27",
            "Cost per Deposit)": "$41.55"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "181713",
            "Clicks": "29,257",
            "Sign Ups": "277",
            "Deposits": "138",
            "Cost per Sign Up": "$13.07",
            "Cost per Deposit)": "$29.14"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "153113",
            "Clicks": "22,047",
            "Sign Ups": "383",
            "Deposits": "176",
            "Cost per Sign Up": "$12.35",
            "Cost per Deposit)": "$28.17"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "212368",
            "Clicks": "18,813",
            "Sign Ups": "274",
            "Deposits": "162",
            "Cost per Sign Up": "$29.92",
            "Cost per Deposit)": "$57.20"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "213727",
            "Clicks": "20,072",
            "Sign Ups": "313",
            "Deposits": "131",
            "Cost per Sign Up": "$16.50",
            "Cost per Deposit)": "$25.51"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "140517",
            "Clicks": "27,619",
            "Sign Ups": "304",
            "Deposits": "155",
            "Cost per Sign Up": "$15.74",
            "Cost per Deposit)": "$47.53"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "133054",
            "Clicks": "24,263",
            "Sign Ups": "374",
            "Deposits": "166",
            "Cost per Sign Up": "$17.54",
            "Cost per Deposit)": "$40.17"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "130525",
            "Clicks": "15,321",
            "Sign Ups": "324",
            "Deposits": "168",
            "Cost per Sign Up": "$19.42",
            "Cost per Deposit)": "$25.54"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "204447",
            "Clicks": "29,301",
            "Sign Ups": "298",
            "Deposits": "205",
            "Cost per Sign Up": "$11.20",
            "Cost per Deposit)": "$55.49"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "166962",
            "Clicks": "16,670",
            "Sign Ups": "440",
            "Deposits": "207",
            "Cost per Sign Up": "$26.46",
            "Cost per Deposit)": "$42.70"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "148649",
            "Clicks": "25,615",
            "Sign Ups": "272",
            "Deposits": "139",
            "Cost per Sign Up": "$25.91",
            "Cost per Deposit)": "$38.20"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "187693",
            "Clicks": "15,507",
            "Sign Ups": "295",
            "Deposits": "155",
            "Cost per Sign Up": "$10.92",
            "Cost per Deposit)": "$39.40"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "144293",
            "Clicks": "28,126",
            "Sign Ups": "323",
            "Deposits": "214",
            "Cost per Sign Up": "$16.61",
            "Cost per Deposit)": "$33.76"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "206894",
            "Clicks": "15,510",
            "Sign Ups": "392",
            "Deposits": "186",
            "Cost per Sign Up": "$23.20",
            "Cost per Deposit)": "$50.08"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "161237",
            "Clicks": "25,989",
            "Sign Ups": "314",
            "Deposits": "142",
            "Cost per Sign Up": "$17.09",
            "Cost per Deposit)": "$23.07"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "167881",
            "Clicks": "22,005",
            "Sign Ups": "356",
            "Deposits": "135",
            "Cost per Sign Up": "$13.83",
            "Cost per Deposit)": "$29.23"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "147887",
            "Clicks": "17,406",
            "Sign Ups": "304",
            "Deposits": "155",
            "Cost per Sign Up": "$13.87",
            "Cost per Deposit)": "$33.33"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "143582",
            "Clicks": "18,782",
            "Sign Ups": "307",
            "Deposits": "223",
            "Cost per Sign Up": "$14.03",
            "Cost per Deposit)": "$36.66"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "176576",
            "Clicks": "28,708",
            "Sign Ups": "266",
            "Deposits": "164",
            "Cost per Sign Up": "$22.72",
            "Cost per Deposit)": "$20.82"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "218726",
            "Clicks": "15,491",
            "Sign Ups": "349",
            "Deposits": "177",
            "Cost per Sign Up": "$12.42",
            "Cost per Deposit)": "$22.71"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "161031",
            "Clicks": "29,458",
            "Sign Ups": "424",
            "Deposits": "157",
            "Cost per Sign Up": "$17.67",
            "Cost per Deposit)": "$25.80"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "140737",
            "Clicks": "19,903",
            "Sign Ups": "448",
            "Deposits": "171",
            "Cost per Sign Up": "$18.98",
            "Cost per Deposit)": "$40.03"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "192103",
            "Clicks": "20,604",
            "Sign Ups": "347",
            "Deposits": "143",
            "Cost per Sign Up": "$16.80",
            "Cost per Deposit)": "$54.98"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "150825",
            "Clicks": "27,699",
            "Sign Ups": "420",
            "Deposits": "178",
            "Cost per Sign Up": "$22.01",
            "Cost per Deposit)": "$46.19"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "165503",
            "Clicks": "15,025",
            "Sign Ups": "422",
            "Deposits": "173",
            "Cost per Sign Up": "$27.17",
            "Cost per Deposit)": "$53.19"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "187588",
            "Clicks": "24,023",
            "Sign Ups": "277",
            "Deposits": "168",
            "Cost per Sign Up": "$23.67",
            "Cost per Deposit)": "$27.01"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "192476",
            "Clicks": "26,941",
            "Sign Ups": "393",
            "Deposits": "171",
            "Cost per Sign Up": "$21.08",
            "Cost per Deposit)": "$46.03"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "159357",
            "Clicks": "24,526",
            "Sign Ups": "265",
            "Deposits": "159",
            "Cost per Sign Up": "$14.76",
            "Cost per Deposit)": "$49.18"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "188402",
            "Clicks": "17,668",
            "Sign Ups": "347",
            "Deposits": "179",
            "Cost per Sign Up": "$17.78",
            "Cost per Deposit)": "$32.39"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "169586",
            "Clicks": "16,156",
            "Sign Ups": "325",
            "Deposits": "145",
            "Cost per Sign Up": "$27.79",
            "Cost per Deposit)": "$34.06"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "138667",
            "Clicks": "20,223",
            "Sign Ups": "364",
            "Deposits": "212",
            "Cost per Sign Up": "$20.62",
            "Cost per Deposit)": "$30.22"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "180180",
            "Clicks": "19,391",
            "Sign Ups": "353",
            "Deposits": "213",
            "Cost per Sign Up": "$19.25",
            "Cost per Deposit)": "$35.41"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "216925",
            "Clicks": "23,227",
            "Sign Ups": "378",
            "Deposits": "152",
            "Cost per Sign Up": "$13.22",
            "Cost per Deposit)": "$59.08"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "178534",
            "Clicks": "29,568",
            "Sign Ups": "409",
            "Deposits": "162",
            "Cost per Sign Up": "$12.56",
            "Cost per Deposit)": "$28.09"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "210589",
            "Clicks": "27,942",
            "Sign Ups": "294",
            "Deposits": "129",
            "Cost per Sign Up": "$23.59",
            "Cost per Deposit)": "$56.20"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "194989",
            "Clicks": "16,472",
            "Sign Ups": "300",
            "Deposits": "144",
            "Cost per Sign Up": "$10.04",
            "Cost per Deposit)": "$33.20"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "137465",
            "Clicks": "29,834",
            "Sign Ups": "420",
            "Deposits": "183",
            "Cost per Sign Up": "$29.67",
            "Cost per Deposit)": "$23.12"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "192807",
            "Clicks": "22,671",
            "Sign Ups": "310",
            "Deposits": "222",
            "Cost per Sign Up": "$20.67",
            "Cost per Deposit)": "$39.05"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "137047",
            "Clicks": "17,564",
            "Sign Ups": "376",
            "Deposits": "159",
            "Cost per Sign Up": "$11.11",
            "Cost per Deposit)": "$51.46"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "163934",
            "Clicks": "24,121",
            "Sign Ups": "423",
            "Deposits": "136",
            "Cost per Sign Up": "$14.67",
            "Cost per Deposit)": "$44.09"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "209987",
            "Clicks": "26,516",
            "Sign Ups": "295",
            "Deposits": "140",
            "Cost per Sign Up": "$19.93",
            "Cost per Deposit)": "$38.71"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "141753",
            "Clicks": "19,521",
            "Sign Ups": "358",
            "Deposits": "201",
            "Cost per Sign Up": "$19.01",
            "Cost per Deposit)": "$24.54"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "209691",
            "Clicks": "22,800",
            "Sign Ups": "393",
            "Deposits": "223",
            "Cost per Sign Up": "$27.85",
            "Cost per Deposit)": "$49.54"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "199576",
            "Clicks": "20,439",
            "Sign Ups": "435",
            "Deposits": "146",
            "Cost per Sign Up": "$14.10",
            "Cost per Deposit)": "$30.53"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "204786",
            "Clicks": "25,931",
            "Sign Ups": "284",
            "Deposits": "140",
            "Cost per Sign Up": "$24.68",
            "Cost per Deposit)": "$29.44"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "138302",
            "Clicks": "19,309",
            "Sign Ups": "250",
            "Deposits": "210",
            "Cost per Sign Up": "$20.63",
            "Cost per Deposit)": "$32.03"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "139516",
            "Clicks": "18,110",
            "Sign Ups": "319",
            "Deposits": "127",
            "Cost per Sign Up": "$22.17",
            "Cost per Deposit)": "$34.32"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "193037",
            "Clicks": "15,810",
            "Sign Ups": "295",
            "Deposits": "224",
            "Cost per Sign Up": "$19.50",
            "Cost per Deposit)": "$21.87"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "158963",
            "Clicks": "29,035",
            "Sign Ups": "364",
            "Deposits": "133",
            "Cost per Sign Up": "$19.52",
            "Cost per Deposit)": "$32.67"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "190367",
            "Clicks": "29,325",
            "Sign Ups": "384",
            "Deposits": "209",
            "Cost per Sign Up": "$15.13",
            "Cost per Deposit)": "$45.42"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "205503",
            "Clicks": "28,846",
            "Sign Ups": "300",
            "Deposits": "220",
            "Cost per Sign Up": "$15.94",
            "Cost per Deposit)": "$49.99"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "201786",
            "Clicks": "26,095",
            "Sign Ups": "304",
            "Deposits": "199",
            "Cost per Sign Up": "$13.71",
            "Cost per Deposit)": "$52.22"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "204174",
            "Clicks": "21,656",
            "Sign Ups": "289",
            "Deposits": "200",
            "Cost per Sign Up": "$12.34",
            "Cost per Deposit)": "$37.05"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "154714",
            "Clicks": "28,353",
            "Sign Ups": "364",
            "Deposits": "221",
            "Cost per Sign Up": "$19.00",
            "Cost per Deposit)": "$40.86"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "214989",
            "Clicks": "27,019",
            "Sign Ups": "309",
            "Deposits": "175",
            "Cost per Sign Up": "$28.18",
            "Cost per Deposit)": "$22.59"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "138601",
            "Clicks": "15,177",
            "Sign Ups": "338",
            "Deposits": "149",
            "Cost per Sign Up": "$28.64",
            "Cost per Deposit)": "$54.96"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "155574",
            "Clicks": "19,931",
            "Sign Ups": "307",
            "Deposits": "147",
            "Cost per Sign Up": "$13.59",
            "Cost per Deposit)": "$43.32"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "206632",
            "Clicks": "18,688",
            "Sign Ups": "318",
            "Deposits": "156",
            "Cost per Sign Up": "$26.66",
            "Cost per Deposit)": "$30.07"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "204520",
            "Clicks": "27,808",
            "Sign Ups": "420",
            "Deposits": "166",
            "Cost per Sign Up": "$14.38",
            "Cost per Deposit)": "$24.09"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "192979",
            "Clicks": "26,650",
            "Sign Ups": "404",
            "Deposits": "200",
            "Cost per Sign Up": "$11.17",
            "Cost per Deposit)": "$25.42"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "194271",
            "Clicks": "18,369",
            "Sign Ups": "364",
            "Deposits": "194",
            "Cost per Sign Up": "$13.44",
            "Cost per Deposit)": "$20.93"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "204943",
            "Clicks": "29,688",
            "Sign Ups": "355",
            "Deposits": "137",
            "Cost per Sign Up": "$21.32",
            "Cost per Deposit)": "$40.25"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "158048",
            "Clicks": "19,227",
            "Sign Ups": "388",
            "Deposits": "161",
            "Cost per Sign Up": "$13.87",
            "Cost per Deposit)": "$39.58"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "179457",
            "Clicks": "21,603",
            "Sign Ups": "402",
            "Deposits": "126",
            "Cost per Sign Up": "$10.12",
            "Cost per Deposit)": "$46.77"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "132023",
            "Clicks": "21,011",
            "Sign Ups": "368",
            "Deposits": "172",
            "Cost per Sign Up": "$14.95",
            "Cost per Deposit)": "$41.68"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "144710",
            "Clicks": "29,137",
            "Sign Ups": "444",
            "Deposits": "202",
            "Cost per Sign Up": "$10.62",
            "Cost per Deposit)": "$25.41"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "136647",
            "Clicks": "17,146",
            "Sign Ups": "326",
            "Deposits": "139",
            "Cost per Sign Up": "$21.12",
            "Cost per Deposit)": "$35.81"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "142195",
            "Clicks": "27,766",
            "Sign Ups": "266",
            "Deposits": "211",
            "Cost per Sign Up": "$13.54",
            "Cost per Deposit)": "$33.39"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "187486",
            "Clicks": "27,660",
            "Sign Ups": "411",
            "Deposits": "195",
            "Cost per Sign Up": "$29.45",
            "Cost per Deposit)": "$26.19"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "179302",
            "Clicks": "15,664",
            "Sign Ups": "291",
            "Deposits": "194",
            "Cost per Sign Up": "$25.98",
            "Cost per Deposit)": "$27.55"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "180151",
            "Clicks": "29,896",
            "Sign Ups": "294",
            "Deposits": "127",
            "Cost per Sign Up": "$13.93",
            "Cost per Deposit)": "$31.53"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "211489",
            "Clicks": "19,595",
            "Sign Ups": "327",
            "Deposits": "146",
            "Cost per Sign Up": "$22.26",
            "Cost per Deposit)": "$42.54"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "137833",
            "Clicks": "28,513",
            "Sign Ups": "395",
            "Deposits": "130",
            "Cost per Sign Up": "$21.70",
            "Cost per Deposit)": "$40.65"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "149420",
            "Clicks": "25,982",
            "Sign Ups": "445",
            "Deposits": "137",
            "Cost per Sign Up": "$27.24",
            "Cost per Deposit)": "$23.90"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "156517",
            "Clicks": "21,094",
            "Sign Ups": "366",
            "Deposits": "219",
            "Cost per Sign Up": "$21.28",
            "Cost per Deposit)": "$59.99"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "205841",
            "Clicks": "19,418",
            "Sign Ups": "357",
            "Deposits": "158",
            "Cost per Sign Up": "$18.83",
            "Cost per Deposit)": "$56.26"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "168286",
            "Clicks": "26,747",
            "Sign Ups": "344",
            "Deposits": "127",
            "Cost per Sign Up": "$16.34",
            "Cost per Deposit)": "$48.11"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "139135",
            "Clicks": "29,835",
            "Sign Ups": "347",
            "Deposits": "194",
            "Cost per Sign Up": "$24.39",
            "Cost per Deposit)": "$52.92"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "159150",
            "Clicks": "26,122",
            "Sign Ups": "426",
            "Deposits": "211",
            "Cost per Sign Up": "$16.55",
            "Cost per Deposit)": "$28.87"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "162732",
            "Clicks": "21,230",
            "Sign Ups": "432",
            "Deposits": "171",
            "Cost per Sign Up": "$23.18",
            "Cost per Deposit)": "$44.57"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "164723",
            "Clicks": "24,635",
            "Sign Ups": "262",
            "Deposits": "224",
            "Cost per Sign Up": "$23.92",
            "Cost per Deposit)": "$52.71"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "158042",
            "Clicks": "28,319",
            "Sign Ups": "281",
            "Deposits": "158",
            "Cost per Sign Up": "$26.66",
            "Cost per Deposit)": "$41.96"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "195867",
            "Clicks": "16,707",
            "Sign Ups": "379",
            "Deposits": "171",
            "Cost per Sign Up": "$18.32",
            "Cost per Deposit)": "$45.29"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "152285",
            "Clicks": "16,459",
            "Sign Ups": "287",
            "Deposits": "146",
            "Cost per Sign Up": "$16.44",
            "Cost per Deposit)": "$45.11"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "141361",
            "Clicks": "29,706",
            "Sign Ups": "308",
            "Deposits": "155",
            "Cost per Sign Up": "$15.57",
            "Cost per Deposit)": "$34.69"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "144204",
            "Clicks": "22,518",
            "Sign Ups": "381",
            "Deposits": "162",
            "Cost per Sign Up": "$16.01",
            "Cost per Deposit)": "$28.32"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "165767",
            "Clicks": "19,590",
            "Sign Ups": "392",
            "Deposits": "135",
            "Cost per Sign Up": "$10.00",
            "Cost per Deposit)": "$20.98"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "200640",
            "Clicks": "19,715",
            "Sign Ups": "310",
            "Deposits": "218",
            "Cost per Sign Up": "$22.63",
            "Cost per Deposit)": "$55.41"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "196306",
            "Clicks": "28,142",
            "Sign Ups": "295",
            "Deposits": "220",
            "Cost per Sign Up": "$28.37",
            "Cost per Deposit)": "$40.57"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "204749",
            "Clicks": "24,332",
            "Sign Ups": "277",
            "Deposits": "182",
            "Cost per Sign Up": "$16.90",
            "Cost per Deposit)": "$48.30"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "191882",
            "Clicks": "18,968",
            "Sign Ups": "263",
            "Deposits": "171",
            "Cost per Sign Up": "$12.09",
            "Cost per Deposit)": "$27.34"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "194050",
            "Clicks": "22,395",
            "Sign Ups": "394",
            "Deposits": "187",
            "Cost per Sign Up": "$24.00",
            "Cost per Deposit)": "$50.98"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "132656",
            "Clicks": "28,246",
            "Sign Ups": "292",
            "Deposits": "181",
            "Cost per Sign Up": "$20.05",
            "Cost per Deposit)": "$24.89"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "219676",
            "Clicks": "19,889",
            "Sign Ups": "355",
            "Deposits": "209",
            "Cost per Sign Up": "$16.86",
            "Cost per Deposit)": "$30.02"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "148130",
            "Clicks": "28,575",
            "Sign Ups": "251",
            "Deposits": "157",
            "Cost per Sign Up": "$16.46",
            "Cost per Deposit)": "$50.44"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "200484",
            "Clicks": "17,951",
            "Sign Ups": "318",
            "Deposits": "126",
            "Cost per Sign Up": "$10.76",
            "Cost per Deposit)": "$55.78"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "191838",
            "Clicks": "22,184",
            "Sign Ups": "429",
            "Deposits": "216",
            "Cost per Sign Up": "$11.54",
            "Cost per Deposit)": "$58.86"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "149730",
            "Clicks": "28,027",
            "Sign Ups": "332",
            "Deposits": "198",
            "Cost per Sign Up": "$23.82",
            "Cost per Deposit)": "$41.58"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "188858",
            "Clicks": "17,726",
            "Sign Ups": "401",
            "Deposits": "175",
            "Cost per Sign Up": "$20.67",
            "Cost per Deposit)": "$22.85"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "132780",
            "Clicks": "29,647",
            "Sign Ups": "380",
            "Deposits": "198",
            "Cost per Sign Up": "$28.00",
            "Cost per Deposit)": "$21.04"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "178789",
            "Clicks": "24,523",
            "Sign Ups": "387",
            "Deposits": "146",
            "Cost per Sign Up": "$18.17",
            "Cost per Deposit)": "$45.86"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "141033",
            "Clicks": "27,041",
            "Sign Ups": "250",
            "Deposits": "140",
            "Cost per Sign Up": "$24.93",
            "Cost per Deposit)": "$58.94"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "188043",
            "Clicks": "26,456",
            "Sign Ups": "323",
            "Deposits": "173",
            "Cost per Sign Up": "$22.17",
            "Cost per Deposit)": "$42.24"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "206339",
            "Clicks": "29,745",
            "Sign Ups": "358",
            "Deposits": "201",
            "Cost per Sign Up": "$23.68",
            "Cost per Deposit)": "$34.52"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "185802",
            "Clicks": "21,160",
            "Sign Ups": "296",
            "Deposits": "186",
            "Cost per Sign Up": "$16.62",
            "Cost per Deposit)": "$35.20"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "187588",
            "Clicks": "22,175",
            "Sign Ups": "271",
            "Deposits": "206",
            "Cost per Sign Up": "$19.14",
            "Cost per Deposit)": "$27.17"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "163089",
            "Clicks": "18,033",
            "Sign Ups": "353",
            "Deposits": "139",
            "Cost per Sign Up": "$27.36",
            "Cost per Deposit)": "$45.60"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "134721",
            "Clicks": "23,304",
            "Sign Ups": "369",
            "Deposits": "136",
            "Cost per Sign Up": "$20.38",
            "Cost per Deposit)": "$43.98"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "182854",
            "Clicks": "22,214",
            "Sign Ups": "393",
            "Deposits": "210",
            "Cost per Sign Up": "$23.63",
            "Cost per Deposit)": "$42.51"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "177717",
            "Clicks": "19,221",
            "Sign Ups": "402",
            "Deposits": "169",
            "Cost per Sign Up": "$11.14",
            "Cost per Deposit)": "$33.51"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "155083",
            "Clicks": "17,687",
            "Sign Ups": "356",
            "Deposits": "211",
            "Cost per Sign Up": "$18.04",
            "Cost per Deposit)": "$54.15"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "218580",
            "Clicks": "15,039",
            "Sign Ups": "448",
            "Deposits": "175",
            "Cost per Sign Up": "$10.34",
            "Cost per Deposit)": "$37.35"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "167566",
            "Clicks": "17,248",
            "Sign Ups": "447",
            "Deposits": "202",
            "Cost per Sign Up": "$14.40",
            "Cost per Deposit)": "$49.14"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "182063",
            "Clicks": "16,079",
            "Sign Ups": "350",
            "Deposits": "178",
            "Cost per Sign Up": "$17.69",
            "Cost per Deposit)": "$52.28"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "163346",
            "Clicks": "19,145",
            "Sign Ups": "351",
            "Deposits": "150",
            "Cost per Sign Up": "$19.73",
            "Cost per Deposit)": "$34.91"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "176856",
            "Clicks": "19,127",
            "Sign Ups": "361",
            "Deposits": "146",
            "Cost per Sign Up": "$28.44",
            "Cost per Deposit)": "$36.42"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "167126",
            "Clicks": "25,836",
            "Sign Ups": "333",
            "Deposits": "130",
            "Cost per Sign Up": "$24.08",
            "Cost per Deposit)": "$42.68"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "178832",
            "Clicks": "19,194",
            "Sign Ups": "397",
            "Deposits": "184",
            "Cost per Sign Up": "$29.28",
            "Cost per Deposit)": "$39.55"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "217186",
            "Clicks": "25,859",
            "Sign Ups": "262",
            "Deposits": "156",
            "Cost per Sign Up": "$29.92",
            "Cost per Deposit)": "$43.63"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "184351",
            "Clicks": "29,318",
            "Sign Ups": "380",
            "Deposits": "140",
            "Cost per Sign Up": "$11.03",
            "Cost per Deposit)": "$51.61"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "147881",
            "Clicks": "15,951",
            "Sign Ups": "395",
            "Deposits": "170",
            "Cost per Sign Up": "$14.04",
            "Cost per Deposit)": "$56.81"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "216670",
            "Clicks": "15,456",
            "Sign Ups": "406",
            "Deposits": "174",
            "Cost per Sign Up": "$29.62",
            "Cost per Deposit)": "$29.11"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "211845",
            "Clicks": "26,256",
            "Sign Ups": "300",
            "Deposits": "193",
            "Cost per Sign Up": "$12.17",
            "Cost per Deposit)": "$57.55"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "176436",
            "Clicks": "20,313",
            "Sign Ups": "355",
            "Deposits": "164",
            "Cost per Sign Up": "$20.19",
            "Cost per Deposit)": "$32.76"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "215676",
            "Clicks": "27,044",
            "Sign Ups": "271",
            "Deposits": "212",
            "Cost per Sign Up": "$26.29",
            "Cost per Deposit)": "$51.09"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "166728",
            "Clicks": "22,419",
            "Sign Ups": "360",
            "Deposits": "195",
            "Cost per Sign Up": "$23.38",
            "Cost per Deposit)": "$31.68"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "178835",
            "Clicks": "22,290",
            "Sign Ups": "290",
            "Deposits": "199",
            "Cost per Sign Up": "$22.62",
            "Cost per Deposit)": "$57.29"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "189945",
            "Clicks": "16,049",
            "Sign Ups": "272",
            "Deposits": "220",
            "Cost per Sign Up": "$21.56",
            "Cost per Deposit)": "$33.99"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "211483",
            "Clicks": "28,760",
            "Sign Ups": "429",
            "Deposits": "137",
            "Cost per Sign Up": "$13.54",
            "Cost per Deposit)": "$20.02"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "183307",
            "Clicks": "20,526",
            "Sign Ups": "386",
            "Deposits": "162",
            "Cost per Sign Up": "$18.88",
            "Cost per Deposit)": "$45.70"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "153069",
            "Clicks": "27,743",
            "Sign Ups": "293",
            "Deposits": "193",
            "Cost per Sign Up": "$28.64",
            "Cost per Deposit)": "$33.68"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "139075",
            "Clicks": "21,292",
            "Sign Ups": "409",
            "Deposits": "196",
            "Cost per Sign Up": "$12.66",
            "Cost per Deposit)": "$50.10"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "142384",
            "Clicks": "20,375",
            "Sign Ups": "293",
            "Deposits": "156",
            "Cost per Sign Up": "$20.97",
            "Cost per Deposit)": "$55.50"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "136780",
            "Clicks": "15,505",
            "Sign Ups": "278",
            "Deposits": "159",
            "Cost per Sign Up": "$16.19",
            "Cost per Deposit)": "$30.75"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "140500",
            "Clicks": "22,317",
            "Sign Ups": "350",
            "Deposits": "186",
            "Cost per Sign Up": "$26.87",
            "Cost per Deposit)": "$38.61"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "156540",
            "Clicks": "24,836",
            "Sign Ups": "394",
            "Deposits": "201",
            "Cost per Sign Up": "$23.47",
            "Cost per Deposit)": "$38.28"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "197534",
            "Clicks": "29,791",
            "Sign Ups": "336",
            "Deposits": "161",
            "Cost per Sign Up": "$25.72",
            "Cost per Deposit)": "$47.85"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "213416",
            "Clicks": "22,125",
            "Sign Ups": "250",
            "Deposits": "143",
            "Cost per Sign Up": "$23.81",
            "Cost per Deposit)": "$33.61"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "200355",
            "Clicks": "24,471",
            "Sign Ups": "284",
            "Deposits": "201",
            "Cost per Sign Up": "$27.29",
            "Cost per Deposit)": "$47.84"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "133114",
            "Clicks": "25,557",
            "Sign Ups": "419",
            "Deposits": "153",
            "Cost per Sign Up": "$15.11",
            "Cost per Deposit)": "$30.45"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "202390",
            "Clicks": "19,692",
            "Sign Ups": "350",
            "Deposits": "149",
            "Cost per Sign Up": "$14.92",
            "Cost per Deposit)": "$58.29"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "204197",
            "Clicks": "22,132",
            "Sign Ups": "279",
            "Deposits": "152",
            "Cost per Sign Up": "$27.15",
            "Cost per Deposit)": "$31.94"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "135448",
            "Clicks": "25,182",
            "Sign Ups": "445",
            "Deposits": "130",
            "Cost per Sign Up": "$23.24",
            "Cost per Deposit)": "$59.79"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "163244",
            "Clicks": "21,619",
            "Sign Ups": "373",
            "Deposits": "151",
            "Cost per Sign Up": "$24.77",
            "Cost per Deposit)": "$50.14"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "202194",
            "Clicks": "22,020",
            "Sign Ups": "308",
            "Deposits": "222",
            "Cost per Sign Up": "$19.80",
            "Cost per Deposit)": "$48.93"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "178757",
            "Clicks": "29,431",
            "Sign Ups": "264",
            "Deposits": "190",
            "Cost per Sign Up": "$20.86",
            "Cost per Deposit)": "$32.49"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "162586",
            "Clicks": "21,443",
            "Sign Ups": "400",
            "Deposits": "198",
            "Cost per Sign Up": "$13.60",
            "Cost per Deposit)": "$35.47"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "190451",
            "Clicks": "18,296",
            "Sign Ups": "381",
            "Deposits": "139",
            "Cost per Sign Up": "$24.62",
            "Cost per Deposit)": "$52.40"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "137461",
            "Clicks": "20,277",
            "Sign Ups": "325",
            "Deposits": "179",
            "Cost per Sign Up": "$23.52",
            "Cost per Deposit)": "$56.67"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "191156",
            "Clicks": "24,395",
            "Sign Ups": "423",
            "Deposits": "154",
            "Cost per Sign Up": "$13.44",
            "Cost per Deposit)": "$47.93"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "202563",
            "Clicks": "17,212",
            "Sign Ups": "254",
            "Deposits": "205",
            "Cost per Sign Up": "$22.37",
            "Cost per Deposit)": "$33.27"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "203878",
            "Clicks": "21,112",
            "Sign Ups": "296",
            "Deposits": "166",
            "Cost per Sign Up": "$20.97",
            "Cost per Deposit)": "$25.95"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "150050",
            "Clicks": "17,440",
            "Sign Ups": "264",
            "Deposits": "164",
            "Cost per Sign Up": "$24.32",
            "Cost per Deposit)": "$43.02"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "201372",
            "Clicks": "16,596",
            "Sign Ups": "387",
            "Deposits": "220",
            "Cost per Sign Up": "$17.59",
            "Cost per Deposit)": "$20.59"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "215924",
            "Clicks": "15,851",
            "Sign Ups": "440",
            "Deposits": "156",
            "Cost per Sign Up": "$13.64",
            "Cost per Deposit)": "$46.07"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "166522",
            "Clicks": "18,335",
            "Sign Ups": "413",
            "Deposits": "185",
            "Cost per Sign Up": "$16.34",
            "Cost per Deposit)": "$53.94"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "168304",
            "Clicks": "20,337",
            "Sign Ups": "318",
            "Deposits": "133",
            "Cost per Sign Up": "$21.42",
            "Cost per Deposit)": "$24.97"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "200108",
            "Clicks": "16,266",
            "Sign Ups": "406",
            "Deposits": "139",
            "Cost per Sign Up": "$12.57",
            "Cost per Deposit)": "$41.76"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "216306",
            "Clicks": "21,686",
            "Sign Ups": "306",
            "Deposits": "208",
            "Cost per Sign Up": "$25.41",
            "Cost per Deposit)": "$49.11"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "186458",
            "Clicks": "15,318",
            "Sign Ups": "259",
            "Deposits": "141",
            "Cost per Sign Up": "$11.25",
            "Cost per Deposit)": "$42.81"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "213680",
            "Clicks": "24,263",
            "Sign Ups": "368",
            "Deposits": "155",
            "Cost per Sign Up": "$21.31",
            "Cost per Deposit)": "$49.78"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "174803",
            "Clicks": "24,400",
            "Sign Ups": "270",
            "Deposits": "193",
            "Cost per Sign Up": "$25.77",
            "Cost per Deposit)": "$51.05"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "167909",
            "Clicks": "19,310",
            "Sign Ups": "357",
            "Deposits": "166",
            "Cost per Sign Up": "$24.08",
            "Cost per Deposit)": "$53.51"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "154646",
            "Clicks": "22,653",
            "Sign Ups": "408",
            "Deposits": "203",
            "Cost per Sign Up": "$15.55",
            "Cost per Deposit)": "$38.76"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "189318",
            "Clicks": "24,375",
            "Sign Ups": "415",
            "Deposits": "167",
            "Cost per Sign Up": "$14.31",
            "Cost per Deposit)": "$42.19"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "141560",
            "Clicks": "29,623",
            "Sign Ups": "267",
            "Deposits": "213",
            "Cost per Sign Up": "$10.06",
            "Cost per Deposit)": "$33.89"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "167458",
            "Clicks": "17,570",
            "Sign Ups": "263",
            "Deposits": "214",
            "Cost per Sign Up": "$14.00",
            "Cost per Deposit)": "$53.48"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "196824",
            "Clicks": "15,796",
            "Sign Ups": "423",
            "Deposits": "170",
            "Cost per Sign Up": "$29.50",
            "Cost per Deposit)": "$36.56"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "193095",
            "Clicks": "18,027",
            "Sign Ups": "265",
            "Deposits": "217",
            "Cost per Sign Up": "$27.63",
            "Cost per Deposit)": "$43.31"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "191148",
            "Clicks": "26,706",
            "Sign Ups": "278",
            "Deposits": "190",
            "Cost per Sign Up": "$10.71",
            "Cost per Deposit)": "$43.32"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "213867",
            "Clicks": "20,819",
            "Sign Ups": "355",
            "Deposits": "125",
            "Cost per Sign Up": "$20.57",
            "Cost per Deposit)": "$54.41"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "182410",
            "Clicks": "20,479",
            "Sign Ups": "288",
            "Deposits": "209",
            "Cost per Sign Up": "$23.83",
            "Cost per Deposit)": "$27.96"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "193798",
            "Clicks": "29,672",
            "Sign Ups": "427",
            "Deposits": "206",
            "Cost per Sign Up": "$19.71",
            "Cost per Deposit)": "$37.94"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "167160",
            "Clicks": "22,043",
            "Sign Ups": "366",
            "Deposits": "219",
            "Cost per Sign Up": "$20.77",
            "Cost per Deposit)": "$37.96"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "170758",
            "Clicks": "18,034",
            "Sign Ups": "306",
            "Deposits": "212",
            "Cost per Sign Up": "$23.90",
            "Cost per Deposit)": "$34.60"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "170333",
            "Clicks": "21,039",
            "Sign Ups": "409",
            "Deposits": "187",
            "Cost per Sign Up": "$14.96",
            "Cost per Deposit)": "$55.16"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "219337",
            "Clicks": "27,206",
            "Sign Ups": "323",
            "Deposits": "169",
            "Cost per Sign Up": "$23.37",
            "Cost per Deposit)": "$30.58"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "191001",
            "Clicks": "15,331",
            "Sign Ups": "336",
            "Deposits": "205",
            "Cost per Sign Up": "$17.97",
            "Cost per Deposit)": "$29.42"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "177950",
            "Clicks": "25,750",
            "Sign Ups": "292",
            "Deposits": "155",
            "Cost per Sign Up": "$11.34",
            "Cost per Deposit)": "$45.34"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "190302",
            "Clicks": "17,744",
            "Sign Ups": "447",
            "Deposits": "208",
            "Cost per Sign Up": "$16.63",
            "Cost per Deposit)": "$29.13"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "210103",
            "Clicks": "15,863",
            "Sign Ups": "375",
            "Deposits": "132",
            "Cost per Sign Up": "$29.56",
            "Cost per Deposit)": "$40.48"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "201399",
            "Clicks": "29,324",
            "Sign Ups": "425",
            "Deposits": "218",
            "Cost per Sign Up": "$17.25",
            "Cost per Deposit)": "$49.81"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "218093",
            "Clicks": "18,421",
            "Sign Ups": "405",
            "Deposits": "205",
            "Cost per Sign Up": "$21.18",
            "Cost per Deposit)": "$51.48"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "208105",
            "Clicks": "24,111",
            "Sign Ups": "395",
            "Deposits": "129",
            "Cost per Sign Up": "$12.34",
            "Cost per Deposit)": "$44.03"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "218968",
            "Clicks": "17,668",
            "Sign Ups": "305",
            "Deposits": "187",
            "Cost per Sign Up": "$13.17",
            "Cost per Deposit)": "$42.10"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "145874",
            "Clicks": "25,635",
            "Sign Ups": "305",
            "Deposits": "175",
            "Cost per Sign Up": "$23.09",
            "Cost per Deposit)": "$24.40"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "193745",
            "Clicks": "24,902",
            "Sign Ups": "343",
            "Deposits": "168",
            "Cost per Sign Up": "$27.32",
            "Cost per Deposit)": "$39.67"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "145284",
            "Clicks": "16,380",
            "Sign Ups": "418",
            "Deposits": "170",
            "Cost per Sign Up": "$14.58",
            "Cost per Deposit)": "$24.93"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "211787",
            "Clicks": "21,327",
            "Sign Ups": "308",
            "Deposits": "186",
            "Cost per Sign Up": "$12.62",
            "Cost per Deposit)": "$59.62"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "207756",
            "Clicks": "29,404",
            "Sign Ups": "437",
            "Deposits": "218",
            "Cost per Sign Up": "$23.80",
            "Cost per Deposit)": "$44.29"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "168347",
            "Clicks": "22,077",
            "Sign Ups": "356",
            "Deposits": "136",
            "Cost per Sign Up": "$25.59",
            "Cost per Deposit)": "$51.39"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "186721",
            "Clicks": "29,435",
            "Sign Ups": "251",
            "Deposits": "179",
            "Cost per Sign Up": "$16.00",
            "Cost per Deposit)": "$30.86"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "188719",
            "Clicks": "29,122",
            "Sign Ups": "445",
            "Deposits": "165",
            "Cost per Sign Up": "$10.46",
            "Cost per Deposit)": "$33.05"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "215152",
            "Clicks": "23,897",
            "Sign Ups": "435",
            "Deposits": "217",
            "Cost per Sign Up": "$15.72",
            "Cost per Deposit)": "$20.59"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "131790",
            "Clicks": "21,407",
            "Sign Ups": "299",
            "Deposits": "130",
            "Cost per Sign Up": "$25.66",
            "Cost per Deposit)": "$54.71"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "164503",
            "Clicks": "16,750",
            "Sign Ups": "298",
            "Deposits": "222",
            "Cost per Sign Up": "$15.57",
            "Cost per Deposit)": "$33.18"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "217213",
            "Clicks": "18,868",
            "Sign Ups": "313",
            "Deposits": "147",
            "Cost per Sign Up": "$19.54",
            "Cost per Deposit)": "$28.11"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "147613",
            "Clicks": "18,163",
            "Sign Ups": "282",
            "Deposits": "185",
            "Cost per Sign Up": "$19.66",
            "Cost per Deposit)": "$24.38"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "217575",
            "Clicks": "26,230",
            "Sign Ups": "313",
            "Deposits": "148",
            "Cost per Sign Up": "$29.67",
            "Cost per Deposit)": "$32.03"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "212861",
            "Clicks": "21,357",
            "Sign Ups": "362",
            "Deposits": "206",
            "Cost per Sign Up": "$18.16",
            "Cost per Deposit)": "$26.51"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "202067",
            "Clicks": "15,358",
            "Sign Ups": "271",
            "Deposits": "125",
            "Cost per Sign Up": "$20.24",
            "Cost per Deposit)": "$23.27"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "162156",
            "Clicks": "16,325",
            "Sign Ups": "439",
            "Deposits": "152",
            "Cost per Sign Up": "$14.06",
            "Cost per Deposit)": "$55.27"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "143918",
            "Clicks": "22,048",
            "Sign Ups": "358",
            "Deposits": "178",
            "Cost per Sign Up": "$28.26",
            "Cost per Deposit)": "$50.07"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "186818",
            "Clicks": "25,198",
            "Sign Ups": "436",
            "Deposits": "138",
            "Cost per Sign Up": "$20.19",
            "Cost per Deposit)": "$20.45"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "186640",
            "Clicks": "26,944",
            "Sign Ups": "421",
            "Deposits": "216",
            "Cost per Sign Up": "$18.42",
            "Cost per Deposit)": "$34.81"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "142740",
            "Clicks": "21,277",
            "Sign Ups": "398",
            "Deposits": "182",
            "Cost per Sign Up": "$19.86",
            "Cost per Deposit)": "$57.20"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "135594",
            "Clicks": "18,811",
            "Sign Ups": "356",
            "Deposits": "200",
            "Cost per Sign Up": "$28.26",
            "Cost per Deposit)": "$33.77"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "166792",
            "Clicks": "25,888",
            "Sign Ups": "393",
            "Deposits": "129",
            "Cost per Sign Up": "$25.72",
            "Cost per Deposit)": "$49.29"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "171968",
            "Clicks": "28,933",
            "Sign Ups": "437",
            "Deposits": "134",
            "Cost per Sign Up": "$20.68",
            "Cost per Deposit)": "$54.25"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "216392",
            "Clicks": "23,255",
            "Sign Ups": "274",
            "Deposits": "131",
            "Cost per Sign Up": "$10.49",
            "Cost per Deposit)": "$31.03"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "216774",
            "Clicks": "23,263",
            "Sign Ups": "282",
            "Deposits": "170",
            "Cost per Sign Up": "$21.88",
            "Cost per Deposit)": "$47.98"
          }
        ]
      },
      "HighValuePlayersData": {
        "name": "High Value Players",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "92,166",
            "Clicks": "19,585",
            "Sign Ups": "221",
            "Deposits": "97",
            "Cost per Sign Up": "$57.82",
            "Cost per Deposit)": "$47.69"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "146,991",
            "Clicks": "20,712",
            "Sign Ups": "231",
            "Deposits": "103",
            "Cost per Sign Up": "$38.83",
            "Cost per Deposit)": "$52.65"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "127,308",
            "Clicks": "18,791",
            "Sign Ups": "150",
            "Deposits": "94",
            "Cost per Sign Up": "$39.56",
            "Cost per Deposit)": "$84.14"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "156,794",
            "Clicks": "21,506",
            "Sign Ups": "174",
            "Deposits": "105",
            "Cost per Sign Up": "$31.27",
            "Cost per Deposit)": "$49.14"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "141,627",
            "Clicks": "27,156",
            "Sign Ups": "235",
            "Deposits": "105",
            "Cost per Sign Up": "$59.92",
            "Cost per Deposit)": "$83.41"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "110,025",
            "Clicks": "23,357",
            "Sign Ups": "176",
            "Deposits": "91",
            "Cost per Sign Up": "$43.56",
            "Cost per Deposit)": "$83.48"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "157,883",
            "Clicks": "18,341",
            "Sign Ups": "235",
            "Deposits": "118",
            "Cost per Sign Up": "$33.19",
            "Cost per Deposit)": "$62.45"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "118,571",
            "Clicks": "25,741",
            "Sign Ups": "157",
            "Deposits": "114",
            "Cost per Sign Up": "$46.90",
            "Cost per Deposit)": "$86.57"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "98,234",
            "Clicks": "22,042",
            "Sign Ups": "235",
            "Deposits": "106",
            "Cost per Sign Up": "$50.47",
            "Cost per Deposit)": "$77.10"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "150,490",
            "Clicks": "26,249",
            "Sign Ups": "158",
            "Deposits": "104",
            "Cost per Sign Up": "$34.37",
            "Cost per Deposit)": "$85.94"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "125,074",
            "Clicks": "22,819",
            "Sign Ups": "179",
            "Deposits": "103",
            "Cost per Sign Up": "$53.58",
            "Cost per Deposit)": "$76.15"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "156,880",
            "Clicks": "15,905",
            "Sign Ups": "201",
            "Deposits": "93",
            "Cost per Sign Up": "$50.95",
            "Cost per Deposit)": "$58.88"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "127,470",
            "Clicks": "24,046",
            "Sign Ups": "187",
            "Deposits": "116",
            "Cost per Sign Up": "$43.57",
            "Cost per Deposit)": "$71.77"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "143,003",
            "Clicks": "29,110",
            "Sign Ups": "198",
            "Deposits": "88",
            "Cost per Sign Up": "$41.64",
            "Cost per Deposit)": "$75.55"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "94,307",
            "Clicks": "20,825",
            "Sign Ups": "236",
            "Deposits": "106",
            "Cost per Sign Up": "$51.46",
            "Cost per Deposit)": "$47.60"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "98,999",
            "Clicks": "15,972",
            "Sign Ups": "185",
            "Deposits": "124",
            "Cost per Sign Up": "$37.66",
            "Cost per Deposit)": "$48.96"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "126,869",
            "Clicks": "26,862",
            "Sign Ups": "231",
            "Deposits": "98",
            "Cost per Sign Up": "$28.93",
            "Cost per Deposit)": "$88.94"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "93,604",
            "Clicks": "18,950",
            "Sign Ups": "150",
            "Deposits": "107",
            "Cost per Sign Up": "$36.87",
            "Cost per Deposit)": "$63.89"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "98,953",
            "Clicks": "29,319",
            "Sign Ups": "150",
            "Deposits": "78",
            "Cost per Sign Up": "$51.11",
            "Cost per Deposit)": "$57.14"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "118,618",
            "Clicks": "27,480",
            "Sign Ups": "245",
            "Deposits": "97",
            "Cost per Sign Up": "$44.80",
            "Cost per Deposit)": "$56.94"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "124,238",
            "Clicks": "21,436",
            "Sign Ups": "200",
            "Deposits": "106",
            "Cost per Sign Up": "$35.81",
            "Cost per Deposit)": "$53.38"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "149,621",
            "Clicks": "22,750",
            "Sign Ups": "156",
            "Deposits": "123",
            "Cost per Sign Up": "$43.81",
            "Cost per Deposit)": "$52.13"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "116,728",
            "Clicks": "21,391",
            "Sign Ups": "242",
            "Deposits": "78",
            "Cost per Sign Up": "$51.53",
            "Cost per Deposit)": "$74.95"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "120,578",
            "Clicks": "18,114",
            "Sign Ups": "187",
            "Deposits": "98",
            "Cost per Sign Up": "$37.42",
            "Cost per Deposit)": "$50.47"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "141,824",
            "Clicks": "24,943",
            "Sign Ups": "247",
            "Deposits": "105",
            "Cost per Sign Up": "$56.79",
            "Cost per Deposit)": "$66.40"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "119,649",
            "Clicks": "25,110",
            "Sign Ups": "242",
            "Deposits": "108",
            "Cost per Sign Up": "$39.80",
            "Cost per Deposit)": "$73.93"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "134,459",
            "Clicks": "16,233",
            "Sign Ups": "247",
            "Deposits": "84",
            "Cost per Sign Up": "$22.31",
            "Cost per Deposit)": "$78.95"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "152,680",
            "Clicks": "18,810",
            "Sign Ups": "221",
            "Deposits": "80",
            "Cost per Sign Up": "$39.22",
            "Cost per Deposit)": "$86.70"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "136,399",
            "Clicks": "26,606",
            "Sign Ups": "223",
            "Deposits": "124",
            "Cost per Sign Up": "$57.82",
            "Cost per Deposit)": "$74.54"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "128,491",
            "Clicks": "27,849",
            "Sign Ups": "247",
            "Deposits": "113",
            "Cost per Sign Up": "$50.96",
            "Cost per Deposit)": "$62.28"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "102,224",
            "Clicks": "23,999",
            "Sign Ups": "201",
            "Deposits": "81",
            "Cost per Sign Up": "$27.31",
            "Cost per Deposit)": "$88.22"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "99,194",
            "Clicks": "21,856",
            "Sign Ups": "178",
            "Deposits": "109",
            "Cost per Sign Up": "$47.58",
            "Cost per Deposit)": "$80.76"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "90,723",
            "Clicks": "19,907",
            "Sign Ups": "213",
            "Deposits": "86",
            "Cost per Sign Up": "$33.35",
            "Cost per Deposit)": "$88.84"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "94,188",
            "Clicks": "18,249",
            "Sign Ups": "227",
            "Deposits": "122",
            "Cost per Sign Up": "$51.91",
            "Cost per Deposit)": "$86.24"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "133,884",
            "Clicks": "26,624",
            "Sign Ups": "242",
            "Deposits": "117",
            "Cost per Sign Up": "$24.54",
            "Cost per Deposit)": "$66.21"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "92,201",
            "Clicks": "24,017",
            "Sign Ups": "169",
            "Deposits": "99",
            "Cost per Sign Up": "$24.20",
            "Cost per Deposit)": "$89.26"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "105,782",
            "Clicks": "22,716",
            "Sign Ups": "186",
            "Deposits": "90",
            "Cost per Sign Up": "$53.47",
            "Cost per Deposit)": "$62.74"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "152,528",
            "Clicks": "19,730",
            "Sign Ups": "187",
            "Deposits": "104",
            "Cost per Sign Up": "$34.41",
            "Cost per Deposit)": "$81.74"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "110,399",
            "Clicks": "24,977",
            "Sign Ups": "203",
            "Deposits": "124",
            "Cost per Sign Up": "$41.89",
            "Cost per Deposit)": "$42.48"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "109,359",
            "Clicks": "20,043",
            "Sign Ups": "218",
            "Deposits": "85",
            "Cost per Sign Up": "$55.05",
            "Cost per Deposit)": "$53.60"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "145,567",
            "Clicks": "17,362",
            "Sign Ups": "160",
            "Deposits": "83",
            "Cost per Sign Up": "$54.47",
            "Cost per Deposit)": "$85.86"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "98,545",
            "Clicks": "28,115",
            "Sign Ups": "169",
            "Deposits": "92",
            "Cost per Sign Up": "$29.32",
            "Cost per Deposit)": "$87.70"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "135,188",
            "Clicks": "23,203",
            "Sign Ups": "236",
            "Deposits": "78",
            "Cost per Sign Up": "$56.19",
            "Cost per Deposit)": "$67.02"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "150,073",
            "Clicks": "18,128",
            "Sign Ups": "170",
            "Deposits": "98",
            "Cost per Sign Up": "$20.83",
            "Cost per Deposit)": "$57.39"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "106,995",
            "Clicks": "26,871",
            "Sign Ups": "197",
            "Deposits": "112",
            "Cost per Sign Up": "$25.54",
            "Cost per Deposit)": "$44.42"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "94,708",
            "Clicks": "18,424",
            "Sign Ups": "177",
            "Deposits": "100",
            "Cost per Sign Up": "$32.28",
            "Cost per Deposit)": "$42.63"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "117,045",
            "Clicks": "27,679",
            "Sign Ups": "150",
            "Deposits": "84",
            "Cost per Sign Up": "$46.97",
            "Cost per Deposit)": "$46.36"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "136,373",
            "Clicks": "19,955",
            "Sign Ups": "152",
            "Deposits": "102",
            "Cost per Sign Up": "$54.38",
            "Cost per Deposit)": "$43.85"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "116,774",
            "Clicks": "16,244",
            "Sign Ups": "182",
            "Deposits": "119",
            "Cost per Sign Up": "$40.77",
            "Cost per Deposit)": "$88.84"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "91,431",
            "Clicks": "21,245",
            "Sign Ups": "217",
            "Deposits": "76",
            "Cost per Sign Up": "$55.83",
            "Cost per Deposit)": "$49.60"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "155,870",
            "Clicks": "20,643",
            "Sign Ups": "246",
            "Deposits": "96",
            "Cost per Sign Up": "$36.52",
            "Cost per Deposit)": "$66.84"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "90,072",
            "Clicks": "28,840",
            "Sign Ups": "215",
            "Deposits": "106",
            "Cost per Sign Up": "$40.68",
            "Cost per Deposit)": "$64.45"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "144,749",
            "Clicks": "24,066",
            "Sign Ups": "246",
            "Deposits": "95",
            "Cost per Sign Up": "$39.22",
            "Cost per Deposit)": "$83.18"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "97,637",
            "Clicks": "24,108",
            "Sign Ups": "209",
            "Deposits": "118",
            "Cost per Sign Up": "$29.58",
            "Cost per Deposit)": "$76.87"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "149,586",
            "Clicks": "27,317",
            "Sign Ups": "196",
            "Deposits": "116",
            "Cost per Sign Up": "$24.24",
            "Cost per Deposit)": "$85.76"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "103,966",
            "Clicks": "25,227",
            "Sign Ups": "202",
            "Deposits": "124",
            "Cost per Sign Up": "$43.31",
            "Cost per Deposit)": "$87.34"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "109,354",
            "Clicks": "24,547",
            "Sign Ups": "182",
            "Deposits": "79",
            "Cost per Sign Up": "$45.67",
            "Cost per Deposit)": "$41.59"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "126,939",
            "Clicks": "22,155",
            "Sign Ups": "184",
            "Deposits": "121",
            "Cost per Sign Up": "$34.71",
            "Cost per Deposit)": "$79.92"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "147,268",
            "Clicks": "20,163",
            "Sign Ups": "171",
            "Deposits": "96",
            "Cost per Sign Up": "$56.00",
            "Cost per Deposit)": "$68.14"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "141,985",
            "Clicks": "19,385",
            "Sign Ups": "206",
            "Deposits": "116",
            "Cost per Sign Up": "$52.79",
            "Cost per Deposit)": "$84.70"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "125,378",
            "Clicks": "18,721",
            "Sign Ups": "224",
            "Deposits": "122",
            "Cost per Sign Up": "$49.89",
            "Cost per Deposit)": "$81.57"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "138,903",
            "Clicks": "18,470",
            "Sign Ups": "249",
            "Deposits": "86",
            "Cost per Sign Up": "$30.13",
            "Cost per Deposit)": "$55.70"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "123,463",
            "Clicks": "24,868",
            "Sign Ups": "198",
            "Deposits": "90",
            "Cost per Sign Up": "$38.21",
            "Cost per Deposit)": "$63.10"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "91,287",
            "Clicks": "21,542",
            "Sign Ups": "161",
            "Deposits": "88",
            "Cost per Sign Up": "$52.71",
            "Cost per Deposit)": "$41.56"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "97,234",
            "Clicks": "20,523",
            "Sign Ups": "200",
            "Deposits": "99",
            "Cost per Sign Up": "$24.88",
            "Cost per Deposit)": "$44.80"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "154,644",
            "Clicks": "17,915",
            "Sign Ups": "206",
            "Deposits": "85",
            "Cost per Sign Up": "$45.41",
            "Cost per Deposit)": "$73.79"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "102,213",
            "Clicks": "24,657",
            "Sign Ups": "182",
            "Deposits": "112",
            "Cost per Sign Up": "$31.45",
            "Cost per Deposit)": "$50.79"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "118,292",
            "Clicks": "20,010",
            "Sign Ups": "228",
            "Deposits": "77",
            "Cost per Sign Up": "$44.17",
            "Cost per Deposit)": "$84.46"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "102,368",
            "Clicks": "29,255",
            "Sign Ups": "195",
            "Deposits": "93",
            "Cost per Sign Up": "$42.52",
            "Cost per Deposit)": "$52.24"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "141,730",
            "Clicks": "19,156",
            "Sign Ups": "157",
            "Deposits": "96",
            "Cost per Sign Up": "$58.69",
            "Cost per Deposit)": "$70.08"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "96,158",
            "Clicks": "27,676",
            "Sign Ups": "235",
            "Deposits": "123",
            "Cost per Sign Up": "$39.95",
            "Cost per Deposit)": "$48.36"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "110,592",
            "Clicks": "17,934",
            "Sign Ups": "200",
            "Deposits": "76",
            "Cost per Sign Up": "$41.47",
            "Cost per Deposit)": "$67.55"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "99,312",
            "Clicks": "24,178",
            "Sign Ups": "182",
            "Deposits": "95",
            "Cost per Sign Up": "$59.78",
            "Cost per Deposit)": "$70.90"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "125,052",
            "Clicks": "17,690",
            "Sign Ups": "171",
            "Deposits": "110",
            "Cost per Sign Up": "$48.18",
            "Cost per Deposit)": "$88.05"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "96,454",
            "Clicks": "26,374",
            "Sign Ups": "210",
            "Deposits": "103",
            "Cost per Sign Up": "$30.88",
            "Cost per Deposit)": "$40.80"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "137,590",
            "Clicks": "29,261",
            "Sign Ups": "234",
            "Deposits": "117",
            "Cost per Sign Up": "$29.42",
            "Cost per Deposit)": "$75.34"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "143,195",
            "Clicks": "19,892",
            "Sign Ups": "153",
            "Deposits": "118",
            "Cost per Sign Up": "$59.42",
            "Cost per Deposit)": "$76.62"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "137,914",
            "Clicks": "28,215",
            "Sign Ups": "238",
            "Deposits": "120",
            "Cost per Sign Up": "$41.55",
            "Cost per Deposit)": "$59.07"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "133,015",
            "Clicks": "28,152",
            "Sign Ups": "198",
            "Deposits": "109",
            "Cost per Sign Up": "$31.49",
            "Cost per Deposit)": "$50.48"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "94,522",
            "Clicks": "19,323",
            "Sign Ups": "184",
            "Deposits": "122",
            "Cost per Sign Up": "$57.40",
            "Cost per Deposit)": "$55.62"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "138,280",
            "Clicks": "27,629",
            "Sign Ups": "232",
            "Deposits": "103",
            "Cost per Sign Up": "$36.29",
            "Cost per Deposit)": "$49.02"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "91,143",
            "Clicks": "27,511",
            "Sign Ups": "200",
            "Deposits": "117",
            "Cost per Sign Up": "$30.21",
            "Cost per Deposit)": "$78.45"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "131,804",
            "Clicks": "27,917",
            "Sign Ups": "236",
            "Deposits": "82",
            "Cost per Sign Up": "$22.19",
            "Cost per Deposit)": "$77.40"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "147,306",
            "Clicks": "20,092",
            "Sign Ups": "234",
            "Deposits": "113",
            "Cost per Sign Up": "$35.42",
            "Cost per Deposit)": "$83.94"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "92,273",
            "Clicks": "22,655",
            "Sign Ups": "169",
            "Deposits": "81",
            "Cost per Sign Up": "$23.40",
            "Cost per Deposit)": "$81.50"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "147,526",
            "Clicks": "19,208",
            "Sign Ups": "200",
            "Deposits": "112",
            "Cost per Sign Up": "$43.94",
            "Cost per Deposit)": "$55.96"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "116,558",
            "Clicks": "15,697",
            "Sign Ups": "230",
            "Deposits": "85",
            "Cost per Sign Up": "$50.15",
            "Cost per Deposit)": "$80.72"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "129,315",
            "Clicks": "19,615",
            "Sign Ups": "247",
            "Deposits": "112",
            "Cost per Sign Up": "$34.57",
            "Cost per Deposit)": "$80.57"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "90,780",
            "Clicks": "16,657",
            "Sign Ups": "173",
            "Deposits": "91",
            "Cost per Sign Up": "$31.73",
            "Cost per Deposit)": "$81.89"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "154,241",
            "Clicks": "19,334",
            "Sign Ups": "214",
            "Deposits": "89",
            "Cost per Sign Up": "$56.30",
            "Cost per Deposit)": "$50.28"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "156,419",
            "Clicks": "26,063",
            "Sign Ups": "193",
            "Deposits": "115",
            "Cost per Sign Up": "$50.62",
            "Cost per Deposit)": "$80.99"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "145,879",
            "Clicks": "29,504",
            "Sign Ups": "168",
            "Deposits": "87",
            "Cost per Sign Up": "$56.72",
            "Cost per Deposit)": "$54.45"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "152,336",
            "Clicks": "16,243",
            "Sign Ups": "219",
            "Deposits": "90",
            "Cost per Sign Up": "$33.61",
            "Cost per Deposit)": "$77.22"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "157,389",
            "Clicks": "21,422",
            "Sign Ups": "191",
            "Deposits": "101",
            "Cost per Sign Up": "$32.33",
            "Cost per Deposit)": "$84.49"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "128,470",
            "Clicks": "20,743",
            "Sign Ups": "185",
            "Deposits": "120",
            "Cost per Sign Up": "$42.47",
            "Cost per Deposit)": "$41.52"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "147,833",
            "Clicks": "18,500",
            "Sign Ups": "188",
            "Deposits": "90",
            "Cost per Sign Up": "$48.26",
            "Cost per Deposit)": "$83.98"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "114,950",
            "Clicks": "19,527",
            "Sign Ups": "205",
            "Deposits": "125",
            "Cost per Sign Up": "$31.24",
            "Cost per Deposit)": "$68.83"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "119,955",
            "Clicks": "22,185",
            "Sign Ups": "215",
            "Deposits": "76",
            "Cost per Sign Up": "$57.48",
            "Cost per Deposit)": "$86.26"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "95,151",
            "Clicks": "26,680",
            "Sign Ups": "154",
            "Deposits": "103",
            "Cost per Sign Up": "$59.05",
            "Cost per Deposit)": "$87.07"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "151,979",
            "Clicks": "29,196",
            "Sign Ups": "223",
            "Deposits": "120",
            "Cost per Sign Up": "$27.53",
            "Cost per Deposit)": "$63.28"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "144,026",
            "Clicks": "17,246",
            "Sign Ups": "228",
            "Deposits": "121",
            "Cost per Sign Up": "$20.75",
            "Cost per Deposit)": "$62.53"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "107,804",
            "Clicks": "18,318",
            "Sign Ups": "249",
            "Deposits": "98",
            "Cost per Sign Up": "$20.20",
            "Cost per Deposit)": "$49.63"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "90,926",
            "Clicks": "28,537",
            "Sign Ups": "250",
            "Deposits": "112",
            "Cost per Sign Up": "$27.36",
            "Cost per Deposit)": "$87.37"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "133,770",
            "Clicks": "23,779",
            "Sign Ups": "176",
            "Deposits": "113",
            "Cost per Sign Up": "$57.94",
            "Cost per Deposit)": "$44.09"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "118,897",
            "Clicks": "17,884",
            "Sign Ups": "219",
            "Deposits": "122",
            "Cost per Sign Up": "$46.15",
            "Cost per Deposit)": "$72.29"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "147,459",
            "Clicks": "16,906",
            "Sign Ups": "179",
            "Deposits": "76",
            "Cost per Sign Up": "$40.87",
            "Cost per Deposit)": "$77.17"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "90,406",
            "Clicks": "27,735",
            "Sign Ups": "150",
            "Deposits": "94",
            "Cost per Sign Up": "$56.24",
            "Cost per Deposit)": "$73.64"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "105,911",
            "Clicks": "28,478",
            "Sign Ups": "204",
            "Deposits": "97",
            "Cost per Sign Up": "$26.09",
            "Cost per Deposit)": "$87.39"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "151,026",
            "Clicks": "17,884",
            "Sign Ups": "180",
            "Deposits": "117",
            "Cost per Sign Up": "$58.99",
            "Cost per Deposit)": "$64.80"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "94,543",
            "Clicks": "26,165",
            "Sign Ups": "250",
            "Deposits": "113",
            "Cost per Sign Up": "$25.29",
            "Cost per Deposit)": "$80.45"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "101,449",
            "Clicks": "24,069",
            "Sign Ups": "219",
            "Deposits": "97",
            "Cost per Sign Up": "$37.76",
            "Cost per Deposit)": "$66.18"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "155,904",
            "Clicks": "19,837",
            "Sign Ups": "175",
            "Deposits": "103",
            "Cost per Sign Up": "$59.37",
            "Cost per Deposit)": "$56.12"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "111,174",
            "Clicks": "23,334",
            "Sign Ups": "225",
            "Deposits": "124",
            "Cost per Sign Up": "$36.09",
            "Cost per Deposit)": "$76.74"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "122,799",
            "Clicks": "24,835",
            "Sign Ups": "223",
            "Deposits": "108",
            "Cost per Sign Up": "$54.01",
            "Cost per Deposit)": "$44.24"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "121,416",
            "Clicks": "16,169",
            "Sign Ups": "236",
            "Deposits": "115",
            "Cost per Sign Up": "$26.93",
            "Cost per Deposit)": "$72.05"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "115,812",
            "Clicks": "24,403",
            "Sign Ups": "175",
            "Deposits": "103",
            "Cost per Sign Up": "$57.04",
            "Cost per Deposit)": "$80.32"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "95,516",
            "Clicks": "23,237",
            "Sign Ups": "185",
            "Deposits": "109",
            "Cost per Sign Up": "$41.38",
            "Cost per Deposit)": "$45.84"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "100,565",
            "Clicks": "17,508",
            "Sign Ups": "179",
            "Deposits": "75",
            "Cost per Sign Up": "$36.78",
            "Cost per Deposit)": "$44.59"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "112,974",
            "Clicks": "28,275",
            "Sign Ups": "229",
            "Deposits": "107",
            "Cost per Sign Up": "$20.51",
            "Cost per Deposit)": "$62.84"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "128,758",
            "Clicks": "21,626",
            "Sign Ups": "240",
            "Deposits": "85",
            "Cost per Sign Up": "$56.34",
            "Cost per Deposit)": "$58.18"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "141,806",
            "Clicks": "26,995",
            "Sign Ups": "208",
            "Deposits": "121",
            "Cost per Sign Up": "$47.13",
            "Cost per Deposit)": "$52.31"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "124,536",
            "Clicks": "19,423",
            "Sign Ups": "194",
            "Deposits": "85",
            "Cost per Sign Up": "$46.89",
            "Cost per Deposit)": "$53.87"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "147,388",
            "Clicks": "28,150",
            "Sign Ups": "226",
            "Deposits": "122",
            "Cost per Sign Up": "$28.90",
            "Cost per Deposit)": "$63.96"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "148,747",
            "Clicks": "17,855",
            "Sign Ups": "150",
            "Deposits": "120",
            "Cost per Sign Up": "$47.76",
            "Cost per Deposit)": "$72.66"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "146,275",
            "Clicks": "24,651",
            "Sign Ups": "195",
            "Deposits": "121",
            "Cost per Sign Up": "$36.05",
            "Cost per Deposit)": "$66.80"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "129,484",
            "Clicks": "17,905",
            "Sign Ups": "234",
            "Deposits": "76",
            "Cost per Sign Up": "$40.63",
            "Cost per Deposit)": "$66.63"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "148,298",
            "Clicks": "19,396",
            "Sign Ups": "208",
            "Deposits": "89",
            "Cost per Sign Up": "$44.03",
            "Cost per Deposit)": "$78.32"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "123,548",
            "Clicks": "22,238",
            "Sign Ups": "235",
            "Deposits": "101",
            "Cost per Sign Up": "$32.98",
            "Cost per Deposit)": "$50.97"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "99,909",
            "Clicks": "28,120",
            "Sign Ups": "186",
            "Deposits": "92",
            "Cost per Sign Up": "$27.58",
            "Cost per Deposit)": "$47.29"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "97,399",
            "Clicks": "28,621",
            "Sign Ups": "209",
            "Deposits": "89",
            "Cost per Sign Up": "$25.16",
            "Cost per Deposit)": "$88.28"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "117,429",
            "Clicks": "25,947",
            "Sign Ups": "241",
            "Deposits": "112",
            "Cost per Sign Up": "$51.00",
            "Cost per Deposit)": "$79.39"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "104,338",
            "Clicks": "18,091",
            "Sign Ups": "163",
            "Deposits": "85",
            "Cost per Sign Up": "$24.46",
            "Cost per Deposit)": "$75.89"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "99,221",
            "Clicks": "19,777",
            "Sign Ups": "203",
            "Deposits": "103",
            "Cost per Sign Up": "$49.88",
            "Cost per Deposit)": "$67.59"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "90,253",
            "Clicks": "18,079",
            "Sign Ups": "231",
            "Deposits": "110",
            "Cost per Sign Up": "$41.28",
            "Cost per Deposit)": "$45.09"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "155,598",
            "Clicks": "27,137",
            "Sign Ups": "165",
            "Deposits": "83",
            "Cost per Sign Up": "$53.07",
            "Cost per Deposit)": "$40.96"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "110,773",
            "Clicks": "20,952",
            "Sign Ups": "174",
            "Deposits": "89",
            "Cost per Sign Up": "$37.51",
            "Cost per Deposit)": "$71.27"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "141,483",
            "Clicks": "21,603",
            "Sign Ups": "171",
            "Deposits": "80",
            "Cost per Sign Up": "$33.98",
            "Cost per Deposit)": "$46.60"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "157,256",
            "Clicks": "19,539",
            "Sign Ups": "235",
            "Deposits": "101",
            "Cost per Sign Up": "$57.51",
            "Cost per Deposit)": "$48.14"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "117,701",
            "Clicks": "23,470",
            "Sign Ups": "250",
            "Deposits": "115",
            "Cost per Sign Up": "$57.10",
            "Cost per Deposit)": "$73.33"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "106,046",
            "Clicks": "21,935",
            "Sign Ups": "176",
            "Deposits": "94",
            "Cost per Sign Up": "$43.59",
            "Cost per Deposit)": "$81.31"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "157,704",
            "Clicks": "28,442",
            "Sign Ups": "190",
            "Deposits": "96",
            "Cost per Sign Up": "$57.05",
            "Cost per Deposit)": "$79.15"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "130,079",
            "Clicks": "22,421",
            "Sign Ups": "207",
            "Deposits": "92",
            "Cost per Sign Up": "$28.22",
            "Cost per Deposit)": "$75.30"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "99,192",
            "Clicks": "16,008",
            "Sign Ups": "154",
            "Deposits": "77",
            "Cost per Sign Up": "$20.37",
            "Cost per Deposit)": "$58.59"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "133,325",
            "Clicks": "22,343",
            "Sign Ups": "190",
            "Deposits": "87",
            "Cost per Sign Up": "$57.87",
            "Cost per Deposit)": "$42.29"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "158,399",
            "Clicks": "22,944",
            "Sign Ups": "240",
            "Deposits": "123",
            "Cost per Sign Up": "$28.88",
            "Cost per Deposit)": "$45.45"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "98,084",
            "Clicks": "15,706",
            "Sign Ups": "173",
            "Deposits": "111",
            "Cost per Sign Up": "$30.80",
            "Cost per Deposit)": "$75.66"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "103,710",
            "Clicks": "26,205",
            "Sign Ups": "192",
            "Deposits": "109",
            "Cost per Sign Up": "$50.32",
            "Cost per Deposit)": "$71.14"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "92,692",
            "Clicks": "17,635",
            "Sign Ups": "198",
            "Deposits": "102",
            "Cost per Sign Up": "$24.56",
            "Cost per Deposit)": "$58.16"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "97,342",
            "Clicks": "27,289",
            "Sign Ups": "189",
            "Deposits": "75",
            "Cost per Sign Up": "$39.20",
            "Cost per Deposit)": "$49.13"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "100,034",
            "Clicks": "18,503",
            "Sign Ups": "207",
            "Deposits": "76",
            "Cost per Sign Up": "$45.42",
            "Cost per Deposit)": "$47.80"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "127,232",
            "Clicks": "17,995",
            "Sign Ups": "170",
            "Deposits": "106",
            "Cost per Sign Up": "$34.58",
            "Cost per Deposit)": "$82.66"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "106,470",
            "Clicks": "20,808",
            "Sign Ups": "173",
            "Deposits": "114",
            "Cost per Sign Up": "$20.41",
            "Cost per Deposit)": "$86.23"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "147,172",
            "Clicks": "18,004",
            "Sign Ups": "162",
            "Deposits": "91",
            "Cost per Sign Up": "$42.66",
            "Cost per Deposit)": "$50.35"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "119,237",
            "Clicks": "15,946",
            "Sign Ups": "212",
            "Deposits": "86",
            "Cost per Sign Up": "$56.01",
            "Cost per Deposit)": "$48.89"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "153,188",
            "Clicks": "15,073",
            "Sign Ups": "225",
            "Deposits": "83",
            "Cost per Sign Up": "$42.02",
            "Cost per Deposit)": "$85.02"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "120,373",
            "Clicks": "22,103",
            "Sign Ups": "215",
            "Deposits": "122",
            "Cost per Sign Up": "$39.54",
            "Cost per Deposit)": "$67.49"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "117,730",
            "Clicks": "29,910",
            "Sign Ups": "166",
            "Deposits": "121",
            "Cost per Sign Up": "$34.45",
            "Cost per Deposit)": "$74.18"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "145,390",
            "Clicks": "16,032",
            "Sign Ups": "196",
            "Deposits": "105",
            "Cost per Sign Up": "$52.08",
            "Cost per Deposit)": "$54.62"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "115,493",
            "Clicks": "18,421",
            "Sign Ups": "166",
            "Deposits": "96",
            "Cost per Sign Up": "$33.94",
            "Cost per Deposit)": "$68.41"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "138,594",
            "Clicks": "18,630",
            "Sign Ups": "152",
            "Deposits": "76",
            "Cost per Sign Up": "$52.24",
            "Cost per Deposit)": "$46.66"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "133,545",
            "Clicks": "28,614",
            "Sign Ups": "160",
            "Deposits": "93",
            "Cost per Sign Up": "$41.44",
            "Cost per Deposit)": "$81.59"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "118,703",
            "Clicks": "22,683",
            "Sign Ups": "246",
            "Deposits": "98",
            "Cost per Sign Up": "$38.34",
            "Cost per Deposit)": "$61.04"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "92,469",
            "Clicks": "27,477",
            "Sign Ups": "212",
            "Deposits": "104",
            "Cost per Sign Up": "$20.10",
            "Cost per Deposit)": "$66.80"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "142,367",
            "Clicks": "29,000",
            "Sign Ups": "161",
            "Deposits": "125",
            "Cost per Sign Up": "$25.92",
            "Cost per Deposit)": "$70.81"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "120,794",
            "Clicks": "17,893",
            "Sign Ups": "174",
            "Deposits": "121",
            "Cost per Sign Up": "$42.10",
            "Cost per Deposit)": "$44.49"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "90,126",
            "Clicks": "22,386",
            "Sign Ups": "163",
            "Deposits": "101",
            "Cost per Sign Up": "$32.98",
            "Cost per Deposit)": "$47.98"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "143,955",
            "Clicks": "17,671",
            "Sign Ups": "207",
            "Deposits": "109",
            "Cost per Sign Up": "$25.45",
            "Cost per Deposit)": "$77.19"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "114,052",
            "Clicks": "24,418",
            "Sign Ups": "197",
            "Deposits": "124",
            "Cost per Sign Up": "$37.85",
            "Cost per Deposit)": "$81.50"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "146,449",
            "Clicks": "23,871",
            "Sign Ups": "172",
            "Deposits": "107",
            "Cost per Sign Up": "$52.96",
            "Cost per Deposit)": "$64.42"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "95,628",
            "Clicks": "26,208",
            "Sign Ups": "176",
            "Deposits": "103",
            "Cost per Sign Up": "$54.86",
            "Cost per Deposit)": "$60.50"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "96,819",
            "Clicks": "17,534",
            "Sign Ups": "169",
            "Deposits": "75",
            "Cost per Sign Up": "$21.35",
            "Cost per Deposit)": "$74.56"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "152,151",
            "Clicks": "18,933",
            "Sign Ups": "182",
            "Deposits": "113",
            "Cost per Sign Up": "$57.57",
            "Cost per Deposit)": "$75.36"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "123,125",
            "Clicks": "17,007",
            "Sign Ups": "196",
            "Deposits": "92",
            "Cost per Sign Up": "$40.26",
            "Cost per Deposit)": "$68.84"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "132,651",
            "Clicks": "20,678",
            "Sign Ups": "190",
            "Deposits": "108",
            "Cost per Sign Up": "$26.66",
            "Cost per Deposit)": "$58.87"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "120,341",
            "Clicks": "20,136",
            "Sign Ups": "188",
            "Deposits": "83",
            "Cost per Sign Up": "$38.86",
            "Cost per Deposit)": "$79.76"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "97,001",
            "Clicks": "27,533",
            "Sign Ups": "157",
            "Deposits": "124",
            "Cost per Sign Up": "$31.21",
            "Cost per Deposit)": "$48.95"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "122,403",
            "Clicks": "25,686",
            "Sign Ups": "165",
            "Deposits": "111",
            "Cost per Sign Up": "$32.59",
            "Cost per Deposit)": "$58.07"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "92,838",
            "Clicks": "20,199",
            "Sign Ups": "152",
            "Deposits": "113",
            "Cost per Sign Up": "$32.76",
            "Cost per Deposit)": "$40.29"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "102,611",
            "Clicks": "21,269",
            "Sign Ups": "231",
            "Deposits": "95",
            "Cost per Sign Up": "$21.97",
            "Cost per Deposit)": "$42.94"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "122,147",
            "Clicks": "21,713",
            "Sign Ups": "168",
            "Deposits": "112",
            "Cost per Sign Up": "$40.20",
            "Cost per Deposit)": "$69.82"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "117,075",
            "Clicks": "16,450",
            "Sign Ups": "196",
            "Deposits": "93",
            "Cost per Sign Up": "$23.29",
            "Cost per Deposit)": "$60.63"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "137,183",
            "Clicks": "20,005",
            "Sign Ups": "194",
            "Deposits": "111",
            "Cost per Sign Up": "$29.70",
            "Cost per Deposit)": "$45.01"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "142,317",
            "Clicks": "20,527",
            "Sign Ups": "184",
            "Deposits": "118",
            "Cost per Sign Up": "$35.03",
            "Cost per Deposit)": "$81.89"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "122,597",
            "Clicks": "22,867",
            "Sign Ups": "191",
            "Deposits": "103",
            "Cost per Sign Up": "$53.68",
            "Cost per Deposit)": "$72.20"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "128,892",
            "Clicks": "25,922",
            "Sign Ups": "216",
            "Deposits": "86",
            "Cost per Sign Up": "$41.51",
            "Cost per Deposit)": "$84.68"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "157,594",
            "Clicks": "22,552",
            "Sign Ups": "206",
            "Deposits": "122",
            "Cost per Sign Up": "$31.76",
            "Cost per Deposit)": "$77.72"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "156,819",
            "Clicks": "16,275",
            "Sign Ups": "169",
            "Deposits": "101",
            "Cost per Sign Up": "$51.26",
            "Cost per Deposit)": "$59.96"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "98,317",
            "Clicks": "29,419",
            "Sign Ups": "156",
            "Deposits": "111",
            "Cost per Sign Up": "$27.13",
            "Cost per Deposit)": "$67.26"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "92,937",
            "Clicks": "17,887",
            "Sign Ups": "155",
            "Deposits": "94",
            "Cost per Sign Up": "$56.11",
            "Cost per Deposit)": "$78.31"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "142,813",
            "Clicks": "26,293",
            "Sign Ups": "188",
            "Deposits": "119",
            "Cost per Sign Up": "$25.41",
            "Cost per Deposit)": "$50.57"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "127,490",
            "Clicks": "16,446",
            "Sign Ups": "213",
            "Deposits": "86",
            "Cost per Sign Up": "$38.42",
            "Cost per Deposit)": "$56.06"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "129,386",
            "Clicks": "29,064",
            "Sign Ups": "224",
            "Deposits": "111",
            "Cost per Sign Up": "$58.78",
            "Cost per Deposit)": "$80.37"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "122,789",
            "Clicks": "26,183",
            "Sign Ups": "240",
            "Deposits": "114",
            "Cost per Sign Up": "$38.94",
            "Cost per Deposit)": "$82.50"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "116,328",
            "Clicks": "19,288",
            "Sign Ups": "190",
            "Deposits": "99",
            "Cost per Sign Up": "$43.69",
            "Cost per Deposit)": "$87.75"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "136,721",
            "Clicks": "24,102",
            "Sign Ups": "241",
            "Deposits": "94",
            "Cost per Sign Up": "$22.62",
            "Cost per Deposit)": "$65.12"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "128,222",
            "Clicks": "27,739",
            "Sign Ups": "229",
            "Deposits": "89",
            "Cost per Sign Up": "$40.82",
            "Cost per Deposit)": "$84.31"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "111,999",
            "Clicks": "24,698",
            "Sign Ups": "242",
            "Deposits": "97",
            "Cost per Sign Up": "$32.44",
            "Cost per Deposit)": "$75.60"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "109,735",
            "Clicks": "25,920",
            "Sign Ups": "200",
            "Deposits": "116",
            "Cost per Sign Up": "$35.25",
            "Cost per Deposit)": "$72.35"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "127,934",
            "Clicks": "19,849",
            "Sign Ups": "234",
            "Deposits": "123",
            "Cost per Sign Up": "$52.89",
            "Cost per Deposit)": "$75.44"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "104,307",
            "Clicks": "17,046",
            "Sign Ups": "150",
            "Deposits": "117",
            "Cost per Sign Up": "$54.57",
            "Cost per Deposit)": "$86.31"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "157,375",
            "Clicks": "20,079",
            "Sign Ups": "155",
            "Deposits": "105",
            "Cost per Sign Up": "$40.63",
            "Cost per Deposit)": "$70.32"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "96,614",
            "Clicks": "23,114",
            "Sign Ups": "232",
            "Deposits": "88",
            "Cost per Sign Up": "$32.74",
            "Cost per Deposit)": "$66.26"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "90,269",
            "Clicks": "17,385",
            "Sign Ups": "186",
            "Deposits": "113",
            "Cost per Sign Up": "$26.67",
            "Cost per Deposit)": "$46.52"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "137,653",
            "Clicks": "26,840",
            "Sign Ups": "166",
            "Deposits": "118",
            "Cost per Sign Up": "$56.18",
            "Cost per Deposit)": "$48.21"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "127,785",
            "Clicks": "25,660",
            "Sign Ups": "152",
            "Deposits": "97",
            "Cost per Sign Up": "$34.04",
            "Cost per Deposit)": "$79.91"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "159,630",
            "Clicks": "18,022",
            "Sign Ups": "246",
            "Deposits": "93",
            "Cost per Sign Up": "$21.18",
            "Cost per Deposit)": "$89.54"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "103,386",
            "Clicks": "28,781",
            "Sign Ups": "200",
            "Deposits": "105",
            "Cost per Sign Up": "$50.61",
            "Cost per Deposit)": "$59.07"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "109,124",
            "Clicks": "29,456",
            "Sign Ups": "183",
            "Deposits": "123",
            "Cost per Sign Up": "$24.30",
            "Cost per Deposit)": "$74.00"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "149,005",
            "Clicks": "26,557",
            "Sign Ups": "207",
            "Deposits": "85",
            "Cost per Sign Up": "$45.55",
            "Cost per Deposit)": "$72.48"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "157,090",
            "Clicks": "21,898",
            "Sign Ups": "152",
            "Deposits": "104",
            "Cost per Sign Up": "$29.53",
            "Cost per Deposit)": "$61.39"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "155,047",
            "Clicks": "22,480",
            "Sign Ups": "230",
            "Deposits": "102",
            "Cost per Sign Up": "$40.80",
            "Cost per Deposit)": "$76.95"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "98,477",
            "Clicks": "21,719",
            "Sign Ups": "225",
            "Deposits": "104",
            "Cost per Sign Up": "$51.31",
            "Cost per Deposit)": "$85.92"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "110,290",
            "Clicks": "15,998",
            "Sign Ups": "221",
            "Deposits": "110",
            "Cost per Sign Up": "$37.87",
            "Cost per Deposit)": "$79.47"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "134,691",
            "Clicks": "15,161",
            "Sign Ups": "165",
            "Deposits": "89",
            "Cost per Sign Up": "$37.24",
            "Cost per Deposit)": "$76.93"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "114,807",
            "Clicks": "29,531",
            "Sign Ups": "246",
            "Deposits": "112",
            "Cost per Sign Up": "$53.52",
            "Cost per Deposit)": "$49.50"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "154,693",
            "Clicks": "25,358",
            "Sign Ups": "181",
            "Deposits": "78",
            "Cost per Sign Up": "$56.30",
            "Cost per Deposit)": "$80.89"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "151,981",
            "Clicks": "16,594",
            "Sign Ups": "185",
            "Deposits": "85",
            "Cost per Sign Up": "$22.81",
            "Cost per Deposit)": "$66.40"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "133,592",
            "Clicks": "16,125",
            "Sign Ups": "226",
            "Deposits": "82",
            "Cost per Sign Up": "$22.30",
            "Cost per Deposit)": "$74.11"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "151,223",
            "Clicks": "17,104",
            "Sign Ups": "173",
            "Deposits": "105",
            "Cost per Sign Up": "$59.86",
            "Cost per Deposit)": "$41.43"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "147,785",
            "Clicks": "25,779",
            "Sign Ups": "244",
            "Deposits": "101",
            "Cost per Sign Up": "$41.65",
            "Cost per Deposit)": "$61.64"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "135,508",
            "Clicks": "27,508",
            "Sign Ups": "190",
            "Deposits": "77",
            "Cost per Sign Up": "$48.26",
            "Cost per Deposit)": "$62.37"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "117,309",
            "Clicks": "25,763",
            "Sign Ups": "250",
            "Deposits": "117",
            "Cost per Sign Up": "$38.74",
            "Cost per Deposit)": "$83.80"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "97,491",
            "Clicks": "28,088",
            "Sign Ups": "176",
            "Deposits": "87",
            "Cost per Sign Up": "$56.83",
            "Cost per Deposit)": "$56.59"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "116,901",
            "Clicks": "23,315",
            "Sign Ups": "240",
            "Deposits": "122",
            "Cost per Sign Up": "$23.60",
            "Cost per Deposit)": "$73.01"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "147,684",
            "Clicks": "29,367",
            "Sign Ups": "207",
            "Deposits": "80",
            "Cost per Sign Up": "$20.89",
            "Cost per Deposit)": "$77.08"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "127,248",
            "Clicks": "18,115",
            "Sign Ups": "160",
            "Deposits": "105",
            "Cost per Sign Up": "$38.59",
            "Cost per Deposit)": "$51.45"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "151,478",
            "Clicks": "21,268",
            "Sign Ups": "242",
            "Deposits": "118",
            "Cost per Sign Up": "$22.44",
            "Cost per Deposit)": "$71.45"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "125,972",
            "Clicks": "25,119",
            "Sign Ups": "200",
            "Deposits": "113",
            "Cost per Sign Up": "$23.02",
            "Cost per Deposit)": "$42.16"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "120,055",
            "Clicks": "23,196",
            "Sign Ups": "183",
            "Deposits": "101",
            "Cost per Sign Up": "$21.14",
            "Cost per Deposit)": "$63.23"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "139,876",
            "Clicks": "20,494",
            "Sign Ups": "211",
            "Deposits": "92",
            "Cost per Sign Up": "$47.20",
            "Cost per Deposit)": "$72.95"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "140,390",
            "Clicks": "23,537",
            "Sign Ups": "191",
            "Deposits": "122",
            "Cost per Sign Up": "$34.18",
            "Cost per Deposit)": "$63.91"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "115,680",
            "Clicks": "24,690",
            "Sign Ups": "212",
            "Deposits": "75",
            "Cost per Sign Up": "$44.51",
            "Cost per Deposit)": "$73.35"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "121,522",
            "Clicks": "27,668",
            "Sign Ups": "160",
            "Deposits": "111",
            "Cost per Sign Up": "$31.12",
            "Cost per Deposit)": "$55.40"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "117,752",
            "Clicks": "24,547",
            "Sign Ups": "240",
            "Deposits": "86",
            "Cost per Sign Up": "$20.53",
            "Cost per Deposit)": "$48.41"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "128,288",
            "Clicks": "18,477",
            "Sign Ups": "155",
            "Deposits": "120",
            "Cost per Sign Up": "$37.28",
            "Cost per Deposit)": "$63.11"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "118,878",
            "Clicks": "19,754",
            "Sign Ups": "186",
            "Deposits": "99",
            "Cost per Sign Up": "$39.61",
            "Cost per Deposit)": "$81.44"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "158,200",
            "Clicks": "25,649",
            "Sign Ups": "220",
            "Deposits": "79",
            "Cost per Sign Up": "$37.43",
            "Cost per Deposit)": "$85.54"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "131,716",
            "Clicks": "23,515",
            "Sign Ups": "201",
            "Deposits": "75",
            "Cost per Sign Up": "$59.35",
            "Cost per Deposit)": "$77.55"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "115,844",
            "Clicks": "19,771",
            "Sign Ups": "153",
            "Deposits": "110",
            "Cost per Sign Up": "$33.95",
            "Cost per Deposit)": "$56.32"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "115,913",
            "Clicks": "27,779",
            "Sign Ups": "196",
            "Deposits": "119",
            "Cost per Sign Up": "$44.45",
            "Cost per Deposit)": "$66.18"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "98,747",
            "Clicks": "21,202",
            "Sign Ups": "222",
            "Deposits": "104",
            "Cost per Sign Up": "$47.59",
            "Cost per Deposit)": "$89.78"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "142,576",
            "Clicks": "22,589",
            "Sign Ups": "155",
            "Deposits": "102",
            "Cost per Sign Up": "$27.23",
            "Cost per Deposit)": "$46.94"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "156,513",
            "Clicks": "22,998",
            "Sign Ups": "228",
            "Deposits": "114",
            "Cost per Sign Up": "$24.26",
            "Cost per Deposit)": "$69.62"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "106,583",
            "Clicks": "21,750",
            "Sign Ups": "159",
            "Deposits": "85",
            "Cost per Sign Up": "$42.53",
            "Cost per Deposit)": "$78.46"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "126,109",
            "Clicks": "27,068",
            "Sign Ups": "169",
            "Deposits": "94",
            "Cost per Sign Up": "$32.32",
            "Cost per Deposit)": "$80.48"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "117,644",
            "Clicks": "23,243",
            "Sign Ups": "154",
            "Deposits": "87",
            "Cost per Sign Up": "$58.64",
            "Cost per Deposit)": "$45.05"
          }
        ]
      },
      "Web3NativesData": {
        "name": "Web3 Natives",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "130,579",
            "Clicks": "17,908",
            "Sign Ups": "406",
            "Deposits": "170",
            "Cost per Sign Up": "$21.85",
            "Cost per Deposit)": "$46.40"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "170,931",
            "Clicks": "22,350",
            "Sign Ups": "394",
            "Deposits": "160",
            "Cost per Sign Up": "$17.24",
            "Cost per Deposit)": "$43.40"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "171,185",
            "Clicks": "26,706",
            "Sign Ups": "324",
            "Deposits": "137",
            "Cost per Sign Up": "$22.08",
            "Cost per Deposit)": "$29.57"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "207,519",
            "Clicks": "15,194",
            "Sign Ups": "441",
            "Deposits": "149",
            "Cost per Sign Up": "$26.03",
            "Cost per Deposit)": "$50.95"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "184,453",
            "Clicks": "20,488",
            "Sign Ups": "411",
            "Deposits": "194",
            "Cost per Sign Up": "$13.33",
            "Cost per Deposit)": "$56.01"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "193,526",
            "Clicks": "24,783",
            "Sign Ups": "349",
            "Deposits": "213",
            "Cost per Sign Up": "$24.47",
            "Cost per Deposit)": "$32.20"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "193,537",
            "Clicks": "19,903",
            "Sign Ups": "447",
            "Deposits": "125",
            "Cost per Sign Up": "$22.31",
            "Cost per Deposit)": "$57.91"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "149,551",
            "Clicks": "15,125",
            "Sign Ups": "375",
            "Deposits": "193",
            "Cost per Sign Up": "$19.44",
            "Cost per Deposit)": "$35.83"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "131,068",
            "Clicks": "25,065",
            "Sign Ups": "415",
            "Deposits": "202",
            "Cost per Sign Up": "$15.94",
            "Cost per Deposit)": "$30.48"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "195,143",
            "Clicks": "18,962",
            "Sign Ups": "362",
            "Deposits": "161",
            "Cost per Sign Up": "$24.32",
            "Cost per Deposit)": "$31.12"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "198,560",
            "Clicks": "18,930",
            "Sign Ups": "435",
            "Deposits": "183",
            "Cost per Sign Up": "$16.93",
            "Cost per Deposit)": "$56.25"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "139,926",
            "Clicks": "16,663",
            "Sign Ups": "313",
            "Deposits": "133",
            "Cost per Sign Up": "$16.65",
            "Cost per Deposit)": "$51.97"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "204,167",
            "Clicks": "25,435",
            "Sign Ups": "352",
            "Deposits": "147",
            "Cost per Sign Up": "$23.81",
            "Cost per Deposit)": "$34.49"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "191,186",
            "Clicks": "21,322",
            "Sign Ups": "263",
            "Deposits": "143",
            "Cost per Sign Up": "$16.54",
            "Cost per Deposit)": "$32.09"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "160,445",
            "Clicks": "28,931",
            "Sign Ups": "436",
            "Deposits": "165",
            "Cost per Sign Up": "$28.68",
            "Cost per Deposit)": "$33.92"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "194,794",
            "Clicks": "17,984",
            "Sign Ups": "370",
            "Deposits": "151",
            "Cost per Sign Up": "$11.39",
            "Cost per Deposit)": "$33.73"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "182,841",
            "Clicks": "20,423",
            "Sign Ups": "439",
            "Deposits": "142",
            "Cost per Sign Up": "$23.57",
            "Cost per Deposit)": "$58.51"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "176,957",
            "Clicks": "17,580",
            "Sign Ups": "450",
            "Deposits": "149",
            "Cost per Sign Up": "$24.32",
            "Cost per Deposit)": "$39.85"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "134,154",
            "Clicks": "15,517",
            "Sign Ups": "256",
            "Deposits": "182",
            "Cost per Sign Up": "$17.63",
            "Cost per Deposit)": "$29.90"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "187,578",
            "Clicks": "27,492",
            "Sign Ups": "352",
            "Deposits": "185",
            "Cost per Sign Up": "$15.71",
            "Cost per Deposit)": "$22.43"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "212,043",
            "Clicks": "24,096",
            "Sign Ups": "397",
            "Deposits": "195",
            "Cost per Sign Up": "$27.83",
            "Cost per Deposit)": "$50.18"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "173,832",
            "Clicks": "24,670",
            "Sign Ups": "421",
            "Deposits": "222",
            "Cost per Sign Up": "$23.54",
            "Cost per Deposit)": "$30.35"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "200,093",
            "Clicks": "27,070",
            "Sign Ups": "447",
            "Deposits": "170",
            "Cost per Sign Up": "$21.62",
            "Cost per Deposit)": "$31.82"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "159,610",
            "Clicks": "29,315",
            "Sign Ups": "415",
            "Deposits": "181",
            "Cost per Sign Up": "$23.56",
            "Cost per Deposit)": "$33.08"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "196,801",
            "Clicks": "24,888",
            "Sign Ups": "298",
            "Deposits": "148",
            "Cost per Sign Up": "$23.85",
            "Cost per Deposit)": "$28.97"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "193,560",
            "Clicks": "28,509",
            "Sign Ups": "398",
            "Deposits": "188",
            "Cost per Sign Up": "$22.33",
            "Cost per Deposit)": "$30.12"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "156,675",
            "Clicks": "21,551",
            "Sign Ups": "350",
            "Deposits": "148",
            "Cost per Sign Up": "$16.81",
            "Cost per Deposit)": "$47.66"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "204,481",
            "Clicks": "20,321",
            "Sign Ups": "274",
            "Deposits": "170",
            "Cost per Sign Up": "$22.39",
            "Cost per Deposit)": "$30.77"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "188,901",
            "Clicks": "18,889",
            "Sign Ups": "296",
            "Deposits": "174",
            "Cost per Sign Up": "$12.61",
            "Cost per Deposit)": "$49.73"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "177,452",
            "Clicks": "28,136",
            "Sign Ups": "316",
            "Deposits": "132",
            "Cost per Sign Up": "$29.25",
            "Cost per Deposit)": "$29.84"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "188,973",
            "Clicks": "25,840",
            "Sign Ups": "344",
            "Deposits": "204",
            "Cost per Sign Up": "$23.48",
            "Cost per Deposit)": "$45.30"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "190,522",
            "Clicks": "22,696",
            "Sign Ups": "275",
            "Deposits": "179",
            "Cost per Sign Up": "$27.17",
            "Cost per Deposit)": "$58.66"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "159,097",
            "Clicks": "27,038",
            "Sign Ups": "336",
            "Deposits": "157",
            "Cost per Sign Up": "$17.80",
            "Cost per Deposit)": "$43.81"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "138,569",
            "Clicks": "21,314",
            "Sign Ups": "260",
            "Deposits": "205",
            "Cost per Sign Up": "$16.10",
            "Cost per Deposit)": "$48.73"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "173,055",
            "Clicks": "17,415",
            "Sign Ups": "264",
            "Deposits": "171",
            "Cost per Sign Up": "$27.78",
            "Cost per Deposit)": "$50.90"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "135,450",
            "Clicks": "15,060",
            "Sign Ups": "435",
            "Deposits": "210",
            "Cost per Sign Up": "$21.87",
            "Cost per Deposit)": "$39.99"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "218,875",
            "Clicks": "28,931",
            "Sign Ups": "266",
            "Deposits": "137",
            "Cost per Sign Up": "$16.26",
            "Cost per Deposit)": "$25.21"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "155,360",
            "Clicks": "18,606",
            "Sign Ups": "404",
            "Deposits": "171",
            "Cost per Sign Up": "$20.88",
            "Cost per Deposit)": "$35.23"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "158,551",
            "Clicks": "26,199",
            "Sign Ups": "336",
            "Deposits": "154",
            "Cost per Sign Up": "$14.23",
            "Cost per Deposit)": "$49.16"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "180,818",
            "Clicks": "28,773",
            "Sign Ups": "309",
            "Deposits": "147",
            "Cost per Sign Up": "$27.93",
            "Cost per Deposit)": "$44.64"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "203,213",
            "Clicks": "22,690",
            "Sign Ups": "389",
            "Deposits": "146",
            "Cost per Sign Up": "$22.28",
            "Cost per Deposit)": "$44.45"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "154,604",
            "Clicks": "22,187",
            "Sign Ups": "375",
            "Deposits": "128",
            "Cost per Sign Up": "$29.35",
            "Cost per Deposit)": "$25.44"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "176,478",
            "Clicks": "28,440",
            "Sign Ups": "283",
            "Deposits": "189",
            "Cost per Sign Up": "$25.49",
            "Cost per Deposit)": "$56.80"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "141,626",
            "Clicks": "24,608",
            "Sign Ups": "445",
            "Deposits": "171",
            "Cost per Sign Up": "$15.71",
            "Cost per Deposit)": "$23.70"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "179,850",
            "Clicks": "20,460",
            "Sign Ups": "376",
            "Deposits": "164",
            "Cost per Sign Up": "$26.14",
            "Cost per Deposit)": "$44.10"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "130,045",
            "Clicks": "27,235",
            "Sign Ups": "422",
            "Deposits": "125",
            "Cost per Sign Up": "$20.32",
            "Cost per Deposit)": "$42.00"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "135,437",
            "Clicks": "24,652",
            "Sign Ups": "370",
            "Deposits": "169",
            "Cost per Sign Up": "$26.12",
            "Cost per Deposit)": "$45.21"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "188,249",
            "Clicks": "28,424",
            "Sign Ups": "338",
            "Deposits": "129",
            "Cost per Sign Up": "$15.95",
            "Cost per Deposit)": "$56.02"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "201,154",
            "Clicks": "18,014",
            "Sign Ups": "450",
            "Deposits": "194",
            "Cost per Sign Up": "$24.39",
            "Cost per Deposit)": "$58.12"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "140,616",
            "Clicks": "17,718",
            "Sign Ups": "442",
            "Deposits": "202",
            "Cost per Sign Up": "$18.71",
            "Cost per Deposit)": "$44.57"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "206,896",
            "Clicks": "19,867",
            "Sign Ups": "436",
            "Deposits": "195",
            "Cost per Sign Up": "$25.39",
            "Cost per Deposit)": "$29.02"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "143,597",
            "Clicks": "17,641",
            "Sign Ups": "407",
            "Deposits": "169",
            "Cost per Sign Up": "$10.82",
            "Cost per Deposit)": "$56.28"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "161,844",
            "Clicks": "24,312",
            "Sign Ups": "386",
            "Deposits": "217",
            "Cost per Sign Up": "$23.95",
            "Cost per Deposit)": "$40.38"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "168,769",
            "Clicks": "24,645",
            "Sign Ups": "298",
            "Deposits": "193",
            "Cost per Sign Up": "$12.90",
            "Cost per Deposit)": "$37.01"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "199,485",
            "Clicks": "21,813",
            "Sign Ups": "448",
            "Deposits": "144",
            "Cost per Sign Up": "$10.32",
            "Cost per Deposit)": "$54.45"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "178,407",
            "Clicks": "23,448",
            "Sign Ups": "325",
            "Deposits": "126",
            "Cost per Sign Up": "$11.39",
            "Cost per Deposit)": "$27.04"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "149,541",
            "Clicks": "27,883",
            "Sign Ups": "424",
            "Deposits": "136",
            "Cost per Sign Up": "$14.96",
            "Cost per Deposit)": "$48.69"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "175,118",
            "Clicks": "23,484",
            "Sign Ups": "381",
            "Deposits": "159",
            "Cost per Sign Up": "$18.67",
            "Cost per Deposit)": "$37.11"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "182,988",
            "Clicks": "21,055",
            "Sign Ups": "343",
            "Deposits": "136",
            "Cost per Sign Up": "$14.82",
            "Cost per Deposit)": "$54.46"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "201,418",
            "Clicks": "21,577",
            "Sign Ups": "290",
            "Deposits": "180",
            "Cost per Sign Up": "$17.48",
            "Cost per Deposit)": "$55.38"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "155,401",
            "Clicks": "16,515",
            "Sign Ups": "392",
            "Deposits": "182",
            "Cost per Sign Up": "$28.57",
            "Cost per Deposit)": "$26.38"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "190,321",
            "Clicks": "28,450",
            "Sign Ups": "387",
            "Deposits": "165",
            "Cost per Sign Up": "$10.56",
            "Cost per Deposit)": "$21.20"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "184,981",
            "Clicks": "26,654",
            "Sign Ups": "366",
            "Deposits": "202",
            "Cost per Sign Up": "$23.14",
            "Cost per Deposit)": "$27.63"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "209,309",
            "Clicks": "27,255",
            "Sign Ups": "302",
            "Deposits": "149",
            "Cost per Sign Up": "$28.73",
            "Cost per Deposit)": "$39.90"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "218,940",
            "Clicks": "29,921",
            "Sign Ups": "450",
            "Deposits": "149",
            "Cost per Sign Up": "$24.70",
            "Cost per Deposit)": "$56.84"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "208,930",
            "Clicks": "19,628",
            "Sign Ups": "279",
            "Deposits": "162",
            "Cost per Sign Up": "$13.27",
            "Cost per Deposit)": "$49.31"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "167,019",
            "Clicks": "16,534",
            "Sign Ups": "332",
            "Deposits": "136",
            "Cost per Sign Up": "$16.90",
            "Cost per Deposit)": "$23.11"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "203,252",
            "Clicks": "27,549",
            "Sign Ups": "439",
            "Deposits": "216",
            "Cost per Sign Up": "$11.80",
            "Cost per Deposit)": "$25.08"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "184,554",
            "Clicks": "26,839",
            "Sign Ups": "441",
            "Deposits": "206",
            "Cost per Sign Up": "$12.52",
            "Cost per Deposit)": "$34.78"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "172,799",
            "Clicks": "29,622",
            "Sign Ups": "313",
            "Deposits": "149",
            "Cost per Sign Up": "$10.83",
            "Cost per Deposit)": "$35.45"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "182,643",
            "Clicks": "23,460",
            "Sign Ups": "419",
            "Deposits": "225",
            "Cost per Sign Up": "$23.61",
            "Cost per Deposit)": "$56.30"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "215,170",
            "Clicks": "21,786",
            "Sign Ups": "251",
            "Deposits": "216",
            "Cost per Sign Up": "$27.42",
            "Cost per Deposit)": "$56.94"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "167,229",
            "Clicks": "18,441",
            "Sign Ups": "278",
            "Deposits": "202",
            "Cost per Sign Up": "$11.31",
            "Cost per Deposit)": "$43.77"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "193,583",
            "Clicks": "29,348",
            "Sign Ups": "396",
            "Deposits": "224",
            "Cost per Sign Up": "$29.07",
            "Cost per Deposit)": "$53.44"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "206,522",
            "Clicks": "15,311",
            "Sign Ups": "283",
            "Deposits": "154",
            "Cost per Sign Up": "$11.38",
            "Cost per Deposit)": "$58.20"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "146,913",
            "Clicks": "23,017",
            "Sign Ups": "413",
            "Deposits": "194",
            "Cost per Sign Up": "$22.94",
            "Cost per Deposit)": "$58.07"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "191,441",
            "Clicks": "28,859",
            "Sign Ups": "371",
            "Deposits": "211",
            "Cost per Sign Up": "$13.26",
            "Cost per Deposit)": "$30.34"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "184,797",
            "Clicks": "23,016",
            "Sign Ups": "298",
            "Deposits": "208",
            "Cost per Sign Up": "$18.44",
            "Cost per Deposit)": "$20.15"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "142,603",
            "Clicks": "22,275",
            "Sign Ups": "262",
            "Deposits": "158",
            "Cost per Sign Up": "$20.19",
            "Cost per Deposit)": "$36.07"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "189,901",
            "Clicks": "20,652",
            "Sign Ups": "296",
            "Deposits": "203",
            "Cost per Sign Up": "$22.03",
            "Cost per Deposit)": "$29.74"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "180,360",
            "Clicks": "21,233",
            "Sign Ups": "285",
            "Deposits": "159",
            "Cost per Sign Up": "$11.34",
            "Cost per Deposit)": "$23.38"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "214,021",
            "Clicks": "19,961",
            "Sign Ups": "443",
            "Deposits": "176",
            "Cost per Sign Up": "$28.27",
            "Cost per Deposit)": "$36.42"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "179,257",
            "Clicks": "23,559",
            "Sign Ups": "326",
            "Deposits": "135",
            "Cost per Sign Up": "$22.55",
            "Cost per Deposit)": "$33.36"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "161,060",
            "Clicks": "16,365",
            "Sign Ups": "284",
            "Deposits": "210",
            "Cost per Sign Up": "$10.49",
            "Cost per Deposit)": "$44.89"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "172,143",
            "Clicks": "23,879",
            "Sign Ups": "351",
            "Deposits": "152",
            "Cost per Sign Up": "$25.10",
            "Cost per Deposit)": "$40.95"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "134,888",
            "Clicks": "26,079",
            "Sign Ups": "312",
            "Deposits": "146",
            "Cost per Sign Up": "$21.07",
            "Cost per Deposit)": "$29.50"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "141,094",
            "Clicks": "17,303",
            "Sign Ups": "402",
            "Deposits": "163",
            "Cost per Sign Up": "$28.49",
            "Cost per Deposit)": "$24.66"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "153,310",
            "Clicks": "22,214",
            "Sign Ups": "409",
            "Deposits": "207",
            "Cost per Sign Up": "$29.63",
            "Cost per Deposit)": "$38.30"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "186,284",
            "Clicks": "20,874",
            "Sign Ups": "322",
            "Deposits": "222",
            "Cost per Sign Up": "$15.09",
            "Cost per Deposit)": "$37.07"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "216,712",
            "Clicks": "29,957",
            "Sign Ups": "408",
            "Deposits": "152",
            "Cost per Sign Up": "$26.28",
            "Cost per Deposit)": "$32.38"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "180,412",
            "Clicks": "24,575",
            "Sign Ups": "379",
            "Deposits": "149",
            "Cost per Sign Up": "$28.67",
            "Cost per Deposit)": "$44.21"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "155,290",
            "Clicks": "26,583",
            "Sign Ups": "267",
            "Deposits": "204",
            "Cost per Sign Up": "$22.56",
            "Cost per Deposit)": "$48.85"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "185,806",
            "Clicks": "29,770",
            "Sign Ups": "450",
            "Deposits": "155",
            "Cost per Sign Up": "$23.01",
            "Cost per Deposit)": "$47.46"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "146,794",
            "Clicks": "18,540",
            "Sign Ups": "443",
            "Deposits": "168",
            "Cost per Sign Up": "$15.99",
            "Cost per Deposit)": "$51.05"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "196,001",
            "Clicks": "26,582",
            "Sign Ups": "375",
            "Deposits": "201",
            "Cost per Sign Up": "$27.44",
            "Cost per Deposit)": "$26.17"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "205,539",
            "Clicks": "15,081",
            "Sign Ups": "280",
            "Deposits": "179",
            "Cost per Sign Up": "$17.18",
            "Cost per Deposit)": "$43.58"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "200,412",
            "Clicks": "26,052",
            "Sign Ups": "263",
            "Deposits": "143",
            "Cost per Sign Up": "$28.21",
            "Cost per Deposit)": "$55.90"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "206,830",
            "Clicks": "29,219",
            "Sign Ups": "433",
            "Deposits": "187",
            "Cost per Sign Up": "$22.81",
            "Cost per Deposit)": "$54.54"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "213,451",
            "Clicks": "28,646",
            "Sign Ups": "327",
            "Deposits": "198",
            "Cost per Sign Up": "$13.00",
            "Cost per Deposit)": "$31.04"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "180,917",
            "Clicks": "29,895",
            "Sign Ups": "307",
            "Deposits": "160",
            "Cost per Sign Up": "$29.67",
            "Cost per Deposit)": "$34.56"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "151,622",
            "Clicks": "19,546",
            "Sign Ups": "440",
            "Deposits": "141",
            "Cost per Sign Up": "$14.16",
            "Cost per Deposit)": "$21.77"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "150,096",
            "Clicks": "24,639",
            "Sign Ups": "328",
            "Deposits": "179",
            "Cost per Sign Up": "$29.11",
            "Cost per Deposit)": "$51.49"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "159,013",
            "Clicks": "29,889",
            "Sign Ups": "400",
            "Deposits": "183",
            "Cost per Sign Up": "$26.23",
            "Cost per Deposit)": "$23.99"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "187,728",
            "Clicks": "22,275",
            "Sign Ups": "270",
            "Deposits": "130",
            "Cost per Sign Up": "$17.21",
            "Cost per Deposit)": "$46.74"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "143,392",
            "Clicks": "17,054",
            "Sign Ups": "360",
            "Deposits": "157",
            "Cost per Sign Up": "$28.42",
            "Cost per Deposit)": "$24.64"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "190,614",
            "Clicks": "28,316",
            "Sign Ups": "318",
            "Deposits": "170",
            "Cost per Sign Up": "$20.62",
            "Cost per Deposit)": "$52.50"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "218,598",
            "Clicks": "19,206",
            "Sign Ups": "336",
            "Deposits": "144",
            "Cost per Sign Up": "$20.90",
            "Cost per Deposit)": "$39.94"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "176,653",
            "Clicks": "23,943",
            "Sign Ups": "423",
            "Deposits": "202",
            "Cost per Sign Up": "$15.13",
            "Cost per Deposit)": "$48.70"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "180,747",
            "Clicks": "23,282",
            "Sign Ups": "449",
            "Deposits": "133",
            "Cost per Sign Up": "$18.23",
            "Cost per Deposit)": "$32.97"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "178,906",
            "Clicks": "23,158",
            "Sign Ups": "335",
            "Deposits": "218",
            "Cost per Sign Up": "$13.21",
            "Cost per Deposit)": "$39.42"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "144,535",
            "Clicks": "15,685",
            "Sign Ups": "280",
            "Deposits": "211",
            "Cost per Sign Up": "$19.10",
            "Cost per Deposit)": "$54.28"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "199,429",
            "Clicks": "24,018",
            "Sign Ups": "375",
            "Deposits": "131",
            "Cost per Sign Up": "$24.22",
            "Cost per Deposit)": "$58.33"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "204,552",
            "Clicks": "18,418",
            "Sign Ups": "365",
            "Deposits": "199",
            "Cost per Sign Up": "$10.94",
            "Cost per Deposit)": "$32.18"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "151,555",
            "Clicks": "26,551",
            "Sign Ups": "266",
            "Deposits": "187",
            "Cost per Sign Up": "$11.06",
            "Cost per Deposit)": "$30.65"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "145,365",
            "Clicks": "15,432",
            "Sign Ups": "405",
            "Deposits": "200",
            "Cost per Sign Up": "$12.89",
            "Cost per Deposit)": "$59.11"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "135,996",
            "Clicks": "28,876",
            "Sign Ups": "302",
            "Deposits": "154",
            "Cost per Sign Up": "$27.19",
            "Cost per Deposit)": "$40.96"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "142,710",
            "Clicks": "25,649",
            "Sign Ups": "356",
            "Deposits": "188",
            "Cost per Sign Up": "$22.54",
            "Cost per Deposit)": "$30.62"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "193,354",
            "Clicks": "19,329",
            "Sign Ups": "435",
            "Deposits": "136",
            "Cost per Sign Up": "$16.90",
            "Cost per Deposit)": "$25.76"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "178,086",
            "Clicks": "21,575",
            "Sign Ups": "408",
            "Deposits": "187",
            "Cost per Sign Up": "$10.41",
            "Cost per Deposit)": "$42.00"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "185,759",
            "Clicks": "22,891",
            "Sign Ups": "425",
            "Deposits": "149",
            "Cost per Sign Up": "$10.01",
            "Cost per Deposit)": "$50.73"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "146,797",
            "Clicks": "15,398",
            "Sign Ups": "439",
            "Deposits": "219",
            "Cost per Sign Up": "$21.14",
            "Cost per Deposit)": "$42.81"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "191,535",
            "Clicks": "22,171",
            "Sign Ups": "334",
            "Deposits": "145",
            "Cost per Sign Up": "$10.61",
            "Cost per Deposit)": "$33.36"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "158,101",
            "Clicks": "25,143",
            "Sign Ups": "346",
            "Deposits": "166",
            "Cost per Sign Up": "$28.56",
            "Cost per Deposit)": "$57.59"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "135,536",
            "Clicks": "28,443",
            "Sign Ups": "271",
            "Deposits": "200",
            "Cost per Sign Up": "$23.16",
            "Cost per Deposit)": "$43.73"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "158,401",
            "Clicks": "15,190",
            "Sign Ups": "267",
            "Deposits": "221",
            "Cost per Sign Up": "$23.03",
            "Cost per Deposit)": "$45.20"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "151,283",
            "Clicks": "16,390",
            "Sign Ups": "356",
            "Deposits": "170",
            "Cost per Sign Up": "$20.99",
            "Cost per Deposit)": "$52.72"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "199,342",
            "Clicks": "26,568",
            "Sign Ups": "363",
            "Deposits": "182",
            "Cost per Sign Up": "$17.20",
            "Cost per Deposit)": "$51.62"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "159,814",
            "Clicks": "23,843",
            "Sign Ups": "367",
            "Deposits": "192",
            "Cost per Sign Up": "$20.72",
            "Cost per Deposit)": "$37.07"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "201,326",
            "Clicks": "19,736",
            "Sign Ups": "284",
            "Deposits": "204",
            "Cost per Sign Up": "$17.81",
            "Cost per Deposit)": "$32.99"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "180,550",
            "Clicks": "26,905",
            "Sign Ups": "300",
            "Deposits": "174",
            "Cost per Sign Up": "$17.51",
            "Cost per Deposit)": "$32.87"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "185,980",
            "Clicks": "25,662",
            "Sign Ups": "401",
            "Deposits": "221",
            "Cost per Sign Up": "$20.78",
            "Cost per Deposit)": "$23.44"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "135,976",
            "Clicks": "22,963",
            "Sign Ups": "418",
            "Deposits": "137",
            "Cost per Sign Up": "$21.30",
            "Cost per Deposit)": "$38.64"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "134,189",
            "Clicks": "20,126",
            "Sign Ups": "252",
            "Deposits": "192",
            "Cost per Sign Up": "$27.08",
            "Cost per Deposit)": "$34.83"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "180,040",
            "Clicks": "18,345",
            "Sign Ups": "274",
            "Deposits": "144",
            "Cost per Sign Up": "$23.83",
            "Cost per Deposit)": "$32.01"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "137,561",
            "Clicks": "22,132",
            "Sign Ups": "275",
            "Deposits": "182",
            "Cost per Sign Up": "$21.43",
            "Cost per Deposit)": "$54.40"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "218,353",
            "Clicks": "20,264",
            "Sign Ups": "338",
            "Deposits": "159",
            "Cost per Sign Up": "$23.41",
            "Cost per Deposit)": "$29.99"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "192,931",
            "Clicks": "23,906",
            "Sign Ups": "446",
            "Deposits": "169",
            "Cost per Sign Up": "$15.73",
            "Cost per Deposit)": "$28.07"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "171,160",
            "Clicks": "15,759",
            "Sign Ups": "338",
            "Deposits": "221",
            "Cost per Sign Up": "$22.56",
            "Cost per Deposit)": "$45.59"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "211,391",
            "Clicks": "24,020",
            "Sign Ups": "379",
            "Deposits": "195",
            "Cost per Sign Up": "$25.43",
            "Cost per Deposit)": "$48.25"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "210,102",
            "Clicks": "24,767",
            "Sign Ups": "291",
            "Deposits": "126",
            "Cost per Sign Up": "$20.44",
            "Cost per Deposit)": "$48.33"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "164,267",
            "Clicks": "29,283",
            "Sign Ups": "376",
            "Deposits": "188",
            "Cost per Sign Up": "$19.17",
            "Cost per Deposit)": "$58.13"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "180,735",
            "Clicks": "19,206",
            "Sign Ups": "302",
            "Deposits": "133",
            "Cost per Sign Up": "$22.50",
            "Cost per Deposit)": "$23.22"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "159,560",
            "Clicks": "22,830",
            "Sign Ups": "315",
            "Deposits": "143",
            "Cost per Sign Up": "$29.36",
            "Cost per Deposit)": "$44.40"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "161,243",
            "Clicks": "21,127",
            "Sign Ups": "273",
            "Deposits": "219",
            "Cost per Sign Up": "$15.24",
            "Cost per Deposit)": "$43.44"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "219,257",
            "Clicks": "21,801",
            "Sign Ups": "299",
            "Deposits": "179",
            "Cost per Sign Up": "$20.96",
            "Cost per Deposit)": "$23.55"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "156,287",
            "Clicks": "16,735",
            "Sign Ups": "278",
            "Deposits": "219",
            "Cost per Sign Up": "$13.39",
            "Cost per Deposit)": "$52.63"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "193,170",
            "Clicks": "26,635",
            "Sign Ups": "429",
            "Deposits": "174",
            "Cost per Sign Up": "$15.87",
            "Cost per Deposit)": "$35.08"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "164,462",
            "Clicks": "25,461",
            "Sign Ups": "276",
            "Deposits": "180",
            "Cost per Sign Up": "$13.07",
            "Cost per Deposit)": "$41.60"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "140,757",
            "Clicks": "15,460",
            "Sign Ups": "316",
            "Deposits": "215",
            "Cost per Sign Up": "$15.40",
            "Cost per Deposit)": "$22.42"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "199,863",
            "Clicks": "27,781",
            "Sign Ups": "352",
            "Deposits": "169",
            "Cost per Sign Up": "$21.09",
            "Cost per Deposit)": "$32.94"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "132,927",
            "Clicks": "21,010",
            "Sign Ups": "389",
            "Deposits": "154",
            "Cost per Sign Up": "$20.10",
            "Cost per Deposit)": "$25.25"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "167,700",
            "Clicks": "22,913",
            "Sign Ups": "260",
            "Deposits": "146",
            "Cost per Sign Up": "$12.93",
            "Cost per Deposit)": "$30.84"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "199,800",
            "Clicks": "22,553",
            "Sign Ups": "380",
            "Deposits": "210",
            "Cost per Sign Up": "$17.38",
            "Cost per Deposit)": "$25.30"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "201,495",
            "Clicks": "23,871",
            "Sign Ups": "353",
            "Deposits": "175",
            "Cost per Sign Up": "$16.18",
            "Cost per Deposit)": "$30.32"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "163,619",
            "Clicks": "22,347",
            "Sign Ups": "422",
            "Deposits": "133",
            "Cost per Sign Up": "$17.43",
            "Cost per Deposit)": "$56.98"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "154,032",
            "Clicks": "28,129",
            "Sign Ups": "346",
            "Deposits": "130",
            "Cost per Sign Up": "$22.00",
            "Cost per Deposit)": "$50.46"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "173,691",
            "Clicks": "27,313",
            "Sign Ups": "303",
            "Deposits": "180",
            "Cost per Sign Up": "$17.94",
            "Cost per Deposit)": "$27.25"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "135,659",
            "Clicks": "15,778",
            "Sign Ups": "341",
            "Deposits": "141",
            "Cost per Sign Up": "$23.18",
            "Cost per Deposit)": "$25.59"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "159,710",
            "Clicks": "28,857",
            "Sign Ups": "319",
            "Deposits": "150",
            "Cost per Sign Up": "$10.78",
            "Cost per Deposit)": "$39.57"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "134,799",
            "Clicks": "28,800",
            "Sign Ups": "295",
            "Deposits": "222",
            "Cost per Sign Up": "$25.61",
            "Cost per Deposit)": "$22.94"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "132,986",
            "Clicks": "17,934",
            "Sign Ups": "273",
            "Deposits": "164",
            "Cost per Sign Up": "$28.03",
            "Cost per Deposit)": "$53.43"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "205,397",
            "Clicks": "20,802",
            "Sign Ups": "326",
            "Deposits": "197",
            "Cost per Sign Up": "$24.04",
            "Cost per Deposit)": "$46.49"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "160,759",
            "Clicks": "26,469",
            "Sign Ups": "371",
            "Deposits": "176",
            "Cost per Sign Up": "$21.34",
            "Cost per Deposit)": "$42.95"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "131,595",
            "Clicks": "16,326",
            "Sign Ups": "390",
            "Deposits": "188",
            "Cost per Sign Up": "$16.23",
            "Cost per Deposit)": "$27.03"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "205,394",
            "Clicks": "19,573",
            "Sign Ups": "353",
            "Deposits": "134",
            "Cost per Sign Up": "$25.37",
            "Cost per Deposit)": "$52.15"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "159,081",
            "Clicks": "25,791",
            "Sign Ups": "388",
            "Deposits": "157",
            "Cost per Sign Up": "$29.69",
            "Cost per Deposit)": "$46.61"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "188,221",
            "Clicks": "24,773",
            "Sign Ups": "265",
            "Deposits": "152",
            "Cost per Sign Up": "$10.52",
            "Cost per Deposit)": "$23.24"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "166,303",
            "Clicks": "26,354",
            "Sign Ups": "338",
            "Deposits": "145",
            "Cost per Sign Up": "$18.70",
            "Cost per Deposit)": "$58.55"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "132,140",
            "Clicks": "17,543",
            "Sign Ups": "407",
            "Deposits": "200",
            "Cost per Sign Up": "$19.66",
            "Cost per Deposit)": "$44.18"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "184,306",
            "Clicks": "15,739",
            "Sign Ups": "380",
            "Deposits": "183",
            "Cost per Sign Up": "$26.25",
            "Cost per Deposit)": "$28.30"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "219,755",
            "Clicks": "27,212",
            "Sign Ups": "355",
            "Deposits": "138",
            "Cost per Sign Up": "$22.99",
            "Cost per Deposit)": "$38.49"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "136,121",
            "Clicks": "20,460",
            "Sign Ups": "340",
            "Deposits": "159",
            "Cost per Sign Up": "$26.24",
            "Cost per Deposit)": "$58.92"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "196,365",
            "Clicks": "25,190",
            "Sign Ups": "445",
            "Deposits": "200",
            "Cost per Sign Up": "$19.89",
            "Cost per Deposit)": "$28.79"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "191,790",
            "Clicks": "19,438",
            "Sign Ups": "288",
            "Deposits": "149",
            "Cost per Sign Up": "$29.17",
            "Cost per Deposit)": "$48.92"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "153,193",
            "Clicks": "29,894",
            "Sign Ups": "383",
            "Deposits": "214",
            "Cost per Sign Up": "$20.42",
            "Cost per Deposit)": "$36.46"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "132,269",
            "Clicks": "18,701",
            "Sign Ups": "429",
            "Deposits": "225",
            "Cost per Sign Up": "$21.99",
            "Cost per Deposit)": "$31.42"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "143,677",
            "Clicks": "29,723",
            "Sign Ups": "282",
            "Deposits": "139",
            "Cost per Sign Up": "$24.20",
            "Cost per Deposit)": "$34.54"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "199,776",
            "Clicks": "22,536",
            "Sign Ups": "374",
            "Deposits": "126",
            "Cost per Sign Up": "$17.31",
            "Cost per Deposit)": "$57.46"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "219,968",
            "Clicks": "24,640",
            "Sign Ups": "265",
            "Deposits": "198",
            "Cost per Sign Up": "$14.31",
            "Cost per Deposit)": "$57.67"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "206,878",
            "Clicks": "25,930",
            "Sign Ups": "373",
            "Deposits": "207",
            "Cost per Sign Up": "$24.19",
            "Cost per Deposit)": "$51.08"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "212,232",
            "Clicks": "16,701",
            "Sign Ups": "324",
            "Deposits": "209",
            "Cost per Sign Up": "$17.57",
            "Cost per Deposit)": "$26.71"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "136,026",
            "Clicks": "24,714",
            "Sign Ups": "425",
            "Deposits": "140",
            "Cost per Sign Up": "$11.86",
            "Cost per Deposit)": "$23.24"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "136,970",
            "Clicks": "19,201",
            "Sign Ups": "363",
            "Deposits": "212",
            "Cost per Sign Up": "$22.00",
            "Cost per Deposit)": "$33.63"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "202,494",
            "Clicks": "27,905",
            "Sign Ups": "259",
            "Deposits": "157",
            "Cost per Sign Up": "$19.57",
            "Cost per Deposit)": "$26.78"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "173,319",
            "Clicks": "19,909",
            "Sign Ups": "381",
            "Deposits": "155",
            "Cost per Sign Up": "$15.44",
            "Cost per Deposit)": "$23.92"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "150,148",
            "Clicks": "16,263",
            "Sign Ups": "336",
            "Deposits": "207",
            "Cost per Sign Up": "$10.26",
            "Cost per Deposit)": "$58.45"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "216,583",
            "Clicks": "26,038",
            "Sign Ups": "370",
            "Deposits": "164",
            "Cost per Sign Up": "$25.03",
            "Cost per Deposit)": "$33.81"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "172,559",
            "Clicks": "26,116",
            "Sign Ups": "319",
            "Deposits": "152",
            "Cost per Sign Up": "$19.36",
            "Cost per Deposit)": "$32.61"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "213,821",
            "Clicks": "22,917",
            "Sign Ups": "259",
            "Deposits": "126",
            "Cost per Sign Up": "$12.44",
            "Cost per Deposit)": "$52.11"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "143,756",
            "Clicks": "20,337",
            "Sign Ups": "279",
            "Deposits": "218",
            "Cost per Sign Up": "$24.31",
            "Cost per Deposit)": "$43.32"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "156,922",
            "Clicks": "20,550",
            "Sign Ups": "352",
            "Deposits": "196",
            "Cost per Sign Up": "$21.18",
            "Cost per Deposit)": "$24.13"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "139,253",
            "Clicks": "23,032",
            "Sign Ups": "261",
            "Deposits": "195",
            "Cost per Sign Up": "$24.66",
            "Cost per Deposit)": "$50.44"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "158,675",
            "Clicks": "16,862",
            "Sign Ups": "370",
            "Deposits": "167",
            "Cost per Sign Up": "$24.61",
            "Cost per Deposit)": "$37.62"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "209,764",
            "Clicks": "15,473",
            "Sign Ups": "318",
            "Deposits": "175",
            "Cost per Sign Up": "$23.00",
            "Cost per Deposit)": "$35.80"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "198,234",
            "Clicks": "15,320",
            "Sign Ups": "280",
            "Deposits": "168",
            "Cost per Sign Up": "$12.45",
            "Cost per Deposit)": "$25.90"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "217,166",
            "Clicks": "17,082",
            "Sign Ups": "257",
            "Deposits": "169",
            "Cost per Sign Up": "$24.33",
            "Cost per Deposit)": "$56.70"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "184,942",
            "Clicks": "20,749",
            "Sign Ups": "345",
            "Deposits": "224",
            "Cost per Sign Up": "$10.63",
            "Cost per Deposit)": "$49.47"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "144,961",
            "Clicks": "18,640",
            "Sign Ups": "407",
            "Deposits": "165",
            "Cost per Sign Up": "$26.13",
            "Cost per Deposit)": "$46.57"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "196,779",
            "Clicks": "23,566",
            "Sign Ups": "410",
            "Deposits": "195",
            "Cost per Sign Up": "$20.13",
            "Cost per Deposit)": "$46.01"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "197,393",
            "Clicks": "29,143",
            "Sign Ups": "317",
            "Deposits": "169",
            "Cost per Sign Up": "$27.11",
            "Cost per Deposit)": "$24.06"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "178,997",
            "Clicks": "15,974",
            "Sign Ups": "361",
            "Deposits": "185",
            "Cost per Sign Up": "$23.29",
            "Cost per Deposit)": "$46.38"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "201,944",
            "Clicks": "27,041",
            "Sign Ups": "363",
            "Deposits": "165",
            "Cost per Sign Up": "$12.13",
            "Cost per Deposit)": "$25.94"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "214,604",
            "Clicks": "22,883",
            "Sign Ups": "433",
            "Deposits": "172",
            "Cost per Sign Up": "$24.74",
            "Cost per Deposit)": "$47.54"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "176,907",
            "Clicks": "19,595",
            "Sign Ups": "322",
            "Deposits": "203",
            "Cost per Sign Up": "$12.06",
            "Cost per Deposit)": "$31.93"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "174,946",
            "Clicks": "25,739",
            "Sign Ups": "381",
            "Deposits": "140",
            "Cost per Sign Up": "$15.45",
            "Cost per Deposit)": "$25.38"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "217,478",
            "Clicks": "17,296",
            "Sign Ups": "361",
            "Deposits": "154",
            "Cost per Sign Up": "$15.60",
            "Cost per Deposit)": "$21.07"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "132,096",
            "Clicks": "29,219",
            "Sign Ups": "279",
            "Deposits": "142",
            "Cost per Sign Up": "$14.33",
            "Cost per Deposit)": "$24.55"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "202,232",
            "Clicks": "17,177",
            "Sign Ups": "433",
            "Deposits": "217",
            "Cost per Sign Up": "$26.59",
            "Cost per Deposit)": "$49.76"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "185,424",
            "Clicks": "22,609",
            "Sign Ups": "337",
            "Deposits": "186",
            "Cost per Sign Up": "$27.16",
            "Cost per Deposit)": "$46.85"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "174,393",
            "Clicks": "28,279",
            "Sign Ups": "415",
            "Deposits": "167",
            "Cost per Sign Up": "$13.25",
            "Cost per Deposit)": "$59.50"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "130,419",
            "Clicks": "21,015",
            "Sign Ups": "382",
            "Deposits": "154",
            "Cost per Sign Up": "$27.24",
            "Cost per Deposit)": "$55.66"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "134,879",
            "Clicks": "22,815",
            "Sign Ups": "402",
            "Deposits": "130",
            "Cost per Sign Up": "$29.62",
            "Cost per Deposit)": "$33.46"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "215,618",
            "Clicks": "28,629",
            "Sign Ups": "423",
            "Deposits": "147",
            "Cost per Sign Up": "$29.47",
            "Cost per Deposit)": "$24.16"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "163,246",
            "Clicks": "18,925",
            "Sign Ups": "304",
            "Deposits": "204",
            "Cost per Sign Up": "$15.71",
            "Cost per Deposit)": "$39.73"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "212,527",
            "Clicks": "19,562",
            "Sign Ups": "349",
            "Deposits": "169",
            "Cost per Sign Up": "$16.99",
            "Cost per Deposit)": "$37.89"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "148,322",
            "Clicks": "18,479",
            "Sign Ups": "398",
            "Deposits": "131",
            "Cost per Sign Up": "$29.25",
            "Cost per Deposit)": "$37.24"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "167,554",
            "Clicks": "27,168",
            "Sign Ups": "398",
            "Deposits": "211",
            "Cost per Sign Up": "$19.20",
            "Cost per Deposit)": "$57.24"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "148,146",
            "Clicks": "29,735",
            "Sign Ups": "430",
            "Deposits": "154",
            "Cost per Sign Up": "$11.85",
            "Cost per Deposit)": "$39.91"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "174,802",
            "Clicks": "18,778",
            "Sign Ups": "284",
            "Deposits": "156",
            "Cost per Sign Up": "$26.81",
            "Cost per Deposit)": "$46.00"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "132,777",
            "Clicks": "17,765",
            "Sign Ups": "268",
            "Deposits": "134",
            "Cost per Sign Up": "$18.51",
            "Cost per Deposit)": "$52.07"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "184,497",
            "Clicks": "29,037",
            "Sign Ups": "324",
            "Deposits": "186",
            "Cost per Sign Up": "$24.60",
            "Cost per Deposit)": "$54.68"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "188,367",
            "Clicks": "21,365",
            "Sign Ups": "295",
            "Deposits": "148",
            "Cost per Sign Up": "$17.24",
            "Cost per Deposit)": "$32.23"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "176,101",
            "Clicks": "20,904",
            "Sign Ups": "269",
            "Deposits": "181",
            "Cost per Sign Up": "$18.11",
            "Cost per Deposit)": "$41.54"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "194,562",
            "Clicks": "26,424",
            "Sign Ups": "270",
            "Deposits": "134",
            "Cost per Sign Up": "$10.78",
            "Cost per Deposit)": "$36.88"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "164,642",
            "Clicks": "22,335",
            "Sign Ups": "254",
            "Deposits": "216",
            "Cost per Sign Up": "$15.72",
            "Cost per Deposit)": "$32.80"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "183,702",
            "Clicks": "25,828",
            "Sign Ups": "410",
            "Deposits": "162",
            "Cost per Sign Up": "$14.05",
            "Cost per Deposit)": "$54.05"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "147,406",
            "Clicks": "28,980",
            "Sign Ups": "400",
            "Deposits": "189",
            "Cost per Sign Up": "$13.80",
            "Cost per Deposit)": "$52.26"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "171,399",
            "Clicks": "22,431",
            "Sign Ups": "295",
            "Deposits": "154",
            "Cost per Sign Up": "$17.43",
            "Cost per Deposit)": "$29.50"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "159,171",
            "Clicks": "16,071",
            "Sign Ups": "264",
            "Deposits": "167",
            "Cost per Sign Up": "$16.31",
            "Cost per Deposit)": "$38.14"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "196,837",
            "Clicks": "20,163",
            "Sign Ups": "310",
            "Deposits": "180",
            "Cost per Sign Up": "$20.62",
            "Cost per Deposit)": "$35.69"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "133,217",
            "Clicks": "17,423",
            "Sign Ups": "402",
            "Deposits": "200",
            "Cost per Sign Up": "$10.56",
            "Cost per Deposit)": "$34.01"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "203,653",
            "Clicks": "16,391",
            "Sign Ups": "391",
            "Deposits": "137",
            "Cost per Sign Up": "$13.81",
            "Cost per Deposit)": "$31.99"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "182,264",
            "Clicks": "26,697",
            "Sign Ups": "298",
            "Deposits": "217",
            "Cost per Sign Up": "$23.80",
            "Cost per Deposit)": "$35.69"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "130,464",
            "Clicks": "19,262",
            "Sign Ups": "319",
            "Deposits": "141",
            "Cost per Sign Up": "$11.20",
            "Cost per Deposit)": "$40.57"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "205,306",
            "Clicks": "18,613",
            "Sign Ups": "281",
            "Deposits": "133",
            "Cost per Sign Up": "$26.46",
            "Cost per Deposit)": "$56.15"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "164,201",
            "Clicks": "19,105",
            "Sign Ups": "412",
            "Deposits": "151",
            "Cost per Sign Up": "$27.52",
            "Cost per Deposit)": "$38.46"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "209,628",
            "Clicks": "19,446",
            "Sign Ups": "303",
            "Deposits": "133",
            "Cost per Sign Up": "$22.72",
            "Cost per Deposit)": "$56.18"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "180,357",
            "Clicks": "23,572",
            "Sign Ups": "262",
            "Deposits": "153",
            "Cost per Sign Up": "$23.13",
            "Cost per Deposit)": "$34.82"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "170,535",
            "Clicks": "15,985",
            "Sign Ups": "280",
            "Deposits": "161",
            "Cost per Sign Up": "$20.33",
            "Cost per Deposit)": "$55.48"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "166,436",
            "Clicks": "29,064",
            "Sign Ups": "298",
            "Deposits": "134",
            "Cost per Sign Up": "$12.39",
            "Cost per Deposit)": "$33.50"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "187,754",
            "Clicks": "22,690",
            "Sign Ups": "407",
            "Deposits": "216",
            "Cost per Sign Up": "$23.72",
            "Cost per Deposit)": "$43.68"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "158,132",
            "Clicks": "23,871",
            "Sign Ups": "307",
            "Deposits": "142",
            "Cost per Sign Up": "$17.62",
            "Cost per Deposit)": "$31.98"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "147,063",
            "Clicks": "17,942",
            "Sign Ups": "373",
            "Deposits": "187",
            "Cost per Sign Up": "$27.56",
            "Cost per Deposit)": "$25.15"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "160,659",
            "Clicks": "28,315",
            "Sign Ups": "367",
            "Deposits": "184",
            "Cost per Sign Up": "$25.65",
            "Cost per Deposit)": "$35.35"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "218,237",
            "Clicks": "28,431",
            "Sign Ups": "429",
            "Deposits": "140",
            "Cost per Sign Up": "$14.84",
            "Cost per Deposit)": "$28.77"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "159,091",
            "Clicks": "24,096",
            "Sign Ups": "392",
            "Deposits": "169",
            "Cost per Sign Up": "$23.13",
            "Cost per Deposit)": "$32.18"
          }
        ]
      },
      "SportsBettingUsersData": {
        "name": "Sports Betting Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "133,311",
            "Clicks": "27,522",
            "Sign Ups": "381",
            "Deposits": "136",
            "Cost per Sign Up": "$27.22",
            "Cost per Deposit)": "$41.72"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "198,388",
            "Clicks": "25,457",
            "Sign Ups": "421",
            "Deposits": "142",
            "Cost per Sign Up": "$11.69",
            "Cost per Deposit)": "$54.45"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "157,626",
            "Clicks": "26,958",
            "Sign Ups": "258",
            "Deposits": "154",
            "Cost per Sign Up": "$20.37",
            "Cost per Deposit)": "$34.64"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "165,888",
            "Clicks": "17,771",
            "Sign Ups": "317",
            "Deposits": "173",
            "Cost per Sign Up": "$24.94",
            "Cost per Deposit)": "$36.72"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "141,822",
            "Clicks": "23,490",
            "Sign Ups": "389",
            "Deposits": "140",
            "Cost per Sign Up": "$24.73",
            "Cost per Deposit)": "$53.57"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "139,440",
            "Clicks": "28,975",
            "Sign Ups": "363",
            "Deposits": "213",
            "Cost per Sign Up": "$18.80",
            "Cost per Deposit)": "$25.84"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "178,932",
            "Clicks": "23,974",
            "Sign Ups": "329",
            "Deposits": "199",
            "Cost per Sign Up": "$16.68",
            "Cost per Deposit)": "$60.00"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "174,050",
            "Clicks": "18,708",
            "Sign Ups": "311",
            "Deposits": "126",
            "Cost per Sign Up": "$27.73",
            "Cost per Deposit)": "$35.50"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "183,583",
            "Clicks": "16,981",
            "Sign Ups": "349",
            "Deposits": "166",
            "Cost per Sign Up": "$17.71",
            "Cost per Deposit)": "$36.08"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "173,080",
            "Clicks": "28,508",
            "Sign Ups": "266",
            "Deposits": "176",
            "Cost per Sign Up": "$16.88",
            "Cost per Deposit)": "$23.91"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "208,346",
            "Clicks": "22,261",
            "Sign Ups": "429",
            "Deposits": "147",
            "Cost per Sign Up": "$25.81",
            "Cost per Deposit)": "$21.07"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "156,615",
            "Clicks": "27,500",
            "Sign Ups": "444",
            "Deposits": "218",
            "Cost per Sign Up": "$21.03",
            "Cost per Deposit)": "$56.44"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "187,322",
            "Clicks": "18,168",
            "Sign Ups": "279",
            "Deposits": "157",
            "Cost per Sign Up": "$14.83",
            "Cost per Deposit)": "$58.18"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "174,869",
            "Clicks": "24,983",
            "Sign Ups": "288",
            "Deposits": "164",
            "Cost per Sign Up": "$26.25",
            "Cost per Deposit)": "$28.03"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "159,463",
            "Clicks": "28,178",
            "Sign Ups": "408",
            "Deposits": "126",
            "Cost per Sign Up": "$16.73",
            "Cost per Deposit)": "$29.04"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "200,121",
            "Clicks": "20,486",
            "Sign Ups": "282",
            "Deposits": "203",
            "Cost per Sign Up": "$16.43",
            "Cost per Deposit)": "$43.95"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "131,330",
            "Clicks": "23,563",
            "Sign Ups": "379",
            "Deposits": "142",
            "Cost per Sign Up": "$16.58",
            "Cost per Deposit)": "$33.58"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "142,344",
            "Clicks": "15,318",
            "Sign Ups": "407",
            "Deposits": "222",
            "Cost per Sign Up": "$16.90",
            "Cost per Deposit)": "$58.31"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "208,773",
            "Clicks": "23,338",
            "Sign Ups": "386",
            "Deposits": "130",
            "Cost per Sign Up": "$28.12",
            "Cost per Deposit)": "$51.12"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "193,251",
            "Clicks": "25,102",
            "Sign Ups": "272",
            "Deposits": "144",
            "Cost per Sign Up": "$19.43",
            "Cost per Deposit)": "$56.95"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "133,072",
            "Clicks": "25,600",
            "Sign Ups": "440",
            "Deposits": "132",
            "Cost per Sign Up": "$29.59",
            "Cost per Deposit)": "$54.07"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "196,304",
            "Clicks": "18,951",
            "Sign Ups": "362",
            "Deposits": "225",
            "Cost per Sign Up": "$12.99",
            "Cost per Deposit)": "$22.18"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "170,116",
            "Clicks": "19,903",
            "Sign Ups": "295",
            "Deposits": "161",
            "Cost per Sign Up": "$22.03",
            "Cost per Deposit)": "$51.17"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "150,599",
            "Clicks": "20,732",
            "Sign Ups": "432",
            "Deposits": "219",
            "Cost per Sign Up": "$20.92",
            "Cost per Deposit)": "$53.45"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "191,585",
            "Clicks": "27,908",
            "Sign Ups": "359",
            "Deposits": "194",
            "Cost per Sign Up": "$24.27",
            "Cost per Deposit)": "$30.32"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "178,850",
            "Clicks": "22,195",
            "Sign Ups": "287",
            "Deposits": "202",
            "Cost per Sign Up": "$17.86",
            "Cost per Deposit)": "$23.94"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "167,059",
            "Clicks": "28,700",
            "Sign Ups": "388",
            "Deposits": "189",
            "Cost per Sign Up": "$15.15",
            "Cost per Deposit)": "$20.44"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "212,295",
            "Clicks": "24,478",
            "Sign Ups": "313",
            "Deposits": "132",
            "Cost per Sign Up": "$20.63",
            "Cost per Deposit)": "$56.22"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "162,463",
            "Clicks": "29,201",
            "Sign Ups": "424",
            "Deposits": "131",
            "Cost per Sign Up": "$21.19",
            "Cost per Deposit)": "$50.94"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "171,739",
            "Clicks": "26,868",
            "Sign Ups": "378",
            "Deposits": "184",
            "Cost per Sign Up": "$28.54",
            "Cost per Deposit)": "$30.26"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "176,977",
            "Clicks": "24,456",
            "Sign Ups": "397",
            "Deposits": "197",
            "Cost per Sign Up": "$19.02",
            "Cost per Deposit)": "$40.92"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "136,455",
            "Clicks": "22,341",
            "Sign Ups": "258",
            "Deposits": "132",
            "Cost per Sign Up": "$26.01",
            "Cost per Deposit)": "$28.80"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "201,152",
            "Clicks": "29,240",
            "Sign Ups": "432",
            "Deposits": "219",
            "Cost per Sign Up": "$13.81",
            "Cost per Deposit)": "$28.76"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "203,967",
            "Clicks": "19,009",
            "Sign Ups": "348",
            "Deposits": "207",
            "Cost per Sign Up": "$18.85",
            "Cost per Deposit)": "$54.40"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "138,618",
            "Clicks": "27,927",
            "Sign Ups": "299",
            "Deposits": "183",
            "Cost per Sign Up": "$14.91",
            "Cost per Deposit)": "$56.49"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "150,094",
            "Clicks": "17,573",
            "Sign Ups": "349",
            "Deposits": "179",
            "Cost per Sign Up": "$14.51",
            "Cost per Deposit)": "$39.21"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "162,722",
            "Clicks": "19,466",
            "Sign Ups": "264",
            "Deposits": "196",
            "Cost per Sign Up": "$25.67",
            "Cost per Deposit)": "$39.21"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "181,930",
            "Clicks": "25,284",
            "Sign Ups": "280",
            "Deposits": "177",
            "Cost per Sign Up": "$13.17",
            "Cost per Deposit)": "$57.17"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "214,395",
            "Clicks": "16,229",
            "Sign Ups": "252",
            "Deposits": "185",
            "Cost per Sign Up": "$21.07",
            "Cost per Deposit)": "$54.98"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "156,820",
            "Clicks": "23,958",
            "Sign Ups": "315",
            "Deposits": "148",
            "Cost per Sign Up": "$13.90",
            "Cost per Deposit)": "$41.73"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "135,359",
            "Clicks": "23,619",
            "Sign Ups": "285",
            "Deposits": "134",
            "Cost per Sign Up": "$18.54",
            "Cost per Deposit)": "$26.56"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "208,994",
            "Clicks": "28,757",
            "Sign Ups": "315",
            "Deposits": "215",
            "Cost per Sign Up": "$29.15",
            "Cost per Deposit)": "$44.43"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "219,725",
            "Clicks": "20,351",
            "Sign Ups": "259",
            "Deposits": "205",
            "Cost per Sign Up": "$19.80",
            "Cost per Deposit)": "$49.04"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "156,414",
            "Clicks": "27,708",
            "Sign Ups": "311",
            "Deposits": "210",
            "Cost per Sign Up": "$25.58",
            "Cost per Deposit)": "$27.61"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "180,370",
            "Clicks": "24,996",
            "Sign Ups": "429",
            "Deposits": "157",
            "Cost per Sign Up": "$15.01",
            "Cost per Deposit)": "$21.88"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "203,840",
            "Clicks": "22,156",
            "Sign Ups": "356",
            "Deposits": "136",
            "Cost per Sign Up": "$16.92",
            "Cost per Deposit)": "$49.04"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "154,377",
            "Clicks": "27,570",
            "Sign Ups": "357",
            "Deposits": "149",
            "Cost per Sign Up": "$20.38",
            "Cost per Deposit)": "$24.36"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "162,213",
            "Clicks": "27,381",
            "Sign Ups": "344",
            "Deposits": "167",
            "Cost per Sign Up": "$29.52",
            "Cost per Deposit)": "$21.17"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "213,682",
            "Clicks": "16,908",
            "Sign Ups": "387",
            "Deposits": "143",
            "Cost per Sign Up": "$13.26",
            "Cost per Deposit)": "$40.22"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "218,595",
            "Clicks": "25,542",
            "Sign Ups": "267",
            "Deposits": "204",
            "Cost per Sign Up": "$25.00",
            "Cost per Deposit)": "$59.18"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "204,953",
            "Clicks": "26,323",
            "Sign Ups": "434",
            "Deposits": "224",
            "Cost per Sign Up": "$20.01",
            "Cost per Deposit)": "$20.96"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "187,133",
            "Clicks": "26,824",
            "Sign Ups": "279",
            "Deposits": "172",
            "Cost per Sign Up": "$25.67",
            "Cost per Deposit)": "$30.39"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "172,529",
            "Clicks": "16,415",
            "Sign Ups": "351",
            "Deposits": "224",
            "Cost per Sign Up": "$15.23",
            "Cost per Deposit)": "$44.92"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "188,400",
            "Clicks": "16,414",
            "Sign Ups": "429",
            "Deposits": "169",
            "Cost per Sign Up": "$18.27",
            "Cost per Deposit)": "$55.96"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "143,381",
            "Clicks": "27,021",
            "Sign Ups": "377",
            "Deposits": "137",
            "Cost per Sign Up": "$29.52",
            "Cost per Deposit)": "$46.64"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "198,079",
            "Clicks": "21,533",
            "Sign Ups": "293",
            "Deposits": "163",
            "Cost per Sign Up": "$26.41",
            "Cost per Deposit)": "$39.84"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "216,304",
            "Clicks": "23,513",
            "Sign Ups": "267",
            "Deposits": "186",
            "Cost per Sign Up": "$20.04",
            "Cost per Deposit)": "$23.24"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "183,813",
            "Clicks": "23,772",
            "Sign Ups": "281",
            "Deposits": "189",
            "Cost per Sign Up": "$22.55",
            "Cost per Deposit)": "$29.29"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "133,663",
            "Clicks": "24,338",
            "Sign Ups": "361",
            "Deposits": "152",
            "Cost per Sign Up": "$13.08",
            "Cost per Deposit)": "$31.03"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "181,567",
            "Clicks": "29,152",
            "Sign Ups": "435",
            "Deposits": "176",
            "Cost per Sign Up": "$17.28",
            "Cost per Deposit)": "$58.30"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "172,390",
            "Clicks": "18,647",
            "Sign Ups": "376",
            "Deposits": "188",
            "Cost per Sign Up": "$13.85",
            "Cost per Deposit)": "$30.48"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "136,253",
            "Clicks": "28,432",
            "Sign Ups": "356",
            "Deposits": "155",
            "Cost per Sign Up": "$11.68",
            "Cost per Deposit)": "$34.79"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "197,911",
            "Clicks": "19,581",
            "Sign Ups": "405",
            "Deposits": "131",
            "Cost per Sign Up": "$12.09",
            "Cost per Deposit)": "$34.89"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "180,402",
            "Clicks": "24,871",
            "Sign Ups": "353",
            "Deposits": "215",
            "Cost per Sign Up": "$29.33",
            "Cost per Deposit)": "$25.05"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "176,209",
            "Clicks": "20,735",
            "Sign Ups": "264",
            "Deposits": "152",
            "Cost per Sign Up": "$14.60",
            "Cost per Deposit)": "$28.15"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "194,550",
            "Clicks": "25,122",
            "Sign Ups": "281",
            "Deposits": "159",
            "Cost per Sign Up": "$28.46",
            "Cost per Deposit)": "$32.92"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "183,221",
            "Clicks": "21,029",
            "Sign Ups": "431",
            "Deposits": "216",
            "Cost per Sign Up": "$21.91",
            "Cost per Deposit)": "$30.85"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "162,441",
            "Clicks": "17,509",
            "Sign Ups": "309",
            "Deposits": "191",
            "Cost per Sign Up": "$15.15",
            "Cost per Deposit)": "$35.69"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "209,682",
            "Clicks": "20,733",
            "Sign Ups": "254",
            "Deposits": "178",
            "Cost per Sign Up": "$25.46",
            "Cost per Deposit)": "$27.72"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "167,570",
            "Clicks": "27,299",
            "Sign Ups": "415",
            "Deposits": "189",
            "Cost per Sign Up": "$18.11",
            "Cost per Deposit)": "$57.95"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "172,904",
            "Clicks": "15,366",
            "Sign Ups": "315",
            "Deposits": "217",
            "Cost per Sign Up": "$27.47",
            "Cost per Deposit)": "$50.66"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "137,657",
            "Clicks": "16,821",
            "Sign Ups": "282",
            "Deposits": "163",
            "Cost per Sign Up": "$13.12",
            "Cost per Deposit)": "$32.36"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "133,843",
            "Clicks": "27,301",
            "Sign Ups": "351",
            "Deposits": "175",
            "Cost per Sign Up": "$14.33",
            "Cost per Deposit)": "$52.12"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "163,901",
            "Clicks": "18,548",
            "Sign Ups": "402",
            "Deposits": "190",
            "Cost per Sign Up": "$15.11",
            "Cost per Deposit)": "$35.21"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "189,146",
            "Clicks": "29,787",
            "Sign Ups": "297",
            "Deposits": "203",
            "Cost per Sign Up": "$18.59",
            "Cost per Deposit)": "$28.07"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "158,998",
            "Clicks": "22,220",
            "Sign Ups": "445",
            "Deposits": "200",
            "Cost per Sign Up": "$19.11",
            "Cost per Deposit)": "$52.96"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "159,432",
            "Clicks": "26,153",
            "Sign Ups": "328",
            "Deposits": "149",
            "Cost per Sign Up": "$28.63",
            "Cost per Deposit)": "$25.51"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "154,610",
            "Clicks": "19,584",
            "Sign Ups": "369",
            "Deposits": "219",
            "Cost per Sign Up": "$17.63",
            "Cost per Deposit)": "$23.87"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "169,327",
            "Clicks": "26,817",
            "Sign Ups": "342",
            "Deposits": "193",
            "Cost per Sign Up": "$19.27",
            "Cost per Deposit)": "$57.82"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "155,840",
            "Clicks": "27,617",
            "Sign Ups": "271",
            "Deposits": "165",
            "Cost per Sign Up": "$23.99",
            "Cost per Deposit)": "$30.06"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "173,947",
            "Clicks": "28,393",
            "Sign Ups": "328",
            "Deposits": "139",
            "Cost per Sign Up": "$13.85",
            "Cost per Deposit)": "$55.47"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "139,211",
            "Clicks": "22,397",
            "Sign Ups": "387",
            "Deposits": "163",
            "Cost per Sign Up": "$11.77",
            "Cost per Deposit)": "$46.19"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "174,892",
            "Clicks": "22,510",
            "Sign Ups": "269",
            "Deposits": "224",
            "Cost per Sign Up": "$16.31",
            "Cost per Deposit)": "$37.70"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "146,271",
            "Clicks": "16,381",
            "Sign Ups": "291",
            "Deposits": "179",
            "Cost per Sign Up": "$16.08",
            "Cost per Deposit)": "$52.74"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "164,878",
            "Clicks": "20,338",
            "Sign Ups": "416",
            "Deposits": "197",
            "Cost per Sign Up": "$12.99",
            "Cost per Deposit)": "$41.75"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "218,135",
            "Clicks": "28,000",
            "Sign Ups": "302",
            "Deposits": "176",
            "Cost per Sign Up": "$28.37",
            "Cost per Deposit)": "$55.01"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "209,933",
            "Clicks": "18,320",
            "Sign Ups": "263",
            "Deposits": "193",
            "Cost per Sign Up": "$22.46",
            "Cost per Deposit)": "$44.48"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "185,848",
            "Clicks": "29,618",
            "Sign Ups": "285",
            "Deposits": "156",
            "Cost per Sign Up": "$12.94",
            "Cost per Deposit)": "$32.99"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "186,604",
            "Clicks": "28,260",
            "Sign Ups": "329",
            "Deposits": "159",
            "Cost per Sign Up": "$19.32",
            "Cost per Deposit)": "$52.87"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "167,257",
            "Clicks": "26,412",
            "Sign Ups": "433",
            "Deposits": "214",
            "Cost per Sign Up": "$28.46",
            "Cost per Deposit)": "$27.83"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "146,446",
            "Clicks": "27,775",
            "Sign Ups": "382",
            "Deposits": "215",
            "Cost per Sign Up": "$16.47",
            "Cost per Deposit)": "$33.78"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "216,016",
            "Clicks": "29,967",
            "Sign Ups": "399",
            "Deposits": "158",
            "Cost per Sign Up": "$14.07",
            "Cost per Deposit)": "$53.39"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "142,802",
            "Clicks": "25,836",
            "Sign Ups": "278",
            "Deposits": "129",
            "Cost per Sign Up": "$19.68",
            "Cost per Deposit)": "$53.67"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "171,314",
            "Clicks": "22,125",
            "Sign Ups": "331",
            "Deposits": "167",
            "Cost per Sign Up": "$20.79",
            "Cost per Deposit)": "$59.07"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "194,627",
            "Clicks": "20,908",
            "Sign Ups": "395",
            "Deposits": "217",
            "Cost per Sign Up": "$28.60",
            "Cost per Deposit)": "$29.83"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "164,965",
            "Clicks": "26,997",
            "Sign Ups": "269",
            "Deposits": "156",
            "Cost per Sign Up": "$17.36",
            "Cost per Deposit)": "$33.88"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "167,091",
            "Clicks": "20,233",
            "Sign Ups": "413",
            "Deposits": "128",
            "Cost per Sign Up": "$17.30",
            "Cost per Deposit)": "$26.53"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "193,415",
            "Clicks": "25,160",
            "Sign Ups": "307",
            "Deposits": "184",
            "Cost per Sign Up": "$14.26",
            "Cost per Deposit)": "$50.91"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "219,453",
            "Clicks": "27,399",
            "Sign Ups": "296",
            "Deposits": "161",
            "Cost per Sign Up": "$23.41",
            "Cost per Deposit)": "$43.25"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "137,310",
            "Clicks": "18,983",
            "Sign Ups": "389",
            "Deposits": "160",
            "Cost per Sign Up": "$16.16",
            "Cost per Deposit)": "$22.74"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "142,160",
            "Clicks": "29,313",
            "Sign Ups": "351",
            "Deposits": "135",
            "Cost per Sign Up": "$23.93",
            "Cost per Deposit)": "$23.86"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "157,263",
            "Clicks": "17,118",
            "Sign Ups": "349",
            "Deposits": "178",
            "Cost per Sign Up": "$21.61",
            "Cost per Deposit)": "$59.30"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "179,389",
            "Clicks": "28,397",
            "Sign Ups": "289",
            "Deposits": "212",
            "Cost per Sign Up": "$14.32",
            "Cost per Deposit)": "$43.08"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "206,716",
            "Clicks": "24,372",
            "Sign Ups": "435",
            "Deposits": "178",
            "Cost per Sign Up": "$10.54",
            "Cost per Deposit)": "$30.50"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "181,597",
            "Clicks": "27,711",
            "Sign Ups": "282",
            "Deposits": "130",
            "Cost per Sign Up": "$19.35",
            "Cost per Deposit)": "$21.02"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "137,218",
            "Clicks": "23,197",
            "Sign Ups": "434",
            "Deposits": "170",
            "Cost per Sign Up": "$16.56",
            "Cost per Deposit)": "$52.66"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "189,034",
            "Clicks": "27,193",
            "Sign Ups": "417",
            "Deposits": "224",
            "Cost per Sign Up": "$23.95",
            "Cost per Deposit)": "$54.15"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "187,344",
            "Clicks": "19,676",
            "Sign Ups": "273",
            "Deposits": "146",
            "Cost per Sign Up": "$27.20",
            "Cost per Deposit)": "$45.68"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "199,287",
            "Clicks": "18,360",
            "Sign Ups": "318",
            "Deposits": "131",
            "Cost per Sign Up": "$13.14",
            "Cost per Deposit)": "$46.35"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "158,835",
            "Clicks": "20,091",
            "Sign Ups": "443",
            "Deposits": "143",
            "Cost per Sign Up": "$26.26",
            "Cost per Deposit)": "$29.65"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "145,189",
            "Clicks": "15,115",
            "Sign Ups": "442",
            "Deposits": "153",
            "Cost per Sign Up": "$22.61",
            "Cost per Deposit)": "$51.43"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "185,829",
            "Clicks": "23,755",
            "Sign Ups": "311",
            "Deposits": "219",
            "Cost per Sign Up": "$25.09",
            "Cost per Deposit)": "$56.88"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "138,373",
            "Clicks": "28,449",
            "Sign Ups": "414",
            "Deposits": "167",
            "Cost per Sign Up": "$28.30",
            "Cost per Deposit)": "$29.26"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "219,173",
            "Clicks": "15,995",
            "Sign Ups": "296",
            "Deposits": "169",
            "Cost per Sign Up": "$12.89",
            "Cost per Deposit)": "$35.90"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "195,042",
            "Clicks": "21,389",
            "Sign Ups": "287",
            "Deposits": "197",
            "Cost per Sign Up": "$14.08",
            "Cost per Deposit)": "$20.19"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "205,382",
            "Clicks": "26,501",
            "Sign Ups": "322",
            "Deposits": "205",
            "Cost per Sign Up": "$11.26",
            "Cost per Deposit)": "$38.64"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "155,510",
            "Clicks": "24,789",
            "Sign Ups": "332",
            "Deposits": "186",
            "Cost per Sign Up": "$19.13",
            "Cost per Deposit)": "$47.25"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "131,804",
            "Clicks": "17,536",
            "Sign Ups": "427",
            "Deposits": "132",
            "Cost per Sign Up": "$24.35",
            "Cost per Deposit)": "$20.56"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "155,849",
            "Clicks": "29,109",
            "Sign Ups": "392",
            "Deposits": "210",
            "Cost per Sign Up": "$11.57",
            "Cost per Deposit)": "$24.22"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "137,583",
            "Clicks": "22,923",
            "Sign Ups": "292",
            "Deposits": "137",
            "Cost per Sign Up": "$20.14",
            "Cost per Deposit)": "$56.88"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "156,402",
            "Clicks": "28,226",
            "Sign Ups": "330",
            "Deposits": "157",
            "Cost per Sign Up": "$17.20",
            "Cost per Deposit)": "$49.14"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "196,217",
            "Clicks": "29,432",
            "Sign Ups": "338",
            "Deposits": "174",
            "Cost per Sign Up": "$12.14",
            "Cost per Deposit)": "$40.34"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "197,893",
            "Clicks": "24,026",
            "Sign Ups": "348",
            "Deposits": "223",
            "Cost per Sign Up": "$19.94",
            "Cost per Deposit)": "$53.66"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "145,430",
            "Clicks": "26,345",
            "Sign Ups": "337",
            "Deposits": "160",
            "Cost per Sign Up": "$22.73",
            "Cost per Deposit)": "$34.38"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "195,507",
            "Clicks": "29,863",
            "Sign Ups": "341",
            "Deposits": "133",
            "Cost per Sign Up": "$27.79",
            "Cost per Deposit)": "$44.83"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "195,077",
            "Clicks": "21,014",
            "Sign Ups": "419",
            "Deposits": "194",
            "Cost per Sign Up": "$26.94",
            "Cost per Deposit)": "$51.06"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "206,288",
            "Clicks": "18,448",
            "Sign Ups": "434",
            "Deposits": "188",
            "Cost per Sign Up": "$10.73",
            "Cost per Deposit)": "$50.14"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "213,296",
            "Clicks": "22,055",
            "Sign Ups": "255",
            "Deposits": "172",
            "Cost per Sign Up": "$28.20",
            "Cost per Deposit)": "$48.56"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "186,133",
            "Clicks": "28,704",
            "Sign Ups": "414",
            "Deposits": "187",
            "Cost per Sign Up": "$17.01",
            "Cost per Deposit)": "$20.41"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "145,712",
            "Clicks": "19,383",
            "Sign Ups": "408",
            "Deposits": "177",
            "Cost per Sign Up": "$26.59",
            "Cost per Deposit)": "$27.02"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "142,714",
            "Clicks": "29,284",
            "Sign Ups": "418",
            "Deposits": "157",
            "Cost per Sign Up": "$25.45",
            "Cost per Deposit)": "$28.71"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "205,601",
            "Clicks": "25,239",
            "Sign Ups": "420",
            "Deposits": "196",
            "Cost per Sign Up": "$18.74",
            "Cost per Deposit)": "$30.34"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "187,196",
            "Clicks": "25,688",
            "Sign Ups": "435",
            "Deposits": "141",
            "Cost per Sign Up": "$15.01",
            "Cost per Deposit)": "$23.08"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "154,059",
            "Clicks": "26,984",
            "Sign Ups": "432",
            "Deposits": "142",
            "Cost per Sign Up": "$10.05",
            "Cost per Deposit)": "$53.09"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "165,114",
            "Clicks": "27,043",
            "Sign Ups": "410",
            "Deposits": "198",
            "Cost per Sign Up": "$29.42",
            "Cost per Deposit)": "$34.61"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "216,190",
            "Clicks": "19,254",
            "Sign Ups": "284",
            "Deposits": "221",
            "Cost per Sign Up": "$21.56",
            "Cost per Deposit)": "$37.31"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "169,371",
            "Clicks": "28,069",
            "Sign Ups": "276",
            "Deposits": "131",
            "Cost per Sign Up": "$14.02",
            "Cost per Deposit)": "$41.28"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "150,348",
            "Clicks": "21,723",
            "Sign Ups": "439",
            "Deposits": "174",
            "Cost per Sign Up": "$21.53",
            "Cost per Deposit)": "$26.53"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "191,981",
            "Clicks": "21,550",
            "Sign Ups": "281",
            "Deposits": "152",
            "Cost per Sign Up": "$23.86",
            "Cost per Deposit)": "$27.17"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "164,687",
            "Clicks": "27,531",
            "Sign Ups": "415",
            "Deposits": "210",
            "Cost per Sign Up": "$26.12",
            "Cost per Deposit)": "$43.74"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "156,517",
            "Clicks": "20,677",
            "Sign Ups": "369",
            "Deposits": "202",
            "Cost per Sign Up": "$22.15",
            "Cost per Deposit)": "$43.68"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "175,812",
            "Clicks": "20,465",
            "Sign Ups": "379",
            "Deposits": "178",
            "Cost per Sign Up": "$13.37",
            "Cost per Deposit)": "$43.89"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "147,980",
            "Clicks": "20,077",
            "Sign Ups": "312",
            "Deposits": "194",
            "Cost per Sign Up": "$26.91",
            "Cost per Deposit)": "$34.46"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "204,430",
            "Clicks": "24,300",
            "Sign Ups": "365",
            "Deposits": "209",
            "Cost per Sign Up": "$24.13",
            "Cost per Deposit)": "$26.29"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "157,891",
            "Clicks": "16,756",
            "Sign Ups": "383",
            "Deposits": "185",
            "Cost per Sign Up": "$23.00",
            "Cost per Deposit)": "$34.22"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "218,835",
            "Clicks": "16,746",
            "Sign Ups": "313",
            "Deposits": "177",
            "Cost per Sign Up": "$14.13",
            "Cost per Deposit)": "$31.80"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "159,338",
            "Clicks": "24,714",
            "Sign Ups": "449",
            "Deposits": "186",
            "Cost per Sign Up": "$15.43",
            "Cost per Deposit)": "$28.73"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "188,962",
            "Clicks": "21,240",
            "Sign Ups": "403",
            "Deposits": "152",
            "Cost per Sign Up": "$20.50",
            "Cost per Deposit)": "$26.90"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "202,887",
            "Clicks": "20,820",
            "Sign Ups": "274",
            "Deposits": "129",
            "Cost per Sign Up": "$29.63",
            "Cost per Deposit)": "$30.67"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "137,131",
            "Clicks": "20,942",
            "Sign Ups": "382",
            "Deposits": "185",
            "Cost per Sign Up": "$18.28",
            "Cost per Deposit)": "$31.96"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "150,677",
            "Clicks": "27,981",
            "Sign Ups": "264",
            "Deposits": "156",
            "Cost per Sign Up": "$19.51",
            "Cost per Deposit)": "$56.70"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "160,553",
            "Clicks": "18,746",
            "Sign Ups": "399",
            "Deposits": "218",
            "Cost per Sign Up": "$18.62",
            "Cost per Deposit)": "$42.38"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "192,828",
            "Clicks": "24,365",
            "Sign Ups": "266",
            "Deposits": "150",
            "Cost per Sign Up": "$27.10",
            "Cost per Deposit)": "$40.74"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "179,774",
            "Clicks": "25,845",
            "Sign Ups": "279",
            "Deposits": "188",
            "Cost per Sign Up": "$18.04",
            "Cost per Deposit)": "$46.34"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "179,817",
            "Clicks": "18,953",
            "Sign Ups": "366",
            "Deposits": "184",
            "Cost per Sign Up": "$18.53",
            "Cost per Deposit)": "$57.66"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "174,689",
            "Clicks": "27,109",
            "Sign Ups": "317",
            "Deposits": "213",
            "Cost per Sign Up": "$20.66",
            "Cost per Deposit)": "$25.89"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "163,675",
            "Clicks": "22,179",
            "Sign Ups": "416",
            "Deposits": "221",
            "Cost per Sign Up": "$12.15",
            "Cost per Deposit)": "$32.46"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "139,186",
            "Clicks": "22,677",
            "Sign Ups": "274",
            "Deposits": "137",
            "Cost per Sign Up": "$14.55",
            "Cost per Deposit)": "$52.67"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "133,389",
            "Clicks": "26,541",
            "Sign Ups": "305",
            "Deposits": "138",
            "Cost per Sign Up": "$23.81",
            "Cost per Deposit)": "$38.66"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "169,080",
            "Clicks": "19,207",
            "Sign Ups": "302",
            "Deposits": "125",
            "Cost per Sign Up": "$24.91",
            "Cost per Deposit)": "$25.85"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "219,437",
            "Clicks": "22,552",
            "Sign Ups": "312",
            "Deposits": "183",
            "Cost per Sign Up": "$20.64",
            "Cost per Deposit)": "$54.28"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "208,596",
            "Clicks": "16,946",
            "Sign Ups": "396",
            "Deposits": "178",
            "Cost per Sign Up": "$15.55",
            "Cost per Deposit)": "$28.58"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "174,122",
            "Clicks": "22,032",
            "Sign Ups": "437",
            "Deposits": "197",
            "Cost per Sign Up": "$26.47",
            "Cost per Deposit)": "$48.03"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "204,386",
            "Clicks": "17,535",
            "Sign Ups": "385",
            "Deposits": "155",
            "Cost per Sign Up": "$25.83",
            "Cost per Deposit)": "$55.84"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "168,514",
            "Clicks": "23,836",
            "Sign Ups": "312",
            "Deposits": "188",
            "Cost per Sign Up": "$24.20",
            "Cost per Deposit)": "$55.74"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "204,840",
            "Clicks": "15,356",
            "Sign Ups": "292",
            "Deposits": "211",
            "Cost per Sign Up": "$29.65",
            "Cost per Deposit)": "$59.09"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "150,068",
            "Clicks": "24,568",
            "Sign Ups": "447",
            "Deposits": "197",
            "Cost per Sign Up": "$21.27",
            "Cost per Deposit)": "$56.39"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "136,534",
            "Clicks": "29,452",
            "Sign Ups": "419",
            "Deposits": "218",
            "Cost per Sign Up": "$29.66",
            "Cost per Deposit)": "$42.18"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "154,280",
            "Clicks": "17,504",
            "Sign Ups": "346",
            "Deposits": "204",
            "Cost per Sign Up": "$15.53",
            "Cost per Deposit)": "$31.11"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "149,929",
            "Clicks": "24,025",
            "Sign Ups": "261",
            "Deposits": "184",
            "Cost per Sign Up": "$23.85",
            "Cost per Deposit)": "$43.21"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "187,202",
            "Clicks": "20,865",
            "Sign Ups": "448",
            "Deposits": "222",
            "Cost per Sign Up": "$27.22",
            "Cost per Deposit)": "$59.60"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "217,308",
            "Clicks": "25,405",
            "Sign Ups": "338",
            "Deposits": "220",
            "Cost per Sign Up": "$29.01",
            "Cost per Deposit)": "$56.23"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "186,755",
            "Clicks": "29,221",
            "Sign Ups": "370",
            "Deposits": "174",
            "Cost per Sign Up": "$29.04",
            "Cost per Deposit)": "$55.67"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "167,298",
            "Clicks": "19,735",
            "Sign Ups": "343",
            "Deposits": "183",
            "Cost per Sign Up": "$19.79",
            "Cost per Deposit)": "$43.99"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "139,366",
            "Clicks": "24,242",
            "Sign Ups": "329",
            "Deposits": "162",
            "Cost per Sign Up": "$13.78",
            "Cost per Deposit)": "$58.32"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "180,663",
            "Clicks": "15,649",
            "Sign Ups": "332",
            "Deposits": "139",
            "Cost per Sign Up": "$12.66",
            "Cost per Deposit)": "$44.12"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "154,414",
            "Clicks": "24,306",
            "Sign Ups": "321",
            "Deposits": "200",
            "Cost per Sign Up": "$17.62",
            "Cost per Deposit)": "$48.67"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "140,305",
            "Clicks": "19,732",
            "Sign Ups": "396",
            "Deposits": "224",
            "Cost per Sign Up": "$27.03",
            "Cost per Deposit)": "$20.21"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "193,783",
            "Clicks": "27,862",
            "Sign Ups": "449",
            "Deposits": "192",
            "Cost per Sign Up": "$28.43",
            "Cost per Deposit)": "$46.11"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "167,199",
            "Clicks": "16,534",
            "Sign Ups": "406",
            "Deposits": "135",
            "Cost per Sign Up": "$24.03",
            "Cost per Deposit)": "$59.41"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "203,900",
            "Clicks": "22,532",
            "Sign Ups": "450",
            "Deposits": "222",
            "Cost per Sign Up": "$10.06",
            "Cost per Deposit)": "$37.08"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "151,207",
            "Clicks": "16,686",
            "Sign Ups": "339",
            "Deposits": "172",
            "Cost per Sign Up": "$27.05",
            "Cost per Deposit)": "$58.64"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "186,077",
            "Clicks": "18,017",
            "Sign Ups": "449",
            "Deposits": "135",
            "Cost per Sign Up": "$24.87",
            "Cost per Deposit)": "$31.42"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "138,082",
            "Clicks": "27,880",
            "Sign Ups": "406",
            "Deposits": "217",
            "Cost per Sign Up": "$20.46",
            "Cost per Deposit)": "$22.48"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "170,645",
            "Clicks": "24,268",
            "Sign Ups": "425",
            "Deposits": "201",
            "Cost per Sign Up": "$23.83",
            "Cost per Deposit)": "$30.58"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "198,563",
            "Clicks": "28,924",
            "Sign Ups": "252",
            "Deposits": "163",
            "Cost per Sign Up": "$10.45",
            "Cost per Deposit)": "$52.42"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "146,074",
            "Clicks": "15,313",
            "Sign Ups": "381",
            "Deposits": "179",
            "Cost per Sign Up": "$14.85",
            "Cost per Deposit)": "$28.34"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "162,793",
            "Clicks": "18,211",
            "Sign Ups": "337",
            "Deposits": "220",
            "Cost per Sign Up": "$29.42",
            "Cost per Deposit)": "$35.64"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "165,329",
            "Clicks": "15,686",
            "Sign Ups": "416",
            "Deposits": "138",
            "Cost per Sign Up": "$27.76",
            "Cost per Deposit)": "$31.02"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "179,842",
            "Clicks": "26,850",
            "Sign Ups": "304",
            "Deposits": "136",
            "Cost per Sign Up": "$11.51",
            "Cost per Deposit)": "$44.27"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "194,527",
            "Clicks": "24,742",
            "Sign Ups": "372",
            "Deposits": "207",
            "Cost per Sign Up": "$26.43",
            "Cost per Deposit)": "$36.61"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "172,135",
            "Clicks": "24,759",
            "Sign Ups": "417",
            "Deposits": "155",
            "Cost per Sign Up": "$22.33",
            "Cost per Deposit)": "$33.75"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "139,427",
            "Clicks": "25,058",
            "Sign Ups": "405",
            "Deposits": "194",
            "Cost per Sign Up": "$16.11",
            "Cost per Deposit)": "$28.48"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "163,848",
            "Clicks": "29,639",
            "Sign Ups": "309",
            "Deposits": "222",
            "Cost per Sign Up": "$19.30",
            "Cost per Deposit)": "$52.32"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "161,335",
            "Clicks": "24,829",
            "Sign Ups": "404",
            "Deposits": "167",
            "Cost per Sign Up": "$10.61",
            "Cost per Deposit)": "$53.81"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "199,072",
            "Clicks": "25,056",
            "Sign Ups": "370",
            "Deposits": "156",
            "Cost per Sign Up": "$16.79",
            "Cost per Deposit)": "$47.92"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "198,138",
            "Clicks": "25,016",
            "Sign Ups": "393",
            "Deposits": "193",
            "Cost per Sign Up": "$10.93",
            "Cost per Deposit)": "$41.41"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "164,513",
            "Clicks": "16,766",
            "Sign Ups": "414",
            "Deposits": "145",
            "Cost per Sign Up": "$12.68",
            "Cost per Deposit)": "$41.99"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "187,864",
            "Clicks": "21,361",
            "Sign Ups": "428",
            "Deposits": "133",
            "Cost per Sign Up": "$15.78",
            "Cost per Deposit)": "$40.64"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "165,188",
            "Clicks": "26,789",
            "Sign Ups": "337",
            "Deposits": "200",
            "Cost per Sign Up": "$29.36",
            "Cost per Deposit)": "$42.96"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "210,473",
            "Clicks": "17,190",
            "Sign Ups": "339",
            "Deposits": "197",
            "Cost per Sign Up": "$13.33",
            "Cost per Deposit)": "$59.52"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "213,589",
            "Clicks": "16,821",
            "Sign Ups": "316",
            "Deposits": "184",
            "Cost per Sign Up": "$27.58",
            "Cost per Deposit)": "$39.04"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "139,494",
            "Clicks": "17,594",
            "Sign Ups": "317",
            "Deposits": "192",
            "Cost per Sign Up": "$23.39",
            "Cost per Deposit)": "$55.05"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "189,809",
            "Clicks": "17,198",
            "Sign Ups": "279",
            "Deposits": "171",
            "Cost per Sign Up": "$16.77",
            "Cost per Deposit)": "$39.88"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "204,408",
            "Clicks": "20,639",
            "Sign Ups": "273",
            "Deposits": "130",
            "Cost per Sign Up": "$15.70",
            "Cost per Deposit)": "$26.01"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "204,826",
            "Clicks": "16,195",
            "Sign Ups": "395",
            "Deposits": "187",
            "Cost per Sign Up": "$19.20",
            "Cost per Deposit)": "$36.93"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "219,687",
            "Clicks": "20,346",
            "Sign Ups": "371",
            "Deposits": "130",
            "Cost per Sign Up": "$12.69",
            "Cost per Deposit)": "$41.45"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "192,165",
            "Clicks": "27,788",
            "Sign Ups": "309",
            "Deposits": "219",
            "Cost per Sign Up": "$21.68",
            "Cost per Deposit)": "$49.95"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "159,641",
            "Clicks": "29,214",
            "Sign Ups": "340",
            "Deposits": "133",
            "Cost per Sign Up": "$26.96",
            "Cost per Deposit)": "$29.54"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "146,323",
            "Clicks": "22,826",
            "Sign Ups": "264",
            "Deposits": "139",
            "Cost per Sign Up": "$29.64",
            "Cost per Deposit)": "$56.03"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "193,525",
            "Clicks": "24,949",
            "Sign Ups": "326",
            "Deposits": "179",
            "Cost per Sign Up": "$12.94",
            "Cost per Deposit)": "$48.77"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "134,385",
            "Clicks": "24,485",
            "Sign Ups": "328",
            "Deposits": "159",
            "Cost per Sign Up": "$20.33",
            "Cost per Deposit)": "$30.09"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "191,734",
            "Clicks": "15,225",
            "Sign Ups": "302",
            "Deposits": "192",
            "Cost per Sign Up": "$10.63",
            "Cost per Deposit)": "$26.61"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "160,926",
            "Clicks": "25,005",
            "Sign Ups": "350",
            "Deposits": "183",
            "Cost per Sign Up": "$11.21",
            "Cost per Deposit)": "$22.30"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "166,958",
            "Clicks": "21,745",
            "Sign Ups": "423",
            "Deposits": "151",
            "Cost per Sign Up": "$11.15",
            "Cost per Deposit)": "$33.95"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "164,526",
            "Clicks": "27,498",
            "Sign Ups": "383",
            "Deposits": "133",
            "Cost per Sign Up": "$14.60",
            "Cost per Deposit)": "$57.45"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "219,182",
            "Clicks": "22,656",
            "Sign Ups": "326",
            "Deposits": "159",
            "Cost per Sign Up": "$11.63",
            "Cost per Deposit)": "$41.35"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "136,767",
            "Clicks": "27,500",
            "Sign Ups": "442",
            "Deposits": "196",
            "Cost per Sign Up": "$10.57",
            "Cost per Deposit)": "$50.51"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "147,765",
            "Clicks": "22,559",
            "Sign Ups": "251",
            "Deposits": "197",
            "Cost per Sign Up": "$11.23",
            "Cost per Deposit)": "$47.57"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "184,398",
            "Clicks": "24,208",
            "Sign Ups": "363",
            "Deposits": "221",
            "Cost per Sign Up": "$18.32",
            "Cost per Deposit)": "$55.95"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "209,326",
            "Clicks": "27,585",
            "Sign Ups": "380",
            "Deposits": "168",
            "Cost per Sign Up": "$11.83",
            "Cost per Deposit)": "$52.68"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "181,361",
            "Clicks": "26,830",
            "Sign Ups": "322",
            "Deposits": "209",
            "Cost per Sign Up": "$24.23",
            "Cost per Deposit)": "$39.29"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "145,661",
            "Clicks": "22,476",
            "Sign Ups": "283",
            "Deposits": "166",
            "Cost per Sign Up": "$25.81",
            "Cost per Deposit)": "$53.38"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "176,501",
            "Clicks": "27,238",
            "Sign Ups": "347",
            "Deposits": "217",
            "Cost per Sign Up": "$18.62",
            "Cost per Deposit)": "$49.15"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "179,899",
            "Clicks": "16,565",
            "Sign Ups": "447",
            "Deposits": "132",
            "Cost per Sign Up": "$26.74",
            "Cost per Deposit)": "$37.38"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "203,007",
            "Clicks": "24,383",
            "Sign Ups": "441",
            "Deposits": "224",
            "Cost per Sign Up": "$17.23",
            "Cost per Deposit)": "$55.26"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "161,885",
            "Clicks": "20,187",
            "Sign Ups": "317",
            "Deposits": "216",
            "Cost per Sign Up": "$26.22",
            "Cost per Deposit)": "$26.61"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "218,059",
            "Clicks": "18,579",
            "Sign Ups": "366",
            "Deposits": "214",
            "Cost per Sign Up": "$27.29",
            "Cost per Deposit)": "$20.48"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "130,502",
            "Clicks": "20,300",
            "Sign Ups": "420",
            "Deposits": "143",
            "Cost per Sign Up": "$16.13",
            "Cost per Deposit)": "$53.13"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "202,697",
            "Clicks": "25,395",
            "Sign Ups": "352",
            "Deposits": "157",
            "Cost per Sign Up": "$28.93",
            "Cost per Deposit)": "$38.50"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "153,124",
            "Clicks": "29,264",
            "Sign Ups": "406",
            "Deposits": "168",
            "Cost per Sign Up": "$17.62",
            "Cost per Deposit)": "$23.12"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "137,792",
            "Clicks": "29,200",
            "Sign Ups": "335",
            "Deposits": "130",
            "Cost per Sign Up": "$14.08",
            "Cost per Deposit)": "$36.15"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "212,308",
            "Clicks": "25,215",
            "Sign Ups": "396",
            "Deposits": "138",
            "Cost per Sign Up": "$29.76",
            "Cost per Deposit)": "$56.72"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "148,195",
            "Clicks": "18,326",
            "Sign Ups": "390",
            "Deposits": "216",
            "Cost per Sign Up": "$16.46",
            "Cost per Deposit)": "$23.64"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "150,686",
            "Clicks": "22,823",
            "Sign Ups": "340",
            "Deposits": "147",
            "Cost per Sign Up": "$26.90",
            "Cost per Deposit)": "$56.99"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "172,906",
            "Clicks": "26,936",
            "Sign Ups": "409",
            "Deposits": "142",
            "Cost per Sign Up": "$14.23",
            "Cost per Deposit)": "$45.47"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "174,902",
            "Clicks": "19,333",
            "Sign Ups": "288",
            "Deposits": "186",
            "Cost per Sign Up": "$26.23",
            "Cost per Deposit)": "$40.74"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "149,516",
            "Clicks": "23,183",
            "Sign Ups": "275",
            "Deposits": "188",
            "Cost per Sign Up": "$25.78",
            "Cost per Deposit)": "$58.76"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "173,468",
            "Clicks": "25,346",
            "Sign Ups": "448",
            "Deposits": "161",
            "Cost per Sign Up": "$28.69",
            "Cost per Deposit)": "$24.21"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "179,532",
            "Clicks": "23,741",
            "Sign Ups": "439",
            "Deposits": "169",
            "Cost per Sign Up": "$25.70",
            "Cost per Deposit)": "$39.88"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "216,349",
            "Clicks": "26,290",
            "Sign Ups": "299",
            "Deposits": "207",
            "Cost per Sign Up": "$27.69",
            "Cost per Deposit)": "$35.55"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "141,633",
            "Clicks": "18,563",
            "Sign Ups": "361",
            "Deposits": "198",
            "Cost per Sign Up": "$12.24",
            "Cost per Deposit)": "$38.57"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "192,437",
            "Clicks": "17,691",
            "Sign Ups": "337",
            "Deposits": "206",
            "Cost per Sign Up": "$11.57",
            "Cost per Deposit)": "$47.95"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "159,816",
            "Clicks": "16,402",
            "Sign Ups": "422",
            "Deposits": "218",
            "Cost per Sign Up": "$18.42",
            "Cost per Deposit)": "$23.87"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "206,227",
            "Clicks": "27,316",
            "Sign Ups": "371",
            "Deposits": "200",
            "Cost per Sign Up": "$26.05",
            "Cost per Deposit)": "$43.74"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "155,930",
            "Clicks": "15,860",
            "Sign Ups": "292",
            "Deposits": "138",
            "Cost per Sign Up": "$18.85",
            "Cost per Deposit)": "$28.93"
          }
        ]
      }
    },
    "cryptoTrading": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1516677",
            "Clicks": "22473",
            "Conversions": "471",
            "Conversion Value": "$34,938",
            "Cost per Result": "$32.72"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1919759",
            "Clicks": "34212",
            "Conversions": "357",
            "Conversion Value": "$47,709",
            "Cost per Result": "$20.87"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "1396435",
            "Clicks": "29486",
            "Conversions": "384",
            "Conversion Value": "$38,603",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "2357513",
            "Clicks": "32702",
            "Conversions": "425",
            "Conversion Value": "$41,861",
            "Cost per Result": "$27.04"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "2225943",
            "Clicks": "29761",
            "Conversions": "382",
            "Conversion Value": "$24,318",
            "Cost per Result": "$23.64"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "2226729",
            "Clicks": "27360",
            "Conversions": "480",
            "Conversion Value": "$25,748",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "1954904",
            "Clicks": "24334",
            "Conversions": "379",
            "Conversion Value": "$25,216",
            "Cost per Result": "$16.75"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1200395",
            "Clicks": "21898",
            "Conversions": "466",
            "Conversion Value": "$27,759",
            "Cost per Result": "$25.70"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1605631",
            "Clicks": "38721",
            "Conversions": "356",
            "Conversion Value": "$51,609",
            "Cost per Result": "$24.28"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "1466740",
            "Clicks": "29558",
            "Conversions": "364",
            "Conversion Value": "$57,689",
            "Cost per Result": "$21.60"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1902712",
            "Clicks": "21645",
            "Conversions": "402",
            "Conversion Value": "$34,821",
            "Cost per Result": "$32.07"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1926234",
            "Clicks": "20292",
            "Conversions": "376",
            "Conversion Value": "$48,958",
            "Cost per Result": "$25.62"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "2090000",
            "Clicks": "22026",
            "Conversions": "439",
            "Conversion Value": "$37,540",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "1524245",
            "Clicks": "30938",
            "Conversions": "431",
            "Conversion Value": "$31,031",
            "Cost per Result": "$24.68"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1846332",
            "Clicks": "30824",
            "Conversions": "303",
            "Conversion Value": "$55,788",
            "Cost per Result": "$25.21"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "995399",
            "Clicks": "33978",
            "Conversions": "354",
            "Conversion Value": "$40,865",
            "Cost per Result": "$20.72"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "2046115",
            "Clicks": "32741",
            "Conversions": "376",
            "Conversion Value": "$52,999",
            "Cost per Result": "$25.62"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "1737852",
            "Clicks": "34361",
            "Conversions": "394",
            "Conversion Value": "$48,597",
            "Cost per Result": "$26.24"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1327714",
            "Clicks": "27007",
            "Conversions": "367",
            "Conversion Value": "$42,571",
            "Cost per Result": "$26.44"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1561176",
            "Clicks": "27299",
            "Conversions": "361",
            "Conversion Value": "$49,258",
            "Cost per Result": "$34.10"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "955457",
            "Clicks": "26587",
            "Conversions": "372",
            "Conversion Value": "$22,741",
            "Cost per Result": "$24.42"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1303075",
            "Clicks": "25916",
            "Conversions": "377",
            "Conversion Value": "$38,965",
            "Cost per Result": "$22.08"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "2002016",
            "Clicks": "20519",
            "Conversions": "402",
            "Conversion Value": "$43,014",
            "Cost per Result": "$25.79"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1518244",
            "Clicks": "26872",
            "Conversions": "392",
            "Conversion Value": "$40,310",
            "Cost per Result": "$23.83"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1735842",
            "Clicks": "27116",
            "Conversions": "391",
            "Conversion Value": "$44,934",
            "Cost per Result": "$23.17"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1825385",
            "Clicks": "27308",
            "Conversions": "348",
            "Conversion Value": "$37,518",
            "Cost per Result": "$24.47"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "1760291",
            "Clicks": "22888",
            "Conversions": "365",
            "Conversion Value": "$40,505",
            "Cost per Result": "$29.14"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "1561159",
            "Clicks": "28081",
            "Conversions": "380",
            "Conversion Value": "$50,801",
            "Cost per Result": "$20.29"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "1990917",
            "Clicks": "27885",
            "Conversions": "446",
            "Conversion Value": "$38,750",
            "Cost per Result": "$23.15"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1925479",
            "Clicks": "28488",
            "Conversions": "420",
            "Conversion Value": "$44,846",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1637211",
            "Clicks": "31450",
            "Conversions": "449",
            "Conversion Value": "$34,759",
            "Cost per Result": "$26.66"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "2091804",
            "Clicks": "32088",
            "Conversions": "363",
            "Conversion Value": "$35,836",
            "Cost per Result": "$23.89"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1423792",
            "Clicks": "29665",
            "Conversions": "391",
            "Conversion Value": "$32,362",
            "Cost per Result": "$24.54"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "2366684",
            "Clicks": "28112",
            "Conversions": "390",
            "Conversion Value": "$53,862",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1780519",
            "Clicks": "28359",
            "Conversions": "423",
            "Conversion Value": "$43,403",
            "Cost per Result": "$30.57"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1670326",
            "Clicks": "24063",
            "Conversions": "407",
            "Conversion Value": "$45,757",
            "Cost per Result": "$24.45"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "2275457",
            "Clicks": "26790",
            "Conversions": "373",
            "Conversion Value": "$44,229",
            "Cost per Result": "$30.99"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1517343",
            "Clicks": "23165",
            "Conversions": "418",
            "Conversion Value": "$30,498",
            "Cost per Result": "$25.84"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "2170684",
            "Clicks": "25700",
            "Conversions": "283",
            "Conversion Value": "$28,024",
            "Cost per Result": "$24.19"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "1934588",
            "Clicks": "25811",
            "Conversions": "360",
            "Conversion Value": "$50,722",
            "Cost per Result": "$25.41"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1847969",
            "Clicks": "25017",
            "Conversions": "358",
            "Conversion Value": "$33,658",
            "Cost per Result": "$29.89"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1879039",
            "Clicks": "22611",
            "Conversions": "345",
            "Conversion Value": "$34,501",
            "Cost per Result": "$28.94"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "2165909",
            "Clicks": "31532",
            "Conversions": "403",
            "Conversion Value": "$50,299",
            "Cost per Result": "$25.84"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "2118495",
            "Clicks": "33008",
            "Conversions": "400",
            "Conversion Value": "$43,708",
            "Cost per Result": "$23.47"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1346537",
            "Clicks": "23735",
            "Conversions": "412",
            "Conversion Value": "$31,330",
            "Cost per Result": "$21.08"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "2104431",
            "Clicks": "23131",
            "Conversions": "438",
            "Conversion Value": "$45,009",
            "Cost per Result": "$29.70"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "2309414",
            "Clicks": "27004",
            "Conversions": "412",
            "Conversion Value": "$51,233",
            "Cost per Result": "$24.77"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "2080179",
            "Clicks": "34137",
            "Conversions": "405",
            "Conversion Value": "$31,632",
            "Cost per Result": "$25.92"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1823900",
            "Clicks": "17989",
            "Conversions": "377",
            "Conversion Value": "$47,897",
            "Cost per Result": "$24.20"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "2196506",
            "Clicks": "27886",
            "Conversions": "387",
            "Conversion Value": "$47,475",
            "Cost per Result": "$26.39"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1372560",
            "Clicks": "32188",
            "Conversions": "342",
            "Conversion Value": "$20,815",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1306390",
            "Clicks": "34574",
            "Conversions": "353",
            "Conversion Value": "$35,138",
            "Cost per Result": "$30.48"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "2334561",
            "Clicks": "30663",
            "Conversions": "414",
            "Conversion Value": "$51,702",
            "Cost per Result": "$21.35"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1623963",
            "Clicks": "26816",
            "Conversions": "318",
            "Conversion Value": "$32,003",
            "Cost per Result": "$16.48"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "2119188",
            "Clicks": "27875",
            "Conversions": "410",
            "Conversion Value": "$30,202",
            "Cost per Result": "$20.88"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1889961",
            "Clicks": "25537",
            "Conversions": "413",
            "Conversion Value": "$38,881",
            "Cost per Result": "$21.83"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1505731",
            "Clicks": "27120",
            "Conversions": "316",
            "Conversion Value": "$36,768",
            "Cost per Result": "$28.01"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "1705470",
            "Clicks": "24210",
            "Conversions": "359",
            "Conversion Value": "$43,640",
            "Cost per Result": "$23.64"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1963157",
            "Clicks": "22565",
            "Conversions": "401",
            "Conversion Value": "$29,609",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1982628",
            "Clicks": "28418",
            "Conversions": "344",
            "Conversion Value": "$49,033",
            "Cost per Result": "$34.55"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "2005974",
            "Clicks": "28886",
            "Conversions": "310",
            "Conversion Value": "$35,148",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "2008419",
            "Clicks": "26296",
            "Conversions": "402",
            "Conversion Value": "$37,896",
            "Cost per Result": "$24.84"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1552933",
            "Clicks": "27833",
            "Conversions": "463",
            "Conversion Value": "$37,274",
            "Cost per Result": "$31.70"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1106062",
            "Clicks": "25519",
            "Conversions": "379",
            "Conversion Value": "$34,590",
            "Cost per Result": "$20.99"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1841817",
            "Clicks": "34283",
            "Conversions": "412",
            "Conversion Value": "$55,863",
            "Cost per Result": "$23.74"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "2075897",
            "Clicks": "28144",
            "Conversions": "361",
            "Conversion Value": "$28,630",
            "Cost per Result": "$19.00"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1753628",
            "Clicks": "26532",
            "Conversions": "400",
            "Conversion Value": "$40,042",
            "Cost per Result": "$19.10"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1559301",
            "Clicks": "33534",
            "Conversions": "377",
            "Conversion Value": "$46,560",
            "Cost per Result": "$23.93"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1836805",
            "Clicks": "32549",
            "Conversions": "467",
            "Conversion Value": "$33,149",
            "Cost per Result": "$27.73"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1738424",
            "Clicks": "35569",
            "Conversions": "458",
            "Conversion Value": "$54,396",
            "Cost per Result": "$13.95"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "2308726",
            "Clicks": "26742",
            "Conversions": "520",
            "Conversion Value": "$58,960",
            "Cost per Result": "$26.68"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1391341",
            "Clicks": "20925",
            "Conversions": "348",
            "Conversion Value": "$48,912",
            "Cost per Result": "$23.48"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1846658",
            "Clicks": "13766",
            "Conversions": "425",
            "Conversion Value": "$33,761",
            "Cost per Result": "$31.89"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "1585092",
            "Clicks": "23659",
            "Conversions": "440",
            "Conversion Value": "$34,763",
            "Cost per Result": "$26.26"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "2050435",
            "Clicks": "24952",
            "Conversions": "445",
            "Conversion Value": "$34,740",
            "Cost per Result": "$20.63"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1613086",
            "Clicks": "35018",
            "Conversions": "394",
            "Conversion Value": "$30,359",
            "Cost per Result": "$24.74"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1238477",
            "Clicks": "19809",
            "Conversions": "351",
            "Conversion Value": "$44,198",
            "Cost per Result": "$29.24"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1861619",
            "Clicks": "24329",
            "Conversions": "413",
            "Conversion Value": "$45,817",
            "Cost per Result": "$27.79"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "2089276",
            "Clicks": "24771",
            "Conversions": "417",
            "Conversion Value": "$37,551",
            "Cost per Result": "$31.68"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1503301",
            "Clicks": "18869",
            "Conversions": "405",
            "Conversion Value": "$42,030",
            "Cost per Result": "$29.71"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "1911077",
            "Clicks": "31744",
            "Conversions": "373",
            "Conversion Value": "$41,509",
            "Cost per Result": "$19.41"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1130872",
            "Clicks": "30580",
            "Conversions": "305",
            "Conversion Value": "$40,934",
            "Cost per Result": "$21.56"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1550271",
            "Clicks": "37502",
            "Conversions": "348",
            "Conversion Value": "$40,073",
            "Cost per Result": "$32.57"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "2218712",
            "Clicks": "32010",
            "Conversions": "470",
            "Conversion Value": "$40,028",
            "Cost per Result": "$23.13"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "2257817",
            "Clicks": "21415",
            "Conversions": "323",
            "Conversion Value": "$40,805",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1874349",
            "Clicks": "30306",
            "Conversions": "403",
            "Conversion Value": "$50,744",
            "Cost per Result": "$26.15"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "2134832",
            "Clicks": "28001",
            "Conversions": "364",
            "Conversion Value": "$54,367",
            "Cost per Result": "$22.65"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1248843",
            "Clicks": "17723",
            "Conversions": "358",
            "Conversion Value": "$50,058",
            "Cost per Result": "$28.53"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1972495",
            "Clicks": "34478",
            "Conversions": "428",
            "Conversion Value": "$46,526",
            "Cost per Result": "$25.61"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1231022",
            "Clicks": "32206",
            "Conversions": "360",
            "Conversion Value": "$48,733",
            "Cost per Result": "$24.34"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1474102",
            "Clicks": "23711",
            "Conversions": "443",
            "Conversion Value": "$26,828",
            "Cost per Result": "$26.28"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "2161682",
            "Clicks": "27991",
            "Conversions": "406",
            "Conversion Value": "$53,224",
            "Cost per Result": "$24.26"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1673070",
            "Clicks": "28808",
            "Conversions": "305",
            "Conversion Value": "$39,114",
            "Cost per Result": "$26.85"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "2063089",
            "Clicks": "25756",
            "Conversions": "465",
            "Conversion Value": "$20,095",
            "Cost per Result": "$29.31"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "2301325",
            "Clicks": "31227",
            "Conversions": "423",
            "Conversion Value": "$29,553",
            "Cost per Result": "$29.80"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1566676",
            "Clicks": "30155",
            "Conversions": "398",
            "Conversion Value": "$40,707",
            "Cost per Result": "$21.21"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1567204",
            "Clicks": "31226",
            "Conversions": "335",
            "Conversion Value": "$29,818",
            "Cost per Result": "$28.57"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "2105887",
            "Clicks": "26449",
            "Conversions": "395",
            "Conversion Value": "$41,941",
            "Cost per Result": "$23.79"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1707403",
            "Clicks": "23522",
            "Conversions": "395",
            "Conversion Value": "$27,373",
            "Cost per Result": "$29.87"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "1477109",
            "Clicks": "22169",
            "Conversions": "381",
            "Conversion Value": "$50,624",
            "Cost per Result": "$22.82"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "1807880",
            "Clicks": "21394",
            "Conversions": "433",
            "Conversion Value": "$51,266",
            "Cost per Result": "$26.72"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1902454",
            "Clicks": "36190",
            "Conversions": "483",
            "Conversion Value": "$25,676",
            "Cost per Result": "$24.54"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "2155669",
            "Clicks": "36948",
            "Conversions": "493",
            "Conversion Value": "$18,503",
            "Cost per Result": "$24.05"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "1884297",
            "Clicks": "25658",
            "Conversions": "339",
            "Conversion Value": "$42,739",
            "Cost per Result": "$31.19"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1786479",
            "Clicks": "25192",
            "Conversions": "378",
            "Conversion Value": "$36,600",
            "Cost per Result": "$24.81"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1541009",
            "Clicks": "27317",
            "Conversions": "428",
            "Conversion Value": "$50,383",
            "Cost per Result": "$21.79"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1918479",
            "Clicks": "40097",
            "Conversions": "392",
            "Conversion Value": "$31,777",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1761563",
            "Clicks": "23141",
            "Conversions": "380",
            "Conversion Value": "$36,436",
            "Cost per Result": "$21.60"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1585030",
            "Clicks": "25168",
            "Conversions": "450",
            "Conversion Value": "$48,913",
            "Cost per Result": "$21.35"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "1506669",
            "Clicks": "26942",
            "Conversions": "350",
            "Conversion Value": "$44,797",
            "Cost per Result": "$26.05"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "2360067",
            "Clicks": "27086",
            "Conversions": "359",
            "Conversion Value": "$35,801",
            "Cost per Result": "$23.78"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1296413",
            "Clicks": "22537",
            "Conversions": "465",
            "Conversion Value": "$46,761",
            "Cost per Result": "$26.26"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "2158440",
            "Clicks": "28458",
            "Conversions": "343",
            "Conversion Value": "$43,035",
            "Cost per Result": "$21.99"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1426678",
            "Clicks": "26063",
            "Conversions": "458",
            "Conversion Value": "$33,108",
            "Cost per Result": "$32.74"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1826729",
            "Clicks": "30188",
            "Conversions": "362",
            "Conversion Value": "$27,783",
            "Cost per Result": "$26.37"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1904779",
            "Clicks": "23567",
            "Conversions": "455",
            "Conversion Value": "$44,692",
            "Cost per Result": "$19.69"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "1541870",
            "Clicks": "27284",
            "Conversions": "412",
            "Conversion Value": "$30,611",
            "Cost per Result": "$26.63"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "2131394",
            "Clicks": "25636",
            "Conversions": "429",
            "Conversion Value": "$39,318",
            "Cost per Result": "$27.74"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "2017203",
            "Clicks": "31452",
            "Conversions": "404",
            "Conversion Value": "$42,432",
            "Cost per Result": "$26.69"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1911674",
            "Clicks": "31813",
            "Conversions": "392",
            "Conversion Value": "$26,117",
            "Cost per Result": "$24.32"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "1277500",
            "Clicks": "19246",
            "Conversions": "414",
            "Conversion Value": "$37,226",
            "Cost per Result": "$24.30"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "1271232",
            "Clicks": "28877",
            "Conversions": "355",
            "Conversion Value": "$29,612",
            "Cost per Result": "$20.29"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1792744",
            "Clicks": "19845",
            "Conversions": "445",
            "Conversion Value": "$46,097",
            "Cost per Result": "$23.14"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "1888119",
            "Clicks": "27048",
            "Conversions": "444",
            "Conversion Value": "$42,857",
            "Cost per Result": "$19.99"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "1529280",
            "Clicks": "23768",
            "Conversions": "399",
            "Conversion Value": "$57,313",
            "Cost per Result": "$34.86"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1522749",
            "Clicks": "26160",
            "Conversions": "411",
            "Conversion Value": "$38,769",
            "Cost per Result": "$21.33"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "2648207",
            "Clicks": "24473",
            "Conversions": "345",
            "Conversion Value": "$47,725",
            "Cost per Result": "$27.68"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "2114572",
            "Clicks": "18004",
            "Conversions": "485",
            "Conversion Value": "$42,844",
            "Cost per Result": "$18.18"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "1403330",
            "Clicks": "22491",
            "Conversions": "341",
            "Conversion Value": "$41,220",
            "Cost per Result": "$31.57"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "1985034",
            "Clicks": "25406",
            "Conversions": "412",
            "Conversion Value": "$30,318",
            "Cost per Result": "$28.54"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1902090",
            "Clicks": "30679",
            "Conversions": "353",
            "Conversion Value": "$36,521",
            "Cost per Result": "$23.19"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1496659",
            "Clicks": "25932",
            "Conversions": "461",
            "Conversion Value": "$27,188",
            "Cost per Result": "$28.50"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1616065",
            "Clicks": "25006",
            "Conversions": "457",
            "Conversion Value": "$48,310",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "2510461",
            "Clicks": "27217",
            "Conversions": "383",
            "Conversion Value": "$33,536",
            "Cost per Result": "$20.17"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "2475248",
            "Clicks": "24237",
            "Conversions": "424",
            "Conversion Value": "$37,205",
            "Cost per Result": "$18.74"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1289425",
            "Clicks": "19261",
            "Conversions": "412",
            "Conversion Value": "$43,278",
            "Cost per Result": "$17.51"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "1851132",
            "Clicks": "27025",
            "Conversions": "313",
            "Conversion Value": "$52,207",
            "Cost per Result": "$20.52"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1031977",
            "Clicks": "29998",
            "Conversions": "383",
            "Conversion Value": "$51,066",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1914694",
            "Clicks": "24534",
            "Conversions": "430",
            "Conversion Value": "$36,615",
            "Cost per Result": "$21.74"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "991770",
            "Clicks": "36251",
            "Conversions": "361",
            "Conversion Value": "$29,376",
            "Cost per Result": "$26.00"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1720629",
            "Clicks": "26136",
            "Conversions": "409",
            "Conversion Value": "$54,368",
            "Cost per Result": "$25.57"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "1841925",
            "Clicks": "31052",
            "Conversions": "407",
            "Conversion Value": "$36,138",
            "Cost per Result": "$24.41"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1280647",
            "Clicks": "30328",
            "Conversions": "328",
            "Conversion Value": "$37,983",
            "Cost per Result": "$30.57"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "2153287",
            "Clicks": "31783",
            "Conversions": "395",
            "Conversion Value": "$29,055",
            "Cost per Result": "$20.71"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "2595197",
            "Clicks": "25349",
            "Conversions": "378",
            "Conversion Value": "$25,589",
            "Cost per Result": "$22.32"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1845390",
            "Clicks": "24923",
            "Conversions": "439",
            "Conversion Value": "$42,657",
            "Cost per Result": "$21.58"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1451653",
            "Clicks": "26775",
            "Conversions": "433",
            "Conversion Value": "$37,713",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "1791073",
            "Clicks": "23494",
            "Conversions": "354",
            "Conversion Value": "$40,544",
            "Cost per Result": "$25.88"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "2091835",
            "Clicks": "22032",
            "Conversions": "523",
            "Conversion Value": "$44,195",
            "Cost per Result": "$20.59"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1654171",
            "Clicks": "30432",
            "Conversions": "427",
            "Conversion Value": "$44,563",
            "Cost per Result": "$20.69"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1514384",
            "Clicks": "24516",
            "Conversions": "418",
            "Conversion Value": "$45,066",
            "Cost per Result": "$19.05"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "2244665",
            "Clicks": "31006",
            "Conversions": "448",
            "Conversion Value": "$27,518",
            "Cost per Result": "$25.44"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "1707526",
            "Clicks": "24350",
            "Conversions": "317",
            "Conversion Value": "$33,975",
            "Cost per Result": "$21.84"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "1805210",
            "Clicks": "32511",
            "Conversions": "359",
            "Conversion Value": "$36,256",
            "Cost per Result": "$23.12"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "1991391",
            "Clicks": "31006",
            "Conversions": "401",
            "Conversion Value": "$58,342",
            "Cost per Result": "$23.73"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "1802638",
            "Clicks": "20455",
            "Conversions": "426",
            "Conversion Value": "$28,622",
            "Cost per Result": "$27.29"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1760898",
            "Clicks": "28985",
            "Conversions": "335",
            "Conversion Value": "$28,840",
            "Cost per Result": "$27.45"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "1780502",
            "Clicks": "24476",
            "Conversions": "357",
            "Conversion Value": "$49,295",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "2321849",
            "Clicks": "25823",
            "Conversions": "385",
            "Conversion Value": "$48,158",
            "Cost per Result": "$19.20"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "2314920",
            "Clicks": "24518",
            "Conversions": "428",
            "Conversion Value": "$31,087",
            "Cost per Result": "$19.79"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "1253473",
            "Clicks": "27880",
            "Conversions": "431",
            "Conversion Value": "$53,048",
            "Cost per Result": "$23.73"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1673333",
            "Clicks": "30439",
            "Conversions": "369",
            "Conversion Value": "$35,767",
            "Cost per Result": "$27.89"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1646998",
            "Clicks": "25461",
            "Conversions": "460",
            "Conversion Value": "$34,188",
            "Cost per Result": "$20.92"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1060315",
            "Clicks": "20825",
            "Conversions": "333",
            "Conversion Value": "$30,969",
            "Cost per Result": "$28.80"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "2286201",
            "Clicks": "27768",
            "Conversions": "428",
            "Conversion Value": "$40,389",
            "Cost per Result": "$23.85"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "2488734",
            "Clicks": "31399",
            "Conversions": "356",
            "Conversion Value": "$46,376",
            "Cost per Result": "$28.28"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1363757",
            "Clicks": "23949",
            "Conversions": "408",
            "Conversion Value": "$38,100",
            "Cost per Result": "$24.85"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1569240",
            "Clicks": "29156",
            "Conversions": "366",
            "Conversion Value": "$50,363",
            "Cost per Result": "$17.76"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "2066206",
            "Clicks": "24575",
            "Conversions": "409",
            "Conversion Value": "$36,316",
            "Cost per Result": "$25.75"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1583976",
            "Clicks": "32086",
            "Conversions": "405",
            "Conversion Value": "$34,005",
            "Cost per Result": "$24.21"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1496670",
            "Clicks": "28882",
            "Conversions": "331",
            "Conversion Value": "$34,013",
            "Cost per Result": "$18.32"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "2307066",
            "Clicks": "29776",
            "Conversions": "345",
            "Conversion Value": "$41,975",
            "Cost per Result": "$28.49"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "2312916",
            "Clicks": "20197",
            "Conversions": "388",
            "Conversion Value": "$45,446",
            "Cost per Result": "$29.54"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1847915",
            "Clicks": "18779",
            "Conversions": "346",
            "Conversion Value": "$30,236",
            "Cost per Result": "$27.82"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "1900063",
            "Clicks": "27636",
            "Conversions": "471",
            "Conversion Value": "$52,751",
            "Cost per Result": "$25.56"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "2704046",
            "Clicks": "27233",
            "Conversions": "415",
            "Conversion Value": "$41,767",
            "Cost per Result": "$24.15"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "2021933",
            "Clicks": "26840",
            "Conversions": "401",
            "Conversion Value": "$41,400",
            "Cost per Result": "$25.45"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "1685957",
            "Clicks": "36242",
            "Conversions": "418",
            "Conversion Value": "$23,581",
            "Cost per Result": "$25.76"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1639066",
            "Clicks": "26105",
            "Conversions": "447",
            "Conversion Value": "$39,107",
            "Cost per Result": "$26.31"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1773144",
            "Clicks": "25216",
            "Conversions": "375",
            "Conversion Value": "$50,145",
            "Cost per Result": "$28.65"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1869273",
            "Clicks": "33888",
            "Conversions": "369",
            "Conversion Value": "$41,936",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "2120376",
            "Clicks": "29433",
            "Conversions": "354",
            "Conversion Value": "$48,135",
            "Cost per Result": "$15.73"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "1609756",
            "Clicks": "31948",
            "Conversions": "387",
            "Conversion Value": "$45,339",
            "Cost per Result": "$28.01"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1887683",
            "Clicks": "29402",
            "Conversions": "432",
            "Conversion Value": "$46,866",
            "Cost per Result": "$19.31"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "2237718",
            "Clicks": "24379",
            "Conversions": "369",
            "Conversion Value": "$26,258",
            "Cost per Result": "$25.75"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1733729",
            "Clicks": "38016",
            "Conversions": "361",
            "Conversion Value": "$43,493",
            "Cost per Result": "$17.98"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1345530",
            "Clicks": "21174",
            "Conversions": "383",
            "Conversion Value": "$35,697",
            "Cost per Result": "$34.26"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "1596054",
            "Clicks": "25973",
            "Conversions": "333",
            "Conversion Value": "$51,889",
            "Cost per Result": "$28.25"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1121397",
            "Clicks": "22276",
            "Conversions": "390",
            "Conversion Value": "$47,260",
            "Cost per Result": "$29.94"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1775991",
            "Clicks": "28450",
            "Conversions": "378",
            "Conversion Value": "$38,375",
            "Cost per Result": "$25.01"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1818440",
            "Clicks": "21023",
            "Conversions": "365",
            "Conversion Value": "$43,115",
            "Cost per Result": "$22.49"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "1031346",
            "Clicks": "24054",
            "Conversions": "444",
            "Conversion Value": "$52,209",
            "Cost per Result": "$31.95"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "1813778",
            "Clicks": "23171",
            "Conversions": "411",
            "Conversion Value": "$25,671",
            "Cost per Result": "$30.29"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1724110",
            "Clicks": "20303",
            "Conversions": "370",
            "Conversion Value": "$36,462",
            "Cost per Result": "$22.74"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1880982",
            "Clicks": "23232",
            "Conversions": "315",
            "Conversion Value": "$41,519",
            "Cost per Result": "$22.27"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1929117",
            "Clicks": "35552",
            "Conversions": "370",
            "Conversion Value": "$24,485",
            "Cost per Result": "$29.99"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "2508558",
            "Clicks": "19649",
            "Conversions": "421",
            "Conversion Value": "$46,826",
            "Cost per Result": "$26.00"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "2325555",
            "Clicks": "24530",
            "Conversions": "489",
            "Conversion Value": "$38,179",
            "Cost per Result": "$28.38"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1605538",
            "Clicks": "25306",
            "Conversions": "442",
            "Conversion Value": "$31,428",
            "Cost per Result": "$18.65"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "2297020",
            "Clicks": "25763",
            "Conversions": "423",
            "Conversion Value": "$38,439",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1942155",
            "Clicks": "23293",
            "Conversions": "268",
            "Conversion Value": "$50,700",
            "Cost per Result": "$21.24"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "1875495",
            "Clicks": "27623",
            "Conversions": "411",
            "Conversion Value": "$37,598",
            "Cost per Result": "$23.77"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1565403",
            "Clicks": "22966",
            "Conversions": "382",
            "Conversion Value": "$48,391",
            "Cost per Result": "$25.32"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "2185532",
            "Clicks": "32743",
            "Conversions": "395",
            "Conversion Value": "$33,946",
            "Cost per Result": "$24.43"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "2580696",
            "Clicks": "33255",
            "Conversions": "314",
            "Conversion Value": "$54,840",
            "Cost per Result": "$21.18"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1770093",
            "Clicks": "21159",
            "Conversions": "426",
            "Conversion Value": "$26,882",
            "Cost per Result": "$23.90"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "1063011",
            "Clicks": "29768",
            "Conversions": "369",
            "Conversion Value": "$30,952",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "2189375",
            "Clicks": "22713",
            "Conversions": "475",
            "Conversion Value": "$28,211",
            "Cost per Result": "$26.13"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "2403600",
            "Clicks": "21499",
            "Conversions": "346",
            "Conversion Value": "$25,252",
            "Cost per Result": "$27.57"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "1646510",
            "Clicks": "26658",
            "Conversions": "329",
            "Conversion Value": "$49,895",
            "Cost per Result": "$25.78"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "1247762",
            "Clicks": "29882",
            "Conversions": "492",
            "Conversion Value": "$29,850",
            "Cost per Result": "$28.18"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1864824",
            "Clicks": "27741",
            "Conversions": "379",
            "Conversion Value": "$51,691",
            "Cost per Result": "$16.91"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1660150",
            "Clicks": "27162",
            "Conversions": "410",
            "Conversion Value": "$44,380",
            "Cost per Result": "$18.48"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "1863046",
            "Clicks": "25739",
            "Conversions": "311",
            "Conversion Value": "$50,727",
            "Cost per Result": "$24.87"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1777472",
            "Clicks": "27180",
            "Conversions": "405",
            "Conversion Value": "$43,304",
            "Cost per Result": "$27.32"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1578473",
            "Clicks": "20584",
            "Conversions": "341",
            "Conversion Value": "$24,984",
            "Cost per Result": "$21.38"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1674572",
            "Clicks": "26919",
            "Conversions": "442",
            "Conversion Value": "$38,271",
            "Cost per Result": "$21.14"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1690930",
            "Clicks": "20352",
            "Conversions": "422",
            "Conversion Value": "$30,777",
            "Cost per Result": "$22.86"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "2164287",
            "Clicks": "33188",
            "Conversions": "408",
            "Conversion Value": "$55,485",
            "Cost per Result": "$29.02"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1608525",
            "Clicks": "25016",
            "Conversions": "449",
            "Conversion Value": "$28,053",
            "Cost per Result": "$26.50"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "2049412",
            "Clicks": "25064",
            "Conversions": "399",
            "Conversion Value": "$42,418",
            "Cost per Result": "$24.41"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1782148",
            "Clicks": "27233",
            "Conversions": "465",
            "Conversion Value": "$39,238",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "1207551",
            "Clicks": "32928",
            "Conversions": "352",
            "Conversion Value": "$32,360",
            "Cost per Result": "$22.56"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1800164",
            "Clicks": "22831",
            "Conversions": "376",
            "Conversion Value": "$24,370",
            "Cost per Result": "$25.85"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "2066228",
            "Clicks": "30832",
            "Conversions": "440",
            "Conversion Value": "$38,139",
            "Cost per Result": "$27.87"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "2045740",
            "Clicks": "29911",
            "Conversions": "402",
            "Conversion Value": "$29,456",
            "Cost per Result": "$25.80"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1552895",
            "Clicks": "36546",
            "Conversions": "364",
            "Conversion Value": "$49,405",
            "Cost per Result": "$29.13"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "2131991",
            "Clicks": "31645",
            "Conversions": "455",
            "Conversion Value": "$36,727",
            "Cost per Result": "$30.14"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "906793",
            "Clicks": "16594",
            "Conversions": "389",
            "Conversion Value": "$51,099",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1807904",
            "Clicks": "30445",
            "Conversions": "411",
            "Conversion Value": "$42,562",
            "Cost per Result": "$20.87"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1028421",
            "Clicks": "30393",
            "Conversions": "388",
            "Conversion Value": "$49,108",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1981025",
            "Clicks": "29847",
            "Conversions": "451",
            "Conversion Value": "$26,641",
            "Cost per Result": "$19.08"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "1517257",
            "Clicks": "23855",
            "Conversions": "454",
            "Conversion Value": "$39,647",
            "Cost per Result": "$20.35"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "2449253",
            "Clicks": "31479",
            "Conversions": "429",
            "Conversion Value": "$47,241",
            "Cost per Result": "$29.27"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1595762",
            "Clicks": "24479",
            "Conversions": "392",
            "Conversion Value": "$50,117",
            "Cost per Result": "$25.54"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1279198",
            "Clicks": "32285",
            "Conversions": "310",
            "Conversion Value": "$41,345",
            "Cost per Result": "$17.92"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "2111913",
            "Clicks": "34375",
            "Conversions": "436",
            "Conversion Value": "$18,938",
            "Cost per Result": "$24.96"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "805537",
            "Clicks": "32488",
            "Conversions": "389",
            "Conversion Value": "$24,493",
            "Cost per Result": "$28.06"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "2115310",
            "Clicks": "28447",
            "Conversions": "348",
            "Conversion Value": "$55,200",
            "Cost per Result": "$27.01"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1334754",
            "Clicks": "26957",
            "Conversions": "452",
            "Conversion Value": "$39,277",
            "Cost per Result": "$16.64"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "2262454",
            "Clicks": "21484",
            "Conversions": "445",
            "Conversion Value": "$29,849",
            "Cost per Result": "$23.97"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1686102",
            "Clicks": "36924",
            "Conversions": "401",
            "Conversion Value": "$44,360",
            "Cost per Result": "$23.34"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1061780",
            "Clicks": "25701",
            "Conversions": "418",
            "Conversion Value": "$53,588",
            "Cost per Result": "$19.11"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "2047446",
            "Clicks": "29546",
            "Conversions": "370",
            "Conversion Value": "$47,436",
            "Cost per Result": "$27.66"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "2442818",
            "Clicks": "26389",
            "Conversions": "318",
            "Conversion Value": "$51,073",
            "Cost per Result": "$24.52"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1907287",
            "Clicks": "19541",
            "Conversions": "355",
            "Conversion Value": "$36,468",
            "Cost per Result": "$22.94"
          }
        ]
      },
      "Web3NativesData": {
        "name": "Web3 Natives",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "272817",
            "Clicks": "11788",
            "Conversions": "171",
            "Conversion Value": "$17,626",
            "Cost per Result": "$35.73"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "453850",
            "Clicks": "12009",
            "Conversions": "100",
            "Conversion Value": "$21,200",
            "Cost per Result": "$33.61"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "641171",
            "Clicks": "13975",
            "Conversions": "132",
            "Conversion Value": "$12,488",
            "Cost per Result": "$38.31"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "931482",
            "Clicks": "11723",
            "Conversions": "139",
            "Conversion Value": "$17,946",
            "Cost per Result": "$36.21"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "455879",
            "Clicks": "13104",
            "Conversions": "163",
            "Conversion Value": "$10,033",
            "Cost per Result": "$29.04"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "678010",
            "Clicks": "13061",
            "Conversions": "158",
            "Conversion Value": "$16,112",
            "Cost per Result": "$29.99"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "865485",
            "Clicks": "4084",
            "Conversions": "137",
            "Conversion Value": "$10,738",
            "Cost per Result": "$14.87"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "203997",
            "Clicks": "8370",
            "Conversions": "146",
            "Conversion Value": "$13,023",
            "Cost per Result": "$39.37"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "272355",
            "Clicks": "12522",
            "Conversions": "87",
            "Conversion Value": "$27,532",
            "Cost per Result": "$32.29"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "348767",
            "Clicks": "4955",
            "Conversions": "86",
            "Conversion Value": "$30,207",
            "Cost per Result": "$18.88"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "302806",
            "Clicks": "10109",
            "Conversions": "180",
            "Conversion Value": "$9,817",
            "Cost per Result": "$31.32"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "366348",
            "Clicks": "5807",
            "Conversions": "141",
            "Conversion Value": "$21,645",
            "Cost per Result": "$20.73"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "605409",
            "Clicks": "9624",
            "Conversions": "133",
            "Conversion Value": "$21,016",
            "Cost per Result": "$19.31"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "735400",
            "Clicks": "12121",
            "Conversions": "134",
            "Conversion Value": "$12,810",
            "Cost per Result": "$17.78"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "437899",
            "Clicks": "10784",
            "Conversions": "95",
            "Conversion Value": "$27,774",
            "Cost per Result": "$17.52"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "330171",
            "Clicks": "13054",
            "Conversions": "165",
            "Conversion Value": "$12,420",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "583100",
            "Clicks": "12540",
            "Conversions": "113",
            "Conversion Value": "$21,782",
            "Cost per Result": "$28.91"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "808288",
            "Clicks": "12733",
            "Conversions": "117",
            "Conversion Value": "$27,064",
            "Cost per Result": "$11.19"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "278652",
            "Clicks": "11399",
            "Conversions": "158",
            "Conversion Value": "$16,078",
            "Cost per Result": "$34.13"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "767081",
            "Clicks": "12263",
            "Conversions": "80",
            "Conversion Value": "$30,685",
            "Cost per Result": "$37.63"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "320403",
            "Clicks": "4609",
            "Conversions": "138",
            "Conversion Value": "$9,460",
            "Cost per Result": "$24.92"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "266015",
            "Clicks": "6089",
            "Conversions": "92",
            "Conversion Value": "$19,365",
            "Cost per Result": "$21.65"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "333976",
            "Clicks": "5580",
            "Conversions": "83",
            "Conversion Value": "$24,126",
            "Cost per Result": "$38.93"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "743493",
            "Clicks": "7873",
            "Conversions": "154",
            "Conversion Value": "$14,985",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "694777",
            "Clicks": "11424",
            "Conversions": "93",
            "Conversion Value": "$31,373",
            "Cost per Result": "$39.73"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "969859",
            "Clicks": "13315",
            "Conversions": "114",
            "Conversion Value": "$15,821",
            "Cost per Result": "$22.04"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "667299",
            "Clicks": "7005",
            "Conversions": "97",
            "Conversion Value": "$14,663",
            "Cost per Result": "$36.72"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "234676",
            "Clicks": "12992",
            "Conversions": "97",
            "Conversion Value": "$29,765",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "846609",
            "Clicks": "10232",
            "Conversions": "161",
            "Conversion Value": "$15,023",
            "Cost per Result": "$26.41"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "388115",
            "Clicks": "11127",
            "Conversions": "149",
            "Conversion Value": "$25,059",
            "Cost per Result": "$36.15"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "397070",
            "Clicks": "11485",
            "Conversions": "177",
            "Conversion Value": "$12,702",
            "Cost per Result": "$31.98"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "528706",
            "Clicks": "9642",
            "Conversions": "119",
            "Conversion Value": "$23,797",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "237284",
            "Clicks": "9083",
            "Conversions": "126",
            "Conversion Value": "$11,541",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "700414",
            "Clicks": "6390",
            "Conversions": "139",
            "Conversion Value": "$31,094",
            "Cost per Result": "$22.67"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "619604",
            "Clicks": "8469",
            "Conversions": "162",
            "Conversion Value": "$18,454",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "341109",
            "Clicks": "12038",
            "Conversions": "115",
            "Conversion Value": "$23,117",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "964026",
            "Clicks": "7481",
            "Conversions": "115",
            "Conversion Value": "$21,764",
            "Cost per Result": "$25.53"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "584765",
            "Clicks": "8618",
            "Conversions": "104",
            "Conversion Value": "$8,005",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "858471",
            "Clicks": "13739",
            "Conversions": "81",
            "Conversion Value": "$8,548",
            "Cost per Result": "$26.98"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "996229",
            "Clicks": "10994",
            "Conversions": "173",
            "Conversion Value": "$26,140",
            "Cost per Result": "$20.76"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "822045",
            "Clicks": "13812",
            "Conversions": "147",
            "Conversion Value": "$16,272",
            "Cost per Result": "$38.99"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "607801",
            "Clicks": "8704",
            "Conversions": "134",
            "Conversion Value": "$15,954",
            "Cost per Result": "$15.38"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "798569",
            "Clicks": "11826",
            "Conversions": "99",
            "Conversion Value": "$29,335",
            "Cost per Result": "$24.16"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "891408",
            "Clicks": "13259",
            "Conversions": "144",
            "Conversion Value": "$27,294",
            "Cost per Result": "$19.80"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "320969",
            "Clicks": "7371",
            "Conversions": "159",
            "Conversion Value": "$10,044",
            "Cost per Result": "$21.09"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "761288",
            "Clicks": "4757",
            "Conversions": "131",
            "Conversion Value": "$17,644",
            "Cost per Result": "$25.70"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "641930",
            "Clicks": "7520",
            "Conversions": "137",
            "Conversion Value": "$27,253",
            "Cost per Result": "$34.65"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "845317",
            "Clicks": "11664",
            "Conversions": "144",
            "Conversion Value": "$17,982",
            "Cost per Result": "$38.88"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "703919",
            "Clicks": "4417",
            "Conversions": "132",
            "Conversion Value": "$19,527",
            "Cost per Result": "$24.61"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "835659",
            "Clicks": "10119",
            "Conversions": "84",
            "Conversion Value": "$29,643",
            "Cost per Result": "$14.03"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "526171",
            "Clicks": "10722",
            "Conversions": "143",
            "Conversion Value": "$8,297",
            "Cost per Result": "$24.21"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "241839",
            "Clicks": "10721",
            "Conversions": "139",
            "Conversion Value": "$19,818",
            "Cost per Result": "$22.76"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "983985",
            "Clicks": "8345",
            "Conversions": "151",
            "Conversion Value": "$30,776",
            "Cost per Result": "$24.16"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "593975",
            "Clicks": "4389",
            "Conversions": "83",
            "Conversion Value": "$18,017",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "875469",
            "Clicks": "7849",
            "Conversions": "133",
            "Conversion Value": "$11,315",
            "Cost per Result": "$15.31"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "716165",
            "Clicks": "7586",
            "Conversions": "107",
            "Conversion Value": "$19,041",
            "Cost per Result": "$10.18"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "504298",
            "Clicks": "13822",
            "Conversions": "97",
            "Conversion Value": "$21,936",
            "Cost per Result": "$34.57"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "307366",
            "Clicks": "5600",
            "Conversions": "103",
            "Conversion Value": "$26,542",
            "Cost per Result": "$14.89"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "507792",
            "Clicks": "6036",
            "Conversions": "118",
            "Conversion Value": "$10,373",
            "Cost per Result": "$36.86"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "689813",
            "Clicks": "10143",
            "Conversions": "120",
            "Conversion Value": "$28,670",
            "Cost per Result": "$27.72"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "344273",
            "Clicks": "13525",
            "Conversions": "88",
            "Conversion Value": "$19,727",
            "Cost per Result": "$24.17"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "735679",
            "Clicks": "10557",
            "Conversions": "112",
            "Conversion Value": "$20,319",
            "Cost per Result": "$35.91"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "201070",
            "Clicks": "13426",
            "Conversions": "152",
            "Conversion Value": "$21,691",
            "Cost per Result": "$30.26"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "346533",
            "Clicks": "12380",
            "Conversions": "110",
            "Conversion Value": "$14,422",
            "Cost per Result": "$23.85"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "836006",
            "Clicks": "13983",
            "Conversions": "179",
            "Conversion Value": "$31,655",
            "Cost per Result": "$12.36"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "684690",
            "Clicks": "10806",
            "Conversions": "156",
            "Conversion Value": "$13,907",
            "Cost per Result": "$16.75"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "624849",
            "Clicks": "10012",
            "Conversions": "131",
            "Conversion Value": "$20,597",
            "Cost per Result": "$26.26"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "251145",
            "Clicks": "12195",
            "Conversions": "116",
            "Conversion Value": "$25,562",
            "Cost per Result": "$31.81"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "721228",
            "Clicks": "13923",
            "Conversions": "163",
            "Conversion Value": "$11,616",
            "Cost per Result": "$34.82"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "243921",
            "Clicks": "13687",
            "Conversions": "139",
            "Conversion Value": "$31,170",
            "Cost per Result": "$13.27"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "672916",
            "Clicks": "8688",
            "Conversions": "167",
            "Conversion Value": "$29,737",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "548549",
            "Clicks": "8297",
            "Conversions": "127",
            "Conversion Value": "$28,791",
            "Cost per Result": "$17.02"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "870626",
            "Clicks": "4559",
            "Conversions": "110",
            "Conversion Value": "$18,114",
            "Cost per Result": "$17.11"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "306750",
            "Clicks": "11490",
            "Conversions": "171",
            "Conversion Value": "$9,802",
            "Cost per Result": "$36.24"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "886277",
            "Clicks": "5423",
            "Conversions": "119",
            "Conversion Value": "$17,236",
            "Cost per Result": "$19.23"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "340300",
            "Clicks": "10986",
            "Conversions": "110",
            "Conversion Value": "$11,996",
            "Cost per Result": "$27.17"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "403713",
            "Clicks": "6262",
            "Conversions": "128",
            "Conversion Value": "$27,700",
            "Cost per Result": "$26.41"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "797129",
            "Clicks": "6875",
            "Conversions": "118",
            "Conversion Value": "$21,090",
            "Cost per Result": "$30.62"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "834760",
            "Clicks": "10830",
            "Conversions": "166",
            "Conversion Value": "$18,769",
            "Cost per Result": "$39.80"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "821291",
            "Clicks": "6207",
            "Conversions": "170",
            "Conversion Value": "$17,044",
            "Cost per Result": "$29.24"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "688894",
            "Clicks": "10173",
            "Conversions": "163",
            "Conversion Value": "$15,379",
            "Cost per Result": "$11.92"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "374941",
            "Clicks": "9396",
            "Conversions": "109",
            "Conversion Value": "$20,730",
            "Cost per Result": "$12.20"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "229456",
            "Clicks": "12759",
            "Conversions": "120",
            "Conversion Value": "$26,486",
            "Cost per Result": "$39.84"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "669232",
            "Clicks": "13561",
            "Conversions": "180",
            "Conversion Value": "$21,054",
            "Cost per Result": "$19.39"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "669718",
            "Clicks": "6530",
            "Conversions": "81",
            "Conversion Value": "$20,574",
            "Cost per Result": "$38.62"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "464632",
            "Clicks": "6205",
            "Conversions": "113",
            "Conversion Value": "$27,637",
            "Cost per Result": "$25.34"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "767080",
            "Clicks": "11035",
            "Conversions": "89",
            "Conversion Value": "$30,461",
            "Cost per Result": "$12.95"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "414801",
            "Clicks": "6673",
            "Conversions": "118",
            "Conversion Value": "$29,461",
            "Cost per Result": "$37.58"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "854767",
            "Clicks": "13656",
            "Conversions": "173",
            "Conversion Value": "$29,837",
            "Cost per Result": "$23.90"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "249416",
            "Clicks": "9867",
            "Conversions": "84",
            "Conversion Value": "$24,400",
            "Cost per Result": "$10.48"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "861252",
            "Clicks": "10948",
            "Conversions": "175",
            "Conversion Value": "$10,870",
            "Cost per Result": "$12.10"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "880461",
            "Clicks": "8708",
            "Conversions": "170",
            "Conversion Value": "$27,889",
            "Cost per Result": "$29.23"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "325001",
            "Clicks": "12901",
            "Conversions": "87",
            "Conversion Value": "$25,927",
            "Cost per Result": "$25.16"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "796052",
            "Clicks": "7550",
            "Conversions": "141",
            "Conversion Value": "$9,687",
            "Cost per Result": "$24.05"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "894638",
            "Clicks": "11699",
            "Conversions": "175",
            "Conversion Value": "$13,291",
            "Cost per Result": "$34.15"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "461126",
            "Clicks": "6709",
            "Conversions": "170",
            "Conversion Value": "$11,283",
            "Cost per Result": "$22.03"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "853801",
            "Clicks": "5178",
            "Conversions": "92",
            "Conversion Value": "$12,921",
            "Cost per Result": "$35.78"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "522773",
            "Clicks": "8826",
            "Conversions": "159",
            "Conversion Value": "$21,129",
            "Cost per Result": "$37.47"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "429736",
            "Clicks": "9642",
            "Conversions": "155",
            "Conversion Value": "$15,551",
            "Cost per Result": "$35.52"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "629994",
            "Clicks": "5922",
            "Conversions": "142",
            "Conversion Value": "$31,899",
            "Cost per Result": "$25.76"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "701356",
            "Clicks": "4153",
            "Conversions": "94",
            "Conversion Value": "$28,934",
            "Cost per Result": "$39.47"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "795690",
            "Clicks": "13801",
            "Conversions": "178",
            "Conversion Value": "$11,315",
            "Cost per Result": "$15.68"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "545052",
            "Clicks": "13884",
            "Conversions": "169",
            "Conversion Value": "$8,271",
            "Cost per Result": "$39.76"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "988031",
            "Clicks": "9278",
            "Conversions": "111",
            "Conversion Value": "$18,722",
            "Cost per Result": "$17.77"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "636453",
            "Clicks": "9630",
            "Conversions": "159",
            "Conversion Value": "$21,621",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "736562",
            "Clicks": "9363",
            "Conversions": "154",
            "Conversion Value": "$31,325",
            "Cost per Result": "$38.93"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "917279",
            "Clicks": "13533",
            "Conversions": "122",
            "Conversion Value": "$18,764",
            "Cost per Result": "$35.19"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "435735",
            "Clicks": "7832",
            "Conversions": "82",
            "Conversion Value": "$27,542",
            "Cost per Result": "$16.45"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "255115",
            "Clicks": "12239",
            "Conversions": "137",
            "Conversion Value": "$19,031",
            "Cost per Result": "$27.83"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "347559",
            "Clicks": "8973",
            "Conversions": "126",
            "Conversion Value": "$23,752",
            "Cost per Result": "$34.47"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "849032",
            "Clicks": "11769",
            "Conversions": "155",
            "Conversion Value": "$13,153",
            "Cost per Result": "$24.09"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "431001",
            "Clicks": "5094",
            "Conversions": "159",
            "Conversion Value": "$31,327",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "906957",
            "Clicks": "7135",
            "Conversions": "118",
            "Conversion Value": "$23,451",
            "Cost per Result": "$20.94"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "406241",
            "Clicks": "10593",
            "Conversions": "158",
            "Conversion Value": "$18,530",
            "Cost per Result": "$37.78"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "945576",
            "Clicks": "11748",
            "Conversions": "119",
            "Conversion Value": "$18,617",
            "Cost per Result": "$16.64"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "206992",
            "Clicks": "11908",
            "Conversions": "168",
            "Conversion Value": "$18,764",
            "Cost per Result": "$17.33"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "506535",
            "Clicks": "10091",
            "Conversions": "117",
            "Conversion Value": "$16,788",
            "Cost per Result": "$34.07"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "656670",
            "Clicks": "8123",
            "Conversions": "176",
            "Conversion Value": "$19,877",
            "Cost per Result": "$35.20"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "772881",
            "Clicks": "13356",
            "Conversions": "80",
            "Conversion Value": "$24,362",
            "Cost per Result": "$30.42"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "659168",
            "Clicks": "8823",
            "Conversions": "133",
            "Conversion Value": "$10,708",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "398359",
            "Clicks": "4089",
            "Conversions": "97",
            "Conversion Value": "$8,643",
            "Cost per Result": "$26.15"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "220205",
            "Clicks": "4002",
            "Conversions": "114",
            "Conversion Value": "$11,495",
            "Cost per Result": "$11.83"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "783285",
            "Clicks": "7806",
            "Conversions": "163",
            "Conversion Value": "$30,452",
            "Cost per Result": "$32.21"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "570710",
            "Clicks": "9774",
            "Conversions": "112",
            "Conversion Value": "$22,010",
            "Cost per Result": "$23.74"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "460659",
            "Clicks": "8359",
            "Conversions": "98",
            "Conversion Value": "$30,583",
            "Cost per Result": "$37.23"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "672205",
            "Clicks": "4540",
            "Conversions": "129",
            "Conversion Value": "$17,739",
            "Cost per Result": "$20.75"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "873901",
            "Clicks": "9182",
            "Conversions": "99",
            "Conversion Value": "$17,845",
            "Cost per Result": "$21.36"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "730761",
            "Clicks": "4219",
            "Conversions": "146",
            "Conversion Value": "$21,582",
            "Cost per Result": "$20.32"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "574379",
            "Clicks": "4605",
            "Conversions": "90",
            "Conversion Value": "$22,249",
            "Cost per Result": "$20.26"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "478506",
            "Clicks": "4958",
            "Conversions": "114",
            "Conversion Value": "$8,738",
            "Cost per Result": "$20.47"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "746858",
            "Clicks": "8153",
            "Conversions": "98",
            "Conversion Value": "$10,991",
            "Cost per Result": "$23.67"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "310065",
            "Clicks": "4959",
            "Conversions": "160",
            "Conversion Value": "$8,782",
            "Cost per Result": "$19.86"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "850317",
            "Clicks": "7162",
            "Conversions": "169",
            "Conversion Value": "$26,814",
            "Cost per Result": "$31.32"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "658201",
            "Clicks": "8134",
            "Conversions": "154",
            "Conversion Value": "$11,533",
            "Cost per Result": "$19.38"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "769326",
            "Clicks": "13304",
            "Conversions": "175",
            "Conversion Value": "$20,076",
            "Cost per Result": "$21.93"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "393736",
            "Clicks": "5300",
            "Conversions": "134",
            "Conversion Value": "$21,085",
            "Cost per Result": "$13.51"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "398690",
            "Clicks": "4736",
            "Conversions": "88",
            "Conversion Value": "$22,884",
            "Cost per Result": "$25.94"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "436905",
            "Clicks": "9600",
            "Conversions": "149",
            "Conversion Value": "$23,081",
            "Cost per Result": "$16.26"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "285226",
            "Clicks": "9784",
            "Conversions": "131",
            "Conversion Value": "$19,502",
            "Cost per Result": "$13.41"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "231032",
            "Clicks": "12379",
            "Conversions": "81",
            "Conversion Value": "$8,718",
            "Cost per Result": "$12.53"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "541852",
            "Clicks": "11504",
            "Conversions": "152",
            "Conversion Value": "$27,360",
            "Cost per Result": "$33.71"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "277362",
            "Clicks": "11488",
            "Conversions": "91",
            "Conversion Value": "$22,548",
            "Cost per Result": "$26.68"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "509400",
            "Clicks": "13310",
            "Conversions": "82",
            "Conversion Value": "$18,426",
            "Cost per Result": "$36.08"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "947198",
            "Clicks": "13104",
            "Conversions": "156",
            "Conversion Value": "$8,357",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "812262",
            "Clicks": "4375",
            "Conversions": "101",
            "Conversion Value": "$8,832",
            "Cost per Result": "$19.18"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "821861",
            "Clicks": "9781",
            "Conversions": "179",
            "Conversion Value": "$29,609",
            "Cost per Result": "$15.38"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "438934",
            "Clicks": "9956",
            "Conversions": "135",
            "Conversion Value": "$20,178",
            "Cost per Result": "$13.57"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "772558",
            "Clicks": "5386",
            "Conversions": "98",
            "Conversion Value": "$24,161",
            "Cost per Result": "$22.47"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "444232",
            "Clicks": "11497",
            "Conversions": "175",
            "Conversion Value": "$22,009",
            "Cost per Result": "$15.99"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "353644",
            "Clicks": "12504",
            "Conversions": "141",
            "Conversion Value": "$24,517",
            "Cost per Result": "$34.65"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "245237",
            "Clicks": "8610",
            "Conversions": "122",
            "Conversion Value": "$18,114",
            "Cost per Result": "$25.33"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "879229",
            "Clicks": "10120",
            "Conversions": "136",
            "Conversion Value": "$8,341",
            "Cost per Result": "$12.36"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "359385",
            "Clicks": "4202",
            "Conversions": "121",
            "Conversion Value": "$18,232",
            "Cost per Result": "$11.02"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "782101",
            "Clicks": "11284",
            "Conversions": "117",
            "Conversion Value": "$18,537",
            "Cost per Result": "$17.93"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "564374",
            "Clicks": "13503",
            "Conversions": "109",
            "Conversion Value": "$31,968",
            "Cost per Result": "$20.52"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "875959",
            "Clicks": "8723",
            "Conversions": "142",
            "Conversion Value": "$13,178",
            "Cost per Result": "$33.45"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "862145",
            "Clicks": "11012",
            "Conversions": "111",
            "Conversion Value": "$13,574",
            "Cost per Result": "$29.16"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "879213",
            "Clicks": "5100",
            "Conversions": "81",
            "Conversion Value": "$31,861",
            "Cost per Result": "$37.98"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "782075",
            "Clicks": "8779",
            "Conversions": "134",
            "Conversion Value": "$30,971",
            "Cost per Result": "$35.22"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "696762",
            "Clicks": "6949",
            "Conversions": "135",
            "Conversion Value": "$17,766",
            "Cost per Result": "$19.48"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "403001",
            "Clicks": "7569",
            "Conversions": "146",
            "Conversion Value": "$24,050",
            "Cost per Result": "$23.23"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "742382",
            "Clicks": "12982",
            "Conversions": "169",
            "Conversion Value": "$24,448",
            "Cost per Result": "$36.90"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "413723",
            "Clicks": "5757",
            "Conversions": "148",
            "Conversion Value": "$18,068",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "229178",
            "Clicks": "8160",
            "Conversions": "106",
            "Conversion Value": "$12,014",
            "Cost per Result": "$27.57"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "833711",
            "Clicks": "10887",
            "Conversions": "171",
            "Conversion Value": "$15,413",
            "Cost per Result": "$21.75"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "831412",
            "Clicks": "11894",
            "Conversions": "117",
            "Conversion Value": "$21,286",
            "Cost per Result": "$34.84"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "608560",
            "Clicks": "6184",
            "Conversions": "168",
            "Conversion Value": "$27,214",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "363128",
            "Clicks": "8269",
            "Conversions": "158",
            "Conversion Value": "$23,305",
            "Cost per Result": "$14.07"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "969959",
            "Clicks": "9700",
            "Conversions": "147",
            "Conversion Value": "$14,574",
            "Cost per Result": "$15.71"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "425077",
            "Clicks": "7154",
            "Conversions": "131",
            "Conversion Value": "$9,363",
            "Cost per Result": "$32.06"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "461226",
            "Clicks": "11592",
            "Conversions": "115",
            "Conversion Value": "$17,301",
            "Cost per Result": "$19.15"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "801520",
            "Clicks": "10177",
            "Conversions": "83",
            "Conversion Value": "$17,932",
            "Cost per Result": "$32.24"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "993456",
            "Clicks": "5339",
            "Conversions": "105",
            "Conversion Value": "$19,074",
            "Cost per Result": "$27.83"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "765376",
            "Clicks": "5316",
            "Conversions": "153",
            "Conversion Value": "$11,205",
            "Cost per Result": "$17.19"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "392679",
            "Clicks": "9687",
            "Conversions": "153",
            "Conversion Value": "$31,477",
            "Cost per Result": "$27.44"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "958881",
            "Clicks": "7673",
            "Conversions": "155",
            "Conversion Value": "$15,845",
            "Cost per Result": "$31.44"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "466837",
            "Clicks": "9089",
            "Conversions": "164",
            "Conversion Value": "$28,146",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "705210",
            "Clicks": "13650",
            "Conversions": "174",
            "Conversion Value": "$11,132",
            "Cost per Result": "$32.39"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "766435",
            "Clicks": "13582",
            "Conversions": "165",
            "Conversion Value": "$17,590",
            "Cost per Result": "$33.24"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "584481",
            "Clicks": "7484",
            "Conversions": "160",
            "Conversion Value": "$27,417",
            "Cost per Result": "$21.74"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "501129",
            "Clicks": "12705",
            "Conversions": "134",
            "Conversion Value": "$27,785",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "547892",
            "Clicks": "6121",
            "Conversions": "127",
            "Conversion Value": "$29,450",
            "Cost per Result": "$13.15"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "534967",
            "Clicks": "8649",
            "Conversions": "168",
            "Conversion Value": "$22,291",
            "Cost per Result": "$39.52"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "781546",
            "Clicks": "13462",
            "Conversions": "160",
            "Conversion Value": "$26,871",
            "Cost per Result": "$15.89"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "706947",
            "Clicks": "8492",
            "Conversions": "152",
            "Conversion Value": "$12,835",
            "Cost per Result": "$38.17"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "564555",
            "Clicks": "13081",
            "Conversions": "149",
            "Conversion Value": "$14,585",
            "Cost per Result": "$18.26"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "465040",
            "Clicks": "4796",
            "Conversions": "132",
            "Conversion Value": "$13,751",
            "Cost per Result": "$33.76"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "654320",
            "Clicks": "8716",
            "Conversions": "123",
            "Conversion Value": "$30,714",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "377038",
            "Clicks": "4138",
            "Conversions": "130",
            "Conversion Value": "$17,504",
            "Cost per Result": "$29.02"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "798153",
            "Clicks": "10706",
            "Conversions": "173",
            "Conversion Value": "$17,741",
            "Cost per Result": "$11.26"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "769616",
            "Clicks": "9017",
            "Conversions": "115",
            "Conversion Value": "$20,248",
            "Cost per Result": "$19.05"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "299909",
            "Clicks": "9940",
            "Conversions": "169",
            "Conversion Value": "$26,897",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "489130",
            "Clicks": "5370",
            "Conversions": "109",
            "Conversion Value": "$11,795",
            "Cost per Result": "$37.57"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "610450",
            "Clicks": "6769",
            "Conversions": "86",
            "Conversion Value": "$16,943",
            "Cost per Result": "$12.25"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "977917",
            "Clicks": "4243",
            "Conversions": "101",
            "Conversion Value": "$25,834",
            "Cost per Result": "$23.17"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "471971",
            "Clicks": "13109",
            "Conversions": "118",
            "Conversion Value": "$9,666",
            "Cost per Result": "$32.43"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "984489",
            "Clicks": "6407",
            "Conversions": "111",
            "Conversion Value": "$29,253",
            "Cost per Result": "$29.35"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "801015",
            "Clicks": "11210",
            "Conversions": "180",
            "Conversion Value": "$15,401",
            "Cost per Result": "$24.22"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "604109",
            "Clicks": "5732",
            "Conversions": "137",
            "Conversion Value": "$12,061",
            "Cost per Result": "$31.65"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "978476",
            "Clicks": "11308",
            "Conversions": "168",
            "Conversion Value": "$11,949",
            "Cost per Result": "$14.52"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "369001",
            "Clicks": "4192",
            "Conversions": "82",
            "Conversion Value": "$29,002",
            "Cost per Result": "$22.39"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "824909",
            "Clicks": "13616",
            "Conversions": "108",
            "Conversion Value": "$20,939",
            "Cost per Result": "$24.47"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "228998",
            "Clicks": "5442",
            "Conversions": "113",
            "Conversion Value": "$28,211",
            "Cost per Result": "$29.93"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "535948",
            "Clicks": "13494",
            "Conversions": "134",
            "Conversion Value": "$15,823",
            "Cost per Result": "$32.38"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "799971",
            "Clicks": "7417",
            "Conversions": "117",
            "Conversion Value": "$26,444",
            "Cost per Result": "$26.53"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "258397",
            "Clicks": "4832",
            "Conversions": "165",
            "Conversion Value": "$10,751",
            "Cost per Result": "$16.93"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "388005",
            "Clicks": "8243",
            "Conversions": "135",
            "Conversion Value": "$11,743",
            "Cost per Result": "$36.18"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "787709",
            "Clicks": "7706",
            "Conversions": "155",
            "Conversion Value": "$10,043",
            "Cost per Result": "$24.99"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "663247",
            "Clicks": "5464",
            "Conversions": "95",
            "Conversion Value": "$16,102",
            "Cost per Result": "$24.09"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "409704",
            "Clicks": "6591",
            "Conversions": "116",
            "Conversion Value": "$31,118",
            "Cost per Result": "$16.49"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "562630",
            "Clicks": "11166",
            "Conversions": "164",
            "Conversion Value": "$9,644",
            "Cost per Result": "$29.76"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "503836",
            "Clicks": "13412",
            "Conversions": "120",
            "Conversion Value": "$27,779",
            "Cost per Result": "$15.60"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "611720",
            "Clicks": "5592",
            "Conversions": "86",
            "Conversion Value": "$20,934",
            "Cost per Result": "$11.08"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "444435",
            "Clicks": "11312",
            "Conversions": "98",
            "Conversion Value": "$30,684",
            "Cost per Result": "$31.49"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "601498",
            "Clicks": "8344",
            "Conversions": "178",
            "Conversion Value": "$14,119",
            "Cost per Result": "$31.50"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "615693",
            "Clicks": "5486",
            "Conversions": "113",
            "Conversion Value": "$9,634",
            "Cost per Result": "$20.74"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "527591",
            "Clicks": "8874",
            "Conversions": "175",
            "Conversion Value": "$19,101",
            "Cost per Result": "$13.32"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "593005",
            "Clicks": "4061",
            "Conversions": "81",
            "Conversion Value": "$14,795",
            "Cost per Result": "$24.83"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "450992",
            "Clicks": "9845",
            "Conversions": "128",
            "Conversion Value": "$30,551",
            "Cost per Result": "$39.26"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "298028",
            "Clicks": "6678",
            "Conversions": "164",
            "Conversion Value": "$12,729",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "687266",
            "Clicks": "6757",
            "Conversions": "156",
            "Conversion Value": "$17,508",
            "Cost per Result": "$20.02"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "530017",
            "Clicks": "10005",
            "Conversions": "123",
            "Conversion Value": "$12,868",
            "Cost per Result": "$38.88"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "354377",
            "Clicks": "12140",
            "Conversions": "112",
            "Conversion Value": "$9,894",
            "Cost per Result": "$18.18"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "796841",
            "Clicks": "6278",
            "Conversions": "127",
            "Conversion Value": "$10,010",
            "Cost per Result": "$11.75"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "549090",
            "Clicks": "10433",
            "Conversions": "163",
            "Conversion Value": "$25,573",
            "Cost per Result": "$27.15"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "263222",
            "Clicks": "13626",
            "Conversions": "128",
            "Conversion Value": "$11,128",
            "Cost per Result": "$22.76"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "282249",
            "Clicks": "11634",
            "Conversions": "92",
            "Conversion Value": "$29,677",
            "Cost per Result": "$36.63"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "711304",
            "Clicks": "10230",
            "Conversions": "180",
            "Conversion Value": "$23,192",
            "Cost per Result": "$39.31"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "250604",
            "Clicks": "4636",
            "Conversions": "134",
            "Conversion Value": "$28,523",
            "Cost per Result": "$15.15"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "242340",
            "Clicks": "10530",
            "Conversions": "164",
            "Conversion Value": "$19,064",
            "Cost per Result": "$14.95"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "286012",
            "Clicks": "10354",
            "Conversions": "115",
            "Conversion Value": "$21,493",
            "Cost per Result": "$18.77"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "773258",
            "Clicks": "11927",
            "Conversions": "159",
            "Conversion Value": "$15,593",
            "Cost per Result": "$28.58"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "293337",
            "Clicks": "6000",
            "Conversions": "130",
            "Conversion Value": "$19,163",
            "Cost per Result": "$20.05"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "895671",
            "Clicks": "8658",
            "Conversions": "172",
            "Conversion Value": "$25,786",
            "Cost per Result": "$36.73"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "720032",
            "Clicks": "4669",
            "Conversions": "84",
            "Conversion Value": "$26,408",
            "Cost per Result": "$31.59"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "227888",
            "Clicks": "13539",
            "Conversions": "80",
            "Conversion Value": "$21,882",
            "Cost per Result": "$15.43"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "809614",
            "Clicks": "13616",
            "Conversions": "144",
            "Conversion Value": "$8,420",
            "Cost per Result": "$23.22"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "225090",
            "Clicks": "13401",
            "Conversions": "139",
            "Conversion Value": "$10,004",
            "Cost per Result": "$25.69"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "856990",
            "Clicks": "13774",
            "Conversions": "133",
            "Conversion Value": "$28,893",
            "Cost per Result": "$17.29"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "704401",
            "Clicks": "4865",
            "Conversions": "126",
            "Conversion Value": "$18,118",
            "Cost per Result": "$16.84"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "847240",
            "Clicks": "6067",
            "Conversions": "134",
            "Conversion Value": "$10,053",
            "Cost per Result": "$14.27"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "637680",
            "Clicks": "10278",
            "Conversions": "174",
            "Conversion Value": "$26,668",
            "Cost per Result": "$20.96"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "324882",
            "Clicks": "4502",
            "Conversions": "130",
            "Conversion Value": "$26,930",
            "Cost per Result": "$14.22"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "975623",
            "Clicks": "10459",
            "Conversions": "150",
            "Conversion Value": "$21,689",
            "Cost per Result": "$28.06"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "674333",
            "Clicks": "4899",
            "Conversions": "116",
            "Conversion Value": "$19,844",
            "Cost per Result": "$22.03"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "464888",
            "Clicks": "9608",
            "Conversions": "88",
            "Conversion Value": "$16,329",
            "Cost per Result": "$24.04"
          }
        ]
      },
      "CryptoTradersData": {
        "name": "Crypto Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "622133",
            "Clicks": "5057",
            "Conversions": "151",
            "Conversion Value": "$16.61",
            "Cost per Result": "$28.49"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "488789",
            "Clicks": "12748",
            "Conversions": "116",
            "Conversion Value": "$14.23",
            "Cost per Result": "$26.69"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "204737",
            "Clicks": "7164",
            "Conversions": "131",
            "Conversion Value": "$22.01",
            "Cost per Result": "$42.04"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "805244",
            "Clicks": "13273",
            "Conversions": "162",
            "Conversion Value": "$21.67",
            "Cost per Result": "$24.31"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "881828",
            "Clicks": "8562",
            "Conversions": "87",
            "Conversion Value": "$29.75",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "924946",
            "Clicks": "5091",
            "Conversions": "169",
            "Conversion Value": "$30.85",
            "Cost per Result": "$18.56"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "368459",
            "Clicks": "11413",
            "Conversions": "108",
            "Conversion Value": "$23.86",
            "Cost per Result": "$31.77"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "267182",
            "Clicks": "7391",
            "Conversions": "166",
            "Conversion Value": "$28.54",
            "Cost per Result": "$35.38"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "848172",
            "Clicks": "12614",
            "Conversions": "159",
            "Conversion Value": "$29.38",
            "Cost per Result": "$40.25"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "316485",
            "Clicks": "12806",
            "Conversions": "159",
            "Conversion Value": "$29.41",
            "Cost per Result": "$42.16"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "987969",
            "Clicks": "6800",
            "Conversions": "121",
            "Conversion Value": "$29.89",
            "Cost per Result": "$19.34"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "778854",
            "Clicks": "7846",
            "Conversions": "96",
            "Conversion Value": "$29.34",
            "Cost per Result": "$43.80"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "524775",
            "Clicks": "7365",
            "Conversions": "176",
            "Conversion Value": "$34.02",
            "Cost per Result": "$43.96"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "284933",
            "Clicks": "8670",
            "Conversions": "151",
            "Conversion Value": "$13.19",
            "Cost per Result": "$43.34"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "679445",
            "Clicks": "13030",
            "Conversions": "82",
            "Conversion Value": "$35.40",
            "Cost per Result": "$43.93"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "293507",
            "Clicks": "11038",
            "Conversions": "82",
            "Conversion Value": "$10.86",
            "Cost per Result": "$15.16"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "768624",
            "Clicks": "9343",
            "Conversions": "103",
            "Conversion Value": "$36.09",
            "Cost per Result": "$24.99"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "256249",
            "Clicks": "8932",
            "Conversions": "113",
            "Conversion Value": "$39.58",
            "Cost per Result": "$37.64"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "260181",
            "Clicks": "6541",
            "Conversions": "89",
            "Conversion Value": "$13.42",
            "Cost per Result": "$15.28"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "261380",
            "Clicks": "6519",
            "Conversions": "151",
            "Conversion Value": "$35.42",
            "Cost per Result": "$15.58"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "270906",
            "Clicks": "12551",
            "Conversions": "85",
            "Conversion Value": "$31.79",
            "Cost per Result": "$32.51"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "502260",
            "Clicks": "8185",
            "Conversions": "135",
            "Conversion Value": "$22.62",
            "Cost per Result": "$21.67"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "914676",
            "Clicks": "5760",
            "Conversions": "177",
            "Conversion Value": "$30.58",
            "Cost per Result": "$40.80"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "202272",
            "Clicks": "5847",
            "Conversions": "101",
            "Conversion Value": "$27.66",
            "Cost per Result": "$37.82"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "216648",
            "Clicks": "10865",
            "Conversions": "167",
            "Conversion Value": "$11.01",
            "Cost per Result": "$34.69"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "417154",
            "Clicks": "8370",
            "Conversions": "109",
            "Conversion Value": "$21.17",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "618299",
            "Clicks": "9709",
            "Conversions": "155",
            "Conversion Value": "$16.79",
            "Cost per Result": "$19.52"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "740493",
            "Clicks": "5924",
            "Conversions": "179",
            "Conversion Value": "$37.18",
            "Cost per Result": "$15.95"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "375428",
            "Clicks": "11059",
            "Conversions": "165",
            "Conversion Value": "$17.48",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "629962",
            "Clicks": "10669",
            "Conversions": "178",
            "Conversion Value": "$34.11",
            "Cost per Result": "$35.48"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "625903",
            "Clicks": "9907",
            "Conversions": "153",
            "Conversion Value": "$20.45",
            "Cost per Result": "$44.20"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "886299",
            "Clicks": "12210",
            "Conversions": "109",
            "Conversion Value": "$28.69",
            "Cost per Result": "$30.87"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "466856",
            "Clicks": "10507",
            "Conversions": "167",
            "Conversion Value": "$34.55",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "751554",
            "Clicks": "11420",
            "Conversions": "143",
            "Conversion Value": "$25.32",
            "Cost per Result": "$44.40"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "386612",
            "Clicks": "13516",
            "Conversions": "116",
            "Conversion Value": "$10.66",
            "Cost per Result": "$43.53"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "708061",
            "Clicks": "4029",
            "Conversions": "178",
            "Conversion Value": "$11.87",
            "Cost per Result": "$18.41"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "660894",
            "Clicks": "7424",
            "Conversions": "156",
            "Conversion Value": "$25.58",
            "Cost per Result": "$39.31"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "277392",
            "Clicks": "7041",
            "Conversions": "180",
            "Conversion Value": "$26.66",
            "Cost per Result": "$32.21"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "924962",
            "Clicks": "7746",
            "Conversions": "91",
            "Conversion Value": "$11.55",
            "Cost per Result": "$41.66"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "424615",
            "Clicks": "7621",
            "Conversions": "85",
            "Conversion Value": "$11.63",
            "Cost per Result": "$32.24"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "214506",
            "Clicks": "4360",
            "Conversions": "85",
            "Conversion Value": "$37.74",
            "Cost per Result": "$42.85"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "814905",
            "Clicks": "8370",
            "Conversions": "84",
            "Conversion Value": "$17.61",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "732460",
            "Clicks": "11703",
            "Conversions": "178",
            "Conversion Value": "$28.29",
            "Cost per Result": "$39.94"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "557335",
            "Clicks": "12520",
            "Conversions": "116",
            "Conversion Value": "$38.92",
            "Cost per Result": "$44.59"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "312540",
            "Clicks": "7589",
            "Conversions": "128",
            "Conversion Value": "$35.17",
            "Cost per Result": "$32.90"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "576996",
            "Clicks": "11869",
            "Conversions": "160",
            "Conversion Value": "$35.38",
            "Cost per Result": "$25.12"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "942535",
            "Clicks": "12047",
            "Conversions": "134",
            "Conversion Value": "$11.71",
            "Cost per Result": "$17.71"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "716682",
            "Clicks": "13427",
            "Conversions": "118",
            "Conversion Value": "$30.72",
            "Cost per Result": "$40.36"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "572135",
            "Clicks": "6012",
            "Conversions": "108",
            "Conversion Value": "$22.52",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "580906",
            "Clicks": "13678",
            "Conversions": "170",
            "Conversion Value": "$18.86",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "415752",
            "Clicks": "13117",
            "Conversions": "108",
            "Conversion Value": "$37.82",
            "Cost per Result": "$23.89"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "458113",
            "Clicks": "12798",
            "Conversions": "88",
            "Conversion Value": "$36.27",
            "Cost per Result": "$22.95"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "786365",
            "Clicks": "13267",
            "Conversions": "136",
            "Conversion Value": "$21.01",
            "Cost per Result": "$27.46"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "614224",
            "Clicks": "12639",
            "Conversions": "115",
            "Conversion Value": "$14.53",
            "Cost per Result": "$39.16"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "341089",
            "Clicks": "10479",
            "Conversions": "116",
            "Conversion Value": "$10.05",
            "Cost per Result": "$39.40"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "427395",
            "Clicks": "9036",
            "Conversions": "129",
            "Conversion Value": "$24.37",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "324239",
            "Clicks": "5810",
            "Conversions": "116",
            "Conversion Value": "$18.46",
            "Cost per Result": "$21.27"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "577590",
            "Clicks": "10093",
            "Conversions": "120",
            "Conversion Value": "$11.24",
            "Cost per Result": "$37.86"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "862956",
            "Clicks": "9274",
            "Conversions": "164",
            "Conversion Value": "$35.87",
            "Cost per Result": "$42.83"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "925508",
            "Clicks": "11427",
            "Conversions": "103",
            "Conversion Value": "$24.86",
            "Cost per Result": "$26.72"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "770572",
            "Clicks": "7961",
            "Conversions": "87",
            "Conversion Value": "$30.62",
            "Cost per Result": "$20.00"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "886203",
            "Clicks": "7522",
            "Conversions": "168",
            "Conversion Value": "$14.78",
            "Cost per Result": "$25.46"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "672567",
            "Clicks": "5191",
            "Conversions": "165",
            "Conversion Value": "$14.99",
            "Cost per Result": "$16.44"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "458521",
            "Clicks": "5676",
            "Conversions": "139",
            "Conversion Value": "$34.12",
            "Cost per Result": "$29.25"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "480647",
            "Clicks": "12065",
            "Conversions": "98",
            "Conversion Value": "$34.44",
            "Cost per Result": "$38.36"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "609977",
            "Clicks": "7905",
            "Conversions": "112",
            "Conversion Value": "$11.72",
            "Cost per Result": "$19.42"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "456163",
            "Clicks": "6388",
            "Conversions": "115",
            "Conversion Value": "$15.30",
            "Cost per Result": "$39.12"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "797935",
            "Clicks": "13338",
            "Conversions": "139",
            "Conversion Value": "$12.11",
            "Cost per Result": "$39.71"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "341021",
            "Clicks": "8312",
            "Conversions": "174",
            "Conversion Value": "$24.50",
            "Cost per Result": "$19.12"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "754787",
            "Clicks": "13042",
            "Conversions": "171",
            "Conversion Value": "$15.04",
            "Cost per Result": "$35.61"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "971494",
            "Clicks": "9300",
            "Conversions": "176",
            "Conversion Value": "$31.80",
            "Cost per Result": "$24.23"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "362160",
            "Clicks": "4249",
            "Conversions": "91",
            "Conversion Value": "$14.93",
            "Cost per Result": "$39.23"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "569189",
            "Clicks": "4049",
            "Conversions": "180",
            "Conversion Value": "$31.18",
            "Cost per Result": "$43.84"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "534937",
            "Clicks": "7033",
            "Conversions": "94",
            "Conversion Value": "$31.99",
            "Cost per Result": "$34.84"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "327881",
            "Clicks": "9883",
            "Conversions": "157",
            "Conversion Value": "$37.47",
            "Cost per Result": "$17.09"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "339967",
            "Clicks": "13615",
            "Conversions": "112",
            "Conversion Value": "$33.38",
            "Cost per Result": "$26.59"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "451279",
            "Clicks": "6861",
            "Conversions": "101",
            "Conversion Value": "$34.35",
            "Cost per Result": "$32.50"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "259630",
            "Clicks": "5621",
            "Conversions": "158",
            "Conversion Value": "$17.23",
            "Cost per Result": "$23.40"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "774097",
            "Clicks": "4556",
            "Conversions": "104",
            "Conversion Value": "$18.86",
            "Cost per Result": "$44.69"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "311881",
            "Clicks": "4382",
            "Conversions": "136",
            "Conversion Value": "$34.32",
            "Cost per Result": "$38.33"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "508302",
            "Clicks": "9204",
            "Conversions": "85",
            "Conversion Value": "$38.03",
            "Cost per Result": "$31.90"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "353868",
            "Clicks": "12135",
            "Conversions": "113",
            "Conversion Value": "$11.72",
            "Cost per Result": "$29.54"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "952155",
            "Clicks": "11902",
            "Conversions": "95",
            "Conversion Value": "$16.52",
            "Cost per Result": "$33.67"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "585659",
            "Clicks": "11183",
            "Conversions": "131",
            "Conversion Value": "$27.40",
            "Cost per Result": "$41.42"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "897936",
            "Clicks": "4898",
            "Conversions": "114",
            "Conversion Value": "$31.09",
            "Cost per Result": "$17.70"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "856882",
            "Clicks": "13433",
            "Conversions": "136",
            "Conversion Value": "$29.25",
            "Cost per Result": "$30.40"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "754091",
            "Clicks": "6943",
            "Conversions": "136",
            "Conversion Value": "$19.07",
            "Cost per Result": "$28.28"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "247148",
            "Clicks": "4212",
            "Conversions": "111",
            "Conversion Value": "$15.12",
            "Cost per Result": "$17.00"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "500194",
            "Clicks": "9409",
            "Conversions": "167",
            "Conversion Value": "$34.84",
            "Cost per Result": "$19.43"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "288822",
            "Clicks": "13431",
            "Conversions": "114",
            "Conversion Value": "$29.77",
            "Cost per Result": "$31.85"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "222647",
            "Clicks": "4810",
            "Conversions": "151",
            "Conversion Value": "$31.38",
            "Cost per Result": "$19.23"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "637975",
            "Clicks": "10224",
            "Conversions": "151",
            "Conversion Value": "$39.66",
            "Cost per Result": "$25.74"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "901767",
            "Clicks": "6515",
            "Conversions": "109",
            "Conversion Value": "$12.73",
            "Cost per Result": "$29.83"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "649145",
            "Clicks": "7505",
            "Conversions": "166",
            "Conversion Value": "$19.59",
            "Cost per Result": "$17.98"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "965721",
            "Clicks": "9463",
            "Conversions": "119",
            "Conversion Value": "$10.34",
            "Cost per Result": "$17.45"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "806662",
            "Clicks": "13005",
            "Conversions": "93",
            "Conversion Value": "$14.63",
            "Cost per Result": "$29.15"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "385183",
            "Clicks": "13716",
            "Conversions": "84",
            "Conversion Value": "$37.42",
            "Cost per Result": "$40.74"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "907706",
            "Clicks": "8459",
            "Conversions": "108",
            "Conversion Value": "$24.67",
            "Cost per Result": "$37.12"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "708322",
            "Clicks": "6213",
            "Conversions": "95",
            "Conversion Value": "$37.71",
            "Cost per Result": "$43.07"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "439951",
            "Clicks": "7632",
            "Conversions": "140",
            "Conversion Value": "$24.65",
            "Cost per Result": "$35.23"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "245411",
            "Clicks": "7486",
            "Conversions": "169",
            "Conversion Value": "$18.90",
            "Cost per Result": "$27.15"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "672327",
            "Clicks": "13533",
            "Conversions": "174",
            "Conversion Value": "$23.84",
            "Cost per Result": "$27.11"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "884721",
            "Clicks": "13878",
            "Conversions": "171",
            "Conversion Value": "$16.84",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "466238",
            "Clicks": "11152",
            "Conversions": "101",
            "Conversion Value": "$29.79",
            "Cost per Result": "$42.36"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "897854",
            "Clicks": "7418",
            "Conversions": "114",
            "Conversion Value": "$22.39",
            "Cost per Result": "$28.62"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "364174",
            "Clicks": "12432",
            "Conversions": "134",
            "Conversion Value": "$34.25",
            "Cost per Result": "$15.00"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "377324",
            "Clicks": "13474",
            "Conversions": "139",
            "Conversion Value": "$20.66",
            "Cost per Result": "$24.86"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "733204",
            "Clicks": "7329",
            "Conversions": "125",
            "Conversion Value": "$25.95",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "708159",
            "Clicks": "5126",
            "Conversions": "174",
            "Conversion Value": "$26.87",
            "Cost per Result": "$33.19"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "604988",
            "Clicks": "8335",
            "Conversions": "96",
            "Conversion Value": "$21.41",
            "Cost per Result": "$33.65"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "837788",
            "Clicks": "5652",
            "Conversions": "97",
            "Conversion Value": "$39.97",
            "Cost per Result": "$43.40"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "296861",
            "Clicks": "4640",
            "Conversions": "168",
            "Conversion Value": "$36.08",
            "Cost per Result": "$41.53"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "665633",
            "Clicks": "9858",
            "Conversions": "109",
            "Conversion Value": "$24.93",
            "Cost per Result": "$44.03"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "417852",
            "Clicks": "7396",
            "Conversions": "152",
            "Conversion Value": "$20.85",
            "Cost per Result": "$41.95"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "479707",
            "Clicks": "8269",
            "Conversions": "120",
            "Conversion Value": "$15.72",
            "Cost per Result": "$31.51"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "919425",
            "Clicks": "4770",
            "Conversions": "180",
            "Conversion Value": "$20.12",
            "Cost per Result": "$40.40"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "538475",
            "Clicks": "7751",
            "Conversions": "180",
            "Conversion Value": "$21.16",
            "Cost per Result": "$41.34"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "865834",
            "Clicks": "7400",
            "Conversions": "147",
            "Conversion Value": "$17.95",
            "Cost per Result": "$15.30"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "611212",
            "Clicks": "8861",
            "Conversions": "180",
            "Conversion Value": "$35.54",
            "Cost per Result": "$36.63"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "758521",
            "Clicks": "13547",
            "Conversions": "127",
            "Conversion Value": "$14.98",
            "Cost per Result": "$33.47"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "590431",
            "Clicks": "5816",
            "Conversions": "161",
            "Conversion Value": "$30.15",
            "Cost per Result": "$34.07"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "300500",
            "Clicks": "12906",
            "Conversions": "150",
            "Conversion Value": "$36.67",
            "Cost per Result": "$28.26"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "479621",
            "Clicks": "4169",
            "Conversions": "167",
            "Conversion Value": "$33.21",
            "Cost per Result": "$26.06"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "540470",
            "Clicks": "7449",
            "Conversions": "166",
            "Conversion Value": "$38.35",
            "Cost per Result": "$36.07"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "504909",
            "Clicks": "4870",
            "Conversions": "147",
            "Conversion Value": "$26.41",
            "Cost per Result": "$15.58"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "264961",
            "Clicks": "12473",
            "Conversions": "155",
            "Conversion Value": "$26.54",
            "Cost per Result": "$33.24"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "829281",
            "Clicks": "10316",
            "Conversions": "128",
            "Conversion Value": "$21.22",
            "Cost per Result": "$27.11"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "745466",
            "Clicks": "6633",
            "Conversions": "167",
            "Conversion Value": "$32.54",
            "Cost per Result": "$27.56"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "253962",
            "Clicks": "7917",
            "Conversions": "110",
            "Conversion Value": "$36.68",
            "Cost per Result": "$15.70"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "893751",
            "Clicks": "13381",
            "Conversions": "148",
            "Conversion Value": "$21.02",
            "Cost per Result": "$16.93"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "527585",
            "Clicks": "12752",
            "Conversions": "137",
            "Conversion Value": "$19.07",
            "Cost per Result": "$42.66"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "692688",
            "Clicks": "9730",
            "Conversions": "150",
            "Conversion Value": "$16.89",
            "Cost per Result": "$16.35"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "265987",
            "Clicks": "11748",
            "Conversions": "168",
            "Conversion Value": "$20.58",
            "Cost per Result": "$18.45"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "974498",
            "Clicks": "10619",
            "Conversions": "117",
            "Conversion Value": "$38.34",
            "Cost per Result": "$19.12"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "893080",
            "Clicks": "4677",
            "Conversions": "95",
            "Conversion Value": "$15.79",
            "Cost per Result": "$43.48"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "498504",
            "Clicks": "6619",
            "Conversions": "149",
            "Conversion Value": "$18.83",
            "Cost per Result": "$15.07"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "787369",
            "Clicks": "9887",
            "Conversions": "128",
            "Conversion Value": "$18.24",
            "Cost per Result": "$29.95"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "284043",
            "Clicks": "9870",
            "Conversions": "129",
            "Conversion Value": "$28.85",
            "Cost per Result": "$28.58"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "963481",
            "Clicks": "6272",
            "Conversions": "146",
            "Conversion Value": "$22.33",
            "Cost per Result": "$29.48"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "291091",
            "Clicks": "12822",
            "Conversions": "158",
            "Conversion Value": "$35.10",
            "Cost per Result": "$29.25"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "799724",
            "Clicks": "4469",
            "Conversions": "142",
            "Conversion Value": "$13.28",
            "Cost per Result": "$24.48"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "784238",
            "Clicks": "8724",
            "Conversions": "170",
            "Conversion Value": "$20.64",
            "Cost per Result": "$15.91"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "270323",
            "Clicks": "4799",
            "Conversions": "107",
            "Conversion Value": "$29.22",
            "Cost per Result": "$15.99"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "599961",
            "Clicks": "13194",
            "Conversions": "104",
            "Conversion Value": "$16.12",
            "Cost per Result": "$37.41"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "845783",
            "Clicks": "13077",
            "Conversions": "160",
            "Conversion Value": "$26.71",
            "Cost per Result": "$28.86"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "360387",
            "Clicks": "6392",
            "Conversions": "137",
            "Conversion Value": "$17.21",
            "Cost per Result": "$24.49"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "540874",
            "Clicks": "7375",
            "Conversions": "156",
            "Conversion Value": "$26.90",
            "Cost per Result": "$18.70"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "699014",
            "Clicks": "13221",
            "Conversions": "121",
            "Conversion Value": "$17.16",
            "Cost per Result": "$26.22"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "833384",
            "Clicks": "4139",
            "Conversions": "179",
            "Conversion Value": "$11.24",
            "Cost per Result": "$35.68"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "807926",
            "Clicks": "9860",
            "Conversions": "158",
            "Conversion Value": "$18.01",
            "Cost per Result": "$38.15"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "526836",
            "Clicks": "4072",
            "Conversions": "147",
            "Conversion Value": "$29.98",
            "Cost per Result": "$22.07"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "666936",
            "Clicks": "13337",
            "Conversions": "149",
            "Conversion Value": "$20.23",
            "Cost per Result": "$20.64"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "528436",
            "Clicks": "11119",
            "Conversions": "81",
            "Conversion Value": "$27.37",
            "Cost per Result": "$15.11"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "320559",
            "Clicks": "13566",
            "Conversions": "117",
            "Conversion Value": "$34.38",
            "Cost per Result": "$27.33"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "863306",
            "Clicks": "8880",
            "Conversions": "169",
            "Conversion Value": "$23.01",
            "Cost per Result": "$30.12"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "438868",
            "Clicks": "5435",
            "Conversions": "147",
            "Conversion Value": "$21.43",
            "Cost per Result": "$16.51"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "214110",
            "Clicks": "11340",
            "Conversions": "134",
            "Conversion Value": "$20.54",
            "Cost per Result": "$42.09"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "205792",
            "Clicks": "10474",
            "Conversions": "162",
            "Conversion Value": "$19.61",
            "Cost per Result": "$44.77"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "890306",
            "Clicks": "10018",
            "Conversions": "108",
            "Conversion Value": "$33.85",
            "Cost per Result": "$16.61"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "758470",
            "Clicks": "11028",
            "Conversions": "176",
            "Conversion Value": "$17.92",
            "Cost per Result": "$21.72"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "284962",
            "Clicks": "10107",
            "Conversions": "148",
            "Conversion Value": "$25.05",
            "Cost per Result": "$41.53"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "351387",
            "Clicks": "4714",
            "Conversions": "89",
            "Conversion Value": "$12.99",
            "Cost per Result": "$26.49"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "723171",
            "Clicks": "9124",
            "Conversions": "151",
            "Conversion Value": "$31.16",
            "Cost per Result": "$22.74"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "299630",
            "Clicks": "7316",
            "Conversions": "109",
            "Conversion Value": "$28.37",
            "Cost per Result": "$20.80"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "961042",
            "Clicks": "4502",
            "Conversions": "107",
            "Conversion Value": "$32.99",
            "Cost per Result": "$38.87"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "888979",
            "Clicks": "9836",
            "Conversions": "132",
            "Conversion Value": "$13.18",
            "Cost per Result": "$15.59"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "336938",
            "Clicks": "10891",
            "Conversions": "104",
            "Conversion Value": "$15.52",
            "Cost per Result": "$25.22"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "712255",
            "Clicks": "10909",
            "Conversions": "120",
            "Conversion Value": "$20.22",
            "Cost per Result": "$38.79"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "448496",
            "Clicks": "4177",
            "Conversions": "110",
            "Conversion Value": "$26.74",
            "Cost per Result": "$15.21"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "548719",
            "Clicks": "12154",
            "Conversions": "179",
            "Conversion Value": "$26.92",
            "Cost per Result": "$22.54"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "233806",
            "Clicks": "10896",
            "Conversions": "91",
            "Conversion Value": "$38.66",
            "Cost per Result": "$35.92"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "956686",
            "Clicks": "8817",
            "Conversions": "142",
            "Conversion Value": "$35.92",
            "Cost per Result": "$30.71"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "939559",
            "Clicks": "8298",
            "Conversions": "157",
            "Conversion Value": "$10.95",
            "Cost per Result": "$18.23"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "571467",
            "Clicks": "5703",
            "Conversions": "100",
            "Conversion Value": "$23.91",
            "Cost per Result": "$39.28"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "991642",
            "Clicks": "9559",
            "Conversions": "180",
            "Conversion Value": "$12.12",
            "Cost per Result": "$15.56"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "826514",
            "Clicks": "12966",
            "Conversions": "111",
            "Conversion Value": "$18.88",
            "Cost per Result": "$15.77"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "836212",
            "Clicks": "8878",
            "Conversions": "122",
            "Conversion Value": "$24.31",
            "Cost per Result": "$38.85"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "342725",
            "Clicks": "13834",
            "Conversions": "148",
            "Conversion Value": "$18.53",
            "Cost per Result": "$32.77"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "445417",
            "Clicks": "4438",
            "Conversions": "160",
            "Conversion Value": "$26.77",
            "Cost per Result": "$39.27"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "219641",
            "Clicks": "7493",
            "Conversions": "81",
            "Conversion Value": "$18.49",
            "Cost per Result": "$37.93"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "898264",
            "Clicks": "10702",
            "Conversions": "121",
            "Conversion Value": "$13.17",
            "Cost per Result": "$42.37"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "735149",
            "Clicks": "12632",
            "Conversions": "99",
            "Conversion Value": "$33.67",
            "Cost per Result": "$28.81"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "632354",
            "Clicks": "11398",
            "Conversions": "85",
            "Conversion Value": "$15.16",
            "Cost per Result": "$19.99"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "302664",
            "Clicks": "7808",
            "Conversions": "144",
            "Conversion Value": "$11.29",
            "Cost per Result": "$18.90"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "857910",
            "Clicks": "7206",
            "Conversions": "92",
            "Conversion Value": "$38.17",
            "Cost per Result": "$18.62"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "694137",
            "Clicks": "13058",
            "Conversions": "105",
            "Conversion Value": "$20.67",
            "Cost per Result": "$37.37"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "335472",
            "Clicks": "7563",
            "Conversions": "118",
            "Conversion Value": "$33.50",
            "Cost per Result": "$38.02"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "423752",
            "Clicks": "6578",
            "Conversions": "88",
            "Conversion Value": "$33.32",
            "Cost per Result": "$24.38"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "209340",
            "Clicks": "9849",
            "Conversions": "132",
            "Conversion Value": "$10.75",
            "Cost per Result": "$30.90"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "273650",
            "Clicks": "11891",
            "Conversions": "105",
            "Conversion Value": "$13.75",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "689149",
            "Clicks": "5479",
            "Conversions": "106",
            "Conversion Value": "$34.95",
            "Cost per Result": "$39.72"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "464404",
            "Clicks": "4610",
            "Conversions": "146",
            "Conversion Value": "$36.06",
            "Cost per Result": "$30.24"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "773293",
            "Clicks": "5696",
            "Conversions": "130",
            "Conversion Value": "$30.09",
            "Cost per Result": "$36.52"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "327068",
            "Clicks": "6456",
            "Conversions": "179",
            "Conversion Value": "$15.27",
            "Cost per Result": "$33.18"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "464178",
            "Clicks": "8344",
            "Conversions": "116",
            "Conversion Value": "$24.96",
            "Cost per Result": "$27.98"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "680571",
            "Clicks": "13935",
            "Conversions": "153",
            "Conversion Value": "$15.28",
            "Cost per Result": "$22.27"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "852898",
            "Clicks": "4327",
            "Conversions": "168",
            "Conversion Value": "$30.99",
            "Cost per Result": "$43.96"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "980550",
            "Clicks": "4420",
            "Conversions": "176",
            "Conversion Value": "$11.69",
            "Cost per Result": "$27.05"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "328750",
            "Clicks": "10802",
            "Conversions": "143",
            "Conversion Value": "$38.96",
            "Cost per Result": "$18.46"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "768834",
            "Clicks": "6289",
            "Conversions": "119",
            "Conversion Value": "$19.29",
            "Cost per Result": "$40.84"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "938546",
            "Clicks": "10443",
            "Conversions": "88",
            "Conversion Value": "$11.47",
            "Cost per Result": "$21.26"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "664026",
            "Clicks": "7920",
            "Conversions": "176",
            "Conversion Value": "$17.93",
            "Cost per Result": "$39.55"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "385674",
            "Clicks": "8442",
            "Conversions": "148",
            "Conversion Value": "$15.55",
            "Cost per Result": "$31.84"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "903451",
            "Clicks": "12721",
            "Conversions": "149",
            "Conversion Value": "$39.03",
            "Cost per Result": "$30.40"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "985939",
            "Clicks": "13035",
            "Conversions": "93",
            "Conversion Value": "$13.20",
            "Cost per Result": "$19.33"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "847592",
            "Clicks": "10787",
            "Conversions": "145",
            "Conversion Value": "$30.25",
            "Cost per Result": "$43.66"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "215053",
            "Clicks": "11808",
            "Conversions": "97",
            "Conversion Value": "$13.20",
            "Cost per Result": "$31.14"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "625251",
            "Clicks": "8984",
            "Conversions": "174",
            "Conversion Value": "$19.12",
            "Cost per Result": "$23.67"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "882847",
            "Clicks": "5448",
            "Conversions": "121",
            "Conversion Value": "$20.02",
            "Cost per Result": "$20.73"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "728744",
            "Clicks": "8675",
            "Conversions": "83",
            "Conversion Value": "$25.19",
            "Cost per Result": "$28.23"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "305558",
            "Clicks": "7526",
            "Conversions": "173",
            "Conversion Value": "$14.65",
            "Cost per Result": "$35.87"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "723531",
            "Clicks": "7084",
            "Conversions": "113",
            "Conversion Value": "$34.56",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "436571",
            "Clicks": "13218",
            "Conversions": "174",
            "Conversion Value": "$29.15",
            "Cost per Result": "$29.93"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "938547",
            "Clicks": "4559",
            "Conversions": "120",
            "Conversion Value": "$14.08",
            "Cost per Result": "$31.61"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "536318",
            "Clicks": "9977",
            "Conversions": "109",
            "Conversion Value": "$13.15",
            "Cost per Result": "$30.38"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "237225",
            "Clicks": "5414",
            "Conversions": "96",
            "Conversion Value": "$23.24",
            "Cost per Result": "$33.91"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "255434",
            "Clicks": "8155",
            "Conversions": "97",
            "Conversion Value": "$36.04",
            "Cost per Result": "$15.27"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "282472",
            "Clicks": "6213",
            "Conversions": "175",
            "Conversion Value": "$38.94",
            "Cost per Result": "$26.91"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "875385",
            "Clicks": "11833",
            "Conversions": "123",
            "Conversion Value": "$15.20",
            "Cost per Result": "$43.87"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "611821",
            "Clicks": "8697",
            "Conversions": "144",
            "Conversion Value": "$19.95",
            "Cost per Result": "$25.60"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "664094",
            "Clicks": "7864",
            "Conversions": "150",
            "Conversion Value": "$27.24",
            "Cost per Result": "$34.83"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "604330",
            "Clicks": "8085",
            "Conversions": "169",
            "Conversion Value": "$27.38",
            "Cost per Result": "$43.96"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "489618",
            "Clicks": "10725",
            "Conversions": "143",
            "Conversion Value": "$18.16",
            "Cost per Result": "$31.36"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "283603",
            "Clicks": "4184",
            "Conversions": "114",
            "Conversion Value": "$22.14",
            "Cost per Result": "$31.69"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "683857",
            "Clicks": "9580",
            "Conversions": "138",
            "Conversion Value": "$36.61",
            "Cost per Result": "$19.40"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "859327",
            "Clicks": "7257",
            "Conversions": "105",
            "Conversion Value": "$29.78",
            "Cost per Result": "$23.24"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "814738",
            "Clicks": "12351",
            "Conversions": "145",
            "Conversion Value": "$36.90",
            "Cost per Result": "$22.41"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "723017",
            "Clicks": "12467",
            "Conversions": "151",
            "Conversion Value": "$32.52",
            "Cost per Result": "$27.41"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "325562",
            "Clicks": "6034",
            "Conversions": "131",
            "Conversion Value": "$38.12",
            "Cost per Result": "$25.27"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "804997",
            "Clicks": "12075",
            "Conversions": "89",
            "Conversion Value": "$22.79",
            "Cost per Result": "$33.17"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "213208",
            "Clicks": "7374",
            "Conversions": "128",
            "Conversion Value": "$10.95",
            "Cost per Result": "$32.89"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "454040",
            "Clicks": "9595",
            "Conversions": "149",
            "Conversion Value": "$37.83",
            "Cost per Result": "$19.99"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "584032",
            "Clicks": "8898",
            "Conversions": "162",
            "Conversion Value": "$33.57",
            "Cost per Result": "$26.62"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "966345",
            "Clicks": "12466",
            "Conversions": "153",
            "Conversion Value": "$38.68",
            "Cost per Result": "$23.96"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "251530",
            "Clicks": "10646",
            "Conversions": "145",
            "Conversion Value": "$34.27",
            "Cost per Result": "$29.15"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "376667",
            "Clicks": "12231",
            "Conversions": "110",
            "Conversion Value": "$26.99",
            "Cost per Result": "$22.24"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "589489",
            "Clicks": "12303",
            "Conversions": "137",
            "Conversion Value": "$15.95",
            "Cost per Result": "$34.01"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "304258",
            "Clicks": "12971",
            "Conversions": "137",
            "Conversion Value": "$19.94",
            "Cost per Result": "$29.31"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "757401",
            "Clicks": "6460",
            "Conversions": "90",
            "Conversion Value": "$21.24",
            "Cost per Result": "$30.17"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "254710",
            "Clicks": "9054",
            "Conversions": "163",
            "Conversion Value": "$35.60",
            "Cost per Result": "$17.75"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "762730",
            "Clicks": "4888",
            "Conversions": "175",
            "Conversion Value": "$38.72",
            "Cost per Result": "$19.75"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "313857",
            "Clicks": "13069",
            "Conversions": "97",
            "Conversion Value": "$23.65",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "271130",
            "Clicks": "12834",
            "Conversions": "149",
            "Conversion Value": "$27.45",
            "Cost per Result": "$43.21"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "491325",
            "Clicks": "9760",
            "Conversions": "100",
            "Conversion Value": "$17.06",
            "Cost per Result": "$36.12"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "937280",
            "Clicks": "13922",
            "Conversions": "80",
            "Conversion Value": "$27.74",
            "Cost per Result": "$23.95"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "902125",
            "Clicks": "4523",
            "Conversions": "102",
            "Conversion Value": "$18.97",
            "Cost per Result": "$21.55"
          }
        ]
      },
      "ICOIDOParticipantsData": {
        "name": "ICO/IDO Participants",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "379986",
            "Clicks": "3519",
            "Conversions": "81",
            "Conversion Value": "$10,861",
            "Cost per Result": "$29.96"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "493599",
            "Clicks": "3939",
            "Conversions": "58",
            "Conversion Value": "$14,048",
            "Cost per Result": "$13.73"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "426454",
            "Clicks": "3166",
            "Conversions": "48",
            "Conversion Value": "$14,990",
            "Cost per Result": "$27.08"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "199441",
            "Clicks": "2504",
            "Conversions": "55",
            "Conversion Value": "$12,841",
            "Cost per Result": "$26.00"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "435403",
            "Clicks": "4301",
            "Conversions": "52",
            "Conversion Value": "$9,013",
            "Cost per Result": "$10.80"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "296316",
            "Clicks": "6204",
            "Conversions": "73",
            "Conversion Value": "$4,406",
            "Cost per Result": "$23.96"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "419299",
            "Clicks": "6669",
            "Conversions": "69",
            "Conversion Value": "$6,838",
            "Cost per Result": "$10.17"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "258236",
            "Clicks": "2851",
            "Conversions": "79",
            "Conversion Value": "$5,730",
            "Cost per Result": "$32.34"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "317238",
            "Clicks": "6668",
            "Conversions": "43",
            "Conversion Value": "$11,387",
            "Cost per Result": "$28.09"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "356735",
            "Clicks": "5823",
            "Conversions": "65",
            "Conversion Value": "$12,699",
            "Cost per Result": "$33.72"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "327383",
            "Clicks": "2036",
            "Conversions": "53",
            "Conversion Value": "$15,115",
            "Cost per Result": "$36.77"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "363455",
            "Clicks": "3236",
            "Conversions": "53",
            "Conversion Value": "$13,671",
            "Cost per Result": "$37.78"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "481859",
            "Clicks": "2917",
            "Conversions": "89",
            "Conversion Value": "$5,073",
            "Cost per Result": "$12.41"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "183917",
            "Clicks": "4354",
            "Conversions": "78",
            "Conversion Value": "$13,781",
            "Cost per Result": "$33.17"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "320058",
            "Clicks": "3386",
            "Conversions": "86",
            "Conversion Value": "$14,425",
            "Cost per Result": "$31.35"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "165509",
            "Clicks": "5016",
            "Conversions": "40",
            "Conversion Value": "$14,109",
            "Cost per Result": "$25.30"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "421786",
            "Clicks": "5717",
            "Conversions": "77",
            "Conversion Value": "$15,574",
            "Cost per Result": "$21.66"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "252191",
            "Clicks": "6343",
            "Conversions": "85",
            "Conversion Value": "$15,250",
            "Cost per Result": "$20.72"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "346139",
            "Clicks": "4484",
            "Conversions": "63",
            "Conversion Value": "$15,828",
            "Cost per Result": "$11.11"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "198399",
            "Clicks": "2703",
            "Conversions": "89",
            "Conversion Value": "$12,625",
            "Cost per Result": "$39.83"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "218714",
            "Clicks": "6317",
            "Conversions": "69",
            "Conversion Value": "$5,559",
            "Cost per Result": "$24.37"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "191386",
            "Clicks": "5818",
            "Conversions": "89",
            "Conversion Value": "$7,178",
            "Cost per Result": "$18.16"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "283167",
            "Clicks": "5011",
            "Conversions": "72",
            "Conversion Value": "$4,175",
            "Cost per Result": "$21.85"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "232376",
            "Clicks": "6331",
            "Conversions": "69",
            "Conversion Value": "$13,432",
            "Cost per Result": "$13.29"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "378895",
            "Clicks": "2574",
            "Conversions": "57",
            "Conversion Value": "$8,531",
            "Cost per Result": "$22.00"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "241258",
            "Clicks": "2832",
            "Conversions": "68",
            "Conversion Value": "$6,943",
            "Cost per Result": "$12.07"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "351763",
            "Clicks": "2982",
            "Conversions": "66",
            "Conversion Value": "$15,601",
            "Cost per Result": "$16.72"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "107196",
            "Clicks": "3840",
            "Conversions": "53",
            "Conversion Value": "$6,611",
            "Cost per Result": "$27.39"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "407461",
            "Clicks": "3234",
            "Conversions": "61",
            "Conversion Value": "$10,822",
            "Cost per Result": "$11.97"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "419771",
            "Clicks": "2099",
            "Conversions": "52",
            "Conversion Value": "$7,554",
            "Cost per Result": "$37.25"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "166721",
            "Clicks": "6144",
            "Conversions": "47",
            "Conversion Value": "$14,234",
            "Cost per Result": "$17.37"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "274708",
            "Clicks": "3701",
            "Conversions": "86",
            "Conversion Value": "$6,818",
            "Cost per Result": "$31.76"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "399028",
            "Clicks": "6963",
            "Conversions": "49",
            "Conversion Value": "$5,922",
            "Cost per Result": "$37.59"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "428737",
            "Clicks": "4104",
            "Conversions": "45",
            "Conversion Value": "$8,515",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "448441",
            "Clicks": "3908",
            "Conversions": "64",
            "Conversion Value": "$15,760",
            "Cost per Result": "$38.13"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "134649",
            "Clicks": "3526",
            "Conversions": "55",
            "Conversion Value": "$8,294",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "305050",
            "Clicks": "6228",
            "Conversions": "57",
            "Conversion Value": "$6,558",
            "Cost per Result": "$36.92"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "156651",
            "Clicks": "3206",
            "Conversions": "75",
            "Conversion Value": "$8,803",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "232740",
            "Clicks": "2088",
            "Conversions": "57",
            "Conversion Value": "$6,778",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "365073",
            "Clicks": "4382",
            "Conversions": "58",
            "Conversion Value": "$14,965",
            "Cost per Result": "$31.44"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "484179",
            "Clicks": "2241",
            "Conversions": "57",
            "Conversion Value": "$12,249",
            "Cost per Result": "$31.70"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "137350",
            "Clicks": "2162",
            "Conversions": "47",
            "Conversion Value": "$11,934",
            "Cost per Result": "$30.45"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "394059",
            "Clicks": "5463",
            "Conversions": "55",
            "Conversion Value": "$7,181",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "428898",
            "Clicks": "2642",
            "Conversions": "54",
            "Conversion Value": "$6,253",
            "Cost per Result": "$29.56"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "250009",
            "Clicks": "2803",
            "Conversions": "47",
            "Conversion Value": "$9,255",
            "Cost per Result": "$27.21"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "284509",
            "Clicks": "3172",
            "Conversions": "73",
            "Conversion Value": "$13,372",
            "Cost per Result": "$35.57"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "236498",
            "Clicks": "2987",
            "Conversions": "79",
            "Conversion Value": "$9,877",
            "Cost per Result": "$33.33"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "280687",
            "Clicks": "6709",
            "Conversions": "61",
            "Conversion Value": "$8,977",
            "Cost per Result": "$29.84"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "401359",
            "Clicks": "5105",
            "Conversions": "52",
            "Conversion Value": "$13,908",
            "Cost per Result": "$27.36"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "445761",
            "Clicks": "2034",
            "Conversions": "85",
            "Conversion Value": "$6,693",
            "Cost per Result": "$30.21"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "209121",
            "Clicks": "2299",
            "Conversions": "41",
            "Conversion Value": "$4,206",
            "Cost per Result": "$26.12"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "402803",
            "Clicks": "4621",
            "Conversions": "57",
            "Conversion Value": "$7,225",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "353378",
            "Clicks": "5227",
            "Conversions": "60",
            "Conversion Value": "$7,201",
            "Cost per Result": "$24.29"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "206593",
            "Clicks": "4236",
            "Conversions": "74",
            "Conversion Value": "$4,924",
            "Cost per Result": "$18.14"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "466358",
            "Clicks": "4003",
            "Conversions": "73",
            "Conversion Value": "$10,229",
            "Cost per Result": "$21.89"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "267686",
            "Clicks": "5645",
            "Conversions": "89",
            "Conversion Value": "$11,656",
            "Cost per Result": "$16.84"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "483546",
            "Clicks": "5337",
            "Conversions": "51",
            "Conversion Value": "$8,384",
            "Cost per Result": "$38.44"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "322992",
            "Clicks": "4847",
            "Conversions": "86",
            "Conversion Value": "$11,407",
            "Cost per Result": "$35.98"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "421339",
            "Clicks": "2112",
            "Conversions": "57",
            "Conversion Value": "$4,861",
            "Cost per Result": "$28.13"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "153073",
            "Clicks": "4176",
            "Conversions": "44",
            "Conversion Value": "$6,924",
            "Cost per Result": "$35.35"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "464752",
            "Clicks": "3342",
            "Conversions": "45",
            "Conversion Value": "$9,941",
            "Cost per Result": "$34.95"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "173106",
            "Clicks": "2891",
            "Conversions": "48",
            "Conversion Value": "$6,290",
            "Cost per Result": "$21.65"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "229431",
            "Clicks": "4945",
            "Conversions": "59",
            "Conversion Value": "$11,398",
            "Cost per Result": "$37.35"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "129554",
            "Clicks": "2916",
            "Conversions": "72",
            "Conversion Value": "$5,217",
            "Cost per Result": "$20.12"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "278414",
            "Clicks": "3081",
            "Conversions": "55",
            "Conversion Value": "$13,927",
            "Cost per Result": "$23.65"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "292084",
            "Clicks": "5470",
            "Conversions": "48",
            "Conversion Value": "$4,422",
            "Cost per Result": "$14.14"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "491187",
            "Clicks": "5703",
            "Conversions": "79",
            "Conversion Value": "$11,511",
            "Cost per Result": "$22.46"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "194941",
            "Clicks": "4109",
            "Conversions": "41",
            "Conversion Value": "$14,919",
            "Cost per Result": "$30.31"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "283770",
            "Clicks": "5957",
            "Conversions": "58",
            "Conversion Value": "$14,029",
            "Cost per Result": "$33.32"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "390518",
            "Clicks": "2282",
            "Conversions": "63",
            "Conversion Value": "$15,936",
            "Cost per Result": "$11.44"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "178380",
            "Clicks": "3957",
            "Conversions": "87",
            "Conversion Value": "$15,154",
            "Cost per Result": "$20.71"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "147502",
            "Clicks": "2782",
            "Conversions": "63",
            "Conversion Value": "$6,440",
            "Cost per Result": "$23.40"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "165156",
            "Clicks": "2298",
            "Conversions": "51",
            "Conversion Value": "$7,088",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "455775",
            "Clicks": "2347",
            "Conversions": "85",
            "Conversion Value": "$10,208",
            "Cost per Result": "$21.51"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "463773",
            "Clicks": "3259",
            "Conversions": "87",
            "Conversion Value": "$9,042",
            "Cost per Result": "$16.29"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "470199",
            "Clicks": "4359",
            "Conversions": "86",
            "Conversion Value": "$10,040",
            "Cost per Result": "$11.66"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "187352",
            "Clicks": "2616",
            "Conversions": "58",
            "Conversion Value": "$7,929",
            "Cost per Result": "$35.84"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "396830",
            "Clicks": "6674",
            "Conversions": "48",
            "Conversion Value": "$15,195",
            "Cost per Result": "$27.77"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "174667",
            "Clicks": "6145",
            "Conversions": "89",
            "Conversion Value": "$10,532",
            "Cost per Result": "$32.13"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "240619",
            "Clicks": "5711",
            "Conversions": "51",
            "Conversion Value": "$13,220",
            "Cost per Result": "$38.21"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "359704",
            "Clicks": "5374",
            "Conversions": "40",
            "Conversion Value": "$14,466",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "217033",
            "Clicks": "3342",
            "Conversions": "41",
            "Conversion Value": "$13,231",
            "Cost per Result": "$27.58"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "192303",
            "Clicks": "5864",
            "Conversions": "79",
            "Conversion Value": "$4,690",
            "Cost per Result": "$38.94"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "486419",
            "Clicks": "3697",
            "Conversions": "79",
            "Conversion Value": "$5,829",
            "Cost per Result": "$17.37"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "242986",
            "Clicks": "3833",
            "Conversions": "60",
            "Conversion Value": "$9,944",
            "Cost per Result": "$29.40"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "285580",
            "Clicks": "4955",
            "Conversions": "76",
            "Conversion Value": "$13,482",
            "Cost per Result": "$38.64"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "483568",
            "Clicks": "4278",
            "Conversions": "63",
            "Conversion Value": "$10,212",
            "Cost per Result": "$14.56"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "273610",
            "Clicks": "3516",
            "Conversions": "87",
            "Conversion Value": "$9,518",
            "Cost per Result": "$37.13"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "228666",
            "Clicks": "5049",
            "Conversions": "48",
            "Conversion Value": "$12,016",
            "Cost per Result": "$28.44"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "327259",
            "Clicks": "2714",
            "Conversions": "85",
            "Conversion Value": "$10,071",
            "Cost per Result": "$24.40"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "178465",
            "Clicks": "2488",
            "Conversions": "65",
            "Conversion Value": "$8,630",
            "Cost per Result": "$38.60"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "145162",
            "Clicks": "5738",
            "Conversions": "41",
            "Conversion Value": "$12,482",
            "Cost per Result": "$27.81"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "178393",
            "Clicks": "4087",
            "Conversions": "57",
            "Conversion Value": "$4,006",
            "Cost per Result": "$20.34"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "261430",
            "Clicks": "6420",
            "Conversions": "87",
            "Conversion Value": "$5,738",
            "Cost per Result": "$35.39"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "196251",
            "Clicks": "4946",
            "Conversions": "66",
            "Conversion Value": "$8,829",
            "Cost per Result": "$36.34"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "119926",
            "Clicks": "5715",
            "Conversions": "75",
            "Conversion Value": "$14,681",
            "Cost per Result": "$34.16"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "132303",
            "Clicks": "6126",
            "Conversions": "86",
            "Conversion Value": "$8,298",
            "Cost per Result": "$18.50"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "394948",
            "Clicks": "5560",
            "Conversions": "82",
            "Conversion Value": "$9,501",
            "Cost per Result": "$28.93"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "175046",
            "Clicks": "3960",
            "Conversions": "81",
            "Conversion Value": "$5,414",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "116983",
            "Clicks": "2169",
            "Conversions": "59",
            "Conversion Value": "$8,793",
            "Cost per Result": "$13.56"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "377878",
            "Clicks": "3155",
            "Conversions": "85",
            "Conversion Value": "$13,210",
            "Cost per Result": "$29.80"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "310125",
            "Clicks": "3268",
            "Conversions": "47",
            "Conversion Value": "$7,331",
            "Cost per Result": "$24.92"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "474266",
            "Clicks": "4784",
            "Conversions": "76",
            "Conversion Value": "$4,832",
            "Cost per Result": "$21.94"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "188101",
            "Clicks": "3168",
            "Conversions": "72",
            "Conversion Value": "$10,129",
            "Cost per Result": "$35.37"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "116828",
            "Clicks": "5637",
            "Conversions": "41",
            "Conversion Value": "$7,007",
            "Cost per Result": "$11.32"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "287645",
            "Clicks": "2322",
            "Conversions": "76",
            "Conversion Value": "$9,266",
            "Cost per Result": "$10.36"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "196093",
            "Clicks": "6678",
            "Conversions": "65",
            "Conversion Value": "$5,396",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "321050",
            "Clicks": "2358",
            "Conversions": "83",
            "Conversion Value": "$4,580",
            "Cost per Result": "$21.53"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "475674",
            "Clicks": "2606",
            "Conversions": "90",
            "Conversion Value": "$13,936",
            "Cost per Result": "$17.93"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "265640",
            "Clicks": "5587",
            "Conversions": "56",
            "Conversion Value": "$10,644",
            "Cost per Result": "$29.65"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "311709",
            "Clicks": "2671",
            "Conversions": "60",
            "Conversion Value": "$14,492",
            "Cost per Result": "$28.53"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "419112",
            "Clicks": "6266",
            "Conversions": "87",
            "Conversion Value": "$11,384",
            "Cost per Result": "$10.21"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "276373",
            "Clicks": "6419",
            "Conversions": "65",
            "Conversion Value": "$15,153",
            "Cost per Result": "$17.08"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "360007",
            "Clicks": "2217",
            "Conversions": "64",
            "Conversion Value": "$5,298",
            "Cost per Result": "$22.37"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "279936",
            "Clicks": "5060",
            "Conversions": "65",
            "Conversion Value": "$4,227",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "367198",
            "Clicks": "4758",
            "Conversions": "56",
            "Conversion Value": "$13,069",
            "Cost per Result": "$18.59"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "216599",
            "Clicks": "5503",
            "Conversions": "69",
            "Conversion Value": "$8,428",
            "Cost per Result": "$27.48"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "257347",
            "Clicks": "5747",
            "Conversions": "50",
            "Conversion Value": "$10,601",
            "Cost per Result": "$33.88"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "210415",
            "Clicks": "5002",
            "Conversions": "83",
            "Conversion Value": "$9,670",
            "Cost per Result": "$32.48"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "262570",
            "Clicks": "6206",
            "Conversions": "78",
            "Conversion Value": "$4,431",
            "Cost per Result": "$11.88"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "104912",
            "Clicks": "5281",
            "Conversions": "86",
            "Conversion Value": "$14,249",
            "Cost per Result": "$30.04"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "340755",
            "Clicks": "5669",
            "Conversions": "51",
            "Conversion Value": "$8,103",
            "Cost per Result": "$17.76"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "250367",
            "Clicks": "2999",
            "Conversions": "51",
            "Conversion Value": "$5,552",
            "Cost per Result": "$26.68"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "318217",
            "Clicks": "6757",
            "Conversions": "77",
            "Conversion Value": "$7,718",
            "Cost per Result": "$29.89"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "154334",
            "Clicks": "3644",
            "Conversions": "89",
            "Conversion Value": "$12,682",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "419982",
            "Clicks": "5463",
            "Conversions": "56",
            "Conversion Value": "$9,350",
            "Cost per Result": "$32.92"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "463469",
            "Clicks": "2434",
            "Conversions": "69",
            "Conversion Value": "$13,961",
            "Cost per Result": "$28.42"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "239560",
            "Clicks": "4149",
            "Conversions": "86",
            "Conversion Value": "$8,392",
            "Cost per Result": "$11.99"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "258475",
            "Clicks": "3213",
            "Conversions": "90",
            "Conversion Value": "$6,869",
            "Cost per Result": "$39.82"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "411373",
            "Clicks": "2108",
            "Conversions": "68",
            "Conversion Value": "$8,049",
            "Cost per Result": "$30.41"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "421312",
            "Clicks": "5803",
            "Conversions": "59",
            "Conversion Value": "$12,998",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "230188",
            "Clicks": "5894",
            "Conversions": "61",
            "Conversion Value": "$12,991",
            "Cost per Result": "$35.79"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "257634",
            "Clicks": "2915",
            "Conversions": "59",
            "Conversion Value": "$7,507",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "449255",
            "Clicks": "4983",
            "Conversions": "58",
            "Conversion Value": "$9,341",
            "Cost per Result": "$18.60"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "442359",
            "Clicks": "2729",
            "Conversions": "81",
            "Conversion Value": "$6,810",
            "Cost per Result": "$20.15"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "199936",
            "Clicks": "4283",
            "Conversions": "66",
            "Conversion Value": "$13,496",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "247933",
            "Clicks": "6323",
            "Conversions": "52",
            "Conversion Value": "$13,928",
            "Cost per Result": "$11.59"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "109326",
            "Clicks": "5734",
            "Conversions": "48",
            "Conversion Value": "$14,294",
            "Cost per Result": "$37.11"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "290292",
            "Clicks": "5402",
            "Conversions": "86",
            "Conversion Value": "$8,577",
            "Cost per Result": "$27.90"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "228692",
            "Clicks": "6594",
            "Conversions": "61",
            "Conversion Value": "$13,527",
            "Cost per Result": "$29.65"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "194787",
            "Clicks": "3544",
            "Conversions": "63",
            "Conversion Value": "$11,618",
            "Cost per Result": "$11.81"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "485895",
            "Clicks": "5121",
            "Conversions": "65",
            "Conversion Value": "$7,035",
            "Cost per Result": "$12.32"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "183074",
            "Clicks": "5564",
            "Conversions": "57",
            "Conversion Value": "$8,228",
            "Cost per Result": "$30.75"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "281884",
            "Clicks": "3083",
            "Conversions": "80",
            "Conversion Value": "$14,337",
            "Cost per Result": "$18.07"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "482710",
            "Clicks": "2441",
            "Conversions": "54",
            "Conversion Value": "$9,045",
            "Cost per Result": "$16.95"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "388640",
            "Clicks": "3064",
            "Conversions": "77",
            "Conversion Value": "$7,086",
            "Cost per Result": "$19.09"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "365996",
            "Clicks": "2802",
            "Conversions": "72",
            "Conversion Value": "$9,607",
            "Cost per Result": "$30.38"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "178689",
            "Clicks": "2835",
            "Conversions": "80",
            "Conversion Value": "$5,224",
            "Cost per Result": "$15.46"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "366636",
            "Clicks": "3358",
            "Conversions": "86",
            "Conversion Value": "$6,613",
            "Cost per Result": "$11.81"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "110950",
            "Clicks": "4122",
            "Conversions": "52",
            "Conversion Value": "$14,482",
            "Cost per Result": "$24.47"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "466474",
            "Clicks": "6449",
            "Conversions": "75",
            "Conversion Value": "$11,697",
            "Cost per Result": "$26.47"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "281576",
            "Clicks": "3299",
            "Conversions": "90",
            "Conversion Value": "$13,398",
            "Cost per Result": "$18.87"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "385689",
            "Clicks": "4593",
            "Conversions": "53",
            "Conversion Value": "$7,361",
            "Cost per Result": "$14.16"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "270627",
            "Clicks": "2414",
            "Conversions": "80",
            "Conversion Value": "$6,173",
            "Cost per Result": "$30.84"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "375259",
            "Clicks": "3062",
            "Conversions": "74",
            "Conversion Value": "$11,263",
            "Cost per Result": "$22.65"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "105886",
            "Clicks": "3406",
            "Conversions": "90",
            "Conversion Value": "$10,704",
            "Cost per Result": "$32.41"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "458464",
            "Clicks": "3323",
            "Conversions": "50",
            "Conversion Value": "$10,715",
            "Cost per Result": "$37.69"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "335166",
            "Clicks": "2569",
            "Conversions": "63",
            "Conversion Value": "$6,988",
            "Cost per Result": "$36.32"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "488220",
            "Clicks": "3126",
            "Conversions": "84",
            "Conversion Value": "$11,889",
            "Cost per Result": "$16.53"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "440710",
            "Clicks": "2465",
            "Conversions": "40",
            "Conversion Value": "$4,824",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "399664",
            "Clicks": "3769",
            "Conversions": "79",
            "Conversion Value": "$13,155",
            "Cost per Result": "$14.65"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "417600",
            "Clicks": "6605",
            "Conversions": "40",
            "Conversion Value": "$5,051",
            "Cost per Result": "$31.07"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "394788",
            "Clicks": "4271",
            "Conversions": "85",
            "Conversion Value": "$8,865",
            "Cost per Result": "$14.23"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "227415",
            "Clicks": "2672",
            "Conversions": "54",
            "Conversion Value": "$4,639",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "381076",
            "Clicks": "5974",
            "Conversions": "68",
            "Conversion Value": "$13,102",
            "Cost per Result": "$25.38"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "365223",
            "Clicks": "5804",
            "Conversions": "47",
            "Conversion Value": "$10,136",
            "Cost per Result": "$18.24"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "313610",
            "Clicks": "4083",
            "Conversions": "82",
            "Conversion Value": "$4,801",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "359765",
            "Clicks": "6952",
            "Conversions": "45",
            "Conversion Value": "$11,434",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "205619",
            "Clicks": "4689",
            "Conversions": "78",
            "Conversion Value": "$13,618",
            "Cost per Result": "$29.53"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "462365",
            "Clicks": "6525",
            "Conversions": "55",
            "Conversion Value": "$15,788",
            "Cost per Result": "$18.35"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "352925",
            "Clicks": "3308",
            "Conversions": "78",
            "Conversion Value": "$9,012",
            "Cost per Result": "$25.48"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "349613",
            "Clicks": "5993",
            "Conversions": "63",
            "Conversion Value": "$9,441",
            "Cost per Result": "$29.28"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "171367",
            "Clicks": "3501",
            "Conversions": "46",
            "Conversion Value": "$11,724",
            "Cost per Result": "$35.69"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "229686",
            "Clicks": "4597",
            "Conversions": "49",
            "Conversion Value": "$14,347",
            "Cost per Result": "$35.27"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "212600",
            "Clicks": "5048",
            "Conversions": "66",
            "Conversion Value": "$8,922",
            "Cost per Result": "$26.73"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "444776",
            "Clicks": "3861",
            "Conversions": "85",
            "Conversion Value": "$10,950",
            "Cost per Result": "$18.23"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "373172",
            "Clicks": "4512",
            "Conversions": "65",
            "Conversion Value": "$5,166",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "226315",
            "Clicks": "4403",
            "Conversions": "51",
            "Conversion Value": "$4,157",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "246760",
            "Clicks": "2369",
            "Conversions": "66",
            "Conversion Value": "$13,675",
            "Cost per Result": "$36.50"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "490738",
            "Clicks": "5220",
            "Conversions": "49",
            "Conversion Value": "$12,785",
            "Cost per Result": "$38.94"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "322709",
            "Clicks": "3618",
            "Conversions": "58",
            "Conversion Value": "$4,437",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "447452",
            "Clicks": "6029",
            "Conversions": "68",
            "Conversion Value": "$8,266",
            "Cost per Result": "$18.86"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "270345",
            "Clicks": "5609",
            "Conversions": "45",
            "Conversion Value": "$15,025",
            "Cost per Result": "$27.27"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "395434",
            "Clicks": "4291",
            "Conversions": "63",
            "Conversion Value": "$10,436",
            "Cost per Result": "$11.99"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "363472",
            "Clicks": "5658",
            "Conversions": "40",
            "Conversion Value": "$5,618",
            "Cost per Result": "$14.83"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "258931",
            "Clicks": "6854",
            "Conversions": "43",
            "Conversion Value": "$13,826",
            "Cost per Result": "$31.38"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "319929",
            "Clicks": "6518",
            "Conversions": "68",
            "Conversion Value": "$12,352",
            "Cost per Result": "$34.82"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "286422",
            "Clicks": "5926",
            "Conversions": "43",
            "Conversion Value": "$9,450",
            "Cost per Result": "$10.34"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "115446",
            "Clicks": "2467",
            "Conversions": "43",
            "Conversion Value": "$14,332",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "421507",
            "Clicks": "2805",
            "Conversions": "52",
            "Conversion Value": "$5,182",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "168564",
            "Clicks": "4332",
            "Conversions": "74",
            "Conversion Value": "$15,216",
            "Cost per Result": "$13.52"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "142347",
            "Clicks": "2756",
            "Conversions": "54",
            "Conversion Value": "$14,921",
            "Cost per Result": "$37.87"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "304887",
            "Clicks": "5214",
            "Conversions": "82",
            "Conversion Value": "$8,631",
            "Cost per Result": "$39.14"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "431513",
            "Clicks": "4078",
            "Conversions": "59",
            "Conversion Value": "$13,306",
            "Cost per Result": "$21.20"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "146371",
            "Clicks": "3775",
            "Conversions": "48",
            "Conversion Value": "$8,410",
            "Cost per Result": "$22.72"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "435675",
            "Clicks": "4275",
            "Conversions": "56",
            "Conversion Value": "$4,978",
            "Cost per Result": "$28.93"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "417846",
            "Clicks": "5182",
            "Conversions": "55",
            "Conversion Value": "$5,098",
            "Cost per Result": "$10.08"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "140827",
            "Clicks": "5485",
            "Conversions": "43",
            "Conversion Value": "$11,706",
            "Cost per Result": "$34.67"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "333044",
            "Clicks": "2003",
            "Conversions": "83",
            "Conversion Value": "$12,831",
            "Cost per Result": "$10.18"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "341240",
            "Clicks": "3795",
            "Conversions": "56",
            "Conversion Value": "$11,198",
            "Cost per Result": "$11.33"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "461778",
            "Clicks": "4591",
            "Conversions": "50",
            "Conversion Value": "$7,722",
            "Cost per Result": "$18.73"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "217939",
            "Clicks": "2798",
            "Conversions": "77",
            "Conversion Value": "$10,429",
            "Cost per Result": "$14.50"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "465406",
            "Clicks": "5487",
            "Conversions": "51",
            "Conversion Value": "$12,695",
            "Cost per Result": "$32.71"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "345250",
            "Clicks": "2696",
            "Conversions": "45",
            "Conversion Value": "$13,610",
            "Cost per Result": "$13.62"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "327774",
            "Clicks": "5883",
            "Conversions": "56",
            "Conversion Value": "$13,311",
            "Cost per Result": "$16.28"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "273211",
            "Clicks": "3334",
            "Conversions": "42",
            "Conversion Value": "$10,965",
            "Cost per Result": "$30.76"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "142590",
            "Clicks": "3876",
            "Conversions": "65",
            "Conversion Value": "$13,808",
            "Cost per Result": "$23.87"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "431942",
            "Clicks": "3060",
            "Conversions": "66",
            "Conversion Value": "$6,785",
            "Cost per Result": "$20.79"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "416668",
            "Clicks": "4386",
            "Conversions": "74",
            "Conversion Value": "$4,265",
            "Cost per Result": "$31.54"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "395283",
            "Clicks": "6786",
            "Conversions": "53",
            "Conversion Value": "$10,071",
            "Cost per Result": "$13.88"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "113389",
            "Clicks": "5981",
            "Conversions": "77",
            "Conversion Value": "$12,549",
            "Cost per Result": "$26.80"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "230194",
            "Clicks": "4769",
            "Conversions": "65",
            "Conversion Value": "$14,005",
            "Cost per Result": "$20.65"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "325447",
            "Clicks": "5198",
            "Conversions": "62",
            "Conversion Value": "$11,463",
            "Cost per Result": "$10.58"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "231874",
            "Clicks": "5797",
            "Conversions": "51",
            "Conversion Value": "$11,540",
            "Cost per Result": "$32.18"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "194742",
            "Clicks": "3053",
            "Conversions": "46",
            "Conversion Value": "$15,117",
            "Cost per Result": "$14.63"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "369946",
            "Clicks": "6423",
            "Conversions": "60",
            "Conversion Value": "$11,018",
            "Cost per Result": "$15.13"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "486145",
            "Clicks": "5635",
            "Conversions": "85",
            "Conversion Value": "$12,799",
            "Cost per Result": "$26.87"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "397085",
            "Clicks": "5061",
            "Conversions": "88",
            "Conversion Value": "$5,847",
            "Cost per Result": "$16.15"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "497317",
            "Clicks": "6771",
            "Conversions": "78",
            "Conversion Value": "$11,328",
            "Cost per Result": "$13.31"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "428377",
            "Clicks": "5984",
            "Conversions": "57",
            "Conversion Value": "$9,217",
            "Cost per Result": "$38.78"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "307545",
            "Clicks": "4614",
            "Conversions": "44",
            "Conversion Value": "$14,644",
            "Cost per Result": "$25.58"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "298605",
            "Clicks": "2775",
            "Conversions": "88",
            "Conversion Value": "$12,367",
            "Cost per Result": "$19.25"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "123566",
            "Clicks": "3384",
            "Conversions": "49",
            "Conversion Value": "$8,887",
            "Cost per Result": "$10.65"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "238367",
            "Clicks": "6861",
            "Conversions": "71",
            "Conversion Value": "$4,280",
            "Cost per Result": "$37.79"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "390773",
            "Clicks": "4903",
            "Conversions": "58",
            "Conversion Value": "$8,014",
            "Cost per Result": "$15.44"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "452096",
            "Clicks": "4142",
            "Conversions": "86",
            "Conversion Value": "$7,538",
            "Cost per Result": "$39.43"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "155629",
            "Clicks": "6803",
            "Conversions": "66",
            "Conversion Value": "$8,529",
            "Cost per Result": "$31.88"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "386831",
            "Clicks": "3284",
            "Conversions": "62",
            "Conversion Value": "$4,418",
            "Cost per Result": "$30.43"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "173017",
            "Clicks": "3228",
            "Conversions": "70",
            "Conversion Value": "$10,682",
            "Cost per Result": "$22.20"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "386303",
            "Clicks": "3230",
            "Conversions": "81",
            "Conversion Value": "$12,852",
            "Cost per Result": "$11.40"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "364832",
            "Clicks": "6502",
            "Conversions": "69",
            "Conversion Value": "$11,820",
            "Cost per Result": "$35.65"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "448113",
            "Clicks": "5196",
            "Conversions": "80",
            "Conversion Value": "$4,338",
            "Cost per Result": "$10.32"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "299362",
            "Clicks": "6902",
            "Conversions": "79",
            "Conversion Value": "$12,785",
            "Cost per Result": "$21.59"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "141316",
            "Clicks": "6911",
            "Conversions": "46",
            "Conversion Value": "$12,956",
            "Cost per Result": "$24.62"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "451592",
            "Clicks": "6503",
            "Conversions": "74",
            "Conversion Value": "$9,642",
            "Cost per Result": "$25.05"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "277698",
            "Clicks": "2556",
            "Conversions": "46",
            "Conversion Value": "$6,846",
            "Cost per Result": "$20.46"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "330881",
            "Clicks": "3710",
            "Conversions": "78",
            "Conversion Value": "$4,644",
            "Cost per Result": "$25.04"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "123711",
            "Clicks": "2783",
            "Conversions": "50",
            "Conversion Value": "$8,435",
            "Cost per Result": "$15.94"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "363890",
            "Clicks": "3879",
            "Conversions": "46",
            "Conversion Value": "$12,505",
            "Cost per Result": "$36.26"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "134066",
            "Clicks": "6935",
            "Conversions": "76",
            "Conversion Value": "$5,776",
            "Cost per Result": "$26.33"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "267876",
            "Clicks": "4565",
            "Conversions": "74",
            "Conversion Value": "$13,596",
            "Cost per Result": "$39.32"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "306124",
            "Clicks": "6959",
            "Conversions": "54",
            "Conversion Value": "$4,002",
            "Cost per Result": "$11.17"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "344502",
            "Clicks": "4627",
            "Conversions": "85",
            "Conversion Value": "$13,837",
            "Cost per Result": "$12.56"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "301612",
            "Clicks": "4462",
            "Conversions": "79",
            "Conversion Value": "$11,465",
            "Cost per Result": "$12.04"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "400326",
            "Clicks": "5549",
            "Conversions": "77",
            "Conversion Value": "$15,535",
            "Cost per Result": "$21.33"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "245154",
            "Clicks": "2332",
            "Conversions": "88",
            "Conversion Value": "$10,822",
            "Cost per Result": "$22.65"
          }
        ]
      },
      "HODLersData": {
        "name": "HODLers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "241741",
            "Clicks": "2109",
            "Conversions": "68",
            "Conversion Value": "$6,434",
            "Cost per Result": "$33.92"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "483521",
            "Clicks": "5516",
            "Conversions": "83",
            "Conversion Value": "$12,447",
            "Cost per Result": "$23.38"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "124073",
            "Clicks": "5181",
            "Conversions": "73",
            "Conversion Value": "$11,103",
            "Cost per Result": "$31.17"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "421346",
            "Clicks": "5202",
            "Conversions": "69",
            "Conversion Value": "$11,052",
            "Cost per Result": "$34.58"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "452833",
            "Clicks": "3794",
            "Conversions": "80",
            "Conversion Value": "$5,242",
            "Cost per Result": "$31.48"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "327457",
            "Clicks": "3004",
            "Conversions": "80",
            "Conversion Value": "$5,199",
            "Cost per Result": "$26.28"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "301661",
            "Clicks": "2168",
            "Conversions": "65",
            "Conversion Value": "$7,616",
            "Cost per Result": "$11.40"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "470980",
            "Clicks": "3286",
            "Conversions": "75",
            "Conversion Value": "$8,977",
            "Cost per Result": "$20.46"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "167866",
            "Clicks": "6917",
            "Conversions": "67",
            "Conversion Value": "$12,661",
            "Cost per Result": "$13.56"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "444753",
            "Clicks": "5974",
            "Conversions": "54",
            "Conversion Value": "$14,754",
            "Cost per Result": "$19.00"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "284554",
            "Clicks": "2700",
            "Conversions": "48",
            "Conversion Value": "$9,859",
            "Cost per Result": "$16.52"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "417577",
            "Clicks": "3403",
            "Conversions": "86",
            "Conversion Value": "$13,613",
            "Cost per Result": "$10.60"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "477957",
            "Clicks": "2120",
            "Conversions": "41",
            "Conversion Value": "$11,417",
            "Cost per Result": "$33.23"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "319995",
            "Clicks": "5793",
            "Conversions": "68",
            "Conversion Value": "$4,427",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "408930",
            "Clicks": "3624",
            "Conversions": "40",
            "Conversion Value": "$13,554",
            "Cost per Result": "$35.79"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "206212",
            "Clicks": "4870",
            "Conversions": "67",
            "Conversion Value": "$14,325",
            "Cost per Result": "$19.87"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "272605",
            "Clicks": "5141",
            "Conversions": "83",
            "Conversion Value": "$15,607",
            "Cost per Result": "$28.62"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "421124",
            "Clicks": "6353",
            "Conversions": "79",
            "Conversion Value": "$6,243",
            "Cost per Result": "$33.50"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "442742",
            "Clicks": "4583",
            "Conversions": "57",
            "Conversion Value": "$10,652",
            "Cost per Result": "$33.20"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "334316",
            "Clicks": "5814",
            "Conversions": "41",
            "Conversion Value": "$5,913",
            "Cost per Result": "$28.73"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "145434",
            "Clicks": "3110",
            "Conversions": "80",
            "Conversion Value": "$7,690",
            "Cost per Result": "$25.88"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "343414",
            "Clicks": "5824",
            "Conversions": "61",
            "Conversion Value": "$12,399",
            "Cost per Result": "$34.40"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "470197",
            "Clicks": "4168",
            "Conversions": "70",
            "Conversion Value": "$14,682",
            "Cost per Result": "$11.72"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "340103",
            "Clicks": "6821",
            "Conversions": "68",
            "Conversion Value": "$11,865",
            "Cost per Result": "$12.36"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "445522",
            "Clicks": "2253",
            "Conversions": "74",
            "Conversion Value": "$5,019",
            "Cost per Result": "$19.48"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "197114",
            "Clicks": "2791",
            "Conversions": "57",
            "Conversion Value": "$14,733",
            "Cost per Result": "$30.25"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "122930",
            "Clicks": "3192",
            "Conversions": "47",
            "Conversion Value": "$10,224",
            "Cost per Result": "$34.04"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "478794",
            "Clicks": "5325",
            "Conversions": "51",
            "Conversion Value": "$14,388",
            "Cost per Result": "$14.72"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "361419",
            "Clicks": "3360",
            "Conversions": "59",
            "Conversion Value": "$12,888",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "487631",
            "Clicks": "4593",
            "Conversions": "41",
            "Conversion Value": "$12,199",
            "Cost per Result": "$20.85"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "447517",
            "Clicks": "3914",
            "Conversions": "72",
            "Conversion Value": "$7,803",
            "Cost per Result": "$29.56"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "402091",
            "Clicks": "6535",
            "Conversions": "49",
            "Conversion Value": "$5,192",
            "Cost per Result": "$15.51"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "320624",
            "Clicks": "3112",
            "Conversions": "49",
            "Conversion Value": "$14,864",
            "Cost per Result": "$20.28"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "485979",
            "Clicks": "6198",
            "Conversions": "63",
            "Conversion Value": "$14,228",
            "Cost per Result": "$31.71"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "325862",
            "Clicks": "2466",
            "Conversions": "81",
            "Conversion Value": "$9,178",
            "Cost per Result": "$27.41"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "486507",
            "Clicks": "4470",
            "Conversions": "59",
            "Conversion Value": "$14,334",
            "Cost per Result": "$39.79"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "345487",
            "Clicks": "5657",
            "Conversions": "45",
            "Conversion Value": "$15,881",
            "Cost per Result": "$24.60"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "498535",
            "Clicks": "4300",
            "Conversions": "59",
            "Conversion Value": "$13,663",
            "Cost per Result": "$25.86"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "154511",
            "Clicks": "2127",
            "Conversions": "54",
            "Conversion Value": "$12,686",
            "Cost per Result": "$26.47"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "148671",
            "Clicks": "2814",
            "Conversions": "44",
            "Conversion Value": "$9,605",
            "Cost per Result": "$29.95"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "327239",
            "Clicks": "4604",
            "Conversions": "69",
            "Conversion Value": "$5,099",
            "Cost per Result": "$25.84"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "318983",
            "Clicks": "3375",
            "Conversions": "80",
            "Conversion Value": "$6,595",
            "Cost per Result": "$36.50"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "240821",
            "Clicks": "2540",
            "Conversions": "71",
            "Conversion Value": "$13,755",
            "Cost per Result": "$28.11"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "240854",
            "Clicks": "4587",
            "Conversions": "86",
            "Conversion Value": "$10,122",
            "Cost per Result": "$11.32"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "463019",
            "Clicks": "5972",
            "Conversions": "78",
            "Conversion Value": "$11,996",
            "Cost per Result": "$34.59"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "481638",
            "Clicks": "3333",
            "Conversions": "74",
            "Conversion Value": "$13,958",
            "Cost per Result": "$24.42"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "488451",
            "Clicks": "4450",
            "Conversions": "62",
            "Conversion Value": "$14,091",
            "Cost per Result": "$13.08"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "237493",
            "Clicks": "2337",
            "Conversions": "82",
            "Conversion Value": "$4,642",
            "Cost per Result": "$11.95"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "146487",
            "Clicks": "2455",
            "Conversions": "85",
            "Conversion Value": "$14,439",
            "Cost per Result": "$28.63"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "334180",
            "Clicks": "2055",
            "Conversions": "48",
            "Conversion Value": "$11,120",
            "Cost per Result": "$30.82"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "221516",
            "Clicks": "6050",
            "Conversions": "50",
            "Conversion Value": "$8,274",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "203635",
            "Clicks": "6434",
            "Conversions": "69",
            "Conversion Value": "$8,059",
            "Cost per Result": "$37.70"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "210833",
            "Clicks": "3824",
            "Conversions": "67",
            "Conversion Value": "$13,704",
            "Cost per Result": "$14.02"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "209171",
            "Clicks": "5552",
            "Conversions": "46",
            "Conversion Value": "$9,047",
            "Cost per Result": "$16.48"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "436272",
            "Clicks": "5544",
            "Conversions": "88",
            "Conversion Value": "$8,648",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "478715",
            "Clicks": "3270",
            "Conversions": "88",
            "Conversion Value": "$8,160",
            "Cost per Result": "$32.96"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "193648",
            "Clicks": "2151",
            "Conversions": "52",
            "Conversion Value": "$6,430",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "497522",
            "Clicks": "3670",
            "Conversions": "50",
            "Conversion Value": "$5,680",
            "Cost per Result": "$34.72"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "171070",
            "Clicks": "5143",
            "Conversions": "62",
            "Conversion Value": "$14,339",
            "Cost per Result": "$11.60"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "214234",
            "Clicks": "2672",
            "Conversions": "77",
            "Conversion Value": "$13,414",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "426377",
            "Clicks": "4058",
            "Conversions": "90",
            "Conversion Value": "$5,449",
            "Cost per Result": "$35.20"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "213431",
            "Clicks": "5326",
            "Conversions": "74",
            "Conversion Value": "$11,272",
            "Cost per Result": "$35.18"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "449865",
            "Clicks": "4271",
            "Conversions": "87",
            "Conversion Value": "$4,170",
            "Cost per Result": "$34.06"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "171454",
            "Clicks": "4547",
            "Conversions": "58",
            "Conversion Value": "$14,917",
            "Cost per Result": "$24.38"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "246750",
            "Clicks": "5154",
            "Conversions": "80",
            "Conversion Value": "$10,247",
            "Cost per Result": "$20.28"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "489146",
            "Clicks": "3963",
            "Conversions": "45",
            "Conversion Value": "$10,289",
            "Cost per Result": "$11.29"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "181429",
            "Clicks": "4429",
            "Conversions": "75",
            "Conversion Value": "$7,919",
            "Cost per Result": "$14.23"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "315280",
            "Clicks": "3892",
            "Conversions": "81",
            "Conversion Value": "$6,067",
            "Cost per Result": "$17.18"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "490786",
            "Clicks": "4357",
            "Conversions": "72",
            "Conversion Value": "$7,479",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "349198",
            "Clicks": "6558",
            "Conversions": "85",
            "Conversion Value": "$7,275",
            "Cost per Result": "$13.41"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "485936",
            "Clicks": "4797",
            "Conversions": "90",
            "Conversion Value": "$14,037",
            "Cost per Result": "$13.24"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "333130",
            "Clicks": "5597",
            "Conversions": "67",
            "Conversion Value": "$13,666",
            "Cost per Result": "$14.43"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "241687",
            "Clicks": "2860",
            "Conversions": "84",
            "Conversion Value": "$8,528",
            "Cost per Result": "$39.97"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "287630",
            "Clicks": "2789",
            "Conversions": "90",
            "Conversion Value": "$14,721",
            "Cost per Result": "$14.41"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "372504",
            "Clicks": "6387",
            "Conversions": "82",
            "Conversion Value": "$8,425",
            "Cost per Result": "$28.35"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "462620",
            "Clicks": "6058",
            "Conversions": "86",
            "Conversion Value": "$8,290",
            "Cost per Result": "$32.85"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "196133",
            "Clicks": "4070",
            "Conversions": "64",
            "Conversion Value": "$8,535",
            "Cost per Result": "$25.79"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "408030",
            "Clicks": "5159",
            "Conversions": "89",
            "Conversion Value": "$9,515",
            "Cost per Result": "$23.51"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "305752",
            "Clicks": "3240",
            "Conversions": "58",
            "Conversion Value": "$8,231",
            "Cost per Result": "$33.89"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "129510",
            "Clicks": "2569",
            "Conversions": "48",
            "Conversion Value": "$11,732",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "354177",
            "Clicks": "6993",
            "Conversions": "85",
            "Conversion Value": "$11,626",
            "Cost per Result": "$11.52"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "185030",
            "Clicks": "5707",
            "Conversions": "42",
            "Conversion Value": "$6,961",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "176357",
            "Clicks": "6977",
            "Conversions": "54",
            "Conversion Value": "$8,880",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "477402",
            "Clicks": "3569",
            "Conversions": "80",
            "Conversion Value": "$13,118",
            "Cost per Result": "$30.53"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "447177",
            "Clicks": "6154",
            "Conversions": "68",
            "Conversion Value": "$10,256",
            "Cost per Result": "$18.83"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "267255",
            "Clicks": "5713",
            "Conversions": "78",
            "Conversion Value": "$9,596",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "130093",
            "Clicks": "5745",
            "Conversions": "76",
            "Conversion Value": "$13,675",
            "Cost per Result": "$24.35"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "313284",
            "Clicks": "3322",
            "Conversions": "42",
            "Conversion Value": "$11,064",
            "Cost per Result": "$16.55"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "388868",
            "Clicks": "6364",
            "Conversions": "40",
            "Conversion Value": "$4,638",
            "Cost per Result": "$19.17"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "365525",
            "Clicks": "6194",
            "Conversions": "77",
            "Conversion Value": "$14,232",
            "Cost per Result": "$37.95"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "211738",
            "Clicks": "5465",
            "Conversions": "52",
            "Conversion Value": "$7,297",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "498084",
            "Clicks": "3321",
            "Conversions": "44",
            "Conversion Value": "$12,813",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "267909",
            "Clicks": "5305",
            "Conversions": "52",
            "Conversion Value": "$9,168",
            "Cost per Result": "$22.49"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "356462",
            "Clicks": "4281",
            "Conversions": "71",
            "Conversion Value": "$4,650",
            "Cost per Result": "$25.05"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "244715",
            "Clicks": "5119",
            "Conversions": "63",
            "Conversion Value": "$7,423",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "178962",
            "Clicks": "4726",
            "Conversions": "60",
            "Conversion Value": "$14,728",
            "Cost per Result": "$10.14"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "195917",
            "Clicks": "6206",
            "Conversions": "73",
            "Conversion Value": "$8,562",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "280460",
            "Clicks": "3604",
            "Conversions": "46",
            "Conversion Value": "$11,286",
            "Cost per Result": "$13.20"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "394299",
            "Clicks": "3707",
            "Conversions": "64",
            "Conversion Value": "$6,370",
            "Cost per Result": "$37.06"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "290181",
            "Clicks": "6446",
            "Conversions": "40",
            "Conversion Value": "$9,907",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "483235",
            "Clicks": "6600",
            "Conversions": "85",
            "Conversion Value": "$9,103",
            "Cost per Result": "$22.33"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "124312",
            "Clicks": "5588",
            "Conversions": "84",
            "Conversion Value": "$7,006",
            "Cost per Result": "$33.62"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "251630",
            "Clicks": "4402",
            "Conversions": "77",
            "Conversion Value": "$5,383",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "241927",
            "Clicks": "2060",
            "Conversions": "55",
            "Conversion Value": "$13,858",
            "Cost per Result": "$35.42"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "135344",
            "Clicks": "2507",
            "Conversions": "64",
            "Conversion Value": "$7,950",
            "Cost per Result": "$35.85"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "152628",
            "Clicks": "3200",
            "Conversions": "64",
            "Conversion Value": "$9,758",
            "Cost per Result": "$24.27"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "427783",
            "Clicks": "6412",
            "Conversions": "66",
            "Conversion Value": "$7,596",
            "Cost per Result": "$14.93"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "271574",
            "Clicks": "5622",
            "Conversions": "90",
            "Conversion Value": "$4,288",
            "Cost per Result": "$21.57"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "146082",
            "Clicks": "5197",
            "Conversions": "49",
            "Conversion Value": "$15,919",
            "Cost per Result": "$11.82"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "288482",
            "Clicks": "4047",
            "Conversions": "72",
            "Conversion Value": "$10,380",
            "Cost per Result": "$11.97"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "361538",
            "Clicks": "6994",
            "Conversions": "47",
            "Conversion Value": "$8,116",
            "Cost per Result": "$12.24"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "149439",
            "Clicks": "6537",
            "Conversions": "51",
            "Conversion Value": "$4,014",
            "Cost per Result": "$35.52"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "309477",
            "Clicks": "5046",
            "Conversions": "51",
            "Conversion Value": "$4,406",
            "Cost per Result": "$17.30"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "242578",
            "Clicks": "5857",
            "Conversions": "84",
            "Conversion Value": "$9,259",
            "Cost per Result": "$39.44"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "121510",
            "Clicks": "5111",
            "Conversions": "58",
            "Conversion Value": "$4,923",
            "Cost per Result": "$15.71"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "411164",
            "Clicks": "2131",
            "Conversions": "51",
            "Conversion Value": "$12,839",
            "Cost per Result": "$19.20"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "280261",
            "Clicks": "3939",
            "Conversions": "46",
            "Conversion Value": "$5,374",
            "Cost per Result": "$12.75"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "351543",
            "Clicks": "4366",
            "Conversions": "56",
            "Conversion Value": "$8,822",
            "Cost per Result": "$22.19"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "422695",
            "Clicks": "4233",
            "Conversions": "61",
            "Conversion Value": "$8,364",
            "Cost per Result": "$19.60"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "231415",
            "Clicks": "3237",
            "Conversions": "54",
            "Conversion Value": "$10,963",
            "Cost per Result": "$39.97"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "183798",
            "Clicks": "4060",
            "Conversions": "70",
            "Conversion Value": "$14,304",
            "Cost per Result": "$11.96"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "409772",
            "Clicks": "6300",
            "Conversions": "40",
            "Conversion Value": "$9,977",
            "Cost per Result": "$31.11"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "279471",
            "Clicks": "4871",
            "Conversions": "64",
            "Conversion Value": "$10,060",
            "Cost per Result": "$11.11"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "458722",
            "Clicks": "3068",
            "Conversions": "89",
            "Conversion Value": "$13,091",
            "Cost per Result": "$10.30"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "409378",
            "Clicks": "6895",
            "Conversions": "65",
            "Conversion Value": "$14,022",
            "Cost per Result": "$32.53"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "165601",
            "Clicks": "3684",
            "Conversions": "71",
            "Conversion Value": "$11,653",
            "Cost per Result": "$11.47"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "481556",
            "Clicks": "2541",
            "Conversions": "49",
            "Conversion Value": "$15,898",
            "Cost per Result": "$38.44"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "398785",
            "Clicks": "3003",
            "Conversions": "86",
            "Conversion Value": "$12,837",
            "Cost per Result": "$27.41"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "316514",
            "Clicks": "6756",
            "Conversions": "51",
            "Conversion Value": "$12,065",
            "Cost per Result": "$26.67"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "201404",
            "Clicks": "4959",
            "Conversions": "82",
            "Conversion Value": "$13,510",
            "Cost per Result": "$39.67"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "206335",
            "Clicks": "3971",
            "Conversions": "59",
            "Conversion Value": "$12,513",
            "Cost per Result": "$33.18"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "263718",
            "Clicks": "5349",
            "Conversions": "90",
            "Conversion Value": "$5,398",
            "Cost per Result": "$35.76"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "242127",
            "Clicks": "3181",
            "Conversions": "61",
            "Conversion Value": "$13,968",
            "Cost per Result": "$21.84"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "428507",
            "Clicks": "3481",
            "Conversions": "54",
            "Conversion Value": "$12,624",
            "Cost per Result": "$23.36"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "370483",
            "Clicks": "3527",
            "Conversions": "73",
            "Conversion Value": "$10,303",
            "Cost per Result": "$19.64"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "197249",
            "Clicks": "3059",
            "Conversions": "63",
            "Conversion Value": "$8,678",
            "Cost per Result": "$29.87"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "417140",
            "Clicks": "6079",
            "Conversions": "45",
            "Conversion Value": "$15,377",
            "Cost per Result": "$16.83"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "201703",
            "Clicks": "4794",
            "Conversions": "57",
            "Conversion Value": "$13,662",
            "Cost per Result": "$30.50"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "375695",
            "Clicks": "3076",
            "Conversions": "67",
            "Conversion Value": "$8,514",
            "Cost per Result": "$39.63"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "240955",
            "Clicks": "4456",
            "Conversions": "61",
            "Conversion Value": "$7,096",
            "Cost per Result": "$16.97"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "184266",
            "Clicks": "6619",
            "Conversions": "52",
            "Conversion Value": "$15,377",
            "Cost per Result": "$23.60"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "294430",
            "Clicks": "5719",
            "Conversions": "81",
            "Conversion Value": "$6,534",
            "Cost per Result": "$36.41"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "317850",
            "Clicks": "6655",
            "Conversions": "82",
            "Conversion Value": "$11,300",
            "Cost per Result": "$20.79"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "324244",
            "Clicks": "2402",
            "Conversions": "55",
            "Conversion Value": "$6,345",
            "Cost per Result": "$12.13"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "454442",
            "Clicks": "5456",
            "Conversions": "63",
            "Conversion Value": "$7,685",
            "Cost per Result": "$15.49"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "274502",
            "Clicks": "5686",
            "Conversions": "46",
            "Conversion Value": "$5,945",
            "Cost per Result": "$17.37"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "105849",
            "Clicks": "6642",
            "Conversions": "70",
            "Conversion Value": "$7,901",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "140812",
            "Clicks": "2052",
            "Conversions": "55",
            "Conversion Value": "$11,142",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "447583",
            "Clicks": "3038",
            "Conversions": "83",
            "Conversion Value": "$15,562",
            "Cost per Result": "$21.39"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "381651",
            "Clicks": "3946",
            "Conversions": "76",
            "Conversion Value": "$5,546",
            "Cost per Result": "$12.33"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "275837",
            "Clicks": "5385",
            "Conversions": "74",
            "Conversion Value": "$15,225",
            "Cost per Result": "$21.27"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "416924",
            "Clicks": "4250",
            "Conversions": "73",
            "Conversion Value": "$5,759",
            "Cost per Result": "$27.45"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "434016",
            "Clicks": "4436",
            "Conversions": "62",
            "Conversion Value": "$8,355",
            "Cost per Result": "$30.27"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "431923",
            "Clicks": "5247",
            "Conversions": "45",
            "Conversion Value": "$11,512",
            "Cost per Result": "$32.71"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "188452",
            "Clicks": "5561",
            "Conversions": "49",
            "Conversion Value": "$15,088",
            "Cost per Result": "$12.14"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "381925",
            "Clicks": "2891",
            "Conversions": "47",
            "Conversion Value": "$4,719",
            "Cost per Result": "$24.21"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "226179",
            "Clicks": "3310",
            "Conversions": "40",
            "Conversion Value": "$4,530",
            "Cost per Result": "$23.59"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "360331",
            "Clicks": "6333",
            "Conversions": "51",
            "Conversion Value": "$10,426",
            "Cost per Result": "$22.98"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "161248",
            "Clicks": "3900",
            "Conversions": "59",
            "Conversion Value": "$5,264",
            "Cost per Result": "$11.53"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "418978",
            "Clicks": "4076",
            "Conversions": "77",
            "Conversion Value": "$8,479",
            "Cost per Result": "$18.32"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "165846",
            "Clicks": "6435",
            "Conversions": "58",
            "Conversion Value": "$15,818",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "161964",
            "Clicks": "6138",
            "Conversions": "71",
            "Conversion Value": "$6,255",
            "Cost per Result": "$12.79"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "115316",
            "Clicks": "6309",
            "Conversions": "76",
            "Conversion Value": "$7,224",
            "Cost per Result": "$14.64"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "304092",
            "Clicks": "2677",
            "Conversions": "64",
            "Conversion Value": "$14,288",
            "Cost per Result": "$31.49"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "110372",
            "Clicks": "6405",
            "Conversions": "82",
            "Conversion Value": "$11,841",
            "Cost per Result": "$26.27"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "403120",
            "Clicks": "3865",
            "Conversions": "60",
            "Conversion Value": "$14,941",
            "Cost per Result": "$32.70"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "104649",
            "Clicks": "2791",
            "Conversions": "54",
            "Conversion Value": "$6,069",
            "Cost per Result": "$24.22"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "134092",
            "Clicks": "3026",
            "Conversions": "43",
            "Conversion Value": "$15,604",
            "Cost per Result": "$18.60"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "442132",
            "Clicks": "6009",
            "Conversions": "74",
            "Conversion Value": "$8,097",
            "Cost per Result": "$23.21"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "147815",
            "Clicks": "6253",
            "Conversions": "40",
            "Conversion Value": "$8,827",
            "Cost per Result": "$33.60"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "448713",
            "Clicks": "3086",
            "Conversions": "47",
            "Conversion Value": "$7,661",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "199247",
            "Clicks": "4789",
            "Conversions": "57",
            "Conversion Value": "$14,566",
            "Cost per Result": "$27.12"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "208534",
            "Clicks": "3059",
            "Conversions": "80",
            "Conversion Value": "$14,637",
            "Cost per Result": "$16.05"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "281386",
            "Clicks": "3163",
            "Conversions": "44",
            "Conversion Value": "$4,660",
            "Cost per Result": "$30.77"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "303142",
            "Clicks": "3342",
            "Conversions": "72",
            "Conversion Value": "$12,340",
            "Cost per Result": "$22.52"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "473875",
            "Clicks": "2733",
            "Conversions": "64",
            "Conversion Value": "$14,953",
            "Cost per Result": "$20.47"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "345712",
            "Clicks": "4361",
            "Conversions": "50",
            "Conversion Value": "$8,064",
            "Cost per Result": "$18.05"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "411707",
            "Clicks": "4355",
            "Conversions": "45",
            "Conversion Value": "$8,273",
            "Cost per Result": "$20.18"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "180454",
            "Clicks": "5716",
            "Conversions": "56",
            "Conversion Value": "$7,815",
            "Cost per Result": "$17.11"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "478284",
            "Clicks": "5019",
            "Conversions": "85",
            "Conversion Value": "$9,925",
            "Cost per Result": "$38.51"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "147171",
            "Clicks": "6863",
            "Conversions": "56",
            "Conversion Value": "$9,701",
            "Cost per Result": "$31.10"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "389883",
            "Clicks": "4651",
            "Conversions": "60",
            "Conversion Value": "$10,385",
            "Cost per Result": "$15.55"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "172090",
            "Clicks": "6292",
            "Conversions": "89",
            "Conversion Value": "$8,008",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "408039",
            "Clicks": "3841",
            "Conversions": "65",
            "Conversion Value": "$9,548",
            "Cost per Result": "$37.77"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "309389",
            "Clicks": "3023",
            "Conversions": "85",
            "Conversion Value": "$7,767",
            "Cost per Result": "$10.85"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "216106",
            "Clicks": "5023",
            "Conversions": "64",
            "Conversion Value": "$15,061",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "225089",
            "Clicks": "2297",
            "Conversions": "65",
            "Conversion Value": "$9,560",
            "Cost per Result": "$38.42"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "231560",
            "Clicks": "4753",
            "Conversions": "79",
            "Conversion Value": "$11,692",
            "Cost per Result": "$23.45"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "419573",
            "Clicks": "5822",
            "Conversions": "85",
            "Conversion Value": "$15,413",
            "Cost per Result": "$36.24"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "282681",
            "Clicks": "3048",
            "Conversions": "48",
            "Conversion Value": "$15,438",
            "Cost per Result": "$29.69"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "191111",
            "Clicks": "2195",
            "Conversions": "70",
            "Conversion Value": "$7,616",
            "Cost per Result": "$20.89"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "124686",
            "Clicks": "6748",
            "Conversions": "75",
            "Conversion Value": "$10,355",
            "Cost per Result": "$34.05"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "246468",
            "Clicks": "6891",
            "Conversions": "90",
            "Conversion Value": "$5,215",
            "Cost per Result": "$28.01"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "355079",
            "Clicks": "3000",
            "Conversions": "46",
            "Conversion Value": "$6,198",
            "Cost per Result": "$20.82"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "292516",
            "Clicks": "6870",
            "Conversions": "50",
            "Conversion Value": "$7,250",
            "Cost per Result": "$18.33"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "340900",
            "Clicks": "4233",
            "Conversions": "43",
            "Conversion Value": "$9,826",
            "Cost per Result": "$38.24"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "253325",
            "Clicks": "3733",
            "Conversions": "87",
            "Conversion Value": "$12,444",
            "Cost per Result": "$35.65"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "403163",
            "Clicks": "3415",
            "Conversions": "90",
            "Conversion Value": "$11,060",
            "Cost per Result": "$33.26"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "339635",
            "Clicks": "6769",
            "Conversions": "79",
            "Conversion Value": "$6,497",
            "Cost per Result": "$22.79"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "208470",
            "Clicks": "4371",
            "Conversions": "80",
            "Conversion Value": "$15,273",
            "Cost per Result": "$21.93"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "172830",
            "Clicks": "4067",
            "Conversions": "48",
            "Conversion Value": "$13,965",
            "Cost per Result": "$33.20"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "168621",
            "Clicks": "3289",
            "Conversions": "50",
            "Conversion Value": "$6,212",
            "Cost per Result": "$10.75"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "485325",
            "Clicks": "3595",
            "Conversions": "70",
            "Conversion Value": "$7,469",
            "Cost per Result": "$11.16"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "400883",
            "Clicks": "3832",
            "Conversions": "67",
            "Conversion Value": "$4,474",
            "Cost per Result": "$31.55"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "467012",
            "Clicks": "6920",
            "Conversions": "48",
            "Conversion Value": "$15,072",
            "Cost per Result": "$13.93"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "390893",
            "Clicks": "2206",
            "Conversions": "74",
            "Conversion Value": "$5,136",
            "Cost per Result": "$39.22"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "317363",
            "Clicks": "5841",
            "Conversions": "72",
            "Conversion Value": "$5,388",
            "Cost per Result": "$30.09"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "344473",
            "Clicks": "2963",
            "Conversions": "80",
            "Conversion Value": "$11,364",
            "Cost per Result": "$17.14"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "440838",
            "Clicks": "6201",
            "Conversions": "56",
            "Conversion Value": "$4,865",
            "Cost per Result": "$19.73"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "112779",
            "Clicks": "4606",
            "Conversions": "77",
            "Conversion Value": "$8,681",
            "Cost per Result": "$27.00"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "266185",
            "Clicks": "5209",
            "Conversions": "78",
            "Conversion Value": "$7,642",
            "Cost per Result": "$21.50"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "407263",
            "Clicks": "2476",
            "Conversions": "81",
            "Conversion Value": "$9,872",
            "Cost per Result": "$16.53"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "286412",
            "Clicks": "3154",
            "Conversions": "88",
            "Conversion Value": "$11,954",
            "Cost per Result": "$24.01"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "248190",
            "Clicks": "4071",
            "Conversions": "42",
            "Conversion Value": "$8,489",
            "Cost per Result": "$12.87"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "444914",
            "Clicks": "5806",
            "Conversions": "72",
            "Conversion Value": "$14,055",
            "Cost per Result": "$27.74"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "355609",
            "Clicks": "3261",
            "Conversions": "72",
            "Conversion Value": "$4,309",
            "Cost per Result": "$18.65"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "405402",
            "Clicks": "4255",
            "Conversions": "85",
            "Conversion Value": "$6,335",
            "Cost per Result": "$18.02"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "418368",
            "Clicks": "5017",
            "Conversions": "78",
            "Conversion Value": "$10,096",
            "Cost per Result": "$20.38"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "340593",
            "Clicks": "4739",
            "Conversions": "79",
            "Conversion Value": "$13,591",
            "Cost per Result": "$25.68"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "270299",
            "Clicks": "3657",
            "Conversions": "84",
            "Conversion Value": "$6,087",
            "Cost per Result": "$31.15"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "390507",
            "Clicks": "5829",
            "Conversions": "49",
            "Conversion Value": "$10,239",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "349196",
            "Clicks": "6368",
            "Conversions": "85",
            "Conversion Value": "$13,976",
            "Cost per Result": "$34.82"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "239990",
            "Clicks": "6679",
            "Conversions": "48",
            "Conversion Value": "$13,561",
            "Cost per Result": "$21.67"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "481353",
            "Clicks": "5508",
            "Conversions": "64",
            "Conversion Value": "$10,058",
            "Cost per Result": "$13.59"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "442508",
            "Clicks": "5916",
            "Conversions": "81",
            "Conversion Value": "$4,515",
            "Cost per Result": "$32.67"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "471095",
            "Clicks": "4886",
            "Conversions": "83",
            "Conversion Value": "$10,760",
            "Cost per Result": "$31.87"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "300279",
            "Clicks": "5758",
            "Conversions": "61",
            "Conversion Value": "$11,162",
            "Cost per Result": "$33.78"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "310839",
            "Clicks": "5664",
            "Conversions": "62",
            "Conversion Value": "$9,084",
            "Cost per Result": "$24.17"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "157610",
            "Clicks": "2696",
            "Conversions": "54",
            "Conversion Value": "$11,856",
            "Cost per Result": "$32.38"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "374264",
            "Clicks": "4610",
            "Conversions": "77",
            "Conversion Value": "$10,623",
            "Cost per Result": "$34.17"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "164369",
            "Clicks": "6163",
            "Conversions": "76",
            "Conversion Value": "$15,784",
            "Cost per Result": "$34.83"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "305614",
            "Clicks": "3129",
            "Conversions": "63",
            "Conversion Value": "$6,672",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "340526",
            "Clicks": "2055",
            "Conversions": "83",
            "Conversion Value": "$7,665",
            "Cost per Result": "$21.46"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "445921",
            "Clicks": "3444",
            "Conversions": "58",
            "Conversion Value": "$8,460",
            "Cost per Result": "$13.92"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "172608",
            "Clicks": "2661",
            "Conversions": "89",
            "Conversion Value": "$14,033",
            "Cost per Result": "$28.54"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "396945",
            "Clicks": "3959",
            "Conversions": "74",
            "Conversion Value": "$12,590",
            "Cost per Result": "$21.80"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "381929",
            "Clicks": "4746",
            "Conversions": "77",
            "Conversion Value": "$5,858",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "152478",
            "Clicks": "3333",
            "Conversions": "63",
            "Conversion Value": "$6,034",
            "Cost per Result": "$26.35"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "137029",
            "Clicks": "4334",
            "Conversions": "79",
            "Conversion Value": "$13,781",
            "Cost per Result": "$32.56"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "241577",
            "Clicks": "6103",
            "Conversions": "87",
            "Conversion Value": "$15,347",
            "Cost per Result": "$12.66"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "384608",
            "Clicks": "5964",
            "Conversions": "62",
            "Conversion Value": "$6,161",
            "Cost per Result": "$36.00"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "428441",
            "Clicks": "6618",
            "Conversions": "76",
            "Conversion Value": "$13,666",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "121266",
            "Clicks": "3738",
            "Conversions": "54",
            "Conversion Value": "$12,794",
            "Cost per Result": "$15.43"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "278886",
            "Clicks": "4865",
            "Conversions": "41",
            "Conversion Value": "$14,265",
            "Cost per Result": "$37.40"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "430879",
            "Clicks": "2019",
            "Conversions": "45",
            "Conversion Value": "$15,666",
            "Cost per Result": "$20.63"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "295120",
            "Clicks": "3078",
            "Conversions": "77",
            "Conversion Value": "$9,298",
            "Cost per Result": "$27.37"
          }
        ]
      },
      "StableCoinUsersData": {
        "name": "Stable Coin Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "487491",
            "Clicks": "4035",
            "Conversions": "60",
            "Conversion Value": "$7,742",
            "Cost per Result": "$30.21"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "452779",
            "Clicks": "2035",
            "Conversions": "71",
            "Conversion Value": "$11,196",
            "Cost per Result": "$18.92"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "181915",
            "Clicks": "3377",
            "Conversions": "77",
            "Conversion Value": "$5,012",
            "Cost per Result": "$20.83"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "298767",
            "Clicks": "2496",
            "Conversions": "81",
            "Conversion Value": "$14,275",
            "Cost per Result": "$24.30"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "414522",
            "Clicks": "5397",
            "Conversions": "80",
            "Conversion Value": "$14,646",
            "Cost per Result": "$35.28"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "154180",
            "Clicks": "6707",
            "Conversions": "60",
            "Conversion Value": "$8,711",
            "Cost per Result": "$34.22"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "469272",
            "Clicks": "6837",
            "Conversions": "56",
            "Conversion Value": "$10,147",
            "Cost per Result": "$13.73"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "421372",
            "Clicks": "5320",
            "Conversions": "82",
            "Conversion Value": "$4,696",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "276986",
            "Clicks": "3786",
            "Conversions": "85",
            "Conversion Value": "$5,614",
            "Cost per Result": "$12.94"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "183624",
            "Clicks": "4753",
            "Conversions": "68",
            "Conversion Value": "$15,415",
            "Cost per Result": "$20.24"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "245430",
            "Clicks": "2380",
            "Conversions": "41",
            "Conversion Value": "$8,184",
            "Cost per Result": "$36.03"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "449321",
            "Clicks": "3528",
            "Conversions": "60",
            "Conversion Value": "$13,900",
            "Cost per Result": "$35.15"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "244296",
            "Clicks": "5674",
            "Conversions": "44",
            "Conversion Value": "$14,194",
            "Cost per Result": "$28.36"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "375100",
            "Clicks": "5800",
            "Conversions": "80",
            "Conversion Value": "$14,543",
            "Cost per Result": "$18.72"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "297122",
            "Clicks": "6682",
            "Conversions": "54",
            "Conversion Value": "$14,143",
            "Cost per Result": "$12.58"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "268348",
            "Clicks": "4284",
            "Conversions": "68",
            "Conversion Value": "$14,315",
            "Cost per Result": "$25.49"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "278079",
            "Clicks": "5825",
            "Conversions": "77",
            "Conversion Value": "$6,443",
            "Cost per Result": "$25.92"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "143972",
            "Clicks": "3984",
            "Conversions": "50",
            "Conversion Value": "$13,153",
            "Cost per Result": "$28.75"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "433330",
            "Clicks": "2781",
            "Conversions": "81",
            "Conversion Value": "$10,702",
            "Cost per Result": "$25.13"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "367322",
            "Clicks": "4291",
            "Conversions": "86",
            "Conversion Value": "$11,445",
            "Cost per Result": "$35.47"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "427576",
            "Clicks": "4558",
            "Conversions": "90",
            "Conversion Value": "$15,257",
            "Cost per Result": "$16.78"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "290823",
            "Clicks": "4562",
            "Conversions": "66",
            "Conversion Value": "$15,689",
            "Cost per Result": "$19.31"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "270832",
            "Clicks": "4212",
            "Conversions": "51",
            "Conversion Value": "$4,393",
            "Cost per Result": "$20.37"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "303705",
            "Clicks": "5876",
            "Conversions": "89",
            "Conversion Value": "$5,159",
            "Cost per Result": "$25.06"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "454210",
            "Clicks": "4191",
            "Conversions": "79",
            "Conversion Value": "$12,226",
            "Cost per Result": "$19.75"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "261213",
            "Clicks": "4334",
            "Conversions": "71",
            "Conversion Value": "$5,189",
            "Cost per Result": "$26.57"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "493397",
            "Clicks": "2452",
            "Conversions": "76",
            "Conversion Value": "$5,033",
            "Cost per Result": "$28.35"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "221811",
            "Clicks": "6158",
            "Conversions": "82",
            "Conversion Value": "$14,307",
            "Cost per Result": "$15.56"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "352881",
            "Clicks": "4075",
            "Conversions": "69",
            "Conversion Value": "$5,052",
            "Cost per Result": "$28.90"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "359498",
            "Clicks": "2599",
            "Conversions": "71",
            "Conversion Value": "$11,230",
            "Cost per Result": "$13.01"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "242192",
            "Clicks": "5695",
            "Conversions": "75",
            "Conversion Value": "$15,332",
            "Cost per Result": "$39.93"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "476058",
            "Clicks": "3852",
            "Conversions": "43",
            "Conversion Value": "$13,337",
            "Cost per Result": "$26.34"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "308214",
            "Clicks": "5050",
            "Conversions": "57",
            "Conversion Value": "$5,573",
            "Cost per Result": "$34.27"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "138624",
            "Clicks": "2077",
            "Conversions": "63",
            "Conversion Value": "$4,854",
            "Cost per Result": "$17.89"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "296957",
            "Clicks": "4981",
            "Conversions": "65",
            "Conversion Value": "$4,598",
            "Cost per Result": "$37.85"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "416522",
            "Clicks": "3235",
            "Conversions": "41",
            "Conversion Value": "$10,204",
            "Cost per Result": "$13.53"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "157229",
            "Clicks": "5092",
            "Conversions": "84",
            "Conversion Value": "$13,398",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "174173",
            "Clicks": "2724",
            "Conversions": "50",
            "Conversion Value": "$7,772",
            "Cost per Result": "$20.03"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "471904",
            "Clicks": "3858",
            "Conversions": "46",
            "Conversion Value": "$8,349",
            "Cost per Result": "$38.34"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "261638",
            "Clicks": "3038",
            "Conversions": "67",
            "Conversion Value": "$7,306",
            "Cost per Result": "$10.83"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "125560",
            "Clicks": "3453",
            "Conversions": "53",
            "Conversion Value": "$10,974",
            "Cost per Result": "$20.71"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "279737",
            "Clicks": "5659",
            "Conversions": "86",
            "Conversion Value": "$11,992",
            "Cost per Result": "$35.46"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "377316",
            "Clicks": "4847",
            "Conversions": "79",
            "Conversion Value": "$8,347",
            "Cost per Result": "$20.12"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "113011",
            "Clicks": "4637",
            "Conversions": "79",
            "Conversion Value": "$7,535",
            "Cost per Result": "$39.73"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "462601",
            "Clicks": "5601",
            "Conversions": "79",
            "Conversion Value": "$7,569",
            "Cost per Result": "$10.02"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "132057",
            "Clicks": "4480",
            "Conversions": "74",
            "Conversion Value": "$4,865",
            "Cost per Result": "$23.76"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "187208",
            "Clicks": "5189",
            "Conversions": "66",
            "Conversion Value": "$13,243",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "359845",
            "Clicks": "3676",
            "Conversions": "65",
            "Conversion Value": "$7,739",
            "Cost per Result": "$19.16"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "421612",
            "Clicks": "5794",
            "Conversions": "50",
            "Conversion Value": "$6,222",
            "Cost per Result": "$20.25"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "248174",
            "Clicks": "2101",
            "Conversions": "52",
            "Conversion Value": "$15,134",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "305420",
            "Clicks": "5503",
            "Conversions": "48",
            "Conversion Value": "$6,470",
            "Cost per Result": "$37.31"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "375591",
            "Clicks": "2417",
            "Conversions": "58",
            "Conversion Value": "$9,987",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "414076",
            "Clicks": "3284",
            "Conversions": "40",
            "Conversion Value": "$11,149",
            "Cost per Result": "$29.76"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "293148",
            "Clicks": "2839",
            "Conversions": "42",
            "Conversion Value": "$6,123",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "236914",
            "Clicks": "3252",
            "Conversions": "82",
            "Conversion Value": "$11,447",
            "Cost per Result": "$13.46"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "327007",
            "Clicks": "6481",
            "Conversions": "89",
            "Conversion Value": "$8,989",
            "Cost per Result": "$21.80"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "313233",
            "Clicks": "6672",
            "Conversions": "78",
            "Conversion Value": "$15,396",
            "Cost per Result": "$13.98"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "195781",
            "Clicks": "6929",
            "Conversions": "84",
            "Conversion Value": "$14,629",
            "Cost per Result": "$20.08"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "348065",
            "Clicks": "3144",
            "Conversions": "47",
            "Conversion Value": "$8,316",
            "Cost per Result": "$17.23"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "495867",
            "Clicks": "4315",
            "Conversions": "69",
            "Conversion Value": "$5,862",
            "Cost per Result": "$39.53"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "189987",
            "Clicks": "4162",
            "Conversions": "51",
            "Conversion Value": "$5,719",
            "Cost per Result": "$27.86"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "311056",
            "Clicks": "2796",
            "Conversions": "43",
            "Conversion Value": "$8,291",
            "Cost per Result": "$14.79"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "339113",
            "Clicks": "3903",
            "Conversions": "64",
            "Conversion Value": "$5,674",
            "Cost per Result": "$36.84"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "156161",
            "Clicks": "5689",
            "Conversions": "41",
            "Conversion Value": "$6,155",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "435048",
            "Clicks": "5099",
            "Conversions": "62",
            "Conversion Value": "$9,727",
            "Cost per Result": "$27.22"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "291850",
            "Clicks": "6423",
            "Conversions": "48",
            "Conversion Value": "$14,456",
            "Cost per Result": "$32.50"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "353962",
            "Clicks": "5153",
            "Conversions": "56",
            "Conversion Value": "$4,642",
            "Cost per Result": "$19.42"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "154711",
            "Clicks": "6740",
            "Conversions": "52",
            "Conversion Value": "$7,624",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "254366",
            "Clicks": "5401",
            "Conversions": "87",
            "Conversion Value": "$5,251",
            "Cost per Result": "$31.89"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "418837",
            "Clicks": "6835",
            "Conversions": "87",
            "Conversion Value": "$7,488",
            "Cost per Result": "$20.65"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "316922",
            "Clicks": "4526",
            "Conversions": "88",
            "Conversion Value": "$15,561",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "310668",
            "Clicks": "3628",
            "Conversions": "84",
            "Conversion Value": "$14,730",
            "Cost per Result": "$32.84"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "120664",
            "Clicks": "6239",
            "Conversions": "76",
            "Conversion Value": "$13,730",
            "Cost per Result": "$28.42"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "286255",
            "Clicks": "2351",
            "Conversions": "69",
            "Conversion Value": "$5,458",
            "Cost per Result": "$30.25"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "312970",
            "Clicks": "3471",
            "Conversions": "58",
            "Conversion Value": "$7,318",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "179479",
            "Clicks": "4633",
            "Conversions": "53",
            "Conversion Value": "$12,143",
            "Cost per Result": "$20.78"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "122018",
            "Clicks": "6595",
            "Conversions": "86",
            "Conversion Value": "$12,063",
            "Cost per Result": "$37.51"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "352635",
            "Clicks": "3482",
            "Conversions": "72",
            "Conversion Value": "$4,865",
            "Cost per Result": "$22.08"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "417539",
            "Clicks": "5674",
            "Conversions": "79",
            "Conversion Value": "$5,938",
            "Cost per Result": "$36.94"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "419471",
            "Clicks": "6614",
            "Conversions": "86",
            "Conversion Value": "$8,805",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "269181",
            "Clicks": "6121",
            "Conversions": "57",
            "Conversion Value": "$12,952",
            "Cost per Result": "$10.43"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "339313",
            "Clicks": "5478",
            "Conversions": "72",
            "Conversion Value": "$13,729",
            "Cost per Result": "$26.10"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "333810",
            "Clicks": "4420",
            "Conversions": "65",
            "Conversion Value": "$12,565",
            "Cost per Result": "$28.02"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "354261",
            "Clicks": "6137",
            "Conversions": "60",
            "Conversion Value": "$7,112",
            "Cost per Result": "$11.38"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "306536",
            "Clicks": "5085",
            "Conversions": "53",
            "Conversion Value": "$10,196",
            "Cost per Result": "$30.53"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "395966",
            "Clicks": "6437",
            "Conversions": "59",
            "Conversion Value": "$8,906",
            "Cost per Result": "$11.89"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "309938",
            "Clicks": "4747",
            "Conversions": "81",
            "Conversion Value": "$9,331",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "141813",
            "Clicks": "3172",
            "Conversions": "80",
            "Conversion Value": "$6,834",
            "Cost per Result": "$29.41"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "217271",
            "Clicks": "3020",
            "Conversions": "40",
            "Conversion Value": "$6,690",
            "Cost per Result": "$19.36"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "351182",
            "Clicks": "5476",
            "Conversions": "82",
            "Conversion Value": "$14,887",
            "Cost per Result": "$16.23"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "285574",
            "Clicks": "2700",
            "Conversions": "60",
            "Conversion Value": "$12,865",
            "Cost per Result": "$22.45"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "441014",
            "Clicks": "6221",
            "Conversions": "74",
            "Conversion Value": "$8,144",
            "Cost per Result": "$14.37"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "421551",
            "Clicks": "2137",
            "Conversions": "64",
            "Conversion Value": "$11,076",
            "Cost per Result": "$33.26"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "427429",
            "Clicks": "4977",
            "Conversions": "64",
            "Conversion Value": "$10,608",
            "Cost per Result": "$30.97"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "456527",
            "Clicks": "2575",
            "Conversions": "89",
            "Conversion Value": "$11,298",
            "Cost per Result": "$27.39"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "209226",
            "Clicks": "6920",
            "Conversions": "50",
            "Conversion Value": "$8,919",
            "Cost per Result": "$23.43"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "237191",
            "Clicks": "5448",
            "Conversions": "78",
            "Conversion Value": "$12,056",
            "Cost per Result": "$23.85"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "323603",
            "Clicks": "6081",
            "Conversions": "69",
            "Conversion Value": "$9,972",
            "Cost per Result": "$21.72"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "248342",
            "Clicks": "3594",
            "Conversions": "83",
            "Conversion Value": "$5,634",
            "Cost per Result": "$37.44"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "269364",
            "Clicks": "4672",
            "Conversions": "67",
            "Conversion Value": "$6,001",
            "Cost per Result": "$21.10"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "105547",
            "Clicks": "3042",
            "Conversions": "76",
            "Conversion Value": "$13,306",
            "Cost per Result": "$26.79"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "154683",
            "Clicks": "2634",
            "Conversions": "79",
            "Conversion Value": "$6,781",
            "Cost per Result": "$14.52"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "454046",
            "Clicks": "5049",
            "Conversions": "78",
            "Conversion Value": "$10,219",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "152601",
            "Clicks": "2590",
            "Conversions": "64",
            "Conversion Value": "$8,620",
            "Cost per Result": "$31.13"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "180984",
            "Clicks": "2938",
            "Conversions": "84",
            "Conversion Value": "$8,725",
            "Cost per Result": "$35.04"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "386811",
            "Clicks": "5587",
            "Conversions": "78",
            "Conversion Value": "$9,708",
            "Cost per Result": "$14.09"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "218216",
            "Clicks": "3615",
            "Conversions": "65",
            "Conversion Value": "$9,779",
            "Cost per Result": "$30.03"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "452901",
            "Clicks": "5123",
            "Conversions": "83",
            "Conversion Value": "$6,479",
            "Cost per Result": "$23.29"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "340560",
            "Clicks": "5449",
            "Conversions": "71",
            "Conversion Value": "$5,469",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "217493",
            "Clicks": "2349",
            "Conversions": "65",
            "Conversion Value": "$5,697",
            "Cost per Result": "$23.55"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "239064",
            "Clicks": "3161",
            "Conversions": "48",
            "Conversion Value": "$6,143",
            "Cost per Result": "$18.77"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "122154",
            "Clicks": "5031",
            "Conversions": "67",
            "Conversion Value": "$5,265",
            "Cost per Result": "$18.58"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "491225",
            "Clicks": "2076",
            "Conversions": "50",
            "Conversion Value": "$8,745",
            "Cost per Result": "$24.95"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "424409",
            "Clicks": "4035",
            "Conversions": "68",
            "Conversion Value": "$12,842",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "412084",
            "Clicks": "6109",
            "Conversions": "77",
            "Conversion Value": "$8,234",
            "Cost per Result": "$32.80"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "189764",
            "Clicks": "3014",
            "Conversions": "60",
            "Conversion Value": "$12,389",
            "Cost per Result": "$18.05"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "435035",
            "Clicks": "6512",
            "Conversions": "55",
            "Conversion Value": "$11,787",
            "Cost per Result": "$38.44"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "342645",
            "Clicks": "2581",
            "Conversions": "90",
            "Conversion Value": "$12,314",
            "Cost per Result": "$16.15"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "153346",
            "Clicks": "6109",
            "Conversions": "89",
            "Conversion Value": "$12,301",
            "Cost per Result": "$26.24"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "308103",
            "Clicks": "2172",
            "Conversions": "74",
            "Conversion Value": "$5,748",
            "Cost per Result": "$17.42"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "298603",
            "Clicks": "6463",
            "Conversions": "85",
            "Conversion Value": "$6,760",
            "Cost per Result": "$38.59"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "129036",
            "Clicks": "4676",
            "Conversions": "62",
            "Conversion Value": "$7,318",
            "Cost per Result": "$17.54"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "417429",
            "Clicks": "4561",
            "Conversions": "53",
            "Conversion Value": "$13,605",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "305361",
            "Clicks": "6653",
            "Conversions": "63",
            "Conversion Value": "$4,160",
            "Cost per Result": "$10.61"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "441205",
            "Clicks": "5653",
            "Conversions": "89",
            "Conversion Value": "$15,490",
            "Cost per Result": "$37.49"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "433562",
            "Clicks": "5102",
            "Conversions": "79",
            "Conversion Value": "$5,330",
            "Cost per Result": "$31.43"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "364430",
            "Clicks": "3306",
            "Conversions": "51",
            "Conversion Value": "$5,897",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "369859",
            "Clicks": "4289",
            "Conversions": "53",
            "Conversion Value": "$7,791",
            "Cost per Result": "$11.76"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "226654",
            "Clicks": "5545",
            "Conversions": "54",
            "Conversion Value": "$13,003",
            "Cost per Result": "$32.60"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "421638",
            "Clicks": "3579",
            "Conversions": "68",
            "Conversion Value": "$14,018",
            "Cost per Result": "$38.70"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "332498",
            "Clicks": "2614",
            "Conversions": "41",
            "Conversion Value": "$13,013",
            "Cost per Result": "$32.56"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "194994",
            "Clicks": "6793",
            "Conversions": "87",
            "Conversion Value": "$14,523",
            "Cost per Result": "$15.38"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "477270",
            "Clicks": "6018",
            "Conversions": "77",
            "Conversion Value": "$5,777",
            "Cost per Result": "$23.99"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "177424",
            "Clicks": "6437",
            "Conversions": "89",
            "Conversion Value": "$5,284",
            "Cost per Result": "$15.68"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "236709",
            "Clicks": "2473",
            "Conversions": "74",
            "Conversion Value": "$13,791",
            "Cost per Result": "$21.55"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "181557",
            "Clicks": "3048",
            "Conversions": "57",
            "Conversion Value": "$7,667",
            "Cost per Result": "$10.39"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "423966",
            "Clicks": "3519",
            "Conversions": "59",
            "Conversion Value": "$14,427",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "467131",
            "Clicks": "2469",
            "Conversions": "46",
            "Conversion Value": "$15,777",
            "Cost per Result": "$31.32"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "342136",
            "Clicks": "6056",
            "Conversions": "70",
            "Conversion Value": "$7,258",
            "Cost per Result": "$16.15"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "224239",
            "Clicks": "6371",
            "Conversions": "63",
            "Conversion Value": "$11,757",
            "Cost per Result": "$33.33"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "124692",
            "Clicks": "3928",
            "Conversions": "56",
            "Conversion Value": "$13,206",
            "Cost per Result": "$22.73"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "452602",
            "Clicks": "6179",
            "Conversions": "90",
            "Conversion Value": "$11,943",
            "Cost per Result": "$28.66"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "348351",
            "Clicks": "2142",
            "Conversions": "62",
            "Conversion Value": "$15,665",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "101729",
            "Clicks": "5172",
            "Conversions": "71",
            "Conversion Value": "$6,364",
            "Cost per Result": "$30.85"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "444153",
            "Clicks": "5735",
            "Conversions": "86",
            "Conversion Value": "$12,317",
            "Cost per Result": "$23.08"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "219312",
            "Clicks": "6137",
            "Conversions": "53",
            "Conversion Value": "$13,450",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "474603",
            "Clicks": "3261",
            "Conversions": "53",
            "Conversion Value": "$14,407",
            "Cost per Result": "$38.66"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "444872",
            "Clicks": "3333",
            "Conversions": "48",
            "Conversion Value": "$5,012",
            "Cost per Result": "$21.42"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "370939",
            "Clicks": "6914",
            "Conversions": "88",
            "Conversion Value": "$13,376",
            "Cost per Result": "$13.77"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "319225",
            "Clicks": "6655",
            "Conversions": "80",
            "Conversion Value": "$12,208",
            "Cost per Result": "$14.49"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "349338",
            "Clicks": "3235",
            "Conversions": "87",
            "Conversion Value": "$10,307",
            "Cost per Result": "$10.29"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "274938",
            "Clicks": "6717",
            "Conversions": "59",
            "Conversion Value": "$15,165",
            "Cost per Result": "$29.83"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "493928",
            "Clicks": "6301",
            "Conversions": "43",
            "Conversion Value": "$11,772",
            "Cost per Result": "$37.24"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "127789",
            "Clicks": "2032",
            "Conversions": "65",
            "Conversion Value": "$9,731",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "384682",
            "Clicks": "6878",
            "Conversions": "40",
            "Conversion Value": "$6,909",
            "Cost per Result": "$32.40"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "184153",
            "Clicks": "5698",
            "Conversions": "71",
            "Conversion Value": "$8,158",
            "Cost per Result": "$27.06"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "267960",
            "Clicks": "2125",
            "Conversions": "56",
            "Conversion Value": "$13,779",
            "Cost per Result": "$18.34"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "275688",
            "Clicks": "4656",
            "Conversions": "72",
            "Conversion Value": "$10,785",
            "Cost per Result": "$10.04"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "355975",
            "Clicks": "5591",
            "Conversions": "47",
            "Conversion Value": "$5,604",
            "Cost per Result": "$21.81"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "173172",
            "Clicks": "5267",
            "Conversions": "62",
            "Conversion Value": "$4,638",
            "Cost per Result": "$31.86"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "469577",
            "Clicks": "6827",
            "Conversions": "74",
            "Conversion Value": "$9,376",
            "Cost per Result": "$29.18"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "139766",
            "Clicks": "5884",
            "Conversions": "85",
            "Conversion Value": "$12,465",
            "Cost per Result": "$30.19"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "308396",
            "Clicks": "5715",
            "Conversions": "72",
            "Conversion Value": "$6,377",
            "Cost per Result": "$19.00"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "365303",
            "Clicks": "4268",
            "Conversions": "45",
            "Conversion Value": "$5,656",
            "Cost per Result": "$38.11"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "176732",
            "Clicks": "3205",
            "Conversions": "74",
            "Conversion Value": "$5,650",
            "Cost per Result": "$12.67"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "163915",
            "Clicks": "2210",
            "Conversions": "68",
            "Conversion Value": "$14,837",
            "Cost per Result": "$20.49"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "477147",
            "Clicks": "6550",
            "Conversions": "73",
            "Conversion Value": "$7,861",
            "Cost per Result": "$11.04"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "484840",
            "Clicks": "3586",
            "Conversions": "88",
            "Conversion Value": "$8,030",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "102148",
            "Clicks": "2495",
            "Conversions": "86",
            "Conversion Value": "$4,456",
            "Cost per Result": "$39.14"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "229489",
            "Clicks": "3430",
            "Conversions": "64",
            "Conversion Value": "$8,206",
            "Cost per Result": "$15.01"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "434650",
            "Clicks": "2130",
            "Conversions": "71",
            "Conversion Value": "$11,095",
            "Cost per Result": "$24.44"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "116950",
            "Clicks": "4657",
            "Conversions": "67",
            "Conversion Value": "$6,955",
            "Cost per Result": "$36.76"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "368173",
            "Clicks": "4924",
            "Conversions": "60",
            "Conversion Value": "$5,714",
            "Cost per Result": "$38.96"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "206191",
            "Clicks": "4627",
            "Conversions": "58",
            "Conversion Value": "$14,860",
            "Cost per Result": "$29.64"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "367655",
            "Clicks": "5000",
            "Conversions": "82",
            "Conversion Value": "$5,046",
            "Cost per Result": "$11.43"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "359142",
            "Clicks": "4493",
            "Conversions": "81",
            "Conversion Value": "$5,014",
            "Cost per Result": "$12.80"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "431670",
            "Clicks": "2914",
            "Conversions": "46",
            "Conversion Value": "$14,263",
            "Cost per Result": "$37.31"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "388164",
            "Clicks": "4636",
            "Conversions": "71",
            "Conversion Value": "$15,730",
            "Cost per Result": "$27.45"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "439088",
            "Clicks": "6391",
            "Conversions": "87",
            "Conversion Value": "$10,407",
            "Cost per Result": "$27.56"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "129395",
            "Clicks": "6532",
            "Conversions": "57",
            "Conversion Value": "$14,867",
            "Cost per Result": "$32.61"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "250739",
            "Clicks": "6160",
            "Conversions": "59",
            "Conversion Value": "$7,639",
            "Cost per Result": "$19.84"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "156368",
            "Clicks": "5267",
            "Conversions": "78",
            "Conversion Value": "$7,028",
            "Cost per Result": "$19.90"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "403949",
            "Clicks": "5502",
            "Conversions": "60",
            "Conversion Value": "$13,591",
            "Cost per Result": "$39.60"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "313666",
            "Clicks": "3011",
            "Conversions": "41",
            "Conversion Value": "$6,826",
            "Cost per Result": "$10.44"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "130702",
            "Clicks": "6419",
            "Conversions": "55",
            "Conversion Value": "$4,435",
            "Cost per Result": "$35.55"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "107159",
            "Clicks": "2961",
            "Conversions": "45",
            "Conversion Value": "$8,248",
            "Cost per Result": "$11.37"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "225514",
            "Clicks": "5773",
            "Conversions": "77",
            "Conversion Value": "$7,072",
            "Cost per Result": "$31.76"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "115480",
            "Clicks": "4239",
            "Conversions": "58",
            "Conversion Value": "$12,425",
            "Cost per Result": "$38.22"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "395027",
            "Clicks": "3022",
            "Conversions": "59",
            "Conversion Value": "$14,108",
            "Cost per Result": "$20.54"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "448275",
            "Clicks": "2781",
            "Conversions": "86",
            "Conversion Value": "$9,219",
            "Cost per Result": "$34.54"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "177543",
            "Clicks": "3369",
            "Conversions": "47",
            "Conversion Value": "$9,333",
            "Cost per Result": "$33.72"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "412993",
            "Clicks": "3079",
            "Conversions": "47",
            "Conversion Value": "$10,718",
            "Cost per Result": "$37.20"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "297563",
            "Clicks": "2787",
            "Conversions": "52",
            "Conversion Value": "$9,126",
            "Cost per Result": "$27.79"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "287318",
            "Clicks": "3554",
            "Conversions": "65",
            "Conversion Value": "$12,072",
            "Cost per Result": "$33.61"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "220153",
            "Clicks": "2410",
            "Conversions": "41",
            "Conversion Value": "$7,705",
            "Cost per Result": "$26.77"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "233888",
            "Clicks": "4227",
            "Conversions": "69",
            "Conversion Value": "$15,714",
            "Cost per Result": "$17.52"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "425670",
            "Clicks": "5205",
            "Conversions": "42",
            "Conversion Value": "$11,328",
            "Cost per Result": "$30.14"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "124116",
            "Clicks": "2784",
            "Conversions": "68",
            "Conversion Value": "$13,975",
            "Cost per Result": "$21.82"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "101463",
            "Clicks": "2832",
            "Conversions": "55",
            "Conversion Value": "$10,224",
            "Cost per Result": "$15.02"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "226140",
            "Clicks": "2338",
            "Conversions": "78",
            "Conversion Value": "$10,769",
            "Cost per Result": "$24.46"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "239490",
            "Clicks": "5557",
            "Conversions": "66",
            "Conversion Value": "$10,121",
            "Cost per Result": "$19.38"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "207163",
            "Clicks": "5455",
            "Conversions": "85",
            "Conversion Value": "$8,424",
            "Cost per Result": "$38.41"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "464092",
            "Clicks": "5129",
            "Conversions": "90",
            "Conversion Value": "$4,401",
            "Cost per Result": "$35.45"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "432682",
            "Clicks": "5905",
            "Conversions": "89",
            "Conversion Value": "$15,128",
            "Cost per Result": "$25.50"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "350873",
            "Clicks": "4863",
            "Conversions": "47",
            "Conversion Value": "$14,863",
            "Cost per Result": "$20.92"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "425707",
            "Clicks": "2170",
            "Conversions": "59",
            "Conversion Value": "$4,621",
            "Cost per Result": "$18.99"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "425003",
            "Clicks": "6682",
            "Conversions": "57",
            "Conversion Value": "$5,406",
            "Cost per Result": "$25.35"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "370647",
            "Clicks": "4805",
            "Conversions": "62",
            "Conversion Value": "$14,986",
            "Cost per Result": "$32.12"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "466108",
            "Clicks": "6365",
            "Conversions": "74",
            "Conversion Value": "$11,335",
            "Cost per Result": "$23.20"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "282841",
            "Clicks": "4159",
            "Conversions": "89",
            "Conversion Value": "$12,171",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "249192",
            "Clicks": "6786",
            "Conversions": "66",
            "Conversion Value": "$9,669",
            "Cost per Result": "$27.50"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "170892",
            "Clicks": "3879",
            "Conversions": "42",
            "Conversion Value": "$14,578",
            "Cost per Result": "$14.33"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "199872",
            "Clicks": "3605",
            "Conversions": "60",
            "Conversion Value": "$6,621",
            "Cost per Result": "$32.52"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "231318",
            "Clicks": "5573",
            "Conversions": "66",
            "Conversion Value": "$7,787",
            "Cost per Result": "$10.56"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "452127",
            "Clicks": "6991",
            "Conversions": "74",
            "Conversion Value": "$4,273",
            "Cost per Result": "$31.98"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "351122",
            "Clicks": "2451",
            "Conversions": "42",
            "Conversion Value": "$9,719",
            "Cost per Result": "$25.64"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "413469",
            "Clicks": "5028",
            "Conversions": "57",
            "Conversion Value": "$4,031",
            "Cost per Result": "$27.69"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "252594",
            "Clicks": "2137",
            "Conversions": "55",
            "Conversion Value": "$8,188",
            "Cost per Result": "$20.09"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "142810",
            "Clicks": "4862",
            "Conversions": "41",
            "Conversion Value": "$5,767",
            "Cost per Result": "$29.96"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "275976",
            "Clicks": "5705",
            "Conversions": "89",
            "Conversion Value": "$9,106",
            "Cost per Result": "$19.34"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "138645",
            "Clicks": "4393",
            "Conversions": "88",
            "Conversion Value": "$5,898",
            "Cost per Result": "$34.66"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "420982",
            "Clicks": "4665",
            "Conversions": "43",
            "Conversion Value": "$14,548",
            "Cost per Result": "$33.75"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "458825",
            "Clicks": "2826",
            "Conversions": "82",
            "Conversion Value": "$14,118",
            "Cost per Result": "$27.98"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "300060",
            "Clicks": "2978",
            "Conversions": "80",
            "Conversion Value": "$7,357",
            "Cost per Result": "$36.06"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "119004",
            "Clicks": "5292",
            "Conversions": "52",
            "Conversion Value": "$13,125",
            "Cost per Result": "$39.76"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "118585",
            "Clicks": "6167",
            "Conversions": "85",
            "Conversion Value": "$5,095",
            "Cost per Result": "$21.55"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "346608",
            "Clicks": "4315",
            "Conversions": "88",
            "Conversion Value": "$8,565",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "394943",
            "Clicks": "5563",
            "Conversions": "82",
            "Conversion Value": "$8,404",
            "Cost per Result": "$35.34"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "134178",
            "Clicks": "6433",
            "Conversions": "43",
            "Conversion Value": "$4,286",
            "Cost per Result": "$20.15"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "353039",
            "Clicks": "2285",
            "Conversions": "53",
            "Conversion Value": "$13,439",
            "Cost per Result": "$30.77"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "104721",
            "Clicks": "2052",
            "Conversions": "90",
            "Conversion Value": "$9,974",
            "Cost per Result": "$24.10"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "328740",
            "Clicks": "2323",
            "Conversions": "81",
            "Conversion Value": "$4,281",
            "Cost per Result": "$32.11"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "367694",
            "Clicks": "2439",
            "Conversions": "48",
            "Conversion Value": "$9,930",
            "Cost per Result": "$12.69"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "478883",
            "Clicks": "6561",
            "Conversions": "80",
            "Conversion Value": "$13,105",
            "Cost per Result": "$33.77"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "234791",
            "Clicks": "2932",
            "Conversions": "86",
            "Conversion Value": "$5,030",
            "Cost per Result": "$14.88"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "396979",
            "Clicks": "5542",
            "Conversions": "43",
            "Conversion Value": "$12,709",
            "Cost per Result": "$11.29"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "337609",
            "Clicks": "2362",
            "Conversions": "40",
            "Conversion Value": "$13,348",
            "Cost per Result": "$37.22"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "134759",
            "Clicks": "4611",
            "Conversions": "64",
            "Conversion Value": "$9,571",
            "Cost per Result": "$35.64"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "434267",
            "Clicks": "5623",
            "Conversions": "40",
            "Conversion Value": "$11,279",
            "Cost per Result": "$25.40"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "496018",
            "Clicks": "6682",
            "Conversions": "43",
            "Conversion Value": "$15,084",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "329753",
            "Clicks": "5203",
            "Conversions": "57",
            "Conversion Value": "$7,719",
            "Cost per Result": "$16.87"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "182415",
            "Clicks": "4646",
            "Conversions": "48",
            "Conversion Value": "$7,706",
            "Cost per Result": "$19.08"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "417086",
            "Clicks": "5715",
            "Conversions": "45",
            "Conversion Value": "$6,452",
            "Cost per Result": "$20.94"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "185365",
            "Clicks": "2409",
            "Conversions": "81",
            "Conversion Value": "$6,577",
            "Cost per Result": "$39.01"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "256551",
            "Clicks": "3314",
            "Conversions": "89",
            "Conversion Value": "$8,893",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "469844",
            "Clicks": "5849",
            "Conversions": "62",
            "Conversion Value": "$15,072",
            "Cost per Result": "$30.29"
          }
        ]
      },
      "CryptoNewsData": {
        "name": "Crypto News",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "497193",
            "Clicks": "3734",
            "Conversions": "120",
            "Conversion Value": "$13,771",
            "Cost per Result": "$33.78"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "150528",
            "Clicks": "6191",
            "Conversions": "110",
            "Conversion Value": "$18,065",
            "Cost per Result": "$14.70"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "521823",
            "Clicks": "7432",
            "Conversions": "67",
            "Conversion Value": "$19,874",
            "Cost per Result": "$28.57"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "746488",
            "Clicks": "3683",
            "Conversions": "114",
            "Conversion Value": "$20,034",
            "Cost per Result": "$14.12"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "275842",
            "Clicks": "5676",
            "Conversions": "94",
            "Conversion Value": "$23,406",
            "Cost per Result": "$11.62"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "700289",
            "Clicks": "8848",
            "Conversions": "106",
            "Conversion Value": "$8,835",
            "Cost per Result": "$38.93"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "686357",
            "Clicks": "3419",
            "Conversions": "95",
            "Conversion Value": "$7,025",
            "Cost per Result": "$33.59"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "504927",
            "Clicks": "6141",
            "Conversions": "95",
            "Conversion Value": "$9,303",
            "Cost per Result": "$12.52"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "422523",
            "Clicks": "8644",
            "Conversions": "89",
            "Conversion Value": "$19,928",
            "Cost per Result": "$34.51"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "231843",
            "Clicks": "3864",
            "Conversions": "88",
            "Conversion Value": "$11,054",
            "Cost per Result": "$16.17"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "547105",
            "Clicks": "7181",
            "Conversions": "65",
            "Conversion Value": "$7,943",
            "Cost per Result": "$39.70"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "395967",
            "Clicks": "3353",
            "Conversions": "118",
            "Conversion Value": "$23,236",
            "Cost per Result": "$23.83"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "366756",
            "Clicks": "5933",
            "Conversions": "83",
            "Conversion Value": "$18,479",
            "Cost per Result": "$11.36"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "219725",
            "Clicks": "3435",
            "Conversions": "106",
            "Conversion Value": "$18,226",
            "Cost per Result": "$23.60"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "336350",
            "Clicks": "6420",
            "Conversions": "128",
            "Conversion Value": "$21,946",
            "Cost per Result": "$28.81"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "182953",
            "Clicks": "3780",
            "Conversions": "95",
            "Conversion Value": "$22,735",
            "Cost per Result": "$15.85"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "629595",
            "Clicks": "5890",
            "Conversions": "86",
            "Conversion Value": "$21,389",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "436713",
            "Clicks": "8197",
            "Conversions": "111",
            "Conversion Value": "$8,442",
            "Cost per Result": "$37.03"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "656682",
            "Clicks": "3023",
            "Conversions": "89",
            "Conversion Value": "$14,163",
            "Cost per Result": "$28.61"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "327512",
            "Clicks": "3752",
            "Conversions": "110",
            "Conversion Value": "$7,157",
            "Cost per Result": "$28.82"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "526976",
            "Clicks": "3168",
            "Conversions": "73",
            "Conversion Value": "$20,676",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "441322",
            "Clicks": "3578",
            "Conversions": "122",
            "Conversion Value": "$12,159",
            "Cost per Result": "$16.86"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "605048",
            "Clicks": "6476",
            "Conversions": "126",
            "Conversion Value": "$16,460",
            "Cost per Result": "$36.08"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "272984",
            "Clicks": "5361",
            "Conversions": "76",
            "Conversion Value": "$20,744",
            "Cost per Result": "$38.45"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "272655",
            "Clicks": "6288",
            "Conversions": "74",
            "Conversion Value": "$6,391",
            "Cost per Result": "$14.90"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "166843",
            "Clicks": "6671",
            "Conversions": "98",
            "Conversion Value": "$10,491",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "315643",
            "Clicks": "7529",
            "Conversions": "128",
            "Conversion Value": "$16,050",
            "Cost per Result": "$29.86"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "291284",
            "Clicks": "5380",
            "Conversions": "91",
            "Conversion Value": "$20,051",
            "Cost per Result": "$22.36"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "427749",
            "Clicks": "8179",
            "Conversions": "83",
            "Conversion Value": "$20,619",
            "Cost per Result": "$10.84"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "207912",
            "Clicks": "6095",
            "Conversions": "74",
            "Conversion Value": "$20,570",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "234942",
            "Clicks": "4426",
            "Conversions": "123",
            "Conversion Value": "$9,951",
            "Cost per Result": "$14.48"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "501589",
            "Clicks": "4917",
            "Conversions": "95",
            "Conversion Value": "$8,767",
            "Cost per Result": "$26.49"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "262443",
            "Clicks": "3698",
            "Conversions": "101",
            "Conversion Value": "$21,422",
            "Cost per Result": "$20.53"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "448851",
            "Clicks": "3252",
            "Conversions": "82",
            "Conversion Value": "$8,126",
            "Cost per Result": "$27.61"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "207623",
            "Clicks": "7282",
            "Conversions": "82",
            "Conversion Value": "$18,780",
            "Cost per Result": "$38.13"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "539355",
            "Clicks": "8072",
            "Conversions": "85",
            "Conversion Value": "$21,034",
            "Cost per Result": "$30.54"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "657761",
            "Clicks": "8936",
            "Conversions": "120",
            "Conversion Value": "$15,798",
            "Cost per Result": "$30.51"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "719994",
            "Clicks": "4134",
            "Conversions": "82",
            "Conversion Value": "$11,856",
            "Cost per Result": "$28.67"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "161340",
            "Clicks": "5940",
            "Conversions": "76",
            "Conversion Value": "$14,084",
            "Cost per Result": "$16.57"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "718054",
            "Clicks": "8437",
            "Conversions": "61",
            "Conversion Value": "$16,971",
            "Cost per Result": "$34.07"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "481294",
            "Clicks": "7303",
            "Conversions": "60",
            "Conversion Value": "$11,788",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "603982",
            "Clicks": "4662",
            "Conversions": "60",
            "Conversion Value": "$9,363",
            "Cost per Result": "$26.90"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "431181",
            "Clicks": "8868",
            "Conversions": "106",
            "Conversion Value": "$7,385",
            "Cost per Result": "$29.17"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "583601",
            "Clicks": "8772",
            "Conversions": "119",
            "Conversion Value": "$14,531",
            "Cost per Result": "$16.96"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "320662",
            "Clicks": "5066",
            "Conversions": "97",
            "Conversion Value": "$13,280",
            "Cost per Result": "$12.51"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "743468",
            "Clicks": "3219",
            "Conversions": "109",
            "Conversion Value": "$20,540",
            "Cost per Result": "$39.05"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "161371",
            "Clicks": "7225",
            "Conversions": "85",
            "Conversion Value": "$22,439",
            "Cost per Result": "$17.88"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "312866",
            "Clicks": "7572",
            "Conversions": "78",
            "Conversion Value": "$9,923",
            "Cost per Result": "$29.76"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "451956",
            "Clicks": "6089",
            "Conversions": "85",
            "Conversion Value": "$16,779",
            "Cost per Result": "$20.13"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "424941",
            "Clicks": "5657",
            "Conversions": "125",
            "Conversion Value": "$23,037",
            "Cost per Result": "$39.21"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "362188",
            "Clicks": "7324",
            "Conversions": "118",
            "Conversion Value": "$16,800",
            "Cost per Result": "$27.89"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "252712",
            "Clicks": "6632",
            "Conversions": "81",
            "Conversion Value": "$8,945",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "557783",
            "Clicks": "8061",
            "Conversions": "70",
            "Conversion Value": "$9,432",
            "Cost per Result": "$14.53"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "630368",
            "Clicks": "4671",
            "Conversions": "123",
            "Conversion Value": "$10,226",
            "Cost per Result": "$14.74"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "484238",
            "Clicks": "8192",
            "Conversions": "118",
            "Conversion Value": "$8,760",
            "Cost per Result": "$28.09"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "634929",
            "Clicks": "8000",
            "Conversions": "115",
            "Conversion Value": "$13,317",
            "Cost per Result": "$27.37"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "190559",
            "Clicks": "6455",
            "Conversions": "71",
            "Conversion Value": "$23,918",
            "Cost per Result": "$30.23"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "559068",
            "Clicks": "4927",
            "Conversions": "97",
            "Conversion Value": "$20,455",
            "Cost per Result": "$12.52"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "400184",
            "Clicks": "4891",
            "Conversions": "100",
            "Conversion Value": "$16,918",
            "Cost per Result": "$11.53"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "735026",
            "Clicks": "6932",
            "Conversions": "116",
            "Conversion Value": "$23,784",
            "Cost per Result": "$33.16"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "301485",
            "Clicks": "7893",
            "Conversions": "109",
            "Conversion Value": "$18,484",
            "Cost per Result": "$34.21"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "426780",
            "Clicks": "3766",
            "Conversions": "125",
            "Conversion Value": "$7,729",
            "Cost per Result": "$16.66"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "655675",
            "Clicks": "4610",
            "Conversions": "90",
            "Conversion Value": "$20,244",
            "Cost per Result": "$20.01"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "669193",
            "Clicks": "5652",
            "Conversions": "116",
            "Conversion Value": "$9,189",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "524236",
            "Clicks": "3384",
            "Conversions": "130",
            "Conversion Value": "$15,382",
            "Cost per Result": "$35.18"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "547056",
            "Clicks": "6738",
            "Conversions": "116",
            "Conversion Value": "$8,074",
            "Cost per Result": "$20.33"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "566678",
            "Clicks": "5353",
            "Conversions": "108",
            "Conversion Value": "$16,334",
            "Cost per Result": "$13.11"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "443906",
            "Clicks": "4810",
            "Conversions": "80",
            "Conversion Value": "$13,974",
            "Cost per Result": "$19.23"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "714654",
            "Clicks": "4234",
            "Conversions": "91",
            "Conversion Value": "$10,183",
            "Cost per Result": "$22.33"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "292172",
            "Clicks": "3502",
            "Conversions": "117",
            "Conversion Value": "$22,692",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "399579",
            "Clicks": "8327",
            "Conversions": "83",
            "Conversion Value": "$6,764",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "386461",
            "Clicks": "3575",
            "Conversions": "116",
            "Conversion Value": "$19,640",
            "Cost per Result": "$29.69"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "503834",
            "Clicks": "5114",
            "Conversions": "126",
            "Conversion Value": "$6,110",
            "Cost per Result": "$36.57"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "716448",
            "Clicks": "6917",
            "Conversions": "105",
            "Conversion Value": "$6,799",
            "Cost per Result": "$28.89"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "673353",
            "Clicks": "6766",
            "Conversions": "108",
            "Conversion Value": "$18,915",
            "Cost per Result": "$17.85"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "492393",
            "Clicks": "3309",
            "Conversions": "68",
            "Conversion Value": "$16,596",
            "Cost per Result": "$31.22"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "721759",
            "Clicks": "3926",
            "Conversions": "109",
            "Conversion Value": "$17,360",
            "Cost per Result": "$20.63"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "204362",
            "Clicks": "4345",
            "Conversions": "103",
            "Conversion Value": "$9,581",
            "Cost per Result": "$34.98"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "569786",
            "Clicks": "8097",
            "Conversions": "96",
            "Conversion Value": "$21,469",
            "Cost per Result": "$15.65"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "527062",
            "Clicks": "7649",
            "Conversions": "85",
            "Conversion Value": "$11,504",
            "Cost per Result": "$13.51"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "492209",
            "Clicks": "4720",
            "Conversions": "90",
            "Conversion Value": "$21,640",
            "Cost per Result": "$24.38"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "479129",
            "Clicks": "5318",
            "Conversions": "106",
            "Conversion Value": "$7,994",
            "Cost per Result": "$13.82"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "328156",
            "Clicks": "4897",
            "Conversions": "62",
            "Conversion Value": "$11,891",
            "Cost per Result": "$33.08"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "513829",
            "Clicks": "7710",
            "Conversions": "100",
            "Conversion Value": "$19,747",
            "Cost per Result": "$36.97"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "386993",
            "Clicks": "7914",
            "Conversions": "116",
            "Conversion Value": "$12,670",
            "Cost per Result": "$18.81"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "274332",
            "Clicks": "3813",
            "Conversions": "108",
            "Conversion Value": "$7,385",
            "Cost per Result": "$23.45"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "334962",
            "Clicks": "7074",
            "Conversions": "81",
            "Conversion Value": "$10,643",
            "Cost per Result": "$36.47"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "330109",
            "Clicks": "6913",
            "Conversions": "109",
            "Conversion Value": "$14,969",
            "Cost per Result": "$21.96"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "458562",
            "Clicks": "3558",
            "Conversions": "118",
            "Conversion Value": "$22,207",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "732021",
            "Clicks": "4373",
            "Conversions": "130",
            "Conversion Value": "$16,829",
            "Cost per Result": "$32.62"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "197228",
            "Clicks": "3169",
            "Conversions": "69",
            "Conversion Value": "$6,604",
            "Cost per Result": "$29.06"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "515190",
            "Clicks": "5657",
            "Conversions": "91",
            "Conversion Value": "$11,774",
            "Cost per Result": "$36.73"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "244924",
            "Clicks": "6298",
            "Conversions": "102",
            "Conversion Value": "$22,325",
            "Cost per Result": "$33.00"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "745113",
            "Clicks": "5277",
            "Conversions": "103",
            "Conversion Value": "$20,877",
            "Cost per Result": "$31.09"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "209570",
            "Clicks": "4529",
            "Conversions": "109",
            "Conversion Value": "$17,267",
            "Cost per Result": "$16.35"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "270090",
            "Clicks": "5745",
            "Conversions": "84",
            "Conversion Value": "$7,606",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "559281",
            "Clicks": "6981",
            "Conversions": "80",
            "Conversion Value": "$18,164",
            "Cost per Result": "$33.74"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "210212",
            "Clicks": "8851",
            "Conversions": "85",
            "Conversion Value": "$10,051",
            "Cost per Result": "$17.64"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "690659",
            "Clicks": "8409",
            "Conversions": "96",
            "Conversion Value": "$13,596",
            "Cost per Result": "$26.34"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "599845",
            "Clicks": "4073",
            "Conversions": "126",
            "Conversion Value": "$7,605",
            "Cost per Result": "$23.10"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "439148",
            "Clicks": "7706",
            "Conversions": "105",
            "Conversion Value": "$13,073",
            "Cost per Result": "$15.20"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "298960",
            "Clicks": "6827",
            "Conversions": "91",
            "Conversion Value": "$21,788",
            "Cost per Result": "$33.94"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "273894",
            "Clicks": "6836",
            "Conversions": "114",
            "Conversion Value": "$7,171",
            "Cost per Result": "$19.59"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "212465",
            "Clicks": "8627",
            "Conversions": "62",
            "Conversion Value": "$19,800",
            "Cost per Result": "$36.25"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "435766",
            "Clicks": "4268",
            "Conversions": "70",
            "Conversion Value": "$14,242",
            "Cost per Result": "$14.61"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "391733",
            "Clicks": "7433",
            "Conversions": "66",
            "Conversion Value": "$17,784",
            "Cost per Result": "$21.31"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "390015",
            "Clicks": "5212",
            "Conversions": "77",
            "Conversion Value": "$18,190",
            "Cost per Result": "$12.20"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "746444",
            "Clicks": "3337",
            "Conversions": "84",
            "Conversion Value": "$15,388",
            "Cost per Result": "$25.14"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "674402",
            "Clicks": "4640",
            "Conversions": "100",
            "Conversion Value": "$11,248",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "447041",
            "Clicks": "8593",
            "Conversions": "61",
            "Conversion Value": "$20,758",
            "Cost per Result": "$30.61"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "476077",
            "Clicks": "4296",
            "Conversions": "79",
            "Conversion Value": "$7,220",
            "Cost per Result": "$35.28"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "350033",
            "Clicks": "3737",
            "Conversions": "128",
            "Conversion Value": "$22,040",
            "Cost per Result": "$28.20"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "409707",
            "Clicks": "6205",
            "Conversions": "101",
            "Conversion Value": "$18,697",
            "Cost per Result": "$29.68"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "703199",
            "Clicks": "8242",
            "Conversions": "75",
            "Conversion Value": "$21,450",
            "Cost per Result": "$32.82"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "285632",
            "Clicks": "5236",
            "Conversions": "62",
            "Conversion Value": "$9,243",
            "Cost per Result": "$36.50"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "580672",
            "Clicks": "6738",
            "Conversions": "83",
            "Conversion Value": "$19,186",
            "Cost per Result": "$25.27"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "337616",
            "Clicks": "6004",
            "Conversions": "101",
            "Conversion Value": "$17,154",
            "Cost per Result": "$20.41"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "525831",
            "Clicks": "6376",
            "Conversions": "86",
            "Conversion Value": "$21,393",
            "Cost per Result": "$31.29"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "459149",
            "Clicks": "7842",
            "Conversions": "87",
            "Conversion Value": "$7,410",
            "Cost per Result": "$24.71"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "431752",
            "Clicks": "5135",
            "Conversions": "106",
            "Conversion Value": "$23,065",
            "Cost per Result": "$17.54"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "706275",
            "Clicks": "5214",
            "Conversions": "88",
            "Conversion Value": "$15,881",
            "Cost per Result": "$14.77"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "725535",
            "Clicks": "3913",
            "Conversions": "109",
            "Conversion Value": "$12,433",
            "Cost per Result": "$23.23"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "241622",
            "Clicks": "5206",
            "Conversions": "118",
            "Conversion Value": "$10,214",
            "Cost per Result": "$34.72"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "426583",
            "Clicks": "8683",
            "Conversions": "121",
            "Conversion Value": "$21,937",
            "Cost per Result": "$25.39"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "693368",
            "Clicks": "5467",
            "Conversions": "68",
            "Conversion Value": "$20,583",
            "Cost per Result": "$32.74"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "374478",
            "Clicks": "6952",
            "Conversions": "115",
            "Conversion Value": "$14,297",
            "Cost per Result": "$10.08"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "706013",
            "Clicks": "7177",
            "Conversions": "63",
            "Conversion Value": "$19,330",
            "Cost per Result": "$38.94"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "745758",
            "Clicks": "4746",
            "Conversions": "73",
            "Conversion Value": "$20,328",
            "Cost per Result": "$19.41"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "385694",
            "Clicks": "6503",
            "Conversions": "84",
            "Conversion Value": "$21,281",
            "Cost per Result": "$38.50"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "449014",
            "Clicks": "7352",
            "Conversions": "76",
            "Conversion Value": "$20,572",
            "Cost per Result": "$13.43"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "656579",
            "Clicks": "6960",
            "Conversions": "130",
            "Conversion Value": "$12,225",
            "Cost per Result": "$13.54"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "274224",
            "Clicks": "8655",
            "Conversions": "64",
            "Conversion Value": "$17,417",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "409197",
            "Clicks": "7865",
            "Conversions": "116",
            "Conversion Value": "$13,757",
            "Cost per Result": "$17.48"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "317310",
            "Clicks": "5926",
            "Conversions": "114",
            "Conversion Value": "$11,425",
            "Cost per Result": "$23.83"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "345352",
            "Clicks": "7423",
            "Conversions": "92",
            "Conversion Value": "$21,867",
            "Cost per Result": "$10.44"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "668281",
            "Clicks": "4912",
            "Conversions": "111",
            "Conversion Value": "$6,759",
            "Cost per Result": "$20.88"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "213682",
            "Clicks": "4134",
            "Conversions": "123",
            "Conversion Value": "$18,560",
            "Cost per Result": "$17.63"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "576807",
            "Clicks": "6776",
            "Conversions": "103",
            "Conversion Value": "$16,077",
            "Cost per Result": "$26.48"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "237930",
            "Clicks": "8319",
            "Conversions": "128",
            "Conversion Value": "$19,280",
            "Cost per Result": "$11.62"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "650275",
            "Clicks": "3416",
            "Conversions": "81",
            "Conversion Value": "$16,425",
            "Cost per Result": "$37.54"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "416273",
            "Clicks": "3296",
            "Conversions": "89",
            "Conversion Value": "$10,481",
            "Cost per Result": "$36.00"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "225741",
            "Clicks": "8501",
            "Conversions": "67",
            "Conversion Value": "$17,604",
            "Cost per Result": "$17.97"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "311435",
            "Clicks": "8795",
            "Conversions": "111",
            "Conversion Value": "$15,597",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "340192",
            "Clicks": "3747",
            "Conversions": "84",
            "Conversion Value": "$8,309",
            "Cost per Result": "$14.19"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "651204",
            "Clicks": "5454",
            "Conversions": "122",
            "Conversion Value": "$14,638",
            "Cost per Result": "$36.89"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "226282",
            "Clicks": "8172",
            "Conversions": "61",
            "Conversion Value": "$7,000",
            "Cost per Result": "$33.95"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "157934",
            "Clicks": "7100",
            "Conversions": "86",
            "Conversion Value": "$6,293",
            "Cost per Result": "$35.84"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "528427",
            "Clicks": "7451",
            "Conversions": "108",
            "Conversion Value": "$22,923",
            "Cost per Result": "$38.98"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "660860",
            "Clicks": "8122",
            "Conversions": "107",
            "Conversion Value": "$12,788",
            "Cost per Result": "$39.97"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "183763",
            "Clicks": "7016",
            "Conversions": "117",
            "Conversion Value": "$20,586",
            "Cost per Result": "$17.53"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "300780",
            "Clicks": "5402",
            "Conversions": "92",
            "Conversion Value": "$15,757",
            "Cost per Result": "$11.88"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "382888",
            "Clicks": "5033",
            "Conversions": "109",
            "Conversion Value": "$23,239",
            "Cost per Result": "$38.70"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "694018",
            "Clicks": "4811",
            "Conversions": "66",
            "Conversion Value": "$21,365",
            "Cost per Result": "$16.52"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "327261",
            "Clicks": "4773",
            "Conversions": "79",
            "Conversion Value": "$10,831",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "409264",
            "Clicks": "8079",
            "Conversions": "120",
            "Conversion Value": "$9,112",
            "Cost per Result": "$30.93"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "166212",
            "Clicks": "3121",
            "Conversions": "96",
            "Conversion Value": "$22,352",
            "Cost per Result": "$19.34"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "252063",
            "Clicks": "5510",
            "Conversions": "113",
            "Conversion Value": "$14,883",
            "Cost per Result": "$28.45"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "631272",
            "Clicks": "4651",
            "Conversions": "90",
            "Conversion Value": "$13,467",
            "Cost per Result": "$28.86"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "318657",
            "Clicks": "8691",
            "Conversions": "70",
            "Conversion Value": "$16,217",
            "Cost per Result": "$10.91"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "715242",
            "Clicks": "5900",
            "Conversions": "62",
            "Conversion Value": "$8,640",
            "Cost per Result": "$16.38"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "442490",
            "Clicks": "6998",
            "Conversions": "123",
            "Conversion Value": "$10,792",
            "Cost per Result": "$28.75"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "166454",
            "Clicks": "8686",
            "Conversions": "104",
            "Conversion Value": "$15,331",
            "Cost per Result": "$28.49"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "728155",
            "Clicks": "6184",
            "Conversions": "60",
            "Conversion Value": "$12,177",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "705002",
            "Clicks": "5561",
            "Conversions": "91",
            "Conversion Value": "$12,853",
            "Cost per Result": "$16.34"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "661324",
            "Clicks": "3747",
            "Conversions": "130",
            "Conversion Value": "$12,659",
            "Cost per Result": "$33.18"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "609423",
            "Clicks": "8128",
            "Conversions": "75",
            "Conversion Value": "$13,843",
            "Cost per Result": "$35.13"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "358381",
            "Clicks": "6603",
            "Conversions": "89",
            "Conversion Value": "$15,372",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "283331",
            "Clicks": "7037",
            "Conversions": "108",
            "Conversion Value": "$10,171",
            "Cost per Result": "$13.43"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "232133",
            "Clicks": "4332",
            "Conversions": "81",
            "Conversion Value": "$21,636",
            "Cost per Result": "$21.15"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "477300",
            "Clicks": "5270",
            "Conversions": "101",
            "Conversion Value": "$12,214",
            "Cost per Result": "$22.05"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "603097",
            "Clicks": "4763",
            "Conversions": "80",
            "Conversion Value": "$23,988",
            "Cost per Result": "$12.08"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "319892",
            "Clicks": "8767",
            "Conversions": "87",
            "Conversion Value": "$6,620",
            "Cost per Result": "$17.04"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "379123",
            "Clicks": "5962",
            "Conversions": "71",
            "Conversion Value": "$9,581",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "682246",
            "Clicks": "3799",
            "Conversions": "74",
            "Conversion Value": "$12,537",
            "Cost per Result": "$26.24"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "306381",
            "Clicks": "5012",
            "Conversions": "101",
            "Conversion Value": "$19,362",
            "Cost per Result": "$39.69"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "264671",
            "Clicks": "7676",
            "Conversions": "114",
            "Conversion Value": "$13,457",
            "Cost per Result": "$37.83"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "705346",
            "Clicks": "4823",
            "Conversions": "80",
            "Conversion Value": "$22,905",
            "Cost per Result": "$23.23"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "224408",
            "Clicks": "4407",
            "Conversions": "63",
            "Conversion Value": "$11,766",
            "Cost per Result": "$17.37"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "237022",
            "Clicks": "3189",
            "Conversions": "116",
            "Conversion Value": "$15,059",
            "Cost per Result": "$17.12"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "656212",
            "Clicks": "6901",
            "Conversions": "84",
            "Conversion Value": "$23,115",
            "Cost per Result": "$11.46"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "184370",
            "Clicks": "4456",
            "Conversions": "85",
            "Conversion Value": "$14,225",
            "Cost per Result": "$25.54"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "458737",
            "Clicks": "4993",
            "Conversions": "121",
            "Conversion Value": "$23,259",
            "Cost per Result": "$11.18"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "516009",
            "Clicks": "5468",
            "Conversions": "125",
            "Conversion Value": "$14,536",
            "Cost per Result": "$15.29"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "258659",
            "Clicks": "3082",
            "Conversions": "129",
            "Conversion Value": "$18,166",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "554817",
            "Clicks": "6345",
            "Conversions": "68",
            "Conversion Value": "$9,294",
            "Cost per Result": "$29.34"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "181853",
            "Clicks": "4252",
            "Conversions": "102",
            "Conversion Value": "$23,011",
            "Cost per Result": "$18.52"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "280967",
            "Clicks": "3641",
            "Conversions": "75",
            "Conversion Value": "$9,497",
            "Cost per Result": "$32.55"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "239342",
            "Clicks": "4289",
            "Conversions": "124",
            "Conversion Value": "$10,354",
            "Cost per Result": "$34.93"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "531946",
            "Clicks": "6327",
            "Conversions": "76",
            "Conversion Value": "$11,264",
            "Cost per Result": "$28.21"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "589646",
            "Clicks": "7962",
            "Conversions": "73",
            "Conversion Value": "$13,050",
            "Cost per Result": "$28.52"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "643044",
            "Clicks": "8827",
            "Conversions": "122",
            "Conversion Value": "$13,575",
            "Cost per Result": "$25.26"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "524962",
            "Clicks": "3804",
            "Conversions": "114",
            "Conversion Value": "$12,208",
            "Cost per Result": "$34.54"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "219071",
            "Clicks": "5243",
            "Conversions": "124",
            "Conversion Value": "$15,343",
            "Cost per Result": "$18.92"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "154513",
            "Clicks": "8603",
            "Conversions": "112",
            "Conversion Value": "$6,510",
            "Cost per Result": "$25.80"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "707177",
            "Clicks": "4345",
            "Conversions": "80",
            "Conversion Value": "$13,894",
            "Cost per Result": "$20.37"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "294591",
            "Clicks": "7104",
            "Conversions": "122",
            "Conversion Value": "$13,025",
            "Cost per Result": "$32.81"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "313860",
            "Clicks": "8548",
            "Conversions": "72",
            "Conversion Value": "$18,186",
            "Cost per Result": "$24.80"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "663175",
            "Clicks": "7324",
            "Conversions": "79",
            "Conversion Value": "$19,856",
            "Cost per Result": "$27.95"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "485885",
            "Clicks": "3807",
            "Conversions": "125",
            "Conversion Value": "$14,183",
            "Cost per Result": "$13.60"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "681552",
            "Clicks": "7418",
            "Conversions": "124",
            "Conversion Value": "$14,055",
            "Cost per Result": "$36.20"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "160617",
            "Clicks": "3310",
            "Conversions": "64",
            "Conversion Value": "$9,903",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "241682",
            "Clicks": "6501",
            "Conversions": "95",
            "Conversion Value": "$9,432",
            "Cost per Result": "$30.71"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "539615",
            "Clicks": "8090",
            "Conversions": "119",
            "Conversion Value": "$11,674",
            "Cost per Result": "$17.36"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "563833",
            "Clicks": "3230",
            "Conversions": "85",
            "Conversion Value": "$10,627",
            "Cost per Result": "$19.12"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "399509",
            "Clicks": "4191",
            "Conversions": "120",
            "Conversion Value": "$20,535",
            "Cost per Result": "$28.26"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "173808",
            "Clicks": "7351",
            "Conversions": "105",
            "Conversion Value": "$12,090",
            "Cost per Result": "$13.60"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "723381",
            "Clicks": "3041",
            "Conversions": "111",
            "Conversion Value": "$19,766",
            "Cost per Result": "$35.27"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "424128",
            "Clicks": "5010",
            "Conversions": "105",
            "Conversion Value": "$13,644",
            "Cost per Result": "$35.63"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "507166",
            "Clicks": "8563",
            "Conversions": "114",
            "Conversion Value": "$14,979",
            "Cost per Result": "$39.30"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "492174",
            "Clicks": "4180",
            "Conversions": "105",
            "Conversion Value": "$12,943",
            "Cost per Result": "$39.63"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "309858",
            "Clicks": "4311",
            "Conversions": "127",
            "Conversion Value": "$21,586",
            "Cost per Result": "$35.34"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "655628",
            "Clicks": "4306",
            "Conversions": "78",
            "Conversion Value": "$23,380",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "510478",
            "Clicks": "6990",
            "Conversions": "118",
            "Conversion Value": "$14,967",
            "Cost per Result": "$14.21"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "288504",
            "Clicks": "8975",
            "Conversions": "70",
            "Conversion Value": "$21,527",
            "Cost per Result": "$37.23"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "393271",
            "Clicks": "4655",
            "Conversions": "67",
            "Conversion Value": "$6,015",
            "Cost per Result": "$30.77"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "178621",
            "Clicks": "4148",
            "Conversions": "114",
            "Conversion Value": "$6,845",
            "Cost per Result": "$26.73"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "586395",
            "Clicks": "8738",
            "Conversions": "67",
            "Conversion Value": "$23,444",
            "Cost per Result": "$19.79"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "650145",
            "Clicks": "8969",
            "Conversions": "84",
            "Conversion Value": "$16,566",
            "Cost per Result": "$32.85"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "256334",
            "Clicks": "4573",
            "Conversions": "120",
            "Conversion Value": "$7,470",
            "Cost per Result": "$36.90"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "415548",
            "Clicks": "3423",
            "Conversions": "65",
            "Conversion Value": "$8,119",
            "Cost per Result": "$30.35"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "335381",
            "Clicks": "4882",
            "Conversions": "95",
            "Conversion Value": "$15,905",
            "Cost per Result": "$14.11"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "684438",
            "Clicks": "7877",
            "Conversions": "122",
            "Conversion Value": "$23,389",
            "Cost per Result": "$26.70"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "704216",
            "Clicks": "4951",
            "Conversions": "105",
            "Conversion Value": "$9,797",
            "Cost per Result": "$34.32"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "348515",
            "Clicks": "3564",
            "Conversions": "75",
            "Conversion Value": "$16,976",
            "Cost per Result": "$30.04"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "634726",
            "Clicks": "5947",
            "Conversions": "85",
            "Conversion Value": "$14,703",
            "Cost per Result": "$24.34"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "640830",
            "Clicks": "4280",
            "Conversions": "79",
            "Conversion Value": "$6,586",
            "Cost per Result": "$13.37"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "634875",
            "Clicks": "8714",
            "Conversions": "122",
            "Conversion Value": "$7,010",
            "Cost per Result": "$21.92"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "153423",
            "Clicks": "3491",
            "Conversions": "88",
            "Conversion Value": "$6,924",
            "Cost per Result": "$21.43"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "340192",
            "Clicks": "6755",
            "Conversions": "95",
            "Conversion Value": "$12,083",
            "Cost per Result": "$33.75"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "282547",
            "Clicks": "4534",
            "Conversions": "107",
            "Conversion Value": "$13,973",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "324364",
            "Clicks": "3242",
            "Conversions": "69",
            "Conversion Value": "$17,280",
            "Cost per Result": "$29.46"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "368695",
            "Clicks": "6313",
            "Conversions": "75",
            "Conversion Value": "$19,329",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "310578",
            "Clicks": "4421",
            "Conversions": "89",
            "Conversion Value": "$11,826",
            "Cost per Result": "$25.95"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "195442",
            "Clicks": "4308",
            "Conversions": "71",
            "Conversion Value": "$18,106",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "458554",
            "Clicks": "8497",
            "Conversions": "74",
            "Conversion Value": "$23,579",
            "Cost per Result": "$27.63"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "514227",
            "Clicks": "8742",
            "Conversions": "88",
            "Conversion Value": "$7,669",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "279538",
            "Clicks": "6045",
            "Conversions": "74",
            "Conversion Value": "$7,770",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "651085",
            "Clicks": "4578",
            "Conversions": "122",
            "Conversion Value": "$20,665",
            "Cost per Result": "$36.66"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "439460",
            "Clicks": "7391",
            "Conversions": "71",
            "Conversion Value": "$16,689",
            "Cost per Result": "$23.54"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "727552",
            "Clicks": "3633",
            "Conversions": "62",
            "Conversion Value": "$13,197",
            "Cost per Result": "$16.02"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "192575",
            "Clicks": "6768",
            "Conversions": "95",
            "Conversion Value": "$20,180",
            "Cost per Result": "$13.41"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "364432",
            "Clicks": "8523",
            "Conversions": "92",
            "Conversion Value": "$18,861",
            "Cost per Result": "$35.27"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "734782",
            "Clicks": "5054",
            "Conversions": "86",
            "Conversion Value": "$21,895",
            "Cost per Result": "$32.38"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "729320",
            "Clicks": "5387",
            "Conversions": "86",
            "Conversion Value": "$6,258",
            "Cost per Result": "$21.78"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "164434",
            "Clicks": "4383",
            "Conversions": "66",
            "Conversion Value": "$17,968",
            "Cost per Result": "$31.20"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "203388",
            "Clicks": "7348",
            "Conversions": "123",
            "Conversion Value": "$17,697",
            "Cost per Result": "$10.36"
          }
        ]
      }
    },
    "web3Casinos": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1501999",
            "Clicks": "27332",
            "Conversions": "440",
            "Conversion Value": "$72,218",
            "Cost per Result": "$35.48"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1835988",
            "Clicks": "33220",
            "Conversions": "422",
            "Conversion Value": "$66,119",
            "Cost per Result": "$22.45"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "2672399",
            "Clicks": "26124",
            "Conversions": "370",
            "Conversion Value": "$66,908",
            "Cost per Result": "$43.85"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "1374148",
            "Clicks": "20749",
            "Conversions": "430",
            "Conversion Value": "$59,194",
            "Cost per Result": "$45.32"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "2980501",
            "Clicks": "28743",
            "Conversions": "478",
            "Conversion Value": "$76,091",
            "Cost per Result": "$38.61"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1835416",
            "Clicks": "18492",
            "Conversions": "540",
            "Conversion Value": "$54,228",
            "Cost per Result": "$34.66"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "2273207",
            "Clicks": "22360",
            "Conversions": "426",
            "Conversion Value": "$66,268",
            "Cost per Result": "$51.03"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1380006",
            "Clicks": "28776",
            "Conversions": "357",
            "Conversion Value": "$67,732",
            "Cost per Result": "$38.74"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1822917",
            "Clicks": "27477",
            "Conversions": "329",
            "Conversion Value": "$62,746",
            "Cost per Result": "$41.98"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "1748976",
            "Clicks": "24669",
            "Conversions": "401",
            "Conversion Value": "$81,709",
            "Cost per Result": "$31.76"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1886954",
            "Clicks": "30055",
            "Conversions": "439",
            "Conversion Value": "$61,606",
            "Cost per Result": "$40.96"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1752856",
            "Clicks": "28047",
            "Conversions": "466",
            "Conversion Value": "$64,773",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "1900389",
            "Clicks": "39366",
            "Conversions": "452",
            "Conversion Value": "$51,630",
            "Cost per Result": "$34.21"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "2824658",
            "Clicks": "22118",
            "Conversions": "423",
            "Conversion Value": "$64,845",
            "Cost per Result": "$38.38"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1752839",
            "Clicks": "32567",
            "Conversions": "358",
            "Conversion Value": "$69,634",
            "Cost per Result": "$40.96"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "2469648",
            "Clicks": "27962",
            "Conversions": "492",
            "Conversion Value": "$78,401",
            "Cost per Result": "$38.10"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "2798682",
            "Clicks": "32074",
            "Conversions": "491",
            "Conversion Value": "$70,297",
            "Cost per Result": "$22.78"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "2662568",
            "Clicks": "38494",
            "Conversions": "426",
            "Conversion Value": "$70,428",
            "Cost per Result": "$40.37"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "2640032",
            "Clicks": "29647",
            "Conversions": "430",
            "Conversion Value": "$52,166",
            "Cost per Result": "$35.64"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "2563182",
            "Clicks": "35918",
            "Conversions": "352",
            "Conversion Value": "$79,383",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1697902",
            "Clicks": "22422",
            "Conversions": "384",
            "Conversion Value": "$60,404",
            "Cost per Result": "$39.57"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "2211044",
            "Clicks": "31199",
            "Conversions": "446",
            "Conversion Value": "$64,905",
            "Cost per Result": "$34.08"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1054352",
            "Clicks": "20580",
            "Conversions": "422",
            "Conversion Value": "$80,635",
            "Cost per Result": "$33.73"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1903527",
            "Clicks": "32176",
            "Conversions": "442",
            "Conversion Value": "$61,218",
            "Cost per Result": "$35.09"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "2635378",
            "Clicks": "28731",
            "Conversions": "565",
            "Conversion Value": "$34,255",
            "Cost per Result": "$32.96"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1820617",
            "Clicks": "30890",
            "Conversions": "427",
            "Conversion Value": "$79,031",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "2023703",
            "Clicks": "30119",
            "Conversions": "452",
            "Conversion Value": "$84,698",
            "Cost per Result": "$41.87"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "2639617",
            "Clicks": "28111",
            "Conversions": "530",
            "Conversion Value": "$54,445",
            "Cost per Result": "$42.68"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "1903494",
            "Clicks": "37124",
            "Conversions": "408",
            "Conversion Value": "$71,668",
            "Cost per Result": "$41.12"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "2190865",
            "Clicks": "36374",
            "Conversions": "390",
            "Conversion Value": "$70,788",
            "Cost per Result": "$29.04"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1566032",
            "Clicks": "23891",
            "Conversions": "509",
            "Conversion Value": "$81,934",
            "Cost per Result": "$34.49"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1966485",
            "Clicks": "36432",
            "Conversions": "494",
            "Conversion Value": "$59,732",
            "Cost per Result": "$40.19"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "2240427",
            "Clicks": "31174",
            "Conversions": "394",
            "Conversion Value": "$61,445",
            "Cost per Result": "$34.40"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "2111021",
            "Clicks": "26529",
            "Conversions": "365",
            "Conversion Value": "$82,783",
            "Cost per Result": "$35.17"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1435111",
            "Clicks": "24133",
            "Conversions": "516",
            "Conversion Value": "$87,171",
            "Cost per Result": "$39.44"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1590814",
            "Clicks": "28741",
            "Conversions": "411",
            "Conversion Value": "$46,446",
            "Cost per Result": "$29.24"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "2110587",
            "Clicks": "24749",
            "Conversions": "439",
            "Conversion Value": "$56,043",
            "Cost per Result": "$44.67"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "2456487",
            "Clicks": "22005",
            "Conversions": "302",
            "Conversion Value": "$62,900",
            "Cost per Result": "$29.05"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "1788239",
            "Clicks": "29364",
            "Conversions": "452",
            "Conversion Value": "$68,385",
            "Cost per Result": "$42.18"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "2095119",
            "Clicks": "35441",
            "Conversions": "474",
            "Conversion Value": "$61,846",
            "Cost per Result": "$40.23"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1943365",
            "Clicks": "23341",
            "Conversions": "417",
            "Conversion Value": "$61,814",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "2359742",
            "Clicks": "31799",
            "Conversions": "469",
            "Conversion Value": "$48,732",
            "Cost per Result": "$34.07"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1677035",
            "Clicks": "30724",
            "Conversions": "415",
            "Conversion Value": "$49,051",
            "Cost per Result": "$37.16"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "2398707",
            "Clicks": "30764",
            "Conversions": "406",
            "Conversion Value": "$52,617",
            "Cost per Result": "$37.06"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "2603241",
            "Clicks": "36718",
            "Conversions": "454",
            "Conversion Value": "$44,923",
            "Cost per Result": "$36.40"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "2726100",
            "Clicks": "29881",
            "Conversions": "417",
            "Conversion Value": "$75,329",
            "Cost per Result": "$37.27"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1927117",
            "Clicks": "29624",
            "Conversions": "430",
            "Conversion Value": "$62,572",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1531869",
            "Clicks": "31429",
            "Conversions": "374",
            "Conversion Value": "$63,365",
            "Cost per Result": "$37.58"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1901741",
            "Clicks": "22110",
            "Conversions": "516",
            "Conversion Value": "$72,243",
            "Cost per Result": "$30.61"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "1161759",
            "Clicks": "30434",
            "Conversions": "362",
            "Conversion Value": "$77,875",
            "Cost per Result": "$36.28"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1694959",
            "Clicks": "34309",
            "Conversions": "419",
            "Conversion Value": "$72,755",
            "Cost per Result": "$41.30"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1828192",
            "Clicks": "30088",
            "Conversions": "423",
            "Conversion Value": "$85,560",
            "Cost per Result": "$33.20"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "3181893",
            "Clicks": "32691",
            "Conversions": "406",
            "Conversion Value": "$67,541",
            "Cost per Result": "$35.26"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "2474128",
            "Clicks": "28278",
            "Conversions": "404",
            "Conversion Value": "$52,342",
            "Cost per Result": "$32.74"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "2170596",
            "Clicks": "32931",
            "Conversions": "511",
            "Conversion Value": "$75,392",
            "Cost per Result": "$33.97"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1148760",
            "Clicks": "32523",
            "Conversions": "367",
            "Conversion Value": "$79,674",
            "Cost per Result": "$39.99"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1958688",
            "Clicks": "21519",
            "Conversions": "380",
            "Conversion Value": "$73,932",
            "Cost per Result": "$35.28"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "1492048",
            "Clicks": "25488",
            "Conversions": "459",
            "Conversion Value": "$89,559",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "2832902",
            "Clicks": "29678",
            "Conversions": "390",
            "Conversion Value": "$77,770",
            "Cost per Result": "$29.56"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1431188",
            "Clicks": "29204",
            "Conversions": "466",
            "Conversion Value": "$70,623",
            "Cost per Result": "$39.69"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1835066",
            "Clicks": "32628",
            "Conversions": "418",
            "Conversion Value": "$73,906",
            "Cost per Result": "$40.10"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "2336700",
            "Clicks": "29268",
            "Conversions": "436",
            "Conversion Value": "$81,953",
            "Cost per Result": "$49.85"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "2310201",
            "Clicks": "30483",
            "Conversions": "432",
            "Conversion Value": "$45,312",
            "Cost per Result": "$28.76"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1305820",
            "Clicks": "34582",
            "Conversions": "358",
            "Conversion Value": "$56,103",
            "Cost per Result": "$35.60"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1981312",
            "Clicks": "21366",
            "Conversions": "478",
            "Conversion Value": "$54,304",
            "Cost per Result": "$43.25"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1952255",
            "Clicks": "35440",
            "Conversions": "451",
            "Conversion Value": "$55,080",
            "Cost per Result": "$45.16"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "2902683",
            "Clicks": "28815",
            "Conversions": "471",
            "Conversion Value": "$55,807",
            "Cost per Result": "$42.56"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1941302",
            "Clicks": "41197",
            "Conversions": "496",
            "Conversion Value": "$69,269",
            "Cost per Result": "$42.48"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1967342",
            "Clicks": "31641",
            "Conversions": "396",
            "Conversion Value": "$67,308",
            "Cost per Result": "$38.54"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "2779083",
            "Clicks": "19118",
            "Conversions": "381",
            "Conversion Value": "$76,125",
            "Cost per Result": "$34.28"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "2434622",
            "Clicks": "22989",
            "Conversions": "440",
            "Conversion Value": "$73,356",
            "Cost per Result": "$34.87"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "2221716",
            "Clicks": "23364",
            "Conversions": "430",
            "Conversion Value": "$60,995",
            "Cost per Result": "$36.15"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1771486",
            "Clicks": "29051",
            "Conversions": "391",
            "Conversion Value": "$87,931",
            "Cost per Result": "$38.34"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "2321263",
            "Clicks": "24351",
            "Conversions": "516",
            "Conversion Value": "$78,836",
            "Cost per Result": "$26.78"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1673924",
            "Clicks": "33448",
            "Conversions": "440",
            "Conversion Value": "$54,786",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1667952",
            "Clicks": "29026",
            "Conversions": "425",
            "Conversion Value": "$67,212",
            "Cost per Result": "$40.69"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "2806475",
            "Clicks": "24546",
            "Conversions": "438",
            "Conversion Value": "$65,630",
            "Cost per Result": "$38.42"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "2274530",
            "Clicks": "30689",
            "Conversions": "532",
            "Conversion Value": "$56,846",
            "Cost per Result": "$39.42"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "2783536",
            "Clicks": "22398",
            "Conversions": "482",
            "Conversion Value": "$84,426",
            "Cost per Result": "$33.95"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "2246665",
            "Clicks": "27897",
            "Conversions": "463",
            "Conversion Value": "$60,985",
            "Cost per Result": "$27.88"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "2003132",
            "Clicks": "29538",
            "Conversions": "461",
            "Conversion Value": "$50,390",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "2990291",
            "Clicks": "32962",
            "Conversions": "469",
            "Conversion Value": "$64,937",
            "Cost per Result": "$38.91"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1554435",
            "Clicks": "27174",
            "Conversions": "414",
            "Conversion Value": "$49,000",
            "Cost per Result": "$45.06"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "2411190",
            "Clicks": "37956",
            "Conversions": "386",
            "Conversion Value": "$85,606",
            "Cost per Result": "$28.64"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "2875013",
            "Clicks": "24975",
            "Conversions": "409",
            "Conversion Value": "$76,148",
            "Cost per Result": "$41.42"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "2006424",
            "Clicks": "33661",
            "Conversions": "442",
            "Conversion Value": "$60,648",
            "Cost per Result": "$33.58"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1612633",
            "Clicks": "25658",
            "Conversions": "344",
            "Conversion Value": "$81,031",
            "Cost per Result": "$49.28"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "2290138",
            "Clicks": "26902",
            "Conversions": "486",
            "Conversion Value": "$67,544",
            "Cost per Result": "$33.16"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "2602630",
            "Clicks": "38332",
            "Conversions": "439",
            "Conversion Value": "$38,565",
            "Cost per Result": "$37.05"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "2675682",
            "Clicks": "38565",
            "Conversions": "418",
            "Conversion Value": "$58,690",
            "Cost per Result": "$44.94"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1993265",
            "Clicks": "27067",
            "Conversions": "385",
            "Conversion Value": "$58,307",
            "Cost per Result": "$35.00"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1681043",
            "Clicks": "34970",
            "Conversions": "455",
            "Conversion Value": "$55,063",
            "Cost per Result": "$29.48"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1874262",
            "Clicks": "30901",
            "Conversions": "419",
            "Conversion Value": "$66,036",
            "Cost per Result": "$32.00"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1868145",
            "Clicks": "26538",
            "Conversions": "459",
            "Conversion Value": "$61,758",
            "Cost per Result": "$39.66"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "2133372",
            "Clicks": "27376",
            "Conversions": "385",
            "Conversion Value": "$69,015",
            "Cost per Result": "$37.57"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1168393",
            "Clicks": "31211",
            "Conversions": "352",
            "Conversion Value": "$71,281",
            "Cost per Result": "$39.89"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1401417",
            "Clicks": "22301",
            "Conversions": "419",
            "Conversion Value": "$70,301",
            "Cost per Result": "$31.63"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "2615072",
            "Clicks": "30546",
            "Conversions": "506",
            "Conversion Value": "$73,836",
            "Cost per Result": "$39.65"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1901119",
            "Clicks": "28617",
            "Conversions": "497",
            "Conversion Value": "$52,669",
            "Cost per Result": "$37.97"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "2367648",
            "Clicks": "30101",
            "Conversions": "382",
            "Conversion Value": "$51,753",
            "Cost per Result": "$40.46"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "1940929",
            "Clicks": "25508",
            "Conversions": "490",
            "Conversion Value": "$68,316",
            "Cost per Result": "$43.88"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1947559",
            "Clicks": "23216",
            "Conversions": "405",
            "Conversion Value": "$63,537",
            "Cost per Result": "$35.05"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1703374",
            "Clicks": "28267",
            "Conversions": "501",
            "Conversion Value": "$74,374",
            "Cost per Result": "$45.31"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "2299417",
            "Clicks": "33962",
            "Conversions": "370",
            "Conversion Value": "$58,042",
            "Cost per Result": "$39.11"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "2102413",
            "Clicks": "31282",
            "Conversions": "400",
            "Conversion Value": "$73,918",
            "Cost per Result": "$37.77"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1915076",
            "Clicks": "38487",
            "Conversions": "354",
            "Conversion Value": "$53,956",
            "Cost per Result": "$42.82"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "2295383",
            "Clicks": "26897",
            "Conversions": "360",
            "Conversion Value": "$73,886",
            "Cost per Result": "$45.66"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "2870962",
            "Clicks": "27517",
            "Conversions": "395",
            "Conversion Value": "$68,383",
            "Cost per Result": "$38.45"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1949334",
            "Clicks": "33223",
            "Conversions": "388",
            "Conversion Value": "$72,415",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "2245575",
            "Clicks": "26055",
            "Conversions": "419",
            "Conversion Value": "$71,312",
            "Cost per Result": "$33.24"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1955996",
            "Clicks": "32244",
            "Conversions": "490",
            "Conversion Value": "$71,725",
            "Cost per Result": "$37.93"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1985235",
            "Clicks": "27530",
            "Conversions": "306",
            "Conversion Value": "$48,333",
            "Cost per Result": "$32.95"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "2114327",
            "Clicks": "24269",
            "Conversions": "389",
            "Conversion Value": "$81,524",
            "Cost per Result": "$44.22"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1881173",
            "Clicks": "22665",
            "Conversions": "363",
            "Conversion Value": "$41,920",
            "Cost per Result": "$39.72"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "2689463",
            "Clicks": "36803",
            "Conversions": "321",
            "Conversion Value": "$53,847",
            "Cost per Result": "$38.74"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "3061370",
            "Clicks": "31241",
            "Conversions": "385",
            "Conversion Value": "$48,310",
            "Cost per Result": "$33.50"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "1894503",
            "Clicks": "27808",
            "Conversions": "458",
            "Conversion Value": "$52,082",
            "Cost per Result": "$34.08"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "2690654",
            "Clicks": "35311",
            "Conversions": "516",
            "Conversion Value": "$51,309",
            "Cost per Result": "$37.78"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "2320010",
            "Clicks": "40045",
            "Conversions": "401",
            "Conversion Value": "$54,568",
            "Cost per Result": "$37.14"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1634236",
            "Clicks": "34019",
            "Conversions": "419",
            "Conversion Value": "$67,073",
            "Cost per Result": "$35.90"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "2320524",
            "Clicks": "32137",
            "Conversions": "435",
            "Conversion Value": "$55,570",
            "Cost per Result": "$49.30"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "2372950",
            "Clicks": "21881",
            "Conversions": "399",
            "Conversion Value": "$38,816",
            "Cost per Result": "$44.37"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "2608700",
            "Clicks": "30284",
            "Conversions": "397",
            "Conversion Value": "$58,151",
            "Cost per Result": "$38.62"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "2654934",
            "Clicks": "23383",
            "Conversions": "424",
            "Conversion Value": "$66,580",
            "Cost per Result": "$33.94"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "1254022",
            "Clicks": "24409",
            "Conversions": "414",
            "Conversion Value": "$76,865",
            "Cost per Result": "$36.26"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1423690",
            "Clicks": "25783",
            "Conversions": "475",
            "Conversion Value": "$59,778",
            "Cost per Result": "$42.30"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1992904",
            "Clicks": "30263",
            "Conversions": "505",
            "Conversion Value": "$64,849",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "2688449",
            "Clicks": "32213",
            "Conversions": "417",
            "Conversion Value": "$42,223",
            "Cost per Result": "$28.78"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "2261452",
            "Clicks": "33714",
            "Conversions": "475",
            "Conversion Value": "$81,670",
            "Cost per Result": "$33.36"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "1640353",
            "Clicks": "27418",
            "Conversions": "414",
            "Conversion Value": "$67,202",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1677539",
            "Clicks": "31395",
            "Conversions": "448",
            "Conversion Value": "$71,431",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "2199603",
            "Clicks": "27778",
            "Conversions": "410",
            "Conversion Value": "$51,142",
            "Cost per Result": "$30.72"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "2039000",
            "Clicks": "22384",
            "Conversions": "390",
            "Conversion Value": "$46,827",
            "Cost per Result": "$43.02"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1690197",
            "Clicks": "29589",
            "Conversions": "399",
            "Conversion Value": "$80,008",
            "Cost per Result": "$37.15"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "2893760",
            "Clicks": "28660",
            "Conversions": "502",
            "Conversion Value": "$60,972",
            "Cost per Result": "$39.56"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "2233371",
            "Clicks": "29843",
            "Conversions": "417",
            "Conversion Value": "$52,296",
            "Cost per Result": "$38.73"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "2572324",
            "Clicks": "31108",
            "Conversions": "456",
            "Conversion Value": "$72,049",
            "Cost per Result": "$40.85"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1909151",
            "Clicks": "33073",
            "Conversions": "349",
            "Conversion Value": "$91,143",
            "Cost per Result": "$31.94"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "2285218",
            "Clicks": "27075",
            "Conversions": "441",
            "Conversion Value": "$68,186",
            "Cost per Result": "$31.12"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "2631171",
            "Clicks": "31870",
            "Conversions": "465",
            "Conversion Value": "$59,037",
            "Cost per Result": "$44.70"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "2456962",
            "Clicks": "31854",
            "Conversions": "426",
            "Conversion Value": "$55,780",
            "Cost per Result": "$38.96"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "1889182",
            "Clicks": "33909",
            "Conversions": "395",
            "Conversion Value": "$54,327",
            "Cost per Result": "$46.74"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1984167",
            "Clicks": "37624",
            "Conversions": "415",
            "Conversion Value": "$62,832",
            "Cost per Result": "$36.40"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1930367",
            "Clicks": "30066",
            "Conversions": "385",
            "Conversion Value": "$66,282",
            "Cost per Result": "$41.94"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "2530102",
            "Clicks": "18166",
            "Conversions": "478",
            "Conversion Value": "$51,687",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "2746213",
            "Clicks": "25087",
            "Conversions": "423",
            "Conversion Value": "$70,873",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1899690",
            "Clicks": "32522",
            "Conversions": "316",
            "Conversion Value": "$58,521",
            "Cost per Result": "$28.96"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "2520256",
            "Clicks": "25726",
            "Conversions": "385",
            "Conversion Value": "$59,815",
            "Cost per Result": "$36.80"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1872072",
            "Clicks": "27769",
            "Conversions": "384",
            "Conversion Value": "$61,665",
            "Cost per Result": "$38.50"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "2940654",
            "Clicks": "25589",
            "Conversions": "477",
            "Conversion Value": "$71,799",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "2458212",
            "Clicks": "29664",
            "Conversions": "542",
            "Conversion Value": "$41,927",
            "Cost per Result": "$37.16"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "2524193",
            "Clicks": "25010",
            "Conversions": "367",
            "Conversion Value": "$61,001",
            "Cost per Result": "$49.77"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "1926393",
            "Clicks": "36920",
            "Conversions": "387",
            "Conversion Value": "$60,539",
            "Cost per Result": "$37.52"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "2297829",
            "Clicks": "27909",
            "Conversions": "480",
            "Conversion Value": "$47,538",
            "Cost per Result": "$40.71"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "2462005",
            "Clicks": "33020",
            "Conversions": "467",
            "Conversion Value": "$57,550",
            "Cost per Result": "$34.89"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "1844103",
            "Clicks": "26098",
            "Conversions": "324",
            "Conversion Value": "$81,963",
            "Cost per Result": "$40.08"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "2410501",
            "Clicks": "23458",
            "Conversions": "400",
            "Conversion Value": "$52,359",
            "Cost per Result": "$39.53"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "1696145",
            "Clicks": "35487",
            "Conversions": "514",
            "Conversion Value": "$55,712",
            "Cost per Result": "$37.63"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "2214934",
            "Clicks": "32808",
            "Conversions": "541",
            "Conversion Value": "$88,184",
            "Cost per Result": "$42.48"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "2340643",
            "Clicks": "28283",
            "Conversions": "489",
            "Conversion Value": "$58,428",
            "Cost per Result": "$30.35"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "1145382",
            "Clicks": "29903",
            "Conversions": "514",
            "Conversion Value": "$68,831",
            "Cost per Result": "$47.77"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "2706695",
            "Clicks": "29574",
            "Conversions": "411",
            "Conversion Value": "$70,851",
            "Cost per Result": "$39.41"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1405866",
            "Clicks": "27122",
            "Conversions": "415",
            "Conversion Value": "$66,885",
            "Cost per Result": "$37.45"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "2679498",
            "Clicks": "39349",
            "Conversions": "503",
            "Conversion Value": "$67,613",
            "Cost per Result": "$28.40"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "2371298",
            "Clicks": "29452",
            "Conversions": "456",
            "Conversion Value": "$66,579",
            "Cost per Result": "$33.48"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "3079211",
            "Clicks": "19404",
            "Conversions": "352",
            "Conversion Value": "$66,524",
            "Cost per Result": "$33.03"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1489301",
            "Clicks": "33781",
            "Conversions": "493",
            "Conversion Value": "$72,516",
            "Cost per Result": "$35.81"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "2001926",
            "Clicks": "28291",
            "Conversions": "447",
            "Conversion Value": "$58,984",
            "Cost per Result": "$41.04"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "2688381",
            "Clicks": "21226",
            "Conversions": "457",
            "Conversion Value": "$69,881",
            "Cost per Result": "$45.79"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1725738",
            "Clicks": "31669",
            "Conversions": "469",
            "Conversion Value": "$48,118",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1609624",
            "Clicks": "27041",
            "Conversions": "331",
            "Conversion Value": "$43,956",
            "Cost per Result": "$39.84"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1875574",
            "Clicks": "28129",
            "Conversions": "337",
            "Conversion Value": "$78,321",
            "Cost per Result": "$45.70"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "915641",
            "Clicks": "34510",
            "Conversions": "485",
            "Conversion Value": "$67,113",
            "Cost per Result": "$40.11"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "2755248",
            "Clicks": "28532",
            "Conversions": "444",
            "Conversion Value": "$45,440",
            "Cost per Result": "$25.79"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "2930127",
            "Clicks": "20833",
            "Conversions": "432",
            "Conversion Value": "$83,783",
            "Cost per Result": "$49.71"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "2299732",
            "Clicks": "36280",
            "Conversions": "303",
            "Conversion Value": "$70,350",
            "Cost per Result": "$28.78"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "2649073",
            "Clicks": "34275",
            "Conversions": "416",
            "Conversion Value": "$54,415",
            "Cost per Result": "$33.89"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "2609457",
            "Clicks": "21705",
            "Conversions": "421",
            "Conversion Value": "$62,514",
            "Cost per Result": "$26.53"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "2450378",
            "Clicks": "30141",
            "Conversions": "393",
            "Conversion Value": "$76,587",
            "Cost per Result": "$42.28"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "2427817",
            "Clicks": "35733",
            "Conversions": "430",
            "Conversion Value": "$56,150",
            "Cost per Result": "$35.77"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "2969783",
            "Clicks": "19700",
            "Conversions": "381",
            "Conversion Value": "$79,082",
            "Cost per Result": "$41.40"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "1142636",
            "Clicks": "30215",
            "Conversions": "336",
            "Conversion Value": "$57,218",
            "Cost per Result": "$40.82"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "2575941",
            "Clicks": "33102",
            "Conversions": "385",
            "Conversion Value": "$64,164",
            "Cost per Result": "$38.82"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "2378608",
            "Clicks": "27920",
            "Conversions": "428",
            "Conversion Value": "$66,783",
            "Cost per Result": "$26.58"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "2806138",
            "Clicks": "28892",
            "Conversions": "458",
            "Conversion Value": "$57,825",
            "Cost per Result": "$41.51"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "2175912",
            "Clicks": "25784",
            "Conversions": "416",
            "Conversion Value": "$89,976",
            "Cost per Result": "$42.28"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "2092470",
            "Clicks": "25383",
            "Conversions": "442",
            "Conversion Value": "$75,588",
            "Cost per Result": "$40.41"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "1987031",
            "Clicks": "33926",
            "Conversions": "421",
            "Conversion Value": "$55,874",
            "Cost per Result": "$38.88"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "2989549",
            "Clicks": "25433",
            "Conversions": "391",
            "Conversion Value": "$65,437",
            "Cost per Result": "$45.58"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1739527",
            "Clicks": "31984",
            "Conversions": "410",
            "Conversion Value": "$70,394",
            "Cost per Result": "$35.53"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "2192274",
            "Clicks": "34372",
            "Conversions": "417",
            "Conversion Value": "$56,574",
            "Cost per Result": "$40.84"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "2244604",
            "Clicks": "37390",
            "Conversions": "485",
            "Conversion Value": "$81,521",
            "Cost per Result": "$45.28"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "1962252",
            "Clicks": "26953",
            "Conversions": "414",
            "Conversion Value": "$65,347",
            "Cost per Result": "$34.90"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1895079",
            "Clicks": "26432",
            "Conversions": "445",
            "Conversion Value": "$61,664",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "2605062",
            "Clicks": "25625",
            "Conversions": "442",
            "Conversion Value": "$63,754",
            "Cost per Result": "$46.27"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1549580",
            "Clicks": "23193",
            "Conversions": "480",
            "Conversion Value": "$65,558",
            "Cost per Result": "$37.76"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1646787",
            "Clicks": "28265",
            "Conversions": "445",
            "Conversion Value": "$63,419",
            "Cost per Result": "$35.76"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "2803252",
            "Clicks": "34680",
            "Conversions": "428",
            "Conversion Value": "$66,576",
            "Cost per Result": "$30.95"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1346324",
            "Clicks": "31121",
            "Conversions": "396",
            "Conversion Value": "$61,189",
            "Cost per Result": "$37.78"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1926794",
            "Clicks": "36731",
            "Conversions": "356",
            "Conversion Value": "$48,222",
            "Cost per Result": "$43.92"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "2521145",
            "Clicks": "26166",
            "Conversions": "408",
            "Conversion Value": "$59,588",
            "Cost per Result": "$35.45"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "2721671",
            "Clicks": "23484",
            "Conversions": "349",
            "Conversion Value": "$62,358",
            "Cost per Result": "$32.65"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1466016",
            "Clicks": "23631",
            "Conversions": "489",
            "Conversion Value": "$77,075",
            "Cost per Result": "$37.52"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1837685",
            "Clicks": "25231",
            "Conversions": "508",
            "Conversion Value": "$76,277",
            "Cost per Result": "$36.56"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "2010551",
            "Clicks": "26435",
            "Conversions": "534",
            "Conversion Value": "$45,176",
            "Cost per Result": "$41.30"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "2181758",
            "Clicks": "27551",
            "Conversions": "368",
            "Conversion Value": "$83,908",
            "Cost per Result": "$31.15"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "1057519",
            "Clicks": "22725",
            "Conversions": "518",
            "Conversion Value": "$77,676",
            "Cost per Result": "$43.61"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1481670",
            "Clicks": "29448",
            "Conversions": "339",
            "Conversion Value": "$53,047",
            "Cost per Result": "$28.12"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "2706771",
            "Clicks": "30021",
            "Conversions": "412",
            "Conversion Value": "$76,421",
            "Cost per Result": "$30.40"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "2003132",
            "Clicks": "24884",
            "Conversions": "514",
            "Conversion Value": "$48,686",
            "Cost per Result": "$37.98"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "1971858",
            "Clicks": "30677",
            "Conversions": "349",
            "Conversion Value": "$57,500",
            "Cost per Result": "$44.18"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "2791052",
            "Clicks": "34583",
            "Conversions": "483",
            "Conversion Value": "$55,268",
            "Cost per Result": "$38.10"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1708407",
            "Clicks": "29928",
            "Conversions": "391",
            "Conversion Value": "$59,266",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "2358960",
            "Clicks": "38235",
            "Conversions": "481",
            "Conversion Value": "$47,408",
            "Cost per Result": "$28.80"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1274894",
            "Clicks": "21676",
            "Conversions": "359",
            "Conversion Value": "$83,213",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1842970",
            "Clicks": "36170",
            "Conversions": "403",
            "Conversion Value": "$76,320",
            "Cost per Result": "$37.05"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "2391221",
            "Clicks": "22710",
            "Conversions": "482",
            "Conversion Value": "$58,639",
            "Cost per Result": "$36.36"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "3005981",
            "Clicks": "26283",
            "Conversions": "479",
            "Conversion Value": "$44,588",
            "Cost per Result": "$32.49"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1735972",
            "Clicks": "27612",
            "Conversions": "448",
            "Conversion Value": "$70,108",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "2703645",
            "Clicks": "33293",
            "Conversions": "486",
            "Conversion Value": "$54,280",
            "Cost per Result": "$45.41"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "2665727",
            "Clicks": "22997",
            "Conversions": "513",
            "Conversion Value": "$65,228",
            "Cost per Result": "$39.10"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "2488446",
            "Clicks": "26434",
            "Conversions": "380",
            "Conversion Value": "$62,311",
            "Cost per Result": "$38.49"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "2094812",
            "Clicks": "27715",
            "Conversions": "489",
            "Conversion Value": "$57,873",
            "Cost per Result": "$35.88"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "2031956",
            "Clicks": "35243",
            "Conversions": "412",
            "Conversion Value": "$62,361",
            "Cost per Result": "$38.29"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1610464",
            "Clicks": "30950",
            "Conversions": "432",
            "Conversion Value": "$54,388",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1916093",
            "Clicks": "35304",
            "Conversions": "474",
            "Conversion Value": "$56,932",
            "Cost per Result": "$31.62"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "2119818",
            "Clicks": "29289",
            "Conversions": "407",
            "Conversion Value": "$68,990",
            "Cost per Result": "$37.25"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "2901882",
            "Clicks": "28443",
            "Conversions": "451",
            "Conversion Value": "$50,378",
            "Cost per Result": "$30.18"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "2283273",
            "Clicks": "31972",
            "Conversions": "445",
            "Conversion Value": "$74,972",
            "Cost per Result": "$29.82"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "2402434",
            "Clicks": "27236",
            "Conversions": "408",
            "Conversion Value": "$54,027",
            "Cost per Result": "$35.49"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "2144972",
            "Clicks": "25751",
            "Conversions": "424",
            "Conversion Value": "$50,487",
            "Cost per Result": "$39.50"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "2305715",
            "Clicks": "23190",
            "Conversions": "406",
            "Conversion Value": "$95,499",
            "Cost per Result": "$38.98"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "2809525",
            "Clicks": "27520",
            "Conversions": "484",
            "Conversion Value": "$60,803",
            "Cost per Result": "$31.53"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "1131187",
            "Clicks": "38354",
            "Conversions": "424",
            "Conversion Value": "$65,647",
            "Cost per Result": "$36.08"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1754368",
            "Clicks": "35168",
            "Conversions": "431",
            "Conversion Value": "$63,710",
            "Cost per Result": "$37.58"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1623028",
            "Clicks": "23465",
            "Conversions": "340",
            "Conversion Value": "$48,013",
            "Cost per Result": "$46.98"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1675994",
            "Clicks": "28273",
            "Conversions": "437",
            "Conversion Value": "$64,158",
            "Cost per Result": "$41.31"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "2645415",
            "Clicks": "30900",
            "Conversions": "423",
            "Conversion Value": "$63,938",
            "Cost per Result": "$41.78"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "2173212",
            "Clicks": "28409",
            "Conversions": "435",
            "Conversion Value": "$45,520",
            "Cost per Result": "$49.25"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "2783481",
            "Clicks": "37834",
            "Conversions": "446",
            "Conversion Value": "$59,735",
            "Cost per Result": "$34.87"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1571188",
            "Clicks": "32450",
            "Conversions": "414",
            "Conversion Value": "$80,817",
            "Cost per Result": "$43.99"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1976106",
            "Clicks": "16856",
            "Conversions": "375",
            "Conversion Value": "$60,302",
            "Cost per Result": "$34.20"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "2271498",
            "Clicks": "33060",
            "Conversions": "449",
            "Conversion Value": "$69,660",
            "Cost per Result": "$34.24"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "2865102",
            "Clicks": "19069",
            "Conversions": "369",
            "Conversion Value": "$54,592",
            "Cost per Result": "$30.52"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "2708306",
            "Clicks": "32477",
            "Conversions": "476",
            "Conversion Value": "$72,311",
            "Cost per Result": "$38.20"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "2794367",
            "Clicks": "29995",
            "Conversions": "449",
            "Conversion Value": "$69,606",
            "Cost per Result": "$41.92"
          }
        ]
      },
      "Web3CasinoUsersData": {
        "name": "Web3 Casino Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "840708",
            "Clicks": "6883",
            "Conversions": "166",
            "Conversion Value": "$27,970",
            "Cost per Result": "$51.25"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "685259",
            "Clicks": "14769",
            "Conversions": "121",
            "Conversion Value": "$39,720",
            "Cost per Result": "$19.82"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "1256658",
            "Clicks": "10998",
            "Conversions": "120",
            "Conversion Value": "$29,610",
            "Cost per Result": "$51.47"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "430022",
            "Clicks": "5581",
            "Conversions": "123",
            "Conversion Value": "$28,917",
            "Cost per Result": "$54.32"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1373429",
            "Clicks": "7821",
            "Conversions": "178",
            "Conversion Value": "$25,191",
            "Cost per Result": "$21.33"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "959152",
            "Clicks": "7176",
            "Conversions": "203",
            "Conversion Value": "$25,427",
            "Cost per Result": "$32.48"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "1229239",
            "Clicks": "10167",
            "Conversions": "126",
            "Conversion Value": "$32,889",
            "Cost per Result": "$49.39"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "518659",
            "Clicks": "8224",
            "Conversions": "101",
            "Conversion Value": "$27,937",
            "Cost per Result": "$53.06"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "921918",
            "Clicks": "14691",
            "Conversions": "122",
            "Conversion Value": "$23,623",
            "Cost per Result": "$18.99"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "687723",
            "Clicks": "6749",
            "Conversions": "124",
            "Conversion Value": "$37,388",
            "Cost per Result": "$56.52"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "651985",
            "Clicks": "6664",
            "Conversions": "167",
            "Conversion Value": "$21,648",
            "Cost per Result": "$32.62"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "783177",
            "Clicks": "13103",
            "Conversions": "215",
            "Conversion Value": "$21,053",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "1145552",
            "Clicks": "15535",
            "Conversions": "126",
            "Conversion Value": "$13,225",
            "Cost per Result": "$30.08"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "1342367",
            "Clicks": "5458",
            "Conversions": "188",
            "Conversion Value": "$25,202",
            "Cost per Result": "$29.41"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "727308",
            "Clicks": "13860",
            "Conversions": "126",
            "Conversion Value": "$32,077",
            "Cost per Result": "$29.13"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "1283005",
            "Clicks": "11989",
            "Conversions": "232",
            "Conversion Value": "$26,820",
            "Cost per Result": "$51.78"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1231988",
            "Clicks": "10249",
            "Conversions": "192",
            "Conversion Value": "$20,786",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "1445538",
            "Clicks": "14029",
            "Conversions": "172",
            "Conversion Value": "$18,031",
            "Cost per Result": "$37.68"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1091948",
            "Clicks": "6923",
            "Conversions": "178",
            "Conversion Value": "$22,564",
            "Cost per Result": "$57.13"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1196930",
            "Clicks": "14845",
            "Conversions": "149",
            "Conversion Value": "$36,248",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "481189",
            "Clicks": "7368",
            "Conversions": "126",
            "Conversion Value": "$28,721",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1036710",
            "Clicks": "12501",
            "Conversions": "158",
            "Conversion Value": "$24,094",
            "Cost per Result": "$25.22"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "279797",
            "Clicks": "5064",
            "Conversions": "176",
            "Conversion Value": "$37,656",
            "Cost per Result": "$32.24"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "310224",
            "Clicks": "6683",
            "Conversions": "134",
            "Conversion Value": "$17,018",
            "Cost per Result": "$36.39"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1392517",
            "Clicks": "14226",
            "Conversions": "224",
            "Conversion Value": "$14,431",
            "Cost per Result": "$43.63"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "567663",
            "Clicks": "7531",
            "Conversions": "155",
            "Conversion Value": "$38,160",
            "Cost per Result": "$57.31"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "316444",
            "Clicks": "12505",
            "Conversions": "170",
            "Conversion Value": "$36,887",
            "Cost per Result": "$42.63"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "1494430",
            "Clicks": "7318",
            "Conversions": "209",
            "Conversion Value": "$15,489",
            "Cost per Result": "$46.38"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "733409",
            "Clicks": "12381",
            "Conversions": "100",
            "Conversion Value": "$20,388",
            "Cost per Result": "$58.40"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "741552",
            "Clicks": "16459",
            "Conversions": "106",
            "Conversion Value": "$30,087",
            "Cost per Result": "$25.77"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "265843",
            "Clicks": "7960",
            "Conversions": "234",
            "Conversion Value": "$36,651",
            "Cost per Result": "$23.94"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "658291",
            "Clicks": "15357",
            "Conversions": "208",
            "Conversion Value": "$27,355",
            "Cost per Result": "$43.06"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1191476",
            "Clicks": "8743",
            "Conversions": "182",
            "Conversion Value": "$20,365",
            "Cost per Result": "$22.87"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "880794",
            "Clicks": "6304",
            "Conversions": "154",
            "Conversion Value": "$29,253",
            "Cost per Result": "$28.83"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "333038",
            "Clicks": "7896",
            "Conversions": "230",
            "Conversion Value": "$35,882",
            "Cost per Result": "$47.14"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1035250",
            "Clicks": "8344",
            "Conversions": "185",
            "Conversion Value": "$20,721",
            "Cost per Result": "$24.58"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1217914",
            "Clicks": "8169",
            "Conversions": "124",
            "Conversion Value": "$24,771",
            "Cost per Result": "$51.40"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "924684",
            "Clicks": "7783",
            "Conversions": "107",
            "Conversion Value": "$21,215",
            "Cost per Result": "$36.25"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "418064",
            "Clicks": "15076",
            "Conversions": "171",
            "Conversion Value": "$26,188",
            "Cost per Result": "$58.34"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "1130203",
            "Clicks": "11327",
            "Conversions": "150",
            "Conversion Value": "$12,903",
            "Cost per Result": "$59.12"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "559075",
            "Clicks": "5539",
            "Conversions": "141",
            "Conversion Value": "$27,695",
            "Cost per Result": "$16.17"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1118802",
            "Clicks": "8281",
            "Conversions": "213",
            "Conversion Value": "$14,062",
            "Cost per Result": "$26.42"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1065255",
            "Clicks": "13146",
            "Conversions": "190",
            "Conversion Value": "$14,078",
            "Cost per Result": "$26.61"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1425811",
            "Clicks": "16416",
            "Conversions": "100",
            "Conversion Value": "$14,526",
            "Cost per Result": "$52.30"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1244770",
            "Clicks": "16499",
            "Conversions": "150",
            "Conversion Value": "$10,144",
            "Cost per Result": "$28.47"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "1364800",
            "Clicks": "11464",
            "Conversions": "144",
            "Conversion Value": "$32,816",
            "Cost per Result": "$16.81"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "559064",
            "Clicks": "12032",
            "Conversions": "183",
            "Conversion Value": "$27,151",
            "Cost per Result": "$51.94"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "296549",
            "Clicks": "16055",
            "Conversions": "111",
            "Conversion Value": "$21,441",
            "Cost per Result": "$23.46"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "956371",
            "Clicks": "7964",
            "Conversions": "204",
            "Conversion Value": "$27,999",
            "Cost per Result": "$23.92"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "300935",
            "Clicks": "14748",
            "Conversions": "113",
            "Conversion Value": "$30,733",
            "Cost per Result": "$52.77"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "732212",
            "Clicks": "15877",
            "Conversions": "144",
            "Conversion Value": "$27,480",
            "Cost per Result": "$57.39"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "746906",
            "Clicks": "6753",
            "Conversions": "180",
            "Conversion Value": "$36,607",
            "Cost per Result": "$18.19"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "1485227",
            "Clicks": "12444",
            "Conversions": "142",
            "Conversion Value": "$29,466",
            "Cost per Result": "$34.65"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1230872",
            "Clicks": "11357",
            "Conversions": "122",
            "Conversion Value": "$16,627",
            "Cost per Result": "$32.79"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "874445",
            "Clicks": "11036",
            "Conversions": "230",
            "Conversion Value": "$24,055",
            "Cost per Result": "$56.69"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "328640",
            "Clicks": "15927",
            "Conversions": "101",
            "Conversion Value": "$30,410",
            "Cost per Result": "$43.69"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "661952",
            "Clicks": "9917",
            "Conversions": "172",
            "Conversion Value": "$25,231",
            "Cost per Result": "$37.41"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "510657",
            "Clicks": "7020",
            "Conversions": "182",
            "Conversion Value": "$37,197",
            "Cost per Result": "$29.48"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1460579",
            "Clicks": "8356",
            "Conversions": "107",
            "Conversion Value": "$21,878",
            "Cost per Result": "$15.22"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "650884",
            "Clicks": "6261",
            "Conversions": "218",
            "Conversion Value": "$38,885",
            "Cost per Result": "$54.07"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "440241",
            "Clicks": "13649",
            "Conversions": "173",
            "Conversion Value": "$35,117",
            "Cost per Result": "$41.21"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1441569",
            "Clicks": "16570",
            "Conversions": "107",
            "Conversion Value": "$32,976",
            "Cost per Result": "$51.02"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1004561",
            "Clicks": "13496",
            "Conversions": "163",
            "Conversion Value": "$18,276",
            "Cost per Result": "$29.69"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "275986",
            "Clicks": "11699",
            "Conversions": "147",
            "Conversion Value": "$25,000",
            "Cost per Result": "$59.26"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "504570",
            "Clicks": "9837",
            "Conversions": "219",
            "Conversion Value": "$27,878",
            "Cost per Result": "$29.12"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "599553",
            "Clicks": "12798",
            "Conversions": "139",
            "Conversion Value": "$15,811",
            "Cost per Result": "$52.62"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1442307",
            "Clicks": "9495",
            "Conversions": "184",
            "Conversion Value": "$17,164",
            "Cost per Result": "$54.32"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "493495",
            "Clicks": "16634",
            "Conversions": "229",
            "Conversion Value": "$28,573",
            "Cost per Result": "$29.44"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "839796",
            "Clicks": "11320",
            "Conversions": "162",
            "Conversion Value": "$35,730",
            "Cost per Result": "$43.69"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1360371",
            "Clicks": "6902",
            "Conversions": "194",
            "Conversion Value": "$24,727",
            "Cost per Result": "$15.27"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1299743",
            "Clicks": "5112",
            "Conversions": "210",
            "Conversion Value": "$29,287",
            "Cost per Result": "$43.70"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1234415",
            "Clicks": "13328",
            "Conversions": "122",
            "Conversion Value": "$25,410",
            "Cost per Result": "$36.74"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "652057",
            "Clicks": "8850",
            "Conversions": "171",
            "Conversion Value": "$32,402",
            "Cost per Result": "$42.19"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "1153210",
            "Clicks": "6676",
            "Conversions": "204",
            "Conversion Value": "$25,697",
            "Cost per Result": "$21.70"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "264150",
            "Clicks": "14619",
            "Conversions": "181",
            "Conversion Value": "$21,029",
            "Cost per Result": "$45.45"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "477982",
            "Clicks": "13845",
            "Conversions": "187",
            "Conversion Value": "$36,046",
            "Cost per Result": "$46.64"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1114626",
            "Clicks": "7555",
            "Conversions": "204",
            "Conversion Value": "$22,872",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "944574",
            "Clicks": "13317",
            "Conversions": "227",
            "Conversion Value": "$19,243",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1146214",
            "Clicks": "5802",
            "Conversions": "161",
            "Conversion Value": "$36,053",
            "Cost per Result": "$48.22"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1163622",
            "Clicks": "11921",
            "Conversions": "180",
            "Conversion Value": "$18,906",
            "Cost per Result": "$39.16"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "629382",
            "Clicks": "13185",
            "Conversions": "182",
            "Conversion Value": "$14,477",
            "Cost per Result": "$53.97"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1368497",
            "Clicks": "16323",
            "Conversions": "183",
            "Conversion Value": "$17,728",
            "Cost per Result": "$57.35"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "597414",
            "Clicks": "10883",
            "Conversions": "199",
            "Conversion Value": "$12,960",
            "Cost per Result": "$59.14"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1101410",
            "Clicks": "15250",
            "Conversions": "124",
            "Conversion Value": "$31,834",
            "Cost per Result": "$23.86"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1144451",
            "Clicks": "6631",
            "Conversions": "168",
            "Conversion Value": "$32,850",
            "Cost per Result": "$23.11"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1028821",
            "Clicks": "16422",
            "Conversions": "202",
            "Conversion Value": "$22,035",
            "Cost per Result": "$39.13"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "832424",
            "Clicks": "7818",
            "Conversions": "115",
            "Conversion Value": "$38,225",
            "Cost per Result": "$57.56"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1175442",
            "Clicks": "11547",
            "Conversions": "226",
            "Conversion Value": "$29,731",
            "Cost per Result": "$17.48"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1301207",
            "Clicks": "15970",
            "Conversions": "222",
            "Conversion Value": "$12,056",
            "Cost per Result": "$20.59"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1304145",
            "Clicks": "16774",
            "Conversions": "141",
            "Conversion Value": "$14,652",
            "Cost per Result": "$28.38"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "998933",
            "Clicks": "7193",
            "Conversions": "149",
            "Conversion Value": "$16,926",
            "Cost per Result": "$30.45"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "255816",
            "Clicks": "16535",
            "Conversions": "214",
            "Conversion Value": "$14,604",
            "Cost per Result": "$20.20"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "549312",
            "Clicks": "14834",
            "Conversions": "124",
            "Conversion Value": "$26,414",
            "Cost per Result": "$29.90"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "986336",
            "Clicks": "8967",
            "Conversions": "158",
            "Conversion Value": "$13,334",
            "Cost per Result": "$52.41"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1238041",
            "Clicks": "10427",
            "Conversions": "111",
            "Conversion Value": "$34,292",
            "Cost per Result": "$33.30"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "426615",
            "Clicks": "14642",
            "Conversions": "131",
            "Conversion Value": "$27,468",
            "Cost per Result": "$39.18"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "366779",
            "Clicks": "8516",
            "Conversions": "142",
            "Conversion Value": "$31,787",
            "Cost per Result": "$25.28"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "779047",
            "Clicks": "14360",
            "Conversions": "213",
            "Conversion Value": "$36,291",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1136741",
            "Clicks": "6474",
            "Conversions": "202",
            "Conversion Value": "$18,654",
            "Cost per Result": "$38.85"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "899188",
            "Clicks": "11754",
            "Conversions": "159",
            "Conversion Value": "$19,440",
            "Cost per Result": "$42.77"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "603112",
            "Clicks": "10469",
            "Conversions": "204",
            "Conversion Value": "$26,638",
            "Cost per Result": "$57.34"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "823522",
            "Clicks": "7318",
            "Conversions": "176",
            "Conversion Value": "$16,287",
            "Cost per Result": "$24.51"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "940300",
            "Clicks": "14497",
            "Conversions": "215",
            "Conversion Value": "$21,332",
            "Cost per Result": "$45.05"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "812369",
            "Clicks": "13729",
            "Conversions": "119",
            "Conversion Value": "$11,954",
            "Cost per Result": "$59.36"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "727528",
            "Clicks": "14204",
            "Conversions": "112",
            "Conversion Value": "$27,794",
            "Cost per Result": "$59.30"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "255200",
            "Clicks": "16732",
            "Conversions": "102",
            "Conversion Value": "$27,281",
            "Cost per Result": "$28.74"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1066427",
            "Clicks": "7170",
            "Conversions": "159",
            "Conversion Value": "$30,890",
            "Cost per Result": "$37.30"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1273818",
            "Clicks": "12684",
            "Conversions": "142",
            "Conversion Value": "$13,260",
            "Cost per Result": "$22.40"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1160973",
            "Clicks": "16648",
            "Conversions": "166",
            "Conversion Value": "$32,748",
            "Cost per Result": "$37.93"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "1326089",
            "Clicks": "15899",
            "Conversions": "182",
            "Conversion Value": "$32,983",
            "Cost per Result": "$28.90"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "644969",
            "Clicks": "11360",
            "Conversions": "209",
            "Conversion Value": "$38,510",
            "Cost per Result": "$44.37"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "469564",
            "Clicks": "9214",
            "Conversions": "118",
            "Conversion Value": "$20,299",
            "Cost per Result": "$16.65"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1400198",
            "Clicks": "9061",
            "Conversions": "135",
            "Conversion Value": "$29,371",
            "Cost per Result": "$56.17"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "604685",
            "Clicks": "8470",
            "Conversions": "101",
            "Conversion Value": "$22,326",
            "Cost per Result": "$22.10"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1496628",
            "Clicks": "15808",
            "Conversions": "117",
            "Conversion Value": "$17,548",
            "Cost per Result": "$36.41"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1212086",
            "Clicks": "10725",
            "Conversions": "150",
            "Conversion Value": "$11,726",
            "Cost per Result": "$25.64"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "554494",
            "Clicks": "16451",
            "Conversions": "189",
            "Conversion Value": "$15,163",
            "Cost per Result": "$16.21"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "1187376",
            "Clicks": "11596",
            "Conversions": "232",
            "Conversion Value": "$12,037",
            "Cost per Result": "$51.28"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "1411628",
            "Clicks": "15307",
            "Conversions": "134",
            "Conversion Value": "$13,974",
            "Cost per Result": "$38.25"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "410734",
            "Clicks": "15962",
            "Conversions": "165",
            "Conversion Value": "$19,122",
            "Cost per Result": "$57.25"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "960890",
            "Clicks": "16179",
            "Conversions": "131",
            "Conversion Value": "$21,571",
            "Cost per Result": "$45.55"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "1472620",
            "Clicks": "6773",
            "Conversions": "179",
            "Conversion Value": "$11,402",
            "Cost per Result": "$15.83"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1044659",
            "Clicks": "7509",
            "Conversions": "211",
            "Conversion Value": "$25,144",
            "Cost per Result": "$50.33"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "882193",
            "Clicks": "7599",
            "Conversions": "144",
            "Conversion Value": "$39,459",
            "Cost per Result": "$16.75"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "471536",
            "Clicks": "5101",
            "Conversions": "109",
            "Conversion Value": "$34,541",
            "Cost per Result": "$34.64"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "362029",
            "Clicks": "6097",
            "Conversions": "232",
            "Conversion Value": "$18,928",
            "Cost per Result": "$32.86"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "955508",
            "Clicks": "12074",
            "Conversions": "213",
            "Conversion Value": "$22,305",
            "Cost per Result": "$19.60"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1422780",
            "Clicks": "12412",
            "Conversions": "155",
            "Conversion Value": "$12,590",
            "Cost per Result": "$26.30"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "1317857",
            "Clicks": "16033",
            "Conversions": "197",
            "Conversion Value": "$30,264",
            "Cost per Result": "$34.68"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "760474",
            "Clicks": "10700",
            "Conversions": "112",
            "Conversion Value": "$24,902",
            "Cost per Result": "$49.32"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "545744",
            "Clicks": "14298",
            "Conversions": "227",
            "Conversion Value": "$37,416",
            "Cost per Result": "$22.75"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1138886",
            "Clicks": "16062",
            "Conversions": "117",
            "Conversion Value": "$12,901",
            "Cost per Result": "$25.20"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "649604",
            "Clicks": "8732",
            "Conversions": "200",
            "Conversion Value": "$25,079",
            "Cost per Result": "$50.26"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "450702",
            "Clicks": "7157",
            "Conversions": "116",
            "Conversion Value": "$37,798",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1370614",
            "Clicks": "10680",
            "Conversions": "234",
            "Conversion Value": "$37,149",
            "Cost per Result": "$58.57"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1176610",
            "Clicks": "16953",
            "Conversions": "169",
            "Conversion Value": "$14,324",
            "Cost per Result": "$32.94"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "1173385",
            "Clicks": "13851",
            "Conversions": "123",
            "Conversion Value": "$30,639",
            "Cost per Result": "$46.40"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "566698",
            "Clicks": "8445",
            "Conversions": "146",
            "Conversion Value": "$36,160",
            "Cost per Result": "$29.81"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1041827",
            "Clicks": "8054",
            "Conversions": "216",
            "Conversion Value": "$36,513",
            "Cost per Result": "$52.31"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "1206724",
            "Clicks": "6871",
            "Conversions": "130",
            "Conversion Value": "$22,683",
            "Cost per Result": "$54.82"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1024458",
            "Clicks": "10373",
            "Conversions": "209",
            "Conversion Value": "$19,865",
            "Cost per Result": "$46.74"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "733936",
            "Clicks": "14767",
            "Conversions": "176",
            "Conversion Value": "$10,942",
            "Cost per Result": "$56.99"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "681343",
            "Clicks": "16978",
            "Conversions": "142",
            "Conversion Value": "$11,199",
            "Cost per Result": "$24.56"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "556540",
            "Clicks": "11292",
            "Conversions": "135",
            "Conversion Value": "$18,785",
            "Cost per Result": "$35.60"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "1347645",
            "Clicks": "8125",
            "Conversions": "180",
            "Conversion Value": "$25,710",
            "Cost per Result": "$34.15"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1484031",
            "Clicks": "6154",
            "Conversions": "176",
            "Conversion Value": "$36,321",
            "Cost per Result": "$18.03"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1138438",
            "Clicks": "16001",
            "Conversions": "106",
            "Conversion Value": "$11,683",
            "Cost per Result": "$32.94"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "985918",
            "Clicks": "9247",
            "Conversions": "132",
            "Conversion Value": "$29,788",
            "Cost per Result": "$27.40"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "538032",
            "Clicks": "14758",
            "Conversions": "104",
            "Conversion Value": "$13,536",
            "Cost per Result": "$22.77"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1285896",
            "Clicks": "11218",
            "Conversions": "190",
            "Conversion Value": "$39,691",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "942346",
            "Clicks": "14269",
            "Conversions": "198",
            "Conversion Value": "$13,177",
            "Cost per Result": "$40.59"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "1030711",
            "Clicks": "9564",
            "Conversions": "114",
            "Conversion Value": "$25,202",
            "Cost per Result": "$51.13"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "699840",
            "Clicks": "15370",
            "Conversions": "138",
            "Conversion Value": "$18,825",
            "Cost per Result": "$35.54"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "1215442",
            "Clicks": "8738",
            "Conversions": "153",
            "Conversion Value": "$14,032",
            "Cost per Result": "$52.24"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "980602",
            "Clicks": "9543",
            "Conversions": "192",
            "Conversion Value": "$23,823",
            "Cost per Result": "$41.29"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "583278",
            "Clicks": "11058",
            "Conversions": "113",
            "Conversion Value": "$37,395",
            "Cost per Result": "$19.71"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "872277",
            "Clicks": "6570",
            "Conversions": "139",
            "Conversion Value": "$15,028",
            "Cost per Result": "$43.95"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "1161117",
            "Clicks": "15046",
            "Conversions": "231",
            "Conversion Value": "$17,874",
            "Cost per Result": "$27.85"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "918842",
            "Clicks": "15355",
            "Conversions": "227",
            "Conversion Value": "$38,938",
            "Cost per Result": "$36.93"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1446326",
            "Clicks": "10903",
            "Conversions": "189",
            "Conversion Value": "$24,648",
            "Cost per Result": "$37.14"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "444515",
            "Clicks": "9251",
            "Conversions": "232",
            "Conversion Value": "$31,724",
            "Cost per Result": "$37.26"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1283106",
            "Clicks": "6180",
            "Conversions": "171",
            "Conversion Value": "$29,887",
            "Cost per Result": "$48.22"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "411249",
            "Clicks": "10364",
            "Conversions": "166",
            "Conversion Value": "$21,813",
            "Cost per Result": "$24.13"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1233321",
            "Clicks": "15960",
            "Conversions": "202",
            "Conversion Value": "$37,708",
            "Cost per Result": "$28.96"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1188022",
            "Clicks": "15732",
            "Conversions": "204",
            "Conversion Value": "$10,680",
            "Cost per Result": "$16.69"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "1209931",
            "Clicks": "7827",
            "Conversions": "122",
            "Conversion Value": "$16,630",
            "Cost per Result": "$48.52"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "442126",
            "Clicks": "13375",
            "Conversions": "178",
            "Conversion Value": "$21,372",
            "Cost per Result": "$23.41"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "446000",
            "Clicks": "8208",
            "Conversions": "214",
            "Conversion Value": "$18,336",
            "Cost per Result": "$38.58"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "1223374",
            "Clicks": "7181",
            "Conversions": "155",
            "Conversion Value": "$27,141",
            "Cost per Result": "$25.40"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "917316",
            "Clicks": "9831",
            "Conversions": "148",
            "Conversion Value": "$13,114",
            "Cost per Result": "$53.76"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "438113",
            "Clicks": "8594",
            "Conversions": "120",
            "Conversion Value": "$14,022",
            "Cost per Result": "$50.31"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "837056",
            "Clicks": "5734",
            "Conversions": "104",
            "Conversion Value": "$30,617",
            "Cost per Result": "$43.32"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "260457",
            "Clicks": "14332",
            "Conversions": "223",
            "Conversion Value": "$15,485",
            "Cost per Result": "$23.94"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1390727",
            "Clicks": "13833",
            "Conversions": "194",
            "Conversion Value": "$16,709",
            "Cost per Result": "$19.20"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "1347479",
            "Clicks": "9789",
            "Conversions": "219",
            "Conversion Value": "$31,250",
            "Cost per Result": "$54.94"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "1014015",
            "Clicks": "12863",
            "Conversions": "108",
            "Conversion Value": "$22,731",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1355447",
            "Clicks": "12504",
            "Conversions": "122",
            "Conversion Value": "$22,504",
            "Cost per Result": "$31.67"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "1274765",
            "Clicks": "6153",
            "Conversions": "213",
            "Conversion Value": "$25,451",
            "Cost per Result": "$22.42"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1197596",
            "Clicks": "10952",
            "Conversions": "180",
            "Conversion Value": "$35,896",
            "Cost per Result": "$17.75"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1408520",
            "Clicks": "15054",
            "Conversions": "129",
            "Conversion Value": "$28,526",
            "Cost per Result": "$34.96"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1465341",
            "Clicks": "8837",
            "Conversions": "144",
            "Conversion Value": "$37,732",
            "Cost per Result": "$48.39"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "259728",
            "Clicks": "15500",
            "Conversions": "112",
            "Conversion Value": "$22,030",
            "Cost per Result": "$22.41"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "1464228",
            "Clicks": "12870",
            "Conversions": "101",
            "Conversion Value": "$38,642",
            "Cost per Result": "$46.73"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1473747",
            "Clicks": "10099",
            "Conversions": "204",
            "Conversion Value": "$24,547",
            "Cost per Result": "$24.04"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1250989",
            "Clicks": "15361",
            "Conversions": "174",
            "Conversion Value": "$28,732",
            "Cost per Result": "$41.83"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "948101",
            "Clicks": "10429",
            "Conversions": "153",
            "Conversion Value": "$34,669",
            "Cost per Result": "$37.84"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1166574",
            "Clicks": "11680",
            "Conversions": "214",
            "Conversion Value": "$22,002",
            "Cost per Result": "$57.61"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "1031558",
            "Clicks": "12900",
            "Conversions": "157",
            "Conversion Value": "$29,177",
            "Cost per Result": "$44.80"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1382528",
            "Clicks": "10155",
            "Conversions": "123",
            "Conversion Value": "$29,842",
            "Cost per Result": "$35.68"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "881430",
            "Clicks": "14723",
            "Conversions": "150",
            "Conversion Value": "$35,670",
            "Cost per Result": "$16.89"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "940164",
            "Clicks": "11066",
            "Conversions": "131",
            "Conversion Value": "$22,076",
            "Cost per Result": "$56.82"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "1320080",
            "Clicks": "14792",
            "Conversions": "221",
            "Conversion Value": "$36,244",
            "Cost per Result": "$29.74"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "536923",
            "Clicks": "6365",
            "Conversions": "184",
            "Conversion Value": "$25,670",
            "Cost per Result": "$49.47"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "291168",
            "Clicks": "9881",
            "Conversions": "185",
            "Conversion Value": "$17,721",
            "Cost per Result": "$40.82"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1435615",
            "Clicks": "6165",
            "Conversions": "160",
            "Conversion Value": "$28,462",
            "Cost per Result": "$53.40"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "741936",
            "Clicks": "7893",
            "Conversions": "223",
            "Conversion Value": "$35,828",
            "Cost per Result": "$43.88"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "595931",
            "Clicks": "11185",
            "Conversions": "193",
            "Conversion Value": "$25,656",
            "Cost per Result": "$45.97"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "1465372",
            "Clicks": "12951",
            "Conversions": "217",
            "Conversion Value": "$17,024",
            "Cost per Result": "$16.10"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "495058",
            "Clicks": "12586",
            "Conversions": "176",
            "Conversion Value": "$20,079",
            "Cost per Result": "$44.33"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "583080",
            "Clicks": "16368",
            "Conversions": "120",
            "Conversion Value": "$20,077",
            "Cost per Result": "$56.15"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1004207",
            "Clicks": "8183",
            "Conversions": "204",
            "Conversion Value": "$13,165",
            "Cost per Result": "$53.97"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "1146856",
            "Clicks": "8724",
            "Conversions": "100",
            "Conversion Value": "$20,952",
            "Cost per Result": "$57.88"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "419591",
            "Clicks": "14086",
            "Conversions": "172",
            "Conversion Value": "$29,139",
            "Cost per Result": "$34.87"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "812282",
            "Clicks": "5739",
            "Conversions": "209",
            "Conversion Value": "$31,584",
            "Cost per Result": "$32.91"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "581503",
            "Clicks": "8462",
            "Conversions": "228",
            "Conversion Value": "$11,943",
            "Cost per Result": "$28.43"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "597411",
            "Clicks": "10051",
            "Conversions": "151",
            "Conversion Value": "$36,321",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "260494",
            "Clicks": "5569",
            "Conversions": "190",
            "Conversion Value": "$27,247",
            "Cost per Result": "$58.95"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "481732",
            "Clicks": "12605",
            "Conversions": "117",
            "Conversion Value": "$26,316",
            "Cost per Result": "$33.62"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "1299965",
            "Clicks": "13137",
            "Conversions": "157",
            "Conversion Value": "$34,341",
            "Cost per Result": "$38.12"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "1376957",
            "Clicks": "6610",
            "Conversions": "229",
            "Conversion Value": "$22,356",
            "Cost per Result": "$51.07"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "944169",
            "Clicks": "9942",
            "Conversions": "106",
            "Conversion Value": "$16,498",
            "Cost per Result": "$21.97"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1229924",
            "Clicks": "14049",
            "Conversions": "226",
            "Conversion Value": "$32,258",
            "Cost per Result": "$42.74"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "887354",
            "Clicks": "11492",
            "Conversions": "100",
            "Conversion Value": "$19,422",
            "Cost per Result": "$24.82"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "996161",
            "Clicks": "16955",
            "Conversions": "185",
            "Conversion Value": "$10,221",
            "Cost per Result": "$16.41"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "675744",
            "Clicks": "8205",
            "Conversions": "102",
            "Conversion Value": "$34,495",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "939007",
            "Clicks": "11032",
            "Conversions": "183",
            "Conversion Value": "$28,689",
            "Cost per Result": "$47.05"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "849736",
            "Clicks": "5927",
            "Conversions": "204",
            "Conversion Value": "$15,044",
            "Cost per Result": "$16.89"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1405970",
            "Clicks": "8958",
            "Conversions": "234",
            "Conversion Value": "$18,317",
            "Cost per Result": "$50.30"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "709129",
            "Clicks": "9850",
            "Conversions": "199",
            "Conversion Value": "$35,537",
            "Cost per Result": "$20.94"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1470149",
            "Clicks": "16753",
            "Conversions": "200",
            "Conversion Value": "$19,565",
            "Cost per Result": "$24.49"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1122597",
            "Clicks": "10402",
            "Conversions": "232",
            "Conversion Value": "$26,045",
            "Cost per Result": "$44.22"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1037742",
            "Clicks": "8047",
            "Conversions": "136",
            "Conversion Value": "$29,711",
            "Cost per Result": "$26.19"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "971921",
            "Clicks": "9154",
            "Conversions": "198",
            "Conversion Value": "$10,632",
            "Cost per Result": "$25.04"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "842071",
            "Clicks": "11665",
            "Conversions": "188",
            "Conversion Value": "$27,664",
            "Cost per Result": "$18.26"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "807694",
            "Clicks": "13613",
            "Conversions": "183",
            "Conversion Value": "$21,047",
            "Cost per Result": "$35.31"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "303232",
            "Clicks": "14651",
            "Conversions": "206",
            "Conversion Value": "$14,823",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1192026",
            "Clicks": "14437",
            "Conversions": "211",
            "Conversion Value": "$26,672",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "1229928",
            "Clicks": "11350",
            "Conversions": "170",
            "Conversion Value": "$14,002",
            "Cost per Result": "$34.70"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "1334023",
            "Clicks": "7501",
            "Conversions": "199",
            "Conversion Value": "$22,840",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1104539",
            "Clicks": "6295",
            "Conversions": "101",
            "Conversion Value": "$18,981",
            "Cost per Result": "$29.26"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1089123",
            "Clicks": "9629",
            "Conversions": "158",
            "Conversion Value": "$19,919",
            "Cost per Result": "$40.43"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "993915",
            "Clicks": "11832",
            "Conversions": "131",
            "Conversion Value": "$39,645",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "1395186",
            "Clicks": "7913",
            "Conversions": "194",
            "Conversion Value": "$11,969",
            "Cost per Result": "$49.32"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "490326",
            "Clicks": "13593",
            "Conversions": "131",
            "Conversion Value": "$30,585",
            "Cost per Result": "$46.11"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "368136",
            "Clicks": "14697",
            "Conversions": "194",
            "Conversion Value": "$20,993",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "349647",
            "Clicks": "13233",
            "Conversions": "140",
            "Conversion Value": "$14,248",
            "Cost per Result": "$51.83"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "912221",
            "Clicks": "5380",
            "Conversions": "135",
            "Conversion Value": "$30,070",
            "Cost per Result": "$55.94"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "876100",
            "Clicks": "5098",
            "Conversions": "181",
            "Conversion Value": "$21,535",
            "Cost per Result": "$41.78"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "683816",
            "Clicks": "9508",
            "Conversions": "133",
            "Conversion Value": "$17,741",
            "Cost per Result": "$54.04"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1265191",
            "Clicks": "16793",
            "Conversions": "190",
            "Conversion Value": "$17,651",
            "Cost per Result": "$27.51"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "300441",
            "Clicks": "15987",
            "Conversions": "113",
            "Conversion Value": "$38,486",
            "Cost per Result": "$40.23"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "473147",
            "Clicks": "5596",
            "Conversions": "111",
            "Conversion Value": "$17,039",
            "Cost per Result": "$31.80"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1185189",
            "Clicks": "6694",
            "Conversions": "206",
            "Conversion Value": "$38,779",
            "Cost per Result": "$16.84"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1462876",
            "Clicks": "6300",
            "Conversions": "135",
            "Conversion Value": "$17,415",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1481221",
            "Clicks": "15132",
            "Conversions": "218",
            "Conversion Value": "$38,988",
            "Cost per Result": "$20.64"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1492858",
            "Clicks": "9838",
            "Conversions": "185",
            "Conversion Value": "$17,205",
            "Cost per Result": "$27.25"
          }
        ]
      },
      "SportsBettingUsersData": {
        "name": "Sports Betting Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "308361",
            "Clicks": "12819",
            "Conversions": "148",
            "Conversion Value": "$25,237",
            "Cost per Result": "$46.45"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "642678",
            "Clicks": "9172",
            "Conversions": "143",
            "Conversion Value": "$10,892",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "915365",
            "Clicks": "8793",
            "Conversions": "101",
            "Conversion Value": "$19,539",
            "Cost per Result": "$49.04"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "671808",
            "Clicks": "5532",
            "Conversions": "170",
            "Conversion Value": "$11,068",
            "Cost per Result": "$18.44"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "991067",
            "Clicks": "11779",
            "Conversions": "174",
            "Conversion Value": "$29,785",
            "Cost per Result": "$51.25"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "253076",
            "Clicks": "6555",
            "Conversions": "177",
            "Conversion Value": "$15,693",
            "Cost per Result": "$56.24"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "252198",
            "Clicks": "5154",
            "Conversions": "125",
            "Conversion Value": "$15,529",
            "Cost per Result": "$58.90"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "235032",
            "Clicks": "13449",
            "Conversions": "110",
            "Conversion Value": "$20,335",
            "Cost per Result": "$17.62"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "247906",
            "Clicks": "4482",
            "Conversions": "98",
            "Conversion Value": "$20,157",
            "Cost per Result": "$58.96"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "299930",
            "Clicks": "11990",
            "Conversions": "156",
            "Conversion Value": "$22,283",
            "Cost per Result": "$18.92"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "789167",
            "Clicks": "11082",
            "Conversions": "135",
            "Conversion Value": "$10,991",
            "Cost per Result": "$53.75"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "380528",
            "Clicks": "4298",
            "Conversions": "126",
            "Conversion Value": "$24,134",
            "Cost per Result": "$44.02"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "210262",
            "Clicks": "12894",
            "Conversions": "179",
            "Conversion Value": "$22,274",
            "Cost per Result": "$29.31"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "541392",
            "Clicks": "6594",
            "Conversions": "116",
            "Conversion Value": "$11,168",
            "Cost per Result": "$34.72"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "499322",
            "Clicks": "11885",
            "Conversions": "82",
            "Conversion Value": "$23,376",
            "Cost per Result": "$26.88"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "857741",
            "Clicks": "5014",
            "Conversions": "153",
            "Conversion Value": "$24,495",
            "Cost per Result": "$45.20"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "859804",
            "Clicks": "11662",
            "Conversions": "152",
            "Conversion Value": "$30,080",
            "Cost per Result": "$35.30"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "530291",
            "Clicks": "13708",
            "Conversions": "116",
            "Conversion Value": "$25,041",
            "Cost per Result": "$31.40"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "984493",
            "Clicks": "12912",
            "Conversions": "109",
            "Conversion Value": "$13,815",
            "Cost per Result": "$28.50"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "856386",
            "Clicks": "10459",
            "Conversions": "89",
            "Conversion Value": "$21,901",
            "Cost per Result": "$18.47"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "291993",
            "Clicks": "4530",
            "Conversions": "116",
            "Conversion Value": "$21,873",
            "Cost per Result": "$44.12"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "596080",
            "Clicks": "10607",
            "Conversions": "166",
            "Conversion Value": "$24,753",
            "Cost per Result": "$50.39"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "309125",
            "Clicks": "7430",
            "Conversions": "110",
            "Conversion Value": "$18,204",
            "Cost per Result": "$33.07"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "749493",
            "Clicks": "12740",
            "Conversions": "132",
            "Conversion Value": "$25,866",
            "Cost per Result": "$34.69"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "660095",
            "Clicks": "6776",
            "Conversions": "161",
            "Conversion Value": "$10,701",
            "Cost per Result": "$39.54"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "583760",
            "Clicks": "11601",
            "Conversions": "128",
            "Conversion Value": "$20,764",
            "Cost per Result": "$29.29"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "986190",
            "Clicks": "8482",
            "Conversions": "120",
            "Conversion Value": "$29,873",
            "Cost per Result": "$40.06"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "861936",
            "Clicks": "9084",
            "Conversions": "169",
            "Conversion Value": "$13,481",
            "Cost per Result": "$49.14"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "671886",
            "Clicks": "12087",
            "Conversions": "175",
            "Conversion Value": "$28,768",
            "Cost per Result": "$28.04"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "742490",
            "Clicks": "11906",
            "Conversions": "166",
            "Conversion Value": "$12,464",
            "Cost per Result": "$23.69"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "461145",
            "Clicks": "5204",
            "Conversions": "129",
            "Conversion Value": "$21,480",
            "Cost per Result": "$58.10"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "809120",
            "Clicks": "10114",
            "Conversions": "150",
            "Conversion Value": "$9,844",
            "Cost per Result": "$40.95"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "340172",
            "Clicks": "13719",
            "Conversions": "91",
            "Conversion Value": "$20,521",
            "Cost per Result": "$16.29"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "591720",
            "Clicks": "9471",
            "Conversions": "97",
            "Conversion Value": "$23,694",
            "Cost per Result": "$22.76"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "531421",
            "Clicks": "6354",
            "Conversions": "161",
            "Conversion Value": "$30,331",
            "Cost per Result": "$23.11"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "348131",
            "Clicks": "10191",
            "Conversions": "86",
            "Conversion Value": "$13,061",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "442102",
            "Clicks": "5308",
            "Conversions": "164",
            "Conversion Value": "$15,624",
            "Cost per Result": "$43.88"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "752895",
            "Clicks": "6277",
            "Conversions": "84",
            "Conversion Value": "$22,487",
            "Cost per Result": "$31.98"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "755221",
            "Clicks": "5056",
            "Conversions": "145",
            "Conversion Value": "$29,692",
            "Cost per Result": "$23.69"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "606314",
            "Clicks": "12844",
            "Conversions": "175",
            "Conversion Value": "$23,087",
            "Cost per Result": "$58.92"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "621206",
            "Clicks": "6292",
            "Conversions": "124",
            "Conversion Value": "$15,201",
            "Cost per Result": "$25.90"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "419853",
            "Clicks": "11560",
            "Conversions": "129",
            "Conversion Value": "$12,460",
            "Cost per Result": "$52.13"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "244911",
            "Clicks": "9160",
            "Conversions": "98",
            "Conversion Value": "$16,804",
            "Cost per Result": "$57.25"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "599887",
            "Clicks": "7267",
            "Conversions": "168",
            "Conversion Value": "$13,958",
            "Cost per Result": "$28.95"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "669957",
            "Clicks": "10521",
            "Conversions": "174",
            "Conversion Value": "$16,799",
            "Cost per Result": "$36.22"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "551290",
            "Clicks": "6832",
            "Conversions": "108",
            "Conversion Value": "$18,496",
            "Cost per Result": "$25.49"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "706626",
            "Clicks": "11034",
            "Conversions": "100",
            "Conversion Value": "$14,166",
            "Cost per Result": "$58.78"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "527902",
            "Clicks": "6362",
            "Conversions": "126",
            "Conversion Value": "$17,048",
            "Cost per Result": "$39.05"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "459599",
            "Clicks": "4371",
            "Conversions": "177",
            "Conversion Value": "$21,191",
            "Cost per Result": "$53.36"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "403729",
            "Clicks": "6682",
            "Conversions": "129",
            "Conversion Value": "$28,233",
            "Cost per Result": "$38.68"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "379135",
            "Clicks": "9068",
            "Conversions": "159",
            "Conversion Value": "$24,255",
            "Cost per Result": "$44.70"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "424455",
            "Clicks": "12863",
            "Conversions": "124",
            "Conversion Value": "$22,755",
            "Cost per Result": "$21.70"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "837863",
            "Clicks": "12420",
            "Conversions": "139",
            "Conversion Value": "$11,691",
            "Cost per Result": "$37.83"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "683672",
            "Clicks": "9832",
            "Conversions": "166",
            "Conversion Value": "$17,633",
            "Cost per Result": "$31.10"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "841459",
            "Clicks": "10773",
            "Conversions": "165",
            "Conversion Value": "$31,245",
            "Cost per Result": "$47.66"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "374749",
            "Clicks": "7701",
            "Conversions": "105",
            "Conversion Value": "$31,596",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "994094",
            "Clicks": "4566",
            "Conversions": "128",
            "Conversion Value": "$29,854",
            "Cost per Result": "$30.81"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "382688",
            "Clicks": "12193",
            "Conversions": "150",
            "Conversion Value": "$31,256",
            "Cost per Result": "$54.54"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "500054",
            "Clicks": "13887",
            "Conversions": "142",
            "Conversion Value": "$26,382",
            "Cost per Result": "$39.23"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "248263",
            "Clicks": "13987",
            "Conversions": "109",
            "Conversion Value": "$13,007",
            "Cost per Result": "$15.36"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "895254",
            "Clicks": "8417",
            "Conversions": "110",
            "Conversion Value": "$14,440",
            "Cost per Result": "$36.26"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "269424",
            "Clicks": "7298",
            "Conversions": "179",
            "Conversion Value": "$26,091",
            "Cost per Result": "$56.53"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "732889",
            "Clicks": "7661",
            "Conversions": "98",
            "Conversion Value": "$10,556",
            "Cost per Result": "$31.21"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "374918",
            "Clicks": "10688",
            "Conversions": "89",
            "Conversion Value": "$17,161",
            "Cost per Result": "$39.74"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "825144",
            "Clicks": "4636",
            "Conversions": "110",
            "Conversion Value": "$15,292",
            "Cost per Result": "$50.16"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "847627",
            "Clicks": "9822",
            "Conversions": "137",
            "Conversion Value": "$21,725",
            "Cost per Result": "$49.68"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "759037",
            "Clicks": "11185",
            "Conversions": "150",
            "Conversion Value": "$22,209",
            "Cost per Result": "$57.61"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "587810",
            "Clicks": "12463",
            "Conversions": "126",
            "Conversion Value": "$23,965",
            "Cost per Result": "$19.70"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "613079",
            "Clicks": "11066",
            "Conversions": "104",
            "Conversion Value": "$20,193",
            "Cost per Result": "$22.92"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "749427",
            "Clicks": "4724",
            "Conversions": "81",
            "Conversion Value": "$29,274",
            "Cost per Result": "$43.91"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "518909",
            "Clicks": "9394",
            "Conversions": "92",
            "Conversion Value": "$19,105",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "378718",
            "Clicks": "5903",
            "Conversions": "164",
            "Conversion Value": "$15,909",
            "Cost per Result": "$55.12"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "417208",
            "Clicks": "13418",
            "Conversions": "99",
            "Conversion Value": "$29,114",
            "Cost per Result": "$33.08"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "618341",
            "Clicks": "7968",
            "Conversions": "176",
            "Conversion Value": "$24,981",
            "Cost per Result": "$20.90"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "791592",
            "Clicks": "10328",
            "Conversions": "113",
            "Conversion Value": "$12,598",
            "Cost per Result": "$16.67"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "760268",
            "Clicks": "7975",
            "Conversions": "118",
            "Conversion Value": "$11,796",
            "Cost per Result": "$18.21"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "931523",
            "Clicks": "6552",
            "Conversions": "90",
            "Conversion Value": "$16,161",
            "Cost per Result": "$37.63"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "746522",
            "Clicks": "4721",
            "Conversions": "149",
            "Conversion Value": "$11,518",
            "Cost per Result": "$52.97"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "842197",
            "Clicks": "9065",
            "Conversions": "158",
            "Conversion Value": "$28,890",
            "Cost per Result": "$15.26"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "401266",
            "Clicks": "9351",
            "Conversions": "158",
            "Conversion Value": "$12,378",
            "Cost per Result": "$30.73"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "814544",
            "Clicks": "10515",
            "Conversions": "135",
            "Conversion Value": "$19,338",
            "Cost per Result": "$27.39"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "866025",
            "Clicks": "7517",
            "Conversions": "146",
            "Conversion Value": "$26,995",
            "Cost per Result": "$43.23"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "483522",
            "Clicks": "4782",
            "Conversions": "133",
            "Conversion Value": "$13,402",
            "Cost per Result": "$45.00"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "699406",
            "Clicks": "13975",
            "Conversions": "91",
            "Conversion Value": "$26,775",
            "Cost per Result": "$31.17"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "991554",
            "Clicks": "9782",
            "Conversions": "122",
            "Conversion Value": "$31,691",
            "Cost per Result": "$32.90"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "353956",
            "Clicks": "9896",
            "Conversions": "113",
            "Conversion Value": "$10,329",
            "Cost per Result": "$55.07"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "352727",
            "Clicks": "7426",
            "Conversions": "138",
            "Conversion Value": "$21,762",
            "Cost per Result": "$50.34"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "278173",
            "Clicks": "6275",
            "Conversions": "117",
            "Conversion Value": "$18,083",
            "Cost per Result": "$53.39"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "461169",
            "Clicks": "12526",
            "Conversions": "112",
            "Conversion Value": "$10,672",
            "Cost per Result": "$59.61"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "696561",
            "Clicks": "10753",
            "Conversions": "128",
            "Conversion Value": "$24,095",
            "Cost per Result": "$46.10"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "645146",
            "Clicks": "9602",
            "Conversions": "86",
            "Conversion Value": "$17,973",
            "Cost per Result": "$34.36"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "942456",
            "Clicks": "9149",
            "Conversions": "129",
            "Conversion Value": "$23,899",
            "Cost per Result": "$27.68"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "740944",
            "Clicks": "9049",
            "Conversions": "135",
            "Conversion Value": "$15,544",
            "Cost per Result": "$23.94"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "567433",
            "Clicks": "10811",
            "Conversions": "170",
            "Conversion Value": "$24,099",
            "Cost per Result": "$36.02"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "513751",
            "Clicks": "7440",
            "Conversions": "143",
            "Conversion Value": "$23,272",
            "Cost per Result": "$40.21"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "400001",
            "Clicks": "9091",
            "Conversions": "117",
            "Conversion Value": "$24,520",
            "Cost per Result": "$34.45"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "515595",
            "Clicks": "5044",
            "Conversions": "169",
            "Conversion Value": "$17,369",
            "Cost per Result": "$15.13"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "910231",
            "Clicks": "5431",
            "Conversions": "118",
            "Conversion Value": "$20,923",
            "Cost per Result": "$37.49"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "234193",
            "Clicks": "11465",
            "Conversions": "160",
            "Conversion Value": "$19,749",
            "Cost per Result": "$52.87"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "853554",
            "Clicks": "9659",
            "Conversions": "104",
            "Conversion Value": "$12,410",
            "Cost per Result": "$33.12"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "805371",
            "Clicks": "5816",
            "Conversions": "154",
            "Conversion Value": "$30,646",
            "Cost per Result": "$55.89"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "576382",
            "Clicks": "7320",
            "Conversions": "107",
            "Conversion Value": "$30,290",
            "Cost per Result": "$36.42"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "451767",
            "Clicks": "4764",
            "Conversions": "155",
            "Conversion Value": "$23,179",
            "Cost per Result": "$59.77"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "891099",
            "Clicks": "10842",
            "Conversions": "97",
            "Conversion Value": "$17,064",
            "Cost per Result": "$31.30"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "450954",
            "Clicks": "12208",
            "Conversions": "141",
            "Conversion Value": "$25,896",
            "Cost per Result": "$28.49"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "753467",
            "Clicks": "8591",
            "Conversions": "157",
            "Conversion Value": "$8,585",
            "Cost per Result": "$50.51"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "568011",
            "Clicks": "11139",
            "Conversions": "99",
            "Conversion Value": "$19,644",
            "Cost per Result": "$57.37"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "949382",
            "Clicks": "9823",
            "Conversions": "101",
            "Conversion Value": "$31,691",
            "Cost per Result": "$23.98"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "315234",
            "Clicks": "7596",
            "Conversions": "91",
            "Conversion Value": "$14,471",
            "Cost per Result": "$26.71"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "630094",
            "Clicks": "4119",
            "Conversions": "104",
            "Conversion Value": "$17,036",
            "Cost per Result": "$39.73"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "791118",
            "Clicks": "10305",
            "Conversions": "130",
            "Conversion Value": "$10,386",
            "Cost per Result": "$26.08"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "798444",
            "Clicks": "8163",
            "Conversions": "87",
            "Conversion Value": "$8,231",
            "Cost per Result": "$32.92"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "331159",
            "Clicks": "8045",
            "Conversions": "150",
            "Conversion Value": "$30,621",
            "Cost per Result": "$32.41"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "678261",
            "Clicks": "7166",
            "Conversions": "163",
            "Conversion Value": "$8,950",
            "Cost per Result": "$43.30"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "765695",
            "Clicks": "10769",
            "Conversions": "100",
            "Conversion Value": "$20,944",
            "Cost per Result": "$54.19"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "992627",
            "Clicks": "11458",
            "Conversions": "105",
            "Conversion Value": "$22,991",
            "Cost per Result": "$24.08"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "778811",
            "Clicks": "4452",
            "Conversions": "136",
            "Conversion Value": "$15,336",
            "Cost per Result": "$45.01"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "794323",
            "Clicks": "13817",
            "Conversions": "170",
            "Conversion Value": "$9,147",
            "Cost per Result": "$34.54"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "413822",
            "Clicks": "13457",
            "Conversions": "113",
            "Conversion Value": "$19,991",
            "Cost per Result": "$17.32"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "656132",
            "Clicks": "10174",
            "Conversions": "143",
            "Conversion Value": "$30,745",
            "Cost per Result": "$34.02"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "753715",
            "Clicks": "6381",
            "Conversions": "168",
            "Conversion Value": "$15,740",
            "Cost per Result": "$50.77"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "324877",
            "Clicks": "4977",
            "Conversions": "135",
            "Conversion Value": "$13,092",
            "Cost per Result": "$39.40"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "860791",
            "Clicks": "10869",
            "Conversions": "81",
            "Conversion Value": "$20,438",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "918887",
            "Clicks": "4559",
            "Conversions": "110",
            "Conversion Value": "$8,468",
            "Cost per Result": "$16.73"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "292370",
            "Clicks": "12126",
            "Conversions": "144",
            "Conversion Value": "$20,775",
            "Cost per Result": "$33.40"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "514003",
            "Clicks": "13358",
            "Conversions": "141",
            "Conversion Value": "$27,468",
            "Cost per Result": "$48.75"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "685282",
            "Clicks": "7177",
            "Conversions": "134",
            "Conversion Value": "$24,177",
            "Cost per Result": "$17.13"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "791111",
            "Clicks": "12249",
            "Conversions": "113",
            "Conversion Value": "$12,910",
            "Cost per Result": "$18.15"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "449794",
            "Clicks": "8558",
            "Conversions": "156",
            "Conversion Value": "$22,675",
            "Cost per Result": "$41.69"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "343471",
            "Clicks": "8167",
            "Conversions": "170",
            "Conversion Value": "$31,814",
            "Cost per Result": "$41.66"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "425163",
            "Clicks": "4385",
            "Conversions": "100",
            "Conversion Value": "$10,511",
            "Cost per Result": "$15.71"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "662655",
            "Clicks": "4533",
            "Conversions": "166",
            "Conversion Value": "$11,009",
            "Cost per Result": "$19.19"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "977884",
            "Clicks": "7284",
            "Conversions": "97",
            "Conversion Value": "$12,244",
            "Cost per Result": "$44.48"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "897685",
            "Clicks": "12113",
            "Conversions": "148",
            "Conversion Value": "$22,567",
            "Cost per Result": "$54.41"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "686486",
            "Clicks": "8532",
            "Conversions": "142",
            "Conversion Value": "$8,672",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "735812",
            "Clicks": "6121",
            "Conversions": "112",
            "Conversion Value": "$16,746",
            "Cost per Result": "$21.48"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "532225",
            "Clicks": "10730",
            "Conversions": "178",
            "Conversion Value": "$16,132",
            "Cost per Result": "$43.27"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "870257",
            "Clicks": "13649",
            "Conversions": "94",
            "Conversion Value": "$26,248",
            "Cost per Result": "$29.36"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "744678",
            "Clicks": "7736",
            "Conversions": "112",
            "Conversion Value": "$12,897",
            "Cost per Result": "$21.46"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "747994",
            "Clicks": "13654",
            "Conversions": "163",
            "Conversion Value": "$18,284",
            "Cost per Result": "$46.55"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "738937",
            "Clicks": "12930",
            "Conversions": "91",
            "Conversion Value": "$22,365",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "639329",
            "Clicks": "12460",
            "Conversions": "109",
            "Conversion Value": "$21,737",
            "Cost per Result": "$58.65"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "793322",
            "Clicks": "10814",
            "Conversions": "122",
            "Conversion Value": "$30,492",
            "Cost per Result": "$49.79"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "513280",
            "Clicks": "10254",
            "Conversions": "161",
            "Conversion Value": "$29,343",
            "Cost per Result": "$55.29"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "582003",
            "Clicks": "4984",
            "Conversions": "141",
            "Conversion Value": "$12,077",
            "Cost per Result": "$35.74"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "873830",
            "Clicks": "7642",
            "Conversions": "120",
            "Conversion Value": "$20,490",
            "Cost per Result": "$44.50"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "387316",
            "Clicks": "11197",
            "Conversions": "93",
            "Conversion Value": "$26,827",
            "Cost per Result": "$55.03"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "909539",
            "Clicks": "6952",
            "Conversions": "114",
            "Conversion Value": "$12,528",
            "Cost per Result": "$18.87"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "625492",
            "Clicks": "4428",
            "Conversions": "154",
            "Conversion Value": "$24,918",
            "Cost per Result": "$48.69"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "693081",
            "Clicks": "6168",
            "Conversions": "140",
            "Conversion Value": "$14,253",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "943646",
            "Clicks": "5913",
            "Conversions": "176",
            "Conversion Value": "$10,667",
            "Cost per Result": "$34.50"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "760451",
            "Clicks": "6927",
            "Conversions": "121",
            "Conversion Value": "$15,921",
            "Cost per Result": "$36.39"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "763943",
            "Clicks": "11062",
            "Conversions": "103",
            "Conversion Value": "$24,816",
            "Cost per Result": "$25.08"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "387293",
            "Clicks": "13873",
            "Conversions": "169",
            "Conversion Value": "$8,231",
            "Cost per Result": "$23.92"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "604344",
            "Clicks": "13518",
            "Conversions": "152",
            "Conversion Value": "$20,284",
            "Cost per Result": "$25.70"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "609442",
            "Clicks": "5246",
            "Conversions": "101",
            "Conversion Value": "$21,500",
            "Cost per Result": "$36.32"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "898102",
            "Clicks": "9224",
            "Conversions": "125",
            "Conversion Value": "$21,819",
            "Cost per Result": "$56.11"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "216642",
            "Clicks": "12417",
            "Conversions": "153",
            "Conversion Value": "$17,877",
            "Cost per Result": "$56.28"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "932711",
            "Clicks": "8029",
            "Conversions": "167",
            "Conversion Value": "$29,710",
            "Cost per Result": "$37.70"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "455172",
            "Clicks": "4990",
            "Conversions": "152",
            "Conversion Value": "$13,062",
            "Cost per Result": "$21.03"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "362120",
            "Clicks": "7912",
            "Conversions": "153",
            "Conversion Value": "$20,415",
            "Cost per Result": "$54.34"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "641794",
            "Clicks": "13483",
            "Conversions": "118",
            "Conversion Value": "$15,369",
            "Cost per Result": "$36.02"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "405161",
            "Clicks": "8151",
            "Conversions": "120",
            "Conversion Value": "$27,095",
            "Cost per Result": "$35.17"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "715893",
            "Clicks": "10480",
            "Conversions": "173",
            "Conversion Value": "$8,091",
            "Cost per Result": "$18.39"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "682217",
            "Clicks": "5687",
            "Conversions": "137",
            "Conversion Value": "$25,290",
            "Cost per Result": "$21.76"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "898879",
            "Clicks": "5759",
            "Conversions": "105",
            "Conversion Value": "$31,558",
            "Cost per Result": "$52.71"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "677594",
            "Clicks": "12387",
            "Conversions": "173",
            "Conversion Value": "$27,498",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "812630",
            "Clicks": "9721",
            "Conversions": "138",
            "Conversion Value": "$22,569",
            "Cost per Result": "$58.86"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "925704",
            "Clicks": "6863",
            "Conversions": "162",
            "Conversion Value": "$21,673",
            "Cost per Result": "$40.15"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "517097",
            "Clicks": "11254",
            "Conversions": "180",
            "Conversion Value": "$14,550",
            "Cost per Result": "$16.22"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "546463",
            "Clicks": "10981",
            "Conversions": "99",
            "Conversion Value": "$10,552",
            "Cost per Result": "$31.55"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "208835",
            "Clicks": "11080",
            "Conversions": "134",
            "Conversion Value": "$22,061",
            "Cost per Result": "$54.72"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "303209",
            "Clicks": "9158",
            "Conversions": "173",
            "Conversion Value": "$23,439",
            "Cost per Result": "$53.46"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "987273",
            "Clicks": "4858",
            "Conversions": "124",
            "Conversion Value": "$17,321",
            "Cost per Result": "$32.59"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "991245",
            "Clicks": "5075",
            "Conversions": "87",
            "Conversion Value": "$23,327",
            "Cost per Result": "$56.32"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "916761",
            "Clicks": "13738",
            "Conversions": "88",
            "Conversion Value": "$24,431",
            "Cost per Result": "$30.05"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "815815",
            "Clicks": "10646",
            "Conversions": "155",
            "Conversion Value": "$11,871",
            "Cost per Result": "$18.34"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "724833",
            "Clicks": "5192",
            "Conversions": "96",
            "Conversion Value": "$17,636",
            "Cost per Result": "$42.21"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "676325",
            "Clicks": "11934",
            "Conversions": "89",
            "Conversion Value": "$24,314",
            "Cost per Result": "$59.57"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "378506",
            "Clicks": "7532",
            "Conversions": "147",
            "Conversion Value": "$11,355",
            "Cost per Result": "$35.89"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "792727",
            "Clicks": "5652",
            "Conversions": "107",
            "Conversion Value": "$23,479",
            "Cost per Result": "$55.13"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "530864",
            "Clicks": "5314",
            "Conversions": "98",
            "Conversion Value": "$12,289",
            "Cost per Result": "$29.71"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "806771",
            "Clicks": "12648",
            "Conversions": "152",
            "Conversion Value": "$9,833",
            "Cost per Result": "$32.74"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "452322",
            "Clicks": "5403",
            "Conversions": "100",
            "Conversion Value": "$25,373",
            "Cost per Result": "$19.98"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "944041",
            "Clicks": "5045",
            "Conversions": "145",
            "Conversion Value": "$10,508",
            "Cost per Result": "$43.45"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "940028",
            "Clicks": "9045",
            "Conversions": "125",
            "Conversion Value": "$30,034",
            "Cost per Result": "$41.00"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "473853",
            "Clicks": "5346",
            "Conversions": "133",
            "Conversion Value": "$31,284",
            "Cost per Result": "$17.03"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "382423",
            "Clicks": "10867",
            "Conversions": "145",
            "Conversion Value": "$16,488",
            "Cost per Result": "$24.38"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "857013",
            "Clicks": "6266",
            "Conversions": "144",
            "Conversion Value": "$8,787",
            "Cost per Result": "$23.18"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "354287",
            "Clicks": "12397",
            "Conversions": "133",
            "Conversion Value": "$21,825",
            "Cost per Result": "$50.58"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "592597",
            "Clicks": "12606",
            "Conversions": "136",
            "Conversion Value": "$24,509",
            "Cost per Result": "$59.48"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "303205",
            "Clicks": "13689",
            "Conversions": "126",
            "Conversion Value": "$17,431",
            "Cost per Result": "$41.54"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "893218",
            "Clicks": "12426",
            "Conversions": "136",
            "Conversion Value": "$28,251",
            "Cost per Result": "$33.63"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "896559",
            "Clicks": "9834",
            "Conversions": "146",
            "Conversion Value": "$12,879",
            "Cost per Result": "$35.96"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "625012",
            "Clicks": "6482",
            "Conversions": "153",
            "Conversion Value": "$19,191",
            "Cost per Result": "$44.50"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "226439",
            "Clicks": "9240",
            "Conversions": "118",
            "Conversion Value": "$13,445",
            "Cost per Result": "$46.41"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "597817",
            "Clicks": "7763",
            "Conversions": "102",
            "Conversion Value": "$12,049",
            "Cost per Result": "$55.55"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "678539",
            "Clicks": "12013",
            "Conversions": "95",
            "Conversion Value": "$22,749",
            "Cost per Result": "$31.92"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "299121",
            "Clicks": "8417",
            "Conversions": "80",
            "Conversion Value": "$20,530",
            "Cost per Result": "$23.33"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "782642",
            "Clicks": "10904",
            "Conversions": "103",
            "Conversion Value": "$15,058",
            "Cost per Result": "$42.22"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "811123",
            "Clicks": "8892",
            "Conversions": "93",
            "Conversion Value": "$22,309",
            "Cost per Result": "$43.30"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "662142",
            "Clicks": "4962",
            "Conversions": "112",
            "Conversion Value": "$29,045",
            "Cost per Result": "$28.53"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "475253",
            "Clicks": "4079",
            "Conversions": "170",
            "Conversion Value": "$25,598",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "235504",
            "Clicks": "10318",
            "Conversions": "133",
            "Conversion Value": "$20,588",
            "Cost per Result": "$22.37"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "676515",
            "Clicks": "7372",
            "Conversions": "136",
            "Conversion Value": "$8,677",
            "Cost per Result": "$21.38"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "704101",
            "Clicks": "10727",
            "Conversions": "108",
            "Conversion Value": "$27,614",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "377487",
            "Clicks": "5188",
            "Conversions": "167",
            "Conversion Value": "$28,618",
            "Cost per Result": "$40.67"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "660915",
            "Clicks": "7280",
            "Conversions": "131",
            "Conversion Value": "$9,187",
            "Cost per Result": "$18.74"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "667681",
            "Clicks": "9348",
            "Conversions": "126",
            "Conversion Value": "$27,465",
            "Cost per Result": "$17.40"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "308491",
            "Clicks": "5969",
            "Conversions": "122",
            "Conversion Value": "$10,913",
            "Cost per Result": "$15.35"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "599588",
            "Clicks": "11303",
            "Conversions": "119",
            "Conversion Value": "$27,040",
            "Cost per Result": "$56.87"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "679738",
            "Clicks": "9659",
            "Conversions": "93",
            "Conversion Value": "$11,020",
            "Cost per Result": "$24.29"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "212068",
            "Clicks": "12295",
            "Conversions": "162",
            "Conversion Value": "$12,744",
            "Cost per Result": "$21.13"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "672038",
            "Clicks": "8143",
            "Conversions": "156",
            "Conversion Value": "$22,990",
            "Cost per Result": "$27.04"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "211095",
            "Clicks": "4625",
            "Conversions": "90",
            "Conversion Value": "$27,644",
            "Cost per Result": "$29.49"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "356211",
            "Clicks": "13827",
            "Conversions": "97",
            "Conversion Value": "$18,766",
            "Cost per Result": "$28.59"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "666328",
            "Clicks": "7199",
            "Conversions": "131",
            "Conversion Value": "$19,963",
            "Cost per Result": "$27.27"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "992270",
            "Clicks": "8510",
            "Conversions": "95",
            "Conversion Value": "$9,921",
            "Cost per Result": "$22.87"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "330710",
            "Clicks": "9998",
            "Conversions": "108",
            "Conversion Value": "$13,030",
            "Cost per Result": "$19.27"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "621455",
            "Clicks": "7780",
            "Conversions": "138",
            "Conversion Value": "$17,884",
            "Cost per Result": "$25.23"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "903446",
            "Clicks": "6927",
            "Conversions": "150",
            "Conversion Value": "$19,644",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "918705",
            "Clicks": "7490",
            "Conversions": "121",
            "Conversion Value": "$8,744",
            "Cost per Result": "$40.73"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "500492",
            "Clicks": "10414",
            "Conversions": "146",
            "Conversion Value": "$27,709",
            "Cost per Result": "$31.21"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "854228",
            "Clicks": "12404",
            "Conversions": "90",
            "Conversion Value": "$18,193",
            "Cost per Result": "$33.77"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "400152",
            "Clicks": "7975",
            "Conversions": "96",
            "Conversion Value": "$13,490",
            "Cost per Result": "$19.34"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "862448",
            "Clicks": "10615",
            "Conversions": "171",
            "Conversion Value": "$17,820",
            "Cost per Result": "$52.65"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "485793",
            "Clicks": "5617",
            "Conversions": "82",
            "Conversion Value": "$19,639",
            "Cost per Result": "$44.53"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "848769",
            "Clicks": "11108",
            "Conversions": "162",
            "Conversion Value": "$10,065",
            "Cost per Result": "$26.67"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "267133",
            "Clicks": "13310",
            "Conversions": "94",
            "Conversion Value": "$21,688",
            "Cost per Result": "$27.93"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "639674",
            "Clicks": "12710",
            "Conversions": "168",
            "Conversion Value": "$11,279",
            "Cost per Result": "$50.34"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "342251",
            "Clicks": "7669",
            "Conversions": "150",
            "Conversion Value": "$11,406",
            "Cost per Result": "$29.27"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "522164",
            "Clicks": "4481",
            "Conversions": "162",
            "Conversion Value": "$29,147",
            "Cost per Result": "$18.69"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "943429",
            "Clicks": "8607",
            "Conversions": "179",
            "Conversion Value": "$28,457",
            "Cost per Result": "$54.27"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "209714",
            "Clicks": "13818",
            "Conversions": "158",
            "Conversion Value": "$15,621",
            "Cost per Result": "$22.10"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "932608",
            "Clicks": "12706",
            "Conversions": "95",
            "Conversion Value": "$23,373",
            "Cost per Result": "$52.50"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "761379",
            "Clicks": "4518",
            "Conversions": "82",
            "Conversion Value": "$9,762",
            "Cost per Result": "$21.37"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "280359",
            "Clicks": "12808",
            "Conversions": "164",
            "Conversion Value": "$16,879",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "878525",
            "Clicks": "12884",
            "Conversions": "121",
            "Conversion Value": "$21,686",
            "Cost per Result": "$34.97"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "742467",
            "Clicks": "12554",
            "Conversions": "156",
            "Conversion Value": "$8,989",
            "Cost per Result": "$48.02"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "982725",
            "Clicks": "12498",
            "Conversions": "107",
            "Conversion Value": "$23,269",
            "Cost per Result": "$25.19"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "669186",
            "Clicks": "6642",
            "Conversions": "167",
            "Conversion Value": "$13,800",
            "Cost per Result": "$45.16"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "873550",
            "Clicks": "4207",
            "Conversions": "127",
            "Conversion Value": "$24,213",
            "Cost per Result": "$21.30"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "435563",
            "Clicks": "13331",
            "Conversions": "94",
            "Conversion Value": "$10,063",
            "Cost per Result": "$16.88"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "874194",
            "Clicks": "5637",
            "Conversions": "100",
            "Conversion Value": "$17,397",
            "Cost per Result": "$25.25"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "320650",
            "Clicks": "9538",
            "Conversions": "118",
            "Conversion Value": "$9,053",
            "Cost per Result": "$39.37"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "456105",
            "Clicks": "7308",
            "Conversions": "136",
            "Conversion Value": "$31,802",
            "Cost per Result": "$57.22"
          }
        ]
      },
      "P2EGamersData": {
        "name": "P2E Gamers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "186177",
            "Clicks": "3110",
            "Conversions": "59",
            "Conversion Value": "$14,968",
            "Cost per Result": "$21.52"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "102864",
            "Clicks": "4916",
            "Conversions": "86",
            "Conversion Value": "$4,493",
            "Cost per Result": "$28.65"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "342249",
            "Clicks": "3092",
            "Conversions": "80",
            "Conversion Value": "$11,554",
            "Cost per Result": "$40.10"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "109904",
            "Clicks": "6413",
            "Conversions": "72",
            "Conversion Value": "$11,415",
            "Cost per Result": "$47.92"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "158246",
            "Clicks": "6000",
            "Conversions": "78",
            "Conversion Value": "$8,190",
            "Cost per Result": "$15.00"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "155631",
            "Clicks": "2137",
            "Conversions": "81",
            "Conversion Value": "$4,174",
            "Cost per Result": "$18.05"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "427232",
            "Clicks": "4858",
            "Conversions": "90",
            "Conversion Value": "$7,895",
            "Cost per Result": "$45.64"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "152035",
            "Clicks": "4029",
            "Conversions": "56",
            "Conversion Value": "$11,983",
            "Cost per Result": "$50.86"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "320878",
            "Clicks": "5324",
            "Conversions": "65",
            "Conversion Value": "$10,752",
            "Cost per Result": "$58.48"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "420572",
            "Clicks": "3074",
            "Conversions": "62",
            "Conversion Value": "$6,292",
            "Cost per Result": "$41.09"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "170956",
            "Clicks": "6887",
            "Conversions": "53",
            "Conversion Value": "$13,944",
            "Cost per Result": "$34.96"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "328588",
            "Clicks": "5038",
            "Conversions": "73",
            "Conversion Value": "$8,457",
            "Cost per Result": "$58.73"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "441362",
            "Clicks": "5898",
            "Conversions": "73",
            "Conversion Value": "$7,341",
            "Cost per Result": "$23.14"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "498184",
            "Clicks": "6282",
            "Conversions": "41",
            "Conversion Value": "$14,516",
            "Cost per Result": "$49.18"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "119996",
            "Clicks": "4733",
            "Conversions": "68",
            "Conversion Value": "$6,969",
            "Cost per Result": "$56.26"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "125941",
            "Clicks": "5720",
            "Conversions": "62",
            "Conversion Value": "$14,107",
            "Cost per Result": "$22.20"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "272194",
            "Clicks": "5595",
            "Conversions": "72",
            "Conversion Value": "$11,301",
            "Cost per Result": "$30.12"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "394425",
            "Clicks": "3884",
            "Conversions": "51",
            "Conversion Value": "$13,367",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "230549",
            "Clicks": "3245",
            "Conversions": "62",
            "Conversion Value": "$6,832",
            "Cost per Result": "$24.31"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "297921",
            "Clicks": "4291",
            "Conversions": "55",
            "Conversion Value": "$10,188",
            "Cost per Result": "$17.05"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "451993",
            "Clicks": "6471",
            "Conversions": "85",
            "Conversion Value": "$4,495",
            "Cost per Result": "$55.51"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "450750",
            "Clicks": "5793",
            "Conversions": "67",
            "Conversion Value": "$11,014",
            "Cost per Result": "$55.66"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "184881",
            "Clicks": "4223",
            "Conversions": "86",
            "Conversion Value": "$9,385",
            "Cost per Result": "$45.42"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "382811",
            "Clicks": "5837",
            "Conversions": "88",
            "Conversion Value": "$12,518",
            "Cost per Result": "$35.17"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "121265",
            "Clicks": "4305",
            "Conversions": "90",
            "Conversion Value": "$4,611",
            "Cost per Result": "$29.84"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "321554",
            "Clicks": "6847",
            "Conversions": "74",
            "Conversion Value": "$15,331",
            "Cost per Result": "$44.79"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "258010",
            "Clicks": "5605",
            "Conversions": "85",
            "Conversion Value": "$4,890",
            "Cost per Result": "$19.00"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "161023",
            "Clicks": "5533",
            "Conversions": "65",
            "Conversion Value": "$15,627",
            "Cost per Result": "$32.13"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "382068",
            "Clicks": "6469",
            "Conversions": "60",
            "Conversion Value": "$11,299",
            "Cost per Result": "$40.67"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "357100",
            "Clicks": "4416",
            "Conversions": "60",
            "Conversion Value": "$13,887",
            "Cost per Result": "$31.31"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "388069",
            "Clicks": "6725",
            "Conversions": "82",
            "Conversion Value": "$13,300",
            "Cost per Result": "$44.82"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "374964",
            "Clicks": "6227",
            "Conversions": "73",
            "Conversion Value": "$7,567",
            "Cost per Result": "$36.57"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "320097",
            "Clicks": "3416",
            "Conversions": "55",
            "Conversion Value": "$15,141",
            "Cost per Result": "$24.09"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "242564",
            "Clicks": "5015",
            "Conversions": "63",
            "Conversion Value": "$15,776",
            "Cost per Result": "$28.78"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "424567",
            "Clicks": "4978",
            "Conversions": "77",
            "Conversion Value": "$15,788",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "106475",
            "Clicks": "3525",
            "Conversions": "75",
            "Conversion Value": "$4,061",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "259669",
            "Clicks": "6554",
            "Conversions": "75",
            "Conversion Value": "$6,973",
            "Cost per Result": "$55.93"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "498949",
            "Clicks": "2886",
            "Conversions": "65",
            "Conversion Value": "$4,247",
            "Cost per Result": "$26.60"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "411914",
            "Clicks": "4567",
            "Conversions": "61",
            "Conversion Value": "$4,364",
            "Cost per Result": "$49.26"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "143550",
            "Clicks": "4448",
            "Conversions": "63",
            "Conversion Value": "$12,240",
            "Cost per Result": "$39.30"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "436522",
            "Clicks": "4723",
            "Conversions": "86",
            "Conversion Value": "$7,411",
            "Cost per Result": "$40.60"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "469135",
            "Clicks": "6128",
            "Conversions": "85",
            "Conversion Value": "$12,106",
            "Cost per Result": "$26.05"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "211298",
            "Clicks": "2869",
            "Conversions": "83",
            "Conversion Value": "$4,712",
            "Cost per Result": "$23.33"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "266854",
            "Clicks": "4320",
            "Conversions": "78",
            "Conversion Value": "$13,581",
            "Cost per Result": "$55.07"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "350308",
            "Clicks": "3033",
            "Conversions": "79",
            "Conversion Value": "$7,709",
            "Cost per Result": "$21.11"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "492478",
            "Clicks": "6862",
            "Conversions": "85",
            "Conversion Value": "$14,648",
            "Cost per Result": "$55.49"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "372217",
            "Clicks": "4379",
            "Conversions": "82",
            "Conversion Value": "$15,559",
            "Cost per Result": "$25.13"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "447907",
            "Clicks": "6060",
            "Conversions": "79",
            "Conversion Value": "$8,975",
            "Cost per Result": "$22.27"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "298116",
            "Clicks": "6324",
            "Conversions": "49",
            "Conversion Value": "$9,204",
            "Cost per Result": "$46.01"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "113902",
            "Clicks": "6181",
            "Conversions": "58",
            "Conversion Value": "$13,654",
            "Cost per Result": "$45.99"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "135646",
            "Clicks": "6096",
            "Conversions": "62",
            "Conversion Value": "$14,974",
            "Cost per Result": "$17.89"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "274617",
            "Clicks": "6197",
            "Conversions": "63",
            "Conversion Value": "$15,983",
            "Cost per Result": "$54.89"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "392304",
            "Clicks": "5107",
            "Conversions": "49",
            "Conversion Value": "$13,564",
            "Cost per Result": "$34.79"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "378523",
            "Clicks": "2025",
            "Conversions": "67",
            "Conversion Value": "$4,515",
            "Cost per Result": "$41.55"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "184054",
            "Clicks": "6681",
            "Conversions": "76",
            "Conversion Value": "$5,900",
            "Cost per Result": "$15.02"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "306174",
            "Clicks": "2988",
            "Conversions": "73",
            "Conversion Value": "$9,348",
            "Cost per Result": "$42.34"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "130373",
            "Clicks": "4450",
            "Conversions": "40",
            "Conversion Value": "$13,958",
            "Cost per Result": "$26.52"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "130421",
            "Clicks": "3989",
            "Conversions": "66",
            "Conversion Value": "$9,060",
            "Cost per Result": "$35.01"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "449150",
            "Clicks": "4736",
            "Conversions": "79",
            "Conversion Value": "$14,045",
            "Cost per Result": "$17.77"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "308151",
            "Clicks": "3761",
            "Conversions": "55",
            "Conversion Value": "$6,796",
            "Cost per Result": "$52.07"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "111587",
            "Clicks": "5873",
            "Conversions": "49",
            "Conversion Value": "$12,003",
            "Cost per Result": "$26.70"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "164262",
            "Clicks": "3041",
            "Conversions": "64",
            "Conversion Value": "$14,973",
            "Cost per Result": "$37.14"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "146482",
            "Clicks": "4032",
            "Conversions": "87",
            "Conversion Value": "$10,918",
            "Cost per Result": "$27.59"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "429978",
            "Clicks": "6188",
            "Conversions": "43",
            "Conversion Value": "$6,854",
            "Cost per Result": "$29.65"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "384460",
            "Clicks": "4026",
            "Conversions": "64",
            "Conversion Value": "$4,963",
            "Cost per Result": "$31.18"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "271023",
            "Clicks": "6505",
            "Conversions": "89",
            "Conversion Value": "$12,910",
            "Cost per Result": "$30.67"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "226694",
            "Clicks": "5621",
            "Conversions": "69",
            "Conversion Value": "$9,416",
            "Cost per Result": "$51.30"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "360792",
            "Clicks": "6537",
            "Conversions": "83",
            "Conversion Value": "$12,137",
            "Cost per Result": "$47.36"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "361229",
            "Clicks": "3592",
            "Conversions": "88",
            "Conversion Value": "$4,167",
            "Cost per Result": "$22.05"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "274197",
            "Clicks": "4252",
            "Conversions": "43",
            "Conversion Value": "$8,532",
            "Cost per Result": "$15.20"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "416426",
            "Clicks": "3299",
            "Conversions": "70",
            "Conversion Value": "$12,719",
            "Cost per Result": "$43.59"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "335991",
            "Clicks": "2068",
            "Conversions": "76",
            "Conversion Value": "$12,030",
            "Cost per Result": "$54.06"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "449849",
            "Clicks": "2585",
            "Conversions": "48",
            "Conversion Value": "$14,990",
            "Cost per Result": "$38.70"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "270377",
            "Clicks": "5939",
            "Conversions": "75",
            "Conversion Value": "$12,600",
            "Cost per Result": "$26.51"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "367614",
            "Clicks": "5073",
            "Conversions": "83",
            "Conversion Value": "$9,611",
            "Cost per Result": "$18.71"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "165633",
            "Clicks": "4660",
            "Conversions": "47",
            "Conversion Value": "$5,142",
            "Cost per Result": "$58.94"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "331014",
            "Clicks": "5986",
            "Conversions": "76",
            "Conversion Value": "$10,994",
            "Cost per Result": "$29.10"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "268253",
            "Clicks": "6300",
            "Conversions": "82",
            "Conversion Value": "$14,143",
            "Cost per Result": "$30.06"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "342062",
            "Clicks": "3851",
            "Conversions": "86",
            "Conversion Value": "$12,539",
            "Cost per Result": "$29.86"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "217187",
            "Clicks": "3417",
            "Conversions": "68",
            "Conversion Value": "$14,133",
            "Cost per Result": "$16.45"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "347163",
            "Clicks": "3435",
            "Conversions": "76",
            "Conversion Value": "$5,928",
            "Cost per Result": "$15.18"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "462580",
            "Clicks": "5974",
            "Conversions": "78",
            "Conversion Value": "$11,434",
            "Cost per Result": "$34.42"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "128941",
            "Clicks": "5836",
            "Conversions": "41",
            "Conversion Value": "$8,721",
            "Cost per Result": "$27.46"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "308926",
            "Clicks": "5520",
            "Conversions": "85",
            "Conversion Value": "$15,716",
            "Cost per Result": "$31.94"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "372377",
            "Clicks": "6542",
            "Conversions": "50",
            "Conversion Value": "$7,297",
            "Cost per Result": "$57.78"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "484805",
            "Clicks": "4056",
            "Conversions": "61",
            "Conversion Value": "$13,066",
            "Cost per Result": "$24.43"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "326014",
            "Clicks": "4762",
            "Conversions": "43",
            "Conversion Value": "$11,615",
            "Cost per Result": "$44.39"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "356604",
            "Clicks": "6782",
            "Conversions": "60",
            "Conversion Value": "$4,632",
            "Cost per Result": "$25.47"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "396528",
            "Clicks": "5183",
            "Conversions": "51",
            "Conversion Value": "$4,076",
            "Cost per Result": "$46.08"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "339572",
            "Clicks": "5594",
            "Conversions": "68",
            "Conversion Value": "$6,913",
            "Cost per Result": "$32.21"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "111123",
            "Clicks": "4373",
            "Conversions": "66",
            "Conversion Value": "$7,828",
            "Cost per Result": "$54.38"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "364902",
            "Clicks": "6445",
            "Conversions": "48",
            "Conversion Value": "$12,319",
            "Cost per Result": "$21.22"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "484001",
            "Clicks": "3107",
            "Conversions": "77",
            "Conversion Value": "$10,902",
            "Cost per Result": "$23.62"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "166441",
            "Clicks": "2549",
            "Conversions": "46",
            "Conversion Value": "$12,087",
            "Cost per Result": "$56.40"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "188671",
            "Clicks": "5326",
            "Conversions": "56",
            "Conversion Value": "$5,239",
            "Cost per Result": "$31.84"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "166412",
            "Clicks": "4544",
            "Conversions": "47",
            "Conversion Value": "$8,736",
            "Cost per Result": "$30.54"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "356746",
            "Clicks": "6012",
            "Conversions": "50",
            "Conversion Value": "$5,666",
            "Cost per Result": "$32.11"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "458704",
            "Clicks": "5319",
            "Conversions": "89",
            "Conversion Value": "$10,225",
            "Cost per Result": "$36.08"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "316518",
            "Clicks": "5990",
            "Conversions": "48",
            "Conversion Value": "$5,668",
            "Cost per Result": "$24.81"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "384808",
            "Clicks": "4774",
            "Conversions": "56",
            "Conversion Value": "$13,535",
            "Cost per Result": "$34.35"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "203396",
            "Clicks": "5258",
            "Conversions": "81",
            "Conversion Value": "$4,972",
            "Cost per Result": "$22.25"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "126281",
            "Clicks": "3769",
            "Conversions": "73",
            "Conversion Value": "$9,522",
            "Cost per Result": "$20.99"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "101938",
            "Clicks": "4460",
            "Conversions": "45",
            "Conversion Value": "$13,868",
            "Cost per Result": "$45.32"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "235129",
            "Clicks": "6148",
            "Conversions": "64",
            "Conversion Value": "$14,183",
            "Cost per Result": "$39.44"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "430613",
            "Clicks": "2335",
            "Conversions": "71",
            "Conversion Value": "$10,251",
            "Cost per Result": "$25.15"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "407255",
            "Clicks": "6883",
            "Conversions": "41",
            "Conversion Value": "$11,899",
            "Cost per Result": "$53.05"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "400977",
            "Clicks": "6471",
            "Conversions": "55",
            "Conversion Value": "$13,893",
            "Cost per Result": "$59.88"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "172985",
            "Clicks": "2927",
            "Conversions": "85",
            "Conversion Value": "$14,158",
            "Cost per Result": "$58.29"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "149784",
            "Clicks": "3204",
            "Conversions": "43",
            "Conversion Value": "$10,607",
            "Cost per Result": "$30.94"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "112655",
            "Clicks": "3535",
            "Conversions": "67",
            "Conversion Value": "$6,520",
            "Cost per Result": "$22.78"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "263733",
            "Clicks": "5799",
            "Conversions": "67",
            "Conversion Value": "$10,131",
            "Cost per Result": "$21.04"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "465764",
            "Clicks": "4211",
            "Conversions": "51",
            "Conversion Value": "$13,543",
            "Cost per Result": "$53.91"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "231682",
            "Clicks": "2703",
            "Conversions": "64",
            "Conversion Value": "$14,554",
            "Cost per Result": "$41.23"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "431731",
            "Clicks": "3637",
            "Conversions": "50",
            "Conversion Value": "$5,621",
            "Cost per Result": "$40.32"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "138836",
            "Clicks": "5156",
            "Conversions": "59",
            "Conversion Value": "$11,293",
            "Cost per Result": "$24.44"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "482794",
            "Clicks": "2304",
            "Conversions": "84",
            "Conversion Value": "$9,515",
            "Cost per Result": "$39.06"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "363573",
            "Clicks": "2233",
            "Conversions": "90",
            "Conversion Value": "$5,839",
            "Cost per Result": "$40.14"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "329964",
            "Clicks": "3827",
            "Conversions": "68",
            "Conversion Value": "$15,144",
            "Cost per Result": "$46.55"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "383545",
            "Clicks": "6566",
            "Conversions": "76",
            "Conversion Value": "$11,615",
            "Cost per Result": "$44.33"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "287722",
            "Clicks": "2493",
            "Conversions": "56",
            "Conversion Value": "$7,266",
            "Cost per Result": "$38.71"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "307798",
            "Clicks": "4097",
            "Conversions": "49",
            "Conversion Value": "$6,386",
            "Cost per Result": "$44.14"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "439734",
            "Clicks": "5224",
            "Conversions": "43",
            "Conversion Value": "$5,200",
            "Cost per Result": "$54.57"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "221624",
            "Clicks": "5677",
            "Conversions": "44",
            "Conversion Value": "$6,348",
            "Cost per Result": "$39.61"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "365197",
            "Clicks": "6123",
            "Conversions": "81",
            "Conversion Value": "$8,784",
            "Cost per Result": "$45.54"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "287759",
            "Clicks": "2945",
            "Conversions": "79",
            "Conversion Value": "$14,142",
            "Cost per Result": "$54.12"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "273523",
            "Clicks": "2231",
            "Conversions": "53",
            "Conversion Value": "$5,696",
            "Cost per Result": "$59.88"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "149365",
            "Clicks": "4521",
            "Conversions": "90",
            "Conversion Value": "$12,258",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "225278",
            "Clicks": "3942",
            "Conversions": "70",
            "Conversion Value": "$9,693",
            "Cost per Result": "$25.49"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "116688",
            "Clicks": "3984",
            "Conversions": "46",
            "Conversion Value": "$13,263",
            "Cost per Result": "$39.34"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "182238",
            "Clicks": "6278",
            "Conversions": "48",
            "Conversion Value": "$4,643",
            "Cost per Result": "$20.24"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "410790",
            "Clicks": "6760",
            "Conversions": "78",
            "Conversion Value": "$8,447",
            "Cost per Result": "$34.64"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "202822",
            "Clicks": "2537",
            "Conversions": "53",
            "Conversion Value": "$15,021",
            "Cost per Result": "$49.05"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "136565",
            "Clicks": "3786",
            "Conversions": "41",
            "Conversion Value": "$4,532",
            "Cost per Result": "$40.90"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "136211",
            "Clicks": "6427",
            "Conversions": "47",
            "Conversion Value": "$14,816",
            "Cost per Result": "$27.36"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "346304",
            "Clicks": "3554",
            "Conversions": "83",
            "Conversion Value": "$4,237",
            "Cost per Result": "$55.33"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "205424",
            "Clicks": "3280",
            "Conversions": "71",
            "Conversion Value": "$7,278",
            "Cost per Result": "$59.07"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "448966",
            "Clicks": "2764",
            "Conversions": "75",
            "Conversion Value": "$11,293",
            "Cost per Result": "$49.18"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "124069",
            "Clicks": "5233",
            "Conversions": "58",
            "Conversion Value": "$14,358",
            "Cost per Result": "$42.54"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "164777",
            "Clicks": "6819",
            "Conversions": "62",
            "Conversion Value": "$11,148",
            "Cost per Result": "$24.63"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "462307",
            "Clicks": "5638",
            "Conversions": "82",
            "Conversion Value": "$12,635",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "296613",
            "Clicks": "3633",
            "Conversions": "61",
            "Conversion Value": "$4,418",
            "Cost per Result": "$58.21"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "144737",
            "Clicks": "2864",
            "Conversions": "55",
            "Conversion Value": "$14,770",
            "Cost per Result": "$47.77"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "397195",
            "Clicks": "4754",
            "Conversions": "88",
            "Conversion Value": "$9,203",
            "Cost per Result": "$45.41"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "487914",
            "Clicks": "4880",
            "Conversions": "40",
            "Conversion Value": "$13,263",
            "Cost per Result": "$20.21"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "393814",
            "Clicks": "2491",
            "Conversions": "68",
            "Conversion Value": "$6,985",
            "Cost per Result": "$21.49"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "210399",
            "Clicks": "5475",
            "Conversions": "83",
            "Conversion Value": "$9,442",
            "Cost per Result": "$41.65"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "160760",
            "Clicks": "2258",
            "Conversions": "77",
            "Conversion Value": "$9,847",
            "Cost per Result": "$21.56"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "297740",
            "Clicks": "3179",
            "Conversions": "72",
            "Conversion Value": "$6,060",
            "Cost per Result": "$37.45"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "305958",
            "Clicks": "3429",
            "Conversions": "68",
            "Conversion Value": "$13,262",
            "Cost per Result": "$28.21"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "483327",
            "Clicks": "5045",
            "Conversions": "62",
            "Conversion Value": "$11,484",
            "Cost per Result": "$26.16"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "195525",
            "Clicks": "5998",
            "Conversions": "78",
            "Conversion Value": "$4,704",
            "Cost per Result": "$55.69"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "400313",
            "Clicks": "2868",
            "Conversions": "59",
            "Conversion Value": "$6,016",
            "Cost per Result": "$44.93"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "242688",
            "Clicks": "3991",
            "Conversions": "70",
            "Conversion Value": "$7,561",
            "Cost per Result": "$50.21"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "237083",
            "Clicks": "2415",
            "Conversions": "76",
            "Conversion Value": "$13,057",
            "Cost per Result": "$16.40"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "431324",
            "Clicks": "5689",
            "Conversions": "62",
            "Conversion Value": "$7,189",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "238022",
            "Clicks": "6127",
            "Conversions": "47",
            "Conversion Value": "$12,337",
            "Cost per Result": "$53.03"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "256292",
            "Clicks": "2128",
            "Conversions": "49",
            "Conversion Value": "$4,188",
            "Cost per Result": "$40.09"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "162650",
            "Clicks": "3068",
            "Conversions": "75",
            "Conversion Value": "$13,763",
            "Cost per Result": "$60.00"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "200972",
            "Clicks": "4398",
            "Conversions": "71",
            "Conversion Value": "$6,691",
            "Cost per Result": "$39.50"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "160862",
            "Clicks": "6709",
            "Conversions": "80",
            "Conversion Value": "$9,295",
            "Cost per Result": "$21.80"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "226352",
            "Clicks": "5760",
            "Conversions": "58",
            "Conversion Value": "$6,387",
            "Cost per Result": "$37.52"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "432638",
            "Clicks": "6238",
            "Conversions": "74",
            "Conversion Value": "$12,863",
            "Cost per Result": "$51.37"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "136445",
            "Clicks": "6489",
            "Conversions": "73",
            "Conversion Value": "$10,609",
            "Cost per Result": "$28.50"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "490941",
            "Clicks": "6390",
            "Conversions": "70",
            "Conversion Value": "$9,346",
            "Cost per Result": "$38.58"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "363623",
            "Clicks": "3455",
            "Conversions": "44",
            "Conversion Value": "$15,648",
            "Cost per Result": "$45.86"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "491804",
            "Clicks": "2348",
            "Conversions": "75",
            "Conversion Value": "$11,179",
            "Cost per Result": "$28.75"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "267885",
            "Clicks": "2158",
            "Conversions": "56",
            "Conversion Value": "$7,923",
            "Cost per Result": "$38.11"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "371974",
            "Clicks": "6723",
            "Conversions": "42",
            "Conversion Value": "$11,645",
            "Cost per Result": "$35.01"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "280539",
            "Clicks": "4268",
            "Conversions": "64",
            "Conversion Value": "$5,136",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "159674",
            "Clicks": "6375",
            "Conversions": "67",
            "Conversion Value": "$11,500",
            "Cost per Result": "$48.10"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "491053",
            "Clicks": "2410",
            "Conversions": "70",
            "Conversion Value": "$5,338",
            "Cost per Result": "$15.54"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "496786",
            "Clicks": "5310",
            "Conversions": "53",
            "Conversion Value": "$12,445",
            "Cost per Result": "$58.52"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "152759",
            "Clicks": "6226",
            "Conversions": "45",
            "Conversion Value": "$14,234",
            "Cost per Result": "$30.97"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "111158",
            "Clicks": "5670",
            "Conversions": "67",
            "Conversion Value": "$5,151",
            "Cost per Result": "$38.38"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "177006",
            "Clicks": "2662",
            "Conversions": "69",
            "Conversion Value": "$15,172",
            "Cost per Result": "$47.96"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "267171",
            "Clicks": "5269",
            "Conversions": "61",
            "Conversion Value": "$13,189",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "135566",
            "Clicks": "6553",
            "Conversions": "66",
            "Conversion Value": "$13,298",
            "Cost per Result": "$51.79"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "343610",
            "Clicks": "4994",
            "Conversions": "47",
            "Conversion Value": "$11,113",
            "Cost per Result": "$27.11"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "468491",
            "Clicks": "2169",
            "Conversions": "71",
            "Conversion Value": "$9,259",
            "Cost per Result": "$56.32"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "264316",
            "Clicks": "6777",
            "Conversions": "78",
            "Conversion Value": "$11,209",
            "Cost per Result": "$40.86"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "400890",
            "Clicks": "2243",
            "Conversions": "83",
            "Conversion Value": "$4,773",
            "Cost per Result": "$42.49"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "243876",
            "Clicks": "3980",
            "Conversions": "85",
            "Conversion Value": "$8,649",
            "Cost per Result": "$52.85"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "140759",
            "Clicks": "4860",
            "Conversions": "67",
            "Conversion Value": "$11,057",
            "Cost per Result": "$54.21"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "294278",
            "Clicks": "5566",
            "Conversions": "78",
            "Conversion Value": "$11,018",
            "Cost per Result": "$20.49"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "325222",
            "Clicks": "2229",
            "Conversions": "70",
            "Conversion Value": "$4,621",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "136452",
            "Clicks": "3018",
            "Conversions": "54",
            "Conversion Value": "$10,877",
            "Cost per Result": "$58.67"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "200202",
            "Clicks": "5206",
            "Conversions": "46",
            "Conversion Value": "$6,941",
            "Cost per Result": "$20.34"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "121880",
            "Clicks": "5060",
            "Conversions": "69",
            "Conversion Value": "$5,042",
            "Cost per Result": "$46.21"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "285585",
            "Clicks": "2014",
            "Conversions": "59",
            "Conversion Value": "$14,797",
            "Cost per Result": "$54.05"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "123924",
            "Clicks": "2597",
            "Conversions": "59",
            "Conversion Value": "$5,049",
            "Cost per Result": "$26.42"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "452407",
            "Clicks": "5265",
            "Conversions": "79",
            "Conversion Value": "$5,383",
            "Cost per Result": "$55.02"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "317681",
            "Clicks": "6365",
            "Conversions": "85",
            "Conversion Value": "$13,451",
            "Cost per Result": "$42.96"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "410214",
            "Clicks": "3290",
            "Conversions": "41",
            "Conversion Value": "$5,136",
            "Cost per Result": "$33.84"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "423489",
            "Clicks": "3486",
            "Conversions": "66",
            "Conversion Value": "$15,542",
            "Cost per Result": "$16.50"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "291939",
            "Clicks": "6065",
            "Conversions": "41",
            "Conversion Value": "$7,242",
            "Cost per Result": "$42.38"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "416521",
            "Clicks": "3699",
            "Conversions": "89",
            "Conversion Value": "$7,376",
            "Cost per Result": "$19.15"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "282536",
            "Clicks": "4794",
            "Conversions": "86",
            "Conversion Value": "$15,490",
            "Cost per Result": "$17.77"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "258385",
            "Clicks": "5721",
            "Conversions": "75",
            "Conversion Value": "$15,605",
            "Cost per Result": "$16.56"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "349383",
            "Clicks": "3139",
            "Conversions": "51",
            "Conversion Value": "$7,839",
            "Cost per Result": "$47.24"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "422472",
            "Clicks": "6964",
            "Conversions": "82",
            "Conversion Value": "$8,354",
            "Cost per Result": "$38.23"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "411514",
            "Clicks": "6331",
            "Conversions": "59",
            "Conversion Value": "$10,322",
            "Cost per Result": "$18.59"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "480679",
            "Clicks": "3267",
            "Conversions": "90",
            "Conversion Value": "$7,949",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "176811",
            "Clicks": "2079",
            "Conversions": "61",
            "Conversion Value": "$15,816",
            "Cost per Result": "$36.85"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "393853",
            "Clicks": "3060",
            "Conversions": "83",
            "Conversion Value": "$11,193",
            "Cost per Result": "$40.39"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "258330",
            "Clicks": "5711",
            "Conversions": "87",
            "Conversion Value": "$12,247",
            "Cost per Result": "$59.86"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "481889",
            "Clicks": "3423",
            "Conversions": "65",
            "Conversion Value": "$8,012",
            "Cost per Result": "$38.56"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "261143",
            "Clicks": "5668",
            "Conversions": "79",
            "Conversion Value": "$8,909",
            "Cost per Result": "$45.35"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "203274",
            "Clicks": "3262",
            "Conversions": "46",
            "Conversion Value": "$5,802",
            "Cost per Result": "$32.26"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "348900",
            "Clicks": "3078",
            "Conversions": "70",
            "Conversion Value": "$8,070",
            "Cost per Result": "$32.31"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "190565",
            "Clicks": "5427",
            "Conversions": "84",
            "Conversion Value": "$9,529",
            "Cost per Result": "$42.63"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "325490",
            "Clicks": "4309",
            "Conversions": "60",
            "Conversion Value": "$4,899",
            "Cost per Result": "$50.77"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "391551",
            "Clicks": "5397",
            "Conversions": "84",
            "Conversion Value": "$7,611",
            "Cost per Result": "$38.98"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "303587",
            "Clicks": "3981",
            "Conversions": "84",
            "Conversion Value": "$12,937",
            "Cost per Result": "$22.56"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "260957",
            "Clicks": "6531",
            "Conversions": "73",
            "Conversion Value": "$5,575",
            "Cost per Result": "$19.15"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "111804",
            "Clicks": "6198",
            "Conversions": "78",
            "Conversion Value": "$10,867",
            "Cost per Result": "$36.74"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "120587",
            "Clicks": "6859",
            "Conversions": "61",
            "Conversion Value": "$14,277",
            "Cost per Result": "$26.38"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "480013",
            "Clicks": "6352",
            "Conversions": "86",
            "Conversion Value": "$15,023",
            "Cost per Result": "$15.20"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "324525",
            "Clicks": "5778",
            "Conversions": "69",
            "Conversion Value": "$7,745",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "433881",
            "Clicks": "2908",
            "Conversions": "90",
            "Conversion Value": "$13,728",
            "Cost per Result": "$58.86"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "379243",
            "Clicks": "2520",
            "Conversions": "64",
            "Conversion Value": "$8,263",
            "Cost per Result": "$50.84"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "351760",
            "Clicks": "2459",
            "Conversions": "77",
            "Conversion Value": "$4,972",
            "Cost per Result": "$41.79"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "406110",
            "Clicks": "6035",
            "Conversions": "70",
            "Conversion Value": "$15,439",
            "Cost per Result": "$37.99"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "447917",
            "Clicks": "4856",
            "Conversions": "61",
            "Conversion Value": "$9,708",
            "Cost per Result": "$57.95"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "207435",
            "Clicks": "5380",
            "Conversions": "54",
            "Conversion Value": "$4,652",
            "Cost per Result": "$44.97"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "135234",
            "Clicks": "4830",
            "Conversions": "79",
            "Conversion Value": "$12,909",
            "Cost per Result": "$29.59"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "299160",
            "Clicks": "3398",
            "Conversions": "56",
            "Conversion Value": "$12,483",
            "Cost per Result": "$33.64"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "208570",
            "Clicks": "4390",
            "Conversions": "42",
            "Conversion Value": "$11,944",
            "Cost per Result": "$20.92"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "377629",
            "Clicks": "2426",
            "Conversions": "52",
            "Conversion Value": "$13,206",
            "Cost per Result": "$50.12"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "328924",
            "Clicks": "5033",
            "Conversions": "66",
            "Conversion Value": "$15,316",
            "Cost per Result": "$35.44"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "249495",
            "Clicks": "4975",
            "Conversions": "86",
            "Conversion Value": "$9,369",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "461668",
            "Clicks": "2120",
            "Conversions": "46",
            "Conversion Value": "$11,875",
            "Cost per Result": "$47.22"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "309155",
            "Clicks": "3545",
            "Conversions": "58",
            "Conversion Value": "$15,214",
            "Cost per Result": "$50.26"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "343707",
            "Clicks": "6263",
            "Conversions": "49",
            "Conversion Value": "$10,494",
            "Cost per Result": "$24.84"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "114645",
            "Clicks": "6154",
            "Conversions": "61",
            "Conversion Value": "$14,361",
            "Cost per Result": "$49.10"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "270363",
            "Clicks": "4446",
            "Conversions": "55",
            "Conversion Value": "$13,356",
            "Cost per Result": "$42.67"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "389634",
            "Clicks": "2464",
            "Conversions": "73",
            "Conversion Value": "$13,091",
            "Cost per Result": "$57.12"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "279855",
            "Clicks": "4612",
            "Conversions": "65",
            "Conversion Value": "$6,515",
            "Cost per Result": "$39.30"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "445911",
            "Clicks": "6927",
            "Conversions": "51",
            "Conversion Value": "$13,592",
            "Cost per Result": "$45.34"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "279835",
            "Clicks": "2139",
            "Conversions": "90",
            "Conversion Value": "$4,639",
            "Cost per Result": "$40.45"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "139122",
            "Clicks": "5668",
            "Conversions": "85",
            "Conversion Value": "$13,919",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "345802",
            "Clicks": "4992",
            "Conversions": "87",
            "Conversion Value": "$15,731",
            "Cost per Result": "$49.87"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "482851",
            "Clicks": "3512",
            "Conversions": "67",
            "Conversion Value": "$4,947",
            "Cost per Result": "$24.04"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "396226",
            "Clicks": "6297",
            "Conversions": "86",
            "Conversion Value": "$6,410",
            "Cost per Result": "$42.56"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "376411",
            "Clicks": "2920",
            "Conversions": "72",
            "Conversion Value": "$9,336",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "415713",
            "Clicks": "5726",
            "Conversions": "51",
            "Conversion Value": "$12,884",
            "Cost per Result": "$53.17"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "454981",
            "Clicks": "6521",
            "Conversions": "45",
            "Conversion Value": "$7,142",
            "Cost per Result": "$28.45"
          }
        ]
      },
      "Web3CasinosPowerUsersData": {
        "name": "Web3 Casinos Power Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "166753",
            "Clicks": "4520",
            "Conversions": "67",
            "Conversion Value": "$4,043",
            "Cost per Result": "$28.69"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "405187",
            "Clicks": "4363",
            "Conversions": "72",
            "Conversion Value": "$11,014",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "158127",
            "Clicks": "3241",
            "Conversions": "69",
            "Conversion Value": "$6,205",
            "Cost per Result": "$31.39"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "162414",
            "Clicks": "3223",
            "Conversions": "65",
            "Conversion Value": "$7,794",
            "Cost per Result": "$48.28"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "457759",
            "Clicks": "3143",
            "Conversions": "48",
            "Conversion Value": "$12,925",
            "Cost per Result": "$48.46"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "467557",
            "Clicks": "2624",
            "Conversions": "79",
            "Conversion Value": "$8,934",
            "Cost per Result": "$30.90"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "364538",
            "Clicks": "2181",
            "Conversions": "85",
            "Conversion Value": "$9,955",
            "Cost per Result": "$51.30"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "474280",
            "Clicks": "3074",
            "Conversions": "90",
            "Conversion Value": "$7,477",
            "Cost per Result": "$40.88"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "332215",
            "Clicks": "2980",
            "Conversions": "44",
            "Conversion Value": "$8,214",
            "Cost per Result": "$21.43"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "340751",
            "Clicks": "2856",
            "Conversions": "59",
            "Conversion Value": "$15,746",
            "Cost per Result": "$24.00"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "274846",
            "Clicks": "5422",
            "Conversions": "84",
            "Conversion Value": "$15,023",
            "Cost per Result": "$47.95"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "260563",
            "Clicks": "5608",
            "Conversions": "52",
            "Conversion Value": "$11,129",
            "Cost per Result": "$19.95"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "103213",
            "Clicks": "5039",
            "Conversions": "74",
            "Conversion Value": "$8,790",
            "Cost per Result": "$54.09"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "442715",
            "Clicks": "3784",
            "Conversions": "78",
            "Conversion Value": "$13,959",
            "Cost per Result": "$39.18"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "406213",
            "Clicks": "2089",
            "Conversions": "82",
            "Conversion Value": "$7,212",
            "Cost per Result": "$57.62"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "202961",
            "Clicks": "5239",
            "Conversions": "45",
            "Conversion Value": "$12,979",
            "Cost per Result": "$31.74"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "434696",
            "Clicks": "4568",
            "Conversions": "75",
            "Conversion Value": "$8,130",
            "Cost per Result": "$16.48"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "292314",
            "Clicks": "6873",
            "Conversions": "87",
            "Conversion Value": "$13,989",
            "Cost per Result": "$51.15"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "333042",
            "Clicks": "6567",
            "Conversions": "81",
            "Conversion Value": "$8,955",
            "Cost per Result": "$15.40"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "211945",
            "Clicks": "6323",
            "Conversions": "59",
            "Conversion Value": "$11,046",
            "Cost per Result": "$33.37"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "472727",
            "Clicks": "4053",
            "Conversions": "57",
            "Conversion Value": "$5,315",
            "Cost per Result": "$41.49"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "127504",
            "Clicks": "2298",
            "Conversions": "55",
            "Conversion Value": "$5,044",
            "Cost per Result": "$19.03"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "280549",
            "Clicks": "3863",
            "Conversions": "50",
            "Conversion Value": "$15,390",
            "Cost per Result": "$17.13"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "460999",
            "Clicks": "6916",
            "Conversions": "88",
            "Conversion Value": "$5,816",
            "Cost per Result": "$42.64"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "461501",
            "Clicks": "3424",
            "Conversions": "90",
            "Conversion Value": "$4,512",
            "Cost per Result": "$38.26"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "347640",
            "Clicks": "4911",
            "Conversions": "70",
            "Conversion Value": "$4,776",
            "Cost per Result": "$23.58"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "463059",
            "Clicks": "3527",
            "Conversions": "77",
            "Conversion Value": "$13,048",
            "Cost per Result": "$51.31"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "122228",
            "Clicks": "6176",
            "Conversions": "87",
            "Conversion Value": "$9,848",
            "Cost per Result": "$46.05"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "116131",
            "Clicks": "6187",
            "Conversions": "73",
            "Conversion Value": "$11,213",
            "Cost per Result": "$22.19"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "349723",
            "Clicks": "3593",
            "Conversions": "58",
            "Conversion Value": "$14,350",
            "Cost per Result": "$17.02"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "450975",
            "Clicks": "4002",
            "Conversions": "64",
            "Conversion Value": "$10,503",
            "Cost per Result": "$16.55"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "124110",
            "Clicks": "4734",
            "Conversions": "63",
            "Conversion Value": "$14,966",
            "Cost per Result": "$49.00"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "388682",
            "Clicks": "5296",
            "Conversions": "66",
            "Conversion Value": "$5,418",
            "Cost per Result": "$57.67"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "395943",
            "Clicks": "5739",
            "Conversions": "51",
            "Conversion Value": "$14,060",
            "Cost per Result": "$40.97"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "146085",
            "Clicks": "4905",
            "Conversions": "48",
            "Conversion Value": "$5,170",
            "Cost per Result": "$51.39"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "100958",
            "Clicks": "6681",
            "Conversions": "65",
            "Conversion Value": "$8,603",
            "Cost per Result": "$42.84"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "190902",
            "Clicks": "4718",
            "Conversions": "76",
            "Conversion Value": "$8,675",
            "Cost per Result": "$50.64"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "279959",
            "Clicks": "5059",
            "Conversions": "46",
            "Conversion Value": "$14,951",
            "Cost per Result": "$39.76"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "203040",
            "Clicks": "4665",
            "Conversions": "75",
            "Conversion Value": "$8,141",
            "Cost per Result": "$22.91"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "215052",
            "Clicks": "6822",
            "Conversions": "86",
            "Conversion Value": "$13,616",
            "Cost per Result": "$23.03"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "326562",
            "Clicks": "6787",
            "Conversions": "66",
            "Conversion Value": "$11,507",
            "Cost per Result": "$22.86"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "351952",
            "Clicks": "5830",
            "Conversions": "42",
            "Conversion Value": "$10,104",
            "Cost per Result": "$45.27"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "155571",
            "Clicks": "5549",
            "Conversions": "44",
            "Conversion Value": "$13,457",
            "Cost per Result": "$38.08"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "106155",
            "Clicks": "2761",
            "Conversions": "60",
            "Conversion Value": "$10,552",
            "Cost per Result": "$36.47"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "338206",
            "Clicks": "6665",
            "Conversions": "51",
            "Conversion Value": "$10,271",
            "Cost per Result": "$40.04"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "317532",
            "Clicks": "4723",
            "Conversions": "80",
            "Conversion Value": "$9,369",
            "Cost per Result": "$53.99"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "289210",
            "Clicks": "2179",
            "Conversions": "65",
            "Conversion Value": "$5,696",
            "Cost per Result": "$30.94"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "259511",
            "Clicks": "2952",
            "Conversions": "58",
            "Conversion Value": "$15,901",
            "Cost per Result": "$58.25"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "187655",
            "Clicks": "3451",
            "Conversions": "86",
            "Conversion Value": "$13,849",
            "Cost per Result": "$16.78"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "343193",
            "Clicks": "2823",
            "Conversions": "62",
            "Conversion Value": "$5,255",
            "Cost per Result": "$28.66"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "447966",
            "Clicks": "3268",
            "Conversions": "54",
            "Conversion Value": "$6,046",
            "Cost per Result": "$16.95"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "382214",
            "Clicks": "4275",
            "Conversions": "56",
            "Conversion Value": "$10,215",
            "Cost per Result": "$19.92"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "466499",
            "Clicks": "2720",
            "Conversions": "76",
            "Conversion Value": "$12,820",
            "Cost per Result": "$31.86"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "181061",
            "Clicks": "5064",
            "Conversions": "49",
            "Conversion Value": "$13,567",
            "Cost per Result": "$39.46"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "270638",
            "Clicks": "4441",
            "Conversions": "40",
            "Conversion Value": "$14,192",
            "Cost per Result": "$35.33"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "139197",
            "Clicks": "5907",
            "Conversions": "88",
            "Conversion Value": "$8,320",
            "Cost per Result": "$46.36"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "172269",
            "Clicks": "2586",
            "Conversions": "40",
            "Conversion Value": "$4,889",
            "Cost per Result": "$28.00"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "468282",
            "Clicks": "2286",
            "Conversions": "61",
            "Conversion Value": "$12,046",
            "Cost per Result": "$53.76"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "423119",
            "Clicks": "2699",
            "Conversions": "62",
            "Conversion Value": "$15,465",
            "Cost per Result": "$23.20"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "223890",
            "Clicks": "5195",
            "Conversions": "84",
            "Conversion Value": "$11,935",
            "Cost per Result": "$42.43"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "387984",
            "Clicks": "4689",
            "Conversions": "86",
            "Conversion Value": "$12,346",
            "Cost per Result": "$51.54"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "461445",
            "Clicks": "2359",
            "Conversions": "86",
            "Conversion Value": "$7,913",
            "Cost per Result": "$44.16"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "426269",
            "Clicks": "5294",
            "Conversions": "84",
            "Conversion Value": "$5,562",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "224938",
            "Clicks": "6007",
            "Conversions": "79",
            "Conversion Value": "$7,088",
            "Cost per Result": "$29.34"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "267138",
            "Clicks": "2867",
            "Conversions": "85",
            "Conversion Value": "$6,171",
            "Cost per Result": "$48.38"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "234052",
            "Clicks": "6315",
            "Conversions": "86",
            "Conversion Value": "$4,634",
            "Cost per Result": "$41.51"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "474645",
            "Clicks": "2514",
            "Conversions": "68",
            "Conversion Value": "$7,018",
            "Cost per Result": "$16.71"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "499205",
            "Clicks": "5563",
            "Conversions": "58",
            "Conversion Value": "$4,594",
            "Cost per Result": "$51.99"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "153238",
            "Clicks": "5663",
            "Conversions": "42",
            "Conversion Value": "$7,218",
            "Cost per Result": "$44.00"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "395088",
            "Clicks": "3240",
            "Conversions": "63",
            "Conversion Value": "$13,592",
            "Cost per Result": "$22.69"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "199544",
            "Clicks": "5184",
            "Conversions": "68",
            "Conversion Value": "$12,245",
            "Cost per Result": "$40.76"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "272592",
            "Clicks": "2065",
            "Conversions": "68",
            "Conversion Value": "$7,646",
            "Cost per Result": "$19.84"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "252372",
            "Clicks": "4198",
            "Conversions": "73",
            "Conversion Value": "$11,425",
            "Cost per Result": "$51.61"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "279335",
            "Clicks": "3768",
            "Conversions": "61",
            "Conversion Value": "$15,558",
            "Cost per Result": "$58.14"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "250568",
            "Clicks": "3428",
            "Conversions": "63",
            "Conversion Value": "$11,548",
            "Cost per Result": "$15.45"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "264069",
            "Clicks": "2546",
            "Conversions": "73",
            "Conversion Value": "$14,228",
            "Cost per Result": "$58.83"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "429312",
            "Clicks": "4453",
            "Conversions": "68",
            "Conversion Value": "$15,603",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "315181",
            "Clicks": "6351",
            "Conversions": "74",
            "Conversion Value": "$11,942",
            "Cost per Result": "$59.87"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "453063",
            "Clicks": "3680",
            "Conversions": "77",
            "Conversion Value": "$6,944",
            "Cost per Result": "$54.20"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "464590",
            "Clicks": "3208",
            "Conversions": "57",
            "Conversion Value": "$15,568",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "212043",
            "Clicks": "2403",
            "Conversions": "68",
            "Conversion Value": "$10,647",
            "Cost per Result": "$48.64"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "293189",
            "Clicks": "3148",
            "Conversions": "62",
            "Conversion Value": "$8,780",
            "Cost per Result": "$43.66"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "344558",
            "Clicks": "5673",
            "Conversions": "41",
            "Conversion Value": "$13,917",
            "Cost per Result": "$58.05"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "301448",
            "Clicks": "3211",
            "Conversions": "86",
            "Conversion Value": "$11,281",
            "Cost per Result": "$15.05"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "366631",
            "Clicks": "2020",
            "Conversions": "69",
            "Conversion Value": "$4,310",
            "Cost per Result": "$39.37"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "138842",
            "Clicks": "3287",
            "Conversions": "66",
            "Conversion Value": "$15,218",
            "Cost per Result": "$17.78"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "101468",
            "Clicks": "5652",
            "Conversions": "48",
            "Conversion Value": "$9,429",
            "Cost per Result": "$27.03"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "479919",
            "Clicks": "2298",
            "Conversions": "83",
            "Conversion Value": "$15,098",
            "Cost per Result": "$25.59"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "443726",
            "Clicks": "4653",
            "Conversions": "54",
            "Conversion Value": "$11,761",
            "Cost per Result": "$21.48"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "335404",
            "Clicks": "5444",
            "Conversions": "81",
            "Conversion Value": "$13,030",
            "Cost per Result": "$51.21"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "238063",
            "Clicks": "5899",
            "Conversions": "84",
            "Conversion Value": "$15,580",
            "Cost per Result": "$47.78"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "117869",
            "Clicks": "2841",
            "Conversions": "64",
            "Conversion Value": "$4,241",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "100005",
            "Clicks": "3911",
            "Conversions": "83",
            "Conversion Value": "$13,176",
            "Cost per Result": "$33.61"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "147935",
            "Clicks": "4211",
            "Conversions": "85",
            "Conversion Value": "$12,238",
            "Cost per Result": "$41.01"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "192909",
            "Clicks": "4183",
            "Conversions": "75",
            "Conversion Value": "$6,212",
            "Cost per Result": "$31.04"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "175365",
            "Clicks": "2934",
            "Conversions": "57",
            "Conversion Value": "$10,557",
            "Cost per Result": "$24.91"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "162297",
            "Clicks": "2729",
            "Conversions": "58",
            "Conversion Value": "$15,479",
            "Cost per Result": "$43.89"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "467090",
            "Clicks": "5436",
            "Conversions": "86",
            "Conversion Value": "$6,397",
            "Cost per Result": "$51.96"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "213667",
            "Clicks": "4688",
            "Conversions": "87",
            "Conversion Value": "$8,598",
            "Cost per Result": "$36.56"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "230098",
            "Clicks": "3914",
            "Conversions": "63",
            "Conversion Value": "$6,368",
            "Cost per Result": "$41.31"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "329050",
            "Clicks": "3965",
            "Conversions": "51",
            "Conversion Value": "$6,060",
            "Cost per Result": "$56.88"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "421374",
            "Clicks": "4809",
            "Conversions": "49",
            "Conversion Value": "$7,438",
            "Cost per Result": "$35.58"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "209369",
            "Clicks": "4546",
            "Conversions": "86",
            "Conversion Value": "$15,995",
            "Cost per Result": "$55.35"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "360820",
            "Clicks": "3243",
            "Conversions": "90",
            "Conversion Value": "$14,841",
            "Cost per Result": "$41.03"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "493318",
            "Clicks": "2535",
            "Conversions": "76",
            "Conversion Value": "$9,977",
            "Cost per Result": "$53.07"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "499154",
            "Clicks": "6281",
            "Conversions": "54",
            "Conversion Value": "$6,191",
            "Cost per Result": "$37.49"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "259968",
            "Clicks": "2117",
            "Conversions": "47",
            "Conversion Value": "$9,459",
            "Cost per Result": "$33.39"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "474777",
            "Clicks": "2083",
            "Conversions": "67",
            "Conversion Value": "$9,274",
            "Cost per Result": "$29.37"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "323343",
            "Clicks": "5775",
            "Conversions": "88",
            "Conversion Value": "$14,589",
            "Cost per Result": "$25.12"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "176737",
            "Clicks": "2502",
            "Conversions": "66",
            "Conversion Value": "$14,773",
            "Cost per Result": "$28.88"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "256176",
            "Clicks": "4780",
            "Conversions": "84",
            "Conversion Value": "$12,698",
            "Cost per Result": "$58.76"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "251463",
            "Clicks": "5942",
            "Conversions": "50",
            "Conversion Value": "$6,260",
            "Cost per Result": "$17.32"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "151288",
            "Clicks": "4460",
            "Conversions": "40",
            "Conversion Value": "$6,978",
            "Cost per Result": "$53.22"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "166496",
            "Clicks": "3392",
            "Conversions": "49",
            "Conversion Value": "$5,023",
            "Cost per Result": "$36.86"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "288304",
            "Clicks": "5070",
            "Conversions": "45",
            "Conversion Value": "$4,062",
            "Cost per Result": "$53.50"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "373863",
            "Clicks": "6754",
            "Conversions": "46",
            "Conversion Value": "$4,078",
            "Cost per Result": "$31.85"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "197625",
            "Clicks": "4672",
            "Conversions": "43",
            "Conversion Value": "$15,744",
            "Cost per Result": "$29.81"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "378991",
            "Clicks": "6071",
            "Conversions": "46",
            "Conversion Value": "$14,981",
            "Cost per Result": "$43.85"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "111015",
            "Clicks": "4715",
            "Conversions": "78",
            "Conversion Value": "$8,988",
            "Cost per Result": "$39.87"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "279648",
            "Clicks": "5390",
            "Conversions": "55",
            "Conversion Value": "$9,940",
            "Cost per Result": "$15.24"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "298121",
            "Clicks": "5480",
            "Conversions": "87",
            "Conversion Value": "$11,873",
            "Cost per Result": "$57.70"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "135719",
            "Clicks": "4907",
            "Conversions": "42",
            "Conversion Value": "$9,122",
            "Cost per Result": "$37.62"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "481626",
            "Clicks": "6229",
            "Conversions": "61",
            "Conversion Value": "$6,221",
            "Cost per Result": "$55.17"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "488657",
            "Clicks": "5102",
            "Conversions": "89",
            "Conversion Value": "$9,869",
            "Cost per Result": "$43.66"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "202357",
            "Clicks": "4237",
            "Conversions": "82",
            "Conversion Value": "$7,407",
            "Cost per Result": "$33.58"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "274135",
            "Clicks": "4097",
            "Conversions": "49",
            "Conversion Value": "$7,686",
            "Cost per Result": "$21.78"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "202749",
            "Clicks": "6491",
            "Conversions": "68",
            "Conversion Value": "$6,109",
            "Cost per Result": "$47.81"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "249280",
            "Clicks": "3610",
            "Conversions": "79",
            "Conversion Value": "$7,030",
            "Cost per Result": "$20.85"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "377113",
            "Clicks": "5139",
            "Conversions": "76",
            "Conversion Value": "$15,468",
            "Cost per Result": "$32.97"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "354170",
            "Clicks": "2273",
            "Conversions": "84",
            "Conversion Value": "$5,843",
            "Cost per Result": "$35.81"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "295842",
            "Clicks": "5952",
            "Conversions": "43",
            "Conversion Value": "$15,057",
            "Cost per Result": "$52.79"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "195240",
            "Clicks": "4646",
            "Conversions": "74",
            "Conversion Value": "$12,211",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "274947",
            "Clicks": "2582",
            "Conversions": "52",
            "Conversion Value": "$4,972",
            "Cost per Result": "$41.15"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "205599",
            "Clicks": "3892",
            "Conversions": "88",
            "Conversion Value": "$4,827",
            "Cost per Result": "$24.84"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "490356",
            "Clicks": "5894",
            "Conversions": "43",
            "Conversion Value": "$10,914",
            "Cost per Result": "$22.55"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "115525",
            "Clicks": "3489",
            "Conversions": "65",
            "Conversion Value": "$13,948",
            "Cost per Result": "$53.85"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "417748",
            "Clicks": "3763",
            "Conversions": "80",
            "Conversion Value": "$13,985",
            "Cost per Result": "$28.83"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "348127",
            "Clicks": "5746",
            "Conversions": "51",
            "Conversion Value": "$14,377",
            "Cost per Result": "$46.39"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "333936",
            "Clicks": "4466",
            "Conversions": "51",
            "Conversion Value": "$7,628",
            "Cost per Result": "$30.89"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "214146",
            "Clicks": "5707",
            "Conversions": "90",
            "Conversion Value": "$5,435",
            "Cost per Result": "$55.51"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "396954",
            "Clicks": "4918",
            "Conversions": "65",
            "Conversion Value": "$9,132",
            "Cost per Result": "$35.19"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "371180",
            "Clicks": "3818",
            "Conversions": "55",
            "Conversion Value": "$6,878",
            "Cost per Result": "$15.10"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "112307",
            "Clicks": "5078",
            "Conversions": "63",
            "Conversion Value": "$11,938",
            "Cost per Result": "$40.31"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "372633",
            "Clicks": "3640",
            "Conversions": "49",
            "Conversion Value": "$4,891",
            "Cost per Result": "$58.75"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "206640",
            "Clicks": "2566",
            "Conversions": "89",
            "Conversion Value": "$6,915",
            "Cost per Result": "$15.35"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "177953",
            "Clicks": "5816",
            "Conversions": "44",
            "Conversion Value": "$4,620",
            "Cost per Result": "$51.71"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "213176",
            "Clicks": "3066",
            "Conversions": "40",
            "Conversion Value": "$10,164",
            "Cost per Result": "$15.02"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "327059",
            "Clicks": "6348",
            "Conversions": "67",
            "Conversion Value": "$11,439",
            "Cost per Result": "$43.85"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "402590",
            "Clicks": "5154",
            "Conversions": "58",
            "Conversion Value": "$9,949",
            "Cost per Result": "$58.67"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "478350",
            "Clicks": "3158",
            "Conversions": "85",
            "Conversion Value": "$6,371",
            "Cost per Result": "$48.50"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "376695",
            "Clicks": "3484",
            "Conversions": "90",
            "Conversion Value": "$13,379",
            "Cost per Result": "$20.50"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "332718",
            "Clicks": "5651",
            "Conversions": "73",
            "Conversion Value": "$13,862",
            "Cost per Result": "$59.76"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "219922",
            "Clicks": "6497",
            "Conversions": "76",
            "Conversion Value": "$9,337",
            "Cost per Result": "$38.73"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "458011",
            "Clicks": "2883",
            "Conversions": "82",
            "Conversion Value": "$12,218",
            "Cost per Result": "$57.13"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "445735",
            "Clicks": "4270",
            "Conversions": "61",
            "Conversion Value": "$6,254",
            "Cost per Result": "$36.76"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "413361",
            "Clicks": "3667",
            "Conversions": "63",
            "Conversion Value": "$10,731",
            "Cost per Result": "$45.70"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "383830",
            "Clicks": "5536",
            "Conversions": "87",
            "Conversion Value": "$11,324",
            "Cost per Result": "$26.07"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "155736",
            "Clicks": "4956",
            "Conversions": "55",
            "Conversion Value": "$6,198",
            "Cost per Result": "$23.27"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "162409",
            "Clicks": "5026",
            "Conversions": "76",
            "Conversion Value": "$12,845",
            "Cost per Result": "$32.17"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "278283",
            "Clicks": "5681",
            "Conversions": "68",
            "Conversion Value": "$11,423",
            "Cost per Result": "$36.19"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "112395",
            "Clicks": "6980",
            "Conversions": "71",
            "Conversion Value": "$10,305",
            "Cost per Result": "$58.85"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "349157",
            "Clicks": "3673",
            "Conversions": "48",
            "Conversion Value": "$12,732",
            "Cost per Result": "$28.76"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "453011",
            "Clicks": "2118",
            "Conversions": "56",
            "Conversion Value": "$7,368",
            "Cost per Result": "$56.18"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "239343",
            "Clicks": "6519",
            "Conversions": "58",
            "Conversion Value": "$12,468",
            "Cost per Result": "$41.80"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "137436",
            "Clicks": "4578",
            "Conversions": "71",
            "Conversion Value": "$14,961",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "478597",
            "Clicks": "3470",
            "Conversions": "50",
            "Conversion Value": "$7,157",
            "Cost per Result": "$23.72"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "101696",
            "Clicks": "5861",
            "Conversions": "86",
            "Conversion Value": "$15,723",
            "Cost per Result": "$53.74"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "371322",
            "Clicks": "3639",
            "Conversions": "53",
            "Conversion Value": "$6,434",
            "Cost per Result": "$22.47"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "258764",
            "Clicks": "2914",
            "Conversions": "76",
            "Conversion Value": "$15,931",
            "Cost per Result": "$58.22"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "131651",
            "Clicks": "4209",
            "Conversions": "74",
            "Conversion Value": "$8,954",
            "Cost per Result": "$39.88"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "133995",
            "Clicks": "5056",
            "Conversions": "42",
            "Conversion Value": "$14,044",
            "Cost per Result": "$51.70"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "332897",
            "Clicks": "6005",
            "Conversions": "46",
            "Conversion Value": "$13,198",
            "Cost per Result": "$34.23"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "199216",
            "Clicks": "4794",
            "Conversions": "44",
            "Conversion Value": "$13,955",
            "Cost per Result": "$54.43"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "266090",
            "Clicks": "4171",
            "Conversions": "59",
            "Conversion Value": "$6,259",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "414397",
            "Clicks": "3307",
            "Conversions": "57",
            "Conversion Value": "$14,034",
            "Cost per Result": "$31.84"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "101785",
            "Clicks": "4410",
            "Conversions": "46",
            "Conversion Value": "$9,999",
            "Cost per Result": "$47.29"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "342245",
            "Clicks": "4572",
            "Conversions": "73",
            "Conversion Value": "$6,742",
            "Cost per Result": "$32.84"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "266249",
            "Clicks": "5366",
            "Conversions": "65",
            "Conversion Value": "$8,314",
            "Cost per Result": "$18.57"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "107966",
            "Clicks": "5086",
            "Conversions": "53",
            "Conversion Value": "$7,118",
            "Cost per Result": "$48.12"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "376475",
            "Clicks": "6370",
            "Conversions": "76",
            "Conversion Value": "$5,060",
            "Cost per Result": "$39.92"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "310825",
            "Clicks": "2968",
            "Conversions": "47",
            "Conversion Value": "$13,098",
            "Cost per Result": "$26.81"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "108168",
            "Clicks": "5421",
            "Conversions": "41",
            "Conversion Value": "$14,250",
            "Cost per Result": "$50.44"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "164183",
            "Clicks": "2724",
            "Conversions": "65",
            "Conversion Value": "$4,632",
            "Cost per Result": "$15.37"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "158261",
            "Clicks": "6852",
            "Conversions": "46",
            "Conversion Value": "$5,845",
            "Cost per Result": "$33.23"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "285886",
            "Clicks": "6257",
            "Conversions": "69",
            "Conversion Value": "$13,964",
            "Cost per Result": "$26.83"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "151331",
            "Clicks": "3292",
            "Conversions": "84",
            "Conversion Value": "$14,396",
            "Cost per Result": "$30.76"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "251841",
            "Clicks": "3151",
            "Conversions": "49",
            "Conversion Value": "$15,361",
            "Cost per Result": "$44.98"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "451170",
            "Clicks": "5099",
            "Conversions": "50",
            "Conversion Value": "$5,167",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "464423",
            "Clicks": "6998",
            "Conversions": "65",
            "Conversion Value": "$12,011",
            "Cost per Result": "$58.65"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "379886",
            "Clicks": "2267",
            "Conversions": "68",
            "Conversion Value": "$7,850",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "207106",
            "Clicks": "5435",
            "Conversions": "71",
            "Conversion Value": "$4,606",
            "Cost per Result": "$17.86"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "303638",
            "Clicks": "2544",
            "Conversions": "53",
            "Conversion Value": "$14,395",
            "Cost per Result": "$59.12"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "121897",
            "Clicks": "4872",
            "Conversions": "53",
            "Conversion Value": "$6,290",
            "Cost per Result": "$24.04"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "283863",
            "Clicks": "3231",
            "Conversions": "48",
            "Conversion Value": "$15,522",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "252496",
            "Clicks": "6913",
            "Conversions": "88",
            "Conversion Value": "$8,859",
            "Cost per Result": "$43.94"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "164684",
            "Clicks": "2361",
            "Conversions": "50",
            "Conversion Value": "$8,909",
            "Cost per Result": "$47.23"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "170503",
            "Clicks": "4523",
            "Conversions": "64",
            "Conversion Value": "$10,224",
            "Cost per Result": "$20.95"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "400956",
            "Clicks": "3995",
            "Conversions": "41",
            "Conversion Value": "$11,198",
            "Cost per Result": "$44.67"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "202762",
            "Clicks": "6979",
            "Conversions": "89",
            "Conversion Value": "$12,741",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "138600",
            "Clicks": "2495",
            "Conversions": "51",
            "Conversion Value": "$4,733",
            "Cost per Result": "$48.71"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "294301",
            "Clicks": "2760",
            "Conversions": "52",
            "Conversion Value": "$13,792",
            "Cost per Result": "$16.02"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "431994",
            "Clicks": "6531",
            "Conversions": "47",
            "Conversion Value": "$4,412",
            "Cost per Result": "$40.18"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "394361",
            "Clicks": "3387",
            "Conversions": "86",
            "Conversion Value": "$6,522",
            "Cost per Result": "$24.63"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "396046",
            "Clicks": "6114",
            "Conversions": "83",
            "Conversion Value": "$12,912",
            "Cost per Result": "$52.99"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "494203",
            "Clicks": "4890",
            "Conversions": "83",
            "Conversion Value": "$12,309",
            "Cost per Result": "$29.22"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "398357",
            "Clicks": "3350",
            "Conversions": "44",
            "Conversion Value": "$11,961",
            "Cost per Result": "$25.84"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "158395",
            "Clicks": "6300",
            "Conversions": "82",
            "Conversion Value": "$12,902",
            "Cost per Result": "$42.88"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "135749",
            "Clicks": "6301",
            "Conversions": "45",
            "Conversion Value": "$11,742",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "390225",
            "Clicks": "4458",
            "Conversions": "59",
            "Conversion Value": "$6,545",
            "Cost per Result": "$37.02"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "127119",
            "Clicks": "6878",
            "Conversions": "79",
            "Conversion Value": "$5,888",
            "Cost per Result": "$45.23"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "102611",
            "Clicks": "5123",
            "Conversions": "64",
            "Conversion Value": "$9,063",
            "Cost per Result": "$45.14"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "489839",
            "Clicks": "5478",
            "Conversions": "80",
            "Conversion Value": "$4,379",
            "Cost per Result": "$45.37"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "305398",
            "Clicks": "2160",
            "Conversions": "45",
            "Conversion Value": "$14,163",
            "Cost per Result": "$32.50"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "429804",
            "Clicks": "6606",
            "Conversions": "67",
            "Conversion Value": "$8,622",
            "Cost per Result": "$54.33"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "276251",
            "Clicks": "2648",
            "Conversions": "89",
            "Conversion Value": "$10,207",
            "Cost per Result": "$22.74"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "427165",
            "Clicks": "4452",
            "Conversions": "62",
            "Conversion Value": "$14,588",
            "Cost per Result": "$55.65"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "395144",
            "Clicks": "3232",
            "Conversions": "61",
            "Conversion Value": "$8,609",
            "Cost per Result": "$57.81"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "283216",
            "Clicks": "3037",
            "Conversions": "81",
            "Conversion Value": "$8,605",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "262252",
            "Clicks": "4856",
            "Conversions": "51",
            "Conversion Value": "$7,813",
            "Cost per Result": "$24.17"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "232798",
            "Clicks": "6240",
            "Conversions": "84",
            "Conversion Value": "$8,568",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "287924",
            "Clicks": "3209",
            "Conversions": "54",
            "Conversion Value": "$14,567",
            "Cost per Result": "$21.59"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "125889",
            "Clicks": "4862",
            "Conversions": "53",
            "Conversion Value": "$8,417",
            "Cost per Result": "$57.92"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "174482",
            "Clicks": "3291",
            "Conversions": "84",
            "Conversion Value": "$9,824",
            "Cost per Result": "$56.54"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "128222",
            "Clicks": "5794",
            "Conversions": "80",
            "Conversion Value": "$11,852",
            "Cost per Result": "$38.63"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "267384",
            "Clicks": "4532",
            "Conversions": "74",
            "Conversion Value": "$6,942",
            "Cost per Result": "$46.62"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "451253",
            "Clicks": "6640",
            "Conversions": "41",
            "Conversion Value": "$11,806",
            "Cost per Result": "$15.76"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "233429",
            "Clicks": "4845",
            "Conversions": "72",
            "Conversion Value": "$10,735",
            "Cost per Result": "$33.47"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "445556",
            "Clicks": "3559",
            "Conversions": "67",
            "Conversion Value": "$13,105",
            "Cost per Result": "$18.60"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "353193",
            "Clicks": "6128",
            "Conversions": "86",
            "Conversion Value": "$15,128",
            "Cost per Result": "$45.00"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "408726",
            "Clicks": "3256",
            "Conversions": "53",
            "Conversion Value": "$14,398",
            "Cost per Result": "$28.11"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "251930",
            "Clicks": "6333",
            "Conversions": "70",
            "Conversion Value": "$7,287",
            "Cost per Result": "$35.74"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "480481",
            "Clicks": "3332",
            "Conversions": "55",
            "Conversion Value": "$11,493",
            "Cost per Result": "$28.88"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "127203",
            "Clicks": "4737",
            "Conversions": "62",
            "Conversion Value": "$9,883",
            "Cost per Result": "$21.46"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "316502",
            "Clicks": "4789",
            "Conversions": "74",
            "Conversion Value": "$5,080",
            "Cost per Result": "$53.02"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "183261",
            "Clicks": "3319",
            "Conversions": "87",
            "Conversion Value": "$5,988",
            "Cost per Result": "$50.13"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "122368",
            "Clicks": "3250",
            "Conversions": "45",
            "Conversion Value": "$10,912",
            "Cost per Result": "$36.71"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "203559",
            "Clicks": "5473",
            "Conversions": "73",
            "Conversion Value": "$10,694",
            "Cost per Result": "$42.78"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "444879",
            "Clicks": "5991",
            "Conversions": "70",
            "Conversion Value": "$7,125",
            "Cost per Result": "$53.51"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "467094",
            "Clicks": "4208",
            "Conversions": "56",
            "Conversion Value": "$14,151",
            "Cost per Result": "$54.91"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "396443",
            "Clicks": "2875",
            "Conversions": "64",
            "Conversion Value": "$4,896",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "255759",
            "Clicks": "4829",
            "Conversions": "47",
            "Conversion Value": "$12,800",
            "Cost per Result": "$19.53"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "146558",
            "Clicks": "3541",
            "Conversions": "70",
            "Conversion Value": "$14,103",
            "Cost per Result": "$43.47"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "254520",
            "Clicks": "6738",
            "Conversions": "63",
            "Conversion Value": "$14,408",
            "Cost per Result": "$19.77"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "151621",
            "Clicks": "4212",
            "Conversions": "62",
            "Conversion Value": "$10,444",
            "Cost per Result": "$19.37"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "490722",
            "Clicks": "2081",
            "Conversions": "89",
            "Conversion Value": "$11,386",
            "Cost per Result": "$59.53"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "390423",
            "Clicks": "6328",
            "Conversions": "83",
            "Conversion Value": "$13,457",
            "Cost per Result": "$50.12"
          }
        ]
      },
      "PokerUsersData": {
        "name": "Poker Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "429478",
            "Clicks": "2130",
            "Conversions": "54",
            "Conversion Value": "$8,775",
            "Cost per Result": "$36.01"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "274852",
            "Clicks": "4465",
            "Conversions": "66",
            "Conversion Value": "$9,917",
            "Cost per Result": "$20.45"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "483606",
            "Clicks": "5011",
            "Conversions": "47",
            "Conversion Value": "$13,583",
            "Cost per Result": "$55.62"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "378156",
            "Clicks": "3250",
            "Conversions": "57",
            "Conversion Value": "$5,752",
            "Cost per Result": "$49.97"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "408532",
            "Clicks": "5435",
            "Conversions": "89",
            "Conversion Value": "$13,629",
            "Cost per Result": "$57.84"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "266494",
            "Clicks": "3276",
            "Conversions": "43",
            "Conversion Value": "$5,860",
            "Cost per Result": "$48.95"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "244052",
            "Clicks": "6563",
            "Conversions": "68",
            "Conversion Value": "$8,277",
            "Cost per Result": "$58.96"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "297523",
            "Clicks": "2150",
            "Conversions": "49",
            "Conversion Value": "$5,806",
            "Cost per Result": "$38.37"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "294197",
            "Clicks": "3247",
            "Conversions": "44",
            "Conversion Value": "$13,784",
            "Cost per Result": "$38.74"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "414754",
            "Clicks": "3509",
            "Conversions": "44",
            "Conversion Value": "$7,477",
            "Cost per Result": "$22.40"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "336370",
            "Clicks": "6929",
            "Conversions": "86",
            "Conversion Value": "$5,968",
            "Cost per Result": "$17.59"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "158076",
            "Clicks": "2530",
            "Conversions": "56",
            "Conversion Value": "$5,307",
            "Cost per Result": "$46.66"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "253774",
            "Clicks": "2545",
            "Conversions": "63",
            "Conversion Value": "$12,816",
            "Cost per Result": "$42.38"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "359863",
            "Clicks": "2419",
            "Conversions": "88",
            "Conversion Value": "$13,200",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "438625",
            "Clicks": "5891",
            "Conversions": "80",
            "Conversion Value": "$12,715",
            "Cost per Result": "$58.92"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "339793",
            "Clicks": "6193",
            "Conversions": "45",
            "Conversion Value": "$7,924",
            "Cost per Result": "$27.44"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "169514",
            "Clicks": "4739",
            "Conversions": "67",
            "Conversion Value": "$4,997",
            "Cost per Result": "$20.72"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "427232",
            "Clicks": "4423",
            "Conversions": "67",
            "Conversion Value": "$12,974",
            "Cost per Result": "$42.02"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "389946",
            "Clicks": "6118",
            "Conversions": "59",
            "Conversion Value": "$9,969",
            "Cost per Result": "$43.45"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "376682",
            "Clicks": "3653",
            "Conversions": "89",
            "Conversion Value": "$15,905",
            "Cost per Result": "$40.26"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "258304",
            "Clicks": "2573",
            "Conversions": "46",
            "Conversion Value": "$15,316",
            "Cost per Result": "$23.09"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "454703",
            "Clicks": "6280",
            "Conversions": "45",
            "Conversion Value": "$13,161",
            "Cost per Result": "$36.19"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "385670",
            "Clicks": "2734",
            "Conversions": "64",
            "Conversion Value": "$13,074",
            "Cost per Result": "$56.80"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "401846",
            "Clicks": "3664",
            "Conversions": "52",
            "Conversion Value": "$10,838",
            "Cost per Result": "$18.98"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "141294",
            "Clicks": "5177",
            "Conversions": "79",
            "Conversion Value": "$12,580",
            "Cost per Result": "$28.16"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "128487",
            "Clicks": "2831",
            "Conversions": "73",
            "Conversion Value": "$8,696",
            "Cost per Result": "$41.41"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "232042",
            "Clicks": "4592",
            "Conversions": "46",
            "Conversion Value": "$7,281",
            "Cost per Result": "$45.41"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "287447",
            "Clicks": "6828",
            "Conversions": "45",
            "Conversion Value": "$5,438",
            "Cost per Result": "$42.12"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "428476",
            "Clicks": "6890",
            "Conversions": "44",
            "Conversion Value": "$8,357",
            "Cost per Result": "$47.59"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "459305",
            "Clicks": "5767",
            "Conversions": "90",
            "Conversion Value": "$4,649",
            "Cost per Result": "$33.59"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "424873",
            "Clicks": "4722",
            "Conversions": "68",
            "Conversion Value": "$7,722",
            "Cost per Result": "$36.70"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "449561",
            "Clicks": "4032",
            "Conversions": "67",
            "Conversion Value": "$6,085",
            "Cost per Result": "$23.63"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "422664",
            "Clicks": "5322",
            "Conversions": "47",
            "Conversion Value": "$5,548",
            "Cost per Result": "$53.24"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "272795",
            "Clicks": "3373",
            "Conversions": "51",
            "Conversion Value": "$7,012",
            "Cost per Result": "$33.35"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "287284",
            "Clicks": "2634",
            "Conversions": "75",
            "Conversion Value": "$15,789",
            "Cost per Result": "$28.24"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "311721",
            "Clicks": "6932",
            "Conversions": "58",
            "Conversion Value": "$8,044",
            "Cost per Result": "$15.23"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "249296",
            "Clicks": "5916",
            "Conversions": "47",
            "Conversion Value": "$4,440",
            "Cost per Result": "$42.03"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "293386",
            "Clicks": "2910",
            "Conversions": "84",
            "Conversion Value": "$4,173",
            "Cost per Result": "$22.61"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "234859",
            "Clicks": "5831",
            "Conversions": "54",
            "Conversion Value": "$9,335",
            "Cost per Result": "$45.79"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "307304",
            "Clicks": "4903",
            "Conversions": "58",
            "Conversion Value": "$9,282",
            "Cost per Result": "$39.72"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "382907",
            "Clicks": "4112",
            "Conversions": "66",
            "Conversion Value": "$6,946",
            "Cost per Result": "$21.34"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "168333",
            "Clicks": "4330",
            "Conversions": "77",
            "Conversion Value": "$9,953",
            "Cost per Result": "$35.88"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "432227",
            "Clicks": "2167",
            "Conversions": "51",
            "Conversion Value": "$11,927",
            "Cost per Result": "$24.59"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "249718",
            "Clicks": "5987",
            "Conversions": "57",
            "Conversion Value": "$14,735",
            "Cost per Result": "$21.23"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "168084",
            "Clicks": "2139",
            "Conversions": "84",
            "Conversion Value": "$9,475",
            "Cost per Result": "$34.67"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "208775",
            "Clicks": "4296",
            "Conversions": "87",
            "Conversion Value": "$8,462",
            "Cost per Result": "$46.01"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "263306",
            "Clicks": "3437",
            "Conversions": "88",
            "Conversion Value": "$13,019",
            "Cost per Result": "$23.57"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "110680",
            "Clicks": "3103",
            "Conversions": "75",
            "Conversion Value": "$14,619",
            "Cost per Result": "$45.14"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "322197",
            "Clicks": "5335",
            "Conversions": "54",
            "Conversion Value": "$5,494",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "117176",
            "Clicks": "3093",
            "Conversions": "70",
            "Conversion Value": "$6,228",
            "Cost per Result": "$29.23"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "158145",
            "Clicks": "3886",
            "Conversions": "50",
            "Conversion Value": "$4,536",
            "Cost per Result": "$57.06"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "320731",
            "Clicks": "6447",
            "Conversions": "74",
            "Conversion Value": "$6,066",
            "Cost per Result": "$38.77"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "159090",
            "Clicks": "2853",
            "Conversions": "65",
            "Conversion Value": "$6,361",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "325655",
            "Clicks": "3829",
            "Conversions": "73",
            "Conversion Value": "$14,131",
            "Cost per Result": "$32.62"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "202490",
            "Clicks": "5197",
            "Conversions": "89",
            "Conversion Value": "$15,738",
            "Cost per Result": "$24.30"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "442406",
            "Clicks": "3333",
            "Conversions": "53",
            "Conversion Value": "$10,878",
            "Cost per Result": "$19.28"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "387448",
            "Clicks": "4621",
            "Conversions": "81",
            "Conversion Value": "$9,354",
            "Cost per Result": "$54.55"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "187234",
            "Clicks": "3512",
            "Conversions": "75",
            "Conversion Value": "$6,670",
            "Cost per Result": "$24.80"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "201918",
            "Clicks": "6833",
            "Conversions": "42",
            "Conversion Value": "$15,569",
            "Cost per Result": "$52.37"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "265375",
            "Clicks": "2918",
            "Conversions": "79",
            "Conversion Value": "$11,570",
            "Cost per Result": "$16.12"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "414422",
            "Clicks": "5363",
            "Conversions": "48",
            "Conversion Value": "$7,088",
            "Cost per Result": "$37.95"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "342159",
            "Clicks": "3987",
            "Conversions": "44",
            "Conversion Value": "$6,898",
            "Cost per Result": "$57.07"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "280584",
            "Clicks": "2558",
            "Conversions": "54",
            "Conversion Value": "$5,887",
            "Cost per Result": "$43.57"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "434132",
            "Clicks": "5165",
            "Conversions": "43",
            "Conversion Value": "$11,213",
            "Cost per Result": "$23.43"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "324635",
            "Clicks": "2617",
            "Conversions": "65",
            "Conversion Value": "$14,611",
            "Cost per Result": "$52.90"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "123972",
            "Clicks": "2412",
            "Conversions": "43",
            "Conversion Value": "$14,091",
            "Cost per Result": "$52.33"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "465316",
            "Clicks": "5361",
            "Conversions": "55",
            "Conversion Value": "$14,001",
            "Cost per Result": "$49.80"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "242688",
            "Clicks": "6155",
            "Conversions": "77",
            "Conversion Value": "$11,675",
            "Cost per Result": "$56.78"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "106573",
            "Clicks": "3671",
            "Conversions": "56",
            "Conversion Value": "$13,492",
            "Cost per Result": "$45.09"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "475159",
            "Clicks": "5980",
            "Conversions": "66",
            "Conversion Value": "$15,819",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "439012",
            "Clicks": "3974",
            "Conversions": "48",
            "Conversion Value": "$12,894",
            "Cost per Result": "$21.67"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "217022",
            "Clicks": "5311",
            "Conversions": "55",
            "Conversion Value": "$15,910",
            "Cost per Result": "$19.14"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "262866",
            "Clicks": "6497",
            "Conversions": "79",
            "Conversion Value": "$12,069",
            "Cost per Result": "$26.11"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "109703",
            "Clicks": "3800",
            "Conversions": "80",
            "Conversion Value": "$6,517",
            "Cost per Result": "$17.60"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "342997",
            "Clicks": "4684",
            "Conversions": "75",
            "Conversion Value": "$8,324",
            "Cost per Result": "$21.80"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "154887",
            "Clicks": "4428",
            "Conversions": "62",
            "Conversion Value": "$4,873",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "267878",
            "Clicks": "6892",
            "Conversions": "55",
            "Conversion Value": "$5,759",
            "Cost per Result": "$58.35"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "390025",
            "Clicks": "5461",
            "Conversions": "42",
            "Conversion Value": "$7,035",
            "Cost per Result": "$22.89"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "166172",
            "Clicks": "2142",
            "Conversions": "77",
            "Conversion Value": "$9,675",
            "Cost per Result": "$22.68"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "203891",
            "Clicks": "3424",
            "Conversions": "73",
            "Conversion Value": "$10,373",
            "Cost per Result": "$15.33"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "425532",
            "Clicks": "3155",
            "Conversions": "70",
            "Conversion Value": "$14,994",
            "Cost per Result": "$19.92"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "178285",
            "Clicks": "5567",
            "Conversions": "77",
            "Conversion Value": "$15,819",
            "Cost per Result": "$24.46"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "479544",
            "Clicks": "2443",
            "Conversions": "69",
            "Conversion Value": "$13,956",
            "Cost per Result": "$31.30"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "132599",
            "Clicks": "3610",
            "Conversions": "48",
            "Conversion Value": "$13,846",
            "Cost per Result": "$20.57"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "132179",
            "Clicks": "5901",
            "Conversions": "41",
            "Conversion Value": "$8,791",
            "Cost per Result": "$48.85"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "177203",
            "Clicks": "6714",
            "Conversions": "62",
            "Conversion Value": "$6,512",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "257856",
            "Clicks": "5829",
            "Conversions": "68",
            "Conversion Value": "$15,850",
            "Cost per Result": "$58.91"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "185733",
            "Clicks": "5874",
            "Conversions": "60",
            "Conversion Value": "$7,578",
            "Cost per Result": "$21.32"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "163842",
            "Clicks": "3748",
            "Conversions": "65",
            "Conversion Value": "$9,385",
            "Cost per Result": "$26.88"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "193019",
            "Clicks": "4697",
            "Conversions": "61",
            "Conversion Value": "$14,684",
            "Cost per Result": "$55.65"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "275830",
            "Clicks": "4991",
            "Conversions": "48",
            "Conversion Value": "$10,946",
            "Cost per Result": "$21.34"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "213379",
            "Clicks": "3583",
            "Conversions": "41",
            "Conversion Value": "$11,283",
            "Cost per Result": "$42.08"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "384062",
            "Clicks": "5974",
            "Conversions": "80",
            "Conversion Value": "$13,550",
            "Cost per Result": "$57.14"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "333397",
            "Clicks": "4472",
            "Conversions": "49",
            "Conversion Value": "$4,260",
            "Cost per Result": "$22.33"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "274647",
            "Clicks": "3093",
            "Conversions": "66",
            "Conversion Value": "$8,529",
            "Cost per Result": "$32.35"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "125431",
            "Clicks": "3237",
            "Conversions": "73",
            "Conversion Value": "$15,209",
            "Cost per Result": "$58.90"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "408068",
            "Clicks": "5431",
            "Conversions": "45",
            "Conversion Value": "$7,408",
            "Cost per Result": "$25.96"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "395572",
            "Clicks": "3298",
            "Conversions": "51",
            "Conversion Value": "$11,949",
            "Cost per Result": "$33.96"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "365049",
            "Clicks": "3323",
            "Conversions": "84",
            "Conversion Value": "$10,328",
            "Cost per Result": "$28.92"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "146327",
            "Clicks": "4801",
            "Conversions": "84",
            "Conversion Value": "$13,154",
            "Cost per Result": "$31.47"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "460930",
            "Clicks": "4410",
            "Conversions": "61",
            "Conversion Value": "$13,476",
            "Cost per Result": "$48.55"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "205178",
            "Clicks": "3776",
            "Conversions": "71",
            "Conversion Value": "$11,514",
            "Cost per Result": "$42.90"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "366647",
            "Clicks": "3982",
            "Conversions": "60",
            "Conversion Value": "$6,567",
            "Cost per Result": "$50.53"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "231482",
            "Clicks": "3474",
            "Conversions": "40",
            "Conversion Value": "$14,726",
            "Cost per Result": "$48.09"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "131787",
            "Clicks": "2437",
            "Conversions": "70",
            "Conversion Value": "$15,588",
            "Cost per Result": "$29.55"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "233731",
            "Clicks": "3955",
            "Conversions": "41",
            "Conversion Value": "$15,257",
            "Cost per Result": "$35.88"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "178695",
            "Clicks": "4870",
            "Conversions": "66",
            "Conversion Value": "$14,482",
            "Cost per Result": "$51.11"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "355615",
            "Clicks": "5130",
            "Conversions": "90",
            "Conversion Value": "$14,138",
            "Cost per Result": "$41.28"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "353253",
            "Clicks": "3411",
            "Conversions": "46",
            "Conversion Value": "$11,103",
            "Cost per Result": "$34.09"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "452841",
            "Clicks": "4533",
            "Conversions": "62",
            "Conversion Value": "$9,363",
            "Cost per Result": "$29.70"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "235813",
            "Clicks": "2012",
            "Conversions": "53",
            "Conversion Value": "$13,122",
            "Cost per Result": "$35.30"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "394532",
            "Clicks": "4004",
            "Conversions": "50",
            "Conversion Value": "$6,595",
            "Cost per Result": "$22.48"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "324464",
            "Clicks": "4878",
            "Conversions": "68",
            "Conversion Value": "$8,368",
            "Cost per Result": "$26.89"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "475805",
            "Clicks": "4217",
            "Conversions": "59",
            "Conversion Value": "$15,025",
            "Cost per Result": "$36.32"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "422845",
            "Clicks": "3557",
            "Conversions": "50",
            "Conversion Value": "$7,856",
            "Cost per Result": "$18.07"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "395734",
            "Clicks": "3285",
            "Conversions": "50",
            "Conversion Value": "$6,957",
            "Cost per Result": "$34.17"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "248301",
            "Clicks": "4704",
            "Conversions": "40",
            "Conversion Value": "$8,973",
            "Cost per Result": "$36.54"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "392300",
            "Clicks": "3688",
            "Conversions": "54",
            "Conversion Value": "$10,308",
            "Cost per Result": "$29.39"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "106232",
            "Clicks": "5007",
            "Conversions": "57",
            "Conversion Value": "$8,387",
            "Cost per Result": "$30.05"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "369543",
            "Clicks": "2160",
            "Conversions": "63",
            "Conversion Value": "$10,920",
            "Cost per Result": "$15.99"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "267659",
            "Clicks": "4876",
            "Conversions": "64",
            "Conversion Value": "$12,654",
            "Cost per Result": "$39.39"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "119479",
            "Clicks": "6249",
            "Conversions": "58",
            "Conversion Value": "$14,716",
            "Cost per Result": "$59.33"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "385003",
            "Clicks": "6864",
            "Conversions": "48",
            "Conversion Value": "$9,428",
            "Cost per Result": "$23.32"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "131287",
            "Clicks": "6486",
            "Conversions": "70",
            "Conversion Value": "$6,353",
            "Cost per Result": "$59.87"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "262669",
            "Clicks": "3664",
            "Conversions": "40",
            "Conversion Value": "$13,961",
            "Cost per Result": "$32.75"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "372860",
            "Clicks": "4166",
            "Conversions": "85",
            "Conversion Value": "$13,240",
            "Cost per Result": "$37.86"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "272642",
            "Clicks": "2089",
            "Conversions": "89",
            "Conversion Value": "$7,357",
            "Cost per Result": "$56.19"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "186403",
            "Clicks": "4751",
            "Conversions": "86",
            "Conversion Value": "$11,689",
            "Cost per Result": "$54.52"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "210197",
            "Clicks": "5934",
            "Conversions": "62",
            "Conversion Value": "$15,949",
            "Cost per Result": "$19.17"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "102948",
            "Clicks": "3913",
            "Conversions": "66",
            "Conversion Value": "$15,392",
            "Cost per Result": "$45.95"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "491874",
            "Clicks": "4788",
            "Conversions": "86",
            "Conversion Value": "$5,234",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "174125",
            "Clicks": "6157",
            "Conversions": "70",
            "Conversion Value": "$13,435",
            "Cost per Result": "$26.38"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "208902",
            "Clicks": "6955",
            "Conversions": "78",
            "Conversion Value": "$15,113",
            "Cost per Result": "$59.37"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "248659",
            "Clicks": "5998",
            "Conversions": "84",
            "Conversion Value": "$7,314",
            "Cost per Result": "$45.59"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "297516",
            "Clicks": "4926",
            "Conversions": "45",
            "Conversion Value": "$8,481",
            "Cost per Result": "$46.56"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "371797",
            "Clicks": "3687",
            "Conversions": "46",
            "Conversion Value": "$15,903",
            "Cost per Result": "$21.92"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "237589",
            "Clicks": "2654",
            "Conversions": "85",
            "Conversion Value": "$13,680",
            "Cost per Result": "$54.01"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "326125",
            "Clicks": "5853",
            "Conversions": "58",
            "Conversion Value": "$9,530",
            "Cost per Result": "$21.58"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "238338",
            "Clicks": "6931",
            "Conversions": "64",
            "Conversion Value": "$14,363",
            "Cost per Result": "$24.59"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "210636",
            "Clicks": "6794",
            "Conversions": "69",
            "Conversion Value": "$15,133",
            "Cost per Result": "$55.99"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "241550",
            "Clicks": "3920",
            "Conversions": "63",
            "Conversion Value": "$6,121",
            "Cost per Result": "$50.29"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "301602",
            "Clicks": "6284",
            "Conversions": "81",
            "Conversion Value": "$15,211",
            "Cost per Result": "$59.14"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "227011",
            "Clicks": "2513",
            "Conversions": "44",
            "Conversion Value": "$10,065",
            "Cost per Result": "$34.71"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "465125",
            "Clicks": "5909",
            "Conversions": "66",
            "Conversion Value": "$5,796",
            "Cost per Result": "$36.31"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "431037",
            "Clicks": "4184",
            "Conversions": "80",
            "Conversion Value": "$10,342",
            "Cost per Result": "$47.40"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "189746",
            "Clicks": "4319",
            "Conversions": "75",
            "Conversion Value": "$6,099",
            "Cost per Result": "$31.30"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "107956",
            "Clicks": "5132",
            "Conversions": "66",
            "Conversion Value": "$4,735",
            "Cost per Result": "$27.42"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "352023",
            "Clicks": "4319",
            "Conversions": "84",
            "Conversion Value": "$8,494",
            "Cost per Result": "$42.22"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "127950",
            "Clicks": "3249",
            "Conversions": "43",
            "Conversion Value": "$6,537",
            "Cost per Result": "$20.83"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "273594",
            "Clicks": "5912",
            "Conversions": "63",
            "Conversion Value": "$13,263",
            "Cost per Result": "$22.84"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "296111",
            "Clicks": "5083",
            "Conversions": "77",
            "Conversion Value": "$10,286",
            "Cost per Result": "$24.80"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "411330",
            "Clicks": "2079",
            "Conversions": "54",
            "Conversion Value": "$9,392",
            "Cost per Result": "$48.41"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "311321",
            "Clicks": "6031",
            "Conversions": "44",
            "Conversion Value": "$8,051",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "160193",
            "Clicks": "5820",
            "Conversions": "58",
            "Conversion Value": "$15,865",
            "Cost per Result": "$43.23"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "205319",
            "Clicks": "5347",
            "Conversions": "72",
            "Conversion Value": "$14,063",
            "Cost per Result": "$52.36"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "340357",
            "Clicks": "6545",
            "Conversions": "82",
            "Conversion Value": "$6,557",
            "Cost per Result": "$50.59"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "189049",
            "Clicks": "2651",
            "Conversions": "48",
            "Conversion Value": "$14,824",
            "Cost per Result": "$54.31"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "265887",
            "Clicks": "2193",
            "Conversions": "67",
            "Conversion Value": "$12,941",
            "Cost per Result": "$26.61"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "493261",
            "Clicks": "3939",
            "Conversions": "56",
            "Conversion Value": "$5,674",
            "Cost per Result": "$58.54"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "313389",
            "Clicks": "4776",
            "Conversions": "51",
            "Conversion Value": "$13,619",
            "Cost per Result": "$40.58"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "462892",
            "Clicks": "6110",
            "Conversions": "74",
            "Conversion Value": "$7,135",
            "Cost per Result": "$54.57"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "367823",
            "Clicks": "5834",
            "Conversions": "62",
            "Conversion Value": "$12,917",
            "Cost per Result": "$30.94"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "420549",
            "Clicks": "3718",
            "Conversions": "42",
            "Conversion Value": "$6,321",
            "Cost per Result": "$55.78"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "343236",
            "Clicks": "4578",
            "Conversions": "83",
            "Conversion Value": "$9,531",
            "Cost per Result": "$21.93"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "255583",
            "Clicks": "5566",
            "Conversions": "53",
            "Conversion Value": "$9,369",
            "Cost per Result": "$53.33"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "163362",
            "Clicks": "2809",
            "Conversions": "71",
            "Conversion Value": "$15,170",
            "Cost per Result": "$26.01"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "254807",
            "Clicks": "5305",
            "Conversions": "65",
            "Conversion Value": "$5,921",
            "Cost per Result": "$16.59"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "127332",
            "Clicks": "3132",
            "Conversions": "66",
            "Conversion Value": "$11,246",
            "Cost per Result": "$55.90"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "133467",
            "Clicks": "2162",
            "Conversions": "50",
            "Conversion Value": "$10,346",
            "Cost per Result": "$45.03"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "346635",
            "Clicks": "6167",
            "Conversions": "86",
            "Conversion Value": "$11,405",
            "Cost per Result": "$52.28"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "246262",
            "Clicks": "3014",
            "Conversions": "81",
            "Conversion Value": "$5,107",
            "Cost per Result": "$58.13"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "369844",
            "Clicks": "5268",
            "Conversions": "72",
            "Conversion Value": "$12,023",
            "Cost per Result": "$38.70"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "284936",
            "Clicks": "5135",
            "Conversions": "75",
            "Conversion Value": "$8,571",
            "Cost per Result": "$39.31"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "341537",
            "Clicks": "2746",
            "Conversions": "76",
            "Conversion Value": "$7,974",
            "Cost per Result": "$23.18"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "259569",
            "Clicks": "2473",
            "Conversions": "66",
            "Conversion Value": "$13,879",
            "Cost per Result": "$53.52"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "182565",
            "Clicks": "2181",
            "Conversions": "49",
            "Conversion Value": "$7,836",
            "Cost per Result": "$18.08"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "304467",
            "Clicks": "3929",
            "Conversions": "47",
            "Conversion Value": "$13,333",
            "Cost per Result": "$47.57"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "123091",
            "Clicks": "4694",
            "Conversions": "59",
            "Conversion Value": "$15,457",
            "Cost per Result": "$18.18"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "467319",
            "Clicks": "6896",
            "Conversions": "82",
            "Conversion Value": "$4,240",
            "Cost per Result": "$49.03"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "241251",
            "Clicks": "6053",
            "Conversions": "84",
            "Conversion Value": "$12,047",
            "Cost per Result": "$40.34"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "383962",
            "Clicks": "6970",
            "Conversions": "44",
            "Conversion Value": "$12,090",
            "Cost per Result": "$39.90"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "114136",
            "Clicks": "2128",
            "Conversions": "79",
            "Conversion Value": "$12,924",
            "Cost per Result": "$47.41"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "393839",
            "Clicks": "6851",
            "Conversions": "57",
            "Conversion Value": "$6,596",
            "Cost per Result": "$55.65"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "310323",
            "Clicks": "6350",
            "Conversions": "88",
            "Conversion Value": "$9,987",
            "Cost per Result": "$34.43"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "227570",
            "Clicks": "4685",
            "Conversions": "60",
            "Conversion Value": "$14,964",
            "Cost per Result": "$45.30"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "161886",
            "Clicks": "3561",
            "Conversions": "42",
            "Conversion Value": "$11,277",
            "Cost per Result": "$26.45"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "243655",
            "Clicks": "5685",
            "Conversions": "64",
            "Conversion Value": "$10,963",
            "Cost per Result": "$49.90"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "147729",
            "Clicks": "4785",
            "Conversions": "85",
            "Conversion Value": "$14,542",
            "Cost per Result": "$45.74"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "483119",
            "Clicks": "3443",
            "Conversions": "43",
            "Conversion Value": "$8,389",
            "Cost per Result": "$55.14"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "479023",
            "Clicks": "4094",
            "Conversions": "56",
            "Conversion Value": "$8,875",
            "Cost per Result": "$51.01"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "457129",
            "Clicks": "4699",
            "Conversions": "51",
            "Conversion Value": "$4,277",
            "Cost per Result": "$33.92"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "263073",
            "Clicks": "2831",
            "Conversions": "79",
            "Conversion Value": "$7,065",
            "Cost per Result": "$39.93"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "322736",
            "Clicks": "3043",
            "Conversions": "44",
            "Conversion Value": "$12,108",
            "Cost per Result": "$15.25"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "176486",
            "Clicks": "3102",
            "Conversions": "68",
            "Conversion Value": "$5,400",
            "Cost per Result": "$31.53"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "406528",
            "Clicks": "3592",
            "Conversions": "81",
            "Conversion Value": "$4,102",
            "Cost per Result": "$46.05"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "325936",
            "Clicks": "3402",
            "Conversions": "67",
            "Conversion Value": "$13,138",
            "Cost per Result": "$16.50"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "407198",
            "Clicks": "3527",
            "Conversions": "88",
            "Conversion Value": "$8,671",
            "Cost per Result": "$19.75"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "220302",
            "Clicks": "3140",
            "Conversions": "70",
            "Conversion Value": "$8,485",
            "Cost per Result": "$27.34"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "278980",
            "Clicks": "2639",
            "Conversions": "51",
            "Conversion Value": "$4,990",
            "Cost per Result": "$43.60"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "207669",
            "Clicks": "6829",
            "Conversions": "44",
            "Conversion Value": "$9,686",
            "Cost per Result": "$37.20"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "432981",
            "Clicks": "2138",
            "Conversions": "50",
            "Conversion Value": "$6,502",
            "Cost per Result": "$29.94"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "112259",
            "Clicks": "5018",
            "Conversions": "43",
            "Conversion Value": "$8,089",
            "Cost per Result": "$17.59"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "288459",
            "Clicks": "3585",
            "Conversions": "52",
            "Conversion Value": "$12,556",
            "Cost per Result": "$51.36"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "488743",
            "Clicks": "5531",
            "Conversions": "43",
            "Conversion Value": "$8,905",
            "Cost per Result": "$36.24"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "387974",
            "Clicks": "3417",
            "Conversions": "48",
            "Conversion Value": "$10,315",
            "Cost per Result": "$49.98"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "124425",
            "Clicks": "5591",
            "Conversions": "50",
            "Conversion Value": "$13,983",
            "Cost per Result": "$38.72"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "417144",
            "Clicks": "6243",
            "Conversions": "60",
            "Conversion Value": "$10,009",
            "Cost per Result": "$34.92"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "175069",
            "Clicks": "6853",
            "Conversions": "82",
            "Conversion Value": "$7,170",
            "Cost per Result": "$38.02"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "131226",
            "Clicks": "4570",
            "Conversions": "73",
            "Conversion Value": "$6,290",
            "Cost per Result": "$26.76"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "309994",
            "Clicks": "4610",
            "Conversions": "68",
            "Conversion Value": "$7,852",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "307231",
            "Clicks": "2339",
            "Conversions": "69",
            "Conversion Value": "$6,446",
            "Cost per Result": "$39.92"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "140551",
            "Clicks": "4366",
            "Conversions": "51",
            "Conversion Value": "$7,823",
            "Cost per Result": "$52.30"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "164089",
            "Clicks": "4454",
            "Conversions": "46",
            "Conversion Value": "$11,929",
            "Cost per Result": "$59.47"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "168080",
            "Clicks": "5095",
            "Conversions": "45",
            "Conversion Value": "$13,692",
            "Cost per Result": "$33.70"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "307443",
            "Clicks": "2267",
            "Conversions": "82",
            "Conversion Value": "$7,633",
            "Cost per Result": "$49.67"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "249438",
            "Clicks": "4587",
            "Conversions": "54",
            "Conversion Value": "$9,201",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "274547",
            "Clicks": "2743",
            "Conversions": "64",
            "Conversion Value": "$7,860",
            "Cost per Result": "$45.82"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "357630",
            "Clicks": "3089",
            "Conversions": "74",
            "Conversion Value": "$10,080",
            "Cost per Result": "$25.80"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "224202",
            "Clicks": "3967",
            "Conversions": "70",
            "Conversion Value": "$4,327",
            "Cost per Result": "$30.32"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "210883",
            "Clicks": "3442",
            "Conversions": "90",
            "Conversion Value": "$13,697",
            "Cost per Result": "$57.71"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "113485",
            "Clicks": "6101",
            "Conversions": "61",
            "Conversion Value": "$13,124",
            "Cost per Result": "$45.49"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "235709",
            "Clicks": "2319",
            "Conversions": "57",
            "Conversion Value": "$15,771",
            "Cost per Result": "$19.04"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "435637",
            "Clicks": "6366",
            "Conversions": "77",
            "Conversion Value": "$14,362",
            "Cost per Result": "$19.91"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "179442",
            "Clicks": "5022",
            "Conversions": "81",
            "Conversion Value": "$15,935",
            "Cost per Result": "$56.10"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "301110",
            "Clicks": "5479",
            "Conversions": "66",
            "Conversion Value": "$6,585",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "238006",
            "Clicks": "2252",
            "Conversions": "48",
            "Conversion Value": "$11,777",
            "Cost per Result": "$21.58"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "297288",
            "Clicks": "2460",
            "Conversions": "67",
            "Conversion Value": "$10,782",
            "Cost per Result": "$34.43"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "139078",
            "Clicks": "4121",
            "Conversions": "85",
            "Conversion Value": "$12,256",
            "Cost per Result": "$27.10"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "363675",
            "Clicks": "2971",
            "Conversions": "70",
            "Conversion Value": "$7,119",
            "Cost per Result": "$17.47"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "224449",
            "Clicks": "4248",
            "Conversions": "85",
            "Conversion Value": "$10,676",
            "Cost per Result": "$38.18"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "401591",
            "Clicks": "4315",
            "Conversions": "46",
            "Conversion Value": "$11,256",
            "Cost per Result": "$56.39"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "394547",
            "Clicks": "3825",
            "Conversions": "89",
            "Conversion Value": "$15,780",
            "Cost per Result": "$43.45"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "265122",
            "Clicks": "3022",
            "Conversions": "74",
            "Conversion Value": "$4,073",
            "Cost per Result": "$17.71"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "280446",
            "Clicks": "5067",
            "Conversions": "86",
            "Conversion Value": "$15,368",
            "Cost per Result": "$15.81"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "248903",
            "Clicks": "4049",
            "Conversions": "56",
            "Conversion Value": "$13,776",
            "Cost per Result": "$43.87"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "242899",
            "Clicks": "4913",
            "Conversions": "58",
            "Conversion Value": "$5,179",
            "Cost per Result": "$56.94"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "325206",
            "Clicks": "6160",
            "Conversions": "55",
            "Conversion Value": "$13,289",
            "Cost per Result": "$36.35"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "294305",
            "Clicks": "4741",
            "Conversions": "69",
            "Conversion Value": "$8,816",
            "Cost per Result": "$49.19"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "446069",
            "Clicks": "6102",
            "Conversions": "45",
            "Conversion Value": "$12,376",
            "Cost per Result": "$55.36"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "109130",
            "Clicks": "5195",
            "Conversions": "90",
            "Conversion Value": "$5,094",
            "Cost per Result": "$44.03"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "305118",
            "Clicks": "3831",
            "Conversions": "74",
            "Conversion Value": "$6,535",
            "Cost per Result": "$55.20"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "123982",
            "Clicks": "6936",
            "Conversions": "45",
            "Conversion Value": "$7,570",
            "Cost per Result": "$34.52"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "269183",
            "Clicks": "6906",
            "Conversions": "44",
            "Conversion Value": "$10,130",
            "Cost per Result": "$58.53"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "103858",
            "Clicks": "3963",
            "Conversions": "85",
            "Conversion Value": "$11,771",
            "Cost per Result": "$46.35"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "393587",
            "Clicks": "5397",
            "Conversions": "86",
            "Conversion Value": "$7,760",
            "Cost per Result": "$34.46"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "121387",
            "Clicks": "4268",
            "Conversions": "85",
            "Conversion Value": "$14,889",
            "Cost per Result": "$31.48"
          }
        ]
      },
      "GamingTokenTradersData": {
        "name": "Gaming Token Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "808806",
            "Clicks": "8487",
            "Conversions": "123",
            "Conversion Value": "$21,284",
            "Cost per Result": "$28.95"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "934881",
            "Clicks": "12360",
            "Conversions": "104",
            "Conversion Value": "$26,614",
            "Cost per Result": "$22.50"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "725984",
            "Clicks": "11712",
            "Conversions": "150",
            "Conversion Value": "$19,738",
            "Cost per Result": "$35.47"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "754682",
            "Clicks": "12269",
            "Conversions": "98",
            "Conversion Value": "$14,685",
            "Cost per Result": "$53.01"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "364995",
            "Clicks": "5138",
            "Conversions": "166",
            "Conversion Value": "$31,724",
            "Cost per Result": "$37.76"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "401664",
            "Clicks": "4122",
            "Conversions": "80",
            "Conversion Value": "$25,961",
            "Cost per Result": "$21.37"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "544890",
            "Clicks": "12422",
            "Conversions": "133",
            "Conversion Value": "$20,368",
            "Cost per Result": "$41.97"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "425496",
            "Clicks": "4922",
            "Conversions": "153",
            "Conversion Value": "$26,112",
            "Cost per Result": "$31.63"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "828172",
            "Clicks": "8293",
            "Conversions": "135",
            "Conversion Value": "$9,243",
            "Cost per Result": "$55.28"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "848292",
            "Clicks": "6718",
            "Conversions": "149",
            "Conversion Value": "$19,140",
            "Cost per Result": "$27.62"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "613523",
            "Clicks": "6069",
            "Conversions": "81",
            "Conversion Value": "$28,972",
            "Cost per Result": "$58.90"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "314898",
            "Clicks": "8355",
            "Conversions": "107",
            "Conversion Value": "$28,170",
            "Cost per Result": "$18.76"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "335403",
            "Clicks": "11276",
            "Conversions": "178",
            "Conversion Value": "$25,392",
            "Cost per Result": "$26.27"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "454044",
            "Clicks": "11679",
            "Conversions": "105",
            "Conversion Value": "$26,113",
            "Cost per Result": "$56.37"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "980113",
            "Clicks": "5819",
            "Conversions": "104",
            "Conversion Value": "$26,444",
            "Cost per Result": "$16.96"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "382165",
            "Clicks": "6114",
            "Conversions": "131",
            "Conversion Value": "$10,269",
            "Cost per Result": "$50.24"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "827028",
            "Clicks": "4508",
            "Conversions": "155",
            "Conversion Value": "$9,225",
            "Cost per Result": "$15.70"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "680120",
            "Clicks": "13358",
            "Conversions": "101",
            "Conversion Value": "$17,540",
            "Cost per Result": "$52.30"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "805155",
            "Clicks": "12473",
            "Conversions": "104",
            "Conversion Value": "$14,213",
            "Cost per Result": "$45.07"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "735291",
            "Clicks": "4222",
            "Conversions": "178",
            "Conversion Value": "$9,279",
            "Cost per Result": "$41.72"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "372695",
            "Clicks": "12429",
            "Conversions": "128",
            "Conversion Value": "$25,892",
            "Cost per Result": "$42.59"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "327932",
            "Clicks": "5639",
            "Conversions": "114",
            "Conversion Value": "$25,801",
            "Cost per Result": "$17.97"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "497552",
            "Clicks": "4859",
            "Conversions": "136",
            "Conversion Value": "$21,330",
            "Cost per Result": "$17.70"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "373731",
            "Clicks": "7418",
            "Conversions": "171",
            "Conversion Value": "$25,549",
            "Cost per Result": "$42.65"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "918348",
            "Clicks": "10257",
            "Conversions": "110",
            "Conversion Value": "$15,877",
            "Cost per Result": "$18.30"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "799524",
            "Clicks": "5459",
            "Conversions": "88",
            "Conversion Value": "$23,482",
            "Cost per Result": "$46.69"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "640098",
            "Clicks": "9857",
            "Conversions": "136",
            "Conversion Value": "$29,178",
            "Cost per Result": "$52.83"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "373631",
            "Clicks": "12196",
            "Conversions": "180",
            "Conversion Value": "$24,559",
            "Cost per Result": "$40.26"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "870093",
            "Clicks": "12077",
            "Conversions": "81",
            "Conversion Value": "$12,203",
            "Cost per Result": "$49.83"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "846508",
            "Clicks": "12482",
            "Conversions": "125",
            "Conversion Value": "$19,571",
            "Cost per Result": "$42.87"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "694104",
            "Clicks": "4339",
            "Conversions": "116",
            "Conversion Value": "$19,740",
            "Cost per Result": "$26.80"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "853945",
            "Clicks": "7123",
            "Conversions": "147",
            "Conversion Value": "$8,939",
            "Cost per Result": "$47.91"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "959787",
            "Clicks": "10617",
            "Conversions": "108",
            "Conversion Value": "$25,558",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "729182",
            "Clicks": "9508",
            "Conversions": "91",
            "Conversion Value": "$13,487",
            "Cost per Result": "$56.34"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "564324",
            "Clicks": "11081",
            "Conversions": "112",
            "Conversion Value": "$27,946",
            "Cost per Result": "$46.24"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "659513",
            "Clicks": "8302",
            "Conversions": "161",
            "Conversion Value": "$24,345",
            "Cost per Result": "$33.18"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "391122",
            "Clicks": "12149",
            "Conversions": "96",
            "Conversion Value": "$17,850",
            "Cost per Result": "$24.13"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "667543",
            "Clicks": "10087",
            "Conversions": "89",
            "Conversion Value": "$20,766",
            "Cost per Result": "$17.09"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "521774",
            "Clicks": "9436",
            "Conversions": "140",
            "Conversion Value": "$14,964",
            "Cost per Result": "$53.12"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "819807",
            "Clicks": "6640",
            "Conversions": "134",
            "Conversion Value": "$13,277",
            "Cost per Result": "$21.28"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "743852",
            "Clicks": "7009",
            "Conversions": "159",
            "Conversion Value": "$16,924",
            "Cost per Result": "$59.47"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "351146",
            "Clicks": "8169",
            "Conversions": "171",
            "Conversion Value": "$13,674",
            "Cost per Result": "$18.66"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "523427",
            "Clicks": "9485",
            "Conversions": "82",
            "Conversion Value": "$9,491",
            "Cost per Result": "$53.11"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "689713",
            "Clicks": "4110",
            "Conversions": "138",
            "Conversion Value": "$13,217",
            "Cost per Result": "$28.36"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "317721",
            "Clicks": "7378",
            "Conversions": "125",
            "Conversion Value": "$19,157",
            "Cost per Result": "$57.86"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "284877",
            "Clicks": "9628",
            "Conversions": "147",
            "Conversion Value": "$9,160",
            "Cost per Result": "$25.86"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "234184",
            "Clicks": "5244",
            "Conversions": "86",
            "Conversion Value": "$31,876",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "851389",
            "Clicks": "8125",
            "Conversions": "118",
            "Conversion Value": "$29,501",
            "Cost per Result": "$37.31"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "326602",
            "Clicks": "9159",
            "Conversions": "143",
            "Conversion Value": "$10,408",
            "Cost per Result": "$21.48"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "682856",
            "Clicks": "7018",
            "Conversions": "160",
            "Conversion Value": "$29,530",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "847922",
            "Clicks": "8558",
            "Conversions": "97",
            "Conversion Value": "$13,593",
            "Cost per Result": "$53.81"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "553875",
            "Clicks": "6743",
            "Conversions": "125",
            "Conversion Value": "$31,170",
            "Cost per Result": "$45.72"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "607179",
            "Clicks": "4068",
            "Conversions": "94",
            "Conversion Value": "$25,267",
            "Cost per Result": "$40.50"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "682881",
            "Clicks": "7016",
            "Conversions": "140",
            "Conversion Value": "$9,865",
            "Cost per Result": "$18.93"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "320822",
            "Clicks": "10287",
            "Conversions": "81",
            "Conversion Value": "$19,933",
            "Cost per Result": "$24.81"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "489795",
            "Clicks": "12556",
            "Conversions": "152",
            "Conversion Value": "$31,596",
            "Cost per Result": "$56.06"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "285494",
            "Clicks": "11399",
            "Conversions": "100",
            "Conversion Value": "$27,823",
            "Cost per Result": "$34.38"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "638945",
            "Clicks": "4661",
            "Conversions": "160",
            "Conversion Value": "$18,684",
            "Cost per Result": "$45.45"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "471603",
            "Clicks": "9580",
            "Conversions": "135",
            "Conversion Value": "$24,857",
            "Cost per Result": "$29.58"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "456153",
            "Clicks": "9764",
            "Conversions": "156",
            "Conversion Value": "$20,955",
            "Cost per Result": "$58.08"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "548543",
            "Clicks": "6252",
            "Conversions": "174",
            "Conversion Value": "$14,127",
            "Cost per Result": "$46.94"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "759517",
            "Clicks": "9725",
            "Conversions": "155",
            "Conversion Value": "$26,468",
            "Cost per Result": "$53.21"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "369665",
            "Clicks": "5134",
            "Conversions": "176",
            "Conversion Value": "$17,901",
            "Cost per Result": "$19.43"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "718877",
            "Clicks": "7237",
            "Conversions": "155",
            "Conversion Value": "$22,718",
            "Cost per Result": "$32.19"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "297001",
            "Clicks": "12574",
            "Conversions": "115",
            "Conversion Value": "$19,294",
            "Cost per Result": "$47.73"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "794469",
            "Clicks": "8338",
            "Conversions": "99",
            "Conversion Value": "$28,660",
            "Cost per Result": "$44.17"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "323586",
            "Clicks": "4627",
            "Conversions": "135",
            "Conversion Value": "$15,562",
            "Cost per Result": "$25.62"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "498410",
            "Clicks": "10149",
            "Conversions": "108",
            "Conversion Value": "$23,092",
            "Cost per Result": "$49.62"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "209115",
            "Clicks": "4768",
            "Conversions": "85",
            "Conversion Value": "$12,674",
            "Cost per Result": "$53.46"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "233630",
            "Clicks": "7315",
            "Conversions": "147",
            "Conversion Value": "$13,380",
            "Cost per Result": "$54.90"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "598791",
            "Clicks": "9772",
            "Conversions": "159",
            "Conversion Value": "$20,010",
            "Cost per Result": "$31.29"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "916336",
            "Clicks": "4151",
            "Conversions": "133",
            "Conversion Value": "$10,941",
            "Cost per Result": "$32.02"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "224638",
            "Clicks": "11293",
            "Conversions": "127",
            "Conversion Value": "$22,750",
            "Cost per Result": "$38.33"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "565169",
            "Clicks": "9576",
            "Conversions": "153",
            "Conversion Value": "$27,561",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "734645",
            "Clicks": "4832",
            "Conversions": "128",
            "Conversion Value": "$28,066",
            "Cost per Result": "$50.42"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "590628",
            "Clicks": "9013",
            "Conversions": "145",
            "Conversion Value": "$17,983",
            "Cost per Result": "$43.27"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "861659",
            "Clicks": "7263",
            "Conversions": "135",
            "Conversion Value": "$25,436",
            "Cost per Result": "$43.52"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "552097",
            "Clicks": "6479",
            "Conversions": "83",
            "Conversion Value": "$30,361",
            "Cost per Result": "$35.52"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "997609",
            "Clicks": "5841",
            "Conversions": "162",
            "Conversion Value": "$11,268",
            "Cost per Result": "$33.45"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "639838",
            "Clicks": "13117",
            "Conversions": "103",
            "Conversion Value": "$17,124",
            "Cost per Result": "$35.45"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "908013",
            "Clicks": "10799",
            "Conversions": "174",
            "Conversion Value": "$11,180",
            "Cost per Result": "$26.35"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "220404",
            "Clicks": "6957",
            "Conversions": "132",
            "Conversion Value": "$17,670",
            "Cost per Result": "$30.33"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "298498",
            "Clicks": "6266",
            "Conversions": "83",
            "Conversion Value": "$11,455",
            "Cost per Result": "$49.39"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "954129",
            "Clicks": "6307",
            "Conversions": "113",
            "Conversion Value": "$12,630",
            "Cost per Result": "$49.22"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "482942",
            "Clicks": "9616",
            "Conversions": "152",
            "Conversion Value": "$25,758",
            "Cost per Result": "$46.53"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "512443",
            "Clicks": "13923",
            "Conversions": "90",
            "Conversion Value": "$8,180",
            "Cost per Result": "$41.61"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "418340",
            "Clicks": "12392",
            "Conversions": "133",
            "Conversion Value": "$21,653",
            "Cost per Result": "$57.42"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "813758",
            "Clicks": "6191",
            "Conversions": "119",
            "Conversion Value": "$31,331",
            "Cost per Result": "$55.73"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "443515",
            "Clicks": "10205",
            "Conversions": "163",
            "Conversion Value": "$16,899",
            "Cost per Result": "$47.66"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "944369",
            "Clicks": "5569",
            "Conversions": "81",
            "Conversion Value": "$19,928",
            "Cost per Result": "$56.09"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "628638",
            "Clicks": "8339",
            "Conversions": "118",
            "Conversion Value": "$21,201",
            "Cost per Result": "$21.71"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "683253",
            "Clicks": "12171",
            "Conversions": "86",
            "Conversion Value": "$15,403",
            "Cost per Result": "$33.24"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "614248",
            "Clicks": "11802",
            "Conversions": "173",
            "Conversion Value": "$13,887",
            "Cost per Result": "$23.77"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "316574",
            "Clicks": "12914",
            "Conversions": "94",
            "Conversion Value": "$24,406",
            "Cost per Result": "$29.82"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "558366",
            "Clicks": "6268",
            "Conversions": "92",
            "Conversion Value": "$30,888",
            "Cost per Result": "$56.71"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "682186",
            "Clicks": "12276",
            "Conversions": "138",
            "Conversion Value": "$8,317",
            "Cost per Result": "$51.36"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "347301",
            "Clicks": "11362",
            "Conversions": "130",
            "Conversion Value": "$23,997",
            "Cost per Result": "$47.42"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "361236",
            "Clicks": "5225",
            "Conversions": "160",
            "Conversion Value": "$27,258",
            "Cost per Result": "$47.32"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "851343",
            "Clicks": "12092",
            "Conversions": "124",
            "Conversion Value": "$28,499",
            "Cost per Result": "$45.82"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "831104",
            "Clicks": "10138",
            "Conversions": "124",
            "Conversion Value": "$28,336",
            "Cost per Result": "$59.72"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "274469",
            "Clicks": "13700",
            "Conversions": "111",
            "Conversion Value": "$8,124",
            "Cost per Result": "$22.37"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "771231",
            "Clicks": "11803",
            "Conversions": "100",
            "Conversion Value": "$26,257",
            "Cost per Result": "$49.87"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "774064",
            "Clicks": "10783",
            "Conversions": "90",
            "Conversion Value": "$17,542",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "693824",
            "Clicks": "13350",
            "Conversions": "93",
            "Conversion Value": "$22,510",
            "Cost per Result": "$15.43"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "991163",
            "Clicks": "13110",
            "Conversions": "109",
            "Conversion Value": "$14,244",
            "Cost per Result": "$31.07"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "339835",
            "Clicks": "12000",
            "Conversions": "170",
            "Conversion Value": "$30,702",
            "Cost per Result": "$51.25"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "275505",
            "Clicks": "8269",
            "Conversions": "122",
            "Conversion Value": "$8,042",
            "Cost per Result": "$34.94"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "284989",
            "Clicks": "9892",
            "Conversions": "92",
            "Conversion Value": "$24,421",
            "Cost per Result": "$55.36"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "816416",
            "Clicks": "8855",
            "Conversions": "85",
            "Conversion Value": "$9,261",
            "Cost per Result": "$39.02"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "550797",
            "Clicks": "5348",
            "Conversions": "149",
            "Conversion Value": "$31,521",
            "Cost per Result": "$49.43"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "266767",
            "Clicks": "8783",
            "Conversions": "129",
            "Conversion Value": "$20,151",
            "Cost per Result": "$42.01"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "884381",
            "Clicks": "6291",
            "Conversions": "139",
            "Conversion Value": "$12,975",
            "Cost per Result": "$54.43"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "301923",
            "Clicks": "7175",
            "Conversions": "153",
            "Conversion Value": "$29,628",
            "Cost per Result": "$55.39"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "893496",
            "Clicks": "10172",
            "Conversions": "84",
            "Conversion Value": "$12,350",
            "Cost per Result": "$59.45"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "679883",
            "Clicks": "7555",
            "Conversions": "148",
            "Conversion Value": "$18,933",
            "Cost per Result": "$45.84"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "897192",
            "Clicks": "9769",
            "Conversions": "173",
            "Conversion Value": "$27,362",
            "Cost per Result": "$46.17"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "216962",
            "Clicks": "6696",
            "Conversions": "113",
            "Conversion Value": "$8,531",
            "Cost per Result": "$36.76"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "238088",
            "Clicks": "12171",
            "Conversions": "129",
            "Conversion Value": "$20,480",
            "Cost per Result": "$21.08"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "381475",
            "Clicks": "8773",
            "Conversions": "125",
            "Conversion Value": "$12,091",
            "Cost per Result": "$53.02"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "522109",
            "Clicks": "6734",
            "Conversions": "95",
            "Conversion Value": "$18,832",
            "Cost per Result": "$54.17"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "314998",
            "Clicks": "12465",
            "Conversions": "145",
            "Conversion Value": "$24,760",
            "Cost per Result": "$58.27"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "590683",
            "Clicks": "4133",
            "Conversions": "81",
            "Conversion Value": "$21,020",
            "Cost per Result": "$59.44"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "658407",
            "Clicks": "7847",
            "Conversions": "120",
            "Conversion Value": "$23,446",
            "Cost per Result": "$39.47"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "936675",
            "Clicks": "11695",
            "Conversions": "143",
            "Conversion Value": "$9,271",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "291661",
            "Clicks": "6227",
            "Conversions": "84",
            "Conversion Value": "$19,281",
            "Cost per Result": "$29.07"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "458740",
            "Clicks": "4129",
            "Conversions": "156",
            "Conversion Value": "$9,831",
            "Cost per Result": "$52.67"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "758916",
            "Clicks": "5192",
            "Conversions": "121",
            "Conversion Value": "$21,924",
            "Cost per Result": "$35.88"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "982106",
            "Clicks": "13889",
            "Conversions": "113",
            "Conversion Value": "$11,588",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "772707",
            "Clicks": "7024",
            "Conversions": "127",
            "Conversion Value": "$8,373",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "892053",
            "Clicks": "12336",
            "Conversions": "160",
            "Conversion Value": "$20,651",
            "Cost per Result": "$33.32"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "264177",
            "Clicks": "5964",
            "Conversions": "151",
            "Conversion Value": "$10,376",
            "Cost per Result": "$29.17"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "927372",
            "Clicks": "13759",
            "Conversions": "115",
            "Conversion Value": "$23,753",
            "Cost per Result": "$27.21"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "924435",
            "Clicks": "6689",
            "Conversions": "172",
            "Conversion Value": "$22,590",
            "Cost per Result": "$21.99"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "824832",
            "Clicks": "5985",
            "Conversions": "126",
            "Conversion Value": "$13,565",
            "Cost per Result": "$33.86"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "881623",
            "Clicks": "6516",
            "Conversions": "177",
            "Conversion Value": "$24,925",
            "Cost per Result": "$17.38"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "414988",
            "Clicks": "7789",
            "Conversions": "117",
            "Conversion Value": "$20,853",
            "Cost per Result": "$43.14"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "758010",
            "Clicks": "9413",
            "Conversions": "110",
            "Conversion Value": "$19,116",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "829544",
            "Clicks": "7850",
            "Conversions": "132",
            "Conversion Value": "$23,399",
            "Cost per Result": "$21.94"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "792758",
            "Clicks": "13659",
            "Conversions": "97",
            "Conversion Value": "$11,683",
            "Cost per Result": "$32.81"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "538669",
            "Clicks": "8119",
            "Conversions": "159",
            "Conversion Value": "$13,872",
            "Cost per Result": "$32.96"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "402985",
            "Clicks": "13207",
            "Conversions": "142",
            "Conversion Value": "$11,048",
            "Cost per Result": "$21.13"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "203355",
            "Clicks": "6122",
            "Conversions": "123",
            "Conversion Value": "$9,757",
            "Cost per Result": "$42.81"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "354615",
            "Clicks": "7281",
            "Conversions": "81",
            "Conversion Value": "$25,516",
            "Cost per Result": "$23.59"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "975588",
            "Clicks": "12909",
            "Conversions": "118",
            "Conversion Value": "$31,917",
            "Cost per Result": "$45.46"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "754253",
            "Clicks": "13750",
            "Conversions": "139",
            "Conversion Value": "$14,875",
            "Cost per Result": "$32.19"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "643135",
            "Clicks": "12603",
            "Conversions": "103",
            "Conversion Value": "$17,392",
            "Cost per Result": "$24.08"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "745408",
            "Clicks": "10324",
            "Conversions": "174",
            "Conversion Value": "$15,149",
            "Cost per Result": "$21.81"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "593709",
            "Clicks": "4433",
            "Conversions": "155",
            "Conversion Value": "$12,451",
            "Cost per Result": "$50.99"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "350550",
            "Clicks": "7931",
            "Conversions": "179",
            "Conversion Value": "$8,930",
            "Cost per Result": "$51.84"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "625172",
            "Clicks": "11692",
            "Conversions": "133",
            "Conversion Value": "$14,270",
            "Cost per Result": "$17.98"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "261329",
            "Clicks": "12692",
            "Conversions": "94",
            "Conversion Value": "$12,326",
            "Cost per Result": "$46.87"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "777269",
            "Clicks": "4577",
            "Conversions": "101",
            "Conversion Value": "$27,641",
            "Cost per Result": "$58.01"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "921572",
            "Clicks": "5193",
            "Conversions": "151",
            "Conversion Value": "$24,838",
            "Cost per Result": "$41.19"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "390898",
            "Clicks": "4862",
            "Conversions": "179",
            "Conversion Value": "$21,101",
            "Cost per Result": "$51.37"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "412871",
            "Clicks": "7274",
            "Conversions": "154",
            "Conversion Value": "$21,765",
            "Cost per Result": "$26.02"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "461142",
            "Clicks": "11510",
            "Conversions": "145",
            "Conversion Value": "$18,186",
            "Cost per Result": "$35.13"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "344606",
            "Clicks": "9674",
            "Conversions": "134",
            "Conversion Value": "$24,949",
            "Cost per Result": "$16.63"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "550061",
            "Clicks": "13964",
            "Conversions": "173",
            "Conversion Value": "$8,307",
            "Cost per Result": "$31.79"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "935423",
            "Clicks": "6925",
            "Conversions": "89",
            "Conversion Value": "$25,601",
            "Cost per Result": "$50.01"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "537521",
            "Clicks": "7163",
            "Conversions": "113",
            "Conversion Value": "$25,155",
            "Cost per Result": "$25.35"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "380568",
            "Clicks": "5120",
            "Conversions": "127",
            "Conversion Value": "$31,515",
            "Cost per Result": "$44.10"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "547962",
            "Clicks": "12051",
            "Conversions": "149",
            "Conversion Value": "$26,325",
            "Cost per Result": "$41.14"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "520340",
            "Clicks": "10991",
            "Conversions": "149",
            "Conversion Value": "$22,581",
            "Cost per Result": "$24.96"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "495950",
            "Clicks": "12372",
            "Conversions": "180",
            "Conversion Value": "$13,026",
            "Cost per Result": "$20.72"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "313169",
            "Clicks": "7555",
            "Conversions": "152",
            "Conversion Value": "$19,847",
            "Cost per Result": "$35.91"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "203509",
            "Clicks": "12228",
            "Conversions": "177",
            "Conversion Value": "$24,803",
            "Cost per Result": "$18.46"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "489057",
            "Clicks": "8777",
            "Conversions": "156",
            "Conversion Value": "$30,655",
            "Cost per Result": "$48.61"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "680427",
            "Clicks": "5452",
            "Conversions": "121",
            "Conversion Value": "$11,805",
            "Cost per Result": "$35.40"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "893888",
            "Clicks": "7739",
            "Conversions": "144",
            "Conversion Value": "$15,939",
            "Cost per Result": "$52.21"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "610021",
            "Clicks": "13437",
            "Conversions": "177",
            "Conversion Value": "$29,726",
            "Cost per Result": "$15.42"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "574233",
            "Clicks": "5196",
            "Conversions": "142",
            "Conversion Value": "$11,715",
            "Cost per Result": "$31.78"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "994969",
            "Clicks": "10439",
            "Conversions": "178",
            "Conversion Value": "$16,311",
            "Cost per Result": "$44.69"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "999312",
            "Clicks": "6659",
            "Conversions": "153",
            "Conversion Value": "$25,021",
            "Cost per Result": "$38.55"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "300842",
            "Clicks": "7063",
            "Conversions": "165",
            "Conversion Value": "$8,607",
            "Cost per Result": "$25.25"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "238325",
            "Clicks": "10773",
            "Conversions": "95",
            "Conversion Value": "$17,126",
            "Cost per Result": "$53.70"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "861638",
            "Clicks": "13171",
            "Conversions": "115",
            "Conversion Value": "$26,832",
            "Cost per Result": "$15.08"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "936322",
            "Clicks": "12418",
            "Conversions": "156",
            "Conversion Value": "$29,274",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "717008",
            "Clicks": "9803",
            "Conversions": "159",
            "Conversion Value": "$22,911",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "351402",
            "Clicks": "4162",
            "Conversions": "97",
            "Conversion Value": "$14,268",
            "Cost per Result": "$22.92"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "532733",
            "Clicks": "7012",
            "Conversions": "151",
            "Conversion Value": "$29,115",
            "Cost per Result": "$22.62"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "984503",
            "Clicks": "6222",
            "Conversions": "164",
            "Conversion Value": "$26,400",
            "Cost per Result": "$35.65"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "474346",
            "Clicks": "6281",
            "Conversions": "96",
            "Conversion Value": "$31,450",
            "Cost per Result": "$42.11"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "966395",
            "Clicks": "10388",
            "Conversions": "105",
            "Conversion Value": "$29,724",
            "Cost per Result": "$28.20"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "481490",
            "Clicks": "5108",
            "Conversions": "169",
            "Conversion Value": "$17,252",
            "Cost per Result": "$27.34"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "744059",
            "Clicks": "8474",
            "Conversions": "80",
            "Conversion Value": "$16,019",
            "Cost per Result": "$56.41"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "312559",
            "Clicks": "9628",
            "Conversions": "129",
            "Conversion Value": "$9,014",
            "Cost per Result": "$58.97"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "971396",
            "Clicks": "12123",
            "Conversions": "90",
            "Conversion Value": "$17,828",
            "Cost per Result": "$52.63"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "743264",
            "Clicks": "12761",
            "Conversions": "159",
            "Conversion Value": "$16,279",
            "Cost per Result": "$41.59"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "961432",
            "Clicks": "13342",
            "Conversions": "122",
            "Conversion Value": "$25,804",
            "Cost per Result": "$46.79"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "718983",
            "Clicks": "9377",
            "Conversions": "88",
            "Conversion Value": "$8,628",
            "Cost per Result": "$51.23"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "565043",
            "Clicks": "4496",
            "Conversions": "151",
            "Conversion Value": "$10,179",
            "Cost per Result": "$21.92"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "551881",
            "Clicks": "11771",
            "Conversions": "89",
            "Conversion Value": "$14,408",
            "Cost per Result": "$58.38"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "733719",
            "Clicks": "11691",
            "Conversions": "163",
            "Conversion Value": "$8,072",
            "Cost per Result": "$53.18"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "854892",
            "Clicks": "13889",
            "Conversions": "167",
            "Conversion Value": "$23,746",
            "Cost per Result": "$42.73"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "592400",
            "Clicks": "6170",
            "Conversions": "128",
            "Conversion Value": "$14,318",
            "Cost per Result": "$47.34"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "813436",
            "Clicks": "8405",
            "Conversions": "92",
            "Conversion Value": "$31,590",
            "Cost per Result": "$53.39"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "546900",
            "Clicks": "7726",
            "Conversions": "108",
            "Conversion Value": "$25,994",
            "Cost per Result": "$54.55"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "572339",
            "Clicks": "8811",
            "Conversions": "150",
            "Conversion Value": "$14,421",
            "Cost per Result": "$49.13"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "595173",
            "Clicks": "10693",
            "Conversions": "153",
            "Conversion Value": "$8,161",
            "Cost per Result": "$30.97"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "282525",
            "Clicks": "8017",
            "Conversions": "115",
            "Conversion Value": "$31,650",
            "Cost per Result": "$40.99"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "681775",
            "Clicks": "4303",
            "Conversions": "138",
            "Conversion Value": "$18,832",
            "Cost per Result": "$50.87"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "594729",
            "Clicks": "4017",
            "Conversions": "180",
            "Conversion Value": "$24,886",
            "Cost per Result": "$16.49"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "771498",
            "Clicks": "12535",
            "Conversions": "143",
            "Conversion Value": "$28,886",
            "Cost per Result": "$46.94"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "353531",
            "Clicks": "12296",
            "Conversions": "107",
            "Conversion Value": "$21,584",
            "Cost per Result": "$34.48"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "325439",
            "Clicks": "5496",
            "Conversions": "171",
            "Conversion Value": "$27,642",
            "Cost per Result": "$58.96"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "544426",
            "Clicks": "8923",
            "Conversions": "158",
            "Conversion Value": "$19,152",
            "Cost per Result": "$30.24"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "706954",
            "Clicks": "11614",
            "Conversions": "145",
            "Conversion Value": "$28,822",
            "Cost per Result": "$38.87"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "352224",
            "Clicks": "9626",
            "Conversions": "140",
            "Conversion Value": "$25,617",
            "Cost per Result": "$15.46"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "855013",
            "Clicks": "12731",
            "Conversions": "123",
            "Conversion Value": "$28,573",
            "Cost per Result": "$30.80"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "835887",
            "Clicks": "10113",
            "Conversions": "98",
            "Conversion Value": "$24,191",
            "Cost per Result": "$19.91"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "529844",
            "Clicks": "8606",
            "Conversions": "89",
            "Conversion Value": "$18,551",
            "Cost per Result": "$50.40"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "710632",
            "Clicks": "5080",
            "Conversions": "147",
            "Conversion Value": "$25,439",
            "Cost per Result": "$24.94"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "414261",
            "Clicks": "12533",
            "Conversions": "147",
            "Conversion Value": "$12,421",
            "Cost per Result": "$45.24"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "912961",
            "Clicks": "5824",
            "Conversions": "169",
            "Conversion Value": "$12,922",
            "Cost per Result": "$22.19"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "860205",
            "Clicks": "10894",
            "Conversions": "92",
            "Conversion Value": "$14,731",
            "Cost per Result": "$32.79"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "230767",
            "Clicks": "4237",
            "Conversions": "85",
            "Conversion Value": "$29,613",
            "Cost per Result": "$26.17"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "882634",
            "Clicks": "9802",
            "Conversions": "99",
            "Conversion Value": "$26,324",
            "Cost per Result": "$55.18"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "894147",
            "Clicks": "8961",
            "Conversions": "110",
            "Conversion Value": "$25,672",
            "Cost per Result": "$33.01"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "963412",
            "Clicks": "5604",
            "Conversions": "180",
            "Conversion Value": "$9,349",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "590443",
            "Clicks": "4150",
            "Conversions": "133",
            "Conversion Value": "$30,423",
            "Cost per Result": "$54.97"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "640171",
            "Clicks": "10389",
            "Conversions": "112",
            "Conversion Value": "$12,465",
            "Cost per Result": "$49.18"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "399750",
            "Clicks": "4064",
            "Conversions": "172",
            "Conversion Value": "$22,939",
            "Cost per Result": "$49.09"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "285646",
            "Clicks": "11884",
            "Conversions": "162",
            "Conversion Value": "$9,900",
            "Cost per Result": "$24.65"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "317099",
            "Clicks": "5605",
            "Conversions": "135",
            "Conversion Value": "$21,499",
            "Cost per Result": "$38.04"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "360631",
            "Clicks": "10727",
            "Conversions": "82",
            "Conversion Value": "$31,444",
            "Cost per Result": "$37.63"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "770060",
            "Clicks": "13933",
            "Conversions": "101",
            "Conversion Value": "$12,229",
            "Cost per Result": "$40.81"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "239908",
            "Clicks": "9261",
            "Conversions": "122",
            "Conversion Value": "$30,670",
            "Cost per Result": "$59.53"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "453193",
            "Clicks": "13068",
            "Conversions": "120",
            "Conversion Value": "$20,369",
            "Cost per Result": "$23.91"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "419072",
            "Clicks": "10272",
            "Conversions": "86",
            "Conversion Value": "$12,377",
            "Cost per Result": "$20.65"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "643799",
            "Clicks": "8412",
            "Conversions": "86",
            "Conversion Value": "$17,433",
            "Cost per Result": "$45.63"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "727648",
            "Clicks": "12779",
            "Conversions": "81",
            "Conversion Value": "$31,619",
            "Cost per Result": "$27.96"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "659363",
            "Clicks": "12624",
            "Conversions": "104",
            "Conversion Value": "$30,616",
            "Cost per Result": "$33.35"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "579079",
            "Clicks": "4594",
            "Conversions": "168",
            "Conversion Value": "$24,137",
            "Cost per Result": "$21.59"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "351272",
            "Clicks": "11345",
            "Conversions": "88",
            "Conversion Value": "$12,590",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "622511",
            "Clicks": "8416",
            "Conversions": "167",
            "Conversion Value": "$11,517",
            "Cost per Result": "$17.82"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "749243",
            "Clicks": "13668",
            "Conversions": "128",
            "Conversion Value": "$16,653",
            "Cost per Result": "$57.89"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "628679",
            "Clicks": "13107",
            "Conversions": "92",
            "Conversion Value": "$12,041",
            "Cost per Result": "$52.41"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "986780",
            "Clicks": "11552",
            "Conversions": "88",
            "Conversion Value": "$31,128",
            "Cost per Result": "$25.89"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "909095",
            "Clicks": "4264",
            "Conversions": "145",
            "Conversion Value": "$21,820",
            "Cost per Result": "$42.70"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "222460",
            "Clicks": "7052",
            "Conversions": "128",
            "Conversion Value": "$10,175",
            "Cost per Result": "$55.74"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "562931",
            "Clicks": "5619",
            "Conversions": "119",
            "Conversion Value": "$8,107",
            "Cost per Result": "$53.93"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "960035",
            "Clicks": "10338",
            "Conversions": "167",
            "Conversion Value": "$22,544",
            "Cost per Result": "$50.05"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "561017",
            "Clicks": "13887",
            "Conversions": "163",
            "Conversion Value": "$25,411",
            "Cost per Result": "$50.87"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "859996",
            "Clicks": "11030",
            "Conversions": "97",
            "Conversion Value": "$13,124",
            "Cost per Result": "$38.30"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "365068",
            "Clicks": "6172",
            "Conversions": "148",
            "Conversion Value": "$8,439",
            "Cost per Result": "$22.02"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "286472",
            "Clicks": "11040",
            "Conversions": "113",
            "Conversion Value": "$13,217",
            "Cost per Result": "$57.00"
          }
        ]
      }
    },
    "deFi": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1547457",
            "Clicks": "23126",
            "Conversions": "296",
            "Conversion Value": "$53,748",
            "Cost per Result": "$33.02"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1813941",
            "Clicks": "23147",
            "Conversions": "263",
            "Conversion Value": "$45,408",
            "Cost per Result": "$41.40"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "967208",
            "Clicks": "16614",
            "Conversions": "298",
            "Conversion Value": "$48,373",
            "Cost per Result": "$45.37"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "1331652",
            "Clicks": "24359",
            "Conversions": "321",
            "Conversion Value": "$46,665",
            "Cost per Result": "$44.27"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1752952",
            "Clicks": "15452",
            "Conversions": "343",
            "Conversion Value": "$59,000",
            "Cost per Result": "$38.36"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1887036",
            "Clicks": "22177",
            "Conversions": "346",
            "Conversion Value": "$53,670",
            "Cost per Result": "$44.22"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "1067597",
            "Clicks": "27598",
            "Conversions": "367",
            "Conversion Value": "$68,687",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1984283",
            "Clicks": "21634",
            "Conversions": "306",
            "Conversion Value": "$55,948",
            "Cost per Result": "$31.40"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1541516",
            "Clicks": "22032",
            "Conversions": "374",
            "Conversion Value": "$43,842",
            "Cost per Result": "$44.53"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "1945509",
            "Clicks": "24780",
            "Conversions": "344",
            "Conversion Value": "$48,923",
            "Cost per Result": "$36.83"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1377185",
            "Clicks": "24430",
            "Conversions": "275",
            "Conversion Value": "$53,491",
            "Cost per Result": "$30.02"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1403247",
            "Clicks": "26069",
            "Conversions": "294",
            "Conversion Value": "$41,493",
            "Cost per Result": "$44.68"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "1282467",
            "Clicks": "22121",
            "Conversions": "313",
            "Conversion Value": "$44,262",
            "Cost per Result": "$35.92"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "1489065",
            "Clicks": "26756",
            "Conversions": "314",
            "Conversion Value": "$54,659",
            "Cost per Result": "$22.48"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "2021344",
            "Clicks": "23534",
            "Conversions": "377",
            "Conversion Value": "$55,120",
            "Cost per Result": "$42.44"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "720451",
            "Clicks": "24549",
            "Conversions": "321",
            "Conversion Value": "$55,637",
            "Cost per Result": "$39.43"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1241107",
            "Clicks": "26513",
            "Conversions": "371",
            "Conversion Value": "$72,633",
            "Cost per Result": "$52.43"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "1466093",
            "Clicks": "25730",
            "Conversions": "371",
            "Conversion Value": "$43,412",
            "Cost per Result": "$37.93"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1845224",
            "Clicks": "20614",
            "Conversions": "279",
            "Conversion Value": "$63,896",
            "Cost per Result": "$43.45"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "956189",
            "Clicks": "18761",
            "Conversions": "361",
            "Conversion Value": "$40,826",
            "Cost per Result": "$39.30"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1539347",
            "Clicks": "20158",
            "Conversions": "304",
            "Conversion Value": "$40,230",
            "Cost per Result": "$41.32"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1161662",
            "Clicks": "15597",
            "Conversions": "330",
            "Conversion Value": "$68,480",
            "Cost per Result": "$46.06"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1723955",
            "Clicks": "25716",
            "Conversions": "337",
            "Conversion Value": "$44,627",
            "Cost per Result": "$37.06"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1235696",
            "Clicks": "23506",
            "Conversions": "352",
            "Conversion Value": "$47,488",
            "Cost per Result": "$30.89"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1611388",
            "Clicks": "21143",
            "Conversions": "294",
            "Conversion Value": "$50,524",
            "Cost per Result": "$46.66"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "2117669",
            "Clicks": "26752",
            "Conversions": "296",
            "Conversion Value": "$60,737",
            "Cost per Result": "$36.32"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "909559",
            "Clicks": "21492",
            "Conversions": "426",
            "Conversion Value": "$54,166",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "1547558",
            "Clicks": "16075",
            "Conversions": "300",
            "Conversion Value": "$38,278",
            "Cost per Result": "$34.71"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "2172717",
            "Clicks": "22772",
            "Conversions": "250",
            "Conversion Value": "$59,075",
            "Cost per Result": "$44.71"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1315235",
            "Clicks": "25869",
            "Conversions": "340",
            "Conversion Value": "$67,026",
            "Cost per Result": "$47.73"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "935525",
            "Clicks": "25147",
            "Conversions": "265",
            "Conversion Value": "$43,195",
            "Cost per Result": "$38.63"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "2140775",
            "Clicks": "24765",
            "Conversions": "284",
            "Conversion Value": "$68,093",
            "Cost per Result": "$39.99"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1916493",
            "Clicks": "24749",
            "Conversions": "417",
            "Conversion Value": "$47,843",
            "Cost per Result": "$35.25"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "1598384",
            "Clicks": "22197",
            "Conversions": "336",
            "Conversion Value": "$60,440",
            "Cost per Result": "$36.79"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1826763",
            "Clicks": "25443",
            "Conversions": "323",
            "Conversion Value": "$51,256",
            "Cost per Result": "$48.53"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1687223",
            "Clicks": "29846",
            "Conversions": "378",
            "Conversion Value": "$42,609",
            "Cost per Result": "$36.23"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1309519",
            "Clicks": "18229",
            "Conversions": "327",
            "Conversion Value": "$64,239",
            "Cost per Result": "$41.65"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1329209",
            "Clicks": "25941",
            "Conversions": "347",
            "Conversion Value": "$60,609",
            "Cost per Result": "$32.94"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "1952286",
            "Clicks": "24068",
            "Conversions": "309",
            "Conversion Value": "$46,537",
            "Cost per Result": "$38.24"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "1628995",
            "Clicks": "23971",
            "Conversions": "304",
            "Conversion Value": "$39,300",
            "Cost per Result": "$41.73"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1380757",
            "Clicks": "27821",
            "Conversions": "325",
            "Conversion Value": "$52,893",
            "Cost per Result": "$44.03"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1538732",
            "Clicks": "19074",
            "Conversions": "370",
            "Conversion Value": "$55,751",
            "Cost per Result": "$34.51"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1009799",
            "Clicks": "15604",
            "Conversions": "381",
            "Conversion Value": "$65,504",
            "Cost per Result": "$47.45"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1885022",
            "Clicks": "25811",
            "Conversions": "375",
            "Conversion Value": "$51,959",
            "Cost per Result": "$35.75"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1478498",
            "Clicks": "21406",
            "Conversions": "253",
            "Conversion Value": "$68,570",
            "Cost per Result": "$41.16"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "1373854",
            "Clicks": "24201",
            "Conversions": "382",
            "Conversion Value": "$49,810",
            "Cost per Result": "$46.91"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1345889",
            "Clicks": "17291",
            "Conversions": "272",
            "Conversion Value": "$51,814",
            "Cost per Result": "$39.50"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1309083",
            "Clicks": "20825",
            "Conversions": "383",
            "Conversion Value": "$44,754",
            "Cost per Result": "$47.00"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1418047",
            "Clicks": "21136",
            "Conversions": "315",
            "Conversion Value": "$41,858",
            "Cost per Result": "$44.14"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "1839719",
            "Clicks": "18276",
            "Conversions": "255",
            "Conversion Value": "$47,330",
            "Cost per Result": "$44.67"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "892407",
            "Clicks": "18832",
            "Conversions": "278",
            "Conversion Value": "$51,196",
            "Cost per Result": "$43.35"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1371302",
            "Clicks": "26584",
            "Conversions": "328",
            "Conversion Value": "$42,655",
            "Cost per Result": "$35.31"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "1449938",
            "Clicks": "25702",
            "Conversions": "371",
            "Conversion Value": "$67,604",
            "Cost per Result": "$38.15"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1298037",
            "Clicks": "19925",
            "Conversions": "342",
            "Conversion Value": "$36,082",
            "Cost per Result": "$40.93"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "1039183",
            "Clicks": "23492",
            "Conversions": "367",
            "Conversion Value": "$53,072",
            "Cost per Result": "$35.13"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1877846",
            "Clicks": "21673",
            "Conversions": "359",
            "Conversion Value": "$33,810",
            "Cost per Result": "$44.19"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1649469",
            "Clicks": "30525",
            "Conversions": "334",
            "Conversion Value": "$34,933",
            "Cost per Result": "$33.81"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "1105902",
            "Clicks": "26841",
            "Conversions": "262",
            "Conversion Value": "$36,537",
            "Cost per Result": "$39.40"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1965051",
            "Clicks": "24843",
            "Conversions": "328",
            "Conversion Value": "$36,775",
            "Cost per Result": "$40.61"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1548635",
            "Clicks": "22244",
            "Conversions": "385",
            "Conversion Value": "$44,011",
            "Cost per Result": "$39.52"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1461444",
            "Clicks": "18442",
            "Conversions": "365",
            "Conversion Value": "$65,855",
            "Cost per Result": "$41.21"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1365738",
            "Clicks": "18190",
            "Conversions": "311",
            "Conversion Value": "$58,348",
            "Cost per Result": "$48.82"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1513701",
            "Clicks": "23558",
            "Conversions": "399",
            "Conversion Value": "$35,981",
            "Cost per Result": "$41.72"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1140393",
            "Clicks": "18539",
            "Conversions": "337",
            "Conversion Value": "$39,867",
            "Cost per Result": "$34.98"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1829825",
            "Clicks": "23944",
            "Conversions": "280",
            "Conversion Value": "$32,001",
            "Cost per Result": "$37.97"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1406093",
            "Clicks": "21325",
            "Conversions": "325",
            "Conversion Value": "$44,907",
            "Cost per Result": "$34.37"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "2027806",
            "Clicks": "25875",
            "Conversions": "266",
            "Conversion Value": "$63,802",
            "Cost per Result": "$40.72"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1732523",
            "Clicks": "20251",
            "Conversions": "390",
            "Conversion Value": "$49,665",
            "Cost per Result": "$45.60"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1400117",
            "Clicks": "24029",
            "Conversions": "273",
            "Conversion Value": "$60,994",
            "Cost per Result": "$42.32"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "924120",
            "Clicks": "27052",
            "Conversions": "386",
            "Conversion Value": "$45,799",
            "Cost per Result": "$39.00"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1844263",
            "Clicks": "24410",
            "Conversions": "311",
            "Conversion Value": "$62,548",
            "Cost per Result": "$32.29"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1385782",
            "Clicks": "19404",
            "Conversions": "331",
            "Conversion Value": "$59,059",
            "Cost per Result": "$41.04"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1503064",
            "Clicks": "28357",
            "Conversions": "372",
            "Conversion Value": "$60,261",
            "Cost per Result": "$52.03"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "1294519",
            "Clicks": "23660",
            "Conversions": "332",
            "Conversion Value": "$45,615",
            "Cost per Result": "$38.18"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1605484",
            "Clicks": "28312",
            "Conversions": "275",
            "Conversion Value": "$56,757",
            "Cost per Result": "$40.47"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1638910",
            "Clicks": "27591",
            "Conversions": "321",
            "Conversion Value": "$58,888",
            "Cost per Result": "$38.98"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1611397",
            "Clicks": "20416",
            "Conversions": "323",
            "Conversion Value": "$57,747",
            "Cost per Result": "$36.03"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1586011",
            "Clicks": "21463",
            "Conversions": "356",
            "Conversion Value": "$60,192",
            "Cost per Result": "$44.86"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1755944",
            "Clicks": "22875",
            "Conversions": "305",
            "Conversion Value": "$47,315",
            "Cost per Result": "$52.38"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1225843",
            "Clicks": "20734",
            "Conversions": "342",
            "Conversion Value": "$46,314",
            "Cost per Result": "$45.80"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "1707105",
            "Clicks": "25040",
            "Conversions": "287",
            "Conversion Value": "$65,126",
            "Cost per Result": "$26.88"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1925276",
            "Clicks": "27060",
            "Conversions": "266",
            "Conversion Value": "$39,624",
            "Cost per Result": "$40.46"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1370296",
            "Clicks": "24051",
            "Conversions": "314",
            "Conversion Value": "$48,864",
            "Cost per Result": "$40.40"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1576298",
            "Clicks": "24278",
            "Conversions": "225",
            "Conversion Value": "$40,384",
            "Cost per Result": "$48.47"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1704909",
            "Clicks": "25780",
            "Conversions": "371",
            "Conversion Value": "$66,399",
            "Cost per Result": "$33.06"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1609535",
            "Clicks": "24659",
            "Conversions": "261",
            "Conversion Value": "$39,803",
            "Cost per Result": "$38.76"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1798751",
            "Clicks": "25554",
            "Conversions": "345",
            "Conversion Value": "$53,777",
            "Cost per Result": "$34.32"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1505048",
            "Clicks": "14587",
            "Conversions": "301",
            "Conversion Value": "$56,766",
            "Cost per Result": "$39.97"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1548287",
            "Clicks": "18328",
            "Conversions": "309",
            "Conversion Value": "$26,783",
            "Cost per Result": "$46.26"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1192888",
            "Clicks": "25862",
            "Conversions": "301",
            "Conversion Value": "$44,278",
            "Cost per Result": "$43.40"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1517355",
            "Clicks": "20611",
            "Conversions": "362",
            "Conversion Value": "$51,938",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1400305",
            "Clicks": "23679",
            "Conversions": "306",
            "Conversion Value": "$58,057",
            "Cost per Result": "$35.53"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1627627",
            "Clicks": "23362",
            "Conversions": "278",
            "Conversion Value": "$55,743",
            "Cost per Result": "$42.47"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1457777",
            "Clicks": "23952",
            "Conversions": "338",
            "Conversion Value": "$56,336",
            "Cost per Result": "$44.18"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1835312",
            "Clicks": "26733",
            "Conversions": "333",
            "Conversion Value": "$70,502",
            "Cost per Result": "$37.16"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1389200",
            "Clicks": "18792",
            "Conversions": "303",
            "Conversion Value": "$35,483",
            "Cost per Result": "$34.73"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "965805",
            "Clicks": "23937",
            "Conversions": "376",
            "Conversion Value": "$51,771",
            "Cost per Result": "$43.74"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "1731772",
            "Clicks": "24360",
            "Conversions": "369",
            "Conversion Value": "$65,066",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1938379",
            "Clicks": "20240",
            "Conversions": "300",
            "Conversion Value": "$49,469",
            "Cost per Result": "$46.96"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "988439",
            "Clicks": "21254",
            "Conversions": "331",
            "Conversion Value": "$45,200",
            "Cost per Result": "$38.19"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "974638",
            "Clicks": "23874",
            "Conversions": "296",
            "Conversion Value": "$49,347",
            "Cost per Result": "$42.39"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1021267",
            "Clicks": "24209",
            "Conversions": "343",
            "Conversion Value": "$46,964",
            "Cost per Result": "$34.55"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1736892",
            "Clicks": "22800",
            "Conversions": "313",
            "Conversion Value": "$53,656",
            "Cost per Result": "$51.74"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "1240767",
            "Clicks": "25009",
            "Conversions": "389",
            "Conversion Value": "$45,523",
            "Cost per Result": "$44.62"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1906413",
            "Clicks": "24315",
            "Conversions": "332",
            "Conversion Value": "$35,876",
            "Cost per Result": "$43.72"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1507424",
            "Clicks": "21018",
            "Conversions": "277",
            "Conversion Value": "$64,277",
            "Cost per Result": "$50.89"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1338850",
            "Clicks": "19516",
            "Conversions": "248",
            "Conversion Value": "$66,979",
            "Cost per Result": "$40.49"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1492958",
            "Clicks": "19695",
            "Conversions": "384",
            "Conversion Value": "$48,653",
            "Cost per Result": "$36.04"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1407866",
            "Clicks": "21004",
            "Conversions": "328",
            "Conversion Value": "$40,988",
            "Cost per Result": "$30.18"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "1486076",
            "Clicks": "28439",
            "Conversions": "389",
            "Conversion Value": "$50,802",
            "Cost per Result": "$38.35"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1483036",
            "Clicks": "22742",
            "Conversions": "359",
            "Conversion Value": "$41,499",
            "Cost per Result": "$31.83"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1388681",
            "Clicks": "25700",
            "Conversions": "239",
            "Conversion Value": "$35,272",
            "Cost per Result": "$29.27"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1829716",
            "Clicks": "19749",
            "Conversions": "294",
            "Conversion Value": "$45,825",
            "Cost per Result": "$23.57"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1432140",
            "Clicks": "20629",
            "Conversions": "383",
            "Conversion Value": "$51,313",
            "Cost per Result": "$33.30"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1943980",
            "Clicks": "17202",
            "Conversions": "365",
            "Conversion Value": "$48,801",
            "Cost per Result": "$40.54"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1929555",
            "Clicks": "24554",
            "Conversions": "329",
            "Conversion Value": "$51,344",
            "Cost per Result": "$39.95"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "1553022",
            "Clicks": "21828",
            "Conversions": "376",
            "Conversion Value": "$32,123",
            "Cost per Result": "$30.29"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "1330359",
            "Clicks": "16236",
            "Conversions": "262",
            "Conversion Value": "$49,976",
            "Cost per Result": "$40.64"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "1560176",
            "Clicks": "30598",
            "Conversions": "304",
            "Conversion Value": "$41,470",
            "Cost per Result": "$24.21"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1600408",
            "Clicks": "22296",
            "Conversions": "355",
            "Conversion Value": "$49,087",
            "Cost per Result": "$37.38"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "1564378",
            "Clicks": "15129",
            "Conversions": "288",
            "Conversion Value": "$50,004",
            "Cost per Result": "$39.92"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "1338094",
            "Clicks": "23278",
            "Conversions": "313",
            "Conversion Value": "$38,358",
            "Cost per Result": "$50.93"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1738773",
            "Clicks": "19372",
            "Conversions": "373",
            "Conversion Value": "$65,390",
            "Cost per Result": "$35.60"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "1359749",
            "Clicks": "15821",
            "Conversions": "355",
            "Conversion Value": "$32,743",
            "Cost per Result": "$39.71"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "1845133",
            "Clicks": "20221",
            "Conversions": "299",
            "Conversion Value": "$53,236",
            "Cost per Result": "$50.58"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "2032167",
            "Clicks": "22055",
            "Conversions": "300",
            "Conversion Value": "$54,584",
            "Cost per Result": "$33.98"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1854499",
            "Clicks": "22049",
            "Conversions": "367",
            "Conversion Value": "$41,474",
            "Cost per Result": "$33.50"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1364086",
            "Clicks": "18098",
            "Conversions": "278",
            "Conversion Value": "$48,178",
            "Cost per Result": "$42.30"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "2035535",
            "Clicks": "23561",
            "Conversions": "298",
            "Conversion Value": "$31,650",
            "Cost per Result": "$40.96"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "2140579",
            "Clicks": "20223",
            "Conversions": "409",
            "Conversion Value": "$52,165",
            "Cost per Result": "$46.57"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1087521",
            "Clicks": "22024",
            "Conversions": "296",
            "Conversion Value": "$58,215",
            "Cost per Result": "$33.71"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1824757",
            "Clicks": "22096",
            "Conversions": "396",
            "Conversion Value": "$53,709",
            "Cost per Result": "$38.38"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1692921",
            "Clicks": "22559",
            "Conversions": "332",
            "Conversion Value": "$45,955",
            "Cost per Result": "$40.61"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1971313",
            "Clicks": "26505",
            "Conversions": "388",
            "Conversion Value": "$53,740",
            "Cost per Result": "$50.50"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1734373",
            "Clicks": "23476",
            "Conversions": "324",
            "Conversion Value": "$43,963",
            "Cost per Result": "$44.58"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1588583",
            "Clicks": "25677",
            "Conversions": "320",
            "Conversion Value": "$47,780",
            "Cost per Result": "$45.81"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "1283733",
            "Clicks": "24302",
            "Conversions": "334",
            "Conversion Value": "$56,857",
            "Cost per Result": "$32.83"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "924193",
            "Clicks": "23013",
            "Conversions": "372",
            "Conversion Value": "$35,677",
            "Cost per Result": "$51.13"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1478023",
            "Clicks": "17730",
            "Conversions": "321",
            "Conversion Value": "$48,459",
            "Cost per Result": "$38.92"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "1288659",
            "Clicks": "25239",
            "Conversions": "319",
            "Conversion Value": "$47,187",
            "Cost per Result": "$47.92"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1432392",
            "Clicks": "21292",
            "Conversions": "346",
            "Conversion Value": "$49,766",
            "Cost per Result": "$50.83"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "1908122",
            "Clicks": "20206",
            "Conversions": "258",
            "Conversion Value": "$45,958",
            "Cost per Result": "$44.86"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1317687",
            "Clicks": "18103",
            "Conversions": "300",
            "Conversion Value": "$48,460",
            "Cost per Result": "$38.55"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1629238",
            "Clicks": "19845",
            "Conversions": "345",
            "Conversion Value": "$31,511",
            "Cost per Result": "$39.58"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "1695726",
            "Clicks": "18689",
            "Conversions": "363",
            "Conversion Value": "$40,704",
            "Cost per Result": "$38.84"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1784863",
            "Clicks": "28458",
            "Conversions": "360",
            "Conversion Value": "$52,227",
            "Cost per Result": "$39.55"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1035834",
            "Clicks": "24045",
            "Conversions": "299",
            "Conversion Value": "$46,507",
            "Cost per Result": "$31.12"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "1548690",
            "Clicks": "19719",
            "Conversions": "326",
            "Conversion Value": "$55,275",
            "Cost per Result": "$41.77"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1239894",
            "Clicks": "23210",
            "Conversions": "305",
            "Conversion Value": "$48,190",
            "Cost per Result": "$37.81"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1521866",
            "Clicks": "25844",
            "Conversions": "327",
            "Conversion Value": "$53,101",
            "Cost per Result": "$43.10"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1619371",
            "Clicks": "25183",
            "Conversions": "257",
            "Conversion Value": "$43,094",
            "Cost per Result": "$41.78"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "1247141",
            "Clicks": "23455",
            "Conversions": "387",
            "Conversion Value": "$55,010",
            "Cost per Result": "$42.51"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "1067398",
            "Clicks": "18988",
            "Conversions": "330",
            "Conversion Value": "$49,961",
            "Cost per Result": "$35.86"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "1760410",
            "Clicks": "23295",
            "Conversions": "352",
            "Conversion Value": "$44,050",
            "Cost per Result": "$35.88"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "1153901",
            "Clicks": "12687",
            "Conversions": "358",
            "Conversion Value": "$54,414",
            "Cost per Result": "$45.30"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "970448",
            "Clicks": "22008",
            "Conversions": "337",
            "Conversion Value": "$36,118",
            "Cost per Result": "$38.96"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1275014",
            "Clicks": "25439",
            "Conversions": "349",
            "Conversion Value": "$56,313",
            "Cost per Result": "$47.97"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "1791726",
            "Clicks": "15458",
            "Conversions": "267",
            "Conversion Value": "$49,136",
            "Cost per Result": "$43.74"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "1374435",
            "Clicks": "19245",
            "Conversions": "322",
            "Conversion Value": "$56,766",
            "Cost per Result": "$43.68"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1209741",
            "Clicks": "24567",
            "Conversions": "370",
            "Conversion Value": "$53,715",
            "Cost per Result": "$45.01"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "1219183",
            "Clicks": "21387",
            "Conversions": "351",
            "Conversion Value": "$51,635",
            "Cost per Result": "$40.31"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1682573",
            "Clicks": "28659",
            "Conversions": "279",
            "Conversion Value": "$43,838",
            "Cost per Result": "$35.04"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1482842",
            "Clicks": "19756",
            "Conversions": "357",
            "Conversion Value": "$52,449",
            "Cost per Result": "$41.99"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "805856",
            "Clicks": "26708",
            "Conversions": "353",
            "Conversion Value": "$59,133",
            "Cost per Result": "$48.54"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1423572",
            "Clicks": "18525",
            "Conversions": "262",
            "Conversion Value": "$62,459",
            "Cost per Result": "$41.35"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "1703038",
            "Clicks": "26179",
            "Conversions": "366",
            "Conversion Value": "$37,927",
            "Cost per Result": "$36.33"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1631841",
            "Clicks": "17888",
            "Conversions": "311",
            "Conversion Value": "$62,579",
            "Cost per Result": "$39.68"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1540587",
            "Clicks": "24774",
            "Conversions": "315",
            "Conversion Value": "$48,888",
            "Cost per Result": "$36.93"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "1584240",
            "Clicks": "20548",
            "Conversions": "335",
            "Conversion Value": "$35,775",
            "Cost per Result": "$44.66"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1483221",
            "Clicks": "30674",
            "Conversions": "307",
            "Conversion Value": "$61,076",
            "Cost per Result": "$45.91"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1427218",
            "Clicks": "18461",
            "Conversions": "293",
            "Conversion Value": "$43,791",
            "Cost per Result": "$40.07"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1151655",
            "Clicks": "27343",
            "Conversions": "303",
            "Conversion Value": "$54,031",
            "Cost per Result": "$32.51"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1111059",
            "Clicks": "27145",
            "Conversions": "269",
            "Conversion Value": "$51,965",
            "Cost per Result": "$38.11"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1461701",
            "Clicks": "13845",
            "Conversions": "339",
            "Conversion Value": "$37,297",
            "Cost per Result": "$40.27"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "1314493",
            "Clicks": "22672",
            "Conversions": "321",
            "Conversion Value": "$54,105",
            "Cost per Result": "$51.85"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "926048",
            "Clicks": "14724",
            "Conversions": "372",
            "Conversion Value": "$44,781",
            "Cost per Result": "$33.48"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1289717",
            "Clicks": "19426",
            "Conversions": "317",
            "Conversion Value": "$39,878",
            "Cost per Result": "$51.71"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "1187632",
            "Clicks": "19802",
            "Conversions": "243",
            "Conversion Value": "$55,884",
            "Cost per Result": "$40.09"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1593097",
            "Clicks": "27169",
            "Conversions": "282",
            "Conversion Value": "$42,536",
            "Cost per Result": "$45.33"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1906277",
            "Clicks": "25924",
            "Conversions": "334",
            "Conversion Value": "$46,139",
            "Cost per Result": "$32.58"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1756833",
            "Clicks": "25247",
            "Conversions": "342",
            "Conversion Value": "$55,818",
            "Cost per Result": "$40.91"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "899507",
            "Clicks": "30276",
            "Conversions": "249",
            "Conversion Value": "$53,587",
            "Cost per Result": "$37.49"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "1840706",
            "Clicks": "19015",
            "Conversions": "360",
            "Conversion Value": "$55,131",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1686793",
            "Clicks": "19110",
            "Conversions": "334",
            "Conversion Value": "$64,964",
            "Cost per Result": "$42.33"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1351449",
            "Clicks": "24285",
            "Conversions": "314",
            "Conversion Value": "$49,258",
            "Cost per Result": "$32.83"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1727594",
            "Clicks": "12776",
            "Conversions": "374",
            "Conversion Value": "$44,119",
            "Cost per Result": "$44.34"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1132220",
            "Clicks": "16685",
            "Conversions": "284",
            "Conversion Value": "$49,076",
            "Cost per Result": "$37.55"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "982885",
            "Clicks": "21751",
            "Conversions": "311",
            "Conversion Value": "$49,484",
            "Cost per Result": "$52.21"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "939350",
            "Clicks": "28755",
            "Conversions": "221",
            "Conversion Value": "$38,065",
            "Cost per Result": "$49.05"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1453235",
            "Clicks": "26134",
            "Conversions": "287",
            "Conversion Value": "$49,969",
            "Cost per Result": "$35.22"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1802404",
            "Clicks": "22246",
            "Conversions": "317",
            "Conversion Value": "$42,468",
            "Cost per Result": "$41.53"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "1621848",
            "Clicks": "14679",
            "Conversions": "309",
            "Conversion Value": "$34,801",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "1502333",
            "Clicks": "31107",
            "Conversions": "341",
            "Conversion Value": "$50,725",
            "Cost per Result": "$40.48"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1807179",
            "Clicks": "17207",
            "Conversions": "397",
            "Conversion Value": "$44,451",
            "Cost per Result": "$44.06"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1428099",
            "Clicks": "22153",
            "Conversions": "253",
            "Conversion Value": "$59,993",
            "Cost per Result": "$33.01"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1736427",
            "Clicks": "26080",
            "Conversions": "332",
            "Conversion Value": "$64,158",
            "Cost per Result": "$39.35"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1042800",
            "Clicks": "20046",
            "Conversions": "406",
            "Conversion Value": "$50,880",
            "Cost per Result": "$51.02"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "1400301",
            "Clicks": "22630",
            "Conversions": "363",
            "Conversion Value": "$46,733",
            "Cost per Result": "$32.43"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1195426",
            "Clicks": "13138",
            "Conversions": "342",
            "Conversion Value": "$52,456",
            "Cost per Result": "$31.51"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "933055",
            "Clicks": "21036",
            "Conversions": "242",
            "Conversion Value": "$60,063",
            "Cost per Result": "$34.57"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1074752",
            "Clicks": "20839",
            "Conversions": "329",
            "Conversion Value": "$39,948",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "1953997",
            "Clicks": "25235",
            "Conversions": "340",
            "Conversion Value": "$49,154",
            "Cost per Result": "$36.25"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1069324",
            "Clicks": "25921",
            "Conversions": "397",
            "Conversion Value": "$54,561",
            "Cost per Result": "$34.93"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1262442",
            "Clicks": "20562",
            "Conversions": "348",
            "Conversion Value": "$39,400",
            "Cost per Result": "$44.71"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1655390",
            "Clicks": "19879",
            "Conversions": "349",
            "Conversion Value": "$57,063",
            "Cost per Result": "$44.64"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1733813",
            "Clicks": "21514",
            "Conversions": "301",
            "Conversion Value": "$50,766",
            "Cost per Result": "$42.63"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "1197194",
            "Clicks": "17631",
            "Conversions": "258",
            "Conversion Value": "$72,735",
            "Cost per Result": "$49.06"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1337746",
            "Clicks": "30273",
            "Conversions": "328",
            "Conversion Value": "$46,614",
            "Cost per Result": "$35.59"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "1457343",
            "Clicks": "22171",
            "Conversions": "312",
            "Conversion Value": "$49,871",
            "Cost per Result": "$37.58"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "1388722",
            "Clicks": "28802",
            "Conversions": "285",
            "Conversion Value": "$47,352",
            "Cost per Result": "$40.73"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "2355446",
            "Clicks": "24263",
            "Conversions": "331",
            "Conversion Value": "$46,979",
            "Cost per Result": "$47.33"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1325536",
            "Clicks": "23646",
            "Conversions": "310",
            "Conversion Value": "$50,928",
            "Cost per Result": "$42.46"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1889654",
            "Clicks": "23503",
            "Conversions": "283",
            "Conversion Value": "$58,932",
            "Cost per Result": "$54.77"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "1359785",
            "Clicks": "19093",
            "Conversions": "309",
            "Conversion Value": "$42,497",
            "Cost per Result": "$46.90"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1987098",
            "Clicks": "26510",
            "Conversions": "391",
            "Conversion Value": "$55,962",
            "Cost per Result": "$36.20"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1377426",
            "Clicks": "22724",
            "Conversions": "385",
            "Conversion Value": "$57,245",
            "Cost per Result": "$44.49"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1346406",
            "Clicks": "21761",
            "Conversions": "347",
            "Conversion Value": "$54,497",
            "Cost per Result": "$40.67"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1845733",
            "Clicks": "15405",
            "Conversions": "343",
            "Conversion Value": "$48,453",
            "Cost per Result": "$33.03"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1680670",
            "Clicks": "26892",
            "Conversions": "327",
            "Conversion Value": "$56,427",
            "Cost per Result": "$48.03"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1375247",
            "Clicks": "25321",
            "Conversions": "401",
            "Conversion Value": "$55,980",
            "Cost per Result": "$38.44"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1295062",
            "Clicks": "24065",
            "Conversions": "284",
            "Conversion Value": "$35,381",
            "Cost per Result": "$30.96"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1599387",
            "Clicks": "21251",
            "Conversions": "289",
            "Conversion Value": "$59,498",
            "Cost per Result": "$38.09"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "1204875",
            "Clicks": "22301",
            "Conversions": "373",
            "Conversion Value": "$46,995",
            "Cost per Result": "$47.59"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "2030291",
            "Clicks": "17380",
            "Conversions": "288",
            "Conversion Value": "$44,545",
            "Cost per Result": "$34.72"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "912285",
            "Clicks": "18139",
            "Conversions": "346",
            "Conversion Value": "$61,466",
            "Cost per Result": "$31.34"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1777639",
            "Clicks": "19441",
            "Conversions": "290",
            "Conversion Value": "$56,496",
            "Cost per Result": "$41.30"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1440778",
            "Clicks": "24514",
            "Conversions": "328",
            "Conversion Value": "$42,064",
            "Cost per Result": "$37.94"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "1207157",
            "Clicks": "16873",
            "Conversions": "419",
            "Conversion Value": "$44,532",
            "Cost per Result": "$52.59"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "1733244",
            "Clicks": "32611",
            "Conversions": "355",
            "Conversion Value": "$56,029",
            "Cost per Result": "$36.94"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1678986",
            "Clicks": "22669",
            "Conversions": "329",
            "Conversion Value": "$59,014",
            "Cost per Result": "$38.19"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1342349",
            "Clicks": "31651",
            "Conversions": "293",
            "Conversion Value": "$70,230",
            "Cost per Result": "$43.92"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1235391",
            "Clicks": "21716",
            "Conversions": "274",
            "Conversion Value": "$61,858",
            "Cost per Result": "$42.15"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "1101031",
            "Clicks": "26710",
            "Conversions": "334",
            "Conversion Value": "$49,710",
            "Cost per Result": "$37.06"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "876565",
            "Clicks": "25434",
            "Conversions": "330",
            "Conversion Value": "$33,151",
            "Cost per Result": "$45.26"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "2008476",
            "Clicks": "25964",
            "Conversions": "306",
            "Conversion Value": "$45,080",
            "Cost per Result": "$37.41"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1727078",
            "Clicks": "23574",
            "Conversions": "284",
            "Conversion Value": "$52,234",
            "Cost per Result": "$33.94"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1486162",
            "Clicks": "20252",
            "Conversions": "366",
            "Conversion Value": "$56,259",
            "Cost per Result": "$30.83"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "1790836",
            "Clicks": "19760",
            "Conversions": "320",
            "Conversion Value": "$48,624",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "1663761",
            "Clicks": "27880",
            "Conversions": "361",
            "Conversion Value": "$50,641",
            "Cost per Result": "$30.92"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1236267",
            "Clicks": "22017",
            "Conversions": "324",
            "Conversion Value": "$41,285",
            "Cost per Result": "$37.55"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1191648",
            "Clicks": "24534",
            "Conversions": "282",
            "Conversion Value": "$47,628",
            "Cost per Result": "$35.95"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1744951",
            "Clicks": "20823",
            "Conversions": "323",
            "Conversion Value": "$46,129",
            "Cost per Result": "$36.58"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1222566",
            "Clicks": "22647",
            "Conversions": "357",
            "Conversion Value": "$50,913",
            "Cost per Result": "$38.36"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1576061",
            "Clicks": "19449",
            "Conversions": "412",
            "Conversion Value": "$55,473",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1367521",
            "Clicks": "24314",
            "Conversions": "334",
            "Conversion Value": "$44,077",
            "Cost per Result": "$39.62"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1207202",
            "Clicks": "17996",
            "Conversions": "334",
            "Conversion Value": "$53,304",
            "Cost per Result": "$42.23"
          }
        ]
      },
      "DeFITradersData": {
        "name": "DeFI Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "469369",
            "Clicks": "4518",
            "Conversions": "50",
            "Conversion Value": "$6,173",
            "Cost per Result": "$46.26"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "413228",
            "Clicks": "5452",
            "Conversions": "63",
            "Conversion Value": "$10,130",
            "Cost per Result": "$44.45"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "115268",
            "Clicks": "4823",
            "Conversions": "62",
            "Conversion Value": "$9,359",
            "Cost per Result": "$52.82"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "397778",
            "Clicks": "6101",
            "Conversions": "71",
            "Conversion Value": "$14,763",
            "Cost per Result": "$59.99"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "222520",
            "Clicks": "2542",
            "Conversions": "83",
            "Conversion Value": "$13,363",
            "Cost per Result": "$39.92"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "391775",
            "Clicks": "4893",
            "Conversions": "54",
            "Conversion Value": "$5,767",
            "Cost per Result": "$52.73"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "169768",
            "Clicks": "6449",
            "Conversions": "83",
            "Conversion Value": "$13,030",
            "Cost per Result": "$48.32"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "386898",
            "Clicks": "5239",
            "Conversions": "74",
            "Conversion Value": "$8,675",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "414557",
            "Clicks": "3016",
            "Conversions": "75",
            "Conversion Value": "$12,980",
            "Cost per Result": "$61.59"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "242462",
            "Clicks": "5995",
            "Conversions": "89",
            "Conversion Value": "$10,869",
            "Cost per Result": "$42.49"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "297861",
            "Clicks": "3934",
            "Conversions": "72",
            "Conversion Value": "$7,505",
            "Cost per Result": "$36.50"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "171044",
            "Clicks": "4043",
            "Conversions": "80",
            "Conversion Value": "$4,044",
            "Cost per Result": "$55.71"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "293857",
            "Clicks": "5652",
            "Conversions": "40",
            "Conversion Value": "$15,066",
            "Cost per Result": "$55.22"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "404179",
            "Clicks": "5721",
            "Conversions": "88",
            "Conversion Value": "$9,728",
            "Cost per Result": "$17.95"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "456478",
            "Clicks": "4480",
            "Conversions": "85",
            "Conversion Value": "$7,077",
            "Cost per Result": "$58.85"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "152418",
            "Clicks": "4249",
            "Conversions": "81",
            "Conversion Value": "$9,284",
            "Cost per Result": "$54.16"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "316654",
            "Clicks": "4962",
            "Conversions": "71",
            "Conversion Value": "$15,447",
            "Cost per Result": "$62.16"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "459193",
            "Clicks": "6389",
            "Conversions": "83",
            "Conversion Value": "$4,370",
            "Cost per Result": "$46.34"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "266924",
            "Clicks": "2324",
            "Conversions": "86",
            "Conversion Value": "$12,802",
            "Cost per Result": "$28.00"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "147247",
            "Clicks": "5611",
            "Conversions": "74",
            "Conversion Value": "$13,307",
            "Cost per Result": "$45.75"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "215074",
            "Clicks": "6303",
            "Conversions": "54",
            "Conversion Value": "$9,114",
            "Cost per Result": "$62.66"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "342381",
            "Clicks": "3685",
            "Conversions": "61",
            "Conversion Value": "$11,031",
            "Cost per Result": "$58.82"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "483402",
            "Clicks": "6595",
            "Conversions": "52",
            "Conversion Value": "$9,349",
            "Cost per Result": "$56.13"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "460196",
            "Clicks": "6965",
            "Conversions": "56",
            "Conversion Value": "$11,872",
            "Cost per Result": "$39.99"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "369386",
            "Clicks": "3056",
            "Conversions": "53",
            "Conversion Value": "$13,801",
            "Cost per Result": "$38.74"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "442389",
            "Clicks": "5399",
            "Conversions": "68",
            "Conversion Value": "$7,466",
            "Cost per Result": "$22.32"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "267191",
            "Clicks": "5951",
            "Conversions": "85",
            "Conversion Value": "$10,228",
            "Cost per Result": "$47.45"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "270774",
            "Clicks": "3414",
            "Conversions": "48",
            "Conversion Value": "$7,647",
            "Cost per Result": "$58.26"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "484350",
            "Clicks": "5512",
            "Conversions": "44",
            "Conversion Value": "$15,021",
            "Cost per Result": "$35.34"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "367008",
            "Clicks": "4956",
            "Conversions": "83",
            "Conversion Value": "$14,771",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "186936",
            "Clicks": "6577",
            "Conversions": "59",
            "Conversion Value": "$7,075",
            "Cost per Result": "$20.86"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "457374",
            "Clicks": "4834",
            "Conversions": "63",
            "Conversion Value": "$14,875",
            "Cost per Result": "$62.94"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "488749",
            "Clicks": "4338",
            "Conversions": "79",
            "Conversion Value": "$14,618",
            "Cost per Result": "$40.49"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "454808",
            "Clicks": "2974",
            "Conversions": "86",
            "Conversion Value": "$13,360",
            "Cost per Result": "$52.16"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "390832",
            "Clicks": "3759",
            "Conversions": "90",
            "Conversion Value": "$6,715",
            "Cost per Result": "$60.40"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "350503",
            "Clicks": "4436",
            "Conversions": "80",
            "Conversion Value": "$4,171",
            "Cost per Result": "$29.47"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "408777",
            "Clicks": "2806",
            "Conversions": "45",
            "Conversion Value": "$8,965",
            "Cost per Result": "$37.86"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "237332",
            "Clicks": "4278",
            "Conversions": "49",
            "Conversion Value": "$13,124",
            "Cost per Result": "$18.52"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "434419",
            "Clicks": "2342",
            "Conversions": "68",
            "Conversion Value": "$10,979",
            "Cost per Result": "$37.40"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "285388",
            "Clicks": "2366",
            "Conversions": "57",
            "Conversion Value": "$6,026",
            "Cost per Result": "$27.12"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "310133",
            "Clicks": "2094",
            "Conversions": "72",
            "Conversion Value": "$12,523",
            "Cost per Result": "$57.32"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "390932",
            "Clicks": "2278",
            "Conversions": "72",
            "Conversion Value": "$6,495",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "198081",
            "Clicks": "2884",
            "Conversions": "55",
            "Conversion Value": "$8,981",
            "Cost per Result": "$58.86"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "215060",
            "Clicks": "3175",
            "Conversions": "86",
            "Conversion Value": "$5,018",
            "Cost per Result": "$20.83"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "260294",
            "Clicks": "3031",
            "Conversions": "43",
            "Conversion Value": "$13,298",
            "Cost per Result": "$59.86"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "464584",
            "Clicks": "2154",
            "Conversions": "85",
            "Conversion Value": "$10,279",
            "Cost per Result": "$52.80"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "191242",
            "Clicks": "3193",
            "Conversions": "75",
            "Conversion Value": "$5,640",
            "Cost per Result": "$20.07"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "248608",
            "Clicks": "2519",
            "Conversions": "86",
            "Conversion Value": "$8,521",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "431578",
            "Clicks": "5669",
            "Conversions": "84",
            "Conversion Value": "$7,041",
            "Cost per Result": "$43.35"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "229791",
            "Clicks": "4050",
            "Conversions": "41",
            "Conversion Value": "$15,562",
            "Cost per Result": "$21.71"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "315611",
            "Clicks": "4248",
            "Conversions": "44",
            "Conversion Value": "$7,654",
            "Cost per Result": "$28.71"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "195250",
            "Clicks": "2649",
            "Conversions": "46",
            "Conversion Value": "$15,860",
            "Cost per Result": "$29.06"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "147267",
            "Clicks": "4901",
            "Conversions": "72",
            "Conversion Value": "$10,652",
            "Cost per Result": "$44.78"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "270942",
            "Clicks": "2337",
            "Conversions": "80",
            "Conversion Value": "$10,897",
            "Cost per Result": "$63.46"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "447769",
            "Clicks": "5212",
            "Conversions": "54",
            "Conversion Value": "$4,983",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "423443",
            "Clicks": "3081",
            "Conversions": "55",
            "Conversion Value": "$6,429",
            "Cost per Result": "$51.52"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "267189",
            "Clicks": "6156",
            "Conversions": "45",
            "Conversion Value": "$6,256",
            "Cost per Result": "$42.75"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "219268",
            "Clicks": "2085",
            "Conversions": "56",
            "Conversion Value": "$14,168",
            "Cost per Result": "$55.68"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "401738",
            "Clicks": "4356",
            "Conversions": "77",
            "Conversion Value": "$10,097",
            "Cost per Result": "$42.98"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "385213",
            "Clicks": "2327",
            "Conversions": "45",
            "Conversion Value": "$8,176",
            "Cost per Result": "$61.55"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "215778",
            "Clicks": "4896",
            "Conversions": "82",
            "Conversion Value": "$7,826",
            "Cost per Result": "$31.16"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "430790",
            "Clicks": "4047",
            "Conversions": "70",
            "Conversion Value": "$11,142",
            "Cost per Result": "$62.77"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "145085",
            "Clicks": "6230",
            "Conversions": "79",
            "Conversion Value": "$4,792",
            "Cost per Result": "$51.77"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "409452",
            "Clicks": "3744",
            "Conversions": "63",
            "Conversion Value": "$5,168",
            "Cost per Result": "$36.71"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "374820",
            "Clicks": "2213",
            "Conversions": "58",
            "Conversion Value": "$7,967",
            "Cost per Result": "$41.49"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "118436",
            "Clicks": "4842",
            "Conversions": "62",
            "Conversion Value": "$9,737",
            "Cost per Result": "$25.83"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "367504",
            "Clicks": "5356",
            "Conversions": "47",
            "Conversion Value": "$11,361",
            "Cost per Result": "$32.62"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "433894",
            "Clicks": "3334",
            "Conversions": "85",
            "Conversion Value": "$10,624",
            "Cost per Result": "$61.38"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "494689",
            "Clicks": "6370",
            "Conversions": "67",
            "Conversion Value": "$13,626",
            "Cost per Result": "$36.31"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "370767",
            "Clicks": "4535",
            "Conversions": "90",
            "Conversion Value": "$10,326",
            "Cost per Result": "$15.41"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "238681",
            "Clicks": "3039",
            "Conversions": "44",
            "Conversion Value": "$11,202",
            "Cost per Result": "$16.20"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "224050",
            "Clicks": "4833",
            "Conversions": "81",
            "Conversion Value": "$5,825",
            "Cost per Result": "$62.52"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "422038",
            "Clicks": "6490",
            "Conversions": "80",
            "Conversion Value": "$12,002",
            "Cost per Result": "$31.09"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "124483",
            "Clicks": "4448",
            "Conversions": "78",
            "Conversion Value": "$4,178",
            "Cost per Result": "$51.78"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "242708",
            "Clicks": "6638",
            "Conversions": "52",
            "Conversion Value": "$11,677",
            "Cost per Result": "$49.14"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "118358",
            "Clicks": "2507",
            "Conversions": "67",
            "Conversion Value": "$13,762",
            "Cost per Result": "$29.22"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "398548",
            "Clicks": "4974",
            "Conversions": "85",
            "Conversion Value": "$9,817",
            "Cost per Result": "$24.90"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "205216",
            "Clicks": "3916",
            "Conversions": "48",
            "Conversion Value": "$4,234",
            "Cost per Result": "$39.05"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "139099",
            "Clicks": "4779",
            "Conversions": "62",
            "Conversion Value": "$15,061",
            "Cost per Result": "$59.55"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "242338",
            "Clicks": "4896",
            "Conversions": "49",
            "Conversion Value": "$11,121",
            "Cost per Result": "$42.08"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "454477",
            "Clicks": "4396",
            "Conversions": "45",
            "Conversion Value": "$12,362",
            "Cost per Result": "$33.17"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "210801",
            "Clicks": "5631",
            "Conversions": "71",
            "Conversion Value": "$4,862",
            "Cost per Result": "$51.69"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "175952",
            "Clicks": "2549",
            "Conversions": "79",
            "Conversion Value": "$11,930",
            "Cost per Result": "$45.71"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "250542",
            "Clicks": "3133",
            "Conversions": "57",
            "Conversion Value": "$11,452",
            "Cost per Result": "$51.55"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "402391",
            "Clicks": "2781",
            "Conversions": "48",
            "Conversion Value": "$9,383",
            "Cost per Result": "$17.16"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "483554",
            "Clicks": "5388",
            "Conversions": "59",
            "Conversion Value": "$8,149",
            "Cost per Result": "$56.27"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "468631",
            "Clicks": "2670",
            "Conversions": "55",
            "Conversion Value": "$13,107",
            "Cost per Result": "$32.05"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "183647",
            "Clicks": "5608",
            "Conversions": "50",
            "Conversion Value": "$15,822",
            "Cost per Result": "$32.43"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "430608",
            "Clicks": "3222",
            "Conversions": "57",
            "Conversion Value": "$4,875",
            "Cost per Result": "$45.71"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "302791",
            "Clicks": "3333",
            "Conversions": "72",
            "Conversion Value": "$15,463",
            "Cost per Result": "$47.35"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "157743",
            "Clicks": "2003",
            "Conversions": "67",
            "Conversion Value": "$5,515",
            "Cost per Result": "$16.20"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "368978",
            "Clicks": "6446",
            "Conversions": "46",
            "Conversion Value": "$13,238",
            "Cost per Result": "$57.36"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "102504",
            "Clicks": "4696",
            "Conversions": "86",
            "Conversion Value": "$11,242",
            "Cost per Result": "$54.21"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "126145",
            "Clicks": "5249",
            "Conversions": "90",
            "Conversion Value": "$4,647",
            "Cost per Result": "$23.04"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "173107",
            "Clicks": "2521",
            "Conversions": "69",
            "Conversion Value": "$11,865",
            "Cost per Result": "$23.68"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "446350",
            "Clicks": "3701",
            "Conversions": "88",
            "Conversion Value": "$9,396",
            "Cost per Result": "$23.83"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "287010",
            "Clicks": "5820",
            "Conversions": "87",
            "Conversion Value": "$13,027",
            "Cost per Result": "$34.91"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "217949",
            "Clicks": "4042",
            "Conversions": "62",
            "Conversion Value": "$11,213",
            "Cost per Result": "$25.53"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "355744",
            "Clicks": "2052",
            "Conversions": "45",
            "Conversion Value": "$12,011",
            "Cost per Result": "$43.66"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "167072",
            "Clicks": "4228",
            "Conversions": "64",
            "Conversion Value": "$8,969",
            "Cost per Result": "$37.79"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "177214",
            "Clicks": "2035",
            "Conversions": "69",
            "Conversion Value": "$11,599",
            "Cost per Result": "$50.35"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "325253",
            "Clicks": "3348",
            "Conversions": "70",
            "Conversion Value": "$14,193",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "127868",
            "Clicks": "2685",
            "Conversions": "89",
            "Conversion Value": "$13,202",
            "Cost per Result": "$40.76"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "392496",
            "Clicks": "5878",
            "Conversions": "79",
            "Conversion Value": "$9,702",
            "Cost per Result": "$51.60"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "498192",
            "Clicks": "4355",
            "Conversions": "68",
            "Conversion Value": "$5,627",
            "Cost per Result": "$58.53"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "396807",
            "Clicks": "2086",
            "Conversions": "50",
            "Conversion Value": "$14,112",
            "Cost per Result": "$49.60"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "251215",
            "Clicks": "3713",
            "Conversions": "52",
            "Conversion Value": "$9,084",
            "Cost per Result": "$22.72"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "109500",
            "Clicks": "4087",
            "Conversions": "80",
            "Conversion Value": "$14,007",
            "Cost per Result": "$26.01"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "261759",
            "Clicks": "4391",
            "Conversions": "49",
            "Conversion Value": "$7,117",
            "Cost per Result": "$24.69"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "287593",
            "Clicks": "5874",
            "Conversions": "77",
            "Conversion Value": "$9,595",
            "Cost per Result": "$16.81"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "276940",
            "Clicks": "6394",
            "Conversions": "55",
            "Conversion Value": "$7,440",
            "Cost per Result": "$43.24"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "283840",
            "Clicks": "3908",
            "Conversions": "46",
            "Conversion Value": "$5,516",
            "Cost per Result": "$33.73"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "395036",
            "Clicks": "5860",
            "Conversions": "50",
            "Conversion Value": "$15,020",
            "Cost per Result": "$19.46"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "151261",
            "Clicks": "3447",
            "Conversions": "77",
            "Conversion Value": "$14,631",
            "Cost per Result": "$27.84"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "465711",
            "Clicks": "4597",
            "Conversions": "75",
            "Conversion Value": "$6,057",
            "Cost per Result": "$32.56"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "388260",
            "Clicks": "5242",
            "Conversions": "64",
            "Conversion Value": "$14,270",
            "Cost per Result": "$46.62"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "451318",
            "Clicks": "2117",
            "Conversions": "62",
            "Conversion Value": "$12,059",
            "Cost per Result": "$17.05"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "395096",
            "Clicks": "3614",
            "Conversions": "44",
            "Conversion Value": "$10,358",
            "Cost per Result": "$42.01"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "244519",
            "Clicks": "3634",
            "Conversions": "53",
            "Conversion Value": "$14,978",
            "Cost per Result": "$25.68"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "470832",
            "Clicks": "3375",
            "Conversions": "52",
            "Conversion Value": "$12,685",
            "Cost per Result": "$50.22"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "405918",
            "Clicks": "2580",
            "Conversions": "47",
            "Conversion Value": "$11,457",
            "Cost per Result": "$60.82"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "256893",
            "Clicks": "5821",
            "Conversions": "75",
            "Conversion Value": "$5,477",
            "Cost per Result": "$57.50"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "467106",
            "Clicks": "4152",
            "Conversions": "60",
            "Conversion Value": "$13,552",
            "Cost per Result": "$49.73"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "269971",
            "Clicks": "3901",
            "Conversions": "79",
            "Conversion Value": "$5,448",
            "Cost per Result": "$56.78"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "246246",
            "Clicks": "3088",
            "Conversions": "61",
            "Conversion Value": "$6,584",
            "Cost per Result": "$55.37"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "460412",
            "Clicks": "5089",
            "Conversions": "90",
            "Conversion Value": "$11,988",
            "Cost per Result": "$33.99"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "257805",
            "Clicks": "4020",
            "Conversions": "73",
            "Conversion Value": "$4,313",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "336105",
            "Clicks": "5777",
            "Conversions": "78",
            "Conversion Value": "$7,713",
            "Cost per Result": "$42.20"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "484580",
            "Clicks": "6341",
            "Conversions": "41",
            "Conversion Value": "$4,364",
            "Cost per Result": "$25.75"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "415806",
            "Clicks": "4978",
            "Conversions": "84",
            "Conversion Value": "$11,949",
            "Cost per Result": "$25.80"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "243997",
            "Clicks": "3562",
            "Conversions": "82",
            "Conversion Value": "$14,629",
            "Cost per Result": "$52.52"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "385971",
            "Clicks": "3570",
            "Conversions": "85",
            "Conversion Value": "$11,666",
            "Cost per Result": "$63.73"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "453584",
            "Clicks": "3559",
            "Conversions": "84",
            "Conversion Value": "$12,379",
            "Cost per Result": "$54.75"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "453728",
            "Clicks": "5196",
            "Conversions": "88",
            "Conversion Value": "$8,285",
            "Cost per Result": "$60.45"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "120239",
            "Clicks": "3294",
            "Conversions": "64",
            "Conversion Value": "$9,410",
            "Cost per Result": "$49.40"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "394069",
            "Clicks": "4923",
            "Conversions": "75",
            "Conversion Value": "$9,580",
            "Cost per Result": "$21.98"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "288792",
            "Clicks": "2439",
            "Conversions": "52",
            "Conversion Value": "$4,123",
            "Cost per Result": "$18.63"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "216560",
            "Clicks": "3678",
            "Conversions": "89",
            "Conversion Value": "$6,434",
            "Cost per Result": "$62.26"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "137555",
            "Clicks": "3604",
            "Conversions": "42",
            "Conversion Value": "$9,859",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "216767",
            "Clicks": "6858",
            "Conversions": "72",
            "Conversion Value": "$7,884",
            "Cost per Result": "$15.61"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "458908",
            "Clicks": "4266",
            "Conversions": "65",
            "Conversion Value": "$14,775",
            "Cost per Result": "$40.28"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "377466",
            "Clicks": "6273",
            "Conversions": "56",
            "Conversion Value": "$7,686",
            "Cost per Result": "$36.62"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "474233",
            "Clicks": "2423",
            "Conversions": "42",
            "Conversion Value": "$4,321",
            "Cost per Result": "$40.09"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "248119",
            "Clicks": "4869",
            "Conversions": "65",
            "Conversion Value": "$7,560",
            "Cost per Result": "$47.10"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "373903",
            "Clicks": "4791",
            "Conversions": "52",
            "Conversion Value": "$9,067",
            "Cost per Result": "$15.89"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "317773",
            "Clicks": "5654",
            "Conversions": "73",
            "Conversion Value": "$5,600",
            "Cost per Result": "$48.16"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "319887",
            "Clicks": "5016",
            "Conversions": "47",
            "Conversion Value": "$7,732",
            "Cost per Result": "$31.35"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "322417",
            "Clicks": "6633",
            "Conversions": "68",
            "Conversion Value": "$9,363",
            "Cost per Result": "$25.26"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "182574",
            "Clicks": "4113",
            "Conversions": "47",
            "Conversion Value": "$5,534",
            "Cost per Result": "$48.68"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "465412",
            "Clicks": "4706",
            "Conversions": "57",
            "Conversion Value": "$7,688",
            "Cost per Result": "$45.03"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "292429",
            "Clicks": "4446",
            "Conversions": "50",
            "Conversion Value": "$7,997",
            "Cost per Result": "$30.19"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "407072",
            "Clicks": "2805",
            "Conversions": "75",
            "Conversion Value": "$11,889",
            "Cost per Result": "$24.32"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "165300",
            "Clicks": "5312",
            "Conversions": "56",
            "Conversion Value": "$5,359",
            "Cost per Result": "$37.73"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "281929",
            "Clicks": "3450",
            "Conversions": "82",
            "Conversion Value": "$15,823",
            "Cost per Result": "$52.83"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "307967",
            "Clicks": "2176",
            "Conversions": "77",
            "Conversion Value": "$14,835",
            "Cost per Result": "$17.72"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "170388",
            "Clicks": "5585",
            "Conversions": "58",
            "Conversion Value": "$6,456",
            "Cost per Result": "$48.91"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "134434",
            "Clicks": "5066",
            "Conversions": "62",
            "Conversion Value": "$15,491",
            "Cost per Result": "$26.04"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "182855",
            "Clicks": "2493",
            "Conversions": "48",
            "Conversion Value": "$14,415",
            "Cost per Result": "$33.31"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "236569",
            "Clicks": "5494",
            "Conversions": "55",
            "Conversion Value": "$7,183",
            "Cost per Result": "$64.92"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "462345",
            "Clicks": "6638",
            "Conversions": "88",
            "Conversion Value": "$15,020",
            "Cost per Result": "$21.85"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "173882",
            "Clicks": "6791",
            "Conversions": "50",
            "Conversion Value": "$6,420",
            "Cost per Result": "$54.40"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "385975",
            "Clicks": "5121",
            "Conversions": "48",
            "Conversion Value": "$11,392",
            "Cost per Result": "$27.50"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "202199",
            "Clicks": "2146",
            "Conversions": "71",
            "Conversion Value": "$11,217",
            "Cost per Result": "$63.58"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "112472",
            "Clicks": "4556",
            "Conversions": "45",
            "Conversion Value": "$14,050",
            "Cost per Result": "$54.64"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "281061",
            "Clicks": "3122",
            "Conversions": "52",
            "Conversion Value": "$9,177",
            "Cost per Result": "$51.64"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "204286",
            "Clicks": "4398",
            "Conversions": "80",
            "Conversion Value": "$5,358",
            "Cost per Result": "$27.84"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "423023",
            "Clicks": "2673",
            "Conversions": "56",
            "Conversion Value": "$8,359",
            "Cost per Result": "$49.95"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "227488",
            "Clicks": "3202",
            "Conversions": "88",
            "Conversion Value": "$14,312",
            "Cost per Result": "$30.60"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "418573",
            "Clicks": "4727",
            "Conversions": "71",
            "Conversion Value": "$5,576",
            "Cost per Result": "$63.10"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "435263",
            "Clicks": "6065",
            "Conversions": "70",
            "Conversion Value": "$13,059",
            "Cost per Result": "$51.23"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "238684",
            "Clicks": "2368",
            "Conversions": "49",
            "Conversion Value": "$7,364",
            "Cost per Result": "$26.52"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "162303",
            "Clicks": "6197",
            "Conversions": "57",
            "Conversion Value": "$15,935",
            "Cost per Result": "$27.32"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "139573",
            "Clicks": "6457",
            "Conversions": "60",
            "Conversion Value": "$8,668",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "445216",
            "Clicks": "2594",
            "Conversions": "80",
            "Conversion Value": "$12,359",
            "Cost per Result": "$63.39"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "434044",
            "Clicks": "2733",
            "Conversions": "71",
            "Conversion Value": "$4,609",
            "Cost per Result": "$62.27"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "141588",
            "Clicks": "4320",
            "Conversions": "83",
            "Conversion Value": "$9,917",
            "Cost per Result": "$27.82"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "319957",
            "Clicks": "4884",
            "Conversions": "51",
            "Conversion Value": "$6,607",
            "Cost per Result": "$34.23"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "307398",
            "Clicks": "3510",
            "Conversions": "43",
            "Conversion Value": "$13,452",
            "Cost per Result": "$34.92"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "144235",
            "Clicks": "6589",
            "Conversions": "76",
            "Conversion Value": "$6,210",
            "Cost per Result": "$39.55"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "325052",
            "Clicks": "6785",
            "Conversions": "61",
            "Conversion Value": "$7,415",
            "Cost per Result": "$19.89"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "212106",
            "Clicks": "3111",
            "Conversions": "54",
            "Conversion Value": "$12,752",
            "Cost per Result": "$57.11"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "124694",
            "Clicks": "6939",
            "Conversions": "50",
            "Conversion Value": "$12,847",
            "Cost per Result": "$41.40"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "313826",
            "Clicks": "6679",
            "Conversions": "90",
            "Conversion Value": "$6,371",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "138140",
            "Clicks": "4164",
            "Conversions": "43",
            "Conversion Value": "$14,681",
            "Cost per Result": "$58.95"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "229108",
            "Clicks": "4523",
            "Conversions": "40",
            "Conversion Value": "$11,863",
            "Cost per Result": "$38.02"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "252556",
            "Clicks": "2431",
            "Conversions": "45",
            "Conversion Value": "$14,920",
            "Cost per Result": "$52.74"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "283224",
            "Clicks": "4780",
            "Conversions": "42",
            "Conversion Value": "$8,596",
            "Cost per Result": "$55.94"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "130857",
            "Clicks": "5305",
            "Conversions": "62",
            "Conversion Value": "$6,444",
            "Cost per Result": "$22.80"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "174990",
            "Clicks": "6859",
            "Conversions": "42",
            "Conversion Value": "$12,646",
            "Cost per Result": "$25.13"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "335069",
            "Clicks": "5278",
            "Conversions": "54",
            "Conversion Value": "$4,425",
            "Cost per Result": "$42.25"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "188393",
            "Clicks": "6777",
            "Conversions": "87",
            "Conversion Value": "$11,966",
            "Cost per Result": "$46.74"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "280929",
            "Clicks": "2624",
            "Conversions": "68",
            "Conversion Value": "$7,837",
            "Cost per Result": "$15.89"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "455637",
            "Clicks": "6592",
            "Conversions": "81",
            "Conversion Value": "$6,609",
            "Cost per Result": "$25.19"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "276831",
            "Clicks": "2900",
            "Conversions": "71",
            "Conversion Value": "$4,348",
            "Cost per Result": "$32.02"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "285301",
            "Clicks": "4146",
            "Conversions": "42",
            "Conversion Value": "$11,608",
            "Cost per Result": "$49.76"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "323553",
            "Clicks": "5102",
            "Conversions": "70",
            "Conversion Value": "$14,590",
            "Cost per Result": "$36.47"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "136713",
            "Clicks": "6070",
            "Conversions": "85",
            "Conversion Value": "$12,409",
            "Cost per Result": "$50.64"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "495568",
            "Clicks": "4419",
            "Conversions": "78",
            "Conversion Value": "$4,809",
            "Cost per Result": "$28.98"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "248427",
            "Clicks": "3616",
            "Conversions": "67",
            "Conversion Value": "$12,850",
            "Cost per Result": "$19.39"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "168489",
            "Clicks": "5644",
            "Conversions": "41",
            "Conversion Value": "$7,318",
            "Cost per Result": "$52.82"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "159068",
            "Clicks": "2322",
            "Conversions": "70",
            "Conversion Value": "$5,294",
            "Cost per Result": "$47.84"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "435186",
            "Clicks": "5156",
            "Conversions": "45",
            "Conversion Value": "$4,496",
            "Cost per Result": "$45.98"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "143072",
            "Clicks": "4472",
            "Conversions": "75",
            "Conversion Value": "$12,210",
            "Cost per Result": "$36.33"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "156252",
            "Clicks": "4698",
            "Conversions": "88",
            "Conversion Value": "$8,116",
            "Cost per Result": "$43.52"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "178705",
            "Clicks": "6731",
            "Conversions": "74",
            "Conversion Value": "$13,221",
            "Cost per Result": "$63.52"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "166244",
            "Clicks": "3672",
            "Conversions": "74",
            "Conversion Value": "$10,184",
            "Cost per Result": "$42.87"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "134049",
            "Clicks": "2161",
            "Conversions": "52",
            "Conversion Value": "$11,485",
            "Cost per Result": "$17.91"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "208710",
            "Clicks": "4727",
            "Conversions": "64",
            "Conversion Value": "$6,442",
            "Cost per Result": "$42.35"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "167571",
            "Clicks": "3995",
            "Conversions": "79",
            "Conversion Value": "$12,397",
            "Cost per Result": "$19.15"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "360546",
            "Clicks": "4903",
            "Conversions": "60",
            "Conversion Value": "$8,386",
            "Cost per Result": "$15.32"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "468780",
            "Clicks": "2408",
            "Conversions": "49",
            "Conversion Value": "$7,602",
            "Cost per Result": "$39.39"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "245362",
            "Clicks": "5602",
            "Conversions": "83",
            "Conversion Value": "$6,499",
            "Cost per Result": "$40.61"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "293040",
            "Clicks": "6954",
            "Conversions": "62",
            "Conversion Value": "$7,715",
            "Cost per Result": "$64.64"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "198877",
            "Clicks": "4103",
            "Conversions": "60",
            "Conversion Value": "$8,033",
            "Cost per Result": "$53.48"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "460666",
            "Clicks": "6952",
            "Conversions": "59",
            "Conversion Value": "$8,784",
            "Cost per Result": "$62.17"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "365146",
            "Clicks": "4074",
            "Conversions": "66",
            "Conversion Value": "$15,814",
            "Cost per Result": "$38.45"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "288127",
            "Clicks": "5853",
            "Conversions": "65",
            "Conversion Value": "$5,520",
            "Cost per Result": "$23.07"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "455630",
            "Clicks": "2613",
            "Conversions": "52",
            "Conversion Value": "$14,324",
            "Cost per Result": "$37.94"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "299048",
            "Clicks": "5462",
            "Conversions": "41",
            "Conversion Value": "$10,714",
            "Cost per Result": "$44.14"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "234577",
            "Clicks": "6203",
            "Conversions": "68",
            "Conversion Value": "$4,650",
            "Cost per Result": "$51.84"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "131082",
            "Clicks": "6602",
            "Conversions": "85",
            "Conversion Value": "$9,515",
            "Cost per Result": "$18.58"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "101357",
            "Clicks": "2887",
            "Conversions": "85",
            "Conversion Value": "$11,469",
            "Cost per Result": "$38.33"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "124890",
            "Clicks": "2895",
            "Conversions": "56",
            "Conversion Value": "$5,014",
            "Cost per Result": "$50.18"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "476587",
            "Clicks": "5061",
            "Conversions": "74",
            "Conversion Value": "$10,205",
            "Cost per Result": "$23.99"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "172186",
            "Clicks": "2401",
            "Conversions": "84",
            "Conversion Value": "$15,430",
            "Cost per Result": "$47.51"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "474045",
            "Clicks": "6852",
            "Conversions": "76",
            "Conversion Value": "$10,061",
            "Cost per Result": "$28.07"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "136777",
            "Clicks": "2759",
            "Conversions": "88",
            "Conversion Value": "$6,263",
            "Cost per Result": "$38.05"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "220420",
            "Clicks": "2063",
            "Conversions": "81",
            "Conversion Value": "$14,154",
            "Cost per Result": "$58.19"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "477172",
            "Clicks": "6888",
            "Conversions": "90",
            "Conversion Value": "$14,166",
            "Cost per Result": "$61.43"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "192147",
            "Clicks": "3418",
            "Conversions": "65",
            "Conversion Value": "$13,127",
            "Cost per Result": "$29.98"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "329660",
            "Clicks": "4332",
            "Conversions": "73",
            "Conversion Value": "$13,116",
            "Cost per Result": "$16.00"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "351463",
            "Clicks": "4000",
            "Conversions": "47",
            "Conversion Value": "$15,224",
            "Cost per Result": "$33.96"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "100884",
            "Clicks": "4589",
            "Conversions": "60",
            "Conversion Value": "$8,665",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "117241",
            "Clicks": "2886",
            "Conversions": "43",
            "Conversion Value": "$9,677",
            "Cost per Result": "$58.80"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "419220",
            "Clicks": "5875",
            "Conversions": "61",
            "Conversion Value": "$15,802",
            "Cost per Result": "$53.59"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "190904",
            "Clicks": "4214",
            "Conversions": "51",
            "Conversion Value": "$5,819",
            "Cost per Result": "$40.32"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "461386",
            "Clicks": "6069",
            "Conversions": "88",
            "Conversion Value": "$6,623",
            "Cost per Result": "$16.02"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "421358",
            "Clicks": "3059",
            "Conversions": "42",
            "Conversion Value": "$11,661",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "367553",
            "Clicks": "6267",
            "Conversions": "65",
            "Conversion Value": "$14,327",
            "Cost per Result": "$26.51"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "338642",
            "Clicks": "5415",
            "Conversions": "67",
            "Conversion Value": "$7,575",
            "Cost per Result": "$57.58"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "153877",
            "Clicks": "5598",
            "Conversions": "58",
            "Conversion Value": "$13,448",
            "Cost per Result": "$45.61"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "373867",
            "Clicks": "4115",
            "Conversions": "63",
            "Conversion Value": "$9,703",
            "Cost per Result": "$64.42"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "101325",
            "Clicks": "4284",
            "Conversions": "82",
            "Conversion Value": "$11,165",
            "Cost per Result": "$39.67"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "228851",
            "Clicks": "3263",
            "Conversions": "89",
            "Conversion Value": "$4,843",
            "Cost per Result": "$44.04"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "225456",
            "Clicks": "3580",
            "Conversions": "62",
            "Conversion Value": "$13,479",
            "Cost per Result": "$19.64"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "235026",
            "Clicks": "6680",
            "Conversions": "79",
            "Conversion Value": "$14,889",
            "Cost per Result": "$56.97"
          }
        ]
      },
      "DeFiUsersData": {
        "name": "DeFi Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "135464",
            "Clicks": "2753",
            "Conversions": "79",
            "Conversion Value": "$6,552",
            "Cost per Result": "$49.85"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "423079",
            "Clicks": "4795",
            "Conversions": "80",
            "Conversion Value": "$5,191",
            "Cost per Result": "$35.22"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "250462",
            "Clicks": "4056",
            "Conversions": "65",
            "Conversion Value": "$6,056",
            "Cost per Result": "$54.56"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "301590",
            "Clicks": "5936",
            "Conversions": "70",
            "Conversion Value": "$8,709",
            "Cost per Result": "$21.64"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "191771",
            "Clicks": "4606",
            "Conversions": "83",
            "Conversion Value": "$8,588",
            "Cost per Result": "$51.09"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "129106",
            "Clicks": "4620",
            "Conversions": "53",
            "Conversion Value": "$4,795",
            "Cost per Result": "$40.68"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "269089",
            "Clicks": "6641",
            "Conversions": "67",
            "Conversion Value": "$14,730",
            "Cost per Result": "$31.34"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "321291",
            "Clicks": "4570",
            "Conversions": "63",
            "Conversion Value": "$10,792",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "332048",
            "Clicks": "2112",
            "Conversions": "62",
            "Conversion Value": "$8,471",
            "Cost per Result": "$41.23"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "451953",
            "Clicks": "2882",
            "Conversions": "84",
            "Conversion Value": "$7,388",
            "Cost per Result": "$28.38"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "285465",
            "Clicks": "2238",
            "Conversions": "58",
            "Conversion Value": "$14,728",
            "Cost per Result": "$39.30"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "242589",
            "Clicks": "6873",
            "Conversions": "48",
            "Conversion Value": "$11,753",
            "Cost per Result": "$40.95"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "101996",
            "Clicks": "4367",
            "Conversions": "90",
            "Conversion Value": "$11,367",
            "Cost per Result": "$57.65"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "175680",
            "Clicks": "5785",
            "Conversions": "61",
            "Conversion Value": "$12,981",
            "Cost per Result": "$16.87"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "490122",
            "Clicks": "3462",
            "Conversions": "68",
            "Conversion Value": "$13,882",
            "Cost per Result": "$48.94"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "211358",
            "Clicks": "4525",
            "Conversions": "79",
            "Conversion Value": "$5,559",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "199890",
            "Clicks": "5342",
            "Conversions": "58",
            "Conversion Value": "$13,467",
            "Cost per Result": "$54.02"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "349816",
            "Clicks": "5896",
            "Conversions": "73",
            "Conversion Value": "$9,173",
            "Cost per Result": "$53.53"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "333494",
            "Clicks": "6274",
            "Conversions": "40",
            "Conversion Value": "$15,137",
            "Cost per Result": "$61.16"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "325986",
            "Clicks": "5207",
            "Conversions": "81",
            "Conversion Value": "$8,036",
            "Cost per Result": "$62.18"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "129268",
            "Clicks": "4232",
            "Conversions": "69",
            "Conversion Value": "$9,656",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "196011",
            "Clicks": "2239",
            "Conversions": "46",
            "Conversion Value": "$12,046",
            "Cost per Result": "$40.80"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "268877",
            "Clicks": "4882",
            "Conversions": "47",
            "Conversion Value": "$6,430",
            "Cost per Result": "$28.07"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "280160",
            "Clicks": "3336",
            "Conversions": "90",
            "Conversion Value": "$10,223",
            "Cost per Result": "$33.48"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "287544",
            "Clicks": "4696",
            "Conversions": "67",
            "Conversion Value": "$5,436",
            "Cost per Result": "$46.61"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "324104",
            "Clicks": "6671",
            "Conversions": "61",
            "Conversion Value": "$14,657",
            "Cost per Result": "$33.17"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "221433",
            "Clicks": "4874",
            "Conversions": "90",
            "Conversion Value": "$11,202",
            "Cost per Result": "$28.58"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "345661",
            "Clicks": "2093",
            "Conversions": "47",
            "Conversion Value": "$5,629",
            "Cost per Result": "$60.69"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "386533",
            "Clicks": "6133",
            "Conversions": "50",
            "Conversion Value": "$11,369",
            "Cost per Result": "$30.13"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "119687",
            "Clicks": "4414",
            "Conversions": "81",
            "Conversion Value": "$6,639",
            "Cost per Result": "$64.59"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "337272",
            "Clicks": "2951",
            "Conversions": "61",
            "Conversion Value": "$12,386",
            "Cost per Result": "$58.02"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "348112",
            "Clicks": "3669",
            "Conversions": "41",
            "Conversion Value": "$13,176",
            "Cost per Result": "$50.53"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "295057",
            "Clicks": "3459",
            "Conversions": "89",
            "Conversion Value": "$7,326",
            "Cost per Result": "$18.15"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "350078",
            "Clicks": "4181",
            "Conversions": "51",
            "Conversion Value": "$4,642",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "367012",
            "Clicks": "6262",
            "Conversions": "76",
            "Conversion Value": "$12,946",
            "Cost per Result": "$57.35"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "329634",
            "Clicks": "6672",
            "Conversions": "84",
            "Conversion Value": "$14,570",
            "Cost per Result": "$33.15"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "100401",
            "Clicks": "4020",
            "Conversions": "71",
            "Conversion Value": "$14,244",
            "Cost per Result": "$42.03"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "432840",
            "Clicks": "5986",
            "Conversions": "66",
            "Conversion Value": "$13,922",
            "Cost per Result": "$34.71"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "351114",
            "Clicks": "5352",
            "Conversions": "90",
            "Conversion Value": "$13,609",
            "Cost per Result": "$61.89"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "445892",
            "Clicks": "4274",
            "Conversions": "41",
            "Conversion Value": "$4,129",
            "Cost per Result": "$56.94"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "204570",
            "Clicks": "6364",
            "Conversions": "43",
            "Conversion Value": "$6,778",
            "Cost per Result": "$18.54"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "420775",
            "Clicks": "2629",
            "Conversions": "83",
            "Conversion Value": "$14,409",
            "Cost per Result": "$42.33"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "299689",
            "Clicks": "2161",
            "Conversions": "85",
            "Conversion Value": "$13,339",
            "Cost per Result": "$43.19"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "311013",
            "Clicks": "4021",
            "Conversions": "89",
            "Conversion Value": "$10,758",
            "Cost per Result": "$44.32"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "402895",
            "Clicks": "5632",
            "Conversions": "42",
            "Conversion Value": "$13,484",
            "Cost per Result": "$50.55"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "179571",
            "Clicks": "3848",
            "Conversions": "77",
            "Conversion Value": "$9,917",
            "Cost per Result": "$45.22"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "297333",
            "Clicks": "3348",
            "Conversions": "54",
            "Conversion Value": "$10,506",
            "Cost per Result": "$50.96"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "282378",
            "Clicks": "5132",
            "Conversions": "44",
            "Conversion Value": "$15,788",
            "Cost per Result": "$42.34"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "336725",
            "Clicks": "4218",
            "Conversions": "60",
            "Conversion Value": "$5,559",
            "Cost per Result": "$40.78"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "469056",
            "Clicks": "5274",
            "Conversions": "42",
            "Conversion Value": "$4,882",
            "Cost per Result": "$51.75"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "113993",
            "Clicks": "4445",
            "Conversions": "40",
            "Conversion Value": "$14,324",
            "Cost per Result": "$33.35"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "268315",
            "Clicks": "6812",
            "Conversions": "89",
            "Conversion Value": "$12,862",
            "Cost per Result": "$28.77"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "468531",
            "Clicks": "4163",
            "Conversions": "52",
            "Conversion Value": "$11,153",
            "Cost per Result": "$26.47"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "286261",
            "Clicks": "2684",
            "Conversions": "62",
            "Conversion Value": "$7,146",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "165690",
            "Clicks": "2008",
            "Conversions": "80",
            "Conversion Value": "$15,241",
            "Cost per Result": "$25.47"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "345226",
            "Clicks": "6317",
            "Conversions": "66",
            "Conversion Value": "$4,792",
            "Cost per Result": "$63.44"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "396937",
            "Clicks": "4478",
            "Conversions": "54",
            "Conversion Value": "$9,460",
            "Cost per Result": "$16.10"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "288306",
            "Clicks": "5524",
            "Conversions": "54",
            "Conversion Value": "$5,754",
            "Cost per Result": "$26.26"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "344369",
            "Clicks": "3971",
            "Conversions": "80",
            "Conversion Value": "$9,437",
            "Cost per Result": "$45.45"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "186696",
            "Clicks": "3482",
            "Conversions": "89",
            "Conversion Value": "$7,506",
            "Cost per Result": "$54.02"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "310797",
            "Clicks": "2230",
            "Conversions": "60",
            "Conversion Value": "$13,480",
            "Cost per Result": "$48.28"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "185306",
            "Clicks": "2269",
            "Conversions": "46",
            "Conversion Value": "$11,613",
            "Cost per Result": "$58.27"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "224021",
            "Clicks": "2860",
            "Conversions": "68",
            "Conversion Value": "$10,358",
            "Cost per Result": "$26.94"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "235656",
            "Clicks": "4007",
            "Conversions": "56",
            "Conversion Value": "$4,740",
            "Cost per Result": "$37.86"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "475600",
            "Clicks": "5744",
            "Conversions": "84",
            "Conversion Value": "$6,309",
            "Cost per Result": "$45.09"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "278361",
            "Clicks": "4063",
            "Conversions": "47",
            "Conversion Value": "$4,081",
            "Cost per Result": "$44.77"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "250587",
            "Clicks": "2419",
            "Conversions": "80",
            "Conversion Value": "$13,444",
            "Cost per Result": "$44.99"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "291678",
            "Clicks": "2019",
            "Conversions": "70",
            "Conversion Value": "$13,060",
            "Cost per Result": "$64.03"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "452106",
            "Clicks": "6719",
            "Conversions": "67",
            "Conversion Value": "$5,258",
            "Cost per Result": "$48.63"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "214866",
            "Clicks": "6926",
            "Conversions": "58",
            "Conversion Value": "$6,159",
            "Cost per Result": "$41.93"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "336092",
            "Clicks": "5425",
            "Conversions": "88",
            "Conversion Value": "$12,788",
            "Cost per Result": "$59.22"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "339004",
            "Clicks": "3375",
            "Conversions": "50",
            "Conversion Value": "$15,063",
            "Cost per Result": "$45.12"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "266285",
            "Clicks": "5428",
            "Conversions": "80",
            "Conversion Value": "$6,797",
            "Cost per Result": "$59.99"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "343213",
            "Clicks": "2033",
            "Conversions": "72",
            "Conversion Value": "$14,928",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "202799",
            "Clicks": "4185",
            "Conversions": "47",
            "Conversion Value": "$4,403",
            "Cost per Result": "$55.00"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "406919",
            "Clicks": "4687",
            "Conversions": "86",
            "Conversion Value": "$11,312",
            "Cost per Result": "$34.45"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "381250",
            "Clicks": "4389",
            "Conversions": "69",
            "Conversion Value": "$13,515",
            "Cost per Result": "$44.61"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "371173",
            "Clicks": "2744",
            "Conversions": "60",
            "Conversion Value": "$14,268",
            "Cost per Result": "$61.62"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "461110",
            "Clicks": "5284",
            "Conversions": "44",
            "Conversion Value": "$14,093",
            "Cost per Result": "$58.22"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "232599",
            "Clicks": "4293",
            "Conversions": "55",
            "Conversion Value": "$4,569",
            "Cost per Result": "$22.21"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "465996",
            "Clicks": "6086",
            "Conversions": "87",
            "Conversion Value": "$9,771",
            "Cost per Result": "$26.42"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "367056",
            "Clicks": "6530",
            "Conversions": "67",
            "Conversion Value": "$5,573",
            "Cost per Result": "$18.68"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "355282",
            "Clicks": "6610",
            "Conversions": "81",
            "Conversion Value": "$6,612",
            "Cost per Result": "$33.96"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "287588",
            "Clicks": "5268",
            "Conversions": "40",
            "Conversion Value": "$7,332",
            "Cost per Result": "$33.74"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "212468",
            "Clicks": "4365",
            "Conversions": "72",
            "Conversion Value": "$15,597",
            "Cost per Result": "$16.06"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "489962",
            "Clicks": "4495",
            "Conversions": "54",
            "Conversion Value": "$13,332",
            "Cost per Result": "$36.23"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "173967",
            "Clicks": "2625",
            "Conversions": "51",
            "Conversion Value": "$14,937",
            "Cost per Result": "$52.78"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "132538",
            "Clicks": "2074",
            "Conversions": "57",
            "Conversion Value": "$15,455",
            "Cost per Result": "$54.19"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "421535",
            "Clicks": "2135",
            "Conversions": "53",
            "Conversion Value": "$8,677",
            "Cost per Result": "$55.04"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "195249",
            "Clicks": "5950",
            "Conversions": "84",
            "Conversion Value": "$7,868",
            "Cost per Result": "$58.69"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "297940",
            "Clicks": "3122",
            "Conversions": "87",
            "Conversion Value": "$5,810",
            "Cost per Result": "$20.75"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "480928",
            "Clicks": "6672",
            "Conversions": "87",
            "Conversion Value": "$12,820",
            "Cost per Result": "$46.28"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "460145",
            "Clicks": "4837",
            "Conversions": "44",
            "Conversion Value": "$13,883",
            "Cost per Result": "$58.75"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "233592",
            "Clicks": "5344",
            "Conversions": "63",
            "Conversion Value": "$9,463",
            "Cost per Result": "$58.98"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "345275",
            "Clicks": "6423",
            "Conversions": "86",
            "Conversion Value": "$14,660",
            "Cost per Result": "$21.39"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "180909",
            "Clicks": "6710",
            "Conversions": "47",
            "Conversion Value": "$8,556",
            "Cost per Result": "$22.24"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "186062",
            "Clicks": "4967",
            "Conversions": "59",
            "Conversion Value": "$14,818",
            "Cost per Result": "$55.96"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "346741",
            "Clicks": "3940",
            "Conversions": "53",
            "Conversion Value": "$12,244",
            "Cost per Result": "$38.12"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "474639",
            "Clicks": "5079",
            "Conversions": "49",
            "Conversion Value": "$15,104",
            "Cost per Result": "$57.62"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "165533",
            "Clicks": "2964",
            "Conversions": "81",
            "Conversion Value": "$15,224",
            "Cost per Result": "$26.73"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "143224",
            "Clicks": "2627",
            "Conversions": "52",
            "Conversion Value": "$14,259",
            "Cost per Result": "$51.48"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "160424",
            "Clicks": "3797",
            "Conversions": "71",
            "Conversion Value": "$12,860",
            "Cost per Result": "$17.38"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "220246",
            "Clicks": "5695",
            "Conversions": "83",
            "Conversion Value": "$11,889",
            "Cost per Result": "$62.38"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "475035",
            "Clicks": "5287",
            "Conversions": "71",
            "Conversion Value": "$5,669",
            "Cost per Result": "$34.65"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "449889",
            "Clicks": "5665",
            "Conversions": "81",
            "Conversion Value": "$4,095",
            "Cost per Result": "$31.61"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "384528",
            "Clicks": "6988",
            "Conversions": "46",
            "Conversion Value": "$7,959",
            "Cost per Result": "$35.59"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "257407",
            "Clicks": "4351",
            "Conversions": "43",
            "Conversion Value": "$13,009",
            "Cost per Result": "$64.84"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "120947",
            "Clicks": "6181",
            "Conversions": "85",
            "Conversion Value": "$9,947",
            "Cost per Result": "$17.00"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "333407",
            "Clicks": "5459",
            "Conversions": "42",
            "Conversion Value": "$8,549",
            "Cost per Result": "$39.18"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "453005",
            "Clicks": "3496",
            "Conversions": "71",
            "Conversion Value": "$15,803",
            "Cost per Result": "$32.70"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "435501",
            "Clicks": "5510",
            "Conversions": "77",
            "Conversion Value": "$15,122",
            "Cost per Result": "$57.10"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "440292",
            "Clicks": "3575",
            "Conversions": "57",
            "Conversion Value": "$7,711",
            "Cost per Result": "$28.14"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "309575",
            "Clicks": "5856",
            "Conversions": "48",
            "Conversion Value": "$8,925",
            "Cost per Result": "$16.01"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "370711",
            "Clicks": "6901",
            "Conversions": "78",
            "Conversion Value": "$9,109",
            "Cost per Result": "$45.91"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "465597",
            "Clicks": "4975",
            "Conversions": "81",
            "Conversion Value": "$6,041",
            "Cost per Result": "$53.31"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "279101",
            "Clicks": "4476",
            "Conversions": "52",
            "Conversion Value": "$7,926",
            "Cost per Result": "$19.64"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "361171",
            "Clicks": "3046",
            "Conversions": "90",
            "Conversion Value": "$4,073",
            "Cost per Result": "$45.98"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "381626",
            "Clicks": "2770",
            "Conversions": "55",
            "Conversion Value": "$6,318",
            "Cost per Result": "$15.51"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "212834",
            "Clicks": "6655",
            "Conversions": "66",
            "Conversion Value": "$5,185",
            "Cost per Result": "$18.99"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "248079",
            "Clicks": "3966",
            "Conversions": "90",
            "Conversion Value": "$10,534",
            "Cost per Result": "$40.04"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "139406",
            "Clicks": "3393",
            "Conversions": "55",
            "Conversion Value": "$15,972",
            "Cost per Result": "$30.90"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "132702",
            "Clicks": "5711",
            "Conversions": "50",
            "Conversion Value": "$4,946",
            "Cost per Result": "$46.73"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "219048",
            "Clicks": "3397",
            "Conversions": "88",
            "Conversion Value": "$15,285",
            "Cost per Result": "$23.57"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "209188",
            "Clicks": "4466",
            "Conversions": "80",
            "Conversion Value": "$5,870",
            "Cost per Result": "$34.25"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "393703",
            "Clicks": "4383",
            "Conversions": "83",
            "Conversion Value": "$14,488",
            "Cost per Result": "$45.13"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "479828",
            "Clicks": "2084",
            "Conversions": "40",
            "Conversion Value": "$5,551",
            "Cost per Result": "$47.33"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "269048",
            "Clicks": "4163",
            "Conversions": "75",
            "Conversion Value": "$10,506",
            "Cost per Result": "$32.92"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "280481",
            "Clicks": "3229",
            "Conversions": "73",
            "Conversion Value": "$11,177",
            "Cost per Result": "$63.67"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "129286",
            "Clicks": "5880",
            "Conversions": "55",
            "Conversion Value": "$5,396",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "367165",
            "Clicks": "6775",
            "Conversions": "75",
            "Conversion Value": "$4,072",
            "Cost per Result": "$63.07"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "114324",
            "Clicks": "3129",
            "Conversions": "44",
            "Conversion Value": "$5,332",
            "Cost per Result": "$62.39"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "187515",
            "Clicks": "6832",
            "Conversions": "79",
            "Conversion Value": "$5,479",
            "Cost per Result": "$25.79"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "198787",
            "Clicks": "4813",
            "Conversions": "60",
            "Conversion Value": "$13,099",
            "Cost per Result": "$25.04"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "308404",
            "Clicks": "3783",
            "Conversions": "76",
            "Conversion Value": "$11,901",
            "Cost per Result": "$60.10"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "280858",
            "Clicks": "6109",
            "Conversions": "86",
            "Conversion Value": "$15,099",
            "Cost per Result": "$52.40"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "343198",
            "Clicks": "4791",
            "Conversions": "87",
            "Conversion Value": "$8,368",
            "Cost per Result": "$62.41"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "353137",
            "Clicks": "6276",
            "Conversions": "57",
            "Conversion Value": "$14,276",
            "Cost per Result": "$59.26"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "108215",
            "Clicks": "2066",
            "Conversions": "42",
            "Conversion Value": "$9,028",
            "Cost per Result": "$58.03"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "213085",
            "Clicks": "5853",
            "Conversions": "58",
            "Conversion Value": "$5,065",
            "Cost per Result": "$60.65"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "203910",
            "Clicks": "5516",
            "Conversions": "72",
            "Conversion Value": "$14,925",
            "Cost per Result": "$56.31"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "408718",
            "Clicks": "2782",
            "Conversions": "47",
            "Conversion Value": "$8,812",
            "Cost per Result": "$53.21"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "384462",
            "Clicks": "4772",
            "Conversions": "48",
            "Conversion Value": "$13,962",
            "Cost per Result": "$48.38"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "243989",
            "Clicks": "5352",
            "Conversions": "74",
            "Conversion Value": "$10,213",
            "Cost per Result": "$34.68"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "428317",
            "Clicks": "6506",
            "Conversions": "75",
            "Conversion Value": "$4,051",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "331842",
            "Clicks": "6060",
            "Conversions": "79",
            "Conversion Value": "$13,876",
            "Cost per Result": "$44.23"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "386491",
            "Clicks": "6969",
            "Conversions": "47",
            "Conversion Value": "$12,848",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "231648",
            "Clicks": "2290",
            "Conversions": "57",
            "Conversion Value": "$12,644",
            "Cost per Result": "$38.63"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "356231",
            "Clicks": "2102",
            "Conversions": "56",
            "Conversion Value": "$4,883",
            "Cost per Result": "$23.84"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "298297",
            "Clicks": "2356",
            "Conversions": "59",
            "Conversion Value": "$11,893",
            "Cost per Result": "$52.15"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "152115",
            "Clicks": "4026",
            "Conversions": "83",
            "Conversion Value": "$9,322",
            "Cost per Result": "$60.99"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "365481",
            "Clicks": "3160",
            "Conversions": "45",
            "Conversion Value": "$11,550",
            "Cost per Result": "$55.87"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "128459",
            "Clicks": "6097",
            "Conversions": "60",
            "Conversion Value": "$15,766",
            "Cost per Result": "$47.35"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "154816",
            "Clicks": "2693",
            "Conversions": "88",
            "Conversion Value": "$5,439",
            "Cost per Result": "$36.37"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "405609",
            "Clicks": "5041",
            "Conversions": "71",
            "Conversion Value": "$4,112",
            "Cost per Result": "$43.34"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "220817",
            "Clicks": "2370",
            "Conversions": "76",
            "Conversion Value": "$12,170",
            "Cost per Result": "$27.24"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "184553",
            "Clicks": "3206",
            "Conversions": "47",
            "Conversion Value": "$10,441",
            "Cost per Result": "$15.21"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "190988",
            "Clicks": "3879",
            "Conversions": "58",
            "Conversion Value": "$7,704",
            "Cost per Result": "$64.34"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "371367",
            "Clicks": "3079",
            "Conversions": "40",
            "Conversion Value": "$5,259",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "228298",
            "Clicks": "4882",
            "Conversions": "88",
            "Conversion Value": "$10,963",
            "Cost per Result": "$61.06"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "303895",
            "Clicks": "2761",
            "Conversions": "71",
            "Conversion Value": "$4,634",
            "Cost per Result": "$48.35"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "124254",
            "Clicks": "4386",
            "Conversions": "63",
            "Conversion Value": "$10,171",
            "Cost per Result": "$40.07"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "262603",
            "Clicks": "6574",
            "Conversions": "53",
            "Conversion Value": "$6,052",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "125030",
            "Clicks": "5513",
            "Conversions": "80",
            "Conversion Value": "$12,427",
            "Cost per Result": "$16.42"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "196594",
            "Clicks": "4952",
            "Conversions": "80",
            "Conversion Value": "$9,140",
            "Cost per Result": "$41.40"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "307277",
            "Clicks": "6392",
            "Conversions": "63",
            "Conversion Value": "$11,599",
            "Cost per Result": "$45.15"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "401736",
            "Clicks": "3884",
            "Conversions": "62",
            "Conversion Value": "$4,389",
            "Cost per Result": "$22.49"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "467279",
            "Clicks": "4094",
            "Conversions": "61",
            "Conversion Value": "$14,588",
            "Cost per Result": "$16.88"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "304160",
            "Clicks": "4615",
            "Conversions": "50",
            "Conversion Value": "$10,806",
            "Cost per Result": "$20.24"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "386121",
            "Clicks": "6374",
            "Conversions": "41",
            "Conversion Value": "$4,078",
            "Cost per Result": "$62.53"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "338892",
            "Clicks": "5918",
            "Conversions": "73",
            "Conversion Value": "$10,249",
            "Cost per Result": "$60.74"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "309887",
            "Clicks": "3060",
            "Conversions": "76",
            "Conversion Value": "$9,108",
            "Cost per Result": "$44.30"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "135929",
            "Clicks": "4883",
            "Conversions": "50",
            "Conversion Value": "$12,490",
            "Cost per Result": "$37.10"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "369305",
            "Clicks": "6658",
            "Conversions": "65",
            "Conversion Value": "$5,140",
            "Cost per Result": "$35.78"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "157202",
            "Clicks": "4687",
            "Conversions": "86",
            "Conversion Value": "$5,305",
            "Cost per Result": "$59.39"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "295511",
            "Clicks": "2906",
            "Conversions": "61",
            "Conversion Value": "$10,295",
            "Cost per Result": "$46.43"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "145770",
            "Clicks": "2951",
            "Conversions": "50",
            "Conversion Value": "$11,098",
            "Cost per Result": "$25.93"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "111946",
            "Clicks": "4226",
            "Conversions": "43",
            "Conversion Value": "$7,361",
            "Cost per Result": "$62.45"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "388341",
            "Clicks": "3876",
            "Conversions": "52",
            "Conversion Value": "$4,476",
            "Cost per Result": "$51.81"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "447126",
            "Clicks": "6183",
            "Conversions": "42",
            "Conversion Value": "$14,990",
            "Cost per Result": "$63.32"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "244572",
            "Clicks": "2869",
            "Conversions": "55",
            "Conversion Value": "$7,215",
            "Cost per Result": "$18.40"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "375844",
            "Clicks": "5925",
            "Conversions": "69",
            "Conversion Value": "$12,816",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "209029",
            "Clicks": "5808",
            "Conversions": "42",
            "Conversion Value": "$15,260",
            "Cost per Result": "$15.46"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "320176",
            "Clicks": "2337",
            "Conversions": "54",
            "Conversion Value": "$10,410",
            "Cost per Result": "$38.58"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "347558",
            "Clicks": "5515",
            "Conversions": "54",
            "Conversion Value": "$12,681",
            "Cost per Result": "$48.54"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "295493",
            "Clicks": "5903",
            "Conversions": "77",
            "Conversion Value": "$12,996",
            "Cost per Result": "$29.63"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "248979",
            "Clicks": "2664",
            "Conversions": "80",
            "Conversion Value": "$9,235",
            "Cost per Result": "$42.91"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "246109",
            "Clicks": "3777",
            "Conversions": "89",
            "Conversion Value": "$5,423",
            "Cost per Result": "$31.79"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "271477",
            "Clicks": "3863",
            "Conversions": "90",
            "Conversion Value": "$9,558",
            "Cost per Result": "$54.96"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "261670",
            "Clicks": "6780",
            "Conversions": "51",
            "Conversion Value": "$5,418",
            "Cost per Result": "$63.44"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "413022",
            "Clicks": "3484",
            "Conversions": "56",
            "Conversion Value": "$14,935",
            "Cost per Result": "$43.14"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "186941",
            "Clicks": "2954",
            "Conversions": "45",
            "Conversion Value": "$6,610",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "225230",
            "Clicks": "2252",
            "Conversions": "60",
            "Conversion Value": "$11,571",
            "Cost per Result": "$20.89"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "328351",
            "Clicks": "5555",
            "Conversions": "43",
            "Conversion Value": "$6,208",
            "Cost per Result": "$28.55"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "204941",
            "Clicks": "2227",
            "Conversions": "83",
            "Conversion Value": "$4,524",
            "Cost per Result": "$26.88"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "420883",
            "Clicks": "6192",
            "Conversions": "49",
            "Conversion Value": "$13,617",
            "Cost per Result": "$42.29"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "278529",
            "Clicks": "6571",
            "Conversions": "69",
            "Conversion Value": "$6,919",
            "Cost per Result": "$25.56"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "255394",
            "Clicks": "3707",
            "Conversions": "66",
            "Conversion Value": "$15,273",
            "Cost per Result": "$58.93"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "337783",
            "Clicks": "6158",
            "Conversions": "56",
            "Conversion Value": "$10,353",
            "Cost per Result": "$32.32"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "291948",
            "Clicks": "2180",
            "Conversions": "58",
            "Conversion Value": "$12,928",
            "Cost per Result": "$26.96"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "346677",
            "Clicks": "3239",
            "Conversions": "51",
            "Conversion Value": "$11,745",
            "Cost per Result": "$15.23"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "218524",
            "Clicks": "3311",
            "Conversions": "55",
            "Conversion Value": "$5,493",
            "Cost per Result": "$51.37"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "325185",
            "Clicks": "2912",
            "Conversions": "78",
            "Conversion Value": "$15,393",
            "Cost per Result": "$26.99"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "381862",
            "Clicks": "6802",
            "Conversions": "65",
            "Conversion Value": "$13,087",
            "Cost per Result": "$60.14"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "195577",
            "Clicks": "3372",
            "Conversions": "80",
            "Conversion Value": "$8,140",
            "Cost per Result": "$38.82"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "249824",
            "Clicks": "2217",
            "Conversions": "73",
            "Conversion Value": "$11,469",
            "Cost per Result": "$39.28"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "470541",
            "Clicks": "3855",
            "Conversions": "64",
            "Conversion Value": "$9,959",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "100466",
            "Clicks": "5974",
            "Conversions": "52",
            "Conversion Value": "$15,767",
            "Cost per Result": "$46.27"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "149623",
            "Clicks": "4747",
            "Conversions": "44",
            "Conversion Value": "$15,661",
            "Cost per Result": "$30.62"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "416077",
            "Clicks": "6528",
            "Conversions": "70",
            "Conversion Value": "$10,862",
            "Cost per Result": "$38.51"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "205561",
            "Clicks": "5780",
            "Conversions": "51",
            "Conversion Value": "$15,826",
            "Cost per Result": "$60.40"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "441222",
            "Clicks": "4753",
            "Conversions": "65",
            "Conversion Value": "$7,733",
            "Cost per Result": "$58.55"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "376083",
            "Clicks": "3085",
            "Conversions": "45",
            "Conversion Value": "$6,716",
            "Cost per Result": "$62.48"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "198909",
            "Clicks": "2659",
            "Conversions": "43",
            "Conversion Value": "$10,290",
            "Cost per Result": "$62.07"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "477095",
            "Clicks": "2555",
            "Conversions": "49",
            "Conversion Value": "$7,947",
            "Cost per Result": "$50.22"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "111282",
            "Clicks": "3770",
            "Conversions": "84",
            "Conversion Value": "$12,883",
            "Cost per Result": "$19.06"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "223166",
            "Clicks": "5652",
            "Conversions": "77",
            "Conversion Value": "$7,993",
            "Cost per Result": "$28.48"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "109895",
            "Clicks": "4252",
            "Conversions": "86",
            "Conversion Value": "$11,977",
            "Cost per Result": "$37.15"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "240784",
            "Clicks": "3069",
            "Conversions": "79",
            "Conversion Value": "$9,510",
            "Cost per Result": "$48.47"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "421040",
            "Clicks": "3418",
            "Conversions": "87",
            "Conversion Value": "$8,935",
            "Cost per Result": "$48.65"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "304936",
            "Clicks": "4392",
            "Conversions": "75",
            "Conversion Value": "$12,254",
            "Cost per Result": "$18.26"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "455335",
            "Clicks": "6080",
            "Conversions": "42",
            "Conversion Value": "$10,980",
            "Cost per Result": "$59.01"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "492457",
            "Clicks": "6699",
            "Conversions": "63",
            "Conversion Value": "$9,846",
            "Cost per Result": "$39.49"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "205868",
            "Clicks": "6900",
            "Conversions": "75",
            "Conversion Value": "$14,213",
            "Cost per Result": "$60.98"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "454761",
            "Clicks": "2648",
            "Conversions": "49",
            "Conversion Value": "$5,377",
            "Cost per Result": "$28.81"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "159633",
            "Clicks": "2881",
            "Conversions": "63",
            "Conversion Value": "$7,865",
            "Cost per Result": "$30.74"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "452873",
            "Clicks": "2586",
            "Conversions": "49",
            "Conversion Value": "$13,018",
            "Cost per Result": "$39.85"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "253266",
            "Clicks": "5365",
            "Conversions": "68",
            "Conversion Value": "$14,115",
            "Cost per Result": "$15.86"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "310011",
            "Clicks": "4009",
            "Conversions": "69",
            "Conversion Value": "$8,445",
            "Cost per Result": "$60.80"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "262826",
            "Clicks": "5742",
            "Conversions": "68",
            "Conversion Value": "$10,055",
            "Cost per Result": "$17.32"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "318734",
            "Clicks": "4207",
            "Conversions": "42",
            "Conversion Value": "$7,444",
            "Cost per Result": "$27.07"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "421408",
            "Clicks": "6876",
            "Conversions": "47",
            "Conversion Value": "$12,508",
            "Cost per Result": "$26.78"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "144232",
            "Clicks": "3971",
            "Conversions": "58",
            "Conversion Value": "$7,949",
            "Cost per Result": "$42.32"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "126894",
            "Clicks": "2252",
            "Conversions": "46",
            "Conversion Value": "$4,858",
            "Cost per Result": "$35.08"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "202629",
            "Clicks": "6035",
            "Conversions": "40",
            "Conversion Value": "$5,967",
            "Cost per Result": "$24.51"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "260570",
            "Clicks": "5888",
            "Conversions": "69",
            "Conversion Value": "$8,157",
            "Cost per Result": "$36.04"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "429021",
            "Clicks": "4949",
            "Conversions": "76",
            "Conversion Value": "$10,881",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "317236",
            "Clicks": "4161",
            "Conversions": "60",
            "Conversion Value": "$14,306",
            "Cost per Result": "$54.93"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "306838",
            "Clicks": "4692",
            "Conversions": "63",
            "Conversion Value": "$5,069",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "286740",
            "Clicks": "6243",
            "Conversions": "88",
            "Conversion Value": "$6,673",
            "Cost per Result": "$29.25"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "286364",
            "Clicks": "2580",
            "Conversions": "53",
            "Conversion Value": "$15,496",
            "Cost per Result": "$26.16"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "169724",
            "Clicks": "2663",
            "Conversions": "87",
            "Conversion Value": "$10,453",
            "Cost per Result": "$18.79"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "210708",
            "Clicks": "2560",
            "Conversions": "80",
            "Conversion Value": "$11,701",
            "Cost per Result": "$29.79"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "107141",
            "Clicks": "3414",
            "Conversions": "49",
            "Conversion Value": "$12,151",
            "Cost per Result": "$57.54"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "283224",
            "Clicks": "6437",
            "Conversions": "87",
            "Conversion Value": "$4,975",
            "Cost per Result": "$17.18"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "210460",
            "Clicks": "6788",
            "Conversions": "59",
            "Conversion Value": "$11,675",
            "Cost per Result": "$53.92"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "198557",
            "Clicks": "2720",
            "Conversions": "51",
            "Conversion Value": "$10,300",
            "Cost per Result": "$55.70"
          }
        ]
      },
      "DEXUsersData": {
        "name": "DEX Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "449134",
            "Clicks": "12886",
            "Conversions": "105",
            "Conversion Value": "$30,488",
            "Cost per Result": "$21.70"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "599215",
            "Clicks": "6738",
            "Conversions": "80",
            "Conversion Value": "$23,762",
            "Cost per Result": "$63.52"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "450071",
            "Clicks": "5475",
            "Conversions": "97",
            "Conversion Value": "$23,170",
            "Cost per Result": "$18.92"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "401733",
            "Clicks": "5344",
            "Conversions": "99",
            "Conversion Value": "$11,610",
            "Cost per Result": "$51.44"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "921673",
            "Clicks": "5568",
            "Conversions": "116",
            "Conversion Value": "$23,435",
            "Cost per Result": "$48.02"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "954952",
            "Clicks": "10467",
            "Conversions": "151",
            "Conversion Value": "$31,926",
            "Cost per Result": "$61.95"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "345214",
            "Clicks": "8222",
            "Conversions": "153",
            "Conversion Value": "$30,295",
            "Cost per Result": "$28.97"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "808562",
            "Clicks": "6303",
            "Conversions": "103",
            "Conversion Value": "$29,802",
            "Cost per Result": "$26.35"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "492248",
            "Clicks": "11454",
            "Conversions": "170",
            "Conversion Value": "$12,595",
            "Cost per Result": "$49.16"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "821946",
            "Clicks": "9940",
            "Conversions": "102",
            "Conversion Value": "$25,156",
            "Cost per Result": "$29.62"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "343359",
            "Clicks": "11664",
            "Conversions": "101",
            "Conversion Value": "$25,466",
            "Cost per Result": "$34.54"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "637932",
            "Clicks": "8197",
            "Conversions": "109",
            "Conversion Value": "$12,847",
            "Cost per Result": "$35.85"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "543223",
            "Clicks": "6923",
            "Conversions": "127",
            "Conversion Value": "$13,773",
            "Cost per Result": "$16.85"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "518083",
            "Clicks": "11889",
            "Conversions": "116",
            "Conversion Value": "$18,775",
            "Cost per Result": "$31.19"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "602623",
            "Clicks": "11856",
            "Conversions": "152",
            "Conversion Value": "$19,658",
            "Cost per Result": "$20.04"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "242624",
            "Clicks": "13577",
            "Conversions": "116",
            "Conversion Value": "$26,624",
            "Cost per Result": "$50.90"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "463818",
            "Clicks": "9994",
            "Conversions": "169",
            "Conversion Value": "$31,395",
            "Cost per Result": "$35.54"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "471394",
            "Clicks": "7989",
            "Conversions": "154",
            "Conversion Value": "$17,849",
            "Cost per Result": "$19.56"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "931283",
            "Clicks": "7369",
            "Conversions": "112",
            "Conversion Value": "$24,894",
            "Cost per Result": "$43.86"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "352703",
            "Clicks": "5550",
            "Conversions": "142",
            "Conversion Value": "$8,215",
            "Cost per Result": "$41.49"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "698343",
            "Clicks": "7019",
            "Conversions": "120",
            "Conversion Value": "$17,435",
            "Cost per Result": "$36.98"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "499454",
            "Clicks": "7629",
            "Conversions": "179",
            "Conversion Value": "$30,584",
            "Cost per Result": "$29.34"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "547644",
            "Clicks": "8404",
            "Conversions": "164",
            "Conversion Value": "$22,347",
            "Cost per Result": "$60.94"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "283081",
            "Clicks": "9434",
            "Conversions": "122",
            "Conversion Value": "$15,661",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "457802",
            "Clicks": "8621",
            "Conversions": "105",
            "Conversion Value": "$18,293",
            "Cost per Result": "$36.69"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "995611",
            "Clicks": "11939",
            "Conversions": "117",
            "Conversion Value": "$29,589",
            "Cost per Result": "$25.38"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "310922",
            "Clicks": "6839",
            "Conversions": "180",
            "Conversion Value": "$17,082",
            "Cost per Result": "$52.92"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "580241",
            "Clicks": "5448",
            "Conversions": "142",
            "Conversion Value": "$14,390",
            "Cost per Result": "$20.51"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "979092",
            "Clicks": "7653",
            "Conversions": "101",
            "Conversion Value": "$27,164",
            "Cost per Result": "$52.20"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "425946",
            "Clicks": "13636",
            "Conversions": "93",
            "Conversion Value": "$30,651",
            "Cost per Result": "$23.02"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "298356",
            "Clicks": "8853",
            "Conversions": "86",
            "Conversion Value": "$10,419",
            "Cost per Result": "$38.19"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "968684",
            "Clicks": "11097",
            "Conversions": "96",
            "Conversion Value": "$28,839",
            "Cost per Result": "$44.63"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "954255",
            "Clicks": "10416",
            "Conversions": "159",
            "Conversion Value": "$18,016",
            "Cost per Result": "$51.62"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "460468",
            "Clicks": "9221",
            "Conversions": "143",
            "Conversion Value": "$27,594",
            "Cost per Result": "$62.84"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "954541",
            "Clicks": "12895",
            "Conversions": "104",
            "Conversion Value": "$25,820",
            "Cost per Result": "$46.33"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "689699",
            "Clicks": "12706",
            "Conversions": "145",
            "Conversion Value": "$10,114",
            "Cost per Result": "$23.86"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "315581",
            "Clicks": "8626",
            "Conversions": "152",
            "Conversion Value": "$30,530",
            "Cost per Result": "$18.68"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "206489",
            "Clicks": "12299",
            "Conversions": "161",
            "Conversion Value": "$27,534",
            "Cost per Result": "$40.38"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "814781",
            "Clicks": "9668",
            "Conversions": "82",
            "Conversion Value": "$11,805",
            "Cost per Result": "$16.94"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "582106",
            "Clicks": "11810",
            "Conversions": "164",
            "Conversion Value": "$17,648",
            "Cost per Result": "$37.28"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "750592",
            "Clicks": "13703",
            "Conversions": "156",
            "Conversion Value": "$19,390",
            "Cost per Result": "$46.98"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "381745",
            "Clicks": "9418",
            "Conversions": "157",
            "Conversion Value": "$25,288",
            "Cost per Result": "$60.42"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "405020",
            "Clicks": "7554",
            "Conversions": "172",
            "Conversion Value": "$27,874",
            "Cost per Result": "$23.06"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "936616",
            "Clicks": "13543",
            "Conversions": "129",
            "Conversion Value": "$28,595",
            "Cost per Result": "$45.63"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "534223",
            "Clicks": "6814",
            "Conversions": "112",
            "Conversion Value": "$28,098",
            "Cost per Result": "$41.67"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "459289",
            "Clicks": "12711",
            "Conversions": "146",
            "Conversion Value": "$22,918",
            "Cost per Result": "$56.13"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "746687",
            "Clicks": "6391",
            "Conversions": "95",
            "Conversion Value": "$28,458",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "662523",
            "Clicks": "11019",
            "Conversions": "168",
            "Conversion Value": "$9,362",
            "Cost per Result": "$64.07"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "303851",
            "Clicks": "7849",
            "Conversions": "126",
            "Conversion Value": "$24,977",
            "Cost per Result": "$61.97"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "835769",
            "Clicks": "6826",
            "Conversions": "108",
            "Conversion Value": "$12,334",
            "Cost per Result": "$35.83"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "345581",
            "Clicks": "4947",
            "Conversions": "149",
            "Conversion Value": "$19,937",
            "Cost per Result": "$62.80"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "437442",
            "Clicks": "11785",
            "Conversions": "137",
            "Conversion Value": "$8,940",
            "Cost per Result": "$35.01"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "466019",
            "Clicks": "13944",
            "Conversions": "166",
            "Conversion Value": "$31,360",
            "Cost per Result": "$31.11"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "319175",
            "Clicks": "11175",
            "Conversions": "121",
            "Conversion Value": "$11,607",
            "Cost per Result": "$43.89"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "219731",
            "Clicks": "12515",
            "Conversions": "171",
            "Conversion Value": "$24,305",
            "Cost per Result": "$33.04"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "867861",
            "Clicks": "9887",
            "Conversions": "152",
            "Conversion Value": "$11,837",
            "Cost per Result": "$53.44"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "812056",
            "Clicks": "13573",
            "Conversions": "170",
            "Conversion Value": "$10,897",
            "Cost per Result": "$23.72"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "301751",
            "Clicks": "12354",
            "Conversions": "84",
            "Conversion Value": "$11,096",
            "Cost per Result": "$47.69"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "821874",
            "Clicks": "13110",
            "Conversions": "120",
            "Conversion Value": "$12,542",
            "Cost per Result": "$30.87"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "670288",
            "Clicks": "9799",
            "Conversions": "178",
            "Conversion Value": "$23,219",
            "Cost per Result": "$16.72"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "633036",
            "Clicks": "5293",
            "Conversions": "153",
            "Conversion Value": "$30,794",
            "Cost per Result": "$62.32"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "478201",
            "Clicks": "5745",
            "Conversions": "137",
            "Conversion Value": "$27,714",
            "Cost per Result": "$39.75"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "838057",
            "Clicks": "11478",
            "Conversions": "172",
            "Conversion Value": "$10,842",
            "Cost per Result": "$36.91"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "289822",
            "Clicks": "6563",
            "Conversions": "151",
            "Conversion Value": "$22,473",
            "Cost per Result": "$17.77"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "762818",
            "Clicks": "11224",
            "Conversions": "90",
            "Conversion Value": "$10,205",
            "Cost per Result": "$15.41"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "888940",
            "Clicks": "9782",
            "Conversions": "170",
            "Conversion Value": "$16,593",
            "Cost per Result": "$22.53"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "994603",
            "Clicks": "13773",
            "Conversions": "96",
            "Conversion Value": "$29,795",
            "Cost per Result": "$42.36"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "805008",
            "Clicks": "9822",
            "Conversions": "147",
            "Conversion Value": "$13,725",
            "Cost per Result": "$23.15"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "341171",
            "Clicks": "4057",
            "Conversions": "93",
            "Conversion Value": "$26,385",
            "Cost per Result": "$40.30"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "212620",
            "Clicks": "11101",
            "Conversions": "148",
            "Conversion Value": "$21,160",
            "Cost per Result": "$23.49"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "981464",
            "Clicks": "12144",
            "Conversions": "124",
            "Conversion Value": "$24,213",
            "Cost per Result": "$49.08"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "424822",
            "Clicks": "8118",
            "Conversions": "130",
            "Conversion Value": "$22,940",
            "Cost per Result": "$27.84"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "677303",
            "Clicks": "13027",
            "Conversions": "170",
            "Conversion Value": "$31,139",
            "Cost per Result": "$52.04"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "601702",
            "Clicks": "13947",
            "Conversions": "135",
            "Conversion Value": "$15,055",
            "Cost per Result": "$19.97"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "800788",
            "Clicks": "11736",
            "Conversions": "99",
            "Conversion Value": "$31,759",
            "Cost per Result": "$18.08"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "965769",
            "Clicks": "13851",
            "Conversions": "84",
            "Conversion Value": "$29,066",
            "Cost per Result": "$54.05"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "407344",
            "Clicks": "8579",
            "Conversions": "93",
            "Conversion Value": "$26,944",
            "Cost per Result": "$28.89"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "549374",
            "Clicks": "12537",
            "Conversions": "167",
            "Conversion Value": "$26,566",
            "Cost per Result": "$36.35"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "875290",
            "Clicks": "10603",
            "Conversions": "120",
            "Conversion Value": "$9,610",
            "Cost per Result": "$58.37"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "403956",
            "Clicks": "8548",
            "Conversions": "151",
            "Conversion Value": "$15,269",
            "Cost per Result": "$64.85"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "577680",
            "Clicks": "7747",
            "Conversions": "95",
            "Conversion Value": "$31,605",
            "Cost per Result": "$31.34"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "895682",
            "Clicks": "10860",
            "Conversions": "85",
            "Conversion Value": "$18,185",
            "Cost per Result": "$61.00"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "501793",
            "Clicks": "7940",
            "Conversions": "113",
            "Conversion Value": "$18,348",
            "Cost per Result": "$28.45"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "929204",
            "Clicks": "12759",
            "Conversions": "81",
            "Conversion Value": "$9,647",
            "Cost per Result": "$42.11"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "949239",
            "Clicks": "12201",
            "Conversions": "165",
            "Conversion Value": "$26,589",
            "Cost per Result": "$18.31"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "325192",
            "Clicks": "10358",
            "Conversions": "98",
            "Conversion Value": "$12,640",
            "Cost per Result": "$16.87"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "911121",
            "Clicks": "13658",
            "Conversions": "174",
            "Conversion Value": "$18,869",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "885129",
            "Clicks": "4794",
            "Conversions": "131",
            "Conversion Value": "$16,884",
            "Cost per Result": "$34.78"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "506186",
            "Clicks": "8777",
            "Conversions": "157",
            "Conversion Value": "$9,228",
            "Cost per Result": "$39.52"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "378141",
            "Clicks": "12326",
            "Conversions": "103",
            "Conversion Value": "$8,183",
            "Cost per Result": "$57.36"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "896249",
            "Clicks": "12830",
            "Conversions": "160",
            "Conversion Value": "$27,559",
            "Cost per Result": "$32.70"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "361293",
            "Clicks": "7795",
            "Conversions": "107",
            "Conversion Value": "$27,157",
            "Cost per Result": "$15.34"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "665716",
            "Clicks": "7949",
            "Conversions": "94",
            "Conversion Value": "$20,709",
            "Cost per Result": "$17.25"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "891453",
            "Clicks": "8470",
            "Conversions": "99",
            "Conversion Value": "$30,140",
            "Cost per Result": "$48.80"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "896459",
            "Clicks": "11837",
            "Conversions": "106",
            "Conversion Value": "$29,056",
            "Cost per Result": "$37.95"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "410464",
            "Clicks": "5146",
            "Conversions": "125",
            "Conversion Value": "$10,181",
            "Cost per Result": "$42.03"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "223922",
            "Clicks": "9100",
            "Conversions": "174",
            "Conversion Value": "$8,328",
            "Cost per Result": "$54.41"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "905618",
            "Clicks": "10076",
            "Conversions": "164",
            "Conversion Value": "$30,680",
            "Cost per Result": "$41.97"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "775990",
            "Clicks": "9107",
            "Conversions": "129",
            "Conversion Value": "$10,896",
            "Cost per Result": "$34.40"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "496087",
            "Clicks": "10142",
            "Conversions": "98",
            "Conversion Value": "$16,636",
            "Cost per Result": "$59.34"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "236028",
            "Clicks": "12776",
            "Conversions": "129",
            "Conversion Value": "$9,771",
            "Cost per Result": "$18.23"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "217919",
            "Clicks": "13454",
            "Conversions": "155",
            "Conversion Value": "$10,514",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "911462",
            "Clicks": "12300",
            "Conversions": "101",
            "Conversion Value": "$15,931",
            "Cost per Result": "$63.43"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "232123",
            "Clicks": "6985",
            "Conversions": "159",
            "Conversion Value": "$16,250",
            "Cost per Result": "$31.88"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "817513",
            "Clicks": "9116",
            "Conversions": "135",
            "Conversion Value": "$19,141",
            "Cost per Result": "$50.71"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "532021",
            "Clicks": "6981",
            "Conversions": "99",
            "Conversion Value": "$30,614",
            "Cost per Result": "$63.30"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "403140",
            "Clicks": "5492",
            "Conversions": "89",
            "Conversion Value": "$30,828",
            "Cost per Result": "$35.96"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "959397",
            "Clicks": "6687",
            "Conversions": "156",
            "Conversion Value": "$13,744",
            "Cost per Result": "$45.46"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "665959",
            "Clicks": "8587",
            "Conversions": "164",
            "Conversion Value": "$17,311",
            "Cost per Result": "$47.22"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "548483",
            "Clicks": "12911",
            "Conversions": "165",
            "Conversion Value": "$13,327",
            "Cost per Result": "$62.66"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "650896",
            "Clicks": "4467",
            "Conversions": "158",
            "Conversion Value": "$10,115",
            "Cost per Result": "$16.94"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "209657",
            "Clicks": "13256",
            "Conversions": "82",
            "Conversion Value": "$12,320",
            "Cost per Result": "$23.94"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "741753",
            "Clicks": "5810",
            "Conversions": "146",
            "Conversion Value": "$14,649",
            "Cost per Result": "$24.96"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "760292",
            "Clicks": "5356",
            "Conversions": "168",
            "Conversion Value": "$16,203",
            "Cost per Result": "$18.67"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "786462",
            "Clicks": "5570",
            "Conversions": "136",
            "Conversion Value": "$31,119",
            "Cost per Result": "$37.90"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "840590",
            "Clicks": "12672",
            "Conversions": "124",
            "Conversion Value": "$19,798",
            "Cost per Result": "$53.22"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "409003",
            "Clicks": "9796",
            "Conversions": "155",
            "Conversion Value": "$10,439",
            "Cost per Result": "$21.26"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "362018",
            "Clicks": "4374",
            "Conversions": "89",
            "Conversion Value": "$22,526",
            "Cost per Result": "$52.71"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "729420",
            "Clicks": "13744",
            "Conversions": "130",
            "Conversion Value": "$13,799",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "582018",
            "Clicks": "9584",
            "Conversions": "128",
            "Conversion Value": "$18,541",
            "Cost per Result": "$21.11"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "626367",
            "Clicks": "4235",
            "Conversions": "116",
            "Conversion Value": "$16,370",
            "Cost per Result": "$19.84"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "550754",
            "Clicks": "4920",
            "Conversions": "116",
            "Conversion Value": "$14,061",
            "Cost per Result": "$64.40"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "735093",
            "Clicks": "8349",
            "Conversions": "169",
            "Conversion Value": "$20,884",
            "Cost per Result": "$40.65"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "515601",
            "Clicks": "4392",
            "Conversions": "155",
            "Conversion Value": "$15,177",
            "Cost per Result": "$27.61"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "890734",
            "Clicks": "9601",
            "Conversions": "97",
            "Conversion Value": "$25,224",
            "Cost per Result": "$50.14"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "791509",
            "Clicks": "8573",
            "Conversions": "90",
            "Conversion Value": "$29,687",
            "Cost per Result": "$18.13"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "828313",
            "Clicks": "11175",
            "Conversions": "148",
            "Conversion Value": "$21,850",
            "Cost per Result": "$33.80"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "617347",
            "Clicks": "6470",
            "Conversions": "80",
            "Conversion Value": "$13,674",
            "Cost per Result": "$41.16"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "974999",
            "Clicks": "4783",
            "Conversions": "140",
            "Conversion Value": "$14,412",
            "Cost per Result": "$40.40"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "939281",
            "Clicks": "5432",
            "Conversions": "168",
            "Conversion Value": "$28,578",
            "Cost per Result": "$52.77"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "267814",
            "Clicks": "9609",
            "Conversions": "102",
            "Conversion Value": "$31,203",
            "Cost per Result": "$19.54"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "924285",
            "Clicks": "7603",
            "Conversions": "176",
            "Conversion Value": "$23,708",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "664453",
            "Clicks": "9744",
            "Conversions": "129",
            "Conversion Value": "$13,287",
            "Cost per Result": "$30.86"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "854538",
            "Clicks": "13323",
            "Conversions": "175",
            "Conversion Value": "$20,301",
            "Cost per Result": "$56.94"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "961797",
            "Clicks": "9403",
            "Conversions": "128",
            "Conversion Value": "$13,148",
            "Cost per Result": "$51.77"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "543684",
            "Clicks": "13450",
            "Conversions": "114",
            "Conversion Value": "$17,303",
            "Cost per Result": "$30.34"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "284569",
            "Clicks": "12225",
            "Conversions": "149",
            "Conversion Value": "$29,190",
            "Cost per Result": "$16.95"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "318395",
            "Clicks": "11553",
            "Conversions": "169",
            "Conversion Value": "$8,643",
            "Cost per Result": "$58.74"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "755949",
            "Clicks": "5662",
            "Conversions": "150",
            "Conversion Value": "$25,149",
            "Cost per Result": "$55.99"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "405246",
            "Clicks": "9791",
            "Conversions": "126",
            "Conversion Value": "$16,599",
            "Cost per Result": "$61.55"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "226283",
            "Clicks": "10957",
            "Conversions": "158",
            "Conversion Value": "$11,949",
            "Cost per Result": "$45.90"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "848282",
            "Clicks": "6868",
            "Conversions": "111",
            "Conversion Value": "$13,214",
            "Cost per Result": "$37.05"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "341052",
            "Clicks": "7748",
            "Conversions": "139",
            "Conversion Value": "$24,015",
            "Cost per Result": "$43.19"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "563695",
            "Clicks": "4649",
            "Conversions": "140",
            "Conversion Value": "$10,149",
            "Cost per Result": "$31.44"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "513118",
            "Clicks": "4997",
            "Conversions": "142",
            "Conversion Value": "$8,094",
            "Cost per Result": "$32.21"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "634442",
            "Clicks": "9965",
            "Conversions": "172",
            "Conversion Value": "$22,819",
            "Cost per Result": "$21.37"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "378027",
            "Clicks": "13615",
            "Conversions": "134",
            "Conversion Value": "$20,063",
            "Cost per Result": "$20.20"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "591263",
            "Clicks": "6995",
            "Conversions": "121",
            "Conversion Value": "$26,532",
            "Cost per Result": "$48.16"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "517793",
            "Clicks": "13832",
            "Conversions": "125",
            "Conversion Value": "$22,280",
            "Cost per Result": "$39.00"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "662137",
            "Clicks": "11924",
            "Conversions": "102",
            "Conversion Value": "$21,301",
            "Cost per Result": "$58.60"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "514073",
            "Clicks": "11800",
            "Conversions": "84",
            "Conversion Value": "$13,545",
            "Cost per Result": "$39.35"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "556568",
            "Clicks": "7655",
            "Conversions": "164",
            "Conversion Value": "$18,087",
            "Cost per Result": "$40.38"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "624253",
            "Clicks": "5713",
            "Conversions": "117",
            "Conversion Value": "$24,503",
            "Cost per Result": "$42.24"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "953945",
            "Clicks": "11299",
            "Conversions": "142",
            "Conversion Value": "$10,040",
            "Cost per Result": "$37.88"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "464358",
            "Clicks": "4731",
            "Conversions": "118",
            "Conversion Value": "$14,923",
            "Cost per Result": "$63.81"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "268429",
            "Clicks": "8323",
            "Conversions": "152",
            "Conversion Value": "$8,500",
            "Cost per Result": "$43.27"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "744302",
            "Clicks": "12334",
            "Conversions": "145",
            "Conversion Value": "$17,392",
            "Cost per Result": "$54.58"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "761093",
            "Clicks": "4230",
            "Conversions": "108",
            "Conversion Value": "$23,471",
            "Cost per Result": "$29.66"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "459467",
            "Clicks": "5336",
            "Conversions": "102",
            "Conversion Value": "$28,729",
            "Cost per Result": "$22.77"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "270190",
            "Clicks": "10091",
            "Conversions": "161",
            "Conversion Value": "$19,455",
            "Cost per Result": "$48.91"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "736343",
            "Clicks": "6364",
            "Conversions": "162",
            "Conversion Value": "$28,446",
            "Cost per Result": "$33.94"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "652279",
            "Clicks": "11893",
            "Conversions": "108",
            "Conversion Value": "$14,960",
            "Cost per Result": "$60.29"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "909604",
            "Clicks": "10012",
            "Conversions": "154",
            "Conversion Value": "$17,658",
            "Cost per Result": "$42.38"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "302147",
            "Clicks": "13823",
            "Conversions": "147",
            "Conversion Value": "$21,993",
            "Cost per Result": "$36.26"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "536987",
            "Clicks": "4691",
            "Conversions": "86",
            "Conversion Value": "$29,318",
            "Cost per Result": "$39.41"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "992983",
            "Clicks": "11913",
            "Conversions": "161",
            "Conversion Value": "$20,112",
            "Cost per Result": "$62.12"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "312346",
            "Clicks": "4955",
            "Conversions": "104",
            "Conversion Value": "$31,163",
            "Cost per Result": "$63.96"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "806730",
            "Clicks": "10837",
            "Conversions": "112",
            "Conversion Value": "$13,252",
            "Cost per Result": "$32.55"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "404567",
            "Clicks": "4199",
            "Conversions": "177",
            "Conversion Value": "$12,284",
            "Cost per Result": "$23.76"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "277782",
            "Clicks": "12932",
            "Conversions": "111",
            "Conversion Value": "$23,000",
            "Cost per Result": "$57.54"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "631502",
            "Clicks": "9577",
            "Conversions": "92",
            "Conversion Value": "$17,239",
            "Cost per Result": "$51.34"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "533082",
            "Clicks": "13901",
            "Conversions": "147",
            "Conversion Value": "$19,106",
            "Cost per Result": "$21.72"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "486910",
            "Clicks": "8886",
            "Conversions": "96",
            "Conversion Value": "$28,826",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "572740",
            "Clicks": "4412",
            "Conversions": "127",
            "Conversion Value": "$10,043",
            "Cost per Result": "$56.33"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "200416",
            "Clicks": "10242",
            "Conversions": "99",
            "Conversion Value": "$25,233",
            "Cost per Result": "$42.69"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "404754",
            "Clicks": "5301",
            "Conversions": "161",
            "Conversion Value": "$15,825",
            "Cost per Result": "$56.21"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "363950",
            "Clicks": "6756",
            "Conversions": "141",
            "Conversion Value": "$20,739",
            "Cost per Result": "$64.98"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "344436",
            "Clicks": "7007",
            "Conversions": "96",
            "Conversion Value": "$28,235",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "853860",
            "Clicks": "9964",
            "Conversions": "122",
            "Conversion Value": "$15,399",
            "Cost per Result": "$20.50"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "946224",
            "Clicks": "10921",
            "Conversions": "163",
            "Conversion Value": "$17,191",
            "Cost per Result": "$44.61"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "879096",
            "Clicks": "12467",
            "Conversions": "164",
            "Conversion Value": "$14,502",
            "Cost per Result": "$63.00"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "396777",
            "Clicks": "12304",
            "Conversions": "101",
            "Conversion Value": "$21,089",
            "Cost per Result": "$56.31"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "868397",
            "Clicks": "4834",
            "Conversions": "176",
            "Conversion Value": "$29,586",
            "Cost per Result": "$55.36"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "921008",
            "Clicks": "6640",
            "Conversions": "168",
            "Conversion Value": "$23,616",
            "Cost per Result": "$31.74"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "461510",
            "Clicks": "7020",
            "Conversions": "149",
            "Conversion Value": "$16,619",
            "Cost per Result": "$43.58"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "906224",
            "Clicks": "4634",
            "Conversions": "178",
            "Conversion Value": "$13,182",
            "Cost per Result": "$31.76"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "391932",
            "Clicks": "5929",
            "Conversions": "96",
            "Conversion Value": "$22,824",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "416159",
            "Clicks": "6161",
            "Conversions": "119",
            "Conversion Value": "$24,616",
            "Cost per Result": "$57.10"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "254775",
            "Clicks": "8922",
            "Conversions": "84",
            "Conversion Value": "$13,457",
            "Cost per Result": "$53.80"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "240616",
            "Clicks": "11767",
            "Conversions": "123",
            "Conversion Value": "$20,358",
            "Cost per Result": "$19.12"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "968897",
            "Clicks": "6253",
            "Conversions": "142",
            "Conversion Value": "$15,745",
            "Cost per Result": "$22.71"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "814967",
            "Clicks": "7374",
            "Conversions": "104",
            "Conversion Value": "$9,596",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "305646",
            "Clicks": "12188",
            "Conversions": "162",
            "Conversion Value": "$27,594",
            "Cost per Result": "$55.06"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "966317",
            "Clicks": "5756",
            "Conversions": "155",
            "Conversion Value": "$29,597",
            "Cost per Result": "$47.52"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "318327",
            "Clicks": "5875",
            "Conversions": "82",
            "Conversion Value": "$26,654",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "931701",
            "Clicks": "7800",
            "Conversions": "114",
            "Conversion Value": "$30,254",
            "Cost per Result": "$40.17"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "429947",
            "Clicks": "5325",
            "Conversions": "178",
            "Conversion Value": "$13,994",
            "Cost per Result": "$60.23"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "392902",
            "Clicks": "10022",
            "Conversions": "174",
            "Conversion Value": "$16,329",
            "Cost per Result": "$55.22"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "497873",
            "Clicks": "4815",
            "Conversions": "145",
            "Conversion Value": "$15,715",
            "Cost per Result": "$24.77"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "239310",
            "Clicks": "8275",
            "Conversions": "92",
            "Conversion Value": "$26,949",
            "Cost per Result": "$20.00"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "560880",
            "Clicks": "12005",
            "Conversions": "162",
            "Conversion Value": "$21,337",
            "Cost per Result": "$16.98"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "974194",
            "Clicks": "13796",
            "Conversions": "164",
            "Conversion Value": "$23,360",
            "Cost per Result": "$25.91"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "387544",
            "Clicks": "12486",
            "Conversions": "167",
            "Conversion Value": "$14,146",
            "Cost per Result": "$32.49"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "602969",
            "Clicks": "8075",
            "Conversions": "137",
            "Conversion Value": "$13,275",
            "Cost per Result": "$55.96"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "798340",
            "Clicks": "8496",
            "Conversions": "150",
            "Conversion Value": "$24,581",
            "Cost per Result": "$24.16"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "678914",
            "Clicks": "10495",
            "Conversions": "84",
            "Conversion Value": "$22,830",
            "Cost per Result": "$58.28"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "852790",
            "Clicks": "4517",
            "Conversions": "110",
            "Conversion Value": "$30,410",
            "Cost per Result": "$52.12"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "839419",
            "Clicks": "13970",
            "Conversions": "168",
            "Conversion Value": "$19,619",
            "Cost per Result": "$17.82"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "616089",
            "Clicks": "5173",
            "Conversions": "111",
            "Conversion Value": "$18,414",
            "Cost per Result": "$34.73"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "663824",
            "Clicks": "12270",
            "Conversions": "102",
            "Conversion Value": "$12,578",
            "Cost per Result": "$55.31"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "997289",
            "Clicks": "12676",
            "Conversions": "149",
            "Conversion Value": "$23,340",
            "Cost per Result": "$46.51"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "554093",
            "Clicks": "10303",
            "Conversions": "130",
            "Conversion Value": "$31,843",
            "Cost per Result": "$59.16"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "946939",
            "Clicks": "9383",
            "Conversions": "131",
            "Conversion Value": "$27,471",
            "Cost per Result": "$55.70"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "426699",
            "Clicks": "9024",
            "Conversions": "157",
            "Conversion Value": "$17,003",
            "Cost per Result": "$56.44"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "991609",
            "Clicks": "11210",
            "Conversions": "180",
            "Conversion Value": "$24,761",
            "Cost per Result": "$25.40"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "665032",
            "Clicks": "7162",
            "Conversions": "159",
            "Conversion Value": "$25,309",
            "Cost per Result": "$45.62"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "554352",
            "Clicks": "4781",
            "Conversions": "146",
            "Conversion Value": "$22,232",
            "Cost per Result": "$50.28"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "686042",
            "Clicks": "6524",
            "Conversions": "135",
            "Conversion Value": "$9,945",
            "Cost per Result": "$23.17"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "664671",
            "Clicks": "13472",
            "Conversions": "148",
            "Conversion Value": "$29,561",
            "Cost per Result": "$61.90"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "604612",
            "Clicks": "8904",
            "Conversions": "179",
            "Conversion Value": "$23,307",
            "Cost per Result": "$58.10"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "412183",
            "Clicks": "7917",
            "Conversions": "112",
            "Conversion Value": "$9,324",
            "Cost per Result": "$32.36"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "728969",
            "Clicks": "4878",
            "Conversions": "87",
            "Conversion Value": "$29,363",
            "Cost per Result": "$28.51"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "570738",
            "Clicks": "5945",
            "Conversions": "152",
            "Conversion Value": "$13,337",
            "Cost per Result": "$47.24"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "797191",
            "Clicks": "5885",
            "Conversions": "81",
            "Conversion Value": "$22,517",
            "Cost per Result": "$31.96"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "352526",
            "Clicks": "6245",
            "Conversions": "143",
            "Conversion Value": "$23,406",
            "Cost per Result": "$16.50"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "688233",
            "Clicks": "5617",
            "Conversions": "104",
            "Conversion Value": "$22,506",
            "Cost per Result": "$41.61"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "795432",
            "Clicks": "12897",
            "Conversions": "102",
            "Conversion Value": "$10,619",
            "Cost per Result": "$20.91"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "442733",
            "Clicks": "8112",
            "Conversions": "179",
            "Conversion Value": "$10,492",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "604956",
            "Clicks": "13042",
            "Conversions": "149",
            "Conversion Value": "$17,160",
            "Cost per Result": "$49.64"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "953864",
            "Clicks": "12892",
            "Conversions": "142",
            "Conversion Value": "$30,021",
            "Cost per Result": "$41.76"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "340057",
            "Clicks": "13815",
            "Conversions": "130",
            "Conversion Value": "$30,330",
            "Cost per Result": "$63.27"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "493589",
            "Clicks": "10697",
            "Conversions": "102",
            "Conversion Value": "$23,221",
            "Cost per Result": "$52.69"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "638689",
            "Clicks": "13748",
            "Conversions": "170",
            "Conversion Value": "$29,888",
            "Cost per Result": "$51.73"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "294465",
            "Clicks": "11894",
            "Conversions": "177",
            "Conversion Value": "$11,875",
            "Cost per Result": "$39.68"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "924303",
            "Clicks": "7489",
            "Conversions": "107",
            "Conversion Value": "$11,541",
            "Cost per Result": "$53.09"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "793557",
            "Clicks": "7514",
            "Conversions": "101",
            "Conversion Value": "$22,669",
            "Cost per Result": "$50.17"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "532509",
            "Clicks": "5166",
            "Conversions": "137",
            "Conversion Value": "$25,994",
            "Cost per Result": "$33.92"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "857721",
            "Clicks": "5488",
            "Conversions": "125",
            "Conversion Value": "$25,189",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "673149",
            "Clicks": "11165",
            "Conversions": "137",
            "Conversion Value": "$23,739",
            "Cost per Result": "$21.70"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "255453",
            "Clicks": "11244",
            "Conversions": "117",
            "Conversion Value": "$10,786",
            "Cost per Result": "$21.00"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "735565",
            "Clicks": "13628",
            "Conversions": "84",
            "Conversion Value": "$8,193",
            "Cost per Result": "$41.96"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "839180",
            "Clicks": "8290",
            "Conversions": "104",
            "Conversion Value": "$16,045",
            "Cost per Result": "$48.04"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "604635",
            "Clicks": "10537",
            "Conversions": "154",
            "Conversion Value": "$11,676",
            "Cost per Result": "$27.56"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "885399",
            "Clicks": "5112",
            "Conversions": "164",
            "Conversion Value": "$29,802",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "545423",
            "Clicks": "10745",
            "Conversions": "163",
            "Conversion Value": "$10,493",
            "Cost per Result": "$41.62"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "652973",
            "Clicks": "5901",
            "Conversions": "128",
            "Conversion Value": "$16,044",
            "Cost per Result": "$45.21"
          }
        ]
      },
      "CEXUsersData": {
        "name": "CEX Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "493490",
            "Clicks": "2969",
            "Conversions": "62",
            "Conversion Value": "$10,535",
            "Cost per Result": "$33.09"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "378419",
            "Clicks": "6162",
            "Conversions": "40",
            "Conversion Value": "$6,325",
            "Cost per Result": "$56.26"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "151407",
            "Clicks": "2260",
            "Conversions": "74",
            "Conversion Value": "$9,788",
            "Cost per Result": "$20.87"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "230551",
            "Clicks": "6978",
            "Conversions": "81",
            "Conversion Value": "$11,583",
            "Cost per Result": "$63.35"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "416988",
            "Clicks": "2736",
            "Conversions": "61",
            "Conversion Value": "$13,614",
            "Cost per Result": "$36.23"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "411203",
            "Clicks": "2197",
            "Conversions": "88",
            "Conversion Value": "$11,182",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "283526",
            "Clicks": "6286",
            "Conversions": "64",
            "Conversion Value": "$10,632",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "467532",
            "Clicks": "5522",
            "Conversions": "66",
            "Conversion Value": "$6,679",
            "Cost per Result": "$20.90"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "302663",
            "Clicks": "5450",
            "Conversions": "67",
            "Conversion Value": "$9,796",
            "Cost per Result": "$44.43"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "429148",
            "Clicks": "5963",
            "Conversions": "69",
            "Conversion Value": "$5,510",
            "Cost per Result": "$28.43"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "450500",
            "Clicks": "6594",
            "Conversions": "44",
            "Conversion Value": "$5,792",
            "Cost per Result": "$19.54"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "351682",
            "Clicks": "6956",
            "Conversions": "57",
            "Conversion Value": "$12,849",
            "Cost per Result": "$42.78"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "343391",
            "Clicks": "5179",
            "Conversions": "56",
            "Conversion Value": "$4,056",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "391123",
            "Clicks": "3361",
            "Conversions": "49",
            "Conversion Value": "$13,175",
            "Cost per Result": "$22.40"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "472121",
            "Clicks": "3736",
            "Conversions": "72",
            "Conversion Value": "$14,503",
            "Cost per Result": "$51.23"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "114051",
            "Clicks": "2198",
            "Conversions": "45",
            "Conversion Value": "$14,170",
            "Cost per Result": "$63.46"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "260745",
            "Clicks": "6215",
            "Conversions": "73",
            "Conversion Value": "$12,324",
            "Cost per Result": "$54.60"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "185690",
            "Clicks": "5456",
            "Conversions": "61",
            "Conversion Value": "$12,020",
            "Cost per Result": "$37.89"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "313523",
            "Clicks": "4647",
            "Conversions": "41",
            "Conversion Value": "$11,063",
            "Cost per Result": "$41.22"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "130253",
            "Clicks": "2393",
            "Conversions": "64",
            "Conversion Value": "$11,268",
            "Cost per Result": "$41.28"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "496662",
            "Clicks": "2604",
            "Conversions": "61",
            "Conversion Value": "$4,025",
            "Cost per Result": "$28.40"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "123816",
            "Clicks": "2044",
            "Conversions": "44",
            "Conversion Value": "$14,819",
            "Cost per Result": "$54.05"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "424032",
            "Clicks": "5835",
            "Conversions": "74",
            "Conversion Value": "$6,501",
            "Cost per Result": "$22.78"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "212259",
            "Clicks": "3771",
            "Conversions": "84",
            "Conversion Value": "$9,732",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "496656",
            "Clicks": "4770",
            "Conversions": "69",
            "Conversion Value": "$12,994",
            "Cost per Result": "$60.22"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "355565",
            "Clicks": "2743",
            "Conversions": "50",
            "Conversion Value": "$9,025",
            "Cost per Result": "$52.54"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "110013",
            "Clicks": "3828",
            "Conversions": "71",
            "Conversion Value": "$15,654",
            "Cost per Result": "$28.24"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "350882",
            "Clicks": "5120",
            "Conversions": "63",
            "Conversion Value": "$10,612",
            "Cost per Result": "$16.73"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "322742",
            "Clicks": "3474",
            "Conversions": "55",
            "Conversion Value": "$5,521",
            "Cost per Result": "$36.17"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "402594",
            "Clicks": "2863",
            "Conversions": "83",
            "Conversion Value": "$14,965",
            "Cost per Result": "$56.46"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "112961",
            "Clicks": "6766",
            "Conversions": "59",
            "Conversion Value": "$13,315",
            "Cost per Result": "$60.89"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "366605",
            "Clicks": "5165",
            "Conversions": "84",
            "Conversion Value": "$11,203",
            "Cost per Result": "$33.67"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "178432",
            "Clicks": "6536",
            "Conversions": "90",
            "Conversion Value": "$7,883",
            "Cost per Result": "$33.75"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "333030",
            "Clicks": "5821",
            "Conversions": "56",
            "Conversion Value": "$14,844",
            "Cost per Result": "$36.80"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "114378",
            "Clicks": "2527",
            "Conversions": "53",
            "Conversion Value": "$5,775",
            "Cost per Result": "$51.14"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "317387",
            "Clicks": "6032",
            "Conversions": "69",
            "Conversion Value": "$13,754",
            "Cost per Result": "$40.85"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "484760",
            "Clicks": "2777",
            "Conversions": "59",
            "Conversion Value": "$10,500",
            "Cost per Result": "$61.44"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "452548",
            "Clicks": "3378",
            "Conversions": "71",
            "Conversion Value": "$6,029",
            "Cost per Result": "$20.67"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "351972",
            "Clicks": "6706",
            "Conversions": "69",
            "Conversion Value": "$10,144",
            "Cost per Result": "$50.11"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "315609",
            "Clicks": "5521",
            "Conversions": "42",
            "Conversion Value": "$11,497",
            "Cost per Result": "$57.91"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "115462",
            "Clicks": "5660",
            "Conversions": "54",
            "Conversion Value": "$14,202",
            "Cost per Result": "$48.10"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "345280",
            "Clicks": "4749",
            "Conversions": "58",
            "Conversion Value": "$9,559",
            "Cost per Result": "$19.70"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "107009",
            "Clicks": "3005",
            "Conversions": "69",
            "Conversion Value": "$15,310",
            "Cost per Result": "$62.15"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "422333",
            "Clicks": "5072",
            "Conversions": "71",
            "Conversion Value": "$7,588",
            "Cost per Result": "$15.77"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "281086",
            "Clicks": "5929",
            "Conversions": "56",
            "Conversion Value": "$13,690",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "270410",
            "Clicks": "5488",
            "Conversions": "74",
            "Conversion Value": "$6,696",
            "Cost per Result": "$57.37"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "110627",
            "Clicks": "4359",
            "Conversions": "48",
            "Conversion Value": "$7,210",
            "Cost per Result": "$55.69"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "115574",
            "Clicks": "2155",
            "Conversions": "85",
            "Conversion Value": "$11,083",
            "Cost per Result": "$53.68"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "345893",
            "Clicks": "3400",
            "Conversions": "45",
            "Conversion Value": "$4,281",
            "Cost per Result": "$24.39"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "305103",
            "Clicks": "2126",
            "Conversions": "64",
            "Conversion Value": "$14,552",
            "Cost per Result": "$61.46"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "117222",
            "Clicks": "5192",
            "Conversions": "45",
            "Conversion Value": "$9,281",
            "Cost per Result": "$18.20"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "470295",
            "Clicks": "5338",
            "Conversions": "56",
            "Conversion Value": "$4,993",
            "Cost per Result": "$59.38"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "368121",
            "Clicks": "2694",
            "Conversions": "81",
            "Conversion Value": "$14,439",
            "Cost per Result": "$16.19"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "421659",
            "Clicks": "3729",
            "Conversions": "79",
            "Conversion Value": "$6,432",
            "Cost per Result": "$51.44"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "205993",
            "Clicks": "3757",
            "Conversions": "62",
            "Conversion Value": "$8,543",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "241316",
            "Clicks": "2388",
            "Conversions": "86",
            "Conversion Value": "$10,752",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "173287",
            "Clicks": "6318",
            "Conversions": "65",
            "Conversion Value": "$8,320",
            "Cost per Result": "$42.40"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "296577",
            "Clicks": "6878",
            "Conversions": "68",
            "Conversion Value": "$5,519",
            "Cost per Result": "$40.66"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "397070",
            "Clicks": "3406",
            "Conversions": "51",
            "Conversion Value": "$4,699",
            "Cost per Result": "$40.68"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "306438",
            "Clicks": "6636",
            "Conversions": "73",
            "Conversion Value": "$5,110",
            "Cost per Result": "$34.11"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "301833",
            "Clicks": "6023",
            "Conversions": "70",
            "Conversion Value": "$13,755",
            "Cost per Result": "$22.01"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "271441",
            "Clicks": "6129",
            "Conversions": "58",
            "Conversion Value": "$7,879",
            "Cost per Result": "$40.53"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "306538",
            "Clicks": "2990",
            "Conversions": "80",
            "Conversion Value": "$9,989",
            "Cost per Result": "$35.31"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "205463",
            "Clicks": "4225",
            "Conversions": "67",
            "Conversion Value": "$7,486",
            "Cost per Result": "$19.53"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "216587",
            "Clicks": "4763",
            "Conversions": "48",
            "Conversion Value": "$7,520",
            "Cost per Result": "$15.00"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "120356",
            "Clicks": "2638",
            "Conversions": "46",
            "Conversion Value": "$14,496",
            "Cost per Result": "$46.76"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "415112",
            "Clicks": "4327",
            "Conversions": "43",
            "Conversion Value": "$9,202",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "201943",
            "Clicks": "5076",
            "Conversions": "88",
            "Conversion Value": "$12,256",
            "Cost per Result": "$57.46"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "112151",
            "Clicks": "6883",
            "Conversions": "46",
            "Conversion Value": "$15,725",
            "Cost per Result": "$38.26"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "125867",
            "Clicks": "4490",
            "Conversions": "90",
            "Conversion Value": "$8,154",
            "Cost per Result": "$58.17"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "288026",
            "Clicks": "3802",
            "Conversions": "55",
            "Conversion Value": "$14,345",
            "Cost per Result": "$25.31"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "397906",
            "Clicks": "3078",
            "Conversions": "70",
            "Conversion Value": "$15,231",
            "Cost per Result": "$39.53"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "137438",
            "Clicks": "3412",
            "Conversions": "42",
            "Conversion Value": "$10,323",
            "Cost per Result": "$41.44"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "225121",
            "Clicks": "3232",
            "Conversions": "47",
            "Conversion Value": "$11,454",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "359189",
            "Clicks": "5753",
            "Conversions": "77",
            "Conversion Value": "$8,918",
            "Cost per Result": "$59.82"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "147864",
            "Clicks": "6546",
            "Conversions": "84",
            "Conversion Value": "$4,748",
            "Cost per Result": "$43.84"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "424255",
            "Clicks": "2474",
            "Conversions": "76",
            "Conversion Value": "$7,471",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "460248",
            "Clicks": "2266",
            "Conversions": "81",
            "Conversion Value": "$15,124",
            "Cost per Result": "$44.89"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "280445",
            "Clicks": "2209",
            "Conversions": "79",
            "Conversion Value": "$8,551",
            "Cost per Result": "$23.08"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "346950",
            "Clicks": "2997",
            "Conversions": "87",
            "Conversion Value": "$15,355",
            "Cost per Result": "$55.52"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "208952",
            "Clicks": "6811",
            "Conversions": "60",
            "Conversion Value": "$11,388",
            "Cost per Result": "$15.60"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "451737",
            "Clicks": "4039",
            "Conversions": "43",
            "Conversion Value": "$11,004",
            "Cost per Result": "$31.63"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "337269",
            "Clicks": "6952",
            "Conversions": "41",
            "Conversion Value": "$11,974",
            "Cost per Result": "$58.83"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "108964",
            "Clicks": "3118",
            "Conversions": "47",
            "Conversion Value": "$11,953",
            "Cost per Result": "$40.94"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "140811",
            "Clicks": "6433",
            "Conversions": "86",
            "Conversion Value": "$14,830",
            "Cost per Result": "$60.13"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "310827",
            "Clicks": "4418",
            "Conversions": "50",
            "Conversion Value": "$5,682",
            "Cost per Result": "$43.22"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "245032",
            "Clicks": "6601",
            "Conversions": "65",
            "Conversion Value": "$6,864",
            "Cost per Result": "$17.79"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "303734",
            "Clicks": "2111",
            "Conversions": "63",
            "Conversion Value": "$8,605",
            "Cost per Result": "$16.21"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "189958",
            "Clicks": "4194",
            "Conversions": "42",
            "Conversion Value": "$4,003",
            "Cost per Result": "$28.99"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "316707",
            "Clicks": "4253",
            "Conversions": "42",
            "Conversion Value": "$12,764",
            "Cost per Result": "$27.07"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "165423",
            "Clicks": "2656",
            "Conversions": "48",
            "Conversion Value": "$13,054",
            "Cost per Result": "$58.95"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "189106",
            "Clicks": "2766",
            "Conversions": "66",
            "Conversion Value": "$4,842",
            "Cost per Result": "$35.54"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "399262",
            "Clicks": "5880",
            "Conversions": "54",
            "Conversion Value": "$9,909",
            "Cost per Result": "$58.50"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "206587",
            "Clicks": "4889",
            "Conversions": "86",
            "Conversion Value": "$12,086",
            "Cost per Result": "$54.80"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "420471",
            "Clicks": "5952",
            "Conversions": "72",
            "Conversion Value": "$14,921",
            "Cost per Result": "$46.97"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "351477",
            "Clicks": "3235",
            "Conversions": "43",
            "Conversion Value": "$7,350",
            "Cost per Result": "$61.42"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "268811",
            "Clicks": "4050",
            "Conversions": "56",
            "Conversion Value": "$15,598",
            "Cost per Result": "$19.92"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "261464",
            "Clicks": "6302",
            "Conversions": "90",
            "Conversion Value": "$10,929",
            "Cost per Result": "$32.16"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "332006",
            "Clicks": "4002",
            "Conversions": "77",
            "Conversion Value": "$11,458",
            "Cost per Result": "$28.39"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "159747",
            "Clicks": "3920",
            "Conversions": "88",
            "Conversion Value": "$4,371",
            "Cost per Result": "$16.49"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "418172",
            "Clicks": "6436",
            "Conversions": "46",
            "Conversion Value": "$13,718",
            "Cost per Result": "$54.78"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "317671",
            "Clicks": "3610",
            "Conversions": "47",
            "Conversion Value": "$9,397",
            "Cost per Result": "$50.39"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "477316",
            "Clicks": "2120",
            "Conversions": "40",
            "Conversion Value": "$12,634",
            "Cost per Result": "$52.77"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "141113",
            "Clicks": "6859",
            "Conversions": "80",
            "Conversion Value": "$13,902",
            "Cost per Result": "$56.59"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "140819",
            "Clicks": "5179",
            "Conversions": "48",
            "Conversion Value": "$7,013",
            "Cost per Result": "$33.34"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "194068",
            "Clicks": "4963",
            "Conversions": "82",
            "Conversion Value": "$11,592",
            "Cost per Result": "$54.01"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "427088",
            "Clicks": "5960",
            "Conversions": "64",
            "Conversion Value": "$14,058",
            "Cost per Result": "$28.76"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "303114",
            "Clicks": "2740",
            "Conversions": "63",
            "Conversion Value": "$10,955",
            "Cost per Result": "$45.14"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "146741",
            "Clicks": "2567",
            "Conversions": "73",
            "Conversion Value": "$8,011",
            "Cost per Result": "$24.78"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "196995",
            "Clicks": "6158",
            "Conversions": "76",
            "Conversion Value": "$12,077",
            "Cost per Result": "$36.27"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "119699",
            "Clicks": "6371",
            "Conversions": "69",
            "Conversion Value": "$8,822",
            "Cost per Result": "$17.74"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "454892",
            "Clicks": "4961",
            "Conversions": "54",
            "Conversion Value": "$9,725",
            "Cost per Result": "$52.29"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "383352",
            "Clicks": "2223",
            "Conversions": "50",
            "Conversion Value": "$7,231",
            "Cost per Result": "$17.79"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "149876",
            "Clicks": "4925",
            "Conversions": "60",
            "Conversion Value": "$11,370",
            "Cost per Result": "$35.31"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "226210",
            "Clicks": "2060",
            "Conversions": "73",
            "Conversion Value": "$5,584",
            "Cost per Result": "$18.95"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "421604",
            "Clicks": "2164",
            "Conversions": "89",
            "Conversion Value": "$9,350",
            "Cost per Result": "$45.84"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "331530",
            "Clicks": "6869",
            "Conversions": "69",
            "Conversion Value": "$5,552",
            "Cost per Result": "$45.73"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "191619",
            "Clicks": "5478",
            "Conversions": "74",
            "Conversion Value": "$10,774",
            "Cost per Result": "$42.63"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "373403",
            "Clicks": "6565",
            "Conversions": "55",
            "Conversion Value": "$7,508",
            "Cost per Result": "$41.09"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "299479",
            "Clicks": "5371",
            "Conversions": "85",
            "Conversion Value": "$7,327",
            "Cost per Result": "$37.07"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "392687",
            "Clicks": "4921",
            "Conversions": "70",
            "Conversion Value": "$6,205",
            "Cost per Result": "$49.17"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "397745",
            "Clicks": "6826",
            "Conversions": "72",
            "Conversion Value": "$13,874",
            "Cost per Result": "$51.26"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "317526",
            "Clicks": "3474",
            "Conversions": "56",
            "Conversion Value": "$15,669",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "364989",
            "Clicks": "3062",
            "Conversions": "41",
            "Conversion Value": "$6,248",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "314450",
            "Clicks": "3149",
            "Conversions": "58",
            "Conversion Value": "$6,940",
            "Cost per Result": "$51.79"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "300418",
            "Clicks": "6309",
            "Conversions": "80",
            "Conversion Value": "$7,358",
            "Cost per Result": "$62.42"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "499333",
            "Clicks": "2691",
            "Conversions": "71",
            "Conversion Value": "$4,805",
            "Cost per Result": "$29.21"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "130153",
            "Clicks": "2622",
            "Conversions": "47",
            "Conversion Value": "$15,614",
            "Cost per Result": "$15.57"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "446670",
            "Clicks": "6557",
            "Conversions": "62",
            "Conversion Value": "$7,478",
            "Cost per Result": "$32.59"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "418327",
            "Clicks": "3038",
            "Conversions": "82",
            "Conversion Value": "$7,566",
            "Cost per Result": "$38.02"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "461386",
            "Clicks": "5724",
            "Conversions": "68",
            "Conversion Value": "$7,051",
            "Cost per Result": "$24.37"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "326986",
            "Clicks": "4091",
            "Conversions": "56",
            "Conversion Value": "$12,856",
            "Cost per Result": "$23.62"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "376097",
            "Clicks": "4443",
            "Conversions": "59",
            "Conversion Value": "$7,190",
            "Cost per Result": "$48.53"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "354643",
            "Clicks": "4203",
            "Conversions": "49",
            "Conversion Value": "$13,253",
            "Cost per Result": "$31.45"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "371479",
            "Clicks": "4670",
            "Conversions": "46",
            "Conversion Value": "$6,306",
            "Cost per Result": "$36.56"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "307632",
            "Clicks": "2513",
            "Conversions": "44",
            "Conversion Value": "$12,529",
            "Cost per Result": "$55.58"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "357235",
            "Clicks": "3362",
            "Conversions": "76",
            "Conversion Value": "$9,268",
            "Cost per Result": "$47.01"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "281023",
            "Clicks": "5716",
            "Conversions": "72",
            "Conversion Value": "$11,572",
            "Cost per Result": "$50.27"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "371434",
            "Clicks": "2611",
            "Conversions": "71",
            "Conversion Value": "$8,386",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "462736",
            "Clicks": "3074",
            "Conversions": "49",
            "Conversion Value": "$7,779",
            "Cost per Result": "$51.77"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "338483",
            "Clicks": "3287",
            "Conversions": "76",
            "Conversion Value": "$14,230",
            "Cost per Result": "$56.76"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "297912",
            "Clicks": "2293",
            "Conversions": "43",
            "Conversion Value": "$11,096",
            "Cost per Result": "$53.07"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "258413",
            "Clicks": "2580",
            "Conversions": "45",
            "Conversion Value": "$9,911",
            "Cost per Result": "$55.49"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "389107",
            "Clicks": "3821",
            "Conversions": "65",
            "Conversion Value": "$9,751",
            "Cost per Result": "$60.56"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "476863",
            "Clicks": "2841",
            "Conversions": "90",
            "Conversion Value": "$9,667",
            "Cost per Result": "$59.06"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "446157",
            "Clicks": "5870",
            "Conversions": "68",
            "Conversion Value": "$10,960",
            "Cost per Result": "$56.91"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "106272",
            "Clicks": "3124",
            "Conversions": "61",
            "Conversion Value": "$6,068",
            "Cost per Result": "$33.12"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "278779",
            "Clicks": "3989",
            "Conversions": "81",
            "Conversion Value": "$14,497",
            "Cost per Result": "$53.90"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "241230",
            "Clicks": "2909",
            "Conversions": "74",
            "Conversion Value": "$8,483",
            "Cost per Result": "$18.72"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "242202",
            "Clicks": "5188",
            "Conversions": "85",
            "Conversion Value": "$14,790",
            "Cost per Result": "$33.15"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "447388",
            "Clicks": "5777",
            "Conversions": "78",
            "Conversion Value": "$10,002",
            "Cost per Result": "$16.15"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "155042",
            "Clicks": "6898",
            "Conversions": "88",
            "Conversion Value": "$9,268",
            "Cost per Result": "$36.59"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "123029",
            "Clicks": "5270",
            "Conversions": "69",
            "Conversion Value": "$14,660",
            "Cost per Result": "$45.51"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "118927",
            "Clicks": "3505",
            "Conversions": "57",
            "Conversion Value": "$14,075",
            "Cost per Result": "$47.47"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "160759",
            "Clicks": "3410",
            "Conversions": "87",
            "Conversion Value": "$12,486",
            "Cost per Result": "$43.93"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "347078",
            "Clicks": "4894",
            "Conversions": "80",
            "Conversion Value": "$10,721",
            "Cost per Result": "$44.04"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "205290",
            "Clicks": "4160",
            "Conversions": "84",
            "Conversion Value": "$15,726",
            "Cost per Result": "$62.33"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "476411",
            "Clicks": "5656",
            "Conversions": "71",
            "Conversion Value": "$5,991",
            "Cost per Result": "$53.84"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "450101",
            "Clicks": "3533",
            "Conversions": "77",
            "Conversion Value": "$9,891",
            "Cost per Result": "$20.30"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "173311",
            "Clicks": "5077",
            "Conversions": "50",
            "Conversion Value": "$14,606",
            "Cost per Result": "$35.24"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "184704",
            "Clicks": "3846",
            "Conversions": "76",
            "Conversion Value": "$6,598",
            "Cost per Result": "$26.17"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "381716",
            "Clicks": "5071",
            "Conversions": "70",
            "Conversion Value": "$11,434",
            "Cost per Result": "$33.43"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "246009",
            "Clicks": "2085",
            "Conversions": "52",
            "Conversion Value": "$11,147",
            "Cost per Result": "$25.77"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "194643",
            "Clicks": "3377",
            "Conversions": "81",
            "Conversion Value": "$13,950",
            "Cost per Result": "$50.24"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "298247",
            "Clicks": "4320",
            "Conversions": "61",
            "Conversion Value": "$12,365",
            "Cost per Result": "$31.18"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "104033",
            "Clicks": "5984",
            "Conversions": "63",
            "Conversion Value": "$8,068",
            "Cost per Result": "$26.79"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "429193",
            "Clicks": "6166",
            "Conversions": "90",
            "Conversion Value": "$8,469",
            "Cost per Result": "$44.75"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "202209",
            "Clicks": "6120",
            "Conversions": "65",
            "Conversion Value": "$10,518",
            "Cost per Result": "$16.08"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "374979",
            "Clicks": "5248",
            "Conversions": "46",
            "Conversion Value": "$13,837",
            "Cost per Result": "$21.27"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "431284",
            "Clicks": "5759",
            "Conversions": "53",
            "Conversion Value": "$14,768",
            "Cost per Result": "$41.64"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "247145",
            "Clicks": "3456",
            "Conversions": "76",
            "Conversion Value": "$10,080",
            "Cost per Result": "$36.19"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "320341",
            "Clicks": "2362",
            "Conversions": "49",
            "Conversion Value": "$6,500",
            "Cost per Result": "$51.17"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "115271",
            "Clicks": "5144",
            "Conversions": "48",
            "Conversion Value": "$9,331",
            "Cost per Result": "$51.08"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "286543",
            "Clicks": "2152",
            "Conversions": "46",
            "Conversion Value": "$9,590",
            "Cost per Result": "$29.26"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "384522",
            "Clicks": "6791",
            "Conversions": "90",
            "Conversion Value": "$13,968",
            "Cost per Result": "$63.50"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "233936",
            "Clicks": "2152",
            "Conversions": "78",
            "Conversion Value": "$7,941",
            "Cost per Result": "$35.63"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "493864",
            "Clicks": "3560",
            "Conversions": "82",
            "Conversion Value": "$5,171",
            "Cost per Result": "$60.31"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "147457",
            "Clicks": "5409",
            "Conversions": "52",
            "Conversion Value": "$9,721",
            "Cost per Result": "$59.58"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "147876",
            "Clicks": "4433",
            "Conversions": "42",
            "Conversion Value": "$5,937",
            "Cost per Result": "$25.87"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "390429",
            "Clicks": "5349",
            "Conversions": "55",
            "Conversion Value": "$14,318",
            "Cost per Result": "$34.50"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "289787",
            "Clicks": "3744",
            "Conversions": "55",
            "Conversion Value": "$15,748",
            "Cost per Result": "$15.98"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "169007",
            "Clicks": "5225",
            "Conversions": "56",
            "Conversion Value": "$4,391",
            "Cost per Result": "$38.87"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "338307",
            "Clicks": "5165",
            "Conversions": "40",
            "Conversion Value": "$8,764",
            "Cost per Result": "$49.45"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "280087",
            "Clicks": "2791",
            "Conversions": "69",
            "Conversion Value": "$13,986",
            "Cost per Result": "$31.33"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "365338",
            "Clicks": "6839",
            "Conversions": "48",
            "Conversion Value": "$7,780",
            "Cost per Result": "$31.49"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "319835",
            "Clicks": "3047",
            "Conversions": "71",
            "Conversion Value": "$6,782",
            "Cost per Result": "$54.17"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "210955",
            "Clicks": "2199",
            "Conversions": "57",
            "Conversion Value": "$12,233",
            "Cost per Result": "$50.79"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "164392",
            "Clicks": "6422",
            "Conversions": "40",
            "Conversion Value": "$8,866",
            "Cost per Result": "$51.98"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "247915",
            "Clicks": "6194",
            "Conversions": "44",
            "Conversion Value": "$6,544",
            "Cost per Result": "$38.90"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "464528",
            "Clicks": "5605",
            "Conversions": "54",
            "Conversion Value": "$10,251",
            "Cost per Result": "$45.30"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "458173",
            "Clicks": "6262",
            "Conversions": "43",
            "Conversion Value": "$8,147",
            "Cost per Result": "$29.43"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "300722",
            "Clicks": "2429",
            "Conversions": "77",
            "Conversion Value": "$5,797",
            "Cost per Result": "$32.33"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "412699",
            "Clicks": "6772",
            "Conversions": "55",
            "Conversion Value": "$10,314",
            "Cost per Result": "$23.55"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "359090",
            "Clicks": "6324",
            "Conversions": "88",
            "Conversion Value": "$5,982",
            "Cost per Result": "$49.08"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "403588",
            "Clicks": "5940",
            "Conversions": "80",
            "Conversion Value": "$8,114",
            "Cost per Result": "$29.07"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "202644",
            "Clicks": "6607",
            "Conversions": "79",
            "Conversion Value": "$12,395",
            "Cost per Result": "$59.22"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "220746",
            "Clicks": "4944",
            "Conversions": "77",
            "Conversion Value": "$9,204",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "174048",
            "Clicks": "2031",
            "Conversions": "55",
            "Conversion Value": "$15,242",
            "Cost per Result": "$29.84"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "157178",
            "Clicks": "2527",
            "Conversions": "72",
            "Conversion Value": "$10,963",
            "Cost per Result": "$28.53"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "178579",
            "Clicks": "3878",
            "Conversions": "58",
            "Conversion Value": "$14,051",
            "Cost per Result": "$40.65"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "136280",
            "Clicks": "3201",
            "Conversions": "42",
            "Conversion Value": "$7,824",
            "Cost per Result": "$24.23"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "219432",
            "Clicks": "3371",
            "Conversions": "53",
            "Conversion Value": "$5,905",
            "Cost per Result": "$57.37"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "156846",
            "Clicks": "2161",
            "Conversions": "90",
            "Conversion Value": "$15,118",
            "Cost per Result": "$35.11"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "307644",
            "Clicks": "4417",
            "Conversions": "43",
            "Conversion Value": "$9,869",
            "Cost per Result": "$40.08"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "428521",
            "Clicks": "2435",
            "Conversions": "52",
            "Conversion Value": "$7,792",
            "Cost per Result": "$42.37"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "418114",
            "Clicks": "3492",
            "Conversions": "79",
            "Conversion Value": "$7,793",
            "Cost per Result": "$16.04"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "109889",
            "Clicks": "4979",
            "Conversions": "44",
            "Conversion Value": "$15,073",
            "Cost per Result": "$52.62"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "139994",
            "Clicks": "6829",
            "Conversions": "52",
            "Conversion Value": "$4,892",
            "Cost per Result": "$39.60"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "257606",
            "Clicks": "6475",
            "Conversions": "52",
            "Conversion Value": "$8,198",
            "Cost per Result": "$52.18"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "158791",
            "Clicks": "5849",
            "Conversions": "72",
            "Conversion Value": "$10,562",
            "Cost per Result": "$29.06"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "448155",
            "Clicks": "4426",
            "Conversions": "68",
            "Conversion Value": "$8,304",
            "Cost per Result": "$35.64"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "149998",
            "Clicks": "4656",
            "Conversions": "52",
            "Conversion Value": "$5,870",
            "Cost per Result": "$28.63"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "450766",
            "Clicks": "4507",
            "Conversions": "47",
            "Conversion Value": "$13,456",
            "Cost per Result": "$58.96"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "257114",
            "Clicks": "3411",
            "Conversions": "43",
            "Conversion Value": "$9,514",
            "Cost per Result": "$57.33"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "423541",
            "Clicks": "4578",
            "Conversions": "68",
            "Conversion Value": "$9,534",
            "Cost per Result": "$19.87"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "124082",
            "Clicks": "5836",
            "Conversions": "83",
            "Conversion Value": "$8,129",
            "Cost per Result": "$47.96"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "394032",
            "Clicks": "6875",
            "Conversions": "50",
            "Conversion Value": "$14,768",
            "Cost per Result": "$61.44"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "463277",
            "Clicks": "3199",
            "Conversions": "77",
            "Conversion Value": "$14,674",
            "Cost per Result": "$35.86"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "295911",
            "Clicks": "4540",
            "Conversions": "51",
            "Conversion Value": "$7,217",
            "Cost per Result": "$35.24"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "231122",
            "Clicks": "5822",
            "Conversions": "79",
            "Conversion Value": "$15,769",
            "Cost per Result": "$53.61"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "296462",
            "Clicks": "3466",
            "Conversions": "45",
            "Conversion Value": "$5,562",
            "Cost per Result": "$15.11"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "276604",
            "Clicks": "6787",
            "Conversions": "54",
            "Conversion Value": "$8,820",
            "Cost per Result": "$39.76"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "303379",
            "Clicks": "6561",
            "Conversions": "90",
            "Conversion Value": "$14,431",
            "Cost per Result": "$15.28"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "301752",
            "Clicks": "3786",
            "Conversions": "84",
            "Conversion Value": "$6,446",
            "Cost per Result": "$51.73"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "227940",
            "Clicks": "6612",
            "Conversions": "56",
            "Conversion Value": "$14,765",
            "Cost per Result": "$20.42"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "162488",
            "Clicks": "4386",
            "Conversions": "61",
            "Conversion Value": "$10,911",
            "Cost per Result": "$27.33"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "255303",
            "Clicks": "3493",
            "Conversions": "70",
            "Conversion Value": "$11,067",
            "Cost per Result": "$61.25"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "233993",
            "Clicks": "2689",
            "Conversions": "90",
            "Conversion Value": "$11,441",
            "Cost per Result": "$43.32"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "388290",
            "Clicks": "6939",
            "Conversions": "48",
            "Conversion Value": "$14,648",
            "Cost per Result": "$15.90"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "214241",
            "Clicks": "2152",
            "Conversions": "80",
            "Conversion Value": "$8,422",
            "Cost per Result": "$41.10"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "251224",
            "Clicks": "6628",
            "Conversions": "43",
            "Conversion Value": "$14,276",
            "Cost per Result": "$53.99"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "246107",
            "Clicks": "3048",
            "Conversions": "67",
            "Conversion Value": "$15,464",
            "Cost per Result": "$57.22"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "234564",
            "Clicks": "6121",
            "Conversions": "58",
            "Conversion Value": "$6,299",
            "Cost per Result": "$29.55"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "262230",
            "Clicks": "4619",
            "Conversions": "70",
            "Conversion Value": "$5,632",
            "Cost per Result": "$55.90"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "404383",
            "Clicks": "6712",
            "Conversions": "69",
            "Conversion Value": "$9,580",
            "Cost per Result": "$30.23"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "313596",
            "Clicks": "6897",
            "Conversions": "56",
            "Conversion Value": "$12,865",
            "Cost per Result": "$41.02"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "175031",
            "Clicks": "4856",
            "Conversions": "81",
            "Conversion Value": "$9,336",
            "Cost per Result": "$28.02"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "204919",
            "Clicks": "6521",
            "Conversions": "90",
            "Conversion Value": "$6,705",
            "Cost per Result": "$49.06"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "336319",
            "Clicks": "4205",
            "Conversions": "71",
            "Conversion Value": "$5,902",
            "Cost per Result": "$52.16"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "355808",
            "Clicks": "2778",
            "Conversions": "87",
            "Conversion Value": "$7,428",
            "Cost per Result": "$21.85"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "132482",
            "Clicks": "2645",
            "Conversions": "53",
            "Conversion Value": "$15,534",
            "Cost per Result": "$54.32"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "321196",
            "Clicks": "5858",
            "Conversions": "76",
            "Conversion Value": "$8,680",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "409465",
            "Clicks": "4412",
            "Conversions": "72",
            "Conversion Value": "$15,921",
            "Cost per Result": "$51.12"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "178587",
            "Clicks": "4637",
            "Conversions": "72",
            "Conversion Value": "$15,853",
            "Cost per Result": "$43.51"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "386182",
            "Clicks": "3201",
            "Conversions": "50",
            "Conversion Value": "$8,430",
            "Cost per Result": "$59.52"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "120646",
            "Clicks": "2695",
            "Conversions": "76",
            "Conversion Value": "$12,071",
            "Cost per Result": "$54.88"
          }
        ]
      },
      "FinanceUsersData": {
        "name": "Finance Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "392679",
            "Clicks": "2126",
            "Conversions": "60",
            "Conversion Value": "$7,964",
            "Cost per Result": "$16.69"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "177495",
            "Clicks": "4226",
            "Conversions": "41",
            "Conversion Value": "$9,116",
            "Cost per Result": "$31.86"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "323633",
            "Clicks": "4541",
            "Conversions": "56",
            "Conversion Value": "$5,116",
            "Cost per Result": "$62.99"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "425460",
            "Clicks": "2846",
            "Conversions": "53",
            "Conversion Value": "$14,092",
            "Cost per Result": "$17.64"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "337205",
            "Clicks": "3221",
            "Conversions": "65",
            "Conversion Value": "$7,313",
            "Cost per Result": "$32.29"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "408212",
            "Clicks": "3023",
            "Conversions": "48",
            "Conversion Value": "$14,459",
            "Cost per Result": "$38.14"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "443622",
            "Clicks": "3687",
            "Conversions": "80",
            "Conversion Value": "$7,680",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "375178",
            "Clicks": "5972",
            "Conversions": "73",
            "Conversion Value": "$7,460",
            "Cost per Result": "$50.45"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "121893",
            "Clicks": "6291",
            "Conversions": "65",
            "Conversion Value": "$5,979",
            "Cost per Result": "$27.15"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "407487",
            "Clicks": "3356",
            "Conversions": "49",
            "Conversion Value": "$8,588",
            "Cost per Result": "$58.09"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "190733",
            "Clicks": "5873",
            "Conversions": "86",
            "Conversion Value": "$11,220",
            "Cost per Result": "$20.85"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "399759",
            "Clicks": "3684",
            "Conversions": "80",
            "Conversion Value": "$12,417",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "113613",
            "Clicks": "4733",
            "Conversions": "66",
            "Conversion Value": "$12,153",
            "Cost per Result": "$22.44"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "295328",
            "Clicks": "4930",
            "Conversions": "53",
            "Conversion Value": "$5,550",
            "Cost per Result": "$25.97"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "461106",
            "Clicks": "5828",
            "Conversions": "76",
            "Conversion Value": "$9,697",
            "Cost per Result": "$58.85"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "490281",
            "Clicks": "4859",
            "Conversions": "80",
            "Conversion Value": "$15,507",
            "Cost per Result": "$19.76"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "382103",
            "Clicks": "3955",
            "Conversions": "75",
            "Conversion Value": "$15,484",
            "Cost per Result": "$46.22"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "354804",
            "Clicks": "2809",
            "Conversions": "74",
            "Conversion Value": "$7,414",
            "Cost per Result": "$33.96"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "263274",
            "Clicks": "5205",
            "Conversions": "73",
            "Conversion Value": "$12,092",
            "Cost per Result": "$55.12"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "236538",
            "Clicks": "4255",
            "Conversions": "64",
            "Conversion Value": "$6,470",
            "Cost per Result": "$15.62"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "264726",
            "Clicks": "2259",
            "Conversions": "87",
            "Conversion Value": "$11,765",
            "Cost per Result": "$27.77"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "182098",
            "Clicks": "3286",
            "Conversions": "86",
            "Conversion Value": "$4,938",
            "Cost per Result": "$31.21"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "497226",
            "Clicks": "2253",
            "Conversions": "81",
            "Conversion Value": "$13,374",
            "Cost per Result": "$33.55"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "268200",
            "Clicks": "6450",
            "Conversions": "55",
            "Conversion Value": "$7,735",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "346661",
            "Clicks": "6790",
            "Conversions": "60",
            "Conversion Value": "$12,048",
            "Cost per Result": "$57.25"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "157707",
            "Clicks": "5369",
            "Conversions": "76",
            "Conversion Value": "$5,451",
            "Cost per Result": "$62.10"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "355031",
            "Clicks": "3819",
            "Conversions": "48",
            "Conversion Value": "$5,436",
            "Cost per Result": "$17.25"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "229379",
            "Clicks": "6015",
            "Conversions": "49",
            "Conversion Value": "$12,049",
            "Cost per Result": "$24.66"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "340800",
            "Clicks": "4251",
            "Conversions": "61",
            "Conversion Value": "$8,480",
            "Cost per Result": "$60.17"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "470411",
            "Clicks": "5866",
            "Conversions": "88",
            "Conversion Value": "$7,916",
            "Cost per Result": "$59.62"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "297874",
            "Clicks": "2286",
            "Conversions": "89",
            "Conversion Value": "$9,265",
            "Cost per Result": "$38.42"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "421555",
            "Clicks": "4892",
            "Conversions": "67",
            "Conversion Value": "$7,892",
            "Cost per Result": "$24.57"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "370813",
            "Clicks": "2254",
            "Conversions": "52",
            "Conversion Value": "$4,930",
            "Cost per Result": "$40.01"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "335462",
            "Clicks": "3875",
            "Conversions": "60",
            "Conversion Value": "$6,119",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "189647",
            "Clicks": "2479",
            "Conversions": "65",
            "Conversion Value": "$14,554",
            "Cost per Result": "$32.10"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "115864",
            "Clicks": "5197",
            "Conversions": "45",
            "Conversion Value": "$5,999",
            "Cost per Result": "$32.02"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "492418",
            "Clicks": "2103",
            "Conversions": "51",
            "Conversion Value": "$5,602",
            "Cost per Result": "$33.97"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "470655",
            "Clicks": "3523",
            "Conversions": "72",
            "Conversion Value": "$10,252",
            "Cost per Result": "$31.18"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "105200",
            "Clicks": "2407",
            "Conversions": "68",
            "Conversion Value": "$5,280",
            "Cost per Result": "$41.97"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "214252",
            "Clicks": "5655",
            "Conversions": "68",
            "Conversion Value": "$8,333",
            "Cost per Result": "$36.79"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "332090",
            "Clicks": "2788",
            "Conversions": "75",
            "Conversion Value": "$9,342",
            "Cost per Result": "$58.41"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "128739",
            "Clicks": "6926",
            "Conversions": "60",
            "Conversion Value": "$10,939",
            "Cost per Result": "$35.03"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "181712",
            "Clicks": "6939",
            "Conversions": "71",
            "Conversion Value": "$5,505",
            "Cost per Result": "$35.44"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "230000",
            "Clicks": "2513",
            "Conversions": "83",
            "Conversion Value": "$15,301",
            "Cost per Result": "$37.22"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "279056",
            "Clicks": "3741",
            "Conversions": "41",
            "Conversion Value": "$5,246",
            "Cost per Result": "$41.06"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "431886",
            "Clicks": "2731",
            "Conversions": "41",
            "Conversion Value": "$7,815",
            "Cost per Result": "$24.63"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "244547",
            "Clicks": "5177",
            "Conversions": "43",
            "Conversion Value": "$6,662",
            "Cost per Result": "$59.25"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "261679",
            "Clicks": "6025",
            "Conversions": "69",
            "Conversion Value": "$15,776",
            "Cost per Result": "$47.82"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "135562",
            "Clicks": "5638",
            "Conversions": "55",
            "Conversion Value": "$6,416",
            "Cost per Result": "$46.05"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "413685",
            "Clicks": "4024",
            "Conversions": "70",
            "Conversion Value": "$15,264",
            "Cost per Result": "$56.73"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "214334",
            "Clicks": "5324",
            "Conversions": "70",
            "Conversion Value": "$12,129",
            "Cost per Result": "$64.30"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "150564",
            "Clicks": "6555",
            "Conversions": "41",
            "Conversion Value": "$11,675",
            "Cost per Result": "$38.06"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "112053",
            "Clicks": "4178",
            "Conversions": "73",
            "Conversion Value": "$5,362",
            "Cost per Result": "$46.73"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "246429",
            "Clicks": "3042",
            "Conversions": "65",
            "Conversion Value": "$12,797",
            "Cost per Result": "$34.06"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "489933",
            "Clicks": "5295",
            "Conversions": "86",
            "Conversion Value": "$8,640",
            "Cost per Result": "$40.31"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "340094",
            "Clicks": "4942",
            "Conversions": "43",
            "Conversion Value": "$14,359",
            "Cost per Result": "$59.34"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "148033",
            "Clicks": "6288",
            "Conversions": "73",
            "Conversion Value": "$10,565",
            "Cost per Result": "$38.57"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "416194",
            "Clicks": "3953",
            "Conversions": "82",
            "Conversion Value": "$6,742",
            "Cost per Result": "$25.85"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "402291",
            "Clicks": "3217",
            "Conversions": "56",
            "Conversion Value": "$7,318",
            "Cost per Result": "$26.74"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "496937",
            "Clicks": "4993",
            "Conversions": "77",
            "Conversion Value": "$10,057",
            "Cost per Result": "$54.32"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "346032",
            "Clicks": "6646",
            "Conversions": "46",
            "Conversion Value": "$9,085",
            "Cost per Result": "$61.15"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "105481",
            "Clicks": "3868",
            "Conversions": "61",
            "Conversion Value": "$5,560",
            "Cost per Result": "$50.33"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "447139",
            "Clicks": "4203",
            "Conversions": "73",
            "Conversion Value": "$9,692",
            "Cost per Result": "$56.19"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "306787",
            "Clicks": "6900",
            "Conversions": "79",
            "Conversion Value": "$5,828",
            "Cost per Result": "$56.52"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "225216",
            "Clicks": "4768",
            "Conversions": "76",
            "Conversion Value": "$6,879",
            "Cost per Result": "$63.71"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "352985",
            "Clicks": "6387",
            "Conversions": "81",
            "Conversion Value": "$7,066",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "329818",
            "Clicks": "3465",
            "Conversions": "81",
            "Conversion Value": "$9,986",
            "Cost per Result": "$40.47"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "423980",
            "Clicks": "3912",
            "Conversions": "90",
            "Conversion Value": "$4,190",
            "Cost per Result": "$28.39"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "332857",
            "Clicks": "4178",
            "Conversions": "62",
            "Conversion Value": "$14,611",
            "Cost per Result": "$53.90"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "482279",
            "Clicks": "6243",
            "Conversions": "51",
            "Conversion Value": "$8,174",
            "Cost per Result": "$42.44"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "293457",
            "Clicks": "5934",
            "Conversions": "67",
            "Conversion Value": "$8,322",
            "Cost per Result": "$27.92"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "332571",
            "Clicks": "5847",
            "Conversions": "83",
            "Conversion Value": "$13,637",
            "Cost per Result": "$52.97"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "495978",
            "Clicks": "5289",
            "Conversions": "46",
            "Conversion Value": "$4,261",
            "Cost per Result": "$62.68"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "226002",
            "Clicks": "3009",
            "Conversions": "90",
            "Conversion Value": "$8,557",
            "Cost per Result": "$46.99"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "152084",
            "Clicks": "4027",
            "Conversions": "57",
            "Conversion Value": "$8,146",
            "Cost per Result": "$41.81"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "267526",
            "Clicks": "6841",
            "Conversions": "84",
            "Conversion Value": "$11,668",
            "Cost per Result": "$37.34"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "252171",
            "Clicks": "2751",
            "Conversions": "60",
            "Conversion Value": "$4,743",
            "Cost per Result": "$41.40"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "119388",
            "Clicks": "6435",
            "Conversions": "61",
            "Conversion Value": "$12,675",
            "Cost per Result": "$26.59"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "113164",
            "Clicks": "3098",
            "Conversions": "51",
            "Conversion Value": "$9,291",
            "Cost per Result": "$56.77"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "348163",
            "Clicks": "6322",
            "Conversions": "42",
            "Conversion Value": "$12,738",
            "Cost per Result": "$56.68"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "367692",
            "Clicks": "5968",
            "Conversions": "75",
            "Conversion Value": "$8,943",
            "Cost per Result": "$29.23"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "296734",
            "Clicks": "5669",
            "Conversions": "58",
            "Conversion Value": "$8,120",
            "Cost per Result": "$18.88"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "249434",
            "Clicks": "3416",
            "Conversions": "57",
            "Conversion Value": "$13,519",
            "Cost per Result": "$29.63"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "492046",
            "Clicks": "6341",
            "Conversions": "67",
            "Conversion Value": "$11,703",
            "Cost per Result": "$63.47"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "232587",
            "Clicks": "3339",
            "Conversions": "50",
            "Conversion Value": "$6,310",
            "Cost per Result": "$39.59"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "396691",
            "Clicks": "4575",
            "Conversions": "67",
            "Conversion Value": "$4,108",
            "Cost per Result": "$23.11"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "167796",
            "Clicks": "3221",
            "Conversions": "88",
            "Conversion Value": "$8,211",
            "Cost per Result": "$25.85"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "306014",
            "Clicks": "5251",
            "Conversions": "62",
            "Conversion Value": "$13,906",
            "Cost per Result": "$60.18"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "316775",
            "Clicks": "6630",
            "Conversions": "54",
            "Conversion Value": "$14,076",
            "Cost per Result": "$64.79"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "467005",
            "Clicks": "5323",
            "Conversions": "40",
            "Conversion Value": "$8,125",
            "Cost per Result": "$32.49"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "223448",
            "Clicks": "4524",
            "Conversions": "61",
            "Conversion Value": "$14,097",
            "Cost per Result": "$20.63"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "192040",
            "Clicks": "2206",
            "Conversions": "43",
            "Conversion Value": "$5,927",
            "Cost per Result": "$33.01"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "177584",
            "Clicks": "6507",
            "Conversions": "72",
            "Conversion Value": "$4,595",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "156614",
            "Clicks": "2683",
            "Conversions": "84",
            "Conversion Value": "$9,393",
            "Cost per Result": "$63.95"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "140261",
            "Clicks": "6827",
            "Conversions": "67",
            "Conversion Value": "$10,434",
            "Cost per Result": "$56.52"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "159168",
            "Clicks": "4139",
            "Conversions": "54",
            "Conversion Value": "$6,347",
            "Cost per Result": "$20.68"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "328469",
            "Clicks": "3908",
            "Conversions": "75",
            "Conversion Value": "$8,063",
            "Cost per Result": "$60.82"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "292783",
            "Clicks": "6806",
            "Conversions": "85",
            "Conversion Value": "$12,768",
            "Cost per Result": "$54.23"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "123886",
            "Clicks": "3885",
            "Conversions": "50",
            "Conversion Value": "$14,082",
            "Cost per Result": "$55.56"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "498740",
            "Clicks": "6627",
            "Conversions": "55",
            "Conversion Value": "$15,522",
            "Cost per Result": "$44.95"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "210186",
            "Clicks": "4408",
            "Conversions": "46",
            "Conversion Value": "$4,983",
            "Cost per Result": "$54.27"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "363176",
            "Clicks": "2741",
            "Conversions": "64",
            "Conversion Value": "$10,051",
            "Cost per Result": "$25.89"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "291877",
            "Clicks": "4955",
            "Conversions": "49",
            "Conversion Value": "$11,144",
            "Cost per Result": "$64.74"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "158689",
            "Clicks": "2597",
            "Conversions": "78",
            "Conversion Value": "$5,416",
            "Cost per Result": "$58.81"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "149926",
            "Clicks": "4156",
            "Conversions": "45",
            "Conversion Value": "$7,298",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "257606",
            "Clicks": "5687",
            "Conversions": "79",
            "Conversion Value": "$15,433",
            "Cost per Result": "$63.43"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "495580",
            "Clicks": "4690",
            "Conversions": "71",
            "Conversion Value": "$5,625",
            "Cost per Result": "$57.84"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "343660",
            "Clicks": "2880",
            "Conversions": "47",
            "Conversion Value": "$10,968",
            "Cost per Result": "$24.61"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "150473",
            "Clicks": "6283",
            "Conversions": "86",
            "Conversion Value": "$8,960",
            "Cost per Result": "$23.79"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "471406",
            "Clicks": "6711",
            "Conversions": "84",
            "Conversion Value": "$15,858",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "371381",
            "Clicks": "5051",
            "Conversions": "78",
            "Conversion Value": "$10,594",
            "Cost per Result": "$25.63"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "171233",
            "Clicks": "5420",
            "Conversions": "44",
            "Conversion Value": "$11,228",
            "Cost per Result": "$16.27"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "220662",
            "Clicks": "5884",
            "Conversions": "68",
            "Conversion Value": "$13,984",
            "Cost per Result": "$38.13"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "232144",
            "Clicks": "4729",
            "Conversions": "66",
            "Conversion Value": "$11,640",
            "Cost per Result": "$23.76"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "334327",
            "Clicks": "3075",
            "Conversions": "40",
            "Conversion Value": "$7,779",
            "Cost per Result": "$59.08"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "130578",
            "Clicks": "5408",
            "Conversions": "60",
            "Conversion Value": "$13,718",
            "Cost per Result": "$49.07"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "139992",
            "Clicks": "4836",
            "Conversions": "51",
            "Conversion Value": "$10,674",
            "Cost per Result": "$25.63"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "483709",
            "Clicks": "6630",
            "Conversions": "61",
            "Conversion Value": "$11,454",
            "Cost per Result": "$38.48"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "214988",
            "Clicks": "5373",
            "Conversions": "81",
            "Conversion Value": "$9,389",
            "Cost per Result": "$16.63"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "418326",
            "Clicks": "6662",
            "Conversions": "87",
            "Conversion Value": "$10,962",
            "Cost per Result": "$26.31"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "244441",
            "Clicks": "4725",
            "Conversions": "48",
            "Conversion Value": "$6,713",
            "Cost per Result": "$37.23"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "346492",
            "Clicks": "3727",
            "Conversions": "59",
            "Conversion Value": "$15,091",
            "Cost per Result": "$35.75"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "140870",
            "Clicks": "3523",
            "Conversions": "49",
            "Conversion Value": "$11,096",
            "Cost per Result": "$18.15"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "167506",
            "Clicks": "5331",
            "Conversions": "64",
            "Conversion Value": "$12,087",
            "Cost per Result": "$53.83"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "227130",
            "Clicks": "2104",
            "Conversions": "45",
            "Conversion Value": "$15,202",
            "Cost per Result": "$40.76"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "369724",
            "Clicks": "4200",
            "Conversions": "48",
            "Conversion Value": "$6,970",
            "Cost per Result": "$18.30"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "476538",
            "Clicks": "4376",
            "Conversions": "51",
            "Conversion Value": "$15,750",
            "Cost per Result": "$22.21"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "138904",
            "Clicks": "5434",
            "Conversions": "41",
            "Conversion Value": "$4,664",
            "Cost per Result": "$62.37"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "466653",
            "Clicks": "5169",
            "Conversions": "47",
            "Conversion Value": "$14,474",
            "Cost per Result": "$41.56"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "401742",
            "Clicks": "2747",
            "Conversions": "71",
            "Conversion Value": "$9,372",
            "Cost per Result": "$38.94"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "161996",
            "Clicks": "5387",
            "Conversions": "50",
            "Conversion Value": "$5,287",
            "Cost per Result": "$23.00"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "410190",
            "Clicks": "6754",
            "Conversions": "51",
            "Conversion Value": "$8,655",
            "Cost per Result": "$43.10"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "427512",
            "Clicks": "4668",
            "Conversions": "53",
            "Conversion Value": "$10,703",
            "Cost per Result": "$35.35"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "226250",
            "Clicks": "5490",
            "Conversions": "89",
            "Conversion Value": "$6,707",
            "Cost per Result": "$52.54"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "426820",
            "Clicks": "2722",
            "Conversions": "62",
            "Conversion Value": "$15,676",
            "Cost per Result": "$45.80"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "376693",
            "Clicks": "4365",
            "Conversions": "86",
            "Conversion Value": "$13,329",
            "Cost per Result": "$43.36"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "421247",
            "Clicks": "4538",
            "Conversions": "45",
            "Conversion Value": "$15,746",
            "Cost per Result": "$39.50"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "447402",
            "Clicks": "5024",
            "Conversions": "76",
            "Conversion Value": "$10,882",
            "Cost per Result": "$45.59"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "407836",
            "Clicks": "3872",
            "Conversions": "62",
            "Conversion Value": "$6,375",
            "Cost per Result": "$27.11"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "168743",
            "Clicks": "5435",
            "Conversions": "50",
            "Conversion Value": "$15,486",
            "Cost per Result": "$37.96"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "473487",
            "Clicks": "4332",
            "Conversions": "54",
            "Conversion Value": "$9,640",
            "Cost per Result": "$57.23"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "253794",
            "Clicks": "2658",
            "Conversions": "78",
            "Conversion Value": "$12,922",
            "Cost per Result": "$31.26"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "261120",
            "Clicks": "6861",
            "Conversions": "45",
            "Conversion Value": "$15,933",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "393961",
            "Clicks": "2936",
            "Conversions": "70",
            "Conversion Value": "$7,067",
            "Cost per Result": "$36.65"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "411284",
            "Clicks": "5958",
            "Conversions": "80",
            "Conversion Value": "$7,376",
            "Cost per Result": "$25.90"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "451219",
            "Clicks": "2045",
            "Conversions": "49",
            "Conversion Value": "$13,486",
            "Cost per Result": "$49.53"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "334426",
            "Clicks": "5007",
            "Conversions": "62",
            "Conversion Value": "$14,736",
            "Cost per Result": "$34.74"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "128938",
            "Clicks": "2655",
            "Conversions": "54",
            "Conversion Value": "$8,444",
            "Cost per Result": "$59.71"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "166757",
            "Clicks": "6638",
            "Conversions": "79",
            "Conversion Value": "$13,223",
            "Cost per Result": "$36.61"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "126313",
            "Clicks": "5849",
            "Conversions": "66",
            "Conversion Value": "$4,761",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "254057",
            "Clicks": "4338",
            "Conversions": "70",
            "Conversion Value": "$8,773",
            "Cost per Result": "$53.37"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "113247",
            "Clicks": "4151",
            "Conversions": "40",
            "Conversion Value": "$6,165",
            "Cost per Result": "$61.62"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "287167",
            "Clicks": "3306",
            "Conversions": "62",
            "Conversion Value": "$14,897",
            "Cost per Result": "$37.34"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "287974",
            "Clicks": "4601",
            "Conversions": "69",
            "Conversion Value": "$8,487",
            "Cost per Result": "$16.76"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "180592",
            "Clicks": "5409",
            "Conversions": "70",
            "Conversion Value": "$6,488",
            "Cost per Result": "$61.84"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "339737",
            "Clicks": "3368",
            "Conversions": "74",
            "Conversion Value": "$10,933",
            "Cost per Result": "$57.77"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "148233",
            "Clicks": "5804",
            "Conversions": "67",
            "Conversion Value": "$8,256",
            "Cost per Result": "$46.14"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "230705",
            "Clicks": "4632",
            "Conversions": "60",
            "Conversion Value": "$15,400",
            "Cost per Result": "$57.85"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "400771",
            "Clicks": "2287",
            "Conversions": "64",
            "Conversion Value": "$10,333",
            "Cost per Result": "$43.58"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "288589",
            "Clicks": "4246",
            "Conversions": "64",
            "Conversion Value": "$10,805",
            "Cost per Result": "$59.93"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "186619",
            "Clicks": "2378",
            "Conversions": "63",
            "Conversion Value": "$10,945",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "173808",
            "Clicks": "4788",
            "Conversions": "46",
            "Conversion Value": "$12,446",
            "Cost per Result": "$26.78"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "448209",
            "Clicks": "3898",
            "Conversions": "59",
            "Conversion Value": "$15,626",
            "Cost per Result": "$61.66"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "318621",
            "Clicks": "3955",
            "Conversions": "58",
            "Conversion Value": "$12,318",
            "Cost per Result": "$54.41"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "199146",
            "Clicks": "5982",
            "Conversions": "76",
            "Conversion Value": "$12,569",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "152607",
            "Clicks": "4663",
            "Conversions": "79",
            "Conversion Value": "$13,413",
            "Cost per Result": "$45.56"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "283533",
            "Clicks": "6742",
            "Conversions": "48",
            "Conversion Value": "$15,608",
            "Cost per Result": "$41.06"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "207840",
            "Clicks": "6398",
            "Conversions": "49",
            "Conversion Value": "$6,277",
            "Cost per Result": "$60.85"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "400462",
            "Clicks": "3752",
            "Conversions": "41",
            "Conversion Value": "$11,486",
            "Cost per Result": "$39.84"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "172645",
            "Clicks": "5279",
            "Conversions": "50",
            "Conversion Value": "$14,374",
            "Cost per Result": "$45.86"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "114911",
            "Clicks": "3255",
            "Conversions": "83",
            "Conversion Value": "$7,315",
            "Cost per Result": "$17.14"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "326278",
            "Clicks": "6185",
            "Conversions": "76",
            "Conversion Value": "$5,356",
            "Cost per Result": "$40.74"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "287505",
            "Clicks": "4926",
            "Conversions": "69",
            "Conversion Value": "$8,184",
            "Cost per Result": "$34.46"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "285083",
            "Clicks": "6420",
            "Conversions": "59",
            "Conversion Value": "$5,452",
            "Cost per Result": "$15.25"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "202821",
            "Clicks": "6722",
            "Conversions": "52",
            "Conversion Value": "$15,940",
            "Cost per Result": "$32.67"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "427622",
            "Clicks": "5632",
            "Conversions": "86",
            "Conversion Value": "$5,455",
            "Cost per Result": "$32.14"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "428265",
            "Clicks": "4101",
            "Conversions": "81",
            "Conversion Value": "$10,479",
            "Cost per Result": "$63.19"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "301269",
            "Clicks": "3341",
            "Conversions": "81",
            "Conversion Value": "$8,892",
            "Cost per Result": "$26.53"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "268320",
            "Clicks": "6681",
            "Conversions": "79",
            "Conversion Value": "$13,806",
            "Cost per Result": "$64.06"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "162217",
            "Clicks": "5191",
            "Conversions": "83",
            "Conversion Value": "$9,927",
            "Cost per Result": "$21.01"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "473961",
            "Clicks": "2443",
            "Conversions": "52",
            "Conversion Value": "$12,408",
            "Cost per Result": "$25.06"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "136733",
            "Clicks": "4205",
            "Conversions": "81",
            "Conversion Value": "$12,258",
            "Cost per Result": "$27.81"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "311951",
            "Clicks": "3266",
            "Conversions": "44",
            "Conversion Value": "$11,935",
            "Cost per Result": "$18.76"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "142487",
            "Clicks": "3394",
            "Conversions": "52",
            "Conversion Value": "$9,342",
            "Cost per Result": "$20.26"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "187928",
            "Clicks": "3937",
            "Conversions": "85",
            "Conversion Value": "$4,684",
            "Cost per Result": "$36.38"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "459620",
            "Clicks": "3392",
            "Conversions": "49",
            "Conversion Value": "$8,859",
            "Cost per Result": "$39.54"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "350826",
            "Clicks": "4748",
            "Conversions": "61",
            "Conversion Value": "$11,333",
            "Cost per Result": "$21.78"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "332866",
            "Clicks": "5562",
            "Conversions": "87",
            "Conversion Value": "$13,344",
            "Cost per Result": "$64.95"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "291116",
            "Clicks": "2236",
            "Conversions": "79",
            "Conversion Value": "$12,012",
            "Cost per Result": "$57.20"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "215820",
            "Clicks": "4765",
            "Conversions": "40",
            "Conversion Value": "$5,044",
            "Cost per Result": "$23.19"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "194292",
            "Clicks": "4825",
            "Conversions": "79",
            "Conversion Value": "$5,734",
            "Cost per Result": "$49.30"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "489842",
            "Clicks": "6494",
            "Conversions": "41",
            "Conversion Value": "$9,485",
            "Cost per Result": "$45.28"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "443252",
            "Clicks": "6020",
            "Conversions": "79",
            "Conversion Value": "$15,260",
            "Cost per Result": "$45.59"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "312879",
            "Clicks": "3110",
            "Conversions": "75",
            "Conversion Value": "$6,039",
            "Cost per Result": "$57.15"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "304017",
            "Clicks": "4909",
            "Conversions": "85",
            "Conversion Value": "$15,485",
            "Cost per Result": "$20.98"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "143712",
            "Clicks": "5871",
            "Conversions": "60",
            "Conversion Value": "$12,564",
            "Cost per Result": "$40.01"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "310374",
            "Clicks": "2001",
            "Conversions": "48",
            "Conversion Value": "$4,679",
            "Cost per Result": "$55.57"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "466948",
            "Clicks": "6199",
            "Conversions": "54",
            "Conversion Value": "$12,334",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "222166",
            "Clicks": "6698",
            "Conversions": "76",
            "Conversion Value": "$7,556",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "392202",
            "Clicks": "6361",
            "Conversions": "81",
            "Conversion Value": "$15,048",
            "Cost per Result": "$29.12"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "413567",
            "Clicks": "4925",
            "Conversions": "77",
            "Conversion Value": "$15,845",
            "Cost per Result": "$38.36"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "151891",
            "Clicks": "4364",
            "Conversions": "74",
            "Conversion Value": "$7,352",
            "Cost per Result": "$19.40"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "401803",
            "Clicks": "6849",
            "Conversions": "69",
            "Conversion Value": "$11,405",
            "Cost per Result": "$29.89"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "167062",
            "Clicks": "3226",
            "Conversions": "88",
            "Conversion Value": "$10,579",
            "Cost per Result": "$58.32"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "310368",
            "Clicks": "2907",
            "Conversions": "48",
            "Conversion Value": "$10,876",
            "Cost per Result": "$39.66"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "336869",
            "Clicks": "4044",
            "Conversions": "62",
            "Conversion Value": "$10,263",
            "Cost per Result": "$54.27"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "136458",
            "Clicks": "3443",
            "Conversions": "47",
            "Conversion Value": "$10,435",
            "Cost per Result": "$64.09"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "438340",
            "Clicks": "5849",
            "Conversions": "61",
            "Conversion Value": "$6,347",
            "Cost per Result": "$47.92"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "229481",
            "Clicks": "4515",
            "Conversions": "48",
            "Conversion Value": "$15,777",
            "Cost per Result": "$57.30"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "258499",
            "Clicks": "6486",
            "Conversions": "55",
            "Conversion Value": "$13,248",
            "Cost per Result": "$27.29"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "261287",
            "Clicks": "3240",
            "Conversions": "47",
            "Conversion Value": "$10,297",
            "Cost per Result": "$43.54"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "236969",
            "Clicks": "5677",
            "Conversions": "72",
            "Conversion Value": "$14,296",
            "Cost per Result": "$35.09"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "161036",
            "Clicks": "5792",
            "Conversions": "61",
            "Conversion Value": "$6,342",
            "Cost per Result": "$35.05"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "445986",
            "Clicks": "2678",
            "Conversions": "85",
            "Conversion Value": "$4,413",
            "Cost per Result": "$38.03"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "386030",
            "Clicks": "4431",
            "Conversions": "62",
            "Conversion Value": "$10,080",
            "Cost per Result": "$27.80"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "404888",
            "Clicks": "2018",
            "Conversions": "54",
            "Conversion Value": "$7,648",
            "Cost per Result": "$61.57"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "398807",
            "Clicks": "6278",
            "Conversions": "73",
            "Conversion Value": "$10,132",
            "Cost per Result": "$17.80"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "402341",
            "Clicks": "5652",
            "Conversions": "57",
            "Conversion Value": "$11,759",
            "Cost per Result": "$19.02"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "220478",
            "Clicks": "6702",
            "Conversions": "90",
            "Conversion Value": "$10,620",
            "Cost per Result": "$62.60"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "222895",
            "Clicks": "6556",
            "Conversions": "71",
            "Conversion Value": "$12,190",
            "Cost per Result": "$18.48"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "437420",
            "Clicks": "2719",
            "Conversions": "72",
            "Conversion Value": "$4,347",
            "Cost per Result": "$33.07"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "342096",
            "Clicks": "6439",
            "Conversions": "56",
            "Conversion Value": "$6,217",
            "Cost per Result": "$33.24"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "265973",
            "Clicks": "3964",
            "Conversions": "46",
            "Conversion Value": "$5,775",
            "Cost per Result": "$49.91"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "119949",
            "Clicks": "4618",
            "Conversions": "78",
            "Conversion Value": "$7,002",
            "Cost per Result": "$36.63"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "146733",
            "Clicks": "2807",
            "Conversions": "79",
            "Conversion Value": "$15,256",
            "Cost per Result": "$41.43"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "395691",
            "Clicks": "5402",
            "Conversions": "41",
            "Conversion Value": "$4,770",
            "Cost per Result": "$48.71"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "371866",
            "Clicks": "5002",
            "Conversions": "54",
            "Conversion Value": "$5,415",
            "Cost per Result": "$51.09"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "133674",
            "Clicks": "6087",
            "Conversions": "51",
            "Conversion Value": "$9,036",
            "Cost per Result": "$62.54"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "277222",
            "Clicks": "3742",
            "Conversions": "56",
            "Conversion Value": "$15,910",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "112952",
            "Clicks": "6099",
            "Conversions": "51",
            "Conversion Value": "$5,984",
            "Cost per Result": "$33.30"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "193792",
            "Clicks": "3650",
            "Conversions": "84",
            "Conversion Value": "$5,234",
            "Cost per Result": "$53.46"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "270301",
            "Clicks": "6219",
            "Conversions": "66",
            "Conversion Value": "$5,947",
            "Cost per Result": "$40.72"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "194299",
            "Clicks": "6880",
            "Conversions": "82",
            "Conversion Value": "$15,447",
            "Cost per Result": "$18.14"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "332883",
            "Clicks": "4550",
            "Conversions": "55",
            "Conversion Value": "$14,063",
            "Cost per Result": "$48.56"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "240957",
            "Clicks": "2829",
            "Conversions": "83",
            "Conversion Value": "$12,177",
            "Cost per Result": "$24.88"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "203335",
            "Clicks": "6853",
            "Conversions": "54",
            "Conversion Value": "$6,023",
            "Cost per Result": "$28.11"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "496215",
            "Clicks": "4984",
            "Conversions": "47",
            "Conversion Value": "$15,122",
            "Cost per Result": "$28.52"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "134203",
            "Clicks": "2599",
            "Conversions": "71",
            "Conversion Value": "$11,593",
            "Cost per Result": "$59.75"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "362554",
            "Clicks": "5944",
            "Conversions": "83",
            "Conversion Value": "$11,345",
            "Cost per Result": "$40.18"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "253727",
            "Clicks": "6209",
            "Conversions": "60",
            "Conversion Value": "$4,853",
            "Cost per Result": "$49.10"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "439240",
            "Clicks": "2884",
            "Conversions": "60",
            "Conversion Value": "$9,484",
            "Cost per Result": "$33.93"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "440902",
            "Clicks": "5018",
            "Conversions": "65",
            "Conversion Value": "$5,665",
            "Cost per Result": "$18.78"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "198814",
            "Clicks": "4651",
            "Conversions": "69",
            "Conversion Value": "$8,701",
            "Cost per Result": "$35.00"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "110344",
            "Clicks": "5930",
            "Conversions": "47",
            "Conversion Value": "$9,524",
            "Cost per Result": "$58.71"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "475542",
            "Clicks": "5416",
            "Conversions": "82",
            "Conversion Value": "$15,785",
            "Cost per Result": "$35.38"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "129266",
            "Clicks": "6178",
            "Conversions": "87",
            "Conversion Value": "$8,894",
            "Cost per Result": "$17.50"
          }
        ]
      },
      "DeFiNewsData": {
        "name": "DeFi News",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "223993",
            "Clicks": "3917",
            "Conversions": "85",
            "Conversion Value": "$5,111",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "256703",
            "Clicks": "6279",
            "Conversions": "45",
            "Conversion Value": "$8,898",
            "Cost per Result": "$17.11"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "388281",
            "Clicks": "3206",
            "Conversions": "60",
            "Conversion Value": "$15,819",
            "Cost per Result": "$62.06"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "302598",
            "Clicks": "2370",
            "Conversions": "83",
            "Conversion Value": "$6,601",
            "Cost per Result": "$51.59"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "277133",
            "Clicks": "3249",
            "Conversions": "53",
            "Conversion Value": "$6,656",
            "Cost per Result": "$22.63"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "328123",
            "Clicks": "5669",
            "Conversions": "40",
            "Conversion Value": "$7,407",
            "Cost per Result": "$40.75"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "249954",
            "Clicks": "6016",
            "Conversions": "90",
            "Conversion Value": "$10,445",
            "Cost per Result": "$24.18"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "462825",
            "Clicks": "5002",
            "Conversions": "61",
            "Conversion Value": "$5,279",
            "Cost per Result": "$29.73"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "332225",
            "Clicks": "5942",
            "Conversions": "64",
            "Conversion Value": "$13,988",
            "Cost per Result": "$43.63"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "270613",
            "Clicks": "4780",
            "Conversions": "74",
            "Conversion Value": "$14,106",
            "Cost per Result": "$33.95"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "130970",
            "Clicks": "3439",
            "Conversions": "52",
            "Conversion Value": "$15,726",
            "Cost per Result": "$29.39"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "329700",
            "Clicks": "3433",
            "Conversions": "63",
            "Conversion Value": "$6,687",
            "Cost per Result": "$58.04"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "464002",
            "Clicks": "3649",
            "Conversions": "85",
            "Conversion Value": "$7,666",
            "Cost per Result": "$35.17"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "441022",
            "Clicks": "5675",
            "Conversions": "78",
            "Conversion Value": "$12,570",
            "Cost per Result": "$20.51"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "446464",
            "Clicks": "4754",
            "Conversions": "47",
            "Conversion Value": "$11,285",
            "Cost per Result": "$16.71"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "422387",
            "Clicks": "4381",
            "Conversions": "63",
            "Conversion Value": "$15,875",
            "Cost per Result": "$27.28"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "253922",
            "Clicks": "4449",
            "Conversions": "67",
            "Conversion Value": "$15,771",
            "Cost per Result": "$62.04"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "281344",
            "Clicks": "3895",
            "Conversions": "78",
            "Conversion Value": "$7,171",
            "Cost per Result": "$36.27"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "335763",
            "Clicks": "2890",
            "Conversions": "65",
            "Conversion Value": "$13,578",
            "Cost per Result": "$31.33"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "145797",
            "Clicks": "2601",
            "Conversions": "83",
            "Conversion Value": "$15,601",
            "Cost per Result": "$29.49"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "142896",
            "Clicks": "4755",
            "Conversions": "67",
            "Conversion Value": "$5,562",
            "Cost per Result": "$54.82"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "414852",
            "Clicks": "3374",
            "Conversions": "56",
            "Conversion Value": "$11,951",
            "Cost per Result": "$62.16"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "322323",
            "Clicks": "4795",
            "Conversions": "70",
            "Conversion Value": "$10,156",
            "Cost per Result": "$20.91"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "376884",
            "Clicks": "2449",
            "Conversions": "82",
            "Conversion Value": "$9,995",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "387410",
            "Clicks": "3485",
            "Conversions": "71",
            "Conversion Value": "$10,327",
            "Cost per Result": "$40.42"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "187061",
            "Clicks": "5122",
            "Conversions": "40",
            "Conversion Value": "$11,676",
            "Cost per Result": "$22.42"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "271878",
            "Clicks": "6250",
            "Conversions": "44",
            "Conversion Value": "$13,878",
            "Cost per Result": "$31.32"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "218590",
            "Clicks": "4677",
            "Conversions": "80",
            "Conversion Value": "$7,707",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "399423",
            "Clicks": "3317",
            "Conversions": "72",
            "Conversion Value": "$8,678",
            "Cost per Result": "$54.25"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "373041",
            "Clicks": "3940",
            "Conversions": "69",
            "Conversion Value": "$4,873",
            "Cost per Result": "$43.91"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "437211",
            "Clicks": "3097",
            "Conversions": "89",
            "Conversion Value": "$13,433",
            "Cost per Result": "$15.39"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "383756",
            "Clicks": "3663",
            "Conversions": "59",
            "Conversion Value": "$9,123",
            "Cost per Result": "$23.60"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "144572",
            "Clicks": "5916",
            "Conversions": "46",
            "Conversion Value": "$15,655",
            "Cost per Result": "$27.47"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "457312",
            "Clicks": "3697",
            "Conversions": "47",
            "Conversion Value": "$11,825",
            "Cost per Result": "$20.59"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "478315",
            "Clicks": "4157",
            "Conversions": "87",
            "Conversion Value": "$7,788",
            "Cost per Result": "$43.87"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "212332",
            "Clicks": "5986",
            "Conversions": "49",
            "Conversion Value": "$8,166",
            "Cost per Result": "$58.06"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "279834",
            "Clicks": "3929",
            "Conversions": "42",
            "Conversion Value": "$8,311",
            "Cost per Result": "$55.94"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "189884",
            "Clicks": "3132",
            "Conversions": "74",
            "Conversion Value": "$4,152",
            "Cost per Result": "$52.18"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "359637",
            "Clicks": "5892",
            "Conversions": "40",
            "Conversion Value": "$6,714",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "278735",
            "Clicks": "4682",
            "Conversions": "75",
            "Conversion Value": "$7,497",
            "Cost per Result": "$34.35"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "430964",
            "Clicks": "2457",
            "Conversions": "40",
            "Conversion Value": "$5,280",
            "Cost per Result": "$34.84"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "431705",
            "Clicks": "5816",
            "Conversions": "79",
            "Conversion Value": "$14,581",
            "Cost per Result": "$26.74"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "351438",
            "Clicks": "5091",
            "Conversions": "50",
            "Conversion Value": "$9,501",
            "Cost per Result": "$61.99"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "312208",
            "Clicks": "4580",
            "Conversions": "44",
            "Conversion Value": "$4,568",
            "Cost per Result": "$50.71"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "241652",
            "Clicks": "6381",
            "Conversions": "44",
            "Conversion Value": "$5,895",
            "Cost per Result": "$34.48"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "225086",
            "Clicks": "4575",
            "Conversions": "62",
            "Conversion Value": "$15,819",
            "Cost per Result": "$45.34"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "200251",
            "Clicks": "3379",
            "Conversions": "66",
            "Conversion Value": "$11,319",
            "Cost per Result": "$28.01"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "331581",
            "Clicks": "2815",
            "Conversions": "78",
            "Conversion Value": "$15,065",
            "Cost per Result": "$44.08"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "370478",
            "Clicks": "2646",
            "Conversions": "69",
            "Conversion Value": "$6,007",
            "Cost per Result": "$48.32"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "359442",
            "Clicks": "2698",
            "Conversions": "44",
            "Conversion Value": "$12,252",
            "Cost per Result": "$40.54"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "283766",
            "Clicks": "6373",
            "Conversions": "40",
            "Conversion Value": "$7,339",
            "Cost per Result": "$52.72"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "286810",
            "Clicks": "4998",
            "Conversions": "88",
            "Conversion Value": "$15,650",
            "Cost per Result": "$21.56"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "468399",
            "Clicks": "3172",
            "Conversions": "74",
            "Conversion Value": "$12,487",
            "Cost per Result": "$63.64"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "449913",
            "Clicks": "6242",
            "Conversions": "68",
            "Conversion Value": "$11,209",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "228374",
            "Clicks": "5695",
            "Conversions": "56",
            "Conversion Value": "$8,851",
            "Cost per Result": "$56.00"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "103205",
            "Clicks": "6553",
            "Conversions": "50",
            "Conversion Value": "$13,762",
            "Cost per Result": "$15.95"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "338632",
            "Clicks": "4477",
            "Conversions": "88",
            "Conversion Value": "$4,680",
            "Cost per Result": "$39.31"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "306248",
            "Clicks": "4028",
            "Conversions": "75",
            "Conversion Value": "$12,090",
            "Cost per Result": "$40.29"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "243013",
            "Clicks": "3864",
            "Conversions": "64",
            "Conversion Value": "$13,016",
            "Cost per Result": "$56.95"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "240297",
            "Clicks": "5410",
            "Conversions": "57",
            "Conversion Value": "$4,340",
            "Cost per Result": "$16.42"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "162453",
            "Clicks": "4545",
            "Conversions": "72",
            "Conversion Value": "$6,730",
            "Cost per Result": "$22.34"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "341547",
            "Clicks": "4297",
            "Conversions": "75",
            "Conversion Value": "$11,454",
            "Cost per Result": "$41.27"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "110812",
            "Clicks": "5282",
            "Conversions": "49",
            "Conversion Value": "$14,760",
            "Cost per Result": "$43.21"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "305744",
            "Clicks": "6596",
            "Conversions": "78",
            "Conversion Value": "$7,928",
            "Cost per Result": "$41.51"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "268535",
            "Clicks": "5722",
            "Conversions": "76",
            "Conversion Value": "$11,648",
            "Cost per Result": "$47.10"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "355276",
            "Clicks": "4537",
            "Conversions": "45",
            "Conversion Value": "$14,431",
            "Cost per Result": "$49.99"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "380455",
            "Clicks": "6141",
            "Conversions": "83",
            "Conversion Value": "$11,475",
            "Cost per Result": "$42.13"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "305783",
            "Clicks": "6228",
            "Conversions": "75",
            "Conversion Value": "$9,495",
            "Cost per Result": "$39.22"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "430142",
            "Clicks": "4625",
            "Conversions": "75",
            "Conversion Value": "$8,479",
            "Cost per Result": "$36.52"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "422182",
            "Clicks": "3678",
            "Conversions": "51",
            "Conversion Value": "$11,408",
            "Cost per Result": "$52.57"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "212043",
            "Clicks": "2129",
            "Conversions": "70",
            "Conversion Value": "$8,810",
            "Cost per Result": "$16.00"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "167156",
            "Clicks": "2128",
            "Conversions": "88",
            "Conversion Value": "$4,651",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "293595",
            "Clicks": "6136",
            "Conversions": "45",
            "Conversion Value": "$7,581",
            "Cost per Result": "$64.96"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "228544",
            "Clicks": "3751",
            "Conversions": "49",
            "Conversion Value": "$15,929",
            "Cost per Result": "$64.48"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "157677",
            "Clicks": "5202",
            "Conversions": "89",
            "Conversion Value": "$12,729",
            "Cost per Result": "$18.98"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "231527",
            "Clicks": "5519",
            "Conversions": "80",
            "Conversion Value": "$9,549",
            "Cost per Result": "$35.00"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "273085",
            "Clicks": "3578",
            "Conversions": "74",
            "Conversion Value": "$8,241",
            "Cost per Result": "$45.81"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "419439",
            "Clicks": "6065",
            "Conversions": "63",
            "Conversion Value": "$13,085",
            "Cost per Result": "$60.67"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "294796",
            "Clicks": "4532",
            "Conversions": "76",
            "Conversion Value": "$9,399",
            "Cost per Result": "$58.26"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "101891",
            "Clicks": "3271",
            "Conversions": "69",
            "Conversion Value": "$14,836",
            "Cost per Result": "$33.48"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "491039",
            "Clicks": "6068",
            "Conversions": "88",
            "Conversion Value": "$7,335",
            "Cost per Result": "$25.50"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "155465",
            "Clicks": "5166",
            "Conversions": "41",
            "Conversion Value": "$9,445",
            "Cost per Result": "$60.91"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "180773",
            "Clicks": "2014",
            "Conversions": "40",
            "Conversion Value": "$14,121",
            "Cost per Result": "$45.82"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "311479",
            "Clicks": "2797",
            "Conversions": "41",
            "Conversion Value": "$6,445",
            "Cost per Result": "$59.01"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "499895",
            "Clicks": "6856",
            "Conversions": "81",
            "Conversion Value": "$11,617",
            "Cost per Result": "$47.14"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "324653",
            "Clicks": "2147",
            "Conversions": "55",
            "Conversion Value": "$9,625",
            "Cost per Result": "$56.86"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "260120",
            "Clicks": "4303",
            "Conversions": "74",
            "Conversion Value": "$5,617",
            "Cost per Result": "$59.81"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "389212",
            "Clicks": "6920",
            "Conversions": "73",
            "Conversion Value": "$12,792",
            "Cost per Result": "$42.05"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "190289",
            "Clicks": "3195",
            "Conversions": "73",
            "Conversion Value": "$9,515",
            "Cost per Result": "$43.52"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "115032",
            "Clicks": "2374",
            "Conversions": "90",
            "Conversion Value": "$15,120",
            "Cost per Result": "$37.42"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "231044",
            "Clicks": "6443",
            "Conversions": "75",
            "Conversion Value": "$8,429",
            "Cost per Result": "$56.74"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "411752",
            "Clicks": "4033",
            "Conversions": "73",
            "Conversion Value": "$5,095",
            "Cost per Result": "$25.63"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "472263",
            "Clicks": "3914",
            "Conversions": "71",
            "Conversion Value": "$4,911",
            "Cost per Result": "$29.30"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "325604",
            "Clicks": "6307",
            "Conversions": "76",
            "Conversion Value": "$15,859",
            "Cost per Result": "$15.50"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "465999",
            "Clicks": "6616",
            "Conversions": "69",
            "Conversion Value": "$4,004",
            "Cost per Result": "$36.45"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "260705",
            "Clicks": "4436",
            "Conversions": "52",
            "Conversion Value": "$11,147",
            "Cost per Result": "$38.18"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "210286",
            "Clicks": "5379",
            "Conversions": "58",
            "Conversion Value": "$11,424",
            "Cost per Result": "$36.44"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "278232",
            "Clicks": "5916",
            "Conversions": "79",
            "Conversion Value": "$12,176",
            "Cost per Result": "$32.29"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "281277",
            "Clicks": "2359",
            "Conversions": "53",
            "Conversion Value": "$8,246",
            "Cost per Result": "$62.14"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "344578",
            "Clicks": "6954",
            "Conversions": "69",
            "Conversion Value": "$10,681",
            "Cost per Result": "$43.86"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "299512",
            "Clicks": "5015",
            "Conversions": "53",
            "Conversion Value": "$11,084",
            "Cost per Result": "$25.23"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "490240",
            "Clicks": "3131",
            "Conversions": "64",
            "Conversion Value": "$4,497",
            "Cost per Result": "$64.88"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "463548",
            "Clicks": "5910",
            "Conversions": "53",
            "Conversion Value": "$5,897",
            "Cost per Result": "$26.36"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "298570",
            "Clicks": "5902",
            "Conversions": "48",
            "Conversion Value": "$14,872",
            "Cost per Result": "$34.21"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "289791",
            "Clicks": "3870",
            "Conversions": "46",
            "Conversion Value": "$8,058",
            "Cost per Result": "$55.82"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "377230",
            "Clicks": "5861",
            "Conversions": "76",
            "Conversion Value": "$4,019",
            "Cost per Result": "$39.39"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "186655",
            "Clicks": "5001",
            "Conversions": "77",
            "Conversion Value": "$12,032",
            "Cost per Result": "$32.85"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "141317",
            "Clicks": "6975",
            "Conversions": "56",
            "Conversion Value": "$13,104",
            "Cost per Result": "$58.02"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "395679",
            "Clicks": "5968",
            "Conversions": "66",
            "Conversion Value": "$12,213",
            "Cost per Result": "$21.42"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "377973",
            "Clicks": "6396",
            "Conversions": "55",
            "Conversion Value": "$6,017",
            "Cost per Result": "$54.33"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "289899",
            "Clicks": "5510",
            "Conversions": "43",
            "Conversion Value": "$10,308",
            "Cost per Result": "$30.30"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "346234",
            "Clicks": "6872",
            "Conversions": "85",
            "Conversion Value": "$5,219",
            "Cost per Result": "$21.24"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "474747",
            "Clicks": "2372",
            "Conversions": "58",
            "Conversion Value": "$4,827",
            "Cost per Result": "$25.06"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "227904",
            "Clicks": "6415",
            "Conversions": "75",
            "Conversion Value": "$8,158",
            "Cost per Result": "$48.32"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "239361",
            "Clicks": "3854",
            "Conversions": "62",
            "Conversion Value": "$8,288",
            "Cost per Result": "$41.43"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "476068",
            "Clicks": "5340",
            "Conversions": "65",
            "Conversion Value": "$14,867",
            "Cost per Result": "$25.28"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "413175",
            "Clicks": "6935",
            "Conversions": "40",
            "Conversion Value": "$9,639",
            "Cost per Result": "$26.07"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "455096",
            "Clicks": "2407",
            "Conversions": "44",
            "Conversion Value": "$9,263",
            "Cost per Result": "$52.52"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "156797",
            "Clicks": "4921",
            "Conversions": "44",
            "Conversion Value": "$8,242",
            "Cost per Result": "$25.47"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "432858",
            "Clicks": "4852",
            "Conversions": "55",
            "Conversion Value": "$11,370",
            "Cost per Result": "$49.53"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "126762",
            "Clicks": "3678",
            "Conversions": "52",
            "Conversion Value": "$4,677",
            "Cost per Result": "$41.56"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "132291",
            "Clicks": "5037",
            "Conversions": "76",
            "Conversion Value": "$5,507",
            "Cost per Result": "$49.95"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "475941",
            "Clicks": "5634",
            "Conversions": "76",
            "Conversion Value": "$9,263",
            "Cost per Result": "$46.72"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "391453",
            "Clicks": "2302",
            "Conversions": "63",
            "Conversion Value": "$5,686",
            "Cost per Result": "$24.03"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "467097",
            "Clicks": "2653",
            "Conversions": "82",
            "Conversion Value": "$15,976",
            "Cost per Result": "$60.30"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "490890",
            "Clicks": "5859",
            "Conversions": "73",
            "Conversion Value": "$10,831",
            "Cost per Result": "$23.68"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "473997",
            "Clicks": "4939",
            "Conversions": "69",
            "Conversion Value": "$9,392",
            "Cost per Result": "$45.55"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "313293",
            "Clicks": "3963",
            "Conversions": "56",
            "Conversion Value": "$6,592",
            "Cost per Result": "$28.81"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "316778",
            "Clicks": "6881",
            "Conversions": "78",
            "Conversion Value": "$5,264",
            "Cost per Result": "$63.70"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "387498",
            "Clicks": "4875",
            "Conversions": "57",
            "Conversion Value": "$6,616",
            "Cost per Result": "$60.79"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "326490",
            "Clicks": "3146",
            "Conversions": "60",
            "Conversion Value": "$12,605",
            "Cost per Result": "$20.43"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "378150",
            "Clicks": "4434",
            "Conversions": "56",
            "Conversion Value": "$6,138",
            "Cost per Result": "$49.28"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "464260",
            "Clicks": "2693",
            "Conversions": "54",
            "Conversion Value": "$4,131",
            "Cost per Result": "$49.12"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "210977",
            "Clicks": "2576",
            "Conversions": "81",
            "Conversion Value": "$12,277",
            "Cost per Result": "$41.53"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "246499",
            "Clicks": "2032",
            "Conversions": "66",
            "Conversion Value": "$13,117",
            "Cost per Result": "$31.54"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "125062",
            "Clicks": "2471",
            "Conversions": "46",
            "Conversion Value": "$4,696",
            "Cost per Result": "$61.17"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "267961",
            "Clicks": "4392",
            "Conversions": "53",
            "Conversion Value": "$8,888",
            "Cost per Result": "$15.65"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "427637",
            "Clicks": "5500",
            "Conversions": "43",
            "Conversion Value": "$11,363",
            "Cost per Result": "$31.91"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "294725",
            "Clicks": "2097",
            "Conversions": "66",
            "Conversion Value": "$8,398",
            "Cost per Result": "$26.75"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "159703",
            "Clicks": "6384",
            "Conversions": "70",
            "Conversion Value": "$5,628",
            "Cost per Result": "$64.30"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "250990",
            "Clicks": "6616",
            "Conversions": "83",
            "Conversion Value": "$9,550",
            "Cost per Result": "$51.61"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "384500",
            "Clicks": "4828",
            "Conversions": "54",
            "Conversion Value": "$7,190",
            "Cost per Result": "$62.77"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "384721",
            "Clicks": "4647",
            "Conversions": "69",
            "Conversion Value": "$6,628",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "363916",
            "Clicks": "4172",
            "Conversions": "41",
            "Conversion Value": "$15,596",
            "Cost per Result": "$31.71"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "211926",
            "Clicks": "4268",
            "Conversions": "62",
            "Conversion Value": "$14,814",
            "Cost per Result": "$55.75"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "225374",
            "Clicks": "3243",
            "Conversions": "59",
            "Conversion Value": "$13,673",
            "Cost per Result": "$20.81"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "257998",
            "Clicks": "4139",
            "Conversions": "87",
            "Conversion Value": "$7,991",
            "Cost per Result": "$28.69"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "234214",
            "Clicks": "3677",
            "Conversions": "69",
            "Conversion Value": "$6,991",
            "Cost per Result": "$39.77"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "193769",
            "Clicks": "5135",
            "Conversions": "89",
            "Conversion Value": "$8,810",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "221657",
            "Clicks": "6059",
            "Conversions": "66",
            "Conversion Value": "$15,193",
            "Cost per Result": "$45.00"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "166625",
            "Clicks": "5382",
            "Conversions": "89",
            "Conversion Value": "$4,322",
            "Cost per Result": "$55.76"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "279521",
            "Clicks": "5817",
            "Conversions": "82",
            "Conversion Value": "$13,760",
            "Cost per Result": "$44.79"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "322307",
            "Clicks": "4557",
            "Conversions": "51",
            "Conversion Value": "$14,699",
            "Cost per Result": "$15.95"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "279624",
            "Clicks": "3634",
            "Conversions": "72",
            "Conversion Value": "$13,801",
            "Cost per Result": "$17.02"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "276317",
            "Clicks": "5704",
            "Conversions": "58",
            "Conversion Value": "$14,045",
            "Cost per Result": "$57.27"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "324438",
            "Clicks": "4389",
            "Conversions": "83",
            "Conversion Value": "$9,002",
            "Cost per Result": "$24.54"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "306763",
            "Clicks": "4664",
            "Conversions": "47",
            "Conversion Value": "$4,770",
            "Cost per Result": "$34.40"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "258199",
            "Clicks": "3224",
            "Conversions": "46",
            "Conversion Value": "$6,887",
            "Cost per Result": "$55.88"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "390901",
            "Clicks": "4190",
            "Conversions": "79",
            "Conversion Value": "$13,155",
            "Cost per Result": "$49.43"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "292876",
            "Clicks": "4046",
            "Conversions": "58",
            "Conversion Value": "$4,370",
            "Cost per Result": "$55.76"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "289771",
            "Clicks": "2686",
            "Conversions": "64",
            "Conversion Value": "$14,758",
            "Cost per Result": "$33.54"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "215958",
            "Clicks": "4712",
            "Conversions": "57",
            "Conversion Value": "$15,722",
            "Cost per Result": "$24.76"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "299449",
            "Clicks": "3436",
            "Conversions": "82",
            "Conversion Value": "$10,230",
            "Cost per Result": "$42.14"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "387877",
            "Clicks": "3762",
            "Conversions": "60",
            "Conversion Value": "$13,112",
            "Cost per Result": "$54.28"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "140324",
            "Clicks": "3841",
            "Conversions": "57",
            "Conversion Value": "$7,436",
            "Cost per Result": "$43.24"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "403407",
            "Clicks": "6095",
            "Conversions": "83",
            "Conversion Value": "$10,828",
            "Cost per Result": "$33.20"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "460243",
            "Clicks": "4046",
            "Conversions": "89",
            "Conversion Value": "$4,000",
            "Cost per Result": "$21.51"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "136968",
            "Clicks": "6534",
            "Conversions": "58",
            "Conversion Value": "$10,958",
            "Cost per Result": "$61.29"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "202519",
            "Clicks": "4099",
            "Conversions": "78",
            "Conversion Value": "$15,360",
            "Cost per Result": "$57.45"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "126308",
            "Clicks": "6244",
            "Conversions": "61",
            "Conversion Value": "$14,186",
            "Cost per Result": "$18.45"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "461142",
            "Clicks": "3265",
            "Conversions": "52",
            "Conversion Value": "$15,972",
            "Cost per Result": "$64.93"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "338418",
            "Clicks": "2114",
            "Conversions": "84",
            "Conversion Value": "$11,696",
            "Cost per Result": "$16.99"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "144517",
            "Clicks": "6912",
            "Conversions": "80",
            "Conversion Value": "$10,706",
            "Cost per Result": "$59.55"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "128145",
            "Clicks": "4187",
            "Conversions": "73",
            "Conversion Value": "$12,550",
            "Cost per Result": "$17.99"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "333796",
            "Clicks": "6750",
            "Conversions": "41",
            "Conversion Value": "$7,909",
            "Cost per Result": "$63.54"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "122273",
            "Clicks": "3295",
            "Conversions": "55",
            "Conversion Value": "$15,329",
            "Cost per Result": "$23.13"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "296344",
            "Clicks": "5654",
            "Conversions": "79",
            "Conversion Value": "$13,729",
            "Cost per Result": "$25.12"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "474509",
            "Clicks": "6095",
            "Conversions": "61",
            "Conversion Value": "$14,977",
            "Cost per Result": "$49.48"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "149557",
            "Clicks": "6420",
            "Conversions": "89",
            "Conversion Value": "$14,699",
            "Cost per Result": "$58.66"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "331076",
            "Clicks": "2609",
            "Conversions": "49",
            "Conversion Value": "$14,112",
            "Cost per Result": "$57.08"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "121720",
            "Clicks": "5069",
            "Conversions": "77",
            "Conversion Value": "$8,185",
            "Cost per Result": "$54.05"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "269539",
            "Clicks": "3221",
            "Conversions": "50",
            "Conversion Value": "$14,688",
            "Cost per Result": "$45.08"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "387827",
            "Clicks": "2793",
            "Conversions": "89",
            "Conversion Value": "$15,184",
            "Cost per Result": "$18.22"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "228650",
            "Clicks": "5384",
            "Conversions": "64",
            "Conversion Value": "$15,585",
            "Cost per Result": "$63.18"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "438310",
            "Clicks": "5509",
            "Conversions": "90",
            "Conversion Value": "$14,403",
            "Cost per Result": "$17.86"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "494243",
            "Clicks": "6298",
            "Conversions": "90",
            "Conversion Value": "$14,921",
            "Cost per Result": "$44.94"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "142949",
            "Clicks": "6940",
            "Conversions": "66",
            "Conversion Value": "$10,984",
            "Cost per Result": "$39.73"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "361421",
            "Clicks": "6728",
            "Conversions": "85",
            "Conversion Value": "$13,867",
            "Cost per Result": "$61.48"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "482393",
            "Clicks": "3129",
            "Conversions": "70",
            "Conversion Value": "$10,222",
            "Cost per Result": "$55.84"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "428834",
            "Clicks": "4234",
            "Conversions": "77",
            "Conversion Value": "$6,265",
            "Cost per Result": "$38.34"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "323688",
            "Clicks": "5464",
            "Conversions": "53",
            "Conversion Value": "$6,199",
            "Cost per Result": "$63.82"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "225174",
            "Clicks": "4405",
            "Conversions": "57",
            "Conversion Value": "$11,015",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "308104",
            "Clicks": "2480",
            "Conversions": "48",
            "Conversion Value": "$11,149",
            "Cost per Result": "$64.96"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "305965",
            "Clicks": "2565",
            "Conversions": "83",
            "Conversion Value": "$15,199",
            "Cost per Result": "$51.74"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "199674",
            "Clicks": "6481",
            "Conversions": "68",
            "Conversion Value": "$7,019",
            "Cost per Result": "$20.75"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "431230",
            "Clicks": "6515",
            "Conversions": "50",
            "Conversion Value": "$7,193",
            "Cost per Result": "$34.70"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "118040",
            "Clicks": "5140",
            "Conversions": "70",
            "Conversion Value": "$15,355",
            "Cost per Result": "$55.06"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "439016",
            "Clicks": "4750",
            "Conversions": "61",
            "Conversion Value": "$12,167",
            "Cost per Result": "$20.13"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "362133",
            "Clicks": "5617",
            "Conversions": "75",
            "Conversion Value": "$13,894",
            "Cost per Result": "$58.35"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "119504",
            "Clicks": "2581",
            "Conversions": "61",
            "Conversion Value": "$13,669",
            "Cost per Result": "$49.59"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "141144",
            "Clicks": "4120",
            "Conversions": "83",
            "Conversion Value": "$14,982",
            "Cost per Result": "$29.82"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "479352",
            "Clicks": "2287",
            "Conversions": "44",
            "Conversion Value": "$12,502",
            "Cost per Result": "$41.83"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "191577",
            "Clicks": "3467",
            "Conversions": "44",
            "Conversion Value": "$15,847",
            "Cost per Result": "$15.62"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "231941",
            "Clicks": "3943",
            "Conversions": "54",
            "Conversion Value": "$8,319",
            "Cost per Result": "$31.53"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "405464",
            "Clicks": "3797",
            "Conversions": "41",
            "Conversion Value": "$12,326",
            "Cost per Result": "$58.87"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "478736",
            "Clicks": "2664",
            "Conversions": "55",
            "Conversion Value": "$12,863",
            "Cost per Result": "$56.95"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "463044",
            "Clicks": "4780",
            "Conversions": "58",
            "Conversion Value": "$12,833",
            "Cost per Result": "$61.37"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "338320",
            "Clicks": "4310",
            "Conversions": "83",
            "Conversion Value": "$9,406",
            "Cost per Result": "$35.25"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "397478",
            "Clicks": "4005",
            "Conversions": "48",
            "Conversion Value": "$13,971",
            "Cost per Result": "$23.62"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "467847",
            "Clicks": "2261",
            "Conversions": "75",
            "Conversion Value": "$11,582",
            "Cost per Result": "$56.97"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "169833",
            "Clicks": "4889",
            "Conversions": "55",
            "Conversion Value": "$15,752",
            "Cost per Result": "$60.33"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "197467",
            "Clicks": "3970",
            "Conversions": "41",
            "Conversion Value": "$9,742",
            "Cost per Result": "$28.80"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "156341",
            "Clicks": "4783",
            "Conversions": "59",
            "Conversion Value": "$7,855",
            "Cost per Result": "$52.19"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "109116",
            "Clicks": "4715",
            "Conversions": "78",
            "Conversion Value": "$7,424",
            "Cost per Result": "$25.91"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "209640",
            "Clicks": "5534",
            "Conversions": "62",
            "Conversion Value": "$8,794",
            "Cost per Result": "$62.87"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "340397",
            "Clicks": "6423",
            "Conversions": "61",
            "Conversion Value": "$10,220",
            "Cost per Result": "$44.88"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "420560",
            "Clicks": "4954",
            "Conversions": "58",
            "Conversion Value": "$5,508",
            "Cost per Result": "$54.28"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "241866",
            "Clicks": "3697",
            "Conversions": "63",
            "Conversion Value": "$9,745",
            "Cost per Result": "$33.74"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "452285",
            "Clicks": "5300",
            "Conversions": "69",
            "Conversion Value": "$5,257",
            "Cost per Result": "$35.65"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "277935",
            "Clicks": "4118",
            "Conversions": "42",
            "Conversion Value": "$6,799",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "426206",
            "Clicks": "3371",
            "Conversions": "54",
            "Conversion Value": "$6,315",
            "Cost per Result": "$27.61"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "209994",
            "Clicks": "2700",
            "Conversions": "79",
            "Conversion Value": "$15,876",
            "Cost per Result": "$49.21"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "496228",
            "Clicks": "4324",
            "Conversions": "41",
            "Conversion Value": "$9,778",
            "Cost per Result": "$61.96"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "463898",
            "Clicks": "5612",
            "Conversions": "79",
            "Conversion Value": "$6,624",
            "Cost per Result": "$35.18"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "249959",
            "Clicks": "6187",
            "Conversions": "88",
            "Conversion Value": "$4,704",
            "Cost per Result": "$31.45"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "347220",
            "Clicks": "3246",
            "Conversions": "87",
            "Conversion Value": "$14,823",
            "Cost per Result": "$62.24"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "121363",
            "Clicks": "3811",
            "Conversions": "77",
            "Conversion Value": "$9,137",
            "Cost per Result": "$40.45"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "485905",
            "Clicks": "4665",
            "Conversions": "53",
            "Conversion Value": "$4,827",
            "Cost per Result": "$61.47"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "196893",
            "Clicks": "6944",
            "Conversions": "83",
            "Conversion Value": "$14,515",
            "Cost per Result": "$61.22"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "292165",
            "Clicks": "6832",
            "Conversions": "78",
            "Conversion Value": "$11,932",
            "Cost per Result": "$55.92"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "258547",
            "Clicks": "3151",
            "Conversions": "78",
            "Conversion Value": "$6,371",
            "Cost per Result": "$50.00"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "309420",
            "Clicks": "2720",
            "Conversions": "56",
            "Conversion Value": "$15,912",
            "Cost per Result": "$25.97"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "252051",
            "Clicks": "3653",
            "Conversions": "64",
            "Conversion Value": "$7,585",
            "Cost per Result": "$60.54"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "115319",
            "Clicks": "5090",
            "Conversions": "72",
            "Conversion Value": "$5,190",
            "Cost per Result": "$44.10"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "421638",
            "Clicks": "6133",
            "Conversions": "46",
            "Conversion Value": "$8,859",
            "Cost per Result": "$26.61"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "166643",
            "Clicks": "4778",
            "Conversions": "76",
            "Conversion Value": "$14,215",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "446510",
            "Clicks": "3250",
            "Conversions": "85",
            "Conversion Value": "$15,727",
            "Cost per Result": "$23.58"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "162190",
            "Clicks": "6867",
            "Conversions": "60",
            "Conversion Value": "$8,170",
            "Cost per Result": "$43.47"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "385789",
            "Clicks": "2793",
            "Conversions": "59",
            "Conversion Value": "$8,386",
            "Cost per Result": "$15.74"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "260375",
            "Clicks": "4857",
            "Conversions": "71",
            "Conversion Value": "$5,072",
            "Cost per Result": "$49.61"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "132777",
            "Clicks": "4010",
            "Conversions": "48",
            "Conversion Value": "$10,758",
            "Cost per Result": "$21.06"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "251726",
            "Clicks": "5343",
            "Conversions": "43",
            "Conversion Value": "$7,353",
            "Cost per Result": "$28.46"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "446059",
            "Clicks": "4971",
            "Conversions": "70",
            "Conversion Value": "$13,895",
            "Cost per Result": "$19.26"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "244002",
            "Clicks": "6161",
            "Conversions": "83",
            "Conversion Value": "$5,637",
            "Cost per Result": "$27.80"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "220986",
            "Clicks": "5545",
            "Conversions": "49",
            "Conversion Value": "$4,168",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "216331",
            "Clicks": "2921",
            "Conversions": "48",
            "Conversion Value": "$14,683",
            "Cost per Result": "$23.10"
          }
        ]
      }
    },
    "NFT": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1280629",
            "Clicks": "12606",
            "Conversions": "258",
            "Conversion Value": "$37,066",
            "Cost per Result": "$61.40"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1360811",
            "Clicks": "19101",
            "Conversions": "234",
            "Conversion Value": "$45,025",
            "Cost per Result": "$61.29"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "1170310",
            "Clicks": "17075",
            "Conversions": "279",
            "Conversion Value": "$44,814",
            "Cost per Result": "$42.27"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "994016",
            "Clicks": "16474",
            "Conversions": "255",
            "Conversion Value": "$43,651",
            "Cost per Result": "$30.96"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1067597",
            "Clicks": "14303",
            "Conversions": "270",
            "Conversion Value": "$40,800",
            "Cost per Result": "$45.04"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1352508",
            "Clicks": "21067",
            "Conversions": "264",
            "Conversion Value": "$37,921",
            "Cost per Result": "$44.74"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "743338",
            "Clicks": "15440",
            "Conversions": "297",
            "Conversion Value": "$40,001",
            "Cost per Result": "$51.27"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1206502",
            "Clicks": "15931",
            "Conversions": "289",
            "Conversion Value": "$35,817",
            "Cost per Result": "$42.41"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1141559",
            "Clicks": "16673",
            "Conversions": "275",
            "Conversion Value": "$51,021",
            "Cost per Result": "$43.99"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "879820",
            "Clicks": "18423",
            "Conversions": "285",
            "Conversion Value": "$38,708",
            "Cost per Result": "$59.50"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1477029",
            "Clicks": "18863",
            "Conversions": "296",
            "Conversion Value": "$44,476",
            "Cost per Result": "$55.42"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1032043",
            "Clicks": "20484",
            "Conversions": "314",
            "Conversion Value": "$33,861",
            "Cost per Result": "$63.40"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "945278",
            "Clicks": "25695",
            "Conversions": "263",
            "Conversion Value": "$30,694",
            "Cost per Result": "$52.27"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "782015",
            "Clicks": "17178",
            "Conversions": "271",
            "Conversion Value": "$34,490",
            "Cost per Result": "$60.78"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1432356",
            "Clicks": "18593",
            "Conversions": "278",
            "Conversion Value": "$46,975",
            "Cost per Result": "$42.77"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "871539",
            "Clicks": "16661",
            "Conversions": "284",
            "Conversion Value": "$30,770",
            "Cost per Result": "$42.64"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1136477",
            "Clicks": "13317",
            "Conversions": "225",
            "Conversion Value": "$43,034",
            "Cost per Result": "$57.42"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "984122",
            "Clicks": "20783",
            "Conversions": "286",
            "Conversion Value": "$33,470",
            "Cost per Result": "$52.85"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1316443",
            "Clicks": "20254",
            "Conversions": "279",
            "Conversion Value": "$39,078",
            "Cost per Result": "$56.75"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1071905",
            "Clicks": "19535",
            "Conversions": "259",
            "Conversion Value": "$56,890",
            "Cost per Result": "$43.31"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1182593",
            "Clicks": "14636",
            "Conversions": "243",
            "Conversion Value": "$39,004",
            "Cost per Result": "$42.77"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1237379",
            "Clicks": "14489",
            "Conversions": "336",
            "Conversion Value": "$50,505",
            "Cost per Result": "$39.33"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1290319",
            "Clicks": "19020",
            "Conversions": "247",
            "Conversion Value": "$47,233",
            "Cost per Result": "$43.05"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1323016",
            "Clicks": "18034",
            "Conversions": "214",
            "Conversion Value": "$43,570",
            "Cost per Result": "$62.49"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1254282",
            "Clicks": "22850",
            "Conversions": "307",
            "Conversion Value": "$38,016",
            "Cost per Result": "$67.00"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1164188",
            "Clicks": "19796",
            "Conversions": "287",
            "Conversion Value": "$52,100",
            "Cost per Result": "$55.14"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "1068702",
            "Clicks": "16420",
            "Conversions": "263",
            "Conversion Value": "$27,643",
            "Cost per Result": "$52.73"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "866646",
            "Clicks": "13813",
            "Conversions": "233",
            "Conversion Value": "$47,208",
            "Cost per Result": "$47.43"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "1415512",
            "Clicks": "15981",
            "Conversions": "292",
            "Conversion Value": "$42,951",
            "Cost per Result": "$55.32"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1412118",
            "Clicks": "16338",
            "Conversions": "297",
            "Conversion Value": "$51,382",
            "Cost per Result": "$43.48"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1213600",
            "Clicks": "15087",
            "Conversions": "279",
            "Conversion Value": "$50,219",
            "Cost per Result": "$46.06"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1032201",
            "Clicks": "17298",
            "Conversions": "231",
            "Conversion Value": "$36,433",
            "Cost per Result": "$61.64"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1578532",
            "Clicks": "19876",
            "Conversions": "225",
            "Conversion Value": "$38,585",
            "Cost per Result": "$51.66"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "1504278",
            "Clicks": "13107",
            "Conversions": "282",
            "Conversion Value": "$36,878",
            "Cost per Result": "$48.01"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1518838",
            "Clicks": "22445",
            "Conversions": "298",
            "Conversion Value": "$43,320",
            "Cost per Result": "$52.20"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1521653",
            "Clicks": "15531",
            "Conversions": "225",
            "Conversion Value": "$30,177",
            "Cost per Result": "$49.48"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1612182",
            "Clicks": "15637",
            "Conversions": "282",
            "Conversion Value": "$33,796",
            "Cost per Result": "$60.42"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1206278",
            "Clicks": "18452",
            "Conversions": "297",
            "Conversion Value": "$35,621",
            "Cost per Result": "$44.16"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "1027962",
            "Clicks": "17037",
            "Conversions": "275",
            "Conversion Value": "$44,251",
            "Cost per Result": "$51.07"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "824073",
            "Clicks": "15652",
            "Conversions": "245",
            "Conversion Value": "$38,821",
            "Cost per Result": "$60.87"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1162413",
            "Clicks": "16592",
            "Conversions": "301",
            "Conversion Value": "$38,815",
            "Cost per Result": "$55.84"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1312711",
            "Clicks": "14041",
            "Conversions": "256",
            "Conversion Value": "$47,341",
            "Cost per Result": "$55.83"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1123269",
            "Clicks": "17135",
            "Conversions": "249",
            "Conversion Value": "$28,935",
            "Cost per Result": "$62.99"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1147884",
            "Clicks": "20085",
            "Conversions": "204",
            "Conversion Value": "$32,646",
            "Cost per Result": "$56.57"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1164169",
            "Clicks": "19678",
            "Conversions": "203",
            "Conversion Value": "$47,133",
            "Cost per Result": "$57.26"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "1088971",
            "Clicks": "21845",
            "Conversions": "248",
            "Conversion Value": "$38,278",
            "Cost per Result": "$67.16"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1068977",
            "Clicks": "20187",
            "Conversions": "257",
            "Conversion Value": "$34,436",
            "Cost per Result": "$50.88"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1316735",
            "Clicks": "19927",
            "Conversions": "254",
            "Conversion Value": "$41,238",
            "Cost per Result": "$53.64"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1294064",
            "Clicks": "19352",
            "Conversions": "247",
            "Conversion Value": "$37,555",
            "Cost per Result": "$56.82"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "787002",
            "Clicks": "15080",
            "Conversions": "292",
            "Conversion Value": "$30,311",
            "Cost per Result": "$51.27"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1186965",
            "Clicks": "23866",
            "Conversions": "230",
            "Conversion Value": "$31,492",
            "Cost per Result": "$64.86"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1182814",
            "Clicks": "16623",
            "Conversions": "314",
            "Conversion Value": "$38,555",
            "Cost per Result": "$50.93"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "933667",
            "Clicks": "18176",
            "Conversions": "271",
            "Conversion Value": "$49,215",
            "Cost per Result": "$64.22"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1486405",
            "Clicks": "19312",
            "Conversions": "225",
            "Conversion Value": "$43,925",
            "Cost per Result": "$57.58"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "1288721",
            "Clicks": "19718",
            "Conversions": "261",
            "Conversion Value": "$35,455",
            "Cost per Result": "$47.09"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1469143",
            "Clicks": "13549",
            "Conversions": "305",
            "Conversion Value": "$50,767",
            "Cost per Result": "$53.52"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1183466",
            "Clicks": "17681",
            "Conversions": "253",
            "Conversion Value": "$31,261",
            "Cost per Result": "$51.51"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "995105",
            "Clicks": "17925",
            "Conversions": "293",
            "Conversion Value": "$43,659",
            "Cost per Result": "$59.78"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1482494",
            "Clicks": "18756",
            "Conversions": "237",
            "Conversion Value": "$38,501",
            "Cost per Result": "$47.76"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1160246",
            "Clicks": "19064",
            "Conversions": "242",
            "Conversion Value": "$46,019",
            "Cost per Result": "$49.98"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1287898",
            "Clicks": "25430",
            "Conversions": "279",
            "Conversion Value": "$49,263",
            "Cost per Result": "$57.77"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1276658",
            "Clicks": "17754",
            "Conversions": "236",
            "Conversion Value": "$36,554",
            "Cost per Result": "$44.17"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1419992",
            "Clicks": "14883",
            "Conversions": "259",
            "Conversion Value": "$44,207",
            "Cost per Result": "$58.81"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1018379",
            "Clicks": "25353",
            "Conversions": "265",
            "Conversion Value": "$34,771",
            "Cost per Result": "$45.81"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1302182",
            "Clicks": "19871",
            "Conversions": "294",
            "Conversion Value": "$38,255",
            "Cost per Result": "$57.37"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1454018",
            "Clicks": "14908",
            "Conversions": "289",
            "Conversion Value": "$27,203",
            "Cost per Result": "$56.63"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1642205",
            "Clicks": "21654",
            "Conversions": "275",
            "Conversion Value": "$39,141",
            "Cost per Result": "$46.60"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1310351",
            "Clicks": "18548",
            "Conversions": "258",
            "Conversion Value": "$42,261",
            "Cost per Result": "$60.73"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1151970",
            "Clicks": "13201",
            "Conversions": "212",
            "Conversion Value": "$39,680",
            "Cost per Result": "$60.59"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1272438",
            "Clicks": "18626",
            "Conversions": "335",
            "Conversion Value": "$41,863",
            "Cost per Result": "$64.29"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1329300",
            "Clicks": "15588",
            "Conversions": "209",
            "Conversion Value": "$29,206",
            "Cost per Result": "$54.06"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1587734",
            "Clicks": "19691",
            "Conversions": "236",
            "Conversion Value": "$43,224",
            "Cost per Result": "$50.29"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1207154",
            "Clicks": "19285",
            "Conversions": "246",
            "Conversion Value": "$38,384",
            "Cost per Result": "$52.37"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "987005",
            "Clicks": "16542",
            "Conversions": "232",
            "Conversion Value": "$50,233",
            "Cost per Result": "$57.21"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1654842",
            "Clicks": "15256",
            "Conversions": "283",
            "Conversion Value": "$53,153",
            "Cost per Result": "$55.50"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1171111",
            "Clicks": "23378",
            "Conversions": "210",
            "Conversion Value": "$36,920",
            "Cost per Result": "$46.78"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1511466",
            "Clicks": "18638",
            "Conversions": "252",
            "Conversion Value": "$38,126",
            "Cost per Result": "$43.79"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1373072",
            "Clicks": "18756",
            "Conversions": "266",
            "Conversion Value": "$41,330",
            "Cost per Result": "$52.63"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1489874",
            "Clicks": "19164",
            "Conversions": "281",
            "Conversion Value": "$45,703",
            "Cost per Result": "$51.92"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1646826",
            "Clicks": "16436",
            "Conversions": "258",
            "Conversion Value": "$32,049",
            "Cost per Result": "$52.79"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "679860",
            "Clicks": "15535",
            "Conversions": "230",
            "Conversion Value": "$47,991",
            "Cost per Result": "$57.66"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1140693",
            "Clicks": "14922",
            "Conversions": "244",
            "Conversion Value": "$45,019",
            "Cost per Result": "$59.42"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1597757",
            "Clicks": "17798",
            "Conversions": "246",
            "Conversion Value": "$50,409",
            "Cost per Result": "$56.37"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1192438",
            "Clicks": "22411",
            "Conversions": "325",
            "Conversion Value": "$42,072",
            "Cost per Result": "$52.36"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1195949",
            "Clicks": "18483",
            "Conversions": "243",
            "Conversion Value": "$25,370",
            "Cost per Result": "$39.29"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1267004",
            "Clicks": "18804",
            "Conversions": "235",
            "Conversion Value": "$45,567",
            "Cost per Result": "$53.82"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1505925",
            "Clicks": "15295",
            "Conversions": "274",
            "Conversion Value": "$45,442",
            "Cost per Result": "$42.68"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1305036",
            "Clicks": "14313",
            "Conversions": "252",
            "Conversion Value": "$37,977",
            "Cost per Result": "$45.19"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1012036",
            "Clicks": "19645",
            "Conversions": "260",
            "Conversion Value": "$46,137",
            "Cost per Result": "$41.32"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1443253",
            "Clicks": "12821",
            "Conversions": "276",
            "Conversion Value": "$39,828",
            "Cost per Result": "$43.70"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1136053",
            "Clicks": "14665",
            "Conversions": "217",
            "Conversion Value": "$44,105",
            "Cost per Result": "$55.37"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1114218",
            "Clicks": "17981",
            "Conversions": "286",
            "Conversion Value": "$43,502",
            "Cost per Result": "$48.72"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1174720",
            "Clicks": "21225",
            "Conversions": "311",
            "Conversion Value": "$40,877",
            "Cost per Result": "$61.94"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1366014",
            "Clicks": "16641",
            "Conversions": "252",
            "Conversion Value": "$50,331",
            "Cost per Result": "$51.85"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1419458",
            "Clicks": "21948",
            "Conversions": "251",
            "Conversion Value": "$30,017",
            "Cost per Result": "$50.42"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1319484",
            "Clicks": "21123",
            "Conversions": "267",
            "Conversion Value": "$35,304",
            "Cost per Result": "$61.13"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1269223",
            "Clicks": "22366",
            "Conversions": "291",
            "Conversion Value": "$44,031",
            "Cost per Result": "$52.78"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "1384974",
            "Clicks": "13154",
            "Conversions": "282",
            "Conversion Value": "$44,837",
            "Cost per Result": "$57.83"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1319845",
            "Clicks": "19358",
            "Conversions": "267",
            "Conversion Value": "$28,496",
            "Cost per Result": "$58.81"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "1299539",
            "Clicks": "19325",
            "Conversions": "247",
            "Conversion Value": "$31,573",
            "Cost per Result": "$59.50"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "795992",
            "Clicks": "18866",
            "Conversions": "269",
            "Conversion Value": "$38,045",
            "Cost per Result": "$42.53"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1052422",
            "Clicks": "12894",
            "Conversions": "272",
            "Conversion Value": "$30,681",
            "Cost per Result": "$69.21"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1330821",
            "Clicks": "20190",
            "Conversions": "260",
            "Conversion Value": "$44,744",
            "Cost per Result": "$60.68"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "698806",
            "Clicks": "19372",
            "Conversions": "293",
            "Conversion Value": "$41,019",
            "Cost per Result": "$57.38"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1563421",
            "Clicks": "24769",
            "Conversions": "287",
            "Conversion Value": "$37,574",
            "Cost per Result": "$60.18"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1202435",
            "Clicks": "18666",
            "Conversions": "290",
            "Conversion Value": "$40,364",
            "Cost per Result": "$50.54"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1210938",
            "Clicks": "19157",
            "Conversions": "328",
            "Conversion Value": "$32,140",
            "Cost per Result": "$66.27"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1604341",
            "Clicks": "12521",
            "Conversions": "280",
            "Conversion Value": "$40,214",
            "Cost per Result": "$54.21"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1000802",
            "Clicks": "14874",
            "Conversions": "256",
            "Conversion Value": "$49,068",
            "Cost per Result": "$53.93"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "920554",
            "Clicks": "20928",
            "Conversions": "248",
            "Conversion Value": "$44,687",
            "Cost per Result": "$55.89"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1664301",
            "Clicks": "15821",
            "Conversions": "284",
            "Conversion Value": "$49,971",
            "Cost per Result": "$40.21"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1283994",
            "Clicks": "20811",
            "Conversions": "280",
            "Conversion Value": "$38,718",
            "Cost per Result": "$58.99"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1366050",
            "Clicks": "15255",
            "Conversions": "264",
            "Conversion Value": "$50,997",
            "Cost per Result": "$52.17"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1147543",
            "Clicks": "21542",
            "Conversions": "239",
            "Conversion Value": "$36,909",
            "Cost per Result": "$44.25"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1070132",
            "Clicks": "15926",
            "Conversions": "245",
            "Conversion Value": "$41,549",
            "Cost per Result": "$45.01"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1164728",
            "Clicks": "17998",
            "Conversions": "275",
            "Conversion Value": "$34,382",
            "Cost per Result": "$58.27"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "742859",
            "Clicks": "15046",
            "Conversions": "287",
            "Conversion Value": "$45,299",
            "Cost per Result": "$45.53"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "893666",
            "Clicks": "18531",
            "Conversions": "241",
            "Conversion Value": "$38,136",
            "Cost per Result": "$58.71"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "1209964",
            "Clicks": "20680",
            "Conversions": "275",
            "Conversion Value": "$30,833",
            "Cost per Result": "$53.37"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1287506",
            "Clicks": "13387",
            "Conversions": "262",
            "Conversion Value": "$35,761",
            "Cost per Result": "$60.32"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "892511",
            "Clicks": "22145",
            "Conversions": "249",
            "Conversion Value": "$35,471",
            "Cost per Result": "$49.71"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "922684",
            "Clicks": "22736",
            "Conversions": "200",
            "Conversion Value": "$44,407",
            "Cost per Result": "$61.87"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1436437",
            "Clicks": "17404",
            "Conversions": "327",
            "Conversion Value": "$42,222",
            "Cost per Result": "$39.22"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "883242",
            "Clicks": "21588",
            "Conversions": "247",
            "Conversion Value": "$26,502",
            "Cost per Result": "$44.51"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "845611",
            "Clicks": "17835",
            "Conversions": "241",
            "Conversion Value": "$31,130",
            "Cost per Result": "$61.51"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1297634",
            "Clicks": "18593",
            "Conversions": "266",
            "Conversion Value": "$45,680",
            "Cost per Result": "$50.53"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1273045",
            "Clicks": "16518",
            "Conversions": "242",
            "Conversion Value": "$34,301",
            "Cost per Result": "$62.76"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1771976",
            "Clicks": "19161",
            "Conversions": "296",
            "Conversion Value": "$28,090",
            "Cost per Result": "$46.28"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "789567",
            "Clicks": "20945",
            "Conversions": "254",
            "Conversion Value": "$46,703",
            "Cost per Result": "$58.39"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "963237",
            "Clicks": "15782",
            "Conversions": "325",
            "Conversion Value": "$40,117",
            "Cost per Result": "$62.16"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1029413",
            "Clicks": "19384",
            "Conversions": "284",
            "Conversion Value": "$39,981",
            "Cost per Result": "$53.15"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1195266",
            "Clicks": "20158",
            "Conversions": "242",
            "Conversion Value": "$34,293",
            "Cost per Result": "$43.78"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1127765",
            "Clicks": "20861",
            "Conversions": "266",
            "Conversion Value": "$45,258",
            "Cost per Result": "$49.85"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1572606",
            "Clicks": "15989",
            "Conversions": "259",
            "Conversion Value": "$43,854",
            "Cost per Result": "$50.27"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1462073",
            "Clicks": "24354",
            "Conversions": "260",
            "Conversion Value": "$38,515",
            "Cost per Result": "$47.00"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1649673",
            "Clicks": "16669",
            "Conversions": "279",
            "Conversion Value": "$31,164",
            "Cost per Result": "$48.27"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "956814",
            "Clicks": "20480",
            "Conversions": "245",
            "Conversion Value": "$45,377",
            "Cost per Result": "$51.19"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1248707",
            "Clicks": "19858",
            "Conversions": "242",
            "Conversion Value": "$36,629",
            "Cost per Result": "$56.91"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1205929",
            "Clicks": "19204",
            "Conversions": "257",
            "Conversion Value": "$33,536",
            "Cost per Result": "$56.72"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "896886",
            "Clicks": "17850",
            "Conversions": "280",
            "Conversion Value": "$56,414",
            "Cost per Result": "$39.56"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1028513",
            "Clicks": "20196",
            "Conversions": "235",
            "Conversion Value": "$43,298",
            "Cost per Result": "$42.73"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "903915",
            "Clicks": "17989",
            "Conversions": "305",
            "Conversion Value": "$43,410",
            "Cost per Result": "$54.37"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1047045",
            "Clicks": "12168",
            "Conversions": "214",
            "Conversion Value": "$39,338",
            "Cost per Result": "$45.05"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1584049",
            "Clicks": "16263",
            "Conversions": "204",
            "Conversion Value": "$35,440",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "933475",
            "Clicks": "22006",
            "Conversions": "259",
            "Conversion Value": "$32,431",
            "Cost per Result": "$44.54"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1323710",
            "Clicks": "21832",
            "Conversions": "279",
            "Conversion Value": "$27,381",
            "Cost per Result": "$46.93"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1502543",
            "Clicks": "20887",
            "Conversions": "234",
            "Conversion Value": "$41,248",
            "Cost per Result": "$45.29"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "815419",
            "Clicks": "13899",
            "Conversions": "291",
            "Conversion Value": "$50,373",
            "Cost per Result": "$51.72"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1163719",
            "Clicks": "14082",
            "Conversions": "312",
            "Conversion Value": "$31,712",
            "Cost per Result": "$53.26"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1053473",
            "Clicks": "15419",
            "Conversions": "257",
            "Conversion Value": "$48,391",
            "Cost per Result": "$53.12"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1018852",
            "Clicks": "18860",
            "Conversions": "200",
            "Conversion Value": "$43,046",
            "Cost per Result": "$55.73"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "1174567",
            "Clicks": "21947",
            "Conversions": "228",
            "Conversion Value": "$44,820",
            "Cost per Result": "$45.21"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "681784",
            "Clicks": "20435",
            "Conversions": "258",
            "Conversion Value": "$31,102",
            "Cost per Result": "$43.57"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "810083",
            "Clicks": "17112",
            "Conversions": "267",
            "Conversion Value": "$43,168",
            "Cost per Result": "$47.54"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "794786",
            "Clicks": "17364",
            "Conversions": "237",
            "Conversion Value": "$44,984",
            "Cost per Result": "$57.78"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "903865",
            "Clicks": "23105",
            "Conversions": "294",
            "Conversion Value": "$29,155",
            "Cost per Result": "$60.98"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1244307",
            "Clicks": "17717",
            "Conversions": "246",
            "Conversion Value": "$42,938",
            "Cost per Result": "$57.82"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "872889",
            "Clicks": "21870",
            "Conversions": "239",
            "Conversion Value": "$49,408",
            "Cost per Result": "$64.00"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "781230",
            "Clicks": "17319",
            "Conversions": "292",
            "Conversion Value": "$26,095",
            "Cost per Result": "$40.96"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1006088",
            "Clicks": "18208",
            "Conversions": "243",
            "Conversion Value": "$32,549",
            "Cost per Result": "$45.07"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "998209",
            "Clicks": "21846",
            "Conversions": "280",
            "Conversion Value": "$27,635",
            "Cost per Result": "$71.00"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1291354",
            "Clicks": "14028",
            "Conversions": "305",
            "Conversion Value": "$37,017",
            "Cost per Result": "$57.33"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1004606",
            "Clicks": "15149",
            "Conversions": "264",
            "Conversion Value": "$38,046",
            "Cost per Result": "$41.55"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1340535",
            "Clicks": "19912",
            "Conversions": "278",
            "Conversion Value": "$32,977",
            "Cost per Result": "$49.22"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1388113",
            "Clicks": "20750",
            "Conversions": "232",
            "Conversion Value": "$33,688",
            "Cost per Result": "$37.16"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "779601",
            "Clicks": "18190",
            "Conversions": "205",
            "Conversion Value": "$38,522",
            "Cost per Result": "$43.94"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1406490",
            "Clicks": "19553",
            "Conversions": "235",
            "Conversion Value": "$42,943",
            "Cost per Result": "$48.17"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1144056",
            "Clicks": "25381",
            "Conversions": "207",
            "Conversion Value": "$32,947",
            "Cost per Result": "$56.08"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "783728",
            "Clicks": "16387",
            "Conversions": "259",
            "Conversion Value": "$54,661",
            "Cost per Result": "$55.49"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1250810",
            "Clicks": "16515",
            "Conversions": "293",
            "Conversion Value": "$46,953",
            "Cost per Result": "$56.72"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1087178",
            "Clicks": "21859",
            "Conversions": "180",
            "Conversion Value": "$40,088",
            "Cost per Result": "$53.38"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1415106",
            "Clicks": "17181",
            "Conversions": "224",
            "Conversion Value": "$44,608",
            "Cost per Result": "$45.55"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1056436",
            "Clicks": "14140",
            "Conversions": "288",
            "Conversion Value": "$40,355",
            "Cost per Result": "$44.61"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1077226",
            "Clicks": "19563",
            "Conversions": "302",
            "Conversion Value": "$45,194",
            "Cost per Result": "$56.72"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "986235",
            "Clicks": "21712",
            "Conversions": "268",
            "Conversion Value": "$26,788",
            "Cost per Result": "$50.21"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "1256735",
            "Clicks": "22043",
            "Conversions": "249",
            "Conversion Value": "$35,446",
            "Cost per Result": "$37.29"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1204477",
            "Clicks": "15730",
            "Conversions": "290",
            "Conversion Value": "$49,098",
            "Cost per Result": "$54.11"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "944458",
            "Clicks": "19870",
            "Conversions": "276",
            "Conversion Value": "$30,006",
            "Cost per Result": "$52.45"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1028375",
            "Clicks": "16974",
            "Conversions": "299",
            "Conversion Value": "$49,949",
            "Cost per Result": "$34.75"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1182354",
            "Clicks": "15596",
            "Conversions": "228",
            "Conversion Value": "$48,731",
            "Cost per Result": "$52.40"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1046558",
            "Clicks": "22998",
            "Conversions": "314",
            "Conversion Value": "$37,451",
            "Cost per Result": "$47.34"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "1201404",
            "Clicks": "17393",
            "Conversions": "263",
            "Conversion Value": "$36,750",
            "Cost per Result": "$59.15"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "808396",
            "Clicks": "16923",
            "Conversions": "228",
            "Conversion Value": "$28,246",
            "Cost per Result": "$49.24"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1545747",
            "Clicks": "12963",
            "Conversions": "262",
            "Conversion Value": "$43,358",
            "Cost per Result": "$47.02"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1573947",
            "Clicks": "19215",
            "Conversions": "259",
            "Conversion Value": "$40,616",
            "Cost per Result": "$42.87"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1690079",
            "Clicks": "19364",
            "Conversions": "227",
            "Conversion Value": "$44,961",
            "Cost per Result": "$48.84"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1184135",
            "Clicks": "21240",
            "Conversions": "257",
            "Conversion Value": "$39,368",
            "Cost per Result": "$40.98"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "864811",
            "Clicks": "19774",
            "Conversions": "231",
            "Conversion Value": "$39,736",
            "Cost per Result": "$44.99"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1571455",
            "Clicks": "17048",
            "Conversions": "248",
            "Conversion Value": "$49,017",
            "Cost per Result": "$39.45"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1372381",
            "Clicks": "18297",
            "Conversions": "230",
            "Conversion Value": "$30,755",
            "Cost per Result": "$72.28"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1091748",
            "Clicks": "13836",
            "Conversions": "280",
            "Conversion Value": "$40,822",
            "Cost per Result": "$53.71"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "866155",
            "Clicks": "16126",
            "Conversions": "240",
            "Conversion Value": "$35,819",
            "Cost per Result": "$46.57"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "988029",
            "Clicks": "17813",
            "Conversions": "238",
            "Conversion Value": "$38,501",
            "Cost per Result": "$53.38"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1253636",
            "Clicks": "16428",
            "Conversions": "223",
            "Conversion Value": "$45,238",
            "Cost per Result": "$48.54"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1492965",
            "Clicks": "20063",
            "Conversions": "245",
            "Conversion Value": "$54,492",
            "Cost per Result": "$53.90"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1385596",
            "Clicks": "17077",
            "Conversions": "290",
            "Conversion Value": "$46,415",
            "Cost per Result": "$59.07"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1457977",
            "Clicks": "15402",
            "Conversions": "231",
            "Conversion Value": "$30,952",
            "Cost per Result": "$54.00"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "935587",
            "Clicks": "17469",
            "Conversions": "261",
            "Conversion Value": "$46,784",
            "Cost per Result": "$46.87"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1009207",
            "Clicks": "18756",
            "Conversions": "254",
            "Conversion Value": "$38,193",
            "Cost per Result": "$52.29"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1217366",
            "Clicks": "22326",
            "Conversions": "302",
            "Conversion Value": "$39,333",
            "Cost per Result": "$52.25"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1344145",
            "Clicks": "20077",
            "Conversions": "256",
            "Conversion Value": "$52,911",
            "Cost per Result": "$52.20"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "614329",
            "Clicks": "22239",
            "Conversions": "278",
            "Conversion Value": "$43,397",
            "Cost per Result": "$39.44"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1492848",
            "Clicks": "16335",
            "Conversions": "235",
            "Conversion Value": "$36,126",
            "Cost per Result": "$40.99"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1412671",
            "Clicks": "20135",
            "Conversions": "278",
            "Conversion Value": "$37,970",
            "Cost per Result": "$51.30"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1094733",
            "Clicks": "17206",
            "Conversions": "245",
            "Conversion Value": "$38,205",
            "Cost per Result": "$52.67"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1271445",
            "Clicks": "18647",
            "Conversions": "249",
            "Conversion Value": "$40,980",
            "Cost per Result": "$48.90"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "931151",
            "Clicks": "19240",
            "Conversions": "191",
            "Conversion Value": "$32,175",
            "Cost per Result": "$48.99"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1386051",
            "Clicks": "15869",
            "Conversions": "272",
            "Conversion Value": "$35,370",
            "Cost per Result": "$57.29"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "975688",
            "Clicks": "19183",
            "Conversions": "277",
            "Conversion Value": "$50,467",
            "Cost per Result": "$58.98"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "989478",
            "Clicks": "18406",
            "Conversions": "214",
            "Conversion Value": "$40,645",
            "Cost per Result": "$38.02"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "873780",
            "Clicks": "18067",
            "Conversions": "257",
            "Conversion Value": "$37,675",
            "Cost per Result": "$51.64"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1095778",
            "Clicks": "19104",
            "Conversions": "245",
            "Conversion Value": "$55,811",
            "Cost per Result": "$56.38"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1360935",
            "Clicks": "14862",
            "Conversions": "256",
            "Conversion Value": "$38,715",
            "Cost per Result": "$67.52"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "1555655",
            "Clicks": "17996",
            "Conversions": "306",
            "Conversion Value": "$38,903",
            "Cost per Result": "$41.63"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1220015",
            "Clicks": "17094",
            "Conversions": "247",
            "Conversion Value": "$32,348",
            "Cost per Result": "$55.82"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1322403",
            "Clicks": "19907",
            "Conversions": "228",
            "Conversion Value": "$30,443",
            "Cost per Result": "$49.86"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1333077",
            "Clicks": "20233",
            "Conversions": "227",
            "Conversion Value": "$40,083",
            "Cost per Result": "$51.98"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1200541",
            "Clicks": "19605",
            "Conversions": "267",
            "Conversion Value": "$38,831",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1097226",
            "Clicks": "16184",
            "Conversions": "271",
            "Conversion Value": "$31,686",
            "Cost per Result": "$49.08"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1477296",
            "Clicks": "14676",
            "Conversions": "323",
            "Conversion Value": "$27,514",
            "Cost per Result": "$43.33"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1167432",
            "Clicks": "16448",
            "Conversions": "249",
            "Conversion Value": "$44,129",
            "Cost per Result": "$53.71"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1251250",
            "Clicks": "14136",
            "Conversions": "294",
            "Conversion Value": "$34,014",
            "Cost per Result": "$52.48"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "1507382",
            "Clicks": "18609",
            "Conversions": "250",
            "Conversion Value": "$40,262",
            "Cost per Result": "$52.97"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1230916",
            "Clicks": "19860",
            "Conversions": "287",
            "Conversion Value": "$51,113",
            "Cost per Result": "$36.57"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1542617",
            "Clicks": "21940",
            "Conversions": "290",
            "Conversion Value": "$41,005",
            "Cost per Result": "$48.52"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1461481",
            "Clicks": "17825",
            "Conversions": "237",
            "Conversion Value": "$32,324",
            "Cost per Result": "$60.78"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1413693",
            "Clicks": "17940",
            "Conversions": "239",
            "Conversion Value": "$43,941",
            "Cost per Result": "$37.96"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "958279",
            "Clicks": "12842",
            "Conversions": "261",
            "Conversion Value": "$39,480",
            "Cost per Result": "$49.52"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "1041231",
            "Clicks": "18964",
            "Conversions": "222",
            "Conversion Value": "$51,144",
            "Cost per Result": "$44.30"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1213609",
            "Clicks": "21603",
            "Conversions": "275",
            "Conversion Value": "$47,319",
            "Cost per Result": "$55.45"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1184872",
            "Clicks": "19950",
            "Conversions": "240",
            "Conversion Value": "$43,165",
            "Cost per Result": "$51.29"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1356987",
            "Clicks": "18540",
            "Conversions": "290",
            "Conversion Value": "$42,328",
            "Cost per Result": "$50.84"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "874452",
            "Clicks": "11853",
            "Conversions": "279",
            "Conversion Value": "$44,965",
            "Cost per Result": "$38.93"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "1270763",
            "Clicks": "18095",
            "Conversions": "269",
            "Conversion Value": "$33,262",
            "Cost per Result": "$47.95"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1409712",
            "Clicks": "16267",
            "Conversions": "262",
            "Conversion Value": "$46,062",
            "Cost per Result": "$66.98"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1000917",
            "Clicks": "17454",
            "Conversions": "282",
            "Conversion Value": "$32,596",
            "Cost per Result": "$48.89"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1384701",
            "Clicks": "19063",
            "Conversions": "226",
            "Conversion Value": "$43,463",
            "Cost per Result": "$55.00"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "1289723",
            "Clicks": "14981",
            "Conversions": "279",
            "Conversion Value": "$35,562",
            "Cost per Result": "$43.29"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "1440044",
            "Clicks": "15610",
            "Conversions": "277",
            "Conversion Value": "$44,202",
            "Cost per Result": "$50.43"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1250551",
            "Clicks": "16388",
            "Conversions": "252",
            "Conversion Value": "$43,599",
            "Cost per Result": "$44.61"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1150851",
            "Clicks": "14261",
            "Conversions": "275",
            "Conversion Value": "$36,419",
            "Cost per Result": "$53.37"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1088199",
            "Clicks": "20372",
            "Conversions": "229",
            "Conversion Value": "$27,554",
            "Cost per Result": "$59.41"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1015471",
            "Clicks": "20525",
            "Conversions": "268",
            "Conversion Value": "$45,528",
            "Cost per Result": "$48.76"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1248672",
            "Clicks": "16554",
            "Conversions": "239",
            "Conversion Value": "$45,481",
            "Cost per Result": "$57.04"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1240741",
            "Clicks": "17891",
            "Conversions": "225",
            "Conversion Value": "$40,295",
            "Cost per Result": "$48.50"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1492948",
            "Clicks": "21026",
            "Conversions": "231",
            "Conversion Value": "$43,419",
            "Cost per Result": "$53.31"
          }
        ]
      },
      "NFTCollectorsData": {
        "name": "NFT Collectors",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "305742",
            "Clicks": "2277",
            "Conversions": "74",
            "Conversion Value": "$12,979",
            "Cost per Result": "$42.65"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "123092",
            "Clicks": "5602",
            "Conversions": "89",
            "Conversion Value": "$9,611",
            "Cost per Result": "$64.07"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "293969",
            "Clicks": "5827",
            "Conversions": "76",
            "Conversion Value": "$13,667",
            "Cost per Result": "$32.91"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "148931",
            "Clicks": "4046",
            "Conversions": "71",
            "Conversion Value": "$7,806",
            "Cost per Result": "$20.61"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "265977",
            "Clicks": "5085",
            "Conversions": "76",
            "Conversion Value": "$12,029",
            "Cost per Result": "$39.38"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "469868",
            "Clicks": "4665",
            "Conversions": "76",
            "Conversion Value": "$4,757",
            "Cost per Result": "$36.38"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "103074",
            "Clicks": "5395",
            "Conversions": "83",
            "Conversion Value": "$5,466",
            "Cost per Result": "$48.79"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "144719",
            "Clicks": "2592",
            "Conversions": "49",
            "Conversion Value": "$4,628",
            "Cost per Result": "$44.44"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "453939",
            "Clicks": "4589",
            "Conversions": "76",
            "Conversion Value": "$8,725",
            "Cost per Result": "$28.84"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "104994",
            "Clicks": "4637",
            "Conversions": "48",
            "Conversion Value": "$4,093",
            "Cost per Result": "$34.76"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "399078",
            "Clicks": "3511",
            "Conversions": "81",
            "Conversion Value": "$12,418",
            "Cost per Result": "$65.00"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "214257",
            "Clicks": "5832",
            "Conversions": "64",
            "Conversion Value": "$11,914",
            "Cost per Result": "$79.80"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "210873",
            "Clicks": "5545",
            "Conversions": "59",
            "Conversion Value": "$4,323",
            "Cost per Result": "$64.68"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "138211",
            "Clicks": "2511",
            "Conversions": "80",
            "Conversion Value": "$8,923",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "440434",
            "Clicks": "3572",
            "Conversions": "80",
            "Conversion Value": "$7,065",
            "Cost per Result": "$69.16"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "249044",
            "Clicks": "2040",
            "Conversions": "88",
            "Conversion Value": "$4,017",
            "Cost per Result": "$78.61"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "110648",
            "Clicks": "2747",
            "Conversions": "48",
            "Conversion Value": "$8,751",
            "Cost per Result": "$26.46"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "245632",
            "Clicks": "6171",
            "Conversions": "88",
            "Conversion Value": "$13,173",
            "Cost per Result": "$24.68"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "471051",
            "Clicks": "5570",
            "Conversions": "54",
            "Conversion Value": "$12,619",
            "Cost per Result": "$42.15"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "287191",
            "Clicks": "6887",
            "Conversions": "42",
            "Conversion Value": "$15,489",
            "Cost per Result": "$33.28"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "441953",
            "Clicks": "2937",
            "Conversions": "53",
            "Conversion Value": "$6,060",
            "Cost per Result": "$52.49"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "107527",
            "Clicks": "3424",
            "Conversions": "88",
            "Conversion Value": "$15,796",
            "Cost per Result": "$26.10"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "391597",
            "Clicks": "2795",
            "Conversions": "60",
            "Conversion Value": "$15,535",
            "Cost per Result": "$49.78"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "327309",
            "Clicks": "6580",
            "Conversions": "44",
            "Conversion Value": "$14,086",
            "Cost per Result": "$59.59"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "180937",
            "Clicks": "5753",
            "Conversions": "80",
            "Conversion Value": "$6,144",
            "Cost per Result": "$84.40"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "270710",
            "Clicks": "3991",
            "Conversions": "73",
            "Conversion Value": "$15,695",
            "Cost per Result": "$72.71"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "187346",
            "Clicks": "2243",
            "Conversions": "53",
            "Conversion Value": "$11,860",
            "Cost per Result": "$84.47"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "110083",
            "Clicks": "4448",
            "Conversions": "45",
            "Conversion Value": "$4,300",
            "Cost per Result": "$76.23"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "273694",
            "Clicks": "5671",
            "Conversions": "70",
            "Conversion Value": "$14,675",
            "Cost per Result": "$33.90"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "328452",
            "Clicks": "2470",
            "Conversions": "54",
            "Conversion Value": "$13,121",
            "Cost per Result": "$46.35"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "376682",
            "Clicks": "3982",
            "Conversions": "59",
            "Conversion Value": "$15,094",
            "Cost per Result": "$57.81"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "356773",
            "Clicks": "5164",
            "Conversions": "57",
            "Conversion Value": "$8,491",
            "Cost per Result": "$51.99"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "403045",
            "Clicks": "3726",
            "Conversions": "85",
            "Conversion Value": "$6,314",
            "Cost per Result": "$76.68"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "394063",
            "Clicks": "3240",
            "Conversions": "65",
            "Conversion Value": "$5,933",
            "Cost per Result": "$48.09"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "480714",
            "Clicks": "5142",
            "Conversions": "84",
            "Conversion Value": "$12,869",
            "Cost per Result": "$42.79"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "268297",
            "Clicks": "2844",
            "Conversions": "54",
            "Conversion Value": "$9,032",
            "Cost per Result": "$31.27"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "231702",
            "Clicks": "2954",
            "Conversions": "81",
            "Conversion Value": "$7,195",
            "Cost per Result": "$45.96"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "489978",
            "Clicks": "5537",
            "Conversions": "70",
            "Conversion Value": "$4,432",
            "Cost per Result": "$20.08"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "313279",
            "Clicks": "5323",
            "Conversions": "75",
            "Conversion Value": "$9,143",
            "Cost per Result": "$52.85"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "165538",
            "Clicks": "3251",
            "Conversions": "72",
            "Conversion Value": "$4,180",
            "Cost per Result": "$80.78"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "393920",
            "Clicks": "4928",
            "Conversions": "67",
            "Conversion Value": "$13,533",
            "Cost per Result": "$49.16"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "482388",
            "Clicks": "3909",
            "Conversions": "58",
            "Conversion Value": "$14,630",
            "Cost per Result": "$66.31"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "351954",
            "Clicks": "2174",
            "Conversions": "47",
            "Conversion Value": "$7,868",
            "Cost per Result": "$53.52"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "190953",
            "Clicks": "6345",
            "Conversions": "54",
            "Conversion Value": "$8,459",
            "Cost per Result": "$22.88"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "450359",
            "Clicks": "6444",
            "Conversions": "44",
            "Conversion Value": "$13,925",
            "Cost per Result": "$83.06"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "162169",
            "Clicks": "5651",
            "Conversions": "75",
            "Conversion Value": "$8,922",
            "Cost per Result": "$84.04"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "374486",
            "Clicks": "3596",
            "Conversions": "75",
            "Conversion Value": "$7,275",
            "Cost per Result": "$45.92"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "393511",
            "Clicks": "3535",
            "Conversions": "68",
            "Conversion Value": "$15,346",
            "Cost per Result": "$66.07"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "225939",
            "Clicks": "5425",
            "Conversions": "86",
            "Conversion Value": "$7,499",
            "Cost per Result": "$49.27"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "206038",
            "Clicks": "2781",
            "Conversions": "72",
            "Conversion Value": "$7,028",
            "Cost per Result": "$62.25"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "331434",
            "Clicks": "6533",
            "Conversions": "42",
            "Conversion Value": "$12,914",
            "Cost per Result": "$60.27"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "283449",
            "Clicks": "4075",
            "Conversions": "87",
            "Conversion Value": "$11,957",
            "Cost per Result": "$33.08"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "218060",
            "Clicks": "3347",
            "Conversions": "81",
            "Conversion Value": "$10,517",
            "Cost per Result": "$39.88"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "447610",
            "Clicks": "6636",
            "Conversions": "62",
            "Conversion Value": "$12,876",
            "Cost per Result": "$71.88"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "474600",
            "Clicks": "6613",
            "Conversions": "58",
            "Conversion Value": "$6,508",
            "Cost per Result": "$36.42"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "270294",
            "Clicks": "2145",
            "Conversions": "80",
            "Conversion Value": "$8,128",
            "Cost per Result": "$20.39"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "492616",
            "Clicks": "3069",
            "Conversions": "58",
            "Conversion Value": "$8,414",
            "Cost per Result": "$53.50"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "146187",
            "Clicks": "3155",
            "Conversions": "67",
            "Conversion Value": "$14,808",
            "Cost per Result": "$79.98"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "272164",
            "Clicks": "2927",
            "Conversions": "82",
            "Conversion Value": "$14,463",
            "Cost per Result": "$42.60"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "487038",
            "Clicks": "5394",
            "Conversions": "61",
            "Conversion Value": "$15,937",
            "Cost per Result": "$80.73"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "477846",
            "Clicks": "5753",
            "Conversions": "70",
            "Conversion Value": "$10,419",
            "Cost per Result": "$41.37"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "161864",
            "Clicks": "6528",
            "Conversions": "85",
            "Conversion Value": "$9,042",
            "Cost per Result": "$41.12"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "415290",
            "Clicks": "3122",
            "Conversions": "68",
            "Conversion Value": "$4,492",
            "Cost per Result": "$77.87"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "355301",
            "Clicks": "6844",
            "Conversions": "66",
            "Conversion Value": "$14,196",
            "Cost per Result": "$80.77"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "321104",
            "Clicks": "3691",
            "Conversions": "66",
            "Conversion Value": "$8,050",
            "Cost per Result": "$38.75"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "328678",
            "Clicks": "2891",
            "Conversions": "54",
            "Conversion Value": "$9,730",
            "Cost per Result": "$59.67"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "457259",
            "Clicks": "5568",
            "Conversions": "68",
            "Conversion Value": "$5,401",
            "Cost per Result": "$42.97"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "460810",
            "Clicks": "5340",
            "Conversions": "84",
            "Conversion Value": "$11,830",
            "Cost per Result": "$52.52"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "391237",
            "Clicks": "2233",
            "Conversions": "59",
            "Conversion Value": "$10,684",
            "Cost per Result": "$75.92"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "463291",
            "Clicks": "4471",
            "Conversions": "87",
            "Conversion Value": "$11,295",
            "Cost per Result": "$75.42"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "325049",
            "Clicks": "2799",
            "Conversions": "43",
            "Conversion Value": "$13,415",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "485416",
            "Clicks": "4954",
            "Conversions": "64",
            "Conversion Value": "$12,282",
            "Cost per Result": "$29.78"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "289322",
            "Clicks": "3684",
            "Conversions": "53",
            "Conversion Value": "$15,296",
            "Cost per Result": "$64.62"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "354866",
            "Clicks": "2625",
            "Conversions": "51",
            "Conversion Value": "$13,895",
            "Cost per Result": "$63.29"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "304591",
            "Clicks": "3609",
            "Conversions": "88",
            "Conversion Value": "$14,760",
            "Cost per Result": "$74.45"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "281367",
            "Clicks": "6046",
            "Conversions": "44",
            "Conversion Value": "$10,219",
            "Cost per Result": "$50.39"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "373098",
            "Clicks": "4005",
            "Conversions": "58",
            "Conversion Value": "$11,125",
            "Cost per Result": "$75.05"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "352498",
            "Clicks": "6005",
            "Conversions": "80",
            "Conversion Value": "$6,931",
            "Cost per Result": "$84.45"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "324753",
            "Clicks": "5130",
            "Conversions": "80",
            "Conversion Value": "$10,339",
            "Cost per Result": "$40.00"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "475513",
            "Clicks": "5032",
            "Conversions": "57",
            "Conversion Value": "$5,813",
            "Cost per Result": "$55.73"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "105504",
            "Clicks": "4373",
            "Conversions": "66",
            "Conversion Value": "$11,667",
            "Cost per Result": "$55.09"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "199779",
            "Clicks": "2188",
            "Conversions": "67",
            "Conversion Value": "$8,436",
            "Cost per Result": "$77.16"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "251452",
            "Clicks": "6014",
            "Conversions": "45",
            "Conversion Value": "$14,362",
            "Cost per Result": "$73.07"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "255509",
            "Clicks": "6229",
            "Conversions": "84",
            "Conversion Value": "$12,732",
            "Cost per Result": "$55.99"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "105323",
            "Clicks": "4176",
            "Conversions": "52",
            "Conversion Value": "$5,801",
            "Cost per Result": "$38.37"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "361777",
            "Clicks": "2954",
            "Conversions": "50",
            "Conversion Value": "$15,993",
            "Cost per Result": "$80.20"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "452117",
            "Clicks": "2321",
            "Conversions": "62",
            "Conversion Value": "$14,717",
            "Cost per Result": "$43.46"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "417144",
            "Clicks": "2535",
            "Conversions": "57",
            "Conversion Value": "$7,724",
            "Cost per Result": "$33.26"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "207985",
            "Clicks": "4831",
            "Conversions": "62",
            "Conversion Value": "$11,263",
            "Cost per Result": "$24.39"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "447894",
            "Clicks": "4574",
            "Conversions": "59",
            "Conversion Value": "$9,539",
            "Cost per Result": "$40.54"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "102021",
            "Clicks": "4087",
            "Conversions": "40",
            "Conversion Value": "$8,629",
            "Cost per Result": "$74.61"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "141247",
            "Clicks": "2158",
            "Conversions": "88",
            "Conversion Value": "$10,310",
            "Cost per Result": "$69.43"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "137183",
            "Clicks": "4864",
            "Conversions": "79",
            "Conversion Value": "$4,817",
            "Cost per Result": "$79.31"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "254805",
            "Clicks": "5357",
            "Conversions": "87",
            "Conversion Value": "$9,936",
            "Cost per Result": "$59.24"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "377892",
            "Clicks": "2569",
            "Conversions": "61",
            "Conversion Value": "$6,802",
            "Cost per Result": "$48.73"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "320704",
            "Clicks": "5985",
            "Conversions": "65",
            "Conversion Value": "$6,575",
            "Cost per Result": "$82.21"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "143041",
            "Clicks": "6071",
            "Conversions": "85",
            "Conversion Value": "$14,654",
            "Cost per Result": "$77.91"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "436846",
            "Clicks": "2995",
            "Conversions": "59",
            "Conversion Value": "$11,628",
            "Cost per Result": "$50.84"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "420474",
            "Clicks": "6130",
            "Conversions": "73",
            "Conversion Value": "$4,788",
            "Cost per Result": "$32.35"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "172456",
            "Clicks": "4717",
            "Conversions": "69",
            "Conversion Value": "$5,450",
            "Cost per Result": "$75.41"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "180110",
            "Clicks": "3234",
            "Conversions": "76",
            "Conversion Value": "$15,418",
            "Cost per Result": "$22.98"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "256944",
            "Clicks": "4115",
            "Conversions": "48",
            "Conversion Value": "$9,049",
            "Cost per Result": "$67.43"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "146584",
            "Clicks": "6281",
            "Conversions": "40",
            "Conversion Value": "$15,704",
            "Cost per Result": "$28.44"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "146396",
            "Clicks": "6391",
            "Conversions": "82",
            "Conversion Value": "$15,080",
            "Cost per Result": "$43.51"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "278596",
            "Clicks": "5742",
            "Conversions": "83",
            "Conversion Value": "$9,184",
            "Cost per Result": "$55.41"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "479391",
            "Clicks": "2872",
            "Conversions": "83",
            "Conversion Value": "$4,578",
            "Cost per Result": "$61.95"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "452528",
            "Clicks": "4943",
            "Conversions": "79",
            "Conversion Value": "$7,706",
            "Cost per Result": "$73.10"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "382475",
            "Clicks": "3425",
            "Conversions": "80",
            "Conversion Value": "$11,045",
            "Cost per Result": "$51.51"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "104771",
            "Clicks": "3727",
            "Conversions": "65",
            "Conversion Value": "$10,371",
            "Cost per Result": "$24.42"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "236438",
            "Clicks": "4325",
            "Conversions": "73",
            "Conversion Value": "$8,936",
            "Cost per Result": "$64.13"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "474532",
            "Clicks": "4107",
            "Conversions": "87",
            "Conversion Value": "$12,808",
            "Cost per Result": "$25.36"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "169297",
            "Clicks": "3926",
            "Conversions": "63",
            "Conversion Value": "$5,150",
            "Cost per Result": "$77.48"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "311602",
            "Clicks": "3769",
            "Conversions": "78",
            "Conversion Value": "$15,179",
            "Cost per Result": "$65.06"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "433924",
            "Clicks": "6800",
            "Conversions": "58",
            "Conversion Value": "$10,827",
            "Cost per Result": "$34.06"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "158246",
            "Clicks": "5162",
            "Conversions": "65",
            "Conversion Value": "$6,587",
            "Cost per Result": "$55.35"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "421969",
            "Clicks": "4584",
            "Conversions": "90",
            "Conversion Value": "$5,412",
            "Cost per Result": "$54.23"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "148216",
            "Clicks": "5015",
            "Conversions": "66",
            "Conversion Value": "$10,618",
            "Cost per Result": "$50.79"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "156830",
            "Clicks": "5462",
            "Conversions": "46",
            "Conversion Value": "$13,458",
            "Cost per Result": "$64.67"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "438134",
            "Clicks": "6877",
            "Conversions": "75",
            "Conversion Value": "$5,317",
            "Cost per Result": "$82.16"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "123845",
            "Clicks": "2117",
            "Conversions": "50",
            "Conversion Value": "$4,573",
            "Cost per Result": "$55.67"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "126906",
            "Clicks": "5582",
            "Conversions": "44",
            "Conversion Value": "$12,527",
            "Cost per Result": "$52.81"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "193868",
            "Clicks": "6809",
            "Conversions": "54",
            "Conversion Value": "$7,230",
            "Cost per Result": "$78.82"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "438910",
            "Clicks": "3627",
            "Conversions": "79",
            "Conversion Value": "$7,772",
            "Cost per Result": "$39.65"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "166371",
            "Clicks": "6892",
            "Conversions": "84",
            "Conversion Value": "$6,143",
            "Cost per Result": "$44.99"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "138921",
            "Clicks": "6478",
            "Conversions": "69",
            "Conversion Value": "$4,169",
            "Cost per Result": "$53.21"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420908",
            "Clicks": "5873",
            "Conversions": "81",
            "Conversion Value": "$10,474",
            "Cost per Result": "$80.96"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "485066",
            "Clicks": "3779",
            "Conversions": "73",
            "Conversion Value": "$7,621",
            "Cost per Result": "$50.10"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "473062",
            "Clicks": "6047",
            "Conversions": "68",
            "Conversion Value": "$9,423",
            "Cost per Result": "$49.28"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "265427",
            "Clicks": "5840",
            "Conversions": "81",
            "Conversion Value": "$5,143",
            "Cost per Result": "$78.34"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "166253",
            "Clicks": "4510",
            "Conversions": "66",
            "Conversion Value": "$7,526",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "140759",
            "Clicks": "2279",
            "Conversions": "83",
            "Conversion Value": "$14,877",
            "Cost per Result": "$47.32"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "308211",
            "Clicks": "4168",
            "Conversions": "63",
            "Conversion Value": "$8,358",
            "Cost per Result": "$44.23"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "447540",
            "Clicks": "5227",
            "Conversions": "84",
            "Conversion Value": "$14,242",
            "Cost per Result": "$69.76"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "426487",
            "Clicks": "5260",
            "Conversions": "61",
            "Conversion Value": "$5,746",
            "Cost per Result": "$46.30"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "401267",
            "Clicks": "6401",
            "Conversions": "61",
            "Conversion Value": "$15,243",
            "Cost per Result": "$33.44"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "447811",
            "Clicks": "2328",
            "Conversions": "77",
            "Conversion Value": "$5,317",
            "Cost per Result": "$72.73"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "159441",
            "Clicks": "6831",
            "Conversions": "45",
            "Conversion Value": "$15,780",
            "Cost per Result": "$59.45"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "309786",
            "Clicks": "5913",
            "Conversions": "76",
            "Conversion Value": "$8,273",
            "Cost per Result": "$35.34"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "118444",
            "Clicks": "4296",
            "Conversions": "71",
            "Conversion Value": "$4,484",
            "Cost per Result": "$35.11"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "229635",
            "Clicks": "6081",
            "Conversions": "53",
            "Conversion Value": "$15,994",
            "Cost per Result": "$48.38"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "271179",
            "Clicks": "5971",
            "Conversions": "40",
            "Conversion Value": "$8,615",
            "Cost per Result": "$37.32"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "154348",
            "Clicks": "5107",
            "Conversions": "83",
            "Conversion Value": "$5,366",
            "Cost per Result": "$56.73"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "366538",
            "Clicks": "2455",
            "Conversions": "49",
            "Conversion Value": "$13,107",
            "Cost per Result": "$32.87"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "401418",
            "Clicks": "4851",
            "Conversions": "54",
            "Conversion Value": "$9,643",
            "Cost per Result": "$52.80"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "392790",
            "Clicks": "6931",
            "Conversions": "89",
            "Conversion Value": "$10,665",
            "Cost per Result": "$38.73"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "402732",
            "Clicks": "3992",
            "Conversions": "56",
            "Conversion Value": "$8,109",
            "Cost per Result": "$60.56"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "340966",
            "Clicks": "5442",
            "Conversions": "56",
            "Conversion Value": "$8,656",
            "Cost per Result": "$54.63"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "150452",
            "Clicks": "3974",
            "Conversions": "41",
            "Conversion Value": "$13,733",
            "Cost per Result": "$70.29"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "159004",
            "Clicks": "2241",
            "Conversions": "51",
            "Conversion Value": "$5,192",
            "Cost per Result": "$81.54"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "108646",
            "Clicks": "3221",
            "Conversions": "64",
            "Conversion Value": "$8,071",
            "Cost per Result": "$42.62"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "131541",
            "Clicks": "4304",
            "Conversions": "47",
            "Conversion Value": "$4,840",
            "Cost per Result": "$32.05"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "124962",
            "Clicks": "6555",
            "Conversions": "78",
            "Conversion Value": "$13,831",
            "Cost per Result": "$34.67"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "143840",
            "Clicks": "5800",
            "Conversions": "55",
            "Conversion Value": "$8,485",
            "Cost per Result": "$26.85"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "310922",
            "Clicks": "3330",
            "Conversions": "65",
            "Conversion Value": "$13,204",
            "Cost per Result": "$79.61"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "122941",
            "Clicks": "3851",
            "Conversions": "50",
            "Conversion Value": "$10,470",
            "Cost per Result": "$61.38"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246994",
            "Clicks": "5211",
            "Conversions": "75",
            "Conversion Value": "$6,167",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "186993",
            "Clicks": "3788",
            "Conversions": "59",
            "Conversion Value": "$11,751",
            "Cost per Result": "$55.09"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "230087",
            "Clicks": "4857",
            "Conversions": "73",
            "Conversion Value": "$11,793",
            "Cost per Result": "$80.30"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "249030",
            "Clicks": "3626",
            "Conversions": "80",
            "Conversion Value": "$4,855",
            "Cost per Result": "$33.34"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "468545",
            "Clicks": "3731",
            "Conversions": "80",
            "Conversion Value": "$7,820",
            "Cost per Result": "$67.59"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "268984",
            "Clicks": "4228",
            "Conversions": "71",
            "Conversion Value": "$7,154",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "326191",
            "Clicks": "2386",
            "Conversions": "87",
            "Conversion Value": "$4,753",
            "Cost per Result": "$37.07"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "464002",
            "Clicks": "2725",
            "Conversions": "81",
            "Conversion Value": "$9,828",
            "Cost per Result": "$24.94"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "337122",
            "Clicks": "5629",
            "Conversions": "59",
            "Conversion Value": "$13,830",
            "Cost per Result": "$20.90"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "489639",
            "Clicks": "6154",
            "Conversions": "53",
            "Conversion Value": "$4,868",
            "Cost per Result": "$35.92"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "102204",
            "Clicks": "4091",
            "Conversions": "41",
            "Conversion Value": "$5,814",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "192112",
            "Clicks": "4822",
            "Conversions": "47",
            "Conversion Value": "$9,951",
            "Cost per Result": "$84.22"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "113846",
            "Clicks": "5614",
            "Conversions": "52",
            "Conversion Value": "$7,800",
            "Cost per Result": "$73.93"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "184505",
            "Clicks": "2771",
            "Conversions": "68",
            "Conversion Value": "$12,884",
            "Cost per Result": "$34.49"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "145558",
            "Clicks": "5218",
            "Conversions": "70",
            "Conversion Value": "$15,383",
            "Cost per Result": "$80.94"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "379121",
            "Clicks": "6659",
            "Conversions": "41",
            "Conversion Value": "$7,780",
            "Cost per Result": "$32.53"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "226677",
            "Clicks": "6011",
            "Conversions": "60",
            "Conversion Value": "$14,676",
            "Cost per Result": "$55.81"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "156438",
            "Clicks": "4737",
            "Conversions": "87",
            "Conversion Value": "$11,998",
            "Cost per Result": "$68.26"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "471034",
            "Clicks": "5407",
            "Conversions": "73",
            "Conversion Value": "$6,881",
            "Cost per Result": "$36.24"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "292362",
            "Clicks": "6005",
            "Conversions": "89",
            "Conversion Value": "$6,156",
            "Cost per Result": "$27.70"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "243306",
            "Clicks": "3256",
            "Conversions": "86",
            "Conversion Value": "$11,348",
            "Cost per Result": "$22.10"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "404629",
            "Clicks": "4609",
            "Conversions": "84",
            "Conversion Value": "$15,024",
            "Cost per Result": "$49.10"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "361482",
            "Clicks": "5717",
            "Conversions": "61",
            "Conversion Value": "$8,316",
            "Cost per Result": "$70.17"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "402717",
            "Clicks": "3572",
            "Conversions": "61",
            "Conversion Value": "$8,577",
            "Cost per Result": "$29.88"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "112696",
            "Clicks": "4684",
            "Conversions": "42",
            "Conversion Value": "$14,656",
            "Cost per Result": "$51.87"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "200950",
            "Clicks": "6854",
            "Conversions": "76",
            "Conversion Value": "$15,702",
            "Cost per Result": "$45.47"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "198506",
            "Clicks": "6664",
            "Conversions": "81",
            "Conversion Value": "$8,644",
            "Cost per Result": "$58.27"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "130592",
            "Clicks": "2677",
            "Conversions": "70",
            "Conversion Value": "$9,558",
            "Cost per Result": "$26.25"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "351206",
            "Clicks": "2950",
            "Conversions": "88",
            "Conversion Value": "$11,069",
            "Cost per Result": "$24.51"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "293553",
            "Clicks": "4272",
            "Conversions": "64",
            "Conversion Value": "$5,164",
            "Cost per Result": "$24.81"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "433469",
            "Clicks": "3681",
            "Conversions": "51",
            "Conversion Value": "$12,167",
            "Cost per Result": "$46.54"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "149300",
            "Clicks": "5470",
            "Conversions": "48",
            "Conversion Value": "$9,704",
            "Cost per Result": "$29.51"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "138702",
            "Clicks": "4633",
            "Conversions": "51",
            "Conversion Value": "$13,956",
            "Cost per Result": "$33.37"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "410254",
            "Clicks": "6609",
            "Conversions": "69",
            "Conversion Value": "$13,992",
            "Cost per Result": "$65.13"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "469871",
            "Clicks": "5903",
            "Conversions": "73",
            "Conversion Value": "$4,766",
            "Cost per Result": "$68.51"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "113105",
            "Clicks": "5442",
            "Conversions": "84",
            "Conversion Value": "$10,844",
            "Cost per Result": "$64.62"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "141990",
            "Clicks": "5693",
            "Conversions": "50",
            "Conversion Value": "$8,856",
            "Cost per Result": "$63.24"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "289286",
            "Clicks": "5155",
            "Conversions": "72",
            "Conversion Value": "$9,987",
            "Cost per Result": "$37.91"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "411380",
            "Clicks": "3282",
            "Conversions": "57",
            "Conversion Value": "$7,212",
            "Cost per Result": "$61.49"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "113518",
            "Clicks": "2498",
            "Conversions": "72",
            "Conversion Value": "$13,119",
            "Cost per Result": "$72.42"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "297382",
            "Clicks": "4762",
            "Conversions": "55",
            "Conversion Value": "$10,539",
            "Cost per Result": "$40.51"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "382620",
            "Clicks": "3591",
            "Conversions": "55",
            "Conversion Value": "$9,801",
            "Cost per Result": "$42.82"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "175453",
            "Clicks": "5689",
            "Conversions": "50",
            "Conversion Value": "$9,081",
            "Cost per Result": "$27.16"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "266223",
            "Clicks": "4338",
            "Conversions": "61",
            "Conversion Value": "$8,301",
            "Cost per Result": "$33.61"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "161132",
            "Clicks": "5452",
            "Conversions": "90",
            "Conversion Value": "$11,862",
            "Cost per Result": "$37.55"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "357095",
            "Clicks": "4970",
            "Conversions": "75",
            "Conversion Value": "$14,328",
            "Cost per Result": "$61.63"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "180731",
            "Clicks": "5749",
            "Conversions": "41",
            "Conversion Value": "$10,020",
            "Cost per Result": "$39.60"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "392388",
            "Clicks": "6027",
            "Conversions": "60",
            "Conversion Value": "$9,937",
            "Cost per Result": "$25.98"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "375968",
            "Clicks": "6166",
            "Conversions": "85",
            "Conversion Value": "$5,589",
            "Cost per Result": "$22.09"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "361193",
            "Clicks": "2689",
            "Conversions": "66",
            "Conversion Value": "$5,535",
            "Cost per Result": "$52.60"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "487073",
            "Clicks": "4903",
            "Conversions": "41",
            "Conversion Value": "$11,141",
            "Cost per Result": "$66.12"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "381916",
            "Clicks": "3660",
            "Conversions": "45",
            "Conversion Value": "$4,993",
            "Cost per Result": "$63.02"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "303855",
            "Clicks": "5239",
            "Conversions": "80",
            "Conversion Value": "$6,310",
            "Cost per Result": "$63.29"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "130384",
            "Clicks": "4558",
            "Conversions": "69",
            "Conversion Value": "$13,978",
            "Cost per Result": "$83.85"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "100297",
            "Clicks": "4334",
            "Conversions": "49",
            "Conversion Value": "$14,263",
            "Cost per Result": "$51.63"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "312523",
            "Clicks": "2726",
            "Conversions": "68",
            "Conversion Value": "$13,793",
            "Cost per Result": "$31.70"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "139624",
            "Clicks": "5561",
            "Conversions": "53",
            "Conversion Value": "$14,464",
            "Cost per Result": "$73.16"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "371514",
            "Clicks": "3558",
            "Conversions": "67",
            "Conversion Value": "$9,711",
            "Cost per Result": "$83.12"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "349420",
            "Clicks": "4207",
            "Conversions": "61",
            "Conversion Value": "$12,529",
            "Cost per Result": "$27.10"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "147354",
            "Clicks": "2348",
            "Conversions": "70",
            "Conversion Value": "$4,860",
            "Cost per Result": "$72.91"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "247017",
            "Clicks": "6237",
            "Conversions": "58",
            "Conversion Value": "$4,245",
            "Cost per Result": "$60.49"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "296814",
            "Clicks": "6709",
            "Conversions": "55",
            "Conversion Value": "$8,653",
            "Cost per Result": "$39.24"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "202346",
            "Clicks": "5878",
            "Conversions": "63",
            "Conversion Value": "$12,613",
            "Cost per Result": "$21.53"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "245198",
            "Clicks": "2861",
            "Conversions": "49",
            "Conversion Value": "$5,113",
            "Cost per Result": "$27.75"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "448166",
            "Clicks": "4242",
            "Conversions": "73",
            "Conversion Value": "$5,764",
            "Cost per Result": "$29.60"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "270685",
            "Clicks": "4523",
            "Conversions": "77",
            "Conversion Value": "$5,659",
            "Cost per Result": "$59.72"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "306721",
            "Clicks": "2601",
            "Conversions": "72",
            "Conversion Value": "$4,284",
            "Cost per Result": "$23.80"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "391165",
            "Clicks": "6306",
            "Conversions": "49",
            "Conversion Value": "$9,478",
            "Cost per Result": "$64.08"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "349489",
            "Clicks": "6490",
            "Conversions": "89",
            "Conversion Value": "$9,334",
            "Cost per Result": "$22.09"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "487465",
            "Clicks": "2905",
            "Conversions": "83",
            "Conversion Value": "$12,674",
            "Cost per Result": "$56.79"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "425805",
            "Clicks": "4537",
            "Conversions": "55",
            "Conversion Value": "$15,541",
            "Cost per Result": "$52.79"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "192024",
            "Clicks": "2275",
            "Conversions": "68",
            "Conversion Value": "$11,533",
            "Cost per Result": "$37.77"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "101414",
            "Clicks": "2193",
            "Conversions": "76",
            "Conversion Value": "$6,147",
            "Cost per Result": "$48.17"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "215995",
            "Clicks": "6961",
            "Conversions": "71",
            "Conversion Value": "$15,275",
            "Cost per Result": "$24.22"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "184982",
            "Clicks": "3779",
            "Conversions": "84",
            "Conversion Value": "$12,806",
            "Cost per Result": "$69.07"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "279242",
            "Clicks": "5227",
            "Conversions": "64",
            "Conversion Value": "$8,191",
            "Cost per Result": "$84.50"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "466317",
            "Clicks": "6914",
            "Conversions": "70",
            "Conversion Value": "$14,825",
            "Cost per Result": "$32.56"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "226870",
            "Clicks": "2158",
            "Conversions": "60",
            "Conversion Value": "$7,782",
            "Cost per Result": "$44.95"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "452670",
            "Clicks": "4494",
            "Conversions": "81",
            "Conversion Value": "$9,908",
            "Cost per Result": "$40.79"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "224075",
            "Clicks": "3787",
            "Conversions": "64",
            "Conversion Value": "$12,699",
            "Cost per Result": "$73.30"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "108498",
            "Clicks": "3244",
            "Conversions": "84",
            "Conversion Value": "$11,462",
            "Cost per Result": "$46.23"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "360812",
            "Clicks": "2431",
            "Conversions": "42",
            "Conversion Value": "$9,795",
            "Cost per Result": "$55.07"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "250145",
            "Clicks": "2206",
            "Conversions": "88",
            "Conversion Value": "$8,585",
            "Cost per Result": "$74.60"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "192895",
            "Clicks": "2811",
            "Conversions": "60",
            "Conversion Value": "$5,390",
            "Cost per Result": "$59.91"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "221991",
            "Clicks": "3620",
            "Conversions": "75",
            "Conversion Value": "$5,775",
            "Cost per Result": "$31.54"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "401730",
            "Clicks": "2331",
            "Conversions": "83",
            "Conversion Value": "$5,633",
            "Cost per Result": "$57.85"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "217977",
            "Clicks": "4716",
            "Conversions": "69",
            "Conversion Value": "$7,194",
            "Cost per Result": "$49.39"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "357273",
            "Clicks": "4978",
            "Conversions": "78",
            "Conversion Value": "$8,679",
            "Cost per Result": "$73.58"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "376424",
            "Clicks": "3381",
            "Conversions": "47",
            "Conversion Value": "$7,846",
            "Cost per Result": "$52.23"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "285561",
            "Clicks": "6108",
            "Conversions": "49",
            "Conversion Value": "$10,608",
            "Cost per Result": "$47.01"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "362458",
            "Clicks": "5136",
            "Conversions": "79",
            "Conversion Value": "$15,147",
            "Cost per Result": "$51.59"
          }
        ]
      },
      "NFTTradersData": {
        "name": "NFT Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "354127",
            "Clicks": "2500",
            "Conversions": "63",
            "Conversion Value": "$6,742",
            "Cost per Result": "$82.96"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "388924",
            "Clicks": "3945",
            "Conversions": "48",
            "Conversion Value": "$12,155",
            "Cost per Result": "$49.11"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "180161",
            "Clicks": "2369",
            "Conversions": "86",
            "Conversion Value": "$11,939",
            "Cost per Result": "$42.27"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "307469",
            "Clicks": "6958",
            "Conversions": "64",
            "Conversion Value": "$12,880",
            "Cost per Result": "$20.71"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "174517",
            "Clicks": "2801",
            "Conversions": "79",
            "Conversion Value": "$10,195",
            "Cost per Result": "$62.95"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "412616",
            "Clicks": "6479",
            "Conversions": "57",
            "Conversion Value": "$7,748",
            "Cost per Result": "$23.82"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "168255",
            "Clicks": "2636",
            "Conversions": "81",
            "Conversion Value": "$12,654",
            "Cost per Result": "$70.65"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "451077",
            "Clicks": "5438",
            "Conversions": "82",
            "Conversion Value": "$9,096",
            "Cost per Result": "$32.36"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "377284",
            "Clicks": "3776",
            "Conversions": "74",
            "Conversion Value": "$14,254",
            "Cost per Result": "$81.52"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "449901",
            "Clicks": "5784",
            "Conversions": "75",
            "Conversion Value": "$8,948",
            "Cost per Result": "$30.19"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "467859",
            "Clicks": "5256",
            "Conversions": "52",
            "Conversion Value": "$4,112",
            "Cost per Result": "$51.42"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "175213",
            "Clicks": "6614",
            "Conversions": "87",
            "Conversion Value": "$6,137",
            "Cost per Result": "$68.86"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "105409",
            "Clicks": "6932",
            "Conversions": "70",
            "Conversion Value": "$8,494",
            "Cost per Result": "$71.87"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "163200",
            "Clicks": "4436",
            "Conversions": "87",
            "Conversion Value": "$4,722",
            "Cost per Result": "$47.12"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "272852",
            "Clicks": "6715",
            "Conversions": "47",
            "Conversion Value": "$15,325",
            "Cost per Result": "$33.38"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "123411",
            "Clicks": "4237",
            "Conversions": "58",
            "Conversion Value": "$10,484",
            "Cost per Result": "$28.99"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "460587",
            "Clicks": "2000",
            "Conversions": "45",
            "Conversion Value": "$14,291",
            "Cost per Result": "$78.12"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "249033",
            "Clicks": "5101",
            "Conversions": "66",
            "Conversion Value": "$10,111",
            "Cost per Result": "$72.10"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "485373",
            "Clicks": "6196",
            "Conversions": "56",
            "Conversion Value": "$6,751",
            "Cost per Result": "$77.53"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "409614",
            "Clicks": "6432",
            "Conversions": "90",
            "Conversion Value": "$11,936",
            "Cost per Result": "$24.03"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "431920",
            "Clicks": "3285",
            "Conversions": "73",
            "Conversion Value": "$6,498",
            "Cost per Result": "$34.18"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "419682",
            "Clicks": "2933",
            "Conversions": "88",
            "Conversion Value": "$13,748",
            "Cost per Result": "$26.30"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "252953",
            "Clicks": "5917",
            "Conversions": "54",
            "Conversion Value": "$10,163",
            "Cost per Result": "$44.63"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "473694",
            "Clicks": "2702",
            "Conversions": "58",
            "Conversion Value": "$8,269",
            "Cost per Result": "$36.34"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "416032",
            "Clicks": "6920",
            "Conversions": "73",
            "Conversion Value": "$12,264",
            "Cost per Result": "$58.53"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "446948",
            "Clicks": "5451",
            "Conversions": "77",
            "Conversion Value": "$11,390",
            "Cost per Result": "$30.38"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "334637",
            "Clicks": "5203",
            "Conversions": "61",
            "Conversion Value": "$4,572",
            "Cost per Result": "$36.91"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "147994",
            "Clicks": "3137",
            "Conversions": "83",
            "Conversion Value": "$12,896",
            "Cost per Result": "$30.37"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "334670",
            "Clicks": "4100",
            "Conversions": "54",
            "Conversion Value": "$8,142",
            "Cost per Result": "$84.50"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "366373",
            "Clicks": "5078",
            "Conversions": "88",
            "Conversion Value": "$14,187",
            "Cost per Result": "$69.80"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "334406",
            "Clicks": "4008",
            "Conversions": "58",
            "Conversion Value": "$14,856",
            "Cost per Result": "$44.42"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "317099",
            "Clicks": "2230",
            "Conversions": "66",
            "Conversion Value": "$4,002",
            "Cost per Result": "$38.06"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "486018",
            "Clicks": "4392",
            "Conversions": "56",
            "Conversion Value": "$11,046",
            "Cost per Result": "$44.08"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "398544",
            "Clicks": "2029",
            "Conversions": "85",
            "Conversion Value": "$4,139",
            "Cost per Result": "$64.43"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "351593",
            "Clicks": "4198",
            "Conversions": "60",
            "Conversion Value": "$4,469",
            "Cost per Result": "$67.18"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "442456",
            "Clicks": "6418",
            "Conversions": "75",
            "Conversion Value": "$5,278",
            "Cost per Result": "$51.42"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "458481",
            "Clicks": "5537",
            "Conversions": "78",
            "Conversion Value": "$4,763",
            "Cost per Result": "$70.48"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "164785",
            "Clicks": "2319",
            "Conversions": "62",
            "Conversion Value": "$11,728",
            "Cost per Result": "$64.72"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "171774",
            "Clicks": "5224",
            "Conversions": "70",
            "Conversion Value": "$8,287",
            "Cost per Result": "$65.59"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "275475",
            "Clicks": "3115",
            "Conversions": "55",
            "Conversion Value": "$4,816",
            "Cost per Result": "$55.54"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "290802",
            "Clicks": "6813",
            "Conversions": "88",
            "Conversion Value": "$13,868",
            "Cost per Result": "$68.11"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "357746",
            "Clicks": "2207",
            "Conversions": "60",
            "Conversion Value": "$8,127",
            "Cost per Result": "$70.39"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "357984",
            "Clicks": "4950",
            "Conversions": "63",
            "Conversion Value": "$4,154",
            "Cost per Result": "$77.38"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "297121",
            "Clicks": "4798",
            "Conversions": "41",
            "Conversion Value": "$8,163",
            "Cost per Result": "$53.91"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "179920",
            "Clicks": "6868",
            "Conversions": "41",
            "Conversion Value": "$6,806",
            "Cost per Result": "$36.54"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "173093",
            "Clicks": "6557",
            "Conversions": "62",
            "Conversion Value": "$8,760",
            "Cost per Result": "$68.04"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "300875",
            "Clicks": "6322",
            "Conversions": "90",
            "Conversion Value": "$8,122",
            "Cost per Result": "$46.29"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "199031",
            "Clicks": "4371",
            "Conversions": "64",
            "Conversion Value": "$11,726",
            "Cost per Result": "$35.69"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "418096",
            "Clicks": "4141",
            "Conversions": "61",
            "Conversion Value": "$7,817",
            "Cost per Result": "$33.68"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "116745",
            "Clicks": "4202",
            "Conversions": "85",
            "Conversion Value": "$12,070",
            "Cost per Result": "$60.53"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "447847",
            "Clicks": "6207",
            "Conversions": "73",
            "Conversion Value": "$6,614",
            "Cost per Result": "$43.53"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "260588",
            "Clicks": "2930",
            "Conversions": "88",
            "Conversion Value": "$5,886",
            "Cost per Result": "$56.59"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "283375",
            "Clicks": "3436",
            "Conversions": "77",
            "Conversion Value": "$14,992",
            "Cost per Result": "$71.34"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "272736",
            "Clicks": "3624",
            "Conversions": "47",
            "Conversion Value": "$15,529",
            "Cost per Result": "$39.13"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "354918",
            "Clicks": "4275",
            "Conversions": "90",
            "Conversion Value": "$4,944",
            "Cost per Result": "$75.60"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "378380",
            "Clicks": "2777",
            "Conversions": "67",
            "Conversion Value": "$13,126",
            "Cost per Result": "$40.26"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "112566",
            "Clicks": "5929",
            "Conversions": "77",
            "Conversion Value": "$11,439",
            "Cost per Result": "$30.59"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "350059",
            "Clicks": "6922",
            "Conversions": "60",
            "Conversion Value": "$7,743",
            "Cost per Result": "$54.01"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "491503",
            "Clicks": "6971",
            "Conversions": "58",
            "Conversion Value": "$11,611",
            "Cost per Result": "$22.81"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "168888",
            "Clicks": "2450",
            "Conversions": "75",
            "Conversion Value": "$7,385",
            "Cost per Result": "$27.02"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "353455",
            "Clicks": "6933",
            "Conversions": "72",
            "Conversion Value": "$9,947",
            "Cost per Result": "$72.80"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "222498",
            "Clicks": "4334",
            "Conversions": "52",
            "Conversion Value": "$7,416",
            "Cost per Result": "$54.20"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "277054",
            "Clicks": "4695",
            "Conversions": "70",
            "Conversion Value": "$15,692",
            "Cost per Result": "$21.21"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "192241",
            "Clicks": "6387",
            "Conversions": "89",
            "Conversion Value": "$6,609",
            "Cost per Result": "$44.93"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "223090",
            "Clicks": "6031",
            "Conversions": "90",
            "Conversion Value": "$8,882",
            "Cost per Result": "$81.00"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "233697",
            "Clicks": "5323",
            "Conversions": "84",
            "Conversion Value": "$6,528",
            "Cost per Result": "$75.68"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "439867",
            "Clicks": "4151",
            "Conversions": "59",
            "Conversion Value": "$14,484",
            "Cost per Result": "$46.18"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "134273",
            "Clicks": "4762",
            "Conversions": "42",
            "Conversion Value": "$13,020",
            "Cost per Result": "$80.57"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "291535",
            "Clicks": "2755",
            "Conversions": "41",
            "Conversion Value": "$6,272",
            "Cost per Result": "$65.89"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "460364",
            "Clicks": "5539",
            "Conversions": "76",
            "Conversion Value": "$12,720",
            "Cost per Result": "$79.97"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "462158",
            "Clicks": "2327",
            "Conversions": "64",
            "Conversion Value": "$6,116",
            "Cost per Result": "$39.80"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "336141",
            "Clicks": "3998",
            "Conversions": "77",
            "Conversion Value": "$15,217",
            "Cost per Result": "$38.35"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "265551",
            "Clicks": "2881",
            "Conversions": "90",
            "Conversion Value": "$4,130",
            "Cost per Result": "$54.47"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "209783",
            "Clicks": "6841",
            "Conversions": "52",
            "Conversion Value": "$14,257",
            "Cost per Result": "$30.33"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "411895",
            "Clicks": "4066",
            "Conversions": "54",
            "Conversion Value": "$9,460",
            "Cost per Result": "$65.43"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "375143",
            "Clicks": "5390",
            "Conversions": "60",
            "Conversion Value": "$6,513",
            "Cost per Result": "$22.13"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "264273",
            "Clicks": "5502",
            "Conversions": "65",
            "Conversion Value": "$4,028",
            "Cost per Result": "$34.13"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "473457",
            "Clicks": "4879",
            "Conversions": "64",
            "Conversion Value": "$14,277",
            "Cost per Result": "$24.68"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "460629",
            "Clicks": "6233",
            "Conversions": "48",
            "Conversion Value": "$11,013",
            "Cost per Result": "$41.24"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "477393",
            "Clicks": "3353",
            "Conversions": "52",
            "Conversion Value": "$7,209",
            "Cost per Result": "$22.04"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "159009",
            "Clicks": "4524",
            "Conversions": "44",
            "Conversion Value": "$11,755",
            "Cost per Result": "$54.27"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "466907",
            "Clicks": "5695",
            "Conversions": "59",
            "Conversion Value": "$15,210",
            "Cost per Result": "$81.67"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "447960",
            "Clicks": "3506",
            "Conversions": "67",
            "Conversion Value": "$9,730",
            "Cost per Result": "$81.77"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "382414",
            "Clicks": "5772",
            "Conversions": "77",
            "Conversion Value": "$8,597",
            "Cost per Result": "$82.43"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "291875",
            "Clicks": "4881",
            "Conversions": "53",
            "Conversion Value": "$9,641",
            "Cost per Result": "$62.63"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "356399",
            "Clicks": "4172",
            "Conversions": "51",
            "Conversion Value": "$11,302",
            "Cost per Result": "$21.17"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "377055",
            "Clicks": "3487",
            "Conversions": "62",
            "Conversion Value": "$15,945",
            "Cost per Result": "$29.93"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "281690",
            "Clicks": "2062",
            "Conversions": "86",
            "Conversion Value": "$15,496",
            "Cost per Result": "$48.66"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "493040",
            "Clicks": "3783",
            "Conversions": "55",
            "Conversion Value": "$7,696",
            "Cost per Result": "$32.97"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "206538",
            "Clicks": "3167",
            "Conversions": "57",
            "Conversion Value": "$7,133",
            "Cost per Result": "$35.83"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "311995",
            "Clicks": "2347",
            "Conversions": "46",
            "Conversion Value": "$15,504",
            "Cost per Result": "$79.53"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "417663",
            "Clicks": "4624",
            "Conversions": "78",
            "Conversion Value": "$15,992",
            "Cost per Result": "$36.50"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "383963",
            "Clicks": "5565",
            "Conversions": "79",
            "Conversion Value": "$13,182",
            "Cost per Result": "$46.21"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "478365",
            "Clicks": "2642",
            "Conversions": "47",
            "Conversion Value": "$14,799",
            "Cost per Result": "$67.55"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "396534",
            "Clicks": "6511",
            "Conversions": "69",
            "Conversion Value": "$7,197",
            "Cost per Result": "$77.06"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "256615",
            "Clicks": "6504",
            "Conversions": "80",
            "Conversion Value": "$11,805",
            "Cost per Result": "$62.05"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "484064",
            "Clicks": "5251",
            "Conversions": "41",
            "Conversion Value": "$10,564",
            "Cost per Result": "$76.92"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "465826",
            "Clicks": "3128",
            "Conversions": "82",
            "Conversion Value": "$12,132",
            "Cost per Result": "$81.16"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "225197",
            "Clicks": "5928",
            "Conversions": "84",
            "Conversion Value": "$8,299",
            "Cost per Result": "$54.55"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "438334",
            "Clicks": "2260",
            "Conversions": "83",
            "Conversion Value": "$12,306",
            "Cost per Result": "$84.21"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "230827",
            "Clicks": "5787",
            "Conversions": "72",
            "Conversion Value": "$4,045",
            "Cost per Result": "$52.78"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "279178",
            "Clicks": "2409",
            "Conversions": "54",
            "Conversion Value": "$4,893",
            "Cost per Result": "$52.84"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "371464",
            "Clicks": "5354",
            "Conversions": "80",
            "Conversion Value": "$11,704",
            "Cost per Result": "$59.15"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "157387",
            "Clicks": "5213",
            "Conversions": "46",
            "Conversion Value": "$5,731",
            "Cost per Result": "$34.21"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "478484",
            "Clicks": "6993",
            "Conversions": "90",
            "Conversion Value": "$10,919",
            "Cost per Result": "$85.00"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "167062",
            "Clicks": "3421",
            "Conversions": "84",
            "Conversion Value": "$15,287",
            "Cost per Result": "$51.65"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "314112",
            "Clicks": "6653",
            "Conversions": "76",
            "Conversion Value": "$4,685",
            "Cost per Result": "$53.38"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "445344",
            "Clicks": "2486",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$62.60"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "357892",
            "Clicks": "5693",
            "Conversions": "74",
            "Conversion Value": "$13,974",
            "Cost per Result": "$43.12"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "114746",
            "Clicks": "4948",
            "Conversions": "45",
            "Conversion Value": "$13,462",
            "Cost per Result": "$40.68"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "301626",
            "Clicks": "2565",
            "Conversions": "54",
            "Conversion Value": "$11,667",
            "Cost per Result": "$29.61"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "400144",
            "Clicks": "4510",
            "Conversions": "72",
            "Conversion Value": "$13,745",
            "Cost per Result": "$65.08"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "360431",
            "Clicks": "6133",
            "Conversions": "81",
            "Conversion Value": "$9,039",
            "Cost per Result": "$53.91"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "364161",
            "Clicks": "4880",
            "Conversions": "86",
            "Conversion Value": "$13,877",
            "Cost per Result": "$53.32"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "255912",
            "Clicks": "5576",
            "Conversions": "72",
            "Conversion Value": "$13,722",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "348377",
            "Clicks": "2955",
            "Conversions": "54",
            "Conversion Value": "$9,452",
            "Cost per Result": "$77.39"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "351196",
            "Clicks": "2766",
            "Conversions": "84",
            "Conversion Value": "$11,426",
            "Cost per Result": "$69.71"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "172145",
            "Clicks": "3447",
            "Conversions": "49",
            "Conversion Value": "$6,405",
            "Cost per Result": "$50.69"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "240362",
            "Clicks": "4466",
            "Conversions": "73",
            "Conversion Value": "$5,620",
            "Cost per Result": "$25.92"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "428082",
            "Clicks": "5361",
            "Conversions": "83",
            "Conversion Value": "$14,281",
            "Cost per Result": "$62.57"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "121936",
            "Clicks": "3732",
            "Conversions": "76",
            "Conversion Value": "$4,651",
            "Cost per Result": "$52.70"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "156432",
            "Clicks": "6082",
            "Conversions": "51",
            "Conversion Value": "$15,053",
            "Cost per Result": "$43.29"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "303681",
            "Clicks": "5901",
            "Conversions": "83",
            "Conversion Value": "$9,321",
            "Cost per Result": "$20.73"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "344204",
            "Clicks": "6109",
            "Conversions": "46",
            "Conversion Value": "$6,385",
            "Cost per Result": "$66.28"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "261713",
            "Clicks": "2827",
            "Conversions": "69",
            "Conversion Value": "$14,289",
            "Cost per Result": "$78.25"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "174674",
            "Clicks": "2277",
            "Conversions": "52",
            "Conversion Value": "$10,930",
            "Cost per Result": "$23.25"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "259665",
            "Clicks": "3938",
            "Conversions": "63",
            "Conversion Value": "$10,656",
            "Cost per Result": "$81.79"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "475775",
            "Clicks": "6744",
            "Conversions": "53",
            "Conversion Value": "$8,032",
            "Cost per Result": "$66.42"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "153348",
            "Clicks": "5622",
            "Conversions": "47",
            "Conversion Value": "$13,104",
            "Cost per Result": "$26.27"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "239988",
            "Clicks": "3126",
            "Conversions": "85",
            "Conversion Value": "$14,813",
            "Cost per Result": "$63.32"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "174510",
            "Clicks": "6639",
            "Conversions": "70",
            "Conversion Value": "$7,776",
            "Cost per Result": "$50.89"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "103315",
            "Clicks": "4087",
            "Conversions": "43",
            "Conversion Value": "$9,539",
            "Cost per Result": "$48.38"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "196315",
            "Clicks": "6415",
            "Conversions": "41",
            "Conversion Value": "$10,892",
            "Cost per Result": "$77.00"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "497184",
            "Clicks": "5977",
            "Conversions": "86",
            "Conversion Value": "$12,197",
            "Cost per Result": "$84.95"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "406991",
            "Clicks": "6076",
            "Conversions": "69",
            "Conversion Value": "$7,557",
            "Cost per Result": "$59.28"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "476962",
            "Clicks": "2010",
            "Conversions": "53",
            "Conversion Value": "$5,532",
            "Cost per Result": "$20.90"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "111792",
            "Clicks": "2087",
            "Conversions": "56",
            "Conversion Value": "$5,473",
            "Cost per Result": "$38.38"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "167081",
            "Clicks": "5021",
            "Conversions": "40",
            "Conversion Value": "$6,163",
            "Cost per Result": "$50.36"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "383234",
            "Clicks": "5901",
            "Conversions": "76",
            "Conversion Value": "$7,685",
            "Cost per Result": "$44.24"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "360201",
            "Clicks": "2232",
            "Conversions": "71",
            "Conversion Value": "$14,917",
            "Cost per Result": "$35.32"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "403188",
            "Clicks": "6578",
            "Conversions": "56",
            "Conversion Value": "$14,322",
            "Cost per Result": "$25.14"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "211998",
            "Clicks": "6485",
            "Conversions": "84",
            "Conversion Value": "$10,700",
            "Cost per Result": "$77.66"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "286610",
            "Clicks": "4292",
            "Conversions": "47",
            "Conversion Value": "$13,573",
            "Cost per Result": "$37.88"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "361135",
            "Clicks": "6002",
            "Conversions": "41",
            "Conversion Value": "$7,149",
            "Cost per Result": "$27.26"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "125505",
            "Clicks": "5587",
            "Conversions": "42",
            "Conversion Value": "$9,085",
            "Cost per Result": "$20.96"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "283687",
            "Clicks": "6481",
            "Conversions": "82",
            "Conversion Value": "$7,300",
            "Cost per Result": "$47.97"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "304596",
            "Clicks": "4535",
            "Conversions": "68",
            "Conversion Value": "$8,501",
            "Cost per Result": "$27.39"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "202571",
            "Clicks": "2066",
            "Conversions": "76",
            "Conversion Value": "$13,582",
            "Cost per Result": "$25.69"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "259528",
            "Clicks": "5363",
            "Conversions": "88",
            "Conversion Value": "$14,025",
            "Cost per Result": "$42.69"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "485743",
            "Clicks": "3532",
            "Conversions": "62",
            "Conversion Value": "$10,720",
            "Cost per Result": "$61.14"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "398303",
            "Clicks": "5731",
            "Conversions": "60",
            "Conversion Value": "$14,558",
            "Cost per Result": "$62.98"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "484237",
            "Clicks": "6918",
            "Conversions": "52",
            "Conversion Value": "$7,600",
            "Cost per Result": "$43.33"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "191838",
            "Clicks": "6888",
            "Conversions": "44",
            "Conversion Value": "$7,941",
            "Cost per Result": "$63.08"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "215647",
            "Clicks": "6219",
            "Conversions": "87",
            "Conversion Value": "$7,690",
            "Cost per Result": "$33.89"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "146679",
            "Clicks": "2089",
            "Conversions": "53",
            "Conversion Value": "$14,955",
            "Cost per Result": "$23.59"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "254853",
            "Clicks": "5871",
            "Conversions": "74",
            "Conversion Value": "$7,162",
            "Cost per Result": "$65.81"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "269312",
            "Clicks": "2527",
            "Conversions": "52",
            "Conversion Value": "$11,672",
            "Cost per Result": "$65.71"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "247382",
            "Clicks": "4405",
            "Conversions": "68",
            "Conversion Value": "$13,238",
            "Cost per Result": "$69.72"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "266224",
            "Clicks": "6089",
            "Conversions": "43",
            "Conversion Value": "$7,227",
            "Cost per Result": "$23.10"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "238764",
            "Clicks": "3207",
            "Conversions": "58",
            "Conversion Value": "$12,101",
            "Cost per Result": "$29.11"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "126754",
            "Clicks": "6311",
            "Conversions": "48",
            "Conversion Value": "$5,522",
            "Cost per Result": "$78.76"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "273686",
            "Clicks": "5033",
            "Conversions": "78",
            "Conversion Value": "$8,889",
            "Cost per Result": "$25.19"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "118401",
            "Clicks": "3319",
            "Conversions": "49",
            "Conversion Value": "$9,569",
            "Cost per Result": "$23.72"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "451248",
            "Clicks": "6252",
            "Conversions": "78",
            "Conversion Value": "$4,160",
            "Cost per Result": "$51.14"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "478972",
            "Clicks": "6275",
            "Conversions": "68",
            "Conversion Value": "$7,278",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "347790",
            "Clicks": "5500",
            "Conversions": "80",
            "Conversion Value": "$5,640",
            "Cost per Result": "$20.25"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "490731",
            "Clicks": "5177",
            "Conversions": "69",
            "Conversion Value": "$9,247",
            "Cost per Result": "$51.12"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "188621",
            "Clicks": "6962",
            "Conversions": "57",
            "Conversion Value": "$6,240",
            "Cost per Result": "$57.85"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "123621",
            "Clicks": "2962",
            "Conversions": "62",
            "Conversion Value": "$15,467",
            "Cost per Result": "$49.41"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "247614",
            "Clicks": "4263",
            "Conversions": "90",
            "Conversion Value": "$15,983",
            "Cost per Result": "$33.57"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "173890",
            "Clicks": "4715",
            "Conversions": "45",
            "Conversion Value": "$10,514",
            "Cost per Result": "$75.57"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "393686",
            "Clicks": "5316",
            "Conversions": "52",
            "Conversion Value": "$6,684",
            "Cost per Result": "$63.75"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "418765",
            "Clicks": "2034",
            "Conversions": "67",
            "Conversion Value": "$8,557",
            "Cost per Result": "$48.19"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "131161",
            "Clicks": "4384",
            "Conversions": "88",
            "Conversion Value": "$15,596",
            "Cost per Result": "$34.52"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "356002",
            "Clicks": "5842",
            "Conversions": "64",
            "Conversion Value": "$5,905",
            "Cost per Result": "$40.93"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "374003",
            "Clicks": "6588",
            "Conversions": "50",
            "Conversion Value": "$4,713",
            "Cost per Result": "$59.04"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "479131",
            "Clicks": "6134",
            "Conversions": "73",
            "Conversion Value": "$12,596",
            "Cost per Result": "$37.71"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "160019",
            "Clicks": "6432",
            "Conversions": "71",
            "Conversion Value": "$6,488",
            "Cost per Result": "$44.80"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "216546",
            "Clicks": "6771",
            "Conversions": "66",
            "Conversion Value": "$11,022",
            "Cost per Result": "$33.77"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "389978",
            "Clicks": "4328",
            "Conversions": "60",
            "Conversion Value": "$12,201",
            "Cost per Result": "$42.59"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "177679",
            "Clicks": "6586",
            "Conversions": "77",
            "Conversion Value": "$7,494",
            "Cost per Result": "$61.25"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "438574",
            "Clicks": "2285",
            "Conversions": "54",
            "Conversion Value": "$11,451",
            "Cost per Result": "$25.82"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "326259",
            "Clicks": "5076",
            "Conversions": "40",
            "Conversion Value": "$9,092",
            "Cost per Result": "$72.71"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "331637",
            "Clicks": "2745",
            "Conversions": "65",
            "Conversion Value": "$7,006",
            "Cost per Result": "$45.53"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "344126",
            "Clicks": "4277",
            "Conversions": "79",
            "Conversion Value": "$11,787",
            "Cost per Result": "$31.15"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "449781",
            "Clicks": "6137",
            "Conversions": "75",
            "Conversion Value": "$14,586",
            "Cost per Result": "$82.58"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "345616",
            "Clicks": "3965",
            "Conversions": "47",
            "Conversion Value": "$7,617",
            "Cost per Result": "$23.73"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "167479",
            "Clicks": "6000",
            "Conversions": "58",
            "Conversion Value": "$9,933",
            "Cost per Result": "$69.63"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "319661",
            "Clicks": "6043",
            "Conversions": "51",
            "Conversion Value": "$15,130",
            "Cost per Result": "$22.60"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "364013",
            "Clicks": "3212",
            "Conversions": "54",
            "Conversion Value": "$10,513",
            "Cost per Result": "$80.32"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "463394",
            "Clicks": "2425",
            "Conversions": "61",
            "Conversion Value": "$11,319",
            "Cost per Result": "$45.47"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "475703",
            "Clicks": "2818",
            "Conversions": "60",
            "Conversion Value": "$15,149",
            "Cost per Result": "$68.42"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "257189",
            "Clicks": "3207",
            "Conversions": "66",
            "Conversion Value": "$13,156",
            "Cost per Result": "$79.50"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "355695",
            "Clicks": "5506",
            "Conversions": "51",
            "Conversion Value": "$15,535",
            "Cost per Result": "$42.96"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "428086",
            "Clicks": "5681",
            "Conversions": "65",
            "Conversion Value": "$15,366",
            "Cost per Result": "$24.28"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "357582",
            "Clicks": "2766",
            "Conversions": "74",
            "Conversion Value": "$14,495",
            "Cost per Result": "$35.91"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "448991",
            "Clicks": "2504",
            "Conversions": "49",
            "Conversion Value": "$8,523",
            "Cost per Result": "$30.44"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "383188",
            "Clicks": "2141",
            "Conversions": "77",
            "Conversion Value": "$15,569",
            "Cost per Result": "$60.39"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "298819",
            "Clicks": "2039",
            "Conversions": "70",
            "Conversion Value": "$4,526",
            "Cost per Result": "$82.59"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "184720",
            "Clicks": "5415",
            "Conversions": "75",
            "Conversion Value": "$7,640",
            "Cost per Result": "$69.51"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "118764",
            "Clicks": "6451",
            "Conversions": "41",
            "Conversion Value": "$11,058",
            "Cost per Result": "$37.04"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "170537",
            "Clicks": "4791",
            "Conversions": "67",
            "Conversion Value": "$6,141",
            "Cost per Result": "$42.40"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "378694",
            "Clicks": "2532",
            "Conversions": "84",
            "Conversion Value": "$7,414",
            "Cost per Result": "$45.11"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "346338",
            "Clicks": "5695",
            "Conversions": "65",
            "Conversion Value": "$14,385",
            "Cost per Result": "$68.30"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "332622",
            "Clicks": "5394",
            "Conversions": "62",
            "Conversion Value": "$6,902",
            "Cost per Result": "$36.02"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "175425",
            "Clicks": "5969",
            "Conversions": "70",
            "Conversion Value": "$15,006",
            "Cost per Result": "$58.15"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "192358",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$8,398",
            "Cost per Result": "$41.60"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "439743",
            "Clicks": "2646",
            "Conversions": "58",
            "Conversion Value": "$6,664",
            "Cost per Result": "$77.64"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "363653",
            "Clicks": "6894",
            "Conversions": "86",
            "Conversion Value": "$15,369",
            "Cost per Result": "$56.28"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "335878",
            "Clicks": "4908",
            "Conversions": "42",
            "Conversion Value": "$8,131",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "230896",
            "Clicks": "3825",
            "Conversions": "62",
            "Conversion Value": "$7,020",
            "Cost per Result": "$50.99"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "186541",
            "Clicks": "4896",
            "Conversions": "56",
            "Conversion Value": "$15,950",
            "Cost per Result": "$58.35"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "387280",
            "Clicks": "4768",
            "Conversions": "74",
            "Conversion Value": "$4,957",
            "Cost per Result": "$71.84"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "358968",
            "Clicks": "2156",
            "Conversions": "73",
            "Conversion Value": "$8,905",
            "Cost per Result": "$28.82"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "310985",
            "Clicks": "2481",
            "Conversions": "48",
            "Conversion Value": "$7,927",
            "Cost per Result": "$51.33"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "159148",
            "Clicks": "4205",
            "Conversions": "50",
            "Conversion Value": "$6,695",
            "Cost per Result": "$41.00"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "189681",
            "Clicks": "5149",
            "Conversions": "47",
            "Conversion Value": "$12,550",
            "Cost per Result": "$71.36"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "447950",
            "Clicks": "4996",
            "Conversions": "65",
            "Conversion Value": "$12,281",
            "Cost per Result": "$40.21"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "381580",
            "Clicks": "3120",
            "Conversions": "55",
            "Conversion Value": "$11,333",
            "Cost per Result": "$36.49"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "366564",
            "Clicks": "3570",
            "Conversions": "83",
            "Conversion Value": "$6,409",
            "Cost per Result": "$31.57"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "439156",
            "Clicks": "5149",
            "Conversions": "63",
            "Conversion Value": "$10,288",
            "Cost per Result": "$65.17"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "259998",
            "Clicks": "6280",
            "Conversions": "85",
            "Conversion Value": "$14,436",
            "Cost per Result": "$69.60"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "260217",
            "Clicks": "4742",
            "Conversions": "60",
            "Conversion Value": "$14,959",
            "Cost per Result": "$50.26"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "275359",
            "Clicks": "3476",
            "Conversions": "72",
            "Conversion Value": "$15,607",
            "Cost per Result": "$34.26"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "400366",
            "Clicks": "6286",
            "Conversions": "73",
            "Conversion Value": "$12,966",
            "Cost per Result": "$35.59"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "144887",
            "Clicks": "4305",
            "Conversions": "86",
            "Conversion Value": "$4,182",
            "Cost per Result": "$74.85"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "492352",
            "Clicks": "5128",
            "Conversions": "57",
            "Conversion Value": "$5,137",
            "Cost per Result": "$20.62"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "131276",
            "Clicks": "4067",
            "Conversions": "57",
            "Conversion Value": "$10,581",
            "Cost per Result": "$56.10"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "163988",
            "Clicks": "4643",
            "Conversions": "65",
            "Conversion Value": "$14,973",
            "Cost per Result": "$67.21"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "335679",
            "Clicks": "5903",
            "Conversions": "66",
            "Conversion Value": "$6,941",
            "Cost per Result": "$31.14"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "100991",
            "Clicks": "5784",
            "Conversions": "43",
            "Conversion Value": "$15,456",
            "Cost per Result": "$21.57"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "498779",
            "Clicks": "2202",
            "Conversions": "90",
            "Conversion Value": "$6,396",
            "Cost per Result": "$35.21"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "119368",
            "Clicks": "2856",
            "Conversions": "61",
            "Conversion Value": "$9,952",
            "Cost per Result": "$28.69"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "345663",
            "Clicks": "3989",
            "Conversions": "81",
            "Conversion Value": "$11,490",
            "Cost per Result": "$46.19"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "430194",
            "Clicks": "3396",
            "Conversions": "70",
            "Conversion Value": "$13,819",
            "Cost per Result": "$72.69"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "350348",
            "Clicks": "5589",
            "Conversions": "71",
            "Conversion Value": "$10,878",
            "Cost per Result": "$31.33"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "315354",
            "Clicks": "5402",
            "Conversions": "85",
            "Conversion Value": "$10,247",
            "Cost per Result": "$53.92"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "371542",
            "Clicks": "4484",
            "Conversions": "82",
            "Conversion Value": "$8,064",
            "Cost per Result": "$25.13"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "384824",
            "Clicks": "2790",
            "Conversions": "78",
            "Conversion Value": "$11,215",
            "Cost per Result": "$36.42"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "214290",
            "Clicks": "6441",
            "Conversions": "72",
            "Conversion Value": "$12,632",
            "Cost per Result": "$42.13"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "334573",
            "Clicks": "3353",
            "Conversions": "76",
            "Conversion Value": "$15,106",
            "Cost per Result": "$80.68"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "210999",
            "Clicks": "5604",
            "Conversions": "68",
            "Conversion Value": "$7,505",
            "Cost per Result": "$71.07"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "330328",
            "Clicks": "4526",
            "Conversions": "52",
            "Conversion Value": "$15,765",
            "Cost per Result": "$41.16"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "229250",
            "Clicks": "4410",
            "Conversions": "64",
            "Conversion Value": "$7,230",
            "Cost per Result": "$38.41"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "348981",
            "Clicks": "2690",
            "Conversions": "43",
            "Conversion Value": "$12,282",
            "Cost per Result": "$31.23"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "273530",
            "Clicks": "5247",
            "Conversions": "43",
            "Conversion Value": "$7,264",
            "Cost per Result": "$27.11"
          }
        ]
      },
      "FlippersData": {
        "name": "Flippers",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "225308",
            "Clicks": "5576",
            "Conversions": "51",
            "Conversion Value": "$12,773",
            "Cost per Result": "$78.24"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490944",
            "Clicks": "4040",
            "Conversions": "53",
            "Conversion Value": "$13,674",
            "Cost per Result": "$84.27"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "409729",
            "Clicks": "3991",
            "Conversions": "50",
            "Conversion Value": "$5,314",
            "Cost per Result": "$30.48"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "399053",
            "Clicks": "3321",
            "Conversions": "58",
            "Conversion Value": "$9,608",
            "Cost per Result": "$53.45"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "148000",
            "Clicks": "3966",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$39.45"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "116623",
            "Clicks": "4377",
            "Conversions": "59",
            "Conversion Value": "$9,692",
            "Cost per Result": "$65.29"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "301271",
            "Clicks": "2328",
            "Conversions": "58",
            "Conversion Value": "$14,634",
            "Cost per Result": "$23.20"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "169921",
            "Clicks": "2277",
            "Conversions": "88",
            "Conversion Value": "$12,055",
            "Cost per Result": "$40.72"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "205455",
            "Clicks": "4590",
            "Conversions": "49",
            "Conversion Value": "$13,491",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "205595",
            "Clicks": "5334",
            "Conversions": "82",
            "Conversion Value": "$10,788",
            "Cost per Result": "$83.92"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "228198",
            "Clicks": "6548",
            "Conversions": "89",
            "Conversion Value": "$12,078",
            "Cost per Result": "$78.83"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "264256",
            "Clicks": "4536",
            "Conversions": "88",
            "Conversion Value": "$4,424",
            "Cost per Result": "$46.23"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "279468",
            "Clicks": "6741",
            "Conversions": "61",
            "Conversion Value": "$5,120",
            "Cost per Result": "$27.91"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "167406",
            "Clicks": "5555",
            "Conversions": "56",
            "Conversion Value": "$9,358",
            "Cost per Result": "$80.82"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "406312",
            "Clicks": "4696",
            "Conversions": "72",
            "Conversion Value": "$13,458",
            "Cost per Result": "$58.44"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "286974",
            "Clicks": "6572",
            "Conversions": "58",
            "Conversion Value": "$11,326",
            "Cost per Result": "$30.28"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "217467",
            "Clicks": "3618",
            "Conversions": "76",
            "Conversion Value": "$9,331",
            "Cost per Result": "$70.19"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "358922",
            "Clicks": "4596",
            "Conversions": "85",
            "Conversion Value": "$4,858",
            "Cost per Result": "$65.06"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "148593",
            "Clicks": "5337",
            "Conversions": "87",
            "Conversion Value": "$10,179",
            "Cost per Result": "$45.79"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "249426",
            "Clicks": "2861",
            "Conversions": "71",
            "Conversion Value": "$15,493",
            "Cost per Result": "$47.91"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "111491",
            "Clicks": "5023",
            "Conversions": "59",
            "Conversion Value": "$13,085",
            "Cost per Result": "$33.33"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "329661",
            "Clicks": "4762",
            "Conversions": "87",
            "Conversion Value": "$15,765",
            "Cost per Result": "$83.24"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "269594",
            "Clicks": "4501",
            "Conversions": "69",
            "Conversion Value": "$15,344",
            "Cost per Result": "$25.65"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "240234",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$14,913",
            "Cost per Result": "$79.93"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "427326",
            "Clicks": "4495",
            "Conversions": "79",
            "Conversion Value": "$13,638",
            "Cost per Result": "$62.71"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "137002",
            "Clicks": "4298",
            "Conversions": "87",
            "Conversion Value": "$13,903",
            "Cost per Result": "$49.68"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "274045",
            "Clicks": "4617",
            "Conversions": "65",
            "Conversion Value": "$6,479",
            "Cost per Result": "$40.20"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "445786",
            "Clicks": "2914",
            "Conversions": "47",
            "Conversion Value": "$14,055",
            "Cost per Result": "$57.87"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "426620",
            "Clicks": "2195",
            "Conversions": "81",
            "Conversion Value": "$6,478",
            "Cost per Result": "$27.88"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "485116",
            "Clicks": "2347",
            "Conversions": "66",
            "Conversion Value": "$10,151",
            "Cost per Result": "$24.88"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "392252",
            "Clicks": "4743",
            "Conversions": "74",
            "Conversion Value": "$5,870",
            "Cost per Result": "$66.30"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "245584",
            "Clicks": "4729",
            "Conversions": "42",
            "Conversion Value": "$10,568",
            "Cost per Result": "$83.98"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "229926",
            "Clicks": "6069",
            "Conversions": "40",
            "Conversion Value": "$15,136",
            "Cost per Result": "$25.84"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "274703",
            "Clicks": "3259",
            "Conversions": "65",
            "Conversion Value": "$15,505",
            "Cost per Result": "$39.09"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "211153",
            "Clicks": "6159",
            "Conversions": "73",
            "Conversion Value": "$13,467",
            "Cost per Result": "$63.80"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "372039",
            "Clicks": "2409",
            "Conversions": "47",
            "Conversion Value": "$5,733",
            "Cost per Result": "$60.75"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "436122",
            "Clicks": "3316",
            "Conversions": "61",
            "Conversion Value": "$7,836",
            "Cost per Result": "$66.18"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "346319",
            "Clicks": "4624",
            "Conversions": "85",
            "Conversion Value": "$7,275",
            "Cost per Result": "$51.01"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "398780",
            "Clicks": "2918",
            "Conversions": "73",
            "Conversion Value": "$15,296",
            "Cost per Result": "$42.72"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "151266",
            "Clicks": "4634",
            "Conversions": "45",
            "Conversion Value": "$14,669",
            "Cost per Result": "$44.26"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "243794",
            "Clicks": "2041",
            "Conversions": "81",
            "Conversion Value": "$5,299",
            "Cost per Result": "$77.02"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "158250",
            "Clicks": "4646",
            "Conversions": "71",
            "Conversion Value": "$14,888",
            "Cost per Result": "$38.85"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "231132",
            "Clicks": "3276",
            "Conversions": "78",
            "Conversion Value": "$8,033",
            "Cost per Result": "$54.67"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "230934",
            "Clicks": "3020",
            "Conversions": "52",
            "Conversion Value": "$4,924",
            "Cost per Result": "$81.07"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "112748",
            "Clicks": "3977",
            "Conversions": "46",
            "Conversion Value": "$11,202",
            "Cost per Result": "$59.98"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "260642",
            "Clicks": "5299",
            "Conversions": "60",
            "Conversion Value": "$15,289",
            "Cost per Result": "$77.08"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "104385",
            "Clicks": "4997",
            "Conversions": "40",
            "Conversion Value": "$12,068",
            "Cost per Result": "$81.97"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "353096",
            "Clicks": "5637",
            "Conversions": "49",
            "Conversion Value": "$9,590",
            "Cost per Result": "$51.54"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "158840",
            "Clicks": "3596",
            "Conversions": "54",
            "Conversion Value": "$15,903",
            "Cost per Result": "$60.18"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "110742",
            "Clicks": "3015",
            "Conversions": "71",
            "Conversion Value": "$6,429",
            "Cost per Result": "$37.20"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "151585",
            "Clicks": "5416",
            "Conversions": "75",
            "Conversion Value": "$6,910",
            "Cost per Result": "$70.58"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "444463",
            "Clicks": "3273",
            "Conversions": "81",
            "Conversion Value": "$11,423",
            "Cost per Result": "$75.86"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "292951",
            "Clicks": "6505",
            "Conversions": "51",
            "Conversion Value": "$15,015",
            "Cost per Result": "$81.65"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "419873",
            "Clicks": "3577",
            "Conversions": "65",
            "Conversion Value": "$8,581",
            "Cost per Result": "$84.85"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "281894",
            "Clicks": "5810",
            "Conversions": "63",
            "Conversion Value": "$10,222",
            "Cost per Result": "$51.63"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "373471",
            "Clicks": "5776",
            "Conversions": "77",
            "Conversion Value": "$13,733",
            "Cost per Result": "$84.16"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "194989",
            "Clicks": "5997",
            "Conversions": "72",
            "Conversion Value": "$7,229",
            "Cost per Result": "$66.82"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "230901",
            "Clicks": "2206",
            "Conversions": "90",
            "Conversion Value": "$11,433",
            "Cost per Result": "$58.13"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "221728",
            "Clicks": "4038",
            "Conversions": "55",
            "Conversion Value": "$6,968",
            "Cost per Result": "$69.08"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "363000",
            "Clicks": "4352",
            "Conversions": "41",
            "Conversion Value": "$13,890",
            "Cost per Result": "$34.35"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "156364",
            "Clicks": "5798",
            "Conversions": "52",
            "Conversion Value": "$14,518",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "495714",
            "Clicks": "3866",
            "Conversions": "41",
            "Conversion Value": "$7,966",
            "Cost per Result": "$64.22"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "460986",
            "Clicks": "4667",
            "Conversions": "73",
            "Conversion Value": "$14,410",
            "Cost per Result": "$65.03"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "249487",
            "Clicks": "6258",
            "Conversions": "42",
            "Conversion Value": "$4,325",
            "Cost per Result": "$33.65"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "429485",
            "Clicks": "4550",
            "Conversions": "68",
            "Conversion Value": "$14,437",
            "Cost per Result": "$65.68"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "455394",
            "Clicks": "2158",
            "Conversions": "72",
            "Conversion Value": "$6,059",
            "Cost per Result": "$36.37"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "393859",
            "Clicks": "6760",
            "Conversions": "61",
            "Conversion Value": "$9,641",
            "Cost per Result": "$57.54"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "343723",
            "Clicks": "5944",
            "Conversions": "74",
            "Conversion Value": "$11,758",
            "Cost per Result": "$84.74"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "190384",
            "Clicks": "3335",
            "Conversions": "70",
            "Conversion Value": "$8,698",
            "Cost per Result": "$56.24"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "221492",
            "Clicks": "3283",
            "Conversions": "84",
            "Conversion Value": "$5,558",
            "Cost per Result": "$38.56"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "392605",
            "Clicks": "6326",
            "Conversions": "46",
            "Conversion Value": "$5,275",
            "Cost per Result": "$34.67"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "375472",
            "Clicks": "6323",
            "Conversions": "52",
            "Conversion Value": "$4,105",
            "Cost per Result": "$58.07"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "350680",
            "Clicks": "6596",
            "Conversions": "49",
            "Conversion Value": "$14,558",
            "Cost per Result": "$60.30"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "215013",
            "Clicks": "3969",
            "Conversions": "62",
            "Conversion Value": "$12,246",
            "Cost per Result": "$57.96"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "463903",
            "Clicks": "2972",
            "Conversions": "54",
            "Conversion Value": "$15,702",
            "Cost per Result": "$37.53"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "293595",
            "Clicks": "5507",
            "Conversions": "65",
            "Conversion Value": "$14,215",
            "Cost per Result": "$34.41"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "447628",
            "Clicks": "4128",
            "Conversions": "76",
            "Conversion Value": "$7,719",
            "Cost per Result": "$32.17"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "340403",
            "Clicks": "4406",
            "Conversions": "48",
            "Conversion Value": "$14,706",
            "Cost per Result": "$43.01"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "311341",
            "Clicks": "3638",
            "Conversions": "83",
            "Conversion Value": "$14,928",
            "Cost per Result": "$78.98"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "345252",
            "Clicks": "3067",
            "Conversions": "68",
            "Conversion Value": "$9,980",
            "Cost per Result": "$79.91"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "296129",
            "Clicks": "2442",
            "Conversions": "77",
            "Conversion Value": "$11,616",
            "Cost per Result": "$64.69"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "155060",
            "Clicks": "3754",
            "Conversions": "44",
            "Conversion Value": "$15,491",
            "Cost per Result": "$31.30"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "463829",
            "Clicks": "5433",
            "Conversions": "86",
            "Conversion Value": "$11,612",
            "Cost per Result": "$29.16"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "272238",
            "Clicks": "5578",
            "Conversions": "77",
            "Conversion Value": "$13,830",
            "Cost per Result": "$61.58"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "404113",
            "Clicks": "2673",
            "Conversions": "82",
            "Conversion Value": "$5,839",
            "Cost per Result": "$22.44"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "240589",
            "Clicks": "6948",
            "Conversions": "86",
            "Conversion Value": "$12,339",
            "Cost per Result": "$71.05"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "428596",
            "Clicks": "4499",
            "Conversions": "77",
            "Conversion Value": "$9,239",
            "Cost per Result": "$28.40"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "239459",
            "Clicks": "3968",
            "Conversions": "46",
            "Conversion Value": "$8,794",
            "Cost per Result": "$57.23"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "132800",
            "Clicks": "4396",
            "Conversions": "54",
            "Conversion Value": "$12,347",
            "Cost per Result": "$48.66"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "490202",
            "Clicks": "2133",
            "Conversions": "70",
            "Conversion Value": "$12,351",
            "Cost per Result": "$79.78"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "388772",
            "Clicks": "3514",
            "Conversions": "77",
            "Conversion Value": "$7,703",
            "Cost per Result": "$22.24"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "450004",
            "Clicks": "5019",
            "Conversions": "42",
            "Conversion Value": "$7,481",
            "Cost per Result": "$66.94"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "425949",
            "Clicks": "5164",
            "Conversions": "83",
            "Conversion Value": "$7,808",
            "Cost per Result": "$37.25"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "297381",
            "Clicks": "3312",
            "Conversions": "74",
            "Conversion Value": "$12,090",
            "Cost per Result": "$21.16"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "189343",
            "Clicks": "6907",
            "Conversions": "74",
            "Conversion Value": "$4,704",
            "Cost per Result": "$63.75"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "303202",
            "Clicks": "6122",
            "Conversions": "62",
            "Conversion Value": "$4,303",
            "Cost per Result": "$72.30"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "237208",
            "Clicks": "4302",
            "Conversions": "78",
            "Conversion Value": "$12,006",
            "Cost per Result": "$20.74"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "264158",
            "Clicks": "2399",
            "Conversions": "85",
            "Conversion Value": "$13,527",
            "Cost per Result": "$38.21"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "441641",
            "Clicks": "5119",
            "Conversions": "69",
            "Conversion Value": "$11,394",
            "Cost per Result": "$42.51"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "216566",
            "Clicks": "6313",
            "Conversions": "50",
            "Conversion Value": "$8,151",
            "Cost per Result": "$20.69"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "152369",
            "Clicks": "6528",
            "Conversions": "42",
            "Conversion Value": "$7,708",
            "Cost per Result": "$46.42"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "242990",
            "Clicks": "4218",
            "Conversions": "85",
            "Conversion Value": "$5,543",
            "Cost per Result": "$81.63"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "325308",
            "Clicks": "2279",
            "Conversions": "64",
            "Conversion Value": "$10,593",
            "Cost per Result": "$50.14"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "259394",
            "Clicks": "2445",
            "Conversions": "90",
            "Conversion Value": "$6,457",
            "Cost per Result": "$78.56"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "333287",
            "Clicks": "6258",
            "Conversions": "60",
            "Conversion Value": "$4,745",
            "Cost per Result": "$42.76"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "284502",
            "Clicks": "6695",
            "Conversions": "64",
            "Conversion Value": "$11,375",
            "Cost per Result": "$26.94"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "278064",
            "Clicks": "5467",
            "Conversions": "90",
            "Conversion Value": "$13,712",
            "Cost per Result": "$82.23"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "335329",
            "Clicks": "2295",
            "Conversions": "48",
            "Conversion Value": "$6,229",
            "Cost per Result": "$81.39"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "402901",
            "Clicks": "2039",
            "Conversions": "41",
            "Conversion Value": "$14,244",
            "Cost per Result": "$70.40"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "183996",
            "Clicks": "6814",
            "Conversions": "80",
            "Conversion Value": "$15,983",
            "Cost per Result": "$57.54"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "437367",
            "Clicks": "3331",
            "Conversions": "57",
            "Conversion Value": "$14,128",
            "Cost per Result": "$21.87"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "403077",
            "Clicks": "6347",
            "Conversions": "70",
            "Conversion Value": "$9,113",
            "Cost per Result": "$73.18"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "245817",
            "Clicks": "2404",
            "Conversions": "62",
            "Conversion Value": "$12,061",
            "Cost per Result": "$51.25"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "202892",
            "Clicks": "5759",
            "Conversions": "40",
            "Conversion Value": "$7,717",
            "Cost per Result": "$63.27"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "234448",
            "Clicks": "2222",
            "Conversions": "45",
            "Conversion Value": "$10,319",
            "Cost per Result": "$50.83"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "138975",
            "Clicks": "6952",
            "Conversions": "55",
            "Conversion Value": "$9,917",
            "Cost per Result": "$59.08"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "116499",
            "Clicks": "4583",
            "Conversions": "85",
            "Conversion Value": "$15,447",
            "Cost per Result": "$62.65"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "186911",
            "Clicks": "4008",
            "Conversions": "89",
            "Conversion Value": "$7,988",
            "Cost per Result": "$60.70"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "139979",
            "Clicks": "4134",
            "Conversions": "87",
            "Conversion Value": "$13,437",
            "Cost per Result": "$64.39"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "322026",
            "Clicks": "2617",
            "Conversions": "89",
            "Conversion Value": "$9,287",
            "Cost per Result": "$48.18"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "327218",
            "Clicks": "5957",
            "Conversions": "51",
            "Conversion Value": "$5,756",
            "Cost per Result": "$27.55"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "311060",
            "Clicks": "4063",
            "Conversions": "54",
            "Conversion Value": "$8,818",
            "Cost per Result": "$66.41"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "421991",
            "Clicks": "2699",
            "Conversions": "87",
            "Conversion Value": "$9,310",
            "Cost per Result": "$47.56"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "261472",
            "Clicks": "6155",
            "Conversions": "42",
            "Conversion Value": "$9,340",
            "Cost per Result": "$21.95"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "330447",
            "Clicks": "4804",
            "Conversions": "49",
            "Conversion Value": "$6,796",
            "Cost per Result": "$35.55"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "452157",
            "Clicks": "4927",
            "Conversions": "60",
            "Conversion Value": "$11,236",
            "Cost per Result": "$58.98"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "300476",
            "Clicks": "6206",
            "Conversions": "44",
            "Conversion Value": "$9,990",
            "Cost per Result": "$65.74"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "388970",
            "Clicks": "3549",
            "Conversions": "89",
            "Conversion Value": "$4,039",
            "Cost per Result": "$65.03"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "215511",
            "Clicks": "6890",
            "Conversions": "65",
            "Conversion Value": "$12,956",
            "Cost per Result": "$34.15"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "322846",
            "Clicks": "3394",
            "Conversions": "90",
            "Conversion Value": "$5,361",
            "Cost per Result": "$68.32"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "469442",
            "Clicks": "5099",
            "Conversions": "62",
            "Conversion Value": "$4,988",
            "Cost per Result": "$39.09"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "301822",
            "Clicks": "5477",
            "Conversions": "51",
            "Conversion Value": "$11,999",
            "Cost per Result": "$26.44"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "106840",
            "Clicks": "2736",
            "Conversions": "83",
            "Conversion Value": "$5,728",
            "Cost per Result": "$53.28"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "196750",
            "Clicks": "2712",
            "Conversions": "41",
            "Conversion Value": "$15,885",
            "Cost per Result": "$23.26"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "206002",
            "Clicks": "5711",
            "Conversions": "86",
            "Conversion Value": "$7,278",
            "Cost per Result": "$21.58"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "470725",
            "Clicks": "5537",
            "Conversions": "80",
            "Conversion Value": "$12,126",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "281280",
            "Clicks": "6926",
            "Conversions": "66",
            "Conversion Value": "$9,611",
            "Cost per Result": "$41.57"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "312620",
            "Clicks": "4218",
            "Conversions": "52",
            "Conversion Value": "$6,311",
            "Cost per Result": "$69.02"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "397433",
            "Clicks": "2025",
            "Conversions": "51",
            "Conversion Value": "$7,868",
            "Cost per Result": "$56.70"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "114913",
            "Clicks": "3453",
            "Conversions": "73",
            "Conversion Value": "$12,141",
            "Cost per Result": "$22.27"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "214473",
            "Clicks": "5642",
            "Conversions": "75",
            "Conversion Value": "$14,519",
            "Cost per Result": "$56.44"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "266027",
            "Clicks": "2078",
            "Conversions": "60",
            "Conversion Value": "$15,874",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "190937",
            "Clicks": "3143",
            "Conversions": "70",
            "Conversion Value": "$5,454",
            "Cost per Result": "$33.46"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "399564",
            "Clicks": "2655",
            "Conversions": "59",
            "Conversion Value": "$14,506",
            "Cost per Result": "$24.69"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "221426",
            "Clicks": "6753",
            "Conversions": "88",
            "Conversion Value": "$4,010",
            "Cost per Result": "$70.98"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "280377",
            "Clicks": "5370",
            "Conversions": "81",
            "Conversion Value": "$6,507",
            "Cost per Result": "$31.24"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "407192",
            "Clicks": "5722",
            "Conversions": "47",
            "Conversion Value": "$8,143",
            "Cost per Result": "$28.57"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "324735",
            "Clicks": "4921",
            "Conversions": "89",
            "Conversion Value": "$11,677",
            "Cost per Result": "$25.24"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "473920",
            "Clicks": "3136",
            "Conversions": "83",
            "Conversion Value": "$4,533",
            "Cost per Result": "$84.39"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "194693",
            "Clicks": "5348",
            "Conversions": "89",
            "Conversion Value": "$15,521",
            "Cost per Result": "$77.75"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "157598",
            "Clicks": "3069",
            "Conversions": "52",
            "Conversion Value": "$8,676",
            "Cost per Result": "$66.76"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "452124",
            "Clicks": "4983",
            "Conversions": "48",
            "Conversion Value": "$10,818",
            "Cost per Result": "$23.74"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "157539",
            "Clicks": "4619",
            "Conversions": "89",
            "Conversion Value": "$6,762",
            "Cost per Result": "$24.19"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "113835",
            "Clicks": "3902",
            "Conversions": "54",
            "Conversion Value": "$10,849",
            "Cost per Result": "$70.62"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "266441",
            "Clicks": "5784",
            "Conversions": "62",
            "Conversion Value": "$4,835",
            "Cost per Result": "$69.11"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "155309",
            "Clicks": "5321",
            "Conversions": "77",
            "Conversion Value": "$9,257",
            "Cost per Result": "$81.46"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "373645",
            "Clicks": "6857",
            "Conversions": "55",
            "Conversion Value": "$9,819",
            "Cost per Result": "$47.32"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "137541",
            "Clicks": "6879",
            "Conversions": "46",
            "Conversion Value": "$9,766",
            "Cost per Result": "$30.86"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "123045",
            "Clicks": "2781",
            "Conversions": "80",
            "Conversion Value": "$4,882",
            "Cost per Result": "$70.91"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "181179",
            "Clicks": "5664",
            "Conversions": "54",
            "Conversion Value": "$7,951",
            "Cost per Result": "$39.43"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "496701",
            "Clicks": "6715",
            "Conversions": "85",
            "Conversion Value": "$6,738",
            "Cost per Result": "$83.88"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "217839",
            "Clicks": "3152",
            "Conversions": "83",
            "Conversion Value": "$12,936",
            "Cost per Result": "$68.58"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "275305",
            "Clicks": "6292",
            "Conversions": "71",
            "Conversion Value": "$7,886",
            "Cost per Result": "$48.35"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "374624",
            "Clicks": "3084",
            "Conversions": "51",
            "Conversion Value": "$9,099",
            "Cost per Result": "$83.34"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "264952",
            "Clicks": "3227",
            "Conversions": "66",
            "Conversion Value": "$13,026",
            "Cost per Result": "$36.87"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "190127",
            "Clicks": "2096",
            "Conversions": "42",
            "Conversion Value": "$11,422",
            "Cost per Result": "$77.66"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "224797",
            "Clicks": "4979",
            "Conversions": "53",
            "Conversion Value": "$12,743",
            "Cost per Result": "$40.48"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "445574",
            "Clicks": "6963",
            "Conversions": "43",
            "Conversion Value": "$11,791",
            "Cost per Result": "$33.97"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "112520",
            "Clicks": "6014",
            "Conversions": "55",
            "Conversion Value": "$15,474",
            "Cost per Result": "$48.02"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "381388",
            "Clicks": "3344",
            "Conversions": "69",
            "Conversion Value": "$9,019",
            "Cost per Result": "$31.78"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "295042",
            "Clicks": "5607",
            "Conversions": "49",
            "Conversion Value": "$7,397",
            "Cost per Result": "$76.06"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "446900",
            "Clicks": "3485",
            "Conversions": "59",
            "Conversion Value": "$8,192",
            "Cost per Result": "$33.85"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "143563",
            "Clicks": "4099",
            "Conversions": "61",
            "Conversion Value": "$5,644",
            "Cost per Result": "$34.56"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "119724",
            "Clicks": "3561",
            "Conversions": "71",
            "Conversion Value": "$9,788",
            "Cost per Result": "$62.87"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "118266",
            "Clicks": "5796",
            "Conversions": "68",
            "Conversion Value": "$5,510",
            "Cost per Result": "$67.30"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "383640",
            "Clicks": "6827",
            "Conversions": "47",
            "Conversion Value": "$12,279",
            "Cost per Result": "$42.42"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "104223",
            "Clicks": "2469",
            "Conversions": "73",
            "Conversion Value": "$5,487",
            "Cost per Result": "$74.03"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "152599",
            "Clicks": "5290",
            "Conversions": "87",
            "Conversion Value": "$11,125",
            "Cost per Result": "$67.82"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "222389",
            "Clicks": "3289",
            "Conversions": "88",
            "Conversion Value": "$15,273",
            "Cost per Result": "$40.90"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "288489",
            "Clicks": "3595",
            "Conversions": "43",
            "Conversion Value": "$7,482",
            "Cost per Result": "$71.51"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "325669",
            "Clicks": "6297",
            "Conversions": "82",
            "Conversion Value": "$7,543",
            "Cost per Result": "$39.38"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "226873",
            "Clicks": "2761",
            "Conversions": "69",
            "Conversion Value": "$12,002",
            "Cost per Result": "$58.61"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "198736",
            "Clicks": "5790",
            "Conversions": "48",
            "Conversion Value": "$4,510",
            "Cost per Result": "$59.74"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "375412",
            "Clicks": "3692",
            "Conversions": "60",
            "Conversion Value": "$14,877",
            "Cost per Result": "$32.69"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "491610",
            "Clicks": "3736",
            "Conversions": "58",
            "Conversion Value": "$15,027",
            "Cost per Result": "$58.98"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "491919",
            "Clicks": "4018",
            "Conversions": "43",
            "Conversion Value": "$14,208",
            "Cost per Result": "$33.33"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "192367",
            "Clicks": "4875",
            "Conversions": "88",
            "Conversion Value": "$11,493",
            "Cost per Result": "$66.81"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "322729",
            "Clicks": "4199",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$40.13"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "426244",
            "Clicks": "2361",
            "Conversions": "74",
            "Conversion Value": "$13,783",
            "Cost per Result": "$26.52"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "157827",
            "Clicks": "2580",
            "Conversions": "47",
            "Conversion Value": "$9,459",
            "Cost per Result": "$57.67"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "380683",
            "Clicks": "2740",
            "Conversions": "68",
            "Conversion Value": "$14,002",
            "Cost per Result": "$56.89"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "138943",
            "Clicks": "3555",
            "Conversions": "84",
            "Conversion Value": "$6,547",
            "Cost per Result": "$45.15"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "103680",
            "Clicks": "6819",
            "Conversions": "57",
            "Conversion Value": "$10,707",
            "Cost per Result": "$33.96"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "173883",
            "Clicks": "5376",
            "Conversions": "66",
            "Conversion Value": "$7,198",
            "Cost per Result": "$31.92"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "492331",
            "Clicks": "6467",
            "Conversions": "60",
            "Conversion Value": "$14,251",
            "Cost per Result": "$59.52"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "429054",
            "Clicks": "5827",
            "Conversions": "81",
            "Conversion Value": "$9,046",
            "Cost per Result": "$28.63"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "347444",
            "Clicks": "6028",
            "Conversions": "62",
            "Conversion Value": "$7,701",
            "Cost per Result": "$75.77"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188223",
            "Clicks": "6629",
            "Conversions": "90",
            "Conversion Value": "$14,699",
            "Cost per Result": "$63.14"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "267261",
            "Clicks": "6352",
            "Conversions": "47",
            "Conversion Value": "$12,150",
            "Cost per Result": "$66.13"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "406044",
            "Clicks": "4550",
            "Conversions": "57",
            "Conversion Value": "$11,368",
            "Cost per Result": "$62.86"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "418762",
            "Clicks": "2317",
            "Conversions": "60",
            "Conversion Value": "$14,537",
            "Cost per Result": "$66.50"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "107772",
            "Clicks": "4944",
            "Conversions": "81",
            "Conversion Value": "$12,793",
            "Cost per Result": "$30.43"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "470088",
            "Clicks": "2373",
            "Conversions": "47",
            "Conversion Value": "$4,187",
            "Cost per Result": "$42.80"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "481683",
            "Clicks": "2260",
            "Conversions": "53",
            "Conversion Value": "$10,546",
            "Cost per Result": "$72.07"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "284903",
            "Clicks": "2210",
            "Conversions": "64",
            "Conversion Value": "$14,258",
            "Cost per Result": "$49.26"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "398274",
            "Clicks": "2869",
            "Conversions": "63",
            "Conversion Value": "$5,070",
            "Cost per Result": "$53.85"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "242762",
            "Clicks": "6197",
            "Conversions": "43",
            "Conversion Value": "$11,839",
            "Cost per Result": "$59.36"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "322748",
            "Clicks": "5544",
            "Conversions": "87",
            "Conversion Value": "$9,883",
            "Cost per Result": "$54.19"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "213924",
            "Clicks": "4173",
            "Conversions": "75",
            "Conversion Value": "$8,814",
            "Cost per Result": "$66.37"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "447422",
            "Clicks": "4805",
            "Conversions": "52",
            "Conversion Value": "$11,861",
            "Cost per Result": "$63.53"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "218895",
            "Clicks": "5279",
            "Conversions": "68",
            "Conversion Value": "$11,967",
            "Cost per Result": "$74.92"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "473201",
            "Clicks": "6153",
            "Conversions": "70",
            "Conversion Value": "$12,675",
            "Cost per Result": "$81.81"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "459737",
            "Clicks": "2550",
            "Conversions": "45",
            "Conversion Value": "$14,597",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "459800",
            "Clicks": "5248",
            "Conversions": "89",
            "Conversion Value": "$8,590",
            "Cost per Result": "$35.85"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "320856",
            "Clicks": "5523",
            "Conversions": "58",
            "Conversion Value": "$4,056",
            "Cost per Result": "$63.98"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "419833",
            "Clicks": "6502",
            "Conversions": "48",
            "Conversion Value": "$15,300",
            "Cost per Result": "$61.45"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "381522",
            "Clicks": "4075",
            "Conversions": "60",
            "Conversion Value": "$11,373",
            "Cost per Result": "$36.52"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "260556",
            "Clicks": "5014",
            "Conversions": "50",
            "Conversion Value": "$7,317",
            "Cost per Result": "$42.60"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "122159",
            "Clicks": "4630",
            "Conversions": "84",
            "Conversion Value": "$9,410",
            "Cost per Result": "$47.33"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "318331",
            "Clicks": "2637",
            "Conversions": "90",
            "Conversion Value": "$5,867",
            "Cost per Result": "$48.63"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "184061",
            "Clicks": "3787",
            "Conversions": "52",
            "Conversion Value": "$13,850",
            "Cost per Result": "$55.49"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "455424",
            "Clicks": "3255",
            "Conversions": "63",
            "Conversion Value": "$6,294",
            "Cost per Result": "$81.85"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "481435",
            "Clicks": "4743",
            "Conversions": "85",
            "Conversion Value": "$7,593",
            "Cost per Result": "$41.24"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "298677",
            "Clicks": "4618",
            "Conversions": "55",
            "Conversion Value": "$14,644",
            "Cost per Result": "$29.83"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "370652",
            "Clicks": "5943",
            "Conversions": "53",
            "Conversion Value": "$8,671",
            "Cost per Result": "$26.78"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "427915",
            "Clicks": "2802",
            "Conversions": "53",
            "Conversion Value": "$5,319",
            "Cost per Result": "$21.41"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "240623",
            "Clicks": "5264",
            "Conversions": "56",
            "Conversion Value": "$15,436",
            "Cost per Result": "$73.63"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "333227",
            "Clicks": "3055",
            "Conversions": "46",
            "Conversion Value": "$7,324",
            "Cost per Result": "$59.00"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "367852",
            "Clicks": "2508",
            "Conversions": "42",
            "Conversion Value": "$5,128",
            "Cost per Result": "$38.29"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "450217",
            "Clicks": "5614",
            "Conversions": "43",
            "Conversion Value": "$12,497",
            "Cost per Result": "$81.46"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "328867",
            "Clicks": "4728",
            "Conversions": "67",
            "Conversion Value": "$7,787",
            "Cost per Result": "$40.74"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "277809",
            "Clicks": "6491",
            "Conversions": "81",
            "Conversion Value": "$13,979",
            "Cost per Result": "$62.53"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "211361",
            "Clicks": "2132",
            "Conversions": "71",
            "Conversion Value": "$13,195",
            "Cost per Result": "$39.55"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "142508",
            "Clicks": "3862",
            "Conversions": "52",
            "Conversion Value": "$6,656",
            "Cost per Result": "$32.15"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "424888",
            "Clicks": "4450",
            "Conversions": "78",
            "Conversion Value": "$15,042",
            "Cost per Result": "$48.26"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "285587",
            "Clicks": "2182",
            "Conversions": "61",
            "Conversion Value": "$5,117",
            "Cost per Result": "$70.87"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "474948",
            "Clicks": "6056",
            "Conversions": "59",
            "Conversion Value": "$7,716",
            "Cost per Result": "$64.40"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "236141",
            "Clicks": "5541",
            "Conversions": "61",
            "Conversion Value": "$13,278",
            "Cost per Result": "$46.71"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "404478",
            "Clicks": "6238",
            "Conversions": "50",
            "Conversion Value": "$14,042",
            "Cost per Result": "$30.96"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "345422",
            "Clicks": "2804",
            "Conversions": "59",
            "Conversion Value": "$9,804",
            "Cost per Result": "$49.21"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "179394",
            "Clicks": "4301",
            "Conversions": "45",
            "Conversion Value": "$10,676",
            "Cost per Result": "$22.71"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "279309",
            "Clicks": "6253",
            "Conversions": "41",
            "Conversion Value": "$8,182",
            "Cost per Result": "$51.60"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "136053",
            "Clicks": "6858",
            "Conversions": "54",
            "Conversion Value": "$12,552",
            "Cost per Result": "$25.58"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "210906",
            "Clicks": "3579",
            "Conversions": "51",
            "Conversion Value": "$15,154",
            "Cost per Result": "$61.75"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "236006",
            "Clicks": "5617",
            "Conversions": "63",
            "Conversion Value": "$12,426",
            "Cost per Result": "$44.14"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "369562",
            "Clicks": "6265",
            "Conversions": "57",
            "Conversion Value": "$10,571",
            "Cost per Result": "$78.04"
          }
        ]
      },
      "NFTWhalesData": {
        "name": "NFT Whales",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "395452",
            "Clicks": "2253",
            "Conversions": "70",
            "Volume": "$4,572",
            "Cost per Result": "$57.33"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "357851",
            "Clicks": "5514",
            "Conversions": "44",
            "Volume": "$9,585",
            "Cost per Result": "$35.93"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "286451",
            "Clicks": "4888",
            "Conversions": "67",
            "Volume": "$13,894",
            "Cost per Result": "$36.45"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "138563",
            "Clicks": "2149",
            "Conversions": "62",
            "Volume": "$13,357",
            "Cost per Result": "$23.66"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "479103",
            "Clicks": "2451",
            "Conversions": "64",
            "Volume": "$8,350",
            "Cost per Result": "$31.15"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "353401",
            "Clicks": "5546",
            "Conversions": "72",
            "Volume": "$15,724",
            "Cost per Result": "$73.00"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "170738",
            "Clicks": "5081",
            "Conversions": "75",
            "Volume": "$7,247",
            "Cost per Result": "$44.67"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "440785",
            "Clicks": "5624",
            "Conversions": "70",
            "Volume": "$10,038",
            "Cost per Result": "$22.64"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "104881",
            "Clicks": "3718",
            "Conversions": "76",
            "Volume": "$14,551",
            "Cost per Result": "$30.04"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "119330",
            "Clicks": "2668",
            "Conversions": "80",
            "Volume": "$14,879",
            "Cost per Result": "$56.23"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "381894",
            "Clicks": "3548",
            "Conversions": "74",
            "Volume": "$15,868",
            "Cost per Result": "$46.70"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "378317",
            "Clicks": "3502",
            "Conversions": "75",
            "Volume": "$11,386",
            "Cost per Result": "$75.67"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "349528",
            "Clicks": "6477",
            "Conversions": "73",
            "Volume": "$12,757",
            "Cost per Result": "$78.47"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "313198",
            "Clicks": "4676",
            "Conversions": "48",
            "Volume": "$11,487",
            "Cost per Result": "$80.36"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "312758",
            "Clicks": "3610",
            "Conversions": "79",
            "Volume": "$11,127",
            "Cost per Result": "$50.06"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "212110",
            "Clicks": "3812",
            "Conversions": "80",
            "Volume": "$4,943",
            "Cost per Result": "$43.26"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "347775",
            "Clicks": "4952",
            "Conversions": "56",
            "Volume": "$10,661",
            "Cost per Result": "$20.76"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "130535",
            "Clicks": "4915",
            "Conversions": "47",
            "Volume": "$5,328",
            "Cost per Result": "$44.10"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "211426",
            "Clicks": "3151",
            "Conversions": "82",
            "Volume": "$9,529",
            "Cost per Result": "$49.39"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "125674",
            "Clicks": "3355",
            "Conversions": "56",
            "Volume": "$13,972",
            "Cost per Result": "$41.81"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "197229",
            "Clicks": "3391",
            "Conversions": "58",
            "Volume": "$13,361",
            "Cost per Result": "$75.42"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "380509",
            "Clicks": "3370",
            "Conversions": "73",
            "Volume": "$5,196",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "376175",
            "Clicks": "5807",
            "Conversions": "64",
            "Volume": "$6,191",
            "Cost per Result": "$28.46"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "281779",
            "Clicks": "4122",
            "Conversions": "62",
            "Volume": "$6,302",
            "Cost per Result": "$81.44"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "229987",
            "Clicks": "5682",
            "Conversions": "75",
            "Volume": "$5,970",
            "Cost per Result": "$77.87"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "309528",
            "Clicks": "6056",
            "Conversions": "50",
            "Volume": "$11,112",
            "Cost per Result": "$75.91"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "272674",
            "Clicks": "4357",
            "Conversions": "84",
            "Volume": "$4,732",
            "Cost per Result": "$75.79"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "162783",
            "Clicks": "3314",
            "Conversions": "58",
            "Volume": "$15,957",
            "Cost per Result": "$23.87"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "380528",
            "Clicks": "4015",
            "Conversions": "87",
            "Volume": "$13,656",
            "Cost per Result": "$36.29"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "232177",
            "Clicks": "6443",
            "Conversions": "89",
            "Volume": "$13,923",
            "Cost per Result": "$49.09"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "110260",
            "Clicks": "2354",
            "Conversions": "88",
            "Volume": "$14,399",
            "Cost per Result": "$27.29"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "112745",
            "Clicks": "5175",
            "Conversions": "66",
            "Volume": "$13,372",
            "Cost per Result": "$50.86"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "459543",
            "Clicks": "5689",
            "Conversions": "44",
            "Volume": "$6,089",
            "Cost per Result": "$81.75"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "436968",
            "Clicks": "4579",
            "Conversions": "67",
            "Volume": "$11,301",
            "Cost per Result": "$34.60"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "475378",
            "Clicks": "6946",
            "Conversions": "81",
            "Volume": "$12,515",
            "Cost per Result": "$35.46"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "438861",
            "Clicks": "3860",
            "Conversions": "49",
            "Volume": "$10,134",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "485877",
            "Clicks": "3830",
            "Conversions": "62",
            "Volume": "$14,002",
            "Cost per Result": "$55.97"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "205196",
            "Clicks": "5972",
            "Conversions": "80",
            "Volume": "$12,186",
            "Cost per Result": "$20.47"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "144129",
            "Clicks": "3572",
            "Conversions": "57",
            "Volume": "$11,525",
            "Cost per Result": "$33.52"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "231794",
            "Clicks": "4652",
            "Conversions": "73",
            "Volume": "$15,156",
            "Cost per Result": "$77.58"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "233897",
            "Clicks": "2810",
            "Conversions": "65",
            "Volume": "$6,115",
            "Cost per Result": "$47.60"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "314327",
            "Clicks": "3279",
            "Conversions": "67",
            "Volume": "$9,696",
            "Cost per Result": "$80.66"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "182199",
            "Clicks": "6735",
            "Conversions": "61",
            "Volume": "$8,880",
            "Cost per Result": "$84.90"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "428876",
            "Clicks": "5922",
            "Conversions": "57",
            "Volume": "$11,100",
            "Cost per Result": "$65.37"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "421142",
            "Clicks": "2389",
            "Conversions": "72",
            "Volume": "$15,200",
            "Cost per Result": "$39.73"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "493067",
            "Clicks": "4338",
            "Conversions": "51",
            "Volume": "$5,307",
            "Cost per Result": "$48.93"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "289231",
            "Clicks": "5272",
            "Conversions": "52",
            "Volume": "$6,971",
            "Cost per Result": "$67.68"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "371097",
            "Clicks": "6384",
            "Conversions": "73",
            "Volume": "$4,576",
            "Cost per Result": "$54.92"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "491189",
            "Clicks": "6190",
            "Conversions": "46",
            "Volume": "$6,336",
            "Cost per Result": "$64.49"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "353477",
            "Clicks": "5082",
            "Conversions": "64",
            "Volume": "$4,784",
            "Cost per Result": "$80.74"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "256099",
            "Clicks": "5710",
            "Conversions": "40",
            "Volume": "$5,054",
            "Cost per Result": "$80.51"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "194314",
            "Clicks": "6345",
            "Conversions": "58",
            "Volume": "$9,289",
            "Cost per Result": "$43.82"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "139281",
            "Clicks": "4888",
            "Conversions": "62",
            "Volume": "$8,691",
            "Cost per Result": "$42.83"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "346186",
            "Clicks": "5475",
            "Conversions": "51",
            "Volume": "$6,939",
            "Cost per Result": "$38.13"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "177309",
            "Clicks": "3020",
            "Conversions": "50",
            "Volume": "$13,781",
            "Cost per Result": "$21.19"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "446998",
            "Clicks": "2851",
            "Conversions": "81",
            "Volume": "$15,780",
            "Cost per Result": "$72.46"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "383295",
            "Clicks": "2686",
            "Conversions": "46",
            "Volume": "$4,179",
            "Cost per Result": "$50.69"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "267958",
            "Clicks": "5642",
            "Conversions": "76",
            "Volume": "$9,675",
            "Cost per Result": "$60.90"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "497099",
            "Clicks": "4820",
            "Conversions": "42",
            "Volume": "$5,459",
            "Cost per Result": "$71.73"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "141320",
            "Clicks": "6868",
            "Conversions": "65",
            "Volume": "$8,807",
            "Cost per Result": "$23.12"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "300233",
            "Clicks": "6946",
            "Conversions": "85",
            "Volume": "$14,379",
            "Cost per Result": "$75.67"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "396582",
            "Clicks": "3026",
            "Conversions": "58",
            "Volume": "$12,130",
            "Cost per Result": "$40.35"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "266662",
            "Clicks": "2399",
            "Conversions": "48",
            "Volume": "$9,613",
            "Cost per Result": "$63.00"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "221350",
            "Clicks": "5864",
            "Conversions": "68",
            "Volume": "$9,641",
            "Cost per Result": "$44.56"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "328503",
            "Clicks": "5599",
            "Conversions": "70",
            "Volume": "$6,886",
            "Cost per Result": "$41.54"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "436249",
            "Clicks": "4536",
            "Conversions": "79",
            "Volume": "$4,886",
            "Cost per Result": "$62.84"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "351220",
            "Clicks": "5175",
            "Conversions": "87",
            "Volume": "$9,615",
            "Cost per Result": "$35.56"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "371545",
            "Clicks": "2502",
            "Conversions": "58",
            "Volume": "$5,653",
            "Cost per Result": "$32.00"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "278814",
            "Clicks": "4878",
            "Conversions": "42",
            "Volume": "$14,026",
            "Cost per Result": "$66.27"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "127291",
            "Clicks": "5333",
            "Conversions": "88",
            "Volume": "$12,290",
            "Cost per Result": "$76.46"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "149488",
            "Clicks": "4136",
            "Conversions": "56",
            "Volume": "$4,400",
            "Cost per Result": "$52.73"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "390705",
            "Clicks": "4416",
            "Conversions": "43",
            "Volume": "$11,620",
            "Cost per Result": "$65.10"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "301601",
            "Clicks": "6124",
            "Conversions": "54",
            "Volume": "$4,400",
            "Cost per Result": "$52.50"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "207343",
            "Clicks": "3107",
            "Conversions": "67",
            "Volume": "$9,835",
            "Cost per Result": "$72.62"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "474453",
            "Clicks": "4609",
            "Conversions": "87",
            "Volume": "$13,231",
            "Cost per Result": "$60.44"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "221006",
            "Clicks": "6435",
            "Conversions": "41",
            "Volume": "$5,973",
            "Cost per Result": "$21.18"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "426467",
            "Clicks": "5003",
            "Conversions": "53",
            "Volume": "$15,254",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "206714",
            "Clicks": "3466",
            "Conversions": "74",
            "Volume": "$5,416",
            "Cost per Result": "$68.56"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "393151",
            "Clicks": "4163",
            "Conversions": "70",
            "Volume": "$9,423",
            "Cost per Result": "$53.82"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "348668",
            "Clicks": "4984",
            "Conversions": "81",
            "Volume": "$9,047",
            "Cost per Result": "$41.44"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "119218",
            "Clicks": "4196",
            "Conversions": "43",
            "Volume": "$12,953",
            "Cost per Result": "$70.87"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "318947",
            "Clicks": "3285",
            "Conversions": "74",
            "Volume": "$5,882",
            "Cost per Result": "$57.68"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "434516",
            "Clicks": "2845",
            "Conversions": "48",
            "Volume": "$14,705",
            "Cost per Result": "$58.56"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "282277",
            "Clicks": "4832",
            "Conversions": "87",
            "Volume": "$6,913",
            "Cost per Result": "$25.40"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "394638",
            "Clicks": "6753",
            "Conversions": "56",
            "Volume": "$4,089",
            "Cost per Result": "$59.90"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "308239",
            "Clicks": "4730",
            "Conversions": "48",
            "Volume": "$5,933",
            "Cost per Result": "$26.95"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "248157",
            "Clicks": "4988",
            "Conversions": "73",
            "Volume": "$5,541",
            "Cost per Result": "$26.34"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "366743",
            "Clicks": "5748",
            "Conversions": "63",
            "Volume": "$5,963",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "178211",
            "Clicks": "6635",
            "Conversions": "89",
            "Volume": "$14,831",
            "Cost per Result": "$31.54"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "298619",
            "Clicks": "2947",
            "Conversions": "90",
            "Volume": "$10,805",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "333265",
            "Clicks": "4717",
            "Conversions": "54",
            "Volume": "$12,269",
            "Cost per Result": "$51.89"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "105304",
            "Clicks": "6180",
            "Conversions": "78",
            "Volume": "$9,719",
            "Cost per Result": "$56.46"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "227625",
            "Clicks": "5632",
            "Conversions": "70",
            "Volume": "$15,070",
            "Cost per Result": "$50.60"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "335463",
            "Clicks": "5330",
            "Conversions": "44",
            "Volume": "$13,506",
            "Cost per Result": "$63.82"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "455689",
            "Clicks": "5961",
            "Conversions": "47",
            "Volume": "$11,314",
            "Cost per Result": "$45.45"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "438963",
            "Clicks": "2512",
            "Conversions": "60",
            "Volume": "$12,621",
            "Cost per Result": "$37.55"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "404910",
            "Clicks": "6742",
            "Conversions": "87",
            "Volume": "$6,807",
            "Cost per Result": "$37.83"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "218144",
            "Clicks": "4632",
            "Conversions": "56",
            "Volume": "$7,550",
            "Cost per Result": "$38.55"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "232533",
            "Clicks": "2181",
            "Conversions": "41",
            "Volume": "$4,015",
            "Cost per Result": "$78.63"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "472183",
            "Clicks": "6035",
            "Conversions": "45",
            "Volume": "$5,666",
            "Cost per Result": "$43.52"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "232686",
            "Clicks": "3317",
            "Conversions": "79",
            "Volume": "$10,874",
            "Cost per Result": "$79.24"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "273310",
            "Clicks": "2152",
            "Conversions": "85",
            "Volume": "$11,196",
            "Cost per Result": "$82.75"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "487465",
            "Clicks": "6276",
            "Conversions": "76",
            "Volume": "$6,743",
            "Cost per Result": "$67.65"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "135629",
            "Clicks": "5323",
            "Conversions": "75",
            "Volume": "$13,751",
            "Cost per Result": "$83.56"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "473054",
            "Clicks": "5776",
            "Conversions": "54",
            "Volume": "$12,726",
            "Cost per Result": "$64.87"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "271480",
            "Clicks": "5678",
            "Conversions": "59",
            "Volume": "$9,124",
            "Cost per Result": "$20.70"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "166234",
            "Clicks": "2094",
            "Conversions": "83",
            "Volume": "$6,037",
            "Cost per Result": "$82.70"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "441193",
            "Clicks": "4315",
            "Conversions": "69",
            "Volume": "$10,446",
            "Cost per Result": "$22.87"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "135238",
            "Clicks": "3415",
            "Conversions": "76",
            "Volume": "$10,479",
            "Cost per Result": "$34.78"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "385374",
            "Clicks": "4841",
            "Conversions": "50",
            "Volume": "$6,306",
            "Cost per Result": "$49.24"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "450776",
            "Clicks": "5818",
            "Conversions": "86",
            "Volume": "$11,368",
            "Cost per Result": "$38.41"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "311476",
            "Clicks": "6028",
            "Conversions": "75",
            "Volume": "$10,710",
            "Cost per Result": "$35.86"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "448200",
            "Clicks": "2949",
            "Conversions": "43",
            "Volume": "$14,718",
            "Cost per Result": "$24.57"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "146566",
            "Clicks": "4103",
            "Conversions": "55",
            "Volume": "$4,488",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "421526",
            "Clicks": "2966",
            "Conversions": "63",
            "Volume": "$10,921",
            "Cost per Result": "$35.59"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "255407",
            "Clicks": "3507",
            "Conversions": "76",
            "Volume": "$9,601",
            "Cost per Result": "$80.91"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "126948",
            "Clicks": "2682",
            "Conversions": "52",
            "Volume": "$7,808",
            "Cost per Result": "$48.33"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "377780",
            "Clicks": "5614",
            "Conversions": "57",
            "Volume": "$10,285",
            "Cost per Result": "$73.57"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "391489",
            "Clicks": "5203",
            "Conversions": "40",
            "Volume": "$6,459",
            "Cost per Result": "$45.79"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "413553",
            "Clicks": "3292",
            "Conversions": "40",
            "Volume": "$7,620",
            "Cost per Result": "$79.59"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "316451",
            "Clicks": "6874",
            "Conversions": "78",
            "Volume": "$12,537",
            "Cost per Result": "$46.96"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "261324",
            "Clicks": "5782",
            "Conversions": "41",
            "Volume": "$13,306",
            "Cost per Result": "$46.20"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "271855",
            "Clicks": "5177",
            "Conversions": "78",
            "Volume": "$15,819",
            "Cost per Result": "$35.78"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "111195",
            "Clicks": "2432",
            "Conversions": "75",
            "Volume": "$4,634",
            "Cost per Result": "$25.10"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "114530",
            "Clicks": "3726",
            "Conversions": "54",
            "Volume": "$5,876",
            "Cost per Result": "$65.59"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "249895",
            "Clicks": "5516",
            "Conversions": "73",
            "Volume": "$13,040",
            "Cost per Result": "$73.67"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "227838",
            "Clicks": "2595",
            "Conversions": "62",
            "Volume": "$6,034",
            "Cost per Result": "$45.97"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "434169",
            "Clicks": "2821",
            "Conversions": "86",
            "Volume": "$6,596",
            "Cost per Result": "$31.31"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "155281",
            "Clicks": "2593",
            "Conversions": "61",
            "Volume": "$15,500",
            "Cost per Result": "$69.21"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "234150",
            "Clicks": "4752",
            "Conversions": "84",
            "Volume": "$12,417",
            "Cost per Result": "$53.95"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "244702",
            "Clicks": "5367",
            "Conversions": "69",
            "Volume": "$12,340",
            "Cost per Result": "$82.21"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "481918",
            "Clicks": "6426",
            "Conversions": "85",
            "Volume": "$4,397",
            "Cost per Result": "$74.17"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "377070",
            "Clicks": "6483",
            "Conversions": "58",
            "Volume": "$14,396",
            "Cost per Result": "$41.82"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "452185",
            "Clicks": "2040",
            "Conversions": "71",
            "Volume": "$10,026",
            "Cost per Result": "$37.07"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "447813",
            "Clicks": "6166",
            "Conversions": "44",
            "Volume": "$8,437",
            "Cost per Result": "$68.00"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "254175",
            "Clicks": "6794",
            "Conversions": "69",
            "Volume": "$8,189",
            "Cost per Result": "$81.41"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "404301",
            "Clicks": "4636",
            "Conversions": "78",
            "Volume": "$14,513",
            "Cost per Result": "$49.91"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "459220",
            "Clicks": "4706",
            "Conversions": "74",
            "Volume": "$15,882",
            "Cost per Result": "$84.77"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "306818",
            "Clicks": "6982",
            "Conversions": "59",
            "Volume": "$13,499",
            "Cost per Result": "$79.86"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "192137",
            "Clicks": "6084",
            "Conversions": "83",
            "Volume": "$13,362",
            "Cost per Result": "$39.61"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "139673",
            "Clicks": "2005",
            "Conversions": "64",
            "Volume": "$5,842",
            "Cost per Result": "$37.66"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "271542",
            "Clicks": "4319",
            "Conversions": "78",
            "Volume": "$11,470",
            "Cost per Result": "$31.97"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "202960",
            "Clicks": "2278",
            "Conversions": "48",
            "Volume": "$7,204",
            "Cost per Result": "$66.20"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "421932",
            "Clicks": "2755",
            "Conversions": "50",
            "Volume": "$4,142",
            "Cost per Result": "$29.63"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "193754",
            "Clicks": "2735",
            "Conversions": "40",
            "Volume": "$8,671",
            "Cost per Result": "$62.39"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "356914",
            "Clicks": "5989",
            "Conversions": "60",
            "Volume": "$5,465",
            "Cost per Result": "$37.24"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "449789",
            "Clicks": "5188",
            "Conversions": "63",
            "Volume": "$15,948",
            "Cost per Result": "$66.42"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "137661",
            "Clicks": "2938",
            "Conversions": "85",
            "Volume": "$11,381",
            "Cost per Result": "$77.55"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "271267",
            "Clicks": "3342",
            "Conversions": "90",
            "Volume": "$7,962",
            "Cost per Result": "$29.94"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "264391",
            "Clicks": "3318",
            "Conversions": "42",
            "Volume": "$14,079",
            "Cost per Result": "$43.86"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "331410",
            "Clicks": "5756",
            "Conversions": "41",
            "Volume": "$14,972",
            "Cost per Result": "$34.83"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "113244",
            "Clicks": "3491",
            "Conversions": "50",
            "Volume": "$12,571",
            "Cost per Result": "$67.98"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "188567",
            "Clicks": "3128",
            "Conversions": "70",
            "Volume": "$7,914",
            "Cost per Result": "$57.01"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "169679",
            "Clicks": "3661",
            "Conversions": "61",
            "Volume": "$11,425",
            "Cost per Result": "$34.56"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "258725",
            "Clicks": "5640",
            "Conversions": "72",
            "Volume": "$14,724",
            "Cost per Result": "$80.28"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246709",
            "Clicks": "6702",
            "Conversions": "68",
            "Volume": "$6,569",
            "Cost per Result": "$60.34"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "414357",
            "Clicks": "4545",
            "Conversions": "80",
            "Volume": "$9,696",
            "Cost per Result": "$48.24"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "257879",
            "Clicks": "5729",
            "Conversions": "52",
            "Volume": "$14,611",
            "Cost per Result": "$55.46"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "142931",
            "Clicks": "4823",
            "Conversions": "89",
            "Volume": "$9,131",
            "Cost per Result": "$41.13"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "117600",
            "Clicks": "5606",
            "Conversions": "51",
            "Volume": "$4,677",
            "Cost per Result": "$71.49"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "105770",
            "Clicks": "4592",
            "Conversions": "76",
            "Volume": "$8,221",
            "Cost per Result": "$73.75"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "473638",
            "Clicks": "3457",
            "Conversions": "57",
            "Volume": "$10,439",
            "Cost per Result": "$68.72"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "146898",
            "Clicks": "2813",
            "Conversions": "63",
            "Volume": "$10,763",
            "Cost per Result": "$81.53"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "177541",
            "Clicks": "4947",
            "Conversions": "90",
            "Volume": "$5,888",
            "Cost per Result": "$29.44"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "154550",
            "Clicks": "5094",
            "Conversions": "45",
            "Volume": "$8,516",
            "Cost per Result": "$29.14"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "139480",
            "Clicks": "6503",
            "Conversions": "42",
            "Volume": "$15,646",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "498850",
            "Clicks": "4575",
            "Conversions": "66",
            "Volume": "$11,002",
            "Cost per Result": "$43.79"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "396015",
            "Clicks": "5842",
            "Conversions": "55",
            "Volume": "$7,116",
            "Cost per Result": "$28.67"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "363082",
            "Clicks": "4640",
            "Conversions": "74",
            "Volume": "$10,836",
            "Cost per Result": "$77.65"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "476250",
            "Clicks": "3690",
            "Conversions": "64",
            "Volume": "$6,568",
            "Cost per Result": "$49.30"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "239125",
            "Clicks": "4878",
            "Conversions": "45",
            "Volume": "$14,397",
            "Cost per Result": "$28.85"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "347843",
            "Clicks": "2369",
            "Conversions": "53",
            "Volume": "$15,056",
            "Cost per Result": "$46.62"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "337670",
            "Clicks": "3270",
            "Conversions": "73",
            "Volume": "$14,156",
            "Cost per Result": "$31.30"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "355307",
            "Clicks": "6211",
            "Conversions": "70",
            "Volume": "$12,929",
            "Cost per Result": "$74.77"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "219605",
            "Clicks": "4069",
            "Conversions": "47",
            "Volume": "$9,217",
            "Cost per Result": "$30.43"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "255786",
            "Clicks": "5372",
            "Conversions": "66",
            "Volume": "$7,106",
            "Cost per Result": "$34.46"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "216494",
            "Clicks": "2518",
            "Conversions": "60",
            "Volume": "$15,991",
            "Cost per Result": "$35.73"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "270358",
            "Clicks": "2431",
            "Conversions": "57",
            "Volume": "$4,077",
            "Cost per Result": "$32.77"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "186723",
            "Clicks": "3342",
            "Conversions": "84",
            "Volume": "$15,077",
            "Cost per Result": "$36.26"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "391191",
            "Clicks": "2989",
            "Conversions": "83",
            "Volume": "$14,392",
            "Cost per Result": "$52.30"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "342260",
            "Clicks": "3261",
            "Conversions": "79",
            "Volume": "$6,712",
            "Cost per Result": "$40.57"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "337451",
            "Clicks": "5683",
            "Conversions": "59",
            "Volume": "$4,653",
            "Cost per Result": "$81.21"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "152809",
            "Clicks": "3380",
            "Conversions": "70",
            "Volume": "$5,086",
            "Cost per Result": "$48.73"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "487492",
            "Clicks": "3576",
            "Conversions": "49",
            "Volume": "$10,406",
            "Cost per Result": "$40.12"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "444658",
            "Clicks": "6930",
            "Conversions": "58",
            "Volume": "$8,638",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "314910",
            "Clicks": "5528",
            "Conversions": "58",
            "Volume": "$4,000",
            "Cost per Result": "$34.10"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "496852",
            "Clicks": "6930",
            "Conversions": "74",
            "Volume": "$10,554",
            "Cost per Result": "$30.10"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "235901",
            "Clicks": "4942",
            "Conversions": "62",
            "Volume": "$4,204",
            "Cost per Result": "$30.79"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "415296",
            "Clicks": "2035",
            "Conversions": "54",
            "Volume": "$6,112",
            "Cost per Result": "$41.11"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "380670",
            "Clicks": "6602",
            "Conversions": "56",
            "Volume": "$6,017",
            "Cost per Result": "$82.78"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "134566",
            "Clicks": "3229",
            "Conversions": "67",
            "Volume": "$4,657",
            "Cost per Result": "$47.15"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "109519",
            "Clicks": "4060",
            "Conversions": "46",
            "Volume": "$5,267",
            "Cost per Result": "$25.35"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "337874",
            "Clicks": "2632",
            "Conversions": "43",
            "Volume": "$4,651",
            "Cost per Result": "$52.20"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "312678",
            "Clicks": "2264",
            "Conversions": "49",
            "Volume": "$15,293",
            "Cost per Result": "$74.28"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "459030",
            "Clicks": "5417",
            "Conversions": "48",
            "Volume": "$11,756",
            "Cost per Result": "$32.80"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "301578",
            "Clicks": "3722",
            "Conversions": "80",
            "Volume": "$12,335",
            "Cost per Result": "$83.58"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "278922",
            "Clicks": "3279",
            "Conversions": "65",
            "Volume": "$4,927",
            "Cost per Result": "$69.10"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188723",
            "Clicks": "3010",
            "Conversions": "44",
            "Volume": "$7,435",
            "Cost per Result": "$31.01"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "176904",
            "Clicks": "6027",
            "Conversions": "76",
            "Volume": "$13,216",
            "Cost per Result": "$42.53"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "465470",
            "Clicks": "6909",
            "Conversions": "80",
            "Volume": "$8,463",
            "Cost per Result": "$39.60"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "449524",
            "Clicks": "6339",
            "Conversions": "80",
            "Volume": "$12,988",
            "Cost per Result": "$43.96"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "155289",
            "Clicks": "6755",
            "Conversions": "89",
            "Volume": "$14,443",
            "Cost per Result": "$28.11"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "251678",
            "Clicks": "5403",
            "Conversions": "44",
            "Volume": "$14,588",
            "Cost per Result": "$71.40"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "208682",
            "Clicks": "6014",
            "Conversions": "75",
            "Volume": "$7,450",
            "Cost per Result": "$28.01"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "116015",
            "Clicks": "6913",
            "Conversions": "53",
            "Volume": "$11,510",
            "Cost per Result": "$47.38"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "210673",
            "Clicks": "4906",
            "Conversions": "75",
            "Volume": "$9,763",
            "Cost per Result": "$25.01"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "114115",
            "Clicks": "3426",
            "Conversions": "53",
            "Volume": "$6,945",
            "Cost per Result": "$42.95"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "319705",
            "Clicks": "2440",
            "Conversions": "47",
            "Volume": "$12,513",
            "Cost per Result": "$30.31"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "267727",
            "Clicks": "3558",
            "Conversions": "47",
            "Volume": "$12,306",
            "Cost per Result": "$64.53"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "105881",
            "Clicks": "4359",
            "Conversions": "71",
            "Volume": "$6,390",
            "Cost per Result": "$23.12"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "111466",
            "Clicks": "6237",
            "Conversions": "59",
            "Volume": "$4,895",
            "Cost per Result": "$24.70"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "296412",
            "Clicks": "2494",
            "Conversions": "66",
            "Volume": "$12,722",
            "Cost per Result": "$40.06"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "142404",
            "Clicks": "3986",
            "Conversions": "70",
            "Volume": "$9,450",
            "Cost per Result": "$60.36"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "387467",
            "Clicks": "6385",
            "Conversions": "83",
            "Volume": "$8,879",
            "Cost per Result": "$74.11"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "440820",
            "Clicks": "6742",
            "Conversions": "71",
            "Volume": "$15,505",
            "Cost per Result": "$80.60"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "496405",
            "Clicks": "2963",
            "Conversions": "72",
            "Volume": "$4,203",
            "Cost per Result": "$42.55"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "465060",
            "Clicks": "4300",
            "Conversions": "65",
            "Volume": "$7,507",
            "Cost per Result": "$72.55"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "289689",
            "Clicks": "3717",
            "Conversions": "89",
            "Volume": "$6,620",
            "Cost per Result": "$76.67"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "348289",
            "Clicks": "5573",
            "Conversions": "83",
            "Volume": "$5,830",
            "Cost per Result": "$64.57"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "344235",
            "Clicks": "4227",
            "Conversions": "77",
            "Volume": "$9,474",
            "Cost per Result": "$40.84"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "273530",
            "Clicks": "2989",
            "Conversions": "57",
            "Volume": "$14,332",
            "Cost per Result": "$54.74"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "229107",
            "Clicks": "2000",
            "Conversions": "74",
            "Volume": "$9,000",
            "Cost per Result": "$65.17"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "374565",
            "Clicks": "2818",
            "Conversions": "56",
            "Volume": "$8,232",
            "Cost per Result": "$75.53"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "307391",
            "Clicks": "5276",
            "Conversions": "71",
            "Volume": "$11,528",
            "Cost per Result": "$25.36"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "284134",
            "Clicks": "6806",
            "Conversions": "81",
            "Volume": "$6,694",
            "Cost per Result": "$77.43"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "462874",
            "Clicks": "6181",
            "Conversions": "43",
            "Volume": "$7,282",
            "Cost per Result": "$79.46"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "488694",
            "Clicks": "5273",
            "Conversions": "58",
            "Volume": "$11,835",
            "Cost per Result": "$22.76"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "392362",
            "Clicks": "3527",
            "Conversions": "82",
            "Volume": "$15,428",
            "Cost per Result": "$28.25"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "293396",
            "Clicks": "4852",
            "Conversions": "44",
            "Volume": "$15,768",
            "Cost per Result": "$48.20"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "242731",
            "Clicks": "6307",
            "Conversions": "82",
            "Volume": "$15,075",
            "Cost per Result": "$43.60"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "475772",
            "Clicks": "4211",
            "Conversions": "66",
            "Volume": "$11,731",
            "Cost per Result": "$47.66"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "114082",
            "Clicks": "2933",
            "Conversions": "49",
            "Volume": "$7,128",
            "Cost per Result": "$56.66"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "316853",
            "Clicks": "4707",
            "Conversions": "87",
            "Volume": "$14,036",
            "Cost per Result": "$57.88"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "329922",
            "Clicks": "5750",
            "Conversions": "55",
            "Volume": "$5,208",
            "Cost per Result": "$80.22"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "330555",
            "Clicks": "4634",
            "Conversions": "50",
            "Volume": "$4,502",
            "Cost per Result": "$61.39"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "256484",
            "Clicks": "6439",
            "Conversions": "66",
            "Volume": "$5,139",
            "Cost per Result": "$60.92"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "233587",
            "Clicks": "5174",
            "Conversions": "40",
            "Volume": "$15,705",
            "Cost per Result": "$58.87"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "431895",
            "Clicks": "2750",
            "Conversions": "48",
            "Volume": "$5,635",
            "Cost per Result": "$23.00"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "457847",
            "Clicks": "3771",
            "Conversions": "89",
            "Volume": "$13,555",
            "Cost per Result": "$40.38"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "468848",
            "Clicks": "3523",
            "Conversions": "46",
            "Volume": "$15,388",
            "Cost per Result": "$59.65"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "235154",
            "Clicks": "4276",
            "Conversions": "71",
            "Volume": "$5,004",
            "Cost per Result": "$67.27"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "379914",
            "Clicks": "3799",
            "Conversions": "51",
            "Volume": "$4,673",
            "Cost per Result": "$62.04"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "191817",
            "Clicks": "4163",
            "Conversions": "84",
            "Volume": "$8,532",
            "Cost per Result": "$35.82"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "432092",
            "Clicks": "5184",
            "Conversions": "77",
            "Volume": "$15,251",
            "Cost per Result": "$48.09"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "370193",
            "Clicks": "3476",
            "Conversions": "70",
            "Volume": "$4,979",
            "Cost per Result": "$25.22"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "487398",
            "Clicks": "4378",
            "Conversions": "52",
            "Volume": "$10,437",
            "Cost per Result": "$50.06"
          }
        ]
      },
      "MarketplaceUsersData": {
        "name": "Marketplace Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "424672",
            "Clicks": "6928",
            "Conversions": "42",
            "Conversion Value": "$14,146",
            "Cost per Result": "$45.39"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "141304",
            "Clicks": "6892",
            "Conversions": "44",
            "Conversion Value": "$13,727",
            "Cost per Result": "$49.97"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "283212",
            "Clicks": "5765",
            "Conversions": "78",
            "Conversion Value": "$13,858",
            "Cost per Result": "$55.70"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "192285",
            "Clicks": "3841",
            "Conversions": "73",
            "Conversion Value": "$6,717",
            "Cost per Result": "$42.73"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "133895",
            "Clicks": "6646",
            "Conversions": "58",
            "Conversion Value": "$13,537",
            "Cost per Result": "$25.53"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "371867",
            "Clicks": "5454",
            "Conversions": "52",
            "Conversion Value": "$6,396",
            "Cost per Result": "$35.43"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "336939",
            "Clicks": "5204",
            "Conversions": "41",
            "Conversion Value": "$6,313",
            "Cost per Result": "$66.96"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "375794",
            "Clicks": "2818",
            "Conversions": "47",
            "Conversion Value": "$8,233",
            "Cost per Result": "$32.36"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "397090",
            "Clicks": "4547",
            "Conversions": "59",
            "Conversion Value": "$5,365",
            "Cost per Result": "$39.88"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "161367",
            "Clicks": "6637",
            "Conversions": "49",
            "Conversion Value": "$6,428",
            "Cost per Result": "$84.11"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "343191",
            "Clicks": "4988",
            "Conversions": "67",
            "Conversion Value": "$9,289",
            "Cost per Result": "$60.14"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "374748",
            "Clicks": "6683",
            "Conversions": "72",
            "Conversion Value": "$4,230",
            "Cost per Result": "$47.26"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "443365",
            "Clicks": "5391",
            "Conversions": "70",
            "Conversion Value": "$15,629",
            "Cost per Result": "$39.43"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "456486",
            "Clicks": "2179",
            "Conversions": "66",
            "Conversion Value": "$9,962",
            "Cost per Result": "$67.71"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "143443",
            "Clicks": "2909",
            "Conversions": "42",
            "Conversion Value": "$14,298",
            "Cost per Result": "$23.71"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "210494",
            "Clicks": "2578",
            "Conversions": "52",
            "Conversion Value": "$11,237",
            "Cost per Result": "$22.92"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "123457",
            "Clicks": "2071",
            "Conversions": "44",
            "Conversion Value": "$13,731",
            "Cost per Result": "$68.26"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "273159",
            "Clicks": "4190",
            "Conversions": "86",
            "Conversion Value": "$7,811",
            "Cost per Result": "$70.71"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "345926",
            "Clicks": "5879",
            "Conversions": "84",
            "Conversion Value": "$8,408",
            "Cost per Result": "$65.04"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "222281",
            "Clicks": "5192",
            "Conversions": "56",
            "Conversion Value": "$7,948",
            "Cost per Result": "$36.98"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "488026",
            "Clicks": "5331",
            "Conversions": "70",
            "Conversion Value": "$6,486",
            "Cost per Result": "$38.46"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "216078",
            "Clicks": "6511",
            "Conversions": "57",
            "Conversion Value": "$15,973",
            "Cost per Result": "$25.80"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "356257",
            "Clicks": "5118",
            "Conversions": "49",
            "Conversion Value": "$12,377",
            "Cost per Result": "$84.86"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "277261",
            "Clicks": "2381",
            "Conversions": "65",
            "Conversion Value": "$11,420",
            "Cost per Result": "$46.73"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "207057",
            "Clicks": "4971",
            "Conversions": "62",
            "Conversion Value": "$8,742",
            "Cost per Result": "$56.66"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "194555",
            "Clicks": "3129",
            "Conversions": "61",
            "Conversion Value": "$15,744",
            "Cost per Result": "$77.95"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "151227",
            "Clicks": "4514",
            "Conversions": "87",
            "Conversion Value": "$7,225",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "493396",
            "Clicks": "4563",
            "Conversions": "58",
            "Conversion Value": "$9,358",
            "Cost per Result": "$26.32"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "296690",
            "Clicks": "5709",
            "Conversions": "49",
            "Conversion Value": "$4,471",
            "Cost per Result": "$69.60"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "349582",
            "Clicks": "3863",
            "Conversions": "67",
            "Conversion Value": "$15,587",
            "Cost per Result": "$46.65"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "256750",
            "Clicks": "2984",
            "Conversions": "66",
            "Conversion Value": "$13,410",
            "Cost per Result": "$49.63"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "436372",
            "Clicks": "3636",
            "Conversions": "54",
            "Conversion Value": "$14,205",
            "Cost per Result": "$65.70"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "474759",
            "Clicks": "6433",
            "Conversions": "65",
            "Conversion Value": "$10,298",
            "Cost per Result": "$40.26"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "190078",
            "Clicks": "4129",
            "Conversions": "45",
            "Conversion Value": "$12,272",
            "Cost per Result": "$31.03"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "427283",
            "Clicks": "5852",
            "Conversions": "48",
            "Conversion Value": "$11,305",
            "Cost per Result": "$80.82"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "166076",
            "Clicks": "2923",
            "Conversions": "58",
            "Conversion Value": "$14,133",
            "Cost per Result": "$49.18"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "184038",
            "Clicks": "5518",
            "Conversions": "40",
            "Conversion Value": "$11,369",
            "Cost per Result": "$61.64"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "282049",
            "Clicks": "6712",
            "Conversions": "73",
            "Conversion Value": "$9,161",
            "Cost per Result": "$70.99"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "222894",
            "Clicks": "5104",
            "Conversions": "63",
            "Conversion Value": "$6,085",
            "Cost per Result": "$38.44"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "485123",
            "Clicks": "2377",
            "Conversions": "85",
            "Conversion Value": "$10,278",
            "Cost per Result": "$44.68"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "302147",
            "Clicks": "2029",
            "Conversions": "67",
            "Conversion Value": "$9,320",
            "Cost per Result": "$69.16"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "342567",
            "Clicks": "4117",
            "Conversions": "53",
            "Conversion Value": "$4,680",
            "Cost per Result": "$50.42"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "441446",
            "Clicks": "4122",
            "Conversions": "67",
            "Conversion Value": "$4,404",
            "Cost per Result": "$50.83"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "481402",
            "Clicks": "3094",
            "Conversions": "74",
            "Conversion Value": "$4,670",
            "Cost per Result": "$43.01"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "199734",
            "Clicks": "3074",
            "Conversions": "44",
            "Conversion Value": "$11,818",
            "Cost per Result": "$84.20"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "461188",
            "Clicks": "5275",
            "Conversions": "81",
            "Conversion Value": "$14,059",
            "Cost per Result": "$69.99"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "387204",
            "Clicks": "3935",
            "Conversions": "66",
            "Conversion Value": "$12,374",
            "Cost per Result": "$32.02"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "358544",
            "Clicks": "6989",
            "Conversions": "79",
            "Conversion Value": "$11,318",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "313582",
            "Clicks": "6342",
            "Conversions": "83",
            "Conversion Value": "$14,727",
            "Cost per Result": "$49.95"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "404652",
            "Clicks": "5038",
            "Conversions": "60",
            "Conversion Value": "$12,393",
            "Cost per Result": "$32.47"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "243526",
            "Clicks": "5292",
            "Conversions": "68",
            "Conversion Value": "$4,430",
            "Cost per Result": "$72.53"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "212679",
            "Clicks": "6347",
            "Conversions": "87",
            "Conversion Value": "$15,780",
            "Cost per Result": "$60.55"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "368880",
            "Clicks": "3841",
            "Conversions": "83",
            "Conversion Value": "$5,438",
            "Cost per Result": "$67.85"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "356353",
            "Clicks": "4209",
            "Conversions": "88",
            "Conversion Value": "$14,848",
            "Cost per Result": "$48.11"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "366080",
            "Clicks": "3880",
            "Conversions": "80",
            "Conversion Value": "$14,529",
            "Cost per Result": "$51.78"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "440784",
            "Clicks": "3839",
            "Conversions": "54",
            "Conversion Value": "$14,283",
            "Cost per Result": "$57.99"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "214993",
            "Clicks": "3182",
            "Conversions": "60",
            "Conversion Value": "$13,219",
            "Cost per Result": "$56.77"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "200353",
            "Clicks": "6574",
            "Conversions": "62",
            "Conversion Value": "$8,550",
            "Cost per Result": "$80.49"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "124396",
            "Clicks": "4852",
            "Conversions": "63",
            "Conversion Value": "$8,917",
            "Cost per Result": "$50.70"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "455404",
            "Clicks": "6533",
            "Conversions": "51",
            "Conversion Value": "$6,105",
            "Cost per Result": "$64.63"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "331605",
            "Clicks": "6289",
            "Conversions": "60",
            "Conversion Value": "$9,657",
            "Cost per Result": "$79.60"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "380328",
            "Clicks": "2731",
            "Conversions": "47",
            "Conversion Value": "$4,231",
            "Cost per Result": "$40.95"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "393382",
            "Clicks": "2384",
            "Conversions": "66",
            "Conversion Value": "$5,366",
            "Cost per Result": "$80.53"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "132466",
            "Clicks": "2316",
            "Conversions": "46",
            "Conversion Value": "$4,380",
            "Cost per Result": "$48.11"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "417228",
            "Clicks": "2338",
            "Conversions": "58",
            "Conversion Value": "$15,383",
            "Cost per Result": "$36.15"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "247114",
            "Clicks": "3359",
            "Conversions": "50",
            "Conversion Value": "$8,145",
            "Cost per Result": "$30.42"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "332732",
            "Clicks": "5389",
            "Conversions": "67",
            "Conversion Value": "$7,289",
            "Cost per Result": "$51.61"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "133352",
            "Clicks": "4218",
            "Conversions": "77",
            "Conversion Value": "$14,915",
            "Cost per Result": "$73.32"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "289622",
            "Clicks": "6811",
            "Conversions": "58",
            "Conversion Value": "$10,196",
            "Cost per Result": "$53.75"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "454185",
            "Clicks": "6894",
            "Conversions": "72",
            "Conversion Value": "$14,092",
            "Cost per Result": "$82.69"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "291039",
            "Clicks": "6658",
            "Conversions": "90",
            "Conversion Value": "$10,559",
            "Cost per Result": "$74.49"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "119357",
            "Clicks": "2021",
            "Conversions": "58",
            "Conversion Value": "$13,815",
            "Cost per Result": "$54.69"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "407763",
            "Clicks": "6998",
            "Conversions": "40",
            "Conversion Value": "$4,570",
            "Cost per Result": "$56.92"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "368580",
            "Clicks": "3538",
            "Conversions": "68",
            "Conversion Value": "$5,550",
            "Cost per Result": "$36.76"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "132688",
            "Clicks": "4651",
            "Conversions": "72",
            "Conversion Value": "$12,123",
            "Cost per Result": "$38.00"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "316131",
            "Clicks": "6691",
            "Conversions": "80",
            "Conversion Value": "$4,841",
            "Cost per Result": "$73.64"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "467555",
            "Clicks": "6002",
            "Conversions": "51",
            "Conversion Value": "$7,229",
            "Cost per Result": "$40.01"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "435564",
            "Clicks": "3922",
            "Conversions": "65",
            "Conversion Value": "$14,983",
            "Cost per Result": "$69.49"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "409499",
            "Clicks": "4849",
            "Conversions": "84",
            "Conversion Value": "$6,674",
            "Cost per Result": "$43.23"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "287617",
            "Clicks": "6135",
            "Conversions": "53",
            "Conversion Value": "$4,437",
            "Cost per Result": "$54.85"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "128594",
            "Clicks": "2252",
            "Conversions": "55",
            "Conversion Value": "$4,069",
            "Cost per Result": "$62.15"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "168435",
            "Clicks": "6693",
            "Conversions": "58",
            "Conversion Value": "$7,324",
            "Cost per Result": "$40.21"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "185339",
            "Clicks": "6114",
            "Conversions": "49",
            "Conversion Value": "$4,643",
            "Cost per Result": "$64.86"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "343485",
            "Clicks": "5987",
            "Conversions": "88",
            "Conversion Value": "$6,206",
            "Cost per Result": "$48.49"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "396839",
            "Clicks": "4818",
            "Conversions": "75",
            "Conversion Value": "$8,176",
            "Cost per Result": "$31.50"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "130652",
            "Clicks": "4830",
            "Conversions": "43",
            "Conversion Value": "$9,451",
            "Cost per Result": "$46.11"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "370853",
            "Clicks": "6780",
            "Conversions": "64",
            "Conversion Value": "$9,700",
            "Cost per Result": "$60.84"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "130993",
            "Clicks": "6183",
            "Conversions": "58",
            "Conversion Value": "$5,394",
            "Cost per Result": "$34.65"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "318117",
            "Clicks": "6362",
            "Conversions": "90",
            "Conversion Value": "$13,020",
            "Cost per Result": "$46.79"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "135618",
            "Clicks": "5493",
            "Conversions": "63",
            "Conversion Value": "$13,136",
            "Cost per Result": "$50.34"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "336675",
            "Clicks": "5229",
            "Conversions": "52",
            "Conversion Value": "$4,711",
            "Cost per Result": "$59.53"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "451683",
            "Clicks": "3291",
            "Conversions": "59",
            "Conversion Value": "$5,528",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "230027",
            "Clicks": "5405",
            "Conversions": "80",
            "Conversion Value": "$13,121",
            "Cost per Result": "$76.88"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "310200",
            "Clicks": "2743",
            "Conversions": "72",
            "Conversion Value": "$11,531",
            "Cost per Result": "$29.29"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "347420",
            "Clicks": "5597",
            "Conversions": "64",
            "Conversion Value": "$11,784",
            "Cost per Result": "$22.62"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "195230",
            "Clicks": "4748",
            "Conversions": "69",
            "Conversion Value": "$15,888",
            "Cost per Result": "$42.52"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "274798",
            "Clicks": "2664",
            "Conversions": "52",
            "Conversion Value": "$8,584",
            "Cost per Result": "$40.54"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "251216",
            "Clicks": "6620",
            "Conversions": "64",
            "Conversion Value": "$4,670",
            "Cost per Result": "$72.24"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "194989",
            "Clicks": "5857",
            "Conversions": "70",
            "Conversion Value": "$10,501",
            "Cost per Result": "$81.47"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "497287",
            "Clicks": "4376",
            "Conversions": "81",
            "Conversion Value": "$7,814",
            "Cost per Result": "$48.17"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "393302",
            "Clicks": "2644",
            "Conversions": "44",
            "Conversion Value": "$5,846",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "249879",
            "Clicks": "5615",
            "Conversions": "40",
            "Conversion Value": "$15,784",
            "Cost per Result": "$57.89"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "108628",
            "Clicks": "2915",
            "Conversions": "58",
            "Conversion Value": "$10,921",
            "Cost per Result": "$83.11"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "405026",
            "Clicks": "3834",
            "Conversions": "60",
            "Conversion Value": "$14,162",
            "Cost per Result": "$45.20"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "400480",
            "Clicks": "6315",
            "Conversions": "45",
            "Conversion Value": "$10,838",
            "Cost per Result": "$72.04"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "267354",
            "Clicks": "2769",
            "Conversions": "88",
            "Conversion Value": "$5,389",
            "Cost per Result": "$82.88"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "380450",
            "Clicks": "2599",
            "Conversions": "77",
            "Conversion Value": "$12,130",
            "Cost per Result": "$22.63"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "473628",
            "Clicks": "6912",
            "Conversions": "86",
            "Conversion Value": "$9,264",
            "Cost per Result": "$73.34"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "385003",
            "Clicks": "3678",
            "Conversions": "54",
            "Conversion Value": "$14,460",
            "Cost per Result": "$76.06"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "215287",
            "Clicks": "3297",
            "Conversions": "87",
            "Conversion Value": "$14,229",
            "Cost per Result": "$70.79"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "368990",
            "Clicks": "6321",
            "Conversions": "69",
            "Conversion Value": "$9,333",
            "Cost per Result": "$62.02"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "418849",
            "Clicks": "2926",
            "Conversions": "52",
            "Conversion Value": "$8,822",
            "Cost per Result": "$20.11"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "181094",
            "Clicks": "6245",
            "Conversions": "54",
            "Conversion Value": "$5,880",
            "Cost per Result": "$46.47"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "328404",
            "Clicks": "3826",
            "Conversions": "43",
            "Conversion Value": "$12,979",
            "Cost per Result": "$23.71"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "429683",
            "Clicks": "5998",
            "Conversions": "55",
            "Conversion Value": "$10,469",
            "Cost per Result": "$66.23"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "215288",
            "Clicks": "6823",
            "Conversions": "59",
            "Conversion Value": "$15,980",
            "Cost per Result": "$29.67"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "427992",
            "Clicks": "3974",
            "Conversions": "81",
            "Conversion Value": "$8,606",
            "Cost per Result": "$20.43"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "211925",
            "Clicks": "5136",
            "Conversions": "63",
            "Conversion Value": "$14,541",
            "Cost per Result": "$75.00"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "269983",
            "Clicks": "4328",
            "Conversions": "84",
            "Conversion Value": "$15,261",
            "Cost per Result": "$21.67"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "456921",
            "Clicks": "6970",
            "Conversions": "70",
            "Conversion Value": "$11,588",
            "Cost per Result": "$84.25"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "362626",
            "Clicks": "4018",
            "Conversions": "88",
            "Conversion Value": "$5,638",
            "Cost per Result": "$70.12"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "304804",
            "Clicks": "6496",
            "Conversions": "42",
            "Conversion Value": "$5,370",
            "Cost per Result": "$52.63"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "238163",
            "Clicks": "3824",
            "Conversions": "52",
            "Conversion Value": "$11,357",
            "Cost per Result": "$52.45"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "185911",
            "Clicks": "2033",
            "Conversions": "75",
            "Conversion Value": "$7,297",
            "Cost per Result": "$52.92"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "183662",
            "Clicks": "4694",
            "Conversions": "66",
            "Conversion Value": "$15,231",
            "Cost per Result": "$55.13"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420448",
            "Clicks": "5546",
            "Conversions": "85",
            "Conversion Value": "$15,852",
            "Cost per Result": "$42.53"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "128669",
            "Clicks": "5122",
            "Conversions": "70",
            "Conversion Value": "$13,917",
            "Cost per Result": "$74.90"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "347682",
            "Clicks": "5802",
            "Conversions": "75",
            "Conversion Value": "$5,604",
            "Cost per Result": "$21.12"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "180123",
            "Clicks": "2475",
            "Conversions": "82",
            "Conversion Value": "$9,848",
            "Cost per Result": "$81.59"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "309820",
            "Clicks": "2992",
            "Conversions": "47",
            "Conversion Value": "$8,183",
            "Cost per Result": "$79.49"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "423247",
            "Clicks": "2937",
            "Conversions": "42",
            "Conversion Value": "$4,466",
            "Cost per Result": "$76.32"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "144654",
            "Clicks": "3257",
            "Conversions": "42",
            "Conversion Value": "$9,350",
            "Cost per Result": "$39.03"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "435409",
            "Clicks": "2412",
            "Conversions": "53",
            "Conversion Value": "$9,908",
            "Cost per Result": "$22.35"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "437509",
            "Clicks": "6251",
            "Conversions": "70",
            "Conversion Value": "$9,541",
            "Cost per Result": "$30.96"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "285836",
            "Clicks": "6713",
            "Conversions": "86",
            "Conversion Value": "$8,318",
            "Cost per Result": "$34.31"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "238050",
            "Clicks": "2759",
            "Conversions": "65",
            "Conversion Value": "$10,413",
            "Cost per Result": "$53.42"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "197575",
            "Clicks": "3349",
            "Conversions": "66",
            "Conversion Value": "$10,712",
            "Cost per Result": "$70.56"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "169551",
            "Clicks": "5572",
            "Conversions": "66",
            "Conversion Value": "$10,443",
            "Cost per Result": "$30.66"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "290638",
            "Clicks": "2433",
            "Conversions": "61",
            "Conversion Value": "$11,048",
            "Cost per Result": "$43.85"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "186578",
            "Clicks": "2686",
            "Conversions": "75",
            "Conversion Value": "$15,470",
            "Cost per Result": "$35.36"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "149361",
            "Clicks": "3472",
            "Conversions": "50",
            "Conversion Value": "$7,691",
            "Cost per Result": "$28.40"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "142509",
            "Clicks": "5130",
            "Conversions": "45",
            "Conversion Value": "$15,356",
            "Cost per Result": "$63.66"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "186491",
            "Clicks": "2315",
            "Conversions": "43",
            "Conversion Value": "$5,754",
            "Cost per Result": "$49.80"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "112785",
            "Clicks": "6493",
            "Conversions": "72",
            "Conversion Value": "$11,682",
            "Cost per Result": "$45.15"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "216463",
            "Clicks": "4241",
            "Conversions": "77",
            "Conversion Value": "$15,695",
            "Cost per Result": "$36.40"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "116496",
            "Clicks": "4987",
            "Conversions": "71",
            "Conversion Value": "$10,441",
            "Cost per Result": "$29.43"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "187113",
            "Clicks": "5049",
            "Conversions": "66",
            "Conversion Value": "$11,792",
            "Cost per Result": "$24.11"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "352316",
            "Clicks": "2733",
            "Conversions": "51",
            "Conversion Value": "$9,036",
            "Cost per Result": "$56.46"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "220210",
            "Clicks": "6502",
            "Conversions": "88",
            "Conversion Value": "$12,482",
            "Cost per Result": "$28.26"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "310556",
            "Clicks": "2868",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$37.23"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "325521",
            "Clicks": "6091",
            "Conversions": "51",
            "Conversion Value": "$8,600",
            "Cost per Result": "$82.30"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "168272",
            "Clicks": "6223",
            "Conversions": "56",
            "Conversion Value": "$6,829",
            "Cost per Result": "$53.75"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "210803",
            "Clicks": "6630",
            "Conversions": "55",
            "Conversion Value": "$6,963",
            "Cost per Result": "$24.95"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "276991",
            "Clicks": "2189",
            "Conversions": "79",
            "Conversion Value": "$8,598",
            "Cost per Result": "$20.05"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "322314",
            "Clicks": "5051",
            "Conversions": "71",
            "Conversion Value": "$13,034",
            "Cost per Result": "$65.86"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "302304",
            "Clicks": "3186",
            "Conversions": "49",
            "Conversion Value": "$11,389",
            "Cost per Result": "$75.19"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "109918",
            "Clicks": "2890",
            "Conversions": "45",
            "Conversion Value": "$14,905",
            "Cost per Result": "$62.10"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "391957",
            "Clicks": "4842",
            "Conversions": "85",
            "Conversion Value": "$6,135",
            "Cost per Result": "$69.69"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "191319",
            "Clicks": "5552",
            "Conversions": "66",
            "Conversion Value": "$11,223",
            "Cost per Result": "$43.25"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "186041",
            "Clicks": "6949",
            "Conversions": "54",
            "Conversion Value": "$12,627",
            "Cost per Result": "$33.23"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "346493",
            "Clicks": "2814",
            "Conversions": "78",
            "Conversion Value": "$10,940",
            "Cost per Result": "$78.38"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "222828",
            "Clicks": "3828",
            "Conversions": "49",
            "Conversion Value": "$7,183",
            "Cost per Result": "$69.95"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "242415",
            "Clicks": "2085",
            "Conversions": "69",
            "Conversion Value": "$8,552",
            "Cost per Result": "$48.80"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "250907",
            "Clicks": "6411",
            "Conversions": "83",
            "Conversion Value": "$8,402",
            "Cost per Result": "$46.18"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "487152",
            "Clicks": "6250",
            "Conversions": "69",
            "Conversion Value": "$15,665",
            "Cost per Result": "$43.61"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "178548",
            "Clicks": "6943",
            "Conversions": "53",
            "Conversion Value": "$8,407",
            "Cost per Result": "$29.15"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "240202",
            "Clicks": "3279",
            "Conversions": "61",
            "Conversion Value": "$7,725",
            "Cost per Result": "$40.64"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151322",
            "Clicks": "2369",
            "Conversions": "71",
            "Conversion Value": "$15,896",
            "Cost per Result": "$81.37"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "242763",
            "Clicks": "5971",
            "Conversions": "90",
            "Conversion Value": "$10,460",
            "Cost per Result": "$78.82"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "357382",
            "Clicks": "3377",
            "Conversions": "59",
            "Conversion Value": "$7,751",
            "Cost per Result": "$65.32"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "438492",
            "Clicks": "6700",
            "Conversions": "78",
            "Conversion Value": "$5,176",
            "Cost per Result": "$25.90"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "455744",
            "Clicks": "6482",
            "Conversions": "70",
            "Conversion Value": "$8,339",
            "Cost per Result": "$30.11"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "275216",
            "Clicks": "3147",
            "Conversions": "81",
            "Conversion Value": "$6,376",
            "Cost per Result": "$32.14"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "177186",
            "Clicks": "5267",
            "Conversions": "47",
            "Conversion Value": "$8,758",
            "Cost per Result": "$54.37"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "434427",
            "Clicks": "6753",
            "Conversions": "64",
            "Conversion Value": "$6,233",
            "Cost per Result": "$58.04"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "461964",
            "Clicks": "4512",
            "Conversions": "86",
            "Conversion Value": "$7,422",
            "Cost per Result": "$39.52"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "133571",
            "Clicks": "3702",
            "Conversions": "55",
            "Conversion Value": "$11,020",
            "Cost per Result": "$68.31"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "206533",
            "Clicks": "5945",
            "Conversions": "41",
            "Conversion Value": "$12,163",
            "Cost per Result": "$67.01"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "472386",
            "Clicks": "4523",
            "Conversions": "50",
            "Conversion Value": "$7,454",
            "Cost per Result": "$33.94"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "257518",
            "Clicks": "4374",
            "Conversions": "85",
            "Conversion Value": "$6,593",
            "Cost per Result": "$48.10"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "216343",
            "Clicks": "3653",
            "Conversions": "87",
            "Conversion Value": "$10,847",
            "Cost per Result": "$51.16"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "410693",
            "Clicks": "5880",
            "Conversions": "74",
            "Conversion Value": "$14,491",
            "Cost per Result": "$59.20"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "376611",
            "Clicks": "6423",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$35.23"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "173018",
            "Clicks": "5319",
            "Conversions": "76",
            "Conversion Value": "$14,180",
            "Cost per Result": "$65.66"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "286719",
            "Clicks": "6766",
            "Conversions": "78",
            "Conversion Value": "$11,592",
            "Cost per Result": "$83.13"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "384723",
            "Clicks": "6090",
            "Conversions": "70",
            "Conversion Value": "$12,033",
            "Cost per Result": "$74.68"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "102892",
            "Clicks": "2309",
            "Conversions": "47",
            "Conversion Value": "$15,597",
            "Cost per Result": "$56.43"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "165687",
            "Clicks": "2582",
            "Conversions": "71",
            "Conversion Value": "$7,923",
            "Cost per Result": "$27.18"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "360111",
            "Clicks": "6478",
            "Conversions": "55",
            "Conversion Value": "$4,800",
            "Cost per Result": "$27.26"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "266585",
            "Clicks": "2076",
            "Conversions": "41",
            "Conversion Value": "$10,178",
            "Cost per Result": "$64.91"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "173388",
            "Clicks": "3362",
            "Conversions": "69",
            "Conversion Value": "$8,487",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "222307",
            "Clicks": "6714",
            "Conversions": "80",
            "Conversion Value": "$11,976",
            "Cost per Result": "$46.63"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "338531",
            "Clicks": "5885",
            "Conversions": "79",
            "Conversion Value": "$5,500",
            "Cost per Result": "$52.55"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "306434",
            "Clicks": "4212",
            "Conversions": "42",
            "Conversion Value": "$14,485",
            "Cost per Result": "$43.29"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "395078",
            "Clicks": "5346",
            "Conversions": "45",
            "Conversion Value": "$9,954",
            "Cost per Result": "$58.69"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "185258",
            "Clicks": "3811",
            "Conversions": "77",
            "Conversion Value": "$7,797",
            "Cost per Result": "$83.97"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "183822",
            "Clicks": "4736",
            "Conversions": "90",
            "Conversion Value": "$15,025",
            "Cost per Result": "$50.08"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "113899",
            "Clicks": "2848",
            "Conversions": "65",
            "Conversion Value": "$15,192",
            "Cost per Result": "$34.91"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "461089",
            "Clicks": "4090",
            "Conversions": "52",
            "Conversion Value": "$14,814",
            "Cost per Result": "$64.01"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "282282",
            "Clicks": "6884",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$22.57"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "301894",
            "Clicks": "5792",
            "Conversions": "55",
            "Conversion Value": "$7,837",
            "Cost per Result": "$67.83"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "213428",
            "Clicks": "3324",
            "Conversions": "80",
            "Conversion Value": "$11,054",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "295535",
            "Clicks": "6907",
            "Conversions": "56",
            "Conversion Value": "$6,241",
            "Cost per Result": "$31.39"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "480036",
            "Clicks": "4602",
            "Conversions": "52",
            "Conversion Value": "$5,443",
            "Cost per Result": "$44.51"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "152661",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$5,922",
            "Cost per Result": "$64.62"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "308410",
            "Clicks": "2485",
            "Conversions": "90",
            "Conversion Value": "$7,448",
            "Cost per Result": "$48.23"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "126277",
            "Clicks": "6422",
            "Conversions": "69",
            "Conversion Value": "$4,574",
            "Cost per Result": "$52.89"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "383491",
            "Clicks": "6784",
            "Conversions": "65",
            "Conversion Value": "$14,865",
            "Cost per Result": "$42.38"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "305477",
            "Clicks": "6930",
            "Conversions": "90",
            "Conversion Value": "$12,898",
            "Cost per Result": "$43.55"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "188039",
            "Clicks": "4281",
            "Conversions": "76",
            "Conversion Value": "$15,614",
            "Cost per Result": "$36.92"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "256397",
            "Clicks": "6774",
            "Conversions": "49",
            "Conversion Value": "$4,758",
            "Cost per Result": "$60.18"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "167637",
            "Clicks": "6035",
            "Conversions": "78",
            "Conversion Value": "$4,237",
            "Cost per Result": "$40.34"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "427427",
            "Clicks": "5580",
            "Conversions": "78",
            "Conversion Value": "$4,461",
            "Cost per Result": "$73.86"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "106572",
            "Clicks": "2507",
            "Conversions": "57",
            "Conversion Value": "$14,726",
            "Cost per Result": "$39.01"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "353946",
            "Clicks": "6443",
            "Conversions": "41",
            "Conversion Value": "$15,482",
            "Cost per Result": "$40.02"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "154964",
            "Clicks": "2133",
            "Conversions": "74",
            "Conversion Value": "$6,758",
            "Cost per Result": "$30.30"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "221931",
            "Clicks": "6457",
            "Conversions": "53",
            "Conversion Value": "$4,137",
            "Cost per Result": "$20.00"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "122829",
            "Clicks": "3778",
            "Conversions": "74",
            "Conversion Value": "$12,165",
            "Cost per Result": "$20.85"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "190920",
            "Clicks": "2000",
            "Conversions": "81",
            "Conversion Value": "$7,827",
            "Cost per Result": "$65.04"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "141579",
            "Clicks": "4884",
            "Conversions": "65",
            "Conversion Value": "$6,009",
            "Cost per Result": "$71.12"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "127663",
            "Clicks": "6586",
            "Conversions": "87",
            "Conversion Value": "$5,498",
            "Cost per Result": "$60.72"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "308489",
            "Clicks": "3371",
            "Conversions": "59",
            "Conversion Value": "$5,902",
            "Cost per Result": "$35.62"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "136184",
            "Clicks": "2950",
            "Conversions": "65",
            "Conversion Value": "$5,940",
            "Cost per Result": "$62.08"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "226835",
            "Clicks": "2383",
            "Conversions": "58",
            "Conversion Value": "$11,323",
            "Cost per Result": "$65.20"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "442906",
            "Clicks": "6480",
            "Conversions": "49",
            "Conversion Value": "$10,110",
            "Cost per Result": "$31.10"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "316950",
            "Clicks": "5686",
            "Conversions": "76",
            "Conversion Value": "$4,373",
            "Cost per Result": "$79.30"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "343440",
            "Clicks": "4429",
            "Conversions": "52",
            "Conversion Value": "$6,698",
            "Cost per Result": "$39.46"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "315753",
            "Clicks": "4847",
            "Conversions": "63",
            "Conversion Value": "$8,903",
            "Cost per Result": "$81.06"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "257999",
            "Clicks": "4865",
            "Conversions": "61",
            "Conversion Value": "$6,459",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "350217",
            "Clicks": "4640",
            "Conversions": "89",
            "Conversion Value": "$9,495",
            "Cost per Result": "$29.29"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "474133",
            "Clicks": "6964",
            "Conversions": "77",
            "Conversion Value": "$11,829",
            "Cost per Result": "$28.70"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "440496",
            "Clicks": "4429",
            "Conversions": "66",
            "Conversion Value": "$10,856",
            "Cost per Result": "$60.07"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "358663",
            "Clicks": "3662",
            "Conversions": "66",
            "Conversion Value": "$10,891",
            "Cost per Result": "$38.92"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "469477",
            "Clicks": "2537",
            "Conversions": "65",
            "Conversion Value": "$13,691",
            "Cost per Result": "$38.08"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "156529",
            "Clicks": "5658",
            "Conversions": "69",
            "Conversion Value": "$10,138",
            "Cost per Result": "$75.70"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "186598",
            "Clicks": "3736",
            "Conversions": "82",
            "Conversion Value": "$9,386",
            "Cost per Result": "$32.55"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "305503",
            "Clicks": "4040",
            "Conversions": "54",
            "Conversion Value": "$7,375",
            "Cost per Result": "$24.96"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "265731",
            "Clicks": "3552",
            "Conversions": "66",
            "Conversion Value": "$13,833",
            "Cost per Result": "$48.39"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "206038",
            "Clicks": "5150",
            "Conversions": "87",
            "Conversion Value": "$11,712",
            "Cost per Result": "$81.04"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "411616",
            "Clicks": "5065",
            "Conversions": "69",
            "Conversion Value": "$13,456",
            "Cost per Result": "$55.81"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "400132",
            "Clicks": "5626",
            "Conversions": "53",
            "Conversion Value": "$6,460",
            "Cost per Result": "$62.00"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "140071",
            "Clicks": "5239",
            "Conversions": "44",
            "Conversion Value": "$7,931",
            "Cost per Result": "$64.46"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "318151",
            "Clicks": "6448",
            "Conversions": "89",
            "Conversion Value": "$9,954",
            "Cost per Result": "$63.59"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "108293",
            "Clicks": "3758",
            "Conversions": "42",
            "Conversion Value": "$12,015",
            "Cost per Result": "$61.41"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "215882",
            "Clicks": "5418",
            "Conversions": "43",
            "Conversion Value": "$7,239",
            "Cost per Result": "$72.04"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "438852",
            "Clicks": "3198",
            "Conversions": "56",
            "Conversion Value": "$10,250",
            "Cost per Result": "$55.64"
          }
        ]
      },
      "TokenTradersData": {
        "name": "Token Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "474292",
            "Clicks": "4961",
            "Conversions": "66",
            "Conversion Value": "$5,756",
            "Cost per Result": "$61.82"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490382",
            "Clicks": "6151",
            "Conversions": "62",
            "Conversion Value": "$5,029",
            "Cost per Result": "$84.40"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "284588",
            "Clicks": "3405",
            "Conversions": "56",
            "Conversion Value": "$10,994",
            "Cost per Result": "$55.83"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "271991",
            "Clicks": "2993",
            "Conversions": "74",
            "Conversion Value": "$11,778",
            "Cost per Result": "$24.61"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "289197",
            "Clicks": "5208",
            "Conversions": "79",
            "Conversion Value": "$12,697",
            "Cost per Result": "$71.78"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "152592",
            "Clicks": "2914",
            "Conversions": "42",
            "Conversion Value": "$10,730",
            "Cost per Result": "$34.51"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "189316",
            "Clicks": "2338",
            "Conversions": "50",
            "Conversion Value": "$13,745",
            "Cost per Result": "$53.37"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "488025",
            "Clicks": "3288",
            "Conversions": "80",
            "Conversion Value": "$12,914",
            "Cost per Result": "$81.96"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "112648",
            "Clicks": "2320",
            "Conversions": "46",
            "Conversion Value": "$8,534",
            "Cost per Result": "$56.47"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "189846",
            "Clicks": "4643",
            "Conversions": "90",
            "Conversion Value": "$15,730",
            "Cost per Result": "$67.79"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "111422",
            "Clicks": "3623",
            "Conversions": "51",
            "Conversion Value": "$11,623",
            "Cost per Result": "$30.41"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "258550",
            "Clicks": "6423",
            "Conversions": "63",
            "Conversion Value": "$13,905",
            "Cost per Result": "$62.55"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "154569",
            "Clicks": "3348",
            "Conversions": "71",
            "Conversion Value": "$15,954",
            "Cost per Result": "$31.27"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "340583",
            "Clicks": "3756",
            "Conversions": "51",
            "Conversion Value": "$11,458",
            "Cost per Result": "$56.97"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "164444",
            "Clicks": "4302",
            "Conversions": "55",
            "Conversion Value": "$5,720",
            "Cost per Result": "$21.89"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "291301",
            "Clicks": "5677",
            "Conversions": "73",
            "Conversion Value": "$10,645",
            "Cost per Result": "$51.77"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "248508",
            "Clicks": "6002",
            "Conversions": "57",
            "Conversion Value": "$15,921",
            "Cost per Result": "$80.73"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "178834",
            "Clicks": "3541",
            "Conversions": "55",
            "Conversion Value": "$14,055",
            "Cost per Result": "$40.45"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "359124",
            "Clicks": "4636",
            "Conversions": "64",
            "Conversion Value": "$8,901",
            "Cost per Result": "$60.62"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "116847",
            "Clicks": "4910",
            "Conversions": "46",
            "Conversion Value": "$5,478",
            "Cost per Result": "$75.83"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "122722",
            "Clicks": "2091",
            "Conversions": "43",
            "Conversion Value": "$12,520",
            "Cost per Result": "$22.71"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "337959",
            "Clicks": "4515",
            "Conversions": "90",
            "Conversion Value": "$6,759",
            "Cost per Result": "$47.18"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "436789",
            "Clicks": "6725",
            "Conversions": "61",
            "Conversion Value": "$12,044",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "416204",
            "Clicks": "3011",
            "Conversions": "74",
            "Conversion Value": "$12,791",
            "Cost per Result": "$70.88"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "244594",
            "Clicks": "3474",
            "Conversions": "52",
            "Conversion Value": "$9,216",
            "Cost per Result": "$61.84"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "449480",
            "Clicks": "2165",
            "Conversions": "58",
            "Conversion Value": "$8,851",
            "Cost per Result": "$24.23"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "249571",
            "Clicks": "6715",
            "Conversions": "82",
            "Conversion Value": "$11,960",
            "Cost per Result": "$56.65"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "285923",
            "Clicks": "6913",
            "Conversions": "42",
            "Conversion Value": "$5,604",
            "Cost per Result": "$69.90"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "291364",
            "Clicks": "4272",
            "Conversions": "47",
            "Conversion Value": "$9,607",
            "Cost per Result": "$79.74"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "417304",
            "Clicks": "5873",
            "Conversions": "80",
            "Conversion Value": "$6,051",
            "Cost per Result": "$24.08"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "122692",
            "Clicks": "4398",
            "Conversions": "82",
            "Conversion Value": "$10,030",
            "Cost per Result": "$30.94"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "294768",
            "Clicks": "4608",
            "Conversions": "72",
            "Conversion Value": "$14,311",
            "Cost per Result": "$79.26"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "130244",
            "Clicks": "5932",
            "Conversions": "81",
            "Conversion Value": "$15,114",
            "Cost per Result": "$41.35"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "405792",
            "Clicks": "5129",
            "Conversions": "81",
            "Conversion Value": "$13,942",
            "Cost per Result": "$70.84"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "445350",
            "Clicks": "5353",
            "Conversions": "46",
            "Conversion Value": "$4,360",
            "Cost per Result": "$23.18"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "175447",
            "Clicks": "5044",
            "Conversions": "90",
            "Conversion Value": "$13,292",
            "Cost per Result": "$74.06"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "483573",
            "Clicks": "2751",
            "Conversions": "46",
            "Conversion Value": "$15,238",
            "Cost per Result": "$62.30"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "240875",
            "Clicks": "4942",
            "Conversions": "72",
            "Conversion Value": "$13,590",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "210583",
            "Clicks": "2819",
            "Conversions": "73",
            "Conversion Value": "$5,894",
            "Cost per Result": "$73.30"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "346907",
            "Clicks": "4215",
            "Conversions": "65",
            "Conversion Value": "$4,685",
            "Cost per Result": "$62.40"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "409717",
            "Clicks": "4896",
            "Conversions": "65",
            "Conversion Value": "$9,125",
            "Cost per Result": "$24.02"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "406588",
            "Clicks": "2670",
            "Conversions": "82",
            "Conversion Value": "$8,915",
            "Cost per Result": "$28.34"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "160826",
            "Clicks": "4439",
            "Conversions": "73",
            "Conversion Value": "$10,472",
            "Cost per Result": "$56.62"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "377095",
            "Clicks": "6242",
            "Conversions": "73",
            "Conversion Value": "$10,925",
            "Cost per Result": "$73.16"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "348052",
            "Clicks": "3542",
            "Conversions": "55",
            "Conversion Value": "$4,130",
            "Cost per Result": "$40.05"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "465122",
            "Clicks": "3386",
            "Conversions": "49",
            "Conversion Value": "$4,720",
            "Cost per Result": "$54.89"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "394664",
            "Clicks": "2609",
            "Conversions": "66",
            "Conversion Value": "$14,227",
            "Cost per Result": "$31.42"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "173084",
            "Clicks": "4734",
            "Conversions": "79",
            "Conversion Value": "$14,915",
            "Cost per Result": "$81.14"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "356366",
            "Clicks": "3702",
            "Conversions": "43",
            "Conversion Value": "$7,922",
            "Cost per Result": "$83.36"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "136674",
            "Clicks": "5286",
            "Conversions": "63",
            "Conversion Value": "$8,440",
            "Cost per Result": "$34.43"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "351663",
            "Clicks": "6636",
            "Conversions": "77",
            "Conversion Value": "$4,959",
            "Cost per Result": "$61.74"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "324864",
            "Clicks": "3296",
            "Conversions": "67",
            "Conversion Value": "$14,830",
            "Cost per Result": "$35.71"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "111676",
            "Clicks": "4522",
            "Conversions": "59",
            "Conversion Value": "$13,371",
            "Cost per Result": "$81.79"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "246104",
            "Clicks": "5572",
            "Conversions": "70",
            "Conversion Value": "$5,729",
            "Cost per Result": "$63.37"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "215955",
            "Clicks": "5116",
            "Conversions": "67",
            "Conversion Value": "$9,326",
            "Cost per Result": "$45.89"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "231112",
            "Clicks": "4573",
            "Conversions": "76",
            "Conversion Value": "$13,546",
            "Cost per Result": "$45.88"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "250489",
            "Clicks": "4297",
            "Conversions": "87",
            "Conversion Value": "$14,080",
            "Cost per Result": "$50.68"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "476579",
            "Clicks": "5714",
            "Conversions": "54",
            "Conversion Value": "$5,981",
            "Cost per Result": "$25.20"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "165589",
            "Clicks": "2967",
            "Conversions": "62",
            "Conversion Value": "$4,654",
            "Cost per Result": "$29.66"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "399658",
            "Clicks": "4851",
            "Conversions": "41",
            "Conversion Value": "$8,529",
            "Cost per Result": "$70.00"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "492103",
            "Clicks": "3230",
            "Conversions": "55",
            "Conversion Value": "$14,847",
            "Cost per Result": "$53.76"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "366123",
            "Clicks": "2971",
            "Conversions": "56",
            "Conversion Value": "$14,624",
            "Cost per Result": "$24.16"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "343499",
            "Clicks": "4286",
            "Conversions": "89",
            "Conversion Value": "$15,153",
            "Cost per Result": "$45.20"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "242348",
            "Clicks": "5890",
            "Conversions": "41",
            "Conversion Value": "$10,822",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "318671",
            "Clicks": "6006",
            "Conversions": "89",
            "Conversion Value": "$7,707",
            "Cost per Result": "$81.11"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "441164",
            "Clicks": "2604",
            "Conversions": "83",
            "Conversion Value": "$4,753",
            "Cost per Result": "$74.80"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "337242",
            "Clicks": "4826",
            "Conversions": "59",
            "Conversion Value": "$13,016",
            "Cost per Result": "$45.75"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "373442",
            "Clicks": "2257",
            "Conversions": "81",
            "Conversion Value": "$5,316",
            "Cost per Result": "$41.21"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "378284",
            "Clicks": "3576",
            "Conversions": "47",
            "Conversion Value": "$6,438",
            "Cost per Result": "$45.44"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "201888",
            "Clicks": "2233",
            "Conversions": "45",
            "Conversion Value": "$7,232",
            "Cost per Result": "$32.66"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "328534",
            "Clicks": "6173",
            "Conversions": "60",
            "Conversion Value": "$11,611",
            "Cost per Result": "$63.46"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "472640",
            "Clicks": "6224",
            "Conversions": "62",
            "Conversion Value": "$13,073",
            "Cost per Result": "$55.75"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "432418",
            "Clicks": "4176",
            "Conversions": "65",
            "Conversion Value": "$14,440",
            "Cost per Result": "$25.42"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "457419",
            "Clicks": "6421",
            "Conversions": "58",
            "Conversion Value": "$15,786",
            "Cost per Result": "$82.30"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "109878",
            "Clicks": "2875",
            "Conversions": "57",
            "Conversion Value": "$6,428",
            "Cost per Result": "$57.15"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "188292",
            "Clicks": "2919",
            "Conversions": "43",
            "Conversion Value": "$9,741",
            "Cost per Result": "$78.92"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "186691",
            "Clicks": "2460",
            "Conversions": "64",
            "Conversion Value": "$10,944",
            "Cost per Result": "$42.91"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "454521",
            "Clicks": "5631",
            "Conversions": "48",
            "Conversion Value": "$11,957",
            "Cost per Result": "$25.62"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "464462",
            "Clicks": "3659",
            "Conversions": "58",
            "Conversion Value": "$9,916",
            "Cost per Result": "$54.23"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "133300",
            "Clicks": "6272",
            "Conversions": "74",
            "Conversion Value": "$12,452",
            "Cost per Result": "$62.80"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "323918",
            "Clicks": "2851",
            "Conversions": "53",
            "Conversion Value": "$6,288",
            "Cost per Result": "$38.88"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "151755",
            "Clicks": "4415",
            "Conversions": "59",
            "Conversion Value": "$13,966",
            "Cost per Result": "$68.48"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "366794",
            "Clicks": "3559",
            "Conversions": "64",
            "Conversion Value": "$10,564",
            "Cost per Result": "$30.83"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "365639",
            "Clicks": "6006",
            "Conversions": "86",
            "Conversion Value": "$10,621",
            "Cost per Result": "$40.25"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "101058",
            "Clicks": "3608",
            "Conversions": "68",
            "Conversion Value": "$10,724",
            "Cost per Result": "$20.92"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "461708",
            "Clicks": "3814",
            "Conversions": "42",
            "Conversion Value": "$11,558",
            "Cost per Result": "$77.43"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "285404",
            "Clicks": "5261",
            "Conversions": "56",
            "Conversion Value": "$4,770",
            "Cost per Result": "$67.11"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "447450",
            "Clicks": "2811",
            "Conversions": "71",
            "Conversion Value": "$8,128",
            "Cost per Result": "$60.33"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "176720",
            "Clicks": "6902",
            "Conversions": "47",
            "Conversion Value": "$6,831",
            "Cost per Result": "$63.60"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "117101",
            "Clicks": "2709",
            "Conversions": "66",
            "Conversion Value": "$13,897",
            "Cost per Result": "$21.39"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "155230",
            "Clicks": "5215",
            "Conversions": "41",
            "Conversion Value": "$6,056",
            "Cost per Result": "$44.44"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "241024",
            "Clicks": "2648",
            "Conversions": "43",
            "Conversion Value": "$9,197",
            "Cost per Result": "$40.64"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "103453",
            "Clicks": "2157",
            "Conversions": "69",
            "Conversion Value": "$5,050",
            "Cost per Result": "$81.41"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "212329",
            "Clicks": "2069",
            "Conversions": "83",
            "Conversion Value": "$9,311",
            "Cost per Result": "$70.07"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "336177",
            "Clicks": "3090",
            "Conversions": "44",
            "Conversion Value": "$6,079",
            "Cost per Result": "$44.92"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "427024",
            "Clicks": "2026",
            "Conversions": "55",
            "Conversion Value": "$10,049",
            "Cost per Result": "$70.12"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "349157",
            "Clicks": "5259",
            "Conversions": "50",
            "Conversion Value": "$7,217",
            "Cost per Result": "$62.75"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "430133",
            "Clicks": "4842",
            "Conversions": "83",
            "Conversion Value": "$10,453",
            "Cost per Result": "$65.99"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "285617",
            "Clicks": "6983",
            "Conversions": "66",
            "Conversion Value": "$5,696",
            "Cost per Result": "$63.33"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "319688",
            "Clicks": "4021",
            "Conversions": "80",
            "Conversion Value": "$5,026",
            "Cost per Result": "$84.98"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "404499",
            "Clicks": "4766",
            "Conversions": "75",
            "Conversion Value": "$8,149",
            "Cost per Result": "$22.01"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "483856",
            "Clicks": "2524",
            "Conversions": "75",
            "Conversion Value": "$11,918",
            "Cost per Result": "$72.74"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "346490",
            "Clicks": "6343",
            "Conversions": "78",
            "Conversion Value": "$7,951",
            "Cost per Result": "$75.60"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "305757",
            "Clicks": "4741",
            "Conversions": "67",
            "Conversion Value": "$15,373",
            "Cost per Result": "$59.24"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "288315",
            "Clicks": "3163",
            "Conversions": "60",
            "Conversion Value": "$10,487",
            "Cost per Result": "$41.00"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "388921",
            "Clicks": "2299",
            "Conversions": "90",
            "Conversion Value": "$11,876",
            "Cost per Result": "$59.13"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "188923",
            "Clicks": "5014",
            "Conversions": "81",
            "Conversion Value": "$12,417",
            "Cost per Result": "$83.55"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "348286",
            "Clicks": "6914",
            "Conversions": "69",
            "Conversion Value": "$9,234",
            "Cost per Result": "$33.57"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "180553",
            "Clicks": "6297",
            "Conversions": "43",
            "Conversion Value": "$12,908",
            "Cost per Result": "$74.80"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "222842",
            "Clicks": "5978",
            "Conversions": "75",
            "Conversion Value": "$8,507",
            "Cost per Result": "$52.95"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "242172",
            "Clicks": "2891",
            "Conversions": "59",
            "Conversion Value": "$7,709",
            "Cost per Result": "$63.98"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "473469",
            "Clicks": "6307",
            "Conversions": "46",
            "Conversion Value": "$10,689",
            "Cost per Result": "$82.23"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "392377",
            "Clicks": "2596",
            "Conversions": "82",
            "Conversion Value": "$8,676",
            "Cost per Result": "$71.75"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "441346",
            "Clicks": "3891",
            "Conversions": "40",
            "Conversion Value": "$12,279",
            "Cost per Result": "$67.71"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "314974",
            "Clicks": "6671",
            "Conversions": "70",
            "Conversion Value": "$14,267",
            "Cost per Result": "$36.76"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "398065",
            "Clicks": "2688",
            "Conversions": "87",
            "Conversion Value": "$13,732",
            "Cost per Result": "$48.33"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "225657",
            "Clicks": "5346",
            "Conversions": "42",
            "Conversion Value": "$8,251",
            "Cost per Result": "$21.25"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "388822",
            "Clicks": "3323",
            "Conversions": "79",
            "Conversion Value": "$4,104",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "126261",
            "Clicks": "2854",
            "Conversions": "77",
            "Conversion Value": "$10,650",
            "Cost per Result": "$80.31"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "256950",
            "Clicks": "5161",
            "Conversions": "77",
            "Conversion Value": "$8,137",
            "Cost per Result": "$31.64"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "279128",
            "Clicks": "6299",
            "Conversions": "54",
            "Conversion Value": "$12,197",
            "Cost per Result": "$48.10"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "174557",
            "Clicks": "2005",
            "Conversions": "48",
            "Conversion Value": "$5,414",
            "Cost per Result": "$83.86"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "454501",
            "Clicks": "4722",
            "Conversions": "44",
            "Conversion Value": "$7,636",
            "Cost per Result": "$39.17"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "354793",
            "Clicks": "6749",
            "Conversions": "68",
            "Conversion Value": "$4,005",
            "Cost per Result": "$55.84"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "426068",
            "Clicks": "2160",
            "Conversions": "66",
            "Conversion Value": "$9,156",
            "Cost per Result": "$81.31"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "381919",
            "Clicks": "2723",
            "Conversions": "87",
            "Conversion Value": "$12,918",
            "Cost per Result": "$23.78"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "262738",
            "Clicks": "4207",
            "Conversions": "57",
            "Conversion Value": "$7,287",
            "Cost per Result": "$58.08"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "192775",
            "Clicks": "5299",
            "Conversions": "54",
            "Conversion Value": "$6,520",
            "Cost per Result": "$44.49"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "218466",
            "Clicks": "2032",
            "Conversions": "83",
            "Conversion Value": "$13,979",
            "Cost per Result": "$60.79"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "335810",
            "Clicks": "5075",
            "Conversions": "82",
            "Conversion Value": "$12,572",
            "Cost per Result": "$77.29"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "467777",
            "Clicks": "4725",
            "Conversions": "46",
            "Conversion Value": "$6,952",
            "Cost per Result": "$23.05"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "110774",
            "Clicks": "6893",
            "Conversions": "66",
            "Conversion Value": "$10,092",
            "Cost per Result": "$30.42"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "265182",
            "Clicks": "3732",
            "Conversions": "40",
            "Conversion Value": "$13,283",
            "Cost per Result": "$34.90"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "474488",
            "Clicks": "5007",
            "Conversions": "52",
            "Conversion Value": "$15,803",
            "Cost per Result": "$79.06"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "195746",
            "Clicks": "6816",
            "Conversions": "73",
            "Conversion Value": "$8,808",
            "Cost per Result": "$65.37"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "337336",
            "Clicks": "4167",
            "Conversions": "43",
            "Conversion Value": "$11,539",
            "Cost per Result": "$27.99"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "186702",
            "Clicks": "5010",
            "Conversions": "67",
            "Conversion Value": "$13,856",
            "Cost per Result": "$47.25"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "154983",
            "Clicks": "6819",
            "Conversions": "86",
            "Conversion Value": "$4,453",
            "Cost per Result": "$71.31"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "402618",
            "Clicks": "5098",
            "Conversions": "89",
            "Conversion Value": "$14,082",
            "Cost per Result": "$80.54"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "454241",
            "Clicks": "5184",
            "Conversions": "53",
            "Conversion Value": "$13,341",
            "Cost per Result": "$56.42"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "266677",
            "Clicks": "2092",
            "Conversions": "85",
            "Conversion Value": "$5,235",
            "Cost per Result": "$71.39"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "167093",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$9,270",
            "Cost per Result": "$75.09"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "237094",
            "Clicks": "2464",
            "Conversions": "54",
            "Conversion Value": "$13,483",
            "Cost per Result": "$50.07"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "459425",
            "Clicks": "3731",
            "Conversions": "52",
            "Conversion Value": "$12,241",
            "Cost per Result": "$34.68"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "300483",
            "Clicks": "2630",
            "Conversions": "50",
            "Conversion Value": "$15,678",
            "Cost per Result": "$37.78"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "139106",
            "Clicks": "4069",
            "Conversions": "84",
            "Conversion Value": "$7,894",
            "Cost per Result": "$75.16"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "284587",
            "Clicks": "5575",
            "Conversions": "51",
            "Conversion Value": "$7,678",
            "Cost per Result": "$70.60"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "351449",
            "Clicks": "4223",
            "Conversions": "72",
            "Conversion Value": "$9,587",
            "Cost per Result": "$55.08"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "383663",
            "Clicks": "3101",
            "Conversions": "84",
            "Conversion Value": "$12,403",
            "Cost per Result": "$52.76"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "489144",
            "Clicks": "5451",
            "Conversions": "62",
            "Conversion Value": "$12,389",
            "Cost per Result": "$56.13"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "254856",
            "Clicks": "4747",
            "Conversions": "64",
            "Conversion Value": "$7,759",
            "Cost per Result": "$55.46"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "460681",
            "Clicks": "3726",
            "Conversions": "54",
            "Conversion Value": "$5,814",
            "Cost per Result": "$47.79"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "488134",
            "Clicks": "6953",
            "Conversions": "54",
            "Conversion Value": "$8,637",
            "Cost per Result": "$65.35"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "209174",
            "Clicks": "6266",
            "Conversions": "46",
            "Conversion Value": "$7,290",
            "Cost per Result": "$46.50"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "362195",
            "Clicks": "6041",
            "Conversions": "89",
            "Conversion Value": "$15,711",
            "Cost per Result": "$46.47"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "416797",
            "Clicks": "4934",
            "Conversions": "52",
            "Conversion Value": "$4,328",
            "Cost per Result": "$52.88"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "241673",
            "Clicks": "4266",
            "Conversions": "78",
            "Conversion Value": "$15,389",
            "Cost per Result": "$68.47"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "364490",
            "Clicks": "4625",
            "Conversions": "83",
            "Conversion Value": "$8,942",
            "Cost per Result": "$78.00"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "395880",
            "Clicks": "3894",
            "Conversions": "58",
            "Conversion Value": "$12,777",
            "Cost per Result": "$34.05"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "269188",
            "Clicks": "2341",
            "Conversions": "56",
            "Conversion Value": "$9,805",
            "Cost per Result": "$29.55"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "156243",
            "Clicks": "6557",
            "Conversions": "45",
            "Conversion Value": "$7,290",
            "Cost per Result": "$57.51"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "349609",
            "Clicks": "5910",
            "Conversions": "62",
            "Conversion Value": "$9,584",
            "Cost per Result": "$74.47"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "289925",
            "Clicks": "3958",
            "Conversions": "44",
            "Conversion Value": "$13,395",
            "Cost per Result": "$21.97"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "466252",
            "Clicks": "4268",
            "Conversions": "77",
            "Conversion Value": "$6,528",
            "Cost per Result": "$64.30"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "243298",
            "Clicks": "6368",
            "Conversions": "83",
            "Conversion Value": "$10,998",
            "Cost per Result": "$40.40"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "462305",
            "Clicks": "3520",
            "Conversions": "82",
            "Conversion Value": "$9,213",
            "Cost per Result": "$77.95"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "475027",
            "Clicks": "2911",
            "Conversions": "72",
            "Conversion Value": "$10,035",
            "Cost per Result": "$28.79"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151576",
            "Clicks": "5831",
            "Conversions": "83",
            "Conversion Value": "$15,904",
            "Cost per Result": "$60.69"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "309305",
            "Clicks": "5700",
            "Conversions": "90",
            "Conversion Value": "$11,766",
            "Cost per Result": "$44.56"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "240634",
            "Clicks": "5567",
            "Conversions": "62",
            "Conversion Value": "$8,672",
            "Cost per Result": "$79.39"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "231883",
            "Clicks": "4855",
            "Conversions": "89",
            "Conversion Value": "$15,784",
            "Cost per Result": "$81.36"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "349426",
            "Clicks": "2426",
            "Conversions": "41",
            "Conversion Value": "$6,643",
            "Cost per Result": "$43.14"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "242278",
            "Clicks": "4944",
            "Conversions": "79",
            "Conversion Value": "$12,778",
            "Cost per Result": "$53.23"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "453551",
            "Clicks": "2893",
            "Conversions": "89",
            "Conversion Value": "$10,731",
            "Cost per Result": "$77.53"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "463941",
            "Clicks": "2607",
            "Conversions": "65",
            "Conversion Value": "$14,951",
            "Cost per Result": "$76.87"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "488898",
            "Clicks": "4830",
            "Conversions": "49",
            "Conversion Value": "$10,371",
            "Cost per Result": "$26.21"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "280212",
            "Clicks": "4934",
            "Conversions": "85",
            "Conversion Value": "$4,627",
            "Cost per Result": "$59.77"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "443912",
            "Clicks": "3222",
            "Conversions": "69",
            "Conversion Value": "$8,095",
            "Cost per Result": "$32.13"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "168894",
            "Clicks": "6604",
            "Conversions": "59",
            "Conversion Value": "$5,221",
            "Cost per Result": "$33.74"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "155600",
            "Clicks": "5508",
            "Conversions": "54",
            "Conversion Value": "$11,499",
            "Cost per Result": "$48.04"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "194866",
            "Clicks": "3893",
            "Conversions": "64",
            "Conversion Value": "$5,091",
            "Cost per Result": "$46.20"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "493351",
            "Clicks": "5134",
            "Conversions": "53",
            "Conversion Value": "$12,366",
            "Cost per Result": "$71.81"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "212168",
            "Clicks": "4364",
            "Conversions": "58",
            "Conversion Value": "$6,036",
            "Cost per Result": "$52.79"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "161617",
            "Clicks": "6362",
            "Conversions": "49",
            "Conversion Value": "$9,258",
            "Cost per Result": "$73.62"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "129442",
            "Clicks": "3593",
            "Conversions": "81",
            "Conversion Value": "$7,901",
            "Cost per Result": "$33.46"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "211732",
            "Clicks": "4062",
            "Conversions": "40",
            "Conversion Value": "$11,868",
            "Cost per Result": "$21.84"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "191849",
            "Clicks": "4463",
            "Conversions": "54",
            "Conversion Value": "$8,932",
            "Cost per Result": "$39.32"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "389009",
            "Clicks": "5686",
            "Conversions": "53",
            "Conversion Value": "$7,603",
            "Cost per Result": "$68.85"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "496275",
            "Clicks": "4756",
            "Conversions": "75",
            "Conversion Value": "$13,705",
            "Cost per Result": "$54.06"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "249578",
            "Clicks": "5483",
            "Conversions": "48",
            "Conversion Value": "$11,112",
            "Cost per Result": "$79.50"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "233443",
            "Clicks": "5751",
            "Conversions": "69",
            "Conversion Value": "$8,438",
            "Cost per Result": "$73.54"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "389027",
            "Clicks": "6782",
            "Conversions": "69",
            "Conversion Value": "$11,478",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "270718",
            "Clicks": "5811",
            "Conversions": "49",
            "Conversion Value": "$10,728",
            "Cost per Result": "$64.18"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "290076",
            "Clicks": "2985",
            "Conversions": "50",
            "Conversion Value": "$4,308",
            "Cost per Result": "$37.31"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "264119",
            "Clicks": "5098",
            "Conversions": "67",
            "Conversion Value": "$8,942",
            "Cost per Result": "$75.72"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "408702",
            "Clicks": "2309",
            "Conversions": "74",
            "Conversion Value": "$15,530",
            "Cost per Result": "$81.80"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "210015",
            "Clicks": "6445",
            "Conversions": "44",
            "Conversion Value": "$14,881",
            "Cost per Result": "$55.78"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "497972",
            "Clicks": "5990",
            "Conversions": "75",
            "Conversion Value": "$9,927",
            "Cost per Result": "$64.61"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "289933",
            "Clicks": "5325",
            "Conversions": "79",
            "Conversion Value": "$9,561",
            "Cost per Result": "$24.88"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "168381",
            "Clicks": "2191",
            "Conversions": "40",
            "Conversion Value": "$15,888",
            "Cost per Result": "$81.44"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "380982",
            "Clicks": "2971",
            "Conversions": "61",
            "Conversion Value": "$13,135",
            "Cost per Result": "$36.21"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "385860",
            "Clicks": "3434",
            "Conversions": "66",
            "Conversion Value": "$14,728",
            "Cost per Result": "$58.35"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "435860",
            "Clicks": "4060",
            "Conversions": "41",
            "Conversion Value": "$5,592",
            "Cost per Result": "$29.29"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "328289",
            "Clicks": "3558",
            "Conversions": "48",
            "Conversion Value": "$4,958",
            "Cost per Result": "$72.85"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "297951",
            "Clicks": "6467",
            "Conversions": "85",
            "Conversion Value": "$7,952",
            "Cost per Result": "$66.12"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "404090",
            "Clicks": "5909",
            "Conversions": "54",
            "Conversion Value": "$7,986",
            "Cost per Result": "$42.02"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "207291",
            "Clicks": "4220",
            "Conversions": "42",
            "Conversion Value": "$11,527",
            "Cost per Result": "$34.15"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "359085",
            "Clicks": "4050",
            "Conversions": "41",
            "Conversion Value": "$11,457",
            "Cost per Result": "$75.91"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "152473",
            "Clicks": "6769",
            "Conversions": "44",
            "Conversion Value": "$14,414",
            "Cost per Result": "$39.29"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "268685",
            "Clicks": "4331",
            "Conversions": "77",
            "Conversion Value": "$5,836",
            "Cost per Result": "$29.96"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "192990",
            "Clicks": "2447",
            "Conversions": "78",
            "Conversion Value": "$6,870",
            "Cost per Result": "$67.33"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "470897",
            "Clicks": "4513",
            "Conversions": "54",
            "Conversion Value": "$7,005",
            "Cost per Result": "$44.55"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "258863",
            "Clicks": "5911",
            "Conversions": "81",
            "Conversion Value": "$15,374",
            "Cost per Result": "$81.32"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "112425",
            "Clicks": "3455",
            "Conversions": "64",
            "Conversion Value": "$7,705",
            "Cost per Result": "$44.90"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "253288",
            "Clicks": "3366",
            "Conversions": "45",
            "Conversion Value": "$12,186",
            "Cost per Result": "$26.08"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "357232",
            "Clicks": "3996",
            "Conversions": "57",
            "Conversion Value": "$8,641",
            "Cost per Result": "$63.35"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "144745",
            "Clicks": "3269",
            "Conversions": "85",
            "Conversion Value": "$5,588",
            "Cost per Result": "$72.18"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "377388",
            "Clicks": "3215",
            "Conversions": "53",
            "Conversion Value": "$11,730",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "123694",
            "Clicks": "4708",
            "Conversions": "64",
            "Conversion Value": "$6,789",
            "Cost per Result": "$53.28"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "174620",
            "Clicks": "3792",
            "Conversions": "69",
            "Conversion Value": "$7,422",
            "Cost per Result": "$38.24"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "283356",
            "Clicks": "6757",
            "Conversions": "60",
            "Conversion Value": "$5,220",
            "Cost per Result": "$26.44"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "362982",
            "Clicks": "5142",
            "Conversions": "67",
            "Conversion Value": "$15,903",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "112863",
            "Clicks": "6444",
            "Conversions": "74",
            "Conversion Value": "$9,428",
            "Cost per Result": "$24.60"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "205818",
            "Clicks": "3135",
            "Conversions": "70",
            "Conversion Value": "$15,082",
            "Cost per Result": "$42.67"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "294407",
            "Clicks": "4650",
            "Conversions": "68",
            "Conversion Value": "$4,445",
            "Cost per Result": "$63.42"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "301717",
            "Clicks": "4879",
            "Conversions": "41",
            "Conversion Value": "$6,310",
            "Cost per Result": "$56.90"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "385227",
            "Clicks": "5830",
            "Conversions": "59",
            "Conversion Value": "$7,062",
            "Cost per Result": "$33.55"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "243820",
            "Clicks": "2118",
            "Conversions": "79",
            "Conversion Value": "$11,310",
            "Cost per Result": "$24.54"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "332908",
            "Clicks": "6265",
            "Conversions": "59",
            "Conversion Value": "$10,133",
            "Cost per Result": "$63.15"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "155619",
            "Clicks": "5112",
            "Conversions": "52",
            "Conversion Value": "$7,450",
            "Cost per Result": "$78.13"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "392405",
            "Clicks": "4914",
            "Conversions": "43",
            "Conversion Value": "$6,518",
            "Cost per Result": "$84.60"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "285930",
            "Clicks": "2296",
            "Conversions": "70",
            "Conversion Value": "$5,718",
            "Cost per Result": "$58.00"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "140432",
            "Clicks": "6508",
            "Conversions": "57",
            "Conversion Value": "$6,632",
            "Cost per Result": "$23.59"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "409444",
            "Clicks": "6075",
            "Conversions": "65",
            "Conversion Value": "$6,005",
            "Cost per Result": "$50.29"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "153857",
            "Clicks": "2367",
            "Conversions": "80",
            "Conversion Value": "$7,288",
            "Cost per Result": "$70.54"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "241536",
            "Clicks": "2454",
            "Conversions": "61",
            "Conversion Value": "$12,926",
            "Cost per Result": "$51.45"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "450872",
            "Clicks": "4505",
            "Conversions": "55",
            "Conversion Value": "$11,682",
            "Cost per Result": "$72.79"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "382511",
            "Clicks": "3169",
            "Conversions": "85",
            "Conversion Value": "$14,774",
            "Cost per Result": "$41.89"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "286052",
            "Clicks": "2729",
            "Conversions": "76",
            "Conversion Value": "$11,201",
            "Cost per Result": "$53.87"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "434233",
            "Clicks": "6012",
            "Conversions": "86",
            "Conversion Value": "$12,450",
            "Cost per Result": "$29.31"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "122815",
            "Clicks": "4921",
            "Conversions": "77",
            "Conversion Value": "$5,287",
            "Cost per Result": "$29.70"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "389223",
            "Clicks": "3109",
            "Conversions": "86",
            "Conversion Value": "$13,646",
            "Cost per Result": "$57.88"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "340751",
            "Clicks": "3039",
            "Conversions": "75",
            "Conversion Value": "$11,901",
            "Cost per Result": "$52.84"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "318370",
            "Clicks": "2573",
            "Conversions": "69",
            "Conversion Value": "$15,207",
            "Cost per Result": "$80.37"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "313257",
            "Clicks": "2290",
            "Conversions": "75",
            "Conversion Value": "$14,138",
            "Cost per Result": "$71.34"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "248984",
            "Clicks": "6461",
            "Conversions": "44",
            "Conversion Value": "$5,565",
            "Cost per Result": "$57.43"
          }
        ]
      }
    },
    "infrastructure": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1280629",
            "Clicks": "12606",
            "Conversions": "258",
            "Conversion Value": "$37,066",
            "Cost per Result": "$14.10"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1360811",
            "Clicks": "19101",
            "Conversions": "234",
            "Conversion Value": "$45,025",
            "Cost per Result": "$18.29"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "1170310",
            "Clicks": "17075",
            "Conversions": "279",
            "Conversion Value": "$44,814",
            "Cost per Result": "$15.12"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "994016",
            "Clicks": "16474",
            "Conversions": "255",
            "Conversion Value": "$43,651",
            "Cost per Result": "$21.75"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1067597",
            "Clicks": "14303",
            "Conversions": "270",
            "Conversion Value": "$40,800",
            "Cost per Result": "$26.43"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1352508",
            "Clicks": "21067",
            "Conversions": "264",
            "Conversion Value": "$37,921",
            "Cost per Result": "$19.54"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "743338",
            "Clicks": "15440",
            "Conversions": "297",
            "Conversion Value": "$40,001",
            "Cost per Result": "$24.81"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1206502",
            "Clicks": "15931",
            "Conversions": "289",
            "Conversion Value": "$35,817",
            "Cost per Result": "$13.99"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1141559",
            "Clicks": "16673",
            "Conversions": "275",
            "Conversion Value": "$51,021",
            "Cost per Result": "$12.15"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "879820",
            "Clicks": "18423",
            "Conversions": "285",
            "Conversion Value": "$38,708",
            "Cost per Result": "$18.00"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1477029",
            "Clicks": "18863",
            "Conversions": "296",
            "Conversion Value": "$44,476",
            "Cost per Result": "$16.09"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1032043",
            "Clicks": "20484",
            "Conversions": "314",
            "Conversion Value": "$33,861",
            "Cost per Result": "$19.55"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "945278",
            "Clicks": "25695",
            "Conversions": "263",
            "Conversion Value": "$30,694",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "782015",
            "Clicks": "17178",
            "Conversions": "271",
            "Conversion Value": "$34,490",
            "Cost per Result": "$17.86"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1432356",
            "Clicks": "18593",
            "Conversions": "278",
            "Conversion Value": "$46,975",
            "Cost per Result": "$21.48"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "871539",
            "Clicks": "16661",
            "Conversions": "284",
            "Conversion Value": "$30,770",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1136477",
            "Clicks": "13317",
            "Conversions": "225",
            "Conversion Value": "$43,034",
            "Cost per Result": "$19.25"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "984122",
            "Clicks": "20783",
            "Conversions": "286",
            "Conversion Value": "$33,470",
            "Cost per Result": "$25.96"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1316443",
            "Clicks": "20254",
            "Conversions": "279",
            "Conversion Value": "$39,078",
            "Cost per Result": "$15.74"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1071905",
            "Clicks": "19535",
            "Conversions": "259",
            "Conversion Value": "$56,890",
            "Cost per Result": "$22.21"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1182593",
            "Clicks": "14636",
            "Conversions": "243",
            "Conversion Value": "$39,004",
            "Cost per Result": "$22.30"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1237379",
            "Clicks": "14489",
            "Conversions": "336",
            "Conversion Value": "$50,505",
            "Cost per Result": "$16.16"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1290319",
            "Clicks": "19020",
            "Conversions": "247",
            "Conversion Value": "$47,233",
            "Cost per Result": "$17.45"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1323016",
            "Clicks": "18034",
            "Conversions": "214",
            "Conversion Value": "$43,570",
            "Cost per Result": "$17.99"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1254282",
            "Clicks": "22850",
            "Conversions": "307",
            "Conversion Value": "$38,016",
            "Cost per Result": "$26.13"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1164188",
            "Clicks": "19796",
            "Conversions": "287",
            "Conversion Value": "$52,100",
            "Cost per Result": "$18.64"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "1068702",
            "Clicks": "16420",
            "Conversions": "263",
            "Conversion Value": "$27,643",
            "Cost per Result": "$16.86"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "866646",
            "Clicks": "13813",
            "Conversions": "233",
            "Conversion Value": "$47,208",
            "Cost per Result": "$23.02"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "1415512",
            "Clicks": "15981",
            "Conversions": "292",
            "Conversion Value": "$42,951",
            "Cost per Result": "$16.16"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1412118",
            "Clicks": "16338",
            "Conversions": "297",
            "Conversion Value": "$51,382",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1213600",
            "Clicks": "15087",
            "Conversions": "279",
            "Conversion Value": "$50,219",
            "Cost per Result": "$21.54"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1032201",
            "Clicks": "17298",
            "Conversions": "231",
            "Conversion Value": "$36,433",
            "Cost per Result": "$19.90"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1578532",
            "Clicks": "19876",
            "Conversions": "225",
            "Conversion Value": "$38,585",
            "Cost per Result": "$23.84"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "1504278",
            "Clicks": "13107",
            "Conversions": "282",
            "Conversion Value": "$36,878",
            "Cost per Result": "$20.30"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1518838",
            "Clicks": "22445",
            "Conversions": "298",
            "Conversion Value": "$43,320",
            "Cost per Result": "$19.33"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1521653",
            "Clicks": "15531",
            "Conversions": "225",
            "Conversion Value": "$30,177",
            "Cost per Result": "$14.11"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1612182",
            "Clicks": "15637",
            "Conversions": "282",
            "Conversion Value": "$33,796",
            "Cost per Result": "$18.59"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1206278",
            "Clicks": "18452",
            "Conversions": "297",
            "Conversion Value": "$35,621",
            "Cost per Result": "$18.88"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "1027962",
            "Clicks": "17037",
            "Conversions": "275",
            "Conversion Value": "$44,251",
            "Cost per Result": "$16.28"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "824073",
            "Clicks": "15652",
            "Conversions": "245",
            "Conversion Value": "$38,821",
            "Cost per Result": "$24.48"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1162413",
            "Clicks": "16592",
            "Conversions": "301",
            "Conversion Value": "$38,815",
            "Cost per Result": "$23.77"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1312711",
            "Clicks": "14041",
            "Conversions": "256",
            "Conversion Value": "$47,341",
            "Cost per Result": "$24.45"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1123269",
            "Clicks": "17135",
            "Conversions": "249",
            "Conversion Value": "$28,935",
            "Cost per Result": "$19.39"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1147884",
            "Clicks": "20085",
            "Conversions": "204",
            "Conversion Value": "$32,646",
            "Cost per Result": "$17.63"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1164169",
            "Clicks": "19678",
            "Conversions": "203",
            "Conversion Value": "$47,133",
            "Cost per Result": "$27.92"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "1088971",
            "Clicks": "21845",
            "Conversions": "248",
            "Conversion Value": "$38,278",
            "Cost per Result": "$24.80"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1068977",
            "Clicks": "20187",
            "Conversions": "257",
            "Conversion Value": "$34,436",
            "Cost per Result": "$16.70"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1316735",
            "Clicks": "19927",
            "Conversions": "254",
            "Conversion Value": "$41,238",
            "Cost per Result": "$19.49"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1294064",
            "Clicks": "19352",
            "Conversions": "247",
            "Conversion Value": "$37,555",
            "Cost per Result": "$18.34"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "787002",
            "Clicks": "15080",
            "Conversions": "292",
            "Conversion Value": "$30,311",
            "Cost per Result": "$19.32"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1186965",
            "Clicks": "23866",
            "Conversions": "230",
            "Conversion Value": "$31,492",
            "Cost per Result": "$23.91"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1182814",
            "Clicks": "16623",
            "Conversions": "314",
            "Conversion Value": "$38,555",
            "Cost per Result": "$21.10"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "933667",
            "Clicks": "18176",
            "Conversions": "271",
            "Conversion Value": "$49,215",
            "Cost per Result": "$19.80"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1486405",
            "Clicks": "19312",
            "Conversions": "225",
            "Conversion Value": "$43,925",
            "Cost per Result": "$22.13"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "1288721",
            "Clicks": "19718",
            "Conversions": "261",
            "Conversion Value": "$35,455",
            "Cost per Result": "$13.31"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1469143",
            "Clicks": "13549",
            "Conversions": "305",
            "Conversion Value": "$50,767",
            "Cost per Result": "$22.46"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1183466",
            "Clicks": "17681",
            "Conversions": "253",
            "Conversion Value": "$31,261",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "995105",
            "Clicks": "17925",
            "Conversions": "293",
            "Conversion Value": "$43,659",
            "Cost per Result": "$22.09"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1482494",
            "Clicks": "18756",
            "Conversions": "237",
            "Conversion Value": "$38,501",
            "Cost per Result": "$27.58"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1160246",
            "Clicks": "19064",
            "Conversions": "242",
            "Conversion Value": "$46,019",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1287898",
            "Clicks": "25430",
            "Conversions": "279",
            "Conversion Value": "$49,263",
            "Cost per Result": "$21.32"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1276658",
            "Clicks": "17754",
            "Conversions": "236",
            "Conversion Value": "$36,554",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1419992",
            "Clicks": "14883",
            "Conversions": "259",
            "Conversion Value": "$44,207",
            "Cost per Result": "$16.18"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1018379",
            "Clicks": "25353",
            "Conversions": "265",
            "Conversion Value": "$34,771",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1302182",
            "Clicks": "19871",
            "Conversions": "294",
            "Conversion Value": "$38,255",
            "Cost per Result": "$16.51"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1454018",
            "Clicks": "14908",
            "Conversions": "289",
            "Conversion Value": "$27,203",
            "Cost per Result": "$23.93"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1642205",
            "Clicks": "21654",
            "Conversions": "275",
            "Conversion Value": "$39,141",
            "Cost per Result": "$23.79"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1310351",
            "Clicks": "18548",
            "Conversions": "258",
            "Conversion Value": "$42,261",
            "Cost per Result": "$21.36"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1151970",
            "Clicks": "13201",
            "Conversions": "212",
            "Conversion Value": "$39,680",
            "Cost per Result": "$22.68"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1272438",
            "Clicks": "18626",
            "Conversions": "335",
            "Conversion Value": "$41,863",
            "Cost per Result": "$17.49"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1329300",
            "Clicks": "15588",
            "Conversions": "209",
            "Conversion Value": "$29,206",
            "Cost per Result": "$19.58"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1587734",
            "Clicks": "19691",
            "Conversions": "236",
            "Conversion Value": "$43,224",
            "Cost per Result": "$19.05"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1207154",
            "Clicks": "19285",
            "Conversions": "246",
            "Conversion Value": "$38,384",
            "Cost per Result": "$21.46"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "987005",
            "Clicks": "16542",
            "Conversions": "232",
            "Conversion Value": "$50,233",
            "Cost per Result": "$20.58"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1654842",
            "Clicks": "15256",
            "Conversions": "283",
            "Conversion Value": "$53,153",
            "Cost per Result": "$27.16"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1171111",
            "Clicks": "23378",
            "Conversions": "210",
            "Conversion Value": "$36,920",
            "Cost per Result": "$20.45"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1511466",
            "Clicks": "18638",
            "Conversions": "252",
            "Conversion Value": "$38,126",
            "Cost per Result": "$19.29"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1373072",
            "Clicks": "18756",
            "Conversions": "266",
            "Conversion Value": "$41,330",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1489874",
            "Clicks": "19164",
            "Conversions": "281",
            "Conversion Value": "$45,703",
            "Cost per Result": "$23.87"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1646826",
            "Clicks": "16436",
            "Conversions": "258",
            "Conversion Value": "$32,049",
            "Cost per Result": "$22.08"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "679860",
            "Clicks": "15535",
            "Conversions": "230",
            "Conversion Value": "$47,991",
            "Cost per Result": "$23.95"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1140693",
            "Clicks": "14922",
            "Conversions": "244",
            "Conversion Value": "$45,019",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1597757",
            "Clicks": "17798",
            "Conversions": "246",
            "Conversion Value": "$50,409",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1192438",
            "Clicks": "22411",
            "Conversions": "325",
            "Conversion Value": "$42,072",
            "Cost per Result": "$23.72"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1195949",
            "Clicks": "18483",
            "Conversions": "243",
            "Conversion Value": "$25,370",
            "Cost per Result": "$23.84"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1267004",
            "Clicks": "18804",
            "Conversions": "235",
            "Conversion Value": "$45,567",
            "Cost per Result": "$18.70"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1505925",
            "Clicks": "15295",
            "Conversions": "274",
            "Conversion Value": "$45,442",
            "Cost per Result": "$18.99"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1305036",
            "Clicks": "14313",
            "Conversions": "252",
            "Conversion Value": "$37,977",
            "Cost per Result": "$22.81"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1012036",
            "Clicks": "19645",
            "Conversions": "260",
            "Conversion Value": "$46,137",
            "Cost per Result": "$13.49"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1443253",
            "Clicks": "12821",
            "Conversions": "276",
            "Conversion Value": "$39,828",
            "Cost per Result": "$15.90"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1136053",
            "Clicks": "14665",
            "Conversions": "217",
            "Conversion Value": "$44,105",
            "Cost per Result": "$19.36"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1114218",
            "Clicks": "17981",
            "Conversions": "286",
            "Conversion Value": "$43,502",
            "Cost per Result": "$20.28"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1174720",
            "Clicks": "21225",
            "Conversions": "311",
            "Conversion Value": "$40,877",
            "Cost per Result": "$16.09"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1366014",
            "Clicks": "16641",
            "Conversions": "252",
            "Conversion Value": "$50,331",
            "Cost per Result": "$20.50"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1419458",
            "Clicks": "21948",
            "Conversions": "251",
            "Conversion Value": "$30,017",
            "Cost per Result": "$21.50"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1319484",
            "Clicks": "21123",
            "Conversions": "267",
            "Conversion Value": "$35,304",
            "Cost per Result": "$23.88"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1269223",
            "Clicks": "22366",
            "Conversions": "291",
            "Conversion Value": "$44,031",
            "Cost per Result": "$15.88"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "1384974",
            "Clicks": "13154",
            "Conversions": "282",
            "Conversion Value": "$44,837",
            "Cost per Result": "$26.69"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1319845",
            "Clicks": "19358",
            "Conversions": "267",
            "Conversion Value": "$28,496",
            "Cost per Result": "$19.42"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "1299539",
            "Clicks": "19325",
            "Conversions": "247",
            "Conversion Value": "$31,573",
            "Cost per Result": "$20.65"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "795992",
            "Clicks": "18866",
            "Conversions": "269",
            "Conversion Value": "$38,045",
            "Cost per Result": "$25.22"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1052422",
            "Clicks": "12894",
            "Conversions": "272",
            "Conversion Value": "$30,681",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1330821",
            "Clicks": "20190",
            "Conversions": "260",
            "Conversion Value": "$44,744",
            "Cost per Result": "$19.36"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "698806",
            "Clicks": "19372",
            "Conversions": "293",
            "Conversion Value": "$41,019",
            "Cost per Result": "$22.61"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1563421",
            "Clicks": "24769",
            "Conversions": "287",
            "Conversion Value": "$37,574",
            "Cost per Result": "$17.33"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1202435",
            "Clicks": "18666",
            "Conversions": "290",
            "Conversion Value": "$40,364",
            "Cost per Result": "$20.59"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1210938",
            "Clicks": "19157",
            "Conversions": "328",
            "Conversion Value": "$32,140",
            "Cost per Result": "$20.42"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1604341",
            "Clicks": "12521",
            "Conversions": "280",
            "Conversion Value": "$40,214",
            "Cost per Result": "$14.21"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1000802",
            "Clicks": "14874",
            "Conversions": "256",
            "Conversion Value": "$49,068",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "920554",
            "Clicks": "20928",
            "Conversions": "248",
            "Conversion Value": "$44,687",
            "Cost per Result": "$17.26"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1664301",
            "Clicks": "15821",
            "Conversions": "284",
            "Conversion Value": "$49,971",
            "Cost per Result": "$16.37"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1283994",
            "Clicks": "20811",
            "Conversions": "280",
            "Conversion Value": "$38,718",
            "Cost per Result": "$17.30"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1366050",
            "Clicks": "15255",
            "Conversions": "264",
            "Conversion Value": "$50,997",
            "Cost per Result": "$18.84"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1147543",
            "Clicks": "21542",
            "Conversions": "239",
            "Conversion Value": "$36,909",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1070132",
            "Clicks": "15926",
            "Conversions": "245",
            "Conversion Value": "$41,549",
            "Cost per Result": "$21.60"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1164728",
            "Clicks": "17998",
            "Conversions": "275",
            "Conversion Value": "$34,382",
            "Cost per Result": "$23.11"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "742859",
            "Clicks": "15046",
            "Conversions": "287",
            "Conversion Value": "$45,299",
            "Cost per Result": "$22.27"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "893666",
            "Clicks": "18531",
            "Conversions": "241",
            "Conversion Value": "$38,136",
            "Cost per Result": "$23.63"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "1209964",
            "Clicks": "20680",
            "Conversions": "275",
            "Conversion Value": "$30,833",
            "Cost per Result": "$20.62"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1287506",
            "Clicks": "13387",
            "Conversions": "262",
            "Conversion Value": "$35,761",
            "Cost per Result": "$22.19"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "892511",
            "Clicks": "22145",
            "Conversions": "249",
            "Conversion Value": "$35,471",
            "Cost per Result": "$19.75"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "922684",
            "Clicks": "22736",
            "Conversions": "200",
            "Conversion Value": "$44,407",
            "Cost per Result": "$18.23"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1436437",
            "Clicks": "17404",
            "Conversions": "327",
            "Conversion Value": "$42,222",
            "Cost per Result": "$17.06"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "883242",
            "Clicks": "21588",
            "Conversions": "247",
            "Conversion Value": "$26,502",
            "Cost per Result": "$26.56"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "845611",
            "Clicks": "17835",
            "Conversions": "241",
            "Conversion Value": "$31,130",
            "Cost per Result": "$19.63"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1297634",
            "Clicks": "18593",
            "Conversions": "266",
            "Conversion Value": "$45,680",
            "Cost per Result": "$21.30"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1273045",
            "Clicks": "16518",
            "Conversions": "242",
            "Conversion Value": "$34,301",
            "Cost per Result": "$23.73"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1771976",
            "Clicks": "19161",
            "Conversions": "296",
            "Conversion Value": "$28,090",
            "Cost per Result": "$23.99"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "789567",
            "Clicks": "20945",
            "Conversions": "254",
            "Conversion Value": "$46,703",
            "Cost per Result": "$16.41"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "963237",
            "Clicks": "15782",
            "Conversions": "325",
            "Conversion Value": "$40,117",
            "Cost per Result": "$23.95"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1029413",
            "Clicks": "19384",
            "Conversions": "284",
            "Conversion Value": "$39,981",
            "Cost per Result": "$21.59"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1195266",
            "Clicks": "20158",
            "Conversions": "242",
            "Conversion Value": "$34,293",
            "Cost per Result": "$17.12"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1127765",
            "Clicks": "20861",
            "Conversions": "266",
            "Conversion Value": "$45,258",
            "Cost per Result": "$19.91"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1572606",
            "Clicks": "15989",
            "Conversions": "259",
            "Conversion Value": "$43,854",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1462073",
            "Clicks": "24354",
            "Conversions": "260",
            "Conversion Value": "$38,515",
            "Cost per Result": "$20.20"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1649673",
            "Clicks": "16669",
            "Conversions": "279",
            "Conversion Value": "$31,164",
            "Cost per Result": "$20.16"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "956814",
            "Clicks": "20480",
            "Conversions": "245",
            "Conversion Value": "$45,377",
            "Cost per Result": "$24.94"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1248707",
            "Clicks": "19858",
            "Conversions": "242",
            "Conversion Value": "$36,629",
            "Cost per Result": "$27.97"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1205929",
            "Clicks": "19204",
            "Conversions": "257",
            "Conversion Value": "$33,536",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "896886",
            "Clicks": "17850",
            "Conversions": "280",
            "Conversion Value": "$56,414",
            "Cost per Result": "$17.92"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1028513",
            "Clicks": "20196",
            "Conversions": "235",
            "Conversion Value": "$43,298",
            "Cost per Result": "$22.23"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "903915",
            "Clicks": "17989",
            "Conversions": "305",
            "Conversion Value": "$43,410",
            "Cost per Result": "$16.75"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1047045",
            "Clicks": "12168",
            "Conversions": "214",
            "Conversion Value": "$39,338",
            "Cost per Result": "$18.82"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1584049",
            "Clicks": "16263",
            "Conversions": "204",
            "Conversion Value": "$35,440",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "933475",
            "Clicks": "22006",
            "Conversions": "259",
            "Conversion Value": "$32,431",
            "Cost per Result": "$19.03"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1323710",
            "Clicks": "21832",
            "Conversions": "279",
            "Conversion Value": "$27,381",
            "Cost per Result": "$25.21"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1502543",
            "Clicks": "20887",
            "Conversions": "234",
            "Conversion Value": "$41,248",
            "Cost per Result": "$15.44"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "815419",
            "Clicks": "13899",
            "Conversions": "291",
            "Conversion Value": "$50,373",
            "Cost per Result": "$22.33"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1163719",
            "Clicks": "14082",
            "Conversions": "312",
            "Conversion Value": "$31,712",
            "Cost per Result": "$11.28"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1053473",
            "Clicks": "15419",
            "Conversions": "257",
            "Conversion Value": "$48,391",
            "Cost per Result": "$20.16"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1018852",
            "Clicks": "18860",
            "Conversions": "200",
            "Conversion Value": "$43,046",
            "Cost per Result": "$15.40"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "1174567",
            "Clicks": "21947",
            "Conversions": "228",
            "Conversion Value": "$44,820",
            "Cost per Result": "$15.69"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "681784",
            "Clicks": "20435",
            "Conversions": "258",
            "Conversion Value": "$31,102",
            "Cost per Result": "$21.84"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "810083",
            "Clicks": "17112",
            "Conversions": "267",
            "Conversion Value": "$43,168",
            "Cost per Result": "$13.91"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "794786",
            "Clicks": "17364",
            "Conversions": "237",
            "Conversion Value": "$44,984",
            "Cost per Result": "$20.33"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "903865",
            "Clicks": "23105",
            "Conversions": "294",
            "Conversion Value": "$29,155",
            "Cost per Result": "$15.22"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1244307",
            "Clicks": "17717",
            "Conversions": "246",
            "Conversion Value": "$42,938",
            "Cost per Result": "$21.63"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "872889",
            "Clicks": "21870",
            "Conversions": "239",
            "Conversion Value": "$49,408",
            "Cost per Result": "$13.07"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "781230",
            "Clicks": "17319",
            "Conversions": "292",
            "Conversion Value": "$26,095",
            "Cost per Result": "$18.59"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1006088",
            "Clicks": "18208",
            "Conversions": "243",
            "Conversion Value": "$32,549",
            "Cost per Result": "$15.75"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "998209",
            "Clicks": "21846",
            "Conversions": "280",
            "Conversion Value": "$27,635",
            "Cost per Result": "$17.75"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1291354",
            "Clicks": "14028",
            "Conversions": "305",
            "Conversion Value": "$37,017",
            "Cost per Result": "$13.67"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1004606",
            "Clicks": "15149",
            "Conversions": "264",
            "Conversion Value": "$38,046",
            "Cost per Result": "$19.45"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1340535",
            "Clicks": "19912",
            "Conversions": "278",
            "Conversion Value": "$32,977",
            "Cost per Result": "$12.24"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1388113",
            "Clicks": "20750",
            "Conversions": "232",
            "Conversion Value": "$33,688",
            "Cost per Result": "$24.29"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "779601",
            "Clicks": "18190",
            "Conversions": "205",
            "Conversion Value": "$38,522",
            "Cost per Result": "$26.94"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1406490",
            "Clicks": "19553",
            "Conversions": "235",
            "Conversion Value": "$42,943",
            "Cost per Result": "$18.20"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1144056",
            "Clicks": "25381",
            "Conversions": "207",
            "Conversion Value": "$32,947",
            "Cost per Result": "$19.81"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "783728",
            "Clicks": "16387",
            "Conversions": "259",
            "Conversion Value": "$54,661",
            "Cost per Result": "$18.13"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1250810",
            "Clicks": "16515",
            "Conversions": "293",
            "Conversion Value": "$46,953",
            "Cost per Result": "$24.59"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1087178",
            "Clicks": "21859",
            "Conversions": "180",
            "Conversion Value": "$40,088",
            "Cost per Result": "$19.41"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1415106",
            "Clicks": "17181",
            "Conversions": "224",
            "Conversion Value": "$44,608",
            "Cost per Result": "$26.10"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1056436",
            "Clicks": "14140",
            "Conversions": "288",
            "Conversion Value": "$40,355",
            "Cost per Result": "$23.14"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1077226",
            "Clicks": "19563",
            "Conversions": "302",
            "Conversion Value": "$45,194",
            "Cost per Result": "$14.96"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "986235",
            "Clicks": "21712",
            "Conversions": "268",
            "Conversion Value": "$26,788",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "1256735",
            "Clicks": "22043",
            "Conversions": "249",
            "Conversion Value": "$35,446",
            "Cost per Result": "$23.98"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1204477",
            "Clicks": "15730",
            "Conversions": "290",
            "Conversion Value": "$49,098",
            "Cost per Result": "$22.06"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "944458",
            "Clicks": "19870",
            "Conversions": "276",
            "Conversion Value": "$30,006",
            "Cost per Result": "$20.02"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1028375",
            "Clicks": "16974",
            "Conversions": "299",
            "Conversion Value": "$49,949",
            "Cost per Result": "$19.33"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1182354",
            "Clicks": "15596",
            "Conversions": "228",
            "Conversion Value": "$48,731",
            "Cost per Result": "$16.47"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1046558",
            "Clicks": "22998",
            "Conversions": "314",
            "Conversion Value": "$37,451",
            "Cost per Result": "$25.86"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "1201404",
            "Clicks": "17393",
            "Conversions": "263",
            "Conversion Value": "$36,750",
            "Cost per Result": "$25.24"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "808396",
            "Clicks": "16923",
            "Conversions": "228",
            "Conversion Value": "$28,246",
            "Cost per Result": "$21.87"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1545747",
            "Clicks": "12963",
            "Conversions": "262",
            "Conversion Value": "$43,358",
            "Cost per Result": "$17.14"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1573947",
            "Clicks": "19215",
            "Conversions": "259",
            "Conversion Value": "$40,616",
            "Cost per Result": "$20.69"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1690079",
            "Clicks": "19364",
            "Conversions": "227",
            "Conversion Value": "$44,961",
            "Cost per Result": "$22.29"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1184135",
            "Clicks": "21240",
            "Conversions": "257",
            "Conversion Value": "$39,368",
            "Cost per Result": "$16.14"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "864811",
            "Clicks": "19774",
            "Conversions": "231",
            "Conversion Value": "$39,736",
            "Cost per Result": "$22.56"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1571455",
            "Clicks": "17048",
            "Conversions": "248",
            "Conversion Value": "$49,017",
            "Cost per Result": "$22.15"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1372381",
            "Clicks": "18297",
            "Conversions": "230",
            "Conversion Value": "$30,755",
            "Cost per Result": "$25.90"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1091748",
            "Clicks": "13836",
            "Conversions": "280",
            "Conversion Value": "$40,822",
            "Cost per Result": "$15.95"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "866155",
            "Clicks": "16126",
            "Conversions": "240",
            "Conversion Value": "$35,819",
            "Cost per Result": "$24.20"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "988029",
            "Clicks": "17813",
            "Conversions": "238",
            "Conversion Value": "$38,501",
            "Cost per Result": "$16.74"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1253636",
            "Clicks": "16428",
            "Conversions": "223",
            "Conversion Value": "$45,238",
            "Cost per Result": "$23.69"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1492965",
            "Clicks": "20063",
            "Conversions": "245",
            "Conversion Value": "$54,492",
            "Cost per Result": "$22.84"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1385596",
            "Clicks": "17077",
            "Conversions": "290",
            "Conversion Value": "$46,415",
            "Cost per Result": "$24.67"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1457977",
            "Clicks": "15402",
            "Conversions": "231",
            "Conversion Value": "$30,952",
            "Cost per Result": "$19.02"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "935587",
            "Clicks": "17469",
            "Conversions": "261",
            "Conversion Value": "$46,784",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1009207",
            "Clicks": "18756",
            "Conversions": "254",
            "Conversion Value": "$38,193",
            "Cost per Result": "$15.04"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1217366",
            "Clicks": "22326",
            "Conversions": "302",
            "Conversion Value": "$39,333",
            "Cost per Result": "$18.39"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1344145",
            "Clicks": "20077",
            "Conversions": "256",
            "Conversion Value": "$52,911",
            "Cost per Result": "$17.50"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "614329",
            "Clicks": "22239",
            "Conversions": "278",
            "Conversion Value": "$43,397",
            "Cost per Result": "$22.38"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1492848",
            "Clicks": "16335",
            "Conversions": "235",
            "Conversion Value": "$36,126",
            "Cost per Result": "$17.81"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1412671",
            "Clicks": "20135",
            "Conversions": "278",
            "Conversion Value": "$37,970",
            "Cost per Result": "$19.04"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1094733",
            "Clicks": "17206",
            "Conversions": "245",
            "Conversion Value": "$38,205",
            "Cost per Result": "$20.64"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1271445",
            "Clicks": "18647",
            "Conversions": "249",
            "Conversion Value": "$40,980",
            "Cost per Result": "$25.47"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "931151",
            "Clicks": "19240",
            "Conversions": "191",
            "Conversion Value": "$32,175",
            "Cost per Result": "$16.13"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1386051",
            "Clicks": "15869",
            "Conversions": "272",
            "Conversion Value": "$35,370",
            "Cost per Result": "$20.19"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "975688",
            "Clicks": "19183",
            "Conversions": "277",
            "Conversion Value": "$50,467",
            "Cost per Result": "$22.74"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "989478",
            "Clicks": "18406",
            "Conversions": "214",
            "Conversion Value": "$40,645",
            "Cost per Result": "$20.27"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "873780",
            "Clicks": "18067",
            "Conversions": "257",
            "Conversion Value": "$37,675",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1095778",
            "Clicks": "19104",
            "Conversions": "245",
            "Conversion Value": "$55,811",
            "Cost per Result": "$17.34"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1360935",
            "Clicks": "14862",
            "Conversions": "256",
            "Conversion Value": "$38,715",
            "Cost per Result": "$22.73"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "1555655",
            "Clicks": "17996",
            "Conversions": "306",
            "Conversion Value": "$38,903",
            "Cost per Result": "$15.16"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1220015",
            "Clicks": "17094",
            "Conversions": "247",
            "Conversion Value": "$32,348",
            "Cost per Result": "$17.71"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1322403",
            "Clicks": "19907",
            "Conversions": "228",
            "Conversion Value": "$30,443",
            "Cost per Result": "$18.34"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1333077",
            "Clicks": "20233",
            "Conversions": "227",
            "Conversion Value": "$40,083",
            "Cost per Result": "$21.23"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1200541",
            "Clicks": "19605",
            "Conversions": "267",
            "Conversion Value": "$38,831",
            "Cost per Result": "$22.62"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1097226",
            "Clicks": "16184",
            "Conversions": "271",
            "Conversion Value": "$31,686",
            "Cost per Result": "$18.75"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1477296",
            "Clicks": "14676",
            "Conversions": "323",
            "Conversion Value": "$27,514",
            "Cost per Result": "$19.79"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1167432",
            "Clicks": "16448",
            "Conversions": "249",
            "Conversion Value": "$44,129",
            "Cost per Result": "$24.02"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1251250",
            "Clicks": "14136",
            "Conversions": "294",
            "Conversion Value": "$34,014",
            "Cost per Result": "$20.30"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "1507382",
            "Clicks": "18609",
            "Conversions": "250",
            "Conversion Value": "$40,262",
            "Cost per Result": "$17.05"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1230916",
            "Clicks": "19860",
            "Conversions": "287",
            "Conversion Value": "$51,113",
            "Cost per Result": "$15.57"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1542617",
            "Clicks": "21940",
            "Conversions": "290",
            "Conversion Value": "$41,005",
            "Cost per Result": "$17.02"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1461481",
            "Clicks": "17825",
            "Conversions": "237",
            "Conversion Value": "$32,324",
            "Cost per Result": "$26.44"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1413693",
            "Clicks": "17940",
            "Conversions": "239",
            "Conversion Value": "$43,941",
            "Cost per Result": "$26.60"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "958279",
            "Clicks": "12842",
            "Conversions": "261",
            "Conversion Value": "$39,480",
            "Cost per Result": "$18.58"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "1041231",
            "Clicks": "18964",
            "Conversions": "222",
            "Conversion Value": "$51,144",
            "Cost per Result": "$19.52"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1213609",
            "Clicks": "21603",
            "Conversions": "275",
            "Conversion Value": "$47,319",
            "Cost per Result": "$18.22"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1184872",
            "Clicks": "19950",
            "Conversions": "240",
            "Conversion Value": "$43,165",
            "Cost per Result": "$24.52"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1356987",
            "Clicks": "18540",
            "Conversions": "290",
            "Conversion Value": "$42,328",
            "Cost per Result": "$14.42"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "874452",
            "Clicks": "11853",
            "Conversions": "279",
            "Conversion Value": "$44,965",
            "Cost per Result": "$19.21"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "1270763",
            "Clicks": "18095",
            "Conversions": "269",
            "Conversion Value": "$33,262",
            "Cost per Result": "$22.40"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1409712",
            "Clicks": "16267",
            "Conversions": "262",
            "Conversion Value": "$46,062",
            "Cost per Result": "$17.03"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1000917",
            "Clicks": "17454",
            "Conversions": "282",
            "Conversion Value": "$32,596",
            "Cost per Result": "$20.53"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1384701",
            "Clicks": "19063",
            "Conversions": "226",
            "Conversion Value": "$43,463",
            "Cost per Result": "$18.34"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "1289723",
            "Clicks": "14981",
            "Conversions": "279",
            "Conversion Value": "$35,562",
            "Cost per Result": "$23.85"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "1440044",
            "Clicks": "15610",
            "Conversions": "277",
            "Conversion Value": "$44,202",
            "Cost per Result": "$17.09"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1250551",
            "Clicks": "16388",
            "Conversions": "252",
            "Conversion Value": "$43,599",
            "Cost per Result": "$20.84"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1150851",
            "Clicks": "14261",
            "Conversions": "275",
            "Conversion Value": "$36,419",
            "Cost per Result": "$26.49"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1088199",
            "Clicks": "20372",
            "Conversions": "229",
            "Conversion Value": "$27,554",
            "Cost per Result": "$17.32"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1015471",
            "Clicks": "20525",
            "Conversions": "268",
            "Conversion Value": "$45,528",
            "Cost per Result": "$13.78"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1248672",
            "Clicks": "16554",
            "Conversions": "239",
            "Conversion Value": "$45,481",
            "Cost per Result": "$25.48"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1240741",
            "Clicks": "17891",
            "Conversions": "225",
            "Conversion Value": "$40,295",
            "Cost per Result": "$20.83"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1492948",
            "Clicks": "21026",
            "Conversions": "231",
            "Conversion Value": "$43,419",
            "Cost per Result": "$18.83"
          }
        ]
      },
      "L1L2UsersData": {
        "name": "L1/L2 Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "305742",
            "Clicks": "2277",
            "Conversions": "74",
            "Conversion Value": "$12,979",
            "Cost per Result": "$6.11"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "123092",
            "Clicks": "5602",
            "Conversions": "89",
            "Conversion Value": "$9,611",
            "Cost per Result": "$11.97"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "293969",
            "Clicks": "5827",
            "Conversions": "76",
            "Conversion Value": "$13,667",
            "Cost per Result": "$11.52"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "148931",
            "Clicks": "4046",
            "Conversions": "71",
            "Conversion Value": "$7,806",
            "Cost per Result": "$27.59"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "265977",
            "Clicks": "5085",
            "Conversions": "76",
            "Conversion Value": "$12,029",
            "Cost per Result": "$23.94"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "469868",
            "Clicks": "4665",
            "Conversions": "76",
            "Conversion Value": "$4,757",
            "Cost per Result": "$12.07"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "103074",
            "Clicks": "5395",
            "Conversions": "83",
            "Conversion Value": "$5,466",
            "Cost per Result": "$21.92"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "144719",
            "Clicks": "2592",
            "Conversions": "49",
            "Conversion Value": "$4,628",
            "Cost per Result": "$18.03"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "453939",
            "Clicks": "4589",
            "Conversions": "76",
            "Conversion Value": "$8,725",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "104994",
            "Clicks": "4637",
            "Conversions": "48",
            "Conversion Value": "$4,093",
            "Cost per Result": "$6.01"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "399078",
            "Clicks": "3511",
            "Conversions": "81",
            "Conversion Value": "$12,418",
            "Cost per Result": "$24.26"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "214257",
            "Clicks": "5832",
            "Conversions": "64",
            "Conversion Value": "$11,914",
            "Cost per Result": "$15.99"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "210873",
            "Clicks": "5545",
            "Conversions": "59",
            "Conversion Value": "$4,323",
            "Cost per Result": "$20.21"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "138211",
            "Clicks": "2511",
            "Conversions": "80",
            "Conversion Value": "$8,923",
            "Cost per Result": "$27.75"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "440434",
            "Clicks": "3572",
            "Conversions": "80",
            "Conversion Value": "$7,065",
            "Cost per Result": "$33.65"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "249044",
            "Clicks": "2040",
            "Conversions": "88",
            "Conversion Value": "$4,017",
            "Cost per Result": "$20.65"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "110648",
            "Clicks": "2747",
            "Conversions": "48",
            "Conversion Value": "$8,751",
            "Cost per Result": "$22.17"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "245632",
            "Clicks": "6171",
            "Conversions": "88",
            "Conversion Value": "$13,173",
            "Cost per Result": "$28.46"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "471051",
            "Clicks": "5570",
            "Conversions": "54",
            "Conversion Value": "$12,619",
            "Cost per Result": "$15.75"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "287191",
            "Clicks": "6887",
            "Conversions": "42",
            "Conversion Value": "$15,489",
            "Cost per Result": "$21.80"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "441953",
            "Clicks": "2937",
            "Conversions": "53",
            "Conversion Value": "$6,060",
            "Cost per Result": "$13.15"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "107527",
            "Clicks": "3424",
            "Conversions": "88",
            "Conversion Value": "$15,796",
            "Cost per Result": "$20.27"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "391597",
            "Clicks": "2795",
            "Conversions": "60",
            "Conversion Value": "$15,535",
            "Cost per Result": "$9.29"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "327309",
            "Clicks": "6580",
            "Conversions": "44",
            "Conversion Value": "$14,086",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "180937",
            "Clicks": "5753",
            "Conversions": "80",
            "Conversion Value": "$6,144",
            "Cost per Result": "$32.78"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "270710",
            "Clicks": "3991",
            "Conversions": "73",
            "Conversion Value": "$15,695",
            "Cost per Result": "$9.41"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "187346",
            "Clicks": "2243",
            "Conversions": "53",
            "Conversion Value": "$11,860",
            "Cost per Result": "$24.75"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "110083",
            "Clicks": "4448",
            "Conversions": "45",
            "Conversion Value": "$4,300",
            "Cost per Result": "$5.54"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "273694",
            "Clicks": "5671",
            "Conversions": "70",
            "Conversion Value": "$14,675",
            "Cost per Result": "$11.74"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "328452",
            "Clicks": "2470",
            "Conversions": "54",
            "Conversion Value": "$13,121",
            "Cost per Result": "$16.85"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "376682",
            "Clicks": "3982",
            "Conversions": "59",
            "Conversion Value": "$15,094",
            "Cost per Result": "$22.45"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "356773",
            "Clicks": "5164",
            "Conversions": "57",
            "Conversion Value": "$8,491",
            "Cost per Result": "$17.08"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "403045",
            "Clicks": "3726",
            "Conversions": "85",
            "Conversion Value": "$6,314",
            "Cost per Result": "$26.54"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "394063",
            "Clicks": "3240",
            "Conversions": "65",
            "Conversion Value": "$5,933",
            "Cost per Result": "$15.50"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "480714",
            "Clicks": "5142",
            "Conversions": "84",
            "Conversion Value": "$12,869",
            "Cost per Result": "$9.76"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "268297",
            "Clicks": "2844",
            "Conversions": "54",
            "Conversion Value": "$9,032",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "231702",
            "Clicks": "2954",
            "Conversions": "81",
            "Conversion Value": "$7,195",
            "Cost per Result": "$16.10"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "489978",
            "Clicks": "5537",
            "Conversions": "70",
            "Conversion Value": "$4,432",
            "Cost per Result": "$19.88"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "313279",
            "Clicks": "5323",
            "Conversions": "75",
            "Conversion Value": "$9,143",
            "Cost per Result": "$15.01"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "165538",
            "Clicks": "3251",
            "Conversions": "72",
            "Conversion Value": "$4,180",
            "Cost per Result": "$16.92"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "393920",
            "Clicks": "4928",
            "Conversions": "67",
            "Conversion Value": "$13,533",
            "Cost per Result": "$27.91"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "482388",
            "Clicks": "3909",
            "Conversions": "58",
            "Conversion Value": "$14,630",
            "Cost per Result": "$14.59"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "351954",
            "Clicks": "2174",
            "Conversions": "47",
            "Conversion Value": "$7,868",
            "Cost per Result": "$26.74"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "190953",
            "Clicks": "6345",
            "Conversions": "54",
            "Conversion Value": "$8,459",
            "Cost per Result": "$26.39"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "450359",
            "Clicks": "6444",
            "Conversions": "44",
            "Conversion Value": "$13,925",
            "Cost per Result": "$23.65"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "162169",
            "Clicks": "5651",
            "Conversions": "75",
            "Conversion Value": "$8,922",
            "Cost per Result": "$34.34"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "374486",
            "Clicks": "3596",
            "Conversions": "75",
            "Conversion Value": "$7,275",
            "Cost per Result": "$29.61"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "393511",
            "Clicks": "3535",
            "Conversions": "68",
            "Conversion Value": "$15,346",
            "Cost per Result": "$28.08"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "225939",
            "Clicks": "5425",
            "Conversions": "86",
            "Conversion Value": "$7,499",
            "Cost per Result": "$15.07"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "206038",
            "Clicks": "2781",
            "Conversions": "72",
            "Conversion Value": "$7,028",
            "Cost per Result": "$17.82"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "331434",
            "Clicks": "6533",
            "Conversions": "42",
            "Conversion Value": "$12,914",
            "Cost per Result": "$23.24"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "283449",
            "Clicks": "4075",
            "Conversions": "87",
            "Conversion Value": "$11,957",
            "Cost per Result": "$26.72"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "218060",
            "Clicks": "3347",
            "Conversions": "81",
            "Conversion Value": "$10,517",
            "Cost per Result": "$7.42"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "447610",
            "Clicks": "6636",
            "Conversions": "62",
            "Conversion Value": "$12,876",
            "Cost per Result": "$23.70"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "474600",
            "Clicks": "6613",
            "Conversions": "58",
            "Conversion Value": "$6,508",
            "Cost per Result": "$7.45"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "270294",
            "Clicks": "2145",
            "Conversions": "80",
            "Conversion Value": "$8,128",
            "Cost per Result": "$27.43"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "492616",
            "Clicks": "3069",
            "Conversions": "58",
            "Conversion Value": "$8,414",
            "Cost per Result": "$33.07"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "146187",
            "Clicks": "3155",
            "Conversions": "67",
            "Conversion Value": "$14,808",
            "Cost per Result": "$25.82"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "272164",
            "Clicks": "2927",
            "Conversions": "82",
            "Conversion Value": "$14,463",
            "Cost per Result": "$26.78"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "487038",
            "Clicks": "5394",
            "Conversions": "61",
            "Conversion Value": "$15,937",
            "Cost per Result": "$16.84"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "477846",
            "Clicks": "5753",
            "Conversions": "70",
            "Conversion Value": "$10,419",
            "Cost per Result": "$9.05"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "161864",
            "Clicks": "6528",
            "Conversions": "85",
            "Conversion Value": "$9,042",
            "Cost per Result": "$20.02"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "415290",
            "Clicks": "3122",
            "Conversions": "68",
            "Conversion Value": "$4,492",
            "Cost per Result": "$8.55"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "355301",
            "Clicks": "6844",
            "Conversions": "66",
            "Conversion Value": "$14,196",
            "Cost per Result": "$5.83"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "321104",
            "Clicks": "3691",
            "Conversions": "66",
            "Conversion Value": "$8,050",
            "Cost per Result": "$11.51"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "328678",
            "Clicks": "2891",
            "Conversions": "54",
            "Conversion Value": "$9,730",
            "Cost per Result": "$23.43"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "457259",
            "Clicks": "5568",
            "Conversions": "68",
            "Conversion Value": "$5,401",
            "Cost per Result": "$12.80"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "460810",
            "Clicks": "5340",
            "Conversions": "84",
            "Conversion Value": "$11,830",
            "Cost per Result": "$5.64"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "391237",
            "Clicks": "2233",
            "Conversions": "59",
            "Conversion Value": "$10,684",
            "Cost per Result": "$15.92"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "463291",
            "Clicks": "4471",
            "Conversions": "87",
            "Conversion Value": "$11,295",
            "Cost per Result": "$21.28"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "325049",
            "Clicks": "2799",
            "Conversions": "43",
            "Conversion Value": "$13,415",
            "Cost per Result": "$8.01"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "485416",
            "Clicks": "4954",
            "Conversions": "64",
            "Conversion Value": "$12,282",
            "Cost per Result": "$26.71"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "289322",
            "Clicks": "3684",
            "Conversions": "53",
            "Conversion Value": "$15,296",
            "Cost per Result": "$25.38"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "354866",
            "Clicks": "2625",
            "Conversions": "51",
            "Conversion Value": "$13,895",
            "Cost per Result": "$34.78"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "304591",
            "Clicks": "3609",
            "Conversions": "88",
            "Conversion Value": "$14,760",
            "Cost per Result": "$34.78"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "281367",
            "Clicks": "6046",
            "Conversions": "44",
            "Conversion Value": "$10,219",
            "Cost per Result": "$6.41"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "373098",
            "Clicks": "4005",
            "Conversions": "58",
            "Conversion Value": "$11,125",
            "Cost per Result": "$19.56"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "352498",
            "Clicks": "6005",
            "Conversions": "80",
            "Conversion Value": "$6,931",
            "Cost per Result": "$6.51"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "324753",
            "Clicks": "5130",
            "Conversions": "80",
            "Conversion Value": "$10,339",
            "Cost per Result": "$20.09"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "475513",
            "Clicks": "5032",
            "Conversions": "57",
            "Conversion Value": "$5,813",
            "Cost per Result": "$9.09"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "105504",
            "Clicks": "4373",
            "Conversions": "66",
            "Conversion Value": "$11,667",
            "Cost per Result": "$34.78"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "199779",
            "Clicks": "2188",
            "Conversions": "67",
            "Conversion Value": "$8,436",
            "Cost per Result": "$18.06"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "251452",
            "Clicks": "6014",
            "Conversions": "45",
            "Conversion Value": "$14,362",
            "Cost per Result": "$6.92"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "255509",
            "Clicks": "6229",
            "Conversions": "84",
            "Conversion Value": "$12,732",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "105323",
            "Clicks": "4176",
            "Conversions": "52",
            "Conversion Value": "$5,801",
            "Cost per Result": "$30.13"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "361777",
            "Clicks": "2954",
            "Conversions": "50",
            "Conversion Value": "$15,993",
            "Cost per Result": "$16.89"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "452117",
            "Clicks": "2321",
            "Conversions": "62",
            "Conversion Value": "$14,717",
            "Cost per Result": "$19.04"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "417144",
            "Clicks": "2535",
            "Conversions": "57",
            "Conversion Value": "$7,724",
            "Cost per Result": "$32.71"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "207985",
            "Clicks": "4831",
            "Conversions": "62",
            "Conversion Value": "$11,263",
            "Cost per Result": "$7.09"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "447894",
            "Clicks": "4574",
            "Conversions": "59",
            "Conversion Value": "$9,539",
            "Cost per Result": "$16.78"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "102021",
            "Clicks": "4087",
            "Conversions": "40",
            "Conversion Value": "$8,629",
            "Cost per Result": "$7.28"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "141247",
            "Clicks": "2158",
            "Conversions": "88",
            "Conversion Value": "$10,310",
            "Cost per Result": "$30.98"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "137183",
            "Clicks": "4864",
            "Conversions": "79",
            "Conversion Value": "$4,817",
            "Cost per Result": "$8.90"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "254805",
            "Clicks": "5357",
            "Conversions": "87",
            "Conversion Value": "$9,936",
            "Cost per Result": "$18.28"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "377892",
            "Clicks": "2569",
            "Conversions": "61",
            "Conversion Value": "$6,802",
            "Cost per Result": "$33.16"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "320704",
            "Clicks": "5985",
            "Conversions": "65",
            "Conversion Value": "$6,575",
            "Cost per Result": "$19.76"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "143041",
            "Clicks": "6071",
            "Conversions": "85",
            "Conversion Value": "$14,654",
            "Cost per Result": "$12.37"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "436846",
            "Clicks": "2995",
            "Conversions": "59",
            "Conversion Value": "$11,628",
            "Cost per Result": "$31.89"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "420474",
            "Clicks": "6130",
            "Conversions": "73",
            "Conversion Value": "$4,788",
            "Cost per Result": "$26.29"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "172456",
            "Clicks": "4717",
            "Conversions": "69",
            "Conversion Value": "$5,450",
            "Cost per Result": "$20.15"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "180110",
            "Clicks": "3234",
            "Conversions": "76",
            "Conversion Value": "$15,418",
            "Cost per Result": "$18.69"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "256944",
            "Clicks": "4115",
            "Conversions": "48",
            "Conversion Value": "$9,049",
            "Cost per Result": "$18.40"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "146584",
            "Clicks": "6281",
            "Conversions": "40",
            "Conversion Value": "$15,704",
            "Cost per Result": "$16.16"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "146396",
            "Clicks": "6391",
            "Conversions": "82",
            "Conversion Value": "$15,080",
            "Cost per Result": "$26.21"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "278596",
            "Clicks": "5742",
            "Conversions": "83",
            "Conversion Value": "$9,184",
            "Cost per Result": "$10.33"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "479391",
            "Clicks": "2872",
            "Conversions": "83",
            "Conversion Value": "$4,578",
            "Cost per Result": "$16.59"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "452528",
            "Clicks": "4943",
            "Conversions": "79",
            "Conversion Value": "$7,706",
            "Cost per Result": "$13.83"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "382475",
            "Clicks": "3425",
            "Conversions": "80",
            "Conversion Value": "$11,045",
            "Cost per Result": "$11.95"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "104771",
            "Clicks": "3727",
            "Conversions": "65",
            "Conversion Value": "$10,371",
            "Cost per Result": "$28.99"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "236438",
            "Clicks": "4325",
            "Conversions": "73",
            "Conversion Value": "$8,936",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "474532",
            "Clicks": "4107",
            "Conversions": "87",
            "Conversion Value": "$12,808",
            "Cost per Result": "$7.40"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "169297",
            "Clicks": "3926",
            "Conversions": "63",
            "Conversion Value": "$5,150",
            "Cost per Result": "$12.29"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "311602",
            "Clicks": "3769",
            "Conversions": "78",
            "Conversion Value": "$15,179",
            "Cost per Result": "$15.03"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "433924",
            "Clicks": "6800",
            "Conversions": "58",
            "Conversion Value": "$10,827",
            "Cost per Result": "$13.21"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "158246",
            "Clicks": "5162",
            "Conversions": "65",
            "Conversion Value": "$6,587",
            "Cost per Result": "$34.84"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "421969",
            "Clicks": "4584",
            "Conversions": "90",
            "Conversion Value": "$5,412",
            "Cost per Result": "$8.18"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "148216",
            "Clicks": "5015",
            "Conversions": "66",
            "Conversion Value": "$10,618",
            "Cost per Result": "$20.86"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "156830",
            "Clicks": "5462",
            "Conversions": "46",
            "Conversion Value": "$13,458",
            "Cost per Result": "$22.09"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "438134",
            "Clicks": "6877",
            "Conversions": "75",
            "Conversion Value": "$5,317",
            "Cost per Result": "$19.87"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "123845",
            "Clicks": "2117",
            "Conversions": "50",
            "Conversion Value": "$4,573",
            "Cost per Result": "$9.75"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "126906",
            "Clicks": "5582",
            "Conversions": "44",
            "Conversion Value": "$12,527",
            "Cost per Result": "$10.39"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "193868",
            "Clicks": "6809",
            "Conversions": "54",
            "Conversion Value": "$7,230",
            "Cost per Result": "$8.88"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "438910",
            "Clicks": "3627",
            "Conversions": "79",
            "Conversion Value": "$7,772",
            "Cost per Result": "$21.37"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "166371",
            "Clicks": "6892",
            "Conversions": "84",
            "Conversion Value": "$6,143",
            "Cost per Result": "$34.52"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "138921",
            "Clicks": "6478",
            "Conversions": "69",
            "Conversion Value": "$4,169",
            "Cost per Result": "$23.43"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420908",
            "Clicks": "5873",
            "Conversions": "81",
            "Conversion Value": "$10,474",
            "Cost per Result": "$13.06"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "485066",
            "Clicks": "3779",
            "Conversions": "73",
            "Conversion Value": "$7,621",
            "Cost per Result": "$32.63"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "473062",
            "Clicks": "6047",
            "Conversions": "68",
            "Conversion Value": "$9,423",
            "Cost per Result": "$20.92"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "265427",
            "Clicks": "5840",
            "Conversions": "81",
            "Conversion Value": "$5,143",
            "Cost per Result": "$17.61"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "166253",
            "Clicks": "4510",
            "Conversions": "66",
            "Conversion Value": "$7,526",
            "Cost per Result": "$24.45"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "140759",
            "Clicks": "2279",
            "Conversions": "83",
            "Conversion Value": "$14,877",
            "Cost per Result": "$26.35"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "308211",
            "Clicks": "4168",
            "Conversions": "63",
            "Conversion Value": "$8,358",
            "Cost per Result": "$20.21"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "447540",
            "Clicks": "5227",
            "Conversions": "84",
            "Conversion Value": "$14,242",
            "Cost per Result": "$21.81"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "426487",
            "Clicks": "5260",
            "Conversions": "61",
            "Conversion Value": "$5,746",
            "Cost per Result": "$8.30"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "401267",
            "Clicks": "6401",
            "Conversions": "61",
            "Conversion Value": "$15,243",
            "Cost per Result": "$21.53"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "447811",
            "Clicks": "2328",
            "Conversions": "77",
            "Conversion Value": "$5,317",
            "Cost per Result": "$11.82"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "159441",
            "Clicks": "6831",
            "Conversions": "45",
            "Conversion Value": "$15,780",
            "Cost per Result": "$32.89"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "309786",
            "Clicks": "5913",
            "Conversions": "76",
            "Conversion Value": "$8,273",
            "Cost per Result": "$28.41"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "118444",
            "Clicks": "4296",
            "Conversions": "71",
            "Conversion Value": "$4,484",
            "Cost per Result": "$31.78"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "229635",
            "Clicks": "6081",
            "Conversions": "53",
            "Conversion Value": "$15,994",
            "Cost per Result": "$19.82"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "271179",
            "Clicks": "5971",
            "Conversions": "40",
            "Conversion Value": "$8,615",
            "Cost per Result": "$13.52"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "154348",
            "Clicks": "5107",
            "Conversions": "83",
            "Conversion Value": "$5,366",
            "Cost per Result": "$13.48"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "366538",
            "Clicks": "2455",
            "Conversions": "49",
            "Conversion Value": "$13,107",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "401418",
            "Clicks": "4851",
            "Conversions": "54",
            "Conversion Value": "$9,643",
            "Cost per Result": "$9.58"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "392790",
            "Clicks": "6931",
            "Conversions": "89",
            "Conversion Value": "$10,665",
            "Cost per Result": "$11.14"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "402732",
            "Clicks": "3992",
            "Conversions": "56",
            "Conversion Value": "$8,109",
            "Cost per Result": "$25.19"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "340966",
            "Clicks": "5442",
            "Conversions": "56",
            "Conversion Value": "$8,656",
            "Cost per Result": "$21.66"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "150452",
            "Clicks": "3974",
            "Conversions": "41",
            "Conversion Value": "$13,733",
            "Cost per Result": "$25.49"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "159004",
            "Clicks": "2241",
            "Conversions": "51",
            "Conversion Value": "$5,192",
            "Cost per Result": "$18.03"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "108646",
            "Clicks": "3221",
            "Conversions": "64",
            "Conversion Value": "$8,071",
            "Cost per Result": "$23.06"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "131541",
            "Clicks": "4304",
            "Conversions": "47",
            "Conversion Value": "$4,840",
            "Cost per Result": "$18.61"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "124962",
            "Clicks": "6555",
            "Conversions": "78",
            "Conversion Value": "$13,831",
            "Cost per Result": "$21.33"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "143840",
            "Clicks": "5800",
            "Conversions": "55",
            "Conversion Value": "$8,485",
            "Cost per Result": "$19.53"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "310922",
            "Clicks": "3330",
            "Conversions": "65",
            "Conversion Value": "$13,204",
            "Cost per Result": "$14.71"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "122941",
            "Clicks": "3851",
            "Conversions": "50",
            "Conversion Value": "$10,470",
            "Cost per Result": "$17.47"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246994",
            "Clicks": "5211",
            "Conversions": "75",
            "Conversion Value": "$6,167",
            "Cost per Result": "$6.27"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "186993",
            "Clicks": "3788",
            "Conversions": "59",
            "Conversion Value": "$11,751",
            "Cost per Result": "$31.72"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "230087",
            "Clicks": "4857",
            "Conversions": "73",
            "Conversion Value": "$11,793",
            "Cost per Result": "$15.95"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "249030",
            "Clicks": "3626",
            "Conversions": "80",
            "Conversion Value": "$4,855",
            "Cost per Result": "$20.20"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "468545",
            "Clicks": "3731",
            "Conversions": "80",
            "Conversion Value": "$7,820",
            "Cost per Result": "$9.67"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "268984",
            "Clicks": "4228",
            "Conversions": "71",
            "Conversion Value": "$7,154",
            "Cost per Result": "$31.12"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "326191",
            "Clicks": "2386",
            "Conversions": "87",
            "Conversion Value": "$4,753",
            "Cost per Result": "$7.39"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "464002",
            "Clicks": "2725",
            "Conversions": "81",
            "Conversion Value": "$9,828",
            "Cost per Result": "$21.38"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "337122",
            "Clicks": "5629",
            "Conversions": "59",
            "Conversion Value": "$13,830",
            "Cost per Result": "$7.96"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "489639",
            "Clicks": "6154",
            "Conversions": "53",
            "Conversion Value": "$4,868",
            "Cost per Result": "$17.62"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "102204",
            "Clicks": "4091",
            "Conversions": "41",
            "Conversion Value": "$5,814",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "192112",
            "Clicks": "4822",
            "Conversions": "47",
            "Conversion Value": "$9,951",
            "Cost per Result": "$24.42"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "113846",
            "Clicks": "5614",
            "Conversions": "52",
            "Conversion Value": "$7,800",
            "Cost per Result": "$17.31"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "184505",
            "Clicks": "2771",
            "Conversions": "68",
            "Conversion Value": "$12,884",
            "Cost per Result": "$7.65"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "145558",
            "Clicks": "5218",
            "Conversions": "70",
            "Conversion Value": "$15,383",
            "Cost per Result": "$16.62"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "379121",
            "Clicks": "6659",
            "Conversions": "41",
            "Conversion Value": "$7,780",
            "Cost per Result": "$30.08"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "226677",
            "Clicks": "6011",
            "Conversions": "60",
            "Conversion Value": "$14,676",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "156438",
            "Clicks": "4737",
            "Conversions": "87",
            "Conversion Value": "$11,998",
            "Cost per Result": "$27.52"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "471034",
            "Clicks": "5407",
            "Conversions": "73",
            "Conversion Value": "$6,881",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "292362",
            "Clicks": "6005",
            "Conversions": "89",
            "Conversion Value": "$6,156",
            "Cost per Result": "$13.32"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "243306",
            "Clicks": "3256",
            "Conversions": "86",
            "Conversion Value": "$11,348",
            "Cost per Result": "$30.50"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "404629",
            "Clicks": "4609",
            "Conversions": "84",
            "Conversion Value": "$15,024",
            "Cost per Result": "$26.97"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "361482",
            "Clicks": "5717",
            "Conversions": "61",
            "Conversion Value": "$8,316",
            "Cost per Result": "$17.83"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "402717",
            "Clicks": "3572",
            "Conversions": "61",
            "Conversion Value": "$8,577",
            "Cost per Result": "$19.32"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "112696",
            "Clicks": "4684",
            "Conversions": "42",
            "Conversion Value": "$14,656",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "200950",
            "Clicks": "6854",
            "Conversions": "76",
            "Conversion Value": "$15,702",
            "Cost per Result": "$20.54"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "198506",
            "Clicks": "6664",
            "Conversions": "81",
            "Conversion Value": "$8,644",
            "Cost per Result": "$31.25"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "130592",
            "Clicks": "2677",
            "Conversions": "70",
            "Conversion Value": "$9,558",
            "Cost per Result": "$6.97"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "351206",
            "Clicks": "2950",
            "Conversions": "88",
            "Conversion Value": "$11,069",
            "Cost per Result": "$19.02"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "293553",
            "Clicks": "4272",
            "Conversions": "64",
            "Conversion Value": "$5,164",
            "Cost per Result": "$8.45"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "433469",
            "Clicks": "3681",
            "Conversions": "51",
            "Conversion Value": "$12,167",
            "Cost per Result": "$21.27"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "149300",
            "Clicks": "5470",
            "Conversions": "48",
            "Conversion Value": "$9,704",
            "Cost per Result": "$17.24"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "138702",
            "Clicks": "4633",
            "Conversions": "51",
            "Conversion Value": "$13,956",
            "Cost per Result": "$13.13"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "410254",
            "Clicks": "6609",
            "Conversions": "69",
            "Conversion Value": "$13,992",
            "Cost per Result": "$30.86"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "469871",
            "Clicks": "5903",
            "Conversions": "73",
            "Conversion Value": "$4,766",
            "Cost per Result": "$19.32"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "113105",
            "Clicks": "5442",
            "Conversions": "84",
            "Conversion Value": "$10,844",
            "Cost per Result": "$15.62"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "141990",
            "Clicks": "5693",
            "Conversions": "50",
            "Conversion Value": "$8,856",
            "Cost per Result": "$27.61"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "289286",
            "Clicks": "5155",
            "Conversions": "72",
            "Conversion Value": "$9,987",
            "Cost per Result": "$7.79"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "411380",
            "Clicks": "3282",
            "Conversions": "57",
            "Conversion Value": "$7,212",
            "Cost per Result": "$22.52"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "113518",
            "Clicks": "2498",
            "Conversions": "72",
            "Conversion Value": "$13,119",
            "Cost per Result": "$31.37"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "297382",
            "Clicks": "4762",
            "Conversions": "55",
            "Conversion Value": "$10,539",
            "Cost per Result": "$22.72"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "382620",
            "Clicks": "3591",
            "Conversions": "55",
            "Conversion Value": "$9,801",
            "Cost per Result": "$23.83"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "175453",
            "Clicks": "5689",
            "Conversions": "50",
            "Conversion Value": "$9,081",
            "Cost per Result": "$11.82"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "266223",
            "Clicks": "4338",
            "Conversions": "61",
            "Conversion Value": "$8,301",
            "Cost per Result": "$12.38"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "161132",
            "Clicks": "5452",
            "Conversions": "90",
            "Conversion Value": "$11,862",
            "Cost per Result": "$21.14"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "357095",
            "Clicks": "4970",
            "Conversions": "75",
            "Conversion Value": "$14,328",
            "Cost per Result": "$18.88"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "180731",
            "Clicks": "5749",
            "Conversions": "41",
            "Conversion Value": "$10,020",
            "Cost per Result": "$22.84"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "392388",
            "Clicks": "6027",
            "Conversions": "60",
            "Conversion Value": "$9,937",
            "Cost per Result": "$22.45"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "375968",
            "Clicks": "6166",
            "Conversions": "85",
            "Conversion Value": "$5,589",
            "Cost per Result": "$24.15"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "361193",
            "Clicks": "2689",
            "Conversions": "66",
            "Conversion Value": "$5,535",
            "Cost per Result": "$17.91"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "487073",
            "Clicks": "4903",
            "Conversions": "41",
            "Conversion Value": "$11,141",
            "Cost per Result": "$23.27"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "381916",
            "Clicks": "3660",
            "Conversions": "45",
            "Conversion Value": "$4,993",
            "Cost per Result": "$18.14"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "303855",
            "Clicks": "5239",
            "Conversions": "80",
            "Conversion Value": "$6,310",
            "Cost per Result": "$33.81"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "130384",
            "Clicks": "4558",
            "Conversions": "69",
            "Conversion Value": "$13,978",
            "Cost per Result": "$29.29"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "100297",
            "Clicks": "4334",
            "Conversions": "49",
            "Conversion Value": "$14,263",
            "Cost per Result": "$18.47"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "312523",
            "Clicks": "2726",
            "Conversions": "68",
            "Conversion Value": "$13,793",
            "Cost per Result": "$5.04"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "139624",
            "Clicks": "5561",
            "Conversions": "53",
            "Conversion Value": "$14,464",
            "Cost per Result": "$32.70"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "371514",
            "Clicks": "3558",
            "Conversions": "67",
            "Conversion Value": "$9,711",
            "Cost per Result": "$6.84"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "349420",
            "Clicks": "4207",
            "Conversions": "61",
            "Conversion Value": "$12,529",
            "Cost per Result": "$9.62"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "147354",
            "Clicks": "2348",
            "Conversions": "70",
            "Conversion Value": "$4,860",
            "Cost per Result": "$7.45"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "247017",
            "Clicks": "6237",
            "Conversions": "58",
            "Conversion Value": "$4,245",
            "Cost per Result": "$9.39"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "296814",
            "Clicks": "6709",
            "Conversions": "55",
            "Conversion Value": "$8,653",
            "Cost per Result": "$32.32"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "202346",
            "Clicks": "5878",
            "Conversions": "63",
            "Conversion Value": "$12,613",
            "Cost per Result": "$15.93"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "245198",
            "Clicks": "2861",
            "Conversions": "49",
            "Conversion Value": "$5,113",
            "Cost per Result": "$32.99"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "448166",
            "Clicks": "4242",
            "Conversions": "73",
            "Conversion Value": "$5,764",
            "Cost per Result": "$13.25"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "270685",
            "Clicks": "4523",
            "Conversions": "77",
            "Conversion Value": "$5,659",
            "Cost per Result": "$34.42"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "306721",
            "Clicks": "2601",
            "Conversions": "72",
            "Conversion Value": "$4,284",
            "Cost per Result": "$34.44"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "391165",
            "Clicks": "6306",
            "Conversions": "49",
            "Conversion Value": "$9,478",
            "Cost per Result": "$12.17"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "349489",
            "Clicks": "6490",
            "Conversions": "89",
            "Conversion Value": "$9,334",
            "Cost per Result": "$18.00"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "487465",
            "Clicks": "2905",
            "Conversions": "83",
            "Conversion Value": "$12,674",
            "Cost per Result": "$6.82"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "425805",
            "Clicks": "4537",
            "Conversions": "55",
            "Conversion Value": "$15,541",
            "Cost per Result": "$17.45"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "192024",
            "Clicks": "2275",
            "Conversions": "68",
            "Conversion Value": "$11,533",
            "Cost per Result": "$14.63"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "101414",
            "Clicks": "2193",
            "Conversions": "76",
            "Conversion Value": "$6,147",
            "Cost per Result": "$28.68"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "215995",
            "Clicks": "6961",
            "Conversions": "71",
            "Conversion Value": "$15,275",
            "Cost per Result": "$31.35"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "184982",
            "Clicks": "3779",
            "Conversions": "84",
            "Conversion Value": "$12,806",
            "Cost per Result": "$9.12"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "279242",
            "Clicks": "5227",
            "Conversions": "64",
            "Conversion Value": "$8,191",
            "Cost per Result": "$26.69"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "466317",
            "Clicks": "6914",
            "Conversions": "70",
            "Conversion Value": "$14,825",
            "Cost per Result": "$11.34"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "226870",
            "Clicks": "2158",
            "Conversions": "60",
            "Conversion Value": "$7,782",
            "Cost per Result": "$32.94"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "452670",
            "Clicks": "4494",
            "Conversions": "81",
            "Conversion Value": "$9,908",
            "Cost per Result": "$18.56"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "224075",
            "Clicks": "3787",
            "Conversions": "64",
            "Conversion Value": "$12,699",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "108498",
            "Clicks": "3244",
            "Conversions": "84",
            "Conversion Value": "$11,462",
            "Cost per Result": "$9.66"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "360812",
            "Clicks": "2431",
            "Conversions": "42",
            "Conversion Value": "$9,795",
            "Cost per Result": "$21.42"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "250145",
            "Clicks": "2206",
            "Conversions": "88",
            "Conversion Value": "$8,585",
            "Cost per Result": "$27.63"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "192895",
            "Clicks": "2811",
            "Conversions": "60",
            "Conversion Value": "$5,390",
            "Cost per Result": "$16.45"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "221991",
            "Clicks": "3620",
            "Conversions": "75",
            "Conversion Value": "$5,775",
            "Cost per Result": "$26.79"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "401730",
            "Clicks": "2331",
            "Conversions": "83",
            "Conversion Value": "$5,633",
            "Cost per Result": "$29.83"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "217977",
            "Clicks": "4716",
            "Conversions": "69",
            "Conversion Value": "$7,194",
            "Cost per Result": "$6.98"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "357273",
            "Clicks": "4978",
            "Conversions": "78",
            "Conversion Value": "$8,679",
            "Cost per Result": "$6.83"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "376424",
            "Clicks": "3381",
            "Conversions": "47",
            "Conversion Value": "$7,846",
            "Cost per Result": "$14.75"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "285561",
            "Clicks": "6108",
            "Conversions": "49",
            "Conversion Value": "$10,608",
            "Cost per Result": "$21.65"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "362458",
            "Clicks": "5136",
            "Conversions": "79",
            "Conversion Value": "$15,147",
            "Cost per Result": "$13.82"
          }
        ]
      },
      "L1TokenHoldersData": {
        "name": "L1 Token holders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "354127",
            "Clicks": "2500",
            "Conversions": "63",
            "Conversion Value": "$6,742",
            "Cost per Result": "$8.57"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "388924",
            "Clicks": "3945",
            "Conversions": "48",
            "Conversion Value": "$12,155",
            "Cost per Result": "$17.52"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "180161",
            "Clicks": "2369",
            "Conversions": "86",
            "Conversion Value": "$11,939",
            "Cost per Result": "$18.75"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "307469",
            "Clicks": "6958",
            "Conversions": "64",
            "Conversion Value": "$12,880",
            "Cost per Result": "$19.85"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "174517",
            "Clicks": "2801",
            "Conversions": "79",
            "Conversion Value": "$10,195",
            "Cost per Result": "$22.55"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "412616",
            "Clicks": "6479",
            "Conversions": "57",
            "Conversion Value": "$7,748",
            "Cost per Result": "$26.89"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "168255",
            "Clicks": "2636",
            "Conversions": "81",
            "Conversion Value": "$12,654",
            "Cost per Result": "$18.47"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "451077",
            "Clicks": "5438",
            "Conversions": "82",
            "Conversion Value": "$9,096",
            "Cost per Result": "$17.06"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "377284",
            "Clicks": "3776",
            "Conversions": "74",
            "Conversion Value": "$14,254",
            "Cost per Result": "$11.33"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "449901",
            "Clicks": "5784",
            "Conversions": "75",
            "Conversion Value": "$8,948",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "467859",
            "Clicks": "5256",
            "Conversions": "52",
            "Conversion Value": "$4,112",
            "Cost per Result": "$22.32"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "175213",
            "Clicks": "6614",
            "Conversions": "87",
            "Conversion Value": "$6,137",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "105409",
            "Clicks": "6932",
            "Conversions": "70",
            "Conversion Value": "$8,494",
            "Cost per Result": "$12.12"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "163200",
            "Clicks": "4436",
            "Conversions": "87",
            "Conversion Value": "$4,722",
            "Cost per Result": "$20.03"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "272852",
            "Clicks": "6715",
            "Conversions": "47",
            "Conversion Value": "$15,325",
            "Cost per Result": "$19.69"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "123411",
            "Clicks": "4237",
            "Conversions": "58",
            "Conversion Value": "$10,484",
            "Cost per Result": "$9.09"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "460587",
            "Clicks": "2000",
            "Conversions": "45",
            "Conversion Value": "$14,291",
            "Cost per Result": "$26.28"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "249033",
            "Clicks": "5101",
            "Conversions": "66",
            "Conversion Value": "$10,111",
            "Cost per Result": "$27.81"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "485373",
            "Clicks": "6196",
            "Conversions": "56",
            "Conversion Value": "$6,751",
            "Cost per Result": "$8.42"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "409614",
            "Clicks": "6432",
            "Conversions": "90",
            "Conversion Value": "$11,936",
            "Cost per Result": "$17.33"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "431920",
            "Clicks": "3285",
            "Conversions": "73",
            "Conversion Value": "$6,498",
            "Cost per Result": "$29.91"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "419682",
            "Clicks": "2933",
            "Conversions": "88",
            "Conversion Value": "$13,748",
            "Cost per Result": "$17.12"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "252953",
            "Clicks": "5917",
            "Conversions": "54",
            "Conversion Value": "$10,163",
            "Cost per Result": "$9.33"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "473694",
            "Clicks": "2702",
            "Conversions": "58",
            "Conversion Value": "$8,269",
            "Cost per Result": "$22.80"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "416032",
            "Clicks": "6920",
            "Conversions": "73",
            "Conversion Value": "$12,264",
            "Cost per Result": "$34.74"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "446948",
            "Clicks": "5451",
            "Conversions": "77",
            "Conversion Value": "$11,390",
            "Cost per Result": "$11.91"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "334637",
            "Clicks": "5203",
            "Conversions": "61",
            "Conversion Value": "$4,572",
            "Cost per Result": "$24.66"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "147994",
            "Clicks": "3137",
            "Conversions": "83",
            "Conversion Value": "$12,896",
            "Cost per Result": "$22.31"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "334670",
            "Clicks": "4100",
            "Conversions": "54",
            "Conversion Value": "$8,142",
            "Cost per Result": "$26.54"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "366373",
            "Clicks": "5078",
            "Conversions": "88",
            "Conversion Value": "$14,187",
            "Cost per Result": "$18.20"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "334406",
            "Clicks": "4008",
            "Conversions": "58",
            "Conversion Value": "$14,856",
            "Cost per Result": "$30.03"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "317099",
            "Clicks": "2230",
            "Conversions": "66",
            "Conversion Value": "$4,002",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "486018",
            "Clicks": "4392",
            "Conversions": "56",
            "Conversion Value": "$11,046",
            "Cost per Result": "$27.59"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "398544",
            "Clicks": "2029",
            "Conversions": "85",
            "Conversion Value": "$4,139",
            "Cost per Result": "$28.09"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "351593",
            "Clicks": "4198",
            "Conversions": "60",
            "Conversion Value": "$4,469",
            "Cost per Result": "$16.71"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "442456",
            "Clicks": "6418",
            "Conversions": "75",
            "Conversion Value": "$5,278",
            "Cost per Result": "$8.89"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "458481",
            "Clicks": "5537",
            "Conversions": "78",
            "Conversion Value": "$4,763",
            "Cost per Result": "$31.75"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "164785",
            "Clicks": "2319",
            "Conversions": "62",
            "Conversion Value": "$11,728",
            "Cost per Result": "$14.66"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "171774",
            "Clicks": "5224",
            "Conversions": "70",
            "Conversion Value": "$8,287",
            "Cost per Result": "$25.08"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "275475",
            "Clicks": "3115",
            "Conversions": "55",
            "Conversion Value": "$4,816",
            "Cost per Result": "$10.58"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "290802",
            "Clicks": "6813",
            "Conversions": "88",
            "Conversion Value": "$13,868",
            "Cost per Result": "$34.87"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "357746",
            "Clicks": "2207",
            "Conversions": "60",
            "Conversion Value": "$8,127",
            "Cost per Result": "$33.09"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "357984",
            "Clicks": "4950",
            "Conversions": "63",
            "Conversion Value": "$4,154",
            "Cost per Result": "$31.54"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "297121",
            "Clicks": "4798",
            "Conversions": "41",
            "Conversion Value": "$8,163",
            "Cost per Result": "$10.64"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "179920",
            "Clicks": "6868",
            "Conversions": "41",
            "Conversion Value": "$6,806",
            "Cost per Result": "$29.23"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "173093",
            "Clicks": "6557",
            "Conversions": "62",
            "Conversion Value": "$8,760",
            "Cost per Result": "$13.62"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "300875",
            "Clicks": "6322",
            "Conversions": "90",
            "Conversion Value": "$8,122",
            "Cost per Result": "$9.87"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "199031",
            "Clicks": "4371",
            "Conversions": "64",
            "Conversion Value": "$11,726",
            "Cost per Result": "$5.80"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "418096",
            "Clicks": "4141",
            "Conversions": "61",
            "Conversion Value": "$7,817",
            "Cost per Result": "$29.26"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "116745",
            "Clicks": "4202",
            "Conversions": "85",
            "Conversion Value": "$12,070",
            "Cost per Result": "$32.16"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "447847",
            "Clicks": "6207",
            "Conversions": "73",
            "Conversion Value": "$6,614",
            "Cost per Result": "$13.73"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "260588",
            "Clicks": "2930",
            "Conversions": "88",
            "Conversion Value": "$5,886",
            "Cost per Result": "$14.42"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "283375",
            "Clicks": "3436",
            "Conversions": "77",
            "Conversion Value": "$14,992",
            "Cost per Result": "$16.59"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "272736",
            "Clicks": "3624",
            "Conversions": "47",
            "Conversion Value": "$15,529",
            "Cost per Result": "$33.40"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "354918",
            "Clicks": "4275",
            "Conversions": "90",
            "Conversion Value": "$4,944",
            "Cost per Result": "$9.74"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "378380",
            "Clicks": "2777",
            "Conversions": "67",
            "Conversion Value": "$13,126",
            "Cost per Result": "$30.12"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "112566",
            "Clicks": "5929",
            "Conversions": "77",
            "Conversion Value": "$11,439",
            "Cost per Result": "$34.91"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "350059",
            "Clicks": "6922",
            "Conversions": "60",
            "Conversion Value": "$7,743",
            "Cost per Result": "$31.09"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "491503",
            "Clicks": "6971",
            "Conversions": "58",
            "Conversion Value": "$11,611",
            "Cost per Result": "$31.14"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "168888",
            "Clicks": "2450",
            "Conversions": "75",
            "Conversion Value": "$7,385",
            "Cost per Result": "$6.56"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "353455",
            "Clicks": "6933",
            "Conversions": "72",
            "Conversion Value": "$9,947",
            "Cost per Result": "$30.41"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "222498",
            "Clicks": "4334",
            "Conversions": "52",
            "Conversion Value": "$7,416",
            "Cost per Result": "$8.91"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "277054",
            "Clicks": "4695",
            "Conversions": "70",
            "Conversion Value": "$15,692",
            "Cost per Result": "$19.02"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "192241",
            "Clicks": "6387",
            "Conversions": "89",
            "Conversion Value": "$6,609",
            "Cost per Result": "$5.85"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "223090",
            "Clicks": "6031",
            "Conversions": "90",
            "Conversion Value": "$8,882",
            "Cost per Result": "$30.69"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "233697",
            "Clicks": "5323",
            "Conversions": "84",
            "Conversion Value": "$6,528",
            "Cost per Result": "$23.97"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "439867",
            "Clicks": "4151",
            "Conversions": "59",
            "Conversion Value": "$14,484",
            "Cost per Result": "$32.82"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "134273",
            "Clicks": "4762",
            "Conversions": "42",
            "Conversion Value": "$13,020",
            "Cost per Result": "$22.06"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "291535",
            "Clicks": "2755",
            "Conversions": "41",
            "Conversion Value": "$6,272",
            "Cost per Result": "$14.25"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "460364",
            "Clicks": "5539",
            "Conversions": "76",
            "Conversion Value": "$12,720",
            "Cost per Result": "$33.29"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "462158",
            "Clicks": "2327",
            "Conversions": "64",
            "Conversion Value": "$6,116",
            "Cost per Result": "$33.32"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "336141",
            "Clicks": "3998",
            "Conversions": "77",
            "Conversion Value": "$15,217",
            "Cost per Result": "$26.63"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "265551",
            "Clicks": "2881",
            "Conversions": "90",
            "Conversion Value": "$4,130",
            "Cost per Result": "$24.40"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "209783",
            "Clicks": "6841",
            "Conversions": "52",
            "Conversion Value": "$14,257",
            "Cost per Result": "$11.96"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "411895",
            "Clicks": "4066",
            "Conversions": "54",
            "Conversion Value": "$9,460",
            "Cost per Result": "$20.55"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "375143",
            "Clicks": "5390",
            "Conversions": "60",
            "Conversion Value": "$6,513",
            "Cost per Result": "$21.00"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "264273",
            "Clicks": "5502",
            "Conversions": "65",
            "Conversion Value": "$4,028",
            "Cost per Result": "$15.04"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "473457",
            "Clicks": "4879",
            "Conversions": "64",
            "Conversion Value": "$14,277",
            "Cost per Result": "$19.10"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "460629",
            "Clicks": "6233",
            "Conversions": "48",
            "Conversion Value": "$11,013",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "477393",
            "Clicks": "3353",
            "Conversions": "52",
            "Conversion Value": "$7,209",
            "Cost per Result": "$25.72"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "159009",
            "Clicks": "4524",
            "Conversions": "44",
            "Conversion Value": "$11,755",
            "Cost per Result": "$21.87"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "466907",
            "Clicks": "5695",
            "Conversions": "59",
            "Conversion Value": "$15,210",
            "Cost per Result": "$25.07"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "447960",
            "Clicks": "3506",
            "Conversions": "67",
            "Conversion Value": "$9,730",
            "Cost per Result": "$22.14"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "382414",
            "Clicks": "5772",
            "Conversions": "77",
            "Conversion Value": "$8,597",
            "Cost per Result": "$24.07"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "291875",
            "Clicks": "4881",
            "Conversions": "53",
            "Conversion Value": "$9,641",
            "Cost per Result": "$30.37"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "356399",
            "Clicks": "4172",
            "Conversions": "51",
            "Conversion Value": "$11,302",
            "Cost per Result": "$30.89"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "377055",
            "Clicks": "3487",
            "Conversions": "62",
            "Conversion Value": "$15,945",
            "Cost per Result": "$27.32"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "281690",
            "Clicks": "2062",
            "Conversions": "86",
            "Conversion Value": "$15,496",
            "Cost per Result": "$31.02"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "493040",
            "Clicks": "3783",
            "Conversions": "55",
            "Conversion Value": "$7,696",
            "Cost per Result": "$12.59"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "206538",
            "Clicks": "3167",
            "Conversions": "57",
            "Conversion Value": "$7,133",
            "Cost per Result": "$7.28"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "311995",
            "Clicks": "2347",
            "Conversions": "46",
            "Conversion Value": "$15,504",
            "Cost per Result": "$12.37"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "417663",
            "Clicks": "4624",
            "Conversions": "78",
            "Conversion Value": "$15,992",
            "Cost per Result": "$16.53"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "383963",
            "Clicks": "5565",
            "Conversions": "79",
            "Conversion Value": "$13,182",
            "Cost per Result": "$7.07"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "478365",
            "Clicks": "2642",
            "Conversions": "47",
            "Conversion Value": "$14,799",
            "Cost per Result": "$27.23"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "396534",
            "Clicks": "6511",
            "Conversions": "69",
            "Conversion Value": "$7,197",
            "Cost per Result": "$16.79"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "256615",
            "Clicks": "6504",
            "Conversions": "80",
            "Conversion Value": "$11,805",
            "Cost per Result": "$24.71"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "484064",
            "Clicks": "5251",
            "Conversions": "41",
            "Conversion Value": "$10,564",
            "Cost per Result": "$10.77"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "465826",
            "Clicks": "3128",
            "Conversions": "82",
            "Conversion Value": "$12,132",
            "Cost per Result": "$32.34"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "225197",
            "Clicks": "5928",
            "Conversions": "84",
            "Conversion Value": "$8,299",
            "Cost per Result": "$25.11"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "438334",
            "Clicks": "2260",
            "Conversions": "83",
            "Conversion Value": "$12,306",
            "Cost per Result": "$7.43"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "230827",
            "Clicks": "5787",
            "Conversions": "72",
            "Conversion Value": "$4,045",
            "Cost per Result": "$19.04"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "279178",
            "Clicks": "2409",
            "Conversions": "54",
            "Conversion Value": "$4,893",
            "Cost per Result": "$8.88"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "371464",
            "Clicks": "5354",
            "Conversions": "80",
            "Conversion Value": "$11,704",
            "Cost per Result": "$17.76"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "157387",
            "Clicks": "5213",
            "Conversions": "46",
            "Conversion Value": "$5,731",
            "Cost per Result": "$23.17"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "478484",
            "Clicks": "6993",
            "Conversions": "90",
            "Conversion Value": "$10,919",
            "Cost per Result": "$18.53"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "167062",
            "Clicks": "3421",
            "Conversions": "84",
            "Conversion Value": "$15,287",
            "Cost per Result": "$5.11"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "314112",
            "Clicks": "6653",
            "Conversions": "76",
            "Conversion Value": "$4,685",
            "Cost per Result": "$17.48"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "445344",
            "Clicks": "2486",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$8.42"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "357892",
            "Clicks": "5693",
            "Conversions": "74",
            "Conversion Value": "$13,974",
            "Cost per Result": "$29.18"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "114746",
            "Clicks": "4948",
            "Conversions": "45",
            "Conversion Value": "$13,462",
            "Cost per Result": "$16.57"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "301626",
            "Clicks": "2565",
            "Conversions": "54",
            "Conversion Value": "$11,667",
            "Cost per Result": "$26.99"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "400144",
            "Clicks": "4510",
            "Conversions": "72",
            "Conversion Value": "$13,745",
            "Cost per Result": "$10.14"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "360431",
            "Clicks": "6133",
            "Conversions": "81",
            "Conversion Value": "$9,039",
            "Cost per Result": "$24.97"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "364161",
            "Clicks": "4880",
            "Conversions": "86",
            "Conversion Value": "$13,877",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "255912",
            "Clicks": "5576",
            "Conversions": "72",
            "Conversion Value": "$13,722",
            "Cost per Result": "$14.34"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "348377",
            "Clicks": "2955",
            "Conversions": "54",
            "Conversion Value": "$9,452",
            "Cost per Result": "$34.05"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "351196",
            "Clicks": "2766",
            "Conversions": "84",
            "Conversion Value": "$11,426",
            "Cost per Result": "$28.59"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "172145",
            "Clicks": "3447",
            "Conversions": "49",
            "Conversion Value": "$6,405",
            "Cost per Result": "$29.63"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "240362",
            "Clicks": "4466",
            "Conversions": "73",
            "Conversion Value": "$5,620",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "428082",
            "Clicks": "5361",
            "Conversions": "83",
            "Conversion Value": "$14,281",
            "Cost per Result": "$23.91"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "121936",
            "Clicks": "3732",
            "Conversions": "76",
            "Conversion Value": "$4,651",
            "Cost per Result": "$13.01"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "156432",
            "Clicks": "6082",
            "Conversions": "51",
            "Conversion Value": "$15,053",
            "Cost per Result": "$13.83"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "303681",
            "Clicks": "5901",
            "Conversions": "83",
            "Conversion Value": "$9,321",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "344204",
            "Clicks": "6109",
            "Conversions": "46",
            "Conversion Value": "$6,385",
            "Cost per Result": "$22.75"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "261713",
            "Clicks": "2827",
            "Conversions": "69",
            "Conversion Value": "$14,289",
            "Cost per Result": "$33.17"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "174674",
            "Clicks": "2277",
            "Conversions": "52",
            "Conversion Value": "$10,930",
            "Cost per Result": "$34.30"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "259665",
            "Clicks": "3938",
            "Conversions": "63",
            "Conversion Value": "$10,656",
            "Cost per Result": "$29.26"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "475775",
            "Clicks": "6744",
            "Conversions": "53",
            "Conversion Value": "$8,032",
            "Cost per Result": "$16.45"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "153348",
            "Clicks": "5622",
            "Conversions": "47",
            "Conversion Value": "$13,104",
            "Cost per Result": "$12.65"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "239988",
            "Clicks": "3126",
            "Conversions": "85",
            "Conversion Value": "$14,813",
            "Cost per Result": "$28.21"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "174510",
            "Clicks": "6639",
            "Conversions": "70",
            "Conversion Value": "$7,776",
            "Cost per Result": "$24.45"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "103315",
            "Clicks": "4087",
            "Conversions": "43",
            "Conversion Value": "$9,539",
            "Cost per Result": "$5.56"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "196315",
            "Clicks": "6415",
            "Conversions": "41",
            "Conversion Value": "$10,892",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "497184",
            "Clicks": "5977",
            "Conversions": "86",
            "Conversion Value": "$12,197",
            "Cost per Result": "$27.75"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "406991",
            "Clicks": "6076",
            "Conversions": "69",
            "Conversion Value": "$7,557",
            "Cost per Result": "$14.50"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "476962",
            "Clicks": "2010",
            "Conversions": "53",
            "Conversion Value": "$5,532",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "111792",
            "Clicks": "2087",
            "Conversions": "56",
            "Conversion Value": "$5,473",
            "Cost per Result": "$7.43"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "167081",
            "Clicks": "5021",
            "Conversions": "40",
            "Conversion Value": "$6,163",
            "Cost per Result": "$33.70"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "383234",
            "Clicks": "5901",
            "Conversions": "76",
            "Conversion Value": "$7,685",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "360201",
            "Clicks": "2232",
            "Conversions": "71",
            "Conversion Value": "$14,917",
            "Cost per Result": "$16.71"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "403188",
            "Clicks": "6578",
            "Conversions": "56",
            "Conversion Value": "$14,322",
            "Cost per Result": "$26.00"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "211998",
            "Clicks": "6485",
            "Conversions": "84",
            "Conversion Value": "$10,700",
            "Cost per Result": "$31.71"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "286610",
            "Clicks": "4292",
            "Conversions": "47",
            "Conversion Value": "$13,573",
            "Cost per Result": "$14.28"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "361135",
            "Clicks": "6002",
            "Conversions": "41",
            "Conversion Value": "$7,149",
            "Cost per Result": "$5.26"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "125505",
            "Clicks": "5587",
            "Conversions": "42",
            "Conversion Value": "$9,085",
            "Cost per Result": "$17.99"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "283687",
            "Clicks": "6481",
            "Conversions": "82",
            "Conversion Value": "$7,300",
            "Cost per Result": "$30.95"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "304596",
            "Clicks": "4535",
            "Conversions": "68",
            "Conversion Value": "$8,501",
            "Cost per Result": "$28.51"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "202571",
            "Clicks": "2066",
            "Conversions": "76",
            "Conversion Value": "$13,582",
            "Cost per Result": "$27.93"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "259528",
            "Clicks": "5363",
            "Conversions": "88",
            "Conversion Value": "$14,025",
            "Cost per Result": "$10.34"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "485743",
            "Clicks": "3532",
            "Conversions": "62",
            "Conversion Value": "$10,720",
            "Cost per Result": "$16.74"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "398303",
            "Clicks": "5731",
            "Conversions": "60",
            "Conversion Value": "$14,558",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "484237",
            "Clicks": "6918",
            "Conversions": "52",
            "Conversion Value": "$7,600",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "191838",
            "Clicks": "6888",
            "Conversions": "44",
            "Conversion Value": "$7,941",
            "Cost per Result": "$34.34"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "215647",
            "Clicks": "6219",
            "Conversions": "87",
            "Conversion Value": "$7,690",
            "Cost per Result": "$11.11"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "146679",
            "Clicks": "2089",
            "Conversions": "53",
            "Conversion Value": "$14,955",
            "Cost per Result": "$9.34"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "254853",
            "Clicks": "5871",
            "Conversions": "74",
            "Conversion Value": "$7,162",
            "Cost per Result": "$16.07"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "269312",
            "Clicks": "2527",
            "Conversions": "52",
            "Conversion Value": "$11,672",
            "Cost per Result": "$16.28"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "247382",
            "Clicks": "4405",
            "Conversions": "68",
            "Conversion Value": "$13,238",
            "Cost per Result": "$5.99"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "266224",
            "Clicks": "6089",
            "Conversions": "43",
            "Conversion Value": "$7,227",
            "Cost per Result": "$14.22"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "238764",
            "Clicks": "3207",
            "Conversions": "58",
            "Conversion Value": "$12,101",
            "Cost per Result": "$31.39"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "126754",
            "Clicks": "6311",
            "Conversions": "48",
            "Conversion Value": "$5,522",
            "Cost per Result": "$15.76"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "273686",
            "Clicks": "5033",
            "Conversions": "78",
            "Conversion Value": "$8,889",
            "Cost per Result": "$25.55"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "118401",
            "Clicks": "3319",
            "Conversions": "49",
            "Conversion Value": "$9,569",
            "Cost per Result": "$23.25"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "451248",
            "Clicks": "6252",
            "Conversions": "78",
            "Conversion Value": "$4,160",
            "Cost per Result": "$10.47"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "478972",
            "Clicks": "6275",
            "Conversions": "68",
            "Conversion Value": "$7,278",
            "Cost per Result": "$24.78"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "347790",
            "Clicks": "5500",
            "Conversions": "80",
            "Conversion Value": "$5,640",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "490731",
            "Clicks": "5177",
            "Conversions": "69",
            "Conversion Value": "$9,247",
            "Cost per Result": "$14.66"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "188621",
            "Clicks": "6962",
            "Conversions": "57",
            "Conversion Value": "$6,240",
            "Cost per Result": "$12.59"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "123621",
            "Clicks": "2962",
            "Conversions": "62",
            "Conversion Value": "$15,467",
            "Cost per Result": "$34.97"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "247614",
            "Clicks": "4263",
            "Conversions": "90",
            "Conversion Value": "$15,983",
            "Cost per Result": "$30.82"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "173890",
            "Clicks": "4715",
            "Conversions": "45",
            "Conversion Value": "$10,514",
            "Cost per Result": "$9.26"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "393686",
            "Clicks": "5316",
            "Conversions": "52",
            "Conversion Value": "$6,684",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "418765",
            "Clicks": "2034",
            "Conversions": "67",
            "Conversion Value": "$8,557",
            "Cost per Result": "$6.46"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "131161",
            "Clicks": "4384",
            "Conversions": "88",
            "Conversion Value": "$15,596",
            "Cost per Result": "$8.45"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "356002",
            "Clicks": "5842",
            "Conversions": "64",
            "Conversion Value": "$5,905",
            "Cost per Result": "$22.41"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "374003",
            "Clicks": "6588",
            "Conversions": "50",
            "Conversion Value": "$4,713",
            "Cost per Result": "$29.60"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "479131",
            "Clicks": "6134",
            "Conversions": "73",
            "Conversion Value": "$12,596",
            "Cost per Result": "$20.42"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "160019",
            "Clicks": "6432",
            "Conversions": "71",
            "Conversion Value": "$6,488",
            "Cost per Result": "$21.49"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "216546",
            "Clicks": "6771",
            "Conversions": "66",
            "Conversion Value": "$11,022",
            "Cost per Result": "$7.92"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "389978",
            "Clicks": "4328",
            "Conversions": "60",
            "Conversion Value": "$12,201",
            "Cost per Result": "$14.74"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "177679",
            "Clicks": "6586",
            "Conversions": "77",
            "Conversion Value": "$7,494",
            "Cost per Result": "$27.59"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "438574",
            "Clicks": "2285",
            "Conversions": "54",
            "Conversion Value": "$11,451",
            "Cost per Result": "$23.49"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "326259",
            "Clicks": "5076",
            "Conversions": "40",
            "Conversion Value": "$9,092",
            "Cost per Result": "$23.16"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "331637",
            "Clicks": "2745",
            "Conversions": "65",
            "Conversion Value": "$7,006",
            "Cost per Result": "$7.91"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "344126",
            "Clicks": "4277",
            "Conversions": "79",
            "Conversion Value": "$11,787",
            "Cost per Result": "$28.51"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "449781",
            "Clicks": "6137",
            "Conversions": "75",
            "Conversion Value": "$14,586",
            "Cost per Result": "$10.70"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "345616",
            "Clicks": "3965",
            "Conversions": "47",
            "Conversion Value": "$7,617",
            "Cost per Result": "$34.98"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "167479",
            "Clicks": "6000",
            "Conversions": "58",
            "Conversion Value": "$9,933",
            "Cost per Result": "$31.68"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "319661",
            "Clicks": "6043",
            "Conversions": "51",
            "Conversion Value": "$15,130",
            "Cost per Result": "$15.54"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "364013",
            "Clicks": "3212",
            "Conversions": "54",
            "Conversion Value": "$10,513",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "463394",
            "Clicks": "2425",
            "Conversions": "61",
            "Conversion Value": "$11,319",
            "Cost per Result": "$12.99"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "475703",
            "Clicks": "2818",
            "Conversions": "60",
            "Conversion Value": "$15,149",
            "Cost per Result": "$17.48"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "257189",
            "Clicks": "3207",
            "Conversions": "66",
            "Conversion Value": "$13,156",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "355695",
            "Clicks": "5506",
            "Conversions": "51",
            "Conversion Value": "$15,535",
            "Cost per Result": "$25.85"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "428086",
            "Clicks": "5681",
            "Conversions": "65",
            "Conversion Value": "$15,366",
            "Cost per Result": "$21.89"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "357582",
            "Clicks": "2766",
            "Conversions": "74",
            "Conversion Value": "$14,495",
            "Cost per Result": "$17.97"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "448991",
            "Clicks": "2504",
            "Conversions": "49",
            "Conversion Value": "$8,523",
            "Cost per Result": "$21.81"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "383188",
            "Clicks": "2141",
            "Conversions": "77",
            "Conversion Value": "$15,569",
            "Cost per Result": "$18.38"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "298819",
            "Clicks": "2039",
            "Conversions": "70",
            "Conversion Value": "$4,526",
            "Cost per Result": "$15.39"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "184720",
            "Clicks": "5415",
            "Conversions": "75",
            "Conversion Value": "$7,640",
            "Cost per Result": "$11.22"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "118764",
            "Clicks": "6451",
            "Conversions": "41",
            "Conversion Value": "$11,058",
            "Cost per Result": "$8.25"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "170537",
            "Clicks": "4791",
            "Conversions": "67",
            "Conversion Value": "$6,141",
            "Cost per Result": "$24.50"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "378694",
            "Clicks": "2532",
            "Conversions": "84",
            "Conversion Value": "$7,414",
            "Cost per Result": "$14.18"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "346338",
            "Clicks": "5695",
            "Conversions": "65",
            "Conversion Value": "$14,385",
            "Cost per Result": "$9.46"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "332622",
            "Clicks": "5394",
            "Conversions": "62",
            "Conversion Value": "$6,902",
            "Cost per Result": "$27.40"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "175425",
            "Clicks": "5969",
            "Conversions": "70",
            "Conversion Value": "$15,006",
            "Cost per Result": "$19.11"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "192358",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$8,398",
            "Cost per Result": "$15.51"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "439743",
            "Clicks": "2646",
            "Conversions": "58",
            "Conversion Value": "$6,664",
            "Cost per Result": "$9.00"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "363653",
            "Clicks": "6894",
            "Conversions": "86",
            "Conversion Value": "$15,369",
            "Cost per Result": "$30.79"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "335878",
            "Clicks": "4908",
            "Conversions": "42",
            "Conversion Value": "$8,131",
            "Cost per Result": "$32.93"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "230896",
            "Clicks": "3825",
            "Conversions": "62",
            "Conversion Value": "$7,020",
            "Cost per Result": "$15.96"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "186541",
            "Clicks": "4896",
            "Conversions": "56",
            "Conversion Value": "$15,950",
            "Cost per Result": "$6.13"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "387280",
            "Clicks": "4768",
            "Conversions": "74",
            "Conversion Value": "$4,957",
            "Cost per Result": "$23.82"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "358968",
            "Clicks": "2156",
            "Conversions": "73",
            "Conversion Value": "$8,905",
            "Cost per Result": "$29.79"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "310985",
            "Clicks": "2481",
            "Conversions": "48",
            "Conversion Value": "$7,927",
            "Cost per Result": "$28.95"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "159148",
            "Clicks": "4205",
            "Conversions": "50",
            "Conversion Value": "$6,695",
            "Cost per Result": "$7.48"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "189681",
            "Clicks": "5149",
            "Conversions": "47",
            "Conversion Value": "$12,550",
            "Cost per Result": "$29.60"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "447950",
            "Clicks": "4996",
            "Conversions": "65",
            "Conversion Value": "$12,281",
            "Cost per Result": "$7.17"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "381580",
            "Clicks": "3120",
            "Conversions": "55",
            "Conversion Value": "$11,333",
            "Cost per Result": "$16.52"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "366564",
            "Clicks": "3570",
            "Conversions": "83",
            "Conversion Value": "$6,409",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "439156",
            "Clicks": "5149",
            "Conversions": "63",
            "Conversion Value": "$10,288",
            "Cost per Result": "$29.67"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "259998",
            "Clicks": "6280",
            "Conversions": "85",
            "Conversion Value": "$14,436",
            "Cost per Result": "$16.34"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "260217",
            "Clicks": "4742",
            "Conversions": "60",
            "Conversion Value": "$14,959",
            "Cost per Result": "$19.51"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "275359",
            "Clicks": "3476",
            "Conversions": "72",
            "Conversion Value": "$15,607",
            "Cost per Result": "$14.10"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "400366",
            "Clicks": "6286",
            "Conversions": "73",
            "Conversion Value": "$12,966",
            "Cost per Result": "$15.42"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "144887",
            "Clicks": "4305",
            "Conversions": "86",
            "Conversion Value": "$4,182",
            "Cost per Result": "$34.24"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "492352",
            "Clicks": "5128",
            "Conversions": "57",
            "Conversion Value": "$5,137",
            "Cost per Result": "$22.43"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "131276",
            "Clicks": "4067",
            "Conversions": "57",
            "Conversion Value": "$10,581",
            "Cost per Result": "$21.70"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "163988",
            "Clicks": "4643",
            "Conversions": "65",
            "Conversion Value": "$14,973",
            "Cost per Result": "$10.62"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "335679",
            "Clicks": "5903",
            "Conversions": "66",
            "Conversion Value": "$6,941",
            "Cost per Result": "$25.49"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "100991",
            "Clicks": "5784",
            "Conversions": "43",
            "Conversion Value": "$15,456",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "498779",
            "Clicks": "2202",
            "Conversions": "90",
            "Conversion Value": "$6,396",
            "Cost per Result": "$10.56"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "119368",
            "Clicks": "2856",
            "Conversions": "61",
            "Conversion Value": "$9,952",
            "Cost per Result": "$24.80"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "345663",
            "Clicks": "3989",
            "Conversions": "81",
            "Conversion Value": "$11,490",
            "Cost per Result": "$28.15"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "430194",
            "Clicks": "3396",
            "Conversions": "70",
            "Conversion Value": "$13,819",
            "Cost per Result": "$16.03"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "350348",
            "Clicks": "5589",
            "Conversions": "71",
            "Conversion Value": "$10,878",
            "Cost per Result": "$15.90"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "315354",
            "Clicks": "5402",
            "Conversions": "85",
            "Conversion Value": "$10,247",
            "Cost per Result": "$8.25"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "371542",
            "Clicks": "4484",
            "Conversions": "82",
            "Conversion Value": "$8,064",
            "Cost per Result": "$32.03"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "384824",
            "Clicks": "2790",
            "Conversions": "78",
            "Conversion Value": "$11,215",
            "Cost per Result": "$24.01"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "214290",
            "Clicks": "6441",
            "Conversions": "72",
            "Conversion Value": "$12,632",
            "Cost per Result": "$19.15"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "334573",
            "Clicks": "3353",
            "Conversions": "76",
            "Conversion Value": "$15,106",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "210999",
            "Clicks": "5604",
            "Conversions": "68",
            "Conversion Value": "$7,505",
            "Cost per Result": "$33.67"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "330328",
            "Clicks": "4526",
            "Conversions": "52",
            "Conversion Value": "$15,765",
            "Cost per Result": "$8.74"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "229250",
            "Clicks": "4410",
            "Conversions": "64",
            "Conversion Value": "$7,230",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "348981",
            "Clicks": "2690",
            "Conversions": "43",
            "Conversion Value": "$12,282",
            "Cost per Result": "$10.25"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "273530",
            "Clicks": "5247",
            "Conversions": "43",
            "Conversion Value": "$7,264",
            "Cost per Result": "$26.25"
          }
        ]
      },
      "BTCETHWhalesData": {
        "name": "BTC/ETH whales",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "225308",
            "Clicks": "5576",
            "Conversions": "51",
            "Conversion Value": "$12,773",
            "Cost per Result": "$20.25"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490944",
            "Clicks": "4040",
            "Conversions": "53",
            "Conversion Value": "$13,674",
            "Cost per Result": "$32.24"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "409729",
            "Clicks": "3991",
            "Conversions": "50",
            "Conversion Value": "$5,314",
            "Cost per Result": "$19.68"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "399053",
            "Clicks": "3321",
            "Conversions": "58",
            "Conversion Value": "$9,608",
            "Cost per Result": "$7.24"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "148000",
            "Clicks": "3966",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$34.93"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "116623",
            "Clicks": "4377",
            "Conversions": "59",
            "Conversion Value": "$9,692",
            "Cost per Result": "$29.14"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "301271",
            "Clicks": "2328",
            "Conversions": "58",
            "Conversion Value": "$14,634",
            "Cost per Result": "$32.44"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "169921",
            "Clicks": "2277",
            "Conversions": "88",
            "Conversion Value": "$12,055",
            "Cost per Result": "$10.47"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "205455",
            "Clicks": "4590",
            "Conversions": "49",
            "Conversion Value": "$13,491",
            "Cost per Result": "$7.00"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "205595",
            "Clicks": "5334",
            "Conversions": "82",
            "Conversion Value": "$10,788",
            "Cost per Result": "$23.26"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "228198",
            "Clicks": "6548",
            "Conversions": "89",
            "Conversion Value": "$12,078",
            "Cost per Result": "$13.52"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "264256",
            "Clicks": "4536",
            "Conversions": "88",
            "Conversion Value": "$4,424",
            "Cost per Result": "$23.00"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "279468",
            "Clicks": "6741",
            "Conversions": "61",
            "Conversion Value": "$5,120",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "167406",
            "Clicks": "5555",
            "Conversions": "56",
            "Conversion Value": "$9,358",
            "Cost per Result": "$16.48"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "406312",
            "Clicks": "4696",
            "Conversions": "72",
            "Conversion Value": "$13,458",
            "Cost per Result": "$20.30"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "286974",
            "Clicks": "6572",
            "Conversions": "58",
            "Conversion Value": "$11,326",
            "Cost per Result": "$30.61"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "217467",
            "Clicks": "3618",
            "Conversions": "76",
            "Conversion Value": "$9,331",
            "Cost per Result": "$5.82"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "358922",
            "Clicks": "4596",
            "Conversions": "85",
            "Conversion Value": "$4,858",
            "Cost per Result": "$24.59"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "148593",
            "Clicks": "5337",
            "Conversions": "87",
            "Conversion Value": "$10,179",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "249426",
            "Clicks": "2861",
            "Conversions": "71",
            "Conversion Value": "$15,493",
            "Cost per Result": "$28.47"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "111491",
            "Clicks": "5023",
            "Conversions": "59",
            "Conversion Value": "$13,085",
            "Cost per Result": "$23.44"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "329661",
            "Clicks": "4762",
            "Conversions": "87",
            "Conversion Value": "$15,765",
            "Cost per Result": "$15.24"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "269594",
            "Clicks": "4501",
            "Conversions": "69",
            "Conversion Value": "$15,344",
            "Cost per Result": "$25.45"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "240234",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$14,913",
            "Cost per Result": "$16.72"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "427326",
            "Clicks": "4495",
            "Conversions": "79",
            "Conversion Value": "$13,638",
            "Cost per Result": "$9.95"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "137002",
            "Clicks": "4298",
            "Conversions": "87",
            "Conversion Value": "$13,903",
            "Cost per Result": "$25.55"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "274045",
            "Clicks": "4617",
            "Conversions": "65",
            "Conversion Value": "$6,479",
            "Cost per Result": "$10.96"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "445786",
            "Clicks": "2914",
            "Conversions": "47",
            "Conversion Value": "$14,055",
            "Cost per Result": "$22.67"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "426620",
            "Clicks": "2195",
            "Conversions": "81",
            "Conversion Value": "$6,478",
            "Cost per Result": "$5.12"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "485116",
            "Clicks": "2347",
            "Conversions": "66",
            "Conversion Value": "$10,151",
            "Cost per Result": "$7.93"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "392252",
            "Clicks": "4743",
            "Conversions": "74",
            "Conversion Value": "$5,870",
            "Cost per Result": "$9.46"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "245584",
            "Clicks": "4729",
            "Conversions": "42",
            "Conversion Value": "$10,568",
            "Cost per Result": "$23.67"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "229926",
            "Clicks": "6069",
            "Conversions": "40",
            "Conversion Value": "$15,136",
            "Cost per Result": "$6.03"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "274703",
            "Clicks": "3259",
            "Conversions": "65",
            "Conversion Value": "$15,505",
            "Cost per Result": "$20.91"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "211153",
            "Clicks": "6159",
            "Conversions": "73",
            "Conversion Value": "$13,467",
            "Cost per Result": "$12.15"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "372039",
            "Clicks": "2409",
            "Conversions": "47",
            "Conversion Value": "$5,733",
            "Cost per Result": "$6.49"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "436122",
            "Clicks": "3316",
            "Conversions": "61",
            "Conversion Value": "$7,836",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "346319",
            "Clicks": "4624",
            "Conversions": "85",
            "Conversion Value": "$7,275",
            "Cost per Result": "$24.61"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "398780",
            "Clicks": "2918",
            "Conversions": "73",
            "Conversion Value": "$15,296",
            "Cost per Result": "$15.80"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "151266",
            "Clicks": "4634",
            "Conversions": "45",
            "Conversion Value": "$14,669",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "243794",
            "Clicks": "2041",
            "Conversions": "81",
            "Conversion Value": "$5,299",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "158250",
            "Clicks": "4646",
            "Conversions": "71",
            "Conversion Value": "$14,888",
            "Cost per Result": "$8.30"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "231132",
            "Clicks": "3276",
            "Conversions": "78",
            "Conversion Value": "$8,033",
            "Cost per Result": "$13.94"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "230934",
            "Clicks": "3020",
            "Conversions": "52",
            "Conversion Value": "$4,924",
            "Cost per Result": "$22.76"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "112748",
            "Clicks": "3977",
            "Conversions": "46",
            "Conversion Value": "$11,202",
            "Cost per Result": "$31.64"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "260642",
            "Clicks": "5299",
            "Conversions": "60",
            "Conversion Value": "$15,289",
            "Cost per Result": "$9.27"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "104385",
            "Clicks": "4997",
            "Conversions": "40",
            "Conversion Value": "$12,068",
            "Cost per Result": "$17.20"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "353096",
            "Clicks": "5637",
            "Conversions": "49",
            "Conversion Value": "$9,590",
            "Cost per Result": "$24.92"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "158840",
            "Clicks": "3596",
            "Conversions": "54",
            "Conversion Value": "$15,903",
            "Cost per Result": "$14.02"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "110742",
            "Clicks": "3015",
            "Conversions": "71",
            "Conversion Value": "$6,429",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "151585",
            "Clicks": "5416",
            "Conversions": "75",
            "Conversion Value": "$6,910",
            "Cost per Result": "$34.62"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "444463",
            "Clicks": "3273",
            "Conversions": "81",
            "Conversion Value": "$11,423",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "292951",
            "Clicks": "6505",
            "Conversions": "51",
            "Conversion Value": "$15,015",
            "Cost per Result": "$23.71"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "419873",
            "Clicks": "3577",
            "Conversions": "65",
            "Conversion Value": "$8,581",
            "Cost per Result": "$9.96"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "281894",
            "Clicks": "5810",
            "Conversions": "63",
            "Conversion Value": "$10,222",
            "Cost per Result": "$14.08"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "373471",
            "Clicks": "5776",
            "Conversions": "77",
            "Conversion Value": "$13,733",
            "Cost per Result": "$26.56"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "194989",
            "Clicks": "5997",
            "Conversions": "72",
            "Conversion Value": "$7,229",
            "Cost per Result": "$9.95"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "230901",
            "Clicks": "2206",
            "Conversions": "90",
            "Conversion Value": "$11,433",
            "Cost per Result": "$20.99"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "221728",
            "Clicks": "4038",
            "Conversions": "55",
            "Conversion Value": "$6,968",
            "Cost per Result": "$28.77"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "363000",
            "Clicks": "4352",
            "Conversions": "41",
            "Conversion Value": "$13,890",
            "Cost per Result": "$19.31"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "156364",
            "Clicks": "5798",
            "Conversions": "52",
            "Conversion Value": "$14,518",
            "Cost per Result": "$24.33"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "495714",
            "Clicks": "3866",
            "Conversions": "41",
            "Conversion Value": "$7,966",
            "Cost per Result": "$14.36"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "460986",
            "Clicks": "4667",
            "Conversions": "73",
            "Conversion Value": "$14,410",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "249487",
            "Clicks": "6258",
            "Conversions": "42",
            "Conversion Value": "$4,325",
            "Cost per Result": "$10.05"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "429485",
            "Clicks": "4550",
            "Conversions": "68",
            "Conversion Value": "$14,437",
            "Cost per Result": "$10.12"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "455394",
            "Clicks": "2158",
            "Conversions": "72",
            "Conversion Value": "$6,059",
            "Cost per Result": "$7.91"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "393859",
            "Clicks": "6760",
            "Conversions": "61",
            "Conversion Value": "$9,641",
            "Cost per Result": "$22.62"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "343723",
            "Clicks": "5944",
            "Conversions": "74",
            "Conversion Value": "$11,758",
            "Cost per Result": "$33.60"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "190384",
            "Clicks": "3335",
            "Conversions": "70",
            "Conversion Value": "$8,698",
            "Cost per Result": "$22.56"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "221492",
            "Clicks": "3283",
            "Conversions": "84",
            "Conversion Value": "$5,558",
            "Cost per Result": "$20.25"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "392605",
            "Clicks": "6326",
            "Conversions": "46",
            "Conversion Value": "$5,275",
            "Cost per Result": "$10.38"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "375472",
            "Clicks": "6323",
            "Conversions": "52",
            "Conversion Value": "$4,105",
            "Cost per Result": "$26.86"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "350680",
            "Clicks": "6596",
            "Conversions": "49",
            "Conversion Value": "$14,558",
            "Cost per Result": "$16.47"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "215013",
            "Clicks": "3969",
            "Conversions": "62",
            "Conversion Value": "$12,246",
            "Cost per Result": "$20.22"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "463903",
            "Clicks": "2972",
            "Conversions": "54",
            "Conversion Value": "$15,702",
            "Cost per Result": "$32.63"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "293595",
            "Clicks": "5507",
            "Conversions": "65",
            "Conversion Value": "$14,215",
            "Cost per Result": "$10.94"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "447628",
            "Clicks": "4128",
            "Conversions": "76",
            "Conversion Value": "$7,719",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "340403",
            "Clicks": "4406",
            "Conversions": "48",
            "Conversion Value": "$14,706",
            "Cost per Result": "$7.61"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "311341",
            "Clicks": "3638",
            "Conversions": "83",
            "Conversion Value": "$14,928",
            "Cost per Result": "$24.84"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "345252",
            "Clicks": "3067",
            "Conversions": "68",
            "Conversion Value": "$9,980",
            "Cost per Result": "$21.89"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "296129",
            "Clicks": "2442",
            "Conversions": "77",
            "Conversion Value": "$11,616",
            "Cost per Result": "$34.29"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "155060",
            "Clicks": "3754",
            "Conversions": "44",
            "Conversion Value": "$15,491",
            "Cost per Result": "$15.86"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "463829",
            "Clicks": "5433",
            "Conversions": "86",
            "Conversion Value": "$11,612",
            "Cost per Result": "$23.65"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "272238",
            "Clicks": "5578",
            "Conversions": "77",
            "Conversion Value": "$13,830",
            "Cost per Result": "$22.92"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "404113",
            "Clicks": "2673",
            "Conversions": "82",
            "Conversion Value": "$5,839",
            "Cost per Result": "$8.52"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "240589",
            "Clicks": "6948",
            "Conversions": "86",
            "Conversion Value": "$12,339",
            "Cost per Result": "$26.75"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "428596",
            "Clicks": "4499",
            "Conversions": "77",
            "Conversion Value": "$9,239",
            "Cost per Result": "$17.29"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "239459",
            "Clicks": "3968",
            "Conversions": "46",
            "Conversion Value": "$8,794",
            "Cost per Result": "$18.85"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "132800",
            "Clicks": "4396",
            "Conversions": "54",
            "Conversion Value": "$12,347",
            "Cost per Result": "$15.51"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "490202",
            "Clicks": "2133",
            "Conversions": "70",
            "Conversion Value": "$12,351",
            "Cost per Result": "$20.58"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "388772",
            "Clicks": "3514",
            "Conversions": "77",
            "Conversion Value": "$7,703",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "450004",
            "Clicks": "5019",
            "Conversions": "42",
            "Conversion Value": "$7,481",
            "Cost per Result": "$30.66"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "425949",
            "Clicks": "5164",
            "Conversions": "83",
            "Conversion Value": "$7,808",
            "Cost per Result": "$30.98"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "297381",
            "Clicks": "3312",
            "Conversions": "74",
            "Conversion Value": "$12,090",
            "Cost per Result": "$24.29"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "189343",
            "Clicks": "6907",
            "Conversions": "74",
            "Conversion Value": "$4,704",
            "Cost per Result": "$27.78"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "303202",
            "Clicks": "6122",
            "Conversions": "62",
            "Conversion Value": "$4,303",
            "Cost per Result": "$5.18"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "237208",
            "Clicks": "4302",
            "Conversions": "78",
            "Conversion Value": "$12,006",
            "Cost per Result": "$20.96"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "264158",
            "Clicks": "2399",
            "Conversions": "85",
            "Conversion Value": "$13,527",
            "Cost per Result": "$22.70"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "441641",
            "Clicks": "5119",
            "Conversions": "69",
            "Conversion Value": "$11,394",
            "Cost per Result": "$17.19"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "216566",
            "Clicks": "6313",
            "Conversions": "50",
            "Conversion Value": "$8,151",
            "Cost per Result": "$22.00"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "152369",
            "Clicks": "6528",
            "Conversions": "42",
            "Conversion Value": "$7,708",
            "Cost per Result": "$28.44"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "242990",
            "Clicks": "4218",
            "Conversions": "85",
            "Conversion Value": "$5,543",
            "Cost per Result": "$26.31"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "325308",
            "Clicks": "2279",
            "Conversions": "64",
            "Conversion Value": "$10,593",
            "Cost per Result": "$23.52"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "259394",
            "Clicks": "2445",
            "Conversions": "90",
            "Conversion Value": "$6,457",
            "Cost per Result": "$25.66"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "333287",
            "Clicks": "6258",
            "Conversions": "60",
            "Conversion Value": "$4,745",
            "Cost per Result": "$15.93"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "284502",
            "Clicks": "6695",
            "Conversions": "64",
            "Conversion Value": "$11,375",
            "Cost per Result": "$31.37"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "278064",
            "Clicks": "5467",
            "Conversions": "90",
            "Conversion Value": "$13,712",
            "Cost per Result": "$14.04"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "335329",
            "Clicks": "2295",
            "Conversions": "48",
            "Conversion Value": "$6,229",
            "Cost per Result": "$17.98"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "402901",
            "Clicks": "2039",
            "Conversions": "41",
            "Conversion Value": "$14,244",
            "Cost per Result": "$9.80"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "183996",
            "Clicks": "6814",
            "Conversions": "80",
            "Conversion Value": "$15,983",
            "Cost per Result": "$15.49"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "437367",
            "Clicks": "3331",
            "Conversions": "57",
            "Conversion Value": "$14,128",
            "Cost per Result": "$11.31"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "403077",
            "Clicks": "6347",
            "Conversions": "70",
            "Conversion Value": "$9,113",
            "Cost per Result": "$10.59"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "245817",
            "Clicks": "2404",
            "Conversions": "62",
            "Conversion Value": "$12,061",
            "Cost per Result": "$11.48"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "202892",
            "Clicks": "5759",
            "Conversions": "40",
            "Conversion Value": "$7,717",
            "Cost per Result": "$13.71"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "234448",
            "Clicks": "2222",
            "Conversions": "45",
            "Conversion Value": "$10,319",
            "Cost per Result": "$34.18"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "138975",
            "Clicks": "6952",
            "Conversions": "55",
            "Conversion Value": "$9,917",
            "Cost per Result": "$33.77"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "116499",
            "Clicks": "4583",
            "Conversions": "85",
            "Conversion Value": "$15,447",
            "Cost per Result": "$7.13"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "186911",
            "Clicks": "4008",
            "Conversions": "89",
            "Conversion Value": "$7,988",
            "Cost per Result": "$16.70"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "139979",
            "Clicks": "4134",
            "Conversions": "87",
            "Conversion Value": "$13,437",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "322026",
            "Clicks": "2617",
            "Conversions": "89",
            "Conversion Value": "$9,287",
            "Cost per Result": "$12.54"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "327218",
            "Clicks": "5957",
            "Conversions": "51",
            "Conversion Value": "$5,756",
            "Cost per Result": "$10.93"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "311060",
            "Clicks": "4063",
            "Conversions": "54",
            "Conversion Value": "$8,818",
            "Cost per Result": "$34.21"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "421991",
            "Clicks": "2699",
            "Conversions": "87",
            "Conversion Value": "$9,310",
            "Cost per Result": "$8.28"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "261472",
            "Clicks": "6155",
            "Conversions": "42",
            "Conversion Value": "$9,340",
            "Cost per Result": "$26.36"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "330447",
            "Clicks": "4804",
            "Conversions": "49",
            "Conversion Value": "$6,796",
            "Cost per Result": "$7.67"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "452157",
            "Clicks": "4927",
            "Conversions": "60",
            "Conversion Value": "$11,236",
            "Cost per Result": "$12.66"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "300476",
            "Clicks": "6206",
            "Conversions": "44",
            "Conversion Value": "$9,990",
            "Cost per Result": "$16.82"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "388970",
            "Clicks": "3549",
            "Conversions": "89",
            "Conversion Value": "$4,039",
            "Cost per Result": "$32.59"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "215511",
            "Clicks": "6890",
            "Conversions": "65",
            "Conversion Value": "$12,956",
            "Cost per Result": "$8.85"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "322846",
            "Clicks": "3394",
            "Conversions": "90",
            "Conversion Value": "$5,361",
            "Cost per Result": "$26.61"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "469442",
            "Clicks": "5099",
            "Conversions": "62",
            "Conversion Value": "$4,988",
            "Cost per Result": "$18.37"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "301822",
            "Clicks": "5477",
            "Conversions": "51",
            "Conversion Value": "$11,999",
            "Cost per Result": "$19.17"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "106840",
            "Clicks": "2736",
            "Conversions": "83",
            "Conversion Value": "$5,728",
            "Cost per Result": "$30.91"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "196750",
            "Clicks": "2712",
            "Conversions": "41",
            "Conversion Value": "$15,885",
            "Cost per Result": "$9.19"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "206002",
            "Clicks": "5711",
            "Conversions": "86",
            "Conversion Value": "$7,278",
            "Cost per Result": "$14.79"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "470725",
            "Clicks": "5537",
            "Conversions": "80",
            "Conversion Value": "$12,126",
            "Cost per Result": "$35.00"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "281280",
            "Clicks": "6926",
            "Conversions": "66",
            "Conversion Value": "$9,611",
            "Cost per Result": "$27.12"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "312620",
            "Clicks": "4218",
            "Conversions": "52",
            "Conversion Value": "$6,311",
            "Cost per Result": "$16.65"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "397433",
            "Clicks": "2025",
            "Conversions": "51",
            "Conversion Value": "$7,868",
            "Cost per Result": "$6.35"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "114913",
            "Clicks": "3453",
            "Conversions": "73",
            "Conversion Value": "$12,141",
            "Cost per Result": "$30.81"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "214473",
            "Clicks": "5642",
            "Conversions": "75",
            "Conversion Value": "$14,519",
            "Cost per Result": "$24.07"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "266027",
            "Clicks": "2078",
            "Conversions": "60",
            "Conversion Value": "$15,874",
            "Cost per Result": "$6.85"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "190937",
            "Clicks": "3143",
            "Conversions": "70",
            "Conversion Value": "$5,454",
            "Cost per Result": "$24.23"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "399564",
            "Clicks": "2655",
            "Conversions": "59",
            "Conversion Value": "$14,506",
            "Cost per Result": "$6.40"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "221426",
            "Clicks": "6753",
            "Conversions": "88",
            "Conversion Value": "$4,010",
            "Cost per Result": "$30.06"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "280377",
            "Clicks": "5370",
            "Conversions": "81",
            "Conversion Value": "$6,507",
            "Cost per Result": "$31.17"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "407192",
            "Clicks": "5722",
            "Conversions": "47",
            "Conversion Value": "$8,143",
            "Cost per Result": "$14.53"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "324735",
            "Clicks": "4921",
            "Conversions": "89",
            "Conversion Value": "$11,677",
            "Cost per Result": "$13.13"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "473920",
            "Clicks": "3136",
            "Conversions": "83",
            "Conversion Value": "$4,533",
            "Cost per Result": "$7.06"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "194693",
            "Clicks": "5348",
            "Conversions": "89",
            "Conversion Value": "$15,521",
            "Cost per Result": "$33.19"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "157598",
            "Clicks": "3069",
            "Conversions": "52",
            "Conversion Value": "$8,676",
            "Cost per Result": "$18.24"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "452124",
            "Clicks": "4983",
            "Conversions": "48",
            "Conversion Value": "$10,818",
            "Cost per Result": "$13.33"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "157539",
            "Clicks": "4619",
            "Conversions": "89",
            "Conversion Value": "$6,762",
            "Cost per Result": "$18.69"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "113835",
            "Clicks": "3902",
            "Conversions": "54",
            "Conversion Value": "$10,849",
            "Cost per Result": "$16.53"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "266441",
            "Clicks": "5784",
            "Conversions": "62",
            "Conversion Value": "$4,835",
            "Cost per Result": "$22.01"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "155309",
            "Clicks": "5321",
            "Conversions": "77",
            "Conversion Value": "$9,257",
            "Cost per Result": "$10.91"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "373645",
            "Clicks": "6857",
            "Conversions": "55",
            "Conversion Value": "$9,819",
            "Cost per Result": "$5.06"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "137541",
            "Clicks": "6879",
            "Conversions": "46",
            "Conversion Value": "$9,766",
            "Cost per Result": "$7.60"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "123045",
            "Clicks": "2781",
            "Conversions": "80",
            "Conversion Value": "$4,882",
            "Cost per Result": "$18.32"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "181179",
            "Clicks": "5664",
            "Conversions": "54",
            "Conversion Value": "$7,951",
            "Cost per Result": "$9.06"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "496701",
            "Clicks": "6715",
            "Conversions": "85",
            "Conversion Value": "$6,738",
            "Cost per Result": "$10.76"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "217839",
            "Clicks": "3152",
            "Conversions": "83",
            "Conversion Value": "$12,936",
            "Cost per Result": "$7.22"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "275305",
            "Clicks": "6292",
            "Conversions": "71",
            "Conversion Value": "$7,886",
            "Cost per Result": "$8.40"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "374624",
            "Clicks": "3084",
            "Conversions": "51",
            "Conversion Value": "$9,099",
            "Cost per Result": "$13.25"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "264952",
            "Clicks": "3227",
            "Conversions": "66",
            "Conversion Value": "$13,026",
            "Cost per Result": "$34.82"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "190127",
            "Clicks": "2096",
            "Conversions": "42",
            "Conversion Value": "$11,422",
            "Cost per Result": "$28.06"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "224797",
            "Clicks": "4979",
            "Conversions": "53",
            "Conversion Value": "$12,743",
            "Cost per Result": "$29.08"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "445574",
            "Clicks": "6963",
            "Conversions": "43",
            "Conversion Value": "$11,791",
            "Cost per Result": "$18.83"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "112520",
            "Clicks": "6014",
            "Conversions": "55",
            "Conversion Value": "$15,474",
            "Cost per Result": "$11.56"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "381388",
            "Clicks": "3344",
            "Conversions": "69",
            "Conversion Value": "$9,019",
            "Cost per Result": "$12.35"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "295042",
            "Clicks": "5607",
            "Conversions": "49",
            "Conversion Value": "$7,397",
            "Cost per Result": "$14.14"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "446900",
            "Clicks": "3485",
            "Conversions": "59",
            "Conversion Value": "$8,192",
            "Cost per Result": "$23.99"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "143563",
            "Clicks": "4099",
            "Conversions": "61",
            "Conversion Value": "$5,644",
            "Cost per Result": "$27.95"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "119724",
            "Clicks": "3561",
            "Conversions": "71",
            "Conversion Value": "$9,788",
            "Cost per Result": "$26.47"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "118266",
            "Clicks": "5796",
            "Conversions": "68",
            "Conversion Value": "$5,510",
            "Cost per Result": "$33.08"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "383640",
            "Clicks": "6827",
            "Conversions": "47",
            "Conversion Value": "$12,279",
            "Cost per Result": "$19.32"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "104223",
            "Clicks": "2469",
            "Conversions": "73",
            "Conversion Value": "$5,487",
            "Cost per Result": "$27.54"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "152599",
            "Clicks": "5290",
            "Conversions": "87",
            "Conversion Value": "$11,125",
            "Cost per Result": "$26.06"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "222389",
            "Clicks": "3289",
            "Conversions": "88",
            "Conversion Value": "$15,273",
            "Cost per Result": "$33.58"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "288489",
            "Clicks": "3595",
            "Conversions": "43",
            "Conversion Value": "$7,482",
            "Cost per Result": "$16.27"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "325669",
            "Clicks": "6297",
            "Conversions": "82",
            "Conversion Value": "$7,543",
            "Cost per Result": "$32.28"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "226873",
            "Clicks": "2761",
            "Conversions": "69",
            "Conversion Value": "$12,002",
            "Cost per Result": "$27.15"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "198736",
            "Clicks": "5790",
            "Conversions": "48",
            "Conversion Value": "$4,510",
            "Cost per Result": "$20.86"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "375412",
            "Clicks": "3692",
            "Conversions": "60",
            "Conversion Value": "$14,877",
            "Cost per Result": "$6.34"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "491610",
            "Clicks": "3736",
            "Conversions": "58",
            "Conversion Value": "$15,027",
            "Cost per Result": "$32.34"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "491919",
            "Clicks": "4018",
            "Conversions": "43",
            "Conversion Value": "$14,208",
            "Cost per Result": "$34.49"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "192367",
            "Clicks": "4875",
            "Conversions": "88",
            "Conversion Value": "$11,493",
            "Cost per Result": "$5.15"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "322729",
            "Clicks": "4199",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$13.58"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "426244",
            "Clicks": "2361",
            "Conversions": "74",
            "Conversion Value": "$13,783",
            "Cost per Result": "$19.42"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "157827",
            "Clicks": "2580",
            "Conversions": "47",
            "Conversion Value": "$9,459",
            "Cost per Result": "$30.15"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "380683",
            "Clicks": "2740",
            "Conversions": "68",
            "Conversion Value": "$14,002",
            "Cost per Result": "$9.11"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "138943",
            "Clicks": "3555",
            "Conversions": "84",
            "Conversion Value": "$6,547",
            "Cost per Result": "$31.75"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "103680",
            "Clicks": "6819",
            "Conversions": "57",
            "Conversion Value": "$10,707",
            "Cost per Result": "$12.50"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "173883",
            "Clicks": "5376",
            "Conversions": "66",
            "Conversion Value": "$7,198",
            "Cost per Result": "$20.26"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "492331",
            "Clicks": "6467",
            "Conversions": "60",
            "Conversion Value": "$14,251",
            "Cost per Result": "$20.12"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "429054",
            "Clicks": "5827",
            "Conversions": "81",
            "Conversion Value": "$9,046",
            "Cost per Result": "$7.32"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "347444",
            "Clicks": "6028",
            "Conversions": "62",
            "Conversion Value": "$7,701",
            "Cost per Result": "$14.68"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188223",
            "Clicks": "6629",
            "Conversions": "90",
            "Conversion Value": "$14,699",
            "Cost per Result": "$18.83"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "267261",
            "Clicks": "6352",
            "Conversions": "47",
            "Conversion Value": "$12,150",
            "Cost per Result": "$7.09"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "406044",
            "Clicks": "4550",
            "Conversions": "57",
            "Conversion Value": "$11,368",
            "Cost per Result": "$28.47"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "418762",
            "Clicks": "2317",
            "Conversions": "60",
            "Conversion Value": "$14,537",
            "Cost per Result": "$17.39"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "107772",
            "Clicks": "4944",
            "Conversions": "81",
            "Conversion Value": "$12,793",
            "Cost per Result": "$32.32"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "470088",
            "Clicks": "2373",
            "Conversions": "47",
            "Conversion Value": "$4,187",
            "Cost per Result": "$8.66"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "481683",
            "Clicks": "2260",
            "Conversions": "53",
            "Conversion Value": "$10,546",
            "Cost per Result": "$28.28"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "284903",
            "Clicks": "2210",
            "Conversions": "64",
            "Conversion Value": "$14,258",
            "Cost per Result": "$12.58"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "398274",
            "Clicks": "2869",
            "Conversions": "63",
            "Conversion Value": "$5,070",
            "Cost per Result": "$28.39"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "242762",
            "Clicks": "6197",
            "Conversions": "43",
            "Conversion Value": "$11,839",
            "Cost per Result": "$19.20"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "322748",
            "Clicks": "5544",
            "Conversions": "87",
            "Conversion Value": "$9,883",
            "Cost per Result": "$29.73"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "213924",
            "Clicks": "4173",
            "Conversions": "75",
            "Conversion Value": "$8,814",
            "Cost per Result": "$25.48"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "447422",
            "Clicks": "4805",
            "Conversions": "52",
            "Conversion Value": "$11,861",
            "Cost per Result": "$6.84"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "218895",
            "Clicks": "5279",
            "Conversions": "68",
            "Conversion Value": "$11,967",
            "Cost per Result": "$30.19"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "473201",
            "Clicks": "6153",
            "Conversions": "70",
            "Conversion Value": "$12,675",
            "Cost per Result": "$7.00"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "459737",
            "Clicks": "2550",
            "Conversions": "45",
            "Conversion Value": "$14,597",
            "Cost per Result": "$18.90"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "459800",
            "Clicks": "5248",
            "Conversions": "89",
            "Conversion Value": "$8,590",
            "Cost per Result": "$9.86"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "320856",
            "Clicks": "5523",
            "Conversions": "58",
            "Conversion Value": "$4,056",
            "Cost per Result": "$28.91"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "419833",
            "Clicks": "6502",
            "Conversions": "48",
            "Conversion Value": "$15,300",
            "Cost per Result": "$16.24"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "381522",
            "Clicks": "4075",
            "Conversions": "60",
            "Conversion Value": "$11,373",
            "Cost per Result": "$16.82"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "260556",
            "Clicks": "5014",
            "Conversions": "50",
            "Conversion Value": "$7,317",
            "Cost per Result": "$22.26"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "122159",
            "Clicks": "4630",
            "Conversions": "84",
            "Conversion Value": "$9,410",
            "Cost per Result": "$21.60"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "318331",
            "Clicks": "2637",
            "Conversions": "90",
            "Conversion Value": "$5,867",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "184061",
            "Clicks": "3787",
            "Conversions": "52",
            "Conversion Value": "$13,850",
            "Cost per Result": "$27.67"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "455424",
            "Clicks": "3255",
            "Conversions": "63",
            "Conversion Value": "$6,294",
            "Cost per Result": "$11.35"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "481435",
            "Clicks": "4743",
            "Conversions": "85",
            "Conversion Value": "$7,593",
            "Cost per Result": "$6.87"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "298677",
            "Clicks": "4618",
            "Conversions": "55",
            "Conversion Value": "$14,644",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "370652",
            "Clicks": "5943",
            "Conversions": "53",
            "Conversion Value": "$8,671",
            "Cost per Result": "$18.55"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "427915",
            "Clicks": "2802",
            "Conversions": "53",
            "Conversion Value": "$5,319",
            "Cost per Result": "$28.93"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "240623",
            "Clicks": "5264",
            "Conversions": "56",
            "Conversion Value": "$15,436",
            "Cost per Result": "$25.27"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "333227",
            "Clicks": "3055",
            "Conversions": "46",
            "Conversion Value": "$7,324",
            "Cost per Result": "$11.10"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "367852",
            "Clicks": "2508",
            "Conversions": "42",
            "Conversion Value": "$5,128",
            "Cost per Result": "$19.05"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "450217",
            "Clicks": "5614",
            "Conversions": "43",
            "Conversion Value": "$12,497",
            "Cost per Result": "$5.74"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "328867",
            "Clicks": "4728",
            "Conversions": "67",
            "Conversion Value": "$7,787",
            "Cost per Result": "$23.39"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "277809",
            "Clicks": "6491",
            "Conversions": "81",
            "Conversion Value": "$13,979",
            "Cost per Result": "$11.36"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "211361",
            "Clicks": "2132",
            "Conversions": "71",
            "Conversion Value": "$13,195",
            "Cost per Result": "$18.39"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "142508",
            "Clicks": "3862",
            "Conversions": "52",
            "Conversion Value": "$6,656",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "424888",
            "Clicks": "4450",
            "Conversions": "78",
            "Conversion Value": "$15,042",
            "Cost per Result": "$5.55"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "285587",
            "Clicks": "2182",
            "Conversions": "61",
            "Conversion Value": "$5,117",
            "Cost per Result": "$26.73"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "474948",
            "Clicks": "6056",
            "Conversions": "59",
            "Conversion Value": "$7,716",
            "Cost per Result": "$11.37"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "236141",
            "Clicks": "5541",
            "Conversions": "61",
            "Conversion Value": "$13,278",
            "Cost per Result": "$21.85"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "404478",
            "Clicks": "6238",
            "Conversions": "50",
            "Conversion Value": "$14,042",
            "Cost per Result": "$13.14"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "345422",
            "Clicks": "2804",
            "Conversions": "59",
            "Conversion Value": "$9,804",
            "Cost per Result": "$31.63"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "179394",
            "Clicks": "4301",
            "Conversions": "45",
            "Conversion Value": "$10,676",
            "Cost per Result": "$8.07"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "279309",
            "Clicks": "6253",
            "Conversions": "41",
            "Conversion Value": "$8,182",
            "Cost per Result": "$10.70"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "136053",
            "Clicks": "6858",
            "Conversions": "54",
            "Conversion Value": "$12,552",
            "Cost per Result": "$20.45"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "210906",
            "Clicks": "3579",
            "Conversions": "51",
            "Conversion Value": "$15,154",
            "Cost per Result": "$18.21"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "236006",
            "Clicks": "5617",
            "Conversions": "63",
            "Conversion Value": "$12,426",
            "Cost per Result": "$28.40"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "369562",
            "Clicks": "6265",
            "Conversions": "57",
            "Conversion Value": "$10,571",
            "Cost per Result": "$13.31"
          }
        ]
      },
      "CryptoNewsData": {
        "name": "Crypto news",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "395452",
            "Clicks": "2253",
            "Conversions": "70",
            "Volume": "$4,572",
            "Cost per Result": "$7.37"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "357851",
            "Clicks": "5514",
            "Conversions": "44",
            "Volume": "$9,585",
            "Cost per Result": "$24.91"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "286451",
            "Clicks": "4888",
            "Conversions": "67",
            "Volume": "$13,894",
            "Cost per Result": "$6.43"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "138563",
            "Clicks": "2149",
            "Conversions": "62",
            "Volume": "$13,357",
            "Cost per Result": "$23.78"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "479103",
            "Clicks": "2451",
            "Conversions": "64",
            "Volume": "$8,350",
            "Cost per Result": "$22.53"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "353401",
            "Clicks": "5546",
            "Conversions": "72",
            "Volume": "$15,724",
            "Cost per Result": "$5.35"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "170738",
            "Clicks": "5081",
            "Conversions": "75",
            "Volume": "$7,247",
            "Cost per Result": "$30.02"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "440785",
            "Clicks": "5624",
            "Conversions": "70",
            "Volume": "$10,038",
            "Cost per Result": "$17.01"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "104881",
            "Clicks": "3718",
            "Conversions": "76",
            "Volume": "$14,551",
            "Cost per Result": "$6.26"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "119330",
            "Clicks": "2668",
            "Conversions": "80",
            "Volume": "$14,879",
            "Cost per Result": "$10.35"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "381894",
            "Clicks": "3548",
            "Conversions": "74",
            "Volume": "$15,868",
            "Cost per Result": "$9.26"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "378317",
            "Clicks": "3502",
            "Conversions": "75",
            "Volume": "$11,386",
            "Cost per Result": "$20.80"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "349528",
            "Clicks": "6477",
            "Conversions": "73",
            "Volume": "$12,757",
            "Cost per Result": "$10.40"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "313198",
            "Clicks": "4676",
            "Conversions": "48",
            "Volume": "$11,487",
            "Cost per Result": "$10.09"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "312758",
            "Clicks": "3610",
            "Conversions": "79",
            "Volume": "$11,127",
            "Cost per Result": "$12.46"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "212110",
            "Clicks": "3812",
            "Conversions": "80",
            "Volume": "$4,943",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "347775",
            "Clicks": "4952",
            "Conversions": "56",
            "Volume": "$10,661",
            "Cost per Result": "$21.94"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "130535",
            "Clicks": "4915",
            "Conversions": "47",
            "Volume": "$5,328",
            "Cost per Result": "$17.60"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "211426",
            "Clicks": "3151",
            "Conversions": "82",
            "Volume": "$9,529",
            "Cost per Result": "$30.69"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "125674",
            "Clicks": "3355",
            "Conversions": "56",
            "Volume": "$13,972",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "197229",
            "Clicks": "3391",
            "Conversions": "58",
            "Volume": "$13,361",
            "Cost per Result": "$30.03"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "380509",
            "Clicks": "3370",
            "Conversions": "73",
            "Volume": "$5,196",
            "Cost per Result": "$8.25"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "376175",
            "Clicks": "5807",
            "Conversions": "64",
            "Volume": "$6,191",
            "Cost per Result": "$11.33"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "281779",
            "Clicks": "4122",
            "Conversions": "62",
            "Volume": "$6,302",
            "Cost per Result": "$14.74"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "229987",
            "Clicks": "5682",
            "Conversions": "75",
            "Volume": "$5,970",
            "Cost per Result": "$19.62"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "309528",
            "Clicks": "6056",
            "Conversions": "50",
            "Volume": "$11,112",
            "Cost per Result": "$23.50"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "272674",
            "Clicks": "4357",
            "Conversions": "84",
            "Volume": "$4,732",
            "Cost per Result": "$18.07"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "162783",
            "Clicks": "3314",
            "Conversions": "58",
            "Volume": "$15,957",
            "Cost per Result": "$29.16"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "380528",
            "Clicks": "4015",
            "Conversions": "87",
            "Volume": "$13,656",
            "Cost per Result": "$12.22"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "232177",
            "Clicks": "6443",
            "Conversions": "89",
            "Volume": "$13,923",
            "Cost per Result": "$19.77"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "110260",
            "Clicks": "2354",
            "Conversions": "88",
            "Volume": "$14,399",
            "Cost per Result": "$18.29"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "112745",
            "Clicks": "5175",
            "Conversions": "66",
            "Volume": "$13,372",
            "Cost per Result": "$27.92"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "459543",
            "Clicks": "5689",
            "Conversions": "44",
            "Volume": "$6,089",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "436968",
            "Clicks": "4579",
            "Conversions": "67",
            "Volume": "$11,301",
            "Cost per Result": "$16.58"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "475378",
            "Clicks": "6946",
            "Conversions": "81",
            "Volume": "$12,515",
            "Cost per Result": "$30.16"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "438861",
            "Clicks": "3860",
            "Conversions": "49",
            "Volume": "$10,134",
            "Cost per Result": "$22.72"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "485877",
            "Clicks": "3830",
            "Conversions": "62",
            "Volume": "$14,002",
            "Cost per Result": "$10.40"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "205196",
            "Clicks": "5972",
            "Conversions": "80",
            "Volume": "$12,186",
            "Cost per Result": "$10.06"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "144129",
            "Clicks": "3572",
            "Conversions": "57",
            "Volume": "$11,525",
            "Cost per Result": "$9.98"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "231794",
            "Clicks": "4652",
            "Conversions": "73",
            "Volume": "$15,156",
            "Cost per Result": "$29.81"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "233897",
            "Clicks": "2810",
            "Conversions": "65",
            "Volume": "$6,115",
            "Cost per Result": "$12.63"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "314327",
            "Clicks": "3279",
            "Conversions": "67",
            "Volume": "$9,696",
            "Cost per Result": "$30.26"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "182199",
            "Clicks": "6735",
            "Conversions": "61",
            "Volume": "$8,880",
            "Cost per Result": "$25.67"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "428876",
            "Clicks": "5922",
            "Conversions": "57",
            "Volume": "$11,100",
            "Cost per Result": "$12.14"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "421142",
            "Clicks": "2389",
            "Conversions": "72",
            "Volume": "$15,200",
            "Cost per Result": "$30.18"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "493067",
            "Clicks": "4338",
            "Conversions": "51",
            "Volume": "$5,307",
            "Cost per Result": "$32.40"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "289231",
            "Clicks": "5272",
            "Conversions": "52",
            "Volume": "$6,971",
            "Cost per Result": "$24.94"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "371097",
            "Clicks": "6384",
            "Conversions": "73",
            "Volume": "$4,576",
            "Cost per Result": "$28.47"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "491189",
            "Clicks": "6190",
            "Conversions": "46",
            "Volume": "$6,336",
            "Cost per Result": "$9.15"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "353477",
            "Clicks": "5082",
            "Conversions": "64",
            "Volume": "$4,784",
            "Cost per Result": "$23.72"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "256099",
            "Clicks": "5710",
            "Conversions": "40",
            "Volume": "$5,054",
            "Cost per Result": "$26.86"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "194314",
            "Clicks": "6345",
            "Conversions": "58",
            "Volume": "$9,289",
            "Cost per Result": "$20.82"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "139281",
            "Clicks": "4888",
            "Conversions": "62",
            "Volume": "$8,691",
            "Cost per Result": "$29.65"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "346186",
            "Clicks": "5475",
            "Conversions": "51",
            "Volume": "$6,939",
            "Cost per Result": "$28.74"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "177309",
            "Clicks": "3020",
            "Conversions": "50",
            "Volume": "$13,781",
            "Cost per Result": "$21.86"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "446998",
            "Clicks": "2851",
            "Conversions": "81",
            "Volume": "$15,780",
            "Cost per Result": "$24.50"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "383295",
            "Clicks": "2686",
            "Conversions": "46",
            "Volume": "$4,179",
            "Cost per Result": "$9.88"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "267958",
            "Clicks": "5642",
            "Conversions": "76",
            "Volume": "$9,675",
            "Cost per Result": "$6.47"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "497099",
            "Clicks": "4820",
            "Conversions": "42",
            "Volume": "$5,459",
            "Cost per Result": "$20.31"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "141320",
            "Clicks": "6868",
            "Conversions": "65",
            "Volume": "$8,807",
            "Cost per Result": "$31.22"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "300233",
            "Clicks": "6946",
            "Conversions": "85",
            "Volume": "$14,379",
            "Cost per Result": "$17.19"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "396582",
            "Clicks": "3026",
            "Conversions": "58",
            "Volume": "$12,130",
            "Cost per Result": "$34.00"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "266662",
            "Clicks": "2399",
            "Conversions": "48",
            "Volume": "$9,613",
            "Cost per Result": "$10.28"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "221350",
            "Clicks": "5864",
            "Conversions": "68",
            "Volume": "$9,641",
            "Cost per Result": "$7.89"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "328503",
            "Clicks": "5599",
            "Conversions": "70",
            "Volume": "$6,886",
            "Cost per Result": "$23.39"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "436249",
            "Clicks": "4536",
            "Conversions": "79",
            "Volume": "$4,886",
            "Cost per Result": "$30.47"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "351220",
            "Clicks": "5175",
            "Conversions": "87",
            "Volume": "$9,615",
            "Cost per Result": "$14.56"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "371545",
            "Clicks": "2502",
            "Conversions": "58",
            "Volume": "$5,653",
            "Cost per Result": "$19.99"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "278814",
            "Clicks": "4878",
            "Conversions": "42",
            "Volume": "$14,026",
            "Cost per Result": "$30.44"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "127291",
            "Clicks": "5333",
            "Conversions": "88",
            "Volume": "$12,290",
            "Cost per Result": "$6.26"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "149488",
            "Clicks": "4136",
            "Conversions": "56",
            "Volume": "$4,400",
            "Cost per Result": "$21.97"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "390705",
            "Clicks": "4416",
            "Conversions": "43",
            "Volume": "$11,620",
            "Cost per Result": "$9.03"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "301601",
            "Clicks": "6124",
            "Conversions": "54",
            "Volume": "$4,400",
            "Cost per Result": "$23.14"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "207343",
            "Clicks": "3107",
            "Conversions": "67",
            "Volume": "$9,835",
            "Cost per Result": "$10.85"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "474453",
            "Clicks": "4609",
            "Conversions": "87",
            "Volume": "$13,231",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "221006",
            "Clicks": "6435",
            "Conversions": "41",
            "Volume": "$5,973",
            "Cost per Result": "$29.84"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "426467",
            "Clicks": "5003",
            "Conversions": "53",
            "Volume": "$15,254",
            "Cost per Result": "$28.25"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "206714",
            "Clicks": "3466",
            "Conversions": "74",
            "Volume": "$5,416",
            "Cost per Result": "$12.82"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "393151",
            "Clicks": "4163",
            "Conversions": "70",
            "Volume": "$9,423",
            "Cost per Result": "$8.86"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "348668",
            "Clicks": "4984",
            "Conversions": "81",
            "Volume": "$9,047",
            "Cost per Result": "$13.38"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "119218",
            "Clicks": "4196",
            "Conversions": "43",
            "Volume": "$12,953",
            "Cost per Result": "$14.40"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "318947",
            "Clicks": "3285",
            "Conversions": "74",
            "Volume": "$5,882",
            "Cost per Result": "$34.17"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "434516",
            "Clicks": "2845",
            "Conversions": "48",
            "Volume": "$14,705",
            "Cost per Result": "$7.53"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "282277",
            "Clicks": "4832",
            "Conversions": "87",
            "Volume": "$6,913",
            "Cost per Result": "$16.12"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "394638",
            "Clicks": "6753",
            "Conversions": "56",
            "Volume": "$4,089",
            "Cost per Result": "$34.54"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "308239",
            "Clicks": "4730",
            "Conversions": "48",
            "Volume": "$5,933",
            "Cost per Result": "$9.58"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "248157",
            "Clicks": "4988",
            "Conversions": "73",
            "Volume": "$5,541",
            "Cost per Result": "$31.14"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "366743",
            "Clicks": "5748",
            "Conversions": "63",
            "Volume": "$5,963",
            "Cost per Result": "$6.53"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "178211",
            "Clicks": "6635",
            "Conversions": "89",
            "Volume": "$14,831",
            "Cost per Result": "$11.65"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "298619",
            "Clicks": "2947",
            "Conversions": "90",
            "Volume": "$10,805",
            "Cost per Result": "$20.17"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "333265",
            "Clicks": "4717",
            "Conversions": "54",
            "Volume": "$12,269",
            "Cost per Result": "$12.74"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "105304",
            "Clicks": "6180",
            "Conversions": "78",
            "Volume": "$9,719",
            "Cost per Result": "$23.95"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "227625",
            "Clicks": "5632",
            "Conversions": "70",
            "Volume": "$15,070",
            "Cost per Result": "$10.55"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "335463",
            "Clicks": "5330",
            "Conversions": "44",
            "Volume": "$13,506",
            "Cost per Result": "$18.65"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "455689",
            "Clicks": "5961",
            "Conversions": "47",
            "Volume": "$11,314",
            "Cost per Result": "$26.07"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "438963",
            "Clicks": "2512",
            "Conversions": "60",
            "Volume": "$12,621",
            "Cost per Result": "$34.01"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "404910",
            "Clicks": "6742",
            "Conversions": "87",
            "Volume": "$6,807",
            "Cost per Result": "$21.72"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "218144",
            "Clicks": "4632",
            "Conversions": "56",
            "Volume": "$7,550",
            "Cost per Result": "$10.95"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "232533",
            "Clicks": "2181",
            "Conversions": "41",
            "Volume": "$4,015",
            "Cost per Result": "$24.17"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "472183",
            "Clicks": "6035",
            "Conversions": "45",
            "Volume": "$5,666",
            "Cost per Result": "$28.70"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "232686",
            "Clicks": "3317",
            "Conversions": "79",
            "Volume": "$10,874",
            "Cost per Result": "$19.58"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "273310",
            "Clicks": "2152",
            "Conversions": "85",
            "Volume": "$11,196",
            "Cost per Result": "$16.82"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "487465",
            "Clicks": "6276",
            "Conversions": "76",
            "Volume": "$6,743",
            "Cost per Result": "$16.34"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "135629",
            "Clicks": "5323",
            "Conversions": "75",
            "Volume": "$13,751",
            "Cost per Result": "$17.71"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "473054",
            "Clicks": "5776",
            "Conversions": "54",
            "Volume": "$12,726",
            "Cost per Result": "$15.42"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "271480",
            "Clicks": "5678",
            "Conversions": "59",
            "Volume": "$9,124",
            "Cost per Result": "$28.61"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "166234",
            "Clicks": "2094",
            "Conversions": "83",
            "Volume": "$6,037",
            "Cost per Result": "$23.92"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "441193",
            "Clicks": "4315",
            "Conversions": "69",
            "Volume": "$10,446",
            "Cost per Result": "$10.69"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "135238",
            "Clicks": "3415",
            "Conversions": "76",
            "Volume": "$10,479",
            "Cost per Result": "$25.61"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "385374",
            "Clicks": "4841",
            "Conversions": "50",
            "Volume": "$6,306",
            "Cost per Result": "$24.67"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "450776",
            "Clicks": "5818",
            "Conversions": "86",
            "Volume": "$11,368",
            "Cost per Result": "$33.47"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "311476",
            "Clicks": "6028",
            "Conversions": "75",
            "Volume": "$10,710",
            "Cost per Result": "$8.27"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "448200",
            "Clicks": "2949",
            "Conversions": "43",
            "Volume": "$14,718",
            "Cost per Result": "$28.09"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "146566",
            "Clicks": "4103",
            "Conversions": "55",
            "Volume": "$4,488",
            "Cost per Result": "$10.15"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "421526",
            "Clicks": "2966",
            "Conversions": "63",
            "Volume": "$10,921",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "255407",
            "Clicks": "3507",
            "Conversions": "76",
            "Volume": "$9,601",
            "Cost per Result": "$31.92"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "126948",
            "Clicks": "2682",
            "Conversions": "52",
            "Volume": "$7,808",
            "Cost per Result": "$25.09"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "377780",
            "Clicks": "5614",
            "Conversions": "57",
            "Volume": "$10,285",
            "Cost per Result": "$23.82"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "391489",
            "Clicks": "5203",
            "Conversions": "40",
            "Volume": "$6,459",
            "Cost per Result": "$29.85"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "413553",
            "Clicks": "3292",
            "Conversions": "40",
            "Volume": "$7,620",
            "Cost per Result": "$30.17"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "316451",
            "Clicks": "6874",
            "Conversions": "78",
            "Volume": "$12,537",
            "Cost per Result": "$26.56"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "261324",
            "Clicks": "5782",
            "Conversions": "41",
            "Volume": "$13,306",
            "Cost per Result": "$5.94"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "271855",
            "Clicks": "5177",
            "Conversions": "78",
            "Volume": "$15,819",
            "Cost per Result": "$24.92"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "111195",
            "Clicks": "2432",
            "Conversions": "75",
            "Volume": "$4,634",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "114530",
            "Clicks": "3726",
            "Conversions": "54",
            "Volume": "$5,876",
            "Cost per Result": "$18.06"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "249895",
            "Clicks": "5516",
            "Conversions": "73",
            "Volume": "$13,040",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "227838",
            "Clicks": "2595",
            "Conversions": "62",
            "Volume": "$6,034",
            "Cost per Result": "$30.40"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "434169",
            "Clicks": "2821",
            "Conversions": "86",
            "Volume": "$6,596",
            "Cost per Result": "$33.93"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "155281",
            "Clicks": "2593",
            "Conversions": "61",
            "Volume": "$15,500",
            "Cost per Result": "$28.07"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "234150",
            "Clicks": "4752",
            "Conversions": "84",
            "Volume": "$12,417",
            "Cost per Result": "$25.20"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "244702",
            "Clicks": "5367",
            "Conversions": "69",
            "Volume": "$12,340",
            "Cost per Result": "$29.33"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "481918",
            "Clicks": "6426",
            "Conversions": "85",
            "Volume": "$4,397",
            "Cost per Result": "$16.68"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "377070",
            "Clicks": "6483",
            "Conversions": "58",
            "Volume": "$14,396",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "452185",
            "Clicks": "2040",
            "Conversions": "71",
            "Volume": "$10,026",
            "Cost per Result": "$5.84"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "447813",
            "Clicks": "6166",
            "Conversions": "44",
            "Volume": "$8,437",
            "Cost per Result": "$31.98"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "254175",
            "Clicks": "6794",
            "Conversions": "69",
            "Volume": "$8,189",
            "Cost per Result": "$27.33"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "404301",
            "Clicks": "4636",
            "Conversions": "78",
            "Volume": "$14,513",
            "Cost per Result": "$33.79"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "459220",
            "Clicks": "4706",
            "Conversions": "74",
            "Volume": "$15,882",
            "Cost per Result": "$29.94"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "306818",
            "Clicks": "6982",
            "Conversions": "59",
            "Volume": "$13,499",
            "Cost per Result": "$28.87"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "192137",
            "Clicks": "6084",
            "Conversions": "83",
            "Volume": "$13,362",
            "Cost per Result": "$8.33"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "139673",
            "Clicks": "2005",
            "Conversions": "64",
            "Volume": "$5,842",
            "Cost per Result": "$16.43"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "271542",
            "Clicks": "4319",
            "Conversions": "78",
            "Volume": "$11,470",
            "Cost per Result": "$8.89"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "202960",
            "Clicks": "2278",
            "Conversions": "48",
            "Volume": "$7,204",
            "Cost per Result": "$6.72"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "421932",
            "Clicks": "2755",
            "Conversions": "50",
            "Volume": "$4,142",
            "Cost per Result": "$14.81"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "193754",
            "Clicks": "2735",
            "Conversions": "40",
            "Volume": "$8,671",
            "Cost per Result": "$7.14"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "356914",
            "Clicks": "5989",
            "Conversions": "60",
            "Volume": "$5,465",
            "Cost per Result": "$16.07"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "449789",
            "Clicks": "5188",
            "Conversions": "63",
            "Volume": "$15,948",
            "Cost per Result": "$7.74"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "137661",
            "Clicks": "2938",
            "Conversions": "85",
            "Volume": "$11,381",
            "Cost per Result": "$18.54"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "271267",
            "Clicks": "3342",
            "Conversions": "90",
            "Volume": "$7,962",
            "Cost per Result": "$17.19"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "264391",
            "Clicks": "3318",
            "Conversions": "42",
            "Volume": "$14,079",
            "Cost per Result": "$19.69"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "331410",
            "Clicks": "5756",
            "Conversions": "41",
            "Volume": "$14,972",
            "Cost per Result": "$12.59"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "113244",
            "Clicks": "3491",
            "Conversions": "50",
            "Volume": "$12,571",
            "Cost per Result": "$12.06"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "188567",
            "Clicks": "3128",
            "Conversions": "70",
            "Volume": "$7,914",
            "Cost per Result": "$14.98"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "169679",
            "Clicks": "3661",
            "Conversions": "61",
            "Volume": "$11,425",
            "Cost per Result": "$7.64"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "258725",
            "Clicks": "5640",
            "Conversions": "72",
            "Volume": "$14,724",
            "Cost per Result": "$34.00"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246709",
            "Clicks": "6702",
            "Conversions": "68",
            "Volume": "$6,569",
            "Cost per Result": "$16.35"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "414357",
            "Clicks": "4545",
            "Conversions": "80",
            "Volume": "$9,696",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "257879",
            "Clicks": "5729",
            "Conversions": "52",
            "Volume": "$14,611",
            "Cost per Result": "$17.57"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "142931",
            "Clicks": "4823",
            "Conversions": "89",
            "Volume": "$9,131",
            "Cost per Result": "$31.86"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "117600",
            "Clicks": "5606",
            "Conversions": "51",
            "Volume": "$4,677",
            "Cost per Result": "$19.98"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "105770",
            "Clicks": "4592",
            "Conversions": "76",
            "Volume": "$8,221",
            "Cost per Result": "$26.46"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "473638",
            "Clicks": "3457",
            "Conversions": "57",
            "Volume": "$10,439",
            "Cost per Result": "$8.28"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "146898",
            "Clicks": "2813",
            "Conversions": "63",
            "Volume": "$10,763",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "177541",
            "Clicks": "4947",
            "Conversions": "90",
            "Volume": "$5,888",
            "Cost per Result": "$6.46"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "154550",
            "Clicks": "5094",
            "Conversions": "45",
            "Volume": "$8,516",
            "Cost per Result": "$27.93"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "139480",
            "Clicks": "6503",
            "Conversions": "42",
            "Volume": "$15,646",
            "Cost per Result": "$10.97"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "498850",
            "Clicks": "4575",
            "Conversions": "66",
            "Volume": "$11,002",
            "Cost per Result": "$6.35"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "396015",
            "Clicks": "5842",
            "Conversions": "55",
            "Volume": "$7,116",
            "Cost per Result": "$29.96"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "363082",
            "Clicks": "4640",
            "Conversions": "74",
            "Volume": "$10,836",
            "Cost per Result": "$12.82"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "476250",
            "Clicks": "3690",
            "Conversions": "64",
            "Volume": "$6,568",
            "Cost per Result": "$20.73"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "239125",
            "Clicks": "4878",
            "Conversions": "45",
            "Volume": "$14,397",
            "Cost per Result": "$27.72"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "347843",
            "Clicks": "2369",
            "Conversions": "53",
            "Volume": "$15,056",
            "Cost per Result": "$17.59"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "337670",
            "Clicks": "3270",
            "Conversions": "73",
            "Volume": "$14,156",
            "Cost per Result": "$21.40"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "355307",
            "Clicks": "6211",
            "Conversions": "70",
            "Volume": "$12,929",
            "Cost per Result": "$20.82"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "219605",
            "Clicks": "4069",
            "Conversions": "47",
            "Volume": "$9,217",
            "Cost per Result": "$34.89"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "255786",
            "Clicks": "5372",
            "Conversions": "66",
            "Volume": "$7,106",
            "Cost per Result": "$19.83"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "216494",
            "Clicks": "2518",
            "Conversions": "60",
            "Volume": "$15,991",
            "Cost per Result": "$8.10"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "270358",
            "Clicks": "2431",
            "Conversions": "57",
            "Volume": "$4,077",
            "Cost per Result": "$13.37"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "186723",
            "Clicks": "3342",
            "Conversions": "84",
            "Volume": "$15,077",
            "Cost per Result": "$18.67"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "391191",
            "Clicks": "2989",
            "Conversions": "83",
            "Volume": "$14,392",
            "Cost per Result": "$7.58"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "342260",
            "Clicks": "3261",
            "Conversions": "79",
            "Volume": "$6,712",
            "Cost per Result": "$34.97"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "337451",
            "Clicks": "5683",
            "Conversions": "59",
            "Volume": "$4,653",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "152809",
            "Clicks": "3380",
            "Conversions": "70",
            "Volume": "$5,086",
            "Cost per Result": "$16.62"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "487492",
            "Clicks": "3576",
            "Conversions": "49",
            "Volume": "$10,406",
            "Cost per Result": "$27.17"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "444658",
            "Clicks": "6930",
            "Conversions": "58",
            "Volume": "$8,638",
            "Cost per Result": "$8.53"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "314910",
            "Clicks": "5528",
            "Conversions": "58",
            "Volume": "$4,000",
            "Cost per Result": "$19.92"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "496852",
            "Clicks": "6930",
            "Conversions": "74",
            "Volume": "$10,554",
            "Cost per Result": "$8.25"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "235901",
            "Clicks": "4942",
            "Conversions": "62",
            "Volume": "$4,204",
            "Cost per Result": "$31.12"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "415296",
            "Clicks": "2035",
            "Conversions": "54",
            "Volume": "$6,112",
            "Cost per Result": "$16.45"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "380670",
            "Clicks": "6602",
            "Conversions": "56",
            "Volume": "$6,017",
            "Cost per Result": "$26.06"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "134566",
            "Clicks": "3229",
            "Conversions": "67",
            "Volume": "$4,657",
            "Cost per Result": "$11.05"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "109519",
            "Clicks": "4060",
            "Conversions": "46",
            "Volume": "$5,267",
            "Cost per Result": "$34.50"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "337874",
            "Clicks": "2632",
            "Conversions": "43",
            "Volume": "$4,651",
            "Cost per Result": "$16.05"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "312678",
            "Clicks": "2264",
            "Conversions": "49",
            "Volume": "$15,293",
            "Cost per Result": "$25.91"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "459030",
            "Clicks": "5417",
            "Conversions": "48",
            "Volume": "$11,756",
            "Cost per Result": "$16.78"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "301578",
            "Clicks": "3722",
            "Conversions": "80",
            "Volume": "$12,335",
            "Cost per Result": "$32.77"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "278922",
            "Clicks": "3279",
            "Conversions": "65",
            "Volume": "$4,927",
            "Cost per Result": "$15.45"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188723",
            "Clicks": "3010",
            "Conversions": "44",
            "Volume": "$7,435",
            "Cost per Result": "$13.26"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "176904",
            "Clicks": "6027",
            "Conversions": "76",
            "Volume": "$13,216",
            "Cost per Result": "$13.11"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "465470",
            "Clicks": "6909",
            "Conversions": "80",
            "Volume": "$8,463",
            "Cost per Result": "$8.00"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "449524",
            "Clicks": "6339",
            "Conversions": "80",
            "Volume": "$12,988",
            "Cost per Result": "$28.64"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "155289",
            "Clicks": "6755",
            "Conversions": "89",
            "Volume": "$14,443",
            "Cost per Result": "$23.33"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "251678",
            "Clicks": "5403",
            "Conversions": "44",
            "Volume": "$14,588",
            "Cost per Result": "$8.60"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "208682",
            "Clicks": "6014",
            "Conversions": "75",
            "Volume": "$7,450",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "116015",
            "Clicks": "6913",
            "Conversions": "53",
            "Volume": "$11,510",
            "Cost per Result": "$28.60"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "210673",
            "Clicks": "4906",
            "Conversions": "75",
            "Volume": "$9,763",
            "Cost per Result": "$34.38"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "114115",
            "Clicks": "3426",
            "Conversions": "53",
            "Volume": "$6,945",
            "Cost per Result": "$9.32"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "319705",
            "Clicks": "2440",
            "Conversions": "47",
            "Volume": "$12,513",
            "Cost per Result": "$19.33"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "267727",
            "Clicks": "3558",
            "Conversions": "47",
            "Volume": "$12,306",
            "Cost per Result": "$27.62"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "105881",
            "Clicks": "4359",
            "Conversions": "71",
            "Volume": "$6,390",
            "Cost per Result": "$22.01"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "111466",
            "Clicks": "6237",
            "Conversions": "59",
            "Volume": "$4,895",
            "Cost per Result": "$12.64"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "296412",
            "Clicks": "2494",
            "Conversions": "66",
            "Volume": "$12,722",
            "Cost per Result": "$28.13"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "142404",
            "Clicks": "3986",
            "Conversions": "70",
            "Volume": "$9,450",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "387467",
            "Clicks": "6385",
            "Conversions": "83",
            "Volume": "$8,879",
            "Cost per Result": "$9.32"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "440820",
            "Clicks": "6742",
            "Conversions": "71",
            "Volume": "$15,505",
            "Cost per Result": "$18.23"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "496405",
            "Clicks": "2963",
            "Conversions": "72",
            "Volume": "$4,203",
            "Cost per Result": "$27.11"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "465060",
            "Clicks": "4300",
            "Conversions": "65",
            "Volume": "$7,507",
            "Cost per Result": "$19.93"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "289689",
            "Clicks": "3717",
            "Conversions": "89",
            "Volume": "$6,620",
            "Cost per Result": "$23.96"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "348289",
            "Clicks": "5573",
            "Conversions": "83",
            "Volume": "$5,830",
            "Cost per Result": "$18.09"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "344235",
            "Clicks": "4227",
            "Conversions": "77",
            "Volume": "$9,474",
            "Cost per Result": "$30.47"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "273530",
            "Clicks": "2989",
            "Conversions": "57",
            "Volume": "$14,332",
            "Cost per Result": "$30.58"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "229107",
            "Clicks": "2000",
            "Conversions": "74",
            "Volume": "$9,000",
            "Cost per Result": "$21.84"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "374565",
            "Clicks": "2818",
            "Conversions": "56",
            "Volume": "$8,232",
            "Cost per Result": "$6.25"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "307391",
            "Clicks": "5276",
            "Conversions": "71",
            "Volume": "$11,528",
            "Cost per Result": "$12.57"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "284134",
            "Clicks": "6806",
            "Conversions": "81",
            "Volume": "$6,694",
            "Cost per Result": "$20.50"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "462874",
            "Clicks": "6181",
            "Conversions": "43",
            "Volume": "$7,282",
            "Cost per Result": "$34.47"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "488694",
            "Clicks": "5273",
            "Conversions": "58",
            "Volume": "$11,835",
            "Cost per Result": "$34.93"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "392362",
            "Clicks": "3527",
            "Conversions": "82",
            "Volume": "$15,428",
            "Cost per Result": "$17.47"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "293396",
            "Clicks": "4852",
            "Conversions": "44",
            "Volume": "$15,768",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "242731",
            "Clicks": "6307",
            "Conversions": "82",
            "Volume": "$15,075",
            "Cost per Result": "$24.49"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "475772",
            "Clicks": "4211",
            "Conversions": "66",
            "Volume": "$11,731",
            "Cost per Result": "$27.13"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "114082",
            "Clicks": "2933",
            "Conversions": "49",
            "Volume": "$7,128",
            "Cost per Result": "$31.31"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "316853",
            "Clicks": "4707",
            "Conversions": "87",
            "Volume": "$14,036",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "329922",
            "Clicks": "5750",
            "Conversions": "55",
            "Volume": "$5,208",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "330555",
            "Clicks": "4634",
            "Conversions": "50",
            "Volume": "$4,502",
            "Cost per Result": "$26.31"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "256484",
            "Clicks": "6439",
            "Conversions": "66",
            "Volume": "$5,139",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "233587",
            "Clicks": "5174",
            "Conversions": "40",
            "Volume": "$15,705",
            "Cost per Result": "$31.83"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "431895",
            "Clicks": "2750",
            "Conversions": "48",
            "Volume": "$5,635",
            "Cost per Result": "$23.77"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "457847",
            "Clicks": "3771",
            "Conversions": "89",
            "Volume": "$13,555",
            "Cost per Result": "$11.01"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "468848",
            "Clicks": "3523",
            "Conversions": "46",
            "Volume": "$15,388",
            "Cost per Result": "$20.11"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "235154",
            "Clicks": "4276",
            "Conversions": "71",
            "Volume": "$5,004",
            "Cost per Result": "$30.49"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "379914",
            "Clicks": "3799",
            "Conversions": "51",
            "Volume": "$4,673",
            "Cost per Result": "$19.27"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "191817",
            "Clicks": "4163",
            "Conversions": "84",
            "Volume": "$8,532",
            "Cost per Result": "$27.69"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "432092",
            "Clicks": "5184",
            "Conversions": "77",
            "Volume": "$15,251",
            "Cost per Result": "$30.51"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "370193",
            "Clicks": "3476",
            "Conversions": "70",
            "Volume": "$4,979",
            "Cost per Result": "$24.04"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "487398",
            "Clicks": "4378",
            "Conversions": "52",
            "Volume": "$10,437",
            "Cost per Result": "$12.84"
          }
        ]
      },
      "DexUsersData": {
        "name": "Dex users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "424672",
            "Clicks": "6928",
            "Conversions": "42",
            "Conversion Value": "$14,146",
            "Cost per Result": "$16.51"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "141304",
            "Clicks": "6892",
            "Conversions": "44",
            "Conversion Value": "$13,727",
            "Cost per Result": "$16.12"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "283212",
            "Clicks": "5765",
            "Conversions": "78",
            "Conversion Value": "$13,858",
            "Cost per Result": "$20.43"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "192285",
            "Clicks": "3841",
            "Conversions": "73",
            "Conversion Value": "$6,717",
            "Cost per Result": "$30.79"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "133895",
            "Clicks": "6646",
            "Conversions": "58",
            "Conversion Value": "$13,537",
            "Cost per Result": "$24.54"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "371867",
            "Clicks": "5454",
            "Conversions": "52",
            "Conversion Value": "$6,396",
            "Cost per Result": "$33.62"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "336939",
            "Clicks": "5204",
            "Conversions": "41",
            "Conversion Value": "$6,313",
            "Cost per Result": "$32.03"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "375794",
            "Clicks": "2818",
            "Conversions": "47",
            "Conversion Value": "$8,233",
            "Cost per Result": "$6.63"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "397090",
            "Clicks": "4547",
            "Conversions": "59",
            "Conversion Value": "$5,365",
            "Cost per Result": "$26.65"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "161367",
            "Clicks": "6637",
            "Conversions": "49",
            "Conversion Value": "$6,428",
            "Cost per Result": "$12.42"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "343191",
            "Clicks": "4988",
            "Conversions": "67",
            "Conversion Value": "$9,289",
            "Cost per Result": "$14.65"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "374748",
            "Clicks": "6683",
            "Conversions": "72",
            "Conversion Value": "$4,230",
            "Cost per Result": "$8.75"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "443365",
            "Clicks": "5391",
            "Conversions": "70",
            "Conversion Value": "$15,629",
            "Cost per Result": "$8.59"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "456486",
            "Clicks": "2179",
            "Conversions": "66",
            "Conversion Value": "$9,962",
            "Cost per Result": "$12.14"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "143443",
            "Clicks": "2909",
            "Conversions": "42",
            "Conversion Value": "$14,298",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "210494",
            "Clicks": "2578",
            "Conversions": "52",
            "Conversion Value": "$11,237",
            "Cost per Result": "$31.99"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "123457",
            "Clicks": "2071",
            "Conversions": "44",
            "Conversion Value": "$13,731",
            "Cost per Result": "$27.07"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "273159",
            "Clicks": "4190",
            "Conversions": "86",
            "Conversion Value": "$7,811",
            "Cost per Result": "$31.88"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "345926",
            "Clicks": "5879",
            "Conversions": "84",
            "Conversion Value": "$8,408",
            "Cost per Result": "$11.71"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "222281",
            "Clicks": "5192",
            "Conversions": "56",
            "Conversion Value": "$7,948",
            "Cost per Result": "$30.39"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "488026",
            "Clicks": "5331",
            "Conversions": "70",
            "Conversion Value": "$6,486",
            "Cost per Result": "$9.60"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "216078",
            "Clicks": "6511",
            "Conversions": "57",
            "Conversion Value": "$15,973",
            "Cost per Result": "$22.50"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "356257",
            "Clicks": "5118",
            "Conversions": "49",
            "Conversion Value": "$12,377",
            "Cost per Result": "$16.49"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "277261",
            "Clicks": "2381",
            "Conversions": "65",
            "Conversion Value": "$11,420",
            "Cost per Result": "$22.75"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "207057",
            "Clicks": "4971",
            "Conversions": "62",
            "Conversion Value": "$8,742",
            "Cost per Result": "$32.51"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "194555",
            "Clicks": "3129",
            "Conversions": "61",
            "Conversion Value": "$15,744",
            "Cost per Result": "$18.71"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "151227",
            "Clicks": "4514",
            "Conversions": "87",
            "Conversion Value": "$7,225",
            "Cost per Result": "$15.22"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "493396",
            "Clicks": "4563",
            "Conversions": "58",
            "Conversion Value": "$9,358",
            "Cost per Result": "$26.22"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "296690",
            "Clicks": "5709",
            "Conversions": "49",
            "Conversion Value": "$4,471",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "349582",
            "Clicks": "3863",
            "Conversions": "67",
            "Conversion Value": "$15,587",
            "Cost per Result": "$27.52"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "256750",
            "Clicks": "2984",
            "Conversions": "66",
            "Conversion Value": "$13,410",
            "Cost per Result": "$34.23"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "436372",
            "Clicks": "3636",
            "Conversions": "54",
            "Conversion Value": "$14,205",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "474759",
            "Clicks": "6433",
            "Conversions": "65",
            "Conversion Value": "$10,298",
            "Cost per Result": "$34.72"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "190078",
            "Clicks": "4129",
            "Conversions": "45",
            "Conversion Value": "$12,272",
            "Cost per Result": "$24.52"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "427283",
            "Clicks": "5852",
            "Conversions": "48",
            "Conversion Value": "$11,305",
            "Cost per Result": "$14.73"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "166076",
            "Clicks": "2923",
            "Conversions": "58",
            "Conversion Value": "$14,133",
            "Cost per Result": "$12.01"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "184038",
            "Clicks": "5518",
            "Conversions": "40",
            "Conversion Value": "$11,369",
            "Cost per Result": "$5.24"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "282049",
            "Clicks": "6712",
            "Conversions": "73",
            "Conversion Value": "$9,161",
            "Cost per Result": "$19.05"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "222894",
            "Clicks": "5104",
            "Conversions": "63",
            "Conversion Value": "$6,085",
            "Cost per Result": "$8.40"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "485123",
            "Clicks": "2377",
            "Conversions": "85",
            "Conversion Value": "$10,278",
            "Cost per Result": "$32.83"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "302147",
            "Clicks": "2029",
            "Conversions": "67",
            "Conversion Value": "$9,320",
            "Cost per Result": "$32.99"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "342567",
            "Clicks": "4117",
            "Conversions": "53",
            "Conversion Value": "$4,680",
            "Cost per Result": "$33.12"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "441446",
            "Clicks": "4122",
            "Conversions": "67",
            "Conversion Value": "$4,404",
            "Cost per Result": "$8.93"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "481402",
            "Clicks": "3094",
            "Conversions": "74",
            "Conversion Value": "$4,670",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "199734",
            "Clicks": "3074",
            "Conversions": "44",
            "Conversion Value": "$11,818",
            "Cost per Result": "$28.09"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "461188",
            "Clicks": "5275",
            "Conversions": "81",
            "Conversion Value": "$14,059",
            "Cost per Result": "$28.02"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "387204",
            "Clicks": "3935",
            "Conversions": "66",
            "Conversion Value": "$12,374",
            "Cost per Result": "$8.86"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "358544",
            "Clicks": "6989",
            "Conversions": "79",
            "Conversion Value": "$11,318",
            "Cost per Result": "$10.99"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "313582",
            "Clicks": "6342",
            "Conversions": "83",
            "Conversion Value": "$14,727",
            "Cost per Result": "$15.47"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "404652",
            "Clicks": "5038",
            "Conversions": "60",
            "Conversion Value": "$12,393",
            "Cost per Result": "$6.04"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "243526",
            "Clicks": "5292",
            "Conversions": "68",
            "Conversion Value": "$4,430",
            "Cost per Result": "$23.97"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "212679",
            "Clicks": "6347",
            "Conversions": "87",
            "Conversion Value": "$15,780",
            "Cost per Result": "$30.14"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "368880",
            "Clicks": "3841",
            "Conversions": "83",
            "Conversion Value": "$5,438",
            "Cost per Result": "$25.92"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "356353",
            "Clicks": "4209",
            "Conversions": "88",
            "Conversion Value": "$14,848",
            "Cost per Result": "$22.70"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "366080",
            "Clicks": "3880",
            "Conversions": "80",
            "Conversion Value": "$14,529",
            "Cost per Result": "$12.89"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "440784",
            "Clicks": "3839",
            "Conversions": "54",
            "Conversion Value": "$14,283",
            "Cost per Result": "$11.23"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "214993",
            "Clicks": "3182",
            "Conversions": "60",
            "Conversion Value": "$13,219",
            "Cost per Result": "$7.33"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "200353",
            "Clicks": "6574",
            "Conversions": "62",
            "Conversion Value": "$8,550",
            "Cost per Result": "$18.08"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "124396",
            "Clicks": "4852",
            "Conversions": "63",
            "Conversion Value": "$8,917",
            "Cost per Result": "$27.08"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "455404",
            "Clicks": "6533",
            "Conversions": "51",
            "Conversion Value": "$6,105",
            "Cost per Result": "$21.56"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "331605",
            "Clicks": "6289",
            "Conversions": "60",
            "Conversion Value": "$9,657",
            "Cost per Result": "$24.06"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "380328",
            "Clicks": "2731",
            "Conversions": "47",
            "Conversion Value": "$4,231",
            "Cost per Result": "$33.18"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "393382",
            "Clicks": "2384",
            "Conversions": "66",
            "Conversion Value": "$5,366",
            "Cost per Result": "$25.86"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "132466",
            "Clicks": "2316",
            "Conversions": "46",
            "Conversion Value": "$4,380",
            "Cost per Result": "$22.90"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "417228",
            "Clicks": "2338",
            "Conversions": "58",
            "Conversion Value": "$15,383",
            "Cost per Result": "$9.06"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "247114",
            "Clicks": "3359",
            "Conversions": "50",
            "Conversion Value": "$8,145",
            "Cost per Result": "$29.58"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "332732",
            "Clicks": "5389",
            "Conversions": "67",
            "Conversion Value": "$7,289",
            "Cost per Result": "$33.37"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "133352",
            "Clicks": "4218",
            "Conversions": "77",
            "Conversion Value": "$14,915",
            "Cost per Result": "$18.11"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "289622",
            "Clicks": "6811",
            "Conversions": "58",
            "Conversion Value": "$10,196",
            "Cost per Result": "$28.03"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "454185",
            "Clicks": "6894",
            "Conversions": "72",
            "Conversion Value": "$14,092",
            "Cost per Result": "$7.62"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "291039",
            "Clicks": "6658",
            "Conversions": "90",
            "Conversion Value": "$10,559",
            "Cost per Result": "$17.94"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "119357",
            "Clicks": "2021",
            "Conversions": "58",
            "Conversion Value": "$13,815",
            "Cost per Result": "$18.97"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "407763",
            "Clicks": "6998",
            "Conversions": "40",
            "Conversion Value": "$4,570",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "368580",
            "Clicks": "3538",
            "Conversions": "68",
            "Conversion Value": "$5,550",
            "Cost per Result": "$19.94"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "132688",
            "Clicks": "4651",
            "Conversions": "72",
            "Conversion Value": "$12,123",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "316131",
            "Clicks": "6691",
            "Conversions": "80",
            "Conversion Value": "$4,841",
            "Cost per Result": "$33.91"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "467555",
            "Clicks": "6002",
            "Conversions": "51",
            "Conversion Value": "$7,229",
            "Cost per Result": "$24.28"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "435564",
            "Clicks": "3922",
            "Conversions": "65",
            "Conversion Value": "$14,983",
            "Cost per Result": "$23.46"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "409499",
            "Clicks": "4849",
            "Conversions": "84",
            "Conversion Value": "$6,674",
            "Cost per Result": "$31.03"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "287617",
            "Clicks": "6135",
            "Conversions": "53",
            "Conversion Value": "$4,437",
            "Cost per Result": "$34.49"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "128594",
            "Clicks": "2252",
            "Conversions": "55",
            "Conversion Value": "$4,069",
            "Cost per Result": "$16.29"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "168435",
            "Clicks": "6693",
            "Conversions": "58",
            "Conversion Value": "$7,324",
            "Cost per Result": "$14.13"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "185339",
            "Clicks": "6114",
            "Conversions": "49",
            "Conversion Value": "$4,643",
            "Cost per Result": "$27.79"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "343485",
            "Clicks": "5987",
            "Conversions": "88",
            "Conversion Value": "$6,206",
            "Cost per Result": "$28.82"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "396839",
            "Clicks": "4818",
            "Conversions": "75",
            "Conversion Value": "$8,176",
            "Cost per Result": "$13.67"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "130652",
            "Clicks": "4830",
            "Conversions": "43",
            "Conversion Value": "$9,451",
            "Cost per Result": "$14.07"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "370853",
            "Clicks": "6780",
            "Conversions": "64",
            "Conversion Value": "$9,700",
            "Cost per Result": "$7.13"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "130993",
            "Clicks": "6183",
            "Conversions": "58",
            "Conversion Value": "$5,394",
            "Cost per Result": "$19.58"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "318117",
            "Clicks": "6362",
            "Conversions": "90",
            "Conversion Value": "$13,020",
            "Cost per Result": "$26.97"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "135618",
            "Clicks": "5493",
            "Conversions": "63",
            "Conversion Value": "$13,136",
            "Cost per Result": "$21.55"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "336675",
            "Clicks": "5229",
            "Conversions": "52",
            "Conversion Value": "$4,711",
            "Cost per Result": "$28.56"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "451683",
            "Clicks": "3291",
            "Conversions": "59",
            "Conversion Value": "$5,528",
            "Cost per Result": "$13.08"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "230027",
            "Clicks": "5405",
            "Conversions": "80",
            "Conversion Value": "$13,121",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "310200",
            "Clicks": "2743",
            "Conversions": "72",
            "Conversion Value": "$11,531",
            "Cost per Result": "$8.96"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "347420",
            "Clicks": "5597",
            "Conversions": "64",
            "Conversion Value": "$11,784",
            "Cost per Result": "$7.54"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "195230",
            "Clicks": "4748",
            "Conversions": "69",
            "Conversion Value": "$15,888",
            "Cost per Result": "$24.70"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "274798",
            "Clicks": "2664",
            "Conversions": "52",
            "Conversion Value": "$8,584",
            "Cost per Result": "$15.04"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "251216",
            "Clicks": "6620",
            "Conversions": "64",
            "Conversion Value": "$4,670",
            "Cost per Result": "$33.98"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "194989",
            "Clicks": "5857",
            "Conversions": "70",
            "Conversion Value": "$10,501",
            "Cost per Result": "$12.65"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "497287",
            "Clicks": "4376",
            "Conversions": "81",
            "Conversion Value": "$7,814",
            "Cost per Result": "$34.22"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "393302",
            "Clicks": "2644",
            "Conversions": "44",
            "Conversion Value": "$5,846",
            "Cost per Result": "$31.83"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "249879",
            "Clicks": "5615",
            "Conversions": "40",
            "Conversion Value": "$15,784",
            "Cost per Result": "$33.20"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "108628",
            "Clicks": "2915",
            "Conversions": "58",
            "Conversion Value": "$10,921",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "405026",
            "Clicks": "3834",
            "Conversions": "60",
            "Conversion Value": "$14,162",
            "Cost per Result": "$22.67"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "400480",
            "Clicks": "6315",
            "Conversions": "45",
            "Conversion Value": "$10,838",
            "Cost per Result": "$21.14"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "267354",
            "Clicks": "2769",
            "Conversions": "88",
            "Conversion Value": "$5,389",
            "Cost per Result": "$27.17"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "380450",
            "Clicks": "2599",
            "Conversions": "77",
            "Conversion Value": "$12,130",
            "Cost per Result": "$25.32"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "473628",
            "Clicks": "6912",
            "Conversions": "86",
            "Conversion Value": "$9,264",
            "Cost per Result": "$30.04"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "385003",
            "Clicks": "3678",
            "Conversions": "54",
            "Conversion Value": "$14,460",
            "Cost per Result": "$9.10"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "215287",
            "Clicks": "3297",
            "Conversions": "87",
            "Conversion Value": "$14,229",
            "Cost per Result": "$18.99"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "368990",
            "Clicks": "6321",
            "Conversions": "69",
            "Conversion Value": "$9,333",
            "Cost per Result": "$12.03"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "418849",
            "Clicks": "2926",
            "Conversions": "52",
            "Conversion Value": "$8,822",
            "Cost per Result": "$30.48"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "181094",
            "Clicks": "6245",
            "Conversions": "54",
            "Conversion Value": "$5,880",
            "Cost per Result": "$20.02"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "328404",
            "Clicks": "3826",
            "Conversions": "43",
            "Conversion Value": "$12,979",
            "Cost per Result": "$7.66"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "429683",
            "Clicks": "5998",
            "Conversions": "55",
            "Conversion Value": "$10,469",
            "Cost per Result": "$6.55"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "215288",
            "Clicks": "6823",
            "Conversions": "59",
            "Conversion Value": "$15,980",
            "Cost per Result": "$19.01"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "427992",
            "Clicks": "3974",
            "Conversions": "81",
            "Conversion Value": "$8,606",
            "Cost per Result": "$31.98"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "211925",
            "Clicks": "5136",
            "Conversions": "63",
            "Conversion Value": "$14,541",
            "Cost per Result": "$30.18"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "269983",
            "Clicks": "4328",
            "Conversions": "84",
            "Conversion Value": "$15,261",
            "Cost per Result": "$9.42"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "456921",
            "Clicks": "6970",
            "Conversions": "70",
            "Conversion Value": "$11,588",
            "Cost per Result": "$30.30"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "362626",
            "Clicks": "4018",
            "Conversions": "88",
            "Conversion Value": "$5,638",
            "Cost per Result": "$25.24"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "304804",
            "Clicks": "6496",
            "Conversions": "42",
            "Conversion Value": "$5,370",
            "Cost per Result": "$34.41"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "238163",
            "Clicks": "3824",
            "Conversions": "52",
            "Conversion Value": "$11,357",
            "Cost per Result": "$17.48"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "185911",
            "Clicks": "2033",
            "Conversions": "75",
            "Conversion Value": "$7,297",
            "Cost per Result": "$26.50"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "183662",
            "Clicks": "4694",
            "Conversions": "66",
            "Conversion Value": "$15,231",
            "Cost per Result": "$26.17"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420448",
            "Clicks": "5546",
            "Conversions": "85",
            "Conversion Value": "$15,852",
            "Cost per Result": "$27.56"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "128669",
            "Clicks": "5122",
            "Conversions": "70",
            "Conversion Value": "$13,917",
            "Cost per Result": "$20.01"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "347682",
            "Clicks": "5802",
            "Conversions": "75",
            "Conversion Value": "$5,604",
            "Cost per Result": "$7.61"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "180123",
            "Clicks": "2475",
            "Conversions": "82",
            "Conversion Value": "$9,848",
            "Cost per Result": "$21.25"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "309820",
            "Clicks": "2992",
            "Conversions": "47",
            "Conversion Value": "$8,183",
            "Cost per Result": "$31.74"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "423247",
            "Clicks": "2937",
            "Conversions": "42",
            "Conversion Value": "$4,466",
            "Cost per Result": "$12.07"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "144654",
            "Clicks": "3257",
            "Conversions": "42",
            "Conversion Value": "$9,350",
            "Cost per Result": "$23.34"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "435409",
            "Clicks": "2412",
            "Conversions": "53",
            "Conversion Value": "$9,908",
            "Cost per Result": "$5.23"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "437509",
            "Clicks": "6251",
            "Conversions": "70",
            "Conversion Value": "$9,541",
            "Cost per Result": "$30.77"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "285836",
            "Clicks": "6713",
            "Conversions": "86",
            "Conversion Value": "$8,318",
            "Cost per Result": "$8.00"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "238050",
            "Clicks": "2759",
            "Conversions": "65",
            "Conversion Value": "$10,413",
            "Cost per Result": "$11.10"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "197575",
            "Clicks": "3349",
            "Conversions": "66",
            "Conversion Value": "$10,712",
            "Cost per Result": "$33.64"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "169551",
            "Clicks": "5572",
            "Conversions": "66",
            "Conversion Value": "$10,443",
            "Cost per Result": "$28.38"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "290638",
            "Clicks": "2433",
            "Conversions": "61",
            "Conversion Value": "$11,048",
            "Cost per Result": "$29.47"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "186578",
            "Clicks": "2686",
            "Conversions": "75",
            "Conversion Value": "$15,470",
            "Cost per Result": "$10.23"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "149361",
            "Clicks": "3472",
            "Conversions": "50",
            "Conversion Value": "$7,691",
            "Cost per Result": "$23.35"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "142509",
            "Clicks": "5130",
            "Conversions": "45",
            "Conversion Value": "$15,356",
            "Cost per Result": "$9.75"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "186491",
            "Clicks": "2315",
            "Conversions": "43",
            "Conversion Value": "$5,754",
            "Cost per Result": "$15.73"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "112785",
            "Clicks": "6493",
            "Conversions": "72",
            "Conversion Value": "$11,682",
            "Cost per Result": "$26.91"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "216463",
            "Clicks": "4241",
            "Conversions": "77",
            "Conversion Value": "$15,695",
            "Cost per Result": "$26.19"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "116496",
            "Clicks": "4987",
            "Conversions": "71",
            "Conversion Value": "$10,441",
            "Cost per Result": "$24.02"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "187113",
            "Clicks": "5049",
            "Conversions": "66",
            "Conversion Value": "$11,792",
            "Cost per Result": "$9.59"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "352316",
            "Clicks": "2733",
            "Conversions": "51",
            "Conversion Value": "$9,036",
            "Cost per Result": "$29.40"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "220210",
            "Clicks": "6502",
            "Conversions": "88",
            "Conversion Value": "$12,482",
            "Cost per Result": "$7.91"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "310556",
            "Clicks": "2868",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "325521",
            "Clicks": "6091",
            "Conversions": "51",
            "Conversion Value": "$8,600",
            "Cost per Result": "$9.98"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "168272",
            "Clicks": "6223",
            "Conversions": "56",
            "Conversion Value": "$6,829",
            "Cost per Result": "$7.21"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "210803",
            "Clicks": "6630",
            "Conversions": "55",
            "Conversion Value": "$6,963",
            "Cost per Result": "$10.33"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "276991",
            "Clicks": "2189",
            "Conversions": "79",
            "Conversion Value": "$8,598",
            "Cost per Result": "$12.97"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "322314",
            "Clicks": "5051",
            "Conversions": "71",
            "Conversion Value": "$13,034",
            "Cost per Result": "$32.54"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "302304",
            "Clicks": "3186",
            "Conversions": "49",
            "Conversion Value": "$11,389",
            "Cost per Result": "$11.89"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "109918",
            "Clicks": "2890",
            "Conversions": "45",
            "Conversion Value": "$14,905",
            "Cost per Result": "$26.85"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "391957",
            "Clicks": "4842",
            "Conversions": "85",
            "Conversion Value": "$6,135",
            "Cost per Result": "$24.06"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "191319",
            "Clicks": "5552",
            "Conversions": "66",
            "Conversion Value": "$11,223",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "186041",
            "Clicks": "6949",
            "Conversions": "54",
            "Conversion Value": "$12,627",
            "Cost per Result": "$10.57"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "346493",
            "Clicks": "2814",
            "Conversions": "78",
            "Conversion Value": "$10,940",
            "Cost per Result": "$10.68"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "222828",
            "Clicks": "3828",
            "Conversions": "49",
            "Conversion Value": "$7,183",
            "Cost per Result": "$11.64"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "242415",
            "Clicks": "2085",
            "Conversions": "69",
            "Conversion Value": "$8,552",
            "Cost per Result": "$19.22"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "250907",
            "Clicks": "6411",
            "Conversions": "83",
            "Conversion Value": "$8,402",
            "Cost per Result": "$7.19"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "487152",
            "Clicks": "6250",
            "Conversions": "69",
            "Conversion Value": "$15,665",
            "Cost per Result": "$22.52"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "178548",
            "Clicks": "6943",
            "Conversions": "53",
            "Conversion Value": "$8,407",
            "Cost per Result": "$32.05"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "240202",
            "Clicks": "3279",
            "Conversions": "61",
            "Conversion Value": "$7,725",
            "Cost per Result": "$12.26"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151322",
            "Clicks": "2369",
            "Conversions": "71",
            "Conversion Value": "$15,896",
            "Cost per Result": "$28.94"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "242763",
            "Clicks": "5971",
            "Conversions": "90",
            "Conversion Value": "$10,460",
            "Cost per Result": "$17.33"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "357382",
            "Clicks": "3377",
            "Conversions": "59",
            "Conversion Value": "$7,751",
            "Cost per Result": "$34.27"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "438492",
            "Clicks": "6700",
            "Conversions": "78",
            "Conversion Value": "$5,176",
            "Cost per Result": "$8.86"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "455744",
            "Clicks": "6482",
            "Conversions": "70",
            "Conversion Value": "$8,339",
            "Cost per Result": "$28.35"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "275216",
            "Clicks": "3147",
            "Conversions": "81",
            "Conversion Value": "$6,376",
            "Cost per Result": "$32.08"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "177186",
            "Clicks": "5267",
            "Conversions": "47",
            "Conversion Value": "$8,758",
            "Cost per Result": "$5.50"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "434427",
            "Clicks": "6753",
            "Conversions": "64",
            "Conversion Value": "$6,233",
            "Cost per Result": "$9.95"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "461964",
            "Clicks": "4512",
            "Conversions": "86",
            "Conversion Value": "$7,422",
            "Cost per Result": "$13.94"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "133571",
            "Clicks": "3702",
            "Conversions": "55",
            "Conversion Value": "$11,020",
            "Cost per Result": "$33.49"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "206533",
            "Clicks": "5945",
            "Conversions": "41",
            "Conversion Value": "$12,163",
            "Cost per Result": "$28.61"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "472386",
            "Clicks": "4523",
            "Conversions": "50",
            "Conversion Value": "$7,454",
            "Cost per Result": "$5.82"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "257518",
            "Clicks": "4374",
            "Conversions": "85",
            "Conversion Value": "$6,593",
            "Cost per Result": "$14.27"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "216343",
            "Clicks": "3653",
            "Conversions": "87",
            "Conversion Value": "$10,847",
            "Cost per Result": "$31.28"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "410693",
            "Clicks": "5880",
            "Conversions": "74",
            "Conversion Value": "$14,491",
            "Cost per Result": "$13.89"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "376611",
            "Clicks": "6423",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$32.34"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "173018",
            "Clicks": "5319",
            "Conversions": "76",
            "Conversion Value": "$14,180",
            "Cost per Result": "$22.21"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "286719",
            "Clicks": "6766",
            "Conversions": "78",
            "Conversion Value": "$11,592",
            "Cost per Result": "$34.19"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "384723",
            "Clicks": "6090",
            "Conversions": "70",
            "Conversion Value": "$12,033",
            "Cost per Result": "$31.12"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "102892",
            "Clicks": "2309",
            "Conversions": "47",
            "Conversion Value": "$15,597",
            "Cost per Result": "$16.25"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "165687",
            "Clicks": "2582",
            "Conversions": "71",
            "Conversion Value": "$7,923",
            "Cost per Result": "$27.58"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "360111",
            "Clicks": "6478",
            "Conversions": "55",
            "Conversion Value": "$4,800",
            "Cost per Result": "$16.51"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "266585",
            "Clicks": "2076",
            "Conversions": "41",
            "Conversion Value": "$10,178",
            "Cost per Result": "$34.76"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "173388",
            "Clicks": "3362",
            "Conversions": "69",
            "Conversion Value": "$8,487",
            "Cost per Result": "$19.30"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "222307",
            "Clicks": "6714",
            "Conversions": "80",
            "Conversion Value": "$11,976",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "338531",
            "Clicks": "5885",
            "Conversions": "79",
            "Conversion Value": "$5,500",
            "Cost per Result": "$19.61"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "306434",
            "Clicks": "4212",
            "Conversions": "42",
            "Conversion Value": "$14,485",
            "Cost per Result": "$28.15"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "395078",
            "Clicks": "5346",
            "Conversions": "45",
            "Conversion Value": "$9,954",
            "Cost per Result": "$32.16"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "185258",
            "Clicks": "3811",
            "Conversions": "77",
            "Conversion Value": "$7,797",
            "Cost per Result": "$34.45"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "183822",
            "Clicks": "4736",
            "Conversions": "90",
            "Conversion Value": "$15,025",
            "Cost per Result": "$17.08"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "113899",
            "Clicks": "2848",
            "Conversions": "65",
            "Conversion Value": "$15,192",
            "Cost per Result": "$8.60"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "461089",
            "Clicks": "4090",
            "Conversions": "52",
            "Conversion Value": "$14,814",
            "Cost per Result": "$23.88"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "282282",
            "Clicks": "6884",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$13.83"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "301894",
            "Clicks": "5792",
            "Conversions": "55",
            "Conversion Value": "$7,837",
            "Cost per Result": "$23.81"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "213428",
            "Clicks": "3324",
            "Conversions": "80",
            "Conversion Value": "$11,054",
            "Cost per Result": "$7.89"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "295535",
            "Clicks": "6907",
            "Conversions": "56",
            "Conversion Value": "$6,241",
            "Cost per Result": "$28.37"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "480036",
            "Clicks": "4602",
            "Conversions": "52",
            "Conversion Value": "$5,443",
            "Cost per Result": "$30.01"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "152661",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$5,922",
            "Cost per Result": "$7.50"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "308410",
            "Clicks": "2485",
            "Conversions": "90",
            "Conversion Value": "$7,448",
            "Cost per Result": "$18.12"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "126277",
            "Clicks": "6422",
            "Conversions": "69",
            "Conversion Value": "$4,574",
            "Cost per Result": "$7.98"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "383491",
            "Clicks": "6784",
            "Conversions": "65",
            "Conversion Value": "$14,865",
            "Cost per Result": "$23.79"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "305477",
            "Clicks": "6930",
            "Conversions": "90",
            "Conversion Value": "$12,898",
            "Cost per Result": "$7.30"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "188039",
            "Clicks": "4281",
            "Conversions": "76",
            "Conversion Value": "$15,614",
            "Cost per Result": "$34.56"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "256397",
            "Clicks": "6774",
            "Conversions": "49",
            "Conversion Value": "$4,758",
            "Cost per Result": "$14.97"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "167637",
            "Clicks": "6035",
            "Conversions": "78",
            "Conversion Value": "$4,237",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "427427",
            "Clicks": "5580",
            "Conversions": "78",
            "Conversion Value": "$4,461",
            "Cost per Result": "$26.87"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "106572",
            "Clicks": "2507",
            "Conversions": "57",
            "Conversion Value": "$14,726",
            "Cost per Result": "$22.30"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "353946",
            "Clicks": "6443",
            "Conversions": "41",
            "Conversion Value": "$15,482",
            "Cost per Result": "$14.18"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "154964",
            "Clicks": "2133",
            "Conversions": "74",
            "Conversion Value": "$6,758",
            "Cost per Result": "$29.44"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "221931",
            "Clicks": "6457",
            "Conversions": "53",
            "Conversion Value": "$4,137",
            "Cost per Result": "$19.44"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "122829",
            "Clicks": "3778",
            "Conversions": "74",
            "Conversion Value": "$12,165",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "190920",
            "Clicks": "2000",
            "Conversions": "81",
            "Conversion Value": "$7,827",
            "Cost per Result": "$15.62"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "141579",
            "Clicks": "4884",
            "Conversions": "65",
            "Conversion Value": "$6,009",
            "Cost per Result": "$11.50"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "127663",
            "Clicks": "6586",
            "Conversions": "87",
            "Conversion Value": "$5,498",
            "Cost per Result": "$10.01"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "308489",
            "Clicks": "3371",
            "Conversions": "59",
            "Conversion Value": "$5,902",
            "Cost per Result": "$23.38"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "136184",
            "Clicks": "2950",
            "Conversions": "65",
            "Conversion Value": "$5,940",
            "Cost per Result": "$32.43"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "226835",
            "Clicks": "2383",
            "Conversions": "58",
            "Conversion Value": "$11,323",
            "Cost per Result": "$25.61"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "442906",
            "Clicks": "6480",
            "Conversions": "49",
            "Conversion Value": "$10,110",
            "Cost per Result": "$33.83"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "316950",
            "Clicks": "5686",
            "Conversions": "76",
            "Conversion Value": "$4,373",
            "Cost per Result": "$9.84"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "343440",
            "Clicks": "4429",
            "Conversions": "52",
            "Conversion Value": "$6,698",
            "Cost per Result": "$29.39"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "315753",
            "Clicks": "4847",
            "Conversions": "63",
            "Conversion Value": "$8,903",
            "Cost per Result": "$6.47"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "257999",
            "Clicks": "4865",
            "Conversions": "61",
            "Conversion Value": "$6,459",
            "Cost per Result": "$12.52"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "350217",
            "Clicks": "4640",
            "Conversions": "89",
            "Conversion Value": "$9,495",
            "Cost per Result": "$22.45"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "474133",
            "Clicks": "6964",
            "Conversions": "77",
            "Conversion Value": "$11,829",
            "Cost per Result": "$28.43"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "440496",
            "Clicks": "4429",
            "Conversions": "66",
            "Conversion Value": "$10,856",
            "Cost per Result": "$9.33"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "358663",
            "Clicks": "3662",
            "Conversions": "66",
            "Conversion Value": "$10,891",
            "Cost per Result": "$7.88"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "469477",
            "Clicks": "2537",
            "Conversions": "65",
            "Conversion Value": "$13,691",
            "Cost per Result": "$27.56"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "156529",
            "Clicks": "5658",
            "Conversions": "69",
            "Conversion Value": "$10,138",
            "Cost per Result": "$11.58"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "186598",
            "Clicks": "3736",
            "Conversions": "82",
            "Conversion Value": "$9,386",
            "Cost per Result": "$32.73"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "305503",
            "Clicks": "4040",
            "Conversions": "54",
            "Conversion Value": "$7,375",
            "Cost per Result": "$19.37"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "265731",
            "Clicks": "3552",
            "Conversions": "66",
            "Conversion Value": "$13,833",
            "Cost per Result": "$26.59"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "206038",
            "Clicks": "5150",
            "Conversions": "87",
            "Conversion Value": "$11,712",
            "Cost per Result": "$7.53"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "411616",
            "Clicks": "5065",
            "Conversions": "69",
            "Conversion Value": "$13,456",
            "Cost per Result": "$8.10"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "400132",
            "Clicks": "5626",
            "Conversions": "53",
            "Conversion Value": "$6,460",
            "Cost per Result": "$34.82"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "140071",
            "Clicks": "5239",
            "Conversions": "44",
            "Conversion Value": "$7,931",
            "Cost per Result": "$19.88"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "318151",
            "Clicks": "6448",
            "Conversions": "89",
            "Conversion Value": "$9,954",
            "Cost per Result": "$6.16"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "108293",
            "Clicks": "3758",
            "Conversions": "42",
            "Conversion Value": "$12,015",
            "Cost per Result": "$32.51"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "215882",
            "Clicks": "5418",
            "Conversions": "43",
            "Conversion Value": "$7,239",
            "Cost per Result": "$34.20"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "438852",
            "Clicks": "3198",
            "Conversions": "56",
            "Conversion Value": "$10,250",
            "Cost per Result": "$18.22"
          }
        ]
      },
      "TokenTradersData": {
        "name": "Token Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "474292",
            "Clicks": "4961",
            "Conversions": "66",
            "Conversion Value": "$5,756",
            "Cost per Result": "$25.76"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490382",
            "Clicks": "6151",
            "Conversions": "62",
            "Conversion Value": "$5,029",
            "Cost per Result": "$7.00"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "284588",
            "Clicks": "3405",
            "Conversions": "56",
            "Conversion Value": "$10,994",
            "Cost per Result": "$13.90"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "271991",
            "Clicks": "2993",
            "Conversions": "74",
            "Conversion Value": "$11,778",
            "Cost per Result": "$21.28"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "289197",
            "Clicks": "5208",
            "Conversions": "79",
            "Conversion Value": "$12,697",
            "Cost per Result": "$30.06"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "152592",
            "Clicks": "2914",
            "Conversions": "42",
            "Conversion Value": "$10,730",
            "Cost per Result": "$10.16"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "189316",
            "Clicks": "2338",
            "Conversions": "50",
            "Conversion Value": "$13,745",
            "Cost per Result": "$13.96"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "488025",
            "Clicks": "3288",
            "Conversions": "80",
            "Conversion Value": "$12,914",
            "Cost per Result": "$14.77"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "112648",
            "Clicks": "2320",
            "Conversions": "46",
            "Conversion Value": "$8,534",
            "Cost per Result": "$6.88"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "189846",
            "Clicks": "4643",
            "Conversions": "90",
            "Conversion Value": "$15,730",
            "Cost per Result": "$32.17"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "111422",
            "Clicks": "3623",
            "Conversions": "51",
            "Conversion Value": "$11,623",
            "Cost per Result": "$12.51"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "258550",
            "Clicks": "6423",
            "Conversions": "63",
            "Conversion Value": "$13,905",
            "Cost per Result": "$25.35"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "154569",
            "Clicks": "3348",
            "Conversions": "71",
            "Conversion Value": "$15,954",
            "Cost per Result": "$7.22"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "340583",
            "Clicks": "3756",
            "Conversions": "51",
            "Conversion Value": "$11,458",
            "Cost per Result": "$20.67"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "164444",
            "Clicks": "4302",
            "Conversions": "55",
            "Conversion Value": "$5,720",
            "Cost per Result": "$31.10"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "291301",
            "Clicks": "5677",
            "Conversions": "73",
            "Conversion Value": "$10,645",
            "Cost per Result": "$24.21"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "248508",
            "Clicks": "6002",
            "Conversions": "57",
            "Conversion Value": "$15,921",
            "Cost per Result": "$12.25"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "178834",
            "Clicks": "3541",
            "Conversions": "55",
            "Conversion Value": "$14,055",
            "Cost per Result": "$25.42"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "359124",
            "Clicks": "4636",
            "Conversions": "64",
            "Conversion Value": "$8,901",
            "Cost per Result": "$10.82"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "116847",
            "Clicks": "4910",
            "Conversions": "46",
            "Conversion Value": "$5,478",
            "Cost per Result": "$7.07"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "122722",
            "Clicks": "2091",
            "Conversions": "43",
            "Conversion Value": "$12,520",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "337959",
            "Clicks": "4515",
            "Conversions": "90",
            "Conversion Value": "$6,759",
            "Cost per Result": "$13.57"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "436789",
            "Clicks": "6725",
            "Conversions": "61",
            "Conversion Value": "$12,044",
            "Cost per Result": "$32.84"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "416204",
            "Clicks": "3011",
            "Conversions": "74",
            "Conversion Value": "$12,791",
            "Cost per Result": "$9.47"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "244594",
            "Clicks": "3474",
            "Conversions": "52",
            "Conversion Value": "$9,216",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "449480",
            "Clicks": "2165",
            "Conversions": "58",
            "Conversion Value": "$8,851",
            "Cost per Result": "$22.73"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "249571",
            "Clicks": "6715",
            "Conversions": "82",
            "Conversion Value": "$11,960",
            "Cost per Result": "$7.53"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "285923",
            "Clicks": "6913",
            "Conversions": "42",
            "Conversion Value": "$5,604",
            "Cost per Result": "$32.19"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "291364",
            "Clicks": "4272",
            "Conversions": "47",
            "Conversion Value": "$9,607",
            "Cost per Result": "$14.12"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "417304",
            "Clicks": "5873",
            "Conversions": "80",
            "Conversion Value": "$6,051",
            "Cost per Result": "$33.34"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "122692",
            "Clicks": "4398",
            "Conversions": "82",
            "Conversion Value": "$10,030",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "294768",
            "Clicks": "4608",
            "Conversions": "72",
            "Conversion Value": "$14,311",
            "Cost per Result": "$12.58"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "130244",
            "Clicks": "5932",
            "Conversions": "81",
            "Conversion Value": "$15,114",
            "Cost per Result": "$15.68"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "405792",
            "Clicks": "5129",
            "Conversions": "81",
            "Conversion Value": "$13,942",
            "Cost per Result": "$16.19"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "445350",
            "Clicks": "5353",
            "Conversions": "46",
            "Conversion Value": "$4,360",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "175447",
            "Clicks": "5044",
            "Conversions": "90",
            "Conversion Value": "$13,292",
            "Cost per Result": "$22.87"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "483573",
            "Clicks": "2751",
            "Conversions": "46",
            "Conversion Value": "$15,238",
            "Cost per Result": "$16.99"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "240875",
            "Clicks": "4942",
            "Conversions": "72",
            "Conversion Value": "$13,590",
            "Cost per Result": "$25.00"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "210583",
            "Clicks": "2819",
            "Conversions": "73",
            "Conversion Value": "$5,894",
            "Cost per Result": "$23.39"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "346907",
            "Clicks": "4215",
            "Conversions": "65",
            "Conversion Value": "$4,685",
            "Cost per Result": "$26.22"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "409717",
            "Clicks": "4896",
            "Conversions": "65",
            "Conversion Value": "$9,125",
            "Cost per Result": "$12.13"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "406588",
            "Clicks": "2670",
            "Conversions": "82",
            "Conversion Value": "$8,915",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "160826",
            "Clicks": "4439",
            "Conversions": "73",
            "Conversion Value": "$10,472",
            "Cost per Result": "$9.52"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "377095",
            "Clicks": "6242",
            "Conversions": "73",
            "Conversion Value": "$10,925",
            "Cost per Result": "$16.77"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "348052",
            "Clicks": "3542",
            "Conversions": "55",
            "Conversion Value": "$4,130",
            "Cost per Result": "$24.72"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "465122",
            "Clicks": "3386",
            "Conversions": "49",
            "Conversion Value": "$4,720",
            "Cost per Result": "$31.18"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "394664",
            "Clicks": "2609",
            "Conversions": "66",
            "Conversion Value": "$14,227",
            "Cost per Result": "$9.75"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "173084",
            "Clicks": "4734",
            "Conversions": "79",
            "Conversion Value": "$14,915",
            "Cost per Result": "$18.66"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "356366",
            "Clicks": "3702",
            "Conversions": "43",
            "Conversion Value": "$7,922",
            "Cost per Result": "$27.08"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "136674",
            "Clicks": "5286",
            "Conversions": "63",
            "Conversion Value": "$8,440",
            "Cost per Result": "$26.39"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "351663",
            "Clicks": "6636",
            "Conversions": "77",
            "Conversion Value": "$4,959",
            "Cost per Result": "$21.03"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "324864",
            "Clicks": "3296",
            "Conversions": "67",
            "Conversion Value": "$14,830",
            "Cost per Result": "$23.55"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "111676",
            "Clicks": "4522",
            "Conversions": "59",
            "Conversion Value": "$13,371",
            "Cost per Result": "$15.53"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "246104",
            "Clicks": "5572",
            "Conversions": "70",
            "Conversion Value": "$5,729",
            "Cost per Result": "$14.30"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "215955",
            "Clicks": "5116",
            "Conversions": "67",
            "Conversion Value": "$9,326",
            "Cost per Result": "$13.85"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "231112",
            "Clicks": "4573",
            "Conversions": "76",
            "Conversion Value": "$13,546",
            "Cost per Result": "$14.92"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "250489",
            "Clicks": "4297",
            "Conversions": "87",
            "Conversion Value": "$14,080",
            "Cost per Result": "$30.46"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "476579",
            "Clicks": "5714",
            "Conversions": "54",
            "Conversion Value": "$5,981",
            "Cost per Result": "$30.10"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "165589",
            "Clicks": "2967",
            "Conversions": "62",
            "Conversion Value": "$4,654",
            "Cost per Result": "$31.39"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "399658",
            "Clicks": "4851",
            "Conversions": "41",
            "Conversion Value": "$8,529",
            "Cost per Result": "$30.79"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "492103",
            "Clicks": "3230",
            "Conversions": "55",
            "Conversion Value": "$14,847",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "366123",
            "Clicks": "2971",
            "Conversions": "56",
            "Conversion Value": "$14,624",
            "Cost per Result": "$15.11"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "343499",
            "Clicks": "4286",
            "Conversions": "89",
            "Conversion Value": "$15,153",
            "Cost per Result": "$26.84"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "242348",
            "Clicks": "5890",
            "Conversions": "41",
            "Conversion Value": "$10,822",
            "Cost per Result": "$24.84"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "318671",
            "Clicks": "6006",
            "Conversions": "89",
            "Conversion Value": "$7,707",
            "Cost per Result": "$14.27"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "441164",
            "Clicks": "2604",
            "Conversions": "83",
            "Conversion Value": "$4,753",
            "Cost per Result": "$28.19"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "337242",
            "Clicks": "4826",
            "Conversions": "59",
            "Conversion Value": "$13,016",
            "Cost per Result": "$26.55"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "373442",
            "Clicks": "2257",
            "Conversions": "81",
            "Conversion Value": "$5,316",
            "Cost per Result": "$28.75"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "378284",
            "Clicks": "3576",
            "Conversions": "47",
            "Conversion Value": "$6,438",
            "Cost per Result": "$24.88"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "201888",
            "Clicks": "2233",
            "Conversions": "45",
            "Conversion Value": "$7,232",
            "Cost per Result": "$16.25"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "328534",
            "Clicks": "6173",
            "Conversions": "60",
            "Conversion Value": "$11,611",
            "Cost per Result": "$25.88"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "472640",
            "Clicks": "6224",
            "Conversions": "62",
            "Conversion Value": "$13,073",
            "Cost per Result": "$6.11"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "432418",
            "Clicks": "4176",
            "Conversions": "65",
            "Conversion Value": "$14,440",
            "Cost per Result": "$23.54"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "457419",
            "Clicks": "6421",
            "Conversions": "58",
            "Conversion Value": "$15,786",
            "Cost per Result": "$25.72"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "109878",
            "Clicks": "2875",
            "Conversions": "57",
            "Conversion Value": "$6,428",
            "Cost per Result": "$32.26"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "188292",
            "Clicks": "2919",
            "Conversions": "43",
            "Conversion Value": "$9,741",
            "Cost per Result": "$20.62"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "186691",
            "Clicks": "2460",
            "Conversions": "64",
            "Conversion Value": "$10,944",
            "Cost per Result": "$16.10"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "454521",
            "Clicks": "5631",
            "Conversions": "48",
            "Conversion Value": "$11,957",
            "Cost per Result": "$27.19"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "464462",
            "Clicks": "3659",
            "Conversions": "58",
            "Conversion Value": "$9,916",
            "Cost per Result": "$23.62"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "133300",
            "Clicks": "6272",
            "Conversions": "74",
            "Conversion Value": "$12,452",
            "Cost per Result": "$27.93"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "323918",
            "Clicks": "2851",
            "Conversions": "53",
            "Conversion Value": "$6,288",
            "Cost per Result": "$22.07"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "151755",
            "Clicks": "4415",
            "Conversions": "59",
            "Conversion Value": "$13,966",
            "Cost per Result": "$16.33"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "366794",
            "Clicks": "3559",
            "Conversions": "64",
            "Conversion Value": "$10,564",
            "Cost per Result": "$22.20"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "365639",
            "Clicks": "6006",
            "Conversions": "86",
            "Conversion Value": "$10,621",
            "Cost per Result": "$22.72"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "101058",
            "Clicks": "3608",
            "Conversions": "68",
            "Conversion Value": "$10,724",
            "Cost per Result": "$25.81"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "461708",
            "Clicks": "3814",
            "Conversions": "42",
            "Conversion Value": "$11,558",
            "Cost per Result": "$14.03"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "285404",
            "Clicks": "5261",
            "Conversions": "56",
            "Conversion Value": "$4,770",
            "Cost per Result": "$12.02"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "447450",
            "Clicks": "2811",
            "Conversions": "71",
            "Conversion Value": "$8,128",
            "Cost per Result": "$28.16"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "176720",
            "Clicks": "6902",
            "Conversions": "47",
            "Conversion Value": "$6,831",
            "Cost per Result": "$7.13"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "117101",
            "Clicks": "2709",
            "Conversions": "66",
            "Conversion Value": "$13,897",
            "Cost per Result": "$9.04"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "155230",
            "Clicks": "5215",
            "Conversions": "41",
            "Conversion Value": "$6,056",
            "Cost per Result": "$34.28"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "241024",
            "Clicks": "2648",
            "Conversions": "43",
            "Conversion Value": "$9,197",
            "Cost per Result": "$6.47"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "103453",
            "Clicks": "2157",
            "Conversions": "69",
            "Conversion Value": "$5,050",
            "Cost per Result": "$6.73"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "212329",
            "Clicks": "2069",
            "Conversions": "83",
            "Conversion Value": "$9,311",
            "Cost per Result": "$25.62"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "336177",
            "Clicks": "3090",
            "Conversions": "44",
            "Conversion Value": "$6,079",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "427024",
            "Clicks": "2026",
            "Conversions": "55",
            "Conversion Value": "$10,049",
            "Cost per Result": "$34.90"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "349157",
            "Clicks": "5259",
            "Conversions": "50",
            "Conversion Value": "$7,217",
            "Cost per Result": "$14.45"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "430133",
            "Clicks": "4842",
            "Conversions": "83",
            "Conversion Value": "$10,453",
            "Cost per Result": "$28.26"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "285617",
            "Clicks": "6983",
            "Conversions": "66",
            "Conversion Value": "$5,696",
            "Cost per Result": "$11.09"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "319688",
            "Clicks": "4021",
            "Conversions": "80",
            "Conversion Value": "$5,026",
            "Cost per Result": "$11.42"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "404499",
            "Clicks": "4766",
            "Conversions": "75",
            "Conversion Value": "$8,149",
            "Cost per Result": "$33.75"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "483856",
            "Clicks": "2524",
            "Conversions": "75",
            "Conversion Value": "$11,918",
            "Cost per Result": "$34.43"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "346490",
            "Clicks": "6343",
            "Conversions": "78",
            "Conversion Value": "$7,951",
            "Cost per Result": "$13.18"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "305757",
            "Clicks": "4741",
            "Conversions": "67",
            "Conversion Value": "$15,373",
            "Cost per Result": "$20.25"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "288315",
            "Clicks": "3163",
            "Conversions": "60",
            "Conversion Value": "$10,487",
            "Cost per Result": "$22.61"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "388921",
            "Clicks": "2299",
            "Conversions": "90",
            "Conversion Value": "$11,876",
            "Cost per Result": "$14.71"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "188923",
            "Clicks": "5014",
            "Conversions": "81",
            "Conversion Value": "$12,417",
            "Cost per Result": "$27.92"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "348286",
            "Clicks": "6914",
            "Conversions": "69",
            "Conversion Value": "$9,234",
            "Cost per Result": "$6.19"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "180553",
            "Clicks": "6297",
            "Conversions": "43",
            "Conversion Value": "$12,908",
            "Cost per Result": "$30.62"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "222842",
            "Clicks": "5978",
            "Conversions": "75",
            "Conversion Value": "$8,507",
            "Cost per Result": "$8.69"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "242172",
            "Clicks": "2891",
            "Conversions": "59",
            "Conversion Value": "$7,709",
            "Cost per Result": "$7.01"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "473469",
            "Clicks": "6307",
            "Conversions": "46",
            "Conversion Value": "$10,689",
            "Cost per Result": "$32.04"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "392377",
            "Clicks": "2596",
            "Conversions": "82",
            "Conversion Value": "$8,676",
            "Cost per Result": "$13.46"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "441346",
            "Clicks": "3891",
            "Conversions": "40",
            "Conversion Value": "$12,279",
            "Cost per Result": "$12.12"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "314974",
            "Clicks": "6671",
            "Conversions": "70",
            "Conversion Value": "$14,267",
            "Cost per Result": "$19.08"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "398065",
            "Clicks": "2688",
            "Conversions": "87",
            "Conversion Value": "$13,732",
            "Cost per Result": "$11.73"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "225657",
            "Clicks": "5346",
            "Conversions": "42",
            "Conversion Value": "$8,251",
            "Cost per Result": "$19.96"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "388822",
            "Clicks": "3323",
            "Conversions": "79",
            "Conversion Value": "$4,104",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "126261",
            "Clicks": "2854",
            "Conversions": "77",
            "Conversion Value": "$10,650",
            "Cost per Result": "$11.03"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "256950",
            "Clicks": "5161",
            "Conversions": "77",
            "Conversion Value": "$8,137",
            "Cost per Result": "$26.46"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "279128",
            "Clicks": "6299",
            "Conversions": "54",
            "Conversion Value": "$12,197",
            "Cost per Result": "$32.38"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "174557",
            "Clicks": "2005",
            "Conversions": "48",
            "Conversion Value": "$5,414",
            "Cost per Result": "$12.13"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "454501",
            "Clicks": "4722",
            "Conversions": "44",
            "Conversion Value": "$7,636",
            "Cost per Result": "$11.17"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "354793",
            "Clicks": "6749",
            "Conversions": "68",
            "Conversion Value": "$4,005",
            "Cost per Result": "$18.61"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "426068",
            "Clicks": "2160",
            "Conversions": "66",
            "Conversion Value": "$9,156",
            "Cost per Result": "$9.27"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "381919",
            "Clicks": "2723",
            "Conversions": "87",
            "Conversion Value": "$12,918",
            "Cost per Result": "$12.88"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "262738",
            "Clicks": "4207",
            "Conversions": "57",
            "Conversion Value": "$7,287",
            "Cost per Result": "$13.26"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "192775",
            "Clicks": "5299",
            "Conversions": "54",
            "Conversion Value": "$6,520",
            "Cost per Result": "$32.45"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "218466",
            "Clicks": "2032",
            "Conversions": "83",
            "Conversion Value": "$13,979",
            "Cost per Result": "$10.05"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "335810",
            "Clicks": "5075",
            "Conversions": "82",
            "Conversion Value": "$12,572",
            "Cost per Result": "$7.48"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "467777",
            "Clicks": "4725",
            "Conversions": "46",
            "Conversion Value": "$6,952",
            "Cost per Result": "$18.94"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "110774",
            "Clicks": "6893",
            "Conversions": "66",
            "Conversion Value": "$10,092",
            "Cost per Result": "$17.78"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "265182",
            "Clicks": "3732",
            "Conversions": "40",
            "Conversion Value": "$13,283",
            "Cost per Result": "$13.22"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "474488",
            "Clicks": "5007",
            "Conversions": "52",
            "Conversion Value": "$15,803",
            "Cost per Result": "$29.11"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "195746",
            "Clicks": "6816",
            "Conversions": "73",
            "Conversion Value": "$8,808",
            "Cost per Result": "$30.37"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "337336",
            "Clicks": "4167",
            "Conversions": "43",
            "Conversion Value": "$11,539",
            "Cost per Result": "$25.53"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "186702",
            "Clicks": "5010",
            "Conversions": "67",
            "Conversion Value": "$13,856",
            "Cost per Result": "$14.76"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "154983",
            "Clicks": "6819",
            "Conversions": "86",
            "Conversion Value": "$4,453",
            "Cost per Result": "$30.75"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "402618",
            "Clicks": "5098",
            "Conversions": "89",
            "Conversion Value": "$14,082",
            "Cost per Result": "$27.35"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "454241",
            "Clicks": "5184",
            "Conversions": "53",
            "Conversion Value": "$13,341",
            "Cost per Result": "$21.60"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "266677",
            "Clicks": "2092",
            "Conversions": "85",
            "Conversion Value": "$5,235",
            "Cost per Result": "$30.03"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "167093",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$9,270",
            "Cost per Result": "$29.79"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "237094",
            "Clicks": "2464",
            "Conversions": "54",
            "Conversion Value": "$13,483",
            "Cost per Result": "$21.97"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "459425",
            "Clicks": "3731",
            "Conversions": "52",
            "Conversion Value": "$12,241",
            "Cost per Result": "$7.04"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "300483",
            "Clicks": "2630",
            "Conversions": "50",
            "Conversion Value": "$15,678",
            "Cost per Result": "$21.66"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "139106",
            "Clicks": "4069",
            "Conversions": "84",
            "Conversion Value": "$7,894",
            "Cost per Result": "$23.84"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "284587",
            "Clicks": "5575",
            "Conversions": "51",
            "Conversion Value": "$7,678",
            "Cost per Result": "$10.61"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "351449",
            "Clicks": "4223",
            "Conversions": "72",
            "Conversion Value": "$9,587",
            "Cost per Result": "$19.47"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "383663",
            "Clicks": "3101",
            "Conversions": "84",
            "Conversion Value": "$12,403",
            "Cost per Result": "$7.15"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "489144",
            "Clicks": "5451",
            "Conversions": "62",
            "Conversion Value": "$12,389",
            "Cost per Result": "$11.97"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "254856",
            "Clicks": "4747",
            "Conversions": "64",
            "Conversion Value": "$7,759",
            "Cost per Result": "$26.48"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "460681",
            "Clicks": "3726",
            "Conversions": "54",
            "Conversion Value": "$5,814",
            "Cost per Result": "$11.67"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "488134",
            "Clicks": "6953",
            "Conversions": "54",
            "Conversion Value": "$8,637",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "209174",
            "Clicks": "6266",
            "Conversions": "46",
            "Conversion Value": "$7,290",
            "Cost per Result": "$20.52"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "362195",
            "Clicks": "6041",
            "Conversions": "89",
            "Conversion Value": "$15,711",
            "Cost per Result": "$6.64"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "416797",
            "Clicks": "4934",
            "Conversions": "52",
            "Conversion Value": "$4,328",
            "Cost per Result": "$29.84"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "241673",
            "Clicks": "4266",
            "Conversions": "78",
            "Conversion Value": "$15,389",
            "Cost per Result": "$19.20"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "364490",
            "Clicks": "4625",
            "Conversions": "83",
            "Conversion Value": "$8,942",
            "Cost per Result": "$7.23"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "395880",
            "Clicks": "3894",
            "Conversions": "58",
            "Conversion Value": "$12,777",
            "Cost per Result": "$15.66"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "269188",
            "Clicks": "2341",
            "Conversions": "56",
            "Conversion Value": "$9,805",
            "Cost per Result": "$13.84"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "156243",
            "Clicks": "6557",
            "Conversions": "45",
            "Conversion Value": "$7,290",
            "Cost per Result": "$11.69"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "349609",
            "Clicks": "5910",
            "Conversions": "62",
            "Conversion Value": "$9,584",
            "Cost per Result": "$21.94"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "289925",
            "Clicks": "3958",
            "Conversions": "44",
            "Conversion Value": "$13,395",
            "Cost per Result": "$32.80"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "466252",
            "Clicks": "4268",
            "Conversions": "77",
            "Conversion Value": "$6,528",
            "Cost per Result": "$28.13"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "243298",
            "Clicks": "6368",
            "Conversions": "83",
            "Conversion Value": "$10,998",
            "Cost per Result": "$18.08"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "462305",
            "Clicks": "3520",
            "Conversions": "82",
            "Conversion Value": "$9,213",
            "Cost per Result": "$25.44"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "475027",
            "Clicks": "2911",
            "Conversions": "72",
            "Conversion Value": "$10,035",
            "Cost per Result": "$22.42"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151576",
            "Clicks": "5831",
            "Conversions": "83",
            "Conversion Value": "$15,904",
            "Cost per Result": "$11.21"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "309305",
            "Clicks": "5700",
            "Conversions": "90",
            "Conversion Value": "$11,766",
            "Cost per Result": "$24.46"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "240634",
            "Clicks": "5567",
            "Conversions": "62",
            "Conversion Value": "$8,672",
            "Cost per Result": "$32.77"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "231883",
            "Clicks": "4855",
            "Conversions": "89",
            "Conversion Value": "$15,784",
            "Cost per Result": "$26.38"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "349426",
            "Clicks": "2426",
            "Conversions": "41",
            "Conversion Value": "$6,643",
            "Cost per Result": "$17.09"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "242278",
            "Clicks": "4944",
            "Conversions": "79",
            "Conversion Value": "$12,778",
            "Cost per Result": "$23.45"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "453551",
            "Clicks": "2893",
            "Conversions": "89",
            "Conversion Value": "$10,731",
            "Cost per Result": "$9.38"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "463941",
            "Clicks": "2607",
            "Conversions": "65",
            "Conversion Value": "$14,951",
            "Cost per Result": "$24.19"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "488898",
            "Clicks": "4830",
            "Conversions": "49",
            "Conversion Value": "$10,371",
            "Cost per Result": "$30.70"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "280212",
            "Clicks": "4934",
            "Conversions": "85",
            "Conversion Value": "$4,627",
            "Cost per Result": "$15.82"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "443912",
            "Clicks": "3222",
            "Conversions": "69",
            "Conversion Value": "$8,095",
            "Cost per Result": "$12.76"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "168894",
            "Clicks": "6604",
            "Conversions": "59",
            "Conversion Value": "$5,221",
            "Cost per Result": "$30.65"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "155600",
            "Clicks": "5508",
            "Conversions": "54",
            "Conversion Value": "$11,499",
            "Cost per Result": "$34.31"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "194866",
            "Clicks": "3893",
            "Conversions": "64",
            "Conversion Value": "$5,091",
            "Cost per Result": "$8.52"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "493351",
            "Clicks": "5134",
            "Conversions": "53",
            "Conversion Value": "$12,366",
            "Cost per Result": "$23.92"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "212168",
            "Clicks": "4364",
            "Conversions": "58",
            "Conversion Value": "$6,036",
            "Cost per Result": "$31.29"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "161617",
            "Clicks": "6362",
            "Conversions": "49",
            "Conversion Value": "$9,258",
            "Cost per Result": "$20.17"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "129442",
            "Clicks": "3593",
            "Conversions": "81",
            "Conversion Value": "$7,901",
            "Cost per Result": "$12.11"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "211732",
            "Clicks": "4062",
            "Conversions": "40",
            "Conversion Value": "$11,868",
            "Cost per Result": "$16.23"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "191849",
            "Clicks": "4463",
            "Conversions": "54",
            "Conversion Value": "$8,932",
            "Cost per Result": "$14.96"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "389009",
            "Clicks": "5686",
            "Conversions": "53",
            "Conversion Value": "$7,603",
            "Cost per Result": "$18.27"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "496275",
            "Clicks": "4756",
            "Conversions": "75",
            "Conversion Value": "$13,705",
            "Cost per Result": "$34.14"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "249578",
            "Clicks": "5483",
            "Conversions": "48",
            "Conversion Value": "$11,112",
            "Cost per Result": "$10.79"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "233443",
            "Clicks": "5751",
            "Conversions": "69",
            "Conversion Value": "$8,438",
            "Cost per Result": "$27.65"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "389027",
            "Clicks": "6782",
            "Conversions": "69",
            "Conversion Value": "$11,478",
            "Cost per Result": "$16.46"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "270718",
            "Clicks": "5811",
            "Conversions": "49",
            "Conversion Value": "$10,728",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "290076",
            "Clicks": "2985",
            "Conversions": "50",
            "Conversion Value": "$4,308",
            "Cost per Result": "$19.44"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "264119",
            "Clicks": "5098",
            "Conversions": "67",
            "Conversion Value": "$8,942",
            "Cost per Result": "$14.71"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "408702",
            "Clicks": "2309",
            "Conversions": "74",
            "Conversion Value": "$15,530",
            "Cost per Result": "$32.77"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "210015",
            "Clicks": "6445",
            "Conversions": "44",
            "Conversion Value": "$14,881",
            "Cost per Result": "$21.26"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "497972",
            "Clicks": "5990",
            "Conversions": "75",
            "Conversion Value": "$9,927",
            "Cost per Result": "$7.97"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "289933",
            "Clicks": "5325",
            "Conversions": "79",
            "Conversion Value": "$9,561",
            "Cost per Result": "$18.42"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "168381",
            "Clicks": "2191",
            "Conversions": "40",
            "Conversion Value": "$15,888",
            "Cost per Result": "$27.68"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "380982",
            "Clicks": "2971",
            "Conversions": "61",
            "Conversion Value": "$13,135",
            "Cost per Result": "$8.03"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "385860",
            "Clicks": "3434",
            "Conversions": "66",
            "Conversion Value": "$14,728",
            "Cost per Result": "$23.37"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "435860",
            "Clicks": "4060",
            "Conversions": "41",
            "Conversion Value": "$5,592",
            "Cost per Result": "$24.60"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "328289",
            "Clicks": "3558",
            "Conversions": "48",
            "Conversion Value": "$4,958",
            "Cost per Result": "$6.05"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "297951",
            "Clicks": "6467",
            "Conversions": "85",
            "Conversion Value": "$7,952",
            "Cost per Result": "$29.82"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "404090",
            "Clicks": "5909",
            "Conversions": "54",
            "Conversion Value": "$7,986",
            "Cost per Result": "$29.56"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "207291",
            "Clicks": "4220",
            "Conversions": "42",
            "Conversion Value": "$11,527",
            "Cost per Result": "$26.63"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "359085",
            "Clicks": "4050",
            "Conversions": "41",
            "Conversion Value": "$11,457",
            "Cost per Result": "$5.47"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "152473",
            "Clicks": "6769",
            "Conversions": "44",
            "Conversion Value": "$14,414",
            "Cost per Result": "$15.96"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "268685",
            "Clicks": "4331",
            "Conversions": "77",
            "Conversion Value": "$5,836",
            "Cost per Result": "$6.78"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "192990",
            "Clicks": "2447",
            "Conversions": "78",
            "Conversion Value": "$6,870",
            "Cost per Result": "$16.13"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "470897",
            "Clicks": "4513",
            "Conversions": "54",
            "Conversion Value": "$7,005",
            "Cost per Result": "$9.49"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "258863",
            "Clicks": "5911",
            "Conversions": "81",
            "Conversion Value": "$15,374",
            "Cost per Result": "$28.97"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "112425",
            "Clicks": "3455",
            "Conversions": "64",
            "Conversion Value": "$7,705",
            "Cost per Result": "$10.10"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "253288",
            "Clicks": "3366",
            "Conversions": "45",
            "Conversion Value": "$12,186",
            "Cost per Result": "$8.57"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "357232",
            "Clicks": "3996",
            "Conversions": "57",
            "Conversion Value": "$8,641",
            "Cost per Result": "$20.36"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "144745",
            "Clicks": "3269",
            "Conversions": "85",
            "Conversion Value": "$5,588",
            "Cost per Result": "$9.27"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "377388",
            "Clicks": "3215",
            "Conversions": "53",
            "Conversion Value": "$11,730",
            "Cost per Result": "$32.05"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "123694",
            "Clicks": "4708",
            "Conversions": "64",
            "Conversion Value": "$6,789",
            "Cost per Result": "$7.65"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "174620",
            "Clicks": "3792",
            "Conversions": "69",
            "Conversion Value": "$7,422",
            "Cost per Result": "$7.16"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "283356",
            "Clicks": "6757",
            "Conversions": "60",
            "Conversion Value": "$5,220",
            "Cost per Result": "$11.79"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "362982",
            "Clicks": "5142",
            "Conversions": "67",
            "Conversion Value": "$15,903",
            "Cost per Result": "$14.48"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "112863",
            "Clicks": "6444",
            "Conversions": "74",
            "Conversion Value": "$9,428",
            "Cost per Result": "$25.06"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "205818",
            "Clicks": "3135",
            "Conversions": "70",
            "Conversion Value": "$15,082",
            "Cost per Result": "$10.55"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "294407",
            "Clicks": "4650",
            "Conversions": "68",
            "Conversion Value": "$4,445",
            "Cost per Result": "$6.99"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "301717",
            "Clicks": "4879",
            "Conversions": "41",
            "Conversion Value": "$6,310",
            "Cost per Result": "$33.73"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "385227",
            "Clicks": "5830",
            "Conversions": "59",
            "Conversion Value": "$7,062",
            "Cost per Result": "$32.96"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "243820",
            "Clicks": "2118",
            "Conversions": "79",
            "Conversion Value": "$11,310",
            "Cost per Result": "$26.04"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "332908",
            "Clicks": "6265",
            "Conversions": "59",
            "Conversion Value": "$10,133",
            "Cost per Result": "$24.45"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "155619",
            "Clicks": "5112",
            "Conversions": "52",
            "Conversion Value": "$7,450",
            "Cost per Result": "$22.04"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "392405",
            "Clicks": "4914",
            "Conversions": "43",
            "Conversion Value": "$6,518",
            "Cost per Result": "$23.11"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "285930",
            "Clicks": "2296",
            "Conversions": "70",
            "Conversion Value": "$5,718",
            "Cost per Result": "$12.61"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "140432",
            "Clicks": "6508",
            "Conversions": "57",
            "Conversion Value": "$6,632",
            "Cost per Result": "$13.83"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "409444",
            "Clicks": "6075",
            "Conversions": "65",
            "Conversion Value": "$6,005",
            "Cost per Result": "$27.36"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "153857",
            "Clicks": "2367",
            "Conversions": "80",
            "Conversion Value": "$7,288",
            "Cost per Result": "$11.73"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "241536",
            "Clicks": "2454",
            "Conversions": "61",
            "Conversion Value": "$12,926",
            "Cost per Result": "$23.38"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "450872",
            "Clicks": "4505",
            "Conversions": "55",
            "Conversion Value": "$11,682",
            "Cost per Result": "$17.77"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "382511",
            "Clicks": "3169",
            "Conversions": "85",
            "Conversion Value": "$14,774",
            "Cost per Result": "$11.25"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "286052",
            "Clicks": "2729",
            "Conversions": "76",
            "Conversion Value": "$11,201",
            "Cost per Result": "$30.43"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "434233",
            "Clicks": "6012",
            "Conversions": "86",
            "Conversion Value": "$12,450",
            "Cost per Result": "$19.26"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "122815",
            "Clicks": "4921",
            "Conversions": "77",
            "Conversion Value": "$5,287",
            "Cost per Result": "$22.58"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "389223",
            "Clicks": "3109",
            "Conversions": "86",
            "Conversion Value": "$13,646",
            "Cost per Result": "$13.41"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "340751",
            "Clicks": "3039",
            "Conversions": "75",
            "Conversion Value": "$11,901",
            "Cost per Result": "$12.79"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "318370",
            "Clicks": "2573",
            "Conversions": "69",
            "Conversion Value": "$15,207",
            "Cost per Result": "$25.86"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "313257",
            "Clicks": "2290",
            "Conversions": "75",
            "Conversion Value": "$14,138",
            "Cost per Result": "$6.44"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "248984",
            "Clicks": "6461",
            "Conversions": "44",
            "Conversion Value": "$5,565",
            "Cost per Result": "$28.55"
          }
        ]
      }
    },
    "tokenLaunches": {
      "TotalData": {
        "name": "TOTAL",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "1280629",
            "Clicks": "12606",
            "Conversions": "258",
            "Conversion Value": "$37,066",
            "Cost per Result": "$45.67"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "1360811",
            "Clicks": "19101",
            "Conversions": "234",
            "Conversion Value": "$45,025",
            "Cost per Result": "$36.97"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "1170310",
            "Clicks": "17075",
            "Conversions": "279",
            "Conversion Value": "$44,814",
            "Cost per Result": "$37.43"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "994016",
            "Clicks": "16474",
            "Conversions": "255",
            "Conversion Value": "$43,651",
            "Cost per Result": "$36.23"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "1067597",
            "Clicks": "14303",
            "Conversions": "270",
            "Conversion Value": "$40,800",
            "Cost per Result": "$48.09"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "1352508",
            "Clicks": "21067",
            "Conversions": "264",
            "Conversion Value": "$37,921",
            "Cost per Result": "$41.85"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "743338",
            "Clicks": "15440",
            "Conversions": "297",
            "Conversion Value": "$40,001",
            "Cost per Result": "$37.05"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "1206502",
            "Clicks": "15931",
            "Conversions": "289",
            "Conversion Value": "$35,817",
            "Cost per Result": "$37.83"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "1141559",
            "Clicks": "16673",
            "Conversions": "275",
            "Conversion Value": "$51,021",
            "Cost per Result": "$43.09"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "879820",
            "Clicks": "18423",
            "Conversions": "285",
            "Conversion Value": "$38,708",
            "Cost per Result": "$31.47"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "1477029",
            "Clicks": "18863",
            "Conversions": "296",
            "Conversion Value": "$44,476",
            "Cost per Result": "$42.48"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "1032043",
            "Clicks": "20484",
            "Conversions": "314",
            "Conversion Value": "$33,861",
            "Cost per Result": "$36.29"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "945278",
            "Clicks": "25695",
            "Conversions": "263",
            "Conversion Value": "$30,694",
            "Cost per Result": "$29.47"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "782015",
            "Clicks": "17178",
            "Conversions": "271",
            "Conversion Value": "$34,490",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "1432356",
            "Clicks": "18593",
            "Conversions": "278",
            "Conversion Value": "$46,975",
            "Cost per Result": "$46.79"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "871539",
            "Clicks": "16661",
            "Conversions": "284",
            "Conversion Value": "$30,770",
            "Cost per Result": "$29.67"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "1136477",
            "Clicks": "13317",
            "Conversions": "225",
            "Conversion Value": "$43,034",
            "Cost per Result": "$47.92"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "984122",
            "Clicks": "20783",
            "Conversions": "286",
            "Conversion Value": "$33,470",
            "Cost per Result": "$36.40"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "1316443",
            "Clicks": "20254",
            "Conversions": "279",
            "Conversion Value": "$39,078",
            "Cost per Result": "$34.83"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "1071905",
            "Clicks": "19535",
            "Conversions": "259",
            "Conversion Value": "$56,890",
            "Cost per Result": "$48.38"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "1182593",
            "Clicks": "14636",
            "Conversions": "243",
            "Conversion Value": "$39,004",
            "Cost per Result": "$32.59"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "1237379",
            "Clicks": "14489",
            "Conversions": "336",
            "Conversion Value": "$50,505",
            "Cost per Result": "$32.73"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "1290319",
            "Clicks": "19020",
            "Conversions": "247",
            "Conversion Value": "$47,233",
            "Cost per Result": "$36.77"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "1323016",
            "Clicks": "18034",
            "Conversions": "214",
            "Conversion Value": "$43,570",
            "Cost per Result": "$36.20"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "1254282",
            "Clicks": "22850",
            "Conversions": "307",
            "Conversion Value": "$38,016",
            "Cost per Result": "$41.11"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "1164188",
            "Clicks": "19796",
            "Conversions": "287",
            "Conversion Value": "$52,100",
            "Cost per Result": "$40.16"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "1068702",
            "Clicks": "16420",
            "Conversions": "263",
            "Conversion Value": "$27,643",
            "Cost per Result": "$45.98"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "866646",
            "Clicks": "13813",
            "Conversions": "233",
            "Conversion Value": "$47,208",
            "Cost per Result": "$53.36"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "1415512",
            "Clicks": "15981",
            "Conversions": "292",
            "Conversion Value": "$42,951",
            "Cost per Result": "$44.30"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "1412118",
            "Clicks": "16338",
            "Conversions": "297",
            "Conversion Value": "$51,382",
            "Cost per Result": "$47.98"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "1213600",
            "Clicks": "15087",
            "Conversions": "279",
            "Conversion Value": "$50,219",
            "Cost per Result": "$38.67"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "1032201",
            "Clicks": "17298",
            "Conversions": "231",
            "Conversion Value": "$36,433",
            "Cost per Result": "$34.64"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "1578532",
            "Clicks": "19876",
            "Conversions": "225",
            "Conversion Value": "$38,585",
            "Cost per Result": "$41.97"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "1504278",
            "Clicks": "13107",
            "Conversions": "282",
            "Conversion Value": "$36,878",
            "Cost per Result": "$41.68"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "1518838",
            "Clicks": "22445",
            "Conversions": "298",
            "Conversion Value": "$43,320",
            "Cost per Result": "$44.26"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "1521653",
            "Clicks": "15531",
            "Conversions": "225",
            "Conversion Value": "$30,177",
            "Cost per Result": "$33.51"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "1612182",
            "Clicks": "15637",
            "Conversions": "282",
            "Conversion Value": "$33,796",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "1206278",
            "Clicks": "18452",
            "Conversions": "297",
            "Conversion Value": "$35,621",
            "Cost per Result": "$36.45"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "1027962",
            "Clicks": "17037",
            "Conversions": "275",
            "Conversion Value": "$44,251",
            "Cost per Result": "$35.07"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "824073",
            "Clicks": "15652",
            "Conversions": "245",
            "Conversion Value": "$38,821",
            "Cost per Result": "$35.52"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "1162413",
            "Clicks": "16592",
            "Conversions": "301",
            "Conversion Value": "$38,815",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "1312711",
            "Clicks": "14041",
            "Conversions": "256",
            "Conversion Value": "$47,341",
            "Cost per Result": "$40.87"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "1123269",
            "Clicks": "17135",
            "Conversions": "249",
            "Conversion Value": "$28,935",
            "Cost per Result": "$30.75"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "1147884",
            "Clicks": "20085",
            "Conversions": "204",
            "Conversion Value": "$32,646",
            "Cost per Result": "$38.28"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "1164169",
            "Clicks": "19678",
            "Conversions": "203",
            "Conversion Value": "$47,133",
            "Cost per Result": "$40.02"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "1088971",
            "Clicks": "21845",
            "Conversions": "248",
            "Conversion Value": "$38,278",
            "Cost per Result": "$34.48"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "1068977",
            "Clicks": "20187",
            "Conversions": "257",
            "Conversion Value": "$34,436",
            "Cost per Result": "$48.30"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "1316735",
            "Clicks": "19927",
            "Conversions": "254",
            "Conversion Value": "$41,238",
            "Cost per Result": "$37.94"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "1294064",
            "Clicks": "19352",
            "Conversions": "247",
            "Conversion Value": "$37,555",
            "Cost per Result": "$43.67"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "787002",
            "Clicks": "15080",
            "Conversions": "292",
            "Conversion Value": "$30,311",
            "Cost per Result": "$47.87"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "1186965",
            "Clicks": "23866",
            "Conversions": "230",
            "Conversion Value": "$31,492",
            "Cost per Result": "$45.90"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "1182814",
            "Clicks": "16623",
            "Conversions": "314",
            "Conversion Value": "$38,555",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "933667",
            "Clicks": "18176",
            "Conversions": "271",
            "Conversion Value": "$49,215",
            "Cost per Result": "$38.69"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "1486405",
            "Clicks": "19312",
            "Conversions": "225",
            "Conversion Value": "$43,925",
            "Cost per Result": "$42.86"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "1288721",
            "Clicks": "19718",
            "Conversions": "261",
            "Conversion Value": "$35,455",
            "Cost per Result": "$51.53"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "1469143",
            "Clicks": "13549",
            "Conversions": "305",
            "Conversion Value": "$50,767",
            "Cost per Result": "$43.07"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "1183466",
            "Clicks": "17681",
            "Conversions": "253",
            "Conversion Value": "$31,261",
            "Cost per Result": "$41.98"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "995105",
            "Clicks": "17925",
            "Conversions": "293",
            "Conversion Value": "$43,659",
            "Cost per Result": "$43.52"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "1482494",
            "Clicks": "18756",
            "Conversions": "237",
            "Conversion Value": "$38,501",
            "Cost per Result": "$42.04"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "1160246",
            "Clicks": "19064",
            "Conversions": "242",
            "Conversion Value": "$46,019",
            "Cost per Result": "$32.00"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "1287898",
            "Clicks": "25430",
            "Conversions": "279",
            "Conversion Value": "$49,263",
            "Cost per Result": "$42.75"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "1276658",
            "Clicks": "17754",
            "Conversions": "236",
            "Conversion Value": "$36,554",
            "Cost per Result": "$35.48"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "1419992",
            "Clicks": "14883",
            "Conversions": "259",
            "Conversion Value": "$44,207",
            "Cost per Result": "$46.66"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "1018379",
            "Clicks": "25353",
            "Conversions": "265",
            "Conversion Value": "$34,771",
            "Cost per Result": "$38.76"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "1302182",
            "Clicks": "19871",
            "Conversions": "294",
            "Conversion Value": "$38,255",
            "Cost per Result": "$42.51"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "1454018",
            "Clicks": "14908",
            "Conversions": "289",
            "Conversion Value": "$27,203",
            "Cost per Result": "$44.86"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "1642205",
            "Clicks": "21654",
            "Conversions": "275",
            "Conversion Value": "$39,141",
            "Cost per Result": "$39.31"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "1310351",
            "Clicks": "18548",
            "Conversions": "258",
            "Conversion Value": "$42,261",
            "Cost per Result": "$43.12"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "1151970",
            "Clicks": "13201",
            "Conversions": "212",
            "Conversion Value": "$39,680",
            "Cost per Result": "$44.85"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "1272438",
            "Clicks": "18626",
            "Conversions": "335",
            "Conversion Value": "$41,863",
            "Cost per Result": "$39.84"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "1329300",
            "Clicks": "15588",
            "Conversions": "209",
            "Conversion Value": "$29,206",
            "Cost per Result": "$41.29"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "1587734",
            "Clicks": "19691",
            "Conversions": "236",
            "Conversion Value": "$43,224",
            "Cost per Result": "$47.29"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "1207154",
            "Clicks": "19285",
            "Conversions": "246",
            "Conversion Value": "$38,384",
            "Cost per Result": "$38.71"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "987005",
            "Clicks": "16542",
            "Conversions": "232",
            "Conversion Value": "$50,233",
            "Cost per Result": "$49.99"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "1654842",
            "Clicks": "15256",
            "Conversions": "283",
            "Conversion Value": "$53,153",
            "Cost per Result": "$36.96"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "1171111",
            "Clicks": "23378",
            "Conversions": "210",
            "Conversion Value": "$36,920",
            "Cost per Result": "$36.65"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "1511466",
            "Clicks": "18638",
            "Conversions": "252",
            "Conversion Value": "$38,126",
            "Cost per Result": "$30.66"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "1373072",
            "Clicks": "18756",
            "Conversions": "266",
            "Conversion Value": "$41,330",
            "Cost per Result": "$45.00"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "1489874",
            "Clicks": "19164",
            "Conversions": "281",
            "Conversion Value": "$45,703",
            "Cost per Result": "$44.53"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "1646826",
            "Clicks": "16436",
            "Conversions": "258",
            "Conversion Value": "$32,049",
            "Cost per Result": "$41.07"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "679860",
            "Clicks": "15535",
            "Conversions": "230",
            "Conversion Value": "$47,991",
            "Cost per Result": "$43.94"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "1140693",
            "Clicks": "14922",
            "Conversions": "244",
            "Conversion Value": "$45,019",
            "Cost per Result": "$49.18"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "1597757",
            "Clicks": "17798",
            "Conversions": "246",
            "Conversion Value": "$50,409",
            "Cost per Result": "$39.15"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "1192438",
            "Clicks": "22411",
            "Conversions": "325",
            "Conversion Value": "$42,072",
            "Cost per Result": "$43.25"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "1195949",
            "Clicks": "18483",
            "Conversions": "243",
            "Conversion Value": "$25,370",
            "Cost per Result": "$29.83"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "1267004",
            "Clicks": "18804",
            "Conversions": "235",
            "Conversion Value": "$45,567",
            "Cost per Result": "$46.98"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "1505925",
            "Clicks": "15295",
            "Conversions": "274",
            "Conversion Value": "$45,442",
            "Cost per Result": "$38.76"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "1305036",
            "Clicks": "14313",
            "Conversions": "252",
            "Conversion Value": "$37,977",
            "Cost per Result": "$34.96"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "1012036",
            "Clicks": "19645",
            "Conversions": "260",
            "Conversion Value": "$46,137",
            "Cost per Result": "$39.29"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "1443253",
            "Clicks": "12821",
            "Conversions": "276",
            "Conversion Value": "$39,828",
            "Cost per Result": "$43.30"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "1136053",
            "Clicks": "14665",
            "Conversions": "217",
            "Conversion Value": "$44,105",
            "Cost per Result": "$42.31"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "1114218",
            "Clicks": "17981",
            "Conversions": "286",
            "Conversion Value": "$43,502",
            "Cost per Result": "$38.01"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "1174720",
            "Clicks": "21225",
            "Conversions": "311",
            "Conversion Value": "$40,877",
            "Cost per Result": "$46.66"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "1366014",
            "Clicks": "16641",
            "Conversions": "252",
            "Conversion Value": "$50,331",
            "Cost per Result": "$37.02"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "1419458",
            "Clicks": "21948",
            "Conversions": "251",
            "Conversion Value": "$30,017",
            "Cost per Result": "$47.28"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "1319484",
            "Clicks": "21123",
            "Conversions": "267",
            "Conversion Value": "$35,304",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "1269223",
            "Clicks": "22366",
            "Conversions": "291",
            "Conversion Value": "$44,031",
            "Cost per Result": "$43.34"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "1384974",
            "Clicks": "13154",
            "Conversions": "282",
            "Conversion Value": "$44,837",
            "Cost per Result": "$31.65"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "1319845",
            "Clicks": "19358",
            "Conversions": "267",
            "Conversion Value": "$28,496",
            "Cost per Result": "$34.75"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "1299539",
            "Clicks": "19325",
            "Conversions": "247",
            "Conversion Value": "$31,573",
            "Cost per Result": "$43.67"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "795992",
            "Clicks": "18866",
            "Conversions": "269",
            "Conversion Value": "$38,045",
            "Cost per Result": "$53.82"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "1052422",
            "Clicks": "12894",
            "Conversions": "272",
            "Conversion Value": "$30,681",
            "Cost per Result": "$42.86"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "1330821",
            "Clicks": "20190",
            "Conversions": "260",
            "Conversion Value": "$44,744",
            "Cost per Result": "$38.16"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "698806",
            "Clicks": "19372",
            "Conversions": "293",
            "Conversion Value": "$41,019",
            "Cost per Result": "$34.39"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "1563421",
            "Clicks": "24769",
            "Conversions": "287",
            "Conversion Value": "$37,574",
            "Cost per Result": "$43.35"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "1202435",
            "Clicks": "18666",
            "Conversions": "290",
            "Conversion Value": "$40,364",
            "Cost per Result": "$34.96"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "1210938",
            "Clicks": "19157",
            "Conversions": "328",
            "Conversion Value": "$32,140",
            "Cost per Result": "$47.27"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "1604341",
            "Clicks": "12521",
            "Conversions": "280",
            "Conversion Value": "$40,214",
            "Cost per Result": "$35.00"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "1000802",
            "Clicks": "14874",
            "Conversions": "256",
            "Conversion Value": "$49,068",
            "Cost per Result": "$36.51"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "920554",
            "Clicks": "20928",
            "Conversions": "248",
            "Conversion Value": "$44,687",
            "Cost per Result": "$36.61"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "1664301",
            "Clicks": "15821",
            "Conversions": "284",
            "Conversion Value": "$49,971",
            "Cost per Result": "$42.50"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "1283994",
            "Clicks": "20811",
            "Conversions": "280",
            "Conversion Value": "$38,718",
            "Cost per Result": "$48.82"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "1366050",
            "Clicks": "15255",
            "Conversions": "264",
            "Conversion Value": "$50,997",
            "Cost per Result": "$31.62"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "1147543",
            "Clicks": "21542",
            "Conversions": "239",
            "Conversion Value": "$36,909",
            "Cost per Result": "$40.80"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "1070132",
            "Clicks": "15926",
            "Conversions": "245",
            "Conversion Value": "$41,549",
            "Cost per Result": "$38.81"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "1164728",
            "Clicks": "17998",
            "Conversions": "275",
            "Conversion Value": "$34,382",
            "Cost per Result": "$38.60"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "742859",
            "Clicks": "15046",
            "Conversions": "287",
            "Conversion Value": "$45,299",
            "Cost per Result": "$45.41"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "893666",
            "Clicks": "18531",
            "Conversions": "241",
            "Conversion Value": "$38,136",
            "Cost per Result": "$44.51"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "1209964",
            "Clicks": "20680",
            "Conversions": "275",
            "Conversion Value": "$30,833",
            "Cost per Result": "$36.03"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "1287506",
            "Clicks": "13387",
            "Conversions": "262",
            "Conversion Value": "$35,761",
            "Cost per Result": "$51.19"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "892511",
            "Clicks": "22145",
            "Conversions": "249",
            "Conversion Value": "$35,471",
            "Cost per Result": "$36.37"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "922684",
            "Clicks": "22736",
            "Conversions": "200",
            "Conversion Value": "$44,407",
            "Cost per Result": "$37.28"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "1436437",
            "Clicks": "17404",
            "Conversions": "327",
            "Conversion Value": "$42,222",
            "Cost per Result": "$47.56"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "883242",
            "Clicks": "21588",
            "Conversions": "247",
            "Conversion Value": "$26,502",
            "Cost per Result": "$34.92"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "845611",
            "Clicks": "17835",
            "Conversions": "241",
            "Conversion Value": "$31,130",
            "Cost per Result": "$37.37"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "1297634",
            "Clicks": "18593",
            "Conversions": "266",
            "Conversion Value": "$45,680",
            "Cost per Result": "$38.83"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "1273045",
            "Clicks": "16518",
            "Conversions": "242",
            "Conversion Value": "$34,301",
            "Cost per Result": "$36.95"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "1771976",
            "Clicks": "19161",
            "Conversions": "296",
            "Conversion Value": "$28,090",
            "Cost per Result": "$34.18"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "789567",
            "Clicks": "20945",
            "Conversions": "254",
            "Conversion Value": "$46,703",
            "Cost per Result": "$41.05"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "963237",
            "Clicks": "15782",
            "Conversions": "325",
            "Conversion Value": "$40,117",
            "Cost per Result": "$30.32"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "1029413",
            "Clicks": "19384",
            "Conversions": "284",
            "Conversion Value": "$39,981",
            "Cost per Result": "$41.60"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "1195266",
            "Clicks": "20158",
            "Conversions": "242",
            "Conversion Value": "$34,293",
            "Cost per Result": "$31.46"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "1127765",
            "Clicks": "20861",
            "Conversions": "266",
            "Conversion Value": "$45,258",
            "Cost per Result": "$28.97"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "1572606",
            "Clicks": "15989",
            "Conversions": "259",
            "Conversion Value": "$43,854",
            "Cost per Result": "$38.73"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "1462073",
            "Clicks": "24354",
            "Conversions": "260",
            "Conversion Value": "$38,515",
            "Cost per Result": "$35.67"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "1649673",
            "Clicks": "16669",
            "Conversions": "279",
            "Conversion Value": "$31,164",
            "Cost per Result": "$30.08"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "956814",
            "Clicks": "20480",
            "Conversions": "245",
            "Conversion Value": "$45,377",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "1248707",
            "Clicks": "19858",
            "Conversions": "242",
            "Conversion Value": "$36,629",
            "Cost per Result": "$43.98"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "1205929",
            "Clicks": "19204",
            "Conversions": "257",
            "Conversion Value": "$33,536",
            "Cost per Result": "$41.09"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "896886",
            "Clicks": "17850",
            "Conversions": "280",
            "Conversion Value": "$56,414",
            "Cost per Result": "$47.50"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "1028513",
            "Clicks": "20196",
            "Conversions": "235",
            "Conversion Value": "$43,298",
            "Cost per Result": "$33.51"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "903915",
            "Clicks": "17989",
            "Conversions": "305",
            "Conversion Value": "$43,410",
            "Cost per Result": "$33.82"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "1047045",
            "Clicks": "12168",
            "Conversions": "214",
            "Conversion Value": "$39,338",
            "Cost per Result": "$34.63"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "1584049",
            "Clicks": "16263",
            "Conversions": "204",
            "Conversion Value": "$35,440",
            "Cost per Result": "$35.71"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "933475",
            "Clicks": "22006",
            "Conversions": "259",
            "Conversion Value": "$32,431",
            "Cost per Result": "$47.53"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "1323710",
            "Clicks": "21832",
            "Conversions": "279",
            "Conversion Value": "$27,381",
            "Cost per Result": "$49.00"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "1502543",
            "Clicks": "20887",
            "Conversions": "234",
            "Conversion Value": "$41,248",
            "Cost per Result": "$46.03"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "815419",
            "Clicks": "13899",
            "Conversions": "291",
            "Conversion Value": "$50,373",
            "Cost per Result": "$37.67"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "1163719",
            "Clicks": "14082",
            "Conversions": "312",
            "Conversion Value": "$31,712",
            "Cost per Result": "$39.91"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "1053473",
            "Clicks": "15419",
            "Conversions": "257",
            "Conversion Value": "$48,391",
            "Cost per Result": "$40.50"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "1018852",
            "Clicks": "18860",
            "Conversions": "200",
            "Conversion Value": "$43,046",
            "Cost per Result": "$38.42"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "1174567",
            "Clicks": "21947",
            "Conversions": "228",
            "Conversion Value": "$44,820",
            "Cost per Result": "$42.62"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "681784",
            "Clicks": "20435",
            "Conversions": "258",
            "Conversion Value": "$31,102",
            "Cost per Result": "$46.76"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "810083",
            "Clicks": "17112",
            "Conversions": "267",
            "Conversion Value": "$43,168",
            "Cost per Result": "$35.69"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "794786",
            "Clicks": "17364",
            "Conversions": "237",
            "Conversion Value": "$44,984",
            "Cost per Result": "$33.50"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "903865",
            "Clicks": "23105",
            "Conversions": "294",
            "Conversion Value": "$29,155",
            "Cost per Result": "$36.14"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "1244307",
            "Clicks": "17717",
            "Conversions": "246",
            "Conversion Value": "$42,938",
            "Cost per Result": "$37.43"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "872889",
            "Clicks": "21870",
            "Conversions": "239",
            "Conversion Value": "$49,408",
            "Cost per Result": "$38.11"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "781230",
            "Clicks": "17319",
            "Conversions": "292",
            "Conversion Value": "$26,095",
            "Cost per Result": "$30.99"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "1006088",
            "Clicks": "18208",
            "Conversions": "243",
            "Conversion Value": "$32,549",
            "Cost per Result": "$43.81"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "998209",
            "Clicks": "21846",
            "Conversions": "280",
            "Conversion Value": "$27,635",
            "Cost per Result": "$38.16"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "1291354",
            "Clicks": "14028",
            "Conversions": "305",
            "Conversion Value": "$37,017",
            "Cost per Result": "$34.75"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "1004606",
            "Clicks": "15149",
            "Conversions": "264",
            "Conversion Value": "$38,046",
            "Cost per Result": "$33.19"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "1340535",
            "Clicks": "19912",
            "Conversions": "278",
            "Conversion Value": "$32,977",
            "Cost per Result": "$49.00"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "1388113",
            "Clicks": "20750",
            "Conversions": "232",
            "Conversion Value": "$33,688",
            "Cost per Result": "$36.27"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "779601",
            "Clicks": "18190",
            "Conversions": "205",
            "Conversion Value": "$38,522",
            "Cost per Result": "$28.41"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "1406490",
            "Clicks": "19553",
            "Conversions": "235",
            "Conversion Value": "$42,943",
            "Cost per Result": "$46.91"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "1144056",
            "Clicks": "25381",
            "Conversions": "207",
            "Conversion Value": "$32,947",
            "Cost per Result": "$34.28"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "783728",
            "Clicks": "16387",
            "Conversions": "259",
            "Conversion Value": "$54,661",
            "Cost per Result": "$36.16"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "1250810",
            "Clicks": "16515",
            "Conversions": "293",
            "Conversion Value": "$46,953",
            "Cost per Result": "$35.42"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "1087178",
            "Clicks": "21859",
            "Conversions": "180",
            "Conversion Value": "$40,088",
            "Cost per Result": "$45.15"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "1415106",
            "Clicks": "17181",
            "Conversions": "224",
            "Conversion Value": "$44,608",
            "Cost per Result": "$34.79"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "1056436",
            "Clicks": "14140",
            "Conversions": "288",
            "Conversion Value": "$40,355",
            "Cost per Result": "$38.13"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "1077226",
            "Clicks": "19563",
            "Conversions": "302",
            "Conversion Value": "$45,194",
            "Cost per Result": "$40.39"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "986235",
            "Clicks": "21712",
            "Conversions": "268",
            "Conversion Value": "$26,788",
            "Cost per Result": "$31.45"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "1256735",
            "Clicks": "22043",
            "Conversions": "249",
            "Conversion Value": "$35,446",
            "Cost per Result": "$51.38"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "1204477",
            "Clicks": "15730",
            "Conversions": "290",
            "Conversion Value": "$49,098",
            "Cost per Result": "$43.72"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "944458",
            "Clicks": "19870",
            "Conversions": "276",
            "Conversion Value": "$30,006",
            "Cost per Result": "$37.80"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "1028375",
            "Clicks": "16974",
            "Conversions": "299",
            "Conversion Value": "$49,949",
            "Cost per Result": "$42.02"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "1182354",
            "Clicks": "15596",
            "Conversions": "228",
            "Conversion Value": "$48,731",
            "Cost per Result": "$34.18"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "1046558",
            "Clicks": "22998",
            "Conversions": "314",
            "Conversion Value": "$37,451",
            "Cost per Result": "$31.58"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "1201404",
            "Clicks": "17393",
            "Conversions": "263",
            "Conversion Value": "$36,750",
            "Cost per Result": "$40.45"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "808396",
            "Clicks": "16923",
            "Conversions": "228",
            "Conversion Value": "$28,246",
            "Cost per Result": "$38.55"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "1545747",
            "Clicks": "12963",
            "Conversions": "262",
            "Conversion Value": "$43,358",
            "Cost per Result": "$39.16"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "1573947",
            "Clicks": "19215",
            "Conversions": "259",
            "Conversion Value": "$40,616",
            "Cost per Result": "$38.14"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "1690079",
            "Clicks": "19364",
            "Conversions": "227",
            "Conversion Value": "$44,961",
            "Cost per Result": "$38.95"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "1184135",
            "Clicks": "21240",
            "Conversions": "257",
            "Conversion Value": "$39,368",
            "Cost per Result": "$44.58"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "864811",
            "Clicks": "19774",
            "Conversions": "231",
            "Conversion Value": "$39,736",
            "Cost per Result": "$38.66"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "1571455",
            "Clicks": "17048",
            "Conversions": "248",
            "Conversion Value": "$49,017",
            "Cost per Result": "$49.84"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "1372381",
            "Clicks": "18297",
            "Conversions": "230",
            "Conversion Value": "$30,755",
            "Cost per Result": "$45.21"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "1091748",
            "Clicks": "13836",
            "Conversions": "280",
            "Conversion Value": "$40,822",
            "Cost per Result": "$36.09"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "866155",
            "Clicks": "16126",
            "Conversions": "240",
            "Conversion Value": "$35,819",
            "Cost per Result": "$40.91"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "988029",
            "Clicks": "17813",
            "Conversions": "238",
            "Conversion Value": "$38,501",
            "Cost per Result": "$44.47"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "1253636",
            "Clicks": "16428",
            "Conversions": "223",
            "Conversion Value": "$45,238",
            "Cost per Result": "$39.49"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "1492965",
            "Clicks": "20063",
            "Conversions": "245",
            "Conversion Value": "$54,492",
            "Cost per Result": "$46.24"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "1385596",
            "Clicks": "17077",
            "Conversions": "290",
            "Conversion Value": "$46,415",
            "Cost per Result": "$42.66"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "1457977",
            "Clicks": "15402",
            "Conversions": "231",
            "Conversion Value": "$30,952",
            "Cost per Result": "$32.37"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "935587",
            "Clicks": "17469",
            "Conversions": "261",
            "Conversion Value": "$46,784",
            "Cost per Result": "$38.43"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "1009207",
            "Clicks": "18756",
            "Conversions": "254",
            "Conversion Value": "$38,193",
            "Cost per Result": "$34.31"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "1217366",
            "Clicks": "22326",
            "Conversions": "302",
            "Conversion Value": "$39,333",
            "Cost per Result": "$37.35"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "1344145",
            "Clicks": "20077",
            "Conversions": "256",
            "Conversion Value": "$52,911",
            "Cost per Result": "$44.68"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "614329",
            "Clicks": "22239",
            "Conversions": "278",
            "Conversion Value": "$43,397",
            "Cost per Result": "$36.82"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "1492848",
            "Clicks": "16335",
            "Conversions": "235",
            "Conversion Value": "$36,126",
            "Cost per Result": "$33.56"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "1412671",
            "Clicks": "20135",
            "Conversions": "278",
            "Conversion Value": "$37,970",
            "Cost per Result": "$35.54"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "1094733",
            "Clicks": "17206",
            "Conversions": "245",
            "Conversion Value": "$38,205",
            "Cost per Result": "$36.22"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "1271445",
            "Clicks": "18647",
            "Conversions": "249",
            "Conversion Value": "$40,980",
            "Cost per Result": "$31.11"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "931151",
            "Clicks": "19240",
            "Conversions": "191",
            "Conversion Value": "$32,175",
            "Cost per Result": "$40.33"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "1386051",
            "Clicks": "15869",
            "Conversions": "272",
            "Conversion Value": "$35,370",
            "Cost per Result": "$43.30"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "975688",
            "Clicks": "19183",
            "Conversions": "277",
            "Conversion Value": "$50,467",
            "Cost per Result": "$41.13"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "989478",
            "Clicks": "18406",
            "Conversions": "214",
            "Conversion Value": "$40,645",
            "Cost per Result": "$42.02"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "873780",
            "Clicks": "18067",
            "Conversions": "257",
            "Conversion Value": "$37,675",
            "Cost per Result": "$28.63"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "1095778",
            "Clicks": "19104",
            "Conversions": "245",
            "Conversion Value": "$55,811",
            "Cost per Result": "$28.00"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "1360935",
            "Clicks": "14862",
            "Conversions": "256",
            "Conversion Value": "$38,715",
            "Cost per Result": "$38.60"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "1555655",
            "Clicks": "17996",
            "Conversions": "306",
            "Conversion Value": "$38,903",
            "Cost per Result": "$41.14"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "1220015",
            "Clicks": "17094",
            "Conversions": "247",
            "Conversion Value": "$32,348",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "1322403",
            "Clicks": "19907",
            "Conversions": "228",
            "Conversion Value": "$30,443",
            "Cost per Result": "$38.23"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "1333077",
            "Clicks": "20233",
            "Conversions": "227",
            "Conversion Value": "$40,083",
            "Cost per Result": "$38.92"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "1200541",
            "Clicks": "19605",
            "Conversions": "267",
            "Conversion Value": "$38,831",
            "Cost per Result": "$52.92"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "1097226",
            "Clicks": "16184",
            "Conversions": "271",
            "Conversion Value": "$31,686",
            "Cost per Result": "$33.52"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "1477296",
            "Clicks": "14676",
            "Conversions": "323",
            "Conversion Value": "$27,514",
            "Cost per Result": "$35.94"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "1167432",
            "Clicks": "16448",
            "Conversions": "249",
            "Conversion Value": "$44,129",
            "Cost per Result": "$51.60"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "1251250",
            "Clicks": "14136",
            "Conversions": "294",
            "Conversion Value": "$34,014",
            "Cost per Result": "$48.34"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "1507382",
            "Clicks": "18609",
            "Conversions": "250",
            "Conversion Value": "$40,262",
            "Cost per Result": "$43.47"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "1230916",
            "Clicks": "19860",
            "Conversions": "287",
            "Conversion Value": "$51,113",
            "Cost per Result": "$35.34"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "1542617",
            "Clicks": "21940",
            "Conversions": "290",
            "Conversion Value": "$41,005",
            "Cost per Result": "$49.10"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "1461481",
            "Clicks": "17825",
            "Conversions": "237",
            "Conversion Value": "$32,324",
            "Cost per Result": "$37.20"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "1413693",
            "Clicks": "17940",
            "Conversions": "239",
            "Conversion Value": "$43,941",
            "Cost per Result": "$44.35"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "958279",
            "Clicks": "12842",
            "Conversions": "261",
            "Conversion Value": "$39,480",
            "Cost per Result": "$41.15"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "1041231",
            "Clicks": "18964",
            "Conversions": "222",
            "Conversion Value": "$51,144",
            "Cost per Result": "$40.41"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "1213609",
            "Clicks": "21603",
            "Conversions": "275",
            "Conversion Value": "$47,319",
            "Cost per Result": "$34.52"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "1184872",
            "Clicks": "19950",
            "Conversions": "240",
            "Conversion Value": "$43,165",
            "Cost per Result": "$35.27"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "1356987",
            "Clicks": "18540",
            "Conversions": "290",
            "Conversion Value": "$42,328",
            "Cost per Result": "$39.79"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "874452",
            "Clicks": "11853",
            "Conversions": "279",
            "Conversion Value": "$44,965",
            "Cost per Result": "$35.59"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "1270763",
            "Clicks": "18095",
            "Conversions": "269",
            "Conversion Value": "$33,262",
            "Cost per Result": "$49.69"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "1409712",
            "Clicks": "16267",
            "Conversions": "262",
            "Conversion Value": "$46,062",
            "Cost per Result": "$40.26"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "1000917",
            "Clicks": "17454",
            "Conversions": "282",
            "Conversion Value": "$32,596",
            "Cost per Result": "$43.91"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "1384701",
            "Clicks": "19063",
            "Conversions": "226",
            "Conversion Value": "$43,463",
            "Cost per Result": "$41.12"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "1289723",
            "Clicks": "14981",
            "Conversions": "279",
            "Conversion Value": "$35,562",
            "Cost per Result": "$47.74"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "1440044",
            "Clicks": "15610",
            "Conversions": "277",
            "Conversion Value": "$44,202",
            "Cost per Result": "$48.21"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "1250551",
            "Clicks": "16388",
            "Conversions": "252",
            "Conversion Value": "$43,599",
            "Cost per Result": "$42.07"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "1150851",
            "Clicks": "14261",
            "Conversions": "275",
            "Conversion Value": "$36,419",
            "Cost per Result": "$32.85"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "1088199",
            "Clicks": "20372",
            "Conversions": "229",
            "Conversion Value": "$27,554",
            "Cost per Result": "$45.97"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "1015471",
            "Clicks": "20525",
            "Conversions": "268",
            "Conversion Value": "$45,528",
            "Cost per Result": "$39.82"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "1248672",
            "Clicks": "16554",
            "Conversions": "239",
            "Conversion Value": "$45,481",
            "Cost per Result": "$34.89"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "1240741",
            "Clicks": "17891",
            "Conversions": "225",
            "Conversion Value": "$40,295",
            "Cost per Result": "$36.11"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "1492948",
            "Clicks": "21026",
            "Conversions": "231",
            "Conversion Value": "$43,419",
            "Cost per Result": "$50.48"
          }
        ]
      },
      "NFTCollectorsData": {
        "name": "NFT Collectors",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "305742",
            "Clicks": "2277",
            "Conversions": "74",
            "Conversion Value": "$12,979",
            "Cost per Result": "$15.63"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "123092",
            "Clicks": "5602",
            "Conversions": "89",
            "Conversion Value": "$9,611",
            "Cost per Result": "$15.68"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "293969",
            "Clicks": "5827",
            "Conversions": "76",
            "Conversion Value": "$13,667",
            "Cost per Result": "$57.76"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "148931",
            "Clicks": "4046",
            "Conversions": "71",
            "Conversion Value": "$7,806",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "265977",
            "Clicks": "5085",
            "Conversions": "76",
            "Conversion Value": "$12,029",
            "Cost per Result": "$56.33"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "469868",
            "Clicks": "4665",
            "Conversions": "76",
            "Conversion Value": "$4,757",
            "Cost per Result": "$42.48"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "103074",
            "Clicks": "5395",
            "Conversions": "83",
            "Conversion Value": "$5,466",
            "Cost per Result": "$41.36"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "144719",
            "Clicks": "2592",
            "Conversions": "49",
            "Conversion Value": "$4,628",
            "Cost per Result": "$48.23"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "453939",
            "Clicks": "4589",
            "Conversions": "76",
            "Conversion Value": "$8,725",
            "Cost per Result": "$25.81"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "104994",
            "Clicks": "4637",
            "Conversions": "48",
            "Conversion Value": "$4,093",
            "Cost per Result": "$16.31"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "399078",
            "Clicks": "3511",
            "Conversions": "81",
            "Conversion Value": "$12,418",
            "Cost per Result": "$35.76"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "214257",
            "Clicks": "5832",
            "Conversions": "64",
            "Conversion Value": "$11,914",
            "Cost per Result": "$17.09"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "210873",
            "Clicks": "5545",
            "Conversions": "59",
            "Conversion Value": "$4,323",
            "Cost per Result": "$15.55"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "138211",
            "Clicks": "2511",
            "Conversions": "80",
            "Conversion Value": "$8,923",
            "Cost per Result": "$44.74"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "440434",
            "Clicks": "3572",
            "Conversions": "80",
            "Conversion Value": "$7,065",
            "Cost per Result": "$40.24"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "249044",
            "Clicks": "2040",
            "Conversions": "88",
            "Conversion Value": "$4,017",
            "Cost per Result": "$34.83"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "110648",
            "Clicks": "2747",
            "Conversions": "48",
            "Conversion Value": "$8,751",
            "Cost per Result": "$55.62"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "245632",
            "Clicks": "6171",
            "Conversions": "88",
            "Conversion Value": "$13,173",
            "Cost per Result": "$22.50"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "471051",
            "Clicks": "5570",
            "Conversions": "54",
            "Conversion Value": "$12,619",
            "Cost per Result": "$20.05"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "287191",
            "Clicks": "6887",
            "Conversions": "42",
            "Conversion Value": "$15,489",
            "Cost per Result": "$40.71"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "441953",
            "Clicks": "2937",
            "Conversions": "53",
            "Conversion Value": "$6,060",
            "Cost per Result": "$17.88"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "107527",
            "Clicks": "3424",
            "Conversions": "88",
            "Conversion Value": "$15,796",
            "Cost per Result": "$17.94"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "391597",
            "Clicks": "2795",
            "Conversions": "60",
            "Conversion Value": "$15,535",
            "Cost per Result": "$44.47"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "327309",
            "Clicks": "6580",
            "Conversions": "44",
            "Conversion Value": "$14,086",
            "Cost per Result": "$56.87"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "180937",
            "Clicks": "5753",
            "Conversions": "80",
            "Conversion Value": "$6,144",
            "Cost per Result": "$51.93"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "270710",
            "Clicks": "3991",
            "Conversions": "73",
            "Conversion Value": "$15,695",
            "Cost per Result": "$56.86"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "187346",
            "Clicks": "2243",
            "Conversions": "53",
            "Conversion Value": "$11,860",
            "Cost per Result": "$52.87"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "110083",
            "Clicks": "4448",
            "Conversions": "45",
            "Conversion Value": "$4,300",
            "Cost per Result": "$30.14"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "273694",
            "Clicks": "5671",
            "Conversions": "70",
            "Conversion Value": "$14,675",
            "Cost per Result": "$26.98"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "328452",
            "Clicks": "2470",
            "Conversions": "54",
            "Conversion Value": "$13,121",
            "Cost per Result": "$57.89"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "376682",
            "Clicks": "3982",
            "Conversions": "59",
            "Conversion Value": "$15,094",
            "Cost per Result": "$45.10"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "356773",
            "Clicks": "5164",
            "Conversions": "57",
            "Conversion Value": "$8,491",
            "Cost per Result": "$17.55"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "403045",
            "Clicks": "3726",
            "Conversions": "85",
            "Conversion Value": "$6,314",
            "Cost per Result": "$28.93"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "394063",
            "Clicks": "3240",
            "Conversions": "65",
            "Conversion Value": "$5,933",
            "Cost per Result": "$49.20"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "480714",
            "Clicks": "5142",
            "Conversions": "84",
            "Conversion Value": "$12,869",
            "Cost per Result": "$58.85"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "268297",
            "Clicks": "2844",
            "Conversions": "54",
            "Conversion Value": "$9,032",
            "Cost per Result": "$26.48"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "231702",
            "Clicks": "2954",
            "Conversions": "81",
            "Conversion Value": "$7,195",
            "Cost per Result": "$31.79"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "489978",
            "Clicks": "5537",
            "Conversions": "70",
            "Conversion Value": "$4,432",
            "Cost per Result": "$29.82"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "313279",
            "Clicks": "5323",
            "Conversions": "75",
            "Conversion Value": "$9,143",
            "Cost per Result": "$33.86"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "165538",
            "Clicks": "3251",
            "Conversions": "72",
            "Conversion Value": "$4,180",
            "Cost per Result": "$27.00"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "393920",
            "Clicks": "4928",
            "Conversions": "67",
            "Conversion Value": "$13,533",
            "Cost per Result": "$28.81"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "482388",
            "Clicks": "3909",
            "Conversions": "58",
            "Conversion Value": "$14,630",
            "Cost per Result": "$30.07"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "351954",
            "Clicks": "2174",
            "Conversions": "47",
            "Conversion Value": "$7,868",
            "Cost per Result": "$20.13"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "190953",
            "Clicks": "6345",
            "Conversions": "54",
            "Conversion Value": "$8,459",
            "Cost per Result": "$42.54"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "450359",
            "Clicks": "6444",
            "Conversions": "44",
            "Conversion Value": "$13,925",
            "Cost per Result": "$19.81"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "162169",
            "Clicks": "5651",
            "Conversions": "75",
            "Conversion Value": "$8,922",
            "Cost per Result": "$24.78"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "374486",
            "Clicks": "3596",
            "Conversions": "75",
            "Conversion Value": "$7,275",
            "Cost per Result": "$64.66"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "393511",
            "Clicks": "3535",
            "Conversions": "68",
            "Conversion Value": "$15,346",
            "Cost per Result": "$46.65"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "225939",
            "Clicks": "5425",
            "Conversions": "86",
            "Conversion Value": "$7,499",
            "Cost per Result": "$57.56"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "206038",
            "Clicks": "2781",
            "Conversions": "72",
            "Conversion Value": "$7,028",
            "Cost per Result": "$59.67"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "331434",
            "Clicks": "6533",
            "Conversions": "42",
            "Conversion Value": "$12,914",
            "Cost per Result": "$59.34"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "283449",
            "Clicks": "4075",
            "Conversions": "87",
            "Conversion Value": "$11,957",
            "Cost per Result": "$48.65"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "218060",
            "Clicks": "3347",
            "Conversions": "81",
            "Conversion Value": "$10,517",
            "Cost per Result": "$58.00"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "447610",
            "Clicks": "6636",
            "Conversions": "62",
            "Conversion Value": "$12,876",
            "Cost per Result": "$16.12"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "474600",
            "Clicks": "6613",
            "Conversions": "58",
            "Conversion Value": "$6,508",
            "Cost per Result": "$50.87"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "270294",
            "Clicks": "2145",
            "Conversions": "80",
            "Conversion Value": "$8,128",
            "Cost per Result": "$37.66"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "492616",
            "Clicks": "3069",
            "Conversions": "58",
            "Conversion Value": "$8,414",
            "Cost per Result": "$26.32"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "146187",
            "Clicks": "3155",
            "Conversions": "67",
            "Conversion Value": "$14,808",
            "Cost per Result": "$62.11"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "272164",
            "Clicks": "2927",
            "Conversions": "82",
            "Conversion Value": "$14,463",
            "Cost per Result": "$51.84"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "487038",
            "Clicks": "5394",
            "Conversions": "61",
            "Conversion Value": "$15,937",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "477846",
            "Clicks": "5753",
            "Conversions": "70",
            "Conversion Value": "$10,419",
            "Cost per Result": "$42.95"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "161864",
            "Clicks": "6528",
            "Conversions": "85",
            "Conversion Value": "$9,042",
            "Cost per Result": "$17.06"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "415290",
            "Clicks": "3122",
            "Conversions": "68",
            "Conversion Value": "$4,492",
            "Cost per Result": "$51.17"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "355301",
            "Clicks": "6844",
            "Conversions": "66",
            "Conversion Value": "$14,196",
            "Cost per Result": "$29.76"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "321104",
            "Clicks": "3691",
            "Conversions": "66",
            "Conversion Value": "$8,050",
            "Cost per Result": "$41.65"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "328678",
            "Clicks": "2891",
            "Conversions": "54",
            "Conversion Value": "$9,730",
            "Cost per Result": "$47.17"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "457259",
            "Clicks": "5568",
            "Conversions": "68",
            "Conversion Value": "$5,401",
            "Cost per Result": "$51.28"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "460810",
            "Clicks": "5340",
            "Conversions": "84",
            "Conversion Value": "$11,830",
            "Cost per Result": "$30.36"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "391237",
            "Clicks": "2233",
            "Conversions": "59",
            "Conversion Value": "$10,684",
            "Cost per Result": "$44.93"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "463291",
            "Clicks": "4471",
            "Conversions": "87",
            "Conversion Value": "$11,295",
            "Cost per Result": "$52.79"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "325049",
            "Clicks": "2799",
            "Conversions": "43",
            "Conversion Value": "$13,415",
            "Cost per Result": "$58.65"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "485416",
            "Clicks": "4954",
            "Conversions": "64",
            "Conversion Value": "$12,282",
            "Cost per Result": "$39.55"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "289322",
            "Clicks": "3684",
            "Conversions": "53",
            "Conversion Value": "$15,296",
            "Cost per Result": "$50.02"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "354866",
            "Clicks": "2625",
            "Conversions": "51",
            "Conversion Value": "$13,895",
            "Cost per Result": "$64.24"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "304591",
            "Clicks": "3609",
            "Conversions": "88",
            "Conversion Value": "$14,760",
            "Cost per Result": "$24.33"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "281367",
            "Clicks": "6046",
            "Conversions": "44",
            "Conversion Value": "$10,219",
            "Cost per Result": "$41.51"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "373098",
            "Clicks": "4005",
            "Conversions": "58",
            "Conversion Value": "$11,125",
            "Cost per Result": "$32.69"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "352498",
            "Clicks": "6005",
            "Conversions": "80",
            "Conversion Value": "$6,931",
            "Cost per Result": "$23.03"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "324753",
            "Clicks": "5130",
            "Conversions": "80",
            "Conversion Value": "$10,339",
            "Cost per Result": "$21.02"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "475513",
            "Clicks": "5032",
            "Conversions": "57",
            "Conversion Value": "$5,813",
            "Cost per Result": "$30.09"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "105504",
            "Clicks": "4373",
            "Conversions": "66",
            "Conversion Value": "$11,667",
            "Cost per Result": "$55.18"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "199779",
            "Clicks": "2188",
            "Conversions": "67",
            "Conversion Value": "$8,436",
            "Cost per Result": "$45.69"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "251452",
            "Clicks": "6014",
            "Conversions": "45",
            "Conversion Value": "$14,362",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "255509",
            "Clicks": "6229",
            "Conversions": "84",
            "Conversion Value": "$12,732",
            "Cost per Result": "$46.38"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "105323",
            "Clicks": "4176",
            "Conversions": "52",
            "Conversion Value": "$5,801",
            "Cost per Result": "$28.36"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "361777",
            "Clicks": "2954",
            "Conversions": "50",
            "Conversion Value": "$15,993",
            "Cost per Result": "$43.75"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "452117",
            "Clicks": "2321",
            "Conversions": "62",
            "Conversion Value": "$14,717",
            "Cost per Result": "$31.73"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "417144",
            "Clicks": "2535",
            "Conversions": "57",
            "Conversion Value": "$7,724",
            "Cost per Result": "$15.60"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "207985",
            "Clicks": "4831",
            "Conversions": "62",
            "Conversion Value": "$11,263",
            "Cost per Result": "$38.68"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "447894",
            "Clicks": "4574",
            "Conversions": "59",
            "Conversion Value": "$9,539",
            "Cost per Result": "$59.48"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "102021",
            "Clicks": "4087",
            "Conversions": "40",
            "Conversion Value": "$8,629",
            "Cost per Result": "$30.68"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "141247",
            "Clicks": "2158",
            "Conversions": "88",
            "Conversion Value": "$10,310",
            "Cost per Result": "$16.08"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "137183",
            "Clicks": "4864",
            "Conversions": "79",
            "Conversion Value": "$4,817",
            "Cost per Result": "$54.41"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "254805",
            "Clicks": "5357",
            "Conversions": "87",
            "Conversion Value": "$9,936",
            "Cost per Result": "$18.63"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "377892",
            "Clicks": "2569",
            "Conversions": "61",
            "Conversion Value": "$6,802",
            "Cost per Result": "$44.73"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "320704",
            "Clicks": "5985",
            "Conversions": "65",
            "Conversion Value": "$6,575",
            "Cost per Result": "$28.18"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "143041",
            "Clicks": "6071",
            "Conversions": "85",
            "Conversion Value": "$14,654",
            "Cost per Result": "$26.84"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "436846",
            "Clicks": "2995",
            "Conversions": "59",
            "Conversion Value": "$11,628",
            "Cost per Result": "$53.12"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "420474",
            "Clicks": "6130",
            "Conversions": "73",
            "Conversion Value": "$4,788",
            "Cost per Result": "$20.68"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "172456",
            "Clicks": "4717",
            "Conversions": "69",
            "Conversion Value": "$5,450",
            "Cost per Result": "$59.74"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "180110",
            "Clicks": "3234",
            "Conversions": "76",
            "Conversion Value": "$15,418",
            "Cost per Result": "$47.06"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "256944",
            "Clicks": "4115",
            "Conversions": "48",
            "Conversion Value": "$9,049",
            "Cost per Result": "$54.20"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "146584",
            "Clicks": "6281",
            "Conversions": "40",
            "Conversion Value": "$15,704",
            "Cost per Result": "$27.72"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "146396",
            "Clicks": "6391",
            "Conversions": "82",
            "Conversion Value": "$15,080",
            "Cost per Result": "$25.27"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "278596",
            "Clicks": "5742",
            "Conversions": "83",
            "Conversion Value": "$9,184",
            "Cost per Result": "$59.89"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "479391",
            "Clicks": "2872",
            "Conversions": "83",
            "Conversion Value": "$4,578",
            "Cost per Result": "$33.70"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "452528",
            "Clicks": "4943",
            "Conversions": "79",
            "Conversion Value": "$7,706",
            "Cost per Result": "$48.70"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "382475",
            "Clicks": "3425",
            "Conversions": "80",
            "Conversion Value": "$11,045",
            "Cost per Result": "$20.49"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "104771",
            "Clicks": "3727",
            "Conversions": "65",
            "Conversion Value": "$10,371",
            "Cost per Result": "$23.16"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "236438",
            "Clicks": "4325",
            "Conversions": "73",
            "Conversion Value": "$8,936",
            "Cost per Result": "$44.18"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "474532",
            "Clicks": "4107",
            "Conversions": "87",
            "Conversion Value": "$12,808",
            "Cost per Result": "$31.64"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "169297",
            "Clicks": "3926",
            "Conversions": "63",
            "Conversion Value": "$5,150",
            "Cost per Result": "$29.95"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "311602",
            "Clicks": "3769",
            "Conversions": "78",
            "Conversion Value": "$15,179",
            "Cost per Result": "$31.33"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "433924",
            "Clicks": "6800",
            "Conversions": "58",
            "Conversion Value": "$10,827",
            "Cost per Result": "$41.42"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "158246",
            "Clicks": "5162",
            "Conversions": "65",
            "Conversion Value": "$6,587",
            "Cost per Result": "$62.83"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "421969",
            "Clicks": "4584",
            "Conversions": "90",
            "Conversion Value": "$5,412",
            "Cost per Result": "$35.85"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "148216",
            "Clicks": "5015",
            "Conversions": "66",
            "Conversion Value": "$10,618",
            "Cost per Result": "$49.82"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "156830",
            "Clicks": "5462",
            "Conversions": "46",
            "Conversion Value": "$13,458",
            "Cost per Result": "$61.95"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "438134",
            "Clicks": "6877",
            "Conversions": "75",
            "Conversion Value": "$5,317",
            "Cost per Result": "$16.23"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "123845",
            "Clicks": "2117",
            "Conversions": "50",
            "Conversion Value": "$4,573",
            "Cost per Result": "$57.61"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "126906",
            "Clicks": "5582",
            "Conversions": "44",
            "Conversion Value": "$12,527",
            "Cost per Result": "$28.29"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "193868",
            "Clicks": "6809",
            "Conversions": "54",
            "Conversion Value": "$7,230",
            "Cost per Result": "$36.03"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "438910",
            "Clicks": "3627",
            "Conversions": "79",
            "Conversion Value": "$7,772",
            "Cost per Result": "$47.50"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "166371",
            "Clicks": "6892",
            "Conversions": "84",
            "Conversion Value": "$6,143",
            "Cost per Result": "$47.59"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "138921",
            "Clicks": "6478",
            "Conversions": "69",
            "Conversion Value": "$4,169",
            "Cost per Result": "$28.64"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420908",
            "Clicks": "5873",
            "Conversions": "81",
            "Conversion Value": "$10,474",
            "Cost per Result": "$61.70"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "485066",
            "Clicks": "3779",
            "Conversions": "73",
            "Conversion Value": "$7,621",
            "Cost per Result": "$34.38"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "473062",
            "Clicks": "6047",
            "Conversions": "68",
            "Conversion Value": "$9,423",
            "Cost per Result": "$46.08"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "265427",
            "Clicks": "5840",
            "Conversions": "81",
            "Conversion Value": "$5,143",
            "Cost per Result": "$15.97"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "166253",
            "Clicks": "4510",
            "Conversions": "66",
            "Conversion Value": "$7,526",
            "Cost per Result": "$28.68"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "140759",
            "Clicks": "2279",
            "Conversions": "83",
            "Conversion Value": "$14,877",
            "Cost per Result": "$20.13"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "308211",
            "Clicks": "4168",
            "Conversions": "63",
            "Conversion Value": "$8,358",
            "Cost per Result": "$59.28"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "447540",
            "Clicks": "5227",
            "Conversions": "84",
            "Conversion Value": "$14,242",
            "Cost per Result": "$19.31"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "426487",
            "Clicks": "5260",
            "Conversions": "61",
            "Conversion Value": "$5,746",
            "Cost per Result": "$52.04"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "401267",
            "Clicks": "6401",
            "Conversions": "61",
            "Conversion Value": "$15,243",
            "Cost per Result": "$40.12"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "447811",
            "Clicks": "2328",
            "Conversions": "77",
            "Conversion Value": "$5,317",
            "Cost per Result": "$18.09"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "159441",
            "Clicks": "6831",
            "Conversions": "45",
            "Conversion Value": "$15,780",
            "Cost per Result": "$21.82"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "309786",
            "Clicks": "5913",
            "Conversions": "76",
            "Conversion Value": "$8,273",
            "Cost per Result": "$53.79"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "118444",
            "Clicks": "4296",
            "Conversions": "71",
            "Conversion Value": "$4,484",
            "Cost per Result": "$57.49"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "229635",
            "Clicks": "6081",
            "Conversions": "53",
            "Conversion Value": "$15,994",
            "Cost per Result": "$51.49"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "271179",
            "Clicks": "5971",
            "Conversions": "40",
            "Conversion Value": "$8,615",
            "Cost per Result": "$39.02"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "154348",
            "Clicks": "5107",
            "Conversions": "83",
            "Conversion Value": "$5,366",
            "Cost per Result": "$17.93"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "366538",
            "Clicks": "2455",
            "Conversions": "49",
            "Conversion Value": "$13,107",
            "Cost per Result": "$48.02"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "401418",
            "Clicks": "4851",
            "Conversions": "54",
            "Conversion Value": "$9,643",
            "Cost per Result": "$17.62"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "392790",
            "Clicks": "6931",
            "Conversions": "89",
            "Conversion Value": "$10,665",
            "Cost per Result": "$37.72"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "402732",
            "Clicks": "3992",
            "Conversions": "56",
            "Conversion Value": "$8,109",
            "Cost per Result": "$38.07"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "340966",
            "Clicks": "5442",
            "Conversions": "56",
            "Conversion Value": "$8,656",
            "Cost per Result": "$60.50"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "150452",
            "Clicks": "3974",
            "Conversions": "41",
            "Conversion Value": "$13,733",
            "Cost per Result": "$27.93"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "159004",
            "Clicks": "2241",
            "Conversions": "51",
            "Conversion Value": "$5,192",
            "Cost per Result": "$37.42"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "108646",
            "Clicks": "3221",
            "Conversions": "64",
            "Conversion Value": "$8,071",
            "Cost per Result": "$29.41"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "131541",
            "Clicks": "4304",
            "Conversions": "47",
            "Conversion Value": "$4,840",
            "Cost per Result": "$33.82"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "124962",
            "Clicks": "6555",
            "Conversions": "78",
            "Conversion Value": "$13,831",
            "Cost per Result": "$24.38"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "143840",
            "Clicks": "5800",
            "Conversions": "55",
            "Conversion Value": "$8,485",
            "Cost per Result": "$20.69"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "310922",
            "Clicks": "3330",
            "Conversions": "65",
            "Conversion Value": "$13,204",
            "Cost per Result": "$36.75"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "122941",
            "Clicks": "3851",
            "Conversions": "50",
            "Conversion Value": "$10,470",
            "Cost per Result": "$28.88"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246994",
            "Clicks": "5211",
            "Conversions": "75",
            "Conversion Value": "$6,167",
            "Cost per Result": "$27.42"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "186993",
            "Clicks": "3788",
            "Conversions": "59",
            "Conversion Value": "$11,751",
            "Cost per Result": "$58.99"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "230087",
            "Clicks": "4857",
            "Conversions": "73",
            "Conversion Value": "$11,793",
            "Cost per Result": "$38.07"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "249030",
            "Clicks": "3626",
            "Conversions": "80",
            "Conversion Value": "$4,855",
            "Cost per Result": "$28.96"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "468545",
            "Clicks": "3731",
            "Conversions": "80",
            "Conversion Value": "$7,820",
            "Cost per Result": "$62.97"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "268984",
            "Clicks": "4228",
            "Conversions": "71",
            "Conversion Value": "$7,154",
            "Cost per Result": "$35.46"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "326191",
            "Clicks": "2386",
            "Conversions": "87",
            "Conversion Value": "$4,753",
            "Cost per Result": "$18.72"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "464002",
            "Clicks": "2725",
            "Conversions": "81",
            "Conversion Value": "$9,828",
            "Cost per Result": "$29.36"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "337122",
            "Clicks": "5629",
            "Conversions": "59",
            "Conversion Value": "$13,830",
            "Cost per Result": "$49.90"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "489639",
            "Clicks": "6154",
            "Conversions": "53",
            "Conversion Value": "$4,868",
            "Cost per Result": "$17.12"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "102204",
            "Clicks": "4091",
            "Conversions": "41",
            "Conversion Value": "$5,814",
            "Cost per Result": "$24.85"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "192112",
            "Clicks": "4822",
            "Conversions": "47",
            "Conversion Value": "$9,951",
            "Cost per Result": "$24.01"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "113846",
            "Clicks": "5614",
            "Conversions": "52",
            "Conversion Value": "$7,800",
            "Cost per Result": "$35.70"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "184505",
            "Clicks": "2771",
            "Conversions": "68",
            "Conversion Value": "$12,884",
            "Cost per Result": "$44.33"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "145558",
            "Clicks": "5218",
            "Conversions": "70",
            "Conversion Value": "$15,383",
            "Cost per Result": "$45.90"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "379121",
            "Clicks": "6659",
            "Conversions": "41",
            "Conversion Value": "$7,780",
            "Cost per Result": "$53.68"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "226677",
            "Clicks": "6011",
            "Conversions": "60",
            "Conversion Value": "$14,676",
            "Cost per Result": "$56.67"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "156438",
            "Clicks": "4737",
            "Conversions": "87",
            "Conversion Value": "$11,998",
            "Cost per Result": "$16.92"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "471034",
            "Clicks": "5407",
            "Conversions": "73",
            "Conversion Value": "$6,881",
            "Cost per Result": "$25.05"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "292362",
            "Clicks": "6005",
            "Conversions": "89",
            "Conversion Value": "$6,156",
            "Cost per Result": "$58.54"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "243306",
            "Clicks": "3256",
            "Conversions": "86",
            "Conversion Value": "$11,348",
            "Cost per Result": "$21.01"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "404629",
            "Clicks": "4609",
            "Conversions": "84",
            "Conversion Value": "$15,024",
            "Cost per Result": "$62.75"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "361482",
            "Clicks": "5717",
            "Conversions": "61",
            "Conversion Value": "$8,316",
            "Cost per Result": "$53.53"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "402717",
            "Clicks": "3572",
            "Conversions": "61",
            "Conversion Value": "$8,577",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "112696",
            "Clicks": "4684",
            "Conversions": "42",
            "Conversion Value": "$14,656",
            "Cost per Result": "$17.72"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "200950",
            "Clicks": "6854",
            "Conversions": "76",
            "Conversion Value": "$15,702",
            "Cost per Result": "$26.13"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "198506",
            "Clicks": "6664",
            "Conversions": "81",
            "Conversion Value": "$8,644",
            "Cost per Result": "$19.03"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "130592",
            "Clicks": "2677",
            "Conversions": "70",
            "Conversion Value": "$9,558",
            "Cost per Result": "$37.47"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "351206",
            "Clicks": "2950",
            "Conversions": "88",
            "Conversion Value": "$11,069",
            "Cost per Result": "$41.10"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "293553",
            "Clicks": "4272",
            "Conversions": "64",
            "Conversion Value": "$5,164",
            "Cost per Result": "$61.06"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "433469",
            "Clicks": "3681",
            "Conversions": "51",
            "Conversion Value": "$12,167",
            "Cost per Result": "$51.53"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "149300",
            "Clicks": "5470",
            "Conversions": "48",
            "Conversion Value": "$9,704",
            "Cost per Result": "$26.05"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "138702",
            "Clicks": "4633",
            "Conversions": "51",
            "Conversion Value": "$13,956",
            "Cost per Result": "$41.55"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "410254",
            "Clicks": "6609",
            "Conversions": "69",
            "Conversion Value": "$13,992",
            "Cost per Result": "$56.07"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "469871",
            "Clicks": "5903",
            "Conversions": "73",
            "Conversion Value": "$4,766",
            "Cost per Result": "$39.61"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "113105",
            "Clicks": "5442",
            "Conversions": "84",
            "Conversion Value": "$10,844",
            "Cost per Result": "$26.41"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "141990",
            "Clicks": "5693",
            "Conversions": "50",
            "Conversion Value": "$8,856",
            "Cost per Result": "$15.76"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "289286",
            "Clicks": "5155",
            "Conversions": "72",
            "Conversion Value": "$9,987",
            "Cost per Result": "$55.77"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "411380",
            "Clicks": "3282",
            "Conversions": "57",
            "Conversion Value": "$7,212",
            "Cost per Result": "$28.86"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "113518",
            "Clicks": "2498",
            "Conversions": "72",
            "Conversion Value": "$13,119",
            "Cost per Result": "$26.52"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "297382",
            "Clicks": "4762",
            "Conversions": "55",
            "Conversion Value": "$10,539",
            "Cost per Result": "$35.28"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "382620",
            "Clicks": "3591",
            "Conversions": "55",
            "Conversion Value": "$9,801",
            "Cost per Result": "$26.99"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "175453",
            "Clicks": "5689",
            "Conversions": "50",
            "Conversion Value": "$9,081",
            "Cost per Result": "$59.69"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "266223",
            "Clicks": "4338",
            "Conversions": "61",
            "Conversion Value": "$8,301",
            "Cost per Result": "$41.15"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "161132",
            "Clicks": "5452",
            "Conversions": "90",
            "Conversion Value": "$11,862",
            "Cost per Result": "$31.45"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "357095",
            "Clicks": "4970",
            "Conversions": "75",
            "Conversion Value": "$14,328",
            "Cost per Result": "$45.74"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "180731",
            "Clicks": "5749",
            "Conversions": "41",
            "Conversion Value": "$10,020",
            "Cost per Result": "$55.79"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "392388",
            "Clicks": "6027",
            "Conversions": "60",
            "Conversion Value": "$9,937",
            "Cost per Result": "$16.35"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "375968",
            "Clicks": "6166",
            "Conversions": "85",
            "Conversion Value": "$5,589",
            "Cost per Result": "$55.44"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "361193",
            "Clicks": "2689",
            "Conversions": "66",
            "Conversion Value": "$5,535",
            "Cost per Result": "$54.89"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "487073",
            "Clicks": "4903",
            "Conversions": "41",
            "Conversion Value": "$11,141",
            "Cost per Result": "$35.92"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "381916",
            "Clicks": "3660",
            "Conversions": "45",
            "Conversion Value": "$4,993",
            "Cost per Result": "$58.60"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "303855",
            "Clicks": "5239",
            "Conversions": "80",
            "Conversion Value": "$6,310",
            "Cost per Result": "$17.00"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "130384",
            "Clicks": "4558",
            "Conversions": "69",
            "Conversion Value": "$13,978",
            "Cost per Result": "$61.81"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "100297",
            "Clicks": "4334",
            "Conversions": "49",
            "Conversion Value": "$14,263",
            "Cost per Result": "$41.18"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "312523",
            "Clicks": "2726",
            "Conversions": "68",
            "Conversion Value": "$13,793",
            "Cost per Result": "$43.49"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "139624",
            "Clicks": "5561",
            "Conversions": "53",
            "Conversion Value": "$14,464",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "371514",
            "Clicks": "3558",
            "Conversions": "67",
            "Conversion Value": "$9,711",
            "Cost per Result": "$58.90"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "349420",
            "Clicks": "4207",
            "Conversions": "61",
            "Conversion Value": "$12,529",
            "Cost per Result": "$47.07"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "147354",
            "Clicks": "2348",
            "Conversions": "70",
            "Conversion Value": "$4,860",
            "Cost per Result": "$39.51"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "247017",
            "Clicks": "6237",
            "Conversions": "58",
            "Conversion Value": "$4,245",
            "Cost per Result": "$30.33"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "296814",
            "Clicks": "6709",
            "Conversions": "55",
            "Conversion Value": "$8,653",
            "Cost per Result": "$60.18"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "202346",
            "Clicks": "5878",
            "Conversions": "63",
            "Conversion Value": "$12,613",
            "Cost per Result": "$64.65"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "245198",
            "Clicks": "2861",
            "Conversions": "49",
            "Conversion Value": "$5,113",
            "Cost per Result": "$33.43"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "448166",
            "Clicks": "4242",
            "Conversions": "73",
            "Conversion Value": "$5,764",
            "Cost per Result": "$32.74"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "270685",
            "Clicks": "4523",
            "Conversions": "77",
            "Conversion Value": "$5,659",
            "Cost per Result": "$51.54"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "306721",
            "Clicks": "2601",
            "Conversions": "72",
            "Conversion Value": "$4,284",
            "Cost per Result": "$27.96"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "391165",
            "Clicks": "6306",
            "Conversions": "49",
            "Conversion Value": "$9,478",
            "Cost per Result": "$15.27"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "349489",
            "Clicks": "6490",
            "Conversions": "89",
            "Conversion Value": "$9,334",
            "Cost per Result": "$22.51"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "487465",
            "Clicks": "2905",
            "Conversions": "83",
            "Conversion Value": "$12,674",
            "Cost per Result": "$52.95"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "425805",
            "Clicks": "4537",
            "Conversions": "55",
            "Conversion Value": "$15,541",
            "Cost per Result": "$15.60"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "192024",
            "Clicks": "2275",
            "Conversions": "68",
            "Conversion Value": "$11,533",
            "Cost per Result": "$22.37"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "101414",
            "Clicks": "2193",
            "Conversions": "76",
            "Conversion Value": "$6,147",
            "Cost per Result": "$20.85"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "215995",
            "Clicks": "6961",
            "Conversions": "71",
            "Conversion Value": "$15,275",
            "Cost per Result": "$35.98"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "184982",
            "Clicks": "3779",
            "Conversions": "84",
            "Conversion Value": "$12,806",
            "Cost per Result": "$62.45"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "279242",
            "Clicks": "5227",
            "Conversions": "64",
            "Conversion Value": "$8,191",
            "Cost per Result": "$54.01"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "466317",
            "Clicks": "6914",
            "Conversions": "70",
            "Conversion Value": "$14,825",
            "Cost per Result": "$58.58"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "226870",
            "Clicks": "2158",
            "Conversions": "60",
            "Conversion Value": "$7,782",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "452670",
            "Clicks": "4494",
            "Conversions": "81",
            "Conversion Value": "$9,908",
            "Cost per Result": "$34.23"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "224075",
            "Clicks": "3787",
            "Conversions": "64",
            "Conversion Value": "$12,699",
            "Cost per Result": "$50.56"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "108498",
            "Clicks": "3244",
            "Conversions": "84",
            "Conversion Value": "$11,462",
            "Cost per Result": "$24.09"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "360812",
            "Clicks": "2431",
            "Conversions": "42",
            "Conversion Value": "$9,795",
            "Cost per Result": "$47.33"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "250145",
            "Clicks": "2206",
            "Conversions": "88",
            "Conversion Value": "$8,585",
            "Cost per Result": "$61.51"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "192895",
            "Clicks": "2811",
            "Conversions": "60",
            "Conversion Value": "$5,390",
            "Cost per Result": "$33.32"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "221991",
            "Clicks": "3620",
            "Conversions": "75",
            "Conversion Value": "$5,775",
            "Cost per Result": "$36.35"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "401730",
            "Clicks": "2331",
            "Conversions": "83",
            "Conversion Value": "$5,633",
            "Cost per Result": "$50.16"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "217977",
            "Clicks": "4716",
            "Conversions": "69",
            "Conversion Value": "$7,194",
            "Cost per Result": "$61.30"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "357273",
            "Clicks": "4978",
            "Conversions": "78",
            "Conversion Value": "$8,679",
            "Cost per Result": "$24.53"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "376424",
            "Clicks": "3381",
            "Conversions": "47",
            "Conversion Value": "$7,846",
            "Cost per Result": "$30.64"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "285561",
            "Clicks": "6108",
            "Conversions": "49",
            "Conversion Value": "$10,608",
            "Cost per Result": "$37.33"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "362458",
            "Clicks": "5136",
            "Conversions": "79",
            "Conversion Value": "$15,147",
            "Cost per Result": "$64.82"
          }
        ]
      },
      "IDOLaunchpadsTokenHoldersData": {
        "name": "IDO/Launchpads Token Holders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "354127",
            "Clicks": "2500",
            "Conversions": "63",
            "Conversion Value": "$6,742",
            "Cost per Result": "$59.92"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "388924",
            "Clicks": "3945",
            "Conversions": "48",
            "Conversion Value": "$12,155",
            "Cost per Result": "$57.69"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "180161",
            "Clicks": "2369",
            "Conversions": "86",
            "Conversion Value": "$11,939",
            "Cost per Result": "$52.88"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "307469",
            "Clicks": "6958",
            "Conversions": "64",
            "Conversion Value": "$12,880",
            "Cost per Result": "$16.68"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "174517",
            "Clicks": "2801",
            "Conversions": "79",
            "Conversion Value": "$10,195",
            "Cost per Result": "$19.74"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "412616",
            "Clicks": "6479",
            "Conversions": "57",
            "Conversion Value": "$7,748",
            "Cost per Result": "$41.38"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "168255",
            "Clicks": "2636",
            "Conversions": "81",
            "Conversion Value": "$12,654",
            "Cost per Result": "$64.42"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "451077",
            "Clicks": "5438",
            "Conversions": "82",
            "Conversion Value": "$9,096",
            "Cost per Result": "$16.81"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "377284",
            "Clicks": "3776",
            "Conversions": "74",
            "Conversion Value": "$14,254",
            "Cost per Result": "$38.54"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "449901",
            "Clicks": "5784",
            "Conversions": "75",
            "Conversion Value": "$8,948",
            "Cost per Result": "$18.55"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "467859",
            "Clicks": "5256",
            "Conversions": "52",
            "Conversion Value": "$4,112",
            "Cost per Result": "$32.76"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "175213",
            "Clicks": "6614",
            "Conversions": "87",
            "Conversion Value": "$6,137",
            "Cost per Result": "$38.91"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "105409",
            "Clicks": "6932",
            "Conversions": "70",
            "Conversion Value": "$8,494",
            "Cost per Result": "$57.04"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "163200",
            "Clicks": "4436",
            "Conversions": "87",
            "Conversion Value": "$4,722",
            "Cost per Result": "$45.25"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "272852",
            "Clicks": "6715",
            "Conversions": "47",
            "Conversion Value": "$15,325",
            "Cost per Result": "$63.53"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "123411",
            "Clicks": "4237",
            "Conversions": "58",
            "Conversion Value": "$10,484",
            "Cost per Result": "$30.80"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "460587",
            "Clicks": "2000",
            "Conversions": "45",
            "Conversion Value": "$14,291",
            "Cost per Result": "$55.26"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "249033",
            "Clicks": "5101",
            "Conversions": "66",
            "Conversion Value": "$10,111",
            "Cost per Result": "$47.05"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "485373",
            "Clicks": "6196",
            "Conversions": "56",
            "Conversion Value": "$6,751",
            "Cost per Result": "$33.40"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "409614",
            "Clicks": "6432",
            "Conversions": "90",
            "Conversion Value": "$11,936",
            "Cost per Result": "$30.27"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "431920",
            "Clicks": "3285",
            "Conversions": "73",
            "Conversion Value": "$6,498",
            "Cost per Result": "$49.62"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "419682",
            "Clicks": "2933",
            "Conversions": "88",
            "Conversion Value": "$13,748",
            "Cost per Result": "$17.95"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "252953",
            "Clicks": "5917",
            "Conversions": "54",
            "Conversion Value": "$10,163",
            "Cost per Result": "$32.52"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "473694",
            "Clicks": "2702",
            "Conversions": "58",
            "Conversion Value": "$8,269",
            "Cost per Result": "$16.93"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "416032",
            "Clicks": "6920",
            "Conversions": "73",
            "Conversion Value": "$12,264",
            "Cost per Result": "$52.67"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "446948",
            "Clicks": "5451",
            "Conversions": "77",
            "Conversion Value": "$11,390",
            "Cost per Result": "$31.11"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "334637",
            "Clicks": "5203",
            "Conversions": "61",
            "Conversion Value": "$4,572",
            "Cost per Result": "$58.21"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "147994",
            "Clicks": "3137",
            "Conversions": "83",
            "Conversion Value": "$12,896",
            "Cost per Result": "$64.83"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "334670",
            "Clicks": "4100",
            "Conversions": "54",
            "Conversion Value": "$8,142",
            "Cost per Result": "$29.03"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "366373",
            "Clicks": "5078",
            "Conversions": "88",
            "Conversion Value": "$14,187",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "334406",
            "Clicks": "4008",
            "Conversions": "58",
            "Conversion Value": "$14,856",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "317099",
            "Clicks": "2230",
            "Conversions": "66",
            "Conversion Value": "$4,002",
            "Cost per Result": "$62.69"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "486018",
            "Clicks": "4392",
            "Conversions": "56",
            "Conversion Value": "$11,046",
            "Cost per Result": "$37.06"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "398544",
            "Clicks": "2029",
            "Conversions": "85",
            "Conversion Value": "$4,139",
            "Cost per Result": "$39.85"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "351593",
            "Clicks": "4198",
            "Conversions": "60",
            "Conversion Value": "$4,469",
            "Cost per Result": "$19.02"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "442456",
            "Clicks": "6418",
            "Conversions": "75",
            "Conversion Value": "$5,278",
            "Cost per Result": "$29.05"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "458481",
            "Clicks": "5537",
            "Conversions": "78",
            "Conversion Value": "$4,763",
            "Cost per Result": "$21.65"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "164785",
            "Clicks": "2319",
            "Conversions": "62",
            "Conversion Value": "$11,728",
            "Cost per Result": "$58.84"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "171774",
            "Clicks": "5224",
            "Conversions": "70",
            "Conversion Value": "$8,287",
            "Cost per Result": "$29.78"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "275475",
            "Clicks": "3115",
            "Conversions": "55",
            "Conversion Value": "$4,816",
            "Cost per Result": "$16.72"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "290802",
            "Clicks": "6813",
            "Conversions": "88",
            "Conversion Value": "$13,868",
            "Cost per Result": "$20.07"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "357746",
            "Clicks": "2207",
            "Conversions": "60",
            "Conversion Value": "$8,127",
            "Cost per Result": "$55.19"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "357984",
            "Clicks": "4950",
            "Conversions": "63",
            "Conversion Value": "$4,154",
            "Cost per Result": "$17.21"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "297121",
            "Clicks": "4798",
            "Conversions": "41",
            "Conversion Value": "$8,163",
            "Cost per Result": "$27.31"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "179920",
            "Clicks": "6868",
            "Conversions": "41",
            "Conversion Value": "$6,806",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "173093",
            "Clicks": "6557",
            "Conversions": "62",
            "Conversion Value": "$8,760",
            "Cost per Result": "$24.37"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "300875",
            "Clicks": "6322",
            "Conversions": "90",
            "Conversion Value": "$8,122",
            "Cost per Result": "$55.85"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "199031",
            "Clicks": "4371",
            "Conversions": "64",
            "Conversion Value": "$11,726",
            "Cost per Result": "$39.61"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "418096",
            "Clicks": "4141",
            "Conversions": "61",
            "Conversion Value": "$7,817",
            "Cost per Result": "$59.82"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "116745",
            "Clicks": "4202",
            "Conversions": "85",
            "Conversion Value": "$12,070",
            "Cost per Result": "$42.89"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "447847",
            "Clicks": "6207",
            "Conversions": "73",
            "Conversion Value": "$6,614",
            "Cost per Result": "$62.71"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "260588",
            "Clicks": "2930",
            "Conversions": "88",
            "Conversion Value": "$5,886",
            "Cost per Result": "$46.89"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "283375",
            "Clicks": "3436",
            "Conversions": "77",
            "Conversion Value": "$14,992",
            "Cost per Result": "$20.02"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "272736",
            "Clicks": "3624",
            "Conversions": "47",
            "Conversion Value": "$15,529",
            "Cost per Result": "$55.94"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "354918",
            "Clicks": "4275",
            "Conversions": "90",
            "Conversion Value": "$4,944",
            "Cost per Result": "$52.66"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "378380",
            "Clicks": "2777",
            "Conversions": "67",
            "Conversion Value": "$13,126",
            "Cost per Result": "$47.49"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "112566",
            "Clicks": "5929",
            "Conversions": "77",
            "Conversion Value": "$11,439",
            "Cost per Result": "$29.16"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "350059",
            "Clicks": "6922",
            "Conversions": "60",
            "Conversion Value": "$7,743",
            "Cost per Result": "$54.64"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "491503",
            "Clicks": "6971",
            "Conversions": "58",
            "Conversion Value": "$11,611",
            "Cost per Result": "$49.31"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "168888",
            "Clicks": "2450",
            "Conversions": "75",
            "Conversion Value": "$7,385",
            "Cost per Result": "$39.74"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "353455",
            "Clicks": "6933",
            "Conversions": "72",
            "Conversion Value": "$9,947",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "222498",
            "Clicks": "4334",
            "Conversions": "52",
            "Conversion Value": "$7,416",
            "Cost per Result": "$15.83"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "277054",
            "Clicks": "4695",
            "Conversions": "70",
            "Conversion Value": "$15,692",
            "Cost per Result": "$22.11"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "192241",
            "Clicks": "6387",
            "Conversions": "89",
            "Conversion Value": "$6,609",
            "Cost per Result": "$36.95"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "223090",
            "Clicks": "6031",
            "Conversions": "90",
            "Conversion Value": "$8,882",
            "Cost per Result": "$51.07"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "233697",
            "Clicks": "5323",
            "Conversions": "84",
            "Conversion Value": "$6,528",
            "Cost per Result": "$15.10"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "439867",
            "Clicks": "4151",
            "Conversions": "59",
            "Conversion Value": "$14,484",
            "Cost per Result": "$16.89"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "134273",
            "Clicks": "4762",
            "Conversions": "42",
            "Conversion Value": "$13,020",
            "Cost per Result": "$40.89"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "291535",
            "Clicks": "2755",
            "Conversions": "41",
            "Conversion Value": "$6,272",
            "Cost per Result": "$49.83"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "460364",
            "Clicks": "5539",
            "Conversions": "76",
            "Conversion Value": "$12,720",
            "Cost per Result": "$43.37"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "462158",
            "Clicks": "2327",
            "Conversions": "64",
            "Conversion Value": "$6,116",
            "Cost per Result": "$40.03"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "336141",
            "Clicks": "3998",
            "Conversions": "77",
            "Conversion Value": "$15,217",
            "Cost per Result": "$42.34"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "265551",
            "Clicks": "2881",
            "Conversions": "90",
            "Conversion Value": "$4,130",
            "Cost per Result": "$53.57"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "209783",
            "Clicks": "6841",
            "Conversions": "52",
            "Conversion Value": "$14,257",
            "Cost per Result": "$60.87"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "411895",
            "Clicks": "4066",
            "Conversions": "54",
            "Conversion Value": "$9,460",
            "Cost per Result": "$43.64"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "375143",
            "Clicks": "5390",
            "Conversions": "60",
            "Conversion Value": "$6,513",
            "Cost per Result": "$55.47"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "264273",
            "Clicks": "5502",
            "Conversions": "65",
            "Conversion Value": "$4,028",
            "Cost per Result": "$17.95"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "473457",
            "Clicks": "4879",
            "Conversions": "64",
            "Conversion Value": "$14,277",
            "Cost per Result": "$57.84"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "460629",
            "Clicks": "6233",
            "Conversions": "48",
            "Conversion Value": "$11,013",
            "Cost per Result": "$42.84"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "477393",
            "Clicks": "3353",
            "Conversions": "52",
            "Conversion Value": "$7,209",
            "Cost per Result": "$38.82"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "159009",
            "Clicks": "4524",
            "Conversions": "44",
            "Conversion Value": "$11,755",
            "Cost per Result": "$59.41"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "466907",
            "Clicks": "5695",
            "Conversions": "59",
            "Conversion Value": "$15,210",
            "Cost per Result": "$59.94"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "447960",
            "Clicks": "3506",
            "Conversions": "67",
            "Conversion Value": "$9,730",
            "Cost per Result": "$23.91"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "382414",
            "Clicks": "5772",
            "Conversions": "77",
            "Conversion Value": "$8,597",
            "Cost per Result": "$59.26"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "291875",
            "Clicks": "4881",
            "Conversions": "53",
            "Conversion Value": "$9,641",
            "Cost per Result": "$18.80"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "356399",
            "Clicks": "4172",
            "Conversions": "51",
            "Conversion Value": "$11,302",
            "Cost per Result": "$17.72"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "377055",
            "Clicks": "3487",
            "Conversions": "62",
            "Conversion Value": "$15,945",
            "Cost per Result": "$26.30"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "281690",
            "Clicks": "2062",
            "Conversions": "86",
            "Conversion Value": "$15,496",
            "Cost per Result": "$17.15"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "493040",
            "Clicks": "3783",
            "Conversions": "55",
            "Conversion Value": "$7,696",
            "Cost per Result": "$38.01"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "206538",
            "Clicks": "3167",
            "Conversions": "57",
            "Conversion Value": "$7,133",
            "Cost per Result": "$53.92"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "311995",
            "Clicks": "2347",
            "Conversions": "46",
            "Conversion Value": "$15,504",
            "Cost per Result": "$50.16"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "417663",
            "Clicks": "4624",
            "Conversions": "78",
            "Conversion Value": "$15,992",
            "Cost per Result": "$19.90"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "383963",
            "Clicks": "5565",
            "Conversions": "79",
            "Conversion Value": "$13,182",
            "Cost per Result": "$61.97"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "478365",
            "Clicks": "2642",
            "Conversions": "47",
            "Conversion Value": "$14,799",
            "Cost per Result": "$59.11"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "396534",
            "Clicks": "6511",
            "Conversions": "69",
            "Conversion Value": "$7,197",
            "Cost per Result": "$55.59"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "256615",
            "Clicks": "6504",
            "Conversions": "80",
            "Conversion Value": "$11,805",
            "Cost per Result": "$18.80"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "484064",
            "Clicks": "5251",
            "Conversions": "41",
            "Conversion Value": "$10,564",
            "Cost per Result": "$59.54"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "465826",
            "Clicks": "3128",
            "Conversions": "82",
            "Conversion Value": "$12,132",
            "Cost per Result": "$26.43"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "225197",
            "Clicks": "5928",
            "Conversions": "84",
            "Conversion Value": "$8,299",
            "Cost per Result": "$29.19"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "438334",
            "Clicks": "2260",
            "Conversions": "83",
            "Conversion Value": "$12,306",
            "Cost per Result": "$58.58"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "230827",
            "Clicks": "5787",
            "Conversions": "72",
            "Conversion Value": "$4,045",
            "Cost per Result": "$57.57"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "279178",
            "Clicks": "2409",
            "Conversions": "54",
            "Conversion Value": "$4,893",
            "Cost per Result": "$48.47"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "371464",
            "Clicks": "5354",
            "Conversions": "80",
            "Conversion Value": "$11,704",
            "Cost per Result": "$48.67"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "157387",
            "Clicks": "5213",
            "Conversions": "46",
            "Conversion Value": "$5,731",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "478484",
            "Clicks": "6993",
            "Conversions": "90",
            "Conversion Value": "$10,919",
            "Cost per Result": "$53.93"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "167062",
            "Clicks": "3421",
            "Conversions": "84",
            "Conversion Value": "$15,287",
            "Cost per Result": "$64.88"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "314112",
            "Clicks": "6653",
            "Conversions": "76",
            "Conversion Value": "$4,685",
            "Cost per Result": "$26.25"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "445344",
            "Clicks": "2486",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$34.26"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "357892",
            "Clicks": "5693",
            "Conversions": "74",
            "Conversion Value": "$13,974",
            "Cost per Result": "$16.79"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "114746",
            "Clicks": "4948",
            "Conversions": "45",
            "Conversion Value": "$13,462",
            "Cost per Result": "$47.98"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "301626",
            "Clicks": "2565",
            "Conversions": "54",
            "Conversion Value": "$11,667",
            "Cost per Result": "$57.45"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "400144",
            "Clicks": "4510",
            "Conversions": "72",
            "Conversion Value": "$13,745",
            "Cost per Result": "$63.82"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "360431",
            "Clicks": "6133",
            "Conversions": "81",
            "Conversion Value": "$9,039",
            "Cost per Result": "$39.62"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "364161",
            "Clicks": "4880",
            "Conversions": "86",
            "Conversion Value": "$13,877",
            "Cost per Result": "$44.19"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "255912",
            "Clicks": "5576",
            "Conversions": "72",
            "Conversion Value": "$13,722",
            "Cost per Result": "$42.42"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "348377",
            "Clicks": "2955",
            "Conversions": "54",
            "Conversion Value": "$9,452",
            "Cost per Result": "$45.45"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "351196",
            "Clicks": "2766",
            "Conversions": "84",
            "Conversion Value": "$11,426",
            "Cost per Result": "$53.30"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "172145",
            "Clicks": "3447",
            "Conversions": "49",
            "Conversion Value": "$6,405",
            "Cost per Result": "$53.34"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "240362",
            "Clicks": "4466",
            "Conversions": "73",
            "Conversion Value": "$5,620",
            "Cost per Result": "$33.46"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "428082",
            "Clicks": "5361",
            "Conversions": "83",
            "Conversion Value": "$14,281",
            "Cost per Result": "$59.62"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "121936",
            "Clicks": "3732",
            "Conversions": "76",
            "Conversion Value": "$4,651",
            "Cost per Result": "$49.79"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "156432",
            "Clicks": "6082",
            "Conversions": "51",
            "Conversion Value": "$15,053",
            "Cost per Result": "$24.58"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "303681",
            "Clicks": "5901",
            "Conversions": "83",
            "Conversion Value": "$9,321",
            "Cost per Result": "$43.56"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "344204",
            "Clicks": "6109",
            "Conversions": "46",
            "Conversion Value": "$6,385",
            "Cost per Result": "$56.39"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "261713",
            "Clicks": "2827",
            "Conversions": "69",
            "Conversion Value": "$14,289",
            "Cost per Result": "$44.21"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "174674",
            "Clicks": "2277",
            "Conversions": "52",
            "Conversion Value": "$10,930",
            "Cost per Result": "$27.23"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "259665",
            "Clicks": "3938",
            "Conversions": "63",
            "Conversion Value": "$10,656",
            "Cost per Result": "$27.76"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "475775",
            "Clicks": "6744",
            "Conversions": "53",
            "Conversion Value": "$8,032",
            "Cost per Result": "$16.08"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "153348",
            "Clicks": "5622",
            "Conversions": "47",
            "Conversion Value": "$13,104",
            "Cost per Result": "$45.88"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "239988",
            "Clicks": "3126",
            "Conversions": "85",
            "Conversion Value": "$14,813",
            "Cost per Result": "$17.05"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "174510",
            "Clicks": "6639",
            "Conversions": "70",
            "Conversion Value": "$7,776",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "103315",
            "Clicks": "4087",
            "Conversions": "43",
            "Conversion Value": "$9,539",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "196315",
            "Clicks": "6415",
            "Conversions": "41",
            "Conversion Value": "$10,892",
            "Cost per Result": "$21.90"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "497184",
            "Clicks": "5977",
            "Conversions": "86",
            "Conversion Value": "$12,197",
            "Cost per Result": "$63.75"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "406991",
            "Clicks": "6076",
            "Conversions": "69",
            "Conversion Value": "$7,557",
            "Cost per Result": "$36.59"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "476962",
            "Clicks": "2010",
            "Conversions": "53",
            "Conversion Value": "$5,532",
            "Cost per Result": "$31.09"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "111792",
            "Clicks": "2087",
            "Conversions": "56",
            "Conversion Value": "$5,473",
            "Cost per Result": "$21.18"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "167081",
            "Clicks": "5021",
            "Conversions": "40",
            "Conversion Value": "$6,163",
            "Cost per Result": "$33.55"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "383234",
            "Clicks": "5901",
            "Conversions": "76",
            "Conversion Value": "$7,685",
            "Cost per Result": "$30.27"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "360201",
            "Clicks": "2232",
            "Conversions": "71",
            "Conversion Value": "$14,917",
            "Cost per Result": "$63.39"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "403188",
            "Clicks": "6578",
            "Conversions": "56",
            "Conversion Value": "$14,322",
            "Cost per Result": "$30.56"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "211998",
            "Clicks": "6485",
            "Conversions": "84",
            "Conversion Value": "$10,700",
            "Cost per Result": "$31.36"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "286610",
            "Clicks": "4292",
            "Conversions": "47",
            "Conversion Value": "$13,573",
            "Cost per Result": "$17.55"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "361135",
            "Clicks": "6002",
            "Conversions": "41",
            "Conversion Value": "$7,149",
            "Cost per Result": "$58.40"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "125505",
            "Clicks": "5587",
            "Conversions": "42",
            "Conversion Value": "$9,085",
            "Cost per Result": "$59.39"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "283687",
            "Clicks": "6481",
            "Conversions": "82",
            "Conversion Value": "$7,300",
            "Cost per Result": "$50.60"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "304596",
            "Clicks": "4535",
            "Conversions": "68",
            "Conversion Value": "$8,501",
            "Cost per Result": "$54.14"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "202571",
            "Clicks": "2066",
            "Conversions": "76",
            "Conversion Value": "$13,582",
            "Cost per Result": "$29.62"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "259528",
            "Clicks": "5363",
            "Conversions": "88",
            "Conversion Value": "$14,025",
            "Cost per Result": "$43.62"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "485743",
            "Clicks": "3532",
            "Conversions": "62",
            "Conversion Value": "$10,720",
            "Cost per Result": "$55.29"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "398303",
            "Clicks": "5731",
            "Conversions": "60",
            "Conversion Value": "$14,558",
            "Cost per Result": "$63.08"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "484237",
            "Clicks": "6918",
            "Conversions": "52",
            "Conversion Value": "$7,600",
            "Cost per Result": "$41.81"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "191838",
            "Clicks": "6888",
            "Conversions": "44",
            "Conversion Value": "$7,941",
            "Cost per Result": "$25.60"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "215647",
            "Clicks": "6219",
            "Conversions": "87",
            "Conversion Value": "$7,690",
            "Cost per Result": "$28.29"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "146679",
            "Clicks": "2089",
            "Conversions": "53",
            "Conversion Value": "$14,955",
            "Cost per Result": "$23.74"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "254853",
            "Clicks": "5871",
            "Conversions": "74",
            "Conversion Value": "$7,162",
            "Cost per Result": "$51.29"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "269312",
            "Clicks": "2527",
            "Conversions": "52",
            "Conversion Value": "$11,672",
            "Cost per Result": "$50.97"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "247382",
            "Clicks": "4405",
            "Conversions": "68",
            "Conversion Value": "$13,238",
            "Cost per Result": "$61.50"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "266224",
            "Clicks": "6089",
            "Conversions": "43",
            "Conversion Value": "$7,227",
            "Cost per Result": "$30.47"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "238764",
            "Clicks": "3207",
            "Conversions": "58",
            "Conversion Value": "$12,101",
            "Cost per Result": "$17.38"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "126754",
            "Clicks": "6311",
            "Conversions": "48",
            "Conversion Value": "$5,522",
            "Cost per Result": "$30.08"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "273686",
            "Clicks": "5033",
            "Conversions": "78",
            "Conversion Value": "$8,889",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "118401",
            "Clicks": "3319",
            "Conversions": "49",
            "Conversion Value": "$9,569",
            "Cost per Result": "$62.99"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "451248",
            "Clicks": "6252",
            "Conversions": "78",
            "Conversion Value": "$4,160",
            "Cost per Result": "$57.35"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "478972",
            "Clicks": "6275",
            "Conversions": "68",
            "Conversion Value": "$7,278",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "347790",
            "Clicks": "5500",
            "Conversions": "80",
            "Conversion Value": "$5,640",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "490731",
            "Clicks": "5177",
            "Conversions": "69",
            "Conversion Value": "$9,247",
            "Cost per Result": "$40.75"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "188621",
            "Clicks": "6962",
            "Conversions": "57",
            "Conversion Value": "$6,240",
            "Cost per Result": "$34.61"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "123621",
            "Clicks": "2962",
            "Conversions": "62",
            "Conversion Value": "$15,467",
            "Cost per Result": "$16.94"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "247614",
            "Clicks": "4263",
            "Conversions": "90",
            "Conversion Value": "$15,983",
            "Cost per Result": "$18.87"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "173890",
            "Clicks": "4715",
            "Conversions": "45",
            "Conversion Value": "$10,514",
            "Cost per Result": "$58.44"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "393686",
            "Clicks": "5316",
            "Conversions": "52",
            "Conversion Value": "$6,684",
            "Cost per Result": "$46.46"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "418765",
            "Clicks": "2034",
            "Conversions": "67",
            "Conversion Value": "$8,557",
            "Cost per Result": "$34.59"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "131161",
            "Clicks": "4384",
            "Conversions": "88",
            "Conversion Value": "$15,596",
            "Cost per Result": "$22.75"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "356002",
            "Clicks": "5842",
            "Conversions": "64",
            "Conversion Value": "$5,905",
            "Cost per Result": "$20.36"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "374003",
            "Clicks": "6588",
            "Conversions": "50",
            "Conversion Value": "$4,713",
            "Cost per Result": "$60.12"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "479131",
            "Clicks": "6134",
            "Conversions": "73",
            "Conversion Value": "$12,596",
            "Cost per Result": "$43.07"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "160019",
            "Clicks": "6432",
            "Conversions": "71",
            "Conversion Value": "$6,488",
            "Cost per Result": "$32.37"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "216546",
            "Clicks": "6771",
            "Conversions": "66",
            "Conversion Value": "$11,022",
            "Cost per Result": "$57.66"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "389978",
            "Clicks": "4328",
            "Conversions": "60",
            "Conversion Value": "$12,201",
            "Cost per Result": "$37.75"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "177679",
            "Clicks": "6586",
            "Conversions": "77",
            "Conversion Value": "$7,494",
            "Cost per Result": "$47.41"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "438574",
            "Clicks": "2285",
            "Conversions": "54",
            "Conversion Value": "$11,451",
            "Cost per Result": "$43.18"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "326259",
            "Clicks": "5076",
            "Conversions": "40",
            "Conversion Value": "$9,092",
            "Cost per Result": "$22.97"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "331637",
            "Clicks": "2745",
            "Conversions": "65",
            "Conversion Value": "$7,006",
            "Cost per Result": "$39.96"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "344126",
            "Clicks": "4277",
            "Conversions": "79",
            "Conversion Value": "$11,787",
            "Cost per Result": "$24.76"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "449781",
            "Clicks": "6137",
            "Conversions": "75",
            "Conversion Value": "$14,586",
            "Cost per Result": "$22.71"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "345616",
            "Clicks": "3965",
            "Conversions": "47",
            "Conversion Value": "$7,617",
            "Cost per Result": "$64.24"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "167479",
            "Clicks": "6000",
            "Conversions": "58",
            "Conversion Value": "$9,933",
            "Cost per Result": "$54.10"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "319661",
            "Clicks": "6043",
            "Conversions": "51",
            "Conversion Value": "$15,130",
            "Cost per Result": "$55.82"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "364013",
            "Clicks": "3212",
            "Conversions": "54",
            "Conversion Value": "$10,513",
            "Cost per Result": "$39.27"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "463394",
            "Clicks": "2425",
            "Conversions": "61",
            "Conversion Value": "$11,319",
            "Cost per Result": "$30.50"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "475703",
            "Clicks": "2818",
            "Conversions": "60",
            "Conversion Value": "$15,149",
            "Cost per Result": "$58.72"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "257189",
            "Clicks": "3207",
            "Conversions": "66",
            "Conversion Value": "$13,156",
            "Cost per Result": "$42.04"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "355695",
            "Clicks": "5506",
            "Conversions": "51",
            "Conversion Value": "$15,535",
            "Cost per Result": "$21.03"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "428086",
            "Clicks": "5681",
            "Conversions": "65",
            "Conversion Value": "$15,366",
            "Cost per Result": "$60.37"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "357582",
            "Clicks": "2766",
            "Conversions": "74",
            "Conversion Value": "$14,495",
            "Cost per Result": "$57.45"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "448991",
            "Clicks": "2504",
            "Conversions": "49",
            "Conversion Value": "$8,523",
            "Cost per Result": "$21.22"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "383188",
            "Clicks": "2141",
            "Conversions": "77",
            "Conversion Value": "$15,569",
            "Cost per Result": "$46.44"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "298819",
            "Clicks": "2039",
            "Conversions": "70",
            "Conversion Value": "$4,526",
            "Cost per Result": "$45.52"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "184720",
            "Clicks": "5415",
            "Conversions": "75",
            "Conversion Value": "$7,640",
            "Cost per Result": "$46.65"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "118764",
            "Clicks": "6451",
            "Conversions": "41",
            "Conversion Value": "$11,058",
            "Cost per Result": "$53.25"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "170537",
            "Clicks": "4791",
            "Conversions": "67",
            "Conversion Value": "$6,141",
            "Cost per Result": "$19.75"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "378694",
            "Clicks": "2532",
            "Conversions": "84",
            "Conversion Value": "$7,414",
            "Cost per Result": "$50.11"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "346338",
            "Clicks": "5695",
            "Conversions": "65",
            "Conversion Value": "$14,385",
            "Cost per Result": "$18.55"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "332622",
            "Clicks": "5394",
            "Conversions": "62",
            "Conversion Value": "$6,902",
            "Cost per Result": "$26.45"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "175425",
            "Clicks": "5969",
            "Conversions": "70",
            "Conversion Value": "$15,006",
            "Cost per Result": "$21.61"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "192358",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$8,398",
            "Cost per Result": "$49.30"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "439743",
            "Clicks": "2646",
            "Conversions": "58",
            "Conversion Value": "$6,664",
            "Cost per Result": "$48.22"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "363653",
            "Clicks": "6894",
            "Conversions": "86",
            "Conversion Value": "$15,369",
            "Cost per Result": "$35.01"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "335878",
            "Clicks": "4908",
            "Conversions": "42",
            "Conversion Value": "$8,131",
            "Cost per Result": "$42.78"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "230896",
            "Clicks": "3825",
            "Conversions": "62",
            "Conversion Value": "$7,020",
            "Cost per Result": "$29.01"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "186541",
            "Clicks": "4896",
            "Conversions": "56",
            "Conversion Value": "$15,950",
            "Cost per Result": "$15.85"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "387280",
            "Clicks": "4768",
            "Conversions": "74",
            "Conversion Value": "$4,957",
            "Cost per Result": "$47.26"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "358968",
            "Clicks": "2156",
            "Conversions": "73",
            "Conversion Value": "$8,905",
            "Cost per Result": "$60.86"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "310985",
            "Clicks": "2481",
            "Conversions": "48",
            "Conversion Value": "$7,927",
            "Cost per Result": "$62.49"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "159148",
            "Clicks": "4205",
            "Conversions": "50",
            "Conversion Value": "$6,695",
            "Cost per Result": "$28.04"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "189681",
            "Clicks": "5149",
            "Conversions": "47",
            "Conversion Value": "$12,550",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "447950",
            "Clicks": "4996",
            "Conversions": "65",
            "Conversion Value": "$12,281",
            "Cost per Result": "$53.88"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "381580",
            "Clicks": "3120",
            "Conversions": "55",
            "Conversion Value": "$11,333",
            "Cost per Result": "$48.14"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "366564",
            "Clicks": "3570",
            "Conversions": "83",
            "Conversion Value": "$6,409",
            "Cost per Result": "$20.55"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "439156",
            "Clicks": "5149",
            "Conversions": "63",
            "Conversion Value": "$10,288",
            "Cost per Result": "$63.87"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "259998",
            "Clicks": "6280",
            "Conversions": "85",
            "Conversion Value": "$14,436",
            "Cost per Result": "$61.85"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "260217",
            "Clicks": "4742",
            "Conversions": "60",
            "Conversion Value": "$14,959",
            "Cost per Result": "$57.74"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "275359",
            "Clicks": "3476",
            "Conversions": "72",
            "Conversion Value": "$15,607",
            "Cost per Result": "$52.63"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "400366",
            "Clicks": "6286",
            "Conversions": "73",
            "Conversion Value": "$12,966",
            "Cost per Result": "$57.28"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "144887",
            "Clicks": "4305",
            "Conversions": "86",
            "Conversion Value": "$4,182",
            "Cost per Result": "$57.03"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "492352",
            "Clicks": "5128",
            "Conversions": "57",
            "Conversion Value": "$5,137",
            "Cost per Result": "$53.34"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "131276",
            "Clicks": "4067",
            "Conversions": "57",
            "Conversion Value": "$10,581",
            "Cost per Result": "$37.84"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "163988",
            "Clicks": "4643",
            "Conversions": "65",
            "Conversion Value": "$14,973",
            "Cost per Result": "$33.84"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "335679",
            "Clicks": "5903",
            "Conversions": "66",
            "Conversion Value": "$6,941",
            "Cost per Result": "$16.42"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "100991",
            "Clicks": "5784",
            "Conversions": "43",
            "Conversion Value": "$15,456",
            "Cost per Result": "$36.39"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "498779",
            "Clicks": "2202",
            "Conversions": "90",
            "Conversion Value": "$6,396",
            "Cost per Result": "$49.08"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "119368",
            "Clicks": "2856",
            "Conversions": "61",
            "Conversion Value": "$9,952",
            "Cost per Result": "$44.06"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "345663",
            "Clicks": "3989",
            "Conversions": "81",
            "Conversion Value": "$11,490",
            "Cost per Result": "$55.63"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "430194",
            "Clicks": "3396",
            "Conversions": "70",
            "Conversion Value": "$13,819",
            "Cost per Result": "$42.85"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "350348",
            "Clicks": "5589",
            "Conversions": "71",
            "Conversion Value": "$10,878",
            "Cost per Result": "$36.36"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "315354",
            "Clicks": "5402",
            "Conversions": "85",
            "Conversion Value": "$10,247",
            "Cost per Result": "$60.15"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "371542",
            "Clicks": "4484",
            "Conversions": "82",
            "Conversion Value": "$8,064",
            "Cost per Result": "$31.11"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "384824",
            "Clicks": "2790",
            "Conversions": "78",
            "Conversion Value": "$11,215",
            "Cost per Result": "$43.35"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "214290",
            "Clicks": "6441",
            "Conversions": "72",
            "Conversion Value": "$12,632",
            "Cost per Result": "$54.03"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "334573",
            "Clicks": "3353",
            "Conversions": "76",
            "Conversion Value": "$15,106",
            "Cost per Result": "$16.54"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "210999",
            "Clicks": "5604",
            "Conversions": "68",
            "Conversion Value": "$7,505",
            "Cost per Result": "$49.48"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "330328",
            "Clicks": "4526",
            "Conversions": "52",
            "Conversion Value": "$15,765",
            "Cost per Result": "$48.79"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "229250",
            "Clicks": "4410",
            "Conversions": "64",
            "Conversion Value": "$7,230",
            "Cost per Result": "$60.67"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "348981",
            "Clicks": "2690",
            "Conversions": "43",
            "Conversion Value": "$12,282",
            "Cost per Result": "$50.52"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "273530",
            "Clicks": "5247",
            "Conversions": "43",
            "Conversion Value": "$7,264",
            "Cost per Result": "$35.97"
          }
        ]
      },
      "IDOsParticipantsData": {
        "name": "IDOs participants",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "225308",
            "Clicks": "5576",
            "Conversions": "51",
            "Conversion Value": "$12,773",
            "Cost per Result": "$57.35"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490944",
            "Clicks": "4040",
            "Conversions": "53",
            "Conversion Value": "$13,674",
            "Cost per Result": "$28.88"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "409729",
            "Clicks": "3991",
            "Conversions": "50",
            "Conversion Value": "$5,314",
            "Cost per Result": "$54.81"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "399053",
            "Clicks": "3321",
            "Conversions": "58",
            "Conversion Value": "$9,608",
            "Cost per Result": "$61.19"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "148000",
            "Clicks": "3966",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$60.96"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "116623",
            "Clicks": "4377",
            "Conversions": "59",
            "Conversion Value": "$9,692",
            "Cost per Result": "$33.90"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "301271",
            "Clicks": "2328",
            "Conversions": "58",
            "Conversion Value": "$14,634",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "169921",
            "Clicks": "2277",
            "Conversions": "88",
            "Conversion Value": "$12,055",
            "Cost per Result": "$54.66"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "205455",
            "Clicks": "4590",
            "Conversions": "49",
            "Conversion Value": "$13,491",
            "Cost per Result": "$55.79"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "205595",
            "Clicks": "5334",
            "Conversions": "82",
            "Conversion Value": "$10,788",
            "Cost per Result": "$29.93"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "228198",
            "Clicks": "6548",
            "Conversions": "89",
            "Conversion Value": "$12,078",
            "Cost per Result": "$45.32"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "264256",
            "Clicks": "4536",
            "Conversions": "88",
            "Conversion Value": "$4,424",
            "Cost per Result": "$46.17"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "279468",
            "Clicks": "6741",
            "Conversions": "61",
            "Conversion Value": "$5,120",
            "Cost per Result": "$23.29"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "167406",
            "Clicks": "5555",
            "Conversions": "56",
            "Conversion Value": "$9,358",
            "Cost per Result": "$20.44"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "406312",
            "Clicks": "4696",
            "Conversions": "72",
            "Conversion Value": "$13,458",
            "Cost per Result": "$58.02"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "286974",
            "Clicks": "6572",
            "Conversions": "58",
            "Conversion Value": "$11,326",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "217467",
            "Clicks": "3618",
            "Conversions": "76",
            "Conversion Value": "$9,331",
            "Cost per Result": "$16.26"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "358922",
            "Clicks": "4596",
            "Conversions": "85",
            "Conversion Value": "$4,858",
            "Cost per Result": "$34.06"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "148593",
            "Clicks": "5337",
            "Conversions": "87",
            "Conversion Value": "$10,179",
            "Cost per Result": "$19.10"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "249426",
            "Clicks": "2861",
            "Conversions": "71",
            "Conversion Value": "$15,493",
            "Cost per Result": "$52.70"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "111491",
            "Clicks": "5023",
            "Conversions": "59",
            "Conversion Value": "$13,085",
            "Cost per Result": "$46.45"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "329661",
            "Clicks": "4762",
            "Conversions": "87",
            "Conversion Value": "$15,765",
            "Cost per Result": "$30.71"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "269594",
            "Clicks": "4501",
            "Conversions": "69",
            "Conversion Value": "$15,344",
            "Cost per Result": "$42.86"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "240234",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$14,913",
            "Cost per Result": "$39.60"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "427326",
            "Clicks": "4495",
            "Conversions": "79",
            "Conversion Value": "$13,638",
            "Cost per Result": "$29.00"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "137002",
            "Clicks": "4298",
            "Conversions": "87",
            "Conversion Value": "$13,903",
            "Cost per Result": "$33.82"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "274045",
            "Clicks": "4617",
            "Conversions": "65",
            "Conversion Value": "$6,479",
            "Cost per Result": "$35.54"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "445786",
            "Clicks": "2914",
            "Conversions": "47",
            "Conversion Value": "$14,055",
            "Cost per Result": "$61.29"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "426620",
            "Clicks": "2195",
            "Conversions": "81",
            "Conversion Value": "$6,478",
            "Cost per Result": "$41.90"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "485116",
            "Clicks": "2347",
            "Conversions": "66",
            "Conversion Value": "$10,151",
            "Cost per Result": "$62.91"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "392252",
            "Clicks": "4743",
            "Conversions": "74",
            "Conversion Value": "$5,870",
            "Cost per Result": "$51.70"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "245584",
            "Clicks": "4729",
            "Conversions": "42",
            "Conversion Value": "$10,568",
            "Cost per Result": "$52.15"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "229926",
            "Clicks": "6069",
            "Conversions": "40",
            "Conversion Value": "$15,136",
            "Cost per Result": "$57.28"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "274703",
            "Clicks": "3259",
            "Conversions": "65",
            "Conversion Value": "$15,505",
            "Cost per Result": "$27.64"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "211153",
            "Clicks": "6159",
            "Conversions": "73",
            "Conversion Value": "$13,467",
            "Cost per Result": "$39.28"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "372039",
            "Clicks": "2409",
            "Conversions": "47",
            "Conversion Value": "$5,733",
            "Cost per Result": "$44.82"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "436122",
            "Clicks": "3316",
            "Conversions": "61",
            "Conversion Value": "$7,836",
            "Cost per Result": "$45.95"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "346319",
            "Clicks": "4624",
            "Conversions": "85",
            "Conversion Value": "$7,275",
            "Cost per Result": "$38.84"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "398780",
            "Clicks": "2918",
            "Conversions": "73",
            "Conversion Value": "$15,296",
            "Cost per Result": "$22.93"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "151266",
            "Clicks": "4634",
            "Conversions": "45",
            "Conversion Value": "$14,669",
            "Cost per Result": "$30.00"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "243794",
            "Clicks": "2041",
            "Conversions": "81",
            "Conversion Value": "$5,299",
            "Cost per Result": "$45.09"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "158250",
            "Clicks": "4646",
            "Conversions": "71",
            "Conversion Value": "$14,888",
            "Cost per Result": "$43.76"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "231132",
            "Clicks": "3276",
            "Conversions": "78",
            "Conversion Value": "$8,033",
            "Cost per Result": "$30.41"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "230934",
            "Clicks": "3020",
            "Conversions": "52",
            "Conversion Value": "$4,924",
            "Cost per Result": "$53.72"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "112748",
            "Clicks": "3977",
            "Conversions": "46",
            "Conversion Value": "$11,202",
            "Cost per Result": "$64.20"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "260642",
            "Clicks": "5299",
            "Conversions": "60",
            "Conversion Value": "$15,289",
            "Cost per Result": "$53.68"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "104385",
            "Clicks": "4997",
            "Conversions": "40",
            "Conversion Value": "$12,068",
            "Cost per Result": "$43.48"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "353096",
            "Clicks": "5637",
            "Conversions": "49",
            "Conversion Value": "$9,590",
            "Cost per Result": "$30.65"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "158840",
            "Clicks": "3596",
            "Conversions": "54",
            "Conversion Value": "$15,903",
            "Cost per Result": "$34.17"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "110742",
            "Clicks": "3015",
            "Conversions": "71",
            "Conversion Value": "$6,429",
            "Cost per Result": "$48.76"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "151585",
            "Clicks": "5416",
            "Conversions": "75",
            "Conversion Value": "$6,910",
            "Cost per Result": "$50.36"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "444463",
            "Clicks": "3273",
            "Conversions": "81",
            "Conversion Value": "$11,423",
            "Cost per Result": "$42.59"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "292951",
            "Clicks": "6505",
            "Conversions": "51",
            "Conversion Value": "$15,015",
            "Cost per Result": "$29.56"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "419873",
            "Clicks": "3577",
            "Conversions": "65",
            "Conversion Value": "$8,581",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "281894",
            "Clicks": "5810",
            "Conversions": "63",
            "Conversion Value": "$10,222",
            "Cost per Result": "$44.22"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "373471",
            "Clicks": "5776",
            "Conversions": "77",
            "Conversion Value": "$13,733",
            "Cost per Result": "$64.64"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "194989",
            "Clicks": "5997",
            "Conversions": "72",
            "Conversion Value": "$7,229",
            "Cost per Result": "$64.61"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "230901",
            "Clicks": "2206",
            "Conversions": "90",
            "Conversion Value": "$11,433",
            "Cost per Result": "$54.28"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "221728",
            "Clicks": "4038",
            "Conversions": "55",
            "Conversion Value": "$6,968",
            "Cost per Result": "$48.48"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "363000",
            "Clicks": "4352",
            "Conversions": "41",
            "Conversion Value": "$13,890",
            "Cost per Result": "$17.51"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "156364",
            "Clicks": "5798",
            "Conversions": "52",
            "Conversion Value": "$14,518",
            "Cost per Result": "$29.71"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "495714",
            "Clicks": "3866",
            "Conversions": "41",
            "Conversion Value": "$7,966",
            "Cost per Result": "$62.93"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "460986",
            "Clicks": "4667",
            "Conversions": "73",
            "Conversion Value": "$14,410",
            "Cost per Result": "$57.26"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "249487",
            "Clicks": "6258",
            "Conversions": "42",
            "Conversion Value": "$4,325",
            "Cost per Result": "$33.58"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "429485",
            "Clicks": "4550",
            "Conversions": "68",
            "Conversion Value": "$14,437",
            "Cost per Result": "$45.98"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "455394",
            "Clicks": "2158",
            "Conversions": "72",
            "Conversion Value": "$6,059",
            "Cost per Result": "$47.85"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "393859",
            "Clicks": "6760",
            "Conversions": "61",
            "Conversion Value": "$9,641",
            "Cost per Result": "$57.12"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "343723",
            "Clicks": "5944",
            "Conversions": "74",
            "Conversion Value": "$11,758",
            "Cost per Result": "$45.63"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "190384",
            "Clicks": "3335",
            "Conversions": "70",
            "Conversion Value": "$8,698",
            "Cost per Result": "$21.92"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "221492",
            "Clicks": "3283",
            "Conversions": "84",
            "Conversion Value": "$5,558",
            "Cost per Result": "$37.76"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "392605",
            "Clicks": "6326",
            "Conversions": "46",
            "Conversion Value": "$5,275",
            "Cost per Result": "$43.60"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "375472",
            "Clicks": "6323",
            "Conversions": "52",
            "Conversion Value": "$4,105",
            "Cost per Result": "$62.28"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "350680",
            "Clicks": "6596",
            "Conversions": "49",
            "Conversion Value": "$14,558",
            "Cost per Result": "$48.61"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "215013",
            "Clicks": "3969",
            "Conversions": "62",
            "Conversion Value": "$12,246",
            "Cost per Result": "$36.30"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "463903",
            "Clicks": "2972",
            "Conversions": "54",
            "Conversion Value": "$15,702",
            "Cost per Result": "$64.88"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "293595",
            "Clicks": "5507",
            "Conversions": "65",
            "Conversion Value": "$14,215",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "447628",
            "Clicks": "4128",
            "Conversions": "76",
            "Conversion Value": "$7,719",
            "Cost per Result": "$16.03"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "340403",
            "Clicks": "4406",
            "Conversions": "48",
            "Conversion Value": "$14,706",
            "Cost per Result": "$49.41"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "311341",
            "Clicks": "3638",
            "Conversions": "83",
            "Conversion Value": "$14,928",
            "Cost per Result": "$62.79"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "345252",
            "Clicks": "3067",
            "Conversions": "68",
            "Conversion Value": "$9,980",
            "Cost per Result": "$60.07"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "296129",
            "Clicks": "2442",
            "Conversions": "77",
            "Conversion Value": "$11,616",
            "Cost per Result": "$26.59"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "155060",
            "Clicks": "3754",
            "Conversions": "44",
            "Conversion Value": "$15,491",
            "Cost per Result": "$52.69"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "463829",
            "Clicks": "5433",
            "Conversions": "86",
            "Conversion Value": "$11,612",
            "Cost per Result": "$40.89"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "272238",
            "Clicks": "5578",
            "Conversions": "77",
            "Conversion Value": "$13,830",
            "Cost per Result": "$23.53"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "404113",
            "Clicks": "2673",
            "Conversions": "82",
            "Conversion Value": "$5,839",
            "Cost per Result": "$18.73"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "240589",
            "Clicks": "6948",
            "Conversions": "86",
            "Conversion Value": "$12,339",
            "Cost per Result": "$46.25"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "428596",
            "Clicks": "4499",
            "Conversions": "77",
            "Conversion Value": "$9,239",
            "Cost per Result": "$17.97"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "239459",
            "Clicks": "3968",
            "Conversions": "46",
            "Conversion Value": "$8,794",
            "Cost per Result": "$59.45"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "132800",
            "Clicks": "4396",
            "Conversions": "54",
            "Conversion Value": "$12,347",
            "Cost per Result": "$47.23"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "490202",
            "Clicks": "2133",
            "Conversions": "70",
            "Conversion Value": "$12,351",
            "Cost per Result": "$40.35"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "388772",
            "Clicks": "3514",
            "Conversions": "77",
            "Conversion Value": "$7,703",
            "Cost per Result": "$24.93"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "450004",
            "Clicks": "5019",
            "Conversions": "42",
            "Conversion Value": "$7,481",
            "Cost per Result": "$39.79"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "425949",
            "Clicks": "5164",
            "Conversions": "83",
            "Conversion Value": "$7,808",
            "Cost per Result": "$44.83"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "297381",
            "Clicks": "3312",
            "Conversions": "74",
            "Conversion Value": "$12,090",
            "Cost per Result": "$18.97"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "189343",
            "Clicks": "6907",
            "Conversions": "74",
            "Conversion Value": "$4,704",
            "Cost per Result": "$23.02"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "303202",
            "Clicks": "6122",
            "Conversions": "62",
            "Conversion Value": "$4,303",
            "Cost per Result": "$42.17"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "237208",
            "Clicks": "4302",
            "Conversions": "78",
            "Conversion Value": "$12,006",
            "Cost per Result": "$63.62"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "264158",
            "Clicks": "2399",
            "Conversions": "85",
            "Conversion Value": "$13,527",
            "Cost per Result": "$39.93"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "441641",
            "Clicks": "5119",
            "Conversions": "69",
            "Conversion Value": "$11,394",
            "Cost per Result": "$61.08"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "216566",
            "Clicks": "6313",
            "Conversions": "50",
            "Conversion Value": "$8,151",
            "Cost per Result": "$37.63"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "152369",
            "Clicks": "6528",
            "Conversions": "42",
            "Conversion Value": "$7,708",
            "Cost per Result": "$63.56"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "242990",
            "Clicks": "4218",
            "Conversions": "85",
            "Conversion Value": "$5,543",
            "Cost per Result": "$49.65"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "325308",
            "Clicks": "2279",
            "Conversions": "64",
            "Conversion Value": "$10,593",
            "Cost per Result": "$50.23"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "259394",
            "Clicks": "2445",
            "Conversions": "90",
            "Conversion Value": "$6,457",
            "Cost per Result": "$25.78"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "333287",
            "Clicks": "6258",
            "Conversions": "60",
            "Conversion Value": "$4,745",
            "Cost per Result": "$29.90"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "284502",
            "Clicks": "6695",
            "Conversions": "64",
            "Conversion Value": "$11,375",
            "Cost per Result": "$17.87"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "278064",
            "Clicks": "5467",
            "Conversions": "90",
            "Conversion Value": "$13,712",
            "Cost per Result": "$64.85"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "335329",
            "Clicks": "2295",
            "Conversions": "48",
            "Conversion Value": "$6,229",
            "Cost per Result": "$48.49"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "402901",
            "Clicks": "2039",
            "Conversions": "41",
            "Conversion Value": "$14,244",
            "Cost per Result": "$60.77"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "183996",
            "Clicks": "6814",
            "Conversions": "80",
            "Conversion Value": "$15,983",
            "Cost per Result": "$32.44"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "437367",
            "Clicks": "3331",
            "Conversions": "57",
            "Conversion Value": "$14,128",
            "Cost per Result": "$29.97"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "403077",
            "Clicks": "6347",
            "Conversions": "70",
            "Conversion Value": "$9,113",
            "Cost per Result": "$45.88"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "245817",
            "Clicks": "2404",
            "Conversions": "62",
            "Conversion Value": "$12,061",
            "Cost per Result": "$21.15"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "202892",
            "Clicks": "5759",
            "Conversions": "40",
            "Conversion Value": "$7,717",
            "Cost per Result": "$37.44"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "234448",
            "Clicks": "2222",
            "Conversions": "45",
            "Conversion Value": "$10,319",
            "Cost per Result": "$26.36"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "138975",
            "Clicks": "6952",
            "Conversions": "55",
            "Conversion Value": "$9,917",
            "Cost per Result": "$34.03"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "116499",
            "Clicks": "4583",
            "Conversions": "85",
            "Conversion Value": "$15,447",
            "Cost per Result": "$60.00"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "186911",
            "Clicks": "4008",
            "Conversions": "89",
            "Conversion Value": "$7,988",
            "Cost per Result": "$48.24"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "139979",
            "Clicks": "4134",
            "Conversions": "87",
            "Conversion Value": "$13,437",
            "Cost per Result": "$36.22"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "322026",
            "Clicks": "2617",
            "Conversions": "89",
            "Conversion Value": "$9,287",
            "Cost per Result": "$31.92"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "327218",
            "Clicks": "5957",
            "Conversions": "51",
            "Conversion Value": "$5,756",
            "Cost per Result": "$32.11"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "311060",
            "Clicks": "4063",
            "Conversions": "54",
            "Conversion Value": "$8,818",
            "Cost per Result": "$35.67"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "421991",
            "Clicks": "2699",
            "Conversions": "87",
            "Conversion Value": "$9,310",
            "Cost per Result": "$64.68"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "261472",
            "Clicks": "6155",
            "Conversions": "42",
            "Conversion Value": "$9,340",
            "Cost per Result": "$46.30"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "330447",
            "Clicks": "4804",
            "Conversions": "49",
            "Conversion Value": "$6,796",
            "Cost per Result": "$58.20"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "452157",
            "Clicks": "4927",
            "Conversions": "60",
            "Conversion Value": "$11,236",
            "Cost per Result": "$37.90"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "300476",
            "Clicks": "6206",
            "Conversions": "44",
            "Conversion Value": "$9,990",
            "Cost per Result": "$54.00"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "388970",
            "Clicks": "3549",
            "Conversions": "89",
            "Conversion Value": "$4,039",
            "Cost per Result": "$37.11"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "215511",
            "Clicks": "6890",
            "Conversions": "65",
            "Conversion Value": "$12,956",
            "Cost per Result": "$41.86"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "322846",
            "Clicks": "3394",
            "Conversions": "90",
            "Conversion Value": "$5,361",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "469442",
            "Clicks": "5099",
            "Conversions": "62",
            "Conversion Value": "$4,988",
            "Cost per Result": "$60.66"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "301822",
            "Clicks": "5477",
            "Conversions": "51",
            "Conversion Value": "$11,999",
            "Cost per Result": "$32.90"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "106840",
            "Clicks": "2736",
            "Conversions": "83",
            "Conversion Value": "$5,728",
            "Cost per Result": "$44.24"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "196750",
            "Clicks": "2712",
            "Conversions": "41",
            "Conversion Value": "$15,885",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "206002",
            "Clicks": "5711",
            "Conversions": "86",
            "Conversion Value": "$7,278",
            "Cost per Result": "$37.71"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "470725",
            "Clicks": "5537",
            "Conversions": "80",
            "Conversion Value": "$12,126",
            "Cost per Result": "$44.91"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "281280",
            "Clicks": "6926",
            "Conversions": "66",
            "Conversion Value": "$9,611",
            "Cost per Result": "$43.82"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "312620",
            "Clicks": "4218",
            "Conversions": "52",
            "Conversion Value": "$6,311",
            "Cost per Result": "$58.63"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "397433",
            "Clicks": "2025",
            "Conversions": "51",
            "Conversion Value": "$7,868",
            "Cost per Result": "$58.58"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "114913",
            "Clicks": "3453",
            "Conversions": "73",
            "Conversion Value": "$12,141",
            "Cost per Result": "$41.32"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "214473",
            "Clicks": "5642",
            "Conversions": "75",
            "Conversion Value": "$14,519",
            "Cost per Result": "$30.78"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "266027",
            "Clicks": "2078",
            "Conversions": "60",
            "Conversion Value": "$15,874",
            "Cost per Result": "$52.47"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "190937",
            "Clicks": "3143",
            "Conversions": "70",
            "Conversion Value": "$5,454",
            "Cost per Result": "$31.61"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "399564",
            "Clicks": "2655",
            "Conversions": "59",
            "Conversion Value": "$14,506",
            "Cost per Result": "$37.82"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "221426",
            "Clicks": "6753",
            "Conversions": "88",
            "Conversion Value": "$4,010",
            "Cost per Result": "$52.94"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "280377",
            "Clicks": "5370",
            "Conversions": "81",
            "Conversion Value": "$6,507",
            "Cost per Result": "$59.00"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "407192",
            "Clicks": "5722",
            "Conversions": "47",
            "Conversion Value": "$8,143",
            "Cost per Result": "$38.86"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "324735",
            "Clicks": "4921",
            "Conversions": "89",
            "Conversion Value": "$11,677",
            "Cost per Result": "$39.08"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "473920",
            "Clicks": "3136",
            "Conversions": "83",
            "Conversion Value": "$4,533",
            "Cost per Result": "$42.92"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "194693",
            "Clicks": "5348",
            "Conversions": "89",
            "Conversion Value": "$15,521",
            "Cost per Result": "$31.04"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "157598",
            "Clicks": "3069",
            "Conversions": "52",
            "Conversion Value": "$8,676",
            "Cost per Result": "$36.20"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "452124",
            "Clicks": "4983",
            "Conversions": "48",
            "Conversion Value": "$10,818",
            "Cost per Result": "$26.14"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "157539",
            "Clicks": "4619",
            "Conversions": "89",
            "Conversion Value": "$6,762",
            "Cost per Result": "$64.48"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "113835",
            "Clicks": "3902",
            "Conversions": "54",
            "Conversion Value": "$10,849",
            "Cost per Result": "$25.45"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "266441",
            "Clicks": "5784",
            "Conversions": "62",
            "Conversion Value": "$4,835",
            "Cost per Result": "$27.16"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "155309",
            "Clicks": "5321",
            "Conversions": "77",
            "Conversion Value": "$9,257",
            "Cost per Result": "$28.67"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "373645",
            "Clicks": "6857",
            "Conversions": "55",
            "Conversion Value": "$9,819",
            "Cost per Result": "$18.88"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "137541",
            "Clicks": "6879",
            "Conversions": "46",
            "Conversion Value": "$9,766",
            "Cost per Result": "$18.35"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "123045",
            "Clicks": "2781",
            "Conversions": "80",
            "Conversion Value": "$4,882",
            "Cost per Result": "$16.74"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "181179",
            "Clicks": "5664",
            "Conversions": "54",
            "Conversion Value": "$7,951",
            "Cost per Result": "$47.05"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "496701",
            "Clicks": "6715",
            "Conversions": "85",
            "Conversion Value": "$6,738",
            "Cost per Result": "$51.38"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "217839",
            "Clicks": "3152",
            "Conversions": "83",
            "Conversion Value": "$12,936",
            "Cost per Result": "$44.52"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "275305",
            "Clicks": "6292",
            "Conversions": "71",
            "Conversion Value": "$7,886",
            "Cost per Result": "$17.18"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "374624",
            "Clicks": "3084",
            "Conversions": "51",
            "Conversion Value": "$9,099",
            "Cost per Result": "$49.00"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "264952",
            "Clicks": "3227",
            "Conversions": "66",
            "Conversion Value": "$13,026",
            "Cost per Result": "$41.37"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "190127",
            "Clicks": "2096",
            "Conversions": "42",
            "Conversion Value": "$11,422",
            "Cost per Result": "$61.68"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "224797",
            "Clicks": "4979",
            "Conversions": "53",
            "Conversion Value": "$12,743",
            "Cost per Result": "$62.89"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "445574",
            "Clicks": "6963",
            "Conversions": "43",
            "Conversion Value": "$11,791",
            "Cost per Result": "$56.91"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "112520",
            "Clicks": "6014",
            "Conversions": "55",
            "Conversion Value": "$15,474",
            "Cost per Result": "$49.59"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "381388",
            "Clicks": "3344",
            "Conversions": "69",
            "Conversion Value": "$9,019",
            "Cost per Result": "$42.70"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "295042",
            "Clicks": "5607",
            "Conversions": "49",
            "Conversion Value": "$7,397",
            "Cost per Result": "$47.25"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "446900",
            "Clicks": "3485",
            "Conversions": "59",
            "Conversion Value": "$8,192",
            "Cost per Result": "$17.40"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "143563",
            "Clicks": "4099",
            "Conversions": "61",
            "Conversion Value": "$5,644",
            "Cost per Result": "$29.85"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "119724",
            "Clicks": "3561",
            "Conversions": "71",
            "Conversion Value": "$9,788",
            "Cost per Result": "$58.51"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "118266",
            "Clicks": "5796",
            "Conversions": "68",
            "Conversion Value": "$5,510",
            "Cost per Result": "$20.46"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "383640",
            "Clicks": "6827",
            "Conversions": "47",
            "Conversion Value": "$12,279",
            "Cost per Result": "$52.10"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "104223",
            "Clicks": "2469",
            "Conversions": "73",
            "Conversion Value": "$5,487",
            "Cost per Result": "$43.20"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "152599",
            "Clicks": "5290",
            "Conversions": "87",
            "Conversion Value": "$11,125",
            "Cost per Result": "$32.46"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "222389",
            "Clicks": "3289",
            "Conversions": "88",
            "Conversion Value": "$15,273",
            "Cost per Result": "$33.37"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "288489",
            "Clicks": "3595",
            "Conversions": "43",
            "Conversion Value": "$7,482",
            "Cost per Result": "$28.82"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "325669",
            "Clicks": "6297",
            "Conversions": "82",
            "Conversion Value": "$7,543",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "226873",
            "Clicks": "2761",
            "Conversions": "69",
            "Conversion Value": "$12,002",
            "Cost per Result": "$22.65"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "198736",
            "Clicks": "5790",
            "Conversions": "48",
            "Conversion Value": "$4,510",
            "Cost per Result": "$29.44"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "375412",
            "Clicks": "3692",
            "Conversions": "60",
            "Conversion Value": "$14,877",
            "Cost per Result": "$16.34"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "491610",
            "Clicks": "3736",
            "Conversions": "58",
            "Conversion Value": "$15,027",
            "Cost per Result": "$23.95"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "491919",
            "Clicks": "4018",
            "Conversions": "43",
            "Conversion Value": "$14,208",
            "Cost per Result": "$21.88"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "192367",
            "Clicks": "4875",
            "Conversions": "88",
            "Conversion Value": "$11,493",
            "Cost per Result": "$23.62"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "322729",
            "Clicks": "4199",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$41.22"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "426244",
            "Clicks": "2361",
            "Conversions": "74",
            "Conversion Value": "$13,783",
            "Cost per Result": "$56.20"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "157827",
            "Clicks": "2580",
            "Conversions": "47",
            "Conversion Value": "$9,459",
            "Cost per Result": "$56.50"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "380683",
            "Clicks": "2740",
            "Conversions": "68",
            "Conversion Value": "$14,002",
            "Cost per Result": "$64.21"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "138943",
            "Clicks": "3555",
            "Conversions": "84",
            "Conversion Value": "$6,547",
            "Cost per Result": "$54.68"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "103680",
            "Clicks": "6819",
            "Conversions": "57",
            "Conversion Value": "$10,707",
            "Cost per Result": "$61.86"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "173883",
            "Clicks": "5376",
            "Conversions": "66",
            "Conversion Value": "$7,198",
            "Cost per Result": "$64.08"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "492331",
            "Clicks": "6467",
            "Conversions": "60",
            "Conversion Value": "$14,251",
            "Cost per Result": "$51.52"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "429054",
            "Clicks": "5827",
            "Conversions": "81",
            "Conversion Value": "$9,046",
            "Cost per Result": "$38.94"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "347444",
            "Clicks": "6028",
            "Conversions": "62",
            "Conversion Value": "$7,701",
            "Cost per Result": "$54.21"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188223",
            "Clicks": "6629",
            "Conversions": "90",
            "Conversion Value": "$14,699",
            "Cost per Result": "$37.17"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "267261",
            "Clicks": "6352",
            "Conversions": "47",
            "Conversion Value": "$12,150",
            "Cost per Result": "$34.94"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "406044",
            "Clicks": "4550",
            "Conversions": "57",
            "Conversion Value": "$11,368",
            "Cost per Result": "$37.10"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "418762",
            "Clicks": "2317",
            "Conversions": "60",
            "Conversion Value": "$14,537",
            "Cost per Result": "$36.46"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "107772",
            "Clicks": "4944",
            "Conversions": "81",
            "Conversion Value": "$12,793",
            "Cost per Result": "$34.81"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "470088",
            "Clicks": "2373",
            "Conversions": "47",
            "Conversion Value": "$4,187",
            "Cost per Result": "$28.26"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "481683",
            "Clicks": "2260",
            "Conversions": "53",
            "Conversion Value": "$10,546",
            "Cost per Result": "$27.94"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "284903",
            "Clicks": "2210",
            "Conversions": "64",
            "Conversion Value": "$14,258",
            "Cost per Result": "$16.53"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "398274",
            "Clicks": "2869",
            "Conversions": "63",
            "Conversion Value": "$5,070",
            "Cost per Result": "$35.93"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "242762",
            "Clicks": "6197",
            "Conversions": "43",
            "Conversion Value": "$11,839",
            "Cost per Result": "$21.50"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "322748",
            "Clicks": "5544",
            "Conversions": "87",
            "Conversion Value": "$9,883",
            "Cost per Result": "$37.17"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "213924",
            "Clicks": "4173",
            "Conversions": "75",
            "Conversion Value": "$8,814",
            "Cost per Result": "$40.67"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "447422",
            "Clicks": "4805",
            "Conversions": "52",
            "Conversion Value": "$11,861",
            "Cost per Result": "$39.38"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "218895",
            "Clicks": "5279",
            "Conversions": "68",
            "Conversion Value": "$11,967",
            "Cost per Result": "$26.67"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "473201",
            "Clicks": "6153",
            "Conversions": "70",
            "Conversion Value": "$12,675",
            "Cost per Result": "$29.72"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "459737",
            "Clicks": "2550",
            "Conversions": "45",
            "Conversion Value": "$14,597",
            "Cost per Result": "$41.72"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "459800",
            "Clicks": "5248",
            "Conversions": "89",
            "Conversion Value": "$8,590",
            "Cost per Result": "$53.25"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "320856",
            "Clicks": "5523",
            "Conversions": "58",
            "Conversion Value": "$4,056",
            "Cost per Result": "$23.55"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "419833",
            "Clicks": "6502",
            "Conversions": "48",
            "Conversion Value": "$15,300",
            "Cost per Result": "$63.41"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "381522",
            "Clicks": "4075",
            "Conversions": "60",
            "Conversion Value": "$11,373",
            "Cost per Result": "$31.47"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "260556",
            "Clicks": "5014",
            "Conversions": "50",
            "Conversion Value": "$7,317",
            "Cost per Result": "$62.04"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "122159",
            "Clicks": "4630",
            "Conversions": "84",
            "Conversion Value": "$9,410",
            "Cost per Result": "$21.56"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "318331",
            "Clicks": "2637",
            "Conversions": "90",
            "Conversion Value": "$5,867",
            "Cost per Result": "$52.81"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "184061",
            "Clicks": "3787",
            "Conversions": "52",
            "Conversion Value": "$13,850",
            "Cost per Result": "$44.45"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "455424",
            "Clicks": "3255",
            "Conversions": "63",
            "Conversion Value": "$6,294",
            "Cost per Result": "$64.23"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "481435",
            "Clicks": "4743",
            "Conversions": "85",
            "Conversion Value": "$7,593",
            "Cost per Result": "$23.10"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "298677",
            "Clicks": "4618",
            "Conversions": "55",
            "Conversion Value": "$14,644",
            "Cost per Result": "$43.85"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "370652",
            "Clicks": "5943",
            "Conversions": "53",
            "Conversion Value": "$8,671",
            "Cost per Result": "$64.49"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "427915",
            "Clicks": "2802",
            "Conversions": "53",
            "Conversion Value": "$5,319",
            "Cost per Result": "$34.15"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "240623",
            "Clicks": "5264",
            "Conversions": "56",
            "Conversion Value": "$15,436",
            "Cost per Result": "$64.21"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "333227",
            "Clicks": "3055",
            "Conversions": "46",
            "Conversion Value": "$7,324",
            "Cost per Result": "$33.58"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "367852",
            "Clicks": "2508",
            "Conversions": "42",
            "Conversion Value": "$5,128",
            "Cost per Result": "$34.18"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "450217",
            "Clicks": "5614",
            "Conversions": "43",
            "Conversion Value": "$12,497",
            "Cost per Result": "$31.56"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "328867",
            "Clicks": "4728",
            "Conversions": "67",
            "Conversion Value": "$7,787",
            "Cost per Result": "$18.43"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "277809",
            "Clicks": "6491",
            "Conversions": "81",
            "Conversion Value": "$13,979",
            "Cost per Result": "$36.70"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "211361",
            "Clicks": "2132",
            "Conversions": "71",
            "Conversion Value": "$13,195",
            "Cost per Result": "$61.87"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "142508",
            "Clicks": "3862",
            "Conversions": "52",
            "Conversion Value": "$6,656",
            "Cost per Result": "$59.48"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "424888",
            "Clicks": "4450",
            "Conversions": "78",
            "Conversion Value": "$15,042",
            "Cost per Result": "$30.99"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "285587",
            "Clicks": "2182",
            "Conversions": "61",
            "Conversion Value": "$5,117",
            "Cost per Result": "$50.51"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "474948",
            "Clicks": "6056",
            "Conversions": "59",
            "Conversion Value": "$7,716",
            "Cost per Result": "$50.18"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "236141",
            "Clicks": "5541",
            "Conversions": "61",
            "Conversion Value": "$13,278",
            "Cost per Result": "$55.84"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "404478",
            "Clicks": "6238",
            "Conversions": "50",
            "Conversion Value": "$14,042",
            "Cost per Result": "$50.63"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "345422",
            "Clicks": "2804",
            "Conversions": "59",
            "Conversion Value": "$9,804",
            "Cost per Result": "$52.20"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "179394",
            "Clicks": "4301",
            "Conversions": "45",
            "Conversion Value": "$10,676",
            "Cost per Result": "$25.29"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "279309",
            "Clicks": "6253",
            "Conversions": "41",
            "Conversion Value": "$8,182",
            "Cost per Result": "$47.48"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "136053",
            "Clicks": "6858",
            "Conversions": "54",
            "Conversion Value": "$12,552",
            "Cost per Result": "$48.77"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "210906",
            "Clicks": "3579",
            "Conversions": "51",
            "Conversion Value": "$15,154",
            "Cost per Result": "$28.27"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "236006",
            "Clicks": "5617",
            "Conversions": "63",
            "Conversion Value": "$12,426",
            "Cost per Result": "$46.16"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "369562",
            "Clicks": "6265",
            "Conversions": "57",
            "Conversion Value": "$10,571",
            "Cost per Result": "$45.29"
          }
        ]
      },
      "BTCETHWhalesData": {
        "name": "BTC/ETH whales",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "395452",
            "Clicks": "2253",
            "Conversions": "70",
            "Volume": "$4,572",
            "Cost per Result": "$56.42"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "357851",
            "Clicks": "5514",
            "Conversions": "44",
            "Volume": "$9,585",
            "Cost per Result": "$33.87"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "286451",
            "Clicks": "4888",
            "Conversions": "67",
            "Volume": "$13,894",
            "Cost per Result": "$18.61"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "138563",
            "Clicks": "2149",
            "Conversions": "62",
            "Volume": "$13,357",
            "Cost per Result": "$18.25"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "479103",
            "Clicks": "2451",
            "Conversions": "64",
            "Volume": "$8,350",
            "Cost per Result": "$61.82"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "353401",
            "Clicks": "5546",
            "Conversions": "72",
            "Volume": "$15,724",
            "Cost per Result": "$61.09"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "170738",
            "Clicks": "5081",
            "Conversions": "75",
            "Volume": "$7,247",
            "Cost per Result": "$30.13"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "440785",
            "Clicks": "5624",
            "Conversions": "70",
            "Volume": "$10,038",
            "Cost per Result": "$49.87"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "104881",
            "Clicks": "3718",
            "Conversions": "76",
            "Volume": "$14,551",
            "Cost per Result": "$43.74"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "119330",
            "Clicks": "2668",
            "Conversions": "80",
            "Volume": "$14,879",
            "Cost per Result": "$59.23"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "381894",
            "Clicks": "3548",
            "Conversions": "74",
            "Volume": "$15,868",
            "Cost per Result": "$57.02"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "378317",
            "Clicks": "3502",
            "Conversions": "75",
            "Volume": "$11,386",
            "Cost per Result": "$55.02"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "349528",
            "Clicks": "6477",
            "Conversions": "73",
            "Volume": "$12,757",
            "Cost per Result": "$18.15"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "313198",
            "Clicks": "4676",
            "Conversions": "48",
            "Volume": "$11,487",
            "Cost per Result": "$46.58"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "312758",
            "Clicks": "3610",
            "Conversions": "79",
            "Volume": "$11,127",
            "Cost per Result": "$27.69"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "212110",
            "Clicks": "3812",
            "Conversions": "80",
            "Volume": "$4,943",
            "Cost per Result": "$16.76"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "347775",
            "Clicks": "4952",
            "Conversions": "56",
            "Volume": "$10,661",
            "Cost per Result": "$48.77"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "130535",
            "Clicks": "4915",
            "Conversions": "47",
            "Volume": "$5,328",
            "Cost per Result": "$32.96"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "211426",
            "Clicks": "3151",
            "Conversions": "82",
            "Volume": "$9,529",
            "Cost per Result": "$52.03"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "125674",
            "Clicks": "3355",
            "Conversions": "56",
            "Volume": "$13,972",
            "Cost per Result": "$42.61"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "197229",
            "Clicks": "3391",
            "Conversions": "58",
            "Volume": "$13,361",
            "Cost per Result": "$15.12"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "380509",
            "Clicks": "3370",
            "Conversions": "73",
            "Volume": "$5,196",
            "Cost per Result": "$34.57"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "376175",
            "Clicks": "5807",
            "Conversions": "64",
            "Volume": "$6,191",
            "Cost per Result": "$49.33"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "281779",
            "Clicks": "4122",
            "Conversions": "62",
            "Volume": "$6,302",
            "Cost per Result": "$37.10"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "229987",
            "Clicks": "5682",
            "Conversions": "75",
            "Volume": "$5,970",
            "Cost per Result": "$45.67"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "309528",
            "Clicks": "6056",
            "Conversions": "50",
            "Volume": "$11,112",
            "Cost per Result": "$57.87"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "272674",
            "Clicks": "4357",
            "Conversions": "84",
            "Volume": "$4,732",
            "Cost per Result": "$24.46"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "162783",
            "Clicks": "3314",
            "Conversions": "58",
            "Volume": "$15,957",
            "Cost per Result": "$42.95"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "380528",
            "Clicks": "4015",
            "Conversions": "87",
            "Volume": "$13,656",
            "Cost per Result": "$56.76"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "232177",
            "Clicks": "6443",
            "Conversions": "89",
            "Volume": "$13,923",
            "Cost per Result": "$57.17"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "110260",
            "Clicks": "2354",
            "Conversions": "88",
            "Volume": "$14,399",
            "Cost per Result": "$54.99"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "112745",
            "Clicks": "5175",
            "Conversions": "66",
            "Volume": "$13,372",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "459543",
            "Clicks": "5689",
            "Conversions": "44",
            "Volume": "$6,089",
            "Cost per Result": "$42.92"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "436968",
            "Clicks": "4579",
            "Conversions": "67",
            "Volume": "$11,301",
            "Cost per Result": "$31.43"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "475378",
            "Clicks": "6946",
            "Conversions": "81",
            "Volume": "$12,515",
            "Cost per Result": "$59.54"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "438861",
            "Clicks": "3860",
            "Conversions": "49",
            "Volume": "$10,134",
            "Cost per Result": "$21.07"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "485877",
            "Clicks": "3830",
            "Conversions": "62",
            "Volume": "$14,002",
            "Cost per Result": "$38.09"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "205196",
            "Clicks": "5972",
            "Conversions": "80",
            "Volume": "$12,186",
            "Cost per Result": "$19.38"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "144129",
            "Clicks": "3572",
            "Conversions": "57",
            "Volume": "$11,525",
            "Cost per Result": "$21.58"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "231794",
            "Clicks": "4652",
            "Conversions": "73",
            "Volume": "$15,156",
            "Cost per Result": "$61.44"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "233897",
            "Clicks": "2810",
            "Conversions": "65",
            "Volume": "$6,115",
            "Cost per Result": "$62.39"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "314327",
            "Clicks": "3279",
            "Conversions": "67",
            "Volume": "$9,696",
            "Cost per Result": "$28.36"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "182199",
            "Clicks": "6735",
            "Conversions": "61",
            "Volume": "$8,880",
            "Cost per Result": "$41.23"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "428876",
            "Clicks": "5922",
            "Conversions": "57",
            "Volume": "$11,100",
            "Cost per Result": "$47.52"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "421142",
            "Clicks": "2389",
            "Conversions": "72",
            "Volume": "$15,200",
            "Cost per Result": "$33.81"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "493067",
            "Clicks": "4338",
            "Conversions": "51",
            "Volume": "$5,307",
            "Cost per Result": "$41.82"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "289231",
            "Clicks": "5272",
            "Conversions": "52",
            "Volume": "$6,971",
            "Cost per Result": "$31.47"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "371097",
            "Clicks": "6384",
            "Conversions": "73",
            "Volume": "$4,576",
            "Cost per Result": "$46.80"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "491189",
            "Clicks": "6190",
            "Conversions": "46",
            "Volume": "$6,336",
            "Cost per Result": "$60.14"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "353477",
            "Clicks": "5082",
            "Conversions": "64",
            "Volume": "$4,784",
            "Cost per Result": "$41.99"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "256099",
            "Clicks": "5710",
            "Conversions": "40",
            "Volume": "$5,054",
            "Cost per Result": "$15.11"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "194314",
            "Clicks": "6345",
            "Conversions": "58",
            "Volume": "$9,289",
            "Cost per Result": "$15.71"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "139281",
            "Clicks": "4888",
            "Conversions": "62",
            "Volume": "$8,691",
            "Cost per Result": "$31.41"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "346186",
            "Clicks": "5475",
            "Conversions": "51",
            "Volume": "$6,939",
            "Cost per Result": "$59.59"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "177309",
            "Clicks": "3020",
            "Conversions": "50",
            "Volume": "$13,781",
            "Cost per Result": "$62.69"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "446998",
            "Clicks": "2851",
            "Conversions": "81",
            "Volume": "$15,780",
            "Cost per Result": "$20.78"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "383295",
            "Clicks": "2686",
            "Conversions": "46",
            "Volume": "$4,179",
            "Cost per Result": "$60.05"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "267958",
            "Clicks": "5642",
            "Conversions": "76",
            "Volume": "$9,675",
            "Cost per Result": "$19.76"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "497099",
            "Clicks": "4820",
            "Conversions": "42",
            "Volume": "$5,459",
            "Cost per Result": "$41.84"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "141320",
            "Clicks": "6868",
            "Conversions": "65",
            "Volume": "$8,807",
            "Cost per Result": "$25.27"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "300233",
            "Clicks": "6946",
            "Conversions": "85",
            "Volume": "$14,379",
            "Cost per Result": "$61.33"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "396582",
            "Clicks": "3026",
            "Conversions": "58",
            "Volume": "$12,130",
            "Cost per Result": "$43.95"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "266662",
            "Clicks": "2399",
            "Conversions": "48",
            "Volume": "$9,613",
            "Cost per Result": "$24.13"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "221350",
            "Clicks": "5864",
            "Conversions": "68",
            "Volume": "$9,641",
            "Cost per Result": "$43.02"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "328503",
            "Clicks": "5599",
            "Conversions": "70",
            "Volume": "$6,886",
            "Cost per Result": "$34.19"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "436249",
            "Clicks": "4536",
            "Conversions": "79",
            "Volume": "$4,886",
            "Cost per Result": "$40.91"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "351220",
            "Clicks": "5175",
            "Conversions": "87",
            "Volume": "$9,615",
            "Cost per Result": "$15.31"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "371545",
            "Clicks": "2502",
            "Conversions": "58",
            "Volume": "$5,653",
            "Cost per Result": "$44.62"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "278814",
            "Clicks": "4878",
            "Conversions": "42",
            "Volume": "$14,026",
            "Cost per Result": "$39.02"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "127291",
            "Clicks": "5333",
            "Conversions": "88",
            "Volume": "$12,290",
            "Cost per Result": "$25.92"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "149488",
            "Clicks": "4136",
            "Conversions": "56",
            "Volume": "$4,400",
            "Cost per Result": "$41.24"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "390705",
            "Clicks": "4416",
            "Conversions": "43",
            "Volume": "$11,620",
            "Cost per Result": "$42.30"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "301601",
            "Clicks": "6124",
            "Conversions": "54",
            "Volume": "$4,400",
            "Cost per Result": "$26.13"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "207343",
            "Clicks": "3107",
            "Conversions": "67",
            "Volume": "$9,835",
            "Cost per Result": "$46.36"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "474453",
            "Clicks": "4609",
            "Conversions": "87",
            "Volume": "$13,231",
            "Cost per Result": "$32.93"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "221006",
            "Clicks": "6435",
            "Conversions": "41",
            "Volume": "$5,973",
            "Cost per Result": "$29.02"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "426467",
            "Clicks": "5003",
            "Conversions": "53",
            "Volume": "$15,254",
            "Cost per Result": "$46.79"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "206714",
            "Clicks": "3466",
            "Conversions": "74",
            "Volume": "$5,416",
            "Cost per Result": "$63.35"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "393151",
            "Clicks": "4163",
            "Conversions": "70",
            "Volume": "$9,423",
            "Cost per Result": "$60.37"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "348668",
            "Clicks": "4984",
            "Conversions": "81",
            "Volume": "$9,047",
            "Cost per Result": "$54.12"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "119218",
            "Clicks": "4196",
            "Conversions": "43",
            "Volume": "$12,953",
            "Cost per Result": "$26.03"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "318947",
            "Clicks": "3285",
            "Conversions": "74",
            "Volume": "$5,882",
            "Cost per Result": "$43.96"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "434516",
            "Clicks": "2845",
            "Conversions": "48",
            "Volume": "$14,705",
            "Cost per Result": "$16.85"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "282277",
            "Clicks": "4832",
            "Conversions": "87",
            "Volume": "$6,913",
            "Cost per Result": "$32.49"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "394638",
            "Clicks": "6753",
            "Conversions": "56",
            "Volume": "$4,089",
            "Cost per Result": "$44.99"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "308239",
            "Clicks": "4730",
            "Conversions": "48",
            "Volume": "$5,933",
            "Cost per Result": "$55.56"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "248157",
            "Clicks": "4988",
            "Conversions": "73",
            "Volume": "$5,541",
            "Cost per Result": "$52.57"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "366743",
            "Clicks": "5748",
            "Conversions": "63",
            "Volume": "$5,963",
            "Cost per Result": "$57.11"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "178211",
            "Clicks": "6635",
            "Conversions": "89",
            "Volume": "$14,831",
            "Cost per Result": "$18.85"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "298619",
            "Clicks": "2947",
            "Conversions": "90",
            "Volume": "$10,805",
            "Cost per Result": "$24.26"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "333265",
            "Clicks": "4717",
            "Conversions": "54",
            "Volume": "$12,269",
            "Cost per Result": "$48.55"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "105304",
            "Clicks": "6180",
            "Conversions": "78",
            "Volume": "$9,719",
            "Cost per Result": "$64.40"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "227625",
            "Clicks": "5632",
            "Conversions": "70",
            "Volume": "$15,070",
            "Cost per Result": "$15.57"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "335463",
            "Clicks": "5330",
            "Conversions": "44",
            "Volume": "$13,506",
            "Cost per Result": "$22.26"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "455689",
            "Clicks": "5961",
            "Conversions": "47",
            "Volume": "$11,314",
            "Cost per Result": "$59.98"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "438963",
            "Clicks": "2512",
            "Conversions": "60",
            "Volume": "$12,621",
            "Cost per Result": "$40.82"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "404910",
            "Clicks": "6742",
            "Conversions": "87",
            "Volume": "$6,807",
            "Cost per Result": "$39.35"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "218144",
            "Clicks": "4632",
            "Conversions": "56",
            "Volume": "$7,550",
            "Cost per Result": "$34.77"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "232533",
            "Clicks": "2181",
            "Conversions": "41",
            "Volume": "$4,015",
            "Cost per Result": "$40.85"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "472183",
            "Clicks": "6035",
            "Conversions": "45",
            "Volume": "$5,666",
            "Cost per Result": "$54.47"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "232686",
            "Clicks": "3317",
            "Conversions": "79",
            "Volume": "$10,874",
            "Cost per Result": "$52.69"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "273310",
            "Clicks": "2152",
            "Conversions": "85",
            "Volume": "$11,196",
            "Cost per Result": "$25.71"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "487465",
            "Clicks": "6276",
            "Conversions": "76",
            "Volume": "$6,743",
            "Cost per Result": "$40.29"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "135629",
            "Clicks": "5323",
            "Conversions": "75",
            "Volume": "$13,751",
            "Cost per Result": "$49.40"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "473054",
            "Clicks": "5776",
            "Conversions": "54",
            "Volume": "$12,726",
            "Cost per Result": "$30.22"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "271480",
            "Clicks": "5678",
            "Conversions": "59",
            "Volume": "$9,124",
            "Cost per Result": "$22.94"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "166234",
            "Clicks": "2094",
            "Conversions": "83",
            "Volume": "$6,037",
            "Cost per Result": "$34.52"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "441193",
            "Clicks": "4315",
            "Conversions": "69",
            "Volume": "$10,446",
            "Cost per Result": "$52.05"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "135238",
            "Clicks": "3415",
            "Conversions": "76",
            "Volume": "$10,479",
            "Cost per Result": "$28.06"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "385374",
            "Clicks": "4841",
            "Conversions": "50",
            "Volume": "$6,306",
            "Cost per Result": "$27.78"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "450776",
            "Clicks": "5818",
            "Conversions": "86",
            "Volume": "$11,368",
            "Cost per Result": "$27.70"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "311476",
            "Clicks": "6028",
            "Conversions": "75",
            "Volume": "$10,710",
            "Cost per Result": "$58.46"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "448200",
            "Clicks": "2949",
            "Conversions": "43",
            "Volume": "$14,718",
            "Cost per Result": "$62.23"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "146566",
            "Clicks": "4103",
            "Conversions": "55",
            "Volume": "$4,488",
            "Cost per Result": "$20.86"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "421526",
            "Clicks": "2966",
            "Conversions": "63",
            "Volume": "$10,921",
            "Cost per Result": "$18.66"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "255407",
            "Clicks": "3507",
            "Conversions": "76",
            "Volume": "$9,601",
            "Cost per Result": "$48.03"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "126948",
            "Clicks": "2682",
            "Conversions": "52",
            "Volume": "$7,808",
            "Cost per Result": "$21.14"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "377780",
            "Clicks": "5614",
            "Conversions": "57",
            "Volume": "$10,285",
            "Cost per Result": "$36.05"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "391489",
            "Clicks": "5203",
            "Conversions": "40",
            "Volume": "$6,459",
            "Cost per Result": "$40.85"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "413553",
            "Clicks": "3292",
            "Conversions": "40",
            "Volume": "$7,620",
            "Cost per Result": "$54.93"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "316451",
            "Clicks": "6874",
            "Conversions": "78",
            "Volume": "$12,537",
            "Cost per Result": "$37.46"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "261324",
            "Clicks": "5782",
            "Conversions": "41",
            "Volume": "$13,306",
            "Cost per Result": "$56.88"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "271855",
            "Clicks": "5177",
            "Conversions": "78",
            "Volume": "$15,819",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "111195",
            "Clicks": "2432",
            "Conversions": "75",
            "Volume": "$4,634",
            "Cost per Result": "$20.60"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "114530",
            "Clicks": "3726",
            "Conversions": "54",
            "Volume": "$5,876",
            "Cost per Result": "$16.50"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "249895",
            "Clicks": "5516",
            "Conversions": "73",
            "Volume": "$13,040",
            "Cost per Result": "$55.47"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "227838",
            "Clicks": "2595",
            "Conversions": "62",
            "Volume": "$6,034",
            "Cost per Result": "$30.96"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "434169",
            "Clicks": "2821",
            "Conversions": "86",
            "Volume": "$6,596",
            "Cost per Result": "$52.01"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "155281",
            "Clicks": "2593",
            "Conversions": "61",
            "Volume": "$15,500",
            "Cost per Result": "$59.69"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "234150",
            "Clicks": "4752",
            "Conversions": "84",
            "Volume": "$12,417",
            "Cost per Result": "$42.10"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "244702",
            "Clicks": "5367",
            "Conversions": "69",
            "Volume": "$12,340",
            "Cost per Result": "$54.28"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "481918",
            "Clicks": "6426",
            "Conversions": "85",
            "Volume": "$4,397",
            "Cost per Result": "$18.12"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "377070",
            "Clicks": "6483",
            "Conversions": "58",
            "Volume": "$14,396",
            "Cost per Result": "$33.65"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "452185",
            "Clicks": "2040",
            "Conversions": "71",
            "Volume": "$10,026",
            "Cost per Result": "$24.79"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "447813",
            "Clicks": "6166",
            "Conversions": "44",
            "Volume": "$8,437",
            "Cost per Result": "$25.87"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "254175",
            "Clicks": "6794",
            "Conversions": "69",
            "Volume": "$8,189",
            "Cost per Result": "$33.98"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "404301",
            "Clicks": "4636",
            "Conversions": "78",
            "Volume": "$14,513",
            "Cost per Result": "$17.94"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "459220",
            "Clicks": "4706",
            "Conversions": "74",
            "Volume": "$15,882",
            "Cost per Result": "$42.13"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "306818",
            "Clicks": "6982",
            "Conversions": "59",
            "Volume": "$13,499",
            "Cost per Result": "$19.96"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "192137",
            "Clicks": "6084",
            "Conversions": "83",
            "Volume": "$13,362",
            "Cost per Result": "$55.20"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "139673",
            "Clicks": "2005",
            "Conversions": "64",
            "Volume": "$5,842",
            "Cost per Result": "$16.63"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "271542",
            "Clicks": "4319",
            "Conversions": "78",
            "Volume": "$11,470",
            "Cost per Result": "$29.28"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "202960",
            "Clicks": "2278",
            "Conversions": "48",
            "Volume": "$7,204",
            "Cost per Result": "$38.06"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "421932",
            "Clicks": "2755",
            "Conversions": "50",
            "Volume": "$4,142",
            "Cost per Result": "$38.16"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "193754",
            "Clicks": "2735",
            "Conversions": "40",
            "Volume": "$8,671",
            "Cost per Result": "$42.25"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "356914",
            "Clicks": "5989",
            "Conversions": "60",
            "Volume": "$5,465",
            "Cost per Result": "$56.76"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "449789",
            "Clicks": "5188",
            "Conversions": "63",
            "Volume": "$15,948",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "137661",
            "Clicks": "2938",
            "Conversions": "85",
            "Volume": "$11,381",
            "Cost per Result": "$60.43"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "271267",
            "Clicks": "3342",
            "Conversions": "90",
            "Volume": "$7,962",
            "Cost per Result": "$23.61"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "264391",
            "Clicks": "3318",
            "Conversions": "42",
            "Volume": "$14,079",
            "Cost per Result": "$43.77"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "331410",
            "Clicks": "5756",
            "Conversions": "41",
            "Volume": "$14,972",
            "Cost per Result": "$17.38"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "113244",
            "Clicks": "3491",
            "Conversions": "50",
            "Volume": "$12,571",
            "Cost per Result": "$43.68"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "188567",
            "Clicks": "3128",
            "Conversions": "70",
            "Volume": "$7,914",
            "Cost per Result": "$52.64"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "169679",
            "Clicks": "3661",
            "Conversions": "61",
            "Volume": "$11,425",
            "Cost per Result": "$43.13"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "258725",
            "Clicks": "5640",
            "Conversions": "72",
            "Volume": "$14,724",
            "Cost per Result": "$37.07"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "246709",
            "Clicks": "6702",
            "Conversions": "68",
            "Volume": "$6,569",
            "Cost per Result": "$26.73"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "414357",
            "Clicks": "4545",
            "Conversions": "80",
            "Volume": "$9,696",
            "Cost per Result": "$18.92"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "257879",
            "Clicks": "5729",
            "Conversions": "52",
            "Volume": "$14,611",
            "Cost per Result": "$22.84"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "142931",
            "Clicks": "4823",
            "Conversions": "89",
            "Volume": "$9,131",
            "Cost per Result": "$41.66"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "117600",
            "Clicks": "5606",
            "Conversions": "51",
            "Volume": "$4,677",
            "Cost per Result": "$49.25"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "105770",
            "Clicks": "4592",
            "Conversions": "76",
            "Volume": "$8,221",
            "Cost per Result": "$35.44"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "473638",
            "Clicks": "3457",
            "Conversions": "57",
            "Volume": "$10,439",
            "Cost per Result": "$30.11"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "146898",
            "Clicks": "2813",
            "Conversions": "63",
            "Volume": "$10,763",
            "Cost per Result": "$18.78"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "177541",
            "Clicks": "4947",
            "Conversions": "90",
            "Volume": "$5,888",
            "Cost per Result": "$27.82"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "154550",
            "Clicks": "5094",
            "Conversions": "45",
            "Volume": "$8,516",
            "Cost per Result": "$32.55"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "139480",
            "Clicks": "6503",
            "Conversions": "42",
            "Volume": "$15,646",
            "Cost per Result": "$17.07"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "498850",
            "Clicks": "4575",
            "Conversions": "66",
            "Volume": "$11,002",
            "Cost per Result": "$50.82"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "396015",
            "Clicks": "5842",
            "Conversions": "55",
            "Volume": "$7,116",
            "Cost per Result": "$34.80"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "363082",
            "Clicks": "4640",
            "Conversions": "74",
            "Volume": "$10,836",
            "Cost per Result": "$42.94"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "476250",
            "Clicks": "3690",
            "Conversions": "64",
            "Volume": "$6,568",
            "Cost per Result": "$34.31"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "239125",
            "Clicks": "4878",
            "Conversions": "45",
            "Volume": "$14,397",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "347843",
            "Clicks": "2369",
            "Conversions": "53",
            "Volume": "$15,056",
            "Cost per Result": "$26.74"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "337670",
            "Clicks": "3270",
            "Conversions": "73",
            "Volume": "$14,156",
            "Cost per Result": "$64.70"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "355307",
            "Clicks": "6211",
            "Conversions": "70",
            "Volume": "$12,929",
            "Cost per Result": "$56.94"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "219605",
            "Clicks": "4069",
            "Conversions": "47",
            "Volume": "$9,217",
            "Cost per Result": "$42.65"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "255786",
            "Clicks": "5372",
            "Conversions": "66",
            "Volume": "$7,106",
            "Cost per Result": "$64.05"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "216494",
            "Clicks": "2518",
            "Conversions": "60",
            "Volume": "$15,991",
            "Cost per Result": "$25.32"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "270358",
            "Clicks": "2431",
            "Conversions": "57",
            "Volume": "$4,077",
            "Cost per Result": "$50.52"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "186723",
            "Clicks": "3342",
            "Conversions": "84",
            "Volume": "$15,077",
            "Cost per Result": "$63.10"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "391191",
            "Clicks": "2989",
            "Conversions": "83",
            "Volume": "$14,392",
            "Cost per Result": "$64.97"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "342260",
            "Clicks": "3261",
            "Conversions": "79",
            "Volume": "$6,712",
            "Cost per Result": "$25.40"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "337451",
            "Clicks": "5683",
            "Conversions": "59",
            "Volume": "$4,653",
            "Cost per Result": "$58.65"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "152809",
            "Clicks": "3380",
            "Conversions": "70",
            "Volume": "$5,086",
            "Cost per Result": "$60.99"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "487492",
            "Clicks": "3576",
            "Conversions": "49",
            "Volume": "$10,406",
            "Cost per Result": "$47.87"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "444658",
            "Clicks": "6930",
            "Conversions": "58",
            "Volume": "$8,638",
            "Cost per Result": "$16.11"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "314910",
            "Clicks": "5528",
            "Conversions": "58",
            "Volume": "$4,000",
            "Cost per Result": "$51.94"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "496852",
            "Clicks": "6930",
            "Conversions": "74",
            "Volume": "$10,554",
            "Cost per Result": "$44.96"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "235901",
            "Clicks": "4942",
            "Conversions": "62",
            "Volume": "$4,204",
            "Cost per Result": "$32.98"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "415296",
            "Clicks": "2035",
            "Conversions": "54",
            "Volume": "$6,112",
            "Cost per Result": "$61.82"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "380670",
            "Clicks": "6602",
            "Conversions": "56",
            "Volume": "$6,017",
            "Cost per Result": "$18.29"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "134566",
            "Clicks": "3229",
            "Conversions": "67",
            "Volume": "$4,657",
            "Cost per Result": "$19.35"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "109519",
            "Clicks": "4060",
            "Conversions": "46",
            "Volume": "$5,267",
            "Cost per Result": "$50.56"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "337874",
            "Clicks": "2632",
            "Conversions": "43",
            "Volume": "$4,651",
            "Cost per Result": "$54.98"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "312678",
            "Clicks": "2264",
            "Conversions": "49",
            "Volume": "$15,293",
            "Cost per Result": "$48.12"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "459030",
            "Clicks": "5417",
            "Conversions": "48",
            "Volume": "$11,756",
            "Cost per Result": "$37.08"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "301578",
            "Clicks": "3722",
            "Conversions": "80",
            "Volume": "$12,335",
            "Cost per Result": "$36.03"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "278922",
            "Clicks": "3279",
            "Conversions": "65",
            "Volume": "$4,927",
            "Cost per Result": "$38.05"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "188723",
            "Clicks": "3010",
            "Conversions": "44",
            "Volume": "$7,435",
            "Cost per Result": "$23.61"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "176904",
            "Clicks": "6027",
            "Conversions": "76",
            "Volume": "$13,216",
            "Cost per Result": "$15.79"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "465470",
            "Clicks": "6909",
            "Conversions": "80",
            "Volume": "$8,463",
            "Cost per Result": "$33.83"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "449524",
            "Clicks": "6339",
            "Conversions": "80",
            "Volume": "$12,988",
            "Cost per Result": "$44.08"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "155289",
            "Clicks": "6755",
            "Conversions": "89",
            "Volume": "$14,443",
            "Cost per Result": "$60.88"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "251678",
            "Clicks": "5403",
            "Conversions": "44",
            "Volume": "$14,588",
            "Cost per Result": "$43.65"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "208682",
            "Clicks": "6014",
            "Conversions": "75",
            "Volume": "$7,450",
            "Cost per Result": "$30.63"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "116015",
            "Clicks": "6913",
            "Conversions": "53",
            "Volume": "$11,510",
            "Cost per Result": "$45.28"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "210673",
            "Clicks": "4906",
            "Conversions": "75",
            "Volume": "$9,763",
            "Cost per Result": "$24.73"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "114115",
            "Clicks": "3426",
            "Conversions": "53",
            "Volume": "$6,945",
            "Cost per Result": "$57.09"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "319705",
            "Clicks": "2440",
            "Conversions": "47",
            "Volume": "$12,513",
            "Cost per Result": "$45.69"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "267727",
            "Clicks": "3558",
            "Conversions": "47",
            "Volume": "$12,306",
            "Cost per Result": "$46.92"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "105881",
            "Clicks": "4359",
            "Conversions": "71",
            "Volume": "$6,390",
            "Cost per Result": "$41.03"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "111466",
            "Clicks": "6237",
            "Conversions": "59",
            "Volume": "$4,895",
            "Cost per Result": "$25.31"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "296412",
            "Clicks": "2494",
            "Conversions": "66",
            "Volume": "$12,722",
            "Cost per Result": "$20.71"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "142404",
            "Clicks": "3986",
            "Conversions": "70",
            "Volume": "$9,450",
            "Cost per Result": "$32.03"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "387467",
            "Clicks": "6385",
            "Conversions": "83",
            "Volume": "$8,879",
            "Cost per Result": "$18.35"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "440820",
            "Clicks": "6742",
            "Conversions": "71",
            "Volume": "$15,505",
            "Cost per Result": "$22.87"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "496405",
            "Clicks": "2963",
            "Conversions": "72",
            "Volume": "$4,203",
            "Cost per Result": "$24.00"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "465060",
            "Clicks": "4300",
            "Conversions": "65",
            "Volume": "$7,507",
            "Cost per Result": "$50.75"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "289689",
            "Clicks": "3717",
            "Conversions": "89",
            "Volume": "$6,620",
            "Cost per Result": "$61.46"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "348289",
            "Clicks": "5573",
            "Conversions": "83",
            "Volume": "$5,830",
            "Cost per Result": "$64.38"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "344235",
            "Clicks": "4227",
            "Conversions": "77",
            "Volume": "$9,474",
            "Cost per Result": "$64.18"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "273530",
            "Clicks": "2989",
            "Conversions": "57",
            "Volume": "$14,332",
            "Cost per Result": "$57.32"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "229107",
            "Clicks": "2000",
            "Conversions": "74",
            "Volume": "$9,000",
            "Cost per Result": "$61.51"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "374565",
            "Clicks": "2818",
            "Conversions": "56",
            "Volume": "$8,232",
            "Cost per Result": "$63.08"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "307391",
            "Clicks": "5276",
            "Conversions": "71",
            "Volume": "$11,528",
            "Cost per Result": "$46.83"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "284134",
            "Clicks": "6806",
            "Conversions": "81",
            "Volume": "$6,694",
            "Cost per Result": "$26.57"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "462874",
            "Clicks": "6181",
            "Conversions": "43",
            "Volume": "$7,282",
            "Cost per Result": "$34.07"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "488694",
            "Clicks": "5273",
            "Conversions": "58",
            "Volume": "$11,835",
            "Cost per Result": "$58.21"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "392362",
            "Clicks": "3527",
            "Conversions": "82",
            "Volume": "$15,428",
            "Cost per Result": "$53.27"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "293396",
            "Clicks": "4852",
            "Conversions": "44",
            "Volume": "$15,768",
            "Cost per Result": "$31.48"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "242731",
            "Clicks": "6307",
            "Conversions": "82",
            "Volume": "$15,075",
            "Cost per Result": "$44.46"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "475772",
            "Clicks": "4211",
            "Conversions": "66",
            "Volume": "$11,731",
            "Cost per Result": "$34.04"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "114082",
            "Clicks": "2933",
            "Conversions": "49",
            "Volume": "$7,128",
            "Cost per Result": "$24.49"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "316853",
            "Clicks": "4707",
            "Conversions": "87",
            "Volume": "$14,036",
            "Cost per Result": "$32.82"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "329922",
            "Clicks": "5750",
            "Conversions": "55",
            "Volume": "$5,208",
            "Cost per Result": "$61.35"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "330555",
            "Clicks": "4634",
            "Conversions": "50",
            "Volume": "$4,502",
            "Cost per Result": "$27.75"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "256484",
            "Clicks": "6439",
            "Conversions": "66",
            "Volume": "$5,139",
            "Cost per Result": "$44.99"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "233587",
            "Clicks": "5174",
            "Conversions": "40",
            "Volume": "$15,705",
            "Cost per Result": "$22.44"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "431895",
            "Clicks": "2750",
            "Conversions": "48",
            "Volume": "$5,635",
            "Cost per Result": "$63.99"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "457847",
            "Clicks": "3771",
            "Conversions": "89",
            "Volume": "$13,555",
            "Cost per Result": "$41.73"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "468848",
            "Clicks": "3523",
            "Conversions": "46",
            "Volume": "$15,388",
            "Cost per Result": "$40.32"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "235154",
            "Clicks": "4276",
            "Conversions": "71",
            "Volume": "$5,004",
            "Cost per Result": "$21.66"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "379914",
            "Clicks": "3799",
            "Conversions": "51",
            "Volume": "$4,673",
            "Cost per Result": "$45.03"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "191817",
            "Clicks": "4163",
            "Conversions": "84",
            "Volume": "$8,532",
            "Cost per Result": "$40.58"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "432092",
            "Clicks": "5184",
            "Conversions": "77",
            "Volume": "$15,251",
            "Cost per Result": "$29.76"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "370193",
            "Clicks": "3476",
            "Conversions": "70",
            "Volume": "$4,979",
            "Cost per Result": "$17.34"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "487398",
            "Clicks": "4378",
            "Conversions": "52",
            "Volume": "$10,437",
            "Cost per Result": "$51.38"
          }
        ]
      },
      "MarketplaceUsersData": {
        "name": "Marketplace Users",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "424672",
            "Clicks": "6928",
            "Conversions": "42",
            "Conversion Value": "$14,146",
            "Cost per Result": "$22.59"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "141304",
            "Clicks": "6892",
            "Conversions": "44",
            "Conversion Value": "$13,727",
            "Cost per Result": "$60.06"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "283212",
            "Clicks": "5765",
            "Conversions": "78",
            "Conversion Value": "$13,858",
            "Cost per Result": "$19.07"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "192285",
            "Clicks": "3841",
            "Conversions": "73",
            "Conversion Value": "$6,717",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "133895",
            "Clicks": "6646",
            "Conversions": "58",
            "Conversion Value": "$13,537",
            "Cost per Result": "$60.32"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "371867",
            "Clicks": "5454",
            "Conversions": "52",
            "Conversion Value": "$6,396",
            "Cost per Result": "$39.52"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "336939",
            "Clicks": "5204",
            "Conversions": "41",
            "Conversion Value": "$6,313",
            "Cost per Result": "$42.08"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "375794",
            "Clicks": "2818",
            "Conversions": "47",
            "Conversion Value": "$8,233",
            "Cost per Result": "$21.62"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "397090",
            "Clicks": "4547",
            "Conversions": "59",
            "Conversion Value": "$5,365",
            "Cost per Result": "$55.23"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "161367",
            "Clicks": "6637",
            "Conversions": "49",
            "Conversion Value": "$6,428",
            "Cost per Result": "$41.93"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "343191",
            "Clicks": "4988",
            "Conversions": "67",
            "Conversion Value": "$9,289",
            "Cost per Result": "$62.61"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "374748",
            "Clicks": "6683",
            "Conversions": "72",
            "Conversion Value": "$4,230",
            "Cost per Result": "$33.50"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "443365",
            "Clicks": "5391",
            "Conversions": "70",
            "Conversion Value": "$15,629",
            "Cost per Result": "$46.97"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "456486",
            "Clicks": "2179",
            "Conversions": "66",
            "Conversion Value": "$9,962",
            "Cost per Result": "$58.54"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "143443",
            "Clicks": "2909",
            "Conversions": "42",
            "Conversion Value": "$14,298",
            "Cost per Result": "$53.80"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "210494",
            "Clicks": "2578",
            "Conversions": "52",
            "Conversion Value": "$11,237",
            "Cost per Result": "$22.93"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "123457",
            "Clicks": "2071",
            "Conversions": "44",
            "Conversion Value": "$13,731",
            "Cost per Result": "$58.53"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "273159",
            "Clicks": "4190",
            "Conversions": "86",
            "Conversion Value": "$7,811",
            "Cost per Result": "$42.33"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "345926",
            "Clicks": "5879",
            "Conversions": "84",
            "Conversion Value": "$8,408",
            "Cost per Result": "$43.06"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "222281",
            "Clicks": "5192",
            "Conversions": "56",
            "Conversion Value": "$7,948",
            "Cost per Result": "$63.00"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "488026",
            "Clicks": "5331",
            "Conversions": "70",
            "Conversion Value": "$6,486",
            "Cost per Result": "$44.95"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "216078",
            "Clicks": "6511",
            "Conversions": "57",
            "Conversion Value": "$15,973",
            "Cost per Result": "$63.18"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "356257",
            "Clicks": "5118",
            "Conversions": "49",
            "Conversion Value": "$12,377",
            "Cost per Result": "$33.95"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "277261",
            "Clicks": "2381",
            "Conversions": "65",
            "Conversion Value": "$11,420",
            "Cost per Result": "$28.21"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "207057",
            "Clicks": "4971",
            "Conversions": "62",
            "Conversion Value": "$8,742",
            "Cost per Result": "$44.52"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "194555",
            "Clicks": "3129",
            "Conversions": "61",
            "Conversion Value": "$15,744",
            "Cost per Result": "$24.12"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "151227",
            "Clicks": "4514",
            "Conversions": "87",
            "Conversion Value": "$7,225",
            "Cost per Result": "$58.86"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "493396",
            "Clicks": "4563",
            "Conversions": "58",
            "Conversion Value": "$9,358",
            "Cost per Result": "$57.25"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "296690",
            "Clicks": "5709",
            "Conversions": "49",
            "Conversion Value": "$4,471",
            "Cost per Result": "$58.38"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "349582",
            "Clicks": "3863",
            "Conversions": "67",
            "Conversion Value": "$15,587",
            "Cost per Result": "$28.78"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "256750",
            "Clicks": "2984",
            "Conversions": "66",
            "Conversion Value": "$13,410",
            "Cost per Result": "$21.83"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "436372",
            "Clicks": "3636",
            "Conversions": "54",
            "Conversion Value": "$14,205",
            "Cost per Result": "$22.57"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "474759",
            "Clicks": "6433",
            "Conversions": "65",
            "Conversion Value": "$10,298",
            "Cost per Result": "$56.49"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "190078",
            "Clicks": "4129",
            "Conversions": "45",
            "Conversion Value": "$12,272",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "427283",
            "Clicks": "5852",
            "Conversions": "48",
            "Conversion Value": "$11,305",
            "Cost per Result": "$54.41"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "166076",
            "Clicks": "2923",
            "Conversions": "58",
            "Conversion Value": "$14,133",
            "Cost per Result": "$24.28"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "184038",
            "Clicks": "5518",
            "Conversions": "40",
            "Conversion Value": "$11,369",
            "Cost per Result": "$25.34"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "282049",
            "Clicks": "6712",
            "Conversions": "73",
            "Conversion Value": "$9,161",
            "Cost per Result": "$54.91"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "222894",
            "Clicks": "5104",
            "Conversions": "63",
            "Conversion Value": "$6,085",
            "Cost per Result": "$41.23"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "485123",
            "Clicks": "2377",
            "Conversions": "85",
            "Conversion Value": "$10,278",
            "Cost per Result": "$53.44"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "302147",
            "Clicks": "2029",
            "Conversions": "67",
            "Conversion Value": "$9,320",
            "Cost per Result": "$35.61"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "342567",
            "Clicks": "4117",
            "Conversions": "53",
            "Conversion Value": "$4,680",
            "Cost per Result": "$55.33"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "441446",
            "Clicks": "4122",
            "Conversions": "67",
            "Conversion Value": "$4,404",
            "Cost per Result": "$26.28"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "481402",
            "Clicks": "3094",
            "Conversions": "74",
            "Conversion Value": "$4,670",
            "Cost per Result": "$31.06"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "199734",
            "Clicks": "3074",
            "Conversions": "44",
            "Conversion Value": "$11,818",
            "Cost per Result": "$15.78"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "461188",
            "Clicks": "5275",
            "Conversions": "81",
            "Conversion Value": "$14,059",
            "Cost per Result": "$22.25"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "387204",
            "Clicks": "3935",
            "Conversions": "66",
            "Conversion Value": "$12,374",
            "Cost per Result": "$35.17"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "358544",
            "Clicks": "6989",
            "Conversions": "79",
            "Conversion Value": "$11,318",
            "Cost per Result": "$22.44"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "313582",
            "Clicks": "6342",
            "Conversions": "83",
            "Conversion Value": "$14,727",
            "Cost per Result": "$21.69"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "404652",
            "Clicks": "5038",
            "Conversions": "60",
            "Conversion Value": "$12,393",
            "Cost per Result": "$58.27"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "243526",
            "Clicks": "5292",
            "Conversions": "68",
            "Conversion Value": "$4,430",
            "Cost per Result": "$43.46"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "212679",
            "Clicks": "6347",
            "Conversions": "87",
            "Conversion Value": "$15,780",
            "Cost per Result": "$52.96"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "368880",
            "Clicks": "3841",
            "Conversions": "83",
            "Conversion Value": "$5,438",
            "Cost per Result": "$39.69"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "356353",
            "Clicks": "4209",
            "Conversions": "88",
            "Conversion Value": "$14,848",
            "Cost per Result": "$57.33"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "366080",
            "Clicks": "3880",
            "Conversions": "80",
            "Conversion Value": "$14,529",
            "Cost per Result": "$36.38"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "440784",
            "Clicks": "3839",
            "Conversions": "54",
            "Conversion Value": "$14,283",
            "Cost per Result": "$60.66"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "214993",
            "Clicks": "3182",
            "Conversions": "60",
            "Conversion Value": "$13,219",
            "Cost per Result": "$56.27"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "200353",
            "Clicks": "6574",
            "Conversions": "62",
            "Conversion Value": "$8,550",
            "Cost per Result": "$26.08"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "124396",
            "Clicks": "4852",
            "Conversions": "63",
            "Conversion Value": "$8,917",
            "Cost per Result": "$40.44"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "455404",
            "Clicks": "6533",
            "Conversions": "51",
            "Conversion Value": "$6,105",
            "Cost per Result": "$63.23"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "331605",
            "Clicks": "6289",
            "Conversions": "60",
            "Conversion Value": "$9,657",
            "Cost per Result": "$15.17"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "380328",
            "Clicks": "2731",
            "Conversions": "47",
            "Conversion Value": "$4,231",
            "Cost per Result": "$43.23"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "393382",
            "Clicks": "2384",
            "Conversions": "66",
            "Conversion Value": "$5,366",
            "Cost per Result": "$63.36"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "132466",
            "Clicks": "2316",
            "Conversions": "46",
            "Conversion Value": "$4,380",
            "Cost per Result": "$41.23"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "417228",
            "Clicks": "2338",
            "Conversions": "58",
            "Conversion Value": "$15,383",
            "Cost per Result": "$18.62"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "247114",
            "Clicks": "3359",
            "Conversions": "50",
            "Conversion Value": "$8,145",
            "Cost per Result": "$55.59"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "332732",
            "Clicks": "5389",
            "Conversions": "67",
            "Conversion Value": "$7,289",
            "Cost per Result": "$54.50"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "133352",
            "Clicks": "4218",
            "Conversions": "77",
            "Conversion Value": "$14,915",
            "Cost per Result": "$47.99"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "289622",
            "Clicks": "6811",
            "Conversions": "58",
            "Conversion Value": "$10,196",
            "Cost per Result": "$58.33"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "454185",
            "Clicks": "6894",
            "Conversions": "72",
            "Conversion Value": "$14,092",
            "Cost per Result": "$27.68"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "291039",
            "Clicks": "6658",
            "Conversions": "90",
            "Conversion Value": "$10,559",
            "Cost per Result": "$44.75"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "119357",
            "Clicks": "2021",
            "Conversions": "58",
            "Conversion Value": "$13,815",
            "Cost per Result": "$46.09"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "407763",
            "Clicks": "6998",
            "Conversions": "40",
            "Conversion Value": "$4,570",
            "Cost per Result": "$38.01"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "368580",
            "Clicks": "3538",
            "Conversions": "68",
            "Conversion Value": "$5,550",
            "Cost per Result": "$37.12"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "132688",
            "Clicks": "4651",
            "Conversions": "72",
            "Conversion Value": "$12,123",
            "Cost per Result": "$40.90"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "316131",
            "Clicks": "6691",
            "Conversions": "80",
            "Conversion Value": "$4,841",
            "Cost per Result": "$44.47"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "467555",
            "Clicks": "6002",
            "Conversions": "51",
            "Conversion Value": "$7,229",
            "Cost per Result": "$19.13"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "435564",
            "Clicks": "3922",
            "Conversions": "65",
            "Conversion Value": "$14,983",
            "Cost per Result": "$41.58"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "409499",
            "Clicks": "4849",
            "Conversions": "84",
            "Conversion Value": "$6,674",
            "Cost per Result": "$37.02"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "287617",
            "Clicks": "6135",
            "Conversions": "53",
            "Conversion Value": "$4,437",
            "Cost per Result": "$15.79"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "128594",
            "Clicks": "2252",
            "Conversions": "55",
            "Conversion Value": "$4,069",
            "Cost per Result": "$37.61"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "168435",
            "Clicks": "6693",
            "Conversions": "58",
            "Conversion Value": "$7,324",
            "Cost per Result": "$34.30"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "185339",
            "Clicks": "6114",
            "Conversions": "49",
            "Conversion Value": "$4,643",
            "Cost per Result": "$62.36"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "343485",
            "Clicks": "5987",
            "Conversions": "88",
            "Conversion Value": "$6,206",
            "Cost per Result": "$62.82"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "396839",
            "Clicks": "4818",
            "Conversions": "75",
            "Conversion Value": "$8,176",
            "Cost per Result": "$50.58"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "130652",
            "Clicks": "4830",
            "Conversions": "43",
            "Conversion Value": "$9,451",
            "Cost per Result": "$54.32"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "370853",
            "Clicks": "6780",
            "Conversions": "64",
            "Conversion Value": "$9,700",
            "Cost per Result": "$62.86"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "130993",
            "Clicks": "6183",
            "Conversions": "58",
            "Conversion Value": "$5,394",
            "Cost per Result": "$24.15"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "318117",
            "Clicks": "6362",
            "Conversions": "90",
            "Conversion Value": "$13,020",
            "Cost per Result": "$28.51"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "135618",
            "Clicks": "5493",
            "Conversions": "63",
            "Conversion Value": "$13,136",
            "Cost per Result": "$24.33"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "336675",
            "Clicks": "5229",
            "Conversions": "52",
            "Conversion Value": "$4,711",
            "Cost per Result": "$61.49"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "451683",
            "Clicks": "3291",
            "Conversions": "59",
            "Conversion Value": "$5,528",
            "Cost per Result": "$64.51"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "230027",
            "Clicks": "5405",
            "Conversions": "80",
            "Conversion Value": "$13,121",
            "Cost per Result": "$60.13"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "310200",
            "Clicks": "2743",
            "Conversions": "72",
            "Conversion Value": "$11,531",
            "Cost per Result": "$54.00"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "347420",
            "Clicks": "5597",
            "Conversions": "64",
            "Conversion Value": "$11,784",
            "Cost per Result": "$60.50"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "195230",
            "Clicks": "4748",
            "Conversions": "69",
            "Conversion Value": "$15,888",
            "Cost per Result": "$31.62"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "274798",
            "Clicks": "2664",
            "Conversions": "52",
            "Conversion Value": "$8,584",
            "Cost per Result": "$34.26"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "251216",
            "Clicks": "6620",
            "Conversions": "64",
            "Conversion Value": "$4,670",
            "Cost per Result": "$18.59"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "194989",
            "Clicks": "5857",
            "Conversions": "70",
            "Conversion Value": "$10,501",
            "Cost per Result": "$37.58"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "497287",
            "Clicks": "4376",
            "Conversions": "81",
            "Conversion Value": "$7,814",
            "Cost per Result": "$32.48"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "393302",
            "Clicks": "2644",
            "Conversions": "44",
            "Conversion Value": "$5,846",
            "Cost per Result": "$55.69"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "249879",
            "Clicks": "5615",
            "Conversions": "40",
            "Conversion Value": "$15,784",
            "Cost per Result": "$53.91"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "108628",
            "Clicks": "2915",
            "Conversions": "58",
            "Conversion Value": "$10,921",
            "Cost per Result": "$46.43"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "405026",
            "Clicks": "3834",
            "Conversions": "60",
            "Conversion Value": "$14,162",
            "Cost per Result": "$34.22"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "400480",
            "Clicks": "6315",
            "Conversions": "45",
            "Conversion Value": "$10,838",
            "Cost per Result": "$39.29"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "267354",
            "Clicks": "2769",
            "Conversions": "88",
            "Conversion Value": "$5,389",
            "Cost per Result": "$45.68"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "380450",
            "Clicks": "2599",
            "Conversions": "77",
            "Conversion Value": "$12,130",
            "Cost per Result": "$61.54"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "473628",
            "Clicks": "6912",
            "Conversions": "86",
            "Conversion Value": "$9,264",
            "Cost per Result": "$31.05"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "385003",
            "Clicks": "3678",
            "Conversions": "54",
            "Conversion Value": "$14,460",
            "Cost per Result": "$60.35"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "215287",
            "Clicks": "3297",
            "Conversions": "87",
            "Conversion Value": "$14,229",
            "Cost per Result": "$38.40"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "368990",
            "Clicks": "6321",
            "Conversions": "69",
            "Conversion Value": "$9,333",
            "Cost per Result": "$56.54"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "418849",
            "Clicks": "2926",
            "Conversions": "52",
            "Conversion Value": "$8,822",
            "Cost per Result": "$36.99"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "181094",
            "Clicks": "6245",
            "Conversions": "54",
            "Conversion Value": "$5,880",
            "Cost per Result": "$16.72"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "328404",
            "Clicks": "3826",
            "Conversions": "43",
            "Conversion Value": "$12,979",
            "Cost per Result": "$41.70"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "429683",
            "Clicks": "5998",
            "Conversions": "55",
            "Conversion Value": "$10,469",
            "Cost per Result": "$25.05"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "215288",
            "Clicks": "6823",
            "Conversions": "59",
            "Conversion Value": "$15,980",
            "Cost per Result": "$15.80"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "427992",
            "Clicks": "3974",
            "Conversions": "81",
            "Conversion Value": "$8,606",
            "Cost per Result": "$32.83"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "211925",
            "Clicks": "5136",
            "Conversions": "63",
            "Conversion Value": "$14,541",
            "Cost per Result": "$24.33"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "269983",
            "Clicks": "4328",
            "Conversions": "84",
            "Conversion Value": "$15,261",
            "Cost per Result": "$43.18"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "456921",
            "Clicks": "6970",
            "Conversions": "70",
            "Conversion Value": "$11,588",
            "Cost per Result": "$43.57"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "362626",
            "Clicks": "4018",
            "Conversions": "88",
            "Conversion Value": "$5,638",
            "Cost per Result": "$53.83"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "304804",
            "Clicks": "6496",
            "Conversions": "42",
            "Conversion Value": "$5,370",
            "Cost per Result": "$54.99"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "238163",
            "Clicks": "3824",
            "Conversions": "52",
            "Conversion Value": "$11,357",
            "Cost per Result": "$33.14"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "185911",
            "Clicks": "2033",
            "Conversions": "75",
            "Conversion Value": "$7,297",
            "Cost per Result": "$16.42"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "183662",
            "Clicks": "4694",
            "Conversions": "66",
            "Conversion Value": "$15,231",
            "Cost per Result": "$43.14"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "420448",
            "Clicks": "5546",
            "Conversions": "85",
            "Conversion Value": "$15,852",
            "Cost per Result": "$26.52"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "128669",
            "Clicks": "5122",
            "Conversions": "70",
            "Conversion Value": "$13,917",
            "Cost per Result": "$18.33"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "347682",
            "Clicks": "5802",
            "Conversions": "75",
            "Conversion Value": "$5,604",
            "Cost per Result": "$16.04"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "180123",
            "Clicks": "2475",
            "Conversions": "82",
            "Conversion Value": "$9,848",
            "Cost per Result": "$55.81"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "309820",
            "Clicks": "2992",
            "Conversions": "47",
            "Conversion Value": "$8,183",
            "Cost per Result": "$35.03"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "423247",
            "Clicks": "2937",
            "Conversions": "42",
            "Conversion Value": "$4,466",
            "Cost per Result": "$61.02"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "144654",
            "Clicks": "3257",
            "Conversions": "42",
            "Conversion Value": "$9,350",
            "Cost per Result": "$27.70"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "435409",
            "Clicks": "2412",
            "Conversions": "53",
            "Conversion Value": "$9,908",
            "Cost per Result": "$15.21"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "437509",
            "Clicks": "6251",
            "Conversions": "70",
            "Conversion Value": "$9,541",
            "Cost per Result": "$28.16"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "285836",
            "Clicks": "6713",
            "Conversions": "86",
            "Conversion Value": "$8,318",
            "Cost per Result": "$45.25"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "238050",
            "Clicks": "2759",
            "Conversions": "65",
            "Conversion Value": "$10,413",
            "Cost per Result": "$21.67"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "197575",
            "Clicks": "3349",
            "Conversions": "66",
            "Conversion Value": "$10,712",
            "Cost per Result": "$20.95"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "169551",
            "Clicks": "5572",
            "Conversions": "66",
            "Conversion Value": "$10,443",
            "Cost per Result": "$37.70"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "290638",
            "Clicks": "2433",
            "Conversions": "61",
            "Conversion Value": "$11,048",
            "Cost per Result": "$59.30"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "186578",
            "Clicks": "2686",
            "Conversions": "75",
            "Conversion Value": "$15,470",
            "Cost per Result": "$56.70"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "149361",
            "Clicks": "3472",
            "Conversions": "50",
            "Conversion Value": "$7,691",
            "Cost per Result": "$61.07"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "142509",
            "Clicks": "5130",
            "Conversions": "45",
            "Conversion Value": "$15,356",
            "Cost per Result": "$19.65"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "186491",
            "Clicks": "2315",
            "Conversions": "43",
            "Conversion Value": "$5,754",
            "Cost per Result": "$43.73"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "112785",
            "Clicks": "6493",
            "Conversions": "72",
            "Conversion Value": "$11,682",
            "Cost per Result": "$45.53"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "216463",
            "Clicks": "4241",
            "Conversions": "77",
            "Conversion Value": "$15,695",
            "Cost per Result": "$42.99"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "116496",
            "Clicks": "4987",
            "Conversions": "71",
            "Conversion Value": "$10,441",
            "Cost per Result": "$41.74"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "187113",
            "Clicks": "5049",
            "Conversions": "66",
            "Conversion Value": "$11,792",
            "Cost per Result": "$53.71"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "352316",
            "Clicks": "2733",
            "Conversions": "51",
            "Conversion Value": "$9,036",
            "Cost per Result": "$36.35"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "220210",
            "Clicks": "6502",
            "Conversions": "88",
            "Conversion Value": "$12,482",
            "Cost per Result": "$43.33"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "310556",
            "Clicks": "2868",
            "Conversions": "51",
            "Conversion Value": "$10,226",
            "Cost per Result": "$31.89"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "325521",
            "Clicks": "6091",
            "Conversions": "51",
            "Conversion Value": "$8,600",
            "Cost per Result": "$36.49"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "168272",
            "Clicks": "6223",
            "Conversions": "56",
            "Conversion Value": "$6,829",
            "Cost per Result": "$60.76"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "210803",
            "Clicks": "6630",
            "Conversions": "55",
            "Conversion Value": "$6,963",
            "Cost per Result": "$58.65"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "276991",
            "Clicks": "2189",
            "Conversions": "79",
            "Conversion Value": "$8,598",
            "Cost per Result": "$33.48"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "322314",
            "Clicks": "5051",
            "Conversions": "71",
            "Conversion Value": "$13,034",
            "Cost per Result": "$48.15"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "302304",
            "Clicks": "3186",
            "Conversions": "49",
            "Conversion Value": "$11,389",
            "Cost per Result": "$28.33"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "109918",
            "Clicks": "2890",
            "Conversions": "45",
            "Conversion Value": "$14,905",
            "Cost per Result": "$15.55"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "391957",
            "Clicks": "4842",
            "Conversions": "85",
            "Conversion Value": "$6,135",
            "Cost per Result": "$52.50"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "191319",
            "Clicks": "5552",
            "Conversions": "66",
            "Conversion Value": "$11,223",
            "Cost per Result": "$26.70"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "186041",
            "Clicks": "6949",
            "Conversions": "54",
            "Conversion Value": "$12,627",
            "Cost per Result": "$60.42"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "346493",
            "Clicks": "2814",
            "Conversions": "78",
            "Conversion Value": "$10,940",
            "Cost per Result": "$21.21"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "222828",
            "Clicks": "3828",
            "Conversions": "49",
            "Conversion Value": "$7,183",
            "Cost per Result": "$29.70"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "242415",
            "Clicks": "2085",
            "Conversions": "69",
            "Conversion Value": "$8,552",
            "Cost per Result": "$52.36"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "250907",
            "Clicks": "6411",
            "Conversions": "83",
            "Conversion Value": "$8,402",
            "Cost per Result": "$50.60"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "487152",
            "Clicks": "6250",
            "Conversions": "69",
            "Conversion Value": "$15,665",
            "Cost per Result": "$52.26"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "178548",
            "Clicks": "6943",
            "Conversions": "53",
            "Conversion Value": "$8,407",
            "Cost per Result": "$25.78"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "240202",
            "Clicks": "3279",
            "Conversions": "61",
            "Conversion Value": "$7,725",
            "Cost per Result": "$59.32"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151322",
            "Clicks": "2369",
            "Conversions": "71",
            "Conversion Value": "$15,896",
            "Cost per Result": "$17.66"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "242763",
            "Clicks": "5971",
            "Conversions": "90",
            "Conversion Value": "$10,460",
            "Cost per Result": "$43.18"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "357382",
            "Clicks": "3377",
            "Conversions": "59",
            "Conversion Value": "$7,751",
            "Cost per Result": "$20.79"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "438492",
            "Clicks": "6700",
            "Conversions": "78",
            "Conversion Value": "$5,176",
            "Cost per Result": "$18.05"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "455744",
            "Clicks": "6482",
            "Conversions": "70",
            "Conversion Value": "$8,339",
            "Cost per Result": "$27.40"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "275216",
            "Clicks": "3147",
            "Conversions": "81",
            "Conversion Value": "$6,376",
            "Cost per Result": "$43.12"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "177186",
            "Clicks": "5267",
            "Conversions": "47",
            "Conversion Value": "$8,758",
            "Cost per Result": "$50.28"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "434427",
            "Clicks": "6753",
            "Conversions": "64",
            "Conversion Value": "$6,233",
            "Cost per Result": "$31.00"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "461964",
            "Clicks": "4512",
            "Conversions": "86",
            "Conversion Value": "$7,422",
            "Cost per Result": "$53.24"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "133571",
            "Clicks": "3702",
            "Conversions": "55",
            "Conversion Value": "$11,020",
            "Cost per Result": "$63.62"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "206533",
            "Clicks": "5945",
            "Conversions": "41",
            "Conversion Value": "$12,163",
            "Cost per Result": "$28.90"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "472386",
            "Clicks": "4523",
            "Conversions": "50",
            "Conversion Value": "$7,454",
            "Cost per Result": "$29.70"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "257518",
            "Clicks": "4374",
            "Conversions": "85",
            "Conversion Value": "$6,593",
            "Cost per Result": "$22.22"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "216343",
            "Clicks": "3653",
            "Conversions": "87",
            "Conversion Value": "$10,847",
            "Cost per Result": "$38.11"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "410693",
            "Clicks": "5880",
            "Conversions": "74",
            "Conversion Value": "$14,491",
            "Cost per Result": "$49.38"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "376611",
            "Clicks": "6423",
            "Conversions": "83",
            "Conversion Value": "$12,494",
            "Cost per Result": "$26.45"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "173018",
            "Clicks": "5319",
            "Conversions": "76",
            "Conversion Value": "$14,180",
            "Cost per Result": "$51.97"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "286719",
            "Clicks": "6766",
            "Conversions": "78",
            "Conversion Value": "$11,592",
            "Cost per Result": "$58.57"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "384723",
            "Clicks": "6090",
            "Conversions": "70",
            "Conversion Value": "$12,033",
            "Cost per Result": "$38.38"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "102892",
            "Clicks": "2309",
            "Conversions": "47",
            "Conversion Value": "$15,597",
            "Cost per Result": "$58.81"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "165687",
            "Clicks": "2582",
            "Conversions": "71",
            "Conversion Value": "$7,923",
            "Cost per Result": "$26.33"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "360111",
            "Clicks": "6478",
            "Conversions": "55",
            "Conversion Value": "$4,800",
            "Cost per Result": "$43.25"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "266585",
            "Clicks": "2076",
            "Conversions": "41",
            "Conversion Value": "$10,178",
            "Cost per Result": "$53.95"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "173388",
            "Clicks": "3362",
            "Conversions": "69",
            "Conversion Value": "$8,487",
            "Cost per Result": "$21.05"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "222307",
            "Clicks": "6714",
            "Conversions": "80",
            "Conversion Value": "$11,976",
            "Cost per Result": "$32.29"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "338531",
            "Clicks": "5885",
            "Conversions": "79",
            "Conversion Value": "$5,500",
            "Cost per Result": "$24.98"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "306434",
            "Clicks": "4212",
            "Conversions": "42",
            "Conversion Value": "$14,485",
            "Cost per Result": "$18.21"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "395078",
            "Clicks": "5346",
            "Conversions": "45",
            "Conversion Value": "$9,954",
            "Cost per Result": "$52.99"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "185258",
            "Clicks": "3811",
            "Conversions": "77",
            "Conversion Value": "$7,797",
            "Cost per Result": "$38.42"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "183822",
            "Clicks": "4736",
            "Conversions": "90",
            "Conversion Value": "$15,025",
            "Cost per Result": "$28.54"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "113899",
            "Clicks": "2848",
            "Conversions": "65",
            "Conversion Value": "$15,192",
            "Cost per Result": "$47.68"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "461089",
            "Clicks": "4090",
            "Conversions": "52",
            "Conversion Value": "$14,814",
            "Cost per Result": "$52.27"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "282282",
            "Clicks": "6884",
            "Conversions": "60",
            "Conversion Value": "$11,643",
            "Cost per Result": "$35.46"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "301894",
            "Clicks": "5792",
            "Conversions": "55",
            "Conversion Value": "$7,837",
            "Cost per Result": "$31.44"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "213428",
            "Clicks": "3324",
            "Conversions": "80",
            "Conversion Value": "$11,054",
            "Cost per Result": "$18.61"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "295535",
            "Clicks": "6907",
            "Conversions": "56",
            "Conversion Value": "$6,241",
            "Cost per Result": "$43.65"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "480036",
            "Clicks": "4602",
            "Conversions": "52",
            "Conversion Value": "$5,443",
            "Cost per Result": "$33.19"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "152661",
            "Clicks": "4630",
            "Conversions": "50",
            "Conversion Value": "$5,922",
            "Cost per Result": "$22.85"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "308410",
            "Clicks": "2485",
            "Conversions": "90",
            "Conversion Value": "$7,448",
            "Cost per Result": "$38.60"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "126277",
            "Clicks": "6422",
            "Conversions": "69",
            "Conversion Value": "$4,574",
            "Cost per Result": "$25.12"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "383491",
            "Clicks": "6784",
            "Conversions": "65",
            "Conversion Value": "$14,865",
            "Cost per Result": "$63.56"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "305477",
            "Clicks": "6930",
            "Conversions": "90",
            "Conversion Value": "$12,898",
            "Cost per Result": "$41.24"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "188039",
            "Clicks": "4281",
            "Conversions": "76",
            "Conversion Value": "$15,614",
            "Cost per Result": "$36.61"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "256397",
            "Clicks": "6774",
            "Conversions": "49",
            "Conversion Value": "$4,758",
            "Cost per Result": "$18.44"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "167637",
            "Clicks": "6035",
            "Conversions": "78",
            "Conversion Value": "$4,237",
            "Cost per Result": "$29.15"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "427427",
            "Clicks": "5580",
            "Conversions": "78",
            "Conversion Value": "$4,461",
            "Cost per Result": "$32.38"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "106572",
            "Clicks": "2507",
            "Conversions": "57",
            "Conversion Value": "$14,726",
            "Cost per Result": "$33.01"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "353946",
            "Clicks": "6443",
            "Conversions": "41",
            "Conversion Value": "$15,482",
            "Cost per Result": "$28.92"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "154964",
            "Clicks": "2133",
            "Conversions": "74",
            "Conversion Value": "$6,758",
            "Cost per Result": "$57.81"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "221931",
            "Clicks": "6457",
            "Conversions": "53",
            "Conversion Value": "$4,137",
            "Cost per Result": "$26.20"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "122829",
            "Clicks": "3778",
            "Conversions": "74",
            "Conversion Value": "$12,165",
            "Cost per Result": "$57.79"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "190920",
            "Clicks": "2000",
            "Conversions": "81",
            "Conversion Value": "$7,827",
            "Cost per Result": "$17.43"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "141579",
            "Clicks": "4884",
            "Conversions": "65",
            "Conversion Value": "$6,009",
            "Cost per Result": "$28.52"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "127663",
            "Clicks": "6586",
            "Conversions": "87",
            "Conversion Value": "$5,498",
            "Cost per Result": "$32.30"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "308489",
            "Clicks": "3371",
            "Conversions": "59",
            "Conversion Value": "$5,902",
            "Cost per Result": "$49.98"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "136184",
            "Clicks": "2950",
            "Conversions": "65",
            "Conversion Value": "$5,940",
            "Cost per Result": "$55.65"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "226835",
            "Clicks": "2383",
            "Conversions": "58",
            "Conversion Value": "$11,323",
            "Cost per Result": "$21.44"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "442906",
            "Clicks": "6480",
            "Conversions": "49",
            "Conversion Value": "$10,110",
            "Cost per Result": "$51.59"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "316950",
            "Clicks": "5686",
            "Conversions": "76",
            "Conversion Value": "$4,373",
            "Cost per Result": "$43.84"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "343440",
            "Clicks": "4429",
            "Conversions": "52",
            "Conversion Value": "$6,698",
            "Cost per Result": "$24.20"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "315753",
            "Clicks": "4847",
            "Conversions": "63",
            "Conversion Value": "$8,903",
            "Cost per Result": "$64.65"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "257999",
            "Clicks": "4865",
            "Conversions": "61",
            "Conversion Value": "$6,459",
            "Cost per Result": "$43.39"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "350217",
            "Clicks": "4640",
            "Conversions": "89",
            "Conversion Value": "$9,495",
            "Cost per Result": "$27.55"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "474133",
            "Clicks": "6964",
            "Conversions": "77",
            "Conversion Value": "$11,829",
            "Cost per Result": "$22.20"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "440496",
            "Clicks": "4429",
            "Conversions": "66",
            "Conversion Value": "$10,856",
            "Cost per Result": "$53.59"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "358663",
            "Clicks": "3662",
            "Conversions": "66",
            "Conversion Value": "$10,891",
            "Cost per Result": "$25.57"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "469477",
            "Clicks": "2537",
            "Conversions": "65",
            "Conversion Value": "$13,691",
            "Cost per Result": "$48.37"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "156529",
            "Clicks": "5658",
            "Conversions": "69",
            "Conversion Value": "$10,138",
            "Cost per Result": "$35.64"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "186598",
            "Clicks": "3736",
            "Conversions": "82",
            "Conversion Value": "$9,386",
            "Cost per Result": "$54.68"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "305503",
            "Clicks": "4040",
            "Conversions": "54",
            "Conversion Value": "$7,375",
            "Cost per Result": "$18.09"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "265731",
            "Clicks": "3552",
            "Conversions": "66",
            "Conversion Value": "$13,833",
            "Cost per Result": "$34.23"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "206038",
            "Clicks": "5150",
            "Conversions": "87",
            "Conversion Value": "$11,712",
            "Cost per Result": "$61.84"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "411616",
            "Clicks": "5065",
            "Conversions": "69",
            "Conversion Value": "$13,456",
            "Cost per Result": "$23.33"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "400132",
            "Clicks": "5626",
            "Conversions": "53",
            "Conversion Value": "$6,460",
            "Cost per Result": "$18.65"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "140071",
            "Clicks": "5239",
            "Conversions": "44",
            "Conversion Value": "$7,931",
            "Cost per Result": "$55.41"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "318151",
            "Clicks": "6448",
            "Conversions": "89",
            "Conversion Value": "$9,954",
            "Cost per Result": "$56.77"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "108293",
            "Clicks": "3758",
            "Conversions": "42",
            "Conversion Value": "$12,015",
            "Cost per Result": "$17.93"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "215882",
            "Clicks": "5418",
            "Conversions": "43",
            "Conversion Value": "$7,239",
            "Cost per Result": "$23.02"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "438852",
            "Clicks": "3198",
            "Conversions": "56",
            "Conversion Value": "$10,250",
            "Cost per Result": "$61.46"
          }
        ]
      },
      "SOLTokenTradersData": {
        "name": "SOL Token Traders",
        "data": [
          {
            "Date": "01/01/2025",
            "Impressions": "474292",
            "Clicks": "4961",
            "Conversions": "66",
            "Conversion Value": "$5,756",
            "Cost per Result": "$62.10"
          },
          {
            "Date": "01/02/2025",
            "Impressions": "490382",
            "Clicks": "6151",
            "Conversions": "62",
            "Conversion Value": "$5,029",
            "Cost per Result": "$25.61"
          },
          {
            "Date": "01/03/2025",
            "Impressions": "284588",
            "Clicks": "3405",
            "Conversions": "56",
            "Conversion Value": "$10,994",
            "Cost per Result": "$21.48"
          },
          {
            "Date": "01/04/2025",
            "Impressions": "271991",
            "Clicks": "2993",
            "Conversions": "74",
            "Conversion Value": "$11,778",
            "Cost per Result": "$48.10"
          },
          {
            "Date": "01/05/2025",
            "Impressions": "289197",
            "Clicks": "5208",
            "Conversions": "79",
            "Conversion Value": "$12,697",
            "Cost per Result": "$29.39"
          },
          {
            "Date": "01/06/2025",
            "Impressions": "152592",
            "Clicks": "2914",
            "Conversions": "42",
            "Conversion Value": "$10,730",
            "Cost per Result": "$32.76"
          },
          {
            "Date": "01/07/2025",
            "Impressions": "189316",
            "Clicks": "2338",
            "Conversions": "50",
            "Conversion Value": "$13,745",
            "Cost per Result": "$17.10"
          },
          {
            "Date": "01/08/2025",
            "Impressions": "488025",
            "Clicks": "3288",
            "Conversions": "80",
            "Conversion Value": "$12,914",
            "Cost per Result": "$35.81"
          },
          {
            "Date": "01/09/2025",
            "Impressions": "112648",
            "Clicks": "2320",
            "Conversions": "46",
            "Conversion Value": "$8,534",
            "Cost per Result": "$39.45"
          },
          {
            "Date": "01/10/2025",
            "Impressions": "189846",
            "Clicks": "4643",
            "Conversions": "90",
            "Conversion Value": "$15,730",
            "Cost per Result": "$22.90"
          },
          {
            "Date": "01/11/2025",
            "Impressions": "111422",
            "Clicks": "3623",
            "Conversions": "51",
            "Conversion Value": "$11,623",
            "Cost per Result": "$21.41"
          },
          {
            "Date": "01/12/2025",
            "Impressions": "258550",
            "Clicks": "6423",
            "Conversions": "63",
            "Conversion Value": "$13,905",
            "Cost per Result": "$27.07"
          },
          {
            "Date": "01/13/2025",
            "Impressions": "154569",
            "Clicks": "3348",
            "Conversions": "71",
            "Conversion Value": "$15,954",
            "Cost per Result": "$15.83"
          },
          {
            "Date": "01/14/2025",
            "Impressions": "340583",
            "Clicks": "3756",
            "Conversions": "51",
            "Conversion Value": "$11,458",
            "Cost per Result": "$17.32"
          },
          {
            "Date": "01/15/2025",
            "Impressions": "164444",
            "Clicks": "4302",
            "Conversions": "55",
            "Conversion Value": "$5,720",
            "Cost per Result": "$37.48"
          },
          {
            "Date": "01/16/2025",
            "Impressions": "291301",
            "Clicks": "5677",
            "Conversions": "73",
            "Conversion Value": "$10,645",
            "Cost per Result": "$51.75"
          },
          {
            "Date": "01/17/2025",
            "Impressions": "248508",
            "Clicks": "6002",
            "Conversions": "57",
            "Conversion Value": "$15,921",
            "Cost per Result": "$53.08"
          },
          {
            "Date": "01/18/2025",
            "Impressions": "178834",
            "Clicks": "3541",
            "Conversions": "55",
            "Conversion Value": "$14,055",
            "Cost per Result": "$39.48"
          },
          {
            "Date": "01/19/2025",
            "Impressions": "359124",
            "Clicks": "4636",
            "Conversions": "64",
            "Conversion Value": "$8,901",
            "Cost per Result": "$41.31"
          },
          {
            "Date": "01/20/2025",
            "Impressions": "116847",
            "Clicks": "4910",
            "Conversions": "46",
            "Conversion Value": "$5,478",
            "Cost per Result": "$61.00"
          },
          {
            "Date": "01/21/2025",
            "Impressions": "122722",
            "Clicks": "2091",
            "Conversions": "43",
            "Conversion Value": "$12,520",
            "Cost per Result": "$21.51"
          },
          {
            "Date": "01/22/2025",
            "Impressions": "337959",
            "Clicks": "4515",
            "Conversions": "90",
            "Conversion Value": "$6,759",
            "Cost per Result": "$32.03"
          },
          {
            "Date": "01/23/2025",
            "Impressions": "436789",
            "Clicks": "6725",
            "Conversions": "61",
            "Conversion Value": "$12,044",
            "Cost per Result": "$17.47"
          },
          {
            "Date": "01/24/2025",
            "Impressions": "416204",
            "Clicks": "3011",
            "Conversions": "74",
            "Conversion Value": "$12,791",
            "Cost per Result": "$38.47"
          },
          {
            "Date": "01/25/2025",
            "Impressions": "244594",
            "Clicks": "3474",
            "Conversions": "52",
            "Conversion Value": "$9,216",
            "Cost per Result": "$22.86"
          },
          {
            "Date": "01/26/2025",
            "Impressions": "449480",
            "Clicks": "2165",
            "Conversions": "58",
            "Conversion Value": "$8,851",
            "Cost per Result": "$37.19"
          },
          {
            "Date": "01/27/2025",
            "Impressions": "249571",
            "Clicks": "6715",
            "Conversions": "82",
            "Conversion Value": "$11,960",
            "Cost per Result": "$45.97"
          },
          {
            "Date": "01/28/2025",
            "Impressions": "285923",
            "Clicks": "6913",
            "Conversions": "42",
            "Conversion Value": "$5,604",
            "Cost per Result": "$63.70"
          },
          {
            "Date": "01/29/2025",
            "Impressions": "291364",
            "Clicks": "4272",
            "Conversions": "47",
            "Conversion Value": "$9,607",
            "Cost per Result": "$52.76"
          },
          {
            "Date": "01/30/2025",
            "Impressions": "417304",
            "Clicks": "5873",
            "Conversions": "80",
            "Conversion Value": "$6,051",
            "Cost per Result": "$64.08"
          },
          {
            "Date": "01/31/2025",
            "Impressions": "122692",
            "Clicks": "4398",
            "Conversions": "82",
            "Conversion Value": "$10,030",
            "Cost per Result": "$23.61"
          },
          {
            "Date": "02/01/2025",
            "Impressions": "294768",
            "Clicks": "4608",
            "Conversions": "72",
            "Conversion Value": "$14,311",
            "Cost per Result": "$36.79"
          },
          {
            "Date": "02/02/2025",
            "Impressions": "130244",
            "Clicks": "5932",
            "Conversions": "81",
            "Conversion Value": "$15,114",
            "Cost per Result": "$29.16"
          },
          {
            "Date": "02/03/2025",
            "Impressions": "405792",
            "Clicks": "5129",
            "Conversions": "81",
            "Conversion Value": "$13,942",
            "Cost per Result": "$64.46"
          },
          {
            "Date": "02/04/2025",
            "Impressions": "445350",
            "Clicks": "5353",
            "Conversions": "46",
            "Conversion Value": "$4,360",
            "Cost per Result": "$34.48"
          },
          {
            "Date": "02/05/2025",
            "Impressions": "175447",
            "Clicks": "5044",
            "Conversions": "90",
            "Conversion Value": "$13,292",
            "Cost per Result": "$55.39"
          },
          {
            "Date": "02/06/2025",
            "Impressions": "483573",
            "Clicks": "2751",
            "Conversions": "46",
            "Conversion Value": "$15,238",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "02/07/2025",
            "Impressions": "240875",
            "Clicks": "4942",
            "Conversions": "72",
            "Conversion Value": "$13,590",
            "Cost per Result": "$16.90"
          },
          {
            "Date": "02/08/2025",
            "Impressions": "210583",
            "Clicks": "2819",
            "Conversions": "73",
            "Conversion Value": "$5,894",
            "Cost per Result": "$61.02"
          },
          {
            "Date": "02/09/2025",
            "Impressions": "346907",
            "Clicks": "4215",
            "Conversions": "65",
            "Conversion Value": "$4,685",
            "Cost per Result": "$24.53"
          },
          {
            "Date": "02/10/2025",
            "Impressions": "409717",
            "Clicks": "4896",
            "Conversions": "65",
            "Conversion Value": "$9,125",
            "Cost per Result": "$34.33"
          },
          {
            "Date": "02/11/2025",
            "Impressions": "406588",
            "Clicks": "2670",
            "Conversions": "82",
            "Conversion Value": "$8,915",
            "Cost per Result": "$32.53"
          },
          {
            "Date": "02/12/2025",
            "Impressions": "160826",
            "Clicks": "4439",
            "Conversions": "73",
            "Conversion Value": "$10,472",
            "Cost per Result": "$49.21"
          },
          {
            "Date": "02/13/2025",
            "Impressions": "377095",
            "Clicks": "6242",
            "Conversions": "73",
            "Conversion Value": "$10,925",
            "Cost per Result": "$27.53"
          },
          {
            "Date": "02/14/2025",
            "Impressions": "348052",
            "Clicks": "3542",
            "Conversions": "55",
            "Conversion Value": "$4,130",
            "Cost per Result": "$47.32"
          },
          {
            "Date": "02/15/2025",
            "Impressions": "465122",
            "Clicks": "3386",
            "Conversions": "49",
            "Conversion Value": "$4,720",
            "Cost per Result": "$39.95"
          },
          {
            "Date": "02/16/2025",
            "Impressions": "394664",
            "Clicks": "2609",
            "Conversions": "66",
            "Conversion Value": "$14,227",
            "Cost per Result": "$59.15"
          },
          {
            "Date": "02/17/2025",
            "Impressions": "173084",
            "Clicks": "4734",
            "Conversions": "79",
            "Conversion Value": "$14,915",
            "Cost per Result": "$41.47"
          },
          {
            "Date": "02/18/2025",
            "Impressions": "356366",
            "Clicks": "3702",
            "Conversions": "43",
            "Conversion Value": "$7,922",
            "Cost per Result": "$28.63"
          },
          {
            "Date": "02/19/2025",
            "Impressions": "136674",
            "Clicks": "5286",
            "Conversions": "63",
            "Conversion Value": "$8,440",
            "Cost per Result": "$35.66"
          },
          {
            "Date": "02/20/2025",
            "Impressions": "351663",
            "Clicks": "6636",
            "Conversions": "77",
            "Conversion Value": "$4,959",
            "Cost per Result": "$44.41"
          },
          {
            "Date": "02/21/2025",
            "Impressions": "324864",
            "Clicks": "3296",
            "Conversions": "67",
            "Conversion Value": "$14,830",
            "Cost per Result": "$43.69"
          },
          {
            "Date": "02/22/2025",
            "Impressions": "111676",
            "Clicks": "4522",
            "Conversions": "59",
            "Conversion Value": "$13,371",
            "Cost per Result": "$53.45"
          },
          {
            "Date": "02/23/2025",
            "Impressions": "246104",
            "Clicks": "5572",
            "Conversions": "70",
            "Conversion Value": "$5,729",
            "Cost per Result": "$46.47"
          },
          {
            "Date": "02/24/2025",
            "Impressions": "215955",
            "Clicks": "5116",
            "Conversions": "67",
            "Conversion Value": "$9,326",
            "Cost per Result": "$62.34"
          },
          {
            "Date": "02/25/2025",
            "Impressions": "231112",
            "Clicks": "4573",
            "Conversions": "76",
            "Conversion Value": "$13,546",
            "Cost per Result": "$27.20"
          },
          {
            "Date": "02/26/2025",
            "Impressions": "250489",
            "Clicks": "4297",
            "Conversions": "87",
            "Conversion Value": "$14,080",
            "Cost per Result": "$15.46"
          },
          {
            "Date": "02/27/2025",
            "Impressions": "476579",
            "Clicks": "5714",
            "Conversions": "54",
            "Conversion Value": "$5,981",
            "Cost per Result": "$44.24"
          },
          {
            "Date": "02/28/2025",
            "Impressions": "165589",
            "Clicks": "2967",
            "Conversions": "62",
            "Conversion Value": "$4,654",
            "Cost per Result": "$20.31"
          },
          {
            "Date": "03/01/2025",
            "Impressions": "399658",
            "Clicks": "4851",
            "Conversions": "41",
            "Conversion Value": "$8,529",
            "Cost per Result": "$20.93"
          },
          {
            "Date": "03/02/2025",
            "Impressions": "492103",
            "Clicks": "3230",
            "Conversions": "55",
            "Conversion Value": "$14,847",
            "Cost per Result": "$48.13"
          },
          {
            "Date": "03/03/2025",
            "Impressions": "366123",
            "Clicks": "2971",
            "Conversions": "56",
            "Conversion Value": "$14,624",
            "Cost per Result": "$29.90"
          },
          {
            "Date": "03/04/2025",
            "Impressions": "343499",
            "Clicks": "4286",
            "Conversions": "89",
            "Conversion Value": "$15,153",
            "Cost per Result": "$61.91"
          },
          {
            "Date": "03/05/2025",
            "Impressions": "242348",
            "Clicks": "5890",
            "Conversions": "41",
            "Conversion Value": "$10,822",
            "Cost per Result": "$48.03"
          },
          {
            "Date": "03/06/2025",
            "Impressions": "318671",
            "Clicks": "6006",
            "Conversions": "89",
            "Conversion Value": "$7,707",
            "Cost per Result": "$63.53"
          },
          {
            "Date": "03/07/2025",
            "Impressions": "441164",
            "Clicks": "2604",
            "Conversions": "83",
            "Conversion Value": "$4,753",
            "Cost per Result": "$62.53"
          },
          {
            "Date": "03/08/2025",
            "Impressions": "337242",
            "Clicks": "4826",
            "Conversions": "59",
            "Conversion Value": "$13,016",
            "Cost per Result": "$40.76"
          },
          {
            "Date": "03/09/2025",
            "Impressions": "373442",
            "Clicks": "2257",
            "Conversions": "81",
            "Conversion Value": "$5,316",
            "Cost per Result": "$49.25"
          },
          {
            "Date": "03/10/2025",
            "Impressions": "378284",
            "Clicks": "3576",
            "Conversions": "47",
            "Conversion Value": "$6,438",
            "Cost per Result": "$55.07"
          },
          {
            "Date": "03/11/2025",
            "Impressions": "201888",
            "Clicks": "2233",
            "Conversions": "45",
            "Conversion Value": "$7,232",
            "Cost per Result": "$51.54"
          },
          {
            "Date": "03/12/2025",
            "Impressions": "328534",
            "Clicks": "6173",
            "Conversions": "60",
            "Conversion Value": "$11,611",
            "Cost per Result": "$19.45"
          },
          {
            "Date": "03/13/2025",
            "Impressions": "472640",
            "Clicks": "6224",
            "Conversions": "62",
            "Conversion Value": "$13,073",
            "Cost per Result": "$51.18"
          },
          {
            "Date": "03/14/2025",
            "Impressions": "432418",
            "Clicks": "4176",
            "Conversions": "65",
            "Conversion Value": "$14,440",
            "Cost per Result": "$15.93"
          },
          {
            "Date": "03/15/2025",
            "Impressions": "457419",
            "Clicks": "6421",
            "Conversions": "58",
            "Conversion Value": "$15,786",
            "Cost per Result": "$55.07"
          },
          {
            "Date": "03/16/2025",
            "Impressions": "109878",
            "Clicks": "2875",
            "Conversions": "57",
            "Conversion Value": "$6,428",
            "Cost per Result": "$15.05"
          },
          {
            "Date": "03/17/2025",
            "Impressions": "188292",
            "Clicks": "2919",
            "Conversions": "43",
            "Conversion Value": "$9,741",
            "Cost per Result": "$18.45"
          },
          {
            "Date": "03/18/2025",
            "Impressions": "186691",
            "Clicks": "2460",
            "Conversions": "64",
            "Conversion Value": "$10,944",
            "Cost per Result": "$51.35"
          },
          {
            "Date": "03/19/2025",
            "Impressions": "454521",
            "Clicks": "5631",
            "Conversions": "48",
            "Conversion Value": "$11,957",
            "Cost per Result": "$34.81"
          },
          {
            "Date": "03/20/2025",
            "Impressions": "464462",
            "Clicks": "3659",
            "Conversions": "58",
            "Conversion Value": "$9,916",
            "Cost per Result": "$43.11"
          },
          {
            "Date": "03/21/2025",
            "Impressions": "133300",
            "Clicks": "6272",
            "Conversions": "74",
            "Conversion Value": "$12,452",
            "Cost per Result": "$47.56"
          },
          {
            "Date": "03/22/2025",
            "Impressions": "323918",
            "Clicks": "2851",
            "Conversions": "53",
            "Conversion Value": "$6,288",
            "Cost per Result": "$58.83"
          },
          {
            "Date": "03/23/2025",
            "Impressions": "151755",
            "Clicks": "4415",
            "Conversions": "59",
            "Conversion Value": "$13,966",
            "Cost per Result": "$58.48"
          },
          {
            "Date": "03/24/2025",
            "Impressions": "366794",
            "Clicks": "3559",
            "Conversions": "64",
            "Conversion Value": "$10,564",
            "Cost per Result": "$37.16"
          },
          {
            "Date": "03/25/2025",
            "Impressions": "365639",
            "Clicks": "6006",
            "Conversions": "86",
            "Conversion Value": "$10,621",
            "Cost per Result": "$35.01"
          },
          {
            "Date": "03/26/2025",
            "Impressions": "101058",
            "Clicks": "3608",
            "Conversions": "68",
            "Conversion Value": "$10,724",
            "Cost per Result": "$17.50"
          },
          {
            "Date": "03/27/2025",
            "Impressions": "461708",
            "Clicks": "3814",
            "Conversions": "42",
            "Conversion Value": "$11,558",
            "Cost per Result": "$64.25"
          },
          {
            "Date": "03/28/2025",
            "Impressions": "285404",
            "Clicks": "5261",
            "Conversions": "56",
            "Conversion Value": "$4,770",
            "Cost per Result": "$41.12"
          },
          {
            "Date": "03/29/2025",
            "Impressions": "447450",
            "Clicks": "2811",
            "Conversions": "71",
            "Conversion Value": "$8,128",
            "Cost per Result": "$36.30"
          },
          {
            "Date": "03/30/2025",
            "Impressions": "176720",
            "Clicks": "6902",
            "Conversions": "47",
            "Conversion Value": "$6,831",
            "Cost per Result": "$64.46"
          },
          {
            "Date": "03/31/2025",
            "Impressions": "117101",
            "Clicks": "2709",
            "Conversions": "66",
            "Conversion Value": "$13,897",
            "Cost per Result": "$57.48"
          },
          {
            "Date": "04/01/2025",
            "Impressions": "155230",
            "Clicks": "5215",
            "Conversions": "41",
            "Conversion Value": "$6,056",
            "Cost per Result": "$38.05"
          },
          {
            "Date": "04/02/2025",
            "Impressions": "241024",
            "Clicks": "2648",
            "Conversions": "43",
            "Conversion Value": "$9,197",
            "Cost per Result": "$23.36"
          },
          {
            "Date": "04/03/2025",
            "Impressions": "103453",
            "Clicks": "2157",
            "Conversions": "69",
            "Conversion Value": "$5,050",
            "Cost per Result": "$43.05"
          },
          {
            "Date": "04/04/2025",
            "Impressions": "212329",
            "Clicks": "2069",
            "Conversions": "83",
            "Conversion Value": "$9,311",
            "Cost per Result": "$49.17"
          },
          {
            "Date": "04/05/2025",
            "Impressions": "336177",
            "Clicks": "3090",
            "Conversions": "44",
            "Conversion Value": "$6,079",
            "Cost per Result": "$39.88"
          },
          {
            "Date": "04/06/2025",
            "Impressions": "427024",
            "Clicks": "2026",
            "Conversions": "55",
            "Conversion Value": "$10,049",
            "Cost per Result": "$47.18"
          },
          {
            "Date": "04/07/2025",
            "Impressions": "349157",
            "Clicks": "5259",
            "Conversions": "50",
            "Conversion Value": "$7,217",
            "Cost per Result": "$36.41"
          },
          {
            "Date": "04/08/2025",
            "Impressions": "430133",
            "Clicks": "4842",
            "Conversions": "83",
            "Conversion Value": "$10,453",
            "Cost per Result": "$17.06"
          },
          {
            "Date": "04/09/2025",
            "Impressions": "285617",
            "Clicks": "6983",
            "Conversions": "66",
            "Conversion Value": "$5,696",
            "Cost per Result": "$19.14"
          },
          {
            "Date": "04/10/2025",
            "Impressions": "319688",
            "Clicks": "4021",
            "Conversions": "80",
            "Conversion Value": "$5,026",
            "Cost per Result": "$19.11"
          },
          {
            "Date": "04/11/2025",
            "Impressions": "404499",
            "Clicks": "4766",
            "Conversions": "75",
            "Conversion Value": "$8,149",
            "Cost per Result": "$46.34"
          },
          {
            "Date": "04/12/2025",
            "Impressions": "483856",
            "Clicks": "2524",
            "Conversions": "75",
            "Conversion Value": "$11,918",
            "Cost per Result": "$25.21"
          },
          {
            "Date": "04/13/2025",
            "Impressions": "346490",
            "Clicks": "6343",
            "Conversions": "78",
            "Conversion Value": "$7,951",
            "Cost per Result": "$15.61"
          },
          {
            "Date": "04/14/2025",
            "Impressions": "305757",
            "Clicks": "4741",
            "Conversions": "67",
            "Conversion Value": "$15,373",
            "Cost per Result": "$39.43"
          },
          {
            "Date": "04/15/2025",
            "Impressions": "288315",
            "Clicks": "3163",
            "Conversions": "60",
            "Conversion Value": "$10,487",
            "Cost per Result": "$46.90"
          },
          {
            "Date": "04/16/2025",
            "Impressions": "388921",
            "Clicks": "2299",
            "Conversions": "90",
            "Conversion Value": "$11,876",
            "Cost per Result": "$24.69"
          },
          {
            "Date": "04/17/2025",
            "Impressions": "188923",
            "Clicks": "5014",
            "Conversions": "81",
            "Conversion Value": "$12,417",
            "Cost per Result": "$47.77"
          },
          {
            "Date": "04/18/2025",
            "Impressions": "348286",
            "Clicks": "6914",
            "Conversions": "69",
            "Conversion Value": "$9,234",
            "Cost per Result": "$23.63"
          },
          {
            "Date": "04/19/2025",
            "Impressions": "180553",
            "Clicks": "6297",
            "Conversions": "43",
            "Conversion Value": "$12,908",
            "Cost per Result": "$29.93"
          },
          {
            "Date": "04/20/2025",
            "Impressions": "222842",
            "Clicks": "5978",
            "Conversions": "75",
            "Conversion Value": "$8,507",
            "Cost per Result": "$28.90"
          },
          {
            "Date": "04/21/2025",
            "Impressions": "242172",
            "Clicks": "2891",
            "Conversions": "59",
            "Conversion Value": "$7,709",
            "Cost per Result": "$51.73"
          },
          {
            "Date": "04/22/2025",
            "Impressions": "473469",
            "Clicks": "6307",
            "Conversions": "46",
            "Conversion Value": "$10,689",
            "Cost per Result": "$57.83"
          },
          {
            "Date": "04/23/2025",
            "Impressions": "392377",
            "Clicks": "2596",
            "Conversions": "82",
            "Conversion Value": "$8,676",
            "Cost per Result": "$18.70"
          },
          {
            "Date": "04/24/2025",
            "Impressions": "441346",
            "Clicks": "3891",
            "Conversions": "40",
            "Conversion Value": "$12,279",
            "Cost per Result": "$59.21"
          },
          {
            "Date": "04/25/2025",
            "Impressions": "314974",
            "Clicks": "6671",
            "Conversions": "70",
            "Conversion Value": "$14,267",
            "Cost per Result": "$57.53"
          },
          {
            "Date": "04/26/2025",
            "Impressions": "398065",
            "Clicks": "2688",
            "Conversions": "87",
            "Conversion Value": "$13,732",
            "Cost per Result": "$52.46"
          },
          {
            "Date": "04/27/2025",
            "Impressions": "225657",
            "Clicks": "5346",
            "Conversions": "42",
            "Conversion Value": "$8,251",
            "Cost per Result": "$55.37"
          },
          {
            "Date": "04/28/2025",
            "Impressions": "388822",
            "Clicks": "3323",
            "Conversions": "79",
            "Conversion Value": "$4,104",
            "Cost per Result": "$43.17"
          },
          {
            "Date": "04/29/2025",
            "Impressions": "126261",
            "Clicks": "2854",
            "Conversions": "77",
            "Conversion Value": "$10,650",
            "Cost per Result": "$46.26"
          },
          {
            "Date": "04/30/2025",
            "Impressions": "256950",
            "Clicks": "5161",
            "Conversions": "77",
            "Conversion Value": "$8,137",
            "Cost per Result": "$59.52"
          },
          {
            "Date": "05/01/2025",
            "Impressions": "279128",
            "Clicks": "6299",
            "Conversions": "54",
            "Conversion Value": "$12,197",
            "Cost per Result": "$16.76"
          },
          {
            "Date": "05/02/2025",
            "Impressions": "174557",
            "Clicks": "2005",
            "Conversions": "48",
            "Conversion Value": "$5,414",
            "Cost per Result": "$15.54"
          },
          {
            "Date": "05/03/2025",
            "Impressions": "454501",
            "Clicks": "4722",
            "Conversions": "44",
            "Conversion Value": "$7,636",
            "Cost per Result": "$59.48"
          },
          {
            "Date": "05/04/2025",
            "Impressions": "354793",
            "Clicks": "6749",
            "Conversions": "68",
            "Conversion Value": "$4,005",
            "Cost per Result": "$22.21"
          },
          {
            "Date": "05/05/2025",
            "Impressions": "426068",
            "Clicks": "2160",
            "Conversions": "66",
            "Conversion Value": "$9,156",
            "Cost per Result": "$33.53"
          },
          {
            "Date": "05/06/2025",
            "Impressions": "381919",
            "Clicks": "2723",
            "Conversions": "87",
            "Conversion Value": "$12,918",
            "Cost per Result": "$24.17"
          },
          {
            "Date": "05/07/2025",
            "Impressions": "262738",
            "Clicks": "4207",
            "Conversions": "57",
            "Conversion Value": "$7,287",
            "Cost per Result": "$56.24"
          },
          {
            "Date": "05/08/2025",
            "Impressions": "192775",
            "Clicks": "5299",
            "Conversions": "54",
            "Conversion Value": "$6,520",
            "Cost per Result": "$37.76"
          },
          {
            "Date": "05/09/2025",
            "Impressions": "218466",
            "Clicks": "2032",
            "Conversions": "83",
            "Conversion Value": "$13,979",
            "Cost per Result": "$27.06"
          },
          {
            "Date": "05/10/2025",
            "Impressions": "335810",
            "Clicks": "5075",
            "Conversions": "82",
            "Conversion Value": "$12,572",
            "Cost per Result": "$26.84"
          },
          {
            "Date": "05/11/2025",
            "Impressions": "467777",
            "Clicks": "4725",
            "Conversions": "46",
            "Conversion Value": "$6,952",
            "Cost per Result": "$32.10"
          },
          {
            "Date": "05/12/2025",
            "Impressions": "110774",
            "Clicks": "6893",
            "Conversions": "66",
            "Conversion Value": "$10,092",
            "Cost per Result": "$33.10"
          },
          {
            "Date": "05/13/2025",
            "Impressions": "265182",
            "Clicks": "3732",
            "Conversions": "40",
            "Conversion Value": "$13,283",
            "Cost per Result": "$39.50"
          },
          {
            "Date": "05/14/2025",
            "Impressions": "474488",
            "Clicks": "5007",
            "Conversions": "52",
            "Conversion Value": "$15,803",
            "Cost per Result": "$32.22"
          },
          {
            "Date": "05/15/2025",
            "Impressions": "195746",
            "Clicks": "6816",
            "Conversions": "73",
            "Conversion Value": "$8,808",
            "Cost per Result": "$28.50"
          },
          {
            "Date": "05/16/2025",
            "Impressions": "337336",
            "Clicks": "4167",
            "Conversions": "43",
            "Conversion Value": "$11,539",
            "Cost per Result": "$30.74"
          },
          {
            "Date": "05/17/2025",
            "Impressions": "186702",
            "Clicks": "5010",
            "Conversions": "67",
            "Conversion Value": "$13,856",
            "Cost per Result": "$62.72"
          },
          {
            "Date": "05/18/2025",
            "Impressions": "154983",
            "Clicks": "6819",
            "Conversions": "86",
            "Conversion Value": "$4,453",
            "Cost per Result": "$38.08"
          },
          {
            "Date": "05/19/2025",
            "Impressions": "402618",
            "Clicks": "5098",
            "Conversions": "89",
            "Conversion Value": "$14,082",
            "Cost per Result": "$20.91"
          },
          {
            "Date": "05/20/2025",
            "Impressions": "454241",
            "Clicks": "5184",
            "Conversions": "53",
            "Conversion Value": "$13,341",
            "Cost per Result": "$16.89"
          },
          {
            "Date": "05/21/2025",
            "Impressions": "266677",
            "Clicks": "2092",
            "Conversions": "85",
            "Conversion Value": "$5,235",
            "Cost per Result": "$23.01"
          },
          {
            "Date": "05/22/2025",
            "Impressions": "167093",
            "Clicks": "5957",
            "Conversions": "50",
            "Conversion Value": "$9,270",
            "Cost per Result": "$52.25"
          },
          {
            "Date": "05/23/2025",
            "Impressions": "237094",
            "Clicks": "2464",
            "Conversions": "54",
            "Conversion Value": "$13,483",
            "Cost per Result": "$28.78"
          },
          {
            "Date": "05/24/2025",
            "Impressions": "459425",
            "Clicks": "3731",
            "Conversions": "52",
            "Conversion Value": "$12,241",
            "Cost per Result": "$16.74"
          },
          {
            "Date": "05/25/2025",
            "Impressions": "300483",
            "Clicks": "2630",
            "Conversions": "50",
            "Conversion Value": "$15,678",
            "Cost per Result": "$49.88"
          },
          {
            "Date": "05/26/2025",
            "Impressions": "139106",
            "Clicks": "4069",
            "Conversions": "84",
            "Conversion Value": "$7,894",
            "Cost per Result": "$47.80"
          },
          {
            "Date": "05/27/2025",
            "Impressions": "284587",
            "Clicks": "5575",
            "Conversions": "51",
            "Conversion Value": "$7,678",
            "Cost per Result": "$40.67"
          },
          {
            "Date": "05/28/2025",
            "Impressions": "351449",
            "Clicks": "4223",
            "Conversions": "72",
            "Conversion Value": "$9,587",
            "Cost per Result": "$32.60"
          },
          {
            "Date": "05/29/2025",
            "Impressions": "383663",
            "Clicks": "3101",
            "Conversions": "84",
            "Conversion Value": "$12,403",
            "Cost per Result": "$48.57"
          },
          {
            "Date": "05/30/2025",
            "Impressions": "489144",
            "Clicks": "5451",
            "Conversions": "62",
            "Conversion Value": "$12,389",
            "Cost per Result": "$51.58"
          },
          {
            "Date": "05/31/2025",
            "Impressions": "254856",
            "Clicks": "4747",
            "Conversions": "64",
            "Conversion Value": "$7,759",
            "Cost per Result": "$43.54"
          },
          {
            "Date": "06/01/2025",
            "Impressions": "460681",
            "Clicks": "3726",
            "Conversions": "54",
            "Conversion Value": "$5,814",
            "Cost per Result": "$58.92"
          },
          {
            "Date": "06/02/2025",
            "Impressions": "488134",
            "Clicks": "6953",
            "Conversions": "54",
            "Conversion Value": "$8,637",
            "Cost per Result": "$58.49"
          },
          {
            "Date": "06/03/2025",
            "Impressions": "209174",
            "Clicks": "6266",
            "Conversions": "46",
            "Conversion Value": "$7,290",
            "Cost per Result": "$47.02"
          },
          {
            "Date": "06/04/2025",
            "Impressions": "362195",
            "Clicks": "6041",
            "Conversions": "89",
            "Conversion Value": "$15,711",
            "Cost per Result": "$35.98"
          },
          {
            "Date": "06/05/2025",
            "Impressions": "416797",
            "Clicks": "4934",
            "Conversions": "52",
            "Conversion Value": "$4,328",
            "Cost per Result": "$54.42"
          },
          {
            "Date": "06/06/2025",
            "Impressions": "241673",
            "Clicks": "4266",
            "Conversions": "78",
            "Conversion Value": "$15,389",
            "Cost per Result": "$61.26"
          },
          {
            "Date": "06/07/2025",
            "Impressions": "364490",
            "Clicks": "4625",
            "Conversions": "83",
            "Conversion Value": "$8,942",
            "Cost per Result": "$35.40"
          },
          {
            "Date": "06/08/2025",
            "Impressions": "395880",
            "Clicks": "3894",
            "Conversions": "58",
            "Conversion Value": "$12,777",
            "Cost per Result": "$41.39"
          },
          {
            "Date": "06/09/2025",
            "Impressions": "269188",
            "Clicks": "2341",
            "Conversions": "56",
            "Conversion Value": "$9,805",
            "Cost per Result": "$25.81"
          },
          {
            "Date": "06/10/2025",
            "Impressions": "156243",
            "Clicks": "6557",
            "Conversions": "45",
            "Conversion Value": "$7,290",
            "Cost per Result": "$55.41"
          },
          {
            "Date": "06/11/2025",
            "Impressions": "349609",
            "Clicks": "5910",
            "Conversions": "62",
            "Conversion Value": "$9,584",
            "Cost per Result": "$47.01"
          },
          {
            "Date": "06/12/2025",
            "Impressions": "289925",
            "Clicks": "3958",
            "Conversions": "44",
            "Conversion Value": "$13,395",
            "Cost per Result": "$18.49"
          },
          {
            "Date": "06/13/2025",
            "Impressions": "466252",
            "Clicks": "4268",
            "Conversions": "77",
            "Conversion Value": "$6,528",
            "Cost per Result": "$59.33"
          },
          {
            "Date": "06/14/2025",
            "Impressions": "243298",
            "Clicks": "6368",
            "Conversions": "83",
            "Conversion Value": "$10,998",
            "Cost per Result": "$37.14"
          },
          {
            "Date": "06/15/2025",
            "Impressions": "462305",
            "Clicks": "3520",
            "Conversions": "82",
            "Conversion Value": "$9,213",
            "Cost per Result": "$22.83"
          },
          {
            "Date": "06/16/2025",
            "Impressions": "475027",
            "Clicks": "2911",
            "Conversions": "72",
            "Conversion Value": "$10,035",
            "Cost per Result": "$43.64"
          },
          {
            "Date": "06/17/2025",
            "Impressions": "151576",
            "Clicks": "5831",
            "Conversions": "83",
            "Conversion Value": "$15,904",
            "Cost per Result": "$25.98"
          },
          {
            "Date": "06/18/2025",
            "Impressions": "309305",
            "Clicks": "5700",
            "Conversions": "90",
            "Conversion Value": "$11,766",
            "Cost per Result": "$19.95"
          },
          {
            "Date": "06/19/2025",
            "Impressions": "240634",
            "Clicks": "5567",
            "Conversions": "62",
            "Conversion Value": "$8,672",
            "Cost per Result": "$49.94"
          },
          {
            "Date": "06/20/2025",
            "Impressions": "231883",
            "Clicks": "4855",
            "Conversions": "89",
            "Conversion Value": "$15,784",
            "Cost per Result": "$61.17"
          },
          {
            "Date": "06/21/2025",
            "Impressions": "349426",
            "Clicks": "2426",
            "Conversions": "41",
            "Conversion Value": "$6,643",
            "Cost per Result": "$34.08"
          },
          {
            "Date": "06/22/2025",
            "Impressions": "242278",
            "Clicks": "4944",
            "Conversions": "79",
            "Conversion Value": "$12,778",
            "Cost per Result": "$39.59"
          },
          {
            "Date": "06/23/2025",
            "Impressions": "453551",
            "Clicks": "2893",
            "Conversions": "89",
            "Conversion Value": "$10,731",
            "Cost per Result": "$28.82"
          },
          {
            "Date": "06/24/2025",
            "Impressions": "463941",
            "Clicks": "2607",
            "Conversions": "65",
            "Conversion Value": "$14,951",
            "Cost per Result": "$15.70"
          },
          {
            "Date": "06/25/2025",
            "Impressions": "488898",
            "Clicks": "4830",
            "Conversions": "49",
            "Conversion Value": "$10,371",
            "Cost per Result": "$57.79"
          },
          {
            "Date": "06/26/2025",
            "Impressions": "280212",
            "Clicks": "4934",
            "Conversions": "85",
            "Conversion Value": "$4,627",
            "Cost per Result": "$24.37"
          },
          {
            "Date": "06/27/2025",
            "Impressions": "443912",
            "Clicks": "3222",
            "Conversions": "69",
            "Conversion Value": "$8,095",
            "Cost per Result": "$29.00"
          },
          {
            "Date": "06/28/2025",
            "Impressions": "168894",
            "Clicks": "6604",
            "Conversions": "59",
            "Conversion Value": "$5,221",
            "Cost per Result": "$43.02"
          },
          {
            "Date": "06/29/2025",
            "Impressions": "155600",
            "Clicks": "5508",
            "Conversions": "54",
            "Conversion Value": "$11,499",
            "Cost per Result": "$33.61"
          },
          {
            "Date": "06/30/2025",
            "Impressions": "194866",
            "Clicks": "3893",
            "Conversions": "64",
            "Conversion Value": "$5,091",
            "Cost per Result": "$15.24"
          },
          {
            "Date": "07/01/2025",
            "Impressions": "493351",
            "Clicks": "5134",
            "Conversions": "53",
            "Conversion Value": "$12,366",
            "Cost per Result": "$49.84"
          },
          {
            "Date": "07/02/2025",
            "Impressions": "212168",
            "Clicks": "4364",
            "Conversions": "58",
            "Conversion Value": "$6,036",
            "Cost per Result": "$54.00"
          },
          {
            "Date": "07/03/2025",
            "Impressions": "161617",
            "Clicks": "6362",
            "Conversions": "49",
            "Conversion Value": "$9,258",
            "Cost per Result": "$37.73"
          },
          {
            "Date": "07/04/2025",
            "Impressions": "129442",
            "Clicks": "3593",
            "Conversions": "81",
            "Conversion Value": "$7,901",
            "Cost per Result": "$44.40"
          },
          {
            "Date": "07/05/2025",
            "Impressions": "211732",
            "Clicks": "4062",
            "Conversions": "40",
            "Conversion Value": "$11,868",
            "Cost per Result": "$47.27"
          },
          {
            "Date": "07/06/2025",
            "Impressions": "191849",
            "Clicks": "4463",
            "Conversions": "54",
            "Conversion Value": "$8,932",
            "Cost per Result": "$49.82"
          },
          {
            "Date": "07/07/2025",
            "Impressions": "389009",
            "Clicks": "5686",
            "Conversions": "53",
            "Conversion Value": "$7,603",
            "Cost per Result": "$35.80"
          },
          {
            "Date": "07/08/2025",
            "Impressions": "496275",
            "Clicks": "4756",
            "Conversions": "75",
            "Conversion Value": "$13,705",
            "Cost per Result": "$25.91"
          },
          {
            "Date": "07/09/2025",
            "Impressions": "249578",
            "Clicks": "5483",
            "Conversions": "48",
            "Conversion Value": "$11,112",
            "Cost per Result": "$63.63"
          },
          {
            "Date": "07/10/2025",
            "Impressions": "233443",
            "Clicks": "5751",
            "Conversions": "69",
            "Conversion Value": "$8,438",
            "Cost per Result": "$55.01"
          },
          {
            "Date": "07/11/2025",
            "Impressions": "389027",
            "Clicks": "6782",
            "Conversions": "69",
            "Conversion Value": "$11,478",
            "Cost per Result": "$33.42"
          },
          {
            "Date": "07/12/2025",
            "Impressions": "270718",
            "Clicks": "5811",
            "Conversions": "49",
            "Conversion Value": "$10,728",
            "Cost per Result": "$27.21"
          },
          {
            "Date": "07/13/2025",
            "Impressions": "290076",
            "Clicks": "2985",
            "Conversions": "50",
            "Conversion Value": "$4,308",
            "Cost per Result": "$56.62"
          },
          {
            "Date": "07/14/2025",
            "Impressions": "264119",
            "Clicks": "5098",
            "Conversions": "67",
            "Conversion Value": "$8,942",
            "Cost per Result": "$48.98"
          },
          {
            "Date": "07/15/2025",
            "Impressions": "408702",
            "Clicks": "2309",
            "Conversions": "74",
            "Conversion Value": "$15,530",
            "Cost per Result": "$49.81"
          },
          {
            "Date": "07/16/2025",
            "Impressions": "210015",
            "Clicks": "6445",
            "Conversions": "44",
            "Conversion Value": "$14,881",
            "Cost per Result": "$25.21"
          },
          {
            "Date": "07/17/2025",
            "Impressions": "497972",
            "Clicks": "5990",
            "Conversions": "75",
            "Conversion Value": "$9,927",
            "Cost per Result": "$16.01"
          },
          {
            "Date": "07/18/2025",
            "Impressions": "289933",
            "Clicks": "5325",
            "Conversions": "79",
            "Conversion Value": "$9,561",
            "Cost per Result": "$16.18"
          },
          {
            "Date": "07/19/2025",
            "Impressions": "168381",
            "Clicks": "2191",
            "Conversions": "40",
            "Conversion Value": "$15,888",
            "Cost per Result": "$39.61"
          },
          {
            "Date": "07/20/2025",
            "Impressions": "380982",
            "Clicks": "2971",
            "Conversions": "61",
            "Conversion Value": "$13,135",
            "Cost per Result": "$57.10"
          },
          {
            "Date": "07/21/2025",
            "Impressions": "385860",
            "Clicks": "3434",
            "Conversions": "66",
            "Conversion Value": "$14,728",
            "Cost per Result": "$31.10"
          },
          {
            "Date": "07/22/2025",
            "Impressions": "435860",
            "Clicks": "4060",
            "Conversions": "41",
            "Conversion Value": "$5,592",
            "Cost per Result": "$19.34"
          },
          {
            "Date": "07/23/2025",
            "Impressions": "328289",
            "Clicks": "3558",
            "Conversions": "48",
            "Conversion Value": "$4,958",
            "Cost per Result": "$47.48"
          },
          {
            "Date": "07/24/2025",
            "Impressions": "297951",
            "Clicks": "6467",
            "Conversions": "85",
            "Conversion Value": "$7,952",
            "Cost per Result": "$51.30"
          },
          {
            "Date": "07/25/2025",
            "Impressions": "404090",
            "Clicks": "5909",
            "Conversions": "54",
            "Conversion Value": "$7,986",
            "Cost per Result": "$29.86"
          },
          {
            "Date": "07/26/2025",
            "Impressions": "207291",
            "Clicks": "4220",
            "Conversions": "42",
            "Conversion Value": "$11,527",
            "Cost per Result": "$30.38"
          },
          {
            "Date": "07/27/2025",
            "Impressions": "359085",
            "Clicks": "4050",
            "Conversions": "41",
            "Conversion Value": "$11,457",
            "Cost per Result": "$48.19"
          },
          {
            "Date": "07/28/2025",
            "Impressions": "152473",
            "Clicks": "6769",
            "Conversions": "44",
            "Conversion Value": "$14,414",
            "Cost per Result": "$21.11"
          },
          {
            "Date": "07/29/2025",
            "Impressions": "268685",
            "Clicks": "4331",
            "Conversions": "77",
            "Conversion Value": "$5,836",
            "Cost per Result": "$51.12"
          },
          {
            "Date": "07/30/2025",
            "Impressions": "192990",
            "Clicks": "2447",
            "Conversions": "78",
            "Conversion Value": "$6,870",
            "Cost per Result": "$28.88"
          },
          {
            "Date": "07/31/2025",
            "Impressions": "470897",
            "Clicks": "4513",
            "Conversions": "54",
            "Conversion Value": "$7,005",
            "Cost per Result": "$50.37"
          },
          {
            "Date": "08/01/2025",
            "Impressions": "258863",
            "Clicks": "5911",
            "Conversions": "81",
            "Conversion Value": "$15,374",
            "Cost per Result": "$19.33"
          },
          {
            "Date": "08/02/2025",
            "Impressions": "112425",
            "Clicks": "3455",
            "Conversions": "64",
            "Conversion Value": "$7,705",
            "Cost per Result": "$34.32"
          },
          {
            "Date": "08/03/2025",
            "Impressions": "253288",
            "Clicks": "3366",
            "Conversions": "45",
            "Conversion Value": "$12,186",
            "Cost per Result": "$48.30"
          },
          {
            "Date": "08/04/2025",
            "Impressions": "357232",
            "Clicks": "3996",
            "Conversions": "57",
            "Conversion Value": "$8,641",
            "Cost per Result": "$25.78"
          },
          {
            "Date": "08/05/2025",
            "Impressions": "144745",
            "Clicks": "3269",
            "Conversions": "85",
            "Conversion Value": "$5,588",
            "Cost per Result": "$40.01"
          },
          {
            "Date": "08/06/2025",
            "Impressions": "377388",
            "Clicks": "3215",
            "Conversions": "53",
            "Conversion Value": "$11,730",
            "Cost per Result": "$17.72"
          },
          {
            "Date": "08/07/2025",
            "Impressions": "123694",
            "Clicks": "4708",
            "Conversions": "64",
            "Conversion Value": "$6,789",
            "Cost per Result": "$16.20"
          },
          {
            "Date": "08/08/2025",
            "Impressions": "174620",
            "Clicks": "3792",
            "Conversions": "69",
            "Conversion Value": "$7,422",
            "Cost per Result": "$16.86"
          },
          {
            "Date": "08/09/2025",
            "Impressions": "283356",
            "Clicks": "6757",
            "Conversions": "60",
            "Conversion Value": "$5,220",
            "Cost per Result": "$60.11"
          },
          {
            "Date": "08/10/2025",
            "Impressions": "362982",
            "Clicks": "5142",
            "Conversions": "67",
            "Conversion Value": "$15,903",
            "Cost per Result": "$24.53"
          },
          {
            "Date": "08/11/2025",
            "Impressions": "112863",
            "Clicks": "6444",
            "Conversions": "74",
            "Conversion Value": "$9,428",
            "Cost per Result": "$45.98"
          },
          {
            "Date": "08/12/2025",
            "Impressions": "205818",
            "Clicks": "3135",
            "Conversions": "70",
            "Conversion Value": "$15,082",
            "Cost per Result": "$24.76"
          },
          {
            "Date": "08/13/2025",
            "Impressions": "294407",
            "Clicks": "4650",
            "Conversions": "68",
            "Conversion Value": "$4,445",
            "Cost per Result": "$41.75"
          },
          {
            "Date": "08/14/2025",
            "Impressions": "301717",
            "Clicks": "4879",
            "Conversions": "41",
            "Conversion Value": "$6,310",
            "Cost per Result": "$38.49"
          },
          {
            "Date": "08/15/2025",
            "Impressions": "385227",
            "Clicks": "5830",
            "Conversions": "59",
            "Conversion Value": "$7,062",
            "Cost per Result": "$43.79"
          },
          {
            "Date": "08/16/2025",
            "Impressions": "243820",
            "Clicks": "2118",
            "Conversions": "79",
            "Conversion Value": "$11,310",
            "Cost per Result": "$36.72"
          },
          {
            "Date": "08/17/2025",
            "Impressions": "332908",
            "Clicks": "6265",
            "Conversions": "59",
            "Conversion Value": "$10,133",
            "Cost per Result": "$63.58"
          },
          {
            "Date": "08/18/2025",
            "Impressions": "155619",
            "Clicks": "5112",
            "Conversions": "52",
            "Conversion Value": "$7,450",
            "Cost per Result": "$24.68"
          },
          {
            "Date": "08/19/2025",
            "Impressions": "392405",
            "Clicks": "4914",
            "Conversions": "43",
            "Conversion Value": "$6,518",
            "Cost per Result": "$46.56"
          },
          {
            "Date": "08/20/2025",
            "Impressions": "285930",
            "Clicks": "2296",
            "Conversions": "70",
            "Conversion Value": "$5,718",
            "Cost per Result": "$16.28"
          },
          {
            "Date": "08/21/2025",
            "Impressions": "140432",
            "Clicks": "6508",
            "Conversions": "57",
            "Conversion Value": "$6,632",
            "Cost per Result": "$17.82"
          },
          {
            "Date": "08/22/2025",
            "Impressions": "409444",
            "Clicks": "6075",
            "Conversions": "65",
            "Conversion Value": "$6,005",
            "Cost per Result": "$39.05"
          },
          {
            "Date": "08/23/2025",
            "Impressions": "153857",
            "Clicks": "2367",
            "Conversions": "80",
            "Conversion Value": "$7,288",
            "Cost per Result": "$53.79"
          },
          {
            "Date": "08/24/2025",
            "Impressions": "241536",
            "Clicks": "2454",
            "Conversions": "61",
            "Conversion Value": "$12,926",
            "Cost per Result": "$52.80"
          },
          {
            "Date": "08/25/2025",
            "Impressions": "450872",
            "Clicks": "4505",
            "Conversions": "55",
            "Conversion Value": "$11,682",
            "Cost per Result": "$48.53"
          },
          {
            "Date": "08/26/2025",
            "Impressions": "382511",
            "Clicks": "3169",
            "Conversions": "85",
            "Conversion Value": "$14,774",
            "Cost per Result": "$39.76"
          },
          {
            "Date": "08/27/2025",
            "Impressions": "286052",
            "Clicks": "2729",
            "Conversions": "76",
            "Conversion Value": "$11,201",
            "Cost per Result": "$58.37"
          },
          {
            "Date": "08/28/2025",
            "Impressions": "434233",
            "Clicks": "6012",
            "Conversions": "86",
            "Conversion Value": "$12,450",
            "Cost per Result": "$46.19"
          },
          {
            "Date": "08/29/2025",
            "Impressions": "122815",
            "Clicks": "4921",
            "Conversions": "77",
            "Conversion Value": "$5,287",
            "Cost per Result": "$64.78"
          },
          {
            "Date": "08/30/2025",
            "Impressions": "389223",
            "Clicks": "3109",
            "Conversions": "86",
            "Conversion Value": "$13,646",
            "Cost per Result": "$17.10"
          },
          {
            "Date": "08/31/2025",
            "Impressions": "340751",
            "Clicks": "3039",
            "Conversions": "75",
            "Conversion Value": "$11,901",
            "Cost per Result": "$19.48"
          },
          {
            "Date": "09/01/2025",
            "Impressions": "318370",
            "Clicks": "2573",
            "Conversions": "69",
            "Conversion Value": "$15,207",
            "Cost per Result": "$42.09"
          },
          {
            "Date": "09/02/2025",
            "Impressions": "313257",
            "Clicks": "2290",
            "Conversions": "75",
            "Conversion Value": "$14,138",
            "Cost per Result": "$42.29"
          },
          {
            "Date": "09/03/2025",
            "Impressions": "248984",
            "Clicks": "6461",
            "Conversions": "44",
            "Conversion Value": "$5,565",
            "Cost per Result": "$43.95"
          }
        ]
      }
    }
  };

  