import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  Select,
  Autocomplete,
  Button,
  Field,
  NestedModal,
  Checkbox,
} from "..";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../hooks/useFetch";
import { auth } from "../../config/firebase.js";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter.jsx";
import { RequestInvoice } from "../../pages/billing/RequestInvoice.jsx";

export default function DepositComponent({ setIsOpen }) {
  const {
    errorAlert,
    requestedData,
    formatNumber,
    isDarkModeEnabled,
    requestedData: {
      userProfileData: {
        currentOrganization,
        currentOrganization: { balance, PredictedBalance },
      },
    },
    companyInfo,
    setOpenCompanyInfoModal,
    setIsCompanyInfoMissing,
    setTermsAccepted,
    setShowDepositModal,
    setTabValue,
    paymentData, 
    setPaymentData
  } = useGlobalContext();

  const { preloaderBig, pay, plants } = localData.images;
  const { close, wallet, budget } = localData.svgs;
  const [isLoading, setIsLoading] = useState(false);
  const [minDeposit, setMinDeposit] = useState(10000);

  const {
    getAllCurrencies,
    getPaymentWithInvoice,
    paymentWithInvoice,
    stripeCheckoutUrl,
  } = useFetch();
  const { addCommas, addCommasKeepMinus, formatDate } = useFormatter();

  // State for controlling the modals
  const [showMainModal, setShowMainModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("crypto");

  const [state, setState] = useState({
    priceAmount: 50000,
  });

  useEffect(() => {
    if (
      requestedData?.userProfileData?.currentOrganization?.admin ===
      "Blockchain-Ads Team"
    ) {
      console.log();
      setMinDeposit(10);
    } else {
      setMinDeposit(10000);
    }
  }, [requestedData.userProfileData]);

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleInitialPayment = async () => {
    setIsLoading(true);
    setShowMainModal(true);

    try {
      const depositRawCrypto = {
        price_amount: state.priceAmount,
        uuid: auth.currentUser.uid,
      };

      const depositRawFiat = {
        organizationId: requestedData.userProfileData.currentOrganization.id,
        amount: state.priceAmount,
        uuid: auth.currentUser.uid,
        email: requestedData.userProfileData.userEmail,
      };

      const [cryptoData, fiatData] = await Promise.all([
        paymentWithInvoice(() => {}, depositRawCrypto),
        stripeCheckoutUrl(() => {}, depositRawFiat),
      ]);

      // Store the URLs for later use
      setPaymentData({
        cryptoUrl: cryptoData.invoice_url,
        fiatUrl: fiatData.res_data.url,
      });

      // Open the first modal
    } catch (err) {
      errorAlert(
        err?.response?.data?.res_msg || "Failed to initialize payment options"
      );
      console.log(err, "Payment initialization error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle payment method selection and redirection
  const handlePaymentMethodSelection = () => {
    setShowMainModal(false);
    window.open(paymentData.cryptoUrl, "_blank");
    setShowDepositModal("none");
    // setShowPaymentMethodModal(true);
  };

  // Handle final payment redirection
  const handleFinalPayment = () => {
    if (selectedPaymentMethod === "crypto") {
      window.open(paymentData.cryptoUrl, "_blank");
    } else if (selectedPaymentMethod === "fiat") {
      window.open(paymentData.fiatUrl, "_blank");
    }

    // Close all modals after redirection
    setShowPaymentMethodModal(false);
    setIsOpen(false);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTermsAccepted(false);
  };

  const [isBalanceOpen, setIsBalanceOpen] = useState(false);

  const handleOpenInfoModal = () => {
    if (!companyInfo.name.length || !companyInfo.address.length) {
      setOpenCompanyInfoModal(true);
      setIsCompanyInfoMissing(true);
      closeModal();
      return;
    }
  };

  const MainModalContent = () => {
    const { termsAccepted, setTermsAccepted } = useGlobalContext();

    const handleCheckbox = (checked) => {
      setTermsAccepted(checked);
    };

    return (
      <div className="redirect-modal">
        <div className="nested-modal-header">
          <h5 className="nested-modal-title" id="exampleModalLabel">
            Payment Terms
          </h5>
        </div>
        <div className="nested-modal-body">
          <p className="text info-text">
            Please review the following terms before proceeding
          </p>

          <p className="text">
            Important points to note before depositing funds for your campaign:
          </p>
          <br />

          <ul>
            <li>
              <span className="list-style list-style-primary"></span>
              <p>
                Blockchain-Ads and/or the publishers may reject any ads at any
                moment.
              </p>
            </li>
            <li>
              <span className="list-style list-style-primary"></span>
              <p>
                Any refunds are at the entire discretion of Blockchain-Ads with
                a minimum 20% fee to be retained.
              </p>
            </li>
          </ul>

          <Checkbox
            callback={handleCheckbox}
            checked={termsAccepted}
            color="primary"
            label="I understand and I want to proceed"
            size="sm"
          />
        </div>
        <div className="nested-modal-footer">
          <Button
            variant="contained"
            color="primary"
            size="sm"
            disabled={!termsAccepted && paymentData.cryptoUrl}
            onClick={handlePaymentMethodSelection}
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            color="dark"
            size="sm"
            onClick={() => setShowMainModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  // Payment Method Selection Modal
  const PaymentMethodModalChildren = () => {
    const { bitcoinLogoPNG } = localData.images;
    return (
      <div className="payment-method-modal">
        <div className="nested-modal-header">
          <h5 className="nested-modal-title">Select Payment Method</h5>
        </div>
        <div className="nested-modal-body">
          <div className="payment-options">
            <div
              className={`payment-option ${
                selectedPaymentMethod === "crypto" ? "selected" : ""
              }`}
              onClick={() => setSelectedPaymentMethod("crypto")}
              style={{ maxWidth: "250px", margin: "0 auto" }}
            >
              <div className="payment-icon">
                <img src={bitcoinLogoPNG} width={40} />
              </div>
              <h4>Crypto</h4>
              <p>BTC, ETH, USDT, and 150+ more</p>
            </div>
            <div
              className={`payment-option fiat ${
                selectedPaymentMethod === "fiat" ? "selected" : ""
              }`}
              onClick={() => setSelectedPaymentMethod("fiat")}
            >
              <h4>$ Fiat</h4>
            </div>
          </div>
        </div>
        <div className="nested-modal-footer">
          <Button
            variant="contained"
            color="primary"
            size="sm"
            disabled={!selectedPaymentMethod}
            onClick={handleFinalPayment}
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            color="dark"
            size="sm"
            onClick={() => setShowPaymentMethodModal(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTermsAccepted(false);
  }, []);
  
  return (
    <div className="deposit-component">
      <div className="modal-header" style={{ paddingBottom: "10px" }}>
        <h2 className="modal-title">
          Pay with Crypto
          <br />
          <span style={{ marginTop: "20px !important" }}>
            {" "}
            BTC, ETH, USDT, and 150+ more
          </span>
        </h2>

        <Button
          className="btn-close"
          variant="circle"
          color="dark"
          size="sm"
          icon={close}
          onClick={closeModal}
        />
      </div>

      <div className="modal-body" style={{ marginBottom: "10px" }}>
        {/* <img className="modal-cover" src={plants} alt="" /> */}

        <Field
          type="number"
          label="Amount"
          color="secondary"
          placeholder=""
          value={state.priceAmount}
          required={true}
          name="priceAmount"
          size="sm"
          callback={handleOnChange}
          startIcon={budget}
        />
        <div>
          <Button
            variant="contained"
            name="Deposit"
            color="primary"
            onClick={handleInitialPayment}
            disabled={
              isLoading ||
              state.priceAmount < minDeposit
            }
            className="balance-btn payment-method-btn"
            data-tooltip-id="payment-method-btn"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              "Please enter a minimum amount of 10000."
            )}
          >
            {isLoading ? (
              <span className="button-with-spinner">
                <img
                  src={preloaderBig}
                  alt="Loading"
                  className="button-spinner"
                />
                Processing...
              </span>
            ) : (
              "Pay"
            )}
          </Button>
          <br />
          <br />
          <p
            style={{
              textAlign: "center",
              margin: "0 auto",
              fontSize: "10px",
              marginTop: "40px",
            }}
          >
            OR
          </p>
          <br />
          <div
            style={{
              border: "1px solid lightgray",
              padding: "12px",
              borderRadius: "10px",
              maxWidth: "220px",
              margin: "0 auto",
              cursor: "pointer",
            }}
            onClick={() => {
              setTabValue(1);
              setShowDepositModal("none");
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginBottom: "5px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Pay with Fiat
            </p>
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              Credit/Debit Cards, Bank Transfer
            </p>
          </div>

          {state.priceAmount < minDeposit && (
            <Tooltip id="payment-method-btn" className="custom-tooltip" />
          )}
        </div>
      </div>

      {/* Main Terms Modal */}
      <NestedModal
        togglerClassName="nested-modal-toggler"
        className="modal-dialog-centered redirect-modal"
        display={showMainModal ? "block" : "none"}
        setDisplay={(val) => setShowMainModal(val === "block")}
      >
        <MainModalContent />
      </NestedModal>

      {/* Payment Method Selection Modal */}
      <NestedModal
        togglerClassName="nested-modal-toggler"
        className="modal-dialog-centered payment-method-modal"
        display={showPaymentMethodModal ? "block" : "none"}
        setDisplay={(val) => setShowPaymentMethodModal(val === "block")}
      >
        <PaymentMethodModalChildren />
      </NestedModal>

      <div className="modal-footer">
        <a
          onClick={() => setShowMainModal("block")}
          className="text-muted"
          style={{
            fontSize: "12px",
            cursor: "pointer",
            color: "red",
            borderBottom: "1px solid gray",
          }}
        >
          Payment terms
        </a>
      </div>
    </div>
  );
}
