import React, { useEffect, useState } from "react";
import { ReferralBanner } from "../../../components";

export default function ReferralMeida ({details}) {


  return (
    <>
        <h2 className="display-2">Referral Media</h2>
          <p className="text-style-1">
            Please feel free to use any of the following advertisements on your
            website, you may host the image files yourself as you require. If
            you require any alternate sizes, please contact us.
          </p>
          <br />
          <br />

          <ReferralBanner
            type="row"
            name="300x65 - white"
            value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${details?.user?.referralCode}" title="ads.txt Blockchain-Ads"><img src="https://uploads-ssl.webflow.com/64da12dbd6d6a3ceb04e7710/6658617f636a7729dbf0dbf2_Blockchain-Ads%20logo%20white-%20for%20black%20background.svg" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
          />
          <br />
          <br />

          <ReferralBanner
            type="row"
            name="300x65 - black"
            value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${details?.user?.referralCode}" title="ads.txt Blockchain-Ads"><img src="https://assets-global.website-files.com/64da12dbd6d6a3ceb04e7710/64e8f0da6f973c0c232e8bf1_logo-s2-white2x-p-500.png" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
          />
          <br />
          <br />

          <ReferralBanner
            type="row"
            name="300x65 - green"
            value={`<a href="https://hub.blockchain-ads.com/sign-up?ref=${details?.user?.referralCode}" title="ads.txt Blockchain-Ads"><img src="https://uploads-ssl.webflow.com/64da12dbd6d6a3ceb04e7710/665861824891e42e458f6e8b_BlockchainAds-7.svg" width="300" height="65"  alt="ads.txt Blockchaind-Ads" /></a>`}
          />
    </>
  );
}
