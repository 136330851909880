import React, { useState, useEffect, useRef } from "react";
import {
  TableSkeleton,
  Select,
  Button,
  Popup,
  Pagination,
  Carousel,
  Checkbox,
} from "../../../../components";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import useFormatter from "../../../../hooks/useFormatter.jsx";
import { Link, useNavigate } from "react-router-dom";

import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { useStateContext } from "../../../../stateContext";
import { auth } from "../../../../config/firebase.js";
import useFetch from "../../../../hooks/useFetch.js";
import { v4 as uuidv4 } from "uuid";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

let timeout = "";

export default function PublicationsTable() {
  const {
    requestedData,
    isLoading,
    isAdminTeamMember,
    marketplaceState,
    marketplaceTheadRef,
    marketplaceScrollItems,
    activePRCategory,
  } = useGlobalContext();

  const { publications, setPublications } = marketplaceState;
  const [filteredPublications, setFilteredPublications] = useState([]);

  const { textPreloader } = localData.images;
  const { order } = localData.svgs;

  useEffect(() => {
    const categories = ["crypto", "casino", "gaming"];
    const selectedCategory = categories[activePRCategory];

    const filteredItems = publications.filter(
      (item) =>
        !item.isFeatured && item.category && item.category === selectedCategory
    );

    setFilteredPublications(filteredItems);
  }, [publications, activePRCategory]);

  const [activePackageId, setActivePackageId] = useState("");

  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleSelectAllChange = () => {
    const areAllSelected = filteredPublications.every((item) =>
      item.products.every((product) => product.isSelected)
    );

    const updatedPublications = filteredPublications.map((item) => ({
      ...item,
      products: item.products.map((product) => ({
        ...product,
        isSelected: !areAllSelected,
      })),
    }));

    setFilteredPublications(updatedPublications);
    setPublications((prevPublications) =>
      prevPublications.map((item) => ({
        ...item,
        products: item.products.map((product) => ({
          ...product,
          isSelected: !areAllSelected,
        })),
      }))
    );

    setIsAllSelected(!areAllSelected);
  };

  return (
    <div className="publications-wrapper">
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <div className="scroll bg-white" style={{ padding: 0 }}>
            {isLoading.userProfileIsLoading ? (
              <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
              <table
                id="publications-table"
                className="table publications-table"
              >
                <thead
                  ref={marketplaceTheadRef}
                  className="publications-table-thead"
                >
                  <tr className="table-row">
                    <th
                      className="table-th-media text-start"
                      style={{ width: "30px" }}
                    >
                      <Checkbox
                        checked={isAllSelected}
                        callback={handleSelectAllChange}
                      />
                    </th>
                    <th className="table-th-media text-start">Media</th>
                    <th className="table-th-monthly-traffic text-start">
                      Potential Reach
                    </th>
                    <th className="table-th-to text-start">Turnaround Time</th>
                    {/* <th className="table-th-media text-start">Price</th> */}
                  </tr>
                </thead>
                <tbody>
                  {!publications.length ? (
                    <tr>
                      <td>
                        <div className="no-data">No Data To Show!</div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {filteredPublications.map((item, index) => {
                        if (item.isFeatured) return;
                        return item.products.map((subItem, subIndex) => {
                          let isFirstItem = subIndex == 0;
                          let isLastItem = subIndex == item.products.length - 1;

                          return (
                            <PublicationRow
                              key={uuidv4()}
                              {...item}
                              {...subItem}
                              _package={item}
                              packageId={item.id}
                              index={index}
                              subIndex={subIndex}
                              isFirstItem={isFirstItem}
                              isLastItem={isLastItem}
                              activePackageId={activePackageId}
                            />
                          );
                        });
                      })}
                    </>
                  )}
                </tbody>
              </table>
            )}
            {!isLoading.blockchainDataIsLoading && !publications.length && (
              <div className="no-data-cover"></div>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ position: "sticky", top: "15%", maxHeight: "300px" }}
        >
          {filteredPublications.map((item, index) => {
            if (item.isFeatured) return;
            return item.products.map((subItem, subIndex) => {
              let isFirstItem = subIndex == 0;
              let isLastItem = subIndex == item.products.length - 1;

              return (
                <TotalPriceBox
                  key={uuidv4()}
                  {...item}
                  {...subItem}
                  _package={item}
                  packageId={item.id}
                  index={index}
                  subIndex={subIndex}
                  isFirstItem={isFirstItem}
                  isLastItem={isLastItem}
                  activePackageId={activePackageId}
                />
              );
            });
          })}
        </Grid>
      </Grid>
    </div>
  );
}

const PublicationRow = ({
  id,
  packageId,
  media,
  format,
  isSelected,
  monthlyTraffic = "",
  reach = "",
  turnaroundTime,
  duration,
  isInShoppingCart,
  isInWishlist,
  cover,
  productTitles,
  price,
  discount,
  included,
  packageType,
  packageName,
  isFirstItem,
  isLastItem,
  index,
  subIndex,
  totalPrice,
  extraSource = [],
  _package,
  activePackageId,
}) => {
  const { marketplaceState } = useGlobalContext();

  const {
    publications,
    setPublications,
    setActiveMarketplaceDetails,
    setActivePublication,
    activePublictions,
  } = marketplaceState;

  const { eye, eyeSlash, heart, shoppingCart, info, arrowRight } =
    localData.svgs;

  const { addCommas } = useFormatter();

  const navigate = useNavigate();

  const handleAddToShoppingCart = () => {
    let tempPublications = [...publications];

    tempPublications = tempPublications.map((item) =>
      item.id === packageId
        ? {
            ...item,
            isInShoppingCart: !isInShoppingCart,
            products: item.products.map((product) =>
              product.isSelected
                ? { ...product, isInShoppingCart: !isInShoppingCart }
                : product
            ),
            totalPrice: item.products.reduce(
              (sum, product) =>
                product.isSelected && !product.isInShoppingCart
                  ? sum + product.price
                  : sum,
              0
            ),
          }
        : item
    );

    setActivePublication(tempPublications);

    setPublications((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInShoppingCart: !isInShoppingCart,
              products: publication.products.map((product) =>
                product.isSelected
                  ? { ...product, isInShoppingCart: !isInShoppingCart }
                  : product
              ),
              totalPrice: publication.products.reduce(
                (sum, product) =>
                  product.isSelected && !product.isInShoppingCart
                    ? sum + product.price
                    : sum,
                0
              ),
            }
          : publication
      )
    );
  };

  const handleAddToWishlist = () => {
    let tempPublications = [...publications];

    tempPublications = tempPublications.map((item) =>
      item.id === packageId
        ? {
            ...item,
            isInWishlist: !isInWishlist,
            products: item.products.map((product) =>
              product.isSelected
                ? { ...product, isInShoppingCart: true }
                : product
            ),
            totalPrice: item.products.reduce(
              (sum, product) =>
                product.isSelected ? sum + product.price : sum,
              0
            ),
          }
        : item
    );

    setActivePublication(tempPublications);

    setPublications((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInWishlist: !isInWishlist,
              products: publication.products.map((product) =>
                product.isSelected
                  ? { ...product, isInShoppingCart: true }
                  : product
              ),
              totalPrice: publication.products.reduce(
                (sum, product) =>
                  product.isSelected ? sum + product.price : sum,
                0
              ),
            }
          : publication
      )
    );
  };

  const handelNavigateToShoppingCart = () => {
    let tempPublications = [...publications];
    tempPublications = tempPublications.map((item) =>
      item.id == packageId
        ? {
            ...item,
            isInShoppingCart: true,
            products: item.products
              .filter((product) => product.isSelected)
              .map((product) => ({
                ...product,
                isInShoppingCart: true,
              })),
            totalPrice: item.products
              .filter((product) => product.isSelected)
              .reduce((sum, product) => sum + product.price, 0),
          }
        : item
    );
    setActivePublication(tempPublications);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      navigate("/additional-products/pr/shopping-cart");
    }, 500);
  };

  const handleCheckboxChange = (packageId, productId) => {
    let tempPublications = [...publications];
    tempPublications = tempPublications.map((item) => {
      if (item.id === packageId) {
        return {
          ...item,
          products: item.products.map((product) =>
            product.id === productId
              ? { ...product, isSelected: !product.isSelected }
              : product
          ),
          isInWishlist: false,
        };
      }
      return item;
    });
    setActivePublication((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInWishlist: false,
              isInShoppingCart: false,
            }
          : publication
      )
    );
    setPublications(tempPublications);
  };

  const totalPriceOfPR = publications
    .filter((item) => item.id === packageId)
    .reduce((sum, item) => {
      return (
        sum +
        item.products.reduce((subSum, product) => {
          return product.isSelected ? subSum + product.price : subSum;
        }, 0)
      );
    }, 0);

  const processPackage = (_package) => {
    const selectedProducts = _package.products.filter(
      (product) => product.isSelected
    );
    const totalPrice = selectedProducts.reduce(
      (sum, product) => sum + product.price,
      0
    );
    return {
      ..._package,
      products: selectedProducts,
      totalPrice,
    };
  };

  return (
    <>
      {subIndex == 0 && <tr id={packageId} className="top"></tr>}
      {isFirstItem && (
        <>
          <tr
            className={`table-tr-summary ${
              packageId == activePackageId ? "active" : ""
            }`}
          ></tr>
        </>
      )}
      <tr
        className={`table-row ${false ? "table-row-marked" : ""} ${
          packageId == activePackageId ? "active" : ""
        }`}
      >
        <td>
          <Checkbox
            checked={isSelected}
            callback={() => handleCheckboxChange(packageId, id)}
          />
        </td>
        <td className="table-td-media text-start" title={media}>
          {media}
        </td>

        {/* <td className="table-td-format ">{format}</td> */}
        <td className="table-td-montdly-traffic ">
          {reach}
          {/* {addCommas(reach.toString())} */}
        </td>
        <td className="table-td-to ">48h</td>
      </tr>
    </>
  );
};

const TotalPriceBox = ({
  id,
  packageId,
  media,
  format,
  isSelected,
  monthlyTraffic = "",
  reach = "",
  turnaroundTime,
  duration,
  isInShoppingCart,
  isInWishlist,
  cover,
  productTitles,
  price,
  discount,
  included,
  packageType,
  packageName,
  isFirstItem,
  isLastItem,
  index,
  subIndex,
  totalPrice,
  extraSource = [],
  _package,
  activePackageId,
}) => {
  const { marketplaceState } = useGlobalContext();

  const {
    publications,
    setPublications,
    setActiveMarketplaceDetails,
    setActivePublication,
    activePublictions,
  } = marketplaceState;

  const { eye, eyeSlash, heart, shoppingCart, info, arrowRight } =
    localData.svgs;

  const { addCommas } = useFormatter();

  const navigate = useNavigate();

  const handleAddToShoppingCart = () => {
    let tempPublications = [...publications];

    tempPublications = tempPublications.map((item) =>
      item.id === packageId
        ? {
            ...item,
            isInShoppingCart: !isInShoppingCart,
            products: item.products.map((product) =>
              product.isSelected
                ? { ...product, isInShoppingCart: !isInShoppingCart }
                : product
            ),
            totalPrice: item.products.reduce(
              (sum, product) =>
                product.isSelected && !product.isInShoppingCart
                  ? sum + product.price
                  : sum,
              0
            ),
          }
        : item
    );

    setActivePublication(tempPublications);

    setPublications((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInShoppingCart: !isInShoppingCart,
              products: publication.products.map((product) =>
                product.isSelected
                  ? { ...product, isInShoppingCart: !isInShoppingCart }
                  : product
              ),
              totalPrice: publication.products.reduce(
                (sum, product) =>
                  product.isSelected && !product.isInShoppingCart
                    ? sum + product.price
                    : sum,
                0
              ),
            }
          : publication
      )
    );
  };

  const handleAddToWishlist = () => {
    let tempPublications = [...publications];

    tempPublications = tempPublications.map((item) =>
      item.id === packageId
        ? {
            ...item,
            isInWishlist: !isInWishlist,
            products: item.products.map((product) =>
              product.isSelected
                ? { ...product, isInShoppingCart: true }
                : product
            ),
            totalPrice: item.products.reduce(
              (sum, product) =>
                product.isSelected ? sum + product.price : sum,
              0
            ),
          }
        : item
    );

    setActivePublication(tempPublications);

    setPublications((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInWishlist: !isInWishlist,
              products: publication.products.map((product) =>
                product.isSelected
                  ? { ...product, isInShoppingCart: true }
                  : product
              ),
              totalPrice: publication.products.reduce(
                (sum, product) =>
                  product.isSelected ? sum + product.price : sum,
                0
              ),
            }
          : publication
      )
    );
  };

  const handelNavigateToShoppingCart = () => {
    let tempPublications = [...publications];
    tempPublications = tempPublications.map((item) =>
      item.id == packageId
        ? {
            ...item,
            isInShoppingCart: true,
            products: item.products
              .filter((product) => product.isSelected)
              .map((product) => ({
                ...product,
                isInShoppingCart: true,
              })),
            totalPrice: item.products
              .filter((product) => product.isSelected)
              .reduce((sum, product) => sum + product.price, 0),
          }
        : item
    );
    setActivePublication(tempPublications);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      navigate("/additional-products/pr/shopping-cart");
    }, 500);
  };

  const handleCheckboxChange = (packageId, productId) => {
    let tempPublications = [...publications];
    tempPublications = tempPublications.map((item) => {
      if (item.id === packageId) {
        return {
          ...item,
          products: item.products.map((product) =>
            product.id === productId
              ? { ...product, isSelected: !product.isSelected }
              : product
          ),
          isInWishlist: false,
        };
      }
      return item;
    });
    setActivePublication((prevPublications) =>
      prevPublications.map((publication) =>
        publication.id === packageId
          ? {
              ...publication,
              isInWishlist: false,
              isInShoppingCart: false,
            }
          : publication
      )
    );
    setPublications(tempPublications);
  };

  const totalPriceOfPR = publications
    .filter((item) => item.id === packageId)
    .reduce((sum, item) => {
      return (
        sum +
        item.products.reduce((subSum, product) => {
          return product.isSelected ? subSum + product.price : subSum;
        }, 0)
      );
    }, 0);

  const processPackage = (_package) => {
    const selectedProducts = _package.products.filter(
      (product) => product.isSelected
    );
    const totalPrice = selectedProducts.reduce(
      (sum, product) => sum + product.price,
      0
    );
    return {
      ..._package,
      products: selectedProducts,
      totalPrice,
    };
  };
  
  return (
    <>
       {publications.some(item => item.products.some(product => product.isSelected)) && <> 
      {isLastItem && (
        <>
          {totalPriceOfPR >= 20000 ? (
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
                border: "1px solid rgba(31, 131, 82, 0.3)",
              }}
              id={packageType}
              className="table-tr-footer"
            >
              <div className="estimated-result-item-container">
                <h4 className="title">
                  Total Price:
                  <span className="total-price-value">
                    $ {addCommas(totalPriceOfPR)} USD
                  </span>
                </h4>
              </div>
              <br />
              <div>
                <Button
                  className="details-btn"
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    const updatedPackage = processPackage(_package);
                    console.log(updatedPackage, "Updated Package");
                    setActiveMarketplaceDetails(updatedPackage);
                  }}
                  style={{ border: "1px solid lightgray" }}
                >
                  <InfoIcon sx={{ marginRight: "2px", color: "gray" }} /> What’s
                  included
                </Button>
              </div>
              <br />
              <div className="">
                <div className="">
                  {/* <Button
                  className={`${isInWishlist ? "active" : ""} circle-btn`}
                  variant="circle"
                  size=""
                  onClick={handleAddToWishlist}
                  color={`${"primary"}`}
                  disabled={totalPriceOfPR <= 15000}
                >
                  {heart}
                </Button> */}

                  {/* <Button
                  className={`${isInShoppingCart ? "active" : ""} circle-btn`}
                  variant="circle"
                  onClick={handleAddToShoppingCart}
                  color={`${"success"}`}
                  disabled={totalPriceOfPR <= 15000}
                >
                  {shoppingCart}
                </Button> */}
                  <Button
                    className="order-btn"
                    color="marketplace"
                    variant="contained"
                    size="sm"
                    onClick={handelNavigateToShoppingCart}
                    disabled={totalPriceOfPR <= 20000}
                    tooltipContent="Add to cart"
                  >
                    <div>Add to cart</div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
                border: "1px solid red",
              }}
            >
                  <div className="d-flex" style={{alignItems: "center"}}>
                  <InfoIcon sx={{ marginRight: "5px", color: "gray", fontSize: "14px" }} /> <span style={{fontSize: "13px"}}>Please add more publication to proceed</span>
                  </div>
                  <br/>
                  <Button
                    className="order-btn"
                    color="marketplace"
                    variant="contained"
                    size="sm"
                    onClick={handelNavigateToShoppingCart}
                    disabled={totalPriceOfPR <= 20000}
                    tooltipContent="Add to cart"
                  >
                    <div>Add to cart</div> 
                  </Button>

            </div>
          )}
          <br />
        </>
      )}
      </>
  }
    </>
  );
};
