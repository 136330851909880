import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const TargetingOverlapPopup = ({ campaignInfo, open, handleClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  
  // Check for targeting overlap conditions
  const hasGeoTargeting = campaignInfo.countriesTargeting && campaignInfo.countriesTargeting.length > 0;
  
  const hasBlockchainTargeting = 
    (campaignInfo.chainTargeting && campaignInfo.chainTargeting.length > 0) ||
    (campaignInfo.walletTargeting && campaignInfo.walletTargeting.length > 0) ||
    (campaignInfo.web3Audience && campaignInfo.web3Audience.length > 0) ||
    (campaignInfo.portfolioBalance && campaignInfo.portfolioBalance.length > 0);

  const hasOverlap = hasGeoTargeting && hasBlockchainTargeting;

  // Check if the user has previously chosen not to show this popup
  const shouldShowPopup = () => {
    const storedPreference = localStorage.getItem('hideTargetingOverlapPopup');
    return !(storedPreference === 'true');
  };

  // Handle the "Don't show again" checkbox
  const handleDontShowAgainChange = (event) => {
    setDontShowAgain(event.target.checked);
  };

  // Handle the close button, considering the "Don't show again" option
  const handleCloseWithPreference = () => {
    if (dontShowAgain) {
      localStorage.setItem('hideTargetingOverlapPopup', 'true');
    }
    handleClose();
  };

  // Arrays to display in the popup
  const getDisplayItems = (category, items) => {
    if (!items || items.length === 0) return null;
    
    return (
      <Box mt={2}>
        <Typography variant="subtitle2" color="primary">{category}:</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {items.map((item, index) => (
            <Chip key={index} label={item} size="small" color="primary" variant="outlined" />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open && hasOverlap && shouldShowPopup()}
      onClose={handleCloseWithPreference}
      aria-labelledby="targeting-overlap-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="targeting-overlap-dialog-title">
        <Box display="flex" alignItems="center">
          <InfoIcon color="warning" sx={{ mr: 1 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Targeting Overlap Detected
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseWithPreference} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          NOTE: When using Web3 audiences, it is recommended to remove any GEO restrictions, as discrepancies in targeting may occur.
        </DialogContentText>        
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" p={2}>
          <FormControlLabel
            control={
              <Checkbox 
                checked={dontShowAgain} 
                onChange={handleDontShowAgainChange} 
                color="primary"
              />
            }
            label="Don't show again"
          />
          <Button onClick={handleCloseWithPreference} color="primary">
            Got it
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TargetingOverlapPopup;