import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import { CSVLink } from "react-csv";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";

export default function CampaignsStatisticsCSV() {
    const { requestedData, isLoading, markedCampaigns } = useGlobalContext();
    const { exportIcon } = localData.svgs;

    console.log(markedCampaigns, "markedCampaigns");
    
    const [csvReport, setCsvReport] = useState({
        filename: "campaign_progression_data.csv",
        headers: [],
        data: [],
    });

    useEffect(() => {
        handleDownload();
    }, [markedCampaigns]);

    const formatDataToCSV = () => {
        const statisticsData = markedCampaigns;
        let csvData = [];

        statisticsData.forEach((campaign) => {
            const { name, progressionData } = campaign;
            
            // Initialize totals for this campaign
            let totalClicks = 0;
            let totalImpressions = 0;
            let totalAmountSpent = 0;
            
            // Sum up values across all dates
            Object.keys(progressionData).forEach((date) => {
                const { clicks, impressions, amount_spent } = progressionData[date];
                totalClicks += clicks || 0;
                totalImpressions += impressions || 0;
                totalAmountSpent += amount_spent || 0;
            });
            
            // Calculate overall metrics
            const totalCTR = totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0;
            const totalCPC = totalClicks > 0 ? totalAmountSpent / totalClicks : 0;
            const totalCPM = totalImpressions > 0 ? (totalAmountSpent / totalImpressions) * 1000 : 0;

            // Create a single row for this campaign with totals
            const csvRow = {
                Name: name,
                Clicks: totalClicks,
                Impressions: totalImpressions,
                AmountSpent: totalAmountSpent.toFixed(2),
                CTR: totalCTR.toFixed(2),
                CPC: totalCPC.toFixed(2),
                CPM: totalCPM.toFixed(2),
            };

            csvData.push(csvRow);
        });

        const headers = [
            { label: "Name", key: "Name" },
            { label: "Clicks", key: "Clicks" },
            { label: "Impressions", key: "Impressions" },
            { label: "Amount Spent", key: "AmountSpent" },
            { label: "CTR", key: "CTR" },
            { label: "CPC", key: "CPC" },
            { label: "CPM", key: "CPM" },
        ];

        return { data: csvData, headers: headers };
    };

    const handleDownload = () => {
        const { data, headers } = formatDataToCSV();
        const csvReport = {
            filename: "campaign_totals_data.csv",
            headers: headers,
            data: data,
        };
        setCsvReport(csvReport);
    };

    return (
        <CSVLink
            {...csvReport}
            className={`csv-btn-link ${
                !markedCampaigns.length ? "disabled" : ""
            }`}
        >
            <Button size="sm" className="csv-btn" color="secondary" name="Export - Totals" startIcon={exportIcon} />
        </CSVLink>
    );
}