import { Box, Typography, TextField, Button } from "@mui/material";
import { useGlobalContext } from "../../../../context"; // Make sure this path is correct

export default function ConversionDetails() {
  const { createConversionData, setCreateConversionData } = useGlobalContext();

  const handleTagNameChange = (event) => {
    setCreateConversionData({
      ...createConversionData,
      conversionTagName: event.target.value
    });
  };

  return (
    <Box 
      sx={{ 
        maxWidth: 800, 
        minHeight: 300,
        mx: "auto", 
        mt: 4, 
        p: 3, 
        borderRadius: 2, 
        backgroundColor: "#fff",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)"
      }}
    >
      {/* Title */}
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Conversion Details
      </Typography>

      <Typography variant="body2" color="textSecondary" mb={3}>
      Set up your conversion tracking by providing a clear and descriptive name for your pixel. 
      <br/>
      This will help you easily identify and manage your conversions from Blockchain-Ads campaigns, improving your tracking and results.
      </Typography>
      <br/>

      <Typography variant="body2" color="textSecondary">
      Enter a descriptive name for this conversion pixel (e.g., "Sign-Up Page Conversion" or "Product Purchase"). A clear name makes it easier to reference and analyze your campaign performance later.
      </Typography>
      <br/>

      <Typography variant="subtitle1" fontWeight="bold" color="green" mb={2}>
        Conversion Pixel
      </Typography>

      <TextField
        sx={{minWidth: "250px"}}
        label="Conversion Name"
        variant="outlined"
        required
        size="small"
        value={createConversionData.conversionTagName || ''}
        onChange={handleTagNameChange}
        placeholder="Sign-Up Page Conversion"
      />

    </Box>
  );
}