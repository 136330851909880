import TrackingSteps from "./TrackingSteps";
import { useGlobalContext } from "../../../context";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  CircularProgress,
  InputLabel,
  Tooltip,
  IconButton,
  Select,
  Alert,
  MenuItem,
} from "@mui/material";
import ConversionSetup from "./tabs/ConversionSetup";
import ConversionDetails from "./tabs/ConversionDetails";
import ConversionType from "./tabs/ConversionType";
import { useEffect, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import CodeIcon from "@mui/icons-material/Code";
import { auth } from "../../../config/firebase";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { handleGTMAuthentication } from "./googleAuthPopup";

export const CreateTrackingPixel = () => {
  const {
    conversionActiveStep,
    setConversionActiveStep,
    CreateConversionPixelRequest,
    createConversionData,
    setCreateConversionData,
    requestedData,
    gtmAccounts,
    setGtmAccounts,
    conversionOptions,
    getLoadUserProfile,
    isGTMConfigurationPopupOpen,
    setIsGTMConfigurationPopupOpen,
    isGTMPopupOpen,
    setIsGTMPopupOpen,
    setTypeOfGTMStep
  } = useGlobalContext();

  const { disconnectGTMAccount } = useFetch();

  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);

  const { connectGTMAccount, getAccountsGTM } = useFetch();

  useEffect(() => {
    setConversionActiveStep(0);
    setCreateConversionData({
      conversionMethod: "",
      conversionTagName: "",
      conversionStep: "",
    });
  }, []);

  const steps = ["Choose tracking method", "Conversion Name", "Installation"];
  const isLastStep = conversionActiveStep === steps.length - 1;
  const isFirstStep = conversionActiveStep === 0;

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ConversionType handleNext={handleNext} />;
      case 1:
        return <ConversionDetails />;
      case 2:
        return <ConversionSetup />;
      default:
        return <ConversionType />;
    }
  };

  const navigate = useNavigate();

  const { trackAnEventScriptTag } = conversionOptions || {};

  const handleNext = (value) => {
    if (isLastStep) {
      return navigate("/tracking");
    }
    if (conversionActiveStep === 0 && value === "gtm") {
      setIsGTMPopupOpen(true);
      return;
    }

    if (conversionActiveStep === 1) {
      return CreateConversionPixelRequest({
        tagName: createConversionData.conversionTagName,
        pixelType: "signup",
        tracker_group: "0",
      });
    }

    if (!isLastStep) {
      setConversionActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (!isFirstStep) {
      setConversionActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleConnectGTM = () => {
    const organizationID =
      requestedData?.userProfileData?.currentOrganization?.id;

    handleGTMAuthentication(
      organizationID,
      connectGTMAccount,
      getAccountsGTM,
      setGtmAccounts,
      setIsGTMConfigurationPopupOpen,
      setIsGTMPopupOpen,
      () => {
        setIsGTMPopupOpen(true);
        setIsGTMConfigurationPopupOpen(true);
      }
    );
  };

  const handleManualSetup = () => {
    setTypeOfGTMStep("manual")
    setIsGTMPopupOpen(false);
    setConversionActiveStep((prevStep) => prevStep + 1);
  };

  const handleSwitchAccount = async () => {
    await disconnectGTM();
    handleConnectGTM();
  };

  const disconnectGTM = async () => {
    if (!requestedData.userProfileData) return;
    setIsDisconnectLoading(true);
    try {
      const params = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
      };

      await disconnectGTMAccount(() => {}, params);
      getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
    } catch (error) {
      console.error("Error disconnecting GTM account:", error);
    }
    setIsDisconnectLoading(false);
  };

  const nextButtonDisabled =
    conversionActiveStep === 1 &&
    !createConversionData.conversionTagName.length;

  return (
    <div className="tracking-drawer">
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <TrackingSteps />
        <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
          {renderStepContent(conversionActiveStep)}
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            background: "#fff",
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #ddd",
            zIndex: 10,
          }}
        >
          {!isFirstStep && (
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={isFirstStep || isLastStep}
            >
              Back
            </Button>
          )}

          {!isFirstStep && (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={trackAnEventScriptTag?.isLoading || nextButtonDisabled}
            >
              {isLastStep ? "Confirm" : "Next"}
            </Button>
          )}
        </Box>
      </Box>

      <GTMConnectionPopup
        open={isGTMPopupOpen}
        onClose={() => setIsGTMPopupOpen(false)}
        onConnectGTM={handleConnectGTM}
        onManualSetup={handleManualSetup}
        onSwitchGTM={handleSwitchAccount}
      />

      <GTMCofigurationPopup
        open={isGTMConfigurationPopupOpen}
        onClose={() => setIsGTMConfigurationPopupOpen(false)}
      />
    </div>
  );
};

const GTMConnectionPopup = ({ open, onClose, onManualSetup }) => {
  const {
    requestedData,
    setGtmAccounts,
    setIsGTMConfigurationPopupOpen,
    setConversionActiveStep,
    setIsGTMPopupOpen,
    setTypeOfGTMStep
  } = useGlobalContext();
  const { connectGTMAccount, getAccountsGTM } = useFetch();
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnectGTM = () => {
    if (requestedData?.userProfileData?.currentOrganization?.isGtmConnected) {
      setConversionActiveStep(1);
      onClose();
      setTypeOfGTMStep("assistant-setup")
      return;
    }

    const organizationID =
      requestedData?.userProfileData?.currentOrganization?.id;

    handleGTMAuthentication(
      organizationID,
      connectGTMAccount,
      getAccountsGTM,
      setGtmAccounts,
      setIsConnecting,
      setIsGTMConfigurationPopupOpen,
      setIsGTMPopupOpen,
      () => {
        console.log("Authentication successful");
      }
    );

    setTypeOfGTMStep("assistant-setup")
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography
          variant="h6"
          sx={{ mb: 4, textAlign: "center", fontWeight: "bold" }}
        >
          Connect Your Google Tag Manager (GTM) Account
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          You haven't connected your Google Tag Manager account yet. To enable
          conversion tracking, you have two options:
        </Typography>

        <Grid container spacing={3} p={4}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderWidth: 2,
                "&:hover": {
                  boxShadow: 6,
                  borderColor: "primary.dark",
                },
              }}
              variant="outlined"
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <LinkIcon color="primary" sx={{ fontSize: 48 }} />
                </Box>
                <Typography variant="h6" align="center" gutterBottom>
                  Assistant setup
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Authorize Blockchain-Ads to access your Google Tag Manager
                  account. This allows our team to install the conversion
                  tracking pixel on your behalf, ensuring a seamless setup.
                  <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleConnectGTM}
                  disabled={isConnecting}
                >
                  {isConnecting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : requestedData?.userProfileData?.currentOrganization
                      ?.isGtmConnected ? (
                    "Proceed"
                  ) : (
                    "Connect GTM"
                  )}
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                "&:hover": {
                  boxShadow: 6,
                  borderColor: "primary.dark",
                },
              }}
              variant="outlined"
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <CodeIcon color="action" sx={{ fontSize: 48 }} />
                </Box>
                <Typography variant="h6" align="center" gutterBottom>
                  Manual Setup
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  If you prefer to set up the tracking yourself, you can proceed
                  manually. We will provide an HTML script that you'll need to
                  add to your Google Tag Manager account to track conversions.
                  <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button fullWidth variant="outlined" onClick={onManualSetup}>
                  Proceed Manually
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const GTMCofigurationPopup = ({ open, onClose }) => {
  const {
    requestedData,
    successAlert,
    gtmAccounts,
    setGtmAccounts,
    setConversionActiveStep,
    getLoadUserProfile,
  } = useGlobalContext();

  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [isPixelCreatingLoading, setIsPixelCreatingLoading] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState("");
  const [accountContainers, setAccountContainers] = useState([]);
  const [isContainerLoading, setIsContainerLoading] = useState(false);

  const {
    disconnectGTMAccount,
    getContainersGTM,
    createTagGTM,
    getGTMAccounts,
  } = useFetch();

  useEffect(() => {
    const loadGTMAccounts = async () => {
      setIsAccountLoading(true);
      try {
        const params = {
          uuid: auth.currentUser?.uid,
          organizationID:
            requestedData?.userProfileData?.currentOrganization?.id,
        };

        const response = await getGTMAccounts(() => {}, params);
        setGtmAccounts(response.res_data?.accounts || []);
      } catch (error) {
        console.error("Error loading GTM accounts:", error);
      }
      setIsAccountLoading(false);
    };

    if (open && requestedData?.userProfileData?.currentOrganization?.id) {
      loadGTMAccounts();
    }
  }, [open, requestedData?.userProfileData?.currentOrganization?.id]);

  const disconnectGTM = async () => {
    if (!requestedData.userProfileData) return;
    setIsDisconnectLoading(true);
    try {
      const params = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
      };

      await disconnectGTMAccount(() => {}, params);
      getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
    } catch (error) {
      console.error("Error disconnecting GTM account:", error);
    }
    setIsDisconnectLoading(false);
  };

  const handleCreateGTM = async () => {
    setIsPixelCreatingLoading(true);
    try {
      const params = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
        accountId: selectedAccount,
        containerId: selectedContainer,
      };

      await createTagGTM(() => {}, params);
      successAlert("GTM has been connected successfully");
      await getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
      onClose();
      setConversionActiveStep(1)
    } catch (error) {
      console.error("Error creating GTM tag:", error);
    }
    setIsPixelCreatingLoading(false);
  };

  const selectAccount = async (accountId) => {
    setSelectedAccount(accountId);
    setAccountContainers([]);
    setSelectedContainer("");
    setIsContainerLoading(true);

    try {
      const params = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
        accountId: accountId,
      };

      const data = await getContainersGTM(() => {}, params);
      setAccountContainers(data.res_data.container || []);
    } catch (error) {
      console.error("Error getting GTM containers:", error);
    }
    setIsContainerLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "10px",
          width: "550px",
          maxWidth: "95vw",
        },
      }}
    >
      <DialogContent>
        <Typography id="modal-title" sx={{ fontSize: "13px" }}>
          Make sure your site has an active Google Tag Manager account and
          implementation before starting. To create and implement a new GTM
          account,{" "}
          <a
            style={{ fontWeight: "bold" }}
            href="https://tagmanager.google.com/#/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
        </Typography>
        <br />
        <InputLabel
          sx={{ alignItems: "center", display: "flex", marginBottom: "10px" }}
        >
          Select Account
          <Tooltip
            title="Select an account option from the dropdown"
            placement="right"
          >
            <IconButton
              size="small"
              sx={{ ml: 1, width: "13px", height: "13px" }}
              aria-label="info"
            >
              <HelpIcon sx={{ width: "13px", height: "13px" }} />
            </IconButton>
          </Tooltip>
        </InputLabel>

        {isAccountLoading || !gtmAccounts.length ? (
          <CircularProgress size={24} />
        ) : (
          <Select
            fullWidth
            value={selectedAccount}
            onChange={(e) => selectAccount(e.target.value)}
            size="small"
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select an account
            </MenuItem>
            {gtmAccounts && gtmAccounts.length > 0 ? (
              gtmAccounts.map((item) => (
                <MenuItem key={item.accountId} value={item.accountId}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Accounts Found</MenuItem>
            )}
          </Select>
        )}

        <br />
        <br />
        <InputLabel
          sx={{ alignItems: "center", display: "flex", marginBottom: "10px" }}
        >
          Select Container
          <Tooltip
            title="Select a container option from the dropdown"
            placement="right"
          >
            <IconButton
              size="small"
              sx={{ ml: 1, width: "13px", height: "13px" }}
              aria-label="info"
            >
              <HelpIcon sx={{ width: "13px", height: "13px" }} />
            </IconButton>
          </Tooltip>
        </InputLabel>

        {isContainerLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Select
            fullWidth
            value={selectedContainer}
            onChange={(e) => setSelectedContainer(e.target.value)}
            size="small"
            disabled={!accountContainers?.length}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a container
            </MenuItem>
            {accountContainers && accountContainers.length > 0 ? (
              accountContainers.map((item) => (
                <MenuItem key={item.containerId} value={item.containerId}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Containers Found</MenuItem>
            )}
          </Select>
        )}

        {gtmAccounts && gtmAccounts.length === 0 && (
          <Alert severity="error" sx={{ marginTop: "20px" }}>
            <b>No Active Account</b> <br /> Please make sure to activate and set
            up your GTM account
          </Alert>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="contained"
            color="error"
            sx={{ mr: 2 }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleCreateGTM}
            disabled={
              isPixelCreatingLoading || !selectedAccount || !selectedContainer
            }
          >
            Create{" "}
            {isPixelCreatingLoading && (
              <CircularProgress
                size={15}
                sx={{
                  marginLeft: "10px",
                }}
              />
            )}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};