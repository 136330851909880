import React, { useEffect, useState } from "react";
import { auth } from "../../../config/firebase";
import {
  Button,
  VerticalComposedChart,
} from "../../../components";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useGlobalContext } from "../../../context";
import { useNavigate, Link } from "react-router-dom";
import useFormatter from "../../../hooks/useFormatter";
import localData from "../../../localData";

const {
    preloaderBig,
  } = localData.images;

export default function AudiencesChart  ({ categories, dateRange }) {
    const { formatDateToMMDD, formatDate, addCommas, formatCurrency } =
      useFormatter();
    const {
      _OrganizationAdditionalData,
      requestedData: {
        userProfileData: {
          currentOrganization: { additionalData },
        },
      },
      assistedCampaignData,
      activeCategory
    } = useGlobalContext();

    
    const getPropertiesForCategory = (category) => {
      if (category === "web3GamingCasinos") {
        return [
          { name: "impressions", displayName: "Impressions", isSelected: false },
          { name: "clicks", displayName: "Clicks", isSelected: true },
          { name: "cost", displayName: "Cost per Sign Up", isSelected: false },
          { name: "deposits", displayName: "Deposits", isSelected: false },
          { name: "signups", displayName: "Sign Ups", isSelected: false },
        ];
      } else {
        return [
          { name: "Impressions", displayName: "Impressions", isSelected: false },
          { name: "clicks", displayName: "Clicks", isSelected: true },
          { name: "conversions", displayName: "Conversions", isSelected: false },
          { name: "volumes", displayName: "Conversion Value", isSelected: false },
          { name: "cost", displayName: "Cost per Result", isSelected: false },
        ];
      }
    };
  
    const [properties, setProperties] = useState(getPropertiesForCategory(activeCategory));

    useEffect(() => {
      setProperties(getPropertiesForCategory(activeCategory));
      setSelectedValue(getPropertiesForCategory(activeCategory).find(prop => prop.isSelected).name);
    }, [activeCategory]);

    const [audiencesSum, setAudiencesSum] = useState([]);
  
    const navigate = useNavigate();
  
    const setChart = ({ category, range }) => {
      const sum = [];
      let startDate = null;
      let endDate = null;
      let totalProgress = 0;
      let count = 0; 
    
      Object.keys(category).forEach((item) => {
        if (item === "TotalData") return;
        const selectedProperty = properties.find((prop) => prop.isSelected);
    
        const today = new Date();
        today.setHours(0, 0, 0, 0); 
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - range);
    
        category[item].data.forEach((subItem) => {
          const subItemDate = new Date(subItem.Date);
          subItemDate.setHours(0, 0, 0, 0); 
    
          if (subItemDate >= sevenDaysAgo && subItemDate < today) {
            if (!startDate || subItemDate < startDate) startDate = subItemDate;
            if (!endDate || subItemDate > endDate) endDate = subItemDate;
    
            const value = parseInt(
              subItem[selectedProperty.displayName].replace(/[$,]/g, "")
            );    
            if (selectedProperty.name === "cost") {
              totalProgress += value;
              count++; 
            } else {
              totalProgress += value; 
            }
          }
        });
    
        let averageCost = null;
        let formattedProgress = addCommas(totalProgress); 
    
        if (selectedProperty.name === "cost" && count > 0) {
          averageCost = "$" + (totalProgress / count).toFixed(2); 
          formattedProgress = averageCost;
        }
    
        let conversionValue = null;
        if (selectedProperty.displayName === "Conversion Value") {
          conversionValue = totalProgress ? formatCurrency(totalProgress, 0) : null;
        }
    
        const obj = {
          progress: totalProgress,
          normalizedProgress: totalProgress,
          formattedProgress: averageCost || conversionValue || formattedProgress,
          yName: category[item].name,
          ySecondaryName: [selectedProperty.name],
        };
        sum.push(obj);
      });
    
      sum.sort((a, b) => b.progress - a.progress);
      sum[0].xName = formatDateToMMDD(startDate);
      sum[sum.length - 1].xName = formatDateToMMDD(endDate);
      setAudiencesSum(sum);
    };
    
  
    useEffect(() => {
      if (!Object.keys(categories).length) return;
      const category = categories[activeCategory];
      const range = dateRange.find((item) => item.isSelected).value;
  
      setChart({ category, range });
    }, [activeCategory, categories, dateRange, properties]);
  
    const [audienceIsLoading, setAudienceIsLoading] = useState(false);
  
    const [activeAudience, setActiveAudience] = useState("");
  
    useEffect(() => {
      const active = JSON.parse(localStorage.getItem("activeAudience"));
      if (Object.keys(additionalData).length) {
        setActiveAudience(additionalData._category);
      } else if (active) {
        setActiveAudience(active);
      }
    }, [additionalData]);
  
    const [selectedValue, setSelectedValue] = React.useState(
      properties.find((item) => item.isSelected).name
    );
  
    const handleChange = (e) => {
      const selected = e.target.value;
  
      const updatedProperties = properties.map((item) => ({
        ...item,
        isSelected: item.name === selected,
      }));
  
      setSelectedValue(selected);
      setProperties(updatedProperties);
    };
    
  
    return (
      <div className="audiences-chart">
        <div
        className="audiences-chart-container"
        >
          <div className="title-conatiner">
            <h3 className="audiences-chart-title display-3">
              Top Performing Audiences by
            </h3>
            <div className="audiences-main">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {properties.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name}
                    >
                      {item.name === "cost"
                        ? "Avg. CPA"
                        : item.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
          </div>
        </div>
        <br />
        <div className="shadow">
          <VerticalComposedChart data={audiencesSum} isLoading={false} />
        </div>
      </div>
    );
  };