import { useState, useEffect } from "react";
import useFormatter from "../../../hooks/useFormatter";
import {
    Select as CustomSelect,
    ChartCard,
  } from "../../../components";
import { useGlobalContext } from "../../../context";

export default function AverageStatsChart  ({ categories, dateRange }) {  
    const {activeCategory} = useGlobalContext()
    const { formatDateToMMDD, formatDate, addCommas } = useFormatter();
    const [categorySum, setCategorySum] = useState([]);
    const [percentages, setPercentages] = useState({
      cost: 0,
      clicks: 0,
      impressions: 0,
      conversions: 0,
      volumes: 0,
      deposits: 0,
      signups: 0
    });
    
    // Helper function to safely parse numeric values from strings that might be undefined
    const safeParseFloat = (value) => {
      if (!value) return 0;
      return parseFloat(value.replace(/[$,]/g, "") || 0);
    };

    const safeParseInt = (value) => {
      if (!value) return 0;
      return parseInt(value || 0);
    };
  
    const calculatePercentageDifference = (data, range) => {
      const today = new Date();
  
      const currentStartDate = new Date(today);
      currentStartDate.setDate(today.getDate() - range);
  
      const previousStartDate = new Date(currentStartDate);
      previousStartDate.setDate(currentStartDate.getDate() - range);
  
      const currentPeriod = {
        cost: 0,
        clicks: 0,
        impressions: 0,
        conversions: 0,
        volumes: 0,
        deposits: 0,
        signups: 0
      };
      const previousPeriod = {
        cost: 0,
        clicks: 0,
        impressions: 0,
        conversions: 0,
        volumes: 0,
        deposits: 0,
        signups: 0
      };
  
      data.forEach((item) => {
        const itemDate = new Date(item.Date);
  
        if (itemDate >= currentStartDate && itemDate <= today) {
          currentPeriod.cost += safeParseFloat(item["Cost per Result"]);
          currentPeriod.impressions += safeParseInt(item.Impressions);
          currentPeriod.clicks += safeParseInt(item.Clicks);
          currentPeriod.conversions += safeParseInt(item.Conversions);
          currentPeriod.volumes += safeParseFloat(item["Conversion Value"]);
          currentPeriod.deposits += safeParseFloat(item["Deposits"]);
          currentPeriod.signups += safeParseFloat(item["Sign Ups"]);

        } else if (itemDate >= previousStartDate && itemDate < currentStartDate) {
          previousPeriod.cost += safeParseFloat(item["Cost per Result"]);
          previousPeriod.impressions += safeParseInt(item.Impressions);
          previousPeriod.clicks += safeParseInt(item.Clicks);
          previousPeriod.conversions += safeParseInt(item.Conversions);
          previousPeriod.volumes += safeParseFloat(item["Conversion Value"]);
          previousPeriod.deposits += safeParseFloat(item["Deposits"]);
          previousPeriod.signups += safeParseFloat(item["Sign Ups"]);
        }
      });
  
      const percentageDifference = (current, previous) => {
        if (previous === 0) return current === 0 ? 0 : 100;
        return ((current - previous) / previous) * 100;
      };
  
      return {
        cost: percentageDifference(currentPeriod.cost, previousPeriod.cost),
        impressions: percentageDifference(
          currentPeriod.impressions,
          previousPeriod.impressions
        ),
        clicks: percentageDifference(currentPeriod.clicks, previousPeriod.clicks),
        conversions: percentageDifference(
          currentPeriod.conversions,
          previousPeriod.conversions
        ),
        volumes: percentageDifference(
          currentPeriod.volumes,
          previousPeriod.volumes
        ),
        deposits: percentageDifference(
          currentPeriod.deposits,
          previousPeriod.deposits
        ),
        signups: percentageDifference(
          currentPeriod.signups,
          previousPeriod.signups
        ),
      };
    };

    const setChart = ({ category, range }) => {
      const totalData = category.TotalData;
      let startDate = null;
      let endDate = null;
  
      let cost = 0;
      let clicks = 0;
      let impressions = 0;
      let conversions = 0;
      let volumes = 0;
      let deposits = 0;
      let signups = 0;
      
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to start of the current day
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - range);
  
      totalData.data.forEach((item) => {
        const itemDate = new Date(item.Date);
        itemDate.setHours(0, 0, 0, 0); // Set to start of the item date
  
        if (itemDate >= sevenDaysAgo && itemDate < today) {
          if (!startDate || itemDate < startDate) startDate = itemDate;
          if (!endDate || itemDate > endDate) endDate = itemDate;
  
          cost += safeParseFloat(item["Cost per Result"]);
          impressions += safeParseInt(item.Impressions);
          clicks += safeParseInt(item.Clicks);
          conversions += safeParseInt(item.Conversions);
          volumes += safeParseFloat(item["Conversion Value"]);
          deposits += safeParseFloat(item["Deposits"]);
          signups += safeParseFloat(item["Sign Ups"]);
        }
      });
      
      const formattedStartDate = startDate ? formatDateToMMDD(startDate) : "";
      const formattedEndDate = endDate ? formatDateToMMDD(endDate) : "";
  
      // Create an array of available metrics
      const metricsToShow = [
        {
          progress: impressions,
          scaledProgress: Math.log(impressions || 1), // Prevent log(0) by using || 1
          formattedProgress: addCommas(impressions),
          yName: "impressions",
          xName: range !== 1000 ? formattedStartDate : "",
          available: impressions > 0 // Only show if we have impressions data
        },
        {
          progress: clicks,
          scaledProgress: Math.log(clicks || 1),
          formattedProgress: addCommas(clicks),
          yName: "clicks",
          xName: "",
          available: clicks > 0 // Only show if we have clicks data
        },
        {
          progress: conversions,
          scaledProgress: Math.log(conversions || 1),
          formattedProgress: addCommas(conversions),
          yName: "conversions",
          xName: "",
          available: conversions > 0 // Only show if we have conversions data
        },
        {
          progress: (cost / range).toFixed(2),
          scaledProgress: Math.log(cost || 1),
          formattedProgress: addCommas(cost),
          yName: "cost",
          xName: range !== 1000 ? formattedEndDate : "",
          available: cost > 0 // Only show if we have cost data
        },
        {
          progress: volumes,
          scaledProgress: Math.log(volumes || 1),
          formattedProgress: addCommas(volumes),
          yName: "volumes",
          xName: range !== 1000 ? formattedEndDate : "",
          available: volumes > 0 // Only show if we have volumes data
        },
        {
          progress: deposits,
          scaledProgress: Math.log(deposits || 1),
          formattedProgress: addCommas(deposits),
          yName: "deposits",
          xName: range !== 1000 ? formattedEndDate : "",
          available: deposits > 0 // Only show if we have deposit data
        },
        {
          progress: signups,
          scaledProgress: Math.log(signups || 1),
          formattedProgress: addCommas(signups),
          yName: "signups",
          xName: range !== 1000 ? formattedEndDate : "",
          available: signups > 0 // Only show if we have signup data
        },
      ];
  
      // Filter out metrics that aren't available
      const availableMetrics = metricsToShow.filter(metric => metric.available);
      
      setCategorySum(availableMetrics);
      const percentageDiff = calculatePercentageDifference(
        totalData.data,
        parseInt(range)
      );
      setPercentages(percentageDiff);
    };
  
    useEffect(() => {
      if (!Object.keys(categories).length) return;
      const category = categories[activeCategory];
      const range = dateRange.find((item) => item.isSelected).value;
  
      setChart({ category, range });
    }, [activeCategory, categories, dateRange]);
  
    const [percentageText, setPercentageText] = useState("");
    
    useEffect(() => {
      const value = parseInt(dateRange.find((item) => item.isSelected).value);
      switch (value) {
        case 7:
          setPercentageText("than last week");
          break;
        case 30:
          setPercentageText("than last month");
          break;
        case 90:
          setPercentageText("than last 3 month");
          break;
        case 1000:
          setPercentageText("all time");
          break;
      }
    }, [dateRange]);


    return (
      <div className="average-stats">
        <div className="title-container">
          <h3
            className="average-stats-title"
          >
            Ad Network Stats 
          </h3>
      
        </div>
        <br />
        <div className="card-group" >
          {categorySum.map((item, index) => {
            return (
              <ChartCard
                key={index}
                title={item.yName == "cost" ? "Avg. CPA" : item.yName}
                value={
                  (item.yName == "volumes" || item.yName == "cost" ? "$" : "") +
                  addCommas(item.progress)
                }
                color="success"
                percentage={percentages[item.yName].toFixed(2)}
                text={percentageText}
              />
            );
          })}
        </div>
      </div>
    );
  };