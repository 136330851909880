import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import localData from "./localData";
import { ToastContainer, toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { auth, googleProvider, facebookProvider } from "./config/firebase.js";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import useFetch from "./hooks/useFetch.js";
import useFormatter from "./hooks/useFormatter.jsx";
import useCampaignsState from "./hooks/state-hook/useCampaignsState.jsx";
import useBlockchainsState from "./hooks/state-hook/useBlockchainsState.jsx";
import useReportsState from "./hooks/state-hook/useReportsState.jsx";
import useAnalyticsState from "./hooks/state-hook/useAnalyticsState.jsx";
import useMarketplaceState from "./hooks/state-hook/useMarketplaceState.jsx";
import useTrackingState from "./pages/tracking/useTrackingState.jsx";
import useSort from "./hooks/useSort.jsx";
import useUtil from "./hooks/useUtil.jsx";
import useConvertKit from "./hooks/useConvertKit.jsx";
import { v4 as uuidv4 } from "uuid";

export const Context = createContext();

const { avatar, user, coinTelegraph, bitcoin, beInCrypto } = localData.images;

const assistedCountries = [
  "ad",
  "ai",
  "al",
  "am",
  "an",
  "ao",
  "aq",
  "ar",
  "as",
  "at",
  "au",
  "aw",
  "ax",
  "az",
  "ba",
  "bb",
  "be",
  "bg",
  "bh",
  "bi",
  "bl",
  "bm",
  "bn",
  "bo",
  "bq",
  "br",
  "bs",
  "bt",
  "bv",
  "bw",
  "by",
  "ca",
  "cc",
  "cd",
  "cf",
  "cg",
  "ch",
  "ck",
  "cl",
  "cn",
  "co",
  "cr",
  "cu",
  "cv",
  "cw",
  "cx",
  "cz",
  "de",
  "dk",
  "dm",
  "do",
  "dz",
  "ee",
  "eg",
  "eh",
  "er",
  "es",
  "et",
  "fi",
  "fj",
  "fk",
  "fo",
  "fr",
  "ga",
  "gb",
  "gd",
  "ge",
  "gf",
  "gg",
  "gh",
  "gi",
  "gl",
  "gp",
  "gq",
  "gr",
  "gs",
  "gt",
  "gu",
  "gw",
  "gy",
  "hk",
  "hm",
  "hn",
  "hr",
  "ht",
  "hu",
  "id",
  "ie",
  "il",
  "im",
  "in",
  "io",
  "iq",
  "ir",
  "is",
  "it",
  "je",
  "jm",
  "jo",
  "jp",
  "kg",
  "kh",
  "ki",
  "km",
  "kn",
  "kp",
  "kr",
  "kw",
  "ky",
  "kz",
  "la",
  "lb",
  "lc",
  "li",
  "lk",
  "lr",
  "ls",
  "lt",
  "lu",
  "lv",
  "ly",
  "ma",
  "mc",
  "md",
  "me",
  "mf",
  "mg",
  "mh",
  "mk",
  "ml",
  "mm",
  "mn",
  "mo",
  "mp",
  "mq",
  "mr",
  "ms",
  "mt",
  "mu",
  "mv",
  "mw",
  "mx",
  "my",
  "mz",
  "na",
  "nc",
  "ne",
  "nf",
  "ng",
  "ni",
  "nl",
  "no",
  "np",
  "nr",
  "nu",
  "nz",
  "om",
  "pa",
  "pe",
  "pf",
  "pg",
  "ph",
  "pk",
  "pl",
  "pm",
  "pn",
  "pr",
  "ps",
  "pt",
  "pw",
  "py",
  "qa",
  "re",
  "ro",
  "rs",
  "ru",
  "rw",
  "sa",
  "sb",
  "sc",
  "sd",
  "se",
  "sg",
  "sh",
  "si",
  "sj",
  "sk",
  "sl",
  "sm",
  "sn",
  "so",
  "sr",
  "ss",
  "st",
  "sv",
  "sx",
  "sy",
  "sz",
  "tc",
  "td",
  "tf",
  "tg",
  "th",
  "tj",
  "tk",
  "tl",
  "tm",
  "tn",
  "to",
  "tr",
  "tt",
  "tv",
  "tw",
  "tz",
  "ua",
  "ug",
  "um",
  "us",
  "uy",
  "uz",
  "va",
  "vc",
  "ve",
  "vg",
  "vi",
  "vn",
  "vu",
  "wf",
  "ws",
  "ye",
  "yt",
  "za",
  "zm",
  "zw",
];

export default function Provider({ children }) {
  const {
    completeSignUp,
    resetAccountPassword,
    loadProfileData,
    loadUserProfile,
    getTransactions,
    loadBlockchainData,
    sumTransactionQuantities,
    updateTransaction,
    loadCampaignData,
    loadCampaignAudienceStatistics,
    loadCampaignGeoOrAdsStatistics,
    // startOrStopCampaignZone,
    viewCampaignInfo,
    connectAnalyticsAccount,
    loadAnalyticsProfile,
    loadAnalyticsProject,
    disconnectAnalyticsAccount,
    switchOrganization,
    updateUserInfo,
    resetEmail,
    inviteUser,
    deleteOrgInvitee,
    getAllCurrencies,
    playOrPauseCampaign,
    CreatePixelTag,
    CreateS2SPixelTag,
    CreateConversionPixel,
    getAdvertiserPixelStats,
    DeleteConversionPixel,
    validateConversion,
    verifyConversion,
    EditConversionPixel,
    isEmailExists,
    updateAccountBCAName,
    updateAccountStatusBCA,
    getInsightsImages,
    conversionTagEmail,
    OrganizationAdditionalData,
    updateCampaignManualData,
    // guideTutorials,
    // updateGuideTutorials,
    adminResetUserPassword,
    listInvitedUsers,
    getOrgMembers,
    addMemberToOrganization,
    loginSuccess,
  } = useFetch();

  const [assistedCampaignData, setAssistedCampaignData] = useState([
    {
      propertyName: "deFi",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "deFi",
      web3Audience: [
        "DeFi",
        "BTC Holders",
        "ETH Holders",
        "Stable Coin Holders",
        "Web3 Natives",
        "Crypto Traders",
        "DeFi Trading",
        "Finance Users",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "blockchainGaming",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "Blockchain Gaming",
      web3Audience: [
        "Blockchain Gaming",
        "Web3 Gamers",
        "PlayToEarn",
        "Web3 Natives",
        "BTC Holders",
        "ETH Holders",
        "Stable Coin Holders",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "marketplace",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "Marketplace",
      web3Audience: [
        "Web3 Natives",
        "BTC Holders",
        "ETH Holders",
        "Stable Coin Holders",
        "NFT Marketplaces",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "infrastructure",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "Infrastructure",
      web3Audience: [
        "Custom",
        // "Custom Audience",
        "Web3 Natives",
        "Stable Coin Holders",
        "BTC Holders",
        "ETH Holders",
        "Finance Users",
        "DeFi",
        "DeFi News",
        "Crypto Traders",
        "DeFi Trading",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "NFT",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "NFT",
      web3Audience: [
        "NFT Traders",
        "NFT Marketplaces",
        "NFT Collectors",
        "Web3 Gamers",
        "PlayToEarn",
        "Web3 Natives",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "DEX",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "DEX",
      web3Audience: [
        "Crypto Traders",
        "DeFi Trading",
        "DeFI",
        "BTC Holders",
        "ETH Holders",
        "Stable Coin Holders",
        "Web3 Natives",
        "Finance Users",
        "DeFi News",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "CEX",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "CEX",
      web3Audience: [
        "Crypto Traders",
        "DeFi Trading",
        "Web3 Natives",
        "BTC Holders",
        "ETH Holders",
        "Finance Users",
        "DeFi",
        "Stable Coin Holders",
        "DeFi News",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "tradeFi",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "TradeFi",
      web3Audience: [
        "Crypto Traders",
        "DeFi Trading",
        "Web3 Natives",
        "BTC Holders",
        "ETH Holders",
        "Finance Users",
        "DeFi",
        "Stable Coin Holders",
        "DeFi News",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "bettingAndgambling",
      alternativeName: "Betting & Gambling",
      campaignName: "My Campaign",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [
        "Awareness (max reach)",
        "Engagement (website visit/interaction)",
      ],
      category: "Betting & Gambling",
      web3Audience: [
        "Casino Players",
        "Casinos Power Users",
        "Sports Betting",
        "PlayToEarn",
        "Web3 Natives",
        "BTC Holders",
        "ETH Holders",
        "SOL Holders",
        "BNB Holders",
      ],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: assistedCountries,
      deviceType: [],
      isSelected: false,
    },
    {
      propertyName: "custom",
      campaignName: "",
      targetURL: "",
      smartContractAddress: "",
      startDate: "",
      endDate: "",
      campaignType: [],
      category: "",
      web3Audience: [],
      walletTargeting: [],
      chainTargeting: [],
      operativeSystem: [],
      browser: [],
      geo: [],
      deviceType: [],
      isSelected: false,
    },
  ]);
  const [isAssistedCampaignEnabled, setIsAssistedCampaignEnabled] =
    useState(false);
  // const { checkForBalanceOnLast7days } = useUtil();

  const { sendConvertKit } = useConvertKit();

  const { formatDateToYYYYMMDD, calculateTimeLeft } = useFormatter();

  const location = useLocation();
  const pathname = location.pathname;

  const navigate = useNavigate();

  const navbarRef = useRef(null);
  const marketplaceTheadRef = useRef(null);
  const marketplaceScrollItems = useRef(null);

  const [state, setState] = useState({
    fetchedData: null,
    localData,
  });
  const [animations, setAnimations] = useState({
    fade: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.5 },
    },
  });
  const [inviteData, setInviteData] = useState({
    invited: false,
    inviteOrgID: "",
  });
  const [refCode, setRefCode] = useState("");
  const [isMultiStepFormDone, setIsMultiStepFormDone] = useState(false);

  // const [allCurrencies, setAllCurrencies] = useState([]);

  const [showSidebar, setShowSidebar] = useState(false);
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
  const [isAdminTeamMember, setIsAdminTeamMember] = useState(false);

  const [selectedObjective, setSelectedObjective] = useState("");

  // useEffect(() => {
  //     if (isAdminTeamMember)
  //         checkForBalanceOnLast7days(
  //             requestedData.userProfileData.allOrganization
  //         );
  // }, [isAdminTeamMember]);

  useEffect(() => {
    const isDarkModeEnabled = JSON.parse(
      localStorage.getItem("isDarkModeEnabled")
    );
    setIsDarkModeEnabled(isDarkModeEnabled);
    document.body.classList[isDarkModeEnabled ? "add" : "remove"]("dark-mode");
  }, []);

  const [placeholders, setPlaceholders] = useState({
    editProfile: {
      image: null,
      modalImage: null,
      croppedImage: null,
    },
  });

  const expandedAlert = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    className: "expanded-toast",
    bodyClassName: "expanded-toast-body",
    closeButton: true,
    draggablePercent: 10,
  };

  const errorAlertExpanded = (message = "This is a persistent toast!") => {
    toast.error(message, expandedAlert);
  };
  const warningAlertExpanded = (
    message = "This is a persistent toast!",
    options
  ) => {
    toast.warning(message, { ...expandedAlert, ...options });
  };
  const successAlertExpanded = (message = "This is a persistent toast!") => {
    toast.success(message, expandedAlert);
  };

  const successAlert = (message = "success") => {
    toast.success(message, expandedAlert);
  };
  const errorAlert = (message = "error") => {
    toast.error(message, expandedAlert);
  };
  const warningAlert = (message = "warning") => {
    toast.warning(message, expandedAlert);
  };

  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);
  const handleGoogleSignIn = async () => {
    let email;
    setIsGoogleSignInLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      const response = await signInWithPopup(auth, provider);
      email = response.user.email;
      if (response.user.uid) {
        const temRaw = {
          uid: response.user.uid,
          email: response.user.email,
        };

        const data = await isEmailExists(() => {}, temRaw);
        const isExist = data.res_data.emailExists;
        if (!isExist) {
          localStorage.setItem(
            "guideTutorials",
            JSON.stringify([
              "loginTutorial",
              "campaignInformationTutorial",
              "uploadAdsTutorial",
              "targetingTutorial",
              "summaryTutorial",
              "trackingTutorial",
              "installPixelTutorial",
              "installPixelModalTutorial",
              "createConversionTutorial",
              "analyticsTutorial",
              "prTutorial",
              "prNoticeModalTutorial",
            ])
          );
          localStorage.setItem("isAdditionalFormCompleted", false);
          sendConvertKit({
            name:
              response.user.displayName || response.user.email.split("@")[0],
            email: response.user.email,
          });
          let googleSignUpData = {
            email: response.user.email,
            uuid: response.user.uid,
            photoURL: response.user.photoURL,
            name: response.user.displayName,
            invited: false,
          };

          const data = await completeSignUp(() => {}, googleSignUpData);
          successAlert(
            "You have successfully authenticated with Google account"
          );
          console.log(response.user.email);

          getLoadUserProfile();
        }
      }
    } catch (err) {
      if (err?.response?.data?.res_data?.emailExists) {
        successAlert("You have successfully signed in with Google account!");
        await loginSuccess(() => {}, { email: email });
        getLoadUserProfile();
      } else {
        // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        warningAlert(
          "Something went wrong. If you haven't signed up with Google, please sign up first before attempting to sign in."
        );
        console.error(err, "=handleGoogleSignIn= request error");
      }
    }
    setIsGoogleSignInLoading(false);
  };

  const [isGoogleSignUpLoading, setIsGoogleSignUpLoading] = useState(false);

  const handleGoogleSignUp = async () => {
    setIsGoogleSignUpLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });

      const response = await signInWithPopup(auth, provider);
      // let response = await signInWithPopup(auth, googleProvider);

      if (response.user.uid) {
        const temRaw = {
          uid: response.user.uid,
          email: response.user.email,
        };

        const data = await isEmailExists(() => {}, temRaw);
        const isExist = data.res_data.emailExists;

        if (!isExist) {
          localStorage.setItem(
            "guideTutorials",
            JSON.stringify([
              "loginTutorial",
              "campaignInformationTutorial",
              "uploadAdsTutorial",
              "targetingTutorial",
              "summaryTutorial",
              "trackingTutorial",
              "installPixelTutorial",
              "installPixelModalTutorial",
              "createConversionTutorial",
              "analyticsTutorial",
              "prTutorial",
              "prNoticeModalTutorial",
            ])
          );
          localStorage.setItem("isAdditionalFormCompleted", false);
          sendConvertKit({
            name:
              response.user.displayName || response.user.email.split("@")[0],
            email: response.user.email,
          });

          let googleSignUpData = {
            email: response.user.email,
            uuid: response.user.uid,
            photoURL: response.user.photoURL,
            name: response.user.displayName,
            invited: inviteData.invited,
            inviteOrgID: inviteData.inviteOrgID,
            referralCode: refCode,
          };
          if (!refCode) delete googleSignUpData.referralCode;

          const data = await completeSignUp(() => {}, googleSignUpData);
          console.log(data, "=handleGoogleSignUp= request");
          successAlert(
            "You have successfully authenticated with Google account"
          );
          getLoadUserProfile();
        }
      }
    } catch (err) {
      if (err?.response?.data?.res_data?.emailExists) {
        warningAlert(err?.response?.data?.res_msg || "Internal Server Error");
        successAlert("You have successfully signed in with Google account!");
        getLoadUserProfile();
      } else {
        errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        console.error(err, "=handleGoogleSignUp= request error");
      }
    }
    setIsGoogleSignUpLoading(false);
  };

  const handleFacebookSignIn = async () => {
    try {
      let response = await signInWithPopup(auth, facebookProvider);

      sendConvertKit({
        name: response.user.displayName || response.user.email.split("@")[0],
        email: response.user.email,
      });

      if (pathname === "/sign-up") {
        // Extract necessary user information from the Facebook response
        const { email, uid, photoURL, displayName } = response.user;

        const facebookSignUpData = {
          email,
          uuid: uid,
          photoURL,
          name: displayName,
          invited: inviteData.invited,
          inviteOrgID: inviteData.inviteOrgID,
          referralCode: refCode,
        };

        if (!refCode) delete facebookSignUpData.referralCode;

        const data = await completeSignUp(() => {}, facebookSignUpData);
      }
    } catch (error) {
      console.error("Facebook Sign-In Error:", error.message);
    }
  };

  const [showCampaingWizardDrawer, setShowCampaignWizardDrawer] =
    useState("none");
  const [showProfileDrawer, setShowProfileDrawer] = useState("none");
  const [showDepositModal, setShowDepositModal] = useState("none");
  const [termsAccepted, setTermsAccepted] = useState(false);

  // REQUESTED STATES
  const DEFAULTREQUESTEDDATA = {
    userProfileData: {
      profile_image: user,
      userName: "User",
      allOrganization: [],
      currentOrganization: {
        balance: 0,
        balancePredicted: 0,
        additionalData: {},
        billing: {
          companyName: "",
          address: "",
        },
      },
    },
    guideTutorials: [
      "loginTutorial",
      "campaignInformationTutorial",
      "uploadAdsTutorial",
      "targetingTutorial",
      "summaryTutorial",
      "trackingTutorial",
      "installPixelTutorial",
      "installPixelModalTutorial",
      "createConversionTutorial",
      "analyticsTutorial",
      "prTutorial",
      "prNoticeModalTutorial",
    ],
    blockchainData: [],
    transactionsData: [],
    reportsData: [
      {
        date: "03/24/2024",
        accountName: "Account name 1",
        commissions: "Comissions 1",
        transactionID: "Transaction ID 1",
        isPaid: false,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 2",
        commissions: "Comissions 2",
        transactionID: "Transaction ID 2",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 3",
        commissions: "Comissions 3",
        transactionID: "Transaction ID 3",
        isPaid: false,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 4",
        commissions: "Comissions 4",
        transactionID: "Transaction ID 4",
        isPaid: false,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 5",
        commissions: "Comissions 5",
        transactionID: "Transaction ID 5",
        isPaid: false,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 6",
        commissions: "Comissions 6",
        transactionID: "Transaction ID 6",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 7",
        commissions: "Comissions 7",
        transactionID: "Transaction ID 7",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 8",
        commissions: "Comissions 8",
        transactionID: "Transaction ID 8",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 9",
        commissions: "Comissions 9",
        transactionID: "Transaction ID 9",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 10",
        commissions: "Comissions 10",
        transactionID: "Transaction ID 10",
        isPaid: true,
      },
      {
        date: "03/24/2024",
        accountName: "Account name 11",
        commissions: "Comissions 11",
        transactionID: "Transaction ID 11",
        isPaid: true,
      },
    ],
    campaignData: {
      currentOrganizationCampaigns: [],
      dashboardSummaryData: {
        totalAdAmountSpent: 0,
        totalClicks: 0,
        totalImpressions: 0,
        conversions: 0,
        CTR: 0,
        totalAdSpend: 0,
        CPC: 0,
        transactionValue: 0,
        transactions: 0,
      },
      dataSize: 0,
    },
    campaignStatistics: {
      audience: {},
      ads: [],
      geo: [],
      zones: [],
    },
    campaignDataSummary: {},

    analyticsProfileData: {
      isFetched: false,
      accountSummaries: [],
    },
    analyticsProjectData: {
      mapData: {
        rows: [],
      },
      tableData: {
        rows: [],
      },
      transformedData: [],
    },
    allCurrenciesData: [],
    isAnalyticsConnected: false,
  };

  const [authUser, setAuthUser] = useState(null);

  const [requestedData, setRequestedData] = useState(DEFAULTREQUESTEDDATA);

  // LOADINGS STATES
  const [isLoading, setIsLoading] = useState({
    userProfileIsLoading: false,
    blockchainDataIsLoading: false,
    transactionsDataIsLoading: false,
    reportsDataIsLoading: false,
    campaignDataIsLoading: false,
    analyticsConnectIsLoading: false,
    analyticsDisconnectIsLoading: false,
    analyticsProfileIsLoading: false,
    analyticsProjectIsLoading: false,
    userInfoIsUpdating: false,
    emailIsUpdating: false,
    passwordIsUpdating: false,
    inviteUserIsLoading: false,
    campaignStatisticsLoading: false,
    updateTransactionIsLoading: false,
    adminResetUserPasswordIsLoading: false,
    // startOrStopCampaignZoneIsLoading: false
  });

  const [conversionOptions, setConversionOptions] = useState({
    pixelScriptTag: { value: "", blockChainPixelID: "", isLoading: false },
    s2sPixelScriptTag: { value: "", blockChainPixelID: "", isLoading: false },
    trackAnEventScriptTag: {
      value: "",
      tagID: "",
      apiKey: "",
      isLoading: false,
    },
    advertiserPixelStats: { value: [], isLoading: false },
  });

  const updateAccountBCANameRequest = async ({
    bcaName = "",
    organizationID = "",
    setBcaNameIsLoading = () => {},
  }) => {
    setBcaNameIsLoading(true);
    const tempRaw = {
      organizationID,
      bcaName,
      uuid: auth.currentUser.uid,
    };
    try {
      const data = await updateAccountBCAName(() => {}, tempRaw);

      console.log(data, "=updateAccountBCAName= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateAccountBCAName= request error");
    }
    setBcaNameIsLoading(false);
  };

  const updateAccountStatusBCARequest = async ({
    account_status = "",
    organizationID = "",
    setBcaStatusIsLoading = () => {},
    setShowModal = () => {},
  }) => {
    setBcaStatusIsLoading(true);
    const tempRaw = {
      organizationID,
      account_status,
    };
    try {
      const data = await updateAccountStatusBCA(() => {}, tempRaw);
      setShowModal(false);
      console.log(data, "=updateAccountStatusBCA= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateAccountStatusBCA= request error");
      setShowModal(false);
    }
    setBcaStatusIsLoading(false);
  };

  const _getInsightsImages = async ({
    setIsLoading = false,
    callback = () => {},
  }) => {
    setIsLoading(true);
    const tempRaw = {
      // organizationID,
    };
    try {
      const data = await getInsightsImages(() => {}, tempRaw);
      callback(data);
      console.log(data, "=getInsightsImages= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=getInsightsImages= request error");
    }
    setIsLoading(false);
  };

  // const getGuideTutorials = async () => {
  //     const tempRaw = {
  //         uuid: auth.currentUser.uid,
  //     };
  //     try {
  //         // const data = await guideTutorials(() => {}, tempRaw);
  //         const data = {
  //             res_data: {
  //                 guideTutorials: [
  //                     "loginTutorial",
  //                     "campaignCreationTutorial",
  //                     "trackingTutorial",
  //                     "analyticsTutorial",
  //                 ],
  //             },
  //         };
  //         console.log(data, "=guideTutorials= request");

  //         setRequestedData((prev) => ({
  //             ...prev,
  //             guideTutorials: data.res_data.guideTutorials,
  //         }));
  //     } catch (err) {
  //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
  //         console.error(err, "=guideTutorials= request error");
  //     }
  // };

  // const getUpdateGuideTutorials = async ({
  //     guideTutorials = [
  //         "loginTutorial",
  //         "campaignCreationTutorial",
  //         "trackingTutorial",
  //         "analyticsTutorial",
  //     ],
  // }) => {
  //     const tempRaw = {
  //         uuid: auth.currentUser.uid,
  //         guideTutorials,
  //     };
  //     try {
  //         // const data = await updateGuideTutorials(() => {}, tempRaw);
  //         // console.log(data, "=updateGuideTutorials= request");

  //         getGuideTutorials();
  //     } catch (err) {
  //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
  //         console.error(err, "=updateGuideTutorials= request error");
  //     }
  // };

  // CREATE PIXEL TAG   CreatePixelTag

  const CreateS2SPixelTagRequest = async () => {
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
    setConversionOptions((prev) => ({
      ...prev,
      pixelScriptTag: { ...prev.pixelScriptTag, isLoading: true },
    }));
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      // advertiserId: auth.currentUser.uid,
      organizationId: organizationID,
    };
    try {
      const data = await CreateS2SPixelTag(() => {}, tempRaw);
      console.log(data.response, "=CreatePixelTag= request");
      setConversionOptions((prev) => ({
        ...prev,
        s2sPixelScriptTag: {
          value: data.response.trackerpixel,
          isLoading: false,
        },
      }));
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=CreatePixelTag= request error");
      setConversionOptions((prev) => ({
        ...prev,
        s2sPixelScriptTag: { value: "", isLoading: false },
      }));
    }
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
  };

  const CreatePixelTagRequest = async () => {
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
    setConversionOptions((prev) => ({
      ...prev,
      pixelScriptTag: { ...prev.pixelScriptTag, isLoading: true },
    }));
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      // advertiserId: auth.currentUser.uid,
      organizationId: organizationID,
    };
    try {
      const data = await CreatePixelTag(() => {}, tempRaw);
      console.log(data.response, "=CreatePixelTag= request");

      const firstKey = Object.keys(data.response)[0];
      const firstElement = data.response[firstKey];

      setConversionOptions((prev) => ({
        ...prev,
        pixelScriptTag: {
          value: firstElement?.jspixel ? firstElement?.jspixel : "",
          blockChainPixelID: data.blockChainPixelID,
          isLoading: false,
        },
      }));
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=CreatePixelTag= request error");
      setConversionOptions((prev) => ({
        ...prev,
        pixelScriptTag: { value: "", isLoading: false },
      }));
    }
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
  };

  const [googleTrackerId, setGoogleTrackerId] = useState(null);

  const CreateConversionPixelRequest = async ({
    tagName = "",
    pixelType = "Sign-up",
    tracker_group,
  }) => {
    setConversionOptions((prev) => ({
      ...prev,
      trackAnEventScriptTag: {
        value: "",
        tagID: "",
        apiKey: "",
        isLoading: true,
      },
    }));
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      uuid: auth.currentUser.uid,
      organizationId: organizationID,
      name: tagName,
      type: pixelType ? pixelType : "signup",
      tracker_group: tracker_group,
    };

    try {
      setConversionActiveStep(conversionActiveStep + 1);
      const data = await CreateConversionPixel(() => {}, tempRaw);
      console.log(data, "=CreateConversionPixel= request");
      setConversionOptions((prev) => ({
        ...prev,
        trackAnEventScriptTag: {
          value: data.response.retargetpixel
            ? data.response.retargetpixel
            : data.response.jspixel,
          tagID: data.response.trackerid,
          isLoading: false,
          s2s: data.response.s2spixel,
          htmlpixel: data.response.htmlpixel,
        },
      }));
      getAdvertiserPixelStatsRequest();
      successAlert(
        `Stat '${tagName}' created and added to the table. Please ensure you followed the documentation before closing the tab.`
      );

      setGoogleTrackerId(data.response.trackerid);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=CreateConversionPixel= request error");
      setConversionOptions((prev) => ({
        ...prev,
        trackAnEventScriptTag: {
          value: "",
          isLoading: false,
          s2s: "",
          htmlpixel: "",
        },
      }));
    }
  };
  const EditConversionPixelRequest = async ({
    trackerid = "",
    name = "",
    type,
    tracker_group,
    setIsLoading = () => {},
  }) => {
    setIsLoading(true);
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      uuid: auth.currentUser.uid,
      organizationId: organizationID,
      trackerid: trackerid,
      name: name,
      type: type,
      tracker_group: tracker_group,
    };

    try {
      const data = await EditConversionPixel(() => {}, tempRaw);
      console.log(data, "=EditConversionPixel= request");
      getAdvertiserPixelStatsRequest();
      successAlert(data.message);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=EditConversionPixel= request error");
    }
    setIsLoading(false);
  };

  const DeleteConversionPixelRequest = async ({
    tagId = "",
    tagName = "",
    setIsLoading = () => {},
  }) => {
    setIsLoading(true);

    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      uuid: auth.currentUser.uid,
      organizationId: organizationID,
      trackerid: tagId,
      tagName,
    };
    try {
      const data = await DeleteConversionPixel(() => {}, tempRaw);
      console.log(data, "=DeleteConversionPixel= request");
      // successAlert(`Stat "${tagName}" has been deleted successfully.`)
      successAlert(data.message);

      getAdvertiserPixelStatsRequest();
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=DeleteConversionPixel= request error");
    }
    setIsLoading(false);
  };

  const validateConversionRequest = async ({
    tagId = "",
    url = "",
    setIsLoading = () => {},
  }) => {
    setIsLoading(true);

    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      organizationID: organizationID,
      tracker_id: tagId,
      url: url,
    };
    try {
      const data = await validateConversion(() => {}, tempRaw);
      if (data.response.adcode) {
        successAlert("Conversion tracker have active campaigns.");
      } else if (data.response.code) {
        // successAlert("Conversion tracker don't have any active campaigns.");
      } else {
        // errorAlert("Conversion tracker don't have any active campaigns.");
      }
      return data;
    } catch (err) {
      errorAlert("Conversion tracker don't have any active campaigns.");
    }
    setIsLoading(false);
  };

  const verifyConversionRequest = async ({
    tagId = "",
    bannerid = "",
    viewerid = "",
    setIsLoading = () => {},
  }) => {
    setIsLoading(true);

    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      organizationID: organizationID,
      trackerid: tagId,
      bannerid: bannerid,
      viewerid: viewerid,
    };
    try {
      const data = await verifyConversion(() => {}, tempRaw);
      if (data.response.details.status === 1) {
        successAlert("Conversion tracking successfully verified.");
      }
      return data;
    } catch (err) {
      errorAlert("There was a problem in verfication! check after some time.");
    }
    setIsLoading(false);
  };

  const getAdvertiserPixelStatsRequest = async (successCallback = () => {}) => {
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
    setConversionOptions((prev) => ({
      ...prev,
      advertiserPixelStats: { ...prev.advertiserPixelStats, isLoading: true },
    }));
    const endDate = new Date(); // today
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6); // 7 days ago

    const selectionRangeFormatted = JSON.parse(
      localStorage.getItem("selectionRangeFormatted")
    ) || {
      dateFrom: formatDate(startDate),
      dateTill: formatDate(endDate),
    };
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      organizationId: organizationID,
      // startDate: selectionRangeFormatted.dateFrom,
      // endDate: selectionRangeFormatted.dateTill,
    };
    try {
      const data = await getAdvertiserPixelStats(() => {}, tempRaw);
      console.log(data, "=getAdvertiserPixelStats= request");
      const pixelStatsArray = Object.entries(data.response).map(
        ([key, value]) => ({
          id: key,
          ...value,
        })
      );

      setConversionOptions((prev) => ({
        ...prev,
        advertiserPixelStats: { value: pixelStatsArray, isLoading: false },
      }));
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=getAdvertiserPixelStats= request error");
      setConversionOptions((prev) => ({
        ...prev,
        advertiserPixelStats: { value: [], isLoading: false },
      }));
    }
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
  };

  const [adminTrackingPixels, setAdminTrackingPixels] = useState();
  const [isTrackingPageLoading, setIsTrackingPageLoading] = useState(false);
  const [selectedConversionTag, setSlectedConversionTag] = useState("all");

  const getAdvertiserPixelStatsRequestAdmin = async (
    orgId,
    successCallback = () => {}
  ) => {
    setIsTrackingPageLoading(true);
    const tempRaw = {
      organizationId: orgId,
    };
    try {
      const data = await getAdvertiserPixelStats(() => {}, tempRaw);
      console.log(data, "Admin Admin Admin");

      setAdminTrackingPixels(data.response);
      setIsTrackingPageLoading(false);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=getAdvertiserPixelStats= request error");
      setConversionOptions((prev) => ({
        ...prev,
        advertiserPixelStats: { value: [], isLoading: false },
      }));
      setIsTrackingPageLoading(false);
    }
  };

  const _conversionTagEmail = async ({
    setIsLoading = () => {},
    callback = () => {},
    pixelName = "",
    organizationID = "",
    uuid = "",
    developerEmail = "",
    pixelId = "",
  }) => {
    setIsLoading(true);
    const tempRaw = {
      // organizationID,
      pixelName,
      organizationID,
      uuid,
      developerEmail,
      pixelId,
    };
    try {
      const data = await conversionTagEmail(() => {}, tempRaw);
      callback(data);
      successAlert("Email sent successfully!");
      console.log(data, "=conversionTagEmail= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=conversionTagEmail= request error");
    }
    setIsLoading(false);
  };
  const _OrganizationAdditionalData = async (props) => {
    const {
      setIsLoading = () => {},
      callback = () => {},
      setQualificationState = () => {},
      companyName = "",
      _category = "",
      marketingBudget = "",
      websiteURL = "",
      marketingGoal = [],
    } = props;
    setIsLoading(true);
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const tempRaw = {
      uuid: auth.currentUser.uid,
      organizationID,
      companyName,
      _category,
      marketingBudget,

      websiteURL,
      marketingGoal,
    };
    try {
      const data = await OrganizationAdditionalData(() => {}, tempRaw);
      callback(data);
      successAlert("Organization additional data updated successfully.");
      localStorage.setItem("isAdditionalFormCompleted", true);
      getLoadUserProfile();
      navigate("/campaigns");
      setIsLoading(false);
      console.log(data, "=OrganizationAdditionalData= request");
    } catch (err) {
      setQualificationState((prev) => ({
        ...prev,
        isAdditionalDataFormCompleted: false,
      }));
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=OrganizationAdditionalData= request error");
      setIsLoading(false);
    }
  };

  const _updateCampaignManualData = async (props) => {
    const {
      setIsLoading = () => {},
      callback = () => {},
      campaignID = "",
      itemName = "",
      amount_spent,
      clicks,
      impressions,
      conversions,
      selectedTagConversion,
    } = props;
    setIsLoading(true);
    const organizationID = requestedData.userProfileData.currentOrganization.id;

    const tagConversions = selectedTagConversion
      ? [{ tagID: selectedTagConversion, conversionCount: conversions }]
      : [];

    const tempRaw = {
      organizationID,
      campaignID,
      manualDataUpdates: [
        {
          [itemName]: {
            spent: amount_spent,
            clicks,
            impressions,
            tagConversions,
            conversions: conversions,
          },
        },
      ],
    };
    try {
      const data = await updateCampaignManualData(() => {}, tempRaw);
      callback(data);
      successAlert("Progression data updated successfully!");
      console.log(data, "=updateCampaignManualData= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateCampaignManualData= request error");
    }
    setIsLoading(false);
  };

  const [paymentHistory, setPaymentHistory] = useState([]);

  // LOAD USER PROFILE DATA
  const getLoadUserProfile = async (orgId = undefined) => {
    if (!auth.currentUser) return;
    // setIsLoading((prev) => ({ ...prev, userProfileIsLoading: true}));
    setIsLoading((prev) => ({
      ...prev,
      userProfileIsLoading: true,
      campaignDataIsLoading: true,
    }));
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationId: orgId,
    };

    try {
      const data = await loadUserProfile(() => {}, temRaw);
      console.log(data, "=loadUserProfile= request");
      const activeData = data.res_data.currentOrganization.additionalData;

      if (Object.keys(activeData).length == 0) {
        setIsMultiStepFormDone(false);
        navigate("/qualification");
      } else {
        setIsMultiStepFormDone(true);
        // navigate("/campaigns")
      }

      // const token = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/token.php`, {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({
      //         client_id: "blockchain-ads",
      //         client_secret: "blockchain-adsapi",
      //         grant_type: "client_credentials",
      //     }),
      // });

      // const access_token = await token.json();

      // const revivedata = await fetch(
      //     `${process.env.REACT_APP_API_ENDPOINT}/advertiser/advertiser-signup.php?access_token=` +
      //         access_token.access_token,
      //     {
      //         method: "POST",
      //         headers: { "Content-Type": "application/json" },
      //         body: JSON.stringify({
      //             username: data.res_data.userName,
      //             password: data.res_data.userName,
      //             email: data.res_data.userEmail,
      //             name: data.res_data.currentOrganization.name,
      //             firebaseuserid: data.res_data.userId,
      //             firebaseorganizationid: data.res_data.currentOrganization.id,
      //         }),
      //     }
      // );

      // const revivedataJSON = await revivedata.json();

      if (
        data.res_data.currentOrganization.id ==
        "d2a4a5c0-05f3-4673-93bd-ec592e63346d"
      ) {
        data.res_data.currentOrganization.balance = -4.19;
      }
      setRequestedData((prev) => ({
        ...prev,
        userProfileData: data.res_data,
        reportsData: data.res_data.reportsData || [],
        // blockchainData: []
      }));

      setPaymentHistory(data.res_data.payment_history.reverse());

      const campaignsStates = JSON.parse(
        localStorage.getItem("campaignsStates")
      );

      getLoadCampaignData(
        data.res_data.currentOrganization.id,
        1,
        campaignsStates?.pageSize || 15
      );

      const isAdminTeamMember = data.res_data.allOrganization.find(
        (member) => member.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
      );

      setIsAdminTeamMember(isAdminTeamMember);

      const pathname = window.location.pathname;
      const match = pathname.match(/^\/campaigns\/(\d+)$/);

      if (match) {
        navigate("/");
      }
    } catch (err) {
      // if (err?.response?.data?.res_data?.redirect) {
      //     navigate("/sign-up");
      //     errorAlert(
      //         "Oops! It seems you haven't signed up with Google yet. Please create an account first before attempting to sign in."
      //     );
      //     // successAlert('Creating a google account...')
      //     // handleGoogleSignUp()
      // } else {
      //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      // }
      errorAlert(
        err?.response?.data?.res_msg ||
          "Something went wrong. Please try to log in again."
      );

      console.error(err, "=loadUserProfile= request error");
      setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: false }));
      handleSignOut();
    }
    setIsLoading((prev) => ({ ...prev, userProfileIsLoading: false }));
  };
  // LOAD CAMPAIGN DATA

  const [statusOption, setStatusOption] = useState(["Active, Paused"]);
  const [selectedStatusOption, setSelectedOption] = useState([
    "Active",
    "Paused",
  ]);

  const getLoadCampaignData = async (
    currentOrganizationID,
    page = 1,
    pageSize = 15,
    customStartDate,
    customEndDate
  ) => {    
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: true }));
    const endDate = new Date(); // today
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6); // 7 days ago

    const selectionRangeFormatted = JSON.parse(
      localStorage.getItem("selectionRangeFormatted")
    ) || {
      dateFrom: formatDate(startDate),
      dateTill: formatDate(endDate),
    };
    const storedPageSize =
      JSON.parse(localStorage.getItem("campaignsStates"))?.pageSize || pageSize;

    const tempRaw = {
      uuid: auth.currentUser.uid,
      currentOrganizationID,
      page,
      pageSize: pageSize,
      dateFrom: customStartDate ? formatDate(customStartDate) : selectionRangeFormatted.dateFrom,
      dateTill: customEndDate ? formatDate(customEndDate) : selectionRangeFormatted.dateTill,
      statusType: selectedStatusOption,
    };
    try {
      let data = await loadCampaignData(() => {}, tempRaw);
      setRequestedData((prev) => ({
        ...prev,
        campaignData: { isRequested: true, ...data.res_data },
      }));
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=loadCampaignData= request error");
    }
    setIsLoading((prev) => ({ ...prev, campaignDataIsLoading: false }));
  };

  const [timeRemaining, setTimeRemaining] = useState(0);

  const { handleOnClick, getSortIcon } = useSort({
    requestedData,
    setRequestedData,
  });

  const getLoadCampaignAudienceStatistics = async (campaignId) => {
    setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: true }));
    const endDate = new Date(); // today
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6); // 7 days ago

    const selectionRangeFormatted = JSON.parse(
      localStorage.getItem("selectionRangeFormatted")
    ) || {
      dateFrom: formatDate(startDate),
      dateTill: formatDate(endDate),
    };
    const tempRaw = {
      campaignID: campaignId,
      startDate: selectionRangeFormatted.dateFrom,
      endDate: selectionRangeFormatted.dateTill,
    };
    try {
      const data = await loadCampaignAudienceStatistics(() => {}, tempRaw);
      setRequestedData((prev) => ({
        ...prev,
        campaignStatistics: {
          ...prev.campaignStatistics,
          audience: data.res_data,
        },
      }));
      handleOnClick("impressions", "desc");
    } catch (err) {
      // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=loadCampaignStatistics= request error");
      setRequestedData((prev) => ({
        ...prev,
        campaignStatistics: { geo: {}, ads: {}, audience: {}, zones: {} },
      }));
      navigate("/");
      warningAlert("Redirecting: Something went wrong!");
    }
    setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: false }));
  };

  const getLoadCampaignGeoOrAdsStatistics = async (campaignId, groupBy) => {
    setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: true }));
    const endDate = new Date(); // today
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6);

    const selectionRangeFormatted = JSON.parse(
      localStorage.getItem("selectionRangeFormatted")
    ) || {
      dateFrom: formatDate(startDate),
      dateTill: formatDate(endDate),
    };
    const tempRaw = {
      campaignID: campaignId,
      startDate: selectionRangeFormatted.dateFrom,
      endDate: selectionRangeFormatted.dateTill,
      type: groupBy,
      organizationID: requestedData.userProfileData.currentOrganization.id,
    };
    try {
      const data = await loadCampaignGeoOrAdsStatistics(() => {}, tempRaw);
      if (groupBy === "ads") {
        setRequestedData((prev) => ({
          ...prev,
          campaignStatistics: {
            ...prev.campaignStatistics,
            ads: data.res_data,
          },
        }));
      } else if (groupBy === "geo") {
        setRequestedData((prev) => ({
          ...prev,
          campaignStatistics: {
            ...prev.campaignStatistics,
            geo: data.res_data,
          },
        }));
      } else if (groupBy === "zones") {
        setRequestedData((prev) => ({
          ...prev,
          campaignStatistics: {
            ...prev.campaignStatistics,
            zones: data.res_data,
          },
        }));
      }

      handleOnClick("impressions", "desc");
    } catch (err) {
      // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=loadCampaignStatistics= request error");
      setRequestedData((prev) => ({
        ...prev,
        campaignStatistics: { geo: {}, ads: {}, audience: {}, zones: {} },
      }));
      navigate("/");
      warningAlert("Redirecting: Something went wrong!");
    }
    setIsLoading((prev) => ({ ...prev, campaignStatisticsLoading: false }));
  };

  // GET TRANSACTIONS
  const _getTransactions = async ({
    smartContractAddress = "",
    network = "",
  }) => {
    // setIsLoading((prev) => ({ ...prev, transactionsDataIsLoading: true }));
    setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
    const tempRaw = {
      smartContractAddress,
      network,
    };
    try {
      const data = await getTransactions(() => {}, tempRaw);
      console.log(data, "=getTransactions= request " + network);

      setRequestedData((prev) => ({
        ...prev,
        blockchainData: data.res_data,
      }));
    } catch (err) {
      setRequestedData((prev) => ({
        ...prev,
        blockchainData: [],
      }));
      // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=getTransactions= request error " + network);
    }
    // setIsLoading((prev) => ({  ...prev,  transactionsDataIsLoading: false }));
    setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: false }));
  };

  const [dataTypes, setDataTypes] = useState([
    {
      title: "TXN",
      value: "txn",
      isSelected: true,
      id: uuidv4(),
    },
    {
      title: "Token",
      value: "token",
      isSelected: false,
      id: uuidv4(),
    },
    {
      title: "Wallet",
      value: "wallet",
      isSelected: false,
      id: uuidv4(),
    },
  ]);

  const _loadBlockchainData = async ({
    smartContractAddress = "",
    chainNetwork = "",
    // dataType = "",
    page = 1,
    pageSize = 10000,
  }) => {
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
    const tempRaw = {
      uuid: auth.currentUser.uid,
      smartContractAddress,
      chainNetwork,
      dataType: dataTypes.find((item) => item.isSelected).value,
      page,
      pageSize,
      order: "desc",
    };
    try {
      const data = await loadBlockchainData(() => {}, tempRaw);
      console.log(data, "=loadBlockchainData= request");

      setRequestedData((prev) => ({
        ...prev,
        blockchainData: data.res_data,
      }));
      // _getTransactions({
      //     smartContractAddress,
      //     network: chainNetwork,
      // });
    } catch (err) {
      setRequestedData((prev) => ({
        ...prev,
        blockchainData: [],
      }));
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=loadBlockchainData= request error");
    }
    setTimeout(() => {
      setIsLoading((prev) => ({
        ...prev,
        blockchainDataIsLoading: false,
      }));
    }, 1000);
  };

  // loadAnalyticsProfile
  const getLoadAnalyticsProfile = async (
    successCB = () => {},
    errorCB = () => {}
  ) => {
    if (!auth.currentUser) return;
    // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationID,
    };
    setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: true }));
    try {
      const data = await loadAnalyticsProfile(() => {}, temRaw);
      console.log(data, "=loadAnalyticsProfile= request");
      setRequestedData((prev) => ({
        ...prev,
        analyticsProfileData: { ...data.res_data, isFetched: true },
        isAnalyticsConnected: true,
      }));
      successCB(data.res_data.accountSummaries);

      // getLoadAnalyticsProject(data.res_data.accountSummaries[0].account.replace(/\D/g, ''));
    } catch (err) {
      // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      setRequestedData((prev) => ({
        ...prev,
        analyticsProfileData: { isFetched: false, accountSummaries: [] },
        isAnalyticsConnected: false,
      }));
      errorCB();
      console.error(err, "=loadAnalyticsProfile= request error");
      getConnectAnalyticsAccount();
    }
    setIsLoading((prev) => ({ ...prev, analyticsProfileIsLoading: false }));
  };

  // connectAnalyticsAccount
  const getConnectAnalyticsAccount = async () => {
    if (!auth.currentUser) return;
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");
    // console.log("Value of code parameter:", authCode);
    navigate("/analytics");
    if (!authCode) return;
    setIsLoading((prev) => ({ ...prev, analyticsConnectIsLoading: true }));

    // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
    const organizationID = requestedData.userProfileData.currentOrganization.id;

    const tempRaw = {
      uuid: auth.currentUser.uid,
      organizationID,
      authCode,
    };
    try {
      const data = await connectAnalyticsAccount(() => {}, tempRaw);
      console.log(data, "=connectAnalyticsAccount= request");
      setRequestedData((prev) => ({ ...prev, isAnalyticsConnected: true }));
      successAlert("Analytics connected, reloading the page...");
      // getLoadAnalyticsProfile();
      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=connectAnalyticsAccount= request error");
    }
    setIsLoading((prev) => ({
      ...prev,
      analyticsConnectIsLoading: false,
    }));
  };

  // loadAnalyticsProject
  const getLoadAnalyticsProject = async (id) => {
    if (!auth.currentUser) return;

    const endDate = new Date(); // today
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 6); // 7 days ago

    const selectionRange = JSON.parse(
      localStorage.getItem("analyticsSelectionRange")
    ) || {
      startDate: formatDateToYYYYMMDD(startDate),
      endDate: formatDateToYYYYMMDD(endDate),
    };

    // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
    const organizationID = requestedData.userProfileData.currentOrganization.id;

    const temRaw = {
      uuid: auth.currentUser.uid,
      projectID: id,
      organizationID,
      ...selectionRange,
    };

    setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: true }));
    try {
      const data = await loadAnalyticsProject(() => {}, temRaw);
      console.log(data, "=loadAnalyticsProject= request");
      if (data.res_data.transformedData) {
        setRequestedData((prev) => ({
          ...prev,
          analyticsProjectData: data.res_data,
        }));
      } else {
        setRequestedData((prev) => ({
          ...prev,
          analyticsProjectData: {
            mapData: {
              rows: [],
            },
            tableData: {
              rows: [],
            },
            transformedData: [],
          },
        }));
      }
    } catch (err) {
      // errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      warningAlert(err?.response?.data?.res_msg || "No Data To Show!");
      console.error(err, "=loadAnalyticsProject= request error");
      setRequestedData((prev) => ({
        ...prev,
        analyticsProjectData: {
          mapData: {
            rows: [],
          },
          tableData: {
            rows: [],
          },
          transformedData: [],
        },
      }));
    }
    setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: false }));
  };

  // disconnectAnalyticsAccount
  const getDisconnectAnalyticsAccount = async () => {
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: true }));

    // const organizationID = requestedData.userProfileData.allOrganization.map((item) => item.id) || [];
    const organizationID = requestedData.userProfileData.currentOrganization.id;

    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationID,
    };
    try {
      const data = await disconnectAnalyticsAccount(() => {}, temRaw);
      console.log(data, "=disconnectAnalyticsAccount= request");
      // setRequestedData((prev) => ({ ...prev, analyticsProfileData: [] }));
      setRequestedData((prev) => ({
        ...prev,
        analyticsProfileData: { accountSummaries: [] },
        isAnalyticsConnected: false,
      }));
      successAlert("Analytics disconnected");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=disconnectAnalyticsAccount= request error");
    }
    setIsLoading((prev) => ({ ...prev, analyticsDisconnectIsLoading: false }));
  };

  // updateUserInfo
  const getUpdateUserInfo = async (
    info,
    successCallback = () => {},
    setIsLoading = () => {}
  ) => {
    if (!auth.currentUser) return;
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: true }));
    setIsLoading(true);
    const temRaw = {
      uuid: auth.currentUser.uid,
      info,
    };
    try {
      const data = await updateUserInfo(() => {}, temRaw);
      successAlert("personal information updated");
      successCallback();
      console.log(data, "=updateUserInfo= request");
      // if(auth?.currentUser) getLoadUserProfile();
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateUserInfo= request error");
    }
    // setIsLoading((prev) => ({ ...prev, userInfoIsUpdating: false }));
    setIsLoading(false);
  };

  // resetEmail
  const getResetEmail = async (info) => {
    // if (!auth.currentUser) return;
    // setIsLoading((prev) => ({ ...prev, emailIsUpdating: true }));
    // const temRaw = {
    //     uuid: auth.currentUser.uid,
    //     info,
    // };
    // try {
    //     const data = await resetEmail(() => {}, temRaw);
    //     console.log(data, "=resetEmail= request");
    //     successAlert("email updated successfully");
    // } catch (err) {
    //     errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //     console.error(err, "=resetEmail= request error");
    // }
    // setIsLoading((prev) => ({ ...prev, emailIsUpdating: false }));
  };

  // resetPassword
  const getResetPassword = async (info) => {
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({ ...prev, passwordIsUpdating: true }));
    const temRaw = {
      uuid: auth.currentUser.uid,
      info,
    };
    try {
      const data = await resetEmail(() => {}, temRaw);
      console.log(data, "=resetPassword= request");
      successAlert("email updated successfully");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=resetPassword= request error");
    }
    setIsLoading((prev) => ({ ...prev, passwordIsUpdating: false }));
  };

  const getAdminResetUserPassword = async (email) => {
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({
      ...prev,
      adminResetUserPasswordIsLoading: true,
    }));
    const temRaw = {
      uuid: auth.currentUser.uid,
      email,
    };
    try {
      const data = await adminResetUserPassword(() => {}, temRaw);
      console.log(data);
      successAlert("Email sent successfully");
      setIsLoading((prev) => ({
        ...prev,
        adminResetUserPasswordIsLoading: false,
      }));
    } catch (err) {
      console.log("here");
      setIsLoading((prev) => ({
        ...prev,
        adminResetUserPasswordIsLoading: false,
      }));
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    }
  };

  // inviteUser

  const [invitedUsers, setInvitedUsers] = useState({
    data: [],
    isLoading: false,
  });

  const getInvitedUsers = async () => {
    const temRaw = {
      organizationID: requestedData.userProfileData.currentOrganization.id,
    };

    setInvitedUsers((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const data = await listInvitedUsers(() => {}, temRaw);

      setInvitedUsers({
        data: data.res_data,
        isLoading: false,
      });

      console.log(data, "=listInvitedUsers= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=listInvitedUsers= request error");

      setInvitedUsers((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const [organizationMembers, setOrganizationMembers] = useState({
    data: [],
    isLoading: false,
  });

  const getOrgMembersRequest = async () => {
    const temRaw = {
      organizationID: requestedData.userProfileData.currentOrganization.id,
    };

    setOrganizationMembers((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const transformData = (rawData) => {
        return Object.entries(rawData).map(([memberId, memberName]) => ({
          memberId,
          memberName,
        }));
      };

      const data = await getOrgMembers(() => {}, temRaw);

      setOrganizationMembers({
        data: transformData(data.res_data),
        isLoading: false,
      });

      console.log(data, "=listInvitedUsers= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=listInvitedUsers= request error");

      setOrganizationMembers((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const deleteOrgInvite = async (userEmail) => {
    setInvitedUsers((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const temRaw = {
      organizationID: requestedData.userProfileData.currentOrganization.id,
      email: userEmail,
    };

    try {
      const data = await deleteOrgInvitee(() => {}, temRaw);
      console.log(data, "=deleteOrgInvitee= request");
      getInvitedUsers();
      successAlert("Users invitation successfully removed");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=deleteOrgInvite= request error");

      setInvitedUsers((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const getInviteUser = async (invitedEmail, successCallback) => {
    if (!auth.currentUser) return;
    setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: true }));
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationID: requestedData.userProfileData.currentOrganization.id,
      senderName: requestedData.userProfileData.userName,
      inviteeEmail: invitedEmail,
    };
    try {
      const data = await inviteUser(() => {}, temRaw);
      console.log(data, "=inviteUser= request");
      successCallback();
      getInvitedUsers();
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=inviteUser= request error");
    }
    setIsLoading((prev) => ({ ...prev, inviteUserIsLoading: false }));
  };

  const initRequests = () => {
    console.log(auth?.currentUser, " auth.currentUser");
    getLoadUserProfile();
  };

  const getShortText = (originalText) => {
    const charsToShow = 12; // You can adjust this value based on your preference
    const midPoint = originalText.length / 2;
    const startText = originalText.substring(0, midPoint - charsToShow);
    const endText = originalText.substring(midPoint + charsToShow);

    return startText + "..." + endText;
  };

  function formatDate(value = null) {
    const date = value !== null ? new Date(value) : new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const formatNumber = (value) => {
    const number = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(number)) {
      return number.toLocaleString();
    }

    return value;
  };

  const getDelayedDate = (_date = "", delayTime) => {
    const date = new Date(_date);
    date.setDate(date.getDate() + delayTime);
    return date;
  };

  const getDateName = ({ date = "" }) => {
    var dateString = date;

    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    var dateObject = new Date(year, month - 1, day);
    var monthName = dateObject.toLocaleString("default", { month: "short" });
    return day + " " + monthName;
  };

  const calculateMinMax = (data) => {
    console.log("Minmax: ", data);
    let minMax = {
      impressions: { min: 0, max: -Infinity },
      amount_spent: { min: 0, max: -Infinity },
      clicks: { min: 0, max: -Infinity },
      cpm: { min: 0, max: -Infinity },
      cpc: { min: 0, max: -Infinity },
    };

    Object.values(data).forEach((item) => {
      Object.keys(minMax).forEach((key) => {
        if (item[key] !== undefined) {
          if (item[key] < minMax[key].min) minMax[key].min = item[key];
          if (item[key] > minMax[key].max) minMax[key].max = item[key];
        }
      });
    });

    return minMax;
  };

  const normalizeChartValue = (value, minMax = { min: 0, max: Infinity }) => {
    return ((value - minMax.min) / (minMax.max - minMax.min)) * 100;
  };

  const [selectedImages, setSelectedImages] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [singleCreativeInputs, setSingleCreativeInputs] = useState({});

  // useEffect(()=>{
  //     console.log(selectedImages, ' selectedImages')
  // },[selectedImages])

  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${today.getFullYear()}`;

  const defaultCampaign = {
    uuid: auth?.currentUser?.uid,
    userName: requestedData.userProfileData.userName,
    // organizationID: requestedData.userProfileData.currentOrganization.id,
    campaignInfo: {
      campaignName: "",
      targetURL: "",
      smartContractAddress: "",
      campaignType: [],
      budget: "360",
      maxCPC: "2", //formatCurrency(state.maxCPC,3,"decimal")
      // startDate: formatDate(),
      // endDate: formatDate(getDelayedDate(30)),
      startDate: formattedDate,
      endDate: "",
      timezone: 3,
      smartContractAddress: "",
      chainTargeting: [],
      category: [],
      web3Audience: [],
      walletTargeting: [],
      deviceType: [],
      operativeSystem: [],
      browser: [],
      countriesTargeting: [],
    },
    creatives: [],
  };

  const [campaignErrors, setCampaignErrors] = useState({
    campaignName: false,
    campaignTragetUrl: false,
    campaignBudget: false,
    campaignMaxCPC: false,
    campaignCreatives: false,
    campaignObjective: false,
  });

  const [isAccordionOpen, setIsAccordionIsOpen] = useState({
    campaignDetails: true,
    targetingFeatures: false,
    ads: false,
  });

  const toggleAccordion = (accordionKey, status) => {
    if (status == "open") {
      setIsAccordionIsOpen((prevState) => ({
        ...prevState,
        [accordionKey]: true,
      }));

      return;
    }
    setIsAccordionIsOpen((prevState) => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  const [campaignState, setCampaignState] = useState(defaultCampaign);

  useEffect(() => {
    console.log(campaignState, " campaignState");
  }, [campaignState]);

  const [campaignWizardStatus, setCampaignWizardStatus] = useState("create"); //edit

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);

  // const [campaigns, setCampaigns] = useState(null);
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [activeBlockchain, setActiveBlockchain] = useState(null);
  const [markedCampaigns, setMarkedCampaigns] = useState([]);
  const [activeInfoId, setActiveInfoId] = useState(null);

  // useEffect(()=>{
  //     console.log(activeBlockchain, ' activeBlockchain')
  // },[activeBlockchain])

  const [filterDate, setFilterDate] = useState(false);

  useEffect(() => {
    const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
    setActiveCampaign(campaigns[0]);

    if(markedCampaigns.length){
      return
    }

    const workingCampaigns = campaigns.filter(
      (campaign) => campaign.status === "working"
    );

    setMarkedCampaigns(workingCampaigns);   
    setActiveBlockchain(
      campaigns.find((item) => item.extraData.smartContractAddress)
    );
  }, [requestedData.campaignData.currentOrganizationCampaigns]);

  const campaignsState = useCampaignsState({
    requestedData,
    getLoadCampaignData,
  });
  const blockchainsState = useBlockchainsState({
    requestedData,
    setRequestedData,
    setIsLoading,
    activeBlockchain,
    _loadBlockchainData,
    _getTransactions,
    dataTypes,
    isAdminTeamMember,
  });
  const reportsState = useReportsState();
  const analyticsState = useAnalyticsState({ requestedData });
  const marketplaceState = useMarketplaceState();
  const trackingState = useTrackingState();
  const [trackingPageTab, setTrackingPageTab] = useState(0);

  const {
    coinTelegraph,
    bitcoin,
    beInCrypto,
    coinblastLogo,
    coinBlast,
    gritDaily,
    coinJet,
    cryptoNewsFlash,
    cryptoNews,
    CoincoDex,
    coinGape,
    newsBtc,
    BitCoin,
    tapChi,
    cryptoDaily,
  } = localData.images;

  const [activePRCategory, setActivePRCategory] = useState(0);

  const [prMediaPackages, setPrMediaPackages] = useState([
    {
      id: "featured-1-103803391c1d",
      packageName: "Featured 1",
      packageShortName: "featured 1",
      packageType: "basic",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "15000",
      isFeatured: true,
      extraSource: ["+75 Crypto Websites"],
      totalReach: "Publication potential reach: 310M+",
      products: [
        { media: "gritDaily", logo: gritDaily },
        { media: "Coinblast", logo: coinBlast },
        { media: "coinJet", logo: coinJet },
      ],
      included: ["+35 additional PR placements"],
      cateogry: "blockchainGaming",
    },
    {
      id: "featured-2-103803391c1d",
      packageName: "Featured 2",
      packageShortName: "featured 2",
      packageType: "Advanced",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "40000",
      isFeatured: true,
      extraSource: ["+75 Crypto Websites"],
      totalReach: "Publication potential reach: 690M+",
      products: [
        { media: "Coincodex", logo: CoincoDex },
        { media: "Gritdaily", logo: gritDaily },
        // { media: "Crypto.news", logo: cryptoNews },
        { media: "Cryptonewsflash", logo: cryptoNewsFlash },
      ],
      included: ["+70 additional PR placements"],
      cateogry: "blockchainGaming",
    },
    {
      id: "featured-3-103803391c1d",
      packageName: "Featured 3",
      packageShortName: "featured 3",
      packageType: "Enterprise",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "50000",
      isFeatured: true,
      extraSource: ["+25 Crypto Websites"],
      totalReach: "Publication potential reach: 1.4B+",
      products: [
        { media: "Coincodex", logo: CoincoDex },
        { media: "Gritdaily", logo: gritDaily },
        { media: "Crypto.news", logo: cryptoNews },
      ],
      included: ["+140 additional PR placements"],
      cateogry: "blockchainGaming",
    },
    {
      id: "featured-4-103803391c1d",
      packageName: "Featured 1",
      packageShortName: "featured 1",
      packageType: "basic",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "20000",
      isFeatured: true,
      extraSource: ["+75 Crypto Websites"],
      totalReach: "Publication potential reach: 610M+",
      products: [
        { media: "Cointelegraph", logo: coinTelegraph },
        { media: "coingape.com", logo: coinGape },
        { media: "NewsBTC", logo: newsBtc },
      ],
      included: ["+35 additional PR placements"],
      cateogry: "web3",
    },
    {
      id: "featured-5-103803391c1d",
      packageName: "Featured 2",
      packageShortName: "featured 2",
      packageType: "Advanced",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "35000",
      isFeatured: true,
      extraSource: ["+75 Crypto Websites"],
      totalReach: "Publication potential reach: 1.2B+",
      products: [
        { media: "Cointelegraph", logo: coinTelegraph },
        { media: "bitcoin.com", logo: bitcoin },
        { media: "Cryptodaily.co.uk", logo: cryptoDaily },
      ],
      included: ["+70 additional PR placements"],
      cateogry: "web3",
    },
    {
      id: "featured-6-103803391c1d",
      packageName: "Featured 3",
      packageShortName: "featured 3",
      packageType: "Enterprise",
      isInShoppingCart: false,
      isInWishlist: false,
      cover: "",
      totalPrice: "45000",
      isFeatured: true,
      extraSource: ["+25 Crypto Websites"],
      totalReach: "Publication potential reach: 2.1B+",
      products: [{ media: "Cointelegraph", logo: coinTelegraph }],
      included: ["+135 additional PR placements"],
      products: [
        { media: "Cointelegraph", logo: coinTelegraph },
        { media: "Bitcoin.com", logo: bitcoin },
        { media: "BeInCrypto", logo: beInCrypto },
      ],
      cateogry: "web3",
    },
  ]);

  const handleSignOut = async (
    message = "You have successfully signed out!",
    isForgotPasswordPath = undefined
  ) => {
    const { resetCampaignsState } = campaignsState;
    const { resetReportsPagination } = reportsState;
    try {
      await auth.signOut();
      localStorage.clear();
      setRequestedData(DEFAULTREQUESTEDDATA);
      resetCampaignsState();
      resetReportsPagination();
      if (isForgotPasswordPath) {
        navigate(isForgotPasswordPath);
        return;
      }

      successAlert(message);
    } catch (error) {
      errorAlert("Error:", error.message);
      console.error("Error:", error.message);
    }
  };

  /// Billing Information

  const { updateOrgBillingDetails } = useFetch();

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    address: "",
  });
  const [tempCompanyInfo, setTempCompanyInfo] = useState(companyInfo);
  const [openCompanyInfoModal, setOpenCompanyInfoModal] = useState(false);
  const [isEditCompanyLoading, setIsEditCompanyloading] = useState(false);
  const [isCompanyInfoMissing, setIsCompanyInfoMissing] = useState(false);

  useEffect(() => {
    if (!requestedData.userProfileData) return;
    setCompanyInfo({
      name: requestedData.userProfileData.currentOrganization.billing
        .companyName,
      address:
        requestedData.userProfileData.currentOrganization.billing.address,
    });
  }, [requestedData]);

  const handleSaveCompanyInfo = async () => {
    setIsEditCompanyloading(true);
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationId: requestedData.userProfileData.currentOrganization.id,
      address: tempCompanyInfo.address,
      companyName: tempCompanyInfo.name,
    };
    try {
      const data = await updateOrgBillingDetails(() => {}, temRaw);
      successAlert("personal information updated");
      setCompanyInfo({
        address: tempCompanyInfo.address,
        name: tempCompanyInfo.name,
      });
      setIsEditCompanyloading(false);
      setOpenCompanyInfoModal(false);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      setIsEditCompanyloading(false);
    }
  };

  const handleOpenCompanyModalModal = () => {
    setTempCompanyInfo(companyInfo);
    setOpenCompanyInfoModal(true);
  };

  const handleCloseCompanyInfoModal = () => setOpenCompanyInfoModal(false);

  const [gtmAccounts, setGtmAccounts] = useState([]);

  const [conversionActiveStep, setConversionActiveStep] = useState(0);
  const [conversionMethod, setConversionMethod] = useState("");

  const [createConversionData, setCreateConversionData] = useState({
    conversionMethod: "",
    conversionTagName: "",
    conversionStep: "",
  });

  const [isGTMConfigurationPopupOpen, setIsGTMConfigurationPopupOpen] =
    useState(false);
  const [isGTMPopupOpen, setIsGTMPopupOpen] = useState(false);

  const [typeOfGTMStep, setTypeOfGTMStep] = useState("manual");

  const [tabValue, setTabValue] = useState(0);

  const [activeCategory, setActiveCategory] = useState("blockchainGaming");

  const [paymentData, setPaymentData] = useState({
    cryptoUrl: "",
    fiatUrl: "",
  });

  const [markedCampaignReports, setMarkedCampaignsReports] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 6);
  const SELECTIONRANGE = {
    startDate,
    endDate,
    key: "selection",
  };

  const [campaignSelectionRange, setCampaignSelectionRange] = useState(SELECTIONRANGE);

  const [performanceOption, setPerformanceOption] = useState("Top Performing");

  const [selectedGrpahCampaigns, setSelectedGraphCampaigns] = useState([]);

  return (
    <Context.Provider
      value={{
        state,
        ...state,
        setState,
        dataTypes,
        setDataTypes,
        campaignsState,
        blockchainsState,
        reportsState,
        setRequestedData,
        // isLoggedIn,
        // setIsLoggedIn,
        animations,
        showSidebar,
        setShowSidebar,
        selectedGrpahCampaigns, setSelectedGraphCampaigns,
        filterDate,
        setFilterDate,
        // isProfileImageSelected,
        // setIsProfileImageSelected,
        inviteData,
        setInviteData,
        setRefCode,

        markedCampaignReports, 
        setMarkedCampaignsReports,
        selectedCampaigns, 
        setSelectedCampaigns,
        campaignSelectionRange, 
        setCampaignSelectionRange,

        performanceOption, 
        setPerformanceOption,

        /// Company Info

        companyInfo,
        setCompanyInfo,
        tempCompanyInfo,
        setTempCompanyInfo,
        openCompanyInfoModal,
        setOpenCompanyInfoModal,
        isEditCompanyLoading,
        setIsEditCompanyloading,
        handleSaveCompanyInfo,
        handleOpenCompanyModalModal,
        handleCloseCompanyInfoModal,
        isCompanyInfoMissing,
        setIsCompanyInfoMissing,

        // profileImagePlaceholder,
        // setProfileImagePlaceholder,
        // profileImage,
        // setProfleImage,
        placeholders,
        setPlaceholders,
        successAlert,
        errorAlertExpanded,
        warningAlertExpanded,
        successAlertExpanded,
        errorAlert,
        warningAlert,
        handleGoogleSignIn,
        isGoogleSignUpLoading,
        isGoogleSignInLoading,
        handleGoogleSignUp,
        handleFacebookSignIn,
        // allCurrencies,
        // setAllCurrencies,
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        showProfileDrawer,
        setShowProfileDrawer,
        showDepositModal,
        setShowDepositModal,
        campaignWizardStatus,
        setCampaignWizardStatus,
        isLoading,
        setIsLoading,
        requestedData,
        authUser,
        setAuthUser,
        getShortText,
        formatDate,
        getDelayedDate,
        formatNumber,
        getDateName,
        calculateMinMax,
        normalizeChartValue,
        navbarRef,
        marketplaceTheadRef,
        marketplaceScrollItems,
        // ANALYTICS
        analyticsState,

        // MAREKETPLACE
        marketplaceState,

        trackingState,

        // CREATIVES
        creatives,
        setCreatives,
        singleCreativeInputs,
        setSingleCreativeInputs,
        selectedImages,
        setSelectedImages,
        //

        campaignState,
        setCampaignState,
        defaultCampaign,
        activeCampaign,
        setActiveCampaign,
        markedCampaigns,
        setMarkedCampaigns,
        activeBlockchain,
        setActiveBlockchain,

        activeInfoId,
        setActiveInfoId,
        isDarkModeEnabled,
        isAdminTeamMember,
        setIsAdminTeamMember,
        setIsDarkModeEnabled,

        // REQUESTS
        initRequests,
        getConnectAnalyticsAccount,
        getDisconnectAnalyticsAccount,
        getLoadAnalyticsProfile,
        getLoadUserProfile,
        getLoadAnalyticsProject,
        // getReturnAnalyticsProject,
        getUpdateUserInfo,
        getResetEmail,
        getResetPassword,
        getInviteUser,
        getInvitedUsers,
        invitedUsers,
        deleteOrgInvite,
        getOrgMembersRequest,
        organizationMembers,
        getLoadCampaignData,
        // getLoadCampaignStatistics,
        getLoadCampaignAudienceStatistics,
        getLoadCampaignGeoOrAdsStatistics,
        // getStartOrStopCampaignZone,
        _getTransactions,
        _loadBlockchainData,

        selectedObjective,
        setSelectedObjective,
        isAccordionOpen,
        setIsAccordionIsOpen,
        toggleAccordion,

        isMultiStepFormDone,
        setIsMultiStepFormDone,

        // campaigns,
        // setCampaigns,
        handleSignOut,
        timeRemaining,
        handleOnClick,
        getSortIcon,
        CreatePixelTagRequest,
        CreateS2SPixelTagRequest,
        CreateConversionPixelRequest,
        EditConversionPixelRequest,
        DeleteConversionPixelRequest,
        validateConversionRequest,
        verifyConversionRequest,
        getAdvertiserPixelStatsRequest,
        getAdvertiserPixelStatsRequestAdmin,
        selectedConversionTag,
        setSlectedConversionTag,
        adminTrackingPixels,
        setAdminTrackingPixels,
        isTrackingPageLoading,
        setIsTrackingPageLoading,
        conversionOptions,
        setConversionOptions,
        updateAccountBCANameRequest,
        updateAccountStatusBCARequest,
        _updateCampaignManualData,
        // guideTutorials,
        // getUpdateGuideTutorials,
        _getInsightsImages,
        _conversionTagEmail,
        _OrganizationAdditionalData,

        assistedCampaignData,
        setAssistedCampaignData,
        isAssistedCampaignEnabled,
        setIsAssistedCampaignEnabled,

        // error handling
        campaignErrors,
        setCampaignErrors,

        activePRCategory,
        setActivePRCategory,
        googleTrackerId,
        setGoogleTrackerId,

        isConversionModalOpen,
        setIsConversionModalOpen,
        prMediaPackages,
        getAdminResetUserPassword,
        statusOption,
        setStatusOption,
        selectedStatusOption,
        setSelectedOption,
        trackingPageTab,
        setTrackingPageTab,
        paymentHistory,
        setPaymentHistory,
        termsAccepted,
        setTermsAccepted,

        conversionActiveStep,
        setConversionActiveStep,
        conversionMethod,
        setConversionMethod,
        createConversionData,
        setCreateConversionData,
        gtmAccounts,
        setGtmAccounts,

        isGTMConfigurationPopupOpen,
        setIsGTMConfigurationPopupOpen,
        isGTMPopupOpen,
        setIsGTMPopupOpen,
        typeOfGTMStep,
        setTypeOfGTMStep,

        tabValue,
        setTabValue,
        activeCategory,
        setActiveCategory,
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useGlobalContext = () => useContext(Context);