import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  TextField,
  Divider,
  Button,
  Card,
  IconButton,
  Alert,
  AlertTitle,
  styled,
  CircularProgress,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useGlobalContext } from "../../context";
import { Link } from "react-router-dom";
import useFormatter from "../../hooks/useFormatter";
import localData from "../../localData";
import { Field } from "../../components";
import { auth } from "../../config/firebase";
import useFetch from "../../hooks/useFetch";
import PaymentUrlDisplay from "./components/PaymentUrlDisplay";
import TransactionHistory from "./components/TransactionHistory";
import { BankTransferDetail } from "./components/BankTransferDetail";
import EditIcon from "@mui/icons-material/Edit";
import { RequestInvoiceModal } from "./BillingHistoryPage";

const StyledTabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  minWidth: "auto",
  padding: "12px 8px",
  fontSize: "0.875rem",
  textTransform: "none",

  "& .MuiTab-iconWrapper": {
    marginRight: "6px",
    marginBottom: "0 !important",
    fontSize: "1.1rem",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
    padding: "10px 4px",
    "& .MuiTab-iconWrapper": {
      fontSize: "0.9rem",
      marginRight: "4px",
    },
  },

  [theme.breakpoints.down("xs")]: {
    fontSize: "0.65rem",
    padding: "8px 2px",
    "& .MuiTab-iconWrapper": {
      fontSize: "0.8rem",
      marginRight: "2px",
    },
  },
}));

const CopyField = ({ label, value, toolTipValue }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip
          title={toolTipValue}
          placement="top-start"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#0a253d",
                padding: "12px",
                lineHeight: "18px",
                fontSize: "12px",
                "& .MuiTooltip-arrow": {
                  color: "#0a253d",
                },
              },
            },
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            value={value}
            InputProps={{
              readOnly: true,
              sx: {
                backgroundColor: "#fff",
                borderColor: "#e0e0e0",
                fontSize: "0.9rem",
              },
            }}
            size="small"
          />
        </Tooltip>
        <Button
          variant="contained"
          onClick={handleCopy}
          sx={{
            ml: 1,
            minWidth: "auto",
            backgroundColor: "#1f8352",
            "&:hover": {
              backgroundColor: "#1f8352",
            },
            borderRadius: "8px",
          }}
        >
          Copy
        </Button>
      </Box>
    </Box>
  );
};

const DepositFundsPage = () => {
  const {
    errorAlert,
    handleOpenCompanyModalModal,
    setOpenCompanyInfoModal,
    companyInfo,
    tabValue,
    setTabValue,
    paymentData, 
    setPaymentData
  } = useGlobalContext();
  const { addCommas } = useFormatter();
  const { budget } = localData.svgs;

  const [minDeposit, setMinDeposit] = useState(10000);

  const {
    requestedData: {
      userProfileData: { currentOrganization: { balance = 0 } = {} } = {},
    } = {},
    formatNumber,
    requestedData,
  } = useGlobalContext();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [state, setState] = useState({
    priceAmount: 50000,
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const { paymentWithInvoice, stripeCheckoutUrl } = useFetch();

  useEffect(() => {
    if (
      requestedData?.userProfileData?.currentOrganization?.admin ===
      "Blockchain-Ads Team"
    ) {
      setMinDeposit(10);
    } else {
      setMinDeposit(10000);
    }
  }, [requestedData.userProfileData]);

  const handleInitCardPayment = async () => {
    setIsLoading(true);
    try {
      const depositRawFiat = {
        organizationId: requestedData.userProfileData.currentOrganization.id,
        amount: state.priceAmount,
        uuid: auth.currentUser.uid,
        email: requestedData.userProfileData.userEmail,
      };

      const data = await stripeCheckoutUrl(() => {}, depositRawFiat);

      setPaymentData({
        fiatUrl: data.res_data.url,
      });
    } catch (err) {
      errorAlert(
        err?.response?.data?.res_msg || "Failed to initialize payment options"
      );
      console.log(err, "Payment initialization error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitialPayment = async () => {
    setIsLoading(true);
    try {
      const depositRawCrypto = {
        price_amount: state.priceAmount,
        uuid: auth.currentUser.uid,
      };

      const data = await paymentWithInvoice(() => {}, depositRawCrypto);

      setPaymentData({
        cryptoUrl: data.invoice_url,
      });

      // Open the first modal
    } catch (err) {
      errorAlert(
        err?.response?.data?.res_msg || "Failed to initialize payment options"
      );
      console.log(err, "Payment initialization error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setPaymentData({
      cryptoUrl: "",
      fiatUrl: "",
      wiseURL: "",
    });
    setState({
      priceAmount: 50000,
    });
  };

  const handleWiseToWisePayment = () => {
    const amount = state.priceAmount
    const numericAmount = parseFloat(amount);
    if (!isNaN(numericAmount)) {
      const wisePaymentUrl = `https://wise.com/pay/business/blockchainadslabsllc?currency=USD&amount=${numericAmount}`;

      setPaymentData((prevState) => ({
        ...prevState,
        wiseURL: wisePaymentUrl,
      }));
    } else {
      console.error("Invalid amount provided");
    }
  };

  const isPaymentInitiatedCrypto = !!paymentData.cryptoUrl;
  const isPaymentInitiatedCard = !!paymentData.fiatUrl;
  const isPaymentInitiatedWise = !!paymentData.wiseURL;

  const [showDetails, setShowDetails] = useState(true);

  const handleGenerateDetails = () => {
    setShowDetails(true);
  };

  const isBelow800px = useMediaQuery("(max-width:800px)");

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={3} sx={{ width: "100%", margin: "0 auto" }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ borderRadius: "8px", overflow: "hidden" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              sx={{
                minHeight: { xs: "40px", sm: "48px", md: "52px" },
                "& .MuiTabs-flexContainer": {
                  height: { xs: "40px", sm: "48px", md: "52px" },
                },
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 500,
                  minHeight: { xs: "36px", sm: "40px", md: "42px" },
                  color: "#555",
                  minWidth: { xs: "auto", sm: "auto" },
                  padding: { xs: "6px 4px", sm: "8px 12px", md: "10px 16px" },
                  fontSize: { xs: "0.65rem", sm: "0.7rem", md: "0.8rem" },
                  "&.Mui-selected": {
                    color: "#2e2680",
                  },
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#2e2680",
                  height: 3,
                },
                "& .MuiTabs-scrollButtons": {
                  display: { xs: "auto", sm: "auto" },
                  "&.Mui-disabled": {
                    opacity: 0.3,
                  },
                },
              }}
            >
              <StyledTab
                icon={<CurrencyBitcoinIcon />}
                iconPosition="start"
                label="Crypto"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <StyledTab
                icon={<AccountBalanceIcon />}
                iconPosition="start"
                label="Bank"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <StyledTab
                icon={<CreditCardIcon />}
                iconPosition="start"
                label="Card"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <StyledTab
                icon={<CreditCardIcon />}
                iconPosition="start"
                label="Wise"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </Tabs>
            {tabValue === 1 && <BankTransferDetail />}
            {tabValue === 0 && (
              <StyledTabPanel>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "15px" }}>
                  Pay with Crypto
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "15px" }}>
                  BTC, ETH, USDT, and 150+ more
                </Typography>

                <Typography
                  variant="body2"
                  paragraph
                  sx={{ mb: 3, fontSize: "12px" }}
                >
                  Any funds deposited will automatically be converted to USD.
                  Your active campaigns will then receive traffic as long as you
                  have funds into your balance.
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mb: 4 }}>
                  <Box
                    sx={{
                      flex: "1 1 400px",
                      backgroundColor: "#f9f9f9",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ mb: 3, fontSize: "16px" }}
                    >
                      Deposit Amount
                    </Typography>

                    <Field
                      type="number"
                      label="Amount"
                      color="secondary"
                      placeholder=""
                      value={state.priceAmount}
                      required={true}
                      name="priceAmount"
                      size="sm"
                      callback={handleOnChange}
                      startIcon={budget}
                      disabled={isPaymentInitiatedCrypto || isLoading}
                    />

                    {!isPaymentInitiatedCrypto && (
                      <Button
                        variant="contained"
                        sx={{
                          mt: 4,
                          backgroundColor: "#15a1c5",
                          "&:hover": {
                            backgroundColor: "#15a1c5",
                          },
                          textTransform: "none",
                          fontWeight: 500,
                          px: 2,
                          py: 0.5,
                        }}
                        size="small"
                        onClick={handleInitialPayment}
                        disabled={
                          isLoading ||
                          state.priceAmount <
                            minDeposit
                        }
                      >
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Generate Payment Link"
                        )}
                      </Button>
                    )}

                    <PaymentUrlDisplay
                      paymentUrl={paymentData.cryptoUrl}
                      onReset={handleReset}
                      isLoading={isLoading}
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: "1 1 300px",
                      border: "1px solid #e0e0e0",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
                    >
                      <Box sx={{ mr: 2, color: "#555" }}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V12"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16H12.01"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          Payments are processed by NowPayments.
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          Minimum deposit $10,000
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </StyledTabPanel>
            )}
            {tabValue === 2 && (
              <StyledTabPanel>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "15px" }}>
                  Pay with Credit/Debit Card
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{ mb: 3, fontSize: "12px" }}
                >
                  Any funds deposited will automatically be converted to USD.
                  Your active campaigns will then receive traffic as long as you
                  have funds into your balance.
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mb: 4 }}>
                  <Box
                    sx={{
                      flex: "1 1 400px",
                      backgroundColor: "#f9f9f9",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ mb: 3, fontSize: "16px" }}
                    >
                      Deposit Amount
                    </Typography>

                    <Field
                      type="text"
                      label="Amount"
                      color="secondary"
                      placeholder=""
                      value={state.priceAmount}
                      required={true}
                      name="priceAmount"
                      size="sm"
                      callback={handleOnChange}
                      startIcon={budget}
                      disabled={isPaymentInitiatedCard || isLoading}
                    />

                    {!isPaymentInitiatedCard && (
                      <Button
                        variant="contained"
                        sx={{
                          mt: 4,
                          backgroundColor: "#15a1c5",
                          "&:hover": {
                            backgroundColor: "#15a1c5",
                          },
                          textTransform: "none",
                          fontWeight: 500,
                          px: 2,
                          py: 0.5,
                        }}
                        size="small"
                        onClick={handleInitCardPayment}
                        disabled={
                          isLoading ||
                          state.priceAmount <
                            minDeposit
                        }
                      >
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Generate Payment Link"
                        )}
                      </Button>
                    )}

                    <PaymentUrlDisplay
                      paymentUrl={paymentData.fiatUrl}
                      onReset={handleReset}
                      isLoading={isLoading}
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: "1 1 300px",
                      border: "1px solid #e0e0e0",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
                    >
                      <Box sx={{ mr: 2, color: "#555" }}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V12"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16H12.01"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          Card payments are processed securely through our
                          payment processor. Your card details are encrypted and
                          not stored on our servers. Upon successful payment,
                          your account will be credited immediately.
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          Minimum deposit $10,000
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </StyledTabPanel>
            )}
            {tabValue === 3 && (
              <StyledTabPanel>
                <Typography variant="h6" gutterBottom sx={{ fontSize: "15px" }}>
                  Pay with Wise to Wise
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{ mb: 3, fontSize: "12px" }}
                >
                  Any funds deposited will automatically be converted to USD.
                  Your active campaigns will then receive traffic as long as you
                  have funds into your balance.
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mb: 4 }}>
                  <Box
                    sx={{
                      flex: "1 1 400px",
                      backgroundColor: "#f9f9f9",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ mb: 3, fontSize: "16px" }}
                    >
                      Deposit Amount
                    </Typography>

                    <Field
                      type="text"
                      label="Amount"
                      color="secondary"
                      placeholder=""
                      value={state.priceAmount}
                      required={true}
                      name="priceAmount"
                      size="sm"
                      callback={handleOnChange}
                      startIcon={budget}
                      disabled={isPaymentInitiatedWise || isLoading}
                    />

                    {!isPaymentInitiatedWise && (
                      <Button
                        variant="contained"
                        sx={{
                          mt: 4,
                          backgroundColor: "#15a1c5",
                          "&:hover": {
                            backgroundColor: "#15a1c5",
                          },
                          textTransform: "none",
                          fontWeight: 500,
                          px: 2,
                          py: 0.5,
                        }}
                        size="small"
                        onClick={handleWiseToWisePayment}
                        disabled={
                          isLoading ||
                          state.priceAmount <
                            minDeposit
                        }
                      >
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Generate Payment Link"
                        )}
                      </Button>
                    )}

                    <PaymentUrlDisplay
                      paymentUrl={paymentData.wiseURL}
                      onReset={handleReset}
                      isLoading={isLoading}
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: "1 1 300px",
                      border: "1px solid #e0e0e0",
                      p: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
                    >
                      <Box sx={{ mr: 2, color: "#555" }}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V12"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16H12.01"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          Card payments are processed securely through our
                          payment processor. Your card details are encrypted and
                          not stored on our servers. Upon successful payment,
                          your account will be credited immediately.
                        </Typography>
                        <Typography variant="body2" fontWeight={600}>
                          Minimum deposit $10,000
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </StyledTabPanel>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Paper
              sx={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="body1" fontWeight="bold">
                    Company Details
                  </Typography>

                  <br />
                  <Typography variant="body2" fontWeight="bold">
                    Business Name:
                  </Typography>
                  <Typography variant="body2">
                    {!companyInfo.name
                      ? "Please enter your company name!"
                      : companyInfo.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" fontWeight="bold">
                    Business Address:
                  </Typography>
                  <Typography variant="body2">
                    {!companyInfo.address
                      ? "Please enter your company address."
                      : companyInfo.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  minWidth: "130px !important"
                }}
              >
                <IconButton
                  onClick={handleOpenCompanyModalModal}
                  sx={{ color: "#1f8352" }}
                >
                  <EditIcon />
                </IconButton>
                <RequestInvoiceModal
                  setOpenCompanyInfoModal={setOpenCompanyInfoModal}
                  companyInfo={companyInfo}
                />
              </Grid>
            </Paper>
          </Box>
          <br />
          <TransactionHistory
            paymentHistory={requestedData.userProfileData.payment_history}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepositFundsPage;
