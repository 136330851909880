import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { TableSkeleton, Pagination } from "../../../components";
import localData from "../../../localData";
import useFormatter from "../../../hooks/useFormatter";

export default function UserActions({ actions, loading }) {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);
  const { formatDateTime } = useFormatter();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const sortAndPaginate = (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Set the sorted data
    setData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    return sortedData;
  };

  const setItems = (data) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    setRows(data.slice(startIndex, endIndex));
  };

  const formatUserAgent = (userAgent) => {
    return `${userAgent.ip}  / ${userAgent.browser} / ${userAgent.userAgent} `;
  };
  useEffect(() => {
    if (actions) {
      try {
        const sortedData = sortAndPaginate(actions);
        setItems(sortedData);
      } catch (error) {
        console.log(error);
      }
    }
  }, actions); // Empty depend
  const { textPreloader } = localData.images;

  const handlePagination = (nextPage = 1) => {
    setPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setRows(data.slice(startIndex, endIndex));
  };

  return (
    <>
      <div
        className="modal-dialog-scrollable accounts modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="scroll">
          {loading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 0 }}>Action</th>
                  <th style={{ width: 0 }}>Status</th>
                  <th style={{ width: 0, textAlign: "center" }}>
                    IP / Browser / User Agent
                  </th>
                  <th style={{ width: 0 }}>Created At</th>
                </tr>
              </thead>
              <tbody>
                {!rows.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  rows.map((item, index) => {
                    return (
                      <tr>
                        <td className="capitalize">{item.action}</td>
                        <td className="table-td-status">
                          <div
                            data-tooltip-id="status-tooltip"
                            className={`status ${item.status}`}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatUserAgent(item.userAgent)}
                        </td>
                        <td>{formatDateTime(new Date(item.createdAt))}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        {!rows.length ? (
          ""
        ) : (
          <div className={`pagination-wrapper  ${loading ? "on-hold" : ""}`}>
            <Pagination
              callback={handlePagination}
              currentPage={page}
              totalPages={totalPages}
              isLoading={loading}
            />
          </div>
        )}
        <br />
        <br />
      </div>
      <br />
      <br />
    </>
  );
}
