import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  TableSkeleton,
  Modal as CustomModal,
  Tabs as CustomTabs,
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {
  Tabs,
  Tab,
  Box,
  Modal,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  InputLabel,
  Select,
  CircularProgress,
  Alert,
} from "@mui/material";
import { auth } from "../../config/firebase";
import ConversionTableRow from "./ConversionTableRow";
import TrackingJoyride from "./TrackingJoyride";
import HelpIcon from "@mui/icons-material/Help";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

const { textPreloader, CodeType, trashV2 } = localData.svgs;
const { gtm, pixel, serverToServer } = localData.images;

export default function Tracking() {
  useEffect(() => {
    document.title = "Tracking";
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Monitor your progress and stay updated with real-time data on your activities.";
  }, []);
  const { fade } = useGlobalContext().animations;

  const { plus, eyeSolid, trackingAccountSetup } = localData.svgs;

  const navigate = useNavigate();

  const {
    requestedData,
    successAlert,
    errorAlert,
    getAdvertiserPixelStatsRequest,
    conversionOptions,
    setConversionOptions,
    successAlertExpanded,
    warningAlertExpanded,
    requestedData: {
      userProfileData: { currentOrganization },
      campaignData: { currentOrganizationCampaigns },
    },
    isLoading: { userProfileIsLoading },
    trackingState,
    campaignsState,
    trackingPageTab,
    setTrackingPageTab,
    getLoadUserProfile,
  } = useGlobalContext();

  const { campaignSelectionRange, setCampaignSelectionRange, handleCalendar } =
    campaignsState;

  const {
    setDisplayInstallPixel,
    setDefaultTab,
    setIsEditingMode,
    setEditingTagId,
    setState,
  } = trackingState;

  const sendRequest = () => {
    getAdvertiserPixelStatsRequest();
  };

  useEffect(() => {
    if (!currentOrganization.id) return;
    getAdvertiserPixelStatsRequest();
  }, [currentOrganization.id]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    if (!conversionOptions.advertiserPixelStats.value.length) {
      warningAlertExpanded(
        `You haven't created any conversions yet. Navigate to "Tracking" to setup Goal Tracking on your website.`,
        { toastId: "toast-tracking-alert" }
      );
    }
  }, [conversionOptions.advertiserPixelStats.value]);

  const handleChange = (event, newValue) => {
    setTrackingPageTab(newValue);
  };

  const audienceData = conversionOptions.advertiserPixelStats.value.filter(
    (item) => item.tracker_group === "1"
  );

  const conversionsData = conversionOptions.advertiserPixelStats.value.filter(
    (item) => item.tracker_group === "0"
  );

  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);

  useEffect(() => {
    const superAdminEmails = [
      "jack@blockchain-ads.com",
      "marko@blockchain-ads.com",
      "vlad@blockchain-ads.com",
      "contact@blockchain-ads.com",
      "rende@blockchain-ads.com",
    ];

    const checkSuperAdmin = () => {
      return superAdminEmails.includes(
        requestedData?.userProfileData?.userEmail || ""
      );
    };

    setIsUserSuperAdmin(checkSuperAdmin());
  }, [requestedData]);

  const handleGTM = (e) => {
    e.preventDefault();
    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=181319171049-t8050hd7pu5i5gg9c6bgm9401v5gsesl.apps.googleusercontent.com&redirect_uri=https://hub.blockchain-ads.com/tracking&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth/tagmanager.readonly+https%3A%2F%2Fwww.googleapis.com/auth/tagmanager.edit.containers+https%3A%2F%2Fwww.googleapis.com/auth/tagmanager.edit.containerversions+https%3A%2F%2Fwww.googleapis.com/auth/tagmanager.publish+https%3A%2F%2Fwww.googleapis.com/auth/tagmanager.manage.users&response_type=code&access_type=offline&prompt=consent";
  };

  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const {
    connectGTMAccount,
    getAccountsGTM,
    disconnectGTMAccount,
    getContainersGTM,
    createTagGTM,
  } = useFetch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get("code");
    if (authCode && requestedData?.userProfileData?.currentOrganization?.id) {
      gtmAuthSchemaRequest(authCode);
    }
  }, [window.location.search]);

  const [gtmAccounts, setGtmAccounts] = useState([]);
  const [isAccountLoading, setISAccountLoading] = useState(false);
  const [isSwitchingLoading, setIsSwitchingLoading] = useState(false);

  const gtmAuthSchemaRequest = async (authCode) => {
    setISAccountLoading(true);
    if (!authCode || !requestedData.userProfileData) return;
    try {
      const requestData = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData.userProfileData.currentOrganization.id,
        authCode: authCode,
      };
      const accountRequestData = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData.userProfileData.currentOrganization.id,
      };

      await connectGTMAccount(() => {}, requestData);
      setShowModal(true);

      const data = await getAccountsGTM(() => {}, accountRequestData);

      setGtmAccounts(data.res_data.account ? data.res_data.account : null);
      console.log(data, "accounts data");
    } catch (error) {
      console.error("GTM connection failed:", error);
    }

    setISAccountLoading(false);
  };

  const disconectGTM = async () => {
    if (!requestedData.userProfileData) return;
    setIsDiscconetLoading(true);
    try {
      const tempRawr = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
      };

      const data = await disconnectGTMAccount(() => {}, tempRawr);

      getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
    } catch (error) {
      console.log(error);
    }

    setIsDiscconetLoading(false);
  };

  const handleSwitchAccount = async () => {
    setIsSwitchingLoading(true);
    await disconectGTM();
    setIsSwitchingLoading(false);
  };

  const closeModal = () => {
    setShowModal(false);
    disconectGTM();
    navigate("/tracking");
    window.history.replaceState({}, document.title, location.pathname);
  };

  const [accountContainers, setAccountContainers] = useState([]);

  const selectAccount = async (accountId) => {
    setSelectAccount(accountId);
    setAccountContainers([]);
    setIsContainerLoading(true);

    try {
      const tempRawr = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
        accountId: accountId,
      };

      const data = await getContainersGTM(() => {}, tempRawr);

      setAccountContainers(data.res_data.container);
    } catch (error) {
      console.log(error);
    }
    setIsContainerLoading(false);
  };

  const [isContainerLoading, setIsContainerLoading] = useState(false);

  const [selectedAccount, setSelectAccount] = useState(null);
  const [seletedContainer, setSelectedConatiner] = useState(null);
  const [isDisconnectLoading, setIsDiscconetLoading] = useState(false);
  const [isPixeCreatingLoading, setIsPixelCreatingLoadin] = useState(false);

  const handleCreateGTM = async () => {
    setIsPixelCreatingLoadin(true);
    try {
      const tempRawr = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
        accountId: selectedAccount,
        containerId: seletedContainer,
      };

      const data = await createTagGTM(() => {}, tempRawr);
      setShowModal(false);
      navigate("/tracking");
      successAlert("GTM has been connected successfully");
      getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
    } catch (error) {
      console.log(error);
    }
    setIsPixelCreatingLoadin(false);
  };

  console.log(trackingPageTab, "trackingPageTab");
  

  return (
    <motion.main {...fade} className="main tracking-page">
      <TrackingJoyride />
      <br />
      <br />
      <Box sx={{ width: "100%", padding: "10px 30px 20px" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            // background: "#ffff",
            borderRadius: "10px 10px 10px 0px ",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "0"
          }}
        >
          <Tabs
            value={trackingPageTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Conversions" />
            {isUserSuperAdmin && <Tab label="Audiences" />}
          </Tabs>
          {trackingPageTab === 0 ? 
          <Button
              id="tracking-tutorial-2"
              name={"Setup Tracking"}
              variant="contained"
              // startIcon={question}
              color="success"
              startIcon={plus}
              onClick={() => {
                setDefaultTab(0);
                setIsEditingMode(false);
                setState({});
                navigate("/tracking/create-pixel");
              }}
              style={{marginRight: "10px"}}
            />
            :            <Button
            id="tracking-tutorial-2"
            name={"New Audience"}
            variant="contained"
            // startIcon={question}
            color="success"
            startIcon={plus}
            onClick={() => {
              navigate("/tracking/audience-pixel");
            }}
            style={{marginRight: "10px"}}
          />}
        </Box>

        <CustomTabPanel value={trackingPageTab} index={0}>
          <div
            className="row tracking-drawer-container"
            style={{ justifyContent: "flex-start", display: "flex" }}
          >
            {/* <Button
              id="tracking-tutorial-2"
              name={"Setup Tracking"}
              variant="contained"
              // startIcon={question}
              color="success"
              startIcon={plus}
              onClick={() => {
                setDefaultTab(0);
                setIsEditingMode(false);
                setState({});
                navigate("/tracking/create-pixel");
              }}
            /> */}
            {/* <Link to="/tracking/create-pixel">Test</Link> */}
          </div>
          <br />

          {conversionOptions.advertiserPixelStats.isLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <>
              <div
                className="scroll shadow bg-white"
                id="tour-step-3"
                style={{ borderRadius: "20px" }}
              >
                <table className="conversion-table">
                  <thead>
                    <tr>
                      <th
                        className="table-th-details text-center"
                        style={{ width: "20px" }}
                      >
                        {/* <Popup
                                        position="right"
                                        toggler="?"
                                        content="See preview of the campaign details."
                                    /> */}
                        <div
                          className="details-icon"
                          style={{ margin: "0 auto" }}
                          data-tooltip-id="tooltip-table-details"
                          data-tooltip-content={`See preview of the tag details.`}
                        >
                          {eyeSolid}
                        </div>
                      </th>
                      <th className="text-start">Conversion Name</th>
                      <th className="align-start">Status</th>
                        <th
                        >
                          <span
                            data-tooltip-id="tooltip-table-events"
                            data-tooltip-content={`The total number of events received since the pixel is active. This number includes all activities, not just conversions attributed to Blockchain-Ads.`}
                            className="with-sort"
                          >
                            Total Events Received
                          </span>
                        </th>
                      <th>Date created</th>
                      <th>Last Recorded</th>
                      <th style={{ width: 0 }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!conversionOptions.advertiserPixelStats.value.length ? (
                      <tr>
                        <td colSpan="10">
                          <div className="no-data-conatiner">
                            <div className="no-data">No Data To Show!</div>
                            <br />
                            <CodeType className="tracking-cover" />
                            <br />
                            <h4>
                              Install Blockchain-Ads Pixel to track conversions
                              from your advertising Campaigns
                            </h4>
                            <br />
                            <div>
                              {/* <TrackingDrawer name="Install Pixel"/> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      conversionsData.map((item, index) => {
                        return (
                          <ConversionTableRow
                            key={index}
                            {...{ item, index }}
                          />
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              <br />
            </>
          )}
          {conversionOptions.advertiserPixelStats.value.length ? (
            <CodeType className="tracking-cover" />
          ) : null}
        </CustomTabPanel>
        {isUserSuperAdmin && (
          <CustomTabPanel value={trackingPageTab} index={1}>
            <div
              className="row tracking-drawer-container"
              style={{ justifyContent: "flex-end", display: "flex" }}
            >

              {/* <Link to="/tracking/create-pixel">Test</Link> */}
            </div>
            <br />

            {conversionOptions.advertiserPixelStats.isLoading ? (
              <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
              <>
                <div
                  className="scroll shadow bg-white"
                  id="tour-step-3"
                  style={{ borderRadius: "20px" }}
                >
                  <table className="conversion-table">
                    <thead>
                      <tr>
                        <th
                          className="table-th-details text-center"
                          style={{ width: "20px" }}
                        >
                          <div
                            className="details-icon"
                            style={{ margin: "0 auto" }}
                            data-tooltip-id="tooltip-table-details"
                            data-tooltip-content={`See preview of the tag details.`}
                          >
                            {eyeSolid}
                          </div>
                        </th>
                        <th className="text-start">Audience Name</th>
                        <th className="align-start">Status</th>
                        <th>Audience Size</th>
                        <th>Date created</th>
                        <th>Last Recorded</th>
                        <th style={{ width: 0 }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!audienceData.length ? (
                        <tr>
                          <td colSpan="10">
                            <div className="no-data-conatiner">
                              <div className="no-data">No Data To Show!</div>
                              <br />
                              <CodeType className="tracking-cover" />
                              <br />
                              {/* <h4>
                          Install Blockchain-Ads Pixel to track conversions from
                          your advertising Campaigns
                        </h4> */}
                              <br />
                              <div>
                                {/* <TrackingDrawer name="Install Pixel"/> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        audienceData.map((item, index) => {
                          return (
                            <ConversionTableRow
                              key={index}
                              {...{ item, index }}
                            />
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
                <br />
              </>
            )}
            {!conversionOptions.advertiserPixelStats.value.length ? (
              <CodeType className="tracking-cover" />
            ) : null}
          </CustomTabPanel>
        )}
      </Box>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography id="modal-title" sx={{ fontSize: "13px" }}>
            Make sure your site has an active Google Tag Manager account and
            implementation before starting. To create and implement a new GTM
            account,{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="https://tagmanager.google.com/#/home"
              target="_blank"
            >
              click here
            </a>
          </Typography>
          <br />
          <Typography
            onClick={handleSwitchAccount}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #667686",
              maxWidth: "150px",
              justifyContent: "center",
              padding: "2px",
              borderRadius: "6px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {isSwitchingLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <img src={gtm} style={{ marginRight: "5px" }} /> Switch Account
              </>
            )}
          </Typography>
          <br />
          <InputLabel
            sx={{ alignItems: "center", display: "flex", marginBottom: "10px" }}
          >
            Select Account
            <Tooltip
              title="Select an account option from the dropdown"
              placement="right"
            >
              <IconButton
                size="small"
                sx={{ ml: 1, width: "13px", height: "13px" }}
                aria-label="info"
              >
                <HelpIcon sx={{ width: "13px", height: "13px" }} />
              </IconButton>
            </Tooltip>
          </InputLabel>

          {isAccountLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Select
              fullWidth
              value={selectedAccount}
              onChange={(e) => selectAccount(e.target.value)}
              size="small"
              // disabled={gtmAccounts?.length}
            >
              {gtmAccounts !== null ? (
                (gtmAccounts || []).map((item) => (
                  <MenuItem key={item.name} value={item.accountId}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Data Found</MenuItem>
              )}
            </Select>
          )}

          <br />
          <br />
          <InputLabel
            sx={{ alignItems: "center", display: "flex", marginBottom: "10px" }}
          >
            Select Container
            <Tooltip
              title="Select an container option from the dropdown"
              placement="right"
            >
              <IconButton
                size="small"
                sx={{ ml: 1, width: "13px", height: "13px" }}
                aria-label="info"
              >
                <HelpIcon sx={{ width: "13px", height: "13px" }} />
              </IconButton>
            </Tooltip>
          </InputLabel>

          {isContainerLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Select
              fullWidth
              value={seletedContainer}
              onChange={(e) => setSelectedConatiner(e.target.value)}
              size="small"
              disabled={!accountContainers?.length}
            >
              {(accountContainers || []).map((item) => (
                <MenuItem value={item.containerId}>{item.name}</MenuItem>
              ))}
            </Select>
          )}
          {!gtmAccounts && (
            <Alert severity="error" sx={{ marginTop: "20px" }}>
              <b>No Active Account</b> <br /> Please make sure to activate and
              set up your GTM account
            </Alert>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 2, marginRight: "20px" }}
              onClick={closeModal}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleCreateGTM}
              disabled={isPixeCreatingLoading || !selectedAccount}
            >
              Create{" "}
              {isPixeCreatingLoading && (
                <CircularProgress
                  size={15}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                    marginLeft: "10px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </motion.main>
  );
}
