import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Alert,
  Button
} from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { auth } from "../../../../config/firebase";
import useFetch from "../../../../hooks/useFetch";
import { handleGTMAuthentication } from "../googleAuthPopup";

export default function GTMIntegration() {
  const { disconnectGTMAccount } = useFetch();
  const [isSwitchingLoading, setIsSwitchingLoading] = useState(false);
  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
  const { createConversionData, requestedData, getLoadUserProfile, setGtmAccounts, isGTMConfigurationPopupOpen, 
    setIsGTMConfigurationPopupOpen,
    isGTMPopupOpen, 
    setIsGTMPopupOpen } = useGlobalContext();
  const [isConnected, setIsConnected] = useState(false);
  const { connectGTMAccount, getAccountsGTM } = useFetch();
  
  useEffect(() => {
    const checkConnection = () => {
      if (requestedData?.userProfileData?.currentOrganization?.isGtmConnected) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    };
    
    checkConnection();
  }, [createConversionData, requestedData]);
  
  if (createConversionData?.conversionMethod !== "gtm") {
    return null;
  }

  const handleGTM = (e) => {
    e.preventDefault();
    
    const organizationID = requestedData?.userProfileData?.currentOrganization?.id;
    
    handleGTMAuthentication(
      organizationID,
      connectGTMAccount,
      getAccountsGTM,
      setGtmAccounts,
      () => {},
      () => {
        getLoadUserProfile(organizationID);
      }
    );
  };

  const handleSwitchAccount = async () => {
    setIsSwitchingLoading(true);
    await disconnectGTM();
    
    const organizationID = requestedData?.userProfileData?.currentOrganization?.id;
    
    handleGTMAuthentication(
      organizationID,
      connectGTMAccount,
      getAccountsGTM,
      setGtmAccounts,
      setIsSwitchingLoading,
      setIsGTMConfigurationPopupOpen,
      setIsGTMPopupOpen,
      () => {
        getLoadUserProfile(organizationID);
      }
    );
  };

  const disconnectGTM = async () => {
    if (!requestedData.userProfileData) return;
    setIsDisconnectLoading(true);
    try {
      const tempRawr = {
        uuid: auth.currentUser?.uid,
        organizationID: requestedData?.userProfileData?.currentOrganization?.id,
      };

      await disconnectGTMAccount(() => {}, tempRawr);

      getLoadUserProfile(
        requestedData?.userProfileData?.currentOrganization?.id
      );
    } catch (error) {
      console.log(error);
    }

    setIsDisconnectLoading(false);
  };
  
  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        borderRadius: 2,
        mb: 3,
        width: "50%",
        maxWidth: "400px"
      }}
    >
      <Box sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center",
        mb: 1
      }}>
        <Typography sx={{ fontWeight: "bold", color: "#2c3e50", fontSize: "13px" }}>
          Google Tag Manager integration
        </Typography>
        
        {isConnected ? (
          <Box sx={{ 
            display: "flex", 
            alignItems: "center", 
            backgroundColor: "#e8f5e9", 
            px: 1.5, 
            py: 0.3, 
            borderRadius: 4,
            ml: 1
          }}>
            <Box 
              sx={{ 
                width: 8, 
                height: 8, 
                borderRadius: "50%", 
                backgroundColor: "#4caf50", 
                mr: 0.5 
              }} 
            />
            <Typography sx={{ color: "#2e7d32", fontWeight: "medium", fontSize: "12px" }}>
              Connected
            </Typography>
          </Box>
        ) : (
          <Alert 
            severity="error" 
            sx={{ py: 0, px: 1, fontSize: "12px", ml: 1, "& .MuiAlert-icon": { fontSize: "18px", mr: 0.5 } }}
          >
            Not connected
          </Alert>
        )}
      </Box>
      
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!isConnected ? (
          <Button 
            variant="contained" 
            color="primary" 
            size="small"
            sx={{ fontSize: "12px", py: 0.5, px: 1.5 }}
            onClick={handleGTM}
          >
            Connect
          </Button>
        ) : (
          <Typography 
            variant="body2" 
            sx={{ color: "#7f8c8d", fontSize: "12px", cursor: "pointer" }} 
            onClick={handleSwitchAccount}
          >
            Switch your GTM account
          </Typography>
        )}
      </Box>
    </Paper>
  );
}