import React, { useState, useEffect } from "react";
import localData from "../../localData";
import { Button } from "../../components";
import { BarChart, LineChart } from "../../components";
import { useGlobalContext } from "../../context";
import { Grid } from "@mui/material";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Icon,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useFormatter from "../../hooks/useFormatter";

const metrics = {
  clicks: {
    propertyName: "clicks",
    displayName: "Clicks",
    normalizeName: "normalizeClicks",
    isRightAxis: "right",
  },
  impressions: {
    propertyName: "impressions",
    displayName: "Impressions",
    normalizeName: "normalizeImpressions",
    isRightAxis: "right",
  },
  amount_spent: {
    propertyName: "amount_spent",
    displayName: "Ad Spend",
    normalizeName: "normalizeAmount_spent",
    isRightAxis: "right",
  },
  conversions: {
    propertyName: "conversions",
    displayName: "Conversions",
    normalizeName: "normalizeConversions",
    isRightAxis: "right",
  },
  CPC: {
    propertyName: "CPC",
    displayName: "CPC",
    normalizeName: "normalizeCPC",
    isRightAxis: "right",
  },
  CTR: {
    propertyName: "CTR",
    displayName: "CTR",
    normalizeName: "normalizeCTR",
    isRightAxis: "right",
  },
};

const cardColors = [
  { color: "#1a73e8", colorDefault: "#1a73e8" },
  { color: "#d93025", colorDefault: "#d93025" },
  { color: "#f9ab00", colorDefault: "#f9ab00" },
  { color: "#1DBA9B", colorDefault: "#1DBA9B" },
  { color: "#2F3192", colorDefault: "#2F3192" },
  { color: "#757574", colorDefault: "#757574" },
];

export default function ReportsGraph({selectedCampaigns}) {
  const {
    requestedData: {
      userProfileData,
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    isLoading: { campaignDataIsLoading, userProfileIsLoading },
    formatNumber,
    getDateName,
    activeCampaign,
    // selectedCampaigns,
    isDarkModeEnabled,
    campaignsState,
    campaignSelectionRange,
    getLoadCampaignData
  } = useGlobalContext();
  const { formatCurrency, removeAlias, formatDateToMMDD, addCommas } =
    useFormatter();

  const { user, textPreloader } = localData.images;
  const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

  const [cardConfigs, setCardConfigs] = useState([
    { ...metrics.clicks, ...cardColors[0], isActive: true, id: 0 },
    { ...metrics.impressions, ...cardColors[1], isActive: true, id: 1 },
    { ...metrics.amount_spent, ...cardColors[2], isActive: true, id: 2 },
    { ...metrics.conversions, ...cardColors[3], isActive: true, id: 3 },
    { ...metrics.CTR, ...cardColors[4], isActive: true, id: 4 },
    { ...metrics.CPC, ...cardColors[5], isActive: true, id: 5 },
  ]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const openMenu = Boolean(menuAnchorEl);

  useEffect(() => {
    getLoadCampaignData(
      userProfileData.currentOrganization.id,
      1,
      10,
      campaignSelectionRange.startDate,
      campaignSelectionRange.endDate
    )
  }, [campaignSelectionRange])

  const handleMenuOpen = (event, cardId) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedCardId(cardId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedCardId(null);
  };

  const handleMetricChange = (metricKey) => {
    setCardConfigs((prevConfigs) =>
      prevConfigs.map((card) =>
        card.id === selectedCardId
          ? {
              ...card,
              ...metrics[metricKey],
            }
          : card
      )
    );
    handleMenuClose();
  };

  const handleCardToggle = (cardId) => {
    setCardConfigs((prevConfigs) =>
      prevConfigs.map((card) =>
        card.id === cardId ? { ...card, isActive: !card.isActive } : card
      )
    );
  };

  const handleMouseHover = (cardId, isHovering) => {
    setCardConfigs((prevConfigs) => {
      const card = prevConfigs.find((c) => c.id === cardId);

      if (card.isActive || !isHovering) {
        return prevConfigs;
      }

      return prevConfigs.map((c) =>
        c.id === cardId
          ? { ...c, color: "lightgray", isActive: true, isHoverActive: true }
          : c
      );
    });
  };

  const defaultTotal = {
    impressions: 0,
    amount_spent: 0,
    clicks: 0,
    CTR: 0,
    CPC: 0,
    conversions: 0,
  };
  const [total, setTotal] = useState(defaultTotal);
  const [filteredGraphData, setFilteredGraphData] = useState([]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filterMultiGraphData = () => {
    if (!currentOrganizationCampaigns.length) {
      setFilteredGraphData([]);
      setTotal(defaultTotal);
      return;
    }

    if (!selectedCampaigns.length) {
      setFilteredGraphData([]);
      setTotal(defaultTotal);
      return;
    }

    const { startDate, endDate } = campaignSelectionRange;

    const array = [];
    const object = {
      impressions: 0,
      amount_spent: 0,
      clicks: 0,
      CTR: 0,
      CPC: 0,
      conversions: 0,
    };

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = formatDate(date);
      const aggregatedData = {
        name: getDateName({ date: dateString }),
        impressions: 0,
        amount_spent: 0,
        clicks: 0,
        conversions: 0,
        CTR: 0,
        CPC: 0,
        AllCTR: 0,
        AllCPC: 0,
      };

      let dataFound = false;

      selectedCampaigns.forEach((campaign) => {
        const { progressionData } = campaign;

        if (progressionData && progressionData[dateString]) {
          dataFound = true;
          const dataForDate = progressionData[dateString];

          ["impressions", "amount_spent", "clicks", "conversions"].forEach(
            (key) => {
              if (dataForDate[key] !== undefined) {
                aggregatedData[key] += dataForDate[key] || 0;
                object[key] += dataForDate[key] || 0;
              }
            }
          );

          ["CPC", "CTR"].forEach((key) => {
            if (dataForDate[key] !== undefined) {
              aggregatedData[key] += dataForDate[key] || 0;
              object[key] += dataForDate[key] || 0;
            }
          });
        }
      });

      if (dataFound) {
        if (aggregatedData.impressions > 0 && aggregatedData.amount_spent > 0) {
          aggregatedData.CTR =
            (aggregatedData.amount_spent / aggregatedData.impressions) * 1000;
          aggregatedData.AllCTR = aggregatedData.CTR;
        }

        if (aggregatedData.clicks > 0 && aggregatedData.amount_spent > 0) {
          aggregatedData.CPC =
            aggregatedData.amount_spent / aggregatedData.clicks;
          aggregatedData.AllCPC = aggregatedData.CPC;
        }

        aggregatedData.normalizeImpressions = aggregatedData.impressions;
        aggregatedData.normalizeAmount_spent = aggregatedData.amount_spent;
        aggregatedData.normalizeClicks = aggregatedData.clicks;
        aggregatedData.normalizeConversions = aggregatedData.conversions * 1.1;
        aggregatedData.normalizeCTR = aggregatedData.CTR;
        aggregatedData.normalizeCPC = aggregatedData.CPC;

        array.push(aggregatedData);
      } else {
        const emptyData = {
          name: formatDateToMMDD(date),
          empty: 0,
        };
        array.push(emptyData);
      }
    }

    if (object.impressions > 0 && object.amount_spent > 0) {
      object.CTR = (object.amount_spent / object.impressions) * 1000;
    }

    if (object.clicks > 0 && object.amount_spent > 0) {
      object.CPC = object.amount_spent / object.clicks;
    }

    setTotal(object);
    setFilteredGraphData(array);
  };
  useEffect(() => {
    filterMultiGraphData();
  }, [
    selectedCampaigns,
    activeCampaign,
    cardConfigs,
    currentOrganizationCampaigns,
  ]);

  const [isLineChartActive, setIsLineChartActive] = useState(true);

  const getValue = (propertyName) => {
    switch (propertyName) {
      case "clicks":
        return addCommas(total?.clicks?.toFixed(2)) || "0";
      case "amount_spent":
        return formatCurrency(total?.amount_spent, 0);
      case "impressions":
        return addCommas(Math.round(total?.impressions)) || "0";
      case "conversions":
        return addCommas(total?.conversions || 0) || 0;
      case "CPC":
        return formatCurrency(total?.CPC || 0, 2);
      case "CTR":
        return `${(total?.CTR || 0).toFixed(2)}%`;
      default:
        return "Unknown Metric";
    }
  };

  console.log(filteredGraphData, "filteredGraphData");

  const [isHover, setIsHover] = useState(false);

  return (
    <div className="campaigns-graph shadow">
      <Grid container spacing={1} sx={{ padding: "0", margin: "0" }}>
        {cardConfigs.map((card, index) => (
          <Grid className="graph-card-container" key={index}>
            <Card
              sx={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "row",
                alignItems: "center",
                padding: 2,
                borderRadius: index === 0 ? "20px 0 0 0" : 0,
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                border: !card.isActive && "1px solid lightgray",
                background: card.isActive && `${card.color}`,
                color: card.isActive && `#ffff`,
                height: "110px",
                width: "180px",
                position: "relative",
              }}
              className="graph-card"
              onClick={() => handleCardToggle(card.id)}
            >
              <CardContent sx={{ padding: 0, width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                    sx={{
                      color: card.isActive && `#ffff`,
                      margin: 0,
                    }}
                  >
                    {card.displayName}
                  </Typography>
                  {/* <MoreVertIcon
                    sx={{
                      cursor: "pointer",
                      color: card.isActive ? "#fff" : "rgba(0, 0, 0, 0.54)",
                    }}
                    onClick={(e) => handleMenuOpen(e, card.id)}
                  /> */}
                </Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {campaignDataIsLoading ? (
                    <img
                      src={textPreloader}
                      className="text-preloader"
                      alt="loading"
                    />
                  ) : (
                    getValue(card.propertyName, total)
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Menu anchorEl={menuAnchorEl} open={openMenu} onClose={handleMenuClose}>
        {Object.entries(metrics).map(([key, metric]) => (
          <MenuItem
            key={key}
            onClick={() => handleMetricChange(key)}
            sx={{
              backgroundColor:
                selectedCardId !== null &&
                cardConfigs.find((c) => c.id === selectedCardId)
                  ?.propertyName === key
                  ? "rgba(0, 0, 0, 0.08)"
                  : "inherit",
            }}
          >
            {metric.displayName}
          </MenuItem>
        ))}
      </Menu>

      <div className="campaigns-graph-inner">
        <div className={`chart-wrapper `}>
          {campaignDataIsLoading && (
            <div className="loader">
              <div className="message">Loading...</div>
              <img src={textPreloader} alt="loading" />
            </div>
          )}
          <div className="row row-bottom">
            <div className="col col-left btn-group">
              <Button
                variant="circle"
                icon={lineChart}
                size="sm"
                color={isLineChartActive ? "dark" : "light"}
                onClick={() => setIsLineChartActive(true)}
              />
              <Button
                variant="circle"
                icon={barChart}
                size="sm"
                color={isLineChartActive ? "light" : "dark"}
                onClick={() => setIsLineChartActive(false)}
              />
            </div>
          </div>
          <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <LineChart
              isTooltipFormatted={selectedCampaigns.length > 1 ? true : false}
              data={filteredGraphData}
              lines={cardConfigs.filter((card) => card.isActive)}
              isLoading={campaignDataIsLoading}
              isHover={isHover}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
