import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter";
import localData from "../../localData";
import { Button, Pagination, TableSkeleton } from "../../components";
import { useGlobalContext } from "../../context";

export default function ReferralTable({ allRows, loading, headers = [] }) {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);
  const { formatDateTime } = useFormatter();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { formatNumber } = useGlobalContext();

  const sortAndPaginate = (data) => {
    const sortedData = data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Set the sorted data
    setData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    return sortedData;
  };

  const setItems = (data) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    setRows(data.slice(startIndex, endIndex));
  };
  useEffect(() => {
    if (allRows) {
      try {
        const sortedData = sortAndPaginate(allRows);
        setItems(sortedData);
      } catch (error) {
        console.log(error);
      }
    }
  }, rows); // Empty depend
  const { textPreloader } = localData.images;
  const { check, close } = localData.svgs;

  const handlePagination = (nextPage = 1) => {
    setPage(nextPage);

    const startIndex = (nextPage - 1) * 10;
    const endIndex = startIndex + 10;
    setRows(data.slice(startIndex, endIndex));
  };

  const getRowProp = (item, header) => {
    if (header.type == "date") {
      if (!item[header.prop]) {
        return "";
      }
      return formatDateTime(new Date(item[header.prop]));
    }

    if (header.type == "amount") {
      return `$${formatNumber(String(item[header.prop]))}`;
    }

    if (header.type == "status") {
      return (
        <div
          data-tooltip-id="status-tooltip"
          className={`status ${item[header.prop]}`}
        >
          {item[header.prop]}
        </div>
      );
    }

    if (header.type == "boolean") {
      if (item[header.prop] == "false" || item[header.prop] == false) {
        return (
          <div data-tooltip-id="status-tooltip" className={`status pending`}>
            Pending
          </div>
        );
      } else {
        return (
          <div data-tooltip-id="status-tooltip" className={`status approved`}>
            Created
          </div>
        );
      }
    }

    return item[header.prop];
  };

  return (
    <>
      <div
        className="referral-page modal-dialog-scrollable modal-body"
        style={{ padding: "0px 1rem 20px" }}
      >
        <div className="scroll">
          {loading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table reports-table">
              <thead>
                <tr className="table-row">
                  {headers.map((header) => {
                    return <th>{header.header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.length == 0 ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  rows.map((item, index) => {
                    return (
                      <tr>
                        {headers.map((header) => {
                          return (
                            <td
                              className={`${
                                header.type == "status" ? "table-td-status" : ""
                              }`}
                            >
                              {getRowProp(item, header)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        {!rows.length ? (
          ""
        ) : (
          <div className={`pagination-wrapper  ${loading ? "on-hold" : ""}`}>
            <Pagination
              callback={handlePagination}
              currentPage={page}
              totalPages={totalPages}
              isLoading={loading}
            />
          </div>
        )}
        <br />
        <br />
      </div>
      <br />
      <br />
    </>
  );
}
