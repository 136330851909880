import React, { useState } from "react";
import localData from "../../../localData";
import { Button } from "../../../components";
import { useGlobalContext } from "../../../context";
import ReactDOMServer from "react-dom/server";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import useFetch from "../../../hooks/useFetch";
import CircularProgress from '@mui/material/CircularProgress';

export default function AdsCard({ organizationID,campaignName, userName, uuid, reviveName, reviveBannerId, campaignId, title, image, size, id, readOnly = false }) {
  const { creatives, setCreatives, campaignWizardStatus, errorAlert, successAlert } = useGlobalContext();
  const {deleteBanner} = useFetch()
  const { close, edit } = localData.svgs;
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [isLoading, setIsLoading] = useState(false)

  const deleteCreative = async () => {
    if(campaignWizardStatus === "edit"){
      setIsLoading(true)
      const deleteBanerData = {
        uuid: uuid,
        userName: userName,
        organizationID: organizationID.toString(),
        campaignId: campaignId,
        campaignName: campaignName,
        revBannerId: reviveBannerId,
        hubBannerId: id
      }
      try {
        const data = await deleteBanner(() => {}, deleteBanerData);
        successAlert(data.res_msg)
        setIsLoading(false)
        let tempCreatives = [...creatives];
        tempCreatives = tempCreatives.filter((item) => item.reviveName !== reviveName);        
        setCreatives(tempCreatives);
        return;
    } catch (err) {
        errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
        setIsLoading(false)
        return;
    }
    } 
    let tempCreatives = [...creatives];
    tempCreatives = tempCreatives.filter((item) => item.reviveName !== reviveName);        
    setCreatives(tempCreatives);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancle = () => {
    setIsEditing(false);
  };

  const handleEditCreatives = (e) => {
    const updatedCreatives = creatives.map((creative) => {
      if (creative.id === id) {
        return { ...creative, title: editedTitle };
      }
      return creative;
    });

    setCreatives(updatedCreatives);
    setIsEditing(false);
  };

  return (
    <div className="card ads-card">
      {isLoading ? <CircularProgress /> : 
      <div>
      <h4 className="card-title">
        {isEditing ? (
          <div className="edit-title-ads-card">
            <input
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <CheckIcon fontSize="small" onClick={handleEditCreatives} />{" "}
            <CloseIcon fontSize="small" onClick={handleCancle} />
          </div>
        ) : (
          <div className="card-title" style={{ width: "100%" }}>
            <div>
              <span className="card-title-content">{title}</span>
              <span onClick={handleEdit} className="edit-icon-ads-card">
                {edit}
              </span>
            </div>

            <Button
              style={readOnly ? { opacity: 0, pointerEvents: "none" } : {}}
              className="btn-close"
              variant="circle"
              color="dark"
              icon={close}
              size="sm"
              onClick={() => deleteCreative(reviveName)}
            />
          </div>
        )}
      </h4>
      <div className="card-body">
        <div className="card-images">
          <div
            className="card-image"
            data-tooltip-float={true}
            data-tooltip-id={`tooltip-ads-card`}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="image-wrapper">
                <img src={image} />
              </div>
            )}
          >
            <img src={image} alt={title} />
          </div>
        </div>
      </div>

      <div className="card-footer">
        <h4 className="card-footer-title">Size</h4>
        <div className="wrapper">
          <div className="card-size">{size}</div>
        </div>
      </div>
      </div>
    }
    </div>
  );
}
