import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import localData from '../../../localData';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F7F7F7',
    padding: 20,
    fontFamily: 'Helvetica'
  },
  section: {
    marginBottom: 15,
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 5
  },
  header: {
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10
  },
  clientInfo: {
    fontSize: 11,
    color: '#333',
    marginBottom: 3
  },
  headerRight: {
    width: 100,
    height: 30
  },
  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    marginTop: 10
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    borderBottomStyle: 'solid',
    minHeight: 30,
    alignItems: 'center'
  },
  tableHeaderRow: {
    flexDirection: 'row',
    minHeight: 40,
    alignItems: 'center',
    backgroundColor: '#53B88B',
    color: 'white'
  },
  tableLastRow: {
    flexDirection: 'row',
    minHeight: 30,
    alignItems: 'center'
  },
  tableColHeader: {
    width: '50%',
    paddingVertical: 8,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
    fontSize: 10
  },
  tableCol: {
    width: '50%',
    paddingVertical: 8,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontSize: 12,
    borderRightWidth: 1,
    borderRightColor: '#E0E0E0',
    justifyContent: 'center'
  },
  tableCellLeft: {
    fontWeight: 'bold',
    fontSize: 14
  },
  tableColRight: {
    width: '50%',
    paddingVertical: 8,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontSize: 14
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
    color: '#555'
  },
  button: {
    marginTop: 15,
    backgroundColor: '#53B88B',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 10
  },
  bulletItem: {
    flexDirection: 'row',
    marginBottom: 5,
    fontSize: 12
  },
  bullet: {
    width: 10,
    fontSize: 12
  },
  logo: {
    maxWidth: "200px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 40
  }
});

const PageHeader = ({ title, reportingPeriod }) => (
  <View style={styles.header}>
    <View>
      <Text style={styles.title}>Campaign Performance Report</Text>
      <Text style={styles.clientInfo}>Reporting Period: {reportingPeriod}</Text>
      <Text style={styles.clientInfo}>Campaign Name: {title}</Text>
    </View>
  </View>
);


const CampaignReportPDF = ({ campaignData, allTabsData, selectedPeriod, selectedCampaing }) => {  
  console.log(selectedPeriod, "maliprcko");
  console.log(selectedCampaing, "maliprcko");
  
  const formatNumber = (num) => {
    return num?.toLocaleString() || '0';
  };
  
  const formatCurrency = (num) => {
    return `$${(num || 0).toFixed(2)}`;
  };
  
  const formatPercentage = (num) => {
    return `${(num || 0).toFixed(2)}%`;
  };
  
  const summaryData = {
    impressions: campaignData.impressions || 0,
    clicks: campaignData.clicks || 0,
    ctr: campaignData.CTR || 0,
    adSpend: campaignData.amount_spent || 0,
    cpc: campaignData.CPC || 0,
    cpa: campaignData.CPA || 0,
    conversions: campaignData.conversions || 0
  };
  const {logoDark} = localData.images

  function formatDate(value = null) {
    const date = value !== null ? new Date(value) : new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const date = `${formatDate(selectedPeriod.startDate)} - ${formatDate(selectedPeriod.endDate)}`
  
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={logoDark} style={styles.logo}/>
        </View>
        <PageHeader 
          title={selectedCampaing?.name} 
          reportingPeriod={date} 
        />
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>1. Campaign Performance Overview</Text>
          
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableHeaderRow}>
              <View style={styles.tableColHeader}>
                <Text>Metric</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text>Value</Text>
              </View>
            </View>
            
            {/* Table Row - Total Spend */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>Total Spend</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatCurrency(summaryData.adSpend)}</Text>
              </View>
            </View>
            
            {/* Table Row - Impressions */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>Impressions</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatNumber(summaryData.impressions)}</Text>
              </View>
            </View>
            
            {/* Table Row - Clicks */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>Clicks</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatNumber(summaryData.clicks)}</Text>
              </View>
            </View>
            
            {/* Table Row - CTR */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>CTR</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatPercentage(summaryData.ctr)}</Text>
              </View>
            </View>
            
            {/* Table Row - CPC */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>CPC</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatCurrency(summaryData.cpc)}</Text>
              </View>
            </View>
            
            {/* Table Row - CPA */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>CPA</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatCurrency(summaryData.cpa || 0)}</Text>
              </View>
            </View>
            
            {/* Table Row - Conversions */}
            <View style={styles.tableLastRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellLeft}>Conversions</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text>{formatNumber(summaryData.conversions)}</Text>
              </View>
            </View>
          </View>
        </View>
        
        <Text style={styles.footer}>Page 1 of 5 | Powered by Blockchain-Ads</Text>
      </Page>
      
      <Page size="A4" style={styles.page}>        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>2. Top Performing Audiences</Text>
          
          <View style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={[styles.tableColHeader, { width: '25%' }]}>
                <Text>Audience Segment</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Impressions</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>Clicks</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CTR</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CPC</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>CPA</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Conversions</Text>
              </View>
            </View>
            
            {allTabsData.audiences.map((audience, index) => (
              <View key={index} style={index === allTabsData.audiences.length - 1 ? styles.tableLastRow : styles.tableRow}>
                <View style={[styles.tableCol, { width: '25%' }]}>
                  <Text>{audience.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatNumber(audience.impressions)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatNumber(audience.clicks)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatPercentage(audience.ctr)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatCurrency(audience.cpc)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatCurrency(audience.cpa || 0)}</Text>
                </View>
                <View style={[styles.tableColRight, { width: '15%' }]}>
                  <Text>{formatNumber(audience.conversions)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        
        <Text style={styles.footer}>Page 2 of 5 | Powered by Blockchain-Ads</Text>
      </Page>
      
      {/* Page 3: Top Performing Ads */}
      <Page size="A4" style={styles.page}>
        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>3. Top Performing Ads</Text>
          
          <View style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={[styles.tableColHeader, { width: '25%' }]}>
                <Text>Ad Name</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Impressions</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>Clicks</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CTR</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CPC</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>CPA</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Conversions</Text>
              </View>
            </View>
            
            {allTabsData.ads.map((ad, index) => (
              <View key={index} style={index === allTabsData.ads.length - 1 ? styles.tableLastRow : styles.tableRow}>
                <View style={[styles.tableCol, { width: '25%' }]}>
                  <Text>{ad.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatNumber(ad.impressions)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatNumber(ad.clicks)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatPercentage(ad.ctr)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatCurrency(ad.cpc)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatCurrency(ad.cpa || 0)}</Text>
                </View>
                <View style={[styles.tableColRight, { width: '15%' }]}>
                  <Text>{formatNumber(ad.conversions)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        
        <Text style={styles.footer}>Page 3 of 5 | Powered by Blockchain-Ads</Text>
      </Page>
      
      {/* Page 4: Top Geographies */}
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>4. Top Geographies (Geos)</Text>
          
          <View style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={[styles.tableColHeader, { width: '25%' }]}>
                <Text>Country</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Impressions</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>Clicks</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CTR</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CPC</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>CPA</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Conversions</Text>
              </View>
            </View>
            
            {allTabsData.geo.map((geo, index) => (
              <View key={index} style={index === allTabsData.geo.length - 1 ? styles.tableLastRow : styles.tableRow}>
                <View style={[styles.tableCol, { width: '25%' }]}>
                  <Text>{geo.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatNumber(geo.impressions)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatNumber(geo.clicks)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatPercentage(geo.ctr)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatCurrency(geo.cpc)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatCurrency(geo.cpa || 0)}</Text>
                </View>
                <View style={[styles.tableColRight, { width: '15%' }]}>
                  <Text>{formatNumber(geo.conversions)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        
        <Text style={styles.footer}>Page 4 of 5 | Powered by Blockchain-Ads</Text>
      </Page>
      
      {/* Page 5: Top Publishers */}
      <Page size="A4" style={styles.page}>
      
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>5. Top Publishers</Text>
          
          <View style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={[styles.tableColHeader, { width: '25%' }]}>
                <Text>Publisher</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Impressions</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>Clicks</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CTR</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '10%' }]}>
                <Text>CPC</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>CPA</Text>
              </View>
              <View style={[styles.tableColHeader, { width: '15%' }]}>
                <Text>Conversions</Text>
              </View>
            </View>
            
            {allTabsData.publishers.map((publisher, index) => (
              <View key={index} style={index === allTabsData.publishers.length - 1 ? styles.tableLastRow : styles.tableRow}>
                <View style={[styles.tableCol, { width: '25%' }]}>
                  <Text>{publisher.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatNumber(publisher.impressions)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatNumber(publisher.clicks)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatPercentage(publisher.ctr)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '10%' }]}>
                  <Text>{formatCurrency(publisher.cpc)}</Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text>{formatCurrency(publisher.cpa || 0)}</Text>
                </View>
                <View style={[styles.tableColRight, { width: '15%' }]}>
                  <Text>{formatNumber(publisher.conversions)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>  
        <Text style={styles.footer}>Page 5 of 5 | Powered by Blockchain-Ads</Text>
      </Page>
    </Document>
  );
};

export const PDFDownloader = ({ campaignData, allTabsData, selectedPeriod,selectedCampaing }) => (  
  <PDFDownloadLink
    document={
      <CampaignReportPDF 
        campaignData={campaignData} 
        allTabsData={allTabsData}
        selectedPeriod={selectedPeriod}
        selectedCampaing={selectedCampaing}
      />
    }
    fileName={`campaign-report-${new Date().toISOString().slice(0, 10)}.pdf`}
    style={{
      textDecoration: 'none',
      display: 'none'
    }}
  >
    {({ blob, url, loading, error }) => {
      return null;
    }}
  </PDFDownloadLink>
);