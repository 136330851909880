import React, { useState, useEffect } from 'react';
// Material UI imports
import { 
  Box, 
  Container, 
  Typography, 
  Tabs, 
  Tab, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Alert, 
  AlertTitle,
  Chip,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useFetch from '../../hooks/useFetch';
import { auth } from '../../config/firebase';
import { useGlobalContext } from '../../context';
import { Button } from '../../components';

const ApiPage = () => {
  const { requestedData } = useGlobalContext();
  const organizationId = requestedData?.userProfileData?.currentOrganization?.id;
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usageData, setUsageData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const {  
    createApiKey, 
    deleteApiKey, 
    checkApiUsage, 
    getApiKeysForOrganization 
  } = useFetch();

  // Fetch API keys on component mount
  useEffect(() => {
    if (organizationId) {
      fetchApiKeys();
    }
  }, [organizationId]);

  // Clear messages after 5 seconds
  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  // Reset form function
  const resetMessages = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // If switching to Usage tab, automatically fetch usage data
    if (newValue === 1 && organizationId && !usageData) {
      handleCheckUsage();
    }
  };

  // Copy API key to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopySnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Failed to copy: ', error);
      });
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setCopySnackbarOpen(false);
  };

  // Create new API key
  const handleCreateApiKey = async () => {
    resetMessages();
    
    setLoading(true);
    try {
      const result = await createApiKey(() => {}, {
        organizationId
      });
      
      if (result && result.res_sts) {
        setSuccessMessage('API key created successfully');
        // Refresh the list of API keys
        await fetchApiKeys();
      } else {
        setErrorMessage(result?.res_msg || 'Failed to create API key');
      }
    } catch (error) {
      setErrorMessage('Error creating API key: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch API keys for organization
  const fetchApiKeys = async () => {
    resetMessages();
    
    if (!organizationId) {
      setErrorMessage('Organization ID is not available');
      return;
    }

    setLoading(true);
    try {
      const result = await getApiKeysForOrganization(() => {}, {
        uuid: auth.currentUser.uid,
        organizationId
      });
      
      if (result && result.res_sts) {
        setApiKeys(result.res_data || []);
        if (result.res_data && result.res_data.length === 0) {
          setSuccessMessage('No API keys found for this organization');
        }
      } else {
        setErrorMessage(result?.res_msg || 'Failed to fetch API keys');
        setApiKeys([]);
      }
    } catch (error) {
      setApiKeys([]);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteDialog = (keyId) => {
    setKeyToDelete(keyId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setKeyToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteApiKey = async () => {
    resetMessages();
    
    if (!keyToDelete) {
      setErrorMessage('API key ID is required');
      closeDeleteDialog();
      return;
    }

    setLoading(true);
    try {
      const result = await deleteApiKey(() => {}, {
        keyId: keyToDelete
      });
      
      if (result && result.res_sts) {
        setSuccessMessage('API key deleted successfully');
        await fetchApiKeys();
      } else {
        setErrorMessage(result?.res_msg || 'Failed to delete API key');
      }
    } catch (error) {
      setErrorMessage('Error deleting API key: ' + error.message);
    } finally {
      setLoading(false);
      closeDeleteDialog();
    }
  };

  // Check API usage
  const handleCheckUsage = async () => {
    resetMessages();
    
    if (!organizationId) {
      setErrorMessage('Organization ID is not available');
      return;
    }
    
    setLoading(true);
    
    try {
      const result = await checkApiUsage(() => {}, {
        uuid: auth.currentUser.uid,
        organizationId
      });
      
      if (result && result.res_sts) {
        setUsageData(result.res_data);
        setSuccessMessage('API usage data retrieved successfully');
      } else {
        setErrorMessage(result?.res_msg || 'Failed to retrieve API usage data');
        setUsageData(null);
      }
    } catch (error) {
      setErrorMessage('Error checking API usage: ' + error.message);
      setUsageData(null);
    } finally {
      setLoading(false);
    }
  };

  // Format date function
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    try {
      return new Date(dateString).toLocaleString();
    } catch (error) {
      return 'Invalid date';
    }
  };

  if (!organizationId) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Organization data is not available. Please select an organization first.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" component="h1" gutterBottom align="center" sx={{ mb: 3 }}>
          API Keys Management
        </Typography>
        
        {/* Organization Info */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Organization: {requestedData?.userProfileData?.currentOrganization?.name || organizationId}
          </Typography>
        </Box>
        
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="API Keys" icon={<VisibilityIcon />} iconPosition="start" />
          </Tabs>
          <Button
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                onClick={handleCreateApiKey}
                disabled={loading}
              >
                Create New API Key
              </Button>
        </Box>

        {/* Loading indicator */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
        {activeTab === 0 && (
          <Box>
            {/* Actions */}
            <Typography variant="h6" gutterBottom>
              API Keys
            </Typography>
            
            {apiKeys.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Key ID</TableCell>
                      <TableCell>API Key</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Usage</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {apiKeys.map((key) => (
                      <TableRow key={key.keyId} hover>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                            {key.keyId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip 
                              title={
                                <Box sx={{ p: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>API Key:</Typography>
                                  <Typography variant="body2" sx={{ fontFamily: 'monospace', wordBreak: 'break-all' }}>
                                    {key.apiKey || 'No API key available'}
                                  </Typography>
                                </Box>
                              } 
                              arrow
                              placement="top"
                            >
                              <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                {key.apiKey ? key.apiKey.substring(0, 8) + '...' : 'N/A'}
                              </Typography>
                            </Tooltip>
                            {key.apiKey && (
                              <IconButton 
                                size="small" 
                                onClick={() => copyToClipboard(key.apiKey)}
                                sx={{ ml: 1 }}
                                color="primary"
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {formatDate(key.createdAt)}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={key.active ? "Active" : "Inactive"}
                            color={key.active ? "success" : "error"}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {key.usage ? `${key.usage.count}/${key.usage.limit}` : '0/0'}
                          </Typography>
                          {key.lastReset && (
                            <Typography variant="caption" color="text.secondary">
                              Last Reset: {formatDate(key.lastReset)}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton 
                            color="error" 
                            onClick={() => openDeleteDialog(key.keyId)}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                No API keys found for this organization. Create a new key using the button above.
              </Typography>
            )}
          </Box>
        )}
          </>)}


      </Paper>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Delete API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this API key? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteApiKey} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Copy to Clipboard Snackbar */}
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="API key copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
};

export default ApiPage;