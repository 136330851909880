import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  BannerSlide,
  Carousel,
  Select as CustomSelect,
  Loading,
} from "../../components";

import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AverageStatsChart from "./components/AverageStatsChart";
import AudiencesChart from "./components/AudiencesChart";
import AnalysisChart from "./components/AnalysisChart";
import AverageCosts from "./components/AverageCosts";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { categoriesData } from "../../utils/insightsData";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
  startDate,
  endDate,
  key: "selection",
};

const { edit, question, password, support, userShield, arrowLeftLong } =
  localData.svgs;
const {
  avatar,
  placeholder,
  gamingImage1,
  gamingImage2,
  gamingImage3,
  tradingImage1,
  tradingImage2,
  tradingImage3,
  web3CasinosImage1,
  web3CasinosImage2,
  web3CasinosImage3,
  deFiImage1,
  deFiImage2,
  deFiImage3,
  NFTImage1,
  NFTImage2,
  NFTImage3,
  preloaderBig,
} = localData.images;

// const verticalsData = {
//     blockchainGaming: [{ cover: gamingImage1 }, { cover: gamingImage2 }, { cover: gamingImage3 }],
//     cryptoTrading: [{ cover: tradingImage1 }, { cover: tradingImage2 }, { cover: tradingImage3 }],
//     web3Casinos: [{ cover: web3CasinosImage1 }, { cover: web3CasinosImage2 }, { cover: web3CasinosImage3 }],
//     deFi: [{ cover: deFiImage1 }, { cover: deFiImage2 }, { cover: deFiImage3 }],
//     NFT: [{ cover: NFTImage1 }, { cover: NFTImage2 }, { cover: NFTImage3 }],
// };

export default function Insights({ isPublic, mainContentRef }) {
  useEffect(() => {
    document.title = "Live Stats";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Get Insights on Advertisers' Performance and Enhance Campaigns.";
  }, []);

  const { fade } = useGlobalContext().animations;
  const { activeCategory, setActiveCategory} = useGlobalContext();

  const [dateRange, setDateRange] = useState([
    { title: "Last 7 Days", value: "7", isSelected: true, id: uuidv4() },
    { title: "Last 30 Days", value: "30", isSelected: false, id: uuidv4() },
    { title: "Last Quarter", value: "90", isSelected: false, id: uuidv4() },
  ]);
  const [categories, setCategories] = useState({});
  const categoriesLength = Object.keys(categoriesData).length;
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [categoryItems, setCategoryItems] = useState([
    {
      startIcon: null,
      title: "Blockchain Gaming",
      value: "blockchainGaming",
      active: true,
    },
    {
        startIcon: null,
        title: "Web3 Gaming & Casinos",
        value: "web3GamingCasinos",
        // disabled: true,
    },
    // {
    //     startIcon: null,
    //     title: "Web3 Casinos",
    //     value: "web3Casinos",
    // },
    {
      startIcon: null,
      title: "DeFi",
      value: "deFi",
    },
    {
      startIcon: null,
      title: "NFT/Meme",
      value: "NFT",
    },
    {
      startIcon: null,
      title: "Infrastructure",
      value: "infrastructure",
    },
    {
      startIcon: null,
      title: "Token Launches",
      value: "tokenLaunches",
    },
  ]);

  const [verticalsData, setVerticalsData] = useState({
    blockchainGaming: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Aradena Games.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Excelsior.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gala.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gamety.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Reel33.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Telefriens.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Virtual Versions.gif",
    ],
    NFT: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Metamorphoses.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Paras Comic.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Paras Marketplace.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Raremint.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Space Metaverse.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/XTN3GZ.gif",
    ],
    web3Casinos: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ(1).gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ(2).gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/4 HQ.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Bet.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Betplay.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Cryptorush.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Dafabet.jpeg",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Roobet.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Stake.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Vbet.gif",
    ],
    cryptoTrading: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Alpha Pro.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Bomb Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Coinbase.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/DogeVision.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/EstateX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Etuktuk.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/FXTM.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Monk Coin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Nebeus.jpeg",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/NextGem.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/OKX.png",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Optimus Ventures.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Palmswap.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Starchain.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/WorkX.gif",
    ],
    deFi: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/BettingCroc.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/BlockDAG.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Bossie.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Compound.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Defiway.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/E-Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Layer3.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Mercado Bitcoin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Ridian.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/RocketX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Rubic Finance.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Telegram.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/UNCX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Valuit.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Xodex.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/brainedge.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/zkEra.gif",
    ],
    tokenLaunches: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/ 8.Gamety.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/1. Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/10. E-Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/11. Aradena Games.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/12. Etuktuk.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/13. Optimus Ventures.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/2. Layer3.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/3. Palmswap.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/4. WorkX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/5. Excelsior.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/6. Bomb Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/7. Monk Coin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/8. Virtual Versions.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/9. EstateX.gif",
    ],
    infrastructure: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/1. Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/2. Rubic Finance.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/3. UNCX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/4. brainedge.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/5. Starchain.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/6. Xodex.gif",
    ],
    web3GamingCasinos: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/9. EstateX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/6. Bomb Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gala.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gamety.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/5. Excelsior.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/1. Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/E-Money.gif",
    ],
  });

  // useEffect(() => {
  //     _getInsightsImages({
  //         callback: (data) => {
  //             console.log(data, "here");
  //             // setImageSrc('')
  //             setVerticalsData(data);
  //         },
  //     });
  // }, []);

  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    const updatedItems = categoryItems.map((item) => ({
      ...item,
      active: item.value === value,
    }));
    setCategoryItems(updatedItems);
    setActiveCategory(value);
  };

  const [selectedDate, setSelectedDate] = useState("7")

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    const updatedDateRange = dateRange.map(item => ({
      ...item,
      isSelected: item.value === selectedValue,
    }));
    console.log(selectedValue, "selectedValue");
    
    setDateRange(updatedDateRange);    
    setSelectedDate(selectedValue);
  };

  useEffect(() => {
    const handleScroll = () => {
        if (mainContentRef?.current) {
            const scrollTop = mainContentRef.current.scrollTop;
            console.log("Scroll Top:", scrollTop);

            if (scrollTop > 0) {
                setIsScrolled(true);  
            } else {
                setIsScrolled(false);
            }
        }
    };

    if (mainContentRef?.current) {
        mainContentRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (mainContentRef?.current) {
            mainContentRef.current.removeEventListener('scroll', handleScroll);
        }
    };
}, [mainContentRef]);


useEffect(() => {
  if(categoriesLength <= 0){
    setIsDataLoading(true)
  }
}, [categories])

  return (
    <React.Fragment>
            <div className={`verticals-container ${isScrolled ? "scrolled" : ""}`}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <h5 className="select-vertical-label">Select Vertical</h5>
          {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Select Vertical
          </InputLabel> */}
          <Select value={activeCategory} onChange={handleChange}>
            {categoryItems.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
              >
                {item.title === "cost" ? "Avg. CPA" : item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="timeframe-selector">
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Select Timeframe
          </InputLabel>
          <Select value={selectedDate} onChange={handleDateChange}>
            {dateRange.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
              >
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      
    <motion.main {...fade} className="main">
        <h2 className="main-title-insights">Platform Live Stats</h2>
      <div className="insights-container">

   
      {isDataLoading ? <Loading className="extended"/> : 
        <div className="insights-page-content bg-white">
          <div style={{ marginBottom: "4rem" }}>
            <AverageStatsChart categories={categoriesData} {...{ categoriesData, dateRange }} />
          </div>
          <div style={{ marginBottom: "4rem" }}>
            <AudiencesChart categories={categoriesData} {...{ categoriesData, dateRange }} />
          </div>
          <div>
            <AverageCosts categories={categoriesData} activeCategory={activeCategory} {...{ categoriesData, dateRange }} />
          </div>
          <div className="insights-row">
            <div className="chart-conatiner">
              <AnalysisChart  categories={categoriesData} {...{ categoriesData, dateRange }} />
            </div>
            <div className="wrapper">
              <h3 className="banner-slide-title">Top Performing Ads</h3>
              <br />
              <BannerSlide  data={verticalsData[activeCategory]} />
            </div>
          </div>
        </div>    
      }

      </div>
    </motion.main>
    </React.Fragment>
  );
}
